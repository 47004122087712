import React, { useCallback, useState } from "react";
import { Dialog } from "primereact/dialog";
import { OverlayPanel } from "primereact/overlaypanel";
// redux
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../redux/hooks";
import Table from "components/Table/Table";

import InformationBox from "components/common/Message/InformationBox/InformationBox";
import PopOver from "components/common/PopOver/PopOver";
import { ATTACHMENT_LIST_COLS } from "./AttachmentListData";
import { getMasterDataAttachmentList, onDownloadMultipleAttachment, setAttachmentList, setAttachmentPageDetails } from "redux/pages/Inspection/InspectionTask/InspectionDetails/Equipment/EquipmentSlice";

interface Props {
  showModal: boolean;
  setShowModal: (state: boolean) => void;
  selectedRows: any;
  selectedItems: any;
}

export const AttachmentList: React.FC<Props> = ({
  showModal = false,
  setShowModal = () => { },
  selectedRows = [],
  selectedItems = [],
}) => {

  const attachmentList = useAppSelector((state) => state.equipment.attachmentList);
  const dispatch = useDispatch<any>();
  const downloadRef = React.createRef<OverlayPanel>();

  const [pageIndex, setPageIndex] = useState(0)
  const [displayInfoModal, setDisplayInfoModal] = useState(false);
  const [message, setMessage] = useState("");

  const [selectedAttachments, setSelectedAttachments] = useState<any>([]);
  const [attachmentListdetails] = useState({
    currentLength: 100,
    currentStart: 1,
    totalCount: 500,
  });

  const onSort = (event) => {
    let sortDirection: string;
    if (event.sortOrder === 1)
      sortDirection = "ASC"
    else
      sortDirection = "DESC"
    dispatch(getMasterDataAttachmentList(selectedRows[0]?.uniqueSystemId,selectedItems[0]?.EquipmentId,setMessage,setDisplayInfoModal,setShowModal, event?.sortField, sortDirection))
  }

  const handleRowSelect = useCallback((data: any) => {
    setSelectedAttachments([...data]);
  }, []);

  const onDownload = () => {
    if (selectedAttachments?.length) {
      let attachmentIds: any = [];
      selectedAttachments.map(item => {
        attachmentIds.push(item.attachmentId);
      });
      dispatch(onDownloadMultipleAttachment(selectedAttachments[0]?.uniqueInspectionTaskId, selectedItems[0]?.EquipmentId, attachmentIds, setMessage, setDisplayInfoModal));
    } else {
      setDisplayInfoModal(true);
      setMessage('Select Attachments');
    }
  };

  return (
    <>
      <Dialog
        header="Attachment List"
        visible={showModal}
        style={{ width: "50vw" }}
        onHide={() => {
          dispatch(setAttachmentList([]));
          dispatch(setAttachmentPageDetails([]));
          setShowModal(false);
        }}
        className="display-settings personal selectsite"
      >
          <Table
            rows={attachmentList}
            cols={ATTACHMENT_LIST_COLS.LIST_COLS}
            dataKeyId="attachmentId"
            title={``}
            pageDetails={attachmentListdetails}
            pageIndex={pageIndex}
            showDownloadIcon={true}
            showSettingIcon={true}
            showHamIcon={false}
            showAddIcon={false}
            showDeleteIcon={false}
            showEditIcon={false}
            searchboxfield={false}
            showroweditor={false}
            onSortData={onSort}
            getSelectedRowData={handleRowSelect}
            onDownloadClick={(e) => downloadRef.current?.toggle(e)}
            settingListItems={[]}
          />
        <InformationBox
          showInfoModal={displayInfoModal}
          setShowInfoModal={setDisplayInfoModal}
          message={message}
        />
        <PopOver ref={downloadRef}>
        <ul>
          <li onClick={onDownload}>Download</li>
        </ul>
      </PopOver>
      </Dialog>
    </>
  );
};

export default AttachmentList;
