import React from 'react'

const ReportsInspectionActivityDocument = () => {
  return (
    <>
      <h4 className="doc-title mb-2">
        Inspection Activity Report
      </h4>

      <p>
        This report shows the list of Inspection Activities conducted during the selected Time period. 
        It includes the details such as Customer Number, Customer Name, Area, Sales office, 
        Inspection Task, Inspection Report(s) generated, Final Inspection Report(s) Uploaded, 
        Contract Number, Contract Name, Site Number, Site Name and System details.
      </p>

      <p>
        User can filter the activity based on Planned Start date and End Date and then clicking Apply button. 
        The report can be customized by selecting Customize button.
      </p>
    </>
  );
}
export default ReportsInspectionActivityDocument;
