import React, { useEffect, useRef, useState  } from "react";

import { Button } from "primereact/button";
import MultiSelect from "components/common/MultiSelect/MultiSelect";
import { Dialog } from "primereact/dialog";
//redux
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../redux/hooks";
import { getContractSiteDetails, setContractSiteDetails, setUpdatedContractSiteDetails, updateContractSiteMapping } from "redux/pages/Inspection/Contracts/contractSlice";
import InformationBox from "components/common/Message/InformationBox/InformationBox";
import { fetchActiveSiteDetails } from "redux/pages/Administration/administrationSlice";

interface Props {
  showModal: boolean;
  setShowModal: (state: boolean) => void;
  selectedRows: any;
  onUpdateContractSite: () => void;
  setSelectedRows: (state: boolean) => void;
}


export const SiteModalContract: React.FC<Props> = ({
  showModal = false,
  setShowModal = () => {},
  selectedRows = [],
  onUpdateContractSite = () => {},
  setSelectedRows = () => {}
}) => {
  const contractSiteDetails = useAppSelector((state) => state.contract.contractSiteDetails);
  const updatedSiteDetails = useAppSelector((state) => state.contract.updatedContractSiteDetails);
  const activeSite = useAppSelector((state) => state.administration.activeSiteDetails);
  
  const [siteData, setSiteData] = useState<any>([]);
  const [contractName, setContractName] = useState('');
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [displayInfoModal, setDisplayInfoModal] = useState(false);
  const [message, setMessage] = useState("");
  const checkedRows = useRef<any>();
  const dispatch = useDispatch<any>();
  const selectedSite = useRef<any>();
  const disabledRows = useRef<any>();

  useEffect(() => {
    dispatch(getContractSiteDetails(selectedRows.uniqueContractId));
    dispatch(fetchActiveSiteDetails());
    
    if (selectedRows.status === "Closed")
      setShowSaveButton(false);
    else
      setShowSaveButton(true);
  }, [dispatch])

  useEffect(() => {
    if (contractSiteDetails) {
      let siteArray: any = [];
      let selectedContract = contractSiteDetails?.contractName + " (" + selectedRows?.contractNumber + ")";
      setContractName(selectedContract)
      contractSiteDetails?.siteDetails?.map((item: any) => {
        return siteArray.push({
          id: item.uniqueSiteId,
          siteName: item.siteName,
          checked: item.isSelected,
          selected: false,
          disabled: item.isDisabled,
        });
      });
      setSiteData(siteArray);
    }
  }, [contractSiteDetails]);

  useEffect(() => {
    if (updatedSiteDetails?.status === "done") {
      onUpdateContractSite && onUpdateContractSite()
      dispatch(setUpdatedContractSiteDetails(""))
    }
  }, [updatedSiteDetails])

  const updateSelectedSites = () => {
    let siteIds: any = [];
    let allCheckedSites = [...checkedRows.current, ...disabledRows.current];
    if(allCheckedSites?.length < 1)
    {
      setMessage("Select atleast one Site");
      setDisplayInfoModal(true);    
    }
    else
    {
    allCheckedSites.map((item: any) => {
      siteIds.push(item.id);
      return null;
    });
    dispatch(
      updateContractSiteMapping(
        selectedRows.uniqueContractId,
        siteIds,
        setDisplayInfoModal,
        setMessage,
        activeSite,
        setSelectedRows,
      )
    );
      }
  };

  const renderFooter = () => {
    return (
      <>
        <Button
          label="Cancel"
          onClick={() => {
            setShowModal(false);
            dispatch(setContractSiteDetails(""));
          }}
          className="cancel btn"
        />
        {showSaveButton && (<Button label="Save Changes" disabled={!siteData?.length} className="update btn" autoFocus onClick={updateSelectedSites}/>)}
      </>
    );
  };

  const onError = (errors: any, e: any) => {};

  return (
    <>
      <Dialog
        header="Update Sites"
        visible={showModal}
        style={{ width: "40vw" }}
        onHide={() => {
          setShowModal(false);
          dispatch(setContractSiteDetails(""));
        }}
        footer={renderFooter()}
        className="display-settings personal selectsite"
      >
        <div className="header d-flex align-items-center">
            <span className="title">Contract:</span>
            <span className="value">{contractName}</span>
          </div>
          <div className="row px-2 pt-1">
            <div className="field col-12 md:col-2 mb-0 py-0">
            <MultiSelect
            singleSelect
            list={siteData}
            getCheckedItems={(checkedItems) => {
              checkedRows.current = checkedItems;
            }}
            getSelectedItems={(selectedItems) => {
              selectedSite.current = selectedItems;
            }}
            getDisabledItems={(disabledItems) => {
              disabledRows.current = disabledItems;
            }}
          />
            </div>
          </div>
      </Dialog>
      <InformationBox
        showInfoModal={displayInfoModal}
        setShowInfoModal={setDisplayInfoModal}
        message={message}
      />
    </>
  );
};

export default SiteModalContract;
