import React, { useState, useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { classNames } from "primereact/utils";
import { InputTextarea } from "primereact/inputtextarea";
import { RadioButton } from "primereact/radiobutton";

import { BsExclamationCircleFill } from "react-icons/bs";
import AddModal from "components/Table/AddModal/AddModal";

//redux
import {
  getCountryList,
  getTimeZoneList,
  getStateList,
  getStatusList,
} from "redux/pages/Administration/administrationSlice";
import { getSiteToEditDetails } from "redux/pages/Administration/Site/siteSlice";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../redux/hooks";
import { USAPattern} from "../ManageSite/SiteNumberPattern";
import InformationBox from "components/common/Message/InformationBox/InformationBox";

interface Props {
  showModal: boolean;
  setShowModal: (state: boolean) => void;
  selectedRows: any;
  updateSite: (data: any) => void;
}

export const Editsite: React.FC<Props> = ({
  showModal = false,
  setShowModal = () => {},
  selectedRows = [],
  updateSite = () => {},
}) => {
  const [countryLookup, setCountryLookup] = useState([{ name: "", code: "" }]);
  const [stateLookup, setStateLookup] = useState([{ name: "", code: "" }]);
  const [timeZoneLookup, setTimeZoneLookup] = useState([
    { name: "", code: "" },
  ]);
  const statusList = useAppSelector((state) => state.administration.statusList);
 
  const siteToEdit = useAppSelector((state) => state.site.siteToEdit);
  const countryList = useAppSelector(
    (state) => state.administration.countryList
  );
  const stateList = useAppSelector((state) => state.administration.stateList);
  const timeZoneList = useAppSelector(
    (state) => state.administration.TimeZoneList
  );

  const [countryId, setCountryId] = useState<any>("");
  const [selectedCountry, setSelectedCountry] = useState<any>("");
  const [pattern, setPattern] = useState<any>([]);
  const [siteNumberPattern, setSiteNumberPattern] = useState<any>();
  const [statusLookup, setStatusLookup] = useState([{ name: "", key: "" }]);
  const [status, setStatus] = useState<any>([]);
  const [showPatternModal, setShowPatternModal] = useState(false);
  const [displayInfoModal, setDisplayInfoModal] = useState(false);
  const [message, setMessage] = useState<any>("");


  const editForm: any = useRef();
  const dispatch = useDispatch<any>();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (selectedRows.length === 1) {
      dispatch(getSiteToEditDetails(selectedRows[0]?.uniqueSiteId));
      dispatch(getCountryList());
      dispatch(getStateList());
      dispatch(getStatusList())
      dispatch(getTimeZoneList());
    }
  }, [dispatch, selectedRows]);

  useEffect(() => {
    if (siteToEdit?.uniqueSiteId !== undefined) {
      setValue("countryName", siteToEdit?.countryName);
      setValue("siteNumber", siteToEdit?.siteNumber);
      setValue("siteName", siteToEdit?.siteName);
      setValue("state", siteToEdit?.state);
      setValue("description", siteToEdit?.description);
      setStatus({ name: siteToEdit?.statusInfo?.statusName, key: siteToEdit?.statusInfo?.statusId })
      setSelectedCountry(siteToEdit?.countryName.trim());
    }
  }, [siteToEdit, setValue]);

  useEffect(() => {
    if (countryList?.countries && countryList?.countries.length) {
      let country: { name: string; code: string }[] = [];
      countryList?.countries.map((item: any) => {
        if (item.countryName === selectedRows[0]?.countryName)
          setCountryId(item.countryId);
        return country.push({ name: item.countryName, code: item.countryId });
      });
      setCountryLookup(country);
    }
  }, [countryList, selectedRows]);

  useEffect(() => {
    if (stateList?.statesOfCountries?.length && countryId !== "") {
      let state: { name: string; code: string }[] = [];
      state.push({ name: "--Select--", code: "--Select--"});
      stateList?.statesOfCountries.map((item: any) => {
        if (item.countryId === countryId)
          state.push({
            name: item.statesOfCountryName,
            code: item.statesOfCountryCode,
          });
        return state;
      });
      setStateLookup(state);
    }
  }, [stateList, setValue, countryId]);

  useEffect(() => {
    if (
      timeZoneList?.timeZones &&
      timeZoneList?.timeZones.length &&
      siteToEdit
    ) {
      let timeZone: { name: string; code: string }[] = [];
      timeZone.push({ name: "--Select--", code: "--Select--"});
      timeZoneList?.timeZones.map((item: any) => {
        return timeZone.push({
          name: item.timeZoneName,
          code: item.timeZoneId,
        });
      });
      setTimeZoneLookup(timeZone);
      const filterData: any = timeZone.filter(
        (item: any) => item.name === siteToEdit?.timeZone
      );
      if (filterData.length > 0) {
        setValue("timeZone", filterData[0].name);
      }
    }
  }, [timeZoneList, siteToEdit, setValue]); 

  useEffect(() => {
    if (statusList?.status && statusList?.status.length) {
        let status: { name: string, key: string }[] = [];
        statusList?.status.map((item: any) => {
            if (item.statusName === "Active" || item.statusName === "Blocked" || item.statusName === "Closed") {
                status.push({ name: item.statusName, key: item.statusId });
            }
            return status;
        })
        setStatusLookup(status)
    }

}, [statusList])

  useEffect(() => {
    if (selectedCountry.trim() === "United States") {
      setPattern(USAPattern);
      setSiteNumberPattern(/^44OC[-][0-9]{7}$|44OP[-][0-9]{6}$|4433[-][0-9]{5}[-][0-9]{5}$|4433[-][0-9]{5}[-][0-9]{4}$/);
    }
  }, [selectedCountry]);

  const onEditFormSubmit = (data: any) => {
    data.statusId = status.key?.toString();
    data.siteName=data.siteName?.trim();
    // Purposely commented based on requirement
    // data.Description=data.Description?.trim();
    updateSite && updateSite(data);
  };

  const formSubmitHandler = (data: any) => {
    editForm.current.requestSubmit();
  };

  const handleStatusChange = (eventValue: any )=> {
     if(siteToEdit.activeContractsExist) {
      if(eventValue.name === "Blocked") {
        setMessage("Active Contract(s) exists for selected site, cannot be Blocked")
      } else if(eventValue.name === "Closed") {
        setMessage("Active Contract(s) exists for selected site, cannot be Closed")
      }
      setDisplayInfoModal(true)
     } else if(siteToEdit.activeSystemsExist) {
      if(eventValue.name === "Blocked") {
        setMessage("Active System(s) exists for selected site, cannot be Blocked")
      } else if(eventValue.name === "Closed") {
        setMessage("Active System(s) exists for selected site, cannot be Closed")
      }
      setDisplayInfoModal(true)
     } else {
      setStatus(eventValue)
     } 
  }

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && (
        <span className="tooltip-text">
          <BsExclamationCircleFill />
          {errors[name].message}
        </span>
      )
    );
  };
  return (
    <AddModal
      header="Edit Site"
      showModal={showModal}
      setShowModal={setShowModal}
      OnSaveClick={formSubmitHandler}
      isAdd={false}
      style={{ width: "35vw", maxHeight: "95%" }}
    >
      <form onSubmit={handleSubmit(onEditFormSubmit)} ref={editForm}>
        <Dialog
          header="Site Number"
          visible={showPatternModal}
          onHide={() => setShowPatternModal(false)}
          className="pattern-overlay"
          style={{ width: "32vw" }}
        >
          <DataTable showGridlines={false} value={pattern}>
            <Column field="pattern" header="Pattern" />
            <Column field="format" header="Format" />
            <Column field="description" header="Description" />
          </DataTable>
        </Dialog>

        <div className="field col-12 md:col-4">
          <span className="p-float-label">
            <Controller
              name="countryName"
              control={control}
              rules={{ required: "Country Name is required." }}
              render={({ field, fieldState }) => (
                <Dropdown
                  inputId="dropdown"
                  disabled
                  className={classNames("w-100 error-tooltip", {
                    error: fieldState.invalid,
                  })}
                  {...field}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e.value);
                    setSelectedCountry(e.value);
                  }}
                  options={countryLookup}
                  optionLabel="name"
                  optionValue="name"
                />
              )}
            />
            <label className="mandatory" htmlFor="dropdown">
              Country
            </label>
            {getFormErrorMessage("countryName")}
          </span>
        </div>
        <div className="field col-12 md:col-4 pb-0 mb-0">
          <span className="p-float-label">
            <Controller
              name="siteNumber"
              control={control}
              rules={{
                required: "Site Number is required.",
                pattern: {
                  value: siteNumberPattern,
                  message: "Enter a valid Site Number",
                },
              }}
              render={({ field, fieldState }) => (
                <InputText
                  id="inputtext"
                  className={classNames("w-100 error-tooltip", {
                    error: fieldState.invalid,
                  })}
                  {...field}
                  value={field.value ?? ""}
                />
              )}
            />
            <label className="mandatory" htmlFor="inputtext">
              Site Number
            </label>
            {getFormErrorMessage("siteNumber")}
          </span>
        </div>
        <div className="field col-12 md:col-2 pt-4">
        <span
            className="pattern-view d-flex justify-content-end pb-1"
            role="button"
            onClick={() => setShowPatternModal(true)}
          >
            View Pattern
          </span>
          <span className="p-float-label">
            <label
              className="mandatory"
              htmlFor="dropdown"
              style={{ marginTop: "-12px", color: "#009999" }}
            >
              Site Status
            </label>
            {getFormErrorMessage("status")}
          </span>
          <div className="radio-section d-flex align-items-center pt-2 pl-2">
            {
              statusLookup.map((item) => {
                return (
                  <div key={item.key} className="field-radiobutton mb-0 pr-4">
                    <RadioButton inputId={item.key} name="status" value={item} onChange={(e) => handleStatusChange(e.value)} checked={status.key === item.key} />
                    <label htmlFor={item.key}>{item.name}</label>
                  </div>
                )
              })
            }
          </div>
        </div>

        <div className="field col-12 md:col-4">
           
          <span className="p-float-label">
            <Controller
              name="siteName"
              control={control}
              rules={{ required: "Site Name is required.",
              validate: (value) => {
                return value.trim().length<=0
                  ?"This field is required":true
              }, }}
              render={({ field, fieldState }) => (
                <InputText
                  id="inputtext"
                  maxLength={100}
                  className={classNames("w-100 error-tooltip", {
                    error: fieldState.invalid,
                  })}
                  {...field}
                  value={field.value ?? ""}
                />
              )}
            />
            <label className="mandatory" htmlFor="inputtext">
              Site Name
            </label>
            {getFormErrorMessage("siteName")}
          </span>
        </div>

        <div className="field col-12 md:col-4">
          <span className="p-float-label">
            <Controller
              name="state"
              control={control}
              rules={{ required: "State is required." ,
              validate:(value)=>{
                return value==="--Select--"?"Select a State":undefined;
              }}}
              render={({ field, fieldState }) => (
                <Dropdown
                  inputId="dropdown"
                  className={classNames("w-100 error-tooltip", {
                    error: fieldState.invalid,
                  })}
                  {...field}
                  value={field.value ?? ""}
                  options={stateLookup}
                  optionLabel="name"
                  optionValue="name"
                />
              )}
            />
            <label className="mandatory" htmlFor="inputtext">
              State
            </label>
            {getFormErrorMessage("state")}
          </span>
        </div>

        <div className="field col-12 md:col-4">
          <span className="p-float-label">
            <Controller
              name="timeZone"
              control={control}
              rules={{ required: "Time Zone is required." ,
              validate:(value)=>{
                return value==="---Select---"?"Select a Time Zone":undefined;
              }}}
              render={({ field, fieldState }) => (
                <Dropdown
                  inputId="dropdown"
                  className={classNames("w-100 error-tooltip", {
                    error: fieldState.invalid,
                  })}
                  {...field}
                  value={field.value ?? ""}
                  options={timeZoneLookup}
                  optionLabel="name"
                  optionValue="name"
                />
              )}
            />
            <label className="mandatory" htmlFor="inputtext">
              Time Zone
            </label>
            {getFormErrorMessage("timeZone")}
          </span>
        </div>
         
        {/* Purposely commented based on requirement
        <div className="field col-12 md:col-4">
          <span className="p-float-label">
            <Controller
              name="description"
              control={control}
              rules={{ required: "Description is required.",
              validate: (value) => {
                return value.trim().length<=0
                  ?"This field is required":true
              }, }}
              render={({ field, fieldState }) => (
                <InputTextarea
                  id="Textarea"
                  className={classNames("w-100 error-tooltip", {
                    error: fieldState.invalid,
                  })}
                  {...field}
                  value={field.value ?? ""}
                />
              )}
            />
            <label className="mandatory" htmlFor="inputtext">
              Description
            </label>
            {getFormErrorMessage("description")}
          </span>
        </div> */}
        
      </form>
      <InformationBox
          showInfoModal={displayInfoModal}
          setShowInfoModal={setDisplayInfoModal}
          message={message}
        />
    </AddModal>
  );
};

export default Editsite;
