import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { zlink, baseURL } from "../../../../service/HttpService";
import { AppThunk } from "../../../store";

export interface ZoneState {
  errors: any;
  salesOfficeList: any;
  salesOfficeDetails: any;
  salesOfficePageDetails: any;
  salesOfficesByQuery: any;
  selectedSalesOffices: any;
}

let initialState: ZoneState = {
  errors: "",
  salesOfficeList: [],
  salesOfficeDetails: [],
  salesOfficePageDetails: [],
  salesOfficesByQuery: [],
  selectedSalesOffices: [],
};
const SalesOfficeSlice = createSlice({
  name: "salesOffice",
  initialState,
  reducers: {
    setErrors: (state, { payload }: PayloadAction<any>) => {
      state.errors = payload;
    },
    setSalesOfficeList: (state, { payload }: PayloadAction<any>) => {
      state.salesOfficeList = payload;
    },
    setUpdatedSalesOffice: (state, { payload }: PayloadAction<any>) => {
      let currentIndex = state.salesOfficeList.findIndex(
        (item: any) => item.uniqueSalesOfficeId === payload?.uniqueSalesOfficeId
      );
      state.salesOfficeList = state.salesOfficeList.filter(
        (item: any) => item.uniqueSalesOfficeId !== payload?.uniqueSalesOfficeId
      );
      state.salesOfficeList.splice(currentIndex, 0, payload);
    },
    setDeletedSalesOfficeList(state, { payload }: PayloadAction<any>) {
			state.salesOfficeList = state.salesOfficeList.filter((item: any) => item.uniqueSalesOfficeId !== payload);
    },
    setSalesOfficeDetails: (state, { payload }: PayloadAction<any>) => {
      state.salesOfficeDetails = payload;
    },
    setSalesOfficePageDetails: (state, { payload }: PayloadAction<any>) => {
      state.salesOfficePageDetails = payload;
    },
    setNewSalesOfficeData: (state, { payload }: PayloadAction<any>) => {
      state.salesOfficeList.unshift(payload);
    },
    setSalesOfficesByQuery: (state, { payload }: PayloadAction<any>) => {
      state.salesOfficesByQuery = payload;
    },
    setSelectedSalesOffices: (state, { payload }: PayloadAction<any>) => {
      state.selectedSalesOffices = payload;
    },
  },
});

export const getSalesOfficeList =
  (setShowGrid, currentStart, keyword: any = "", sortField: any = "", sortOrder: any = ""): AppThunk =>
  async (dispatch) => {
    try {
      const res = await zlink.get(
        `${baseURL}api/v1/organization/salesoffices?keyword=${keyword}&currentstart=${currentStart}&sortcolumn=${sortField}&sortorder=${sortOrder}&isPagination=true`
      );
      if (res.status === "done") {
        dispatch(setSalesOfficeList(res?.data?.salesOfficeDetails));
        dispatch(setSalesOfficePageDetails(res?.data?.pageDetails));
        setShowGrid(true);
      } else {
        dispatch(setErrors(res.error));
      }
    } catch (error) {
      dispatch(setErrors(error));
    }
  };
export const onAddSalesOffice =
  (
    body: any,
    setMessage,
    setDisplayInfoModal,
    setShowAddSalesOfficeModal
  ): AppThunk =>
  async (dispatch) => {
    try {
      const res = await zlink.post(
        `${baseURL}api/v1/organization/salesoffices/add`,
        body
      );
      if (res.status === "done") {
        dispatch(setNewSalesOfficeData(res.data));
        setMessage("Sales Office added");
        setShowAddSalesOfficeModal(false);
        setDisplayInfoModal(true);
      } else {
        dispatch(setErrors(res.error));
        setMessage(res.message);
        setDisplayInfoModal(true);
      }
    } catch (error) {
      dispatch(setErrors(error));
    }
  };
export const onEditSalesOffice =
  (
    body: any,
    uniqueSalesOfficeId,
    setMessage,
    setDisplayInfoModal,
    setShowEditModal
  ): AppThunk =>
  async (dispatch) => {
    try {
      const res = await zlink.post(
        `${baseURL}api/v1/organization/salesoffices/${uniqueSalesOfficeId}`,
        body
      );
      if (res.status === "done") {
        dispatch(setUpdatedSalesOffice(res.data));
        setMessage("Sales office updated");
        setShowEditModal(false);
        setDisplayInfoModal(true);
      } else {
        dispatch(setErrors(res.error));
        setMessage(res.message);
        setDisplayInfoModal(true);
      }
    } catch (error) {
      dispatch(setErrors(error));
    }
  };
export const getSalesOfficeDetails =
  (uniqueSalesOfficeId): AppThunk =>
  async (dispatch) => {
    try {
      const res = await zlink.get(
        `${baseURL}api/v1/organization/salesOffices/${uniqueSalesOfficeId}`
      );
      if (res.status === "done") {
        dispatch(setSalesOfficeDetails(res.data));
      } else {
        dispatch(setErrors(res.error));
      }
    } catch (error) {
      dispatch(setErrors(error));
    }
    
  };

  export const deleteSalesOffice = ({ uniqueSalesOfficeId, setSelectedRows, setMessage, setDisplayInfoModal }): AppThunk => async (dispatch) => {
    try {
      const res = await zlink.post(`${baseURL}api/v1/organization/salesoffices/${uniqueSalesOfficeId}/remove`, null);
      if (res.status === "done") {
        dispatch(setDeletedSalesOfficeList(uniqueSalesOfficeId));
        setSelectedRows([]);
        setMessage("Sales Office deleted");
        setDisplayInfoModal(true);
      } else {
        setSelectedRows([]);
        setMessage(res.message);
        setDisplayInfoModal(true);
      }
    } catch (error) {
      dispatch(setErrors(error));
    }
  };

export const getSalesOfficesByQuery = (query) : AppThunk => async (dispatch) => {
  try {
    const res = await zlink.get(`${baseURL}api/v1/organization/salesoffices?${query}`);
    if (res.status === "done") {
      dispatch(setSalesOfficesByQuery(res?.data?.salesOfficeDetails));
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const getSelectedSalesOffices = (data:any) : AppThunk => async (dispatch) => {
  dispatch(setSelectedSalesOffices(data));
}

export const getTechnicians = (salesOfficeId, setSalesOfficeName, setTechnicians, setIsLoaded) : AppThunk => async (dispatch) =>  {
  try {
    const res = await zlink.get(`${baseURL}api/v1/organization/salesoffices/${salesOfficeId}/technicians`);
    if (res.status === "done") {
      if (res.data) {
        if (res.data.salesOfficeName) {
          setSalesOfficeName(res.data.salesOfficeName);
        }
        if (res.data.technicianDetails && res.data.technicianDetails.length) {
          let technicians: any = [];
          res.data.technicianDetails.map(element => {
            technicians.push({
              id: element.uniqueTechnicianId,
              siteName: element.technicianName,
              checked: element.isSelected,
              selected: false,
              disabled: element.isDisabled,
            });
            return null;
          });
          setTechnicians(technicians);
        } else {
          setTechnicians([])
        }
        setIsLoaded(true);
      }
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
}

export const assignTechnicians = (salesOfficeId, body, setLoader, setShowTechs, setMessage, setDisplayInfoModal): AppThunk => async (dispatch) =>  {
  try {
    const res = await zlink.post(`${baseURL}api/v1/organization/salesoffices/${salesOfficeId}/technicians`, body);
    if (res.status === "done") {
      setShowTechs(false);
      setMessage("Technician(s) updated");
      setDisplayInfoModal(true);
    } else {
      if (res.message) {
        setMessage(res.message);
        setDisplayInfoModal(true);        
      }
      dispatch(setErrors(res.error));
    }
    setLoader(false)
  } catch (error) {
    dispatch(setErrors(error));
    setLoader(false);
  }
}

export const {
  setErrors,
  setSalesOfficeList,
  setSalesOfficeDetails,
  setUpdatedSalesOffice,
  setDeletedSalesOfficeList,
  setSalesOfficePageDetails,
  setNewSalesOfficeData,
  setSalesOfficesByQuery,
  setSelectedSalesOffices
} = SalesOfficeSlice.actions;

export default SalesOfficeSlice.reducer;
