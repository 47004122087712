import React from 'react'
import { Button } from 'react-bootstrap'

const SearchSiteDocument = () => {
  return (
    <>

<h4 className="doc-title mb-2">Search - Site</h4>
<p>
  Site Search option allows the user to search for the required Site. 
  User can type minimum three characters of the Site Name / Site Number 
  in the text box provided and select the required Site from the list displayed. 
  Click on <span className="doc-subtitle"> Search </span>{" "}

  <Button className="doc-button-icon" >
    <i className="pi pi-search icon" title="Search"  />
  </Button>{" "}

  icon. The page will be redirected to <span className="doc-subtitle"> Administration: Sites </span> 
  page where the selected Site will be listed.
</p>

</>
  );
}
export default SearchSiteDocument;