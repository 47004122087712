import React, { useCallback, useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { useNavigate } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { SketchPicker } from "react-color";
import { Button } from "react-bootstrap";
//components
import Breadcrumb from "components/common/Breadcrumb/Breadcrumb";
import DatafieldOrder from "../../components/common/DatafieldOrder/DatafieldOrder";
import InformationBox from "components/common/Message/InformationBox/InformationBox";
//icons
import { BsArrowRight } from "react-icons/bs";
//utility
import { APP_ROUTES } from "constants/AppRoutes";
//redux
import { useDispatch } from "react-redux";
import { getCountryList } from "redux/pages/Administration/administrationSlice";
import { getEquipmentTypeList } from "redux/pages/Inspection/InspectionTask/InspectionDetails/Equipment/EquipmentSlice";
import { useAppSelector } from "redux/hooks";
import {
    onDatafieldOrderUpdated,
    onDisplaySettingsUpdated,
    onGetDatafieldOrderList,
    onGetDisplaySettings,
    setUpdatedDatafieldOrder,
    setUpdatedDisplaySettings,
} from "redux/pages/Manage/Customization/CustomizationSlice";

interface DisplayTable {
    CustomHeaderCmp?: any;
    showCustomHeader?: boolean;
    showCountries?: boolean;
}

const ManageDisplaySettings: React.FC<DisplayTable> = ({ CustomHeaderCmp = null, showCustomHeader = false }) => {
   
    const navigate = useNavigate();

    const [selectedRowData, setSelectedRowData] = useState<any>(null);
    const [selectedFields, setSelectedFields] = useState({ countryId:"", equipmentTypeId: "" });
    const [datafields, setDatafields] = useState<any>([]);
    const [showFieldOrder, setShowFieldOrder] = useState(false);
    const [displayInfoModal, setDisplayInfoModal] = useState(false);
    const [message, setMessage] = useState<string>("");
    const [fieldOrder, setFieldOrder] = useState<any>([]);
    const [countryLookup, setCountryLookup] = useState<any>([]);
    const [selectedCountry, setSelectedCountry] = useState<string>("");

    const dispatch = useDispatch<any>();
    const equipmentSpecificLists = useAppSelector((state) => state.equipment.equipmentSpecificLists);
    const displaySettings = useAppSelector((state) => state.customization.displaySettings);
    const datafieldOrderList = useAppSelector((state) => state.customization.datafieldOrderList);
    const updatedDatafieldOrder = useAppSelector((state) => state.customization.updatedDatafieldOrder);
    const updatedDisplaySettings = useAppSelector((state) => state.customization.updatedDisplaySettings);
    const countryList = useAppSelector((state) => state.administration.countryList);
   
     
    document.addEventListener("mousedown", (e: any) => {
        let element = document.getElementById("color-picker");
        if (!element?.contains(e.target)) {
            setSelectedRowData(null);
        }
    });

    useEffect(() => {
        if (Object.keys(countryList).length !== 0)
        dispatch(getEquipmentTypeList(false));
    }, [dispatch,countryList]);

    useEffect(() => {
        dispatch(getCountryList());
      }, [dispatch]);

    useEffect(() => {
        if (countryList && countryList.countries.length > 0 && countryList.countries !== undefined) {
          let country: any = [];
          countryList.countries.map((countryItem: any, key: any) => {
            country.push({ "code": countryItem.countryId, "name": countryItem.countryName.trim() })
          });
          setSelectedFields({ countryId:country[0].code, equipmentTypeId: "" })
          setCountryLookup(country);
          setSelectedCountry(country[0]);
        }
      }, [countryList]);

    useEffect(() => {
        if (equipmentSpecificLists) {
            const equipmentClass=equipmentSpecificLists?.find((item) => item?.equipmentClassName === "Devices");
            setSelectedFields((prev) => ({ ...prev, ["equipmentTypeId"]: equipmentClass?.uniqueEquipmentClassId }))
        }
    }, [equipmentSpecificLists]);

    useEffect(() => { 
        if (selectedFields?.equipmentTypeId) {
            dispatch(onGetDisplaySettings(selectedFields?.countryId, selectedFields?.equipmentTypeId));
        }
    }, [selectedFields?.equipmentTypeId]);

    useEffect(() => {
        if (displaySettings?.length) {
            let convData = JSON.parse(JSON.stringify(displaySettings));
            setDatafields(convData);
        }
    }, [displaySettings]);

    useEffect(() => {
        if (updatedDatafieldOrder) {
            setShowFieldOrder(false);
            setDisplayInfoModal(true);
            setMessage("Field Order updated");
            dispatch(setUpdatedDatafieldOrder(""));

            updatedDatafieldOrder?.forEach((element) => {
                const index = datafields.findIndex((item) => item.uniqueDataFieldId === element.uniqueDataFieldId);
                if (index >= 0) {
                    datafields[index].position = element.position;
                }
            });
            const ascDatafields = [...datafields].sort((a, b) => a.position - b.position);
            setDatafields([...ascDatafields]);
        }
    }, [updatedDatafieldOrder]);

    useEffect(() => {
        if (updatedDisplaySettings) {
            setDisplayInfoModal(true);
            setMessage("Display Settings updated");
            dispatch(setUpdatedDisplaySettings(""));
        }
    }, [updatedDisplaySettings]);

    useEffect(() => {
        if (datafieldOrderList) {
            let value: any = JSON.stringify(datafieldOrderList);
            value = value?.replace(/\"uniqueDataFieldId\":/g, '"id":');
            value = value?.replace(/\"dataFieldName\":/g, '"name":');
            value = JSON.parse(value);
            setFieldOrder(value);
        }
    }, [datafieldOrderList])

    const onChangeColor = (e: any, details) => {
        datafields[details.rowIndex][details.field] = e.hex;
        setDatafields([...datafields]);
    };

    const onCheckboxChanged = (e, details, rowData) => {
        if (details?.field === "isShowInDrawing") {
            if (!e.checked) {
                const index = displaySettings?.findIndex((item) => item.uniqueDataFieldId === rowData.uniqueDataFieldId);
                if (index >= 0) {
                    datafields[details?.rowIndex]["size"] = displaySettings[index].size;
                    datafields[details?.rowIndex]["rgb"] = displaySettings[index].rgb;
                }
            }
        }
        datafields[details?.rowIndex][details?.field] = e.checked;
        setDatafields([...datafields]);
    };

    const onGetAllChecked = (type) => {
        let checked = false;
        if (type === "DRAWING") {
            const filterItem = datafields?.filter((item) => item.isShowInDrawing);
            if (filterItem?.length === datafields?.length) checked = true;
            else checked = false;
        } else if (type === "TOOLTIP") {
            const filterItem = datafields?.filter((item) => item.isShowInToolTip);
            if (filterItem?.length === datafields?.length) checked = true;
            else checked = false;
        } else if (type === "GRID") {
            const filterItem = datafields?.filter((item) => item.isShowInGrid);
            if (filterItem?.length === datafields?.length) checked = true;
            else checked = false;
        }
        return checked;
    };

    const onHeaderCheckBoxChanged = (e, type) => {
        if (type === "DRAWING") {
            datafields.forEach((element) => {
                if (!e.checked) {
                    const index = displaySettings?.findIndex((item) => item.uniqueDataFieldId === element.uniqueDataFieldId);
                    if (index >= 0) {
                        element["size"] = displaySettings[index].size;
                        element["rgb"] = displaySettings[index].rgb;
                    }
                }
                element.isShowInDrawing = e.checked;
            });
        } else if (type === "TOOLTIP") {
            datafields.forEach((element) => {
                element.isShowInToolTip = e.checked;
            });
        } else if (type === "GRID") {
            datafields.forEach((element) => {
                element.isShowInGrid = e.checked;
            });
        }
        setDatafields([...datafields]);
    };

    const onSizeChanged = (e, details) => {
        datafields[details?.rowIndex][details?.field] = e.target.value;
        setDatafields([...datafields]);
    };

    const onOrderUpdated = useCallback((body) => {
        let value: any = JSON.stringify(body);
        value = value?.replace(/\"id\":/g, '"uniqueDataFieldId":');
        value = JSON.parse(value);
        dispatch(onDatafieldOrderUpdated(selectedFields?.countryId, selectedFields?.equipmentTypeId, value));
    }, [selectedFields]);

    const onDisplaySettingsUpdate = () => {
        if (!datafields?.length) {
            return;
        }
        const removedDataSets = datafields?.map(
            ({ accessColorId, colorId, dataFieldName, isAttachmentField, isEnableBoldAndItalics, isStaticField, ...rest }: any) => {
                return rest;
            }
        );
        const body = {
            dataFields: removedDataSets,
        };
        dispatch(onDisplaySettingsUpdated(selectedFields?.countryId, selectedFields?.equipmentTypeId, body));
    };

    const showInDrawingTemplate = (rowData: any, columnDetails: any) => {
        return (
            <div className="checkbox-wrapper field-checkbox">
                <Checkbox
                    className="check-box"
                    id={rowData.uniqueDataFieldId}
                    checked={rowData.isShowInDrawing}
                    onChange={(e) => onCheckboxChanged(e, columnDetails, rowData)}
                />
            </div>
        );
    };

    const showInTooltipTemplate = (rowData: any, columnDetails: any) => {
        return (
            <div className="checkbox-wrapper field-checkbox">
                <Checkbox
                    className="check-box"
                    id={rowData.uniqueDataFieldId}
                    checked={rowData.isShowInToolTip}
                    onChange={(e) => onCheckboxChanged(e, columnDetails, rowData)}
                />
            </div>
        );
    };

    const showInGridTemplate = (rowData: any, columnDetails: any) => {
        return (
            <div className="checkbox-wrapper field-checkbox">
                <Checkbox
                    className="check-box"
                    id={rowData.uniqueDataFieldId}
                    checked={rowData.isShowInGrid}
                    onChange={(e) => onCheckboxChanged(e, columnDetails, rowData)}
                />
            </div>
        );
    };

    const fieldNameTemplate = (rowData: any) => {
        return (
            <div className="checkbox-wrapper field-checkbox">
                <div>{rowData.dataFieldName}</div>
            </div>
        );
    };

    const colorTemplate = (rowData: any, columnDetails) => {
        return (
            <div className="color-select__wrapper">
                {rowData?.isShowInDrawing && (
                    <>
                        <div
                            className="color-select"
                            style={{ backgroundColor: rowData?.rgb, }}
                            onClick={() => {
                                setSelectedRowData(rowData.uniqueDataFieldId);
                            }}
                        >
                        </div>
                        {selectedRowData === rowData.uniqueDataFieldId && (
                            <div id="color-picker">
                                <SketchPicker color={rowData?.rgb} onChange={(e: any) => onChangeColor(e, columnDetails)} />
                            </div>
                        )}
                    </>
                )}
            </div>
        );
    };

    const fontSizeTemplate = (rowData, columnDetails) => {
        return (
            <InputText
                disabled={!rowData?.isShowInDrawing}
                type="text"
                value={rowData?.isShowInDrawing ? rowData.size : ""}
                onChange={(e) => onSizeChanged(e, columnDetails)}
            />
        );
    };

    const showInDrawingHeaderTemlpate = () => {
        return (
            <div>
                <Checkbox
                    className="check-box mr-2"
                    id="show-in-drawing"
                    checked={onGetAllChecked("DRAWING")}
                    onChange={(e) => onHeaderCheckBoxChanged(e, "DRAWING")}
                />
                <label htmlFor="dataEnshow-in-drawintryHead">Show in Drawing</label>
            </div>
        );
    };

    const showInTooltipHeaderTemplate = () => {
        return (
            <div>
                <Checkbox
                    className="check-box mr-2"
                    id="show-in-tooltip"
                    checked={onGetAllChecked("TOOLTIP")}
                    onChange={(e) => onHeaderCheckBoxChanged(e, "TOOLTIP")}
                />
                <label htmlFor="show-in-tooltip">Show in Tooltip</label>
            </div>
        );
    };

    const showInGridHeaderTemplate = () => {
        return (
            <div>
                <Checkbox className="check-box mr-2" id="show-in-grid" checked={onGetAllChecked("GRID")} onChange={(e) => onHeaderCheckBoxChanged(e, "GRID")} />
                <label htmlFor="show-in-grid">Show in Grid</label>
            </div>
        );
    };

    const onCountryChange = (data:any) => {
        if (selectedFields?.equipmentTypeId) {
            dispatch(onGetDisplaySettings(data.code, selectedFields?.equipmentTypeId));
        }
    }
    return (
        <div className="manage_display_setting">
            <section className="d-flex align-items-center">
                <Breadcrumb title="Customization" classNames="report-header" handleGoBackClick={() => navigate(`/${APP_ROUTES.MANAGE_VIEW}`)} />
                <div className="breadcrumb-right-arrow ml-3 mr-3">
                    <BsArrowRight className="icon" />
                </div>
                <div className="breadcrumb-sub-page-title">
                    <span>Display Settings</span>
                </div>
            </section>
            <div className="manage-display-table">
                <div className="custom-table">
                    {showCustomHeader ? <CustomHeaderCmp /> : <div className="heading">Display Settings</div>}
                    <div className="search-section d-flex align-items-center pt-2 pb-2">
                        <div className="attribute-dropdown">
                            <span className="p-float-label">
                                <Dropdown
                                    inputId="country"
                                    className={classNames("w-100")}
                                    value={selectedCountry}
                                    onChange={(e) =>{  setSelectedCountry(e.value);onCountryChange(e.value)}}
                                    options={countryLookup}
                                     optionLabel="name" 
                                />
                                <label className="mandatory" htmlFor="dropdown">
                                    Country
                                </label>
                            </span>
                        </div>

                        <div className="attribute-dropdown">
                            <span className="p-float-label">
                                <Dropdown
                                    inputId="leadTech"
                                    className={classNames("w-100")}
                                    value={selectedFields?.equipmentTypeId}
                                    onChange={(e) => setSelectedFields((prev) => ({ ...prev, ["equipmentTypeId"]: e.value }))}
                                    options={equipmentSpecificLists}
                                    optionLabel="equipmentClassName"
                                    optionValue="uniqueEquipmentClassId"
                                    // defaultValue={}
                                />
                                <label className="mandatory" htmlFor="dropdown">
                                    Equipment Type
                                </label>
                            </span>
                        </div>
                    </div>
                    <DataTable dataKey="uniqueDataFieldId" value={datafields}>
                        <Column field="dataFieldName" header="Field Name" body={fieldNameTemplate}/>
                        <Column field="size" header="Font Size" body={fontSizeTemplate} />
                        <Column field="rgb" header="Color" body={colorTemplate} />
                        <Column field="isShowInDrawing" header={showInDrawingHeaderTemlpate} body={showInDrawingTemplate} />
                        <Column field="isShowInToolTip" header={showInTooltipHeaderTemplate} body={showInTooltipTemplate} />
                        <Column field="isShowInGrid" header={showInGridHeaderTemplate} body={showInGridTemplate} />
                    </DataTable>
                </div>
                <div className="p-dialog-footer d-flex">
                    {(datafields && datafields.length) ? 
                        <>
                            <Button
                                className="cancel-btn"
                                onClick={() => {
                                    setShowFieldOrder(true);
                                    dispatch(onGetDatafieldOrderList(selectedFields?.countryId, selectedFields?.equipmentTypeId));
                                }}
                            >
                                {" "}
                                Field Order
                            </Button>
                            <Button className="save-btn ml-0" onClick={onDisplaySettingsUpdate}>
                                {" "}
                                Save Changes 
                            </Button>                        
                        </>
                        :
                        <></>
                    }
                </div>
            </div>
            {showFieldOrder && (
                <DatafieldOrder
                    header="Field Order"
                    showModal={showFieldOrder}
                    data={fieldOrder}
                    onOrderUpdated={onOrderUpdated}
                    onHideModal={() => setShowFieldOrder(false)}
                />
            )}
            <InformationBox showInfoModal={displayInfoModal} setShowInfoModal={setDisplayInfoModal} message={message} />
        </div>
    );
};

export default ManageDisplaySettings;
