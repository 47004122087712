import React, { memo, useEffect, useRef, useState } from 'react'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ListGroup } from 'react-bootstrap';
import { BiDotsVerticalRounded } from 'react-icons/bi';

interface IDatafieldOrder {
    showModal: boolean;
    onHideModal: () => void;
    onOrderUpdated: (body) => void;
    data?: any;
    header?:string;
}

const DatafieldOrder: React.FC<IDatafieldOrder> = (props) => {
    const { showModal, onHideModal = () => { }, data = [], onOrderUpdated = () => { },header } = props

    const [datafields, setDatafields] = useState<any>([]);
    const [activeKey, setActiveKey] = useState(null);
    const listGroupRef = useRef<any>(null);


    useEffect(() => {
        let covData = JSON.parse(JSON.stringify(data));
        setDatafields(covData);
    }, [data])

    const onUpdate = () => {
        let fileds: any = [];
        datafields.forEach((element: any, i) => {
            let item = {
                id: element.id,
                position: i + 1
            }
            fileds.push(item);
        });
        const body = {
            dataFields: fileds
        }
        onOrderUpdated(body);
    }

    const handleDragEnd = (result: any) => {
        if (!result.destination) return;
        const items = Array.from(datafields);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setDatafields(items);
    };

    const renderFooter = () => {
        return (
            <>
                <Button label="Cancel" onClick={() => onHideModal()} className='cancel btn' />
                <Button label="Save Changes" disabled={datafields?.length ? false : true} onClick={() => onUpdate()} className='update btn' autoFocus />
            </>
        );
    }

    return (
        <Dialog
            header={header}
            visible={showModal}
            style={{ width: '32vw' }}
            footer={renderFooter()}
            onHide={() => onHideModal()}
            className='s-modal field-order-model'
        >
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided) => (
                        <div className="col-list__wrapper" ref={listGroupRef}>
                            <ListGroup {...provided.droppableProps} ref={provided.innerRef}>
                                {datafields &&
                                    datafields.map((data: any, index: any) => {
                                        return (
                                            <Draggable key={index} draggableId={index.toString()} index={index}>
                                                {(provided) => (
                                                    <ListGroup.Item
                                                        key={index}
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        className={`${index === activeKey ? "active" : ""}`}
                                                        onClick={() => setActiveKey(index)}
                                                        onMouseLeave={() => setActiveKey(null)}
                                                    >
                                                        <div className="grip-dots">
                                                            <BiDotsVerticalRounded />
                                                            <BiDotsVerticalRounded />
                                                        </div>
                                                        <div className="col-name__wrapper">
                                                            <div className="col-name">
                                                                <span>{data?.name}</span>
                                                            </div>
                                                        </div>
                                                    </ListGroup.Item>
                                                )}
                                            </Draggable>
                                        );
                                    })}
                                {provided.placeholder}
                            </ListGroup>
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </Dialog>
    )
}

export default memo(DatafieldOrder)