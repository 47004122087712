import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "redux/store";
import { baseURL, zlink } from "service/HttpService";

export interface PasswordPolicyState {
    errors: any;
    passwordPolicyData: any;
}

let initialState: PasswordPolicyState = {
    errors: "",
    passwordPolicyData: "",
};

const PasswordPolicySlice = createSlice({
    name: "passwordPolicy",
    initialState,
    reducers: {
        setErrors: (state, { payload }: PayloadAction<any>) => {
            state.errors = payload;
        },
        setPasswordPolicyData: (state, { payload }: PayloadAction<any>) => {
            state.passwordPolicyData = payload;
        },
    }
})

export const getPasswordPolicyData = (): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.get(`${baseURL}api/v1/organization/passwordpolicy`);
            if (res.status === "done") {
                dispatch(setPasswordPolicyData(res.data));
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

export const onUpdatePasswordPolicyData = (body:any,setMessage,setDisplayInfoModal): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.post(`${baseURL}api/v1/organization/passwordpolicy`,body);
            if (res.status === "done") {
                dispatch(setPasswordPolicyData(res.data));
                setMessage("Password Policy updated");
                setDisplayInfoModal(true);
            } else {
                dispatch(setErrors(res.error));
                setMessage(res.message);
                setDisplayInfoModal(true);

            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

export const {
    setErrors,
    setPasswordPolicyData
} = PasswordPolicySlice.actions;

export default PasswordPolicySlice.reducer;