import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "redux/store";
import { baseURL, zlink } from "service/HttpService";

export interface LinkState {
    errors: any;
    linkList: any;
}

let initialState: LinkState = {
    errors: "",
    linkList: "",
};

const LinkSlice = createSlice({
    name: "link",
    initialState,
    reducers: {
        setErrors: (state, { payload }: PayloadAction<any>) => {
            state.errors = payload;
        },
        setLinkList: (state, { payload }: PayloadAction<any>) => {
            state.linkList = payload;
        },
        setNewLink: (state, { payload }: PayloadAction<any>) => {
            state.linkList.unshift(payload);
        },
        setUpdatedLink: (state, { payload }: PayloadAction<any>) => {
            let currentIndex = state.linkList.findIndex((item: any) => item.linkId === payload?.linkId);
			state.linkList = state.linkList.filter((item: any) => item.linkId !== payload?.linkId);
			state.linkList.splice(currentIndex, 0, payload);
        },
        setDeletedLink(state, { payload }: PayloadAction<any>) {
			state.linkList = state.linkList.filter((item: any) => item.linkId !== payload);
		},
    }
})

export const getLinkList = (): AppThunk => async (dispatch) => {
    try {
		const res = await zlink.get(`${baseURL}api/v1/links`);
        if (res.status === "done") {
			dispatch(setLinkList(res?.data?.linkDetails));
		} else {
			dispatch(setErrors(res.error));
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const onAddLink = (body:any,reset,setDisplyInfoModal,setMessage): AppThunk => async (dispatch) => {
    try {
		const res = await zlink.post(`${baseURL}api/v1/links/add`,body);
        if (res.status === "done") {
			dispatch(setNewLink(res?.data));
            setDisplyInfoModal(true);
            setMessage("Link added");
            reset();
		} else {
            setDisplyInfoModal(true);
            setMessage(res.message);
			dispatch(setErrors(res.error));
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const onEditLink = (body:any,linkId:any,setDisplyInfoModal,setMessage): AppThunk => async (dispatch) => {
    try {
		const res = await zlink.post(`${baseURL}api/v1/links/${linkId}`,body);
        if (res.status === "done") {
			dispatch(setUpdatedLink(res?.data));
            setDisplyInfoModal(true);
            setMessage("Link updated");
		} else {
            setDisplyInfoModal(true);
            setMessage(res.message);
			dispatch(setErrors(res.error));
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const onDeleteLink = (linkId:any,setDisplyInfoModal,setMessage): AppThunk => async (dispatch) => {
    try {
		const res = await zlink.post(`${baseURL}api/v1/links/${linkId}/remove`,null);
        if (res.status === "done") {
			dispatch(setDeletedLink(linkId));
            setDisplyInfoModal(true);
            setMessage("Link deleted");
		} else {
            setDisplyInfoModal(true);
            setMessage(res.message);
			dispatch(setErrors(res.error));
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const {
    setErrors,
    setLinkList,
    setNewLink,
    setUpdatedLink,
    setDeletedLink
} = LinkSlice.actions;

export default LinkSlice.reducer;