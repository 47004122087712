import React, { useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";

import { InputText } from "primereact/inputtext";

import { classNames } from "primereact/utils";

import { BsExclamationCircleFill } from "react-icons/bs";

import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../redux/hooks";
import { getZoneDetails } from "redux/pages/Manage/OrganizationSettings/zoneSlice";

import AddModal from "components/Table/AddModal/AddModal";

interface IZoneEdit {
  showModal: boolean;
  selectedRows: any;
  setShowModal: (state: boolean) => void;
  country: string;
  onEditZone: (data: any) => void;
}

type FormData = {
  zoneName: string;
  country: string;
};

export const EditZone: React.FC<IZoneEdit> = ({
  showModal = false,
  selectedRows = [],
  setShowModal = () => {},
  country = "",
  onEditZone = () => {},
}) => {
  const zoneDetails = useAppSelector((state) => state.zone.zoneDetails);

  const EditForm: any = useRef();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      zoneName: "",
      country: "",
    },
  });
  const dispatch = useDispatch<any>();
  useEffect(() => {
    dispatch(getZoneDetails(selectedRows[0].uniqueBusinessZoneId));
  }, [dispatch, selectedRows]);

  useEffect(() => {
    if (zoneDetails) {
      setValue("country", country);
      setValue("zoneName", zoneDetails.name);
    }
  }, [zoneDetails, country, setValue]);

  const formSubmitHandler = (data: any) => {
    EditForm.current.requestSubmit();
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && (
        <span className="tooltip-text">
          <BsExclamationCircleFill />
          {errors[name].message}
        </span>
      )
    );
  };

  const onEditFormSubmit = (data: any) => {
    data.zoneName=data.zoneName.trim();
    onEditZone && onEditZone(data);
  };

  const onError = (errors: any, e: any) => {};

  return (
    <>
      <AddModal
        header="Edit Zone"
        showModal={showModal}
        isAdd={false}
        setShowModal={setShowModal}
        style={{ width: "35vw", height: "32vh" }}
        OnSaveClick={formSubmitHandler}
      >
        <form onSubmit={handleSubmit(onEditFormSubmit, onError)} ref={EditForm}>
          <div className="row px-2 pt-2">
            <div className="field col-12 md:col-2 mb-0">
              <span className="p-float-label text-container">
                <label htmlFor="inputtext">Country</label>
                <Controller
                  name="country"
                  control={control}
                  render={() => <span className="text-value">{country}</span>}
                />
              </span>
            </div>
          </div>

          <div className="row px-2 pt-2">
            <div className="field col-12 md:col-2 mb-0">
              <span className="p-float-label">
                <Controller
                  name="zoneName"
                  control={control}
                  rules={{
                    required: "Zone Name is required.",
                    validate: (value) => {
                      return value.trim().length<=0
                        ?"Zone Name is required.":true
                    }
                  }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id="zoneName"
                      maxLength={100}
                      className={classNames("w-100 error-tooltip", {
                        error: fieldState.invalid,
                      })}
                      {...field}
                    />
                  )}
                />
                <label className="mandatory" htmlFor="inputtext">
                  Name
                </label>
                {getFormErrorMessage("zoneName")}
              </span>
            </div>
          </div>
        </form>
      </AddModal>
    </>
  );
};

export default EditZone;
