import React from "react";
import { Dialog } from "primereact/dialog";

interface Props {
  showModal: boolean;
  setShowModal: (state: boolean) => void;
  setStreamData: (state: any) => void;
  streamData: any;
  fileName: any;
}

export const ViewFileModal: React.FC<Props> = ({
  showModal = false,
  setShowModal = () => { },
  setStreamData = () => { },
  streamData = {},
  fileName = ""
}) => {

  return (
    <Dialog
      header={<span title={fileName.length > 15 ? fileName : ''}>
        View File ({fileName.length > 15 ? `${fileName.slice(0, 15)}...` : fileName})
      </span>}
      visible={showModal}
      style={{ maxWidth: '50%' }}
      onHide={() => {
        setShowModal(false);
        setStreamData(null);
      }}
      className="view-file-modal"
    >
      <div className="content-box">
        {streamData ?
          <div className="file-view-wrapper">
            <img src={streamData} className="image-file"></img>
          </div> : null }
      </div>
    </Dialog>
  );
};

export default ViewFileModal;
