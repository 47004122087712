import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { onDownloadFile, setProgressFlag } from "redux/common/commonSlice";
import { AppThunk } from "redux/store";
import { baseURL, zlink } from "service/HttpService";

export interface EquipmentState {
    errors: any;
    equipmentList: any;
    equipmentPanelList: any;
    equipmentTypeList: any;
    equipmentDatafields: any;
    newEquipment: any;
    updatedEquipment: any;
    removedEquipment: any;
    equipmentImportCheckData: any;
    checkSystemParseInProgress: any;
    xmlImportStatus: any;
    associatedDevicesData: any;
    updatedEquipmentAddress: any;
    masterDataList: any;
    masterDataPageDetails: any;
    attachmentList: any;
    attachmentPageDetails: any;
    reportPreviewSession: any;
    uploadedExcels: any;
    worksheets: any;
    excelMappingColumns: any;
    equipmentSpecificLists: any;
    fetchedEquipmentData: any;
    verifiedFetchedData: any;
    importedEquipmentData: any;
    multipleEditDatafields: any;
    multipleUpdatedData: any;
    archievedSearches: any;
    equipmentGroupDatafields: any;
    newEquipmentGroup: any;
    updatedEquipmentGroup: any;
    importEquipmentDatafields: any;
    typeOfEquipmentList: string;
    equipmentAttachmentsList: any;
    isDevReadingChanged:string;
    IsTestDateChanged:string; 
    equipmentAttachmentlist: any;
    userDefaultEquipmentClass:any;
    isEquipmentClassDropdownChanged:any; 
}

let initialState: EquipmentState = {
    errors: "",
    equipmentList: "",
    equipmentPanelList: "",
    equipmentTypeList: "",
    equipmentDatafields: "",
    newEquipment: "",
    updatedEquipment: "",
    removedEquipment: "",
    equipmentImportCheckData: "",
    checkSystemParseInProgress: "",
    xmlImportStatus: "",
    associatedDevicesData: [],
    updatedEquipmentAddress: {},
    masterDataList: "",
    masterDataPageDetails: "",
    attachmentList: [],
    attachmentPageDetails: "",
    reportPreviewSession: "",
    uploadedExcels: "",
    worksheets: "",
    excelMappingColumns: "",
    equipmentSpecificLists: "",
    fetchedEquipmentData: "",
    verifiedFetchedData: "",
    importedEquipmentData: "",
    multipleEditDatafields: "",
    multipleUpdatedData: "",
    archievedSearches: "",
    equipmentGroupDatafields: "",
    newEquipmentGroup: "",
    updatedEquipmentGroup: "",
    importEquipmentDatafields: "",
    typeOfEquipmentList: "",
    equipmentAttachmentsList: [],
    isDevReadingChanged:"",
    IsTestDateChanged:"",
    equipmentAttachmentlist: [],
    userDefaultEquipmentClass:"",
    isEquipmentClassDropdownChanged:false
}

const EquipmentSlice = createSlice({
    name: "equipment",
    initialState,
    reducers: {
        setErrors: (state, { payload }: PayloadAction<any>) => {
            state.errors = payload;
        },
        setEquipmentList: (state, { payload }: PayloadAction<any>) => {
            state.equipmentList = payload;
        },
        setEquipmentPanelList: (state, { payload }: PayloadAction<any>) => {
            state.equipmentPanelList = payload;
        },
        setEquipmentTypeList: (state, { payload }: PayloadAction<any>) => {
            state.equipmentTypeList = payload;
        },
        setEquipmentDatafields: (state, { payload }: PayloadAction<any>) => {
            state.equipmentDatafields = payload;
        },
        setNewEquipment: (state, { payload }: PayloadAction<any>) => {
            state.newEquipment = payload;
        },
        setUpdatedEquipment: (state, { payload }: PayloadAction<any>) => {
            state.updatedEquipment = payload;
        },
        setRemovedEquipment: (state, { payload }: PayloadAction<any>) => {
            state.removedEquipment = payload;
        },
        setEquipmentImportCheckData: (state, { payload }: PayloadAction<any>) => {
            state.equipmentImportCheckData = payload;
        },
        setCheckSystemParseInProgress: (state, { payload }: PayloadAction<any>) => {
            state.checkSystemParseInProgress = payload;
        },
        setXMLImportStatus: (state, { payload }: PayloadAction<any>) => {
            state.xmlImportStatus = payload;
        },
        setAssociatedDevicesData: (state, { payload }: PayloadAction<any>) => {
            state.associatedDevicesData = payload;
        },
        setUpdatedEquipmentAddress: (state, { payload }: PayloadAction<any>) => {
            state.updatedEquipmentAddress = payload;
        },
        setMasterDataList: (state, { payload }: PayloadAction<any>) => {
            state.masterDataList = payload;
        },
        setMasterDataPageDetails: (state, { payload }: PayloadAction<any>) => {
            state.masterDataPageDetails = payload;
        },
        setAttachmentList: (state, { payload }: PayloadAction<any>) => {
            state.attachmentList = payload;
        },
        setAttachmentPageDetails: (state, { payload }: PayloadAction<any>) => {
            state.attachmentPageDetails = payload;
        },
        setReportPreviewSession: (state, { payload }: PayloadAction<any>) => {
            state.reportPreviewSession = payload;
        },
        setUploadedExcels: (state, { payload }: PayloadAction<any>) => {
            state.uploadedExcels = payload;
        },
        setWorksheets: (state, { payload }: PayloadAction<any>) => {
            state.worksheets = payload;
        },
        setExcelMappingColumn: (state, { payload }: PayloadAction<any>) => {
            state.excelMappingColumns = payload;
        },
        setEquipmentSpecificLists: (state, { payload }: PayloadAction<any>) => {
            state.equipmentSpecificLists = payload;
        },
        setFetchedEquipmentData: (state, { payload }: PayloadAction<any>) => {
            state.fetchedEquipmentData = payload;
        },
        setVerifiedFetchedData: (state, { payload }: PayloadAction<any>) => {
            state.verifiedFetchedData = payload;
        },
        setImportedEquipmentData: (state, { payload }: PayloadAction<any>) => {
            state.importedEquipmentData = payload;
        },
        setMultipleEditDatafields: (state, { payload }: PayloadAction<any>) => {
            state.multipleEditDatafields = payload;
        },
        setMultipleUpdatedData: (state, { payload }: PayloadAction<any>) => {
            state.multipleUpdatedData = payload;
        },
        setArchievedSearches: (state, { payload }: PayloadAction<any>) => {
            state.archievedSearches = payload;
        },
        setEquipmentGroupDatafields: (state, { payload }: PayloadAction<any>) => {
            state.equipmentGroupDatafields = payload;
        },
        setNewEquipmentGroup: (state, { payload }: PayloadAction<any>) => {
            state.newEquipmentGroup = payload;
        },
        setUpdatedEquipmentGroup: (state, { payload }: PayloadAction<any>) => {
            state.updatedEquipmentGroup = payload;
        },
        setImportEquipmentDatafields: (state, { payload }: PayloadAction<any>) => {
            state.importEquipmentDatafields = payload;
        },
        setTypeOfEquipmentList: (state, { payload }: PayloadAction<any>) => {
            state.typeOfEquipmentList = payload;
        },
        setEquipmentAttachmentsList: (state, { payload }: PayloadAction<any>) => {
            state.equipmentAttachmentsList = payload;
        },
        setNewEquipmentAttachmentData: (state, { payload }: PayloadAction<any>) => {
            state.equipmentAttachmentsList.unshift(...payload);
        },
        setUpdatedAttachmentData: (state, { payload }: PayloadAction<any>) => {
            let currentIndex = state.equipmentAttachmentsList.findIndex((item: any) => item.attachmentId === payload?.attachmentId);
            state.equipmentAttachmentsList = state.equipmentAttachmentsList.filter((item: any) => item.attachmentId !== payload?.attachmentId);
            state.equipmentAttachmentsList.splice(currentIndex, 0, payload);
        },
        setDeletedEquipmentAttachmentList(state, { payload }: PayloadAction<any>) {
            state.equipmentAttachmentsList = state.equipmentAttachmentsList.filter((item: any) => item.attachmentId !== payload);
        },
        setIsDevReadingChanged(state, { payload }: PayloadAction<any>) {
            state.isDevReadingChanged = payload;
        },
        setIsTestedDateChanged(state, { payload }: PayloadAction<any>) {
            state.IsTestDateChanged = payload;
        },
        setEquipmentAttachmentlist: (state, { payload }: PayloadAction<any>) => {
            state.equipmentAttachmentlist = (payload);
        },
        setUserDefaultEquipmentClass: (state, { payload }: PayloadAction<any>) => {
            state.userDefaultEquipmentClass = payload;
        },
        setIsEquipmentClassDropdownChanged(state, { payload }: PayloadAction<any>) {
            state.isEquipmentClassDropdownChanged = payload;
        },
    }
})

export const getEquipmentList = (uniqueInspectionTaskId, body, currentStart, sortField: any = "", sortOrder: any = "", keyword: any = "",): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.post(`${baseURL}api/v1/inspection/inspectiontask/${uniqueInspectionTaskId}/equipments?keyword=${keyword}&currentstart=${currentStart}&sortcolumn=${sortField}&sortorder=${sortOrder}`, body);
            if (res.status === "done") {
                dispatch(setEquipmentList(res?.data));
                dispatch(setTypeOfEquipmentList(body?.filterType))
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

export const getEquipmentPanelList = (uniqueInspectionTaskId, addAll: boolean = true): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.get(`${baseURL}api/v1/inspection/inspectiontask/${uniqueInspectionTaskId}/equipments/panels`);
            if (res.status === "done") {
                let panelList = res?.data?.panelDetails;
                if (addAll) {
                    if (panelList.length > 1) {
                        panelList.splice(0, 0, { equipmentId: 0, name: "All" });
                    }
                }
                dispatch(setEquipmentPanelList(panelList));
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

export const getEquipmentTypeList = (isAllSelectionRequired: boolean = true): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.get(`${baseURL}api/v1/manage/customization/attributes/typeattribute/equipmenttypelist`);
            if (res.status === "done") {
                let typeList = res?.data?.equipmentClassDetails;
                if (isAllSelectionRequired) {
                    typeList.splice(0, 0, { uniqueEquipmentClassId: "All", equipmentClassName: "All" });
                    dispatch(setEquipmentTypeList(typeList));
                } else {
                    dispatch(setEquipmentSpecificLists(typeList));
                }
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

export const getEquipmentDatafields = (body): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.post(`${baseURL}api/v1/inspection/inspectiontask/equipments/datafields`, body);
            if (res.status === "done") {
                dispatch(setEquipmentDatafields(res?.data?.dataFields));
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

export const onEquipmentDetailsSaved = (body, reset, dataFieldsBody): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.post(`${baseURL}api/v1/inspection/inspectiontask/equipments/add`, body);
            if (res.status === "done") {
                dispatch(setNewEquipment(res?.data));
                if (res?.data?.isSuccess === true) {
                    reset();
                    dispatch(getEquipmentDatafields(dataFieldsBody))
                }
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

export const onEquipmentDetailsUpdated = (uniqueInspectionTaskId, equipmentId, body): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.post(`${baseURL}api/v1/inspection/inspectiontask/equipments/${equipmentId}`, body);
        if (res) {
            if (res.status === "done") {
                dispatch(setUpdatedEquipment(res?.data));
            } else {
                dispatch(setErrors(res.error));
            }
            dispatch(setProgressFlag(false));
        }
    } catch (error) {
        dispatch(setErrors(error));
        dispatch(setProgressFlag(false));
    }
};

export const onEquipmentImportCheck = (uniqueInspectionTaskId, equipmentId): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.get(`${baseURL}api/v1/inspection/inspectiontask/${uniqueInspectionTaskId}/equipments/${equipmentId}/checkisimportedequipment`);
            if (res.status === "done") {
                dispatch(setEquipmentImportCheckData(res.data))
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

export const onEquipmentDetailsCloned = (body, setValue, datafieldId): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.post(`${baseURL}api/v1/inspection/inspectiontask/equipments/clone`, body);
            if (res.status === "done") {
                dispatch(setNewEquipment(res?.data));
                setValue(datafieldId, "");
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

export const onEquipmentUsageChecked = (body, setShowDelete, setShowInfo, setMessage,setIds): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.post(`${baseURL}api/v1/inspection/inspectiontask/equipments/checkequipmentinuse`, body);
            if (res.status === "done") {
                if(res?.data?.isSuccess){
                    setShowDelete(true);
                    setMessage(res?.data?.returnMessage);
                    setIds(res?.data?.equipmentIds)
                }else{
                    setShowInfo(true);
                    setMessage(res?.data?.returnMessage);
                }
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

export const onEquipmentRemoved = (uniqueInspectionTaskId, body): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.post(`${baseURL}api/v1/inspection/inspectiontask/equipments/remove`, body);
        if (res.status === "done") {
            dispatch(setRemovedEquipment(res?.data));
        } else {
            dispatch(setErrors(res.error));
        }
    } catch (error) {
        dispatch(setErrors(error));
    }
};

export const getCheckSystemParseInProgress = (systemId): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.get(`${baseURL}api/v1/inspection/system/${systemId}/checksystemparseinprogress`);
            if (res.status === "done") {
                dispatch(setCheckSystemParseInProgress(res.data));
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

export const getXMLImportStatus = (body): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.post(`${baseURL}api/v1/inspection/inspectiontask/equipments/XMLimportStatus`, body);
            if (res.status === "done") {
                dispatch(setXMLImportStatus(res.data));
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

export const getSystemPanelList = (uniqueSystemId): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.get(`${baseURL}api/v1/inspection/systems/${uniqueSystemId}/panels`);
            if (res.status === "done") {
                let panelList = res?.data?.panelDetails;
                dispatch(setEquipmentPanelList(panelList));
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

export const getAssociatedDevices = (uniqueinspectiontaskid: any, uniqueequipmentclassid: any, body: any, setShowModal: any): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.post(`${baseURL}api/v1/inspection/inspectiontask/${uniqueinspectiontaskid}/equipments/equipmentclasses/${uniqueequipmentclassid}/associateddevices`, body);
            if (res.status === "done") {
                dispatch(setAssociatedDevicesData(res?.data));
                setShowModal(true);
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

export const onConnectAssociatedDevices = (uniqueinspectiontaskid: any, associatedDeviceId: any, body: any, selectedRows: any, filterBody: any, currentStart: any, setMessage: any, setDisplayInfoModal: any): AppThunk =>
    async (dispatch) => {
    try {
        const res = await zlink.post(`${baseURL}api/v1/inspection/inspectiontask/${uniqueinspectiontaskid}/equipments/associateddevices/${associatedDeviceId}`, body);
        if (res.status === "done") {
            if (associatedDeviceId === 0) {
                setMessage("Removed Connectivity");
            } else {
                setMessage("Added Connectivity");
            };
            setDisplayInfoModal(true);
            if (selectedRows?.length === 1) {
                dispatch(setUpdatedEquipmentAddress(res?.data));
            } else {
                dispatch(getEquipmentList(uniqueinspectiontaskid, filterBody, currentStart));
            }
        } else {
            dispatch(setErrors(res.error));
            if (res.message) {
                setMessage(res.message);
                setDisplayInfoModal(true);
            }
        }
    } catch (error) {
        dispatch(setErrors(error));
    }
};

export const getMasterdataList = (uniquesystemid, body, setMessage, setDisplayInfoModal, currentStart, sortField, sortOrder): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.post(`${baseURL}api/v1/inspection/systems/${uniquesystemid}/masterdata?currentstart=${currentStart}&sortcolumn=${sortField}&sortorder=${sortOrder}`, body);
        if (res.status === "done") {
            dispatch(setMasterDataList(res.data));
            dispatch(setMasterDataPageDetails(res?.data?.pageDetails));
            if (!res.data?.data?.length) {
                setMessage('No Equipment Data exists');
                setDisplayInfoModal(true);
            }
        } else {
            dispatch(setErrors(res.error));
            if (res.message) {
                setMessage(res.message);
                setDisplayInfoModal(true);
            }
        }
    } catch (error) {
        dispatch(setErrors(error));
    }
}

export const getMasterDataAttachmentList = (uniqueId, equipmentId, setMessage, setDisplayInfoModal, setShowAttachmentListModal, sortField: any = "", sortOrder: any = "",): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.get(`${baseURL}api/v1/inspection/systems/${uniqueId}/equipments/${equipmentId}/attachments${(sortField || sortOrder) ? (`?sortcolumn=${sortField}&sortorder=${sortOrder}`) : ''}`);
        if (res.status === "done") {
            if (res?.data?.attachmentList.length > 0) {
                dispatch(setAttachmentList(res?.data?.attachmentList));
                dispatch(setAttachmentPageDetails(res?.data?.pageDetails));
                setShowAttachmentListModal(true);
            } else {
                setMessage("No Attachments exist")
                setDisplayInfoModal(true);
                setShowAttachmentListModal(false);
            }
        } else {
            dispatch(setErrors(res.error));
        }
    } catch (error) {
        dispatch(setErrors(error));
    }
};

export const createReportPreviewSession = (reportParameters: any): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.post(`${baseURL}api/v1/reports/preview/newsession`, reportParameters);
            if (res.status === "done") {
                dispatch(setReportPreviewSession(res.data));
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

export const onDownloadAttachmentFile = (attachmentId, FullPath: any, setDisplayInfoModal, setMessage, renderFlag: boolean, setStreamData: any, fileName: any, ): AppThunk => async (dispatch) => {
    try {
        const request: any = {
            method: "POST",
            cache: "no-store",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                Authorization: sessionStorage.getItem("accesstoken"),
            },
            body: JSON.stringify({ FullPath: FullPath })
        };
        const downloadUrl = `${baseURL}api/v1/common/files/${attachmentId}/download`
        await fetch(downloadUrl, request).then((response: any) => {

            if (response.status === 200) {
                const res = new Response(
                    new ReadableStream({
                        async start(controller) {
                            const reader = response.body.getReader();
                            for (; ;) {
                                const { done, value } = await reader.read();
                                if (done) break;
                                controller.enqueue(value);
                            }
                            controller.close();
                        },
                    })
                );
                if (!renderFlag) {
                    res.blob().then((blob) => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement("a");
                        a.href = url;
                        a.download = fileName;
                        a.click();
                    });
                } else {
                    res.blob().then((blob) => {
                        var result: any
                        var reader = new FileReader();
                        reader.onload = function (e) {
                            result = e?.target?.result;
                            setStreamData(result)
                        }
                        reader.readAsDataURL(blob);
                    });
                }
            } else {
                setDisplayInfoModal(true)
                setMessage("File not found")
            }
        });
    } catch (error: any) {
        dispatch(setErrors(error));
    }
};

export const onDownloadAttachment = (inspectionTaskId: any, equipmentId: any, attachmentId: any, setDisplayInfoModal, setMessage, setStreamData?: any, fileName?: any): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.get(`${baseURL}api/v1/inspection/inspectiontask/${inspectionTaskId}/equipments/${equipmentId}/attachments/${attachmentId}`);
        if (res.status === "done") {
            dispatch(onDownloadAttachmentFile(attachmentId, res.data?.fullPath, setDisplayInfoModal, setMessage, false, setStreamData, fileName));
        } else {
            dispatch(setErrors(res.error));
        }
    } catch (error) {
        dispatch(setErrors(error));
    }
};

export const onGetWorksheets = (excelFileId: any): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.get(`${baseURL}api/v1/inspection/equipments/import/excel/${excelFileId}/sheets`);
        if (res.status === "done") {
            dispatch(setWorksheets(res.data.sheetDetails));
        } else {
            dispatch(setErrors(res.error));
        }
    } catch (error) {
        dispatch(setErrors(error));
    }
};

export const onGetExcelMappingColumns = (equipmentClassId: any, excelFileId: any, sheetIndex: any,setMessage:any,setDisplayInfoModal:any): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.get(`${baseURL}api/v1/inspection/equipments/${equipmentClassId}/import/excel/${excelFileId}/sheets/${sheetIndex}/columns`);
        if (res.status === "done") {
            dispatch(setExcelMappingColumn(res.data.columnMappings));
        } else {
            dispatch(setErrors(res.error));
            setMessage(res.message);
            setDisplayInfoModal(true);
        }
    } catch (error) {
        dispatch(setErrors(error));
    }
};

export const onGetFetchedData = (equipmentClassId: any, excelFileId: any, sheetIndex: any, body: any,setMessage:any,setDisplayInfoModal:any): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.post(`${baseURL}api/v1/inspection/equipments/${equipmentClassId}/import/excel/${excelFileId}/sheets/${sheetIndex}/data`, body);
        if (res.status === "done") {
            dispatch(setFetchedEquipmentData(res));
        } else {
            dispatch(setFetchedEquipmentData(res));
            dispatch(setErrors(res.error));
            setMessage(res.message);
            setDisplayInfoModal(true);
        }
    } catch (error) {
        dispatch(setErrors(error));
    }
};

export const onVerifiedFetchedData = (equipmentClassId: any, excelFileId: any, sheetIndex: any, body: any): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.post(`${baseURL}api/v1/inspection/equipments/${equipmentClassId}/import/excel/${excelFileId}/sheets/${sheetIndex}/data/validate`, body);
        if (res.status === "done") {
            dispatch(setVerifiedFetchedData(res.data));
        } else {
            dispatch(setErrors(res.error));
        }
    } catch (error) {
        dispatch(setErrors(error));
    }
};

export const onImportEquipmentData = (equipmentClassId: any, excelFileId: any, sheetIndex: any, body: any): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.post(`${baseURL}api/v1/inspection/equipments/${equipmentClassId}/import/excel/${excelFileId}/sheets/${sheetIndex}/data/import`, body);
        if (res.status === "done") {
            dispatch(setImportedEquipmentData(res.data));
        } else {
            dispatch(setErrors(res.error));
        }
    } catch (error) {
        dispatch(setErrors(error));
    }
};

export const onExportEquipmentData = (equipmentClassId: any, excelFileId: any, sheetIndex: any, body: any): AppThunk => async (dispatch) => {
    try {
        const request: any = {
            method: "POST",
            cache: "no-store",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                Authorization: sessionStorage.getItem("accesstoken"),
            },
            body: JSON.stringify(body)
        };
        const downloadUrl = (window as any).envSettings.baseAPIUrl + `api/v1/inspection/equipments/${equipmentClassId}/import/excel/${excelFileId}/sheets/${sheetIndex}/data/export`;
        await fetch(downloadUrl, request).then((response: any) => {
            if (response.ok) {
                response.blob().then((blob: any) => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement("a");
                    a.href = url;
                    a.download = "ImportEquipment";
                    a.click();
                });
            }
        });
    } catch (error) {
        dispatch(setErrors(error));
    }
};

export const onGetMultipleEditEquipmentData = (body: any): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.post(`${baseURL}api/v1/inspection/inspectiontask/equipments/datafields/general`, body);
        if (res.status === "done") {
            dispatch(setMultipleEditDatafields(res.data?.dataFields));
        } else {
            dispatch(setErrors(res.error));
        }
    } catch (error) {
        dispatch(setErrors(error));
    }
};

export const onMultipleEditEquipmentDataUpdate = (body: any, reset, setSelectedDatafield, setModifiedContent): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.post(`${baseURL}api/v1/inspection/inspectiontask/equipments/update `, body);
        if (res.status === "done") {
            dispatch(setMultipleUpdatedData(res.data));
            reset();
            setSelectedDatafield([]);
            setModifiedContent({});
        } else {
            dispatch(setErrors(res.error));
        }
    } catch (error) {
        dispatch(setErrors(error));
    }
};

export const onGetArchievedSearches = (taskId): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.get(`${baseURL}api/v1/inspection/inspectionTasks/${taskId}/equipments/archievedsearches `);
        if (res.status === "done") {
            dispatch(setArchievedSearches(res?.data?.searchCriteria));
        } else {
            dispatch(setErrors(res.error));
        }
    } catch (error) {
        dispatch(setErrors(error));
    }
};

export const onGetEquipmentGroupDatafields = (panelId, taskId): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.get(`${baseURL}api/v1/inspection/inspectiontask/${taskId}/equipments/panels/${panelId}`);
        if (res.status === "done") {
            dispatch(setEquipmentGroupDatafields(res?.data?.dataFields));
        } else {
            dispatch(setErrors(res.error));
        }
    } catch (error) {
        dispatch(setErrors(error));
    }
};

export const onNewEquipmentGroupAdd = (body: any, taskId: any, setLoader: any): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.post(`${baseURL}api/v1/inspection/inspectiontask/${taskId}/equipments/panels/add`, body);
        if (res.status === "done") {
            dispatch(setNewEquipmentGroup(res?.data));
        } else {
            dispatch(setErrors(res.error));
        }
        setLoader(false);
    } catch (error) {
        dispatch(setErrors(error));
        setLoader(false);
    }
};

export const onEquipmentGroupUpdate = (body, taskId, panelId): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.post(`${baseURL}api/v1/inspection/inspectiontask/${taskId}/equipments/panels/${panelId}`, body);
        if (res.status === "done") {
            dispatch(setUpdatedEquipmentGroup(res?.data));
        } else {
            dispatch(setErrors(res.error));
        }
    } catch (error) {
        dispatch(setErrors(error));
    }
};

export const onGetImportEquipmentDatafields = (body, classId): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.get(`${baseURL}api/v1/inspection/equipments/import/excel/equipmentclasses/${classId}/datafields`);
        if (res.status === "done") {
            dispatch(setImportEquipmentDatafields(res?.data?.dataFieldDetails));
        } else {
            dispatch(setErrors(res.error));
        }
    } catch (error) {
        dispatch(setErrors(error));
    }
};

export const onResetEquipments = (selectedRows: any, uniqueInspectiontaskId: any, panelId: any, body): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.post(`${baseURL}api/v1/inspection/inspectiontask/${uniqueInspectiontaskId}/equipments/panels/${panelId}/reset`, body);
            if (res.status === "done") {
                let resettedRows = JSON.parse(JSON.stringify(selectedRows));
                resettedRows?.map((item: any, key: any) => {
                    item["Test Result"] = "Not Tested";
                    item["Test Method"] = "None";
                    item["Rec"] = null;
                    item["Test Date"] = null;
                });
                dispatch(setMultipleUpdatedData(resettedRows));
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

export const getEquipmentAttachments = (inspectiontaskid: any, equipmentid: any, currentStart: any, sortField: any, sortOrder: any): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.get(`${baseURL}api/v1/inspection/inspectiontask/${inspectiontaskid}/equipments/${equipmentid}/attachments?currentstart=${currentStart}&sortcolumn=${sortField}&sortorder=${sortOrder}`);
            if (res.status === "done") {
                dispatch(setEquipmentAttachmentsList(res.data?.attachmentList));
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

export const uploadAttachment = (inspectiontaskid: any, equipmentid: any, body: any, setMessage: any, setDisplayInfoModal: any, resetFileUploadForm: any, setEnableUpload?: any): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.post(`${baseURL}api/v1/inspection/inspectiontask/${inspectiontaskid}/equipments/${equipmentid}/attachments/add`, body);
            if (res.status === "done") {
                if (res.data && res.data.attachmentList) {
                    dispatch(setNewEquipmentAttachmentData(res.data?.attachmentList));
                    dispatch(setEquipmentAttachmentlist(res.data?.attachmentList))
                    setMessage('Selected File uploaded');
                    setDisplayInfoModal(true);
                    resetFileUploadForm();
                }
            } else {
                dispatch(setErrors(res.error));
                setEnableUpload(false);
                if (res.message) {
                    setMessage(res.message);
                    setDisplayInfoModal(true);
                }
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

export const editAttachment = (inspectiontaskid: any, equipmentid: any, fileId: any, body: any, setMessage: any, setDisplayInfoModal: any, resetFileEditForm: any, setSelectedRows: any, setEnableUpload?: any): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.post(`${baseURL}api/v1/inspection/inspectiontask/${inspectiontaskid}/equipments/${equipmentid}/attachments/${fileId}`, body);
            if (res.status === "done") {
                setSelectedRows([(res?.data)]);
                dispatch(setUpdatedAttachmentData(res?.data));
                dispatch(setEquipmentAttachmentlist([res.data]))
                setMessage('Attachment updated');
                setDisplayInfoModal(true);
                resetFileEditForm();
            } else {
                dispatch(setErrors(res.error));
                setEnableUpload(false);
                if (res.message) {
                    setMessage(res.message);
                    setDisplayInfoModal(true);
                }
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

export const deleteAttachment = (inspectiontaskid: any, equipmentid: any, selectedRows: any, setMessage: any, setDisplayInfoModal: any, setDisplayConfirmModal: any, setSelectedRows: any): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.post(`${baseURL}api/v1/inspection/inspectiontask/${inspectiontaskid}/equipments/${equipmentid}/attachments/${selectedRows[0]?.attachmentId}/remove`, selectedRows[0]?.fileName);
            if (res.status === "done") {
                dispatch(setDeletedEquipmentAttachmentList(selectedRows[0]?.attachmentId));
                setSelectedRows([]);
                setMessage('Attachment deleted');
                setDisplayConfirmModal(false);
                setDisplayInfoModal(true);
            } else {
                dispatch(setErrors(res.error));
                if (res.message) {
                    setMessage(res.message);
                    setDisplayInfoModal(true);
                }
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

export const downloadEquipmentAttachment = (inspectiontaskid: any, equipmentid: any, fileId: any, fileName: any, setMessage: any, setDisplayInfoModal: any,): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.get(`${baseURL}api/v1/inspection/inspectiontask/${inspectiontaskid}/equipments/${equipmentid}/attachments/${fileId}`);
            if (res.status === "done") {
                dispatch(onDownloadFile(fileId, fileName, res?.data?.fullPath, setMessage, setDisplayInfoModal));
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

export const onViewEquipmentAttachment = (inspectiontaskid: any, equipmentid: any, fileId: any, fileName: any, setMessage: any, setDisplayInfoModal: any, setStreamData: any, setShowViewModal: any): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.get(`${baseURL}api/v1/inspection/inspectiontask/${inspectiontaskid}/equipments/${equipmentid}/attachments/${fileId}`);
            if (res.status === "done") {
                if(fileName){
                    if(fileName.split('.').pop()==="png"||fileName.split('.').pop()==="jpg"||fileName.split('.').pop()==="jpeg"){
                        setShowViewModal(true);
                        dispatch(onDownloadAttachmentFile(fileId, res.data?.fullPath, setDisplayInfoModal, setMessage, true, setStreamData, fileName));
                    } else {
                        dispatch(onDownloadAttachmentFile(fileId, res.data?.fullPath, setDisplayInfoModal, setMessage, false, setStreamData, fileName));
                    }
                }
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

export const onDownloadMultipleAttachment = (inspectionTaskId: any, equipmentId: any, attachmentIds: any, setMessage: any, setDisplayInfoModal: any): AppThunk => async (dispatch) => {
    try {
        const request: any = {
            method: "POST",
            cache: "no-store",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                Authorization: sessionStorage.getItem("accesstoken"),
            },
            body: JSON.stringify({ attachmentIds: attachmentIds })
        };
        const downloadUrl = (window as any).envSettings.baseAPIUrl + `api/v1/inspection/inspectiontask/${inspectionTaskId}/equipments/${equipmentId}/attachments`;
        await fetch(downloadUrl, request).then((response: any) => {
            if (response.ok) {

                const res = new Response(
                    new ReadableStream({
                        async start(controller) {
                            const reader = response.body.getReader();
                            for (; ;) {
                                const { done, value } = await reader.read();
                                if (done) break;
                                controller.enqueue(value);
                            }
                            controller.close();
                        },
                    })
                );

                res.blob().then((blob) => {
                    const filename = response.headers.get("content-disposition")?.split(';')[1]?.split('=')[1];
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement("a");
                    a.href = url;
                    a.download = filename.replaceAll('\"', '');
                    a.click();
                });
            } else {
                setDisplayInfoModal(true);
                setMessage("File not found")
            }
        });
    } catch (error) {
        dispatch(setErrors(error));
    }
};

export const addUserDefaultEquipmentClass = (body:any): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.post(`${baseURL}api/v1/inspection/inspectiontask/userdefaultequipmentclass/add`, body);

        } catch (error) {
            dispatch(setErrors(error));
        }
    };

    export const getEquipmentTypeListWithDefaultEquipmentClass = (type:any,taskId:any): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.get(`${baseURL}api/v1/inspection/inspectiontask/getequipmentclasslist/${taskId}`);
            if (res.status === "done") {
                let typeList = res?.data?.equipmentTypeInfo;
                let userDefaultEquipmentClass = res?.data?.userDefaultEquipmentClass;
               
                    typeList.splice(0, 0, { uniqueEquipmentClassId: "All", equipmentClassName: "All" });
                    dispatch(setEquipmentTypeList(typeList));
                
                    if(type == '')
                    {
                        if(userDefaultEquipmentClass == null || userDefaultEquipmentClass == "")
                        userDefaultEquipmentClass = "All";

                        dispatch(setUserDefaultEquipmentClass(userDefaultEquipmentClass))
                    }
                
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };    

export const {
    setErrors,
    setEquipmentList,
    setEquipmentPanelList,
    setEquipmentTypeList,
    setEquipmentDatafields,
    setNewEquipment,
    setUpdatedEquipment,
    setRemovedEquipment,
    setEquipmentImportCheckData,
    setCheckSystemParseInProgress,
    setXMLImportStatus,
    setAssociatedDevicesData,
    setUpdatedEquipmentAddress,
    setMasterDataList,
    setMasterDataPageDetails,
    setAttachmentList,
    setAttachmentPageDetails,
    setReportPreviewSession,
    setUploadedExcels,
    setWorksheets,
    setExcelMappingColumn,
    setEquipmentSpecificLists,
    setFetchedEquipmentData,
    setVerifiedFetchedData,
    setImportedEquipmentData,
    setMultipleEditDatafields,
    setMultipleUpdatedData,
    setArchievedSearches,
    setEquipmentGroupDatafields,
    setNewEquipmentGroup,
    setUpdatedEquipmentGroup,
    setImportEquipmentDatafields,
    setTypeOfEquipmentList,
    setEquipmentAttachmentsList,
    setNewEquipmentAttachmentData,
    setUpdatedAttachmentData,
    setDeletedEquipmentAttachmentList,
    setIsDevReadingChanged,
    setIsTestedDateChanged,
    setEquipmentAttachmentlist,
    setUserDefaultEquipmentClass,
    setIsEquipmentClassDropdownChanged

} = EquipmentSlice.actions;
export default EquipmentSlice.reducer;