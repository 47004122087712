import React from 'react'
import { Dialog } from 'primereact/dialog';
import { baseURL } from "../../../service/HttpService";
interface DocumentViewerPropType {
    showModal: boolean;
    setShowModal: (state: boolean) => void;
    fileName?: string;
    setFileName: (state: any) => void;
    drawingId?: any;
    revisionNumber?:any;

}
const PdfDrawingViewer: React.FC<DocumentViewerPropType> = ({
    showModal = false,
    setShowModal = () => { },
    fileName = "",
    setFileName = () => { },
    drawingId = "",
    revisionNumber=""
}) => {
    let reportUrl = `${baseURL}api/v1/asbuilts/pdfdrawing/${drawingId}/${revisionNumber}/view`;
    return (
        <div>
            <Dialog
                header={<span title={`${fileName}`}>View Drawing ({fileName})</span>}
                visible={showModal}
                style={{ width: "75vw", height: "85%" }}
                onHide={() => {
                    setShowModal(false);
                    setFileName('');
                }}
                className="view-file-modal">
                <div className="content-box">
                    <div className='file-content'>
                        {(reportUrl) ?
                            <object data={reportUrl} title={fileName} type="application/pdf" width="100%" height="100%" ></object>
                            :
                            (<></>)
                        }
                    </div>
                </div>
            </Dialog>
        </div>
    )
}
export default PdfDrawingViewer;