
export const TRUNAROUND_COLS = [
  {
    field: "reportName",
    header: "Report Name",
    style: { flex: "1 0 150px" },
    sortable: true,
  },
  {
    field: "workCompletionDate",
    header: "Work Completion Date",
    style: { flex: "1 0 120px" },
    sortable: true,
  },
  {
    field: "reportDeliveryDate",
    header: "Report Delivery Date",
    style: { flex: "1 0 140px" },
    sortable: true,
  },
  {
    field: "turnAroundDays",
    header: "Days / Turnaround",
    style: { flex: "1 0 30px" },
    sortable: true,
    align: "right",
  },
];
