import { Button } from "primereact/button";
import React from "react";
import { BsPlusLg } from "react-icons/bs";


const CopyDocument = () => {
    return (
        <>
            <h4 className="doc-title mb-2">Copy</h4>
            <p>
                This option is used to Copy System and All Systems in a Contract 
                from one Site to another. This option is only available for Super 
                Administrators and Support users.
            </p>
            <p>
              <i>Note: </i>Move is not allowed if any Active Inspection Task available 
              under the selected Site/System.
            </p>
            <b className="doc-title mb-2">Copy System</b>
            <p>
                On selecting Copy System, options to select Customer (source and destination),
                Site(Destination), System (Source) and Contract (Destination) will be displayed.
                Two Options namely - 'Copy only the latest Task under the System' and 
                'Copy all tasks under the System' are also provided. If all the existing Reports 
                of the selected System need to be copied, a check box 'Copy all existing Reports' 
                is provided. Make neccessary selections and click Copy button. Data will be copied 
                to the Destination as per the selections made If no Contract exists in the 
                Destination, New Contract can be added by clicking
                <span  className="doc-subtitle"> Add </span> 
                <Button className="doc-button-icon"> <BsPlusLg className="icon" title="Add" /> </Button> icon 
                provided next to the Destination Contract field.
            </p>
            <p>
               <i>Note: </i>Systems in the selected Contract cannot be moved if the selected Contract is linked to multiple Sites.
            </p>

            <b className="doc-title mb-2">Copy All System in a Contract</b>

            <p>   
                On selecting All Systems in a Contract for Copy, 
                options to select Customer (source and destination), 
                Site (source and destination), and Contract (source and destination) 
                will be displayed. Two Options namely - 'Copy only the latest Task of 
                all Systems' and 'Copy all tasks under the Contract' are also provided. 
                If all the existing Reports of the selected System need to be copied, 
                a check box 'Copy all existing Reports' is provided.Make neccessary 
                selections and click Copy button. Data will be copied to the Destination 
                as per the selections made. If no Contract exists in the Destination, 
                New Contract can be added by clicking 
                <span className="doc-subtitle"> Add </span>  
                <Button className="doc-button-icon"> <BsPlusLg className="icon" title="Add"/> </Button> icon 
                provided next to the Destination Contract field.
             </p>
        </>
    );
}

export default CopyDocument;