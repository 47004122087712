import React from "react";
import { APP_ROUTES } from "constants/AppRoutes";

import OverviewDoument from "./HelpDocuments/OverviewDoument";
import ModulesDoument from "./HelpDocuments/ModulesDoument";
import DashboardDoument from "./HelpDocuments/DashboardDoument";

import AdministrationDocument from "./HelpDocuments/Admininstration/AdministrationDoument";
import AdministrationCustomers from "./HelpDocuments/Admininstration/AdministrationCustomers";
import AdministrationSites from "./HelpDocuments/Admininstration/AdministrationSites";
import UsersDocument from "./HelpDocuments/Admininstration/UsersDocument";
import AdministrationBuildingsDocument from "./HelpDocuments/Admininstration/AdministrationBuildingsDocument";
import AdministrationFloorsDocument from "./HelpDocuments/Admininstration/AdministrationFloorsDocument";
import AdministrationSearchDocument from "./HelpDocuments/Admininstration/AdministrationSearchDocument";
import AdministrationSearchContractDocument from "./HelpDocuments/Admininstration/AdministrationSearchContract";
import AdministrationSearchCustomerDocument from "./HelpDocuments/Admininstration/AdministrationSearchCustomerDocument";
import AdministrationSearchInspectionTaskDocument from "./HelpDocuments/Admininstration/AdministrationSearchInspectionTaskDocument";
import AdministrationSearchNotificationNumberDocument from "./HelpDocuments/Admininstration/AdministrationSearchNotificationNumber";
import AdministrationSearchSiteDocument from "./HelpDocuments/Admininstration/AdministrationSearchSiteDocument";
import AdministrationSearchSystemDocument from "./HelpDocuments/Admininstration/AdministrationSearchSystem";
import AdministrationSearchUserDocument from "./HelpDocuments/Admininstration/AdministrationSearchUsers";

import InspectionDocuments from "./HelpDocuments/Inspection/InspectionDocuments"
import InspectionTasksDocument from "./HelpDocuments/Inspection/InspectionTasksDocument";
import InspectionTaskInspectionDetailsDocument from "./HelpDocuments/Inspection/InspectionTaskInspectionDetailsDocument";
import InspectionDrawingsDocument from "./HelpDocuments/Inspection/InspectionDrawingsDocument";
import InspectionContractsDocument from "./HelpDocuments/Inspection/InspectionContractsDocument";
import InspectionSystemsDocument from "./HelpDocuments/Inspection/InspectionSystemsDocument";
import InspectionReportsReportsDocument from "./HelpDocuments/Inspection/InspectionReportsReportsDocument";
import InspectionReportsGenerateReportsDocument from "./HelpDocuments/Inspection/InspectionReportsGenerateReportsDocument";
import SearchDocument from "./HelpDocuments/Inspection/SearchDocument";
import SearchSiteDocument from "./HelpDocuments/Inspection/SearchSiteDocument";
import SearchCustomerDocument from "./HelpDocuments/Inspection/SearchCustomerDocument";
import SearchContractDocument from "./HelpDocuments/Inspection/SearchContractDocument";
import SearchSystemDocument from "./HelpDocuments/Inspection/SearchSystemDocument";
import SearchInspectionTaskDocument from "./HelpDocuments/Inspection/SearchInspectionTaskDocument";
import SearchNotificationNumberDocument from "./HelpDocuments/Inspection/SearchNotificationNumberDocument";
import SearchUserDocument from "./HelpDocuments/Inspection/SearchUserDocument";

import AsBuiltsDocument from "./HelpDocuments/AsBuilts/AsBuiltsDocument";
import AsBuiltsDrawingsDocument from "./HelpDocuments/AsBuilts/AsBuiltsDrawingsDocument";

import SecuritySettingsDocument from "./HelpDocuments/Manage/SecuritySettings/SecuritySettingsDocument";
import PasswordPolicyDocument from "./HelpDocuments/Manage/SecuritySettings/PasswordPolicyDocument";
import MailDomainsDocument from "./HelpDocuments/Manage/SecuritySettings/MailDomainsDocument";

import OrganizationSettingsDocument from "./HelpDocuments/Manage/OrganizationSettings/OrganizationSettingsDocument";
import BranchOfficeOrganizationSettingsDocument from "./HelpDocuments/Manage/OrganizationSettings/BranchOfficeOrganizationSettingsDocument";
import SalesOfficeOrganizationSettingsDocument from "./HelpDocuments/Manage/OrganizationSettings/SalesOfficeOrganizationSettingsDocument";
import ZoneOrganizationSettingsDocument from "./HelpDocuments/Manage/OrganizationSettings/ZoneOrganizationSettingsDocument";

import LookUpsDocument from "./HelpDocuments/Manage/LookUps/LookUpsDocument";
import HealthClassificationsDocument from "./HelpDocuments/Manage/LookUps/HealthClassificationsDocument";
import NFPAClassificationsDocument from "./HelpDocuments/Manage/LookUps/NFPAClassificationsDocument";

import InspectionSettingsDocument from "./HelpDocuments/Manage/InspectionSettings/InspectionSettingsDocument";
import InspectionFieldsDocument from "./HelpDocuments/Manage/InspectionSettings/InspectionFieldsDocument";
import ReportMappingDocuments from "./HelpDocuments/Manage/InspectionSettings/ReportMappingDocuments";

import InspectionReportGroupsDocument from "./HelpDocuments/Manage/InspectionSettings/InspectionReportGroupsDocument";
import InspectionReportsDocument from "./HelpDocuments/Manage/InspectionSettings/InspectionReportsDocument";
import InspectionReportConfigsDocument from "./HelpDocuments/Manage/InspectionSettings/InspectionReportConfigsDocument";
import InspectionReportTemplatesDocument from "./HelpDocuments/Manage/InspectionSettings/InspectionReportTemplatesDocument";

import ToolsDocument from "./HelpDocuments/Manage/Tools/ToolsDocument";
import CopyDocument from "./HelpDocuments/Manage/Tools/CopyDocument";
import MoveDocument from "./HelpDocuments/Manage/Tools/MoveDocument";

import CustomizationDocument from "./HelpDocuments/Manage/Customization/CustomizationDocument";
import CommonAttributesDocument from "./HelpDocuments/Manage/Customization/CommonAttributesDocument";
import CommonAttributeValuesDocument from "./HelpDocuments/Manage/Customization/CommonAttributeValuesDocument";
import EquipmentTypeAttributesDocument from "./HelpDocuments/Manage/Customization/EquipmentTypeAttributesDocument";
import EquipmentTypeAttributeValuesDocument from "./HelpDocuments/Manage/Customization/EquipmentTypeAttributeValuesDocument";
import DisplaySettingsDocument from "./HelpDocuments/Manage/Customization/DisplaySettingsDocument";
import DataEntrySettingsDocument from "./HelpDocuments/Manage/Customization/DataEntrySettingsDocument";

import ReportsDocument from "./HelpDocuments/Reports/ReportsDocument";
import ReportsContractDocument from "./HelpDocuments/Reports/ReportsContractDocument";
import ReportsInspectionTasksDocuments from "./HelpDocuments/Reports/ReportsInspectionTasksDocuments";
import ReportsInspectionActivityDocument from "./HelpDocuments/Reports/ReportsInspectionActivityDocument";
import ReportsUploadedFinalReportsDocument from "./HelpDocuments/Reports/ReportsUploadedFinalReportsDocument";
import ReportsUserDetailsDocument from "./HelpDocuments/Reports/ReportsUserDetailsDocument";
import ReportsSitesDocument from "./HelpDocuments/Reports/ReportsSitesDocument";
import ReportsBuildingsDocument from "./HelpDocuments/Reports/ReportsBuildingsDocument";
import ReportsFloorsDocument from "./HelpDocuments/Reports/ReportsFloorsDocument";
import ReportsMasterDataDocument from "./HelpDocuments/Reports/ReportsMasterDataDocument";
import ReportsExpiredContractsDocument from "./HelpDocuments/Reports/ReportsExpiredContractsDocument";
import ReportsUserActivityReportsDocument from "./HelpDocuments/Reports/ReportsUserActivityReportsDocument";
import ReportSAPImportStatusDocument from "./HelpDocuments/Reports/ReportSAPImportStatusDocument";

import ReleaseNotesDocument from "./HelpDocuments/ReleaseNotesDocument";

import PluginsDocument from "./HelpDocuments/Plugins/PluginsDocument";
import PluginsTapAppDocument from "./HelpDocuments/Plugins/PluginsTapAppDocument";
import PluginsTapBridgeDocument from "./HelpDocuments/Plugins/PluginsTapBridgeDocument";

import LinksDocument from "./HelpDocuments/LinksDocument";

import FeedbackDocument from "./HelpDocuments/Preferences/FeedbackDocument";
import PersonalSettingsDocument from "./HelpDocuments/Preferences/PersonalSettingsDocument";
import SendMailDocuments from "./HelpDocuments/Preferences/SendMailDocuments";

import LogbookDocument from "./HelpDocuments/LogbookDocument";
import FaqDocument from "./HelpDocuments/Faq/FaqDocument";
import AdministrationFaqDocument from "./HelpDocuments/Faq/AdministrationFaqDocument";
import InspectionFAQDocument from "./HelpDocuments/Faq/InspectionFAQDocument";
import PreferencesDocument from "./HelpDocuments/Preferences/PreferencesDocument";
import ReportsTapPerformanceDocument from "./HelpDocuments/Reports/ReportsTapPerformanceDocument";
import ReportsSalesOpportunityDocument from "./HelpDocuments/Reports/ReportsSalesOpportunityDocument";


export const CondtionalRender = (selectedPage) => {
    switch (selectedPage) {
        case "overview":
            return (<OverviewDoument />);

        case "techAdavanceModules":
            return (<ModulesDoument />);

        case APP_ROUTES.DASHBOARD:
            return (<DashboardDoument />);

        case "dashboard":
            return (<DashboardDoument />);
        
        case APP_ROUTES.ADMINISTRATION:
            return (<AdministrationDocument />);

        case "administration":
            return (<AdministrationDocument />);
        
        case APP_ROUTES.CUSTOMERS:
            return (<AdministrationCustomers />)

        case "administration-customers":
            return (<AdministrationCustomers />)

        case APP_ROUTES.SITES:
            return (<AdministrationSites />)

        case "administration-sites":
            return (<AdministrationSites />)

        case APP_ROUTES.USERS:
            return (<UsersDocument />);

        case "users":
            return (<UsersDocument />);

        case "administration-buildings":
            return (<AdministrationBuildingsDocument />);

        case "administration-floors":
            return (<AdministrationFloorsDocument />);

        case "administration-search":
            return (<AdministrationSearchDocument />)

        case "administration-search-contract":
            return (<AdministrationSearchContractDocument />)

        case "administration-search-customer":
            return (<AdministrationSearchCustomerDocument />)

        case "administration-search-inspection-task":
            return (<AdministrationSearchInspectionTaskDocument />)

        case "administration-search-notification-number":
            return (<AdministrationSearchNotificationNumberDocument />)

        case "administration-search-site":
            return (<AdministrationSearchSiteDocument />)

        case "administration-search-system":
            return (<AdministrationSearchSystemDocument />)

        case "administration-search-user":
            return (<AdministrationSearchUserDocument />)

        case APP_ROUTES.AS_BUILTS:
            return (<AsBuiltsDrawingsDocument />);

        case "as-builts":
            return (<AsBuiltsDrawingsDocument />);  
        case "as-builts-overview":
            return (<AsBuiltsDocument />);

        case APP_ROUTES.INSPECTION:
            return (<InspectionDocuments />);

        case "inspection":
            return (<InspectionDocuments />);

        case APP_ROUTES.INSPECTION_TASKS:
            return (<InspectionTasksDocument />);

        case "inspection-tasks":
            return (<InspectionTasksDocument />);

        case "inspection-task-details":
            return (<InspectionTaskInspectionDetailsDocument />);
        
        case "inspection-task-drawings":
                return (<InspectionDrawingsDocument />);

        case APP_ROUTES.CONTRACTS:
            return (<InspectionContractsDocument />);

        case "contracts":
            return (<InspectionContractsDocument />);

        case APP_ROUTES.SYSTEMS:
            return (<InspectionSystemsDocument />);

        case "systems":
            return (<InspectionSystemsDocument />);

        case APP_ROUTES.INSPECTIONREPORTS_REPORTS:
            return (<InspectionReportsReportsDocument />);

        case "inspection-reports":
            return (<InspectionReportsReportsDocument />);

        case "Inspection-GenerateReports":
            return (<InspectionReportsGenerateReportsDocument />)

        case "inspection-search":
            return (<SearchDocument />);

        case "search-site":
            return (<SearchSiteDocument />);

        case "search-customer":
            return (<SearchCustomerDocument />);

        case "search-contract":
            return (<SearchContractDocument />);

        case "search-system":
            return (<SearchSystemDocument />);

        case "search-inspection-task":
            return (<SearchInspectionTaskDocument />);

        case "search-notification-number":
            return (<SearchNotificationNumberDocument />);

        case "search-user":
            return (<SearchUserDocument />);

        case APP_ROUTES.SECURITY_SETTINGS:
            return (<SecuritySettingsDocument />);

        case "security-settings":
            return (<SecuritySettingsDocument />);

        case "password-policy":
            return (<PasswordPolicyDocument />);

        case "mail-domains":
            return (<MailDomainsDocument />);

        case APP_ROUTES.MANAGE_ORGANIZATION:
            return (<OrganizationSettingsDocument />);

        case "organization-settings":
            return (<OrganizationSettingsDocument />);

        case "zone":
            return (<ZoneOrganizationSettingsDocument />);

        case "sales-office":
            return (<SalesOfficeOrganizationSettingsDocument />);

        case "branch-office":
            return (<BranchOfficeOrganizationSettingsDocument />);

        case "manage-lookups":
            return (<LookUpsDocument />);
        
        case APP_ROUTES.MANAGE_LOOKUPS_HEALTHCLASSIFICATION:
            return (<HealthClassificationsDocument />);

        case "health-classifications":
            return (<HealthClassificationsDocument />);

        case APP_ROUTES.MANAGE_LOOKUPS_NFPA_CLASSIFICATION:
            return (<NFPAClassificationsDocument />);

        case "nfpa-classifications":
            return (<NFPAClassificationsDocument />);

        case "inspection-settings":
            return (<InspectionSettingsDocument />);
        case APP_ROUTES.INSPECTION_FIELDS:
            return (<InspectionFieldsDocument />);

        case "inspection-settings-inspection-fields":
            return (<InspectionFieldsDocument />);
        
        case APP_ROUTES.MANAGE_INSPECTION_REPORT_MAPPING:
            return (<ReportMappingDocuments />);

        case "inspection-settings-report-mapping":
            return (<ReportMappingDocuments />);

        case APP_ROUTES.MANAGE_INSPECTION_REPORTS:
            return (<InspectionReportGroupsDocument />);

        case "inspection-settings-report-group":
            return (<InspectionReportGroupsDocument />);

        case "inspection-settings-reports":
            return (<InspectionReportsDocument />);


        case "inspection-settings-reports-templates":
            return (<InspectionReportTemplatesDocument />);


        case "inspection-settings-report-configs":
            return (<InspectionReportConfigsDocument />);

        case "tools":
            return (<ToolsDocument />);
        
        case APP_ROUTES.TOOLS_COPY:
            return (<CopyDocument />);

        case "copy":
            return (<CopyDocument />);

        case APP_ROUTES.TOOLS_Move:
            return (<MoveDocument />);

        case "move":
            return (<MoveDocument />);

        case "manage-customization":
            return (<CustomizationDocument />);
        
        case APP_ROUTES.CUSTOMIZATION_ATTRIBUTES:
            return (<CommonAttributesDocument />);

        case "common-attributes":
            return (<CommonAttributesDocument />);

        case "common-attributes-values":
            return (<CommonAttributeValuesDocument />);

        case "equipment-type-attributes":
            return (<EquipmentTypeAttributesDocument />);

        case "equipment-type-attributes-values":
            return (<EquipmentTypeAttributeValuesDocument />);

        case APP_ROUTES.MANAGE_DATAENTRY_SETTINGS:
            return (<DataEntrySettingsDocument />);

        case "data-entry-settings":
            return (<DataEntrySettingsDocument />);

        case APP_ROUTES.MANAGE_DISPLAY_SETTINGS:
            return (<DisplaySettingsDocument />);

        case "data-display-settings":
            return (<DisplaySettingsDocument />);

        case APP_ROUTES.REPORT_VIEW:
            return (<ReportsDocument />);

        case "reports":
            return (<ReportsDocument />);

        case APP_ROUTES.CONTRACTS_REPORT:
            return (<ReportsContractDocument />);

        case "reports-contracts":
            return (<ReportsContractDocument />);

        case APP_ROUTES.INSPECTION_TASK_REPORT:
            return (<ReportsInspectionTasksDocuments />);

        case "reports-inspection-tasks":
            return (<ReportsInspectionTasksDocuments />);

        case APP_ROUTES.INSPECTION_ACTIVITY_REPORT:
            return (<ReportsInspectionActivityDocument />);

        case "reports-inspection-activity":
            return (<ReportsInspectionActivityDocument />);
        
        case APP_ROUTES.UPLOADED_FINAL_REPORTS:
            return (<ReportsUploadedFinalReportsDocument />);

        case "reports-uploaded-final-reports":
            return (<ReportsUploadedFinalReportsDocument />);
        
        case APP_ROUTES.USER_DETAILS_REPORT:
            return (<ReportsUserDetailsDocument />);

        case "reports-user-details":
            return (<ReportsUserDetailsDocument />);

        case APP_ROUTES.SAP_IMPORT_STATUS_REPORT:
            return (<ReportSAPImportStatusDocument />);

        case "sap-import-status-report":
            return (<ReportSAPImportStatusDocument />);

        case APP_ROUTES.SITE_REPORT:
            return (<ReportsSitesDocument />);

        case "reports-sites":
            return (<ReportsSitesDocument />);

        case "reports-buildings":
            return (<ReportsBuildingsDocument />);


        case "reports-floors":
            return (<ReportsFloorsDocument />);

        case APP_ROUTES.MASTER_DATA_REPORT:
            return (<ReportsMasterDataDocument />);

        case "reports-master-data":
            return (<ReportsMasterDataDocument />);

        case APP_ROUTES.EXPIRED_CONTRACTS_REPORT:
            return (<ReportsExpiredContractsDocument />);

        case "reports-expired-contracts":
            return (<ReportsExpiredContractsDocument />);

        case APP_ROUTES.USER_ACTIVITY_REPORT:
            return (<ReportsUserActivityReportsDocument />);

        case "user-activity-report":
            return (<ReportsUserActivityReportsDocument />);

        case "release-notes":
            return (<ReleaseNotesDocument />);

        case "plugins":
            return (<PluginsDocument />);

        case "plugins-tap-app":
            return (<PluginsTapAppDocument />);

        case "plugins-tap-bridge":
            return (<PluginsTapBridgeDocument />);

        case "links":
            return (<LinksDocument />);

        case "preferences":
            return (<PreferencesDocument />)

        case "send-mail":
            return (<SendMailDocuments />);

        case "personal-settings":
            return (<PersonalSettingsDocument />);

        case "feedback":
            return (<FeedbackDocument />);

        case "logbook":
            return (<LogbookDocument />);

        case "faqs":
            return (<FaqDocument />);
        case "admininstration-faqs":
            return (<AdministrationFaqDocument />);
        case "inspection-faqs":
            return (<InspectionFAQDocument />);
        case APP_ROUTES.TAP_PERFORMACE:
                return (<ReportsTapPerformanceDocument />);
        case APP_ROUTES.SALES_ACTIVITY_REPORT:
                    return (<ReportsSalesOpportunityDocument />); 
        default:
            return (<OverviewDoument />);
    }
}