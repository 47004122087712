import React, { useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { Dialog } from "primereact/dialog";
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from "primereact/checkbox";
import moment from "moment";
//icons
import { BsExclamationCircleFill } from "react-icons/bs";


interface Props {
  showModal: boolean;
  generalProperties?: any;
  selectedTask?: any;
  uniqueEquipmentClassId?: string;
  uniquePanelId?: string;
  setShowModal: (state: boolean) => void;
  onSearched: (body: any) => void;
}

type FormData = {
  plannedStartDate: any;
  plannedEndDate: any;
  firstRowDataFieldId: any;
  secondRowDataFieldId: any;
  thirdRowDataFieldId: any;
  firstRowFieldValue: string;
  secondRowFieldValue: string;
  thirdRowFieldValue: string;
};

export const FilterEquipment: React.FC<Props> = ({
  showModal = false,
  generalProperties,
  selectedTask,
  uniqueEquipmentClassId,
  uniquePanelId,
  setShowModal = () => { },
  onSearched = () => { },
}) => {
  const addUserForm: any = useRef();
  const [equipmentColumns, setEquipmentColumns] = useState<any>([]);
  const [checkedItem, setCheckedItem] = useState({ "showUntestedEquipments": false, "showWithAttachmentsOnly": false, "isFromXMLImported": true, "isFromManuallyAdded": true });

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      plannedStartDate: "",
      plannedEndDate: "",
      firstRowDataFieldId: "",
      secondRowDataFieldId: "",
      thirdRowDataFieldId: "",
      firstRowFieldValue: "",
      secondRowFieldValue: "",
      thirdRowFieldValue: "",
    },
  });

  useEffect(() => {
    if (generalProperties?.length) {
      const filterItems = generalProperties?.filter((item) => item.propertyType !== "INTERNAL");
      filterItems.unshift({ uniqueDataFieldId: 0, name: "--select--" })
      setEquipmentColumns(filterItems);
      setValue("firstRowDataFieldId",0);
      setValue("secondRowDataFieldId",0);
      setValue("thirdRowDataFieldId",0);
    }
  }, [generalProperties])

  const formSubmitHandler = () => {
    addUserForm?.current?.requestSubmit();
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && (
        <span className="tooltip-text">
          <BsExclamationCircleFill />
          {errors[name].message}
        </span>
      )
    );
  };

  const onAddFormSubmit = (data: any) => {
    let searchCriteria: any = [];
    
    if (data?.firstRowDataFieldId) {
      const filtered = equipmentColumns?.filter((item) => item?.uniqueDataFieldId === data.firstRowDataFieldId);     
      searchCriteria.push({
          condition: null,
          uniqueDataFieldId: data.firstRowDataFieldId,
          dataFieldName: filtered[0]?.name,
          fieldValue: data.firstRowFieldValue,
          operator: "Equal to",
          conditionIndex: 0,
          startGroup: null,
          endGroup: null
        })
    }
    if (data?.secondRowDataFieldId) {
      const filtered = equipmentColumns?.filter((item) => item?.uniqueDataFieldId === data.secondRowDataFieldId);      
      searchCriteria.push({
        condition: "ÄND",
        uniqueDataFieldId: data.secondRowDataFieldId,
        dataFieldName: filtered[0]?.name,
        fieldValue: data.secondRowFieldValue,
        operator: "Equal to",
        conditionIndex: 1,
        startGroup: null,
        endGroup: null
      });
    }
    if (data?.thirdRowDataFieldId) {
      const filtered = equipmentColumns?.filter((item) => item?.uniqueDataFieldId === data.thirdRowDataFieldId);      
      searchCriteria.push({
        condition: "ÄND",
        uniqueDataFieldId: data.thirdRowDataFieldId,
        dataFieldName: filtered[0]?.name,
        fieldValue: data.thirdRowFieldValue,
        operator: "Equal to",
        conditionIndex: 2,
        startGroup: null,
        endGroup: null
      });
    }
    const body = {
      uniqueSystemId: selectedTask?.system?.uniqueSystemId,
      uniqueEquipmentClassId: uniqueEquipmentClassId === "All" ? null : uniqueEquipmentClassId,
      inspectionTaskStatus: selectedTask?.status,
      uniquePanelId: uniquePanelId === "All" ? null : uniquePanelId,
      searchCriteria: searchCriteria,
      testDateFrom: data.plannedStartDate ? moment(data.plannedStartDate).format("DD MMM YYYY") : null,
      testDateTo: data.plannedEndDate ? moment(data.plannedEndDate).format("DD MMM YYYY") : null,
      showUntestedEquipments: checkedItem.showUntestedEquipments,
      showWithAttachmentsOnly: checkedItem.showWithAttachmentsOnly,
      isFromXMLImported: checkedItem.isFromXMLImported,
      isFromManuallyAdded: checkedItem.isFromManuallyAdded,
      filterType: "FILTER"
    }
    onSearched(body)
  };

  const onReset = () => {
    reset();
    setCheckedItem({
      "showUntestedEquipments": false,
      "showWithAttachmentsOnly": false,
      "isFromXMLImported": true,
      "isFromManuallyAdded": true
    });
  }

  const renderFooter = () => {
    return (
      <>
        <Button
          label="Clear"
          onClick={() => onReset()}
          className="cancel btn"
        />
        <Button label="Go" className="update btn" autoFocus onClick={formSubmitHandler} />
      </>
    );
  };

  const onError = (errors: any, e: any) => { };

  return (
    <>
      <Dialog
        header="Filter"
        visible={showModal}
        style={{ width: "48vw" }}
        onHide={() => setShowModal(false)}
        footer={renderFooter()}
        className="display-settings personal selectsite"
      >
        <form
          onSubmit={handleSubmit(onAddFormSubmit, onError)}
          ref={addUserForm}
        >
          <div className="filter-field d-flex">
            <div className="left-field">
              <div className="field-left d-flex">
                <div className="dropdown-field">
                  <div className="row px-2">
                    <div className="field col-12 md:col-2 mb-0">
                      <span className="p-float-label">
                        <Controller
                          name="plannedStartDate"
                          control={control}
                          render={({ field, fieldState }) => (
                            <Calendar
                              id="plannedStartDate"
                              className={classNames('w-100 error-tooltip p-0', { 'error': fieldState.invalid })}
                              dateFormat="dd M yy"
                              {...field}
                              value={field.value}
                              onChange={(e) => field.onChange(e.value)} />
                          )} />
                        <label htmlFor="calendar">Test Date from</label>
                        {getFormErrorMessage('plannedStartDate')}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="value-field">
                  <div className="row px-2">
                    <div className="field col-12 md:col-2 mb-0">
                      <span className="p-float-label">
                        <Controller
                          name="plannedEndDate"
                          control={control}
                          render={({ field, fieldState }) => (
                            <Calendar
                              id="plannedEndDate"
                              className={classNames('w-100 error-tooltip p-0', { 'error': fieldState.invalid })}
                              dateFormat="dd M yy"
                              {...field}
                              value={field.value}
                              onChange={(e) => field.onChange(e.value)} />
                          )
                          } />
                        <label htmlFor="calendar">Test Date to</label>
                        {getFormErrorMessage('plannedEndDate')}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="field-left d-flex">
                <div className="dropdown-field">
                  <div className="row px-2">
                    <div className="field col-12 md:col-2 mb-0">
                      <span className="p-float-label">
                        <Controller
                          name="firstRowDataFieldId"
                          control={control}
                          render={({ field, fieldState }) => (
                            <Dropdown
                              inputId="firstRowDataFieldId"
                              className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })}
                              {...field}
                              value={field.value}
                              onChange={(e) => field.onChange(e.value)}
                              options={equipmentColumns}
                              optionLabel="name"
                              optionValue="uniqueDataFieldId"
                            />

                          )} />
                        <label htmlFor="dropdown">Data Field</label>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="value-field">
                  <div className="row px-2">
                    <div className="field col-12 md:col-2 mb-0">
                      <span className="p-float-label">
                        <Controller
                          name="firstRowFieldValue"
                          control={control}
                          rules={{
                            required: watch("firstRowDataFieldId") ? 'This field is required.' : ''
                          }}

                          render={({ field, fieldState }) => (
                            <InputText
                              id="firstRowFieldValue"
                              disabled={watch("firstRowDataFieldId") ? false : true}
                              className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })}
                              {...field}
                            />
                          )} />
                        <label htmlFor="inputtext">Select Value</label>
                        {getFormErrorMessage('firstRowFieldValue')}
                      </span>
                    </div>
                  </div>
                </div>
              </div>    <div className="field-left d-flex">
                <div className="dropdown-field">
                  <div className="row px-2">
                    <div className="field col-12 md:col-2 mb-0">
                      <span className="p-float-label">
                        <Controller
                          name="secondRowDataFieldId"
                          control={control}
                          render={({ field, fieldState }) => (
                            <Dropdown
                              inputId="secondRowDataFieldId"
                              className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })}
                              {...field}
                              value={field.value}
                              disabled={watch("firstRowDataFieldId") ? false : true}
                              onChange={(e) => field.onChange(e.value)}
                              options={equipmentColumns}
                              optionLabel="name"
                              optionValue="uniqueDataFieldId"
                            />

                          )} />
                        <label htmlFor="dropdown">Data Field</label>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="value-field">
                  <div className="row px-2">
                    <div className="field col-12 md:col-2 mb-0">
                      <span className="p-float-label">
                        <Controller
                          name="secondRowFieldValue"
                          control={control}
                          rules={{
                            required: watch("secondRowDataFieldId") ? 'This field is required.' : ''
                          }}

                          render={({ field, fieldState }) => (
                            <InputText
                              id="secondRowFieldValue"
                              disabled={watch("secondRowDataFieldId") ? false : true}
                              className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })}
                              {...field}
                            />
                          )} />
                        <label htmlFor="inputtext">Select Value</label>
                        {getFormErrorMessage('secondRowFieldValue')}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="field-left d-flex">
                <div className="dropdown-field">
                  <div className="row px-2">
                    <div className="field col-12 md:col-2 mb-0">
                      <span className="p-float-label">
                        <Controller
                          name="thirdRowDataFieldId"
                          control={control}
                          render={({ field, fieldState }) => (
                            <Dropdown
                              inputId="thirdRowDataFieldId"
                              className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })}
                              {...field}
                              value={field.value}
                              disabled={watch("secondRowDataFieldId") ? false : true}
                              onChange={(e) => field.onChange(e.value)}
                              options={equipmentColumns}
                              optionLabel="name"
                              optionValue="uniqueDataFieldId"
                            />

                          )} />
                        <label htmlFor="dropdown">Data Field</label>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="value-field">
                  <div className="row px-2">
                    <div className="field col-12 md:col-2 mb-0">
                      <span className="p-float-label">
                        <Controller
                          name="thirdRowFieldValue"
                          control={control}
                          rules={{
                            required: watch("thirdRowDataFieldId") ? 'This field is required.' : ''
                          }}

                          render={({ field, fieldState }) => (
                            <InputText
                              id="thirdRowFieldValue"
                              disabled={watch("thirdRowDataFieldId") ? false : true}
                              className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })}
                              {...field}
                            />
                          )} />
                        <label htmlFor="inputtext">Select Value</label>
                        {getFormErrorMessage('thirdRowFieldValue')}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="right-field">
              <div className="field-checkbox px-2 pt-2">
                <Checkbox
                  inputId="binary"
                  checked={checkedItem.showUntestedEquipments}
                  onChange={(e) => setCheckedItem((prev) => ({ ...prev, showUntestedEquipments: e.checked }))}
                />
                <label className="mandatory" htmlFor="binary">
                  Show only untested Equipment
                </label>
              </div>
              <div className="field-checkbox px-2 pt-2">
                <Checkbox
                  inputId="binary"
                  checked={checkedItem.showWithAttachmentsOnly}
                  onChange={(e) => setCheckedItem((prev) => ({ ...prev, showWithAttachmentsOnly: e.checked }))}
                />
                <label className="mandatory" htmlFor="binary">
                  Attachments only
                </label>
              </div>
              <div className="field-checkbox px-2 pt-2">
                <Checkbox
                  inputId="binary"
                  checked={checkedItem.isFromXMLImported}
                  onChange={(e) => setCheckedItem((prev) => ({ ...prev, isFromXMLImported: e.checked }))}
                />
                <label className="mandatory" htmlFor="binary">
                  XML Imported
                </label>
              </div>
              <div className="field-checkbox px-2 pt-2">
                <Checkbox
                  inputId="binary"
                  checked={checkedItem.isFromManuallyAdded}
                  onChange={(e) => setCheckedItem((prev) => ({ ...prev, isFromManuallyAdded: e.checked }))}
                />
                <label className="mandatory" htmlFor="binary">
                  Manually added
                </label>
              </div>
            </div>
          </div>
        </form>
      </Dialog>
    </>
  );
};

export default FilterEquipment;
