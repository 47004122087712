import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { zlink, baseURL } from "../../../../service/HttpService"
import { AppThunk } from "../../../store";

export interface ContractState {
	errors: any;
	contractList: any;
	contractPageDetails: any;
	customerData: any;
	salesOfficeData: any;
	contractDetails: any;
	contractInUse: any;
	contractSearchId: any;
	contractSiteDetails: any;
	updatedContractSiteDetails: any;
	newContractData: any;
	checkContractClose: any;
}

let initialState: ContractState = {
	errors: "",
	contractList: [],
	contractPageDetails: [],
	customerData: [],
	salesOfficeData: [],
	contractDetails: [],
	contractInUse: "",
	contractSearchId: "",
	contractSiteDetails: "",
	updatedContractSiteDetails: "",
	newContractData: "",
	checkContractClose: "",

};

const ContractSlice = createSlice({
	name: "contract",
	initialState,
	reducers: {
		setErrors: (state, { payload }: PayloadAction<any>) => {
			state.errors = payload;
		},
		setContractList: (state, { payload }: PayloadAction<any>) => {
			payload.map((element: any) => {
				element.endDate = moment(element.endDate).format("YYYY-MM-DD").toString()
				element.startDate = moment(element.startDate).format("YYYY-MM-DD").toString()
				return null;
			});
			state.contractList = payload;
		},
		setContractPageDetails: (state, { payload }: PayloadAction<any>) => {
			state.contractPageDetails = payload;
		},
		setNewContractData: (state, { payload }: PayloadAction<any>) => {
			if (payload) {
				payload.endDate = moment(payload.endDate).format("YYYY-MM-DD").toString()
				payload.startDate = moment(payload.startDate).format("YYYY-MM-DD").toString()
				state.contractList.unshift(payload);
			}
			state.newContractData = payload;
		},
		setCustomerLookup: (state, { payload }: PayloadAction<any>) => {
			state.customerData = payload;
		},
		setSalesOfficeLookup: (state, { payload }: PayloadAction<any>) => {
			state.salesOfficeData = payload;
		},
		setContractDetails: (state, { payload }: PayloadAction<any>) => {
			state.contractDetails = payload;
		},
		setUpdatedContractData: (state, { payload }: PayloadAction<any>) => {
			payload.endDate = moment(payload.endDate).format("YYYY-MM-DD").toString()
			payload.startDate = moment(payload.startDate).format("YYYY-MM-DD").toString()
			let currentIndex = state.contractList.findIndex((item: any) => item.uniqueContractId === payload?.uniqueContractId);
			state.contractList = state.contractList.filter((item: any) => item.uniqueContractId !== payload?.uniqueContractId);
			state.contractList.splice(currentIndex, 0, payload);
		},
		setDeletedContractList(state, { payload }: PayloadAction<any>) {
			state.contractList = state.contractList.filter((item: any) => item.uniqueContractId !== payload);
		},
		setContractInUse: (state, { payload }: PayloadAction<any>) => {
			state.contractInUse = payload;
		},
		setContractSearchId: (state, { payload }: PayloadAction<any>) => {
			state.contractSearchId = payload;
		},
		setContractSiteDetails: (state, { payload }: PayloadAction<any>) => {
			state.contractSiteDetails = payload;
		},
		setUpdatedContractSiteDetails: (state, { payload }: PayloadAction<any>) => {
			state.updatedContractSiteDetails = payload;
		},
		setCheckContractClose: (state, { payload }: PayloadAction<any>) => {
			state.checkContractClose = payload;
		}

	}
})

export const getContractList = (setShowContractGrid, currentStart, keyword: any, sortField: any, sortOrder: any): AppThunk => async (dispatch) => {
	try {
		let requestUrl = `${baseURL}api/v1/inspection/contract/contractlist`;
		if (currentStart) {
			requestUrl += `?currentstart=${currentStart}`;
		}
		if (keyword) {
			requestUrl += `&keyword=${keyword}`;
		}
		if (sortField && sortOrder) {
			requestUrl += `&sortcolumn=${sortField}&sortorder=${sortOrder}`;
		}
		const res = await zlink.get(`${requestUrl}`);
		if (res.status === "done") {
			if (res.data) {
				dispatch(setContractList(res?.data?.contractDetails));
				dispatch(setContractPageDetails(res?.data?.pageDetails));
				setShowContractGrid(true);
			}
		} else {
			dispatch(setErrors(res.error));
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const getContractSearchList = (setShowContractGrid, currentStart, uniqueContractId): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.get(`${baseURL}api/v1/common/search/contract/${uniqueContractId}/contractlist?currentStart=${currentStart}`);
		if (res.status === "done") {
			dispatch(setContractList(res?.data?.contractDetails));
			dispatch(setContractPageDetails(res?.data?.pageDetails));
			dispatch(setContractSearchId(""))
			setShowContractGrid(true);
		} else {
			dispatch(setErrors(res.error));
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const onAddContract =
(body: any, setMessage, setDisplayInfoModal, setShowAddModal, setShowContractGrid?, inspectionDataFlag: boolean = false , iscopymove: boolean = false): AppThunk =>
		async (dispatch) => {
			try {
				const res = await zlink.post(`${baseURL}api/v1/inspection/contract/add?isCopyMove=${iscopymove}`, body);
				if (res.status === "done") {
					dispatch(setNewContractData(res.data));
					if (setShowContractGrid)
						setShowContractGrid(true);
					if(!inspectionDataFlag) {
						setMessage("Contract added");
						setDisplayInfoModal(true);
						setShowAddModal(false);
					}
				} else {
					dispatch(setErrors(res.error));
					if (setShowContractGrid)
						setShowContractGrid(true);
					setMessage(res.message);
					setDisplayInfoModal(true);
				}
			} catch (error) {
				if (setShowContractGrid)
					setShowContractGrid(true);
				dispatch(setErrors(error));
			}
		};

export const getCustomerLookup = (body): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.post(`${baseURL}api/v1/inspection/contract/add/customerlist`, body);
		if (res.status === "done") {
			dispatch(setCustomerLookup(res?.data?.customerDetails));
		} else {
			dispatch(setErrors(res.error));
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const getSalesOfficeLookup = (): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.get(`${baseURL}api/v1/inspection/contract/add/salesofficelist`);
		if (res.status === "done") {
			dispatch(setSalesOfficeLookup(res?.data?.salesOfficeDetails));
		} else {
			dispatch(setErrors(res.error));
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const getContractDetails = (uniqueContractId): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.get(`${baseURL}api/v1/inspection/contract/${uniqueContractId}`);
		if (res.status === "done") {
			dispatch(setContractDetails(res.data));
		} else {
			dispatch(setErrors(res.error));
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const onEditContract =
	(body: any, uniqueContractId, setMessage, setDisplayInfoModal, setShowEditModal, setSelectedRows?): AppThunk =>
		async (dispatch) => {
			try {
				const res = await zlink.post(`${baseURL}api/v1/inspection/contract/${uniqueContractId}/update`, body);
				if (res.status === "done") {
					dispatch(setUpdatedContractData(res.data));
					if (setSelectedRows)
						setSelectedRows([res.data]);
					setMessage("Contract updated");
					setShowEditModal(false);
					setDisplayInfoModal(true);
				} else {
					dispatch(setErrors(res.error));
					setMessage(res.message);
					setDisplayInfoModal(true);
				}
			} catch (error) {
				dispatch(setErrors(error));
			}
		};

export const deleteContractData = ({ uniqueContractId, setSelectedRows, setMessage, setDisplayInfoModal }): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.post(`${baseURL}api/v1/inspection/contract/${uniqueContractId}/remove`, null);
		if (res.status === "done") {
			dispatch(setDeletedContractList(uniqueContractId));
			setSelectedRows([]);
			setMessage(res.message);
			setDisplayInfoModal(true);
		} else {
			setSelectedRows([]);
			setMessage(res.message);
			setDisplayInfoModal(true);
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const checkContractInUse = (uniqueContractId): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.post(`${baseURL}api/v1/inspection/contracts/${uniqueContractId}/checkcontractinuse`, null);
		if (res.status === "done") {
			dispatch(setContractInUse(res.data));
		} else {
			dispatch(setErrors(res.error));
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const getContractSiteDetails = (uniqueContractId): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.get(`${baseURL}api/v1/inspection/contracts/${uniqueContractId}/sites`);
		if (res.status === "done") {
			dispatch(setContractSiteDetails(res?.data));
		} else {
			dispatch(setErrors(res.error));
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const updateContractSiteMapping = (uniqueContractId, body: any, setDisplayInfoModal, setMessage, activeSite?: any, setSelectedRows?: any): AppThunk =>
	async (dispatch) => {
		try {
			const res = await zlink.post(
				`${baseURL}api/v1/inspection/contracts/${uniqueContractId}/sites `, body
			);
			if (res.status === "done") {
				dispatch(setUpdatedContractSiteDetails(res));
				dispatch(setContractSiteDetails(""));
				if (!(body.includes(activeSite?.uniqueSiteId))) {
					setSelectedRows([]);
					dispatch(setDeletedContractList(uniqueContractId));
				}
			} else {
				setMessage(res.message);
				setDisplayInfoModal(true);
			}
		} catch (error) {
			dispatch(setErrors(error));
		}
	};

export const checkContractForClose = (uniqueContractId: any): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.get(`${baseURL}api/v1/inspection/contracts/${uniqueContractId}/checkcontractforclose`);
		if (res.status === "done") {
			dispatch(setCheckContractClose(res.data));
		} else {
			dispatch(setErrors(res.error));
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const onCloseContract =
	(uniqueContractId: any, setSelectedRows, setMessage, setDisplayInfoModal): AppThunk =>
		async (dispatch) => {
			try {
				const res = await zlink.post(`${baseURL}api/v1/inspection/contracts/${uniqueContractId}/close`, null);
				if (res.status === "done") {
					dispatch(setUpdatedContractData(res.data));
					setSelectedRows([res.data]);
					setMessage("Contract closed");
					setDisplayInfoModal(true);
				} else {
					dispatch(setErrors(res.error));
					setMessage(res.message);
					setDisplayInfoModal(true);
				}
			} catch (error) {
				dispatch(setErrors(error));
			}
		};

export const onActivateContract =
	(uniqueContractId: any, setSelectedRows, setMessage, setDisplayInfoModal): AppThunk =>
		async (dispatch) => {
			try {
				const res = await zlink.post(`${baseURL}api/v1/inspection/contracts/${uniqueContractId}/activate`, null);
				if (res.status === "done") {
					dispatch(setUpdatedContractData(res.data));
					setSelectedRows([res.data]);
					setMessage("Contract activated");
					setDisplayInfoModal(true);
				} else {
					dispatch(setErrors(res.error));
					setMessage(res.message);
					setDisplayInfoModal(true);
				}
			} catch (error) {
				dispatch(setErrors(error));
			}
		};
	export const onSiteCustomerMapping =
	(uniqueCustomerId:any,uniqueSiteIds:any): AppThunk =>
		async (dispatch) => {
			try {
				const res = await zlink.post(`${baseURL}api/v1/admin/customer/${uniqueCustomerId}/mapsite/updatesite`, uniqueSiteIds);
				if (res.status === "done") {
					
				} else {
					dispatch(setErrors(res.error));
				}
			} catch (error) {
				dispatch(setErrors(error));
			}
		};

export const { setErrors,
	setContractList,
	setContractPageDetails,
	setNewContractData,
	setCustomerLookup,
	setSalesOfficeLookup,
	setContractDetails,
	setUpdatedContractData,
	setDeletedContractList,
	setContractInUse,
	setContractSearchId,
	setContractSiteDetails,
	setUpdatedContractSiteDetails,
	setCheckContractClose,
} = ContractSlice.actions;
export default ContractSlice.reducer;
