import React, { useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { BsExclamationCircleFill, BsPlusLg } from "react-icons/bs";
import { InputTextarea } from "primereact/inputtextarea";
import { classNames } from "primereact/utils";
import { useDispatch } from "react-redux";
import { getDrawingDetails, setDrawingDetails } from "redux/pages/AsBuilts/AsBuiltsSlice";
import { useAppSelector } from "redux/hooks";

interface Props {
    showModal: boolean;
    setShowModal: (state: boolean) => void;
    selectedRows: any;
    selectedBuilding: any;
    selectedSite: any;
    onEditDrawing: (data: any) => void;
}

type FormData = {
    description: string;
};

export const EditAsBuilts: React.FC<Props> = ({ 
    showModal = false,
    setShowModal = () => { },
    selectedRows = [],
    selectedBuilding = [],
    selectedSite = [],
    onEditDrawing = () => { },
}) => {
    const editForm: any = useRef();
    

    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<FormData>({
        defaultValues: {
            description: "",
        },
    });

    const drawingDetails = useAppSelector((state) => state.asbuilts.drawingDetails);

    const dispatch = useDispatch<any>();


    useEffect(() => {
        let body = {
            revisionNo: selectedRows[0]?.revisions
        };
        dispatch(getDrawingDetails(selectedSite?.uniqueSiteId, selectedBuilding.id, selectedRows[0]?.drawingId, body))
    }, [])

    useEffect(() => {
        if (drawingDetails) {
            setValue("description", drawingDetails.description)
        }
    }, [drawingDetails])


    const formSubmitHandler = (data: any) => {
        editForm.current.requestSubmit();
    };

    const onEditFormSubmit = (data: any) => {
        let payload = {
            drawingId: drawingDetails?.drawingId,
            description: data.description.trim(),
            revisionNo: drawingDetails?.revisionNo
        }
        onEditDrawing && onEditDrawing(payload);
    }

    const getFormErrorMessage = (name) => {
        return (
            errors[name] && (
                <span className="tooltip-text">
                    <BsExclamationCircleFill />
                    {errors[name].message}
                </span>
            )
        );
    };

    const renderFooter = () => {
        return (
            <>
                <Button label="Cancel" className="cancel btn" autoFocus onClick={() => setShowModal(false)}/>
                <Button label="Save Changes" className="Upload btn" autoFocus onClick={formSubmitHandler}/>
            </>
        );
    };

    const onError = (errors: any, e: any) => {};

    const hideDialogHandler = () => {
        dispatch(setDrawingDetails(""));
        setShowModal(false);
    }

    return (
        <>
            <Dialog
                header="Edit Drawing"
                visible={showModal}
                style={{ width: "32vw" }}
                onHide={() => hideDialogHandler()}
                footer={renderFooter()}
                className={`s-modal`}
            >
                <form
                    onSubmit={handleSubmit(onEditFormSubmit, onError)}
                    ref={editForm}
                >
                <div className="row">
                    <div className="d-flex align-items-center px-0">
                        <div className="field col-4">
                            <span className="p-float-label text-container">
                                <label htmlFor="inputtext">Site</label>
                            </span>
                        </div>
                        <div className="field col-8">
                            <span className="p-float-label text-container">
                                <span className="text-value">{drawingDetails?.siteName}</span>
                            </span>
                        </div>
                    </div>
                    <div className="d-flex align-items-center px-0">
                        <div className="field col-4">
                            <span className="p-float-label text-container">
                                <label>Building</label>
                            </span>
                        </div>
                        <div className="field col-8">
                            <span className="p-float-label text-container">
                                <span className="text-value">{drawingDetails?.buildingName}</span>
                            </span>
                        </div>
                    </div>
                    <div className="d-flex align-items-center px-0">
                        <div className="field col-4">
                            <span className="p-float-label text-container">
                                <label htmlFor="inputtext">Floor</label>
                            </span>
                        </div>
                        <div className="field col-8">
                            <span className="p-float-label text-container">
                                <span className="text-value">{drawingDetails?.floorName}</span>
                            </span>
                        </div>
                    </div>
                    <div className="d-flex align-items-center px-0">
                        <div className="field col-4">
                            <span className="p-float-label text-container">
                                <label htmlFor="inputtext">File Name</label>
                            </span>
                        </div>
                        <div className="field col-8">
                            <span className="p-float-label text-container">
                                <span className="text-value">{drawingDetails?.dwgFileName}</span>
                            </span>
                        </div>
                    </div>
                    <div className="d-flex align-items-center px-0">
                        <div className="field col-4">
                            <span className="p-float-label text-container">
                                <label htmlFor="inputtext">Category</label>
                            </span>
                        </div>
                        <div className="field col-8">
                            <span className="p-float-label text-container">
                                <span className="text-value">{drawingDetails?.categoryName}</span>
                            </span>
                        </div>
                    </div>
                    <div className="d-flex align-items-center px-0">
                        <div className="field col-4">
                            <span className="p-float-label text-container">
                                <label htmlFor="inputtext">	Drawing Type</label>
                            </span>
                        </div>
                        <div className="field col-8">
                            <span className="p-float-label text-container">
                                <span className="text-value">{drawingDetails?.typeName}</span>
                            </span>
                        </div>
                    </div>
                    <div className="d-flex align-items-center px-0">
                        <div className="field col-4">
                            <span className="p-float-label text-container">
                                <label htmlFor="inputtext">Revision No.</label>
                            </span>
                        </div>
                        <div className="field col-8">
                            <span className="p-float-label text-container">
                                <span className="text-value">{drawingDetails?.revisionNo}</span>
                            </span>
                        </div>
                    </div>
                    
                    <div className="field col-12">
                        <span className="p-float-label">
                            <Controller
                                name="description"
                                control={control}
                                rules={{
                                    required: 'Description is required.',
                                    validate: (value) => {
                                        return value.trim().length <= 0
                                            ? "Description is required." : true
                                    },
                                }}
                                render={({ field, fieldState }) => (
                                    <InputTextarea
                                        id="Textarea"
                                        className={classNames("w-100 error-tooltip", { error: fieldState.invalid })}
                                        maxLength={4000}
                                        {...field}
                                    />
                                )}
                            />
                            <label className='mandatory' htmlFor="inputtext">Description</label>
                            {getFormErrorMessage("description")}
                        </span>
                        <span className="textarea-txt-count">Max 4000 Chars</span>
                    </div>
                </div>
                </form>
            </Dialog>
        </>
    );
};

export default EditAsBuilts;