import React from "react";
import { Button } from "primereact/button";
import { BsFillPencilFill, BsPlusLg, BsTrashFill } from "react-icons/bs";
import { FaCog, FaDownload } from "react-icons/fa";

const HealthClassificationsDocument = () => {
  return (
    <>
      <h4 className="doc-title mb-2">
        Health Classifications
      </h4>

      <p>
        Equipment in a Fire & Life Safety System is classified under a standard Health Classification 
        which is mapped against its Equipment Type. This option is used to add/edit/delete Health 
        Classification for an Equipment Type based on the selected Country. It helps the user to identify the 
        health classification of each equipment and to ensure that the equipment meet the required standards. 
        The Equipment Types listed are AHU, Air Sampling Systems, Devices, Doors, Elevators, Emergency 
        Communications, Emergency Lightings, ERCES, Fire Pumps, Gaseous Extinguishing Systems, Kitchen and 
        Dry Chemical Ext Systems, Modules, NAC Panels, Portable Extinguishers, Sprinklers and 
        Water Based Equipment.
      </p>

      <p>
        <span className="doc-subtitle"><b>Add:</b></span>
      </p>
      <p> 
        A Health Classification can be added using the <span className="doc-subtitle">Add</span> {" "}
        <Button className="doc-button-icon"> 
          <BsPlusLg className="icon" title="Add"/> 
        </Button> {" "} icon. Enter a valid Health Classification and select Equipment Type by selecting the 
        required check boxes displayed against it. Click Save button to save newly added Health Classification.
      </p>

      <p>
        <span className="doc-subtitle"><b>Edit:</b></span>
      </p>
      <p>
        A Health Classifications can be edited by selecting it and then clicking the 
        <span className="doc-subtitle"> Edit</span> {' '}
        <Button className="doc-button-icon"> 
          <BsFillPencilFill className="icon" title="Edit"/> 
        </Button> {' '} icon. After making the required changes 
        click the Save Changes button to save the changes.
      </p>

      <p>
        <span className="doc-subtitle"><b>Delete:</b></span>
      </p>
      <p>
        A Health Classification can be deleted using the <span className="doc-subtitle">Delete</span> {" "}
        <Button className="doc-button-icon"> 
          <BsTrashFill className="icon" title="Delete"/> 
        </Button>{" "} icon. 
        If the selected Health Classification is in use, it cannot be deleted.
      </p>

      <p>
        <span className="doc-subtitle"><b>Download:</b></span>{" "}
        <Button className="doc-button-icon">
          <FaDownload className="icon" title="Download"/>
        </Button>{" "}
      </p>
      <p>
        This option lists Report All, Report Highlighted, Export All, Export Highlighted and Group Highlighted.
      </p>

      <p>
        <span className="doc-subtitle">Report All: </span>
      </p>

      <p>
        This option is for generating a report with all the records in the data grid. 
        The report can be saved to PDF, Text, Rich Text or Excel format by selecting the file format from 
        the list under Save option, and then clicking OK button after selecting the required options from the 
        Export Settings window.
      </p>

      <p>
        <span className="doc-subtitle">Report Highlighted: </span>
      </p>
      <p>
        This option is for generating a report with only selected records in the grid. 
        Select the desired rows by holding down the control key and clicking on each of the records to be 
        selected. Users can also select a set of rows using the shift key. 
        The selected records will be highlighted. Click on the <span className="doc-subtitle">Report Highlighted </span> 
        option in the <span className="doc-subtitle">Download </span> {" "}
        <Button className="doc-button-icon">
          <FaDownload className="icon" title="Download" />
        </Button>{" "} menu to generate the report. 
        The report will be created with the highlighted records only. The report can be saved to PDF, Text, 
        Rich Text or Excel format by selecting the file format from the list under Save option, and then clicking 
        OK button after selecting the required options from the Export Settings window.
      </p>


      <p>
        <span className="doc-subtitle"><b>More:</b></span> {' '}
        <Button className="doc-button-icon">
          <FaCog className="icon" title="More"/>
        </Button>{" "}
      </p>
      <p>
        This option lists Display Settings option. 
      </p>

      <p>
        <span className="doc-subtitle">Display Settings:</span>
      </p>
      <p>
        This option allows the user to change the visibility of the data fields displayed in the data grid. 
        On selecting this option, all the available data fields are displayed in a window. 
        Display settings can be modified by selecting the required fields from the list. 
        The grid settings modified through this option will not be saved and will be effective only for the 
        current instance of the data grid. 
      </p>

    </>
  );
}
export default HealthClassificationsDocument;