import React, { memo, useEffect, useState } from 'react'
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';

interface ActivityModalType {
  showModal: boolean;
  onHide: (selection: any) => void;
  activities?: any;
  selectedActivities?: any;
}
const ActivityModal: React.FC<ActivityModalType> = (props) => {
  const { showModal, onHide = () => { }, activities, selectedActivities } = props;
  const [selection, setSelection] = useState(null)

  useEffect(() => {
    if (selectedActivities) {
      setSelection(selectedActivities);
    }
  }, [selectedActivities])

  return (
    <Dialog
      showHeader={false}
      visible={showModal}
      onHide={() => onHide(selection)}
      className="logbook-activity"
    >
      <div className="custom-header">
        <div className="row">
          <div className="col-6 box py-1 pr-0 pl-1">
            <div className="title">Activities</div>
          </div>

          <div className="col-6 box py-1 pl-0 align-items-end pr-1">
            <i className="pi pi-times" onClick={() => onHide(selection)}></i>
          </div>
        </div>
      </div>
      <div className='custom-table'>
        <DataTable
          value={activities}
          selection={selection}
          onSelectionChange={e => setSelection(e.value)}
          dataKey="eventId"
          responsiveLayout="scroll">
          <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>
          <Column field="eventName" header="Event Name"></Column>
        </DataTable>
      </div>
    </Dialog>
  )
}

export default memo(ActivityModal);