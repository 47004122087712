import React, { useCallback, useEffect, useState } from "react";

import { Dialog } from "primereact/dialog";
import { OverlayPanel } from "primereact/overlaypanel";
import PopOver from "components/common/PopOver/PopOver";
import InformationBox from "components/common/Message/InformationBox/InformationBox";
import ConfirmationBox from "components/common/Message/ConfirmationBox/ConfirmationBox";
import Table from "components/Table/Table";
import { CUSTOMIZATION_EQUIPMENT_TYPE_ATTRIBUTES_VALUES_COLS } from "../EquipmentTypeAttributesValuesData";
import AddEquipmentTypeAttributeValues from "../ManageEquipmentTypeAttributeValues/AddEquipmentTypeAttributeValues";
import EditEquipmentTypeAttributeValues from "../ManageEquipmentTypeAttributeValues/EditEquipmentTypeAttributeValues";

import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../redux/hooks";
import { getEquipmentTypeAttributeValuesList, onAddEquipmentTypeAttributeValue, onEditEquipmentTypeAttributeValue, deleteEquipmentTypeAttributeValue } from "../../../../redux/pages/Manage/Customization/Attributes/EquipmentTypeAttributeValueSlice";
import { checkIsAttributeValueInUse, setIsAttributeValueInUse } from "../../../../redux/pages/Manage/Customization/Attributes/CommonAttributeSlice";
import CustomizationReport from "../Reports/CustomizationReports";
import { CUSTOMIZATION_ATTRIBUTES_TYPE } from "constants/common";
import { onExportReport } from "redux/common/report/customizeReportSlice";

interface Props {
    showModal: boolean;
    setShowModal: (state: boolean) => void;
    rowsSelected: any;
    equipmentTypeId: any;
    equipmentTypeName: any;
}

export const EquipmentTypeAttributesFieldValues: React.FC<Props> = ({
    showModal = false,
    setShowModal = () => { },
    rowsSelected = [],
    equipmentTypeId = "",
    equipmentTypeName = "",
}) => {

    const dispatch = useDispatch<any>();
    const equipmentTypeAttributeValueList = useAppSelector((state) => state.equipmentTypeAttributeValue.equipmentTypeAttributeValueList);
    const equipmentTypeAttributeValuePageDetails = useAppSelector((state) => state.equipmentTypeAttributeValue.equipmentTypeAttributeValuePageDetails);
    const newEquipmentTypeAttributeValue = useAppSelector((state) => state.equipmentTypeAttributeValue.newEquipmentTypeAttributeValue);
    const isAttributeValueInUse = useAppSelector((state) => state.commonAttribute.isAttributeValueInUse);
    
    const downloadRef = React.createRef<OverlayPanel>();
    
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [pageIndex, setPageIndex] = useState(0);
    const [selectedRows, setSelectedRows] = useState<any>([]);
    const [isAttributeDelete, setIsAttributeDelete] = useState(false);
    const [message, setMessage] = useState("");
    const [displayInfoModal, setDisplayInfoModal] = useState(false);
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
    const [isEquipmentTypeAttributeValueAdd, setIsEquipmentTypeAttributeValueAdd] = useState(false);
    const [showAddEquipmentTypeAttributeValues, setShowAddEquipmentTypeAttributeValues] = useState(false);
    const [showEditEquipmentTypeAttributeValue, setShowEditEquipmentTypeAttributeValue] = useState(false);
    const [showReportModal, setShowReportModal] = useState(false);
    const [reportFlag, setReportFlag] = useState<any>('all');
    const [sortField, setSortField] = useState('')
    const [sortOrder, setSortOrder] = useState('');
    const [currentStart, setCurrentStart] = useState(1);

    useEffect(() => {
        if (rowsSelected && rowsSelected.length) {
            if (rowsSelected[0].uniqueAttributeId) {
                dispatch(getEquipmentTypeAttributeValuesList(rowsSelected[0].uniqueAttributeId, 1,"","", "", setMessage, setDisplayInfoModal, setIsLoaded));
            }
        }
    }, [rowsSelected]);

    useEffect(() => {
        if (newEquipmentTypeAttributeValue) {
          setIsEquipmentTypeAttributeValueAdd(true)
        }
    }, [newEquipmentTypeAttributeValue])

    useEffect(() => {
        if (isAttributeValueInUse && isAttributeDelete) {
          if (isAttributeValueInUse?.isAttributeValueInUse) {
            setMessage("Selected Attribute Value is in use, cannot be deleted");
            setDisplayInfoModal(true);
          } else {
            setMessage("Are you sure you want to delete the selected Attribute Value?");
            setDisplayConfirmationModal(true);
          }
          setIsAttributeDelete(false);
          dispatch(setIsAttributeValueInUse(""))
        }
    }, [isAttributeValueInUse]);

    const handleRowSelect = useCallback((data: any) => {
        setSelectedRows([...data]);
    }, []);

    const onPageChange = (options) => {
        if (options !== pageIndex) {
            setPageIndex(options);
            let currentStart = options * equipmentTypeAttributeValuePageDetails?.currentLength + 1;
            setCurrentStart(currentStart);
            dispatch(getEquipmentTypeAttributeValuesList(rowsSelected[0].uniqueAttributeId, currentStart,"","","", setMessage, setDisplayInfoModal, setIsLoaded));
        }
    }

    const onSort = (event) => {
        if (event) {
            let sortDirection: string;
            if (event.sortOrder === 1)
                sortDirection = "ASC"
            else
                sortDirection = "DESC"
            setSortField(event.sortField);
            setSortOrder(sortDirection);
            dispatch(getEquipmentTypeAttributeValuesList(rowsSelected[0].uniqueAttributeId,currentStart,"",event.sortField, sortDirection, setMessage, setDisplayInfoModal, setIsLoaded));
        }
    }

    const onAddEquipmentTypeAttributeValueClick = (data) => {
        let body = {
          attributeValue: data.Value,
        };
        dispatch(onAddEquipmentTypeAttributeValue(body, rowsSelected[0].uniqueAttributeId, setMessage, setDisplayInfoModal, setShowAddEquipmentTypeAttributeValues));
    };

    const editFieldValueHandler = () => {
        if (selectedRows && selectedRows.length) {
            if (selectedRows.length === 1) {
                if (selectedRows[0].attributeName === "Barcode") {
                    setMessage("This Attribute cannot be edited.");
                    setDisplayInfoModal(true);
                } else {
                    setShowEditEquipmentTypeAttributeValue(true)
                }
            } else {
                setMessage("This operation can be performed only one row at a time");
                setDisplayInfoModal(true);
            }
        } else {
            setMessage("Select an Attribute Value");
            setDisplayInfoModal(true);
        }
    }

    const onEditEquipmentTypeAttributeValueClick = (data) => {      
        let body = {
          attributeValue: data.Value,
        };
        dispatch(onEditEquipmentTypeAttributeValue(rowsSelected[0].uniqueAttributeId, selectedRows[0].dataFieldLookUpId, body, setMessage, setDisplayInfoModal, setShowEditEquipmentTypeAttributeValue));
    };

    const deleteFieldValueHandler = () => {
        if (selectedRows && selectedRows.length) {
            if (selectedRows.length === 1) {
                if (selectedRows[0].attributeName === "Barcode") {
                    setMessage("This Attribute cannot be deleted.");
                    setDisplayInfoModal(true);
                } else {
                    setIsAttributeDelete(true);
                    dispatch(checkIsAttributeValueInUse(rowsSelected[0].uniqueAttributeId, selectedRows[0].dataFieldLookUpId));
                }
            } else {
                setMessage("This operation can be performed only one row at a time");
                setDisplayInfoModal(true);
            }

        } else {
            setMessage("Select an Attribute Value");
            setDisplayInfoModal(true);
        }
    }

    const onDeleteConfirm = () => {
        setDisplayConfirmationModal(false);
        dispatch(deleteEquipmentTypeAttributeValue(rowsSelected[0].uniqueAttributeId, selectedRows[0].dataFieldLookUpId, setSelectedRows, setMessage, setDisplayInfoModal));
    }

    const onOkClick = () => {
        if (isEquipmentTypeAttributeValueAdd) {
            setShowAddEquipmentTypeAttributeValues(true);
            setIsEquipmentTypeAttributeValueAdd(false);
        }
    }

    const onReportAllClick = () => {
        setReportFlag('all');
        setShowReportModal(true);
    };

    const onReportHighlightedClick = () => {
        setReportFlag('highlighted');
        setShowReportModal(true);
    };

    const getSelectedValuesIds = () => {
        let params: any = [];
        selectedRows.map(item => {
            params.push(item.dataFieldLookUpId);
        });
        return params;
    };

    const onExportAll = () => {
        const reportParameters = {
            exportParameters: {
                uniqueDataFieldId: equipmentTypeId,
                uniqueAttributeId: rowsSelected[0]?.uniqueAttributeId,
                attributeName: rowsSelected[0]?.attributeName,
                equipmentTypeName: equipmentTypeName,
                selectedIds: "",
                needCommonAttribute: false,
                sortColumn: sortField,
                sortOrder: sortOrder,
                search: ''
            },
            exportType: "Equipment Type Attributes Values Report"
        };
        dispatch(onExportReport(reportParameters, "Equipment Type Attributes Values"));
    };

    const onExportHighlightedClick = () => {
        const reportParameters = {
            exportParameters: {
                uniqueDataFieldId: equipmentTypeId,
                uniqueAttributeId: rowsSelected[0]?.uniqueAttributeId,
                attributeName: rowsSelected[0]?.attributeName,
                equipmentTypeName: equipmentTypeName,
                selectedIds: getSelectedValuesIds(),
                needCommonAttribute: false,
                sortColumn: sortField,
                sortOrder: sortOrder,
                search: ''
            },
            exportType: "Equipment Type Attributes Values Report"
        };
        dispatch(onExportReport(reportParameters, "Equipment Type Attributes Values"));
    };

    return (
        <>
            <Dialog
                header="Equipment Type Attribute Values"
                visible={showModal}
                style={{ width: "50vw" }}
                onHide={() => setShowModal(false)}
                footer={false}
                className="display-settings personal selectsite"
            >
                {isLoaded ? (
                    <>
                        <div className="content-box mb-2">
                            <div className="header d-flex align-items-center">
                                <span className="title">Attribute Name:</span>
                                <span className="value">{rowsSelected[0]?.attributeName}</span>
                            </div>
                        </div>
                        <Table
                            rows={equipmentTypeAttributeValueList}
                            cols={CUSTOMIZATION_EQUIPMENT_TYPE_ATTRIBUTES_VALUES_COLS.EQUIPMENT_TYPE_ATTRIBUTES_VALUES_COLS}
                            title={``}
                            dataKeyId="dataFieldLookUpId"
                            searchboxfield={false}
                            showroweditor={false}
                            paginator
                            pageDetails={equipmentTypeAttributeValuePageDetails}
                            pageIndex={pageIndex}
                            onPageChanged={onPageChange}
                            onSortData={onSort}
                            getSelectedRowData={handleRowSelect}
                            onAddClick={() => setShowAddEquipmentTypeAttributeValues(true)}
                            onEditClick={editFieldValueHandler}
                            onDeleteClick={deleteFieldValueHandler}
                            onDownloadClick={(e) => downloadRef.current?.toggle(e)}
                            defaultSelectedRows={equipmentTypeAttributeValueList.length > 0 ? [equipmentTypeAttributeValueList[0]] : []}
                        /> 
                    </>) : (<></>)
                }

                {showAddEquipmentTypeAttributeValues ? (
                    <AddEquipmentTypeAttributeValues
                        showModal={showAddEquipmentTypeAttributeValues}
                        setShowModal={setShowAddEquipmentTypeAttributeValues}
                        equipmentAttributeName={rowsSelected[0].attributeName}
                        equipmentAttributeType={rowsSelected[0].attributeType}
                        maxLength={rowsSelected[0].maxlength}
                        onAddEquipmentTypeAttributeValueClick={onAddEquipmentTypeAttributeValueClick}
                    />
                    ) : null
                }

                {showEditEquipmentTypeAttributeValue ? (
                    <EditEquipmentTypeAttributeValues
                        showModal={showEditEquipmentTypeAttributeValue}
                        selectedRows={selectedRows}
                        equipmentAttributeId={rowsSelected[0].uniqueAttributeId}
                        equipmentAttributeName={rowsSelected[0].attributeName}
                        equipmentAttributeType={rowsSelected[0].attributeType}
                        maxLength={rowsSelected[0].maxlength}
                        setShowModal={setShowEditEquipmentTypeAttributeValue}
                        onEditEquipmentTypeAttributeValueClick={onEditEquipmentTypeAttributeValueClick}
                    />
                    ) : null
                }

                <PopOver ref={downloadRef}>
                    <ul>
                        <li onClick={onReportAllClick}>Report All</li>
                        <li onClick={onReportHighlightedClick}>Report Highlighted</li>
                        <li onClick={onExportAll}>Export All</li>
					    <li onClick={onExportHighlightedClick}>Export Highlighted</li>
                    </ul>
                </PopOver>

                <InformationBox
                    showInfoModal={displayInfoModal}
                    setShowInfoModal={setDisplayInfoModal}
                    message={message}
                    okOnClick={onOkClick}
                />

                <ConfirmationBox
                    showConfirmModal={displayConfirmationModal}
                    setShowConfirmModal={setDisplayConfirmationModal}
                    confirmAction={onDeleteConfirm}
                    message={message}
                />

                {showReportModal ? (
                    <CustomizationReport showModal={showReportModal}
                        setShowModal={setShowReportModal}
                        reportFlag={reportFlag}
                        selectedRows={selectedRows}
                        equipmentTypeId={equipmentTypeId}
                        attributeId={rowsSelected[0]?.uniqueAttributeId}
                        attributeName={rowsSelected[0]?.attributeName}
                        equipmentTypeName={equipmentTypeName}
                        selectedTab={CUSTOMIZATION_ATTRIBUTES_TYPE.EQUIPMENT_TYPE_ATTRIBUTES_VALUES}
                        sortByColumns={CUSTOMIZATION_EQUIPMENT_TYPE_ATTRIBUTES_VALUES_COLS.EQUIPMENT_TYPE_ATTRIBUTES_VALUES_COLS}
                        searchKeyword={''}
                        sortColumn={sortField}
                        sortOrder={sortOrder} />
                ) : null}

            </Dialog>
        </>
    );
};


export default EquipmentTypeAttributesFieldValues;
