import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "redux/store";
import { baseURL, zlink } from "service/HttpService";

export interface CustomizationState {
    errors: any;
    nfpaClassifications: any;
    dataEntrySettings: any;
    updatedDataEntrySettings: any;
    displaySettings: any;
    datafieldOrderList: any;
    updatedDatafieldOrder: any;
    updatedDisplaySettings: any;
}

let initialState: CustomizationState = {
    errors: "",
    nfpaClassifications: "",
    dataEntrySettings: "",
    updatedDataEntrySettings: "",
    displaySettings: "",
    datafieldOrderList: "",
    updatedDatafieldOrder: "",
    updatedDisplaySettings: "",
}

const CustomizationSlice = createSlice({
    name: "customization",
    initialState,
    reducers: {
        setErrors: (state, { payload }: PayloadAction<any>) => {
            state.errors = payload;
        },
        setNfpaClassifications: (state, { payload }: PayloadAction<any>) => {
            state.nfpaClassifications = payload;
        },
        setDataEntrySettings: (state, { payload }: PayloadAction<any>) => {
            state.dataEntrySettings = payload;
        },
        setUpdatedDataEntrySettings: (state, { payload }: PayloadAction<any>) => {
            state.updatedDataEntrySettings = payload;
        },
        setDisplaySettings: (state, { payload }: PayloadAction<any>) => {
            state.displaySettings = payload;
        },
        setDatafieldOrderList: (state, { payload }: PayloadAction<any>) => {
            state.datafieldOrderList = payload;
        },
        setUpdatedDatafieldOrder: (state, { payload }: PayloadAction<any>) => {
            state.updatedDatafieldOrder = payload;
        },
        setUpdatedDisplaySettings: (state, { payload }: PayloadAction<any>) => {
            state.updatedDisplaySettings = payload;
        },
    }
});

export const onGetNfpaClassifications = (countryId, classId): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.get(`${baseURL}api/v1/countries/${countryId}/equipmentclasses/${classId}/nfpaclassifications`);
        if (res.status === "done") {
            let nfpa = res?.data?.nfpaClassificationLookUpDetails;
            nfpa.splice(0, 0, { uniqueNFPAClassificationId: "0", nfpaClassificationName: "--Select--" });
            dispatch(setNfpaClassifications(nfpa));
        } else {
            dispatch(setErrors(res.error));
        }
    } catch (error) {
        dispatch(setErrors(error));
    }
};

export const onGetDataEntrySettings = (countryId, classId, nfpaId): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.get(`${baseURL}api/v1/countries/${countryId}/equipmentclasses/${classId}/nfpaclassifications/${nfpaId}/datafields`);
        if (res.status === "done") {
            dispatch(setDataEntrySettings(res?.data?.dataFields));
        } else {
            dispatch(setErrors(res.error));
        }
    } catch (error) {
        dispatch(setErrors(error));
    }
};

export const onDataEntrySettingsUpdate = (countryId, classId, nfpaId, body): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.post(`${baseURL}api/v1/countries/${countryId}/equipmentclasses/${classId}/nfpaclassifications/${nfpaId}/datafields`, body);
        if (res.status === "done") {
            dispatch(setUpdatedDataEntrySettings(body));
        } else {
            dispatch(setErrors(res.error));
        }
    } catch (error) {
        dispatch(setErrors(error));
    }
};

export const onGetDisplaySettings = (countryId, classId): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.get(`${baseURL}api/v1/countries/${countryId}/equipmentclasses/${classId}/displaysettings`);
        if (res.status === "done") {
            dispatch(setDisplaySettings(res?.data?.displaySettingDetails));
        } else {
            dispatch(setErrors(res.error));
        }
    } catch (error) {
        dispatch(setErrors(error));
    }
};

export const onGetDatafieldOrderList = (countryId, classId): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.get(`${baseURL}api/v1/countries/${countryId}/equipmentclasses/${classId}/displaysettings/datafieldorder`);
        if (res.status === "done") {
            dispatch(setDatafieldOrderList(res?.data?.dataFieldOrderDetails));
        } else {
            dispatch(setErrors(res.error));
        }
    } catch (error) {
        dispatch(setErrors(error));
    }
};

export const onDatafieldOrderUpdated = (countryId, classId, body): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.post(`${baseURL}api/v1/countries/${countryId}/equipmentclasses/${classId}/displaysettings/datafieldorder`, body);
        if (res.status === "done") {
            dispatch(setUpdatedDatafieldOrder(body.dataFields));
        } else {
            dispatch(setErrors(res.error));
        }
    } catch (error) {
        dispatch(setErrors(error));
    }
};

export const onDisplaySettingsUpdated = (countryId, classId, body): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.post(`${baseURL}api/v1/countries/${countryId}/equipmentclasses/${classId}/displaysettings`, body);
        if (res.status === "done") {
            dispatch(setUpdatedDisplaySettings(body.dataFields));
        } else {
            dispatch(setErrors(res.error));
        }
    } catch (error) {
        dispatch(setErrors(error));
    }
};

export const {
    setErrors,
    setNfpaClassifications,
    setDataEntrySettings,
    setUpdatedDataEntrySettings,
    setDisplaySettings,
    setDatafieldOrderList,
    setUpdatedDatafieldOrder,
    setUpdatedDisplaySettings,
} = CustomizationSlice.actions;

export default CustomizationSlice.reducer;