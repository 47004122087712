import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../../store";
import { zlink, baseURL } from "../../../service/HttpService";

export interface LogOutState {
	errors: any;
}

const initialState: LogOutState = {
	errors:"",
};

const LogOutSlice = createSlice({
	name: "logout",
	initialState,
	reducers: {
		setErrors: (state, { payload }: PayloadAction<any>) => {
			state.errors = payload;
		  },

	},
});

export const onLogOut = (): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.get(`${baseURL}api/v1/users/me/logout`);
        if (res.status === "done") {

        } else {
          dispatch(setErrors(res.error));
        }
      } catch (error) {
        dispatch(setErrors(error));
    }
};

export const {setErrors} = LogOutSlice.actions;
export default LogOutSlice.reducer;
