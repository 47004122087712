import React, { useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";

import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { Calendar } from "primereact/calendar";
import { BsExclamationCircleFill } from "react-icons/bs";

import { useDispatch } from "react-redux";
import { useAppSelector } from "redux/hooks";
import { getEquipmentTypeAttributeValueDetails, setEquipmentTypeAttributeValueDetails } from "redux/pages/Manage/Customization/Attributes/EquipmentTypeAttributeValueSlice";
import AddModal from "components/Table/AddModal/AddModal";
import { Button } from "primereact/button";
import { blockedNonKeyboardCharacters } from "service/GeneralUtility";

interface IEquipmentAttributeValue {
  showModal: boolean;
  equipmentAttributeId: any;
  equipmentAttributeType: any;
  equipmentAttributeName: any;
  maxLength: any;
  selectedRows: any;
  setShowModal: (state: boolean) => void;
  onEditEquipmentTypeAttributeValueClick: (data: any) => void;
}

type FormData = {
  AttributeName: string;
  Value: any;
};

export const EditEquipmentTypeAttributeValues: React.FC<IEquipmentAttributeValue> = ({
  showModal = false,
  setShowModal = () => {},
  onEditEquipmentTypeAttributeValueClick = () => {},
  equipmentAttributeId = "",
  equipmentAttributeType = "",
  equipmentAttributeName = "",
  maxLength = "",
  selectedRows = [],
}) => {
  const equipmentAttributeValueDetails = useAppSelector(
    (state) =>
      state.equipmentTypeAttributeValue.equipmentTypeAttributeValueDetails
  );

  const dispatch = useDispatch<any>();

  const editForm: any = useRef();
  const cal = React.createRef<Calendar>();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      AttributeName: "",
      Value: "",
    },
  });

  useEffect(() => {
    dispatch(getEquipmentTypeAttributeValueDetails(equipmentAttributeId, selectedRows[0].dataFieldLookUpId));
    return (() => {
      dispatch(setEquipmentTypeAttributeValueDetails({}));
    });
  }, [dispatch, selectedRows, equipmentAttributeId]);

  useEffect(() => {
    if (equipmentAttributeValueDetails) {
      if(equipmentAttributeType==="Date Time" || equipmentAttributeType==="Date"){
        setValue("Value", new Date(equipmentAttributeValueDetails?.attributeValue));
        }
        else{
        setValue("Value", equipmentAttributeValueDetails?.attributeValue);
        }
      
    }
  }, [equipmentAttributeValueDetails, setValue]);
  const formSubmitHandler = (data: any) => {
    editForm.current.requestSubmit();
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && (
        <span className="tooltip-text">
          <BsExclamationCircleFill />
          {errors[name].message}
        </span>
      )
    );
  };

  const onEditFormSubmit = (submitData: any) => {
    onEditEquipmentTypeAttributeValueClick &&
      onEditEquipmentTypeAttributeValueClick(submitData);
  };

  const textValidate = (value) => {
    if (value) {
      let invalidCharacters: any = blockedNonKeyboardCharacters(value);
      if (invalidCharacters != null && invalidCharacters.length > 0) {
        return "Non-Keyboard character(s) " + invalidCharacters.join() + " is not allowed";
      }
      else {
        return true;
      }
    }
  }

  const onError = (errors: any, e: any) => {};

  return (
    <>
      <AddModal
        header="Edit Equipment Type Attribute Value"
        showModal={showModal}
        isAdd={false}
        setShowModal={setShowModal}
        style={{ width: "35vw", height: '30vh' }}
        OnSaveClick={formSubmitHandler}
      >
        <form onSubmit={handleSubmit(onEditFormSubmit, onError)} ref={editForm}>
          <div className="row px-2 pt-2">
            <div className="field col-12 md:col-2 mb-0">
              <span className="p-float-label text-container">
                <label htmlFor="inputtext">Attribute Name</label>
                <Controller
                  name="AttributeName"
                  control={control}
                  render={() => (
                    <span className="text-value">{equipmentAttributeName}</span>
                  )}
                />
              </span>
            </div>
          </div>

          {equipmentAttributeType === "Single Line Text" ? (
            <div className="row px-2 pb-2">
              <div className="field col-12 md:col-2 mb-0">
                <span className="p-float-label">
                  <Controller
                    name="Value"
                    control={control}
                    rules={{
                      required: "Enter Value",
                      validate: (value) => {
                        return (value.trim().length <= 0 ? "This field is required." : textValidate(value))
                      }
                    }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id="name"
                        maxLength={maxLength}
                        className={classNames("w-100 error-tooltip", {
                          error: fieldState.invalid,
                        })}
                        {...field}
                        aria-autocomplete='none'
                      />
                    )}
                  />
                  <label className="mandatory" htmlFor="inputtext">
                    Value
                  </label>

                  {getFormErrorMessage("Value")}
                </span>
              </div>
            </div>
          ) : null}
          {equipmentAttributeType === "Date" ? (
            <div className="row px-2 pb-2">
              <div className="field col-12 md:col-2 mb-0">
                <span className="p-float-label">
                  <Controller
                    name="Value"
                    control={control}
                    rules={{
                      required: "Enter Value",
                    }}
                    render={({ field, fieldState }) => (
                      <Calendar
                        id="Date"
                        className={classNames("w-100 error-tooltip", {
                          error: fieldState.invalid,
                        })}
                        dateFormat="yy-mm-dd"
                        {...field}
                        value={field.value}
                        onChange={(e) => field.onChange(e.value)}
                      />
                    )}
                  />
                  <label className="mandatory" htmlFor="calendar">
                    Value
                  </label>

                  {getFormErrorMessage("Value")}
                </span>
              </div>
            </div>
          ) : null}
          {equipmentAttributeType === "Date Time" ? (
            <div className="row px-2 pb-2">
              <div className="field col-12 md:col-2 mb-0">
                <span className="p-float-label">
                  <Controller
                    name="Value"
                    control={control}
                    rules={{
                      required: "Enter Value",
                    }}
                    render={({ field, fieldState }) => (
                      <Calendar
                        id="Date Time"
                        {...field}
                        ref={cal}
                        className={classNames("w-100 error-tooltip", {
                          error: fieldState.invalid,
                        })}
                        dateFormat="yy-mm-dd"
                        showTime
                        showSeconds
                        value={field.value}
                        onChange={(e) => field.onChange(e.value)}
                        footerTemplate={() => <div>
                          <Button className="confirm-btn" onClick={() => cal.current?.hide()}>Save</Button>
                      </div>}
                      />
                    )}
                  />
                  <label className="mandatory" htmlFor="calendar">
                    Value
                  </label>

                  {getFormErrorMessage("Value")}
                </span>
              </div>
            </div>
          ) : null}
          {equipmentAttributeType === "Integer" ? (
            <div className="row px-2 pb-2">
              <div className="field col-12 md:col-2 mb-0">
                <span className="p-float-label">
                  <Controller
                    name="Value"
                    control={control}
                    rules={{
                      required: "Enter Value",
                      pattern: {
                        value: /^[0-9]+$/,
                        message: " 'Value' is an Integer Field",
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id="name"
                        maxLength={maxLength}
                        className={classNames("w-100 error-tooltip", {
                          error: fieldState.invalid,
                        })}
                        {...field}
                        aria-autocomplete='none'
                      />
                    )}
                  />
                  <label className="mandatory" htmlFor="inputtext">
                    Value
                  </label>

                  {getFormErrorMessage("Value")}
                </span>
              </div>
            </div>
          ) : null}
          {equipmentAttributeType === "Numeric" ? (
            <div className="row px-2 pb-2">
              <div className="field col-12 md:col-2 mb-0">
                <span className="p-float-label">
                  <Controller
                    name="Value"
                    control={control}
                    rules={{
                      required: "Enter Value",
                      pattern: {
                        value: /^\d*\.?\d*$/,
                        message: "'Value' is a Numeric Field",
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id="name"
                        maxLength={maxLength}
                        className={classNames("w-100 error-tooltip", {
                          error: fieldState.invalid,
                        })}
                        {...field}
                        aria-autocomplete='none'
                      />
                    )}
                  />
                  <label className="mandatory" htmlFor="inputtext">
                    Value
                  </label>

                  {getFormErrorMessage("Value")}
                </span>
              </div>
            </div>
          ) : null}
          {equipmentAttributeType === "Multi Line Text" ? (
            <div className="row px-2 pb-2">
              <div className="field col-12 md:col-2 mb-0">
                <span className="p-float-label">
                  <Controller
                    name="Value"
                    control={control}
                    rules={{
                      required: "Enter Value",
                      validate: (value) => {
                        return (value.trim().length <= 0 ? "This field is required." : textValidate(value))
                      }
                    }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id="name"
                        maxLength={maxLength}
                        className={classNames("w-100 error-tooltip", {
                          error: fieldState.invalid,
                        })}
                        {...field}
                        aria-autocomplete='none'
                      />
                    )}
                  />
                  <label className="mandatory" htmlFor="inputtext">
                    Value
                  </label>

                  {getFormErrorMessage("Value")}
                </span>
              </div>
            </div>
          ) : null}
        </form>
      </AddModal>
    </>
  );
};

export default EditEquipmentTypeAttributeValues;
