import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { setSalesOfficeLookup } from "redux/pages/Inspection/Contracts/contractSlice";
import { AppThunk } from "redux/store";
import { baseURL, zlink } from "service/HttpService";


export interface ToolState {
    errors: any;
    isCopiedDone: boolean;
    sapImportStatusList: any;
    sapImportStatusDetails: any;
    sapImportPageDetails:any;
    userLists:any;
    copyMoveLogDetails: any;
    copyMoveLogPageDetails:any;
    customerSiteMap:any;
}

let initialState: ToolState = {
    errors: "",
    isCopiedDone: false,
    sapImportStatusList: "",
    sapImportStatusDetails: "",
    sapImportPageDetails:"",
    userLists:"",
    copyMoveLogDetails:"",   
    copyMoveLogPageDetails:"",
    customerSiteMap:""
}

const ToolSlice = createSlice({
    name: "tool",
    initialState,
    reducers: {
        setErrors: (state, { payload }: PayloadAction<any>) => {
            state.errors = payload;
        },
        setIsCopiedDone: (state, { payload }: PayloadAction<any>) => {
            state.isCopiedDone = payload;
        },
        setSapImportStatusList: (state, { payload }: PayloadAction<any>) => {
           if(payload){
            payload.sapImportDetails.map((element: any) => {
				element.dateOfIntegration = moment(element.dateOfIntegration).format("yyyy-MM-DD").toString()
				return null;
			});
           }
            state.sapImportStatusList = payload;
        },
        setSapImportStatusDetails: (state, { payload }: PayloadAction<any>) => {
            state.sapImportStatusDetails = payload;
        },
        setSapImportPageDetails: (state, { payload }: PayloadAction<any>) => {
            state.sapImportPageDetails = payload;
        },
        setUsersList: (state, { payload }: PayloadAction<any>) => {
            state.userLists = payload;
        },
        setCopyMoveLogDetails: (state, { payload }: PayloadAction<any>) => {
            state.copyMoveLogDetails = payload;
        },
        setCopyMoveLogPageDetails: (state, { payload }: PayloadAction<any>) => {
            state.copyMoveLogPageDetails = payload;
        },
        setcustomerSiteMap: (state, { payload }: PayloadAction<any>) => {
            state.customerSiteMap = payload;
        },
    }
})


export const onGetCustomers = (countryId, body, setData): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.post(`${baseURL}api/v1/country/${countryId}/customers`, body);
        if (res.status === "done") {
            setData(res.data.customerDetails);
        } else {
            dispatch(setErrors(res.error));
        }
    } catch (error) {
        dispatch(setErrors(error));
    }
};

export const onGetSystems = (body, setData, setInfo, setMessage): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.post(`${baseURL}api/v1/common/search/system/searchtext/systemlist`, body);
        if (res.status === "done") {
            if (res?.data?.systemDetails?.length) {
                setData(res.data.systemDetails);
            } else {
                setInfo(true);
                setMessage('No Systems exist for the selected Customer');
            }

        } else {
            dispatch(setErrors(res.error));
        }
    } catch (error) {
        dispatch(setErrors(error));
    }
};

export const onGetSites = (countryId, body, setData, isAllSites = false, setInfo, setMessage): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.post(`${baseURL}api/v1/country/${countryId}/sites `, body);
        if (res.status === "done") {
            if (res.data.siteDetails?.length) {
                let siteDetails = res.data.siteDetails
                if (isAllSites && siteDetails?.length > 1)
                    siteDetails.splice(0, 0, { uniqueSiteId: 0, siteName: "All Sites" });
                setData(siteDetails);
            } else {
                setInfo(true);
                setMessage('No Sites exist for the selected Customer');
            }
        } else {
            dispatch(setErrors(res.error));
        }
    } catch (error) {
        dispatch(setErrors(error));
    }
};

export const onGetContract = (body, setData, setInfo, setMessage): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.post(`${baseURL}api/v1/common/search/contract/searchtext/contractlist`, body);
        if (res.status === "done") {
            if (res.data.contractDetails?.length) {
                setData(res.data.contractDetails);
            } else {
                setInfo(true);
                setMessage('No Contracts exist for the selected Customer');
            }

        } else {
            dispatch(setErrors(res.error));
        }
    } catch (error) {
        dispatch(setErrors(error));
    }
};

export const checkSalesOfficeExist = (body, setData): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.post(`${baseURL}api/v1/movecustomer/checksalesofficeexists`, body);
        if (res.status === "done") {
            setData(res.data.isSalesOfficeExists);
        } else {
            dispatch(setErrors(res.error));
        }
    } catch (error) {
        dispatch(setErrors(error));
    }
};

export const onCopyOrMoveSystemReports = (body, setStatusMessage?, setTargetPage?, setSystemList?, setIsCopied?, setMessage?, setShowInfo?): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.post(`${baseURL}api/v1/movecustomer`, body);
        if (res.status === "done") {
            if (res?.data?.isDone) {
                setIsCopied(true);
                setMessage(res?.data?.statusMessage);
                setShowInfo(true);
            } else {
                if (res?.data?.isSuccess) {
                    setStatusMessage(res?.data?.statusMessage);
                    setTargetPage(res?.data?.pageTarget);
                    if (res?.data?.systems?.length && res?.data?.pageTarget === 3) {
                        res?.data?.systems.forEach(element => {
                            element.sourceSystemName = element?.uniqueSystemName;
                            element.uniqueSystemName = "";
                        });
                        setSystemList(res?.data?.systems);
                    }
                    setIsCopied(false);
                } else {
                    setMessage(res?.data?.statusMessage);
                    setShowInfo(true);
                }
            }
        } else {
            dispatch(setErrors(res.error));
        }
    } catch (error) {
        dispatch(setErrors(error));
    }
};

export const onGetContractSalesOffice = (sourceContractId, sourceSystemId): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.get(`${baseURL}api/v1/movecustomer/contracts/${sourceContractId}/systems/${sourceSystemId}/salesoffices`);
        if (res.status === "done") {
            dispatch(setSalesOfficeLookup(res?.data));
        } else {
            dispatch(setErrors(res.error));
        }
    } catch (error) {
        dispatch(setErrors(error));
    }
};

export const onGetSapImportStatusList = (body, currentStart, sortField?: any, sortOrder?: any): AppThunk => async (dispatch) => {
    try {
        let requestUrl = `${baseURL}api/v1/sapimport`;
        if (currentStart) {
            requestUrl += `?currentstart=${currentStart}`;
        }
        if (sortField && sortOrder) {
            requestUrl += `&sortcolumn=${sortField}&sortorder=${sortOrder}`;
        }
        const res = await zlink.post(`${requestUrl}`, body);
        if (res && res.status) {
            if (res.status === "done") { 
                if (res.data) {
                    dispatch(setSapImportStatusList(res.data));
                    dispatch(setSapImportPageDetails(res.data.pageDetails));
                }
            }    
        }
    } catch (error) {
        dispatch(setErrors(error));
    }
};

export const onGetSapImportStatusDetails = (importId): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.get(`${baseURL}api/v1/sapimport/${importId}/statusdetails `);
        if (res.status === "done") {
            dispatch(setSapImportStatusDetails(res?.data));
        } else {
            dispatch(setErrors(res.error));
        }
    } catch (error) {
        dispatch(setErrors(error));
    }
};

export const onSapImportDataDownload = (importId, fileName, setDisplayInfoModal?: any, setMessage?: any): AppThunk => async (dispatch) => {
    try {
        const request: any = {
            method: "POST",
            cache: "no-store",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                Authorization: sessionStorage.getItem("accesstoken"),
            }
        };
        const downloadUrl = (window as any).envSettings.baseAPIUrl + `api/v1/sapimport/${importId}/statusdetails/download`;
        await fetch(downloadUrl, request).then((response: any) => {
            if (response && response.status) {
                if (response.status === 200) {
                    response.blob().then((blob: any) => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement("a");
                        a.href = url;
                        a.download = fileName;
                        a.click();
                    });
                } else {
                    setMessage("File not found");
                    setDisplayInfoModal(true);
                }
            }
        });
    } catch (error) {
        dispatch(setErrors(error));
    }
         
};
export const onGetCopyMoveLogDetails = (body,currentStart,sortField: any = "", sortOrder: any = ""): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.post(`${baseURL}api/v1/movecustomer/copymovelogs?currentstart=${currentStart}&sortcolumn=${sortField}&sortorder=${sortOrder}`, body);
        if (res.status === "done") {
            dispatch(setCopyMoveLogDetails(res?.data));
            dispatch(setCopyMoveLogPageDetails(res?.data?.pageDetails))
        } else {
            dispatch(setErrors(res.error));
        }
    } catch (error) {
        dispatch(setErrors(error));
    }
};
export const onGetUserLists= (body): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.post(`${baseURL}api/v1/movecustomer/copymovelog/userlist`,body);
        if (res.status === "done") {
            dispatch(setUsersList(res?.data));
        } else {
            dispatch(setErrors(res.error));
        }
    
      }    catch (error) {
        dispatch(setErrors(error));
    }
};
export const addCustomerSitemap = (body: any): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.post(`${baseURL}api/v1/movecustomer/CustomerSiteMapping`, body);
            if (res.status === "done") {
                dispatch(setcustomerSiteMap(res?.data));
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };
export const {
    setErrors,
    setIsCopiedDone,
    setSapImportStatusList,
    setSapImportStatusDetails,
    setSapImportPageDetails,
    setUsersList,
    setCopyMoveLogDetails,
    setCopyMoveLogPageDetails,
    setcustomerSiteMap
} = ToolSlice.actions;

export default ToolSlice.reducer;