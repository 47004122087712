import { Button } from 'primereact/button'
import React from 'react'
import { BsFillPencilFill, BsPlusLg, BsTrashFill } from 'react-icons/bs'
import { FaCog, FaDownload } from 'react-icons/fa'

const CommonAttributeValuesDocument = () => {
  return (
    <>
        


        <h4 className="doc-title mb-2">
            Common Attribute Values
        </h4>
        <p> 
            This option is to add/edit/delete Common Attribute Values for Validated Attributes. 
            On selecting a validated Common Attribute from the drop down, all values defined for that 
            Common Attribute will be displayed in the data grid.
        </p>

        <p className="doc-subtitle">
            <b>Add: </b>
        </p>
        <p>
            A new Attribute Value can be created by clicking the 
            <span className="doc-subtitle">Add</span>{" "}
            <Button className="doc-button-icon"> <BsPlusLg className="icon" title='Add'/></Button> {" "} icon.
        </p>
        <p className="doc-subtitle">
            <b>Edit: </b>
        </p>
        <p>
            Edit an Attribute value by selecting it and then clicking the 
            <span className="doc-subtitle"> Edit</span>{' '} 
            <Button className="doc-button-icon"> 
                <BsFillPencilFill className="icon" title='Edit'/> 
            </Button> {' '} icon.
        </p>

        <p className="doc-subtitle">
            <b>Delete: </b>
        </p>
        <p>
            Delete an Attribute value by selecting it and then clicking the 
            <span className="doc-subtitle">Delete</span> {" "}
            <Button className="doc-button-icon"> <BsTrashFill className="icon" title='Delete'/> </Button>{" "} icon.
        </p>

        

        <p>
            <b><span className="doc-subtitle">Download</span></b>{" "}
            <Button className="doc-button-icon">
                <FaDownload className="icon" title='Download'/>
            </Button>{" "}
        </p>
        
        <p>
            This option lists Report All, Report Highlighted, Export All, 
            Export Highlighted and Group Highlighted. 
        </p>

        <p>
            <span className="doc-subtitle">Report All: </span>
        </p>

        <p>
            This option is for generating a report with all the records in the data grid. 
            The report can be saved to PDF, Text, Rich Text or Excel format by selecting the file 
            format from the list under Save option, and then clicking OK button after selecting the 
            required options from the Export Settings window.
        </p>

        <p>
            <span className="doc-subtitle">Report Highlighted: </span>
        </p>
        <p>
            This option is for generating a report with only selected records in the grid. 
            Select the desired rows by holding down the control key and clicking on each of the 
            records to be selected. Users can also select a set of rows using the shift key. 
            The selected records will be highlighted. Click on the 
            <span className="doc-subtitle"> Report Highlighted </span> option in the 
            <span className="doc-subtitle"> Download </span>{" "}
            <Button className="doc-button-icon">
                <FaDownload className="icon" title='Download'/>
            </Button>{" "}
            menu to generate the report. The report will be created with the highlighted records only. 
            The report can be saved to PDF, Text, Rich Text or Excel format by selecting the file format 
            from the list under Save option, and then clicking OK button after selecting the required options 
            from the Export Settings window.
        </p>


        <p>
            <b><span className="doc-subtitle">More: </span></b>{" "}
            <Button className="doc-button-icon">
                <FaCog className="icon" title='More'/>
            </Button>{" "}
        </p>
        <p>
            This option lists Display Settings option. 
        </p>

        <p>
            <span className="doc-subtitle">Display Settings</span>
        </p>
        <p>
            This option allows the user to change the visibility of the data fields displayed in the data grid. 
            On selecting this option, all the available data fields are displayed in a window. 
            Display settings can be modified by selecting the required fields from the list. 
            The grid settings modified through this option will not be saved and will be effective only for 
            the current instance of the data grid. 
        </p>


    </>
  );
}
export default CommonAttributeValuesDocument;
