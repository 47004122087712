import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "redux/store";
import { baseURL, zlink } from "service/HttpService";

export interface PluginState {
    errors: any;
    pluginList: any;
    pluginFilePath:any;
    uploadedPlugin: any;
    qrcode: any;
}

let initialState: PluginState = {
    errors: "",
    pluginList: "",
    pluginFilePath:"",
    uploadedPlugin: "",
    qrcode:"",
};

const PluginSlice = createSlice({
    name: "plugin",
    initialState,
    reducers: {
        setErrors: (state, { payload }: PayloadAction<any>) => {
            state.errors = payload;
        },
        setPluginList: (state, { payload }: PayloadAction<any>) => {
            state.pluginList = payload;
        },
        setPluginFilePath: (state, { payload }: PayloadAction<any>) => {
            state.pluginFilePath = payload;
        },
        setUploadedPlugin: (state, { payload }: PayloadAction<any>) => {
          state.uploadedPlugin = payload;
      },
      setQrCode: (state, { payload }: PayloadAction<any>) => {
        state.qrcode = payload;
    },
    }
})

export const getPluginList = (): AppThunk => async (dispatch) => {
    try {
		const res = await zlink.get(`${baseURL}api/v1/plugins`);
        if (res.status === "done") {
			dispatch(setPluginList(res?.data?.pluginDetails));
		} else {
			dispatch(setErrors(res.error));
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const getPluginFilePath = (pluginId,countryId,isTapBridge,isTapAppPro,setMessage,setDisplayInfoModal): AppThunk => async (dispatch) => {
    try {
		const res = await zlink.get(`${baseURL}api/v1/plugins/${pluginId}/${countryId}?isTapBridge=${isTapBridge}&isTapAppPro=${isTapAppPro}`);
    if (res.status === "done") {
			dispatch(setPluginFilePath(res?.data));
		} else {
			dispatch(setErrors(res.error));
      setDisplayInfoModal(true);
      setMessage(res.message);
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const onUploadPlugin =
    (body,setShowAddModal,setMessage,setDisplayInfoModal): AppThunk =>
      async (dispatch) => {
        try {
          const res = await zlink.post(`${baseURL}api/v1/plugins/add `, body);       
          if (res.status === "done") {
            setShowAddModal(false);
            dispatch(setUploadedPlugin(res?.data))
            setMessage("File uploaded");
			      setDisplayInfoModal(true);
          } else {
			      setDisplayInfoModal(true);
            setMessage(res.message);
          }
        } catch (error) {
          dispatch(setErrors(error));
        }
};

export const onPluginSendMail =
  (body: any,countryId,isTapAppPro, setMessage,setDisplayInfoModal,setShowSendMailModal,reset): AppThunk =>
    async (dispatch) => {
      try {
        const res = await zlink.post(`${baseURL}api/v1/plugins/sendmail/${countryId}?isTapAppPro=${isTapAppPro}`, body);
        if (res.status === "done") {
            setDisplayInfoModal(true);
            setMessage("Mail sent with APK file download link");
            setShowSendMailModal(false);
            reset();       
        
        } else {
          dispatch(setErrors(res.error));
        }
      } catch (error) {
        dispatch(setErrors(error));
      }
    };

export const getQrCode = (setMessage, setDisplayInfoModal): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.get(`${baseURL}api/v1/qrcode`);
    if (res.status === "done") {
      dispatch(setQrCode(res?.data));
    } else {
      dispatch(setErrors(res.error));
      setDisplayInfoModal(true);
      setMessage(res.message);
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};


export const {
    setErrors,
    setPluginList,
    setPluginFilePath,
    setUploadedPlugin,
    setQrCode
} = PluginSlice.actions;

export default PluginSlice.reducer;