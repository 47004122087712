import React, { useState, useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";

import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";

import { classNames } from "primereact/utils";

import { useAppSelector } from "../../../redux/hooks";
import { BsExclamationCircleFill } from "react-icons/bs";

import AddModal from "components/Table/AddModal/AddModal";
import { useDispatch } from "react-redux";
import { getZoneList } from "redux/pages/Manage/OrganizationSettings/zoneSlice";
interface ISalesOffice {
  showModal: boolean;
  setShowModal: (state: boolean) => void;
  onAddSalesOffice: (submitData: any) => void;
}

type FormData = {
  name: string;
  zone: string;
  code: string;
};

export const AddSalesOffice: React.FC<ISalesOffice> = ({
  showModal = false,
  setShowModal = () => {},
  onAddSalesOffice = () => {},
}) => {
  const addUserForm: any = useRef();
  const dispatch = useDispatch<any>();
  const [zoneLookup, setZoneLookup] = useState([{ name: "", code: "" }]);
  const zoneList = useAppSelector((state) => state.zone.zoneList);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      name: "",
      zone: undefined,
      code: "",
    },
  });

  useEffect(() => {
    dispatch(getZoneList("", ""))
  }, [dispatch])

  useEffect(() => {
    if (zoneList && zoneList?.length) {
      let zone: { name: string; code: string }[] = [];
      zone.push({name:"Select",code:"Select"})
      zoneList?.map((item: any) => {
        return zone.push({ name: item.name, code: item.code });
      });
      setZoneLookup(zone);
      setValue("zone", zone[0].name);
    }
  }, [zoneList, setValue]);
  const formSubmitHandler = (data: any) => {
    addUserForm.current.requestSubmit();
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && (
        <span className="tooltip-text">
          <BsExclamationCircleFill />
          {errors[name].message}
        </span>
      )
    );
  };

  const onAddFormSubmit = (submitData: any) => {
    let zoneId = "";
    zoneList.map((zoneItem: any, key: any) => {
      if (submitData.zone === zoneItem.name) {
        zoneId = zoneItem.uniqueBusinessZoneId;
      }
      return null;
    });
    let ReviseData =
      {
        name: submitData.name.trim(),
        code: submitData.code.trim(),
        uniqueBusinessZoneId: zoneId,
      }
    onAddSalesOffice && onAddSalesOffice(ReviseData);
  };

  const onError = (errors: any, e: any) => {};

  return (
    <>
      <AddModal
        header="New Sales Office"
        showModal={showModal}
        isAdd={true}
        setShowModal={setShowModal}
        style={{ width: "35vw", maxHeight: "100%" }}
        OnSaveClick={formSubmitHandler}
      >
        <form
          onSubmit={handleSubmit(onAddFormSubmit, onError)}
          ref={addUserForm}
        >
          <div className="row px-2 pt-2">
            <div className="field col-12 md:col-2 mb-0">
              <span className="p-float-label">
                <Controller
                  name="name"
                  control={control}
                  rules={{
                    required: "Sales Office Name is required.",
                    validate: (value) => {
                      return value.trim().length<=0
                        ?"Sales Office Name is required.":true
                    }
                  }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id="name"
                      maxLength={100}
                      className={classNames("w-100 error-tooltip", {
                        error: fieldState.invalid,
                      })}
                      {...field}
                    />
                  )}
                />
                <label className="mandatory" htmlFor="inputtext">
                  Name
                </label>
                {getFormErrorMessage("name")}
              </span>
            </div>
          </div>

          <div className="row px-2">
            <div className="field col-12 md:col-2 mb-0">
              <span className="p-float-label">
                <Controller
                  name="code"
                  control={control}
                  rules={{
                    required: "Code is required.",
                    validate: (value) => {
                      return value.trim().length<=0
                        ?"Code is required":true
                    }                  
                  }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id="code"
                      className={classNames("w-100 error-tooltip", {
                        error: fieldState.invalid,
                      })}
                      {...field}
                    />
                  )}
                />
                <label className="mandatory" htmlFor="inputtext">
                  Code
                </label>
                {getFormErrorMessage("code")}
              </span>
            </div>
          </div>

          <div className="row px-2">
            <div className="field col-12 md:col-2">
              <span className="p-float-label">
                <Controller
                  name="zone"
                  control={control}
                  rules={{
                    required: "Zone is required.",
                    validate:(value)=>{
                      return value==="Select"?"Select a Zone":undefined;
                    }
                  }}
                  render={({ field, fieldState }) => (
                    <Dropdown
                      inputId=""
                      className={classNames("w-100 error-tooltip", {
                        error: fieldState.invalid,
                      })}
                      {...field}
                      value={field.value}
                      onChange={(e) => field.onChange(e.value)}
                      options={zoneLookup}
                      optionLabel="name"
                      optionValue="name"
                    />
                  )}
                />
                <label className="mandatory" htmlFor="Country">
                  Zone
                </label>
                {getFormErrorMessage("zone")}
              </span>
            </div>
          </div>
        </form>
      </AddModal>
    </>
  );
};

export default AddSalesOffice;
