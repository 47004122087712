import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { zlink, baseURL } from "../../../service/HttpService";
import { setProgressFlag } from "redux/common/commonSlice";
import { AppThunk } from "../../store";
import moment from "moment";

export interface ProvenOutcomesState {
  errors: any;
  branches : any;
  inspectionTurnAroundList: any;
  inspectionTurnAroundPageDetails: any;
  tapPerformanceReportList: any;
	tapPerformanceReportPageDetails: any;
  systemList: any;
  goalList: any;
	goalListDetails: any;
  updatedGoal:string;
  systemtasklist : any;
  projectDetails: any;
  updatedDeficiency:any;
  inspectionDeficiencyList: any;
  inspectionDeficiencyPageDetails: any;
  comments:any;
  deletedProject:any;
  softwareVersionList: any;
	softwareVersionPageDetails: any;
  setUpdatedSoftwareVersionData:any;
  newlyAddedData : any;
  dependencyCheckData : any;
  checkTAPPerformanceReportGenerated:string;
  checkIsValueChanged : boolean;
  deletedGoalIds:any;
  tabChangeMessageHandler : boolean;
  tabChangeMessage:string;
  clonedProjectData:any;
}

let initialState: ProvenOutcomesState = {
  errors: "",
  branches : [],
  inspectionTurnAroundList: "",
  inspectionTurnAroundPageDetails: "",
  tapPerformanceReportList: [],
	tapPerformanceReportPageDetails: [],
  systemList: [],
  systemtasklist : [],
  goalList: [],
	goalListDetails: [],
  updatedGoal :"",
  updatedDeficiency:[],
  inspectionDeficiencyList: [],
  inspectionDeficiencyPageDetails: [],
  comments: [],
  deletedProject:[],
  projectDetails: "",
  softwareVersionList:[],
  softwareVersionPageDetails:"",
  setUpdatedSoftwareVersionData:"",
  newlyAddedData:'',
  dependencyCheckData : "",
  checkTAPPerformanceReportGenerated:"",
  checkIsValueChanged : false,
  deletedGoalIds:[],
  tabChangeMessageHandler :false,
  tabChangeMessage:"",
  clonedProjectData:[],
};

const ProvenOutcomesState = createSlice({
  name: "provenOutcomes",
  initialState,
  reducers: {
    setErrors: (state, { payload }: PayloadAction<any>) => {
      state.errors = payload;
    },
    setBranches: (state, { payload }: PayloadAction<any>) => {
      state.branches = payload;
    },
    setInspectionTurnaroundList: (state, { payload }: PayloadAction<any>) => {
      payload.map((element: any) => {
        element.reportDeliveryDate= moment(element.reportDeliveryDate).format("yyyy-MM-DD").toString()
        element.workCompletionDate = element.workCompletionDate?moment(element.workCompletionDate).format("yyyy-MM-DD").toString() : " "
        return null;
      });
      state.inspectionTurnAroundList = payload;
    },
    setInspectionTurnaroundPageDetails: (
      state,
      { payload }: PayloadAction<any>
    ) => {
      state.inspectionTurnAroundPageDetails = payload;
    },
    setTapPerformanceReportList: (state, { payload }: PayloadAction<any>) => {
			state.tapPerformanceReportList = payload;
		},
		setTapPerformanceReportListPageDetails: (state, { payload }: PayloadAction<any>) => {
			state.tapPerformanceReportPageDetails = payload;
		},
    setSystemList: (state, { payload }: PayloadAction<any>) => {
      state.systemList = payload;
    },
    setSystemTaskList: (state, { payload }: PayloadAction<any>) => {
      state.systemtasklist = payload;
    }, 
    setgoalList: (state, { payload }: PayloadAction<any>) => {
			state.goalList = payload;
		},
		setgoalListDetails: (state, { payload }: PayloadAction<any>) => {
			state.goalListDetails = payload;
		},
    setUpdatedGoal: (state, { payload }: PayloadAction<any>) => {
      let currentIndex = state.goalList.findIndex((item: any) => item.uniqueProjectGoalId === payload?.uniqueProjectGoalId);
			state.goalList = state.goalList.filter((item: any) => item.uniqueProjectGoalId !== payload?.uniqueProjectGoalId);
			state.goalList.splice(currentIndex, 0, payload);
  },
  setProjectDetails: (state, { payload }: PayloadAction<any>) => {
    state.projectDetails = payload;

  },
  
    setUpdatedDeficiency: (state, { payload }: PayloadAction<any>) => {
      let currentIndex = state.inspectionDeficiencyList.findIndex((item: any) => item.equipmentId === payload?.equipmentId);
      state.inspectionDeficiencyList = state.inspectionDeficiencyList.filter((item: any) => item.equipmentId !== payload?.equipmentId);
      state.inspectionDeficiencyList.splice(currentIndex, 0, payload);
    },

    setDeletedProjectDetails: (state, { payload }: PayloadAction<any>) => {
			state.tapPerformanceReportList = state.tapPerformanceReportList.filter((item: any) => item.uniqueProjectId !== payload);
		},
    setInspectionDeficiencyList: (state, { payload }: PayloadAction<any>) => {
      payload.map((element: any) => {
        element.date = moment(element.date).format("yyyy-MM-DD").toString()
        element.correctedDate = element.correctedDate  ? moment(element.correctedDate).format("yyyy-MM-DD").toString() : " "
        return null;
      });
      state.inspectionDeficiencyList = payload;
    },
    setInspectionDeficiencyPageDetails: (
      state,
      { payload }: PayloadAction<any>
    ) => {
      state.inspectionDeficiencyPageDetails = payload;
    },

    setComment: (state, { payload }: PayloadAction<any>) => {
      state.comments = payload;
    },
    setSoftwareVersionList: (state, { payload }: PayloadAction<any>) => {
			state.softwareVersionList = payload;
		},
    setSoftwareVersionPageDetails: (state, { payload }: PayloadAction<any>) => {
      state.softwareVersionPageDetails = payload;
    },
    setNewlyAddedData: (state, { payload }: PayloadAction<any>) => {
      state.newlyAddedData = payload;
    },
    setUpdatedSoftwareVersionData: (state, { payload }: PayloadAction<any>) => {
      let newData: any = [];
      newData = [
        {
          inspectionTaskId: payload.inspectionTaskId,
          equipmentId: payload.equipmentId,
          equipmentName: payload.equipmentName,
          currentVersion: payload.currentVersion,
          newVersion:payload.newVersion,
          recommend: payload.recommend
        }
      ];
			let currentIndex = state.softwareVersionList.findIndex((item: any) => item.equipmentId === payload?.equipmentId);
			state.softwareVersionList = state.softwareVersionList.filter((item: any) => item.equipmentId !== payload?.equipmentId);
			state.softwareVersionList.splice(currentIndex, 0, newData[0]);
		},
    setUpdatedProjectDetailsData: (state, { payload }: PayloadAction<any>) => {
      let newData: any = [];
      newData = [
        {
          uniqueProjectId: payload.uniqueProjectId,
          projectName: payload.projectName,
          projectOwner: payload.projectOwner,
          branch: payload.branchName,
          projectId: 0
        }
      ];
      let currentIndex = state.tapPerformanceReportList.findIndex((item: any) => item.uniqueProjectId === payload?.uniqueProjectId);
      state.tapPerformanceReportList = state.tapPerformanceReportList.filter((item: any) => item.uniqueProjectId !== payload?.uniqueProjectId);
      state.tapPerformanceReportList.splice(currentIndex, 0, newData[0]);
		},
    setDependencyCheckData: (state, { payload }: PayloadAction<any>) => {
      state.dependencyCheckData = payload;
    },
    setCheckTAPPerformanceReportGenerated: (state, { payload }: PayloadAction<any>) => {
      state.checkTAPPerformanceReportGenerated = payload;
    },
    setDeletedGoalIds(state, { payload }: PayloadAction<any>) {
      state.deletedGoalIds = payload;
    },
    clearDeletedGoalIds:(state, {payload}: PayloadAction<any>) => {
      state.deletedGoalIds = payload;
    },
    setcheckIsValueChanged: (state, { payload }: PayloadAction<any>) => {
      state.checkIsValueChanged = payload;
    },
    setTabChangeMessageHandler: (state, { payload }: PayloadAction<any>) => {
      state.tabChangeMessageHandler = payload;
    },
    setTabChangeMessage: (state, { payload }: PayloadAction<any>) => {
      state.tabChangeMessage = payload;
    },
    setclonedProjectData: (state, { payload }: PayloadAction<any>) => {
      state.clonedProjectData = payload;
    },
  },
});

export const onGetInspectionTurnAroundList =(UniqueProjectId, systemId,currentStart, sortField: any = "", sortOrder: any = ""): AppThunk =>
  async (dispatch) => {
    try {
      const res = await zlink.get(`${baseURL}api/v1/tappeformancereport/systemdetails/${UniqueProjectId}/inspectionturnaround/${systemId}?currentstart=${currentStart}&sortcolumn=${sortField}&sortDirection=${sortOrder}`);
      if (res && res.status) {
        if (res.status === "done") {
          if (res.data) {
            dispatch(setInspectionTurnaroundList(res.data.inspectionTurnAroundList));
            dispatch(setInspectionTurnaroundPageDetails(res.data.pageDetails));
          }
        }
      }
    } catch (error) {
      dispatch(setErrors(error));
    }
  };

  export const onGetTapPerformanceReportList = (currentStart,sortField: any = "", sortOrder: any = ""): AppThunk => async (dispatch) => {
    try {
      const res = await zlink.get(`${baseURL}api/v1/tappeformancereport/projectlist?currentstart=${currentStart}&sortcolumn=${sortField}&sortDirection=${sortOrder}`);
      if (res.status === "done") {
      	dispatch(setTapPerformanceReportList(res?.data?.projectList));
      	dispatch(setTapPerformanceReportListPageDetails(res?.data?.pageDetails));
      } else {
      	dispatch(setErrors(res.error));
      }
    } catch (error) {
      dispatch(setErrors(error));
    }
  };
  
   export const getProjectSystems = (UniqueProjectId,setMessage, setDisplayInfoModal): AppThunk => async (dispatch) => {
        try {
          const res = await zlink.get(`${baseURL}api/v1/tappeformancereport/systemdetails/${UniqueProjectId}`);
          if (res.status === "done") {
            if (res.data) {
              if (!res.data.systemList.length) {
                setMessage('No Systems exist')
                setDisplayInfoModal(true);
              }
              else{
                dispatch(setSystemList(res?.data?.systemList));
              }
            }
            
          } else {
            dispatch(setErrors(res.error));
          }
        } catch (error) {
          dispatch(setErrors(error));
        }
      };
      
export const onGetSystemTasks = (siteId, setData, setInfo, setMessage): AppThunk => async (dispatch) => {
  try {
      const res = await zlink.get(`${baseURL}api/v1/common/search/system/${siteId}/systemlist`);
      if (res.status === "done") {
          if (res?.data?.systemDetails?.length) {
              setData(res.data.systemDetails);
          } else {
              setInfo(true);
              setMessage('No Systems exist for the selected Site');
          }

      } else {
          dispatch(setErrors(res.error));
      }
  } catch (error) {
      dispatch(setErrors(error));
  }
};

export const onAddProjectDetails = (body, setMessage,setNewProjectData): AppThunk => async (dispatch) => {
  try {
      const res = await zlink.post(`${baseURL}api/v1/tappeformancereport/addprojectdetails`,body);
      if (res.status === "done") {
        if (res?.data) {
          setNewProjectData(res.data);
          dispatch(setNewlyAddedData(res.data));      
          dispatch(setTabChangeMessageHandler(true))
				 dispatch(setTabChangeMessage('Project Details added'))
        } else { 
              setMessage(res.message);
          }

      } else {     
        dispatch(setErrors(res.error));
        dispatch(setTabChangeMessageHandler(true))
				dispatch(setTabChangeMessage(res.message))
      }
  } catch (error) {
      dispatch(setErrors(error));
  }
};

export const getBranches = (): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.get(`${baseURL}api/v1/tappeformancereport/getbranchofficelist`);
		if (res.status === "done") {
			dispatch(setBranches(res?.data?.branchOfficeList));
		} else {
			dispatch(setErrors(res.error));
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const getSystemTaskList = (uniqueBranchId): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.get(`${baseURL}api/v1/tappeformancereport/projectdetail/${uniqueBranchId}/getinspectiontasklistbasedonsystem`);
    if (res.status === "done") {
      dispatch(setSystemTaskList(res?.data?.systemInspectionTaskList));
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};
export const addGoal =
  (body: any, setMessage,setnewGoal): AppThunk =>
    async (dispatch) => {
      try {
        const res = await zlink.post(`${baseURL}api/v1/tappeformancereport/projectgoals/add`, body);
        if (res.status === "done") {
          setMessage("Goal added");
          setnewGoal(res.data);
        } else {
          dispatch(setErrors(res.error));
          setMessage(res.message);
        }
      } catch (error) {
        dispatch(setErrors(error));
      }
    };

export const onGetGoalList = (uniqueProjectId, currentStart, sortField: any = "", sortOrder: any = ""): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.get(`${baseURL}api/v1/tappeformancereport/projectgoals/${uniqueProjectId}/list?currentstart=${currentStart}&sortcolumn=${sortField}&sortDirection=${sortOrder}`);
    if (res.status === "done") {
      dispatch(setgoalList(res.data?.projectGoalList));
      dispatch(setgoalListDetails(res?.data?.pageDetails));
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const onGoalUpdated = (body, setMessage, setDisplayInfoModal): AppThunk =>
  async (dispatch) => {
    try {
      const res = await zlink.post(`${baseURL}api/v1/tappeformancereport/projectgoals/update`, body);
      if (res.status === "done") {
        dispatch(setUpdatedGoal(res?.data));
        setMessage("Goal updated");
        setDisplayInfoModal(true);
      } else {
        dispatch(setErrors(res.error));
      }
    } catch (error) {
      dispatch(setErrors(error));
    }
  };

    export const getProjectDetails = (uniqueProjectId): AppThunk => async (dispatch) => {
      try {
        dispatch(setProjectDetails(""));
        const res = await zlink.get(`${baseURL}api/v1/tappeformancereport/projectdetail/${uniqueProjectId}`);
        if (res.status === "done") {
          dispatch(setProjectDetails(res.data));
        } else {
          dispatch(setErrors(res.error));
        }
      } catch (error) {
        dispatch(setErrors(error));
      }
    };

    export const onUpdateProjectDetails = (uniqueProjectId,isInlineEdit, body, setMessage): AppThunk => async (dispatch) => {
      try {
          const res = await zlink.post(`${baseURL}api/v1/tappeformancereport/projectdetail/${uniqueProjectId}/update`,body);
          if (res.status === "done") {
            if (res?.data) {
               dispatch(setUpdatedProjectDetailsData(res?.data));
                dispatch(setTabChangeMessageHandler(true))
                dispatch(setTabChangeMessage('Project Details updated'))
            } else {
               
                setMessage(res.message);
            }
  
        } else {
       
          dispatch(setTabChangeMessageHandler(true))
          dispatch(setTabChangeMessage(res.message))
          dispatch(setErrors(res.error));
        }
      } catch (error) {
          dispatch(setErrors(error));
      }
    }
    export const onGenerateReport = (uniqueProjectId:any,setMessage, setDisplayInfoModal): AppThunk => async (dispatch) => {
      try {
        const res = await zlink.get(`${baseURL}api/v1/tappeformancereport/${uniqueProjectId}/generatetapperformancereport`);
        if (res.status === "done") {
          setDisplayInfoModal(true);
          setMessage(res.message)
          dispatch(setProgressFlag(false));
        } else {
          setDisplayInfoModal(true);
          dispatch(setErrors(res.error));
          dispatch(setProgressFlag(false));
        }
      } catch (error) {
        dispatch(setErrors(error));
        dispatch(setProgressFlag(false));
      }
    };

    export const onUpdateDeficiencyList = (uniqueProjectId,body, setMessage,setDisplayInfoModal): AppThunk =>
    async (dispatch) => {
      try {
        const res = await zlink.post(`${baseURL}api/v1/tappeformancereport/inspectiondeficiency/${uniqueProjectId}/update`,body);
        if (res.status === "done") {
          if (res?.data?.isUpdated) {
            dispatch(setUpdatedDeficiency(res.data))
            setMessage("Deficiency List updated");
            setDisplayInfoModal(true);
          } else {
            setMessage("Updation Failed");
            setDisplayInfoModal(true);
          }
      } else {
          dispatch(setErrors(res.error));
      }
  } catch (error) {
      dispatch(setErrors(error));
  }
  };
  export const addComments =(body: any, setMessage): AppThunk =>
    async (dispatch) => {
      try {
        const res = await zlink.post(`${baseURL}api/v1/tappeformancereport/additionalinformation/add `, body);
        if (res.status === "done") {
          setMessage("Comments added");
          dispatch(setComment(res.data.comment));
        } else {
          dispatch(setErrors(res.error));
          setMessage(res.message);
        }
      } catch (error) {
        dispatch(setErrors(error));
      }
    };
    export const getProjectComments = (UniqueProjectId,setMessage, setDisplayInfoModal): AppThunk => async (dispatch) => {
      try {
        const res = await zlink.get(`${baseURL}api/v1/tappeformancereport/additionalinformation/${UniqueProjectId}`);
        if (res.status === "done") {
          if (res.data) {
            if (res.data.comment == "") {
              setMessage('No Comments exist')
              setDisplayInfoModal(true);
            }
            else{
              dispatch(setComment(res.data.comment));
            }
          }
          
        } else {
          dispatch(setErrors(res.error));
        }
      } catch (error) {
        dispatch(setErrors(error));
      }
    };
    export const UpdatesComments =(body: any, setMessage): AppThunk =>
      async (dispatch) => {
        try {
          const res = await zlink.post(`${baseURL}api/v1/tappeformancereport/updateadditionalinformation `, body);
          if (res.status === "done") {
            setMessage("Comments updated");
          } else {
            dispatch(setErrors(res.error));
            setMessage(res.message);
          }
        } catch (error) {
          dispatch(setErrors(error));
        }
      };
   

  export const onGetInspectionDeficiencyList = (uniqueProjectId,systemId,currentStart, sortfield: any = "", sortorder: any = ""): AppThunk => async (dispatch) => {
    try {
      const res = await zlink.get(`${baseURL}api/v1/tappeformancereport/systemdetails/${uniqueProjectId}/inspectiondeficiency/${systemId}?currentstart=${currentStart}&sortcolumn=${sortfield}&sortDirection=${sortorder}`);
      if (res.status === "done") {
        dispatch(setInspectionDeficiencyList(res?.data?.inspectionDeficiencyList));
        dispatch(setInspectionDeficiencyPageDetails(res?.data?.pageDetails));
      } else {
        dispatch(setErrors(res.error));
      }
    } catch (error) {
      dispatch(setErrors(error));
    }
  };

export const deleteProjectDetails = ({ uniqueProjectId, setMessage, setDisplayInfoModal,setSelectedRows }): AppThunk => async (dispatch) => {
	try {  
		const res = await zlink.post(`${baseURL}api/v1/tappeformancereport/projectdetails/${uniqueProjectId}/remove`, null);
		if (res.status === "done") {
			dispatch(setDeletedProjectDetails(uniqueProjectId));
      setMessage("Project Details deleted");
      setDisplayInfoModal(true);
      setSelectedRows([]);
		} else {
			setMessage(res.message);     
			setDisplayInfoModal(true);
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};
export const onUpdateSoftwareVersion = (uniqueSoftwareVersionId,body, setMessage,setDisplayInfoModal): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.post(`${baseURL}api/v1/tappeformancereport/panelsoftwareversionupdate`,body);
    if (res.status === "done") {
        if (res?.data?.isUpdated) {
          dispatch(setUpdatedSoftwareVersionData(res.data))
          setMessage("Software Version updated");
          setDisplayInfoModal(true);
        } else {
          setMessage("Updation Failed");
          setDisplayInfoModal(true);
        }
    } else {
        dispatch(setErrors(res.error));
    }
} catch (error) {
    dispatch(setErrors(error));
}
};

  export const onGetSoftwareVersionList = (uniqueProjectId,uniqueSystemId, currentStart, keyword: any = "", sortField: any = "", sortOrder: any = ""): AppThunk => async (dispatch) => {
    try {  
      const res = await zlink.get(`${baseURL}api/v1/tappeformancereport/panelsoftwareversions/projectid/${uniqueProjectId}/SystemId/${uniqueSystemId}?currentstart=${currentStart}&sortcolumn=${sortField}&sortorder=${sortOrder}`);
      if (res.status === "done") {
        dispatch(setSoftwareVersionList(res?.data?.softwareVersionList));
        dispatch(setSoftwareVersionPageDetails(res?.data?.pageDetails));
      } else {
        dispatch(setErrors(res.error));
      }
    } catch (error) {
      dispatch(setErrors(error));
    }
  };

  export const onDependencyCheck = (uniqueProjectId ,setdependencyCheckData): AppThunk => async (dispatch) => {
    try { 
      const res = await zlink.get(`${baseURL}api/v1/tappeformancereport/projectdetail/${uniqueProjectId}/isgenarate`);
      if (res.status === "done") {
        dispatch(setDependencyCheckData(res?.data));
        setdependencyCheckData(res?.data);
      } else {
        dispatch(setErrors(res.error));
      }
    } catch (error) {
      dispatch(setErrors(error));
    }
  };

export const checkTapPerformaceReportGenerated = (uniqueProjectId: any): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.get(`${baseURL}api/v1/tappeformancereport/${uniqueProjectId}/tapperformancereport/checktapperformancereportgenerated`);
    if (res.status === "done") {
      dispatch(setCheckTAPPerformanceReportGenerated(res?.message)); 
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const onDownloadTapPerformanceReport = (uniqueProjectId: any, setDisplayInfoModal: any, setMessage: any): AppThunk => async (dispatch) => {
  try {
    let filename: any = "";
    let url = (window as any).envSettings.baseAPIUrl + `api/v1/tappeformancereport/${uniqueProjectId}/tapperformancereport/download`;
    fetch(url)
      .then((result) => {
        if (!result.ok) {
          setMessage("Report not generated");
          setDisplayInfoModal(true);
          throw Error(result.statusText);
        }
        const header = result.headers.get('Content-Disposition');
        filename = result.headers.get("content-disposition")?.split(';')[1]?.split('=')[1];
        if (/\s/.test(filename)) {
          filename = result.headers.get('Content-Disposition')?.split(';')?.[1]?.split('=')?.[1]?.slice(1, -1);
        }
        return result.blob();
      })
      .then((blob) => {
        if (blob != null) {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          a.remove();
        }
      })
      .catch((err) => {
        dispatch(setErrors(err));
      });
  } catch (error) {
    dispatch(setErrors(error));
  }
};
export const deleteSelectedProjectGoal = (uniqueProjectGoalIds,setMessage:any,setDisplayInfoModal:any): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.post(`${baseURL}api/v1/tappeformancereport/projectgoals/delete`,uniqueProjectGoalIds);
    if (res.status === "done") {
           dispatch(setDeletedGoalIds(uniqueProjectGoalIds))
           setMessage("Selected Goal(s) deleted");
           setDisplayInfoModal(true);
       
    } else {
        dispatch(setErrors(res.error));
    }
} catch (error) {
    dispatch(setErrors(error));
}
};

export const onCloneProject = (uniqueProjectId:any,setMessage, setDisplayInfoModal,setCloneData): AppThunk => async (dispatch) => {
  try {
      const res = await zlink.post(`${baseURL}api/v1/tappeformancereport/projectdetail/${uniqueProjectId}/clone`,null);
      if (res.status === "done") {
        if (res?.data) {
          setCloneData(res.data);
          setDisplayInfoModal(true);
          setMessage('Project Details Cloned');
        } else { 
              setMessage(res.message);
          }

      } else {     
        dispatch(setErrors(res.error));
        dispatch(setTabChangeMessageHandler(true))
				dispatch(setTabChangeMessage(res.message))
      }
  } catch (error) {
      dispatch(setErrors(error));
  }
}


export const {
  setErrors,
  setBranches,
  setSystemTaskList,
  setInspectionTurnaroundList,
  setInspectionTurnaroundPageDetails,
  setTapPerformanceReportList,
	setTapPerformanceReportListPageDetails,
  setSystemList,
  setgoalList,
	setgoalListDetails,
  setUpdatedGoal,
  setUpdatedDeficiency,
  setInspectionDeficiencyList,
  setInspectionDeficiencyPageDetails,
  setComment,
  setDeletedProjectDetails,
  setSoftwareVersionList,
  setSoftwareVersionPageDetails,
  setUpdatedSoftwareVersionData,
  setProjectDetails,
  setNewlyAddedData,
  setDependencyCheckData,
  setCheckTAPPerformanceReportGenerated,
  setUpdatedProjectDetailsData,
  setcheckIsValueChanged,
  setDeletedGoalIds,
  clearDeletedGoalIds,
  setTabChangeMessageHandler,
  setTabChangeMessage,
  setclonedProjectData,
} = ProvenOutcomesState.actions;

export default ProvenOutcomesState.reducer;
