import React from 'react'

const ReportsUserDetailsDocument = () => {
  return (
  <>  
    <h4 className="doc-title mb-2">User Details</h4>
    <p>
      This report shows the list of Admins / Techs / Admin-Techs with Name, 
      Email address and Status, grouped by Sales Office based on the 
      User Role selected from the User Role drop down. 
      By Default, Admin-Tech User Role will be selected in the User Role drop down. 
    </p>

    <p>
      For Super Administrators and Support, all Users in the selected User Role will be listed. 
      For Admin, Admin-Tech, Tech and Branch users, this report shows the list of Users of 
      the selected User Role who belongs to the logged in User's Sales Office, with 
      Name and Email address, grouped by Sales Office.
    </p>

  </>
  );
}
export default ReportsUserDetailsDocument;