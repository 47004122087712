import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../redux/hooks";

import { useForm, Controller } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { classNames } from "primereact/utils";
import { Dialog } from "primereact/dialog";
import { BsExclamationCircleFill } from "react-icons/bs";
import { getFeedback } from "redux/pages/Dashboard/UserMenu/feedbackSlice";

interface Props {
  showModal: boolean;
  setShowModal: (state: boolean) => void;
  onSendFeedback: (feedbackInfo: any, setLoader: any) => void;
}

type FormData = {
  userName: string;
  subject: string;
  feedBackSend: string;
};

export const FeedbackUserinfo: React.FC<Props> = ({
  showModal = false,
  setShowModal = () => {},
  onSendFeedback = () => {}
}) => {

  const dispatch = useDispatch<any>();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const feedbackData = useAppSelector((state) => state.feedback.feedbackData);
  const [loader, setLoader] = useState<boolean>(false);

  const feedbackForm: any = useRef();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
        userName: "",
        subject: "",
        feedBackSend: "",
    },
  });

  useEffect(() => {
    dispatch(getFeedback());
  }, [dispatch]);

  useEffect(() => {
    if (feedbackData && feedbackData.uniqueUserId) {
      setValue("userName", feedbackData.userName);
      setIsLoaded(true)
    }
  }, [feedbackData])

  const formSubmitHandler = (data: any) => {
    feedbackForm.current.requestSubmit();
  };

  const onFeedbackFormSubmit = (data: any) => {
    setLoader(true);
    let info = {
      uniqueUserId: feedbackData.uniqueUserId,
      uniqueSiteId: feedbackData.uniqueSiteId,
      userName: data.userName ? data.userName : feedbackData.userName,
      emailId: feedbackData.emailId,
      subject: data.subject,
      feedBackSend: data.feedBackSend
    }
    onSendFeedback(info, setLoader);
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && (
        <span className="tooltip-text">
          <BsExclamationCircleFill />
          {errors[name].message}
        </span>
      )
    );
  };


  const renderFooter = () => {
    return (
      <>
        <Button
          label="Cancel"
          onClick={() => setShowModal(false)}
          className="cancel btn"
        />
        <Button label="Submit" className="update btn" disabled={loader} autoFocus onClick={formSubmitHandler}/>
      </>
    );
  };

  return (
    <>
      <Dialog
        header="Please provide us your Feedback."
        visible={showModal}
        style={{ width: "40vw", height: "45vh" }}
        onHide={() => setShowModal(false)}
        footer={isLoaded ? renderFooter() : null}
        className="display-settings personal selectsite"
      >
        {isLoaded &&
          <form onSubmit={handleSubmit(onFeedbackFormSubmit)} ref={feedbackForm}>
            <div className="row px-2">
              <div className="field col-12 md:col-2 mb-0">
                <span className="p-float-label text-container">
                  <label htmlFor="inputtext">User Name</label>
                  <Controller
                    name="userName"
                    control={control}
                    render={() => (
                      <span className="text-value">
                        {feedbackData.userName}
                      </span>
                    )}
                  />
                </span>
              </div>
            </div>

            <div className="row px-2 pt-1">
              <div className="field col-12 md:col-2 mb-0">
                <span className="p-float-label">
                  <Controller
                    name="subject"
                    control={control}
                    rules={{
                      required: "This field is required.",
                    }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id="name"
                        className={classNames("w-100 error-tooltip", {
                          error: fieldState.invalid,
                        })}
                        {...field}
                      />
                    )}
                  />
                  <label className="mandatory" htmlFor="inputtext">
                  Subject
                  </label>
                  {getFormErrorMessage("subject")}
                </span>
              </div>
            </div>

            <div className="row px-2 pt-1">
        
              <div className="field col-12 md:col-2 mb-0">
                <span className="p-float-label">
                  <Controller
                    name="feedBackSend"
                    control={control}
                    rules={{
                      required: "This field is required.",
                    }}
                    render={({ field, fieldState }) => (
                      <InputTextarea id={field.name} className={classNames('w-100 ', { 'p-invalid error-tooltip': fieldState.invalid })} maxLength={4000} {...field} />
                    )}
                  />
                  <label className="mandatory" htmlFor="inputtext">
                    Feedback
                  </label>
                  {getFormErrorMessage("feedBackSend")}
                </span>
                <span className='textarea-txt-count'>Max 4000 Chars</span>
              </div>
              
            </div>
            
          </form>
        }
      </Dialog>
    </>
  );
};

export default FeedbackUserinfo;
