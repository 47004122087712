import React, { useState, useEffect, useCallback } from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';

import Table from "components/Table/Table";
import Filters from "components/Filters/Filters";

//redux
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../redux/hooks";
import {
  getCustomerList,
  getCustomerSearchList,
  deleteCustomerData,
  onAddCustomer,
  onUpdateCustomer
} from "../../redux/pages/Administration/administrationSlice";
import AddCustomer from "./ManageCustomer/AddCustomer";
import EditCustomer from "./ManageCustomer/EditCustomer";
import SiteModal from './ManageCustomer/SiteModal/SiteModal';

import { CUSTOMER_COLS } from "./CustomerTableData";
import InformationBox from "components/common/Message/InformationBox/InformationBox";
import ConfirmationBox from "components/common/Message/ConfirmationBox/ConfirmationBox";

import PopOver from 'components/common/PopOver/PopOver';
import CustomerReport from 'pages/Customers/Report/CustomerReport';
import { onExportReport } from 'redux/common/report/customizeReportSlice';

interface Props {

};

const Customers: React.FC<Props> = () => {
  const customerList = useAppSelector(
    (state) => state.administration.customerList
  );
  const customerPageDetails = useAppSelector(
    (state) => state.administration.customerPageDetails
  );
  const newCustomerData = useAppSelector(
    (state) => state.administration.newCustomerData
  );
  const userPrivileges = useAppSelector(
    (state) => state.user.userPrivileges
  );
  const customerSearchId = useAppSelector((state) => state.administration.customerSearchId)
  const rowsPerPage = useAppSelector((state) => state.personalSettings.rowsPerPage);
  const [pageDetails, setPageDetails] = useState<any>({});
  const [filterExpanded, setFilterExpanded] = useState<any>(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showSiteModal, setShowSiteModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [displayInfoModal, setDisplayInfoModal] = useState(false);
  const [displayConfirmModal, setDisplayConfirmModal] = useState(false);
  const [showCustomerGrid, setShowCustomerGrid] = useState(false);
  const [pageIndex, setPageIndex] = useState(0)
  const [currentStart, setCurrentStart] = useState(1);
  const [message, setMessage] = useState("");
  const [sortField, setSortField] = useState('')
  const [sortOrder, setSortOrder] = useState('');
  const [keyWord, setKeyWord] = useState('');
  const [showReportModal, setShowReportModal] = useState(false);
  const [isAddCustomer, setIsAddCustomer] = useState(false);
  const [reportFlag, setReportFlag] = useState<any>('all');
  const [customerGridList, setCustomerGridList] = useState<any[]>([]);

  const dispatch = useDispatch<any>();

  const downloadRef = React.createRef<OverlayPanel>();

  useEffect(() => {
    if (customerList && customerList.length) {
      setSelectedRows([customerList[0]]);
      setCustomerGridList(customerList);
    }
  }, [customerList])
  
  useEffect(() => {
    if(customerSearchId.length===0 )
    {
    dispatch(getCustomerList(setShowCustomerGrid, 1))
    }
    else
    {
    dispatch(getCustomerSearchList(setShowCustomerGrid, 1, customerSearchId))
    }
  }, [dispatch,customerSearchId,rowsPerPage])

  useEffect(() => {
    if (customerPageDetails) {
      setPageDetails(customerPageDetails);
    }
  }, [customerPageDetails])

  useEffect(() => {
    if (rowsPerPage && rowsPerPage > 0) {
      let modifiedPageDetails = { ...pageDetails, 'currentLength': rowsPerPage, 'pageCount': rowsPerPage };
      setPageDetails(modifiedPageDetails);
      setCurrentStart(1)
      setPageIndex(0)
    }
  }, [rowsPerPage])

  const onGlobalSearch = (filter) => {
    setKeyWord(filter)
    dispatch(getCustomerList(setShowCustomerGrid, 1, filter, sortField, sortOrder))
  }

  const onSort = (event) => {
    let sortDirection: string;
    if (event.sortOrder === 1)
      sortDirection = "ASC"
    else
      sortDirection = "DESC"

    setSortField(event.sortField)
    setSortOrder(sortDirection)
    dispatch(getCustomerList(setShowCustomerGrid, currentStart, keyWord, event?.sortField, sortDirection))
  }

  const onPageChanged = (options: any) => {
    if (options !== pageIndex) {
      let currentStart = (options * pageDetails?.currentLength) + 1
      setCurrentStart(currentStart)
      setPageIndex(options)
      dispatch(getCustomerList(setShowCustomerGrid, currentStart, keyWord, sortField, sortOrder));
    }      
  }

  const removeCustomerData = () => {
    setDisplayConfirmModal(false);
    const uniqueCustomerId = selectedRows[0].uniqueCustomerId;
    if (selectedRows.length === 1) {
      dispatch(
        deleteCustomerData({
          uniqueCustomerId,
          setMessage,
          setDisplayInfoModal,
        })
      );
    }
  };

  const deleteIconClick = () => {
    if (selectedRows.length === 1) {
      switch (selectedRows[0].status) {
        case "Deleted":
          setMessage("Customer already deleted");
          setDisplayInfoModal(true);
          break;
        case "Not Activated":
          setMessage("Selected Customer is not active, cannot be deleted");
          setDisplayInfoModal(true);
          break;
        default:
          setMessage("Are you sure you want to delete the selected Customer?");
          setDisplayConfirmModal(true);
          break;
      }
    } else if (selectedRows.length > 1) {
      setMessage("This operation can be performed only one row at a time");
      setDisplayInfoModal(true);
    } else if (selectedRows.length < 1) {
      setMessage("Select a Customer");
      setDisplayInfoModal(true);
    }
  };

  const editIconClick = () => {
    if (selectedRows.length > 1) {
      setMessage("This operation can be performed only one row at a time");
      setDisplayInfoModal(true);
    } else if (selectedRows.length < 1) {
      setMessage("Select a Customer");
      setDisplayInfoModal(true);
    } else {
      setShowEditModal(true);
    }
  }

  const handleRowSelect = useCallback((data: any) => {    //To resolve useEffect dependency
    setSelectedRows([...data]);
  }, []);

  const onAddCustomerClick = (submitData: any) => {
    setIsAddCustomer(true);
    dispatch(
      onAddCustomer(
        submitData,
        setMessage,
        setDisplayInfoModal,
        setShowAddModal,
        setShowCustomerGrid,
        false
      ))
  }

  useEffect(() => {
    if (newCustomerData && isAddCustomer) {
      setSelectedRows([newCustomerData])
      setIsAddCustomer(false)
    }
  }, [newCustomerData])

  const onEditCustomerClick = (submitData: any) => {
    if (submitData.customerName) { 
      submitData.customerName = submitData.customerName.trim(); 
    }
    if (submitData.customerNumber) {
      submitData.customerNumber = submitData.customerNumber.trim();
    }
    if (submitData.cityStateZip) {
      submitData.cityStateZip = submitData.cityStateZip.trim();
    }
    if (submitData.contact) {
      submitData.contact = submitData.contact.trim();
    }
    if (submitData.customerAddress) {
      submitData.customerAddress = submitData.customerAddress.trim();
    }
    if (submitData.email) {
      submitData.email = submitData.email.trim();
    }
    if (submitData.fax) {
      submitData.fax = submitData.fax.trim();
    }
    if (submitData.phone) {
      submitData.phone = submitData.phone.trim();
    }
    let uniqueCustomerId: any;
    if (submitData.uniqueCustomerId) {
      uniqueCustomerId = submitData.uniqueCustomerId; 
    } else if (selectedRows && selectedRows.length) {
      uniqueCustomerId = selectedRows[0].uniqueCustomerId;
      submitData.clientId = selectedRows[0].clientId;
    }     
    dispatch(onUpdateCustomer(uniqueCustomerId, submitData, setMessage, setDisplayInfoModal, setShowEditModal));
  }


  const onRowEditValidation = (rowData: any) => {
    let value = true;
    if (!rowData.customerNumber || !rowData.customerName || !rowData.customerAddress || !rowData.cityStateZip || !isPhoneValid(rowData.phone) || !rowData.phone ||!rowData.fax||
      !isFaxValid(rowData.fax) || !rowData.email || !rowData.contact || !rowData.status || !rowData.accountExpiryDate || !isEmailValid(rowData.email) || !isPatternValid(rowData.customerNumber, rowData)) {
      value = false;
    }
    return value;
  }

  const isPhoneValid = (phone) => {
    if (!phone) {
      return true;
    }
    return /^[0-9!@#$ %^&*()_+\-=\[\]{};':"\\|,.<>\/?None]*$/.test(phone);
  }

  const isFaxValid = (fax) => {
    if (!fax) {
      return true;
    }
    return /^[0-9!@#$ %^&*()_+\-=\[\]{};':"\\|,.<>\/?None]*$/.test(fax);
  }

  const isPatternValid = (customerNumber, rowData) => {
    if (!customerNumber) {
      return true;
    }
    if (rowData.countryId === 220)
    return /^[1,3][0-9]{7}$/.test(customerNumber);
    else
    return /^[6][0-9]{5}$/.test(customerNumber);
  }

  const isEmailValid = (email) => {
    if (!email) {
      return true;
    }
    return /^None$|^\S+@\S+\.\S+$/.test(email);
  }

  const onSiteIconClick = () => {
    if (selectedRows.length === 1) {
      setShowSiteModal(true)
    } else if (selectedRows.length > 1) {
      setMessage("This operation can be performed only one row at a time");
      setDisplayInfoModal(true);
    } else if (selectedRows.length < 1) {
      setMessage("Select a Customer");
      setDisplayInfoModal(true);
    }
  }

  const onReportAllClick = () => {
    setReportFlag('all');
    setShowReportModal(true);
  };

  const onReportHighlightedClick = () => {
    setReportFlag('highlighted');
    setShowReportModal(true);
  };

  const getSelectedCustomersId = () => {
    let params: any = [];
    selectedRows.map(item => {
        params.push(item.uniqueCustomerId);
    });
    return params;
};

  const onExportAll = () => {
    const reportParameters = {
      exportParameters: {
        UniqueCustomerId: "",
        search: keyWord,
        sortColumn: sortField,
        sortOrder: sortOrder
      },
      exportType: "Inspection Client Grid Report"
    };
    dispatch(onExportReport(reportParameters, "Customers"));
  };

  const onExportHighlightedClick = () => {
    const reportParameters = {
      exportParameters: {
        UniqueCustomerId: getSelectedCustomersId(),
        search: keyWord,
        sortColumn: sortField,
        sortOrder: sortOrder
      },
      exportType: "Inspection Client Grid Report"
    };
    dispatch(onExportReport(reportParameters, "Customers"));
  };
  const onGroupHighlightedClick = () => {
      const groupedItems = [...selectedRows, ...customerList.filter(item => !selectedRows.includes(item))];
      setCustomerGridList(groupedItems);
    };
  

  return (
    <div className="customers-container d-flex">
      <section
        className={`${filterExpanded ? "expanded" : ""
          } customer-filter d-flex flex-column align-items-center d-none`}
      >
        <Filters
          filterExpanded={filterExpanded}
          setFilterExpanded={setFilterExpanded}
        />
      </section>
      {showCustomerGrid ? (<section className={`${filterExpanded ? "expanded" : ""} customer-table`}>
        <Table
          rows={customerGridList}
          cols={CUSTOMER_COLS}
          paginator
          dataKeyId="clientId"
          title="Sold to Customers"
          searchText='Enter Customer Number / Name'
          pageDetails={pageDetails}
          pageIndex={pageIndex}
          onAddClick={() => setShowAddModal(true)}
          onEditClick={editIconClick}
          onDeleteClick={deleteIconClick}
          getSelectedRowData={handleRowSelect}
          onSaveRowEdit={(e) => {onEditCustomerClick(e)}}
          onPageChanged={onPageChanged}
          onRowEditValidation={onRowEditValidation}
          onDownloadClick={(e) => downloadRef.current?.toggle(e)}
          onGlobalSearch={onGlobalSearch}
          onSortData={onSort}
          defaultSelectedRows={selectedRows}
          settingListItems={[
            {
              id: 1,
              name: 'Sites',
              onClick: onSiteIconClick
            }
          ]}
        />
      </section>) : null}

      <PopOver ref={downloadRef}>
        <ul>
          <li onClick={onReportAllClick}>Report All</li>
          <li onClick={onReportHighlightedClick}>Report Highlighted</li>
          <li onClick={onExportAll}>Export All</li>
					<li onClick={onExportHighlightedClick}>Export Highlighted</li>
          <li onClick={onGroupHighlightedClick}>Group Highlighted</li>
        </ul>
      </PopOver>

      {showAddModal ? (<AddCustomer showModal={showAddModal} setShowModal={setShowAddModal} onAddCustomerClick={(data) => onAddCustomerClick(data)} />) : null}
      {showEditModal ? (<EditCustomer showModal={showEditModal} setShowModal={setShowEditModal} selectedRows={selectedRows} onEditCustomerClick={(data) => onEditCustomerClick(data)} />) : null}
      {showSiteModal ? (<SiteModal showModal={showSiteModal} setShowModal={setShowSiteModal} selectedRows={selectedRows} />) : null}
      <InformationBox
        showInfoModal={displayInfoModal}
        setShowInfoModal={setDisplayInfoModal}
        message={message}
      />
      <ConfirmationBox
        showConfirmModal={displayConfirmModal}
        setShowConfirmModal={setDisplayConfirmModal}
        confirmAction={removeCustomerData}
        message={message}
      />

      {showReportModal ? (
        <CustomerReport showModal={showReportModal}
          setShowModal={setShowReportModal}
          reportFlag={reportFlag}
          selectedRows={selectedRows}
          sortByColumns={CUSTOMER_COLS}
          searchKeyWord={keyWord}
          sortField={sortField} 
          sortOrder={sortOrder}
          />

      ) : null}
    </div>
  );
};

export default Customers;
