
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';


const statusList = [
    { label: 'Yes', value: 'Yes' },
    { label: 'No', value: 'No' },
  ];

const textEditor = (options) => {
    return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
}
const nameversionEditor = (options) => {
    return <InputText disabled type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
}

const recommendEditor = (options) => {

        return (
            <Dropdown  value={options.value} options={statusList} optionLabel="label" optionValue="value"
              onChange={(e) => options.editorCallback(e.value)} placeholder="Select"
              itemTemplate={(option) => {
                return <span className='role-badge'>{option.label}</span>
              }} />
          );
  }


export const SOFTWAREVERAIONUPDATES_COLS = [
    {
        field: 'equipmentName',
        header: 'Name',
        editor: (options) => nameversionEditor(options),
        style: { flex: '1 0 100px' },
        sortable: true,
    },
    {
        field: 'currentVersion',
        header: 'Current Version',
        editor: (options) => nameversionEditor(options),
        style: { flex: '1 0 100px' },
        sortable: true,
    },
    {
        field: 'newVersion',
        header: 'New Version',
        editor: (options) => textEditor(options),
        style: { flex: '1 0 100px' },
        sortable: true,
    },
    {
        field: 'recommend',
        header: 'Recommend',
        editor: (options) => recommendEditor(options),
        style: { flex: '1 0 100px' },
        sortable: true,
    },

];