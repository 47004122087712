import React, { useEffect, useState } from "react";
import { TabView, TabPanel } from 'primereact/tabview';

import { useAppSelector } from "../../redux/hooks";
import GenerateReports from "./GenerateReports";
import { showUI } from 'service/GeneralUtility';
import Reports from "./Reports";
import { Button } from "primereact/button";

export interface NavItemProps {
  id: number;
  name: string;
  url: string;
  active: boolean;
}
interface IInspectionReports { }

export const InspectionReports: React.FC<IInspectionReports> = () => {

  const userPrivileges = useAppSelector((state) => state.user.userPrivileges);
  
  const [toggleGenerateButton, setToggleGenerateButton] = useState<boolean>(true);
  const [activeIndex, setActiveIndex] = useState<any>(showUI(userPrivileges,"SIETAP_FS_GENERATE_REPORTS")?0:1);
  const [toggleGenerateClick, setToggleGenerateClick] = useState<boolean>(false);
  const [disableGenerateButton, setDisableGenerateButton] = useState<boolean>(false);
 
useEffect(()=>{
  if(activeIndex === 0) {
    setToggleGenerateButton(true);
  } else if(activeIndex === 1) {
    setToggleGenerateButton(false);
  }

},[activeIndex])



  return (
    <div className="inspection-reports">
      <TabView activeIndex={activeIndex} onTabChange={(indexChangeEvent)=>{setActiveIndex(indexChangeEvent.index)}} >
         {showUI(userPrivileges,"SIETAP_FS_GENERATE_REPORTS") && 
         <TabPanel header="Generate Reports">
          <GenerateReports toggleGenerateClick={toggleGenerateClick} setToggleGenerateClick={setToggleGenerateClick}/>
        </TabPanel> 
       }
        <TabPanel header="Reports">
          <Reports />
        </TabPanel>
      </TabView>
      {toggleGenerateButton &&
      <Button label="Generate" className="generate-btn"  
              disabled={disableGenerateButton} 
              onClick={()=>setToggleGenerateClick(true)}/> }
    </div>
  );
};

export default InspectionReports;
