import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "components/common/Breadcrumb/Breadcrumb";
import { BsArrowRight, BsExclamationCircleFill } from "react-icons/bs";
import Filters from "components/Filters/Filters";
import { Button } from "primereact/button";
import Table from "components/Table/Table";
import { INSPECTION_REPORT_TYPES } from "constants/common";
import { APP_ROUTES } from "constants/AppRoutes";
import { Card } from "primereact/card";
import { OverlayPanel } from "primereact/overlaypanel";
import PopOver from "components/common/PopOver/PopOver";
import { BsFillPencilFill, BsTrashFill } from "react-icons/bs";
import { InputText } from "primereact/inputtext";
import { useForm, Controller } from "react-hook-form";
import { ScrollPanel } from "primereact/scrollpanel";
import { classNames } from "primereact/utils";

import { INSPECTION_REPORTS_COLS } from "./ReportsData";
import { INSPECTION_REPORTSTEMPLATE_COLS } from "./ReportTemplatesData";
import { INSPECTION_REPORTSCONFIGS_COLS } from "./ReportConfigsData";

import { useAppSelector } from "redux/hooks";
import { useDispatch } from "react-redux";
import { checkIsReportInUse, deleteReport, getReportList, onAddReport, onGetFieldOrder, onUpdateFieldOrder, onUpdateReport, setReportList, setUpdatedFieldOrder } from "redux/pages/Manage/InspectionSettings/Reports/ReportsSlice";
import { deleteReportTemplate, getReportTemplateList, onAddReportTemplate,getDownloadTemplateFilePath,setReportTemplateList} from "redux/pages/Manage/InspectionSettings/Reports/ReportTemplateSlice";
import { deleteReportConfig, getReportConfigList, onAddReportConfig ,getDownloadConfigFilePath,setReportConfigList} from "redux/pages/Manage/InspectionSettings/Reports/ReportConfigSlice";
import {
	deleteReportGroup,
	getReportGroupList,
	onAddReportGroup,
	onUpdateReportGroup,
} from "redux/pages/Manage/InspectionSettings/Reports/ReportGroupSlice";

import InformationBox from "components/common/Message/InformationBox/InformationBox";
import ConfirmationBox from "components/common/Message/ConfirmationBox/ConfirmationBox";
import { EditReportGroups } from "./ManageReportGroups/EditReportGroups";
import { AddReportTemplates } from "../Reports/ManageReportTemplates/AddReportTemplates";
import { AddReports } from "../Reports/ManageReports/AddReports";
import { AddReportConfigs } from "../Reports/ManageReportConfigs/AddReportConfigs";
import { EditReports } from "../Reports/ManageReports/EditReports";
import DatafieldOrder from "components/common/DatafieldOrder/DatafieldOrder";
import { getCountryList } from "redux/pages/Administration/administrationSlice";

interface Props { }

export const ManageInspectionReports: React.FC<Props> = () => {
	const navigate = useNavigate();
	const mailpopref = React.createRef<OverlayPanel>();
	const reportList = useAppSelector((state) => state.report.reportList);
	const reportPageDetails = useAppSelector((state) => state.report.reportPageDetails);
	const reportTemplateList = useAppSelector((state) => state.reportTemplate.reportTemplateList);
	const reportTemplatePageDetails = useAppSelector((state) => state.reportTemplate.reportTemplatePageDetails);
	const reportConfigList = useAppSelector((state) => state.reportConfig.reportConfigList);
	const reportConfigPageDetails = useAppSelector((state) => state.reportConfig.reportConfigPageDetails);
	const reportGroupList = useAppSelector((state) => state.reportGroup.reportGroupList);

	const newReportGroupData = useAppSelector((state) => state.reportGroup.newReportGroupData);

	const deletedReportGroupData = useAppSelector((state) => state.reportGroup.deletedReportGroupData);

	const updatedReportGroupData = useAppSelector((state) => state.reportGroup.updatedReportGroupData);

	const newReportData = useAppSelector((state) => state.report.newReportData);

	const deletedReportData = useAppSelector((state) => state.report.deletedReportData);

	const updatedReportData = useAppSelector((state) => state.report.updatedReportData);

	const isReportInUse = useAppSelector((state) => state.report.isReportInUse);

	const newReportConfigData = useAppSelector((state) => state.reportConfig.newReportConfigData);

	const deletedReportConfigData = useAppSelector((state) => state.reportConfig.deletedReportConfigData);
	const newReportTemplateData = useAppSelector((state) => state.reportTemplate.newReportTemplate);
	const deletedReportTemplateData = useAppSelector((state) => state.reportTemplate.deletedReportTemplate);
	const reportFieldOrder = useAppSelector((state) => state.report.reportFieldOrder);
	const updatedFieldOrder = useAppSelector((state) => state.report.updatedFieldOrder);
	const countryList = useAppSelector((state) => state.administration.countryList);
	const rowsPerPage = useAppSelector((state) => state.personalSettings.rowsPerPage);

	const [pageDetails, setPageDetails] = useState<any>({});
	const [pageIndex, setPageIndex] = useState(0);
	const [selectedTab, setSelectedTab] = useState(INSPECTION_REPORT_TYPES.REPORT_GROUPS);
	const [showAddReportsModal, setShowAddReportsModal] = useState<boolean>(false);
	const [selectedCountry, setSelectedCountry] = useState("");
	const [selectedCountryId, setSelectedCountryId] = useState<any>("1");

	const [showEditReportGroupsModal, setShowEditReportGroupsModal] = useState(false);

	const [showAddReportTemplatesModal, setShowAddReportTemplatesModal] = useState<boolean>(false);

	const [showEditReportsModal, setShowEditReportsModal] = useState(false);
	const [showEditReportsConfigModal, setShowEditReportsConfigModal] = useState(false);

	const [selectedRows, setSelectedRows] = useState<any>([]);
	const [reportGroupData, setReportGroupData] = useState<any>([]);
	const [reportsData, setReportsData] = useState<any>([]);
	const [reportConfigData, setReportConfigData] = useState<any>([]);
	const [reportTemplateData, setReportTemplateData] = useState<any>([]);
	const [showGrid, setShowGrid] = useState(false);
	const [displayInfoModal, setDisplayInfoModal] = useState(false);
	const [displayConfirmModal, setDisplayConfirmModal] = useState(false);
	const [message, setMessage] = useState("");
	const [isReportDelete, setIsReportDelete] = useState(false);
	const [currentStart, setCurrentStart] = useState(1);
	const [sortField, setSortField] = useState('')
	const [sortOrder, setSortOrder] = useState('');
	const [keyWord, setKeyWord] = useState('');
	const [showFieldOrder, setShowFieldOrder] = useState(false);
	const [fieldOrder, setFieldOrder] = useState<any>([]);
	const dispatch = useDispatch<any>();
	const [countryLookup, setCountryLookup] = useState<any>([]);
	const [reportPagination, setReportPagination] = useState<any>({});
	const [reportTemplatePagination, setReportTemplatePagination] = useState<any>({});
	const [reportConfigPagination, setReportConfigPagination] = useState<any>({});
	

	useEffect(() => {
        dispatch(getCountryList());
      }, [dispatch]);

      useEffect(() => {
        if (countryList && countryList.countries.length > 0 && countryList.countries !== undefined) {
          let country: any = [];
          countryList.countries.map((countryItem: any, key: any) => {
            country.push({ "code": countryItem.countryId, "name": countryItem.countryName.trim() })
          });
          setSelectedCountry(country[0].name);
          setSelectedCountryId(country[0].code);
          setCountryLookup(country);
        }
      }, [countryList]);

	useEffect(() => {
		setShowGrid(true);
		switch (selectedTab) {
			case INSPECTION_REPORT_TYPES.REPORT_GROUPS: {
				dispatch(getReportGroupList());
				break;
			}
			case INSPECTION_REPORT_TYPES.REPORTS: {
				dispatch(setReportList([]))
				let body = {
					CountryName: selectedCountry,
				};        
				if(countryLookup.length>0){
                countryLookup.map((item: any, key: any) => {
                if (selectedCountry === item.name) {
                setSelectedCountryId(item.code);
              }
            });
          }
				dispatch(getReportList(body, 1, keyWord, sortField, sortOrder));
				break;
			}
			case INSPECTION_REPORT_TYPES.REPORT_TEMPLATES: {
				dispatch(setReportTemplateList([]));
				dispatch(getReportTemplateList(1,keyWord,sortField,sortOrder));
				break;
			}
			case INSPECTION_REPORT_TYPES.REPORT_CONFIGS: {
				dispatch(setReportConfigList([]));
				dispatch(getReportConfigList(1,keyWord,sortField,sortOrder));
				break;
			}
		}
	}, [dispatch, selectedCountry, selectedTab, rowsPerPage]);
	
	useEffect(() => {
		if (reportPageDetails) {
			setReportPagination(reportPageDetails);
		}
		if (reportTemplatePageDetails) {
			setReportTemplatePagination(reportTemplatePageDetails);
		}
		if (reportConfigPageDetails) {
			setReportConfigPagination(reportConfigPageDetails);
		}
	}, [reportPageDetails, reportTemplatePageDetails, reportConfigPageDetails]);

	useEffect(() => {
		if (selectedTab && rowsPerPage && rowsPerPage > 0) {
			let modifiedPageDetails: any = {};
			switch (selectedTab) {
				case INSPECTION_REPORT_TYPES.REPORTS: {
					modifiedPageDetails = { ...reportPagination, 'currentLength': rowsPerPage, 'pageCount': rowsPerPage };
					setReportPagination(modifiedPageDetails);
					setCurrentStart(1)
					setPageIndex(0)
					break;
				}
				case INSPECTION_REPORT_TYPES.REPORT_TEMPLATES: {
					modifiedPageDetails = { ...reportTemplatePagination, 'currentLength': rowsPerPage, 'pageCount': rowsPerPage };
					setReportTemplatePagination(modifiedPageDetails);
					setCurrentStart(1)
					setPageIndex(0)
					break;
				}
				case INSPECTION_REPORT_TYPES.REPORT_CONFIGS: {
					modifiedPageDetails = { ...reportConfigPagination, 'currentLength': rowsPerPage, 'pageCount': rowsPerPage };
					setReportConfigPagination(modifiedPageDetails);
					setCurrentStart(1)
					setPageIndex(0)
					break;
				}
			}
		}
	}, [rowsPerPage]);

	type FormData = {
		reportGroupName: string;
	};
	const [showAddWindow, setShowAddWindow] = useState(false);
	const {
		control,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm<FormData>({
		defaultValues: {
			reportGroupName: "",
		},
	});

	const addForm: any = useRef();

	useEffect(() => {
		if (reportGroupList) {
			let reportGroups: any = [];
			reportGroupList.map((item: any) => {
				return reportGroups.push({ reportGroupId: item.reportGroupId, reportGroupName: item.reportGroupName, isReportGroupInUse: item.isReportGroupInUse });
			});
			setReportGroupData(reportGroups);
		}
	}, [reportGroupList]);

	useEffect(() => {
		if (reportList){
			let convData =  JSON.parse(JSON.stringify(reportList));
			setReportsData(convData);
		} 
	}, [reportList]);

	useEffect(() => {
		if (newReportGroupData) {
			let newData: any = [];
			newData.push({ reportGroupId: newReportGroupData.reportGroupId, reportGroupName: newReportGroupData.reportGroupName });
			let newReportGroupList = JSON.parse(JSON.stringify(reportGroupData));
			newReportGroupList.push(newData[0]);
			setReportGroupData(newReportGroupList);
			setShowAddWindow(false);
		}
	}, [newReportGroupData]);

	useEffect(() => {
		if (reportFieldOrder) {
			let value: any = JSON.stringify(reportFieldOrder);
			value = value?.replace(/\"reportId\":/g, '"id":');
			value = value?.replace(/\"reportName\":/g, '"name":');
			value = JSON.parse(value);
			setFieldOrder(value);
		}
	}, [reportFieldOrder])

	useEffect(() => {
		if (updatedFieldOrder) {
			setShowFieldOrder(false);
			setDisplayInfoModal(true);
			setMessage("Report Order updated");
			updatedFieldOrder?.forEach((element) => {
				const index = reportsData.findIndex((item) => item.reportId === element.reportId);
				if (index >= 0) {
					reportsData[index].position = element.position;
				}
			});
			const ascDatafields = [...reportsData].sort((a, b) => a.position - b.position);
			setReportsData([...ascDatafields])
			dispatch(setUpdatedFieldOrder(""));
		}
	}, [updatedFieldOrder])

	const onPageChanged = (options: any) => {
		if (options !== pageIndex) {
			setPageIndex(options);
			switch (selectedTab) {
				case INSPECTION_REPORT_TYPES.REPORTS: {
					let currentStart = options * reportPagination?.currentLength + 1;
					setCurrentStart(currentStart)
					let body = {
						CountryName: selectedCountry,
					};
					dispatch(getReportList(body, currentStart, keyWord, sortField, sortOrder));
					break;
				}
				case INSPECTION_REPORT_TYPES.REPORT_TEMPLATES: {
					let currentStart = options * reportTemplatePagination?.currentLength + 1;
					setCurrentStart(currentStart);
					dispatch(getReportTemplateList(currentStart,keyWord,sortField,sortOrder));
					break;
				}
				case INSPECTION_REPORT_TYPES.REPORT_CONFIGS: {
					let currentStart = options * reportConfigPagination?.currentLength + 1;
					setCurrentStart(currentStart);
					dispatch(getReportConfigList(currentStart,keyWord,sortField,sortOrder));
					break;
				}
			}
		}
	};
	const onCountryDropdownChange = (data: any) => {
        if (data.value) {
          setSelectedCountry(data.value)
          let countrySelected: any = {};
          countrySelected = { countryName: data.value };
          dispatch(getReportList(countrySelected, currentStart, keyWord, sortField, sortOrder));
        }
        else {
          setSelectedCountry(data.name)
          let countrySelected: any = {};
          countrySelected = { countryName: data.name };
          dispatch(getReportList(countrySelected, currentStart, keyWord, sortField, sortOrder));
        }
      };

	const onGlobalSearch = (filter) => {
		setKeyWord(filter)
		switch (selectedTab) {
			case INSPECTION_REPORT_TYPES.REPORTS: {
				let body = {
					CountryName: selectedCountry,
				};
				dispatch(getReportList(body, 1, filter, sortField, sortOrder));
				break;
			}
			case INSPECTION_REPORT_TYPES.REPORT_TEMPLATES: {
				dispatch(getReportTemplateList(1,filter,sortField,sortOrder));
				break;
			}
			case INSPECTION_REPORT_TYPES.REPORT_CONFIGS: {
				dispatch(getReportConfigList(1,filter,sortField,sortOrder));
				break;
			}
		}
	}

	const onSort = (event) => {
		let sortDirection: string;
		if (event.sortOrder === 1)
			sortDirection = "ASC"
		else
			sortDirection = "DESC"
		setSortField(event.sortField)
		setSortOrder(sortDirection)
		switch (selectedTab) {
			case INSPECTION_REPORT_TYPES.REPORTS: {
				let body = {
					CountryName: selectedCountry,
				};
				dispatch(getReportList(body, currentStart, keyWord, event?.sortField, sortDirection));
				break;
			}
			case INSPECTION_REPORT_TYPES.REPORT_TEMPLATES: {
				dispatch(getReportTemplateList(currentStart,keyWord,event?.sortField,sortDirection));
				break;
			}
			case INSPECTION_REPORT_TYPES.REPORT_CONFIGS: {
				dispatch(getReportConfigList(currentStart,keyWord,event?.sortField,sortDirection));
				break;
			}
		}
	}

	const handleTabChange = (selectedReport) => {
		if (selectedReport !== selectedTab) {
			setKeyWord("");
			setSortField("");
			setSortOrder("");
			setPageIndex(0);
			setShowGrid(false);
			setSelectedTab(selectedReport);
		}
	};

	const handleRowSelect = useCallback((data: any) => {
		setSelectedRows([...data]);
	}, []);

	const getFormErrorMessage = (name) => {
		return (
			errors[name] && (
				<span className="tooltip-text">
					<BsExclamationCircleFill />
					{errors[name].message}
				</span>
			)
		);
	};

	//handle form validation errors
	const onError = (errors: any, e: any) => { };
	const onAddReportGroupClick = () => {
		setValue("reportGroupName", "");
		setShowAddWindow(true);
	};

	const onSaveClick = () => {
		addForm.current.requestSubmit();
	};

	const onCancelClick = () => {
		setShowAddWindow(false);
	};

	const onAddFormSubmit = (data: any) => {
		dispatch(onAddReportGroup(data, setMessage, setDisplayInfoModal));
	};

	const deleteReportGroupClick = () => {
		if (selectedRows?.isReportGroupInUse) {
			setDisplayInfoModal(true);
			setMessage("Selected Report Group is in use, cannot be deleted");
		} else {
			setMessage("Are you sure you want to delete the selected Report Group?");
			setDisplayConfirmModal(true);
		}
	};

	const removeReportGroup = () => {
		setDisplayConfirmModal(false);
		dispatch(deleteReportGroup(selectedRows.reportGroupId, setMessage, setDisplayInfoModal));
	};

	useEffect(() => {
		if (deletedReportGroupData.status === "done") {
			const deletedReportGroup: any = reportGroupData.filter((item: any) => item.reportGroupId !== selectedRows?.reportGroupId);
			setReportGroupData(deletedReportGroup);
			setSelectedRows([]);
		}
	}, [deletedReportGroupData]);

	const onEditReportGroup = (data: any) => {
		dispatch(onUpdateReportGroup(data, selectedRows.reportGroupId, setMessage, setDisplayInfoModal));
	};

	useEffect(() => {
		if (updatedReportGroupData) {
			setShowEditReportGroupsModal(false);
			let currentIndex = reportGroupData.findIndex((item: any) => item.reportGroupId === selectedRows.reportGroupId);
			const reportGroupInfo: any = reportGroupData.filter((item: any) => item.reportGroupId !== selectedRows.reportGroupId);
			reportGroupInfo.splice(currentIndex, 0, updatedReportGroupData);
			setReportGroupData(reportGroupInfo);
		}
	}, [updatedReportGroupData]);

	const onAddReportClick = (data) => {
		dispatch(onAddReport(data, selectedCountryId, setMessage, setDisplayInfoModal));
	};

	useEffect(() => {
		if (newReportData) {
			let newReportList = JSON.parse(JSON.stringify(reportsData));
			newReportList.unshift(newReportData);
			setReportsData(newReportList);
			setShowAddReportsModal(false);
		}
	}, [newReportData]);

	const editReportIconClick = () => {
		if (selectedRows.length < 1) {
			setMessage("Select a Report");
			setDisplayInfoModal(true);
		} else if (selectedRows.length > 1) {
			setMessage("This operation can be performed only one row at a time");
			setDisplayInfoModal(true);
		} else {
			setShowEditReportsModal(true);
		}
	};

	const onEditReportClick = (data) => {
		dispatch(onUpdateReport(data, selectedRows[0].reportId, selectedCountryId, setMessage, setDisplayInfoModal));
	};

	const deleteReportClick = () => {
		if (selectedRows.length < 1) {
			setMessage("Select a Report");
			setDisplayInfoModal(true);
		} else if (selectedRows.length > 1) {
			setMessage("This operation can be performed only one row at a time");
			setDisplayInfoModal(true);
		} else {
			dispatch(checkIsReportInUse(selectedRows[0].reportId, selectedCountryId));
			setIsReportDelete(true);
		}
	};

	useEffect(() => {
		if (isReportInUse && isReportDelete) {
			if (isReportInUse?.isReportInUse) {
				setDisplayInfoModal(true);
				setMessage("Selected Report is in use, cannot be deleted");
			} else {
				setMessage("Are you sure you want to delete the selected Report?");
				setDisplayConfirmModal(true);
			}
			setIsReportDelete(false);
		}
	}, [isReportInUse]);

	const removeReport = () => {
		setDisplayConfirmModal(false);
		dispatch(deleteReport(selectedRows[0].reportId, selectedCountryId, setMessage, setDisplayInfoModal));
	};

	useEffect(() => {
		if (deletedReportData.status === "done") {
			const deletedReport: any = reportsData.filter((item: any) => item.reportId !== selectedRows[0]?.reportId);
			setReportsData(deletedReport);
			setSelectedRows([]);
		}
	}, [deletedReportData]);

	useEffect(() => {
		if (updatedReportData) {
			setShowEditReportsModal(false);
			let currentIndex = reportsData.findIndex((item: any) => item.reportId === selectedRows[0].reportId);
			const reportInfo: any = reportsData.filter((item: any) => item.reportId !== selectedRows[0].reportId);
			reportInfo.splice(currentIndex, 0, updatedReportData);
			setReportsData(reportInfo);
		}
	}, [updatedReportData]);

	useEffect(() => {
		if (reportConfigList) {
			setReportConfigData(reportConfigList);
		}
	}, [reportConfigList])

	const onAddReportConfigData = (data: any) => {
		dispatch(onAddReportConfig(data, setMessage, setDisplayInfoModal));
	}

	useEffect(() => {
		if (newReportConfigData) {
			let newData: any = JSON.parse(JSON.stringify(newReportConfigData))
			let newReportConfigList = JSON.parse(JSON.stringify(reportConfigData));
			let count = 0;
			count = newReportConfigList?.length > reportConfigList?.length ? newReportConfigList?.length + 1 : reportConfigList?.length + 1;
			newData.map((item: any) => {
				newReportConfigList = newReportConfigList.filter((el: any) => el.reportConfig !== item.reportConfig)
				item.reportConfigId = count;
				newReportConfigList.unshift(item);
				count++
			})
			setReportConfigData(newReportConfigList);
			setShowEditReportsConfigModal(false);
		}
	}, [newReportConfigData])

	const deleteReportConfigClick = () => {
		if (selectedRows.length < 1) {
			setMessage("Select a Report");
			setDisplayInfoModal(true);
		} else if (selectedRows.length > 1) {
			setMessage("This operation can be performed only one row at a time");
			setDisplayInfoModal(true);
		} else {
			setMessage("Deleting the Report Config may have issue with Report generation. Are you sure you want to delete the selected Report Config?")
			setDisplayConfirmModal(true)
		}
	};

	const removeReportConfig = () => {
		setDisplayConfirmModal(false)
		let fileName = Buffer.from(selectedRows[0].reportConfig).toString('base64')
		let body = {
			enCryptedConfigName: fileName
		};

		dispatch(deleteReportConfig(body, setMessage, setDisplayInfoModal))
	}

	useEffect(() => {
		if (deletedReportConfigData) {
			const deletedReportConfig: any = reportConfigData.filter((item: any) => item.reportConfigId !== selectedRows[0]?.reportConfigId);
			setReportConfigData(deletedReportConfig);
			setSelectedRows([]);
		}
	}, [deletedReportConfigData])

	useEffect(() => {
		if (reportTemplateList) {
			setReportTemplateData(reportTemplateList);
		}
	}, [reportTemplateList])

	const onAddReportTemplateData = (data: any) => {
		dispatch(onAddReportTemplate(data, setMessage, setDisplayInfoModal));
	}

	useEffect(() => {
		if (newReportTemplateData) {
			let newData: any = JSON.parse(JSON.stringify(newReportTemplateData))
			let newReportTemplateList = JSON.parse(JSON.stringify(reportTemplateData));
			let count = 0;
			count = newReportTemplateList?.length > reportTemplateList?.length ? newReportTemplateList?.length + 1 : reportTemplateList?.length + 1;
			newData.map((item: any) => {
				newReportTemplateList = newReportTemplateList.filter((el: any) => el.reportTemplate !== item.reportTemplate)
				item.reportTemplateId = count;
				newReportTemplateList.unshift(item);
				count++
			})
			setReportTemplateData(newReportTemplateList);
			setShowAddReportTemplatesModal(false);
		}
	}, [newReportTemplateData])

	const deleteReportTemplateClick = () => {
		if (selectedRows.length < 1) {
			setMessage("Select a Report");
			setDisplayInfoModal(true);
		} else if (selectedRows.length > 1) {
			setMessage("This operation can be performed only one row at a time");
			setDisplayInfoModal(true);
		} else {
			setMessage("Deleting the Report Template may have issue with Report generation. Are you sure you want to delete the selected Report Template?")
			setDisplayConfirmModal(true)
		}
	};

	const removeReportTemplate = () => {
		setDisplayConfirmModal(false)
		let fileName = Buffer.from(selectedRows[0].reportTemplate).toString('base64')
		let body = {
			enCryptedTemplateName: fileName
		};

		dispatch(deleteReportTemplate(body, setMessage, setDisplayInfoModal))
	}

	useEffect(() => {
		if (deletedReportTemplateData) {
			const deletedReportTemplate: any = reportTemplateData.filter((item: any) => item.reportTemplateId !== selectedRows[0]?.reportTemplateId);
			setReportTemplateData(deletedReportTemplate);
			setSelectedRows([]);
		}
	}, [deletedReportTemplateData])

	const onOrderUpdated = useCallback((body) => {
		let value: any = JSON.stringify(body);
		value = value?.replace(/\"id\":/g, '"reportId":');
		value = JSON.parse(value);
		dispatch(onUpdateFieldOrder(selectedCountryId, value))
	}, [selectedCountryId]);

	const onDownloadTemplateClick = () => {

		if (selectedRows.length < 1) {
			setMessage("Select a report template");
			setDisplayInfoModal(true);
		}
		else if (selectedRows.length === 1 || selectedRows.length > 1) {
			let reportTemplateFileNames: any = [];
			selectedRows.forEach(element => {
				reportTemplateFileNames.push(element?.reportTemplate);
			});
			const body = {
				reportTemplateFileNames: reportTemplateFileNames
			}
			dispatch(getDownloadTemplateFilePath(body, setMessage, setDisplayInfoModal))
		}
	}

	const onDownloadConfigClick = () => {

		if (selectedRows.length < 1) {
			setMessage("Select a report config");
			setDisplayInfoModal(true);
		}
		else if (selectedRows.length === 1 || selectedRows.length > 1) {
			let reportConfigFileNames: any = [];
			selectedRows.forEach(element => {
				reportConfigFileNames.push(element?.reportConfig);
			});
			const body = {
				reportConfigFileNames: reportConfigFileNames
			}
			dispatch(getDownloadConfigFilePath(body, setMessage, setDisplayInfoModal))
		}
	}

	const getMainReportsContent = () => {
		switch (selectedTab) {
			case INSPECTION_REPORT_TYPES.REPORT_GROUPS: {
				return (
					<div className="main-report-groups pl-3 pt-3 pr-3">
						<div className="main-report-group">
							<p className="Pagetitle mb-0">
								<b>Report Groups</b>
							</p>
							<div className="report-group-list-add pt-3 pb-3">
								{showAddWindow && (
									<div className="add-main-report-group d-flex flex-column justify-content-center align-items-center mb-3">
										<div className="add-report-group">
											<form onSubmit={handleSubmit(onAddFormSubmit, onError)} ref={addForm}>
												<p className="mandatory">Report Group</p>
												<div className="field">
													<span className="add-report-group-field p-float-label">
														<Controller
															name="reportGroupName"
															control={control}
															rules={{
																required: "Report Group is required.",
															}}
															render={({ field, fieldState }) => (
																<InputText
																	id="adddomain"
																	maxLength={100}
																	className={classNames("w-100 error-tooltip", {
																		error: fieldState.invalid,
																	})}
																	{...field}
																/>
															)}
														/>

														{getFormErrorMessage("reportGroupName")}
													</span>
												</div>
											</form>
											<div className="pt-3 text-right">
												<Button label="Cancel" className="cancel-btn" onClick={onCancelClick} />
												<Button label="Save" className="save-btn" onClick={onSaveClick} />
											</div>
										</div>
									</div>
								)}

								{!showAddWindow && (
									<div className="report-group-add d-flex justify-content-end mb-2">
										<div className="new-report-group" onClick={onAddReportGroupClick}>
											<i className="pi pi-plus"></i>
											<span className="ml-1">New Report Group</span>
										</div>
									</div>
								)}

								<div className="report-group-list">
									<ScrollPanel style={{ width: "100%", height: "390px" }}>
										<div className="row">
											{reportGroupData && reportGroupData.length
												? reportGroupData.map((item: any, key: any) => (
													<div className="col-3" key={key}>
														<Card>
															<div className="icon">
																<span>
																	<i className="pi pi-globe"></i>
																</span>
															</div>
															<div className="report-group-name ml-3">{item.reportGroupName}</div>
															<div className="option-icon">
																<i
																	className="pi pi-ellipsis-v"
																	onClick={(e) => {
																		mailpopref.current?.toggle(e);
																		setSelectedRows(item);
																	}}
																></i>
															</div>
														</Card>
													</div>
												))
												: null}
										</div>
									</ScrollPanel>
								</div>
								<InformationBox showInfoModal={displayInfoModal} setShowInfoModal={setDisplayInfoModal} message={message} />
								<ConfirmationBox
									showConfirmModal={displayConfirmModal}
									setShowConfirmModal={setDisplayConfirmModal}
									confirmAction={removeReportGroup}
									message={message}
								/>
							</div>
						</div>
						<PopOver ref={mailpopref}>
							<ul className="maildomain-icon-list">
								<li onClick={() => setShowEditReportGroupsModal(true)}>
									<span>
										{" "}
										<BsFillPencilFill className="icon" />
									</span>
									Edit
								</li>
								<li onClick={deleteReportGroupClick}>
									<span>
										<BsTrashFill className="icon" />
									</span>
									Delete
								</li>
							</ul>
						</PopOver>
						{showEditReportGroupsModal && (
							<EditReportGroups
								showModal={showEditReportGroupsModal}
								setShowModal={setShowEditReportGroupsModal}
								seletedRows={selectedRows}
								onEditReportGroup={onEditReportGroup}
							/>
						)}
					</div>
				);
			}
			case INSPECTION_REPORT_TYPES.REPORTS: {
				return (
					<>
						{reportsData && (
							<Table
								rows={reportsData}
								cols={INSPECTION_REPORTS_COLS.REPORTS_COLS}
								paginator
								dataKeyId="reportId"
								title={`Reports`}
								searchText="Search Report Name"
								pageDetails={reportPagination}
								pageIndex={pageIndex}
								onPageChanged={onPageChanged}
								getSelectedCountry={(selectedItem) => {
									setSelectedCountry(selectedItem.countryName);
									setSelectedCountryId(selectedItem.id);
								}}
								getSelectedRowData={handleRowSelect}
								showDownloadIcon={false}
								showSettingIcon={false}
								showHamIcon
								showOrderIcon
								onAddClick={() => setShowAddReportsModal(true)}
								onEditClick={editReportIconClick}
								onDeleteClick={deleteReportClick}
								onGlobalSearch={onGlobalSearch}
								showCountryDropdown
								countryLookup={countryLookup}
								onCountryDropdownChange={onCountryDropdownChange}
								onSortData={onSort}
								showroweditor={false}
								onHamClick={() => {
									setShowFieldOrder(true);
									dispatch(onGetFieldOrder(selectedCountryId));
									
								}}
								isLazyLoading={true}
							/>
						)}
						{showEditReportsModal && (
							<EditReports
								showModal={showEditReportsModal}
								setShowModal={setShowEditReportsModal}
								selectedCountry={selectedCountry}
								selectedCountryId={selectedCountryId}
								onEditReportClick={onEditReportClick}
								seletedRows={selectedRows}
							/>
						)}

						{showAddReportsModal && (
							<AddReports
								showModal={showAddReportsModal}
								setShowModal={setShowAddReportsModal}
								selectedCountry={selectedCountry}
								selectedCountryId={selectedCountryId}
								onAddReportClick={onAddReportClick}
							/>
						)}
						<InformationBox showInfoModal={displayInfoModal} setShowInfoModal={setDisplayInfoModal} message={message} />
						<ConfirmationBox
							showConfirmModal={displayConfirmModal}
							setShowConfirmModal={setDisplayConfirmModal}
							confirmAction={removeReport}
							message={message}
						/>
						{showFieldOrder && (
							<DatafieldOrder
								header="Report Order"
								showModal={showFieldOrder}
								data={fieldOrder}
								onOrderUpdated={onOrderUpdated}
								onHideModal={() => setShowFieldOrder(false)}
							/>
						)}
					</>
				);
			}
			case INSPECTION_REPORT_TYPES.REPORT_TEMPLATES: {
				return (
					<>
						<div className="report-template-table">
							{showGrid && (
								<Table
									rows={reportTemplateData}
									cols={INSPECTION_REPORTSTEMPLATE_COLS.REPORTSTEMPLATE_COLS}
									paginator
									dataKeyId="reportTemplateId"
									pageIndex={pageIndex}
									onPageChanged={onPageChanged}
									pageDetails={reportTemplatePagination}
									getSelectedRowData={handleRowSelect}
									title={`Report Templates`}
									searchText="Search"
									showEditIcon={false}
									showSettingIcon={false}
									showroweditor={false}
									onAddClick={() => setShowAddReportTemplatesModal(true)}
									onDeleteClick={deleteReportTemplateClick}
									onDownloadClick={onDownloadTemplateClick}
									onGlobalSearch={onGlobalSearch}
									onSortData={onSort}
									isLazyLoading={true}
								/>
							)}
						</div>
						{showAddReportTemplatesModal && (
							<AddReportTemplates showModal={showAddReportTemplatesModal} setShowModal={setShowAddReportTemplatesModal} onAddReportTemplate={onAddReportTemplateData} />
						)}
						<InformationBox showInfoModal={displayInfoModal} setShowInfoModal={setDisplayInfoModal} message={message} />
						<ConfirmationBox
							showConfirmModal={displayConfirmModal}
							setShowConfirmModal={setDisplayConfirmModal}
							confirmAction={removeReportTemplate}
							message={message}
						/>
					</>
				);
			}
			case INSPECTION_REPORT_TYPES.REPORT_CONFIGS: {
				return (
					<>
						<div className="report-configs-table">
							{showGrid && (
								<Table
									rows={reportConfigData}
									cols={INSPECTION_REPORTSCONFIGS_COLS.REPORTSCONFIGS_COLS}
									paginator
									pageIndex={pageIndex}
									onPageChanged={onPageChanged}
									pageDetails={reportConfigPagination}
									getSelectedRowData={handleRowSelect}
									dataKeyId="reportConfigId"
									title={`Report Configs`}
									searchText="Search"
									showEditIcon={false}
									showSettingIcon={false}
									showroweditor={false}
									onAddClick={() => setShowEditReportsConfigModal(true)}
									onDeleteClick={deleteReportConfigClick}
									onDownloadClick={onDownloadConfigClick}
									onGlobalSearch={onGlobalSearch}
									onSortData={onSort}
									isLazyLoading={true}
								/>
							)}
						</div>
						{showEditReportsConfigModal && <AddReportConfigs showModal={showEditReportsConfigModal} setShowModal={setShowEditReportsConfigModal} onAddReportConfig={onAddReportConfigData} />}
						<InformationBox showInfoModal={displayInfoModal} setShowInfoModal={setDisplayInfoModal} message={message} />
						<ConfirmationBox
							showConfirmModal={displayConfirmModal}
							setShowConfirmModal={setDisplayConfirmModal}
							confirmAction={removeReportConfig}
							message={message}
						/>
					</>
				);
			}
			default: {
				return <h4>Report Groups Section</h4>;
			}
		}
	};
	return (
		<div className="manage-inspection-reports">
			<section className="d-flex align-items-center">
				<Breadcrumb title="Inspection Settings" classNames="report-header" handleGoBackClick={() => navigate(`/${APP_ROUTES.MANAGE_VIEW}`)} />
				<div className="breadcrumb-right-arrow ml-3 mr-3">
					<BsArrowRight className="icon" />
				</div>
				<div className="breadcrumb-sub-page-title">
					<span>Reports</span>
				</div>
			</section>
			<div className="table-container d-flex">
				<section className="inspection-report-filter expanded d-flex flex-column align-items-center">
					<Filters getSelectedReportsTab={handleTabChange} reportFilter filterExpanded />
				</section>
				<section className="inspection-report-table expanded">{getMainReportsContent()}</section>
			</div>
		</div>
	);
};
export default ManageInspectionReports;
