import React from "react";

const FaqDocument = () => {
    return (
        <>
            <h4 className="doc-title mb-2" >
                FAQ 
            </h4>
            <p>
                FAQ related to different modules are grouped under FAQ 
            </p>
        </>
    );
}

export default FaqDocument;