import React, { useState, useEffect } from 'react';

import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';

import { useAppSelector } from 'redux/hooks';
import { useDispatch } from 'react-redux';
import { getSystemTypes } from 'redux/pages/Inspection/Systems/systemSlice';

interface ISystemTableData {
    getTableColumns?: (data: any) => void,
    selectedRow: any;
};
export const SystemTableData: React.FC<ISystemTableData> = ({
    getTableColumns = () => { },
    selectedRow = [],
}) => {

    const systemTypes = useAppSelector((state) => state.system.systemTypes);

    const [isSiemensSystem, setIsSiemensSystem] = useState<boolean>(true);
    const [disableSystemType, setDisableSystemType] = useState<boolean>(false);
    const [systemTypeLookup, setSystemTypeLookup] = useState([{ label: "", value: "", isSiemens: false }]);
    const [systemColumns, setSystemColumns] = useState<any>([])
    const dispatch = useDispatch<any>()

    useEffect(() => {
        if (systemTypeLookup) {
            let systemType = systemTypes.find((item: any) => item.panelTypeName === selectedRow.systemType)
            if ((systemType?.isSiemens)) {

                setIsSiemensSystem(true);
            } else {
                setIsSiemensSystem(false);
            }

        }

    }, [selectedRow, systemTypeLookup]);

    useEffect(() => {
        dispatch(getSystemTypes())
    }, [dispatch])

    useEffect(() => {
        if (systemTypes?.length > 0) {
            let systemType: { label: string, value: string, isSiemens: boolean }[] = [];
            if (selectedRow.systemType === "XLS") {
                systemTypes?.map((item: any) => {
                    if (item.panelTypeName === "Desigo Modular" || item.panelTypeName === "XLS") {
                        return systemType.push({ label: item.panelTypeName, value: item.panelTypeName, isSiemens: item.isSiemens })
                    }
                    setDisableSystemType(false)
                })
            } else if (selectedRow.systemType === "Desigo Modular") {
                systemTypes?.map((item: any) => {
                    if (item.panelTypeName === "Desigo Modular") {
                        return systemType.push({ label: item.panelTypeName, value: item.panelTypeName, isSiemens: item.isSiemens })
                    }
                    setDisableSystemType(true)
                })
            } else if (selectedRow.systemType === "MXL" ||
                selectedRow.systemType === "Desigo FS20" ||
                selectedRow.systemType === "Desigo FV20" ||
                selectedRow.systemType === "Desigo Modular" ||
                selectedRow.systemType === "Cerberus PRO FC92" ||
                selectedRow.systemType === "Cerberus PRO FV92" ||
                selectedRow.systemType === "Cerberus PRO Modular"
            ) {
                setDisableSystemType(true)
            } else {
                systemTypes?.map((item: any) => {
                    if (!item.isSiemens) {

                        return systemType.push({ label: item.panelTypeName, value: item.panelTypeName, isSiemens: item.isSiemens });
                    }
                })
                setDisableSystemType(false)
            }
            setSystemTypeLookup(systemType)
        }
    }, [systemTypes, selectedRow])


    const NetworkList = [
        { label: 'True', value: 'True' },
        { label: 'False', value: 'False' }
    ];

    const languageList = [
        { label: 'Base', value: 'Base' },
        { label: 'Alternate', value: 'Alternate' }
    ];


    const networkEditor = (options) => {
        return (
            <Dropdown value={options.value} options={NetworkList} onChange={(e) => options.editorCallback(e.target.value)} optionLabel="label" optionValue="value"
                placeholder="Select Networked Status"
                itemTemplate={(option) => {
                    return <span className='status-badge'>{option.label}</span>
                }} />
        );
    }

    const systemTypeEditor = (options) => {
        return (
            <Dropdown value={options.value} options={systemTypeLookup} onChange={(e) => options.editorCallback(e.target.value)} optionLabel="label" optionValue="value"
                placeholder="Select System"
                itemTemplate={(option) => {
                    return <span className='status-badge'>{option.label}</span>
                }} />
        );
    }
    const languageEditor = (options) => {
        return (
            <Dropdown value={options.value} options={languageList} onChange={(e) => options.editorCallback(e.target.value)} optionLabel="label" optionValue="value"
                itemTemplate={(option) => {
                    return <span className='status-badge'>{option.label}</span>
                }} />
        );
    }

    const textEditor = (options) => {
        return <InputText type="text" value={options.value} maxLength={100} onChange={(e) => options.editorCallback(e.target.value)} />;
    }
    const disableEditor = (options) => {
        return <InputText type="text" value={options.value} disabled onChange={(e) => options.editorCallback(e.target.value)} />;
    }

    useEffect(() => {

        let column: { field: string, header: string, editor: any, style: any, sortable: boolean }[] = [];
        column.push({
            field: 'systemName',
            header: 'Name',
            editor: (options) => textEditor(options),
            style: { flex: '1 0 130px' },
            sortable: true,
        })
        if (disableSystemType) {

            column.push(
                {
                    field: 'systemType',
                    header: 'System Type',
                    editor: (options) => disableEditor(options),
                    style: { flex: '1 0 150px' },
                    sortable: true,
                }
            )
        } else {
            column.push(
                {
                    field: 'systemType',
                    header: 'System Type',
                    editor: (options) => systemTypeEditor(options),
                    style: { flex: '1 0 150px' },
                    sortable: true,
                }
            )
        }
        if (isSiemensSystem) {
            column.push({
                field: 'isNetworked',
                header: 'Is Networked',
                editor: (options) => networkEditor(options),
                style: { flex: '1 0 150px' },
                sortable: true,
            })
        } else {
            column.push({
                field: 'isNetworked',
                header: 'Is Networked',
                editor: (options) => disableEditor(options),
                style: { flex: '1 0 150px' },
                sortable: true,
            })
        }
        column.push({
            field: 'globalPMI',
            header: 'Global PMI',
            editor: (options) => disableEditor(options),
            style: { flex: '1 0 200px' },
            sortable: true,
        })
        if (isSiemensSystem) {
            column.push({
                field: 'preferredLanguage',
                header: 'Preferred Language',
                editor: (options) => languageEditor(options),
                style: { flex: '1 0 200px' },
                sortable: true,
            })
        }
        else {
            column.push({
                field: 'preferredLanguage',
                header: 'Preferred Language',
                editor: (options) => disableEditor(options),
                style: { flex: '1 0 200px' },
                sortable: true,
            })
        }
        column.push({
            field: 'status',
            header: 'Status',
            editor: (options) => disableEditor(options),
            style: { flex: '1 0 110px' },
            sortable: true,
        })
        column.push({
            field: 'xmlFile',
            header: 'XML File',
            editor: (options) => disableEditor(options),
            style: { flex: '1 0 110px' },
            sortable: true,
        })
        column.push({
            field: 'configFile',
            header: 'Config File',
            editor: (options) => disableEditor(options),
            style: { flex: '1 0 110px' },
            sortable: true,
        })
        column.push({
            field: 'activeTask',
            header: 'Active Task',
            editor: (options) => disableEditor(options),
            style: { flex: '1 0 150px' },
            sortable: true,
        })
        column.push({
            field: 'xmlUploadDate',
            header: 'XML Upload Date',
            editor: (options) => disableEditor(options),
            style: { flex: '1 0 150px' },
            sortable: true,
        })
        column.push({
            field: 'softwareVersion',
            header: 'Software Version',
            editor: (options) => disableEditor(options),
            style: { flex: '1 0 150px' },
            sortable: true,
        })
        column.push({
            field: 'revision',
            header: 'Revision',
            editor: (options) => disableEditor(options),
            style: { flex: '1 0 150px' },
            sortable: true,
        })
        setSystemColumns(column)

    }, [isSiemensSystem,disableSystemType])


    useEffect(() => {
        getTableColumns(systemColumns);

    }, [getTableColumns, selectedRow, systemColumns]);
    return (
        <>
        </>
    )
}
export default SystemTableData