import { InputText } from 'primereact/inputtext';


const textEditor = (options) => {
    return <InputText type="text" value={options.value} onChange={(e) => 
        {options.editorCallback(e.target.value)}} />;
}

export const tapPerformance_Cols = [
    {
        field: 'projectName',
        header: 'Project Name',
        editor: (options) => textEditor(options),
        style: { flex: '1 0 150px' },
        sortable: true,
    },
    {
        field: 'projectOwner',
        header: 'Project Owner',
        editor: (options) => textEditor(options),
        style: { flex: '1 0 100px' },
        sortable: true,
    },
    {
        field: 'branch',
        header: 'Branch',
        editor: (options) => textEditor(options),
        style: { flex: '1 0 100px' },
        sortable: true,
    },

];
