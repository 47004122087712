import React, { useState, useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";

import { Calendar } from "primereact/calendar";

import { classNames } from "primereact/utils";

import { BsExclamationCircleFill } from "react-icons/bs";
import moment from "moment";


import { useDispatch } from "react-redux";
import { useAppSelector } from "redux/hooks";
import { onEditContractDates } from "redux/pages/Inspection/InspectionTask/inspectionTaskSlice";

import AddModal from "components/Table/AddModal/AddModal";
import InformationBox from "components/common/Message/InformationBox/InformationBox";

interface IContractDate {
  showModal: boolean;
  setShowModal: (state: boolean) => void;
  contractDetails: any;
  plannedStartDate: any;
  plannedEndDate: any;
  onContractUpdated: (data: any) => void;
}

type FormData = {
  endDate: any;
  startDate: any;
};

export const ContractDate: React.FC<IContractDate> = ({
  showModal = false,
  setShowModal = () => {},
  contractDetails = '',
  plannedStartDate = '',
  plannedEndDate = '',
  onContractUpdated = () => {},
}) => {
  
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      endDate: "",
      startDate: ""
    },
  });

  const updatedContractDates = useAppSelector((state) => state.inspectionTask.updatedContractDates);

  const [isContractSubmit, setIsContractSubmit] = useState(false);
  const [displayInfoModal, setDisplayInfoModal] = useState(false);
  const [message, setMessage] = useState("");

  const ContrtactDateForm: any = useRef();
  const dispatch = useDispatch<any>();

  useEffect(() => {
    setValue("startDate",  new Date(contractDetails?.startDate))
    setValue("endDate",  new Date(contractDetails?.endDate))
  }, [contractDetails, setValue])

  useEffect(() => {
    if (updatedContractDates && isContractSubmit) {
      onContractUpdated && onContractUpdated(updatedContractDates)
      setIsContractSubmit(false);
    }
  }, [updatedContractDates, isContractSubmit, onContractUpdated])

  const formSubmitHandler = (data: any) => {
    ContrtactDateForm.current.requestSubmit();
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && (
        <span className="tooltip-text">
          <BsExclamationCircleFill />
          {errors[name].message}
        </span>
      )
    );
  };

  const onAddFormSubmit = (data: any) => {
    data.startDate = moment(data?.startDate).format("YYYY-MM-DD").toString()
    data.endDate = moment(data?.endDate).format("YYYY-MM-DD").toString()
    if (new Date(data.startDate) > new Date(data.endDate)) {
      setDisplayInfoModal(true)
      setMessage("Contract End Date should be greater than Contract Start Date")
    } else {
      dispatch(
        onEditContractDates(
          data,
          contractDetails?.uniqueContractId
        ))
      setIsContractSubmit(true);
    }
  };

  const onError = (errors: any, e: any) => {};

  return (
    <>
      <AddModal
        header="Contract Date"
        showModal={showModal}
        isAdd={true}
        setShowModal={setShowModal}
        style={{ width: "35vw", maxHeight: "100%" }}
        OnSaveClick={formSubmitHandler}
      >
        <form
          onSubmit={handleSubmit(onAddFormSubmit, onError)}
          ref={ContrtactDateForm}
        >
          <div className="row px-2 mt-1">
            <div className="field col-12 md:col-2 mb-0">
              <span className="p-float-label">
                <Controller
                  name="startDate"
                  control={control}
                  rules={{
                    required: "Contract Start Date is required.",
                  }}
                  render={({ field, fieldState }) => (
                    <Calendar
                      id="startDate"
                      className={classNames("w-100 error-tooltip", {
                        error: fieldState.invalid,
                      })}
                      dateFormat="yy-mm-dd"
                      {...field}
                      value={field.value}
                      onChange={(e) => field.onChange(e.value)}
                    />
                  )}
                />
                <label htmlFor="calendar">
                  Contract Start Date
                </label>
                {getFormErrorMessage("startDate")}
              </span>
            </div>
          </div>

          <div className="row px-2">
            <div className="field col-12 md:col-2 mb-0">
              <span className="p-float-label">
                <Controller
                  name="endDate"
                  control={control}
                  rules={{
                    validate: () => {
                        return getValues("endDate") <= new Date() ? "Contract End Date should be a future date" : undefined;
                    }
                }}
                  render={({ field, fieldState }) => (
                    <Calendar
                      id="endDate"
                      className={classNames("w-100 error-tooltip", {
                        error: fieldState.invalid,
                      })}
                      dateFormat="yy-mm-dd"
                      {...field}
                      value={field.value}
                      onChange={(e) => field.onChange(e.value)}
                    />
                  )}
                />
                <label  htmlFor="calendar">
                  Contract End Date
                </label>
                {getFormErrorMessage("endDate")}
              </span>
            </div>
          </div>

          <div className="row px-2">
            <div className="field col-12 md:col-2">
              <span className="p-float-label text-container">
                <label htmlFor="inputtext">Planned Start Date</label>
                 <span className="text-value">{plannedStartDate}</span>
              </span>
            </div>
          </div>

          <div className="row px-2">
            <div className="field col-12 md:col-2 py-0 contractplan-enddate">
              <span className="p-float-label text-container">
                <label htmlFor="inputtext">Planned End Date</label>
                <span className="text-value">{plannedEndDate}</span>
              </span>
            </div>
          </div>
        </form>
      </AddModal>
      <InformationBox showInfoModal={displayInfoModal} setShowInfoModal={setDisplayInfoModal} message={message} />
    </>
  );
};

export default ContractDate;
