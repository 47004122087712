import React, { memo, useEffect, useState } from 'react'
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { useDispatch } from 'react-redux';
import { setSelectedBranchOfficeData, setSelectedEquipmentTypeData, setSelectedModelData, setSelectedPanelTypedata } from 'redux/pages/Reports/SalesActivitySlice';

interface CommonFilterType {
    showModal: boolean;
    onHide: (checkedData) => void;
    gridData?: any;
    selectedData?: any;
    field?: any;
    header?: any;
    title?: any
    dataKey?: any
}

const CommonFilter: React.FC<CommonFilterType> = (props) => {
    const { showModal, onHide = () => { }, gridData, selectedData, field, header, title, dataKey } = props;
    const [selection, setSelection] = useState(null)
    const dispatch = useDispatch<any>();

    useEffect(() => {
        if (selectedData) {
            setSelection(selectedData)
        }
    }, [selectedData])

    useEffect(() => {
        if (header === "Branch Office") {
            dispatch(setSelectedBranchOfficeData(selection))
        } else if (header === "Panel Type") {
            dispatch(setSelectedPanelTypedata(selection))
        } else if (header === "Equipment Type") {
            dispatch(setSelectedEquipmentTypeData(selection))
        } else if (header === "Model") {
            dispatch(setSelectedModelData(selection))
        }
    }, [selection])

    return (
        <Dialog
            showHeader={false}
            visible={showModal}
            onHide={() => onHide(selection)}
            className="SalesActivity"
        >
            <div className="custom-header">
                <div className="row">
                    <div className="col-6 box py-1 pr-0 pl-1">
                        <div className="title">{title}</div>
                    </div>

                    <div className="col-6 box py-1 pl-0 align-items-end pr-1">
                        <i className="pi pi-times" onClick={() => { onHide(selection) }}></i>
                    </div>
                </div>
            </div>
            <div className='custom-table'>
                <DataTable
                    value={gridData}
                    selection={selection}
                    onSelectionChange={e => setSelection(e.value)}
                    dataKey={dataKey}
                    responsiveLayout="scroll">
                    <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>
                    <Column field={field} header={header}></Column>
                </DataTable> 
            </div>
        </Dialog>
    )
}

export default memo(CommonFilter);



  