export const TREE_DATA = [
    {
        hasDataFields: true,
        isTreeNode: true,
        key: "overview",
        label: "Overview",
        level: 1,
        parentGroupId: 0,
        position: 1,
        children: []
    },
    {
        hasDataFields: true,
        isTreeNode: true,
        key: "techAdavanceModules",
        label: "Modules in Tech Advance⁺ ",
        level: 1,
        parentGroupId: 0,
        position: 2,
        children: []
    },
    {
        hasDataFields: true,
        isTreeNode: true,
        key: "dashboard",
        label: "Dashboard",
        level: 1,
        parentGroupId: 0,
        position: 3,
        children: []
    },
    {
        hasDataFields: true,
        isTreeNode: true,
        key: "administration",
        label: "Administration",
        level: 1,
        parentGroupId: 0,
        position: 5,
        children: [
            {
                hasDataFields: true,
                isTreeNode: true,
                key: "administration-customers",
                label: "Customers",
                level: 1,
                parentGroupId: "administration",
                position: 1,
                children: []
            },
            {
                hasDataFields: true,
                isTreeNode: true,
                key: "users",
                label: "Users",
                level: 1,
                parentGroupId: "administration",
                position: 2,
                children: []
            },
            {
                hasDataFields: true,
                isTreeNode: true,
                key:"administration-sites",
                label: "Sites",
                level: 1,
                parentGroupId: "administration",
                position: 3,
                children: []
            },
            {
                hasDataFields: true,
                isTreeNode: true,
                key: "administration-buildings",
                label: "Buildings",
                level: 1,
                parentGroupId: "administration",
                position: 4,
                children: []
            },
            {
                hasDataFields: true,
                isTreeNode: true,
                key: "administration-floors",
                label: "Floors",
                level: 1,
                parentGroupId: "administration",
                position: 5,
                children: []
            },
            {
                hasDataFields: true,
                isTreeNode: true,
                key: "administration-search",
                label: "Search",
                level: 1,
                parentGroupId: "admininstration",
                position: 6,
                children: []
            },
            {
                hasDataFields: true,
                isTreeNode: true,
                key: "administration-search-contract",
                label: "Search-Contract",
                level: 1,
                parentGroupId: "admininstration",
                position: 7,
                children: []
            },
            {
                hasDataFields: true,
                isTreeNode: true,
                key: "administration-search-customer",
                label: "Search-Customer",
                level: 1,
                parentGroupId: "admininstration",
                position: 8,
                children: []
            },
            {
                hasDataFields: true,
                isTreeNode: true,
                key: "administration-search-inspection-task",
                label: "Search-Inspection Task",
                level: 1,
                parentGroupId: "admininstration",
                position: 9,
                children: []
            },
            {
                hasDataFields: true,
                isTreeNode: true,
                key: "administration-search-notification-number",
                label: "Search-Notification Number",
                level: 1,
                parentGroupId: "admininstration",
                position: 10,
                children: []
            },
            {
                hasDataFields: true,
                isTreeNode: true,
                key: "administration-search-site",
                label: "Search-Site",
                level: 1,
                parentGroupId: "admininstration",
                position: 11,
                children: []
            },
            {
                hasDataFields: true,
                isTreeNode: true,
                key: "administration-search-system",
                label: "Search-System",
                level: 1,
                parentGroupId: "admininstration",
                position: 12,
                children: []
            },
            {
                hasDataFields: true,
                isTreeNode: true,
                key: "administration-search-user",
                label: "Search-User",
                level: 1,
                parentGroupId: "admininstration",
                position: 13,
                children: []
            },
        ],
    },
    {
        hasDataFields: true,
        isTreeNode: true,
        key: "inspection",
        label: "Inspection",
        level: 1,
        parentGroupId: 0,
        position: 6,
        children: [
            {
                hasDataFields: true,
                isTreeNode: true,
                key: "inspection-tasks",
                label: "Inspection Tasks",
                level: 1,
                parentGroupId: "inspection",
                position: 1,
                children: []
            },
            {
                hasDataFields: true,
                isTreeNode: true,
                key: "inspection-task-details",
                label: "Inspection Tasks - Details",
                level: 1,
                parentGroupId: "inspection",
                position: 2,
                children: []
            },
            {
                hasDataFields: true,
                isTreeNode: true,
                key: "inspection-task-drawings",
                label: "Inspection Tasks - Drawings",
                level: 1,
                parentGroupId: "inspection",
                position: 3,
                children: []
            },
            {
                hasDataFields: true,
                isTreeNode: true,
                key: "contracts",
                label: "Contracts",
                level: 1,
                parentGroupId: "inspection",
                position: 4,
                children: []
            },
            {
                hasDataFields: true,
                isTreeNode: true,
                key: "systems",
                label: "Systems",
                level: 1,
                parentGroupId: "inspection",
                position: 5,
                children: []
            },
            {
                hasDataFields: true,
                isTreeNode: true,
                key: "inspection-reports",
                label: "Inspection Reports",
                level: 1,
                parentGroupId: "inspection",
                position: 6,
                children: []
            },
            {
                hasDataFields: true,
                isTreeNode: true,
                key: "Inspection-GenerateReports",
                label: "Inspection Generate Reports",
                level: 1,
                parentGroupId: "inspection",
                position: 7,
                children: []
            },
            {
                hasDataFields: true,
                isTreeNode: true,
                key: "inspection-search",
                label: "Search",
                level: 1,
                parentGroupId: "inspection",
                position: 8,
                children: []
            },
            {
                hasDataFields: true,
                isTreeNode: true,
                key: "search-site",
                label: "Search-Site",
                level: 1,
                parentGroupId: "inspection",
                position: 9,
                children: []
            },
            {
                
                hasDataFields: true,
                isTreeNode: true,
                key: "search-customer",
                label: "Search-Customer",
                level: 1,
                parentGroupId: "inspection",
                position: 10,
                children: []
            },
            {
                
                hasDataFields: true,
                isTreeNode: true,
                key: "search-contract",
                label: "Search-Contract",
                level: 1,
                parentGroupId: "inspection",
                position: 11,
                children: []
            },
            {
                
                hasDataFields: true,
                isTreeNode: true,
                key: "search-system",
                label: "Search-System",
                level: 1,
                parentGroupId: "inspection",
                position: 12,
                children: []
            },
            {
                
                hasDataFields: true,
                isTreeNode: true,
                key: "search-inspection-task",
                label: "Search-InspectionTask",
                level: 1,
                parentGroupId: "inspection",
                position: 13,
                children: []
            },
            {
                
                hasDataFields: true,
                isTreeNode: true,
                key: "search-notification-number",
                label: "Search-Notification Number",
                level: 1,
                parentGroupId: "inspection",
                position: 14,
                children: []
            },
            {
                
                hasDataFields: true,
                isTreeNode: true,
                key: "search-user",
                label: "Search-User",
                level: 1,
                parentGroupId: "inspection",
                position: 15,
                children: []
            }

        ]
    },
    {
        hasDataFields: true,
        isTreeNode: true,
        key: "as-builts-overview",
        label: "As Builts",
        level: 5,
        parentGroupId: 0,
        position: 5,
        children: [
            {
                hasDataFields: true,
                isTreeNode: true,
                key: "as-builts",
                label: "Drawings",
                level: 5,
                parentGroupId: 0,
                position: 5,
                children: []
            }
        ]
    },
    {
        hasDataFields: true,
        isTreeNode: true,
        key: "security-settings",
        label: "Manage - Security Settings",
        level: 1,
        parentGroupId: 0,
        position: 7,
        children: [
            {
                hasDataFields: true,
                isTreeNode: true,
                key: "password-policy",
                label: "Password Policy",
                level: 1,
                parentGroupId: "security-settings",
                position: 1,
                children: []
            },
            {
                hasDataFields: true,
                isTreeNode: true,
                key: "mail-domains",
                label: "Mail Domains",
                level: 1,
                parentGroupId: "security-settings",
                position: 2,
                children: []
            }
        ]
    },
    {
        hasDataFields: true,
        isTreeNode: true,
        key: "organization-settings",
        label: "Manage - Organization Settings",
        level: 1,
        parentGroupId: 0,
        position: 8,
        children: [
            {
                hasDataFields: true,
                isTreeNode: true,
                key: "zone",
                label: "Zone",
                level: 1,
                parentGroupId: "organization-settings",
                position: 1,
                children: []
            },
            {
                hasDataFields: true,
                isTreeNode: true,
                key: "sales-office",
                label: "Sales Office",
                level: 1,
                parentGroupId: "organization-settings",
                position: 2,
                children: []
            },
            {
                hasDataFields: true,
                isTreeNode: true,
                key: "branch-office",
                label: "Branch Office",
                level: 1,
                parentGroupId: "organization-settings",
                position: 3,
                children: []
            }
        ]
    },
    {
        hasDataFields: true,
        isTreeNode: true,
        key: "manage-lookups",
        label: "Manage - Look Ups",
        level: 1,
        parentGroupId: 0,
        position: 9,
        children: [
            {
                hasDataFields: true,
                isTreeNode: true,
                key: "health-classifications",
                label: "Health Classfications",
                level: 1,
                parentGroupId: "manage-lookups",
                position: 1,
                children: []
            },
            {
                hasDataFields: true,
                isTreeNode: true,
                key: "nfpa-classifications",
                label: "NFPA Classifications",
                level: 1,
                parentGroupId: "manage-lookups",
                position: 2,
                children: []
            }
        ]
    },
    {
        hasDataFields: true,
        isTreeNode: true,
        key: "inspection-settings",
        label: "Manage - Inspection Settings",
        level: 1,
        parentGroupId: 0,
        position: 10,
        children: [
            {
                hasDataFields: true,
                isTreeNode: true,
                key: "inspection-settings-inspection-fields",
                label: "Inspection Fields",
                level: 1,
                parentGroupId: "inspection-settings",
                position: 1,
                children: []
            },
            {
                hasDataFields: true,
                isTreeNode: true,
                key: "inspection-settings-report-group",
                label: "Reports - Report Groups",
                level: 1,
                parentGroupId: "inspection-settings",
                position: 2,
                children: []
            },
            {
                hasDataFields: true,
                isTreeNode: true,
                key: "inspection-settings-reports",
                label: "Reports - Reports",
                level: 1,
                parentGroupId: "inspection-settings",
                position: 3,
                children: []
            },
            {
                hasDataFields: true,
                isTreeNode: true,
                key: "inspection-settings-reports-templates",
                label: "Reports - Templates",
                level: 1,
                parentGroupId: "inspection-settings",
                position: 4,
                children: []
            }, 
            {
                hasDataFields: true,
                isTreeNode: true,
                key: "inspection-settings-report-configs",
                label: "Reports - Report Configs",
                level: 1,
                parentGroupId: "inspection-settings",
                position: 5,
                children: []
            },
            {
                hasDataFields: true,
                isTreeNode: true,
                key: "inspection-settings-report-mapping",
                label: "Report Mapping",
                level: 1,
                parentGroupId: "inspection-settings",
                position: 6,
                children: []
            }
        ]
    },
    {
        hasDataFields: true,
        isTreeNode: true,
        key: "tools",
        label: "Manage - Tools",
        level: 1,
        parentGroupId: 0,
        position: 11,
        children: [
            {
                hasDataFields: true,
                isTreeNode: true,
                key: "copy",
                label: "Copy",
                level: 1,
                parentGroupId: "tools",
                position: 1,
                children: []
            },
            {
                hasDataFields: true,
                isTreeNode: true,
                key: "move",
                label: "Move",
                level: 1,
                parentGroupId: "tools",
                position: 2,
                children: []
            }
        ]
    },
    {
        hasDataFields: true,
        isTreeNode: true,
        key: "manage-customization",
        label: "Manage - Customization",
        level: 1,
        parentGroupId: 0,
        position: 12,
        children: [
            {
                hasDataFields: true,
                isTreeNode: true,
                key: "common-attributes",
                label: "Attributes- Common Attributes",
                level: 1,
                parentGroupId: "manage-customization",
                position: 1,
                children: []
            },
            {
                hasDataFields: true,
                isTreeNode: true,
                key: "common-attributes-values",
                label: "Attributes - Common Attribute Values",
                level: 1,
                parentGroupId: "manage-customization",
                position: 2,
                children: []
            },
            {
                hasDataFields: true,
                isTreeNode: true,
                key: "equipment-type-attributes",
                label: "Equipment Type Attributes",
                level: 1,
                parentGroupId: "manage-customization",
                position: 3,
                children: []
            },
            {
                hasDataFields: true,
                isTreeNode: true,
                key: "equipment-type-attributes-values",
                label: "Attributes - Equipment Type Attribute Value",
                level: 1,
                parentGroupId: "manage-customization",
                position: 4,
                children: []
            },
            {
                hasDataFields: true,
                isTreeNode: true,
                key: "data-display-settings",
                label: "Data Display Settings",
                level: 1,
                parentGroupId: "manage-customization",
                position: 5,
                children: []
            },
            {
                hasDataFields: true,
                isTreeNode: true,
                key: "data-entry-settings",
                label: "Data Entry Settings",
                level: 1,
                parentGroupId: "manage-customization",
                position: 6,
                children: []
            }
        ]
    },
    {
        hasDataFields: true,
        isTreeNode: true,
        key: "reports",
        label: "Reports",
        level: 1,
        parentGroupId: 0,
        position: 13,
        children: [
            {
                hasDataFields: true,
                isTreeNode: true,
                key: "reports-contracts",
                label: "Contracts",
                level: 1,
                parentGroupId: "reports",
                position: 1,
                children: []
            },
            {
                hasDataFields: true,
                isTreeNode: true,
                key: "reports-inspection-tasks",
                label: "Inspection Tasks",
                level: 1,
                parentGroupId: "reports",
                position: 2,
                children: []
            },
            {
                hasDataFields: true,
                isTreeNode: true,
                key: "reports-inspection-activity",
                label: "Inspection Activity",
                level: 1,
                parentGroupId: "reports",
                position: 3,
                children: []
            },
            {
                hasDataFields: true,
                isTreeNode: true,
                key: "reports-uploaded-final-reports",
                label: "Uploaded Final Reports",
                level: 1,
                parentGroupId: "reports",
                position: 4,
                children: []
            },
            {
                hasDataFields: true,
                isTreeNode: true,
                key: "reports-user-details",
                label: "User Details",
                level: 1,
                parentGroupId: "reports",
                position: 5,
                children: []
            },
            {
                hasDataFields: true,
                isTreeNode: true,
                key: "reports-sites",
                label: "Sites",
                level: 1,
                parentGroupId: "reports",
                position: 6,
                children: []
            },
            {
                hasDataFields: true,
                isTreeNode: true,
                key: "reports-buildings",
                label: "Buildings",
                level: 1,
                parentGroupId: "reports",
                position: 7,
                children: []
            },
            {
                hasDataFields: true,
                isTreeNode: true,
                key: "reports-floors",
                label: "Floors",
                level: 1,
                parentGroupId: "reports",
                position: 8,
                children: []
            },
            {
                hasDataFields: true,
                isTreeNode: true,
                key: "reports-master-data",
                label: "Master Data",
                level: 1,
                parentGroupId: "reports",
                position: 9,
                children: []
            },
            {
                hasDataFields: true,
                isTreeNode: true,
                key: "reports-expired-contracts",
                label: "Expired Contracts",
                level: 1,
                parentGroupId: "reports",
                position: 10,
                children: []
            },
            {
                hasDataFields: true,
                isTreeNode: true,
                key: "user-activity-report",
                label: "User Activity Report",
                level: 1,
                parentGroupId: "reports",
                position: 11,
                children: []
            },
            {
                hasDataFields: true,
                isTreeNode: true,
                key: "sap-import-status-report",
                label: "SAP Import Status",
                level: 1,
                parentGroupId: "reports",
                position: 12,
                children: []
            }
        ]
    },
    {
        hasDataFields: true,
        isTreeNode: true,
        key: "release-notes",
        label: "Release Notes",
        level: 1,
        parentGroupId: 0,
        position: 14,
        children: []
    },
    {
        hasDataFields: true,
        isTreeNode: true,
        key: "plugins",
        label: "Plugins",
        level: 1,
        parentGroupId: 0,
        position: 15,
        children: [
            {
                hasDataFields: true,
                isTreeNode: true,
                key: "plugins-tap-app",
                label: "TAP App",
                level: 1,
                parentGroupId: "plugins",
                position: 1,
                children: []
            },
            {
                hasDataFields: true,
                isTreeNode: true,
                key: "plugins-tap-bridge",
                label: "TAP Bridge",
                level: 1,
                parentGroupId: "plugins",
                position: 2,
                children: []
            }
        ]
    },
    {
        hasDataFields: true,
        isTreeNode: true,
        key: "links",
        label: "Links",
        level: 1,
        parentGroupId: 0,
        position: 16,
        children: []
    },
    {
        hasDataFields: true,
        isTreeNode: true,
        key: "preferences",
        label: "Preferences",
        level: 1,
        parentGroupId: 0,
        position: 17,
        children: [
            {
                hasDataFields: true,
                isTreeNode: true,
                key: "personal-settings",
                label: "Personal Settings",
                level: 1,
                parentGroupId: "preferences",
                position: 1,
                children: []
            },
            {
                hasDataFields: true,
                isTreeNode: true,
                key: "feedback",
                label: "Feedback",
                level: 1,
                parentGroupId: "preferences",
                position: 2,
                children: []
            },
            {
                hasDataFields: true,
                isTreeNode: true,
                key: "send-mail",
                label: "Send Mail",
                level: 1,
                parentGroupId: "preferences",
                position: 3,
                children: []
            }
        ]
    },
    {
        hasDataFields: true,
        isTreeNode: true,
        key: "logbook",
        label: "Logbook",
        level: 1,
        parentGroupId: 0,
        position: 18,
        children: []
    },
    {
        hasDataFields: true,
        isTreeNode: true,
        key: "faqs",
        label: "FAQs",
        level: 1,
        parentGroupId: 0,
        position: 19,
        children: [
            {
                hasDataFields: true,
                isTreeNode: true,
                key: "admininstration-faqs",
                label: "Administration FAQs",
                level: 1,
                parentGroupId: "faqs",
                position: 1,
                children: []
            },
            {
                hasDataFields: true,
                isTreeNode: true,
                key: "inspection-faqs",
                label: "Inspection FAQs",
                level: 1,
                parentGroupId: "faqs",
                position: 2,
                children: []
            },
        ]
    }
];