import React, { useState, useEffect, useCallback } from 'react';

import { Checkbox } from 'primereact/checkbox';
import { classNames } from 'primereact/utils';

type ListItem = {
    id: string,
    siteName: string,
    checked?: boolean,
    selected?: boolean,
    disabled?: boolean
};

interface Props {
    list: Array<ListItem>,
    singleSelect?: boolean,
    getCheckedItems?: (list: Array<ListItem>) => void,
    getSelectedItems?: (list: Array<ListItem>) => void,
    getDisabledItems?: (list: Array<ListItem>) => void,
    headerName?: string,
}

const ITEM_FILTERS = {
    CHECKED: 'CHECKED',
    SELECTED: 'SELECTED',
    DISABLED: 'DISABLED'
};

export const MultiSelect: React.FC<Props> = ({
    list = [],
    singleSelect = false,
    getCheckedItems = () => { },
    getSelectedItems = () => { },
    getDisabledItems = () => { },
    headerName = "Site Name"
}) => {
    const [siteList, setSiteList] = useState<Array<ListItem>>([]);
    const [allChecked, setAllChecked] = useState(false);

    useEffect(() => {
        const modifiedList = list.map(item => {
            item['checked'] = item?.checked || false;
            item['selected'] = item?.selected || false;
            item['disabled'] = item?.disabled || false;

            return item;
        });

        // select first item by default in case singleSelect = true, provided no item is selected by default
        if (singleSelect) {
            const isAnyItemSelectedByDefault = modifiedList.filter(item => item.selected);

            if (isAnyItemSelectedByDefault.length === 0 && modifiedList.length > 0) {
                modifiedList[0]['selected'] = true;
            }
        }

        setSiteList(modifiedList);
    }, [list, singleSelect]);

    // return callback props with checked/selected/disabled list items
    useEffect(() => {
        const checkedItemList = getFilteredItems(siteList, ITEM_FILTERS.CHECKED);
        const selectedItemList = getFilteredItems(siteList, ITEM_FILTERS.SELECTED);
        const disabledItemList = getFilteredItems(siteList, ITEM_FILTERS.DISABLED);

        getCheckedItems(checkedItemList);
        getSelectedItems(selectedItemList);
        getDisabledItems(disabledItemList);

        // set all checked if all table items are checked by default
        updateAllChecked(siteList);
    }, [siteList, getCheckedItems, getSelectedItems, getDisabledItems]);

    const getFilteredItems = (list, type) => {
        switch (type) {
            case ITEM_FILTERS.CHECKED: {
                return list.filter(item => (item?.checked && !item?.disabled));
            }
            case ITEM_FILTERS.SELECTED: {
                return list.filter(item => item?.selected);
            }
            case ITEM_FILTERS.DISABLED: {
                return list.filter(item => item?.disabled);
            }
            default: {
                return list;
            }
        }
    };

    const updateAllChecked = newSiteList => {
        const siteListLength = Array.isArray(newSiteList) && newSiteList.length;
        const checkedItemsLength = newSiteList.filter(item => item?.checked)?.length;

        if (checkedItemsLength && checkedItemsLength === siteListLength) {
            setAllChecked(true);
        } else {
            setAllChecked(false);
        }
    };

    const handleCheckboxClick = useCallback((e, selectedItem) => {
        const modifiedSiteList = siteList.map(item => {
            if (item?.id === selectedItem?.id)
                item['checked'] = e.checked;

            return item;
        });

        setSiteList(modifiedSiteList);

        // update allChecked checkbox
        updateAllChecked(modifiedSiteList);
    }, [siteList]);

    const handleRowClick = useCallback((selectedItem) => {
        setSiteList(siteList.map(item => {
            if (item?.id === selectedItem?.id) {
                item['selected'] = !item['selected'];
            } else if (singleSelect) {
                item['selected'] = false;
            }

            return item;
        }));
    }, [siteList, singleSelect]);

    const handleAllCheckClick = e => {
        setAllChecked(e.checked);

        // update list items

        setSiteList(siteList.map(item => {
            if (!item['disabled']) {
                item['checked'] = e.checked;
            }
            return item;
        }));
    };

    if (siteList.length === 0) {
        return <h6 className='py-1'>No Data found.</h6>;
    }

    return (
        <div className="filtered-result">
            <div className={classNames('field-checkbox site-header', { 'selected': allChecked })}>
                <Checkbox checked={allChecked} onChange={handleAllCheckClick} />
                <label htmlFor="siteHeader" role="button">{headerName}</label>
            </div>
            {
                siteList.map(item => (
                    <div
                        key={item.id}
                        className={classNames('field-checkbox site-item', { 'selected': item?.selected })}
                        role="button"
                        onClick={() => handleRowClick(item)}
                    >
                        <Checkbox inputId={item.id} className={classNames({ 'checked': item?.checked })} checked={item?.checked} onChange={e => handleCheckboxClick(e, item)} disabled={item?.disabled} />
                        <label htmlFor="siteName">{item.siteName.split(",").join(", ")}</label>
                    </div>
                ))
            }
        </div>
    );
}

export default MultiSelect;