import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { zlink, baseURL } from "../../../../service/HttpService"
import { setProgressFlag } from "redux/common/commonSlice";
import { AppThunk } from "../../../store";
import {setOffset} from 'redux/pages/Administration/administrationSlice';

export interface SiteState {
	errors: any;
	siteList: any;
	sitePageDetails: any;
	dependencyForSite:any;
	siteToEdit:any;
	activeSite:any;
	siteSearchId:any;
	reportPreviewSession: any;
	attachmentList: any;
	attachmentPageDetails:any;
	siteCustomerList: any;
	newSite:any;
	siteAttachmentCountList:any;
}

let initialState: SiteState = {
	errors: "",
	siteList: [],
	sitePageDetails: [],
	dependencyForSite:[],
	siteToEdit:"",
	activeSite:[],
	siteSearchId:"",
	reportPreviewSession: "",
	attachmentList: [],
	attachmentPageDetails: [],
	siteCustomerList: [],
	newSite:"",
	siteAttachmentCountList:[],
};

const SiteSlice = createSlice({
	name: "site",
	initialState,
	reducers: {
		setErrors: (state, { payload }: PayloadAction<any>) => {
			state.errors = payload;
		},
		setSiteList: (state, { payload }: PayloadAction<any>) => {
			state.siteList = payload;
		},
		setSitePageDetails: (state, { payload }: PayloadAction<any>) => {
			state.sitePageDetails = payload;
		},
		setNewSiteData: (state, { payload }: PayloadAction<any>) => {
			state.siteList.unshift(payload);
			state.newSite = payload;
		},
		setDependencyForSite: (state, { payload }: PayloadAction<any>) => {
			state.dependencyForSite = payload;
		},
		setDeletedSiteList(state, { payload }: PayloadAction<any>) {
			state.siteList = state.siteList.filter((item: any) => item.uniqueSiteId !== payload);
		},
		setSiteToEdit: (state, { payload }: PayloadAction<any>) => {
			state.siteToEdit = payload;
		},
		setUpdatedSite: (state, { payload }: PayloadAction<any>) => {
			let currentIndex = state.siteList.findIndex((item: any) => item.uniqueSiteId === payload?.uniqueSiteId);
			state.siteList = state.siteList.filter((item: any) => item.uniqueSiteId !== payload?.uniqueSiteId);
			state.siteList.splice(currentIndex, 0, payload);
		},
		setActiveSiteForSite: (state, { payload }: PayloadAction<any>) => {
			state.activeSite = payload;
		},
		setSiteSearchId: (state, { payload }: PayloadAction<any>) => {
			state.siteSearchId = payload;
		},
		setReportPreviewSession: (state, { payload }: PayloadAction<any>) => {
			state.reportPreviewSession = payload;
		},
		setAttachmentList: (state, {payload}: PayloadAction<any>) => {
			state.attachmentList = payload;
		},
		setAttachmentPageDetails: (state, {payload}: PayloadAction<any>) => {
			state.attachmentPageDetails = payload;
		},
		setNewAttachmentData: (state, { payload }: PayloadAction<any>) => {
			state.attachmentList.unshift(...payload);
		},
		setUpdatedAttachement: (state, { payload }: PayloadAction<any>) => {
			let currentIndex = state.attachmentList.findIndex((item: any) => item.attachmentId === payload?.attachmentId);
			state.attachmentList = state.attachmentList.filter((item: any) => item.attachmentId !== payload?.attachmentId);
			state.attachmentList.splice(currentIndex, 0, payload);
		},
		setDeletedAttachmentList(state, { payload }: PayloadAction<any>) {
			state.attachmentList = state.attachmentList.filter((item: any) => item.attachmentId !== payload);
		},
		setSiteCustomerList: (state, { payload }: PayloadAction<any>) => {
			state.siteCustomerList = payload;
		},
		setSiteAttachmentCountList(state, { payload }: PayloadAction<any>) {
			let currentIndex = state.siteList.findIndex((item: any) => item.uniqueSiteId === payload?.uniqueSiteId);
			state.siteList = state.siteList.filter((item: any) => item.uniqueSiteId !== payload?.uniqueSiteId);
			state.siteList.splice(currentIndex, 0, payload);
		},
		setNewAttachmentList(state, { payload }: PayloadAction<any>) {
			payload.map((element: any) =>  {
				let currentIndex = state.siteList.findIndex((item: any) => item.uniqueSiteId === element?.uniqueSiteId);
				state.siteList = state.siteList.filter((item: any) => item.uniqueSiteId !== element?.uniqueSiteId);
				state.siteList.splice(currentIndex, 0,element);
				});
		},
	},
})

export const getSiteList = (currentStart, keyword: any = "", sortField: any = "", sortOrder: any = ""): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.get(`${baseURL}api/v1/admin/site/sitelist?keyword=${keyword}&currentstart=${currentStart}&sortcolumn=${sortField}&sortorder=${sortOrder}`);
		if (res.status === "done") {
			dispatch(setSiteList(res?.data?.siteDetails));
			dispatch(setSitePageDetails(res?.data?.pageDetails));
		} else {
			dispatch(setErrors(res.error));
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const onAddSite =
	(body: any, setMessage, setDisplayInfoModal, setShowAddModal, setSelectedRows?): AppThunk =>
		async (dispatch) => {
			try {
				const res = await zlink.post(`${baseURL}api/v1/admin/site/add`, body);
				if (res.status === "done") {
					dispatch(setNewSiteData(res.data));
					if (setSelectedRows)
						setSelectedRows([res.data]);
					setMessage("Site added");
					setShowAddModal(false);
					setDisplayInfoModal(true);
				} else {
					dispatch(setErrors(res.error));
					setMessage(res.message);
					setDisplayInfoModal(true);
				}
			} catch (error) {
				dispatch(setErrors(error));
			}
		};

export const getDependencyForSite = (uniqueSiteId): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.post(`${baseURL}api/v1/admin/site/${uniqueSiteId}/dependencyforsite`, null);
		if (res.status === "done") {
			dispatch(setDependencyForSite(res?.data));
		} else {
			dispatch(setErrors(res.error));
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const deleteSite = ({ uniqueSiteId, setSelectedRows, setMessage, setDisplayInfoModal }): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.post(`${baseURL}api/v1/admin/site/${uniqueSiteId}/remove`, null);
		if (res.status === "done") {
			dispatch(setDeletedSiteList(uniqueSiteId));
			setSelectedRows([]);
			setMessage("Site deleted");
			setDisplayInfoModal(true);
		} else {
			setSelectedRows([]);
			setMessage(res.message);
			setDisplayInfoModal(true);
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const getSiteToEditDetails = (uniqueSiteId): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.get(`${baseURL}api/v1/admin/site/${uniqueSiteId}`);
		if (res.status === "done") {
			dispatch(setSiteToEdit(res?.data));
		} else {
			dispatch(setErrors(res.error));
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const onUpdateSite =
	(uniqueSiteId, body: any, setMessage, setDisplayInfoModal, setShowEditModal, setSelectedRows, inlineEdit: boolean,loggedInUserDetails): AppThunk =>
		async (dispatch) => {
			try {
				const res = await zlink.post(`${baseURL}api/v1/admin/site/${uniqueSiteId}/update`, body);
				if (res.status === "done") {
					dispatch(setUpdatedSite(res?.data));
					if (!inlineEdit) {
						setSelectedRows(prev => {
							let arr = prev;
							arr[0]={...res?.data};
							return arr;
					})
					}
					if(loggedInUserDetails?.userDefaults?.uniqueSiteId === uniqueSiteId && res?.data?.siteOffset !== loggedInUserDetails?.userDefaults?.siteOffset)
					{
							dispatch(setOffset(res?.data?.siteOffset));
					}
					setMessage("Site updated");
					setShowEditModal(false);
					setDisplayInfoModal(true);
				} else {
					dispatch(setErrors(res.error));
					setMessage(res.message);
					setDisplayInfoModal(true);
				}
			} catch (error) {
				dispatch(setErrors(error));
			}
		};

export const setAsActiveSiteForSite = (uniqueSiteId: any, setDisplayInfoModal: any, setMessage: any, setIsNavigate?: any): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.post(`${baseURL}api/v1/admin/site/${uniqueSiteId}/setasactivesite`, null);
		if (res.status === "done") {
			dispatch(setActiveSiteForSite(res.data));
			if (setIsNavigate) {
				setIsNavigate(true);
			}
		} else {
			setMessage(res.message);
			setDisplayInfoModal(true);
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const getSiteSearchDetailsList = (uniqueSiteId: any, setShowSiteGrid): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.get(`${baseURL}api/v1/common/search/site/${uniqueSiteId}/sitelist`);
		if (res.status === "done") {
			dispatch(setSiteList(res?.data?.siteDetails));
			dispatch(setSitePageDetails(res?.data?.pageDetails));
			dispatch(setSiteSearchId(""));
			setShowSiteGrid(true);
		} else {
			dispatch(setErrors(res.error));
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const createReportPreviewSession = (reportParameters: any): AppThunk =>
	async (dispatch) => {
		try {
			 dispatch(setProgressFlag(true))
			const res = await zlink.post(`${baseURL}api/v1/reports/preview/newsession`, reportParameters);
			if (res.status === "done") {
				dispatch(setProgressFlag(false))
				dispatch(setReportPreviewSession(res.data));
			} else {
				dispatch(setProgressFlag(false))
				dispatch(setErrors(res.error));
			}
		} catch (error) {
			dispatch(setProgressFlag(false))
			dispatch(setErrors(error));
		}
	};

export const getSiteCustomerList = (uniqueSiteId: any, keyword?: String): AppThunk => async (dispatch) => {	
	try {
		const res = await zlink.get(`${baseURL}api/v1/sites/${uniqueSiteId}/customers?keyword=${keyword ? keyword : ''}`);
		if (res.status === "done") {
			dispatch(setSiteCustomerList(res?.data?.customerDetails));
		} else {
			dispatch(setErrors(res.error));
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const getAttachmentList = (uniqueId, sortField: any = "", sortOrder: any = ""): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.get(`${baseURL}api/v1/sites/${uniqueId}/attachments?sortcolumn=${sortField}&sortorder=${sortOrder}`);
		if (res.status === "done") {
			dispatch(setAttachmentList(res?.data?.attachmentList));
			dispatch(setAttachmentPageDetails(res?.data?.pageDetails));
		} else {
			dispatch(setErrors(res.error));
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const onUploadAttachment =
    (uniqueId,selectedRows,body,setShowAddModal,setMessage,setDisplayInfoModal, setEnableUpload?: any): AppThunk =>
      async (dispatch) => {
        try {
          const res = await zlink.post(`${baseURL}api/v1/sites/${uniqueId}/attachments/add`, body);
         
          if (res.status === "done") {
			  if (res.data && res.data.attachmentList && res.data.attachmentList.length) {
				  var count = Number(selectedRows.attachments) + res.data.attachmentList.length;
				  dispatch(setSiteAttachmentCountList({ ...selectedRows, 'attachments': count }));
				  dispatch(setNewAttachmentData(res.data?.attachmentList));
				  setShowAddModal(false);
				  setMessage("Attachment uploaded");
				  setDisplayInfoModal(true);
			  }
          } else {
			setEnableUpload(false);
			setDisplayInfoModal(true);
            setMessage(res.message);
          }
        } catch (error) {
          dispatch(setErrors(error));
        }
};

export const onUploadMultipleSiteAttachment =(body,setShowAddModal,setMessage,setDisplayInfoModal, setEnableUpload?: any): AppThunk =>
      async (dispatch) => {
        try {
          const res = await zlink.post(`${baseURL}api/v1/sites/attachments/add`, body);
         
          if (res.status === "done") {
			  if (res.data && res.data.siteDetails && res.data.siteDetails.length) {
				  dispatch(setNewAttachmentList(res.data?.siteDetails));
				  setShowAddModal(false);
				  setMessage("Attachment uploaded");
				  setDisplayInfoModal(true);
			  }
          } else {
			setEnableUpload(false);
			setDisplayInfoModal(true);
            setMessage(res.message);
          }
        } catch (error) {
          dispatch(setErrors(error));
        }
};

export const onEditAttachment =
	(uniqueId: any = "", attachmentId: any = "", body: any, setShowEditModal, setMessage: any = "", setDisplayInfoModal, setEnableUpload?: any): AppThunk =>
		async (dispatch) => {
			try {
				const res = await zlink.post(`${baseURL}api/v1/sites/${uniqueId}/attachments/${attachmentId}`, body);

				if (res.status === "done") {
					dispatch(setUpdatedAttachement(res?.data));
					setShowEditModal(false);
					setMessage("Attachment uploaded");
					setDisplayInfoModal(true);
				} else {
					setEnableUpload(false);
					setMessage(res.message);
					setDisplayInfoModal(true);
				}
			} catch (error) {
				dispatch(setErrors(error));
			}
		};

export const onDeleteAttachment = (uniqueId,attachmentId,fullPath,setMessage,setDisplayConfirmModal,setSelectedRows,setDisplayInfoModal,selectedRows): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.post(`${baseURL}api/v1/sites/${uniqueId}/attachments/${attachmentId}/remove`,{filePath:fullPath});
		if (res.status === "done") {	
			let count = Number(selectedRows.attachments) - 1;
			dispatch(setSiteAttachmentCountList({...selectedRows, 'attachments': count}));
			dispatch(setDeletedAttachmentList(attachmentId));
			setSelectedRows([]);
			setMessage("Attachment deleted")
			setDisplayInfoModal(true)
			setDisplayConfirmModal(false)
		} else {
			dispatch(setErrors(res.error));
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const getAttachmentPath = (uniqueId,attachmentId,setMessage,setDisplayConfirmModal,setSelectedRows,setDisplayInfoModal,selectedRows): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.get(`${baseURL}api/v1/sites/${uniqueId}/attachments/${attachmentId}/file`);
		if (res.status === "done") {
		 dispatch(onDeleteAttachment(uniqueId,attachmentId,res.data?.fullPath,setMessage,setDisplayConfirmModal,setSelectedRows,setDisplayInfoModal,selectedRows))
		} else {
			dispatch(setErrors(res.error));
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const onDownloadAttachmentFile = (attachmentId, FullPath: any, setDisplayInfoModal, setMessage, renderFlag: boolean, setStreamData?: any, fileName?: any): AppThunk => async (dispatch) => {
	try {
		const request: any = {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "application/json; charset=utf-8",
				Authorization: sessionStorage.getItem("accesstoken"),
			},
			body: JSON.stringify({ FullPath: FullPath })
		};
		const downloadUrl = `${baseURL}api/v1/common/files/${attachmentId}/download`
		await fetch(downloadUrl, request).then((response: any) => {

			if (response.status === 200) {
				const res = new Response(
					new ReadableStream({
						async start(controller) {
							const reader = response.body.getReader();
							for (; ;) {
								const { done, value } = await reader.read();
								if (done) break;
								controller.enqueue(value);
							}
							controller.close();
						},
					})
				);
				if (!renderFlag) {
					res.blob().then((blob) => {
						let url = window.URL.createObjectURL(blob);
						let a = document.createElement("a");
						a.href = url;
						a.download = fileName;
						a.click();
					});
				} else {
					res.blob().then((blob) => {
						var result: any
						var reader = new FileReader();
						reader.onload = function (e) {
							result = e?.target?.result;
							setStreamData(result)
						}
						reader.readAsDataURL(blob);

					});
				}
			}
			else {
				setDisplayInfoModal(true)
				setMessage("File not found")
			}
		});

	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const onDownloadAttachment = (uniqueId:any,attachmentId:any,setDisplayInfoModal,setMessage, viewFlag: boolean, setStreamData?:any,fileName?:any, setShowViewModal?: any): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.get(`${baseURL}api/v1/sites/${uniqueId}/attachments/${attachmentId}/file`);
		if (res.status === "done") {
			if (viewFlag) {
				if (fileName) {
					if (fileName.split('.').pop() === "png" || fileName.split('.').pop() === "jpg" || fileName.split('.').pop() === "jpeg") {
						setShowViewModal(true);
						dispatch(onDownloadAttachmentFile(attachmentId, res.data?.fullPath, setDisplayInfoModal, setMessage, true, setStreamData, fileName));
					} else {
						dispatch(onDownloadAttachmentFile(attachmentId, res.data?.fullPath, setDisplayInfoModal, setMessage, false, setStreamData, fileName));
					}
				}
			} else {
				dispatch(onDownloadAttachmentFile(attachmentId, res.data?.fullPath, setDisplayInfoModal, setMessage, false, setStreamData, fileName));
			}
		} else {
			dispatch(setErrors(res.error));
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};
export const onUpdateSiteCustomerMapping = (uniqueSiteId: any, body: any, setDisplayInfoModal: any, setMessage: any): AppThunk =>
	async (dispatch) => {
		try {
			const res = await zlink.post(`${baseURL}api/v1/sites/${uniqueSiteId}/customers`, body);
			if (res.status === "done") {
				setDisplayInfoModal(true);
				setMessage("Customer(s) updated");
			} else {
				dispatch(setErrors(res.error));
			}
		} catch (error) {
			dispatch(setErrors(error));
		}
	};

	export const onCloseSite = (uniqueSiteId: any,setDisplayInfoModal: any, setMessage: any,setDisplayConfirmCloseSiteModal,selectedSite,setSelectedRows): AppThunk =>
	async (dispatch) => {
		try {
			const res = await zlink.post(`${baseURL}api/v1/sites/${uniqueSiteId}/close`, {});
			if (res.status === "done") {
				setSelectedRows(prev=>{
					let arr=prev;
					arr[0]={...selectedSite,status:"Closed",siteName:res?.data?.siteName};
					return arr
				})
				dispatch(setUpdatedSite({...selectedSite,status:"Closed",siteName:res?.data?.siteName}));
				setDisplayInfoModal(true);
				setDisplayConfirmCloseSiteModal(false);
				setMessage("Site Closed");
			} else {
				dispatch(setErrors(res.error));
			}
		} catch (error) {
			dispatch(setErrors(error));
		}
	};
	
	export const getCheckSiteForClose = (uniqueSiteId: any,setDisplayInfoModal:any,setMessage:any,setDisplayConfirmCloseSiteModal,selectedSite): AppThunk => async (dispatch) => {
		try {
			const res = await zlink.get(`${baseURL}api/v1/sites/${uniqueSiteId}/checksiteforclose`);
			if (res.status === "done") {
				if(res.data?.hasActiveContract===false&&res.data?.hasActiveSystem===false){	
					setMessage("Are you sure you want to close the selected Site?")
					setDisplayConfirmCloseSiteModal(true)
				}else if(res.data?.hasActiveContract===true){
					setDisplayInfoModal(true)
					setMessage("Active Contract(s) exists for selected site, cannot be closed")
					setDisplayConfirmCloseSiteModal(false)
				}else if(res.data?.hasActiveSystem===true){
					setDisplayInfoModal(true)
					setMessage("Active System(s) exists for selected site, cannot be closed")
					setDisplayConfirmCloseSiteModal(false)
				}
			} else {
				dispatch(setErrors(res.error));
			}
		} catch (error) {
			dispatch(setErrors(error));
		}
	};

	export const onSiteReOpen = (uniqueSiteId: any,setDisplayInfoModal: any, setMessage: any,setDisplayConfirmReopenModal,selectedSite,setSelectedRows): AppThunk =>
	async (dispatch) => {
		try {
			const res = await zlink.post(`${baseURL}api/v1/sites/${uniqueSiteId}/reopen`, {});
			if (res.status === "done") {
				setSelectedRows(prev=>{
					let arr=prev;
					arr[0]={...selectedSite,status:"Active"};
					return arr
				})
				dispatch(setUpdatedSite({...selectedSite,status:"Active"}));
				setDisplayInfoModal(true);
				setDisplayConfirmReopenModal(false);
				setMessage("Site Re-opened");
			} else {
				dispatch(setErrors(res.error));
			}
		} catch (error) {
			dispatch(setErrors(error));
		}
	};

export const {
	setErrors,
	setSiteList,
	setSitePageDetails,
	setNewSiteData,
	setDependencyForSite,
	setDeletedSiteList,
	setSiteToEdit,
	setUpdatedSite,
	setActiveSiteForSite,
	setSiteSearchId,
	setReportPreviewSession,
	setAttachmentList,
	setAttachmentPageDetails,
	setNewAttachmentData,
	setNewAttachmentList,
	setUpdatedAttachement,
	setDeletedAttachmentList,
	setSiteCustomerList,
	setSiteAttachmentCountList
} = SiteSlice.actions;
export default SiteSlice.reducer;
