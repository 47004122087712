import React, { useEffect, useState } from "react";
import ListGroup from 'react-bootstrap/ListGroup';

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

import ReportViewer from "components/common/Report/ReportViewer";
import { useAppSelector } from "redux/hooks";
import { useDispatch } from "react-redux";
import InformationBox from "components/common/Message/InformationBox/InformationBox";
import CustomizeReport from "pages/Reports/CustomizeReport/CustomizeReport";
import { createReportPreviewSession, setReportPreviewSession } from "redux/pages/Administration/Site/siteSlice";
import { setIsAppliedDone } from 'redux/common/report/customizeReportSlice';

interface Props {
    showModal: boolean;
    setShowModal: (state: boolean) => void;
    reportFlag: string;
    selectedRows: any;
    sortByColumns: any;
    searchKeyWord: string;
    sortField: any,
    sortOrder: any
}

export const CustomerReport: React.FC<Props> = ({
    showModal = false,
    setShowModal = () => { },
    reportFlag = '',
    selectedRows = [],
    sortByColumns = [],
    searchKeyWord = '',
    sortField = '',
    sortOrder = ''
}) => {

    const [reportId, setReportId] = useState<any>(null);
    const [showCustomizeReport, setShowCustomizeReport] = useState(false);
    const [message, setMessage] = useState("");
    const [displayInfoModal, setDisplayInfoModal] = useState(false);
    const [customizationType, setCustomizationType] = useState('user');

    const reportPreviewSession = useAppSelector((state) => state.site.reportPreviewSession);
    const isAppliedDone = useAppSelector((state) => state.customizeReport.isAppliedDone);

    const dispatch = useDispatch<any>();

    const getSelectedCustomersId = () => {
        let params: any = [];
        selectedRows.map(item => {
            params.push(item.uniqueCustomerId);
        });
        return params;
    };

    const viewCustomerReport = () => {
        let params;
        if (reportFlag === 'all') {
            params = "";
        } else {
            params = getSelectedCustomersId();
        }
        const reportParameters = {
        
            reportParameters: {
                UniqueCustomerId: params,
                search: searchKeyWord,
                sortColumn: sortField,
                sortorder :sortOrder,
                customizationType: customizationType
            },
            reportType: "Inspection Client Grid Report"
        };
        dispatch(createReportPreviewSession(reportParameters));
    };

    useEffect(() => {
        viewCustomerReport();

        return (() => {
			dispatch(setReportPreviewSession(""));
		})
    }, []);

    useEffect(() => {
        if (isAppliedDone) viewCustomerReport();
    }, [isAppliedDone]);

    useEffect(() => {
        if (reportPreviewSession) {
            setReportId(reportPreviewSession.previewSessionId);
        }
    }, [reportPreviewSession])

    useEffect(() => {
        if (isAppliedDone) {
            dispatch(setIsAppliedDone(false));
            setShowCustomizeReport(false);
            setMessage("Report settings saved");
            setDisplayInfoModal(true);
        }
    }, [isAppliedDone]);

    return (
        <>
            <Dialog
                header="Report"
                visible={showModal}
                onHide={() => {
                    dispatch(setReportPreviewSession(""));
                    setShowModal(false);
                }}
                className="display-settings report selectsite"
                style={{ width: "90vw", height: "600px" }}
            >
                <div className="report-export-main d-flex mb-3">
                    <div className="report-field">
                        <div className="row px-2">
                            <div className="field col-12 md:col-2 mb-0">
                            </div>
                        </div>
                    </div>
                    <div className="report-buttons-main d-flex">
                        <div className="report-buttons">
                            <ListGroup horizontal>
                                <ListGroup.Item><Button label="Customize" className="report-btn" onClick={() => setShowCustomizeReport(true)} /></ListGroup.Item>
                            </ListGroup>
                        </div>
                    </div>
                </div>

                <ReportViewer reportId={reportId} />
                <InformationBox
                    showInfoModal={displayInfoModal}
                    setShowInfoModal={setDisplayInfoModal}
                    message={message}
                />
                {showCustomizeReport &&
                    <CustomizeReport
                        reportType="CUSTOMERS"
                        showModal={showCustomizeReport}
                        onHideModal={() => setShowCustomizeReport(false)}
                        customizationType={customizationType}
                        setCustomizationType={setCustomizationType} />
                }
            </Dialog>
        </>
    );
};

export default CustomerReport;
