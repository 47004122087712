import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { zlink, baseURL } from "../../../../../service/HttpService";
import { AppThunk } from "../../../../store";
import { onDownloadByFileName } from "redux/common/commonSlice";

export interface ReportTemplateState {
  errors: any;
  reportTemplateList: any;
  reportTemplatePageDetails: any;
  newReportTemplate: any;
  deletedReportTemplate: any;
}

let initialState: ReportTemplateState = {
  errors: "",
  reportTemplateList: [],
  reportTemplatePageDetails: [],
  newReportTemplate: "",
  deletedReportTemplate: "",
};

const ReportTemplateSlice = createSlice({
  name: "reporttemplate",
  initialState,
  reducers: {
    setErrors: (state, { payload }: PayloadAction<any>) => {
      state.errors = payload;
    },
    setReportTemplateList: (state, { payload }: PayloadAction<any>) => {
      state.reportTemplateList = payload;

     
    },
    setReportTemplatePageDetails: (state, { payload }: PayloadAction<any>) => {
      state.reportTemplatePageDetails = payload;
    },
    setNewReportTemplate: (state, { payload }: PayloadAction<any>) => {
      state.newReportTemplate = payload;
    },
    setDeletedReportTemplate: (state, { payload }: PayloadAction<any>) => {
      state.deletedReportTemplate = payload;
    },
  },
});

export const getReportTemplateList =
  (currentStart,keyword,sortField,sortOrder): AppThunk =>
  async (dispatch) => {
    try {
      const res = await zlink.get(`${baseURL}api/v1/reporttemplates?currentStart=${currentStart}&keyword=${keyword}&sortcolumn=${sortField}&sortorder=${sortOrder}`);
      if (res.status === "done") {
        dispatch(setReportTemplateList(res?.data?.reportTemplateDetails));
        dispatch(setReportTemplatePageDetails(res?.data?.pageDetails));
      } else {
        dispatch(setErrors(res.error));
      }
    } catch (error) {
      dispatch(setErrors(error));
    }
  };

  export const onAddReportTemplate =
  (body,setMessage,setDisplayInfoModal): AppThunk =>
    async (dispatch) => {
      try {
        const res = await zlink.post(`${baseURL}api/v1/reporttemplates/add `, body);
        if (res.status === "done") {
          dispatch(setNewReportTemplate(res?.data?.reportTemplateDetails ));
          setDisplayInfoModal(true);
          setMessage("Report Template added");
        } else {
          setDisplayInfoModal(true);
          setMessage(res.message);
        }
      } catch (error) {
        dispatch(setErrors(error));
      }
};

export const deleteReportTemplate = (body, setMessage, setDisplayInfoModal): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.post(`${baseURL}api/v1/reporttemplates/remove`, body);
    if (res.status === "done") {
      dispatch(setDeletedReportTemplate(res))
      setMessage("Report Template deleted");
      setDisplayInfoModal(true);
    } else {
      setMessage(res.message);
      setDisplayInfoModal(true);
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const getDownloadTemplateFilePath = (body:any, setMessage: any, setDisplayInfoModal: any,): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.post(`${baseURL}api/v1/reporttemplates/file`,body);
            if (res.status === "done") {
              dispatch(onDownloadByFileName(res?.data?.fileName, res?.data?.fullPath, setMessage, setDisplayInfoModal));
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

export const { setErrors,
  setReportTemplateList,
  setReportTemplatePageDetails,
  setNewReportTemplate,
  setDeletedReportTemplate } =
  ReportTemplateSlice.actions;
export default ReportTemplateSlice.reducer;
