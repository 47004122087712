import React from "react";
import { Button } from "primereact/button";
import { MdOutlineBrightness5, MdOutlineListAlt } from "react-icons/md";

const InspectionDocuments = () => {
    return (
        <>
            <h4 className="doc-title mb-2">
                Overview of Inspection Module
            </h4>

            <p>
                Tech Advance<sup>+ </sup>
                Inspection module facilitates the management of Fire and Life Safety inspections by
                providing organized maintenance of Contracts and documentation of the Fire Protection Systems
                with all System, Equipment Group, Module, and Device information,
                with related floor plan drawings and any other available documents.
                Inspection Tasks can be created and managed through the system,
                and all inspection data can be stored in the System.
            </p>

            <p>
                The Inspection module functions are grouped under the menu options -
                Inspection Tasks, Contracts, Systems, Inspection Reports and Search.
                All settings options are grouped under
                <span className="doc-subtitle"> Manage </span>
                <Button className="doc-button-icon">
                    <MdOutlineBrightness5 className="icon" title="Manage" />
                </Button>
                {" "} icon in the top right corner of the application.Inspection related functions
                such as creating Contracts, Systems and Inspection Tasks, and viewing and
                editing Inspection data are done through the menu options -
                Contracts, Systems and Inspection Tasks respectively.
                Inspection Reports options are for generating inspection reports and uploading them for sites,
                where as Administrative Reports are for Administrators, to view the User Activity Reports,
                Contracts, Tasks etc.are provided in the
                <span className="doc-subtitle"> Reports </span>
                <Button className="doc-button-icon">
                    <MdOutlineListAlt className="icon" title="Reports" />
                </Button>
                {" "} icon in the top right corner of the application.
                Search option provides a Global Search of Sites, Contracts, Systems, Inspection Tasks and Notification Number.
            </p>

            <p>
                The accessibility of the options in this module is restricted based on the user role.
                The Super Administrator can access all the options in the module. Support users are 
                restricted to all the options, still have access to most of the settings in the module.
                Admin-Tech, Admin and Technicians can access only Inspection related menu options,
                Reports and the download options in the Plugins. Branch Users only have access to view 
                Inspection related menu, Reports and download options.
            </p>

            <p>
                Administrators and Technicians can select the required Sites and setup the system for 
                conducting the inspections and publishing the reports. Users can select/change a Site 
                by clicking the link provided in the Site Name, which on the top - right corner of the 
                screen of Tech Advance<sup>+</sup>. On clicking this link, Select Site page will be 
                displayed with a drop down listing the Country, Zone, Select Customer and a list box to 
                select the Site. User can type minimum three characters of the required Site; the Site
                Name will be listed in the drop down and on selecting the Site Name, User can be changed
                to the selected Site.
            </p>

            <p>
                When a user logs in for the first time, no Site will be selected by default. Once a user
                has logged in and selected a Site through the link "Selected Site [Change]" provided on 
                the top - right corner of the screen. For all subsequent logins, the last selected Site will
                be selected by default. Users have to ensure that the required Site is displayed against the
                Selected Site at the top of the screen before doing any Site specific operations like Adding
                Customers, Buildings, Floors, Inspection Tasks, Contracts, Systems, etc.
            </p>

        </>
    );
}

export default InspectionDocuments;