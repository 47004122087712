export function getMasterDataListGridColumns(generalProperties: any) {
    const columnInfo: any = [];
    if (generalProperties != null) {
        for (let i = 0; i < generalProperties?.length; i++) {
            if (generalProperties[i]?.propertyType !== "INTERNAL") {
                let data = {
                    field: generalProperties[i]?.name,
                    header: generalProperties[i]?.alias,
                    style: { flex: '1 0 200px' },
                    sortable: true,
                }
                columnInfo.push(data);
            }
        }
    }
    return columnInfo;
}

