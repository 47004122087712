import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "components/common/Breadcrumb/Breadcrumb";
import Table from "components/Table/Table";
import InformationBox from "components/common/Message/InformationBox/InformationBox";
import ConfirmationBox from "components/common/Message/ConfirmationBox/ConfirmationBox";
import { BsArrowRight } from "react-icons/bs";
import { HEALTH_CLASSIFICATION_COLS } from "./HealthClassificationData";
import { APP_ROUTES } from "constants/AppRoutes";
import { useAppSelector } from "redux/hooks";
import {
  getHealthClassificationList,
  onAddHealthClassification,
  onEditHealthClassification,
  deleteHealthClassification,
  checkHealthClassifictionInUse,
  setHealthClassificationList,
} from "redux/pages/Manage/Lookups/HealthClassification/healthClassificationSlice";
import { getCountryList } from "redux/pages/Administration/administrationSlice";
import { useDispatch } from "react-redux";

import AddHealthClassification from "../HealthClassification/ManageHealthClassification/AddHealthClassification";

import EditHealthClassification from "../HealthClassification/ManageHealthClassification/EditHealthClassification";
import PopOver from "components/common/PopOver/PopOver";
import HealthClassificationReport from "pages/ManageLookups/HealthClassification/Reports/HealthClassificationReports";
import { OverlayPanel } from "primereact/overlaypanel";
import { onExportReport } from "redux/common/report/customizeReportSlice";
import { Overlay, Tooltip } from "react-bootstrap";

interface IHealthClassification {}
export const HealthClassification: React.FC<IHealthClassification> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const downloadRef = React.createRef<OverlayPanel>();

  const healthClassificationList = useAppSelector(
    (state) => state.healthClassification.healthClassificationList
  );
  const healthClassificationPageDetails = useAppSelector(
    (state) => state.healthClassification.healthClassificationPageDetails
  );
  const countryList = useAppSelector(
    (state) => state.administration.countryList
  );
  const healthClassifictionInUse = useAppSelector(
    (state) => state.healthClassification.healthClassifictionInUse
  );
  const rowsPerPage = useAppSelector((state) => state.personalSettings.rowsPerPage);
  const [pageDetails, setPageDetails] = useState<any>({});
  const [pageIndex, setPageIndex] = useState(0);
  const [selectedCountry, setSelectedCountry] = useState<any>("");
  const [selectedCountryId, setSelectedCountryId] = useState<any>("");
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [showGrid, setShowGrid] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [message, setMessage] = useState("");
  const [displayInfoModal, setDisplayInfoModal] = useState(false);
  const [displayConfirmModal, setDisplayConfirmModal] = useState(false);
  const [currentStart, setCurrentStart] = useState(1);
  const [sortField, setSortField] = useState('healthClassification');
  const [sortOrder, setSortOrder] = useState('ASC');
  const [keyWord, setKeyWord] = useState('');
  const [isDelete, setIsDelete] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [reportFlag, setReportFlag] = useState<any>('all');
  const [countryLookup, setCountryLookup] = useState<any>([]);
  const [target, setTarget] = useState(null);
  const [rowNumber, setRowNumber] = useState("");
  const [field, SetField] = useState("");

  useEffect(() => {
    dispatch(getCountryList());
  }, [dispatch]);

  useEffect(() => {
    if (countryList && countryList.countries.length > 0 && countryList.countries !== undefined) {
      let country: any = [];
      countryList.countries.map((countryItem: any, key: any) => {
        country.push({ "code": countryItem.countryId, "name": countryItem.countryName.trim() })
      });
      setSelectedCountry(country[0].name);
      setSelectedCountryId(country[0].code);
      setCountryLookup(country);
    }
  }, [countryList]);

  useEffect(() => {
    dispatch(setHealthClassificationList([]))
    if(selectedCountry!== "")
    {
      let countrySelected: any = {};
    countrySelected = { countryName: selectedCountry };
    dispatch(getHealthClassificationList(countrySelected, 1, keyWord, sortField, sortOrder, setShowGrid));
    }
    if(countryLookup.length>0){
      countryLookup.map((item: any, key: any) => {
        if (selectedCountry === item.name) {
          setSelectedCountryId(item.code);
        }
      });
    }
  }, [dispatch, selectedCountry, rowsPerPage]);

  useEffect(() => {
    if (healthClassificationPageDetails) {
      setPageDetails(healthClassificationPageDetails);
    }
  }, [healthClassificationPageDetails])

  useEffect(() => {
    if (rowsPerPage && rowsPerPage > 0) {
      let modifiedPageDetails = { ...pageDetails, 'currentLength': rowsPerPage, 'pageCount': rowsPerPage };
      setPageDetails(modifiedPageDetails);
      setCurrentStart(1)
      setPageIndex(0)
    }
  }, [rowsPerPage])

  const onPageChanged = (options: any) => {
    if (pageIndex !== options) {  
    let currentStart =
      options * pageDetails?.currentLength + 1;
    setCurrentStart(currentStart)
    setPageIndex(options);
    let countrySelected: any = {};
    countrySelected = { countryName: selectedCountry };
    dispatch(
      getHealthClassificationList(countrySelected, currentStart, keyWord, sortField, sortOrder, setShowGrid)
    );
    }
  };

  const onGlobalSearch = (filter) => {
    setKeyWord(filter)
    let countrySelected: any = {};
    countrySelected = { countryName: selectedCountry };
    dispatch(getHealthClassificationList(countrySelected, 1, filter, sortField, sortOrder, setShowGrid));
}

const onSort = (event) => {
    let sortDirection: string;
    if (event.sortOrder === 1)
    sortDirection = "ASC"
    else
    sortDirection = "DESC"
    setSortField(event.sortField)
    setSortOrder(sortDirection)
    let countrySelected: any = {};
    countrySelected = { countryName: selectedCountry };
    dispatch(getHealthClassificationList(countrySelected, currentStart, keyWord, event.sortField, sortDirection, setShowGrid));
}

  const editIconClick = () => {
    if (selectedRows.length > 1) {
      setMessage("This operation can be performed only one row at a time");
      setDisplayInfoModal(true);
    } else if (selectedRows.length < 1) {
      setMessage("Select a Health Classification");
      setDisplayInfoModal(true);
    } else
      setShowEditModal(true);
  };
 
 

  const handleRowSelect = useCallback((data: any) => {
    setSelectedRows([...data]);
  }, []);

  
  const onAddHealthClassificationClick = (submitData: any) => {
    dispatch(
      onAddHealthClassification(
        submitData,
        selectedCountryId,
        setMessage,
        setDisplayInfoModal,
        setShowAddModal
      )
    );
  };
  const onEditHealthClassificationClick = (submitData: any) => {
    dispatch(
      onEditHealthClassification(
        submitData,
        selectedCountryId,
        selectedRows[0].uniqueHealthClassificationId,
        setMessage,
        setDisplayInfoModal,
        setShowEditModal
      )
    );
  };
  const deleteHealthClassificationClick = () => {
    if (selectedRows.length > 1) {
      setMessage("This operation can be performed only one row at a time");
      setDisplayInfoModal(true);
    } else if (selectedRows.length < 1) {
      setMessage("Select a Health Classification");
      setDisplayInfoModal(true);
    } else if (selectedRows.length === 1) {
      dispatch(checkHealthClassifictionInUse(selectedRows[0].uniqueHealthClassificationId))
      setIsDelete(true)
      
    }
  };

  useEffect(() => {
    if (healthClassifictionInUse && isDelete) {
      if (healthClassifictionInUse.isHealthClassificationInUse) {
        setMessage("Health Classification is in use, cannot be deleted");
        setDisplayInfoModal(true);
      } else {
        setMessage("Are you sure you want to delete the selected Health Classification?");
        setDisplayConfirmModal(true);
      }
      setIsDelete(false)
    }
  }, [healthClassifictionInUse])

  const OnConfirmClick = () => {
    setDisplayConfirmModal(false);
    if (selectedRows.length === 1) {
      const uniqueHealthClassificationId =
        selectedRows[0].uniqueHealthClassificationId;
      dispatch(
        deleteHealthClassification({
          uniqueHealthClassificationId,
          setSelectedRows,
          setMessage,
          setDisplayInfoModal,
        })
      );
    }
  };

  const onReportAllClick = () => {
    setReportFlag('all');
    setShowReportModal(true);
  };

  const onReportHighlightedClick = () => {
    setReportFlag('highlighted');
    setShowReportModal(true);
  };

  const onCountryDropdownChange = (data: any) => {
    if (data.value) {
      setSelectedCountry(data.value)
      let countrySelected: any = {};
      countrySelected = { countryName: data.value };
      dispatch(getHealthClassificationList(countrySelected, 1, keyWord, sortField, sortOrder, setShowGrid));
    }
    else {
      setSelectedCountry(data.name)
      let countrySelected: any = {};
      countrySelected = { countryName: data.name };
      dispatch(getHealthClassificationList(countrySelected, 1, keyWord, sortField, sortOrder, setShowGrid));
    }
  }
  
  const getSelectedClassificationId = () => {
    let params: any = [];
    selectedRows.map(item => {
      params.push(item.uniqueHealthClassificationId);
    });
    return params;
  };

  const onExportAll = () => {
    const reportParameters = {
      exportParameters: {
        uniqueHealthcareClassificationId: "",
        sortColumn: sortField,
        sortOrder: sortOrder,
        search: keyWord,
        countryId: selectedCountryId
      },
      exportType: "Health Classification Grid Report"
    };
    dispatch(onExportReport(reportParameters, "Health Classification"));
  };

  const onExportHighlightedClick = () => {
    const reportParameters = {
      exportParameters: {
        uniqueHealthcareClassificationId: getSelectedClassificationId(),
        sortColumn: sortField,
        sortOrder: sortOrder,
        search: keyWord,
        countryId: selectedCountryId
      },
      exportType: "Health Classification Grid Report"
    };
    dispatch(onExportReport(reportParameters, "Health Classification"));
  };

  const onShowToolTip = (e, indexData, options) => {
    if (options?.field === "equipmentType"&& e.currentTarget.offsetWidth > e.currentTarget.offsetParent.offsetWidth  ) {
      setTarget(e.currentTarget);
      setRowNumber(indexData?.RowNumber);
      SetField( indexData?.["equipmentType"])
    }
  };

  const onHideToolTip = () => {
    setTarget(null);
    setRowNumber("");
    SetField("");
  };

const bodyTemplate = (rowData, options) => {
  
  return (  
    <div className="table-col">
      {options?.field == "equipmentType" ? (
        <>
          <Overlay show={rowNumber == rowData?.RowNumber } key={rowData.RowNumber} placement="left" target={target}>
            <Tooltip id={`tooltip${rowData?.RowNumber}`} className="data-import-tooltip">
            <div id="tooltip-description"> {field} </div>
            </Tooltip>
          </Overlay>
          <span
            onMouseEnter={(e: any) => onShowToolTip(e, rowData, options)}
            onMouseLeave={() => onHideToolTip()}
            className="table-col"
          >
          {rowData?.["equipmentType"]}
          </span>
        </>
      ) : (
        <div >
          {rowData?.["healthClassification"]}
        </div>
      )}
    </div>
  );
};

  return (
    <div className="health-classification d-flex flex-column">
      <section className="d-flex align-items-center">
        <Breadcrumb
          title="Lookups"
          classNames="report-header"
          handleGoBackClick={() => navigate(`/${APP_ROUTES.MANAGE_VIEW}`)}
        />
        <div className="breadcrumb-right-arrow ml-3 mr-3">
          <BsArrowRight className="icon" />
        </div>
        <div className="breadcrumb-sub-page-title">
          <span>Health Classifications</span>
        </div>
      </section>

      <section className="health-table">
        {showGrid && (
          <Table
            rows={healthClassificationList}
            cols={HEALTH_CLASSIFICATION_COLS.HEALTH_COLS}
            paginator
            dataKeyId="uniqueHealthClassificationId"
            title={`Health Classifications`}
            searchText="Search"
            pageIndex={pageIndex}
            pageDetails={pageDetails}
            onPageChanged={onPageChanged}
            showDownloadIcon={true}
            showSettingIcon={true}
            showHamIcon={false}
            onGlobalSearch={onGlobalSearch}
            onSortData={onSort}
            onAddClick={() => setShowAddModal(true)}
            getSelectedRowData={handleRowSelect}
            getSelectedCountry={(selectedItem) => {
              setSelectedCountry(selectedItem.countryName);
            }}
            onEditClick={editIconClick}
            onDeleteClick={deleteHealthClassificationClick}
            showroweditor={false}
            onDownloadClick={(e) => downloadRef.current?.toggle(e)}
            showCountryDropdown
            countryLookup= {countryLookup}
            onCountryDropdownChange = {onCountryDropdownChange}
            templateFromParent={bodyTemplate}
            isTemplateFromParent={true}
          />
        )}
      </section>

      {showEditModal && (
        <EditHealthClassification
          showModal={showEditModal}
          setShowModal={setShowEditModal}
          Country={selectedCountry}
          CountryId={selectedCountryId}
          selectedRows={selectedRows}
          onEditHealthClassification={(data) => {
            onEditHealthClassificationClick(data);
          }}
        />
      )}
      {showAddModal && (
        <AddHealthClassification
          showModal={showAddModal}
          setShowModal={setShowAddModal}
          onAddHealthClassification={(data) => {
            onAddHealthClassificationClick(data);
          }}
          Country={selectedCountry}
        />
      )}
      <InformationBox
        showInfoModal={displayInfoModal}
        setShowInfoModal={setDisplayInfoModal}
        message={message}
      />
      <ConfirmationBox
        showConfirmModal={displayConfirmModal}
        setShowConfirmModal={setDisplayConfirmModal}
        confirmAction={OnConfirmClick}
        message={message}
      />
      <PopOver ref={downloadRef}>
        <ul>
          <li onClick={onReportAllClick}>Report All</li>
          <li onClick={onReportHighlightedClick}>Report Highlighted</li>
          <li onClick={onExportAll}>Export All</li>
					<li onClick={onExportHighlightedClick}>Export Highlighted</li>
        </ul>
      </PopOver>
      {showReportModal ? (
        <HealthClassificationReport showModal={showReportModal}
          setShowModal={setShowReportModal}
          reportFlag={reportFlag}
          selectedRows={selectedRows}
          countryId={selectedCountryId}
          sortByColumns={HEALTH_CLASSIFICATION_COLS.HEALTH_COLS}
          searchKeyword={keyWord} />
      ) : null}
    </div>
  );
};
export default HealthClassification;
