import React, { useCallback, useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { DataTableComponent } from "components/Table/DataTable";
import { useAppSelector } from "redux/hooks";
import { SOFTWAREVERAIONUPDATES_COLS } from "./SoftwareVersionUpdatesData";
import { Dialog } from "primereact/dialog";
import InformationBox from "components/common/Message/InformationBox/InformationBox";
import { onGetSoftwareVersionList, onUpdateSoftwareVersion,setSoftwareVersionList } from "redux/pages/Reports/ProvenOutcomesSlice";
import { useDispatch } from "react-redux";
interface Props {
    showModal: boolean;
    setShowModal: (state: boolean) => void;
    systemId: any;
    selectedProjectId: any;
}

type FormData = {
    recommend: string;
    name: string;
    newversion :string;
    currentversion : string;
};

export const SoftwareVersionUpdates: React.FC<Props> = ({
    showModal = false,
    setShowModal = () => { },
    systemId = "",
    selectedProjectId = "",
}) => {
    const [softwareVersionPageDetailData, setSoftwareVersionPageDetail] = useState<any>([]);
    const [pageIndex, setPageIndex] = useState(0);
    const [message, setMessage] = useState("");
    const [displayInfoModal, setDisplayInfoModal] = useState(false);
    const softwareVersionList = useAppSelector((state) => state.provenOutcomes.softwareVersionList);
    const softwareVersionPageDetails = useAppSelector((state) => state.provenOutcomes.softwareVersionPageDetails);
    const addForm: any = useRef();
    const [selectedRows, setSelectedRows] = useState<any>([]);
	const [currentStart, setCurrentStart] = useState<any>(0);
	const [sortField, setSortField] = useState('');
	const [sortOrder, setSortOrder] = useState('');
    const dispatch = useDispatch<any>();
    const [softwareVersionData, setsoftwareVersionData] = useState<any>([]);
   
    const {
		control,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm<FormData>({
		defaultValues: {
            recommend: "",
            name: "",
            newversion :"",
            currentversion : "",
		},
	});

 useEffect(() => {
    dispatch(onGetSoftwareVersionList(selectedProjectId,systemId,currentStart,sortField,sortOrder));
 },[])

 useEffect(() => {
    if (softwareVersionList?.length) {
        setSoftwareVersionPageDetail(softwareVersionPageDetails)
        setsoftwareVersionData(softwareVersionList);
        if(selectedRows && selectedRows?.length)
        {
            setSelectedRows(selectedRows);
        }
        else
        setSelectedRows([softwareVersionList[0]]);
        
    }
}, [softwareVersionList]);

const onClose = () => {
    dispatch(setSoftwareVersionList([]));
    setShowModal(false)
}

    const onPageChanged = (options: any) => {
		if (options !== pageIndex) { 
			let currentStart = (options * softwareVersionPageDetails?.currentLength) + 1;
			setPageIndex(options)
			setCurrentStart(currentStart)
		   dispatch(onGetSoftwareVersionList(selectedProjectId,systemId,currentStart,"",sortField,sortOrder));  
		}
	};

    const onSort = (event) => {
		let sortDirection: string;
		if (event.sortOrder === 1)
		  sortDirection = "ASC"
		else
		  sortDirection = "DESC"
		setSortField(event.sortField)
		setSortOrder(sortDirection) 
		dispatch(onGetSoftwareVersionList(selectedProjectId,systemId,currentStart,"",event?.sortField,sortDirection)); 
	  }

      const handleRowSelect = useCallback((data: any) => {
        setSelectedRows([...data])
	  }, []);

      const updateSoftwareVersion = (submitData: any) => {
        let uniqueSoftwareVersionId: any;
        uniqueSoftwareVersionId = submitData.uniqueProjectId; 
    
        let body = {
           systemId : systemId,
           projectId : selectedProjectId,
           equipmentId : submitData?.equipmentId,
           inspectionTaskId : submitData?.inspectionTaskId,
           equipmentname: submitData?.equipmentName,
           currentVersion : submitData?.currentVersion,
           newVersion : submitData?.newVersion,
           recommend : submitData?.recommend
           
        }

        dispatch(onUpdateSoftwareVersion(uniqueSoftwareVersionId,body,setMessage,
            setDisplayInfoModal));
    };



    return (
        <Dialog
            header="Software and Version Updates"
            visible={showModal}
            style={{ width: "60vw" }}
            onHide={onClose}
            className="s-modal"
        >

            <InformationBox
                showInfoModal={displayInfoModal}
                setShowInfoModal={setDisplayInfoModal}
                message={message}
            />

                            <DataTableComponent
								rows={softwareVersionData}
								cols={SOFTWAREVERAIONUPDATES_COLS}
								title={`Software and Version Updates`}
								dataKeyId="equipmentId"
								pageDetails={softwareVersionPageDetailData}
								pageIndex={pageIndex}
								showroweditor={true}
								onPageChanged={onPageChanged}
								onSortData={onSort}
								getSelectedRowData={handleRowSelect}
								onSaveRowEdit={updateSoftwareVersion}
                                paginator={true}
                                defaultSelectedRows = {selectedRows}
								
							/>
        </Dialog >
        
    );
};

export default SoftwareVersionUpdates;
