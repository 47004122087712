import React, { useCallback, useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import { DataTableComponent } from 'components/Table/DataTable';
import { Button } from "primereact/button";
import { classNames } from 'primereact/utils';
import { tapPerformance_Cols } from './TAPPerformanceData';
import { BsDownload, BsEye, BsFillPencilFill, BsPlayCircleFill, BsPlusLg, BsTrashFill } from 'react-icons/bs';
import AddReportWizard from './ReportWizard';
import { useDispatch } from 'react-redux';
import { deleteProjectDetails, onGenerateReport, onGetTapPerformanceReportList,onDownloadTapPerformanceReport, checkTapPerformaceReportGenerated, setCheckTAPPerformanceReportGenerated,onDependencyCheck, onCloneProject, setTapPerformanceReportList, setTapPerformanceReportListPageDetails} from 'redux/pages/Reports/ProvenOutcomesSlice';
import InformationBox from 'components/common/Message/InformationBox/InformationBox';
import TAPPerformanceReportViewer from './TAPPerformanceReportViewer/TAPPerformanceReportViewer';
import ConfirmationBox from 'components/common/Message/ConfirmationBox/ConfirmationBox';
import { IoIosCopy } from 'react-icons/io';
interface Props {

}

export const TAPPerformance: React.FC<Props> = () => {
	const tapPerformanceReportPageDetails = useAppSelector((state) => state.provenOutcomes.tapPerformanceReportPageDetails);
	const tapPerformanceReportList = useAppSelector((state) => state.provenOutcomes.tapPerformanceReportList);
	const checkReportGenerated = useAppSelector((state) => state.provenOutcomes.checkTAPPerformanceReportGenerated);
	const newlyAddedData = useAppSelector((state) => state.provenOutcomes.newlyAddedData);

	const [tapPerformanceData, setTAPPerformanceData] = useState<any>([]);
	const [pageIndex, setPageIndex] = useState(0);
	const [pageDetails, setPageDetails] = useState(null);
	const [showAddModal, setShowAddModal] = useState(false);
	const [showEditModal, setShowEditModal] = useState(false);
	const [displayConfirmModal, setDisplayConfirmModal] = useState(false);
	const [IsAdd, setIsAdd] = useState(false);
	const dispatch = useDispatch<any>();
	const [selectedRows, setSelectedRows] = useState<any>([]);
	const [currentStart, setCurrentStart] = useState<any>([]);
	const [sortField, setSortField] = useState('');
	const [sortOrder, setSortOrder] = useState('');
	const [message, setMessage] = useState("");
	const [displayInfoModal, setDisplayInfoModal] = useState(false);
	const [showViewModal, setShowViewModal] = useState<any>(false);
	const [fileName, setFileName] = useState("");
	const [reportGenerateDependencyData, setReportGenerateDependencyData] = useState<any>();
	const [tapPerformanceButtonDisable, setTapPerformanceButtonDisable] = useState(false);
	const [isDelete, setIsDelete] = useState(false);
	const [isGenerate, setIsGenerate] = useState(false);
	const [cloneData, setCloneData] = useState<any>('');

	useEffect(() => {
		if (tapPerformanceReportList?.length) {
			setPageDetails(tapPerformanceReportPageDetails);
			setTAPPerformanceData(tapPerformanceReportList);
			setTapPerformanceButtonDisable(false);
            if (newlyAddedData) {
				setSelectedRows([tapPerformanceReportList[0]]);	
			}
			else if (cloneData) {
				setSelectedRows([tapPerformanceReportList[0]]);	
			}
			else if(selectedRows && selectedRows?.length)
			setSelectedRows(selectedRows);
			else 
            setSelectedRows([tapPerformanceReportList[0]]);	
		}
		else {
			setTAPPerformanceData([]);
			setSelectedRows([]);
			setTapPerformanceButtonDisable(true)
		}
	}, [tapPerformanceReportList]);

	   useEffect(() => {
		if (cloneData) {
			let projectList: { branch: string; uniqueProjectId: string; projectOwner: string; projectName: string; }[] = [];
			projectList.push({
				branch: cloneData.branchName,
				uniqueProjectId: cloneData.uniqueProjectId,
				projectOwner: cloneData.projectOwner,
				projectName: cloneData.projectName
			})

			tapPerformanceReportList?.map((item: any) => {
				projectList.push({
					branch: item.branch,
					uniqueProjectId: item.uniqueProjectId,
					projectOwner: item.projectOwner,
					projectName: item.projectName
				})
			})
			
			dispatch(setTapPerformanceReportList(projectList));
			setSelectedRows([projectList[0]]);	
			if (tapPerformanceReportPageDetails == null) {
				let projectDetail: { currentLength: any; currentStart: any; pageCount: any; totalCount: any; } = {
					currentLength: 1,
					currentStart: 1,
					pageCount: 100,
					totalCount: 1
				};
				dispatch(setTapPerformanceReportListPageDetails(projectDetail))
				 setSelectedRows([projectList[0]]);	
			}
		}

	}, [cloneData]);

	useEffect(() => {
		dispatch(onGetTapPerformanceReportList(1));
	}, [])

	useEffect(() => {
		if (checkReportGenerated && checkReportGenerated === "Report Not Generated" && !isGenerate) {
			setMessage("Report not generated");
			setDisplayInfoModal(true);
			dispatch(setCheckTAPPerformanceReportGenerated(""));
		}else if(checkReportGenerated && checkReportGenerated === "Report Already Generated" && !isGenerate){
			setShowViewModal(true);
			dispatch(setCheckTAPPerformanceReportGenerated(""));
		}
		else if(checkReportGenerated && checkReportGenerated === "Report Not Generated" && isGenerate){
			setMessage("Do you want to generate the report?");
			setDisplayConfirmModal(true);  
			dispatch(setCheckTAPPerformanceReportGenerated(""));
		}
		else if(checkReportGenerated && checkReportGenerated === "Report Already Generated" && isGenerate){
			setMessage("Are you sure you want to regenerate the report?");
			setDisplayConfirmModal(true);   
			dispatch(setCheckTAPPerformanceReportGenerated(""));
		}
	}, [checkReportGenerated])

	const handleRowSelect = useCallback((data: any) => { 
		setSelectedRows([...data]);
	}, []);

	useEffect(() => {
		if (reportGenerateDependencyData) {
		  if (!reportGenerateDependencyData?.isReadyToGenerate) {
			setDisplayInfoModal(true);
			setMessage(reportGenerateDependencyData?.message);
		  }
		  else if (reportGenerateDependencyData?.isReadyToGenerate) {
			let uniqueProjectId = selectedRows[0]?.uniqueProjectId;
			dispatch(onGenerateReport(uniqueProjectId, setMessage, setDisplayInfoModal));
	
		  }
		}
	
	  }, [reportGenerateDependencyData]);

	const onSort = (event) => {
		let sortDirection: string;
		if (event.sortOrder === 1)
			sortDirection = "ASC"
		else
			sortDirection = "DESC"
		setSortField(event.sortField)
		setSortOrder(sortDirection)
		dispatch(onGetTapPerformanceReportList(currentStart, event?.sortField, sortDirection));
	}

	const ViewTapPerformanceReport = () => {
		if (selectedRows?.length === 1) {
			setFileName(selectedRows[0]?.projectName);
			dispatch(checkTapPerformaceReportGenerated(selectedRows[0]?.uniqueProjectId));
		} else {
			setMessage('This operation can be performed only one row at a time.');
			setDisplayInfoModal(true);
		}
	};

	const downloadTapPerformanceReport = () => {
		if (selectedRows?.length === 1) {
			setFileName(selectedRows[0]?.projectName);
			dispatch(onDownloadTapPerformanceReport(selectedRows[0]?.uniqueProjectId,setDisplayInfoModal,setMessage));
		} else {
			setMessage('This operation can be performed only one row at a time.');
			setDisplayInfoModal(true);
		}
	};

	const onPageChanged = (options: any) => {
		if (options !== pageIndex) {
			let currentStart = (options * tapPerformanceReportPageDetails?.currentLength) + 1;
			setPageIndex(options)
			setCurrentStart(currentStart)
			dispatch(onGetTapPerformanceReportList(currentStart, sortField, sortOrder));
		}
	};


	const onGenerateReportProvenOutcomes = () => {
		if (selectedRows?.length > 1) {
			setMessage("This operation can be performed only one row at a time");
			setDisplayInfoModal(true);
		}
		else if (selectedRows?.length == 1){
			setIsGenerate(true);
			dispatch(checkTapPerformaceReportGenerated(selectedRows[0]?.uniqueProjectId));
		}
		else{
			setMessage("Select a project");
			setDisplayInfoModal(true);
		}
	}

	const onCloneReportProvenOutcomes = () => {
		if (selectedRows?.length > 1) {
			setMessage("This operation can be performed only one row at a time");
			setDisplayInfoModal(true);
		}
		else if (selectedRows?.length == 1){
			let uniqueProjectId = selectedRows[0]?.uniqueProjectId;
			dispatch(onCloneProject(uniqueProjectId,setMessage,setDisplayInfoModal,setCloneData));	
		}
		else{
			setMessage("Select a project");
			setDisplayInfoModal(true);
		}
	}


	const deleteIconClick = () => {
		setIsDelete(true);
        if (selectedRows?.length > 1) {
            setMessage("This operation can be performed only one row at a time");
            setDisplayInfoModal(true);
        } else {
            setDisplayConfirmModal(true);
            setMessage("Are you sure you want to delete the project?")
        }
    };

	const onCancel=()=>{
        setDisplayConfirmModal(false);
		setIsGenerate(false);
    }

	const removeProjectData = () => {
		setDisplayConfirmModal(false);
		const uniqueProjectId = selectedRows[0]?.uniqueProjectId;
		setIsDelete(true);
		dispatch(deleteProjectDetails({uniqueProjectId,setMessage,setDisplayInfoModal,setSelectedRows}));	
	    setSelectedRows([]);
		};

	  const editIconClick = () => {
        if (selectedRows && selectedRows.length > 1) {
            setMessage("This operation can be performed only one row at a time");
            setDisplayInfoModal(true);
		}
         else {
            setIsAdd(false);
			setShowEditModal(true) 
        }
    }

	const onConfirm = () => {
		if (isDelete) {
			removeProjectData();
			setIsDelete(false);
			setDisplayConfirmModal(false);
		}
		else if(isGenerate){
			let uniqueProjectId = selectedRows[0]?.uniqueProjectId;
			dispatch(onDependencyCheck(
				uniqueProjectId,
				setReportGenerateDependencyData,
			))
			setIsGenerate(false);
			setDisplayConfirmModal(false);
		}
	}

	const reportHeaderTemplate = (
		<div
			className={classNames("d-flex  justify-content-between", {
				"align-items-center justify-content-between": false,
			})}
		>
			{
				<div className="search-section d-flex align-items-center">
					<div className="attributes-dropdowns d-flex align-items-center">
					</div>
				</div>
			}

			<div
				className={classNames("table-config", {
					"custom-align": false,
				})}
			>
				{
					<div className="action-btns">
						<Button
							className="button-icon"
							tooltip="Add"
							tooltipOptions={{ position: "top" }}
							onClick={() => {setIsAdd(true);setShowAddModal(true) }}
							disabled={false}
						>
							<BsPlusLg className="icon" />
						</Button>
						<Button
							className="button-icon"
							tooltip="Edit"
							tooltipOptions={{ position: "top" }}
							onClick={editIconClick}
							disabled={tapPerformanceButtonDisable}
						>
							<BsFillPencilFill className="icon" />
						</Button>
						<Button
							className="button-icon"
							tooltip="Delete"
							disabled={tapPerformanceButtonDisable}
							onClick={deleteIconClick}
							tooltipOptions={{ position: "top" }}
						>
							<BsTrashFill className="icon" />
						</Button>
						<Button
							className="button-icon"
							tooltip="View"
							disabled={tapPerformanceButtonDisable}
							onClick={ViewTapPerformanceReport}
							tooltipOptions={{ position: "top" }}
						>
							<BsEye className="icon" />
						</Button>
						<Button
							className="button-icon"
							tooltip="Download Report"
							disabled={tapPerformanceButtonDisable}
							onClick={downloadTapPerformanceReport}
							tooltipOptions={{ position: "top" }}
						>
							<BsDownload className="icon" />
						</Button>
						<Button
							className="button-icon"
							tooltip="Clone"
							disabled={tapPerformanceButtonDisable}
							tooltipOptions={{ position: "top" }}
							onClick={onCloneReportProvenOutcomes}
						>
							<IoIosCopy className="icon" />
						</Button>
					</div>
					
				}
				<>
					<Button 
						label="Generate"
						className="generate-btn"
						disabled={tapPerformanceButtonDisable}
						onClick={onGenerateReportProvenOutcomes}					
					/> 
				</>

			</div>
		</div>
	);

	return (
		<div className='TAPPerformance'>
			<div className='TAPPerformance-container'>
				<section className={`expanded TAPPerformance-table`}>
					{tapPerformanceData ? (
						<DataTableComponent
							rows={tapPerformanceData}
							cols={tapPerformance_Cols}
							title={`Life Safety Systems Report`}
							dataKeyId="uniqueProjectId"
							pageDetails={tapPerformanceReportPageDetails}
							pageIndex={pageIndex}
							headerTemplate={reportHeaderTemplate}
							showroweditor={false}
							paginator={true}
							onPageChanged={onPageChanged}
							onSortData={onSort}
							getSelectedRowData={handleRowSelect}
							defaultSelectedRows={selectedRows}
						/>) : <span>No Data Found</span>}
				</section>
				{showAddModal ? (<AddReportWizard showModal={showAddModal} setShowModal={setShowAddModal} isAdd ={IsAdd} selectedProject={selectedRows[0]} isGenerate={isGenerate} setIsGenerate={setIsGenerate} />) : null}
				{showEditModal ? (<AddReportWizard showModal={showEditModal} setShowModal={setShowEditModal} isAdd ={IsAdd} selectedProject={selectedRows[0]} isGenerate={isGenerate} setIsGenerate={setIsGenerate}/>) : null}
			</div>

			{showViewModal &&
				<TAPPerformanceReportViewer
					showModal={showViewModal}
					setShowModal={setShowViewModal}
					fileName={fileName}
					setFileName={setFileName}
					uniqueProjectId={selectedRows[0]?.uniqueProjectId}
				/>
			}

			<InformationBox
				showInfoModal={displayInfoModal}
				setShowInfoModal={setDisplayInfoModal}
				okOnClick={onCancel}
				message={message}
				onHide={onCancel}
			/>
			 <ConfirmationBox
                showConfirmModal={displayConfirmModal}
                setShowConfirmModal={setDisplayConfirmModal}
                confirmAction={onConfirm}
                cancelAction={onCancel}
				onHideAction={onCancel}
                message={message}
            />
		</div>
	);
}

export default TAPPerformance;