import React, { useState, useCallback, useEffect } from "react";
import Table from "components/Table/Table";
import { SiteTableData } from "./SiteTableData";
import { SITEBUILD_COLS } from "./SiteBuildingTableData";
import { SiteFloorTableData } from "./SiteFloorTableData";

import { OverlayPanel } from "primereact/overlaypanel";

import InformationBox from "components/common/Message/InformationBox/InformationBox";
import ConfirmationBox from "components/common/Message/ConfirmationBox/ConfirmationBox";
import PopOver from "components/common/PopOver/PopOver";
import AddSite from "./ManageSite/AddSite";
import AddBuildings from "./ManageBuildings/AddBuildings";
import AddFloors from "./ManageFloors/AddFloor";
import EditBuildings from "./ManageBuildings/EditBuildings";
import EditFloors from "./ManageFloors/EditFloor";
import Editsite from "./ManageSite/EditSite";
import SiteMapCustomer from "./Settings/MapCustomer"

//icon
import { HiOutlineArrowNarrowRight } from "react-icons/hi";

//routing
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "constants/AppRoutes";

import { useDispatch } from "react-redux";
import { useAppSelector } from "../../redux/hooks";
import {
  fetchActiveSiteDetails,
  fetchLoggedInUserDetails,
} from "../../redux/pages/Administration/administrationSlice";
import {
  getSiteList,
  onAddSite,
  getDependencyForSite,
  deleteSite,
  onUpdateSite,
  setAsActiveSiteForSite,
  getSiteSearchDetailsList,
  onUpdateSiteCustomerMapping,
  getCheckSiteForClose,
  onSiteReOpen,
  onCloseSite,
  setSiteList,
} from "redux/pages/Administration/Site/siteSlice";
import { setCustomerReviseList } from "redux/pages//Inspection/InspectionTask/inspectionTaskFilterSlice";
import { checkBuildigInUse, deleteBuilding, getSiteBuildingList, onAddBuilding, onUpdateBuilding, setBuildingInUse, setNewBuilding, setUpdatedBuildingData } from "redux/pages/Administration/Building/buildingSlice";
import { deleteFloor, getBuildingListLookup, getFloorList, onAddFloor, onUpdateFloor, setFloorList, setNewFloorData, setUpdatedFloor } from "redux/pages/Administration/Floor/floorSlice";

import AttachmentList from "./Attachments/List/AttachmentList";
import AttachmentUpload from "./Attachments/Upload/AttachmentUpload";
import { showUI } from 'service/GeneralUtility';
import SiteReport from "pages/Sites/Report/SiteReport";
import BuildingAttachmentList from "./Attachments/List/BuildingAttachmentList";
import BuildingReport from "./Report/BuildingReport";
import FloorAttachmentList from "./Attachments/List/FloorAttachmentList";
import FloorReport from "./Report/FloorReport";
import { onExportReport } from "redux/common/report/customizeReportSlice";

interface Props { }

export const Sites: React.FC<Props> = () => {
  const SITE_ROLES_LIST = [
    { name: "Sites", code: "Si", selected: true },
    { name: "Buildings", code: "BU", selected: false },
    { name: "Floors", code: "Fl", selected: false },
  ];
  const dependencyForSite = useAppSelector((state) => state.site.dependencyForSite);
  const siteData = useAppSelector((state) => state.site.siteList);
  const sitePageDetails = useAppSelector((state) => state.site.sitePageDetails);
  const activeSiteDetails = useAppSelector((state) => state.administration.activeSiteDetails);
  const switchedSite = useAppSelector((state) => state.site.activeSite);
  const siteSearchId = useAppSelector((state) => state.site.siteSearchId);
  const loggedInUserData = useAppSelector((state) => state.administration.loggedInUserDetails);
  const userPrivileges = useAppSelector((state) => state.user.userPrivileges);

  const buildingList = useAppSelector((state) => state.building.buildingList);
  const buildingPageDetails = useAppSelector((state) => state.building.buildingPageDetails);
  const newBuilding = useAppSelector((state) => state.building.newBuilding);
  const updatedBuildingData = useAppSelector((state) => state.building.updatedBuildingData);
  const buildingInUse = useAppSelector((state) => state.building.buildingInUse);

  const floorList = useAppSelector((state) => state.floor.floorList);
  const floorPageDetails = useAppSelector((state) => state.floor.floorPageDetails);
  const newFloorData = useAppSelector((state) => state.floor.newFloorData);
  const updatedFloor = useAppSelector((state) => state.floor.updatedFloor);
  const buildngDetails = useAppSelector((state) => state.floor.buildingList);
  const rowsPerPage = useAppSelector((state) => state.personalSettings.rowsPerPage);

  const [showAddModal, setShowAddModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>("Sites");
  const [showAddBuildingsModal, setShowAddBuildingsModal] = useState<boolean>(false);
  const [showEditBuildingsModal, setShowEditBuildingsModal] = useState<boolean>(false);
  const [showEditFloorsModal, setShowEditFloorsModal] = useState<boolean>(false);
  const [showAddFloorsModal, setShowAddFloorsModal] = useState<boolean>(false);
  const [filterExpanded] = useState<any>(true);
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [showSiteMapCustomer, setShowSiteMapCustomer] = useState(false);
  const [displayInfoModal, setDisplayInfoModal] = useState(false);
  const [displayConfirmModal, setDisplayConfirmModal] = useState(false);
  const [displayConfirmReopenModal, setDisplayConfirmReopenModal] = useState(false);
  const [displayConfirmCloseSiteModal, setDisplayConfirmCloseSiteModal] = useState(false);
  const [message, setMessage] = useState("");
  const [pageIndex, setPageIndex] = useState(0);
  const [showSiteGrid, setShowSiteGrid] = useState(true);
  const [showBuildingGrid, setShowBuildingGrid] = useState(false);
  const [showFloorGrid, setShowFloorGrid] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [siteRoleList, setsiteRoleList] = useState<Array<any>>(SITE_ROLES_LIST);
  const [showEditModal, setShowEditModal] = useState(false);
  const [tableColumns, setTableColumns] = useState<Array<any>>([]);
  const [floorTableColumns, setFloorTableColumns] = useState<Array<any>>([]);
  const [isNavigate, setIsNavigate] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [currentStart, setCurrentStart] = useState(1);
  const [sortField, setSortField] = useState('')
  const [sortOrder, setSortOrder] = useState('');
  const [keyWord, setKeyWord] = useState('');
  const [showAttachmentListModal, setShowAttachmentListModal] = useState(false);
  const [showAttachmentUploadModal, setShowAttachmentUploadModal] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [siteRowData, setSiteRowData] = useState<any>("");
  const [settingListItems, setSettingListItems] = useState<any>()
  const [reportFlag, setReportFlag] = useState<any>('all');
  const [buildingData, setBuildingData] = useState<any>([]);
  const [selectedSite, setSelectedSite] = useState<any>("");
  const [displayConfirmBuildingModal, setDisplayConfirmBuildingModal] = useState(false);
  const [showBuildingAttachmnetModel, setShowBuildingAttachmnetModel] = useState(false);
  const [showBuildingReportModal, setShowBuildingReportModal] = useState(false);
  const [floorData, setFloorData] = useState<any>([]);
  const [selectedBuilding, setSelectedBuilding] = useState<any>('');
  const [displayConfirmFloorModal, setDisplayConfirmFloorModal] = useState(false);
  const [showFloorAttachmnetModel, setShowFloorAttachmnetModel] = useState(false);
  const [showFloorReportModal, setShowFloorReportModal] = useState(false);
  const [headerText, setHeaderText] = useState<any>([{ site: "", building: "", floor: "" }]);
  const [selectedSiteName, setSelectedSiteName] = useState<any>("")
  const downloadRef = React.createRef<OverlayPanel>();
  const attachmentRef = React.createRef<OverlayPanel>();
  const buildingAttachRef = React.createRef<OverlayPanel>();
  const floorAttachRef = React.createRef<OverlayPanel>();
  const [sitePagination, setSitePagination] = useState<any>({});
  const [buildingPagination, setBuildingPagination] = useState<any>({});
  const [floorPagination, setFloorPagination] = useState<any>({});

  const navigate = useNavigate();
  const dispatch = useDispatch<any>();

  useEffect(() => {
    dispatch(fetchActiveSiteDetails());

    return(() => {
      dispatch(setSiteList([]));
    });
  }, [dispatch]);

  useEffect(() => {
    if (userPrivileges && userPrivileges.length) {
      filterSettingItemList();
    }
  }, [userPrivileges, selectedRows])

  const filterSettingItemList = () => {
    let settingList: any = [];
    userPrivileges.map(element => {
      if (element === 'SIETAP_SITE_CLOSE') {
        settingList.push({
          id: 1,
          name: "Close",
          onClick: () => onClose(),
        })
      } else if (element === 'SIETAP_SITE_REOPEN') {
        settingList.push({
          id: 2,
          name: "Re-Open",
          onClick: () => onReOpenSiteHandler(),
        })
      } else if (element === 'SIETAP_SITE_CUSTOMERMAPPING') {
        settingList.push({
          id: 3,
          name: "Customers",
          onClick: () => { onCustomerMappingClick() },
        })
      } else if (element === 'PORTFOLIO_MANAGE_BUILDINGS') {
        settingList.push({
          id: 4,
          name: "Buildings",
          onClick: () => { onBuildingClick() },
        })
      }
    });
    if (selectedItem === "Buildings") {
      settingList = [];
      settingList.push({
        id: 5,
        name: "Floors",
        onClick: () => { onFloorClick() },
      })
    }
    setSettingListItems(settingList)
  }

  useEffect(() => {
    if (siteSearchId !== "" && selectedItem === 'Sites') {
      setIsSearch(true);
      dispatch(setCustomerReviseList([]));
      dispatch(getSiteSearchDetailsList(siteSearchId, setShowSiteGrid));
    } else if (isSearch === false && selectedItem === 'Sites') {
      dispatch(getSiteList(1));
    }
  }, [dispatch, siteSearchId, isSearch, selectedItem]);

  useEffect(() => {
    if (rowsPerPage && rowsPerPage > 0) {
      if (selectedItem === "Sites") {
        dispatch(getSiteList(1));
      }
      else if (selectedItem === "Buildings") {
        dispatch(getSiteBuildingList(loggedInUserData?.userDefaults?.uniqueSiteId, 1));
      }
      else if (selectedItem === "Floors") {
        dispatch(getFloorList(loggedInUserData?.userDefaults?.uniqueSiteId, null, 1));
      }
    }
  }, [rowsPerPage]);

  useEffect(() => {
    if (sitePageDetails) {
      setSitePagination(sitePageDetails);
    }
    if (buildingPageDetails) {
      setBuildingPagination(buildingPageDetails);
    }
    if (floorPageDetails) {
      setFloorPagination(floorPageDetails);
    }
  }, [sitePageDetails, buildingPageDetails, floorPageDetails])

  useEffect(() => {
    if (rowsPerPage && rowsPerPage > 0) {
      if (selectedItem === "Sites") {
        let modifiedPageDetails = { ...sitePagination, 'currentLength': rowsPerPage, 'pageCount': rowsPerPage };
        setSitePagination(modifiedPageDetails);
        setCurrentStart(1)
        setPageIndex(0)
      }
      else if (selectedItem === "Buildings") {
        let modifiedPageDetails = { ...buildingPagination, 'currentLength': rowsPerPage, 'pageCount': rowsPerPage };
        setBuildingPagination(modifiedPageDetails);
        setCurrentStart(1)
        setPageIndex(0)
      }
      else if (selectedItem === "Floors") {
        let modifiedPageDetails = { ...floorPagination, 'currentLength': rowsPerPage, 'pageCount': rowsPerPage };
        setFloorPagination(modifiedPageDetails);
        setCurrentStart(1)
        setPageIndex(0)
      }
    }
  }, [rowsPerPage])

  useEffect(() => {
    if (isDelete && Object.keys(dependencyForSite).length !== 0) {
      if (dependencyForSite.hasDependencyToRemove === true) {
        setMessage("All data linked to the selected Site will be deleted. Are you sure you want to delete the Site?");
        setIsDelete(false);
        setDisplayConfirmModal(true);
      } else if (dependencyForSite.hasDependencyToRemove === false) {
        setMessage("Are you sure you want to delete the Site?");
        setIsDelete(false);
        setDisplayConfirmModal(true);
      }
    }
  }, [dispatch, dependencyForSite, isDelete]);

  useEffect(() => {
    if (isNavigate && switchedSite.uniqueSiteId !== undefined) {
      dispatch(fetchLoggedInUserDetails());
      navigate(`/${APP_ROUTES.INSPECTION_TASKS}`);
      setIsNavigate(false);
    }
  }, [switchedSite, navigate, isNavigate, dispatch]);

  const handleRoleSelect = useCallback((selectedItem) => {
    setSelectedSite(loggedInUserData?.userDefaults);
    setSelectedItem(selectedItem.name);
    const modifiedsiteList = siteRoleList.map((item) => {
      if (item.code === selectedItem.code) {
        item["selected"] = true;
      } else {
        item["selected"] = false;
      }
      return item;
    });
    setsiteRoleList(modifiedsiteList);
    setSelectedRows([]);
    if (selectedItem.name === "Buildings") {
      dispatch(getSiteBuildingList(loggedInUserData?.userDefaults?.uniqueSiteId, 1))
      setHeaderText({
        site: `Site : ${loggedInUserData?.userDefaults?.siteName}`,
        building: "Buildings",
        floor: ""
      })
      setSelectedSiteName("Site : " + loggedInUserData?.userDefaults?.siteName)
    } else if (selectedItem.name === "Floors") {
      dispatch(getFloorList(loggedInUserData?.userDefaults?.uniqueSiteId, null, 1))
      setSelectedBuilding("");
      setHeaderText({
        site: `Site : ${loggedInUserData?.userDefaults?.siteName}`,
        building: "All Buildings",
        floor: "Floors"
      })
    }
  }, [siteRoleList]);

  useEffect(() => {
    setKeyWord("");
    setSortField("");
    setSortOrder("");
    setPageIndex(0);
    setSelectedRows([]);
    switch (selectedItem) {
      case "Sites":
        setShowSiteGrid(true);
        setShowBuildingGrid(false);
        setShowFloorGrid(false);
        break;
      case "Buildings":
        setShowSiteGrid(false);
        setShowBuildingGrid(true);
        setShowFloorGrid(false);
        setBuildingData([]);
        break;
      case "Floors":
        setShowSiteGrid(false);
        setShowBuildingGrid(false);
        setShowFloorGrid(true);
        setFloorData([]);
        break;
      default:
        break;
    }
  }, [selectedItem]);

  const onPageChanged = (options: any) => {
    setSelectedRows([]);
    if (options !== pageIndex) {
      let currentStart;
      setPageIndex(options);
      switch (selectedItem) {
        case "Sites":
          currentStart = options * sitePagination?.currentLength + 1;
          setCurrentStart(currentStart)
          dispatch(getSiteList(currentStart, keyWord, sortField, sortOrder));
          break;
        case "Buildings":
          currentStart = options * buildingPagination?.currentLength + 1;
          setCurrentStart(currentStart)
          dispatch(getSiteBuildingList(selectedSite?.uniqueSiteId, currentStart, keyWord, sortField, sortOrder))
          break;
        case "Floors":
          currentStart = options * floorPagination?.currentLength + 1;
          setCurrentStart(currentStart)
          dispatch(getFloorList(selectedSite.uniqueSiteId, selectedBuilding ? selectedBuilding.uniqueBuildingId : null, currentStart, keyWord, sortField, sortOrder))
          break;
        default:
          break;
      }
    }
  };

  const onGlobalSearch = (filter) => {  
    setSelectedRows([]);
    setKeyWord(filter);
    switch (selectedItem) {
      case "Sites":
        dispatch(getSiteList(1, filter, sortField, sortOrder))
        break;
      case "Buildings":
        dispatch(getSiteBuildingList(selectedSite?.uniqueSiteId, 1, filter, sortField, sortOrder))
        break;
      case "Floors":
        dispatch(getFloorList(selectedSite?.uniqueSiteId, selectedBuilding ? selectedBuilding.uniqueBuildingId : null, 1, filter, sortField, sortOrder))
        break;
      default:
        break;
    }
  }

  const onSort = (event) => {
    let sortDirection: string;
    if (event.sortOrder === 1)
      sortDirection = "ASC"
    else
      sortDirection = "DESC"
    setSortField(event.sortField)
    setSortOrder(sortDirection)
    switch (selectedItem) {
      case "Sites":
        dispatch(getSiteList(currentStart, keyWord, event?.sortField, sortDirection))
        break;
      case "Buildings":
        dispatch(getSiteBuildingList(selectedSite?.uniqueSiteId, currentStart, keyWord, event?.sortField, sortDirection))
        break;
      case "Floors":
        dispatch(getFloorList(selectedSite?.uniqueSiteId, selectedBuilding ? selectedBuilding.uniqueBuildingId : null, currentStart, keyWord, event?.sortField, sortDirection))
        break;
      default:
        break;
    }
  }

  const onAddSiteClick = (submitData: any) => {
    dispatch(
      onAddSite(
        submitData,
        setMessage,
        setDisplayInfoModal,
        setShowAddModal,
        setSelectedRows
      )
    );
  };

  const handleRowSelect = useCallback((data: any) => {
    if (data && data.length > 0) {
      setSelectedRows([...data]); //To resolve useEffect dependency
    } else {
      setSelectedRows([])
    }
  }, []);

  const deleteIconClick = () => {
    if (selectedRows.length > 1) {
      setMessage("This operation can be performed only one row at a time");
      setDisplayInfoModal(true);
    } else if (selectedRows.length < 1) {
      setMessage("Select a Site");
      setDisplayInfoModal(true);
    } else if (selectedRows.length === 1) {
      if (selectedRows[0].status === "Proposed") {
        setMessage("Proposed Site cannot be deleted");
        setDisplayInfoModal(true);
      } else if (
        activeSiteDetails?.uniqueSiteId === selectedRows[0].uniqueSiteId
      ) {
        setMessage("Logged in Site cannot be deleted");
        setDisplayInfoModal(true);
      } else {
        dispatch(getDependencyForSite(selectedRows[0].uniqueSiteId));
        setIsDelete(true);
      }
    }
  };

  const removeSite = () => {
    setDisplayConfirmModal(false);
    if (selectedRows.length === 1) {
      const uniqueSiteId = selectedRows[0].uniqueSiteId;
      dispatch(
        deleteSite({
          uniqueSiteId,
          setSelectedRows,
          setMessage,
          setDisplayInfoModal,
        })
      );
    }
  };

  const onEditIconClick = () => {
    if (selectedRows.length > 1) {
      setMessage("This operation can be performed only one row at a time");
      setDisplayInfoModal(true);
    } else if (selectedRows.length < 1) {
      setMessage("Select a Site");
      setDisplayInfoModal(true);
    } else {
      setShowEditModal(true);
    }
  };

  const onCustomerMappingClick = () => {
    if (selectedRows.length > 1) {
      setMessage("This operation can be performed only one row at a time");
      setDisplayInfoModal(true);
    } else if (selectedRows.length < 1) {
      setMessage("Select a Site");
      setDisplayInfoModal(true);
    } else {
      setShowSiteMapCustomer(true);
    }
  };
  const onRowEditClick = (rowData: any) => {
    let isEditable = true;
    setSiteRowData(rowData)
    return isEditable;
  }

  const onRowEditValidation = (rowData: any) => {
    let value = true;
    if (
      !rowData.siteName ||
      !rowData.siteNumber ||
      !isSiteNumberValid(rowData.siteNumber, rowData.countryName) ||
      !rowData.countryName ||
      //Purposely commented based on requirement
      //!rowData.description ||
      !rowData.state ||
      !rowData.timeZone ||
      !rowData.status
    ) {
      value = false;
    }
    return value;
  };

  const isSiteNumberValid = (siteNumber, countryName) => {
    if (!siteNumber) {
      return true;
    }
    if (countryName.trim() === "United States")
      return /^44OC[-][0-9]{7}$|44OP[-][0-9]{6}$|4433[-][0-9]{5}[-][0-9]{5}$|4433[-][0-9]{5}[-][0-9]{4}$/.test(siteNumber);
  };

  const updateSite = (submitData: any) => {
    submitData.siteName = submitData.siteName?.trim();
    //submitData.description = submitData.description?.trim();
    let uniqueSiteId: any;
    let isInlineEdit: boolean = false;
    if (submitData.uniqueSiteId) {
      uniqueSiteId = submitData.uniqueSiteId;
      isInlineEdit = true;
    }
    else
      uniqueSiteId = selectedRows[0].uniqueSiteId;

    if (submitData.status === "Active") {
      submitData.statusId = "1"
      submitData.status = "Active"
    }
    else if (submitData.status === "Closed") {
      submitData.statusId = "3"
      submitData.status = "Closed"
    }
    else if (submitData.status === "Blocked") {
      submitData.statusId = "2"
      submitData.status = "Blocked"
    }
    dispatch(
      onUpdateSite(
        uniqueSiteId,
        submitData,
        setMessage,
        setDisplayInfoModal,
        setShowEditModal,
        setSelectedRows,
        isInlineEdit,
        loggedInUserData
      )
    );
  };

  const updateSiteMapCustomer = (uniqueSiteId: any, submitData: any) => {
    if (uniqueSiteId) {
      dispatch(
        onUpdateSiteCustomerMapping(
          uniqueSiteId,
          submitData,
          setDisplayInfoModal,
          setMessage,
        )
      );
    }
  };

  const onBuildingClick = () => {
    if (selectedRows.length > 1) {
      setMessage("This operation can be performed only one row at a time");
      setDisplayInfoModal(true);
    } else if (selectedRows.length < 1) {
      setMessage("Select a Site");
      setDisplayInfoModal(true);
    } else {
      setBuildingData([]);
      setSelectedSite(selectedRows[0]);
      const modifiedsiteList = siteRoleList.map((item) => {
        if (item.name === "Buildings") {
          item["selected"] = !item["selected"];
        } else {
          item["selected"] = false;
        }

        return item;
      });
      setSelectedItem("Buildings");
      setsiteRoleList(modifiedsiteList);
      dispatch(getSiteBuildingList(selectedRows[0]?.uniqueSiteId, 1))
      setHeaderText({
        site: `Site : ${selectedRows[0].siteName} (${selectedRows[0].siteNumber})`,
        building: "Buildings",
        floor: ""
      })
      setSelectedSiteName("Site : " + selectedRows[0].siteName + " (" + selectedRows[0].siteNumber + ")")
    }
  }

  useEffect(() => { 
    if (buildingList && buildingList.length) {
      setBuildingData(buildingList);
      setSelectedRows([buildingList[0]])
      // let body = {
      //   uniqueSiteId: selectedSite?.uniqueSiteId,
      // };
      // dispatch(getBuildingListLookup(selectedSite?.uniqueSiteId, body));
      //need to check this call is required or not
    } else {
      if (showBuildingGrid) {
        setBuildingData(buildingList);
        setMessage("No Buildings exist")
        setDisplayInfoModal(true)
      }
    }
  }, [buildingList])

  const onAddBuildingClick = () => {
    if (selectedSite.status && selectedSite.status !== "Active") {
      setMessage("Site is not active, Building cannot be added")
      setDisplayInfoModal(true)
    } else
      setShowAddBuildingsModal(true)
  }

  const onAddBuildingData = (data: any) => {
    dispatch(onAddBuilding(data, setMessage, setDisplayInfoModal))
  }

  useEffect(() => {
    if (newBuilding) {
      let newData = JSON.parse(JSON.stringify(newBuilding));
      let buildingInfo = JSON.parse(JSON.stringify(buildingData));
      buildingInfo.unshift(newData);
      setBuildingData(buildingInfo);
      setShowAddBuildingsModal(false);
      setSelectedRows([newData]);
      dispatch(setNewBuilding(""))
    }
  }, [newBuilding])

  const onEditBuildingClick = () => {
    if (selectedRows.length > 1) {
      setMessage("This operation can be performed only one row at a time");
      setDisplayInfoModal(true);
    } else if (selectedRows.length < 1) {
      setMessage("Select a Building");
      setDisplayInfoModal(true);
    } else {
      if (selectedSite.status && selectedSite.status !== "Active") {
        setMessage("Site is not active, Building cannot be edited")
        setDisplayInfoModal(true)
      } else
        setShowEditBuildingsModal(true)
    }
  }

  const onBuildingRowEditClick = () => {
    let isEditable = true;
    if (selectedSite.status && selectedSite.status !== "Active") {
      setMessage("Site is not active, Building cannot be edited")
      setDisplayInfoModal(true)
      isEditable = false;
    }
    return isEditable;
  }

  const onBuildingRowEditValidation = (rowData: any) => {
    let value = true;

    if (!rowData.buildingName ) {
      value = false;
    }
    else if(rowData.buildingName?.includes(",")){
    setMessage("Comma is not allowed in building name");
		setDisplayInfoModal(true);
    value = false
    }
    return value;
  }

  const onEditBuildingData = (data: any) => {
    dispatch(onUpdateBuilding(data, selectedRows[0]?.uniqueBuildingId, setMessage, setDisplayInfoModal))
  }

  const onBuildingRowEdit = (data: any) => {
    data.uniqueSiteId = selectedSite?.uniqueSiteId;
    dispatch(onUpdateBuilding(data, data?.uniqueBuildingId, setMessage, setDisplayInfoModal))
  }

  useEffect(() => {
    if (updatedBuildingData) {
      let updatedData = JSON.parse(JSON.stringify(updatedBuildingData));
      let buildingInfo = JSON.parse(JSON.stringify(buildingData));
      let currentIndex = buildingInfo.findIndex((item: any) => item.uniqueBuildingId === updatedData.uniqueBuildingId);
      const newBuildingList: any = buildingInfo.filter((item: any) => item.uniqueBuildingId !== updatedData.uniqueBuildingId);
      newBuildingList.splice(currentIndex, 0, updatedData);
      setBuildingData(newBuildingList);
      setShowEditBuildingsModal(false);
      dispatch(setUpdatedBuildingData(""));
    }
  }, [updatedBuildingData])

  const deleteBuildingClick = () => {
    if (selectedRows.length > 1) {
      setMessage("This operation can be performed only one row at a time");
      setDisplayInfoModal(true);
    } else if (selectedRows.length < 1) {
      setMessage("Select a Building");
      setDisplayInfoModal(true);
    } else {
      dispatch(checkBuildigInUse(selectedRows[0]?.uniqueSiteId, selectedRows[0].uniqueBuildingId))
    }
  }

  useEffect(() => {
    if (buildingInUse) {
      if (buildingInUse?.isBuildingInUSe) {
        setMessage("Building cannot be deleted, Floors are attached to it");
        setDisplayInfoModal(true);
      } else {
        setDisplayConfirmBuildingModal(true);
        setMessage("Are you sure you want to delete the selected Building?");
      }
      dispatch(setBuildingInUse(""))
    }
  }, [buildingInUse])

  const onBuildingConfirmClick = () => {
    setDisplayConfirmBuildingModal(false);
    dispatch(deleteBuilding(selectedRows[0]?.uniqueSiteId, selectedRows[0]?.uniqueBuildingId, setSelectedRows, setMessage, setDisplayInfoModal))
  }

  useEffect(() => {
    if (floorList && floorList.length) {
      setSelectedRows([floorList[0]])
      setFloorData(floorList);
    } else {
      if (showFloorGrid) {
        setFloorData(floorList);
        setMessage("No Floors exist");
        setDisplayInfoModal(true);
      }
    }
  }, [floorList])

  useEffect(() => {
    if (siteData && siteData.length) {
      if (selectedRows && selectedRows.length && (selectedItem === 'Sites')) {
        setSelectedRows(selectedRows);
      } 
     else if (selectedRows && selectedRows.length && (selectedItem === 'Floors')) {
        setSelectedRows(selectedRows);
      }
      else if (selectedRows && selectedRows.length && (selectedItem === 'Buildings')) {
        setSelectedRows(selectedRows);
      }
      else {
        setSelectedRows([siteData[0]]);
      }
    }
  }, [siteData])

  const onFloorClick = () => {
    if (selectedRows.length > 1) {
      setMessage("This operation can be performed only one row at a time");
      setDisplayInfoModal(true);
    } else if (selectedRows.length < 1) {
      setMessage("Select a Building");
      setDisplayInfoModal(true);
    } else {
      setFloorData([]);
      setSelectedBuilding(selectedRows[0])
      const modifiedsiteList = siteRoleList.map((item) => {
        if (item.name === "Floors") {
          item["selected"] = !item["selected"];
        } else {
          item["selected"] = false;
        }

        return item;
      });
      setSelectedItem("Floors");
      setsiteRoleList(modifiedsiteList);
      dispatch(getFloorList(selectedSite?.uniqueSiteId, selectedRows[0].uniqueBuildingId, 1));
      setSelectedRows([]);
      setHeaderText({
        site: selectedSiteName,
        building: `Building : ${selectedRows[0].buildingName}`,
        floor: "Floors"
      });
    }
  }

  const onAddFloorClick = () => {
    if (selectedSite.status && selectedSite.status !== "Active") {
      setMessage("Site is not active, Floor cannot be added")
      setDisplayInfoModal(true)
    } else
      setShowAddFloorsModal(true)
  }

  const onAddFloorData = (data: any) => {
    dispatch(onAddFloor(data, selectedSite?.uniqueSiteId, setMessage, setDisplayInfoModal))
  }

  useEffect(() => {
    if (newFloorData) {
      let newData = JSON.parse(JSON.stringify(newFloorData));
      let floorInfo = JSON.parse(JSON.stringify(floorData));
      floorInfo.unshift(newData);
      setFloorData(floorInfo);
      dispatch(setFloorList(floorInfo));
      setShowAddFloorsModal(false);
      setSelectedRows([newData]);
      dispatch(setNewFloorData(""))
    }
  }, [newFloorData])

  const onEditFloorClick = () => {
    if (selectedRows.length > 1) {
      setMessage("This operation can be performed only one row at a time");
      setDisplayInfoModal(true);
    } else if (selectedRows.length < 1) {
      setMessage("Select a Floor");
      setDisplayInfoModal(true);
    } else {
      if (selectedSite.status && selectedSite.status !== "Active") {
        setMessage("Site is not active, Floor cannot be edited")
        setDisplayInfoModal(true)
      } else
        setShowEditFloorsModal(true)
    }
  }


  const onEditFloorData = (data: any) => {
    dispatch(onUpdateFloor(data, selectedRows[0]?.uniqueFloorId, selectedRows[0]?.uniqueSiteId, setMessage, setDisplayInfoModal))
  }

  const onFloorRowEditClick = () => {
    let isEditable = true;
    if (selectedSite.status && selectedSite.status !== "Active") {
      setMessage("Site is not active, Building cannot be edited")
      setDisplayInfoModal(true)
      isEditable = false;
    }
    return isEditable;
  }

  const onFloorRowEditValidation = (rowData: any) => {
    let value = true;

    if (!rowData.floorName || !rowData.buildingName) {
      value = false;
    }
    return value;
  }

  const onFloorRowEdit = (data: any) => {
    let building: any;
    building = buildngDetails?.find((item: any) => item.buildingName === data?.buildingName)
    data.uniqueBuildingId = building.uniqueBuildingId
    dispatch(onUpdateFloor(data, data?.uniqueFloorId, data?.uniqueSiteId, setMessage, setDisplayInfoModal))
  }

  useEffect(() => {
    if (updatedFloor) {
      let updatedData = JSON.parse(JSON.stringify(updatedFloor));
      let floorInfo = JSON.parse(JSON.stringify(floorData));
      let currentIndex = floorInfo.findIndex((item: any) => item.uniqueFloorId === updatedData.uniqueFloorId);
      const newFloorList: any = floorInfo.filter((item: any) => item.uniqueFloorId !== updatedData.uniqueFloorId);
      newFloorList.splice(currentIndex, 0, updatedData);
      setFloorData(newFloorList);
      setShowEditFloorsModal(false);
      dispatch(setUpdatedFloor(""));
    }
  }, [updatedFloor])

  const onDeleteFloorClick = () => {
    if (selectedRows.length > 1) {
      setMessage("This operation can be performed only one row at a time");
      setDisplayInfoModal(true);
    } else if (selectedRows.length < 1) {
      setMessage("Select a Floor");
      setDisplayInfoModal(true);
    } else {
      setMessage("All data linked to the selected Floor will be deleted. Are you sure you want to delete the Floor?")
      setDisplayConfirmFloorModal(true);

    }
  }

  const onFloorConfirmClick = () => {
    setDisplayConfirmFloorModal(false);
    dispatch(deleteFloor(selectedRows[0].uniqueSiteId, selectedRows[0].uniqueBuildingId, selectedRows[0].uniqueFloorId, setSelectedRows, setMessage, setDisplayInfoModal))
  }


  const handleTableColumns = useCallback((data: any) => {
    setTableColumns([...data]);
  }, []);

  const handleFloorTableColumns = useCallback((data: any) => {
    setFloorTableColumns([...data]);
  }, []);

  const onSetAsActiveSiteClick = () => {
    if (selectedRows.length > 1) {
      setMessage("This operation can be performed only one row at a time");
      setDisplayInfoModal(true);
    } else if (selectedRows.length < 1) {
      setMessage("Select a Site");
      setDisplayInfoModal(true);
    } else if (
      selectedRows[0].status !== "Active"
    ) {
      setMessage("Selected Site is not active");
      setDisplayInfoModal(true);
    } else if (
      activeSiteDetails.uniqueSiteId === selectedRows[0].uniqueSiteId
    ) {
      setMessage("You are currently working in the selected Site");
      setDisplayInfoModal(true);
    } else {
      dispatch(setAsActiveSiteForSite(selectedRows[0].uniqueSiteId, setDisplayInfoModal, setMessage, setIsNavigate));
    }
  };

  const getSelectedColumns = useCallback(() => {
    switch (selectedItem) {
      case "Sites": {
        return tableColumns
      }
      case "Buildings": {
        return SITEBUILD_COLS
      }
      case "Floors": {
        return floorTableColumns
      }
      default: {
        return tableColumns
      }
    }
  }, [selectedItem, tableColumns, SITEBUILD_COLS, floorTableColumns]);


  const onClose = () => {
    if (selectedRows.length > 1) {
      setMessage("This operation can be performed only one row at a time");
      setDisplayInfoModal(true);
    } else if (selectedRows.length < 1) {
      setMessage("Select a Site");
      setDisplayInfoModal(true);
    } else {
      if (selectedRows[0]?.status === "Blocked") {
        setDisplayInfoModal(true)
        setMessage("Blocked Site cannot be closed")
      } else if (selectedRows[0].status === "Active") {
        if (loggedInUserData?.userDefaults?.uniqueSiteId !== selectedRows[0]?.uniqueSiteId) {
          dispatch(getCheckSiteForClose(selectedRows[0].uniqueSiteId, setDisplayInfoModal, setMessage, setDisplayConfirmCloseSiteModal, selectedRows[0]));
        } else {
          setMessage("Selected Site is in use, cannot be closed");
          setDisplayInfoModal(true);
        }
      } else if (selectedRows[0].status === "Closed") {
        setDisplayInfoModal(true)
        setMessage("Site is already closed")
      }
    };
  }

  const onReOpenSiteHandler = () => {
    if (selectedRows.length > 1) {
      setMessage("This operation can be performed only one row at a time");
      setDisplayInfoModal(true);
    } else if (selectedRows.length < 1) {
      setMessage("Select a Site");
      setDisplayInfoModal(true);
    } else {
      if (selectedRows[0]?.status === "Blocked") {
        setDisplayInfoModal(true)
        setMessage("Blocked Site cannot be re-opened")
      } else if (selectedRows[0].status === "Active") {
        setDisplayInfoModal(true)
        setMessage("Site is already active")
      } else if (selectedRows[0].status === "Closed") {
        setDisplayConfirmReopenModal(true)
        setMessage("Are you sure you want to  re-open the selected Site?")
      }
    }
  }

  const onReportAllClick = () => {
    setReportFlag('all');
    switch (selectedItem) {
      case "Sites":
        setShowReportModal(true);
        break;
      case "Buildings":
        setShowBuildingReportModal(true);
        break;
      case "Floors":
        setShowFloorReportModal(true);
        break;
      default:
        break;
    }

  };

  const onReportHighlightedClick = () => {
    setReportFlag('highlighted');
    if (selectedRows.length <= 0) {
      setMessage("Select an item");
      setDisplayInfoModal(true);
    } else {
    switch (selectedItem) {
      case "Sites":
        setShowReportModal(true);
        break;
      case "Buildings":
        setShowBuildingReportModal(true);
        break;
      case "Floors":
        setShowFloorReportModal(true);
        break;
      default:
        break;
    }
  }
  };

  const getSelectedSitesId = () => {
    let params: any = [];
    selectedRows.map(item => {
      params.push(item.uniqueSiteId);
    });
    return params;
  };

  const getSelectedBuildingId = () => {
    let params: any = [];
    selectedRows.map(item => {
      params.push(item.uniqueBuildingId);
    });
    return params;
  };

  const getSelectedFloorId = () => {
    let params: any = [];
    selectedRows.map(item => {
      params.push(item.uniqueFloorId);
    });
    return params;
  };

  const onExportAll = () => {
    switch (selectedItem) {
      case "Sites": {
        const reportParameters = {
          exportParameters: {
            uniqueSiteId: "",
            search: keyWord,
            sortColumn: sortField,
            sortOrder: sortOrder
          },
          exportType: "SiteGridReport"
        };
        dispatch(onExportReport(reportParameters, "Sites"));
        break;
      }
      case "Buildings": {
        const reportParameters = {
          exportParameters: {
            uniqueSiteId: selectedSite?.uniqueSiteId,
            search: keyWord,
            sortColumn: sortField,
            sortOrder: sortOrder,
            selectedIds: "",
          },
          exportType: "BuildingGridReport"
        };
        dispatch(onExportReport(reportParameters, "Buildings"));
        break;
      }
      case "Floors": {
        const reportParameters = {
          exportParameters: {
            uniqueSiteId: selectedSite?.uniqueSiteId,
            UniqueBuildingId: selectedBuilding ? selectedBuilding.uniqueBuildingId : "",
            search: keyWord,
            sortColumn: sortField,
            sortOrder: sortOrder,
            selectedIds: ""
          },
          exportType: "FLOORS"
        };
        dispatch(onExportReport(reportParameters, "Floors"));
        break;
      }
      default: {
        const reportParameters = {
          exportParameters: {
            uniqueSiteId: "",
            search: keyWord,
            sortColumn: sortField,
            sortOrder: sortOrder
          },
          exportType: "SiteGridReport"
        };
        dispatch(onExportReport(reportParameters, "Sites"));
        break;
      }
    }
  };

  const onExportHighlightedClick = () => {
    switch (selectedItem) {
      case "Sites": {
        const reportParameters = {
          exportParameters: {
            uniqueSiteId: getSelectedSitesId(),
            search: keyWord,
            sortColumn: sortField,
            sortOrder: sortOrder
          },
          exportType: "SiteGridReport"
        };
        dispatch(onExportReport(reportParameters, "Sites"));
        break;
      }
      case "Buildings": {
        const reportParameters = {
          exportParameters: {
            uniqueSiteId: selectedSite?.uniqueSiteId,
            search: keyWord,
            sortColumn: sortField,
            sortOrder: sortOrder,
            selectedIds: getSelectedBuildingId()
          },
          exportType: "BuildingGridReport"
        };
        dispatch(onExportReport(reportParameters, "Buildings"));
        break;
      }
      case "Floors": {
        const reportParameters = {
          exportParameters: {
            uniqueSiteId: selectedSite?.uniqueSiteId,
            UniqueBuildingId: selectedBuilding ? selectedBuilding.uniqueBuildingId : "",
            search: keyWord,
            sortColumn: sortField,
            sortOrder: sortOrder,
            selectedIds: getSelectedFloorId()
          },
          exportType: "FLOORS"
        };
        dispatch(onExportReport(reportParameters, "Floors"));
        break;
      }
      default: {
        const reportParameters = {
          exportParameters: {
            uniqueSiteId: getSelectedSitesId(),
            search: keyWord,
            sortColumn: sortField,
            sortOrder: sortOrder
          },
          exportType: "SiteGridReport"
        };
        dispatch(onExportReport(reportParameters, "Sites"));
        break;
      }
    }
  };

  const CustomHeaderBuilding = () => {
    return (
      <div className="custom-header">
        <div className="title">{headerText.site}<HiOutlineArrowNarrowRight /></div>
        <div className="title">{headerText.building}</div>
      </div>

    );
  };
  const CustomHeaderFloor = () => {
    return (
      <div className="custom-header">
        <div className="title">{headerText.site}</div>
        <div className="title"><HiOutlineArrowNarrowRight />{headerText.building}</div>
        <div className="title"><HiOutlineArrowNarrowRight />{headerText.floor}</div>
      </div>
    );
  };
  const onAttachmentIconClick = () => {
    if (selectedRows.length > 1) {
      setMessage("This operation can be performed only one row at a time");
      setDisplayInfoModal(true);
    } else if (selectedRows.length < 1) {
      setMessage("Select a Site");
      setDisplayInfoModal(true);
    } else {
      setShowAttachmentListModal(true)
    }
  }
  const onUploadIconClick = () => {
    if (selectedRows.length <= 0) {
      setMessage("Select a Site");
      setDisplayInfoModal(true);
    }
    else {
      setShowAttachmentUploadModal(true)
    }
  }
  const onBuildingAttachmentIconClick = () => {
    if (selectedRows.length > 1) {
      setMessage("This operation can be performed only one row at a time");
      setDisplayInfoModal(true);
    } else if (selectedRows.length < 1) {
      setMessage("Select a Building");
      setDisplayInfoModal(true);
    } else {
      setShowBuildingAttachmnetModel(true)
    }
  }
  const onBuildingUploadIconClick = () => {
    if (selectedRows.length <= 0) {
      setMessage("Select a Building");
      setDisplayInfoModal(true);
    }
    else {
      setShowAttachmentUploadModal(true)
    }
  }
  const onFloorAttachmentIconClick = () => {
    if (selectedRows.length > 1) {
      setMessage("This operation can be performed only one row at a time");
      setDisplayInfoModal(true);
    } else if (selectedRows.length < 1) {
      setMessage("Select a Floor");
      setDisplayInfoModal(true);
    } else {
      setShowFloorAttachmnetModel(true)
    }
  }
  const onFloorAttachmentUploadClick = () => {
    if (selectedRows.length <= 0) {
      setMessage("Select a Floor");
      setDisplayInfoModal(true);
    }
    else {
      setShowAttachmentUploadModal(true)
    }
  }
  return (
    <div className="sites-container  d-flex">
      <section className="administration-site-leftsidebar d-flex flex-column">
        <div className="administration-site-listname">
          <div className="main">
            <ul className="list-items">
              {siteRoleList.map((item) => (
                <li
                  key={item.code}
                  className={item.selected ? "active" : ""}
                  onClick={() => handleRoleSelect(item)}
                >
                  {item.name}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
      <SiteTableData getTableColumns={handleTableColumns} selectedRow={siteRowData} />
      <section className={`${filterExpanded ? "expanded" : ""} site-table`}>
        {showSiteGrid && tableColumns.length > 0 && (
          <Table
            setAsActiveSiteIcon
            showAttachIcon
            rows={siteData}
            cols={tableColumns}
            title="Sites"
            paginator
            pageDetails={sitePagination}
            pageIndex={pageIndex}
            dataKeyId="uniqueSiteId"
            onAddClick={() => setShowAddModal(true)}
            searchText="Enter Site Name / Number"
            onPageChanged={onPageChanged}
            onDownloadClick={(e) => downloadRef.current?.toggle(e)}
            getSelectedRowData={handleRowSelect}
            onDeleteClick={deleteIconClick}
            onEditClick={onEditIconClick}
            onSaveRowEdit={updateSite}
            onRowEditValidation={onRowEditValidation}
            onRowEditClick={onRowEditClick}
            onSetAsActiveSiteClick={onSetAsActiveSiteClick}
            onGlobalSearch={onGlobalSearch}
            onSortData={onSort}
            onAttachmentClick={(e) => attachmentRef.current?.toggle(e)}
            showroweditor={showUI(userPrivileges, 'SIETAP_SITES_MANAGE')}
            showAddIcon={showUI(userPrivileges, 'SIETAP_SITES_MANAGE')}
            showEditIcon={showUI(userPrivileges, 'SIETAP_SITES_MANAGE')}
            showDeleteIcon={showUI(userPrivileges, 'SIETAP_SITES_MANAGE')}
            settingListItems={settingListItems}
            defaultSelectedRows={selectedRows}
            showCustomHeader={false}
          />
        )}
        {showBuildingGrid && (
          <Table
            showAttachIcon
            rows={buildingData}
            cols={SITEBUILD_COLS}
            dataKeyId="uniqueBuildingId"
            title={headerText.site + headerText.building}
            searchText="Enter Building Name"
            getSelectedRowData={handleRowSelect}
            onAddClick={onAddBuildingClick}
            onEditClick={onEditBuildingClick}
            onDeleteClick={deleteBuildingClick}
            onRowEditValidation={onBuildingRowEditValidation}
            onSaveRowEdit={onBuildingRowEdit}
            onRowEditClick={onBuildingRowEditClick}
            pageDetails={buildingPagination}
            paginator
            pageIndex={pageIndex}
            onPageChanged={onPageChanged}
            onGlobalSearch={onGlobalSearch}
            onSortData={onSort}
            onDownloadClick={(e) => downloadRef.current?.toggle(e)}
            settingListItems={settingListItems}
            onAttachmentClick={(e) => buildingAttachRef.current?.toggle(e)}
            defaultSelectedRows={selectedRows}
            CustomHeaderCmp={CustomHeaderBuilding}
            showCustomHeader={true}
          />
        )}
        {showFloorGrid && (
          <>
            <SiteFloorTableData getTableColumns={handleFloorTableColumns} />
            <Table
              showAttachIcon
              rows={floorData}
              cols={floorTableColumns}
              dataKeyId="uniqueFloorId"
              title={headerText.site + headerText.building + headerText.floor}
              searchText="Enter Floor Name"
              getSelectedRowData={handleRowSelect}
              onAddClick={onAddFloorClick}
              onEditClick={onEditFloorClick}
              onDeleteClick={onDeleteFloorClick}
              pageDetails={floorPagination}
              pageIndex={pageIndex}
              paginator
              onPageChanged={onPageChanged}
              onGlobalSearch={onGlobalSearch}
              onSortData={onSort}
              onRowEditValidation={onFloorRowEditValidation}
              onSaveRowEdit={onFloorRowEdit}
              onRowEditClick={onFloorRowEditClick}
              onAttachmentClick={(e) => floorAttachRef.current?.toggle(e)}
              onDownloadClick={(e) => downloadRef.current?.toggle(e)}
              defaultSelectedRows={selectedRows}
              CustomHeaderCmp={CustomHeaderFloor}
              showCustomHeader={true}
            />
          </>
        )}
      </section>

      <InformationBox
        showInfoModal={displayInfoModal}
        setShowInfoModal={setDisplayInfoModal}
        message={message}
      />
      <ConfirmationBox
        showConfirmModal={displayConfirmModal}
        setShowConfirmModal={setDisplayConfirmModal}
        confirmAction={removeSite}
        message={message}
      />
      <ConfirmationBox
        showConfirmModal={displayConfirmReopenModal}
        setShowConfirmModal={setDisplayConfirmReopenModal}
        confirmAction={() => {
          dispatch(onSiteReOpen(selectedRows[0].uniqueSiteId, setDisplayInfoModal, setMessage, setDisplayConfirmReopenModal, selectedRows[0], setSelectedRows));
        }}
        message={message}
      />

      <ConfirmationBox
        showConfirmModal={displayConfirmCloseSiteModal}
        setShowConfirmModal={setDisplayConfirmCloseSiteModal}
        confirmAction={() => {
          dispatch(onCloseSite(selectedRows[0].uniqueSiteId, setDisplayInfoModal, setMessage, setDisplayConfirmCloseSiteModal, selectedRows[0], setSelectedRows));
        }}
        message={message}
      />
      {showAddModal && (
        <AddSite
          showModal={showAddModal}
          setShowModal={setShowAddModal}
          onAddSiteClick={(data) => onAddSiteClick(data)}
        />
      )}
      {showEditModal && (
        <Editsite
          showModal={showEditModal}
          setShowModal={setShowEditModal}
          selectedRows={selectedRows}
          updateSite={(data) => updateSite(data)}
        />
      )}
      {showAddBuildingsModal && (
        <AddBuildings
          showModal={showAddBuildingsModal}
          setShowModal={setShowAddBuildingsModal}
          selectedSite={selectedSite}
          onAddBuildingClick={onAddBuildingData}
        />
      )}
      {showEditBuildingsModal && (
        <EditBuildings
          showModal={showEditBuildingsModal}
          setShowModal={setShowEditBuildingsModal}
          selectedRow={selectedRows}
          onEditBuildingClick={onEditBuildingData}
        />
      )}
      <ConfirmationBox
        showConfirmModal={displayConfirmBuildingModal}
        setShowConfirmModal={setDisplayConfirmBuildingModal}
        confirmAction={onBuildingConfirmClick}
        message={message}
      />
      <ConfirmationBox
        showConfirmModal={displayConfirmFloorModal}
        setShowConfirmModal={setDisplayConfirmFloorModal}
        confirmAction={onFloorConfirmClick}
        message={message}
      />
      {showAddFloorsModal && (
        <AddFloors
          showModal={showAddFloorsModal}
          setShowModal={setShowAddFloorsModal}
          onAddFloor={onAddFloorData}
          selectedSite={selectedSite}
        />
      )}
      {showEditFloorsModal && (
        <EditFloors
          showModal={showEditFloorsModal}
          setShowModal={setShowEditFloorsModal}
          selectedRow={selectedRows[0]}
          onEditFloor={onEditFloorData}
        />
      )}
      {showSiteMapCustomer && (
        <SiteMapCustomer
          showModal={showSiteMapCustomer}
          setShowModal={setShowSiteMapCustomer}
          selectedRows={selectedRows}
          updateSiteMapCustomer={(siteId, data) => updateSiteMapCustomer(siteId, data)}
        />
      )}
      <PopOver ref={attachmentRef}>
        <ul>
          <li onClick={onAttachmentIconClick}>List</li>
          {showUI(userPrivileges, "SIETAP_SITE_ATTACHMENT_UPLOAD") && <li onClick={onUploadIconClick}>Upload</li>}
        </ul>
      </PopOver>
      <PopOver ref={buildingAttachRef}>
        <ul>
          <li onClick={onBuildingAttachmentIconClick}>List</li>
          <li onClick={onBuildingUploadIconClick}>Upload</li>
        </ul>
      </PopOver>
      <PopOver ref={floorAttachRef}>
        <ul>
          <li onClick={onFloorAttachmentIconClick}>List</li>
          <li onClick={onFloorAttachmentUploadClick}>Upload</li>
        </ul>
      </PopOver>
      <PopOver ref={downloadRef}>
        <ul>
          <li onClick={onReportAllClick}>Report All</li>
          <li onClick={onReportHighlightedClick}>Report Highlighted</li>
          <li onClick={onExportAll}>Export All</li>
          <li onClick={onExportHighlightedClick}>Export Highlighted</li>
        </ul>
      </PopOver>
      {showAttachmentListModal ? (
        <AttachmentList
          showModal={showAttachmentListModal}
          setShowModal={setShowAttachmentListModal}
          selectedRows={selectedRows}
        />
      ) : null}
      {showBuildingAttachmnetModel ? (
        <BuildingAttachmentList
          showModal={showBuildingAttachmnetModel}
          setShowModal={setShowBuildingAttachmnetModel}
          selectedRows={selectedRows}
          selectedSite={selectedSite}
        />
      ) : null}
      {showFloorAttachmnetModel ? (
        <FloorAttachmentList
          showModal={showFloorAttachmnetModel}
          setShowModal={setShowFloorAttachmnetModel}
          selectedRows={selectedRows}
          selectedSite={selectedSite}
        />
      ) : null}
      {showAttachmentUploadModal ? (
        <AttachmentUpload
          showModal={showAttachmentUploadModal}
          setShowModal={setShowAttachmentUploadModal}
          selectedRows={selectedRows}
          setDisplayInfoModal={setDisplayInfoModal}
          setMessage={setMessage}
          selectedTab={selectedItem}
          selectedSite={selectedSite}
          setSelectedRows={setSelectedRows}
        />
      ) : null}
      {showReportModal ? (
        <SiteReport showModal={showReportModal}
          setShowModal={setShowReportModal}
          activeSiteId={activeSiteDetails?.uniqueSiteId}
          reportFlag={reportFlag}
          selectedRows={selectedRows}
          sortByColumns={getSelectedColumns()}
          searchKeyWord={keyWord} />
      ) : null}
      {showBuildingReportModal ? (
        <BuildingReport showModal={showBuildingReportModal}
          setShowModal={setShowBuildingReportModal}
          reportFlag={reportFlag}
          selectedRows={selectedRows}
          uniqueSiteId={selectedSite?.uniqueSiteId}
          searchText={keyWord}
          sortByColumns={getSelectedColumns()} />
      ) : null}
      {showFloorReportModal ? (
        <FloorReport showModal={showFloorReportModal}
          setShowModal={setShowFloorReportModal}
          reportFlag={reportFlag}
          selectedRows={selectedRows}
          uniqueSiteId={selectedSite?.uniqueSiteId}
          selectedBuilding={selectedBuilding}
          searchText={keyWord}
          sortByColumns={getSelectedColumns()} />
      ) : null}
    </div>
  );
};

export default Sites;