export const LOG_COLS = [
	{
		field: 'action',
		header: 'Action',
		style: { flex: '1 0 100px' },
		sortable: true,
	},
    {
		field: 'date',
		header: 'Date',
		style: { flex: '1 0 100px' },
		sortable: true,
	},
    {
		field: 'sourceCustomer',
		header: 'Source Customer',
		style: { flex: '1 0 200px' },
		sortable: true,
	},
    {
		field: 'destinationCustomer',
		header: 'Destination Customer',
		style: { flex: '1 0 200px' },
		sortable: true,
	},
    {
		field: 'actionBy',
		header: 'Action By',
		style: { flex: '1 0 200px' },
		sortable: true,
	},
];

