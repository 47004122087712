import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { showUI } from 'service/GeneralUtility';
//components
import InspectionForms from "components/common/Forms/InspectionForm/InspectionForms";
import InformationBox from "components/common/Message/InformationBox/InformationBox";
import ConfirmationBox from "components/common/Message/ConfirmationBox/ConfirmationBox";
//redux
import { useDispatch } from "react-redux";
import { setIsReportUpdated } from "redux/pages/Inspection/InspectionTask/inspectionTaskSlice";
import { getInspectionSystems, onGetInspectionSystemFieldValues, onSaveInspectionSystem, setInspectionSystemFieldValues, setInspectionSystems, setUpdatedSystemDatafields } from "redux/pages/Inspection/InspectionTask/InspectionDetails/InspectionDetails/InspectionDetailsSlice";
import { useAppSelector } from "redux/hooks";
//utility
import { addDefaultDataValues, convertInspectionFields } from "pages/ManageInspection/InspectionFields/Utility/InspectionFieldUtility";
import { ProgressSpinner } from "primereact/progressspinner";

interface SystemPropType {
    selectedTask?: any;
    setIsValueChanged?: any;
    displayConfirmModal?: any;
    setDisplayConfirmModal?: any;
    cancelUpdate?: () => void;
    confirmMessage?: any;
    hideConfirmModal?: any;
    reportBtnClicked?: any;
}
const Systems: React.FC<SystemPropType> = (props) => {
    const {
        selectedTask,
        setIsValueChanged,
        displayConfirmModal,
        setDisplayConfirmModal,
        cancelUpdate = () => { },
        confirmMessage,
        hideConfirmModal,
        reportBtnClicked } = props;

    const inspectionFormRef = useRef<any>(null);
    const [inspectionFormSetup, setInspectionFormSetup] = useState<any>([]);
    const [displayInfoModal, setDisplayInfoModal] = useState(false);
    const [message, setMessage] = useState<string>("");
    const [showProgress, setShowProgress] = useState(false);//progress bar
    const [resetFormField, setResetFormField] = useState<boolean>(false);
    const [fieldFocus, setFieldFocus] = useState<boolean>(false);

    const dispatch = useDispatch<any>();
    const inspectionSystems = useAppSelector((state) => state.inspectionDetails.inspectionSystems);
    const updatedDatafields = useAppSelector((state) => state.inspectionDetails.updatedSystemDatafields);
    const inspectionSystemFieldValues = useAppSelector((state) => state.inspectionDetails.inspectionSystemFieldValues);
    const isReportUpdated = useAppSelector((state) => state.inspectionTask.isReportUpdated);
    const userPrivileges = useAppSelector((state) => state.user.userPrivileges);

    const body = {
        uniqueTaskId: selectedTask?.uniqueInspectionTaskId,
        uniqueSystemId: selectedTask?.uniqueSystemId,
    }

    useEffect(() => {
        if (selectedTask) {
            dispatch(getInspectionSystems(selectedTask?.uniqueInspectionTaskId, selectedTask?.uniqueSystemId));
            dispatch(setIsReportUpdated(false));
        }
    }, [selectedTask, isReportUpdated, resetFormField]);

    useEffect(() => {
        if (inspectionFormSetup?.inspectionGroupFields) {
            setShowProgress(true);
            dispatch(onGetInspectionSystemFieldValues(selectedTask?.uniqueInspectionTaskId, setShowProgress));
        }
    }, [inspectionFormSetup])

    useEffect(() => {
        if (inspectionSystems) {
            let items = JSON.parse(JSON.stringify(inspectionSystems));
            let convFields = convertInspectionFields(items)
            setInspectionFormSetup(convFields);
            dispatch(setInspectionSystems(""));
        }
    }, [inspectionSystems]);

    useEffect(() => {
        if (updatedDatafields) {
            let conv = JSON.parse(JSON.stringify(inspectionSystemFieldValues))
            const result = addDefaultDataValues(conv, updatedDatafields?.fieldDetails);
            dispatch(setInspectionSystemFieldValues(result));
            if (!reportBtnClicked) {
                setMessage("System updated");
                setDisplayInfoModal(true);
            }
            cancelUpdate();
            dispatch(dispatch(setUpdatedSystemDatafields("")));
        }
    }, [updatedDatafields])

    const onInspectionSubmit = useCallback((data) => {
        const body = {
            fieldDetails: data,
        }
        setShowProgress(true);
        dispatch(onSaveInspectionSystem(selectedTask?.uniqueInspectionTaskId, selectedTask?.uniqueSystemId, body, setShowProgress));
        setFieldFocus(false);
    }, []);

    const checkIsValueChanged = useCallback((e) => {
        if (e) {
            setIsValueChanged(true);
            setFieldFocus(false);
        }
    }, [])

    const onCancel = () => {
        if (reportBtnClicked) {
            setResetFormField(!resetFormField);
        }
        cancelUpdate();
    }

    const onConfirmClick = () => {
        inspectionFormRef?.current?.requestSubmit();
        setDisplayConfirmModal(false);
    }

    const onInspectionFieldError = (error) => {
        if(error) {
            setMessage(error);
            setDisplayInfoModal(true);
            setFieldFocus(true);
        }
    };

    return (
        <>
            {showProgress &&
                (
                    <div className="pre-loader inspection-system">
                        <ProgressSpinner />
                    </div>
                )
            }
            <div className="parent">
                <div className="parent-container">
                    <div className={`questions-wrap`}>
                        <InspectionForms
                            type='SYSTEM'
                            ref={inspectionFormRef}
                            isAddAction={true}
                            isCheckedAction={true}
                            showAction={false}
                            formElements={inspectionFormSetup}
                            inspectionFieldValues={inspectionSystemFieldValues}
                            body={body}
                            onInspectionSubmit={onInspectionSubmit}
                            checkIsValueChanged={checkIsValueChanged}
                            onInspectionFieldError={onInspectionFieldError}
                            fieldFocus={fieldFocus}
                        />
                        {inspectionFormSetup?.inspectionGroupFields?.length > 0 && selectedTask?.status === "Active" && (
                            <div className="footer-btn text-left">
                                {showUI(userPrivileges, "SIETAP_INSPECTIONDETAILS_SYSTEM_SAVE") && <Button className={`save-btn`} onClick={() => inspectionFormRef?.current?.requestSubmit()}>
                                    Save
                                </Button>}
                            </div>
                        )}
                    </div>
                </div>
                <InformationBox
                    showInfoModal={displayInfoModal}
                    setShowInfoModal={setDisplayInfoModal}
                    message={message}
                />

                <ConfirmationBox
                    showConfirmModal={displayConfirmModal}
                    setShowConfirmModal={setDisplayConfirmModal}
                    cancelAction={() => onCancel()}
                    onHideAction={hideConfirmModal}
                    confirmAction={onConfirmClick}
                    message={confirmMessage}
                />
            </div>
        </>
    );
};

export default Systems;
