import React, { useEffect, useRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';

import { BsExclamationCircleFill } from 'react-icons/bs';

import AddModal from 'components/Table/AddModal/AddModal';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'redux/hooks';
import { getReportDetails, getAllReportGroups } from 'redux/pages/Manage/InspectionSettings/Reports/ReportsSlice';
import { RadioButton } from "primereact/radiobutton";
import { getStatusList } from 'redux/pages/Administration/administrationSlice';

interface Props {
    showModal: boolean,
    setShowModal: (state: boolean) => void
    selectedCountry: string
    selectedCountryId: any
    seletedRows: any
    onEditReportClick: (data: any) => void
}

type FormData = {
    countryId: string,
    reportName: string,
    reportTitle: string,
    reportGroupId: string,
    reportStatus: boolean
};

export const EditReports: React.FC<Props> = ({
    showModal = false,
    setShowModal = () => { },
    selectedCountry = '',
    selectedCountryId,
    seletedRows = [],
    onEditReportClick = () => { },
}) => {

   

    const editForm: any = useRef();

    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<FormData>({
        defaultValues: {
            countryId: selectedCountryId,
            reportName: '',
            reportTitle: '',
            reportGroupId: '',
            reportStatus: true
        }
    });

    const statusList = useAppSelector((state) => state.administration.statusList);
    const reportGroupsData = useAppSelector((state) => state.report.reportGroupsData);
    const reportDetails = useAppSelector((state) => state.report.reportDetails);
    const [reportGroupLookup, setReportGroupLookup] = useState([{}]);
    
    const statusLookup = [{ name: "Active", key: "Active" },{ name: "Inactive", key: "Inactive" }];
    const [status, setStatus] = useState<any>([]);
    const dispatch = useDispatch<any>();

    useEffect(() => {
        dispatch(getReportDetails(seletedRows[0].reportId, selectedCountryId))
        dispatch(getAllReportGroups())
        dispatch(getStatusList())
    }, [dispatch, selectedCountryId, seletedRows])

    useEffect(() => {
        
        if (reportDetails && statusList) {
          let reportGroup: any = [];
          reportGroup.push({ name: reportDetails?.inspectionReportGroup.inspectionReportGroupName, code: reportDetails?.inspectionReportGroup.inspectionReportGroupId })
          setValue("reportName", reportDetails?.reportName)
          setValue("reportTitle", reportDetails?.reportTitle)
          setValue("reportGroupId", reportGroup[0])
          setValue("reportStatus", reportDetails?.status)
          let status = statusList?.status.find((item: any) => item.statusId === reportDetails?.status)          
          setStatus({ name: status.statusName, key: status.statusCode })
          
        }
      }, [reportDetails,statusList, setValue])

    useEffect(() => {
        if (reportGroupsData) {
            let reportGroup: any = [];
            reportGroupsData.map((item: any) => {
                return reportGroup.push({ name: item.reportGroupName, code: item.reportGroupId })
            })
            setReportGroupLookup(reportGroup)
        }
    }, [reportGroupsData])
  
    const formSubmitHandler = (data: any) => {
        editForm.current.requestSubmit();
    };

    const getFormErrorMessage = (name) => {
        return errors[name] && <span className="tooltip-text"><BsExclamationCircleFill />{errors[name].message}</span>
    };

    const onEditFormSubmit = (data: any) => {
        data.reportStatus = status.key==="Active"?1:36
        data.reportGroupId = data.reportGroupId.code
        onEditReportClick && onEditReportClick(data)
    };

    const onError = (errors: any, e: any) => { };

    return (
        <>
            <AddModal
                header='Edit Report'
                showModal={showModal}
                isAdd={false}
                setShowModal={setShowModal}
                style={{ width: '35vw', height: '55vh' }}
                OnSaveClick={formSubmitHandler}
            >
                <form onSubmit={handleSubmit(onEditFormSubmit, onError)} ref={editForm}>
                  

                   

                    <div className="row px-2">
                        <div className="field col-12 md:col-2 mb-0">
                            <span className="p-float-label text-container">
                                <label htmlFor="inputtext">Country</label>
                                <Controller
                                    name="countryId"
                                    control={control}
                                    render={() => (
                                        <span className='text-value'>{selectedCountry}</span>
                                    )} />
                            </span>
                        </div>
                    </div>

                  

                    <div className="row px-2 pt-2">
                        <div className="field col-12 md:col-2 mb-0">
                            <span className="p-float-label">
                            <Controller
                                    name="reportName"
                                    control={control}
                                    rules={{
                                        required: 'Report Name is required.'
                                    }}

                                    render={({ field, fieldState }) => (
                                        <InputText
                                            id="reportName"
                                            maxLength={100}
                                            className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })}
                                            {...field}
                                        />
                                    )} />
                                <label className='mandatory' htmlFor="inputtext">Report Name</label>
                                {getFormErrorMessage('reportName')}
                            </span>
                        </div>
                    </div>

               
                  

                   
                    <div className="row px-2 py-2">
                        <div className="field col-12 md:col-2 mb-0 pb-0">
                            <span className="p-float-label">
                            <Controller
                                    name="reportTitle"
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <InputText
                                            id="reportTitle"
                                            maxLength={150}
                                            className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })}
                                            {...field}
                                        />
                                    )} />
                                <label htmlFor="inputtext">Report Title</label>
                            </span>
                        </div>
                   
                    </div>

              

                    <div className="row px-2 pt-2 pb-2">
                        <div className="field col-12 md:col-2 mb-0 pb-0">
                            <span className="p-float-label">
                            <Controller
                                    name="reportGroupId"
                                    control={control}
                                    rules={{
                                        required: 'Report Group is required.'
                                    }}
                                    render={({ field, fieldState }) => (
                                        <Dropdown
                                            inputId="reportGroup"
                                            className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })}
                                            {...field}
                                            value={field.value}
                                            onChange={(e) => field.onChange(e.value)}
                                            options={reportGroupLookup}
                                            optionLabel="name"
                                        />
                                    )} />
                                <label className='mandatory' htmlFor="dropdown">Report Group</label>
                                {getFormErrorMessage('reportGroupId')}
                            </span>
                        </div>
                        
                        <div className='radio-section d-flex align-items-center pt-2 pl-2'>
                                {
                                    statusLookup.map((item) => {
                                        return (
                                            <div key={item.key} className="field-radiobutton mb-0 pr-4">
                                                <RadioButton inputId={item.key} name="reportStatus" value={item} onChange={(e) => setStatus(e.value)} checked={status.key === item.key} />
                                                <label htmlFor={item.key}>{item.name}</label>
                                            </div>
                                        )
                                    })
                                }
                        </div>
                                              

                    </div>
                </form>
            </AddModal>
        </>
    );
}

export default EditReports;