import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { zlink, baseURL } from "../../../../../service/HttpService";
import { AppThunk } from "../../../../store";

export interface ReportGroupState {
  errors: any;
  reportGroupList: any;
  newReportGroupData: any;
  isReportGroupInUse: any;
  deletedReportGroupData: any;
  reportGroupDetails: any;
  updatedReportGroupData: any;
}

let initialState: ReportGroupState = {
  errors: "",
  reportGroupList: [],
  newReportGroupData: "",
  isReportGroupInUse: "",
  deletedReportGroupData: "",
  reportGroupDetails: "",
  updatedReportGroupData: "",
};

const ReportGroupSlice = createSlice({
  name: "reportGroup",
  initialState,
  reducers: {
    setErrors: (state, { payload }: PayloadAction<any>) => {
      state.errors = payload;
    },
    setReportGroupList: (state, { payload }: PayloadAction<any>) => {
      state.reportGroupList = payload; 
    },
    setNewReportGroupData: (state, { payload }: PayloadAction<any>) => {
        state.newReportGroupData = payload; 
    },
    setIsReportGroupInUse: (state, { payload }: PayloadAction<any>) => {
      state.isReportGroupInUse = payload; 
    },
    setDeletedReportGroupData: (state, { payload }: PayloadAction<any>) => {
      state.deletedReportGroupData = payload; 
    },
    setReportGroupDetails: (state, { payload }: PayloadAction<any>) => {
      state.reportGroupDetails = payload; 
    },
    setUpdatedReportGroupData: (state, { payload }: PayloadAction<any>) => {
      state.updatedReportGroupData = payload; 
    },
  },
});

export const getReportGroupList =
  (): AppThunk =>
  async (dispatch) => {
    try {
      const res = await zlink.get(`${baseURL}api/v1/inspection/reports/groups?reportlist=no`);
      if (res.status === "done") {
        dispatch(setReportGroupList(res?.data?.reportGroupDetails));
      } else {
        dispatch(setErrors(res.error));
      }
    } catch (error) {
      dispatch(setErrors(error));
    }
  };

export const onAddReportGroup =
    (body: any, setMessage, setDisplayInfoModal): AppThunk =>
        async (dispatch) => {
            try {
                const res = await zlink.post(`${baseURL}api/v1/inspection/reports/groups/add`, body);
                if (res.status === "done") {
                    dispatch(setNewReportGroupData(res.data));
                    setDisplayInfoModal(true);
                    setMessage("Report Group added");
                } else {
                    dispatch(setErrors(res.error));
                    setDisplayInfoModal(true);
                    setMessage(res.message);
                }
            } catch (error) {
                dispatch(setErrors(error));
            }
        };

export const checkIsReportGroupInUse =
  (reportGroupId: any): AppThunk =>
    async (dispatch) => {
      try {
        const res = await zlink.get(`${baseURL}api/v1/inspection/reports/groups/${reportGroupId}/checkreportgroupinuse`);
        if (res.status === "done") {
          dispatch(setIsReportGroupInUse(res?.data));
        } else {
          dispatch(setErrors(res.error));
        }
      } catch (error) {
        dispatch(setErrors(error));
      }
    };

export const deleteReportGroup = (reportGroupId, setMessage, setDisplayInfoModal): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.post(`${baseURL}api/v1/inspection/reports/groups/${reportGroupId}/remove`, null);
    if (res.status === "done") {
      dispatch(setDeletedReportGroupData(res))
      setMessage("Report Group deleted");
      setDisplayInfoModal(true);
    } else {
      setMessage(res.message);
      setDisplayInfoModal(true);
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const getReportGroupDetails =
  (reportGroupId: any): AppThunk =>
    async (dispatch) => {
      try {
        const res = await zlink.get(`${baseURL}api/v1/inspection/reports/groups/${reportGroupId}`);
        if (res.status === "done") {
          dispatch(setReportGroupDetails(res?.data));
        } else {
          dispatch(setErrors(res.error));
        }
      } catch (error) {
        dispatch(setErrors(error));
      }
    };

export const onUpdateReportGroup =
  (body: any,reportGroupId: any, setMessage, setDisplayInfoModal): AppThunk =>
    async (dispatch) => {
      try {
        const res = await zlink.post(`${baseURL}api/v1/inspection/reports/groups/${reportGroupId}`, body);
        if (res.status === "done") {
          dispatch(setUpdatedReportGroupData(res.data));
          setDisplayInfoModal(true);
          setMessage("Report Group updated");
        } else {
          dispatch(setErrors(res.error));
          setDisplayInfoModal(true);
          setMessage(res.message);
        }
      } catch (error) {
        dispatch(setErrors(error));
      }
    };
export const { setErrors,
     setReportGroupList,
     setNewReportGroupData,
     setIsReportGroupInUse,
     setDeletedReportGroupData,
     setReportGroupDetails,
     setUpdatedReportGroupData } =
ReportGroupSlice.actions;
export default ReportGroupSlice.reducer;
