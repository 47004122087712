import { Button } from "primereact/button";
import React from "react";
import { MdOutlineListAlt } from "react-icons/md";

const ReportsDocument = () => {
    return (
        <>
            <h4 className="doc-title mb-2">
                Reports
            </h4>
            <p>
                All Inspection Related and Administrative Reports are grouped under 
                <span className="doc-subtitle"> Reports </span> 
                <Button className="doc-button-icon">
                    <MdOutlineListAlt className="icon" title="Reports"/>
                </Button>
                {" "} icon
            </p>
        </>
    );
}

export default ReportsDocument;