import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../redux/hooks";
import Table from "components/Table/Table";
import { UNLINKEDEQUIPMENTGROUPS_COLS } from "./UnlinkedEquipmentGroupsData";
import InformationBox from "components/common/Message/InformationBox/InformationBox";
import ConfirmationBox from "components/common/Message/ConfirmationBox/ConfirmationBox";
import {onDeleteUnlinkedEquipmentGroup,setUnlinkedEquipmentGroups} from "redux/pages/Inspection/Systems/systemSlice";

interface Props {
  selectedRows: any;
  showModal: boolean;
  setShowModal: (state: boolean) => void;
  defaultSiteId:any;
  defaultSiteName:any;
}

export const UnlinkedEquipmentGroups: React.FC<Props> = ({
  selectedRows = [],
  setShowModal = () => { },
  showModal = false,
  defaultSiteId = "",
  defaultSiteName = "",
}) => {
  const unlinkedEquipmentGroups = useAppSelector((state) => state.system.unlinkedEquipmentGroups);
  const [selectedEquipmentGroup, setSelectedEquipmentGroup] = useState<any>([]);
  const [displayInfoModal, setDisplayInfoModal] = useState(false);
  const [displayConfirmModal, setDisplayConfirmModal] = useState(false);
  const [message, setMessage] = useState("");
  const dispatch = useDispatch<any>();

  const handleRowSelect = (data: any) => {
    setSelectedEquipmentGroup([...data]);
  };

  const onOkClick = () => {
    setDisplayInfoModal(false);
  }

  const onConfirmClick = () => {
    setDisplayConfirmModal(false);
    dispatch(onDeleteUnlinkedEquipmentGroup(defaultSiteId,selectedEquipmentGroup[0]?.equipmentId,setSelectedEquipmentGroup,setDisplayInfoModal,setMessage));
  }

  const onCancelClick = () => {
    setDisplayInfoModal(false);
    setDisplayConfirmModal(false);
  }

  const onHide=()=>{
    dispatch(setUnlinkedEquipmentGroups([]));
    setShowModal(false);
  }

  const onDeleteClick = () => {
    if (selectedEquipmentGroup.length < 1) {
      setMessage("Select an Equipment Group");
      setDisplayInfoModal(true);
    } else if (selectedEquipmentGroup.length > 1) {
      setMessage("This operation can be performed only one row at a time");
      setDisplayInfoModal(true);
    } else if (selectedEquipmentGroup.length === 1) {
      setMessage("Selected Equipment Group and linked equipment will be lost from all Inspection Tasks. Do you want to proceed?");
      setDisplayConfirmModal(true); 
    }
  }

  return (
    <>
      <Dialog
        header="Unlinked Equipment Groups"
        visible={showModal}
        style={{ width: "70vw" }}
        onHide={() => onHide()}
        className="display-settings personal selectsite equipment-groups"
      >
        <div className="content-box">
          <div className="header-text">
            <span className="label">Site: </span>
            <span className="value">{defaultSiteName}</span>
          </div>
          <div className="table-wrap">
            <Table
              rows={unlinkedEquipmentGroups}
              cols={UNLINKEDEQUIPMENTGROUPS_COLS.LIST_COLS}
              dataKeyId="equipmentId"
              title={``}
              showroweditor={false}
              searchboxfield={false}
              showAddIcon={false}
              showEditIcon={false}
              showDeleteIcon={false}
              showDownloadIcon={false}
              showSettingIcon={false}
              getSelectedRowData={handleRowSelect}
            />
          </div>
          <div className="footer text-left">
            <Button
              label="Cancel"
              onClick={() => {onHide()}}
              className="cancel-btn"
            />
            <Button
              label="Delete"
              onClick={onDeleteClick}
              className="submit-btn"
            />
          </div>
        </div>
      </Dialog>
      <InformationBox showInfoModal={displayInfoModal} setShowInfoModal={setDisplayInfoModal} okOnClick={onOkClick} message={message} />
      <ConfirmationBox showConfirmModal={displayConfirmModal} setShowConfirmModal={setDisplayConfirmModal} confirmAction={onConfirmClick} cancelAction={onCancelClick} message={message} />
    </>
  );
};

export default UnlinkedEquipmentGroups;
