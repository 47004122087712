import React, { memo, useEffect, useState } from 'react'
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
//redux
import { useDispatch } from 'react-redux';
import { getCustomizeReportDataFields, onApplied, onGetLoadData } from 'redux/common/report/customizeReportSlice';
//components
import CustomizeReportFieldOrder from './CustomizeReportFieldOrder';
import InformationBox from 'components/common/Message/InformationBox/InformationBox';
import { showUI } from 'service/GeneralUtility';
import { useAppSelector } from 'redux/hooks';


interface CustomizeReportPropType {
    showModal?: boolean;
    reportType?: any;
    onHideModal?: () => void;
    customizationType?: string;
    setCustomizationType?: any;
}
const CustomizeReport: React.FC<CustomizeReportPropType> = (props) => {
    const {
        showModal = false,
        onHideModal = () => { },
        reportType,
        customizationType = 'user',
        setCustomizationType,
    } = props;

    const dispatch = useDispatch<any>();
    const [datafields, setDatafields] = useState<any>([]);
    const [showFieldOrder, setShowFieldOrder] = useState(false);
    const [reportFieldOrder, setReportFieldOrder] = useState([]);
    const [subGroupFieldOrder, setSubGroupFieldOrder] = useState([]);
    const [fieldOrderUpdated, setFieldOrderUpdated] = useState<boolean>(false);
    const [reportSetting, setReportSetting] = useState({ isLandscapeOriented: false, isSlNoRequired: false, isSystemDefault: false });
    const [displayInfoModal, setDisplayInfoModal] = useState(false);
    const [message, setMessage] = useState("");

    const userPrivileges = useAppSelector((state) => state.user.userPrivileges);

    useEffect(() => {
        dispatch(getCustomizeReportDataFields(reportType, customizationType, setDatafields, setReportSetting))
    }, [reportType])


    const onChangeCheckBox = (e, columnDetails, type) => {
        datafields[columnDetails.rowIndex][columnDetails.field] = e.checked;
        if (e.checked) {
            if (type === "REPORT")
                datafields[columnDetails.rowIndex]["isGroupField"] = false;
            else if (type === "GROUP")
                datafields[columnDetails.rowIndex]["isReportField"] = false;
        } else {
            if (type === "REPORT")
                datafields[columnDetails.rowIndex]["positionNo"] = 0;
            else if (type === "GROUP")
                datafields[columnDetails.rowIndex]["groupIndex"] = 0;
        }
        setDatafields([...datafields])
    }

    const onFieldOrderShow = () => {
        let value: any = JSON.stringify(datafields);
        value = value?.replace(/\"uniqueDataFieldId\":/g, '"id":');
        value = value?.replace(/\"name\":/g, '"name":');
        value = JSON.parse(value);
        const reports = value?.filter((item) => item.isReportField);
        const groups = value?.filter((item) => item.isGroupField);
        const sortedReport = reports?.sort((a, b) => (a.positionNo > b.positionNo) ? 1 : (a.positionNo < b.positionNo) ? -1 : 0);
        const sortedGroups = groups?.sort((a, b) => (a.groupIndex > b.groupIndex) ? 1 : (a.groupIndex < b.groupIndex) ? -1 : 0);
        setReportFieldOrder(sortedReport);
        setSubGroupFieldOrder(sortedGroups);
        setFieldOrderUpdated(true);
        setShowFieldOrder(true);
    }

    const onUpdate = () => {
        const tempDataFields = [...datafields]
        reportFieldOrder.forEach((element: any, i) => {
            const findIndex = tempDataFields?.findIndex((item) => item?.uniqueDataFieldId === element?.id);
            tempDataFields[findIndex].positionNo = i + 1;
            tempDataFields[findIndex].groupIndex = 0;
        });
        subGroupFieldOrder.forEach((element: any, i) => {
            const findIndex = tempDataFields?.findIndex((item) => item?.uniqueDataFieldId === element?.id);
            tempDataFields[findIndex].positionNo = 0;
            tempDataFields[findIndex].groupIndex = i + 1;
        });
        setTimeout(() => {
            setDatafields(tempDataFields);
            setShowFieldOrder(false);
        }, 300);
    }

    const onApply = (isSystemDefault, isTemporary, type) => {
        setCustomizationType(type);
        if(fieldOrderUpdated){
            const remainedData = datafields?.map(({ dataFieldName, dataType, ...rest }: any) => {
                rest.width = rest.width ? (parseFloat(rest.width)).toFixed(2) : '0.00';
                return rest;
            });
            const body = {
                isLandscapeOriented: reportSetting?.isLandscapeOriented,
                isSlNoRequired: reportSetting?.isSlNoRequired,
                isSystemDefault: isSystemDefault,
                dataFieldDetails: remainedData
            }
            dispatch(onApplied(reportType, isTemporary, body));
        } else {
            const remainedData = datafields?.map(({ dataFieldName, dataType, ...rest }: any) => {
                if(rest.isReportField && rest.positionNo === 0) {
                    rest.positionNo = 1;
                } else if(rest.isGroupField && rest.groupIndex === 0) {
                    rest.groupIndex = 1;
                }
                rest.width = rest.width ? (parseFloat(rest.width)).toFixed(2) : '0.00';
                return rest;
            });
            const body = {
                isLandscapeOriented: reportSetting?.isLandscapeOriented,
                isSlNoRequired: reportSetting?.isSlNoRequired,
                isSystemDefault: isSystemDefault,
                dataFieldDetails: remainedData
            }
            dispatch(onApplied(reportType, isTemporary, body));
            setFieldOrderUpdated(false);
        }
    }

    const dataFieldTemplate = (rowData, columnDetails) => {
        return (
            <div>
                {rowData[columnDetails?.field]}
            </div>
        )
    }

    const reportColumnTemplate = (rowData, columnDetails) => {
        return (
            <div className="field-checkbox">
                <Checkbox
                    inputId="binary"
                    checked={rowData[columnDetails?.field]}
                    onChange={(e) => onChangeCheckBox(e, columnDetails, "REPORT")}
                />
            </div>
        )
    }

    const subHeadingTemplate = (rowData, columnDetails) => {
        return (
            <div className="field-checkbox">
                <Checkbox
                    inputId="binary"
                    // checked={rowData[columnDetails?.field]}
                    onChange={(e) => onChangeCheckBox(e, columnDetails, "GROUP")}
                    disabled
                />
            </div>
        )
    }

    const widthTemplate = (rowData, columnDetails) => {
        return (
            <InputText
                disabled={!rowData?.isReportField}
                value={rowData[columnDetails?.field]}
                type="number"
                min={0}
                onKeyDown={(evt) => {
                    if (evt.key === 'e' || evt.key === '-') evt.preventDefault()
                }}
                onPaste={e => e.preventDefault()}
                onChange={(e) => {
                    datafields[columnDetails.rowIndex][columnDetails.field] = e.target.value;
                    setDatafields([...datafields]);
                }}
            />
        )
    }

    const subTotalTemplate = (rowData, columnDetails) => {
        return (
            <div className="field-checkbox">
                {rowData?.dataType?.dataTypeName === "Integer" &&
                    <Checkbox
                        inputId="binary"
                        // checked={rowData[columnDetails?.field]}
                        onChange={(e) => onChangeCheckBox(e, columnDetails, "NONE")}
                        disabled
                    />
                }
            </div>
        )
    }

    const subGrantTotalTemplate = (rowData, columnDetails) => {
        return (
            <div className="field-checkbox">
                {rowData?.dataType?.dataTypeName === "Integer" &&
                    <Checkbox
                        inputId="binary"
                        // checked={rowData[columnDetails?.field]}
                        onChange={(e) => onChangeCheckBox(e, columnDetails, "NONE")}
                        disabled
                    />
                }
            </div>
        )
    }

    const renderFooter = () => {
        return (
            <>
                <Button label="Cancel" onClick={() => setShowFieldOrder(false)} className='cancel btn' />
                <Button label="OK" disabled={datafields?.length ? false : true} onClick={() => onUpdate()} className='update btn' autoFocus />
            </>
        );
    }

    return (
        <Dialog
            visible={showModal}
            onHide={() => onHideModal()}
            showHeader={false}
            className="customize-report"
        >
            <div className="header">
                <div className="box-left">
                    <div className="title">Customize Report</div>
                </div>
                <div className="box-right">
                    <i className="pi pi-times" onClick={onHideModal}></i>
                </div>
            </div>

            <div className='view'>
                <div className='position'>
                    <span>Orientation</span>
                    <div className="field-radiobutton">
                        <RadioButton
                            checked={!reportSetting?.isLandscapeOriented}
                            onChange={(e) => setReportSetting((pre) => ({ ...pre, isLandscapeOriented: false }))}
                        />
                        <label className="mandatory">Portrait</label>
                    </div>
                    <div className="field-radiobutton">
                        <RadioButton
                            checked={reportSetting?.isLandscapeOriented}
                            onChange={(e) => setReportSetting((pre) => ({ ...pre, isLandscapeOriented: true }))}
                        />
                        <label className="mandatory">Landscape</label>
                    </div>
                </div>

                <div className="field-checkbox">
                    <Checkbox
                        inputId="binary"
                        checked={reportSetting?.isSlNoRequired}
                        onChange={(e) => setReportSetting((pre) => ({ ...pre, isSlNoRequired: e.checked }))}
                    />
                    <label className='mandatory' htmlFor="binary"> Show Serial Number</label>
                </div>
            </div>

            <div className='customize-report-grid'>
                <DataTable value={datafields} dataKey="uniqueDataFieldId">
                    <Column field="name" header="Data Fields" body={dataFieldTemplate} />
                    <Column field="isReportField" header="Report Columns" body={reportColumnTemplate} />
                    <Column field="isGroupField" header="Sub Heading" body={subHeadingTemplate} />
                    <Column field="width" header="Width" body={widthTemplate}/>
                    <Column field="isSubTotalRequired" header="Show Sub Total" body={subTotalTemplate} />
                    <Column field="isGrandTotalRequired" header="Show Grand Total" body={subGrantTotalTemplate} />
                </DataTable>
            </div>

            <ul className="list">
                <li role="button" className="item" onClick={() => onApply(false, true, 'custom')}> Apply </li>
                <li role="button" className="item" onClick={() => dispatch(onGetLoadData(reportType, "user", setDatafields, setReportSetting, setMessage, setDisplayInfoModal))}> Load My Default </li>
                <li role="button" className="item" onClick={() => onApply(false, false, 'user')}> Set as My Default </li>
                <li role="button" className="item" onClick={() => dispatch(onGetLoadData(reportType, "system", setDatafields, setReportSetting, setMessage, setDisplayInfoModal))}> Load System Default </li>
                {showUI(userPrivileges, "SIETAP_REPORT_CUSTOMIZE_SET_AS_SYSTEM_DEFAULT") &&
                    <li role="button" className="item" onClick={() => onApply(true, false, 'system')}> Set as System Default</li>
                }
                <li role="button" className="item" onClick={onFieldOrderShow}> Field Order </li>
                <li role="button" className="item" onClick={onHideModal}> Cancel </li>
            </ul>

            <Dialog
                visible={showFieldOrder}
                onHide={() => setShowFieldOrder(false)}
                footer={renderFooter}
                header="Field Order"
                style={{ width: "40vw" }}
                className='s-modal report-field-order'
            >
                <div className='report-field-col'>
                    <h3>Report Fields</h3>
                        <CustomizeReportFieldOrder
                            onDragEnded={setReportFieldOrder}
                            data={reportFieldOrder} 
                        />
                </div>
                <div className='report-field-col'>
                    <h3>Sub Header Fields</h3>
                        <CustomizeReportFieldOrder
                            onDragEnded={setSubGroupFieldOrder}
                            data={subGroupFieldOrder} 
                        />
                </div>
            </Dialog>
            {displayInfoModal && <InformationBox
                showInfoModal={displayInfoModal}
                setShowInfoModal={setDisplayInfoModal}
                message={message}
            />}
        </Dialog>
    )
}

export default memo(CustomizeReport);
