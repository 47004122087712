import { Button } from "primereact/button";
import React from "react";
const AdministrationSearchContractDocument = () => {
    return (
    <>
         <h4 className="doc-title mb-2">Search - Contract</h4>
    
        <p>
            Contract Search option allows the user to search for the required Contract. 
            User can type minimum three characters of the Contract Name / Contract 
            Number in the text box provided and select the required Contract from the list displayed. Click on 
            <span className="doc-subtitle"> Search </span> {" "}
            <Button className="doc-button-icon" >
            <i className="pi pi-search icon" title="Search" />
            </Button>{" "} icon. The page will be redirected to 
            <span className="doc-subtitle"> Inspection: Contracts </span> 
            page where the selected Contract will be listed.
        </p>
    </>
    );
}

export default AdministrationSearchContractDocument;