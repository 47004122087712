import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { zlink, baseURL } from "../../../service/HttpService"
import { AppThunk } from "../../store";
import { onGetUserPrivileges } from "./User/userSlice";
const appBaseURL = (window as any).envSettings.appBaseUrl;

export interface AdministrationState {
	errors: any;
	customerList: any;
	statusList: any;
	countryList: any;
	stateList: any;
	TimeZoneList:any;
	customerDetails: any;
	customerPageDetails: any;
	mapSiteList: any;
	mapSiteSearchList: any;
	activeSiteDetails: any;
	switchedSite: any;
	customerSearchId: any;
	loggedInUserDetails: any;
	newCustomerData: any;
	loggedInUserDetailsStatus: any
}

let initialState: AdministrationState = {
	errors: "",
	customerList: [],
	statusList: "",
	countryList: "",
	stateList: "",
	TimeZoneList:"",
	customerDetails: "",
	customerPageDetails: "",
	mapSiteList: [],
	mapSiteSearchList: [],
	activeSiteDetails: [],
	switchedSite: [],
	customerSearchId:[],
	loggedInUserDetails: "",
	newCustomerData: "",
	loggedInUserDetailsStatus: 'pending'
};

const AdministrationSlice = createSlice({
	name: "administration",
	initialState,
	reducers: {
		setErrors: (state, { payload }: PayloadAction<any>) => {
			state.errors = payload;
		},
		setCustomerList: (state, { payload }: PayloadAction<any>) => {
			payload.map((element: any) => {
				element.accountExpiryDate = moment(element.accountExpiryDate).format("yyyy-MM-DD").toString()
				return null;
			});
			state.customerList = payload;
		},
		setDeletedCustomerList(state, { payload }: PayloadAction<any>) {
			payload.accountExpiryDate = moment(payload.accountExpiryDate).format("yyyy-MM-DD").toString()
			let currentIndex = state.customerList.findIndex((item: any) => item.uniqueCustomerId === payload?.uniqueCustomerId);
			state.customerList = state.customerList.filter((item: any) => item.uniqueCustomerId !== payload?.uniqueCustomerId);
			state.customerList.splice(currentIndex, 0, payload);
		},
		setNewCustomerData: (state, { payload }: PayloadAction<any>) => {
			payload.accountExpiryDate = moment(payload.accountExpiryDate).format("yyyy-MM-DD").toString()
			state.customerList.unshift(payload);
			state.newCustomerData = payload;
		},
		setStatusList: (state, { payload }: PayloadAction<any>) => {
			state.statusList = payload;
		},
		setCountryList: (state, { payload }: PayloadAction<any>) => {
			state.countryList = payload;
		},
		setStateList: (state, { payload }: PayloadAction<any>) => {
			state.stateList = payload;
		},

		setTimeZoneList: (state, { payload }: PayloadAction<any>) => {
			state.TimeZoneList = payload;
		},
		
		setCustomerDetails: (state, { payload }: PayloadAction<any>) => {
			state.customerDetails = payload;
		},
		setUpdatedCustomerData: (state, { payload }: PayloadAction<any>) => {
			payload.accountExpiryDate = moment(payload.accountExpiryDate).format("yyyy-MM-DD").toString()
			let currentIndex = state.customerList.findIndex((item: any) => item.uniqueCustomerId === payload?.uniqueCustomerId);
			state.customerList = state.customerList.filter((item: any) => item.uniqueCustomerId !== payload?.uniqueCustomerId);
			state.customerList.splice(currentIndex, 0, payload);
		},
		setCustomerPageDetails: (state, {payload}: PayloadAction<any>) => {
			state.customerPageDetails = payload;
		},
		setMapSiteList(state, { payload }: PayloadAction<any>) {
			state.mapSiteList = payload;
		},
		setMapSiteSearchList(state, { payload }: PayloadAction<any>) {
			state.mapSiteSearchList = payload;
		},
		setActiveSiteDetails: (state, { payload }: PayloadAction<any>) => {
			state.activeSiteDetails = payload;
		},
		setActiveSite: (state, { payload }: PayloadAction<any>) => {
			state.switchedSite = payload;
		},
		setCustomerSearchId: (state, {payload}: PayloadAction<any>) => {
			state.customerSearchId = payload;
		},
		setLoggedInUserDetails: (state, {payload}: PayloadAction<any>) => {
			state.loggedInUserDetails = payload;
		},
		setLoggedInUserDetailsStatus: (state, {payload}: PayloadAction<any>) => {
			state.loggedInUserDetailsStatus = payload;
		},
		setOffset: (state, {payload}: PayloadAction<any>) => {
			state.loggedInUserDetails.userDefaults.siteOffset = payload 
		}
	},
});

export const getCustomerList = (setShowCustomerGrid, currentStart, keyword: any = "", sortField: any = "", sortOrder: any = ""): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.get(`${baseURL}api/v1/admin/customer/list?keyword=${keyword}&currentstart=${currentStart}&sortcolumn=${sortField}&sortorder=${sortOrder}`);		
		if (res.status === "done") {
			if (res.data) {
				dispatch(setCustomerList(res?.data?.customerDetails));
				dispatch(setCustomerPageDetails(res?.data?.pageDetails))
				setShowCustomerGrid(true);
			}
		} else {
			dispatch(setErrors(res.error));
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};
export const getCustomerSearchList = (setShowCustomerGrid, currentStart,uniqueCustomerId): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.get(`${baseURL}api/v1/common/search/customer/${uniqueCustomerId}/customerlist?currentStart=${currentStart}`);		
		if (res.status === "done") {
			dispatch(setCustomerList(res?.data?.customerDetails));
			dispatch(setCustomerPageDetails(res?.data?.pageDetails))
			setShowCustomerGrid(true);
		} else {
			dispatch(setErrors(res.error));
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};
export const deleteCustomerData = ({ uniqueCustomerId, setMessage, setDisplayInfoModal }): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.post(`${baseURL}api/v1/admin/customer/${uniqueCustomerId}/remove`, null);
		if (res.status === "done") {
			dispatch(setDeletedCustomerList(res.data));
			setMessage("Customer deleted");
			setDisplayInfoModal(true);
		} else {
			setMessage(res.message);
			setDisplayInfoModal(true);
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const getStatusList = (): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.get(`${baseURL}api/v1/common/status/getallstatus`);
		if (res.status === "done") {
			dispatch(setStatusList(res.data));
		} else {
			dispatch(setErrors(res.error));
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const getCountryList = (): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.get(`${baseURL}api/v1/common/countries/list`);
		if (res.status === "done") {
			dispatch(setCountryList(res.data));
		} else {
			dispatch(setErrors(res.error));
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const getStateList = (): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.get(`${baseURL}api/v1/common/countries/states/stateofcountries`);
		if (res.status === "done") {
			dispatch(setStateList(res.data));
		} else {
			dispatch(setErrors(res.error));
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const getTimeZoneList = (): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.get(`${baseURL}api/v1/common/timezones`);
		if (res.status === "done") {
			dispatch(setTimeZoneList(res.data));
		} else {
			dispatch(setErrors(res.error));
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const onAddCustomer =
	(body: any, setMessage, setDisplayInfoModal, setShowAddModal, setShowCustomerGrid?,isInspectionPage?): AppThunk =>
		async (dispatch) => {
			try {
				const res = await zlink.post(`${baseURL}api/v1/admin/customer/add`, body);
				if (res.status === "done") {
					dispatch(setNewCustomerData(res.data));
					setShowCustomerGrid(true);
					if(isInspectionPage) {
					const mappedData = await zlink.get(`${baseURL}api/v1/inspection/sites/customers?keyword=''`);
					if (mappedData.status === "done") {
						let mappedIds:any = []
						mappedData.data.customerDetails.map((item:any) => {
                        mappedIds.push(item.uniqueCustomerId)
						});
						mappedIds.push(res.data.uniqueCustomerId)
						let body ={
							customerIds:mappedIds,
						}
					   const mappingResponse = await zlink.post(`${baseURL}api/v1/inspection/sites/customers`, body);
					 if(mappingResponse.status === "done") {
				      setMessage("Customer added");
					  setShowAddModal(false);
					  setDisplayInfoModal(true);
					  } else {
						dispatch(setErrors(mappingResponse.error));
					    setMessage(mappingResponse.message);
					    setDisplayInfoModal(true);
					  }
					}  else {
						dispatch(setErrors(mappedData.error));
					    setMessage(mappedData.message);
					    setDisplayInfoModal(true);
					}
					} else {
						setMessage("Customer added");
						setShowAddModal(false);
						setDisplayInfoModal(true);
					}
				} else {
					dispatch(setErrors(res.error));
					setShowCustomerGrid(true);
					setMessage(res.message);
					setDisplayInfoModal(true);
				}
			} catch (error) {
				setShowCustomerGrid(true);
				dispatch(setErrors(error));
			}
		};

export const getCustomerDetails = (uniqueCustomerId): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.get(`${baseURL}api/v1/admin/customer/${uniqueCustomerId}`);
		if (res.status === "done") {
			dispatch(setCustomerDetails(res.data));
		} else {
			dispatch(setErrors(res.error));
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const onUpdateCustomer =
	(uniqueCustomerId, body: any, setMessage, setDisplayInfoModal, setShowEditModal): AppThunk =>
		async (dispatch) => {
			try {
				const res = await zlink.post(`${baseURL}api/v1/admin/customer/${uniqueCustomerId}/update`, body);
				if (res.status === "done") {
					dispatch(setUpdatedCustomerData(res.data));
					setMessage("Customer updated");
					setShowEditModal(false);
					setDisplayInfoModal(true);
				} else {
					dispatch(setErrors(res.error));
					setMessage(res.message);
					setDisplayInfoModal(true);
				}
			} catch (error) {
				dispatch(setErrors(error));
			}
		};
export const getMapSiteList = (uniqueCustomerId: any): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.get(`${baseURL}api/v1/admin/customer/${uniqueCustomerId}/mapsite/sitelist`)
		if (res.status === "done") {
			dispatch(setMapSiteList(res.data.siteDetails))
		}
	}
	catch (error) {
		dispatch(setErrors(error));
	}
};

export const getMapSiteSearchList = (uniqueCustomerId: any, searchKeyWord: any): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.get(`${baseURL}api/v1/admin/customer/${uniqueCustomerId}/mapsite/search/${searchKeyWord}/sitelist`)
		if (res.status === "done") {
			dispatch(setMapSiteSearchList(res.data.siteDetails))
		}
	}
	catch (error) {
		dispatch(setErrors(error));
	}
};

export const updateCustomerSiteMapping = (uniqueCustomerId, body: any, setDisplayInfoModal, setMessage): AppThunk =>
	async (dispatch) => {
		try {
			const res = await zlink.post(
				`${baseURL}api/v1/admin/customer/${uniqueCustomerId}/mapsite/updatesite`, body
			);
			if (res.status === "done") {
				setMessage(res.message);
				setDisplayInfoModal(true);
			} else {
				setMessage(res.message);
				setDisplayInfoModal(true);
			}
		} catch (error) {
			dispatch(setErrors(error));
		}
	};

export const fetchActiveSiteDetails = (): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.get(`${baseURL}api/v1/common/site/defaultsitedetail`)
		if (res.status === "done") {
			dispatch(setActiveSiteDetails(res.data));
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const setAsActiveSite = (uniqueSiteId: any, setDisplayInfoModal: any, setMessage: any): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.post(`${baseURL}api/v1/admin/site/${uniqueSiteId}/setasactivesite`, null);
		if (res.status === "done") {
			dispatch(setActiveSite(res.data));
		} else {
			setMessage(res.message);
			setDisplayInfoModal(true);
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const fetchLoggedInUserDetails = (): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.get(`${baseURL}api/v1/users/me`);
		if (res.status === "done") {
			if (res.data && res.data.status) {
				if (res.data.userDefaults && res.data.userDefaults.siteName) {
					dispatch(setLoggedInUserDetailsStatus('success'));
				} else {
					dispatch(setLoggedInUserDetailsStatus('error'));
				}
				dispatch(setLoggedInUserDetails(res.data));
				dispatch(onGetUserPrivileges());
			}
		} else {
			if (res.messageCode === 401) {
				dispatch(setLoggedInUserDetailsStatus('pending'));
				document.location.href=`${appBaseURL}/api/v1/auth/signout`;
				setTimeout(() => {
					(window as any).location.reload();					
				}, 500);
			} else {
				dispatch(setLoggedInUserDetailsStatus('error'));
			}
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const { setErrors,
	setCustomerList,
	setDeletedCustomerList,
	setNewCustomerData,
	setStatusList,
	setCountryList,
	setStateList,
	setTimeZoneList,
	setCustomerDetails,
	setUpdatedCustomerData,
	setCustomerPageDetails, 
	setMapSiteList,
	setMapSiteSearchList,
	setActiveSiteDetails,
	setActiveSite,
	setCustomerSearchId,
	setLoggedInUserDetails,
	setLoggedInUserDetailsStatus,
	setOffset
} = AdministrationSlice.actions;
export default AdministrationSlice.reducer;
