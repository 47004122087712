
import React, { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form';
//components
import FormElement from '../Utility/FormElement';
//redux
import { useAppSelector } from 'redux/hooks';
import { useDispatch } from 'react-redux';
import { getAdditionalDatafieldSets, getDatafieldValues, setAdditionalDatafieldSets, setAdditionalDatafieldValues } from 'redux/common/commonSlice';
import { setIsDevReadingChanged } from 'redux/pages/Inspection/InspectionTask/InspectionDetails/Equipment/EquipmentSlice';
import moment from 'moment';

interface GeneralFormPropType {
    datafields?: any;
    ddAppendTo?: 'self' | undefined;
    isEditForm?: boolean;
    onFormSubmit?: (body, reset) => void;
    body?: any;
    fieldFocus?: any;
    setFieldFocus?:any; 
}
const GeneralForm = forwardRef<any, GeneralFormPropType>((props, ref) => {
    const { datafields, isEditForm, fieldFocus=false, setFieldFocus, onFormSubmit = () => { }, body, ddAppendTo = undefined } = props;
    const { control, formState: { errors }, handleSubmit, watch, setValue, unregister, reset } = useForm({mode:'all'});
    const addForm = useRef<any>(null);
    const [datafieldlist, setDatafieldList] = useState<any>([]);
    const [focusElement, setFocusElement] = useState<any>(null);

    const dispatch = useDispatch<any>();
    const additionalFieldValues = useAppSelector((state) => state.common.additionalDatafieldValues);
    const additionalDatafieldSets = useAppSelector((state) => state.common.additionalDatafieldSets);
    const loggedInUserDetails = useAppSelector((state) => state.administration.loggedInUserDetails);
    useImperativeHandle(ref, () => ({
        requestSubmit: () => {
            addForm?.current?.requestSubmit();
        },
    }));

    useEffect(() => {
        let fieldsConv = JSON.parse(JSON.stringify(datafields))
        if (fieldsConv?.length) {
            fieldsConv.forEach(element => {
                const devReadingDateIndex = fieldsConv?.findIndex((item) => item.dataFieldName === "Dev Reading Date");
                if (devReadingDateIndex > 0) {
                    if (element?.dataFieldName === "Dev Reading") {
                        if (element?.fieldValue === '') {
                            fieldsConv[devReadingDateIndex].disabled = true;
                        }
                        else {
                            fieldsConv[devReadingDateIndex].disabled = false;
                        }
                    }
                }
                if (element?.dataEntryControl?.dataEntryControlName === "Drop-down list") {
                    if (element?.defaultSelectNeeded)
                        element.validatedValues.unshift({ dataFieldLookupId: -1, dataFieldLookupValue: "--Select--", isSelected: false });
                    const slectedItem = element.validatedValues?.filter((item) => item?.isSelected);
                    if (slectedItem?.length) {
                        setValue(element.uniqueDataFieldId, slectedItem[0].dataFieldLookupId);
                    } else {
                        setValue(element.uniqueDataFieldId, -1)
                    }
                } else if (element?.dataEntryControl?.dataEntryControlName === "Check Box(s)") {
                    if (element?.fieldValue) {
                        setValue(element.uniqueDataFieldId, element.fieldValue)
                    } else {
                        let list: any = [];
                        for (let j = 0; j < element?.validatedValues?.length; j++) {
                            let sm = element.validatedValues[j].dataFieldLookupId;
                            let item = {
                                [sm]: false,
                            };
                            list.push(item);
                        }
                        setValue(element?.uniqueDataFieldId, list);
                    }
                } else
                    setValue(element.uniqueDataFieldId, element.fieldValue);
            });
            const ascDatafields = [...fieldsConv].sort((a, b) => a.positionNo - b.positionNo);
            setDatafieldList([...ascDatafields]);
        }
    }, [datafields]);

    useEffect(() => {
        if (additionalFieldValues) {
            let lists = JSON.parse(JSON.stringify(datafieldlist));
            let fieldValues = JSON.parse(JSON.stringify(additionalFieldValues));
            const findIndex = lists?.findIndex((item) => item.uniqueDataFieldId === fieldValues[1]);
            if (findIndex >= 0) {
                if (lists[findIndex]?.defaultSelectNeeded) {
                    fieldValues[0].unshift({ dataFieldLookupId: -1, dataFieldLookupValue: "--Select--", isSelected: false });
                }
                lists[findIndex].validatedValues = fieldValues[0];
            }
            const filterData: any = fieldValues?.[0]?.filter(
                (item: any) =>
                    item.isSelected === true
            );
            if (findIndex >= 0 && filterData?.length > 0) {
                lists[findIndex].fieldValue = filterData?.[0]?.dataFieldLookupId?.toString();
                setValue(lists[findIndex]?.uniqueDataFieldId, parseInt(filterData?.[0]?.dataFieldLookupId))
            } else {
                setValue(lists[findIndex]?.uniqueDataFieldId, -1)
            }

            setDatafieldList(lists);
            dispatch(setAdditionalDatafieldValues(""));
        }
    }, [additionalFieldValues]);

    useEffect(() => {
        if (additionalDatafieldSets?.length) {
            let lists = JSON.parse(JSON.stringify(datafieldlist))
            let count = 0;
            for (let i = 0; i < datafieldlist?.length; i++) {
                if (datafieldlist[i]?.parentUniqueDataFieldId) {
                    unregister(datafieldlist[i]?.uniqueDataFieldId);
                    lists.splice(i - count, 1);
                    count++;
                }
            }
            let fieldCount = 0;
            let filteredArray = JSON.parse(JSON.stringify(additionalDatafieldSets));
            for (let i = 0; i < lists?.length; i++) {
                for (let j = 0; j < filteredArray?.length; j++) {
                    if (lists[i].uniqueDataFieldId === filteredArray[j]?.uniqueDataFieldId && filteredArray[j]?.hasToBeDeleted === 0) {
                        filteredArray.splice(j, 1);
                        fieldCount++;
                    }
                }
            }

            // const dt = additionalDatafieldSets.filter(({ uniqueDataFieldId: id1, }) => !lists.some(({ uniqueDataFieldId: id2 }) => id2 === id1));

            for (let i = 0; i < filteredArray?.length; i++) {
                if (filteredArray[i]?.hasToBeDeleted === 0)
                    lists.push(filteredArray[i]);
                else {
                    const removeIndex = lists.findIndex((item) => item?.uniqueDataFieldId === filteredArray[i]?.uniqueDataFieldId);
                    if (removeIndex >= 0) {
                        unregister(lists[removeIndex]?.uniqueDataFieldId);
                        lists.splice(removeIndex, 1);
                    }
                }
            }
            const ascDatafields = [...lists].sort((a, b) => a.positionNo - b.positionNo);
            setDatafieldList([...ascDatafields]);
            dispatch(setAdditionalDatafieldSets(""));
        }

    }, [additionalDatafieldSets])

    useLayoutEffect(() => {
        if (datafieldlist?.length) {
            if (focusElement) {
                const element = document.getElementsByName(focusElement);
                element?.[0]?.focus();
                setFocusElement(null);
            }
        }
    }, [datafieldlist])

    const onSubmit = (data) => {
        let fields: any = [];
        for (const k in data) {
            if (data[k] != undefined) {
                let multipleValue: any = [];
                let item: any = {
                    dataFieldId: k,
                    value: null,
                    multipleValue: null,
                }
                if (Array.isArray(data[k])) {
                    for (let i = 0; i < data[k]?.length; i++) {
                        for (const obj in data[k][i]) {
                            if (data[k][i][obj]) {
                                multipleValue.push(obj);
                            }
                        }
                    }
                    item.multipleValue = multipleValue;
                } else {
                    const field = datafieldlist?.filter((item) => item?.uniqueDataFieldId === k);
                    if (field?.[0]?.dataEntryControl?.dataEntryControlName === "Drop-down list") {
                        if (data[k] === -1)
                            data[k] = "";
                    }
                    item.value = data[k]?.toString();
                }
                fields.push(item);
            }
        }
        onFormSubmit(fields, reset);
        dispatch(setIsDevReadingChanged(''));
    }
    const onDateTimeChanged = useCallback((data,e) => {
        onCheckDateTimeChanged(data,e);
    }, [datafieldlist]);

    const onCheckDateTimeChanged = useCallback((data,e) => {
        if (data.dataFieldName === "Dev Reading Date") {
            dispatch(setIsDevReadingChanged('D'));
        } 
    }, []);

    const onSelectionChange = useCallback((data, id) => {
        if (data?.dataFieldRelation !== undefined) {
            onFieldRelationChange(data, id);            
        }
        if (data?.dataFieldSet !== undefined) {
            onDataFieldSetChange(data, id);            
        }
    }, []);

    const onFieldRelationChange = (data, id) => {
        let service: any = data?.dataFieldRelation?.subSetValueService;
        if (service !== undefined) {
            service = service.replace("{dataFieldLookupId}", id);
            dispatch(getDatafieldValues(service, data?.dataFieldRelation?.childDataFieldId, body));
            setFocusElement(data?.uniqueDataFieldId);
        }
    }

    const onDataFieldSetChange = (data, id) => {
        let service: any = data?.dataFieldSet?.subSetValueService;
        if (service !== undefined) {
            service = service.replace("{dataFieldLookupId}", id);
            dispatch(getAdditionalDatafieldSets(service, body));
            setFocusElement(data?.uniqueDataFieldId);
        }
    }
    
    const onTextBoxChange = useCallback((data,e) => {
        onCheckTextBoxChange(data,e);
    }, [datafieldlist]);

    const onCheckTextBoxChange = (data,e) => {
        if (data.dataFieldName === "Dev Reading") {
            const devReadingDate = datafieldlist?.filter((item) => item.dataFieldName === "Dev Reading Date");
            const devReadingDateindex = datafieldlist?.findIndex((item) => item.dataFieldName === "Dev Reading Date");
            if(devReadingDateindex > 0){
                if(e.currentTarget.value?.length>0){
                    datafieldlist[devReadingDateindex].disabled = false;
                    setValue(devReadingDate[0]?.uniqueDataFieldId, moment(new Date()).utcOffset(loggedInUserDetails?.userDefaults?.siteOffset).format("YYYY-MM-DD HH:mm:ss"));
                }else{
                    datafieldlist[devReadingDateindex].disabled = true;
                    setValue(devReadingDate[0]?.uniqueDataFieldId,''); 
                }
            }
           
            
        } 
    }

    return (

        <form className="form-data-wrapper" onSubmit={handleSubmit(onSubmit)} ref={addForm}>
            {datafieldlist?.length > 0 &&
                datafieldlist?.map((item: any, key: any) => (
                    <FormElement
                        key={key}
                        data={item}
                        control={control}
                        ddAppendTo={ddAppendTo}
                        setValue={setValue}
                        watch={watch}
                        onSelectionChange={onSelectionChange}
                        onDateTimeChanged={onDateTimeChanged}
                        onTextBoxChange={onTextBoxChange}
                        errors={errors}
                        fieldFocus={fieldFocus}
                        setFieldFocus={setFieldFocus} />
                ))
            }
        </form>

    )
})

export default memo(GeneralForm)