import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { zlink, baseURL } from "../../../../../service/HttpService";
import { AppThunk } from "../../../../store";

export interface NFPAClassificationState {
  errors: any;
  NFPAList: any;
  NFPAPageDetails: any;
  NFPAClassificationDetails: any;
  ReportClassifications: any;
  isNFPAInUse: any;
  equipmentTypeList: any;
  inUseReturn: any;
}

let initialState: NFPAClassificationState = {
  errors: "",
  NFPAList: [],
  NFPAPageDetails: [],
  NFPAClassificationDetails: [],
  ReportClassifications: [],
  isNFPAInUse: "",
  equipmentTypeList: [],
  inUseReturn:""
};

const NFPAClassificationSlice = createSlice({
  name: "nfpaclassification",
  initialState,
  reducers: {
    setErrors: (state, { payload }: PayloadAction<any>) => {
      state.errors = payload;
    },
    setNFPAList: (state, { payload }: PayloadAction<any>) => {
      state.NFPAList = payload;
    },
    setNewNFPAClassificationData: (state, { payload }: PayloadAction<any>) => {
      let newNFPAitem ={
        uniquenfpaClassificationId: payload.uniqueNFPAClassificationId,
        nfpaCode: payload.nfpaCode,
        nfpaClassification: payload.nfpaClassification,
        equipmentType: payload.equipmentType,
        healthClassification: payload.healthClassification.healthcareClassificationName,
        reportClassification: payload.reportClassification
      }
      state.NFPAList.unshift(newNFPAitem);
    },
    setReportClassificationList: (state, { payload }: PayloadAction<any>) => {
      state.ReportClassifications = payload;
    },
    setUpdatedNFPAClassification: (state, { payload }: PayloadAction<any>) => {
      let updatedNFPAitem ={
        uniquenfpaClassificationId: payload.uniqueNFPAClassificationId,
        nfpaCode: payload.nfpaCode,
        nfpaClassification: payload.nfpaClassification,
        equipmentType: payload.equipmentType,
        healthClassification: payload.healthClassification.healthcareClassificationName,
        reportClassification: payload.reportClassification
      }
      let currentIndex = state.NFPAList.findIndex(
        (item: any) =>
          item.uniquenfpaClassificationId ===
          updatedNFPAitem?.uniquenfpaClassificationId
      );
      state.NFPAList = state.NFPAList.filter(
        (item: any) =>
          item.uniquenfpaClassificationId !==
          updatedNFPAitem?.uniquenfpaClassificationId
      );
      state.NFPAList.splice(currentIndex, 0, updatedNFPAitem);
    },
    setNFPAPageDetails: (state, { payload }: PayloadAction<any>) => {
      state.NFPAPageDetails = payload;
    },
    setDeletedNFPAClassificationList(state, { payload }: PayloadAction<any>) {
      state.NFPAList = state.NFPAList.filter(
        (item: any) => item.uniquenfpaClassificationId !== payload
      );
    },
    setNFPAClassificationDetails: (state, { payload }: PayloadAction<any>) => {
      state.NFPAClassificationDetails = payload;
    },
    setIsNFPAInUse: (state, { payload }: PayloadAction<any>) => {
      state.isNFPAInUse = payload;
    },
    setEquipmentTypeList: (state, { payload }: PayloadAction<any>) => {
      state.equipmentTypeList = payload;
    },
    setInUseReturnForEdit: (state, { payload }: PayloadAction<any>) => {
      state.inUseReturn = payload;
    },
  },
});

export const getNFPAList =
  (setShowGrid, currentStart, body, keyword: any = "", sortField: any = "", sortOrder: any = ""): AppThunk =>
  async (dispatch) => {
    try {
      const res = await zlink.post(
        `${baseURL}api/v1/nfpa/nfpaclassificationlist?keyword=${keyword}&currentstart=${currentStart}&sortcolumn=${sortField}&sortorder=${sortOrder}`,
        body
      );
      if (res.status === "done") {
        dispatch(setNFPAList(res?.data?.nfpaClassificationDetails));
        dispatch(setNFPAPageDetails(res?.data?.pageDetails));
        setShowGrid(true);
      } else {
        dispatch(setErrors(res.error));
      }
    } catch (error) {
      dispatch(setErrors(error));
    }
  };
export const getReportClassificationList = (): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.get(`${baseURL}api/v1/reportclassifications`);
    if (res.status === "done") {
      dispatch(setReportClassificationList(res?.data?.reportClassifications));
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const onAddNFPAClassification =
  (
    body: any,
    uniqueCountryId,
    setMessage,
    setDisplayInfoModal,
    setShowAddModal
  ): AppThunk =>
  async (dispatch) => {
    try {
      const res = await zlink.post(
        `${baseURL}api/v1/country/${uniqueCountryId}/nfpaclassifications/add`,
        body
      );
      if (res.status === "done") {
        dispatch(setNewNFPAClassificationData(res.data));
        setMessage("NFPA Classification added");
        setShowAddModal(false);
        setDisplayInfoModal(true);
      } else {
        dispatch(setErrors(res.error));
        setMessage(res.message);
        setDisplayInfoModal(true);
      }
    } catch (error) {
      dispatch(setErrors(error));
    }
  };
export const onEditNFPAClassification =
  (
    body: any,
    uniqueCountryId,
    uniquenfpaClassificationId,
    setMessage,
    setDisplayInfoModal,
    setShowEditModal
  ): AppThunk =>
  async (dispatch) => {
    try {
      const res = await zlink.post(
        `${baseURL}api/v1/country/${uniqueCountryId}/nfpaclassifications/${uniquenfpaClassificationId}`,
        body
      );
      if (res.status === "done") {
        dispatch(setUpdatedNFPAClassification(res.data));
        setMessage("NFPA Classification updated");
        setShowEditModal(false);
        setDisplayInfoModal(true);
      } else {
        dispatch(setErrors(res.error));
        setMessage(res.message);
        setDisplayInfoModal(true);
      }
    } catch (error) {
      dispatch(setErrors(error));
    }
  };
export const getNFPAClassificationDetails =
  (uniqueCountryId, uniquenfpaClassificationId): AppThunk =>
  async (dispatch) => {
    try {
      const res = await zlink.get(
        `${baseURL}api/v1/country/${uniqueCountryId}/nfpaclassifications/${uniquenfpaClassificationId}`
      );
      if (res.status === "done") {
        dispatch(setNFPAClassificationDetails(res.data));
      } else {
        dispatch(setErrors(res.error));
      }
    } catch (error) {
      dispatch(setErrors(error));
    }
  };
export const deleteNFPAClassification =
  ({
    uniquenfpaClassificationId,
    setSelectedRows,
    setMessage,
    setDisplayInfoModal,
  }): AppThunk =>
  async (dispatch) => {
    try {
      const res = await zlink.post(
        `${baseURL}api/v1/country/nfpaclassifications/${uniquenfpaClassificationId}/remove`,
        null
      );
      if (res.status === "done") {
        dispatch(setDeletedNFPAClassificationList(uniquenfpaClassificationId));
        setSelectedRows([]);
        setMessage("NFPA Classification deleted");
        setDisplayInfoModal(true);
      } else {
        setSelectedRows([]);
        setMessage(res.message);
        setDisplayInfoModal(true);
      }
    } catch (error) {
      dispatch(setErrors(error));
    }
  };

  export const checkNFPAInUse = (uniquenfpaClassificationId): AppThunk => async (dispatch) => {
    try {
      const res = await zlink.get(`${baseURL}api/v1/country/nfpaclassifications/${uniquenfpaClassificationId}/checkinuse`);
      if (res.status === "done") {
        dispatch(setIsNFPAInUse(res?.data));
      } else {
        dispatch(setErrors(res.error));
      }
    } catch (error) {
      dispatch(setErrors(error));
    }
  };

  export const getEquipmentTypeListBsedOnHealth = (healthClassification:any = ""): AppThunk => async (dispatch) => {
    try {
      dispatch(setEquipmentTypeList([]));
      const res = await zlink.get(
        `${baseURL}api/v1/nfpa/equipmenttypeList?uniqueHealthClassificationId=${healthClassification}`
      );
      if (res.status === "done") {
        dispatch(setEquipmentTypeList(res?.data?.equipmentClassDetails));
      } else {
        dispatch(setErrors(res.error));
      }
    } catch (error) {
      dispatch(setErrors(error));
    }
  };

  export const checkNFPAInUseForEdit = (uniqueHealthClassificationId,body): AppThunk => async (dispatch) => {
    try {
      const res = await zlink.post(`${baseURL}api/v1/country/nfpaclassification/${uniqueHealthClassificationId}/checkinusebeforeedit`,body);
      if (res.status === "done") {
        dispatch(setInUseReturnForEdit(res?.data));
      } else {
        dispatch(setErrors(res.error));
      }
    } catch (error) {
      dispatch(setErrors(error));
    }
  };

export const {
  setErrors,
  setNFPAList,
  setNewNFPAClassificationData,
  setUpdatedNFPAClassification,
  setNFPAClassificationDetails,
  setReportClassificationList,
  setDeletedNFPAClassificationList,
  setNFPAPageDetails,
  setIsNFPAInUse,
  setEquipmentTypeList,
  setInUseReturnForEdit
} = NFPAClassificationSlice.actions;
export default NFPAClassificationSlice.reducer;
