import React, { memo, useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { classNames } from "primereact/utils";
import { Dialog } from "primereact/dialog";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ScrollPanel } from "primereact/scrollpanel";
import { Calendar } from "primereact/calendar";
import moment from "moment";
//icons
import { BsPlusLg, BsDashLg, BsCheck2 } from "react-icons/bs";
//components
import InformationBox from "components/common/Message/InformationBox/InformationBox";
//utility
import { createGuid } from "service/GeneralUtility";
//redux
import { useDispatch } from "react-redux";
import { setEquipmentDatafields } from "redux/pages/Inspection/InspectionTask/InspectionDetails/Equipment/EquipmentSlice";


interface AdvancedSearchPropType {
  equipmentColumns?: any;
  showModal: boolean;
  selectedTask?: any;
  uniqueEquipmentClassId?: string;
  uniquePanelId?: string;
  archievedSearches?: any;
  filterType?: string;
  equipmentList?: any;
  setShowModal: (state: boolean) => void;
  onSearched: (body: any) => void;
}

type FormData = {
  condition: string;
  uniqueDataFieldId: string;
  operator: string;
  fieldValue: any;
};

export const AdvancedSearch: React.FC<AdvancedSearchPropType> = (props) => {
  const { showModal = false,
    setShowModal = () => { },
    equipmentColumns,
    selectedTask,
    uniqueEquipmentClassId,
    uniquePanelId,
    onSearched = () => { },
    filterType,
    equipmentList,
    archievedSearches } = props;
  const [checkedItem, setCheckedItem] = useState({ "showUntestedEquipments": false, "showWithAttachmentsOnly": false, "isFromXMLImported": true, "isFromManuallyAdded": true });
  const [searchCriteria, setSearchCriteria] = useState<any>([]);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [displayInfoModal, setDisplayInfoModal] = useState(false);
  const [message, setMessage] = useState<string>("");
  const [isEditMode, setIsEditMode] = useState(false);
  const [datafields, setDatafields] = useState<any>([]);
  const [operatorss, setOperators] = useState<any>([]);
  const [dataType, setDataType] = useState<any>(null);

  const dispatch = useDispatch<any>();

  const addUserForm: any = useRef();
  const { control, handleSubmit, formState: { errors }, watch, reset, setValue } = useForm<FormData>({
    defaultValues: {
      uniqueDataFieldId: "",
      operator: "",
      condition: "",
      fieldValue: "",
    },
  });

  const operatorsList = [
    { name: 'EQUAL TO', code: 'EQUAL TO' },
    { name: `NOT EQUAL TO`, code: 'NOT EQUAL TO' },
    { name: `CONTAINS`, code: 'CONTAINS' },
    { name: `IS BLANK`, code: 'IS BLANK' },
    { name: `IS NOT BLANK`, code: 'IS NOT BLANK' },
    { name: `GREATER THAN`, code: 'GREATER THAN' },
    { name: `LESS THAN`, code: 'LESS THAN' },
  ];

  useEffect(() => {
    if (equipmentColumns) {
      const removedDataSets = equipmentColumns?.map(({ disabled, ...rest }: any) => {
        return rest;
      });
      setDatafields(removedDataSets);
      dispatch(setEquipmentDatafields(""))

    }
  }, [equipmentColumns])

  useEffect(() => {
    if (filterType === "ADVANCED_FILTER" && equipmentList?.data?.length === 0) {
      setDisplayInfoModal(true);
      setMessage("No data exists for the search criteria");
    }
  }, [equipmentList, filterType])

  useEffect(() => {
    let convItems = JSON.parse(JSON.stringify(archievedSearches))
    if (convItems?.length) {
      convItems.forEach((element, index) => {
        element.isValuabe = true;
        element.id = createGuid();
        if (element?.condition) {
          searchCriteria.push({ operator: element?.condition, id: createGuid(), isValuabe: false, parentId: element.id, conditionIndex: element.conditionIndex });
          element.conditionIndex = element.conditionIndex + 1;
          searchCriteria.push(element)
        } else {
          searchCriteria.push(element)
        }
      });
      setSearchCriteria([...searchCriteria])
    }
  }, [archievedSearches]);

  const onAddFormSubmit = (data: any) => {
    const filtered = datafields?.filter((item) => item?.uniqueDataFieldId === data?.uniqueDataFieldId);
    const item: any = {
      id: createGuid(),
      condition: data?.condition,
      uniqueDataFieldId: data?.uniqueDataFieldId,
      dataFieldName: filtered[0]?.dataFieldName,
      fieldValue: data?.fieldValue,
      operator: data?.operator,
      startGroup: null,
      endGroup: null,
      isValuabe: true,
      conditionIndex: searchCriteria?.length,
    }
    if (isEditMode) {
      const indexes = searchCriteria?.findIndex((item) => item?.id === selectedItem[0]?.id);
      if (indexes >= 0) {
        searchCriteria[indexes].uniqueDataFieldId = data?.uniqueDataFieldId;
        searchCriteria[indexes].dataFieldName = filtered[0]?.dataFieldName;
        searchCriteria[indexes].operator = data?.operator;
        searchCriteria[indexes].fieldValue = data?.fieldValue;
        setSelectedItem(null)
      }
      setIsEditMode(false);
    } else {
      if (data?.condition) {
        searchCriteria.push({ operator: data?.condition, id: createGuid(), isValuabe: false, parentId: item.id, conditionIndex: item.conditionIndex });
        item.conditionIndex = item.conditionIndex + 1
        searchCriteria.push(item)
      } else {
        searchCriteria.push(item)
      }

    }
    setSearchCriteria([...searchCriteria]);
    reset();
  };

  const onEdit = () => {
    if (!searchCriteria?.length) {
      setDisplayInfoModal(true);
      setMessage("Conditions List cannot be empty");
      return;
    }
    if (!selectedItem?.length) {
      setDisplayInfoModal(true);
      setMessage("Select a row from the Conditions List");
      return;
    }
    if (selectedItem?.length > 1) {
      setDisplayInfoModal(true);
      setMessage("This operation can be performed only one row at a time");
    } else if (selectedItem?.[0]?.isValuabe) {
      const datafield = datafields?.filter((item) => item?.uniqueDataFieldId === selectedItem[0]?.uniqueDataFieldId);
      if (datafield?.length) {
        if (["Date Time", "Integer"].includes(datafield[0]?.dataType?.dataTypeName)) {
          setOperators(operatorsList);
        } else {
          const filterItem = operatorsList?.filter((item) => !["GREATER THAN", "LESS THAN"].includes(item.code));
          setOperators(filterItem);
        }
        setDataType(datafield[0]);
        setValue("uniqueDataFieldId", selectedItem[0].uniqueDataFieldId);
        setValue("operator", selectedItem[0].operator);
        setValue("condition", selectedItem[0].condition);
        setValue("fieldValue", selectedItem[0].fieldValue);
        setIsEditMode(true);
      }
    } else {
      setDisplayInfoModal(true);
      setMessage("This operation cannot be performed on the selected row");
    }
  }

  const onDelete = () => {
    if (!searchCriteria?.length) {
      setDisplayInfoModal(true);
      setMessage("Conditions List cannot be empty");
      return;
    }
    if (!selectedItem?.length) {
      setDisplayInfoModal(true);
      setMessage("Select a row from the Conditions List");
      return;
    }
    if (selectedItem?.length > 1) {
      setDisplayInfoModal(true);
      setMessage("This operation can be performed only one row at a time");
    } else if (selectedItem?.[0]?.isValuabe) {
      if (selectedItem?.[0]?.startGroup || selectedItem?.[0]?.endGroup) {
        setDisplayInfoModal(true);
        setMessage("Remove the grouping and then delete");
        return;
      }
      const filterItems = searchCriteria?.filter((item) => item?.id !== selectedItem[0].id && item?.parentId !== selectedItem[0].id);
      if (filterItems?.length) {
        if (!filterItems[0].isValuabe) {
          filterItems.splice(0, 1);
          filterItems[0].condition = "";
        }
        filterItems.forEach((element, index) => {
          element.conditionIndex = index
        });
      }
      setSearchCriteria([...filterItems]);
      setSelectedItem(null)
    } else {
      setDisplayInfoModal(true);
      setMessage("This operation cannot be performed on the selected row");
    }
  }

  const onGroup = () => {
    if (!searchCriteria?.length) {
      setDisplayInfoModal(true);
      setMessage("Conditions List cannot be empty");
      return;
    }
    if (!selectedItem?.length) {
      setDisplayInfoModal(true);
      setMessage("Select valid rows for grouping form the Conditions List");
      return;
    }
    let sortingItems = selectedItem?.sort((r1, r2) => (r1.conditionIndex > r2.conditionIndex) ? 1 : (r1.conditionIndex < r2.conditionIndex) ? -1 : 0);

    if (sortingItems?.length > 2 && sortingItems[0]?.isValuabe && sortingItems[sortingItems.length - 1]?.isValuabe) {
      const filterItems = sortingItems?.filter((item) => item?.startGroup === "(" || item?.endGroup === ")");
      if (filterItems?.length) {
        setDisplayInfoModal(true);
        setMessage("This selection is invalid for a group operation");
      } else {
        const firstIndex = searchCriteria?.findIndex((item) => item?.id === sortingItems[0]?.id);
        const lastIndex = searchCriteria?.findIndex((item) => item?.id === sortingItems[sortingItems.length - 1]?.id);
        searchCriteria[firstIndex].startGroup = "(";
        searchCriteria[lastIndex].endGroup = ")";
        setSearchCriteria([...searchCriteria]);
        setSelectedItem(null)
      }
    } else {
      setDisplayInfoModal(true);
      setMessage("This selection is invalid for a group operation");
    }
  }

  const onUnGroup = () => {
    if (!searchCriteria?.length) {
      setDisplayInfoModal(true);
      setMessage("Conditions List cannot be empty");
      return;
    }
    if (!selectedItem?.length) {
      setDisplayInfoModal(true);
      setMessage("Select valid rows for grouping form the Conditions List");
      return;
    }
    let sortingItems = selectedItem?.sort((r1, r2) => (r1.conditionIndex > r2.conditionIndex) ? 1 : (r1.conditionIndex < r2.conditionIndex) ? -1 : 0);
    if (sortingItems?.length > 2 && sortingItems[0]?.isValuabe && sortingItems[sortingItems.length - 1]?.isValuabe) {
      const firstIndex = searchCriteria?.findIndex((item) => item?.id === sortingItems[0]?.id);
      const lastIndex = searchCriteria?.findIndex((item) => item?.id === sortingItems[sortingItems.length - 1]?.id);
      if (searchCriteria[firstIndex]?.startGroup && searchCriteria[lastIndex]?.endGroup) {
        searchCriteria[firstIndex].startGroup = "";
        searchCriteria[lastIndex].endGroup = "";
        setSearchCriteria([...searchCriteria]);
        setSelectedItem(null)
      }
    } else {
      setDisplayInfoModal(true);
      setMessage("This selection is invalid for a ungroup operation");
    }
  }

  const onSearchCriteria = () => {
    let checked = false;
    if (checkedItem.isFromManuallyAdded || checkedItem.isFromXMLImported || checkedItem.showUntestedEquipments || checkedItem.showWithAttachmentsOnly) {
      checked = true
    }
    if (searchCriteria?.length || checked) {
      const selectedList = searchCriteria?.filter((item) => item?.isValuabe);
      const filterCriteria = selectedList?.map(({ isValuabe, id, ...rest }: any) => {
        return rest;
      });
      filterCriteria.forEach((element, index) => {
        element.conditionIndex = index;
      });
      const body = {
        uniqueSystemId: selectedTask?.system?.uniqueSystemId,
        uniqueEquipmentClassId: uniqueEquipmentClassId === "All" ? null : uniqueEquipmentClassId,
        inspectionTaskStatus: selectedTask?.status,
        uniquePanelId: uniquePanelId === "All" ? null : uniquePanelId,
        searchCriteria: filterCriteria,
        testDateFrom: moment(new Date()).format("DD MMM YYYY"),
        testDateTo: moment(new Date()).format("DD MMM YYYY"),
        showUntestedEquipments: checkedItem.showUntestedEquipments,
        showWithAttachmentsOnly: checkedItem.showWithAttachmentsOnly,
        isFromXMLImported: checkedItem.isFromXMLImported,
        isFromManuallyAdded: checkedItem.isFromManuallyAdded,
        filterType: "ADVANCED_FILTER"
      }
      onSearched(body)
    }
  }

  const onDatafieldChanged = (value) => {
    const types = datafields?.filter((item) => item?.uniqueDataFieldId === value);
    if (["Date Time", "Integer"].includes(types[0]?.dataType?.dataTypeName)) {
      setOperators(operatorsList);
    } else {
      const filterItem = operatorsList?.filter((item) => !["GREATER THAN", "LESS THAN"].includes(item.code));
      setOperators(filterItem);
    }
    setDataType(types[0]);
    setValue("operator", "");
    setValue("fieldValue", "");
  }

  const renderFooter = () => {
    return (
      <>
        <Button
          label="Cancel"
          onClick={() => setShowModal(false)}
          className="cancel btn"
        />
        <Button label="Search" className="update btn" autoFocus onClick={onSearchCriteria} />
      </>
    );
  };

  const onError = (errors: any, e: any) => { };

  const datafieldNameTemplate = (rowData, columnDetails) => {

    return (
      <div>
        {rowData?.dataFieldName &&
          <span>{rowData?.startGroup} [{rowData?.dataFieldName}]</span>
        }
      </div>
    )
  }

  const operatorTemplate = (rowData, columnDetails) => {

    return (
      <div>
        <span>{rowData?.operator}</span>
      </div>
    )
  }

  const fieldValueTemplate = (rowData, columnDetails) => {

    return (
      <div>
        <span>{rowData?.fieldValue} {rowData?.endGroup}</span>
      </div>
    )
  }

  return (
    <>
      <Dialog
        header="Advanced Search"
        visible={showModal}
        style={{ width: "50vw" }}
        onHide={() => setShowModal(false)}
        footer={renderFooter()}
        className="display-settings selectsite"
      >
        <label className="pop-subhead pl-2">
          <b>Condition List</b>
        </label>
        <div className="condition-container">        
          <div className="conditions-list">         
            <ScrollPanel style={{ width: '100%', height: '230px' }}>
              <DataTable
                disabled={isEditMode}
                value={searchCriteria}
                selectionMode="multiple"
                dragSelection
                selection={selectedItem}
                onSelectionChange={e => {
                  if (!isEditMode) setSelectedItem(e.value)
                }}
                dataKey="id"
                responsiveLayout="scroll">
                <Column body={datafieldNameTemplate}></Column>
                <Column body={operatorTemplate}></Column>
                <Column body={fieldValueTemplate}></Column>
              </DataTable>
            </ScrollPanel>
          </div>
          <div className="conditions-button">
            <Button label="Edit" className="btn" onClick={() => onEdit()} />
            <Button disabled={isEditMode} label="Delete" className="btn" onClick={() => onDelete()} />
            <Button disabled={isEditMode} label="Group" className="btn" onClick={() => onGroup()} />
            <Button disabled={isEditMode} label="Ungroup" className="btn" onClick={() => onUnGroup()} />
          </div>
        </div>


        <form
          onSubmit={handleSubmit(onAddFormSubmit, onError)}
          ref={addUserForm}
        >
          <label className="pop-subhead pl-2">
            <b>Condition Builder</b>
          </label>
          <div className="condition-builder">
            <div className="fields">
              <div className="field condition">
                <span className="p-float-label">
                  <Controller
                    name="condition"
                    control={control}
                    rules={{
                      required: (searchCriteria?.length && !isEditMode) ? "This field is required" : "",
                    }}
                    render={({ field, fieldState }) => (
                      <Dropdown
                        inputId="type"
                        className={classNames('w-100')}
                        {...field}
                        value={field.value}
                        disabled={(searchCriteria?.length === 0 || isEditMode) ? true : false}
                        onChange={(e) => field.onChange(e.value)}
                        options={[
                          { name: 'AND', code: 'AND' },
                          { name: `OR`, code: 'OR' }
                        ]}
                        optionLabel="name"
                        optionValue="code"
                      />
                    )}
                  />
                  <label htmlFor="Condition">Condition</label>
                </span>
              </div>

              <div className="field field-name">
                <span className="p-float-label">
                  <Controller
                    name="uniqueDataFieldId"
                    control={control}
                    rules={{
                      required: "This field is required",
                    }}
                    render={({ field, fieldState }) => (
                      <Dropdown
                        inputId="type"
                        disabled={(!searchCriteria?.length || watch("condition") || isEditMode) ? false : true}
                        className={classNames('w-100')}
                        {...field}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e.value);
                          onDatafieldChanged(e.value);
                        }}
                        options={datafields}
                        optionLabel="dataFieldName"
                        optionValue="uniqueDataFieldId"
                      />
                    )}
                  />
                  <label htmlFor="Field Name">Field Name</label>
                </span>
              </div>

              <div className="field operator">
                <span className="p-float-label">
                  <Controller
                    name="operator"
                    control={control}
                    rules={{
                      required: "This field is required",
                    }}
                    render={({ field, fieldState }) => (
                      <Dropdown
                        inputId="type"
                        className={classNames('w-100')}
                        {...field}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e.value);
                          setValue("fieldValue", "")
                        }}
                        disabled={watch("uniqueDataFieldId") ? false : true}
                        options={operatorss}
                        optionLabel="name"
                        optionValue="code"
                      />
                    )}
                  />
                  <label htmlFor="Operator">Operator</label>
                </span>
              </div>

              <div className="field value mr-0">
                <span className="p-float-label">
                  <Controller
                    name="fieldValue"
                    control={control}
                    rules={{
                      required: ["", "IS BLANK", "IS NOT BLANK"].includes(watch("operator")) ? "" : "This field is required",
                    }}
                    render={({ field, fieldState }) => {
                      return (
                        <>
                          {dataType?.dataType?.dataTypeName === "Date Time" ? (
                            <Calendar
                              id="single"
                              value={field.value !== "" ? new Date(field.value) : field.value}
                              onChange={(e: any) => {
                                setValue("fieldValue", moment(e.value).format("DD MMM YYYY"))
                              }}
                              selectionMode="single"
                              disabled={["", "IS BLANK", "IS NOT BLANK"].includes(watch("operator")) ? true : false}
                              dateFormat="dd M yy"
                              readOnlyInput
                            />
                          ) : (
                            <InputText
                              id="fieldValue"
                              {...field}
                              disabled={["", "IS BLANK", "IS NOT BLANK"].includes(watch("operator")) ? true : false}
                            />
                          )}
                        </>
                      )
                    }}
                  />
                  <label htmlFor="fieldValue">Value</label>
                </span>
              </div>
            </div>

            <div className="icon-fields">
              <div className="field">
                <span className="p-float-label action-btns center">
                  {isEditMode ? (
                    <Button
                      className="button-icon"
                      onClick={() => addUserForm?.current?.requestSubmit()}>
                      <BsCheck2 />
                    </Button>
                  ) : (
                    <Button
                      className="button-icon"
                      onClick={() => addUserForm?.current?.requestSubmit()}>
                      <BsPlusLg className="icon" />
                    </Button>
                  )
                  }
                </span>
              </div>
              <div className="field">
                <span className="p-float-label action-btns center">
                  <Button className="button-icon" onClick={() => { reset(); setIsEditMode(false) }}>
                    <BsDashLg className="icon" />
                  </Button>
                </span>
              </div>
            </div>

          </div>
        </form>
        <label className="pop-subhead pl-2">
        </label>
        <div className="checklist">
          <div className="field-checkbox px-2 pt-2">
            <Checkbox
              inputId="binary"
              checked={checkedItem.showUntestedEquipments}
              onChange={(e) => setCheckedItem((prev) => ({ ...prev, showUntestedEquipments: e.checked }))}
            />
            <label className="mandatory" htmlFor="binary">
              Untested Equipment
            </label>
          </div>
          <div className="field-checkbox px-2 pt-2">
            <Checkbox
              inputId="binary"
              checked={checkedItem.showWithAttachmentsOnly}
              onChange={(e) => setCheckedItem((prev) => ({ ...prev, showWithAttachmentsOnly: e.checked }))}
            />
            <label className="mandatory" htmlFor="binary">
              Attachments only
            </label>
          </div>
          <div className="field-checkbox px-2 pt-2">
            <Checkbox
              inputId="binary"
              checked={checkedItem.isFromXMLImported}
              onChange={(e) => setCheckedItem((prev) => ({ ...prev, isFromXMLImported: e.checked }))}
            />
            <label className="mandatory" htmlFor="binary">
              XML Imported
            </label>
          </div>
          <div className="field-checkbox px-2 pt-2">
            <Checkbox
              inputId="binary"
              checked={checkedItem.isFromManuallyAdded}
              onChange={(e) => setCheckedItem((prev) => ({ ...prev, isFromManuallyAdded: e.checked }))}
            />
            <label className="mandatory" htmlFor="binary">
              Manually added
            </label>
          </div>
        </div>
        <InformationBox
          showInfoModal={displayInfoModal}
          setShowInfoModal={setDisplayInfoModal}
          message={message} />
      </Dialog>
    </>
  );
};

export default memo(AdvancedSearch);
