import React, { memo, useRef, useState } from 'react'
//components
import EquipmentForm from 'components/common/Forms/GeneralForm/EquipmentForm';
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useDispatch } from 'react-redux';
import { ProgressSpinner } from 'primereact/progressspinner';
import { setIsDevReadingChanged, setIsTestedDateChanged } from 'redux/pages/Inspection/InspectionTask/InspectionDetails/Equipment/EquipmentSlice';

interface EditEquipmentPropType {
  showModal: boolean;
  datafields?: any;
  gridData?: any;
  selectedRow?: any;
  setShowModal?: (state: boolean) => void;
  onHideModal?: () => void;
  onFormSubmit?: (body) => void;
  onClickNext?: () => void;
  onClickPrev?: () => void;
  uniqueEquipmentClassId?: any;
  isFromDrawing?: boolean;
  showProgressBar?: boolean;
}

const EditEquipment: React.FC<EditEquipmentPropType> = (props) => {

  const { showModal, datafields, gridData, selectedRow, onHideModal = () => { }, setShowModal = () => { }, onFormSubmit = () => { }, onClickNext = () => { }, onClickPrev = () => { }, uniqueEquipmentClassId, showProgressBar, isFromDrawing = false } = props;
  const editForm = useRef<any>(null);
  const dispatch = useDispatch<any>();
  let body = {
    uniqueEquipmentClassId: uniqueEquipmentClassId
  }

  const formSubmitHandler = () => {
    editForm?.current?.requestSubmit();
  }

  const getDisabled = (type) => {
    const currentIndex = gridData?.findIndex((item) => item?.EquipmentId === selectedRow?.[0]?.EquipmentId);

    if (type === "PREV") {
      if (currentIndex > 0) return false
      else return true
    } else {
      if (currentIndex < (gridData?.length - 1)) return false
      else return true
    }
  }
  const onCancelClick =() =>{
    onHideModal();
    dispatch(setIsDevReadingChanged(''));
    dispatch(setIsTestedDateChanged(''));
  }

  const renderFooter = () => {
    return (
      <>
        <Button label="Cancel" onClick={onCancelClick} className="pre btn" />

        {!isFromDrawing && (<Button label="Previous" onClick={onClickPrev} disabled={getDisabled("PREV")} className="pre btn"/>)}

        <Button label="Save Changes " className="update btn" autoFocus onClick={formSubmitHandler} />
        {!isFromDrawing && (<Button label="Next" onClick={onClickNext} disabled={getDisabled("NEXT")} className="pre btn"/>)}
      </>
    );
  };

  return (
    <Dialog header="Edit Equipment" visible={showModal} style={{ width: "40vw", height: "95%" }} onHide={() => onHideModal()}
      footer={renderFooter()} className="form-element-modal">

      {showProgressBar && <div className='formElement-modal-spinner'> <ProgressSpinner /> </div> }

      <EquipmentForm ref={editForm} datafields={datafields} isEditForm={true} ddAppendTo='self' onFormSubmit={onFormSubmit} body={body}/>

    </Dialog>
  )

}

export default memo(EditEquipment)