import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { zlink, baseURL } from "../../../../../service/HttpService";
import { AppThunk } from "../../../../store";

export interface CommonAttributeState {
  errors: any;
  commonAttributeList: any;
  commonAttributePageDetails: any;
  attributeNameList: any;
  commonAttributeValues: any;
  commonAttributeValuePageDetails: any;
  fieldTypesData: any;
  commonAttributeDetails: any;
  commonAttributeValueDetails: any;
  isCommonAttributeInUse: any;
  isAttributeValueInUse:any;
  newCommonAtributeValue: any;
}

let initialState: CommonAttributeState = {
  errors: "",
  commonAttributeList: [],
  commonAttributePageDetails: [],
  attributeNameList: [],
  commonAttributeValues: [],
  commonAttributeValuePageDetails: [],
  fieldTypesData: "",
  commonAttributeDetails: {},
  commonAttributeValueDetails: {},
  isCommonAttributeInUse: "",
  isAttributeValueInUse:"",
  newCommonAtributeValue: "",
};

const CommonAttributeSlice = createSlice({
  name: "commonAttribute",
  initialState,
  reducers: {
    setErrors: (state, { payload }: PayloadAction<any>) => {
      state.errors = payload;
    },
    setCommonAttributeList: (state, { payload }: PayloadAction<any>) => {
      let ReviseData: any = [];
      payload.map((element: any, key: any) => {
        ReviseData = [
          ...ReviseData,
          {
            uniqueAttributeId: element.uniqueAttributeId,
            attributeName: element.attributeName,
            attributeType: element.attributeType,
            isMandatory: element.isMandatory ? "True" : "False",
            isValidated: element.isValidated ? "True" : "False",
            maxlength: element.maxlength,
          },
        ];
        return null;
      });
      state.commonAttributeList = ReviseData;
    },
    setCommonAttributePageDetails: (state, { payload }: PayloadAction<any>) => {
      state.commonAttributePageDetails = payload;
    },
    setAttributeNameList: (state, { payload }: PayloadAction<any>) => {
      state.attributeNameList = payload;
    },
    setCommonAttributeValues: (state, { payload }: PayloadAction<any>) => {
      state.commonAttributeValues = payload;
    },
    setCommonAttributeValuePageDetails: (
      state,
      { payload }: PayloadAction<any>
    ) => {
      state.commonAttributeValuePageDetails = payload;
    },
    setFieldTypesData: (state, { payload }: PayloadAction<any>) => {
      state.fieldTypesData = payload;
    },
    setNewCommonAttributeData: (state, { payload }: PayloadAction<any>) => {
      let newData: any = [];
      newData = [
        {
          uniqueAttributeId: payload.uniqueAttributeId,
          attributeName: payload.attributeName,
          attributeType: payload.attributeType,
          isMandatory: payload.isMandatory ? "True" : "False",
          isValidated: payload.isValidated ? "True" : "False",
          maxlength: payload.maxlength,
        },
      ];
      state.commonAttributeList.unshift(newData[0]);
    },

    setNewCommonAttributeValueData: (state, { payload }: PayloadAction<any>) => {
      let newData: any = [];
      newData = [
        {
          dataFieldLookUpId: payload.dataFieldLookUpId,
          attributeValue: payload.attributeValue,
        },
      ];
      state.commonAttributeValues.unshift(newData[0]);
      state.newCommonAtributeValue = newData[0];
    },
    setCommonAttributeDetails: (state, { payload }: PayloadAction<any>) => {
      state.commonAttributeDetails = payload;
    },
    setCommonAttributeValueDetails: (state, { payload }: PayloadAction<any>) => {
      state.commonAttributeValueDetails = payload;
    },
    setIsCommonAttributeInUse: (state, { payload }: PayloadAction<any>) => {
      state.isCommonAttributeInUse = payload;
    },
    setIsAttributeValueInUse: (state, { payload }: PayloadAction<any>) => {
      state.isAttributeValueInUse = payload;
    },
    setUpdatedCommonAttributeData: (state, { payload }: PayloadAction<any>) => {
      let newData: any = [];
      newData = [
        {
          uniqueAttributeId: payload.uniqueAttributeId,
          attributeName: payload.attributeName,
          attributeType: payload.attributeType,
          isMandatory: payload.isMandatory ? "True" : "False",
          isValidated: payload.isValidated ? "True" : "False",
          maxlength: payload.maxlength,
        },
      ];
      let currentIndex = state.commonAttributeList.findIndex(
        (item: any) => item.uniqueAttributeId === payload?.uniqueAttributeId
      );
      state.commonAttributeList = state.commonAttributeList.filter(
        (item: any) => item.uniqueAttributeId !== payload?.uniqueAttributeId
      );
      state.commonAttributeList.splice(currentIndex, 0, newData[0]);
    },
    setUpdatedCommonAttributeValueData: (state, { payload }: PayloadAction<any>) => {
      let newData: any = [];
      newData = [
        {
          dataFieldLookUpId: payload.dataFieldLookUpId,
          attributeValue: payload.attributeValue,
        },
      ];
      let currentIndex = state.commonAttributeValues.findIndex(
        (item: any) => item.dataFieldLookUpId === payload?.dataFieldLookUpId
      );
      state.commonAttributeValues = state.commonAttributeValues.filter(
        (item: any) => item.dataFieldLookUpId !== payload?.dataFieldLookUpId
      );
      state.commonAttributeValues.splice(currentIndex, 0, newData[0]);
    },
    setDeletedCommonAttribute(state, { payload }: PayloadAction<any>) {
      state.commonAttributeList = state.commonAttributeList.filter(
        (item: any) => item.uniqueAttributeId !== payload
      );
    },
    setDeletedCommonAttributeValues(state, { payload }: PayloadAction<any>) {
      state.commonAttributeValues = state.commonAttributeValues.filter(
        (item: any) => item.dataFieldLookUpId !== payload
      );
    },
  },
});

export const getCommonAttributeList = (currentStart,keyword: any = "",sortField: any = "", sortOrder: any = ""): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.get(`${baseURL}api/v1/manage/customization/attributes/commonattribute/commonattributelist?currentStart=${currentStart}&keyWord=${keyword}&sortColumn=${sortField}&sortOrder=${sortOrder}`);
    if (res.status === "done") {
      dispatch(setCommonAttributeList(res?.data?.attributeDetails));
      dispatch(setCommonAttributePageDetails(res?.data?.pageDetails));
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const getAttributeNameList = (setFetchAttributeName): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.get(`${baseURL}api/v1/manage/customization/attributes/commonattributevalues/attributenamelist`);
    if (res.status === "done") {
      dispatch(setAttributeNameList(res?.data?.attributeDetails));
      setFetchAttributeName(true);
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const getCommonAttributeValues = (attributeId, currentStart,keyword: any = "",sortField: any = "", sortOrder: any = "", setMessage, setDisplayInfoModal, setIsLoaded): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.get(`${baseURL}api/v1/manage/customization/attributes/commonattribute/${attributeId}/commonattributevalues?currentStart=${currentStart}&keyWord=${keyword}&sortColumn=${sortField}&sortOrder=${sortOrder}`);
    if (res.status === "done") {
      if (res.data) {
        dispatch(setCommonAttributeValues(res?.data?.attributeDetails));
        dispatch(setCommonAttributeValuePageDetails(res?.data?.pageDetails));
        if (!res.data.attributeDetails.length) { 
          setMessage('No Common Attribute Values exist')
          setDisplayInfoModal(true);
        }
      }
      if (setIsLoaded) {
        setIsLoaded(true);
      }
    } else {
      dispatch(setErrors(res.error));
      if (setIsLoaded) {
        setIsLoaded(true);
      }
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const getFieldTypesData = (): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.get(`${baseURL}api/v1/fieldtypes`);
    if (res.status === "done") {
      dispatch(setFieldTypesData(res?.data?.dataTypes));
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const onAddCommonAttribute = (body: any, setMessage, setDisplayInfoModal, setShowAddModal): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.post(`${baseURL}api/v1/attributes/commonattributes/add`, body);
    if (res.status === "done") {
      dispatch(setNewCommonAttributeData(res.data));
      setShowAddModal(false);
      setMessage("Common Attribute added");
      setDisplayInfoModal(true);
    } else {
      dispatch(setErrors(res.error));
      setMessage(res.message);
      setDisplayInfoModal(true);
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const onAddCommonAttributeValue = (body: any, uniqueAttributeId, setMessage, setDisplayInfoModal, setShowAddModal): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.post(`${baseURL}api/v1/attributes/${uniqueAttributeId}/values/add`, body);
    if (res.status === "done") {
      setShowAddModal(false);
      dispatch(setNewCommonAttributeValueData(res.data));
      setMessage("Common Attribute Value added");
      setDisplayInfoModal(true);
    } else {
      dispatch(setErrors(res.error));
      setMessage(res.message);
      setDisplayInfoModal(true);
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const getCommonAttributeDetails = (attributeId: any): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.get(`${baseURL}api/v1/attributes/commonattributes/${attributeId}`);
    if (res.status === "done") {
      dispatch(setCommonAttributeDetails(res?.data));
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const checkIsCommonAttributeInUse = (attributeId): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.get(
      `${baseURL}api/v1/attributes/commonattributes/${attributeId}/checkcommonattributeinuse`
    );
    if (res.status === "done") {
      dispatch(setIsCommonAttributeInUse(res?.data));
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const onEditCommonAttribute = (attributeId, body: any, setMessage, setDisplayInfoModal, setShowAddModal): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.post(`${baseURL}api/v1/attributes/commonattributes/${attributeId} `, body);
    if (res.status === "done") {
      dispatch(setUpdatedCommonAttributeData(res.data));
      setShowAddModal(false);
      setMessage("Common Attribute updated");
      setDisplayInfoModal(true);
    } else {
      dispatch(setErrors(res.error));
      setMessage(res.message);
      setDisplayInfoModal(true);
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const onEditCommonAttributeValue = (attributeId, datafieldlookupid, body: any, setMessage, setDisplayInfoModal, setShowEditModal): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.post(`${baseURL}api/v1/attributes/${attributeId}/values/${datafieldlookupid}`, body);
    if (res.status === "done") {
      dispatch(setUpdatedCommonAttributeValueData(res.data));
      setShowEditModal(false);
      setMessage("Common Attribute Value updated");
      setDisplayInfoModal(true);
    } else {
      dispatch(setErrors(res.error));
      setMessage(res.message);
      setDisplayInfoModal(true);
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const getCommonAttributeValueDetails = (attributeId: any, datafieldlookupid: any): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.get(`${baseURL}api/v1/attributes/${attributeId}/values/${datafieldlookupid}`);
    if (res.status === "done") {
      dispatch(setCommonAttributeValueDetails(res?.data));
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const deleteCommonAttribute = (attributeId, setSelectedRows, setMessage, setDisplayInfoModal): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.post(`${baseURL}api/v1/attributes/commonattributes/${attributeId}/remove`, null);
    if (res.status === "done") {
      dispatch(setDeletedCommonAttribute(attributeId));
      setSelectedRows([]);
      setMessage("Common Attribute deleted");
      setDisplayInfoModal(true);
    } else {
      setSelectedRows([]);
      setMessage(res.message);
      setDisplayInfoModal(true);
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const checkIsAttributeValueInUse = (uniqueattributeid,datafieldlookupid): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.get(`${baseURL}api/v1/attributes/${uniqueattributeid}/values/${datafieldlookupid}/checkattributevalueinuse`);
    if (res.status === "done") {
      dispatch(setIsAttributeValueInUse(res?.data));
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const deleteCommonAttributeValue = (uniqueattributeid,datafieldlookupid, setSelectedRows, setMessage, setDisplayInfoModal): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.post(`${baseURL}api/v1/attributes/${uniqueattributeid}/values/${datafieldlookupid}/remove`, null);
    if (res.status === "done") {
      dispatch(setDeletedCommonAttributeValues(datafieldlookupid));
      setSelectedRows([]);
      setMessage("Common Attribute Value deleted");
      setDisplayInfoModal(true);
    } else {
      setSelectedRows([]);
      setMessage(res.message);
      setDisplayInfoModal(true);
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const {
  setErrors,
  setCommonAttributeList,
  setCommonAttributePageDetails,
  setAttributeNameList,
  setCommonAttributeValues,
  setDeletedCommonAttributeValues,
  setCommonAttributeValuePageDetails,
  setFieldTypesData,
  setNewCommonAttributeData,
  setNewCommonAttributeValueData,
  setCommonAttributeDetails,
  setCommonAttributeValueDetails,
  setIsCommonAttributeInUse,
  setIsAttributeValueInUse,
  setUpdatedCommonAttributeData,
  setUpdatedCommonAttributeValueData,
  setDeletedCommonAttribute,
} = CommonAttributeSlice.actions;

export default CommonAttributeSlice.reducer;
