import { InputText } from 'primereact/inputtext';


const textEditor = (options) => {
    return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
};


export const CUSTOMIZATION_ATTRIBUTES_VALUE_COLS = {
    COMMON_ATTRIBUTES_VALUE_COLS: [
        {
            field: 'attributeValue',
            header: 'Value',
            editor: (options) => textEditor(options),
            style: { flex: '1 0 200px' },
            sortable: true,
        }
    ]
    
};
