export const SAP_IMPORT_STATUS_COLS = [
    {
        field: "fileName",
        header: "File Name",
        style: { flex: '1 0 150px' },
        sortable: true,
    },
    {
        field: "fileType",
        header: "File Type",
        style: { flex: '1 0 150px' },
        sortable: true,
    },
    {
        field: "dateOfIntegration",
        header: "Date of Integration",
        style: { flex: '1 0 150px' },
        sortable: true,
    },
    {
        field: "status",
        header: "Status",
        style: { flex: '1 0 150px' },
        sortable: true,
    }
];