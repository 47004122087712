export const TASK_LIST_COLS = {
    LIST_COLS: [
        {
            field: 'equipmentType',
            header: 'Equipment Type',
            // editor: (options) => textEditor(options),
            style: { flex: '1 0 200px' },
            sortable: true,
        },
        {
            field: 'address',
            header: 'Address',
           // editor: (options) => textEditor(options),
            style: { flex: '1 0 300px' },
            sortable: true,
        },
        {
            field: 'status',
            header: 'Status',
            //editor: (options) => textEditor(options),
            style: { flex: '1 0 200px' },
            sortable: true,
        },
        {
            field: 'nfpaClassification',
            header: 'NFPA Classification',
            //editor: (options) => textEditor(options),
            style: { flex: '1 0 200px' },
            sortable: true,
        },
        {
            field: 'healthClassification',
            header: 'Health Classification',
            //editor: (options) => reportEditor(options),
            style: { flex: '1 0 200px' },
            sortable: true,
        },
        {
            field: 'testResult',
            header: 'Test Result',
            //editor: (options) => reportEditor(options),
            style: { flex: '1 0 200px' },
            sortable: true,
        },
        {
            field: 'xmlUsage',
            header: 'XML Usage',
            //editor: (options) => reportEditor(options),
            style: { flex: '1 0 200px' },
            sortable: true,
        },
        {
            field: 'xmlType',
            header: 'XML Type',
            //editor: (options) => reportEditor(options),
            style: { flex: '1 0 200px' },
            sortable: true,
        },
        {
            field: 'location',
            header: 'Location',
            style: { flex: '1 0 300px' },
            sortable: true,
        }       
    ]
};