import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "redux/store";
import { baseURL, zlink } from "service/HttpService";



export interface InspectionFieldState {
    errors: any;
    inputTypes: any;
    fieldTypes: any;
    newQuestionInspectionField: any;
    inspectionQuestions: any;
    newFieldValue: any;
    updatedFieldValue: any;
    deletedFieldValue: any;
    updatedQuestionInspectionField: any;
    deletedQuestionInspectionField: any;
    newGroupSection: any;
    updatedGroupSection: any;
    deletedGroupSection: any;
    inspectionContracts: any;
    inspectionSystems: any;
    newContractInspectionField: any;
    newSystemInspectionField: any;
    updatedContractInspectionField: any;
    updatedSystemInspectionField: any;
    deletedSystemInspectionField: any;
    deletedContractInspectionField: any;
    isCheckInUse: any;
    DefaultData: any;
    inspectionFieldValues: any;
    inspectiondatafieldOrderList: any;
    updatedInspectionDatafieldOrder:any;
    inspectionquestionfieldOrderList: any;
    updatedQuestionInspectionDatafieldOrder:any;
    updatedQuestionInspectionDatafieldOrderList:any;
    isQuestionNodeInUse: any;
    isQuestionDataInUse:any;
}

let initialState: InspectionFieldState = {
    errors: "",
    inputTypes: [],
    fieldTypes: [],
    newQuestionInspectionField: "",
    inspectionQuestions: "",
    newFieldValue: "",
    updatedFieldValue: "",
    deletedFieldValue: "",
    updatedQuestionInspectionField: "",
    deletedQuestionInspectionField: "",
    newGroupSection: "",
    updatedGroupSection: "",
    deletedGroupSection: "",
    inspectionContracts: "",
    inspectionSystems: "",
    newContractInspectionField: "",
    newSystemInspectionField: "",
    updatedContractInspectionField: "",
    updatedSystemInspectionField: "",
    deletedSystemInspectionField: "",
    deletedContractInspectionField: "",
    isCheckInUse: false,
    DefaultData: "",
    inspectionFieldValues: "",
    inspectiondatafieldOrderList: "",
    updatedInspectionDatafieldOrder:"",
    inspectionquestionfieldOrderList:"",
    updatedQuestionInspectionDatafieldOrder:"",
    updatedQuestionInspectionDatafieldOrderList:"",
    isQuestionNodeInUse: [],
    isQuestionDataInUse:[],
};

const InspectionFieldSlice = createSlice({
    name: "inspectionField",
    initialState,
    reducers: {
        setErrors: (state, { payload }: PayloadAction<any>) => {
            state.errors = payload;
        },
        setInputTypes: (state, { payload }: PayloadAction<any>) => {
            state.inputTypes = payload;
        },
        setFieldTypes: (state, { payload }: PayloadAction<any>) => {
            state.fieldTypes = payload;
        },
        setNewQuestionInspectionField: (state, { payload }: PayloadAction<any>) => {
            state.newQuestionInspectionField = payload;
        },
        setInspectionQuestions: (state, { payload }: PayloadAction<any>) => {
            state.inspectionQuestions = payload;
        },
        setNewFieldValue: (state, { payload }: PayloadAction<any>) => {
            state.newFieldValue = payload;
        },
        setupdatedFieldValue: (state, { payload }: PayloadAction<any>) => {
            state.updatedFieldValue = payload;
        },
        setDeletedFieldValue: (state, { payload }: PayloadAction<any>) => {
            state.deletedFieldValue = payload;
        },
        setUpdatedQuestionInspectionField: (state, { payload }: PayloadAction<any>) => {
            state.updatedQuestionInspectionField = payload;
        },
        setDeletedQuestionInspectionField: (state, { payload }: PayloadAction<any>) => {
            state.deletedQuestionInspectionField = payload;
        },
        setNewGroupSection: (state, { payload }: PayloadAction<any>) => {
            state.newGroupSection = payload;
        },
        setUpdatedGroupSection: (state, { payload }: PayloadAction<any>) => {
            state.updatedGroupSection = payload;
        },
        setDeletedGroupSection: (state, { payload }: PayloadAction<any>) => {
            state.deletedGroupSection = payload;
        },
        setInspectionContracts: (state, { payload }: PayloadAction<any>) => {
            state.inspectionContracts = payload;
        },
        setInspectionSystems: (state, { payload }: PayloadAction<any>) => {
            state.inspectionSystems = payload;
        },
        setNewContractInspectionField: (state, { payload }: PayloadAction<any>) => {
            state.newContractInspectionField = payload;
        },
        setNewSystemInspectionField: (state, { payload }: PayloadAction<any>) => {
            state.newSystemInspectionField = payload;
        },
        setUpdatedContractInspectionField: (state, { payload }: PayloadAction<any>) => {
            state.updatedContractInspectionField = payload;
        },
        setUpdatedSystemInspectionField: (state, { payload }: PayloadAction<any>) => {
            state.updatedSystemInspectionField = payload;
        },
        setDeletedContractInspectionField: (state, { payload }: PayloadAction<any>) => {
            state.deletedContractInspectionField = payload;
        },
        setDeletedSystemInspectionField: (state, { payload }: PayloadAction<any>) => {
            state.deletedSystemInspectionField = payload;
        },
        setIsCheckInUse: (state, { payload }: PayloadAction<any>) => {
            state.isCheckInUse = payload;
        },
        setAsDefaultData: (state, { payload }: PayloadAction<any>) => {
            state.DefaultData = payload;
        },
        setInspectionFieldValues: (state, { payload }: PayloadAction<any>) => {
            state.inspectionFieldValues = payload;
        },
        setInspectionDatafieldOrderList: (state, { payload }: PayloadAction<any>) => {
            state.inspectiondatafieldOrderList = payload;
        },
        setUpdatedInspectionDatafieldOrder: (state, { payload }: PayloadAction<any>) => {
            state.updatedInspectionDatafieldOrder = payload;
        },
        setInspectionquestionfieldOrderList: (state, { payload }: PayloadAction<any>) => {
            state.inspectionquestionfieldOrderList = payload;
        },
        setUpdatedQuestionInspectionDatafieldOrder: (state, { payload }: PayloadAction<any>) => {
            state.updatedQuestionInspectionDatafieldOrder = payload;
        },
        setUpdatedQuestionInspectionDatafieldOrderList: (state, { payload }: PayloadAction<any>) => {
            state.updatedQuestionInspectionDatafieldOrderList = payload;
        },
        setIsQuestionNodeInUse:(state, { payload }: PayloadAction<any>) => {
            state.isQuestionNodeInUse = payload;
        },
        setIsQuestionDataInUse: (state, { payload }: PayloadAction<any>) => {
            state.isQuestionDataInUse = payload;
        },
    },
});

export const getInputTypes = (): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.get(`${baseURL}api/v1/inspectionfields/inputtypes`);
            if (res.status === "done") {
                dispatch(setInputTypes(res?.data?.inputTypeDetails));
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

export const getFieldTypes = (inputTypeId: any): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.get(`${baseURL}api/v1/inspectionfields/inputtypes/${inputTypeId}/fieldtypes`);
            if (res.status === "done") {
                dispatch(setFieldTypes(res?.data?.fieldTypes));
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

export const onAddQuestionInspectionField = (body: any): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.post(`${baseURL}api/v1/inspectionfields/inspectionquestions/datafields/add`, body);
            if (res.status === "done") {
                dispatch(setNewQuestionInspectionField(res?.data));
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

export const onGetInspectionQuestions = (): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.get(`${baseURL}api/v1/inspectionfields/inspectionquestions/datafields`);
            if (res.status === "done") {
                dispatch(setInspectionQuestions(res?.data?.inspectionGroupSections));
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

export const onAddNewInspectionFieldValue = (datafieldId, body, reset): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.post(`${baseURL}api/v1/inspectionfields/inspectionquestions/datafields/${datafieldId}/datafieldvalues/add`, body);
            if (res.status === "done") {
                dispatch(setNewFieldValue(res.data));
                reset();
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

export const onUpdateInspectionFieldValue = (datafieldId, datafieldLookupId, body, updatedValue): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.post(`${baseURL}api/v1/inspectionfields/inspectionquestions/datafields/${datafieldId}/datafieldvalues/${datafieldLookupId}`, body);
            if (res.status === "done") {
                dispatch(setupdatedFieldValue(res.data));
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

export const checkUsageOfInspectionFieldValue = (datafieldId, datafieldLookupId, deletedItem): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.get(`${baseURL}api/v1/inspectionfields/inspectionquestions/datafields/${datafieldId}/datafieldvalues/${datafieldLookupId}/checkdatafieldlookupinuse`);
            if (res.status === "done") {
                if (res.data.isDataFieldLookupInUse === false) {
                    dispatch(onRemoveInspectionFieldValue(datafieldId, datafieldLookupId, deletedItem));
                }
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

const onRemoveInspectionFieldValue = (datafieldId, datafieldLookupId, deletedItem): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.post(`${baseURL}api/v1/inspectionfields/inspectionquestions/datafields/${datafieldId}/datafieldvalues/${datafieldLookupId}/remove`, null);
            if (res.status === "done") {
                if (res?.data?.isSuccess)
                    dispatch(setDeletedFieldValue(deletedItem));
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

export const onUpdateQuestionInspectionField = (datafieldId, body): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.post(`${baseURL}api/v1/inspectionfields/inspectionquestions/datafields/${datafieldId}`, body);
            if (res.status === "done") {
                dispatch(setUpdatedQuestionInspectionField(res.data));
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

export const checkUsageOfQuestionInspectionField = (datafieldId, body, deletedItem): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.post(`${baseURL}api/v1/inspectionfields/inspectionquestions/datafields/${datafieldId}/checkdatafieldinuse`, body);
            if (res.status === "done") {
                if (res.data.isDataFieldInUse === false) {
                    dispatch(onRemoveQuestionInspectionField(datafieldId, body, deletedItem));
                } else {
                    dispatch(setIsQuestionDataInUse([res.data]));
                }
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

export const onRemoveQuestionInspectionField = (datafieldId, body, deletedItem): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.post(`${baseURL}api/v1/inspectionfields/inspectionquestions/datafields/${datafieldId}/remove`, body);
            if (res.status === "done") {
                dispatch(setDeletedQuestionInspectionField(deletedItem));
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

export const onInspectionGroupSectionAdded = (body): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.post(`${baseURL}api/v1/inspectionfields/inspectionquestions/nodes/add`, body);
            if (res.status === "done") {
                dispatch(setNewGroupSection(res.data));
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

export const onInspectionGroupSectionUpdated = (nodeId, body): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.post(`${baseURL}api/v1/inspectionfields/inspectionquestions/nodes/${nodeId}`, body);
            if (res.status === "done") {
                dispatch(setUpdatedGroupSection(res.data));
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

export const checkUsageOfGroupSection = (nodeId): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.get(`${baseURL}api/v1/inspectionfields/inspectionquestions/nodes/${nodeId}/checknodeinuse`);
            if (res.status === "done") {
                if (res.data.inspectionGroupSectionInUse === false) {
                    dispatch(onRemoveGroupSection(nodeId));
                } else {
                    dispatch(setIsQuestionNodeInUse([res.data]))
                }
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

export const onRemoveGroupSection = (nodeId): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.post(`${baseURL}api/v1/inspectionfields/inspectionquestions/nodes/${nodeId}/remove`, null);
            if (res.status === "done") {
                dispatch(setDeletedGroupSection(nodeId))
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

export const onGetInspectionContracts = (): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.get(`${baseURL}api/v1/inspectionfields/contractfields/datafields`);
            if (res.status === "done") {
                dispatch(setInspectionContracts(res?.data?.inspectionGroupSections));
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

export const onGetInspectionSystems = (): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.get(`${baseURL}api/v1/inspectionfields/systemfields/datafields`);
            if (res.status === "done") {
                dispatch(setInspectionSystems(res?.data?.inspectionGroupSections));
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

export const onAddContractInspectionField = (body: any): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.post(`${baseURL}api/v1/inspectionfields/contractfields/datafields/add`, body);
            if (res.status === "done") {
                dispatch(setNewContractInspectionField(res?.data));
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

export const onAddSystemInspectionField = (body: any): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.post(`${baseURL}api/v1/inspectionfields/systemfields/datafields/add`, body);
            if (res.status === "done") {
                dispatch(setNewSystemInspectionField(res?.data));
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

export const onAddNewContractInspectionFieldValue = (datafieldId, body, reset): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.post(`${baseURL}api/v1/inspectionfields/contractfields/datafields/${datafieldId}/datafieldvalues/add`, body);
            if (res.status === "done") {
                dispatch(setNewFieldValue(res.data));
                reset();
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

export const onAddNewSystemInspectionFieldValue = (datafieldId, body, reset): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.post(`${baseURL}api/v1/inspectionfields/systemfields/datafields/${datafieldId}/datafieldvalues/add`, body);
            if (res.status === "done") {
                dispatch(setNewFieldValue(res.data));
                reset();
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

export const onUpdateContractInspectionFieldValue = (datafieldId, datafieldLookupId, body, updatedValue): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.post(`${baseURL}api/v1/inspectionfields/contractfields/datafields/${datafieldId}/datafieldvalues/${datafieldLookupId}`, body);
            if (res.status === "done") {
                dispatch(setupdatedFieldValue(res.data));
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

export const onUpdateSystemInspectionFieldValue = (datafieldId, datafieldLookupId, body, updatedValue): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.post(`${baseURL}api/v1/inspectionfields/systemfields/datafields/${datafieldId}/datafieldvalues/${datafieldLookupId}`, body);
            if (res.status === "done") {
                dispatch(setupdatedFieldValue(res.data));
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

export const checkUsageOfContractInspectionFieldValue = (datafieldId, datafieldLookupId, deletedItem): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.get(`${baseURL}api/v1/inspectionfields/contractfields/datafields/${datafieldId}/datafieldvalues/${datafieldLookupId}/checkdatafieldlookupinuse`);
            if (res.status === "done") {
                if (res.data.isDataFieldLookupInUse === false) {
                    dispatch(onRemoveContractInspectionFieldValue(datafieldId, datafieldLookupId, deletedItem));
                }
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

export const checkUsageOfSystemInspectionFieldValue = (datafieldId, datafieldLookupId, deletedItem): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.get(`${baseURL}api/v1/inspectionfields/systemfields/datafields/${datafieldId}/datafieldvalues/${datafieldLookupId}/checkdatafieldlookupinuse`);
            if (res.status === "done") {
                if (res.data.isDataFieldLookupInUse === false) {
                    dispatch(onRemoveSystemInspectionFieldValue(datafieldId, datafieldLookupId, deletedItem));
                }
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

const onRemoveContractInspectionFieldValue = (datafieldId, datafieldLookupId, deletedItem): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.post(`${baseURL}api/v1/inspectionfields/contractfields/datafields/${datafieldId}/datafieldvalues/${datafieldLookupId}/remove`, null);
            if (res.status === "done") {
                if (res?.data?.isSuccess)
                    dispatch(setDeletedFieldValue(deletedItem));
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

const onRemoveSystemInspectionFieldValue = (datafieldId, datafieldLookupId, deletedItem): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.post(`${baseURL}api/v1/inspectionfields/systemfields/datafields/${datafieldId}/datafieldvalues/${datafieldLookupId}/remove`, null);
            if (res.status === "done") {
                if (res?.data?.isSuccess)
                    dispatch(setDeletedFieldValue(deletedItem));
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

export const onUpdateSystemInspectionField = (datafieldId, body): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.post(`${baseURL}api/v1/inspectionfields/systemfields/datafields/${datafieldId}`, body);
            if (res.status === "done") {
                dispatch(setUpdatedSystemInspectionField(res.data));
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

export const onUpdateContractInspectionField = (datafieldId, body): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.post(`${baseURL}api/v1/inspectionfields/contractfields/datafields/${datafieldId}`, body);
            if (res.status === "done") {
                dispatch(setUpdatedContractInspectionField(res?.data));
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

export const checkUsageOfContractInspectionField = (datafieldId, body, deletedItem): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.post(`${baseURL}api/v1/inspectionfields/contractfields/datafields/${datafieldId}/checkcontractfieldinuse`, body);
            if (res.status === "done") {
                if (res.data.isDataFieldInUse === false) {
                    dispatch(onRemoveContractInspectionField(datafieldId, body, deletedItem));
                } else {
                    dispatch(setIsCheckInUse(true));
                }
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

export const onRemoveContractInspectionField = (datafieldId, body, deletedItem): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.post(`${baseURL}api/v1/inspectionfields/contractfields/datafields/${datafieldId}/remove`, body);
            if (res.status === "done") {
                dispatch(setDeletedContractInspectionField(deletedItem));
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

export const checkUsageOfSystemInspectionField = (datafieldId, body, deletedItem): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.post(`${baseURL}api/v1/inspectionfields/systemfields/datafields/${datafieldId}/checksystemfieldinuse`, body);
            if (res.status === "done") {
                if (res.data.isDataFieldInUse === false) {
                    dispatch(onRemoveSystemInspectionField(datafieldId, body, deletedItem));
                } else {
                    dispatch(setIsCheckInUse(true));
                }
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

export const onRemoveSystemInspectionField = (datafieldId, body, deletedItem): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.post(`${baseURL}api/v1/inspectionfields/systemfields/datafields/${datafieldId}/remove`, body);
            if (res.status === "done") {
                dispatch(setDeletedSystemInspectionField(deletedItem));
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

export const onSetAsDefualtValue = (body): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.post(`${baseURL}api/v1/inspectionfields/inspectionquestions/datafields/defaultvalues`, body);
            if (res.status === "done") {
                dispatch(setAsDefaultData(body));
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

export const onGetInspectionFieldValues = (): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.get(`${baseURL}api/v1/inspectionfields/inspectionquestions/datafields/values`);
            if (res.status === "done") {
                dispatch(setInspectionFieldValues(res.data.values));
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

    export const onGetInspectionDatafieldOrderList = (): AppThunk => async (dispatch) => {
        try {
            const res = await zlink.get(`${baseURL}api/v1/inspectionfields/datafieldorder/system`);
            if (res.status === "done") {
                dispatch(setInspectionDatafieldOrderList(res?.data?.dataFieldOrderDetails));
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

    export const onInspectionDatafieldOrderUpdated = (body): AppThunk => async (dispatch) => {
        try {
            const res = await zlink.post(`${baseURL}api/v1/inspectionFields/datafieldorder/update`, body);
            if (res.status === "done") {
                dispatch(setUpdatedInspectionDatafieldOrder(body.dataFields));
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

    export const onGetInspectionquestionfieldOrderList = (dataFieldId): AppThunk => async (dispatch) => {
        try {
            const res = await zlink.get(`${baseURL}api/v1/inspectionfields/datafieldorder/getquestionsfieldorderdata/${dataFieldId}`);
            if (res.status === "done") {
               dispatch(setInspectionquestionfieldOrderList(res?.data?.questionsFieldOrderList));
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

export const onInspectionQuestionDatafieldOrderUpdated = (body): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.post(`${baseURL}api/v1/inspectionFields/datafieldorder/updatefieldorderquestions`, body);
        if (res.status === "done") {
            dispatch(setUpdatedQuestionInspectionDatafieldOrder(body.dataFields));
        } else {
            dispatch(setErrors(res.error));
        }
    } catch (error) {
        dispatch(setErrors(error));
    }
};

export const {
    setErrors,
    setInputTypes,
    setFieldTypes,
    setNewQuestionInspectionField,
    setInspectionQuestions,
    setNewFieldValue,
    setupdatedFieldValue,
    setDeletedFieldValue,
    setUpdatedQuestionInspectionField,
    setDeletedQuestionInspectionField,
    setNewGroupSection,
    setUpdatedGroupSection,
    setDeletedGroupSection,
    setInspectionContracts,
    setInspectionSystems,
    setNewContractInspectionField,
    setNewSystemInspectionField,
    setUpdatedContractInspectionField,
    setUpdatedSystemInspectionField,
    setDeletedContractInspectionField,
    setDeletedSystemInspectionField,
    setIsCheckInUse,
    setAsDefaultData,    
    setInspectionFieldValues,
    setInspectionDatafieldOrderList,
    setUpdatedInspectionDatafieldOrder,
    setInspectionquestionfieldOrderList,
    setUpdatedQuestionInspectionDatafieldOrder,
    setUpdatedQuestionInspectionDatafieldOrderList,
    setIsQuestionNodeInUse,
    setIsQuestionDataInUse,
} = InspectionFieldSlice.actions;
export default InspectionFieldSlice.reducer;