import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "components/common/Breadcrumb/Breadcrumb";
import { BsArrowRight } from "react-icons/bs";
import Table from "components/Table/Table";
import { NFPA_CLASSIFICATION_COLS } from "./NFPAClassificationData";
import { APP_ROUTES } from "constants/AppRoutes";
import InformationBox from "components/common/Message/InformationBox/InformationBox";
import ConfirmationBox from "components/common/Message/ConfirmationBox/ConfirmationBox";
import { useAppSelector } from "redux/hooks";
import { useDispatch } from "react-redux";
import {
	getNFPAList,
	onAddNFPAClassification,
	onEditNFPAClassification,
	deleteNFPAClassification,
	checkNFPAInUse,
	setNFPAList,
} from "redux/pages/Manage/Lookups/NFPAClassification/NFPAClassificationSlice";
import { getCountryList } from "redux/pages/Administration/administrationSlice";

import EditNFPAClassification from "../NFPAClassification/ManageNFPAClassification/EditNFPAClassification";
import AddNFPAClassification from "../NFPAClassification/ManageNFPAClassification/AddNFPAClassification";
import NFPAClassificationReport from "pages/ManageLookups/NFPAClassification/Reports/NFPAClassificationReport";
import { OverlayPanel } from "primereact/overlaypanel";
import PopOver from "components/common/PopOver/PopOver";
import { onExportReport } from "redux/common/report/customizeReportSlice";

interface INFPAClassification {}
export const NFPAClassification: React.FC<INFPAClassification> = () => {
	const NFPAList = useAppSelector((state) => state.nfpaclassification.NFPAList);
	const NFPAPageDetails = useAppSelector((state) => state.nfpaclassification.NFPAPageDetails);
	const isNFPAInUse = useAppSelector((state) => state.nfpaclassification.isNFPAInUse);
	const countryList = useAppSelector((state) => state.administration.countryList);
	const rowsPerPage = useAppSelector((state) => state.personalSettings.rowsPerPage);
	const [pageDetails, setPageDetails] = useState<any>({});
	const [selectedCountry, setSelectedCountry] = useState("");
	const [selectedCountryId, setSelectedCountryId] = useState<any>("");
	const [selectedRows, setSelectedRows] = useState<any>([]);
	const [showEditModal, setShowEditModal] = useState(false);
	const [showAddModal, setShowAddModal] = useState<boolean>(false);
	const [showGrid, setShowGrid] = useState(false);
	const [message, setMessage] = useState("");
	const [displayInfoModal, setDisplayInfoModal] = useState(false);
	const [displayConfirmModal, setDisplayConfirmModal] = useState(false);
	const [pageIndex, setPageIndex] = useState(0);
	const [currentStart, setCurrentStart] = useState(1);
    const [sortField, setSortField] = useState('nfpaClassification');
    const [sortOrder, setSortOrder] = useState('ASC');
    const [keyWord, setKeyWord] = useState('');
	const [isDelete, setIsDelete] = useState(false);
	const [showReportModal, setShowReportModal] = useState(false);
	const [reportFlag, setReportFlag] = useState<any>('all');
	const dispatch = useDispatch<any>();
	const navigate = useNavigate();
	const downloadRef = React.createRef<OverlayPanel>();
	const [countryLookup, setCountryLookup] = useState<any>([]);

	useEffect(() => {
		dispatch(getCountryList());
	  }, [dispatch]);

	  useEffect(() => {
		if (countryList && countryList.countries.length > 0 && countryList.countries !== undefined) {
		  let country: any = [];
		  countryList.countries.map((countryItem: any, key: any) => {
			country.push({ "code": countryItem.countryId, "name": countryItem.countryName.trim() })
		  });
		  setSelectedCountry(country[0].name);
		  setSelectedCountryId(country[0].code);
		  setCountryLookup(country);
		}
	  }, [countryList]);

	  
	useEffect(() => {
		dispatch(setNFPAList([]))
		let body = {
			CountryName: selectedCountry,
		};
		if (selectedCountry) dispatch(getNFPAList(setShowGrid, 1, body, keyWord, sortField, sortOrder));
		if(countryLookup.length>0){
			countryLookup.map((item: any, key: any) => {
			  if (selectedCountry === item.name) {
				setSelectedCountryId(item.code);
			  }
			});
		  }
	}, [dispatch, selectedCountry, rowsPerPage]);

	useEffect(() => {
		if (NFPAPageDetails) {
			setPageDetails(NFPAPageDetails);
		}
	}, [NFPAPageDetails]);

	useEffect(() => {
		if (rowsPerPage && rowsPerPage > 0) {
			let modifiedPageDetails = { ...pageDetails, 'currentLength': rowsPerPage, 'pageCount': rowsPerPage };
			setPageDetails(modifiedPageDetails);
			setCurrentStart(1)
			setPageIndex(0)
		}
	}, [rowsPerPage])

	const onPageChanged = (options: any) => {
		if (options !== pageIndex) {
			let body = {
				CountryName: selectedCountry,
			};
			let currentStart = options * pageDetails?.currentLength + 1;
			setCurrentStart(currentStart)
			setPageIndex(options);
			let countrySelected: any = {};
			countrySelected = { countryName: selectedCountry };
			dispatch(getNFPAList(setShowGrid, currentStart, body, keyWord, sortField, sortOrder));
		}
	};


	const onGlobalSearch = (filter) => {
        setKeyWord(filter)
		let body = {
			CountryName: selectedCountry,
		};
		dispatch(getNFPAList(setShowGrid, 1, body, filter, sortField, sortOrder));
    }

    const onSort = (event) => {
        let sortDirection: string;
        if (event.sortOrder === 1)
        sortDirection = "ASC"
        else
        sortDirection = "DESC"
        setSortField(event.sortField)
        setSortOrder(sortDirection)
		let body = {
			CountryName: selectedCountry,
		};
		dispatch(getNFPAList(setShowGrid, currentStart, body, keyWord, event.sortField, sortDirection));
    }

	const editIconClick = () => {
		if (selectedRows.length > 1) {
			setMessage("This operation can be performed only one row at a time");
			setDisplayInfoModal(true);
		} else if (selectedRows.length < 1) {
			setMessage("Select a NFPA Classification");
			setDisplayInfoModal(true);
		} else
			setShowEditModal(true);
	};
	useEffect(() => {
		dispatch(getCountryList());
	}, [dispatch]);
	const handleRowSelect = useCallback((data: any) => {
		setSelectedRows([...data]);
	}, []);
	useEffect(() => {
		if (countryList) {
			countryList.countries.map((countryItem: any, key: any) => {
				let countryName = countryItem.countryName;

				if (selectedCountry === countryName.trim()) {
					setSelectedCountryId(countryItem.countryId);
				}
				return null;
			});
		}
	}, [countryList, selectedCountry]);
	const onAddNFPAClassificationClick = (submitData: any) => {
		dispatch(onAddNFPAClassification(submitData, selectedCountryId, setMessage, setDisplayInfoModal, setShowAddModal));
	};
	const onEditNFPAClassificationClick = (submitData: any) => {
		dispatch(
			onEditNFPAClassification(
				submitData,
				selectedCountryId,
				selectedRows[0].uniquenfpaClassificationId,
				setMessage,
				setDisplayInfoModal,
				setShowEditModal
			)
		);
	};
	const deleteNFPAClassificationClick = () => {
		if (selectedRows.length > 1) {
			setMessage("This operation can be performed only one row at a time");
			setDisplayInfoModal(true);
		} else if (selectedRows.length < 1) {
			setMessage("Select a NFPA Classification");
			setDisplayInfoModal(true);
		} else if (selectedRows.length === 1) {
			dispatch(checkNFPAInUse(selectedRows[0].uniquenfpaClassificationId))
			setIsDelete(true)
		}
	};

	useEffect(() => {
		if (isNFPAInUse && isDelete) {
		  if (isNFPAInUse.isNFPAClassificationInUse) {
			setMessage("NFPA Classification is in use, cannot be deleted");
			setDisplayInfoModal(true);
		  } else {
			setMessage("Are you sure you want to delete the selected NFPA Classification?");
			setDisplayConfirmModal(true);
		  }
		  setIsDelete(false)
		}
	  }, [isNFPAInUse])

	const OnConfirmClick = () => {
		setDisplayConfirmModal(false);
		if (selectedRows.length === 1) {
			const uniquenfpaClassificationId = selectedRows[0].uniquenfpaClassificationId;
			dispatch(
				deleteNFPAClassification({
					uniquenfpaClassificationId,
					setSelectedRows,
					setMessage,
					setDisplayInfoModal,
				})
			);
		}
	};
	const onCountryDropdownChange = (data: any) => {
		if (data.value) {
		  setSelectedCountry(data.value)
		  let countrySelected: any = {};
		  countrySelected = { countryName: data.value };
		  dispatch(getNFPAList(setShowGrid, currentStart, countrySelected, keyWord, sortField, sortOrder));
		}
		else {
		  setSelectedCountry(data.name)
		  let countrySelected: any = {};
		  countrySelected = { countryName: data.name };
		  dispatch(getNFPAList(setShowGrid, currentStart, countrySelected, keyWord,sortField, sortOrder));
		}
	  };

	const onReportAllClick = () => {
		setReportFlag('all');
		setShowReportModal(true);
	};

	const onReportHighlightedClick = () => {
		setReportFlag('highlighted');
		setShowReportModal(true);
	};

	const getSelectedClassificationId = () => {
        let params: any = [];
        selectedRows.map(item => {
            params.push(item.uniquenfpaClassificationId);
        });
        return params;
    };

	const onExportAll = () => {
		const reportParameters = {
			exportParameters: {
				uniqueNFPAClassificationId: "",
				sortColumn: sortField,
				sortOrder: sortOrder,
				search: keyWord,
				countryId: selectedCountryId
			},
			exportType: "NFPA Classification Grid Report"
		};
		dispatch(onExportReport(reportParameters, "NFPA Classification"));
	  };
	
	  const onExportHighlightedClick = () => {
		const reportParameters = {
			exportParameters: {
				uniqueNFPAClassificationId: getSelectedClassificationId(),
				sortColumn: sortField,
				sortOrder: sortOrder,
				search: keyWord,
				countryId: selectedCountryId
			},
			exportType: "NFPA Classification Grid Report"
		};
		dispatch(onExportReport(reportParameters, "NFPA Classification"));
	  };
	

	return (
		<div className="nfpa-classification d-flex flex-column">
			<section className="d-flex align-items-center">
				<Breadcrumb title="Lookups" classNames="report-header" handleGoBackClick={() => navigate(`/${APP_ROUTES.MANAGE_VIEW}`)} />
				<div className="breadcrumb-right-arrow ml-3 mr-3">
					<BsArrowRight className="icon" />
				</div>
				<div className="breadcrumb-sub-page-title">
					<span>NFPA Classifications</span>
				</div>
			</section>

			<section className="nfpa-table">
				{showGrid && (
					<Table
						rows={NFPAList}
						cols={NFPA_CLASSIFICATION_COLS.NFPA_COLS}
						paginator
						dataKeyId="uniquenfpaClassificationId"
						title={`NFPA Classifications`}
						searchText="Search"
						pageIndex={pageIndex}
						pageDetails={pageDetails}
						onPageChanged={onPageChanged}
						getSelectedCountry={(selectedItem) => {
							setSelectedCountry(selectedItem.countryName);
						}}
						getSelectedRowData={handleRowSelect}	
						showDownloadIcon={true}
						showSettingIcon={true}
						onGlobalSearch={onGlobalSearch}
                        onSortData={onSort}
						onAddClick={() => setShowAddModal(true)}
						showHamIcon={false}
						onEditClick={editIconClick}
						onDeleteClick={deleteNFPAClassificationClick}
						showroweditor={false}
						onDownloadClick={(e) => downloadRef.current?.toggle(e)}
						showCountryDropdown
						countryLookup= {countryLookup}
						onCountryDropdownChange = {onCountryDropdownChange}
					/>
				)}
			</section>

			{showEditModal && (
				<EditNFPAClassification
					showModal={showEditModal}
					setShowModal={setShowEditModal}
					Country={selectedCountry}
					CountryId={selectedCountryId}
					selectedRows={selectedRows}
					onEditNFPAClassification={(data) => {
						onEditNFPAClassificationClick(data);
					}}
				/>
			)}
			{showAddModal && (
				<AddNFPAClassification
					showModal={showAddModal}
					setShowModal={setShowAddModal}
					onAddNFPAClassification={(data) => {
						onAddNFPAClassificationClick(data);
					}}
					Country={selectedCountry}
				/>
			)}
			<InformationBox showInfoModal={displayInfoModal} setShowInfoModal={setDisplayInfoModal} message={message} />
			<ConfirmationBox
				showConfirmModal={displayConfirmModal}
				setShowConfirmModal={setDisplayConfirmModal}
				confirmAction={OnConfirmClick}
				message={message}
			/>
			<PopOver ref={downloadRef}>
				<ul>
					<li onClick={onReportAllClick}>Report All</li>
					<li onClick={onReportHighlightedClick}>Report Highlighted</li>
					<li onClick={onExportAll}>Export All</li>
					<li onClick={onExportHighlightedClick}>Export Highlighted</li>
				</ul>
			</PopOver>
			{showReportModal ? (
				<NFPAClassificationReport showModal={showReportModal}
					setShowModal={setShowReportModal}
					reportFlag={reportFlag}
					selectedRows={selectedRows}
					countryId={selectedCountryId}
					sortByColumns={NFPA_CLASSIFICATION_COLS.NFPA_COLS}
					searchKeyword={keyWord} />
			) : null}
		</div>
	);
};
export default NFPAClassification;
