import React from 'react';

interface Props { };

const PageNotFound: React.FC<Props> = () => {
    return (
        <div className='page-not-found-container'>
            Page Not Found
        </div>
    )
}

export default PageNotFound;