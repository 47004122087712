export const USER_ACTIVITY_REPORT_COLS = [
    {
        field: 'name',
        header: 'Name'
    },
    {
        field: 'email',
        header: 'Email'
    },
    {
        field: 'activity',
        header: 'Activity'
    },
    {
        field: 'accountStatus',
        header: 'Account Status'
    },
    {
        field: 'salesOfficeCount',
        header: 'Sales Office Count'
    },
    {
        field: 'firstLogin',
        header: 'First Login'
    },
    {
        field: 'lastLogin',
        header: 'Last Login'
    },
    {
        field: 'accountCreatedOn',
        header: 'Account Created On'
    },
    {
        field: 'createdMonth',
        header: 'Created Month'
    },
    {
        field: 'NumberOfDaysSinceLastLogin',
        header: 'No. of Days Since Last Login'
    },
    {
        field: 'deletedOn',
        header: 'Deleted on'
    },
    {
        field: 'todaysDate',
        header: 'Todays Date'
    },
    {
        field: 'daysSinceAccountCreated',
        header: 'Days Since Account Created'
    },
    {
        field: 'userRole',
        header: 'User Role'
    },
];