import React, { useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";

import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../redux/hooks";
import { getCountryList, setCountryList  } from "redux/pages/Administration/administrationSlice";
import { getZonesByCountryId, setZonesByCountryId } from "redux/pages/Manage/OrganizationSettings/zoneSlice";
import { getSalesOfficesByQuery, getSelectedSalesOffices, setSalesOfficesByQuery } from "redux/pages/Manage/OrganizationSettings/salesOfficeSlice";

import MultiSelect from "components/common/MultiSelect/MultiSelect";

import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { Dialog } from "primereact/dialog";

interface Props {
  showModal: boolean;
  setShowModal: (state: boolean) => void;
  setMessage: (state: string) => void;
  setDisplayInfoModal: (state: boolean) => void;
  selectedSalesOffices?: any;
  userSelected?: any;
  setSelectedSalesOffices?: (state : any) => void;
}

type FormData = {
  country: any;
  zone: any;
};

export const SelectSalesOffice: React.FC<Props> = ({
  showModal = false,
  setShowModal = () => {},
  setMessage = () => {},
  setDisplayInfoModal = () => {},
  selectedSalesOffices = [],
  userSelected = {},
  setSelectedSalesOffices = () => {},
}) => {

  const dispatch = useDispatch<any>();
  const [salesOfficesData, setSalesOfficesData] = useState<any>([]);
  const [countryLookup, setCountryLookup] = useState([{ countryName: "--Select--", countryId: 0 }]);
  const [zoneLookup, setZoneLookup] = useState([{ name: "--Select--", uniqueBusinessZoneId: 0 }]);

  const countryList = useAppSelector((state) =>  state.administration.countryList?.countries);
  const zoneList = useAppSelector((state) => state.zone.zonesByCountry);
  const salesOffices = useAppSelector((state) => state.salesOffice.salesOfficesByQuery);

  const selectedSite = useRef<any>();
  const checkedRows = useRef<any>();
  const disabledRows = useRef<any>();

  const addSalesOfficeForm: any = useRef();
  
  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue
  } = useForm<FormData>({
    defaultValues: {
      country: null,
      zone: null,
    },
  });

  useEffect(() => {
    dispatch(getCountryList());
    getZones(0);
    getSalesOffices();
  }, []);

  useEffect(() => {
    if (countryList && countryList?.length) {
      countryList?.map((item: any) => {
        return countryLookup.push({ countryName: item.countryName, countryId: item.countryId })
      });
      setValue("country", countryLookup[0].countryId)
      setCountryLookup(countryLookup)
      dispatch(setCountryList(""))
    }
  }, [countryList, setValue])

  useEffect(()=>{
    if(zoneList && zoneList?.length){
      zoneList?.map((item: any) => {    
         return zoneLookup.push({ name: item.name, uniqueBusinessZoneId: item.uniqueBusinessZoneId })
      });  
      setValue("zone",zoneLookup[0].uniqueBusinessZoneId)    
      setZoneLookup(zoneLookup)
      dispatch(setZonesByCountryId(""));
    }
  },[zoneList,setValue]);

  useEffect(() => {
    if (salesOffices && salesOffices.length) {
      let offices: any = [];
      salesOffices.map((item: any) => {
        return offices.push({
          id: item.uniqueSalesOfficeId,
          siteName: item.name,
          checked: (selectedSalesOffices.findIndex(element => element.uniqueSalesOfficeId === item.uniqueSalesOfficeId) !== -1) ? true : false,
          selected: (selectedSalesOffices.findIndex(element => element.uniqueSalesOfficeId === item.uniqueSalesOfficeId) !== -1) ? true : false,
          disabled: item.isDisabled ? true : false,
        });
      });
      selectedSalesOffices.map((item: any) => {
        if(offices && offices.length){
            let index = offices.findIndex(element => element.id === item.uniqueSalesOfficeId);
            if (index === -1) {
              return offices.unshift({
                id: item.uniqueSalesOfficeId,
                siteName: item.name,
                checked: true,
                selected: true,
                disabled: false,
              })
            }
          }
        });
        offices= [...offices].sort((prev, next) => next.siteName > prev.siteName? -1:1)
        setSalesOfficesData(offices);
        dispatch(setSalesOfficesByQuery(""));
    }
  }, [salesOffices]);

  const onCountryChange = (countryId: any)=>{
    getZones(countryId);
    setZoneLookup([{ name: "--Select--", uniqueBusinessZoneId: 0 }])
    setValue("zone",zoneLookup[0].uniqueBusinessZoneId)   
    getSalesOffices();
    
  }

  const getZones = (countryId: any) => {
    dispatch(getZonesByCountryId(countryId));
  }

  const onZoneChange = (zoneId: any) => {
    getSalesOffices();
  }

  const getSalesOffices = () => {
    let query = 'pagination=no';
    if (getValues('country')) {
      query += `&countryid=${getValues('country')}`;
    }
    if (getValues('zone')) {
      query += `&zoneid=${getValues('zone')}`;
    }
    dispatch(getSalesOfficesByQuery(query));
    setSalesOfficesData([])
  }

  const formSubmitHandler = (data: any) => {
    addSalesOfficeForm.current.requestSubmit();
  };

  const onAddFormSubmit = (data: any) => {  
    let offices: any = [];
    let allCheckedOffices = [...checkedRows.current, ...disabledRows.current]; 
    if (allCheckedOffices.length) {
      allCheckedOffices.map((item: any) => {
        offices.push({
          uniqueSalesOfficeId: item.id,
          name: item.siteName
        });
        return null;
      });
      setSelectedSalesOffices(offices);
      dispatch(getSelectedSalesOffices(offices));
      setMessage("Sales Office(s) updated");
			setDisplayInfoModal(true);
      setShowModal(false);
    } else {
      setMessage("At least one Sales Office should be selected");
			setDisplayInfoModal(true);
    }
  };

  const renderFooter = () => {
    return (
      <>
        <Button
          label="Cancel"
          onClick={() => setShowModal(false)}
          className="cancel btn"
        />
        <Button label="Save Changes" className="update btn" autoFocus onClick={formSubmitHandler} />
      </>
    );
  };

  return (
    <>
      <Dialog
        header="Update Sales Office "
        visible={showModal}
        style={{ width: "40vw" }}
        onHide={() => setShowModal(false)}
        footer={renderFooter()}
        className="display-settings personal selectsite"
      >
        <form
          onSubmit={handleSubmit(onAddFormSubmit)}
          ref={addSalesOfficeForm}
        >
          {(userSelected && userSelected.userName) &&
            <div className="row">
              <div className="col-12 md:col-2 mb-1 py-0">
              <div className="selectedUser">
                User: {userSelected.userName}
              </div> 
              </div>
            </div>          
          }
          <div className="row px-2">
            <div className="field col-12 md:col-2 mb-0 pb-0">
              <span className="p-float-label">
                <Controller
                  name="country"
                  control={control}
                  render={({ field }) => (
                    <Dropdown
                      inputId="country"
                      className={classNames("w-100")}
                      {...field}
                      value={field.value}
                      onChange={(e) => {field.onChange(e.value);onCountryChange(e.value) }}
                      options={countryLookup}
                      optionLabel="countryName"
                      optionValue="countryId"
                    />
                  )}
                />
                <label htmlFor="dropdown">
                  Country
                </label>
              </span>
            </div>
          </div>
          <div className="row px-2 pt-1">
            <div className="field col-12 md:col-2 mb-0 pb-0">
              <span className="p-float-label">
                <Controller
                  name="zone"
                  control={control}
                  render={({ field }) => (
                    <Dropdown
                      inputId="zone"
                      className={classNames("w-100")}
                      {...field}
                      value={field.value}
                      onChange={(e) => {field.onChange(e.value);onZoneChange(e.value) }}
                      options={zoneLookup}
                      optionLabel="name"
                      optionValue="uniqueBusinessZoneId"
                    />
                  )}
                />
                <label htmlFor="dropdown">
                  Zone
                </label>
              </span>
            </div>
          </div>
          <div className="row px-2 pt-1">
            <div className="field col-12 md:col-2 mb-0 py-0">
              {salesOfficesData &&
                <MultiSelect
                  singleSelect
                  list={salesOfficesData}
                  headerName = "Sales Office"
                  getCheckedItems={(checkedItems) => {
                    checkedRows.current = checkedItems;
                  }}
                  getSelectedItems={(selectedItems) => {
                    selectedSite.current = selectedItems;
                  }}
                  getDisabledItems={(disabledItems) => {
                    disabledRows.current = disabledItems;
                  }}
                />
              }
            </div>
          </div>
        </form>
      </Dialog>
    </>
  );
};

export default SelectSalesOffice;
