import React, { useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { Dialog } from "primereact/dialog";
import ReportViewer from "components/common/Report/ReportViewer"
import { useAppSelector } from "redux/hooks";
import { useDispatch } from "react-redux";
import { createReportPreviewSession, setReportPreviewSession } from "../../../../redux/pages/Administration/Site/siteSlice";


interface Props {
    showModal: boolean;
    setShowModal: (state: boolean) => void;
    reportFlag: string;
    selectedRows: any;
    uniqueInspectionTaskId: string;
    uniqueSystemId: string;
    uniqueDrawingId:string;
    sortByColumns: any;
    filterType:string;
    searchKeyword: string;
}

type FormData = {
    sortBy: string;
};

export const EquipmentSpecificEquipmentReport: React.FC<Props> = ({
    showModal = false,
    setShowModal = () => { },
    reportFlag = '',
    selectedRows = [],
    uniqueInspectionTaskId = "",
    uniqueSystemId = "",
    uniqueDrawingId = "",
    sortByColumns = [],
    filterType="",
    searchKeyword = ''
}) => {

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<FormData>({
        defaultValues: {
            sortBy: "",
        },
    });

    const [sortByLookup, setSortByLookup] = useState([{ field: "", header: "" }]);
    const [reportId, setReportId] = useState<any>(null);
    const [sortColumn, setSortColumn] = useState<any>("");
    const reportPreviewSession = useAppSelector((state) => state.site.reportPreviewSession);
    const dispatch = useDispatch<any>();

    useEffect(() => {
        if (sortByColumns?.length) {
            let sortBy: { field: string; header: string }[] = [];
            sortByColumns?.map((item: any) => {
                return sortBy.push({ field: item.field, header: item.header });
            });
            setSortByLookup(sortBy);
        }
    }, [sortByColumns]);

    const getSelectedIds = () => {
        let params: any = [];
        selectedRows.map(item => {
            params.push(item.EquipmentId);
        });
        return params;
    };

    useEffect(() => {
        let params;
        if (reportFlag === 'all') {
            params = "";
        } else {
            params = getSelectedIds();
        }
        const reportParameters = {
            reportParameters: {
                uniqueInspectionTaskId: uniqueInspectionTaskId,
                uniqueDrawingId:uniqueDrawingId,
                uniqueSystemId: uniqueSystemId,
                sortColumn: sortColumn,
                selectedIds: params,
                filterType:filterType,
                searchText: searchKeyword
            },
            reportType: "EquipmentSpecific Equipment Report"
        };
        dispatch(createReportPreviewSession(reportParameters));
    }, [sortColumn]);

    useEffect(() => {
        if (reportPreviewSession) {
            setReportId(reportPreviewSession.previewSessionId);
        }
    }, [reportPreviewSession])

    return (
        <>
            <Dialog
                header="Report"
                visible={showModal}
                onHide={() => {
                    dispatch(setReportPreviewSession(""));
                    setShowModal(false);
                }}
                className="display-settings report selectsite"
                style={{ width: "90vw", height: "600px" }}
            >
                <div className="report-export-main d-flex mb-3">
                    <div className="report-field">
                        <div className="row px-2">
                            <div className="field col-12 md:col-2 mb-0">
                                <span className="p-float-label">
                                    <Controller
                                        name="sortBy"
                                        control={control}
                                        render={({ field, fieldState }) => (
                                            <Dropdown
                                                inputId="dropdown"
                                                className={classNames("w-100 error-tooltip", {
                                                    error: fieldState.invalid,
                                                })}
                                                {...field}
                                                value={sortColumn}
                                                onChange={(e) => {
                                                    setSortColumn(e.value);
                                                }}
                                                options={sortByLookup}
                                                optionLabel="header"
                                                optionValue="header"
                                            />
                                        )}
                                    />
                                    <label htmlFor="dropdown">Sort by</label>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="report-buttons-main d-flex">
                    </div>
                </div>

                <ReportViewer reportId={reportId} />
            </Dialog>
        </>
    );
};

export default EquipmentSpecificEquipmentReport;
