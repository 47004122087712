import { Button } from "primereact/button";
import React from "react";
import { BsPersonCircle } from "react-icons/bs";

const PreferencesDocument = () => {
    return (
            <>
             <h4 className="doc-title mb-2">
                Preferences
             </h4>
             <p>Personal Settings, Feedback Send Mail and Logbook options are grouped under 
                <span className="doc-subtitle"> Preferences </span> 
                <Button className="doc-button-icon"><BsPersonCircle className="icon" title="Profile"/></Button> icon
             </p>
  

            </>
          );
}

export default PreferencesDocument;