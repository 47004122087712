import React from "react";

const DashboardDoument = () => {
    return (
        <>
            <h4 className="doc-title mb-2">
                Dashboard
            </h4>
            
            <p>Dashboard in Tech Advance<sup>+</sup> provides a summary of Fire and Safety Inspections as Charts along with the details of Active Tasks, Recently Closed Tasks, Recently Completed Tasks, list of Failed Equipment and Expired Contracts.</p>
            <p><span className="doc-subtitle">Tasks Completion – Last 12 Months (Top 10):-</span> This bar chart provides the information about the Total Tasks assigned, Completed Task and Open Task to the various Technicians. Top 10 Items will be displayed in the chart by default. Details of other technicians will be displayed on clicking the More link below the chart.</p>
            <p><span className="doc-subtitle">Inspection Performance Indicators:-</span> This Pie chart provides the summary of inspections completed or open, grouped by Devices and Tasks.  </p>
            <p>Count of Live Inspections and Technicians are also provided at the bottom of these charts.</p>
            <p><span className="doc-subtitle">Mix of Systems Inspected (Last 12 Months):-</span> This chart provides the summary of Inspections done during the last year for different Systems. It also provides a grid /Table view of the Inspections conducted for various Systems.</p>
            <p><span className="doc-subtitle">Active Inspection Tasks:-</span> Active Inspection Tasks tab list all the active Inspection Tasks across the Customers. The details like Report(s) created, Report(s) uploaded etc. will be listed under Active Inspection Tasks. Super Administrator, Support, Admin-Tech and Technician can directly add new Inspection Tasks from this Tab. Also they can close the Inspection Tasks also from the Dashboard. Clicking on Active / Closed Inspection Task will redirect the user to corresponding Inspection Task details page. Sold to Customer will be changed based on the selected Inspection Task.</p>
            <p><span className="doc-subtitle">Recently Closed:-</span> Inspection Tasks closed within 30 days will be listed under 'Recently Closed’ tab.</p>
            <p><span className="doc-subtitle">Recently Completed:-</span> Inspection Tasks completed within 30 days will be listed under 'Recently Completed’ tab.</p>
            <p><span className="doc-subtitle">Expired Contacts:-</span> This tab shows the list of Expired Contracts, which have active or inactive Tasks with the details namely Customer Name, Contract Name, Inspection Task Name, Task Status, Contract Expired Date, Inspection Start Date and Inspection End Date.  </p>
        </>
    );
}

export default DashboardDoument;