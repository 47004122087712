import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useForm, Controller } from "react-hook-form";
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';

import { BsExclamationCircleFill, BsPlusLg } from 'react-icons/bs';
import moment from 'moment';

//redux
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'redux/hooks';
import { getCustomerLookup, getSalesOfficeLookup, onSiteCustomerMapping } from 'redux/pages/Inspection/Contracts/contractSlice';
import { onAddCustomer, setNewCustomerData } from 'redux/pages/Administration/administrationSlice'
import InformationBox from 'components/common/Message/InformationBox/InformationBox';
import AddModal from 'components/Table/AddModal/AddModal';
import AddCustomer from 'pages/Customers/ManageCustomer/AddCustomer';
import {
    USAPattern,
    CanadaPattern,
} from "./ContractNumberPattern";

interface Props {
    selectedCustomerId?: any;
    showModal: boolean,
    setShowModal: (state: boolean) => void,
    onAddContractClick: (data: any) => void,
    isFromInspection?: boolean,
    isFromCopy?: boolean,
    siteId?: string;
}

type FormData = {
    uniqueCustomerId: string,
    contractNumber: string,
    contractName: string,
    startDate: any,
    endDate: any,
    notes: string,
    status: string,
    salesOffice: string
};

export const AddContract: React.FC<Props> = ({
    selectedCustomerId,
    showModal = false,
    setShowModal = () => { },
    onAddContractClick = () => { },
    isFromInspection = false,
    isFromCopy = false,
    siteId = null,
}) => {

    const {
        control,
        handleSubmit,
        getValues,
        setValue,
        formState: { errors },
        reset,
    } = useForm<FormData>({
        defaultValues: {
            uniqueCustomerId: undefined,
            contractNumber: '',
            contractName: '',
            startDate: null,
            endDate: null,
            notes: '',
            status: 'Active',
            salesOffice: ''
        }
    });
    const customerData = useAppSelector((state) => state.contract.customerData);
    const salesOfficeData = useAppSelector((state) => state.contract.salesOfficeData);
    const loggedInUserData = useAppSelector((state) => state.administration.loggedInUserDetails)
    const newCustomerData = useAppSelector((state) => state.administration.newCustomerData);
    const [displayInfoModal, setDisplayInfoModal] = useState(false);
    const [message, setMessage] = useState("");
    const [customerLookup, setCustomerLookup] = useState([{ name: "", code: "" }]);
    const [salesOfficeLookup, setSalesOfficeLookup] = useState([{ name: "", code: "" }]);
    const [countryId, setCountryId] = useState<any>(220)
    const [showAddCustomer, setShowAddCustomer] = useState<any>()
    const [showPatternModal, setShowPatternModal] = useState(false);
    const [pattern, setPattern] = useState<any>([]);
    const [contractNumberPattern, setContractNumberPattern] = useState<any>();
    const [isAddCustomer, setIsAddCustomer] = useState(false);
    const addForm: any = useRef();

    const dispatch = useDispatch<any>();  

    const setDate = useCallback(() => {
        let endDate = moment(new Date()).add(12, "M").format();
        setValue("startDate", new Date());
        setValue("endDate", new Date(endDate));
    }, [setValue])

    useEffect(() => {
        setDate();
        const body = {
            uniqueSiteId: siteId ? siteId : null
        }
        dispatch(getCustomerLookup(body));
        if (!isFromCopy) {
            dispatch(getSalesOfficeLookup());
        }
    }, [dispatch, setDate])

    useEffect(() => {
        if (loggedInUserData) {
            setCountryId(loggedInUserData.userDefaults?.countryId)
        }
    }, [loggedInUserData])

    useEffect(() => {
        if (customerData?.length) {
            let customer: { name: string, code: string }[] = [];
            customerData?.map((item: any) => {
                return customer.push({ name: item.customerName, code: item.uniqueCustomerId });
            })
            if (customer.length === 1)
                setValue("uniqueCustomerId", customer[0].code);
            setCustomerLookup(customer);
            if (selectedCustomerId)
                setValue("uniqueCustomerId", selectedCustomerId);
        }

    }, [customerData])

    useEffect(() => {
        if (salesOfficeData?.length) {
            let salesOffice: { name: string, code: string }[] = [];
            salesOfficeData?.map((item: any) => {
                return salesOffice.push({ name: item.salesOfficeName, code: item.uniqueSalesOfficeId });
            })
            setSalesOfficeLookup(salesOffice)
        }

    }, [salesOfficeData])
   
    useEffect(() => {
        if (newCustomerData && isAddCustomer) {
            let customer: any = [];
            let customerName = newCustomerData.customerName + " (" + newCustomerData.customerNumber + ")"
            customer.push({ name: customerName, code: newCustomerData.uniqueCustomerId })
            setCustomerLookup([...customerLookup, customer[0]])
            setValue("uniqueCustomerId", customer[0].code)
            setIsAddCustomer(false);
            let uniqueSiteIds :any = [];
            uniqueSiteIds.push(loggedInUserData?.userDefaults?.uniqueSiteId)
            
            dispatch(onSiteCustomerMapping(customer[0].code,uniqueSiteIds))
          
        }
    }, [newCustomerData, setValue])

    useEffect(() => {
        if (countryId === 220) {
            setPattern(USAPattern);
            setContractNumberPattern(/^([5]\d{9}|44OP[-]\d{6}|26\d{8}|44OC[-]\d{7})$/);
        } else {
            setPattern(CanadaPattern);
            setContractNumberPattern(/^(([4]\d{7})|((60|13)\d{5})|([a-zA-Z][-][0-9]{2}[-][a-zA-Z][-][0-9]{4})|([a-zA-Z][-]SI[-][0-9]{5}))$/);
        }
    }, [countryId]);

    const onAddFormSubmit = (data: any) => {
        if (new Date(data.startDate) > new Date(data.endDate)) {
            setDisplayInfoModal(true)
            setMessage("End Date should be greater than Start Date")
        } else {
            data.startDate = moment(data?.startDate).format("DD MMM YYYY").toString()
            data.endDate = moment(data?.endDate).format("DD MMM YYYY").toString()
            data.contractName = data.contractName?.trim();
            data.notes = data.notes?.trim();

            onAddContractClick && onAddContractClick(data)
        }
    };

    const formSubmitHandler = (data: any) => {
        addForm.current.requestSubmit();
    };

    const getFormErrorMessage = (name) => {
        return errors[name] && <span className="tooltip-text"><BsExclamationCircleFill />{errors[name].message}</span>
    };

    //handle form validation errors

    const onAddCustomerClick = (event) => {
        event?.preventDefault();
        setShowAddCustomer(true)
    }
    const onAddCustomerData = (submitData: any) => {
        setIsAddCustomer(true)
        dispatch(
            onAddCustomer(
                submitData,
                setMessage,
                setDisplayInfoModal,
                setShowAddCustomer,
                setShowAddCustomer,
                false
            ))
    }
    return (
        <div>
            <AddModal header='New Contract' showModal={showModal} setShowModal={setShowModal} OnSaveClick={formSubmitHandler} isAdd={true} style={{ width: '35vw', maxHeight: '95%' }}>
                <form onSubmit={handleSubmit(onAddFormSubmit)} ref={addForm}>
                    <Dialog
                        header="Contract Number"
                        visible={showPatternModal}
                        onHide={() => setShowPatternModal(false)}
                        className='pattern-overlay'
                        style={{ width: '35vw' }}
                    >
                        <DataTable showGridlines={false} value={pattern}>
                            <Column field="pattern" header="Pattern" />
                            <Column field="format" header="Format" />
                            <Column field="description" header="Description" />
                        </DataTable>
                    </Dialog>
                    <div className="row px-2 pt-2 d-flex align-items-center">
                        <div className="field col-11 md:col-11 mb-0">
                            <span className="p-float-label">
                                <Controller name="uniqueCustomerId" control={control} rules={{ required: 'Customer is required.' }} render={({ field, fieldState }) => (
                                    <Dropdown disabled={selectedCustomerId} inputId="dropdown" className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })}  {...field} value={field.value} onChange={(e) => field.onChange(e.value)} options={customerLookup} optionValue="code" optionLabel="name" />
                                )} />
                                <label className='mandatory' htmlFor="dropdown">Customer</label>
                                {getFormErrorMessage('uniqueCustomerId')}
                            </span>
                        </div>
                        {!isFromInspection && (<div className="field col-1 md:col-1 p-0">
                            <span className="p-float-label action-btns center">
                                <Button className='button-icon' onClick={onAddCustomerClick} disabled={selectedCustomerId}>
                                    <BsPlusLg className='icon' />
                                </Button>
                            </span>
                        </div>)}
                    </div>
                    <div className="field col-12 md:col-4 pt-0">
                        <span className="pattern-view d-flex justify-content-end pb-1" role='button' onClick={() => setShowPatternModal(true)}>View Pattern</span>
                        <span className="p-float-label">
                            <Controller name="contractNumber" control={control} rules={{
                                required: 'Contract Number is required.',
                                pattern: { value: contractNumberPattern, message: "Enter a valid Contract Number" }

                            }} render={({ field, fieldState }) => (
                                <InputText id="inputtext" className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })}  {...field} />
                            )} />
                            <label className='mandatory' htmlFor="inputtext">Contract Number</label>
                            {getFormErrorMessage('contractNumber')}
                        </span>
                    </div>
                    <div className="field col-12 md:col-4 mb-0">
                        <span className="p-float-label">
                            <Controller name="contractName" control={control} rules={{
                                required: 'Contract Name is required.', validate: (value) => {
                                    return value.trim().length <= 0
                                        ? "Contract Name is required." : true
                                },
                            }} render={({ field, fieldState }) => (
                                <InputText id="inputtext" maxLength={100} className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })}  {...field} />
                            )} />
                            <label className='mandatory' htmlFor="inputtext">Contract Name</label>
                            {getFormErrorMessage('contractName')}
                        </span>
                    </div>

                    <div className="field col-12 md:col-4">
                        <span className="p-float-label">
                            <Controller name="startDate" control={control} rules={{


                            }} render={({ field, fieldState }) => (
                                <Calendar id="calendar" className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })} dateFormat="yy-mm-dd"  {...field} value={field.value} onChange={(e) => field.onChange(e.value)} />
                            )} />
                            <label htmlFor="calendar">Start Date</label>
                            {getFormErrorMessage('startDate')}
                        </span>
                    </div>

                    <div className="field col-12 md:col-4">
                        <span className="p-float-label">
                            <Controller name="endDate" control={control} rules={{
                                validate: () => {
                                    return getValues("endDate") <= new Date() ? "End Date should be a future date" : undefined;
                                }

                            }} render={({ field, fieldState }) => (
                                <Calendar id="calendar" className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })} dateFormat="yy-mm-dd"  {...field} value={field.value} onChange={(e) => field.onChange(e.value)} />
                            )} />
                            <label htmlFor="calendar">End Date</label>
                            {getFormErrorMessage('endDate')}
                        </span>
                    </div>


                    <div className="field col-12 md:col-4 mb-0">
                        <span className="p-float-label">
                            <Controller name="notes" control={control} rules={{

                            }} render={({ field, fieldState }) => (
                                <InputTextarea id="Textarea" className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })} maxLength={4000} {...field} />
                            )} />
                            <label htmlFor="inputtext">Notes</label>
                            {getFormErrorMessage('notes')}
                        </span>
                        <span className='textarea-txt-count'>Max 4000 Chars</span>
                    </div>

                    <div className="field col-12 md:col-4">
                        <span className="p-float-label">
                            <Controller name="salesOffice" control={control} rules={{ required: 'Sales Office is required.' }} render={({ field, fieldState }) => (
                                <Dropdown inputId="dropdown" appendTo="self" className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })}  {...field} value={field.value} onChange={(e) => field.onChange(e.value)} options={salesOfficeLookup} optionLabel="name" optionValue="name" />
                            )} />
                            <label className='mandatory' htmlFor="dropdown">Sales Office</label>
                            {getFormErrorMessage('salesOffice')}
                        </span>
                    </div>

                </form>
            </AddModal>
            {showAddCustomer && (<AddCustomer showModal={showAddCustomer} setShowModal={setShowAddCustomer} isAddContract={true} userCountryId={countryId} onAddCustomerClick={(data) => onAddCustomerData(data)} />)}
            <InformationBox
                showInfoModal={displayInfoModal}
                setShowInfoModal={setDisplayInfoModal}
                message={message}
            />
        </div>
    );
}

export default AddContract;