import React, { useCallback, useState } from "react";

import { Dialog } from "primereact/dialog";
import { OverlayPanel } from "primereact/overlaypanel";

import Table from "components/Table/Table";
import { TASK_LIST_COLS } from "./ColumnData";
import PopOver from "components/common/PopOver/PopOver";
import CompareInspectionTaskReport from "./Report/CompareInspectionTaskReport";
import { Overlay, Tooltip } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { onExportReport } from "redux/common/report/customizeReportSlice";

interface Props {
  showModal: boolean;
  setShowModal: (state: boolean) => void;
  tableData: any;
  pageIndex: any;
  onPageChanged: (state: any, data: any) => void;
  onSort: (data: any) => void;
  inspectionTasksSelected: any;
}

export const CompareInspectionTasks: React.FC<Props> = ({
  showModal = false,
  setShowModal = () => { },
  tableData = {},
  pageIndex = 0,
  onPageChanged = () => {},
  onSort = () => {},
  inspectionTasksSelected = []
}) => {

  const downloadRef = React.createRef<OverlayPanel>();

  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [showReportModal, setShowReportModal] = useState(false);
  const [reportFlag, setReportFlag] = useState<any>('all');
  const [sortField, setSortField] = useState("");
	const [sortOrder, setSortOrder] = useState("");
  const [target, setTarget] = useState(null);
  const [rowNumber, setRowNumber] = useState("");
  const [field, SetField] = useState("");
  const dispatch = useDispatch<any>();
  
  const handleRowSelect = useCallback((data: any) => {
    setSelectedRows([...data]);
  }, []);

  const onPageChange = (options: any) => {
    let sortData: any = {
      sortDirection: sortOrder,
      sortField: sortField
    };
    onPageChanged(options, sortData);
  }

  const onColumnSort = (event) => {
    if (event && event.sortField) {
      let sortData = {
        sortDirection: event.sortOrder === 1 ? "ASC" : "DESC",
        sortField: event.sortField
      }
      setSortField(sortData.sortField);
      setSortOrder(sortData.sortDirection);
      onSort(sortData);
    }
  }

  const onReportAllClick = () => {
    setReportFlag('all');
    setShowReportModal(true);
  };

  const onReportHighlightedClick = () => {
    setReportFlag('highlighted');
    setShowReportModal(true);
  };

    const onShowToolTip = (e, indexData, options) => {
      if (options?.field === "location" ) {
        setTarget(e.currentTarget);
        setRowNumber(indexData?.RowNumber);
        SetField(indexData?.[options?.field])
      }
    };
  
    const onHideToolTip = () => {
      setTarget(null);
      setRowNumber("");
      SetField("");
    };

    const getSelectedIds = () => {
      let params: any = [];
      selectedRows.map(item => {
          params.push(item.equipmentId);
      });
      return params;
  };

    const onExportAll = () => {
      var fileName=(inspectionTasksSelected[0]?.inspectionTaskName)+' ['+(inspectionTasksSelected[1]?.inspectionTaskName)+']';
      const reportParameters = {
        exportParameters: {
          oldUniqueInspectionTaskId: inspectionTasksSelected[0]?.uniqueInspectionTaskId,
                newUniqueInspectionTaskId: inspectionTasksSelected[1]?.uniqueInspectionTaskId,
                sortColumn:sortField,
                sortOrder:sortOrder,
                oldInspectionTaskName: inspectionTasksSelected[0]?.inspectionTaskName,
                newInspectionTaskName: inspectionTasksSelected[1]?.inspectionTaskName,
                selectedIds: "",
                fileName:fileName
        },
        exportType: "Inspection Compare Report"
      };
      dispatch(onExportReport(reportParameters,fileName));
    };

    const onExportHighlightedClick = () => {
      var fileName=(inspectionTasksSelected[0]?.inspectionTaskName)+' ['+(inspectionTasksSelected[1]?.inspectionTaskName)+']';
      const reportParameters = {
        exportParameters: {
          oldUniqueInspectionTaskId: inspectionTasksSelected[0]?.uniqueInspectionTaskId,
                newUniqueInspectionTaskId: inspectionTasksSelected[1]?.uniqueInspectionTaskId,
                sortColumn:sortField,
                sortOrder:sortOrder,
                oldInspectionTaskName: inspectionTasksSelected[0]?.inspectionTaskName,
                newInspectionTaskName: inspectionTasksSelected[1]?.inspectionTaskName,
                selectedIds: getSelectedIds(),
                fileName:fileName
        },
        exportType: "Inspection Compare Report"
      };
      dispatch(onExportReport(reportParameters,fileName));
    };

  const bodyTemplate = (rowData, options) => {
    
    return (
      <div className="table-col">
        {options?.field == "location" ? (
          <>
            <Overlay show={rowNumber == rowData?.RowNumber} key={rowData.RowNumber} placement="left" target={target}>
              <Tooltip id={`tooltip${rowData?.RowNumber}`} className="data-import-tooltip">
                <div id="tooltip-description">
                  <span dangerouslySetInnerHTML={{ __html: `${field}` }} /></div>

              </Tooltip>
            </Overlay>
            <span
              onMouseEnter={(e: any) => onShowToolTip(e, rowData, options)}
              onMouseLeave={() => onHideToolTip()}
              className="table-col"
            >
              <span className="table-col" dangerouslySetInnerHTML={{ __html: `${rowData?.[options?.field]}` }} />
            </span>
          </>
        ) : (
          <div >
            <span className="table-col" dangerouslySetInnerHTML={{ __html: `${rowData?.[options?.field]}` }} />
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <Dialog
        header="Comparison Report"
        visible={showModal}
        style={{ width: "70vw" }}
        onHide={() => setShowModal(false)}
        className="display-settings personal selectsite compare-inspection"
      >
        <div className="content-box">
          {(tableData && tableData.inspectionTaskName) && 
            <div className="header-text">
              <span className="label">Inspection Task: 
              </span>
              <span className="value fw-700" dangerouslySetInnerHTML={{__html: `${tableData.inspectionTaskName}`}} />
            </div>          
          }
          <div className="table-wrap">
            <Table
              rows={tableData?.equipmentDetails}
              cols={TASK_LIST_COLS.LIST_COLS}
              dataKeyId="equipmentId"
              title={``}
              paginator
              pageDetails={tableData?.pageDetails}
              pageIndex={pageIndex}
              onPageChanged={onPageChange}
              getSelectedRowData={handleRowSelect}
              showroweditor={false}
              searchboxfield={false}
              showAddIcon={false}
              showEditIcon={false}
              showDeleteIcon={false}
              showDownloadIcon={true}
              showSettingIcon={false}
              onDownloadClick={(e) => downloadRef.current?.toggle(e)}
              isTemplateMode={true}
              onSortData={onColumnSort}
              templateFromParent={bodyTemplate}
              isTemplateFromParent={true}
            />
          </div>
        </div>
      </Dialog>

      <PopOver ref={downloadRef}>
        <ul>
          <li onClick={onReportAllClick}>Report All</li>
          <li onClick={onReportHighlightedClick}>Report Highlighted</li>
          <li onClick={onExportAll}>Export All</li>
          <li onClick={onExportHighlightedClick}>Export Highlighted</li>
        </ul>
      </PopOver>

      {showReportModal ? (
        <CompareInspectionTaskReport showModal={showReportModal}
          setShowModal={setShowReportModal}
          reportFlag={reportFlag}
          selectedRows={selectedRows}
          sortByColumns={TASK_LIST_COLS.LIST_COLS} 
          inspectionTasksSelected={inspectionTasksSelected}
          />
      ) : null}
    </>
  );
};

export default CompareInspectionTasks;