import { Button } from 'primereact/button'
import React from 'react'
import { BsFillPencilFill, BsFunnelFill, BsPaperclip, BsPlusLg, BsTrashFill } from 'react-icons/bs'
import { FaCog, FaCopy, FaDownload, FaFilter } from 'react-icons/fa'

const InspectionTaskInspectionDetailsDocument = () => {
  return (
    <>
    <h4 className="doc-title mb-2">Inspection Details</h4>

    <p>
       This option allows the user to enter/view the inspection details of an Inspection Task. 
       The user can enter and save the details related to Contract, System, Equipment, Questions 
       (including data of all nodes of the Panel) using this option and Summary. User cannot add 
       or edit any inspection details if the selected Inspection Task is in 'Closed' or 'Inactive' 
       or 'Completed' status.
    </p>

    <p>
       Names of the selected Inspection Task, Contract and System will be displayed as read only 
       on the top of the page in each session. 
    </p>

    <p>
       Inspection Details page is provided with different Tabs namely Contract, System, 
       Equipment,Questions and Summary. The user can select the required tab and enter/modify 
       the details. 'Report Selected' link is provided on the top right side of the Inspection 
       Details page so that the user can view/update the Reports selected for the Inspection Task. 
       The number of reports selected for the Inspection Task will be shown against the link. Only 
       the fields which are required for the Reports selected for the Inspection Task will be 
       visible in the Inspection Details.
    </p>

    <p>
       By default, Equipment tab will be selected on selecting the Inspection Details option.
    </p>

    <p><span className="doc-subtitle"><b>Contract:</b></span></p>
    <p>
       On selecting Contract tab, the Contract page will be displayed listing the options to 
       enter the details of Customer Information, Branch Office and Service Organization. User 
       can modify the data if required and click Save button to save the details. If the Inspection 
       task is Closed/Inactive/Completed, the details cannot be edited and the Save button will not 
       be available.
    </p>

    <p><span className="doc-subtitle"><b>System:</b></span></p>
    <p>
       On selecting System tab, the System page will be displayed with the selected System Name 
       and Panel Type displayed as read only and options to enter the details like Type(s) of System, 
       Type/Features of Mass Notification System, Mass Notification Device Numbers, Pre Discharge 
       Notification/Override and other fields related to the System. The user can enter the required 
       details and click Save button to save the details.  If the Inspection task is closed/Inactive/
       Completed, the details cannot be edited and the Save button will not be available.
    </p>


    <p><span className="doc-subtitle"><b>Equipment:</b></span></p>
    <p>
       On selecting Equipment tab, the Equipment List page will be displayed with a Equipment 
       Group drop down loaded with Panel names of the selected System. For MXL/XLS/Desigo 
       FS20/Desigo FV20/Desigo Modular panels, all Panels parsed from the XML file will be listed 
       in the Equipment Group combo.
    </p>

    <p>
    <span className="doc-subtitle">Add </span> 
    <Button className="doc-button-icon"> <BsPlusLg className="icon" title="Add"/> </Button> and 
    <span className="doc-subtitle"> Edit </span> 
    <Button className="doc-button-icon"><BsFillPencilFill className="icon" title="Edit" /></Button> icons 
    are displayed against the Equipment Group drop down using which the user can add a new panel 
    if required and Edit the Address or Panel Name. 
     The Address/Name of the Panels added manually can be edited whereas the Address of the 
     Panels added through XML Parsing cannot be edited. 
    </p>

    <p>
       On selecting Equipment tab, for MXL/XLS/Desigo FS20/Desigo FV20/Desigo Modular panels, 
       the Equipment page will be displayed with a Equipment Group drop down loaded with Panel 
       Address and name of the selected Panel. First Panel will be selected by default if available. 
       An 'Equipment Type' drop down is provided  with option 'All' and all the Equipment Types such 
       as AHU, Devices, Doors, Kitchen Extinguishers, Fire Pumps, Modules, NAC Panels, Sprinklers 
       etc. By default, 'Devices' will be selected in the drop down.
    </p>

    <p>
       If 'All' option is selected in Equipment Type dropdown, then the options available to the 
       Users are Reset, Delete, Attachments and Import Equipment. If options other than 'All' 
       option is selected in Equipment Type drop down, Users can do the operations like 
       Add/Edit/Clone/Delete/Reset/Connectivity/Attachments/Import Equipment if required against the 
       Inspection Task. On selecting the panel, the equipment details will be loaded automatically.
    </p>

    <p>
       If 'Devices' option is selected in Equipment Type drop down, Users can Add/Edit/Clone/
       Delete Equipment. Linking of added Equipment to a Panel/Module can be done manually 
       sing Connectivity option after adding Equipment for devices and providing Attachments. 
       Multiple Devices from an Excel can be imported using the option Import Equipment. 
       The options Add/Edit/Clone/Delete/Attachments /Import Equipment are available to AHU, 
       Air Sampling Systems, Doors, Elevators, Emergency Communications, Emergency Lightings, 
       ERCES, Fire Pumps, Gaseous Extinguishing Systems, Kitchen and Dry Chemical Ext Systems, 
       Modules, NAC Panels, Portable Extinguishers, Sprinklers and Water Based Equipments options.
    </p>

    <p><span className="mb-2"><b>Filter:</b></span></p>
    <p>
       Users can search for Equipment by clicking the <span className="doc-subtitle">Filter </span> 
       <Button className="doc-button-icon"><FaFilter className="icon" title="Filter"/></Button> icon after entering the 
       Test Date, and other criteria in the Filter window. Equipment satisfying the Filter criteria 
       will be listed in the data grid and <span className="doc-subtitle">Filter </span> 
       <Button className="doc-button-icon"><FaFilter className="icon" title="Filter"/></Button> icon 
       changed to <span className="doc-subtitle">Change Filter </span> 
       <Button className="doc-button-icon"><FaFilter className="icon" title="Change Filter"/></Button> icon. 
       Users can clear the search criteria by clicking <Button className="doc-button-icon"><FaFilter className="icon" title="Filter"/></Button> icon and 
       then clicking the <span className="doc-subtitle">Clear </span> button in the Filter window. 
    </p>
    <p>
       Filter window is provided with fields - Test Date from and to, a drop down to 
       select the Attributes and a textbox to enter the attribute value for filter and checkboxes 
       - Show only untested, Attachments only, XML imported and Manually added, with 
       <span className="doc-subtitle"> Go </span> and <span className="doc-subtitle">Clear</span> buttons. 
       Users can select "Show only untested" check box to view the untested equipment. The 
       "Attachments Only", "XML Imported" and "Manually Added" check boxes are also provided in the 
       Filter window, to short list the equipment accordingly. The "Attachments Only" check box is 
       used to list only that Equipment that has Attachments added to it. The "XML Imported" 
       checkbox is used to list the Equipment that is uploaded via XML import after running TAP 
       Bridge. Data will be listed here only after running TAP Bridge. The "Manually Added" 
       check box is used to list that Equipment that is added through the options Import Equipment 
       and Add Equipment. Initially both "XML imported" and "Manually added" check boxes will be 
       displayed as selected by default in the Filter window.
    </p>
    <p>
       After filtering the Equipment List, the <span className="doc-subtitle">Filter </span> 
       <Button className="doc-button-icon"><FaFilter className="icon" title="Filter"/></Button> icon 
       changed to <span className="doc-subtitle">Change Filter </span> 
       <Button className="doc-button-icon"><BsFunnelFill className="icon" title="Change Filter"/></Button> icon. 
       User can change the Filter criteria by clicking the <span className="doc-subtitle">Change 
       Filter </span> <Button className="doc-button-icon"><BsFunnelFill className="icon" title="Change Filter"/></Button> icon.
    </p>

    <p><span className="mb-2"><b>Advanced Search:</b></span></p>

    <p>
       This option allows users to search for Equipment details by building a query for the 
       selection criteria. The query can be built, specifying conditions one by one, and then 
       grouping them as required. Each condition is built using the Condition Builder, specifying 
       a field, an operator (like Equal to, Not Equal to etc.) and a value.  Multiple conditions 
       can be added using AND or OR operators. User can edit or delete or group/ungroup conditions 
       as required. After specifying the conditions for the query, click on the 
       <span className="doc-subtitle"> Search</span> button to view the search results in a data grid. 
       All the options in the data grid are available in the search results grid. 
    </p>

    
    <p><span className="doc-subtitle">Add: </span></p>
    <p>
       Create each condition by selecting a field, the operator (such as Equal to, Not Equal to, 
       Less than, Greater than, Contains, Is blank and Is not blank etc.), and the value. On 
       clicking the <span className="doc-subtitle">Add</span> button, the newly framed condition in 
       the condition builder will be added to the conditions list box. Multiple conditions can be 
       added using AND or OR operators.
    </p>

    <p>
    <span className="doc-subtitle">Edit :</span></p>
    <p>
       Select the desired condition to edit. On clicking the <span className="doc-subtitle">Edit </span> 
       button, the Field Name, Operator, Value and the AND or OR condition is loaded in the 
       Condition Builder. User can change the details as required and on clicking the 
       <span className="doc-subtitle"> Update</span> button in the Condition Builder, the selected 
       condition is updated. It is possible to edit only one condition at a time.
    </p>

    <p>
    <span className="doc-subtitle">Delete :</span></p>
    <p>
      Select the desired condition to delete. On clicking the <span className="doc-subtitle">Delete
      </span> button, the selected condition is deleted along with any associated AND or OR 
      operator. It is possible to delete only one condition at a time. If the selected condition 
      is part of a group, it has to be ungrouped first.
    </p>

    <p>
    <span className="doc-subtitle">Group :</span></p>
    <p>
       Select the desired conditions by clicking the start and end points holding down the Shift 
       key. Click the <span className="doc-subtitle">Group</span> button and the selected query items 
       are grouped by adding braces at the start and end of the group. Multiple groups can be 
       created and multiple subgroups can also be grouped together. If multiple rows are selected 
       from the conditions list and if the selection is not a valid group, it will not be possible 
       to group the selection.
    </p>

    <p>
    <span className="doc-subtitle">Ungroup :</span></p>
    <p>
       Ungroup a group of query items by selecting the start and end rows of the group and clicking 
       the <span className="doc-subtitle">Ungroup</span> Button. If the selected items are not of a 
       valid group it will not be possible to ungroup the same.
    </p>

    <p>
    <span className="doc-subtitle">Search :</span></p>
    <p>
      After building the conditions for the query, click on the <span className="doc-subtitle">Search
      </span> button to view the search results in the data grid. When a search is done, 
      the search criteria will be saved and available for subsequent searches in the current 
      session until another search is done with a new criteria.
    </p>

    <p>
    <span className="doc-subtitle">Cancel :</span></p>
    <p> 
       <span className="doc-subtitle">Cancel</span> button is used to close the Advanced Search window.
    </p>

    <p><span className="mb-2"><b>Add:</b></span></p>
    <p>
       Equipment can be added by clicking <span className="doc-subtitle">Add </span> 
       <Button className="doc-button-icon"> <BsPlusLg className="icon" title="Add"/> </Button> icon. User can enter the 
       Address, NFPA Classification, Health Classification etc., and click Add button.  When a 
       device is added, a drop down to select the Module is also provided in the Equipment data 
       window to connect it to a Module.
    </p>

    <p><span className="mb-2"><b>Edit:</b></span></p>
    <p>
       Edit an Equipment by selecting it and clicking the <span className="doc-subtitle"> 
       Edit </span> <Button className="doc-button-icon"><BsFillPencilFill className="icon" title="Edit"/></Button> icon. 
       Make required modifications and click Save button to save the changes. The Address of 
       the Equipment added manually and through Import Equipment option can be edited whereas 
       the Address of the Equipment added through TAP Bridge cannot be edited. Equipment can also 
       be edited by double clicking the required row.
    </p>

    <p>
       <i>Note:</i> 'Next' and 'Previous' buttons are provided in the Equipment Edit and Update 
       Status window for the quick update of Equipment.
    </p>

    <p>
       Multiple records can also be edited by selecting the required Equipment using the Shift or 
       Ctrl keys, and then clicking on the <span className="doc-subtitle"> Edit </span> 
       <Button className="doc-button-icon"><BsFillPencilFill className="icon" title="Edit"/></Button> icon. A window will be displayed 
       listing the fields that can be edited. The changes have to be made selecting one field at a time. 
    </p>

    <p><span className="mb-2"><b>Clone:</b></span></p>
    <p>Clone an Equipment by selecting it and clicking the 
      <span className="doc-subtitle"> Clone </span> 
      <Button className="doc-button-icon"><FaCopy className="icon" title="Clone"/></Button> icon. All the attributes except 
      Barcode and address of the selected Equipment will be copied for the new Equipment. 
      Make required modifications and click Save button to save the changes. Equipment added by 
      TAP Bridge cannot be cloned. 
    </p>
    <p>Note: Equipment imported via XML Parsing / TAP Bridge cannot be cloned</p>

    <p><span className="mb-2"><b>Delete:</b></span></p>
    <p>
      Delete an Equipment by selecting it and clicking the <span className="doc-subtitle">Delete </span> 
      <Button className="doc-button-icon"><BsTrashFill className="icon" title="Delete"/></Button> icon. 
      Multiple records can also be deleted by selecting the required Equipment using the Shift or 
      Ctrl keys, and then clicking on the <span className="doc-subtitle">Delete </span> 
      <Button className="doc-button-icon"><BsTrashFill className="icon" title="Delete"/></Button> icon.</p>



    <p><span className="mb-2"><b>Attachments: </b></span>
    <Button className="doc-button-icon"><BsPaperclip className="icon" title="Attachment"/></Button>
    </p>
    <p> 
       Users can add/edit/delete/view/download attachments linked to each Equipment, 
       using this option. Select Equipment and then select the Attachments icon. Now Attachment 
       list window will be displayed with Add/Edit/Delete/Download and More icons. If there is any 
       file attached to that Equipment that will be displayed there. 
    </p>

    <p><span className="doc-subtitle">Add: </span></p>
    <p>
       An attachment can be added by clicking the <span className="doc-subtitle">Add </span>
       <Button className="doc-button-icon"> <BsPlusLg className="icon" title="Add"/> </Button> icon. Browse for the required 
       file. The attachment will be uploaded on selecting Upload button.    
    </p>

    <p><span className="doc-subtitle">Edit: </span></p>
    <p>
       Edit an Attachment by selecting it and clicking the <span className="doc-subtitle">Edit </span> 
       <Button className="doc-button-icon"><BsFillPencilFill className="icon" title="Edit" /></Button> icon. 
    </p>

    <p>
       Select an attachment and click <span className="doc-subtitle">Edit </span> 
       <Button className="doc-button-icon"><BsFillPencilFill className="icon" title="Edit" /></Button> icon. The Edit Attachment 
       window will be displayed with the current file name. The user can replace the selected 
       attachment with a new file if necessary. 
    </p>

    <p><span className="doc-subtitle">Delete: </span></p>
    <p>
       Delete an attachment by selecting the required file and clicking the 
       <span className="doc-subtitle"> Delete </span>
       <Button className="doc-button-icon"><BsTrashFill className="icon" title="Delete"/></Button> icon. 
    </p>
    <p>
       <i>Note:</i> Edit and Delete options are also provided as Quick action icons{" "} 
       <Button className="doc-button-icon"><BsFillPencilFill className="icon" title="Edit" /></Button> 
       {" "}<Button className="doc-button-icon"><BsTrashFill className="icon" title="Delete"/></Button> , which are 
       displayed when the mouse points to or moves over the required row 
    </p>

    <p><span className="doc-subtitle">View: </span></p>
    <p>
       The attachment can be viewed by selecting the file and clicking the 
       <span className="doc-subtitle"> View </span> option from <span className="doc-subtitle">More </span>
       <Button className="doc-button-icon"><FaCog className="icon" title="More"/></Button> menu. 
    </p>
    <p>
       Note:<i>Only image files can be viewed through attachment view option. Other files 
        will be downloaded to user's computer.</i>
    </p>
    <p>
       Equipment attachments can be viewed by double clicking on the Equipment symbol in 
       the Drawing. If multiple attachments are present, user can click the forward and backward 
       arrow buttons at the bottom left and right corners in the Attachment viewer to view the 
       attachments. 
    </p>	

    <p><span className="doc-subtitle">Download: </span></p>
    <p>
       Attachment can be downloaded and saved in the user's computer through
       <span className="doc-subtitle"> Download</span> option from 
       <span className="doc-subtitle"> Download </span>
       <Button className="doc-button-icon"><FaDownload className="icon" title="Download" /></Button> menu. 
    </p>

    <p>
      <span className="mb-2"><b>Download: </b></span>
      <Button className="doc-button-icon"><FaDownload className="icon" title="Download" /></Button>
    </p>
    <p>
       This option lists Export All, Export Highlighted and Group Highlighted. 
    </p>

    <p>
    <span className="doc-subtitle">Report All: </span>
    </p>

    <p>
       This option is for generating a report with all the records in the data grid. 
       The report can be saved to PDF, Text, Rich Text or Excel format by selecting the file 
       format from the list under Save option, and then clicking OK button after selecting the 
       required options from the Export Settings window.
    </p>

    <p>
    <span className="doc-subtitle">Report Highlighted: </span>
    </p>
    <p>
       This option is for generating a report with only selected records in the grid. 
       Select the desired rows by holding down the control key and clicking on each of 
       the records to be selected. Users can also select a set of rows using the shift key. 
       The selected records will be highlighted. Click on the 
       <span className="doc-subtitle"> Report Highlighted </span> option in the 
       <span className="doc-subtitle"> Download </span> 
       <Button className="doc-button-icon"><FaDownload className="icon" title="Download" /></Button> menu to 
       generate the report. The report will be created with the highlighted records only. 
       The report can be saved to PDF, Text, Rich Text or Excel format by selecting the file 
       format from the list under Save option, and then clicking OK button after selecting the 
       required options from the Export Settings window.
    </p>

   <p>
     <span className="mb-2"><b>More: </b></span>
     <Button className="doc-button-icon"><FaCog className="icon" title="More"/></Button>
   </p>
   <p>
      This option lists Reset, Connectivity, Import Equipment and Display Settings option. 
   </p>

  <p><span className="mb-2"><b>Reset:</b></span></p>
  <p>
    User can reset the Equipment Test Results values using <span className="doc-subtitle">Reset </span> 
    option from <span className="doc-subtitle">More </span>
    <Button className="doc-button-icon"><FaCog className="icon" title="More"/></Button> menu. 
    On selecting this option, a message "Are you sure you want to reset Equipment Test Result 
    values?" will be displayed. Click 'Yes' and the Equipment Test Result values of the selected 
    Equipment will be reset.Test Results of multiple Equipment can be reset using this option.
  </p>


  <p><span className="mb-2"><b>Connectivity:</b></span></p>
  <p>
     Users can link the Equipment to Module/Sprinklers through this option from 
     <span className="doc-subtitle"> More </span>
     <Button className="doc-button-icon"><FaCog className="icon" title="More"/></Button> menu. After adding 
     equipment, a message "Do you want to link this Equipment to Module/Sprinklers?" message will 
     be displayed. 
  </p>

  <p>
     On clicking 'Yes' to the corresponding message, Set Connectivity window will be displayed 
     with System, Panel and Module/Sprinklers drop downes. The drop downes System and Panel will 
     be displayed as read only and cannot be edited.  User can select Module/Sprinklers and set the 
     connectivity by clicking Save button. 
  </p>

  <p><span className="mb-2"><b>Import Equipment:</b></span></p>
  <p>
     This option is provided for importing Equipment Data from Excel spread sheets directly 
     into TechAdvance<sup>+</sup> database without having to manually enter these details one 
     by one into TechAdvance<sup>+</sup>. 
  </p>

  <p>
     Following are the requirements/restrictions when importing data from Excel to TechAdvance<sup>+</sup>
  </p>

  <ul className="bullet-list">
    <li>Microsoft Excel is required to be installed in the user's computer.</li>
    <li>The Excel file should be saved in Excel 2007 or later versions.</li>
    <li>The browser security settings for ActiveX control should be set as required by TechAdvance<sup>+</sup>.</li>
    <li>There should not be any blank/hidden columns/rows between Excel data columns/rows.</li>
    <li>The Excel sheet must contain the essential fields (depends on each module) and it should be mapped to TechAdvance<sup>+</sup>columns before importing data from Excel.</li>
    <li>The column type in the Excel sheet should be of the same type as TechAdvance<sup>+</sup>data field. e.g.  Date fields should be of Date type.</li> 
    <li>Date fields should be given in the MM/DD/YYYY  format (eg. Date in Excel should be 01/25/2015 for 25 Jan 2015). </li>
    <li>Date Time fields should be given in the MM/DD/YYYY HH:MM:SS AM/PM format(eg. Date time value in Excel should be 01/25/2015 10:30:00 AM for 25 Jan 2015 10:30:00 AM) </li>
    <li>Values for Date field, Integer field, and numeric field in the Excel file should be valid.</li>
    <li>The special characters ~, ;,`,{'<,>'},[,] and & are not allowed in the data or Column headings in the Excel.</li> 
    <li>The special characters !,.,@,#,$,%,*,#,\,|,?,/,:,{'{,}'},(,) are allowed in all text fields except Address and special characters ' and " are allowed in Location field.</li>
    <li>Length of data  in any cell in Excel should not exceed the maximum length of  data in the corresponding data field in TechAdvance<sup>+</sup>.</li>
  </ul>


   <p>
      The columns in the Excel sheet should be mapped to the data fields in TechAdvance<sup>+</sup> Inspection module. The column type in the Excel sheet should be of the same type as TechAdvance<sup>+</sup> field type. 
   </p>

   <p>
     Common Attributes, if required, should be created using the 
     <span className="doc-subtitle"> Manage: Customization: Attributes: Common Attributes </span> 
     option first, and mapped to the columns in the Excel sheet. 
   </p>

   <p>
     Data import page will be displayed with the option to browse the Excel File, select 
     Worksheet, and Attributes with Configure and Fetch Data buttons. The user can use the Browse 
     button, to browse and pick up the file. The work sheets in the Excel file will be listed by 
     name in the Work Sheet drop down automatically. On selecting the required worksheet a mapping 
     table will be displayed showing the Excel sheet columns and TechAdvance<sup>+</sup> data fields. If any mapping was done earlier it will be displayed with the field name as mapped, otherwise TechAdvance<sup>+</sup> column will be shown as Not Selected.
   </p>

   <p>
     For importing Devices and Modules only with connectivity, user has to select the option 
     'Common'. In this case, all the Equipment data can be in a single Excel sheet. For importing 
     AHU, Doors, NAC Panels, Sprinklers, Modules seperately, user has to select the 'Equipment 
     Specific' option and select Equipment Type from the drop down. In this case, a separate Excel 
     sheet is required for the data of each Equipment Type, and the data has to be imported one 
     Equipment Type at a time. The mapping of fields has to be done separately for each type and 
     saved. 
   </p>

   <p>
     <span className="doc-subtitle">Configure:</span> Click the Configure button to map the Excel 
     sheet columns to the Equipment data fields of TechAdvance<sup>+</sup>. Map Equipment Details 
     window will be displayed where the columns can be mapped by selecting the corresponding field 
     from the drop down. The following are the fields displayed:
   </p>

   <p>
      <i>Module Address: </i>Module Address field is a static field. It is used to import the address of the module. 
   </p>

   <p>
     <i>Address: </i>Address field is a static field. It is used to import the address of the equipment. This is a mandatory field. 
   </p>

   <p>
     <i>Comment:</i> Comment field is a Common Attribute. 
   </p>

   <p>
     <i>Dev. Reading:</i> Dev. Reading field is a static field. It is used to import the device reading.
   </p>

   <p>
     <i>Dev. Reading Date:</i> Dev. Reading Date field is a static field. It is used to import the device reading date.
   </p>

   <p>
     <i>Health Classification: </i>Health Classification field is a static field. It is used to import the Health Classification of the equipment.
   </p>

   <p>
      <i>Location:</i> Location field is a Common Attribute.
   </p>

   <p>
      <i>Module Address:</i> Module Address is a static field. It is used to import the address of the module if available.
   </p>

   <p>
      <i>NFPA Classification:</i> NFPA Classification is a static field. It is used to import the NFPA Classification of the equipment.
   </p>

   <p>
      <b>Note:</b> Note field is a Common Attribute.
   </p>

   <p>
      <i>Test Date:</i> Test Date is a static field. It is used to import the date on which the equipment was tested.
   </p>

  <p>
      <i>Test Method:</i> Test Method is a static field. It is used to import the test method: Functional, Visual or None.
  </p>

  <p>
     <i>Test Result:</i> Test Result  is a static field. It is used to import the test result: Pass, Fail or Not Tested.
  </p>

  <p>
     All the required columns should be mapped to the corresponding columns in the Excel 
     field. Select the corresponding check boxes for the required fields to be mapped and select 
     the required fields and click Save button. A confirmation message "Column Mapping saved" will 
     be displayed. The Data Import window will be displayed with the selected Excel fields mapped 
     to its corresponding TechAdvance<sup>+</sup> fields. Click on Fetch Data button. Location of 
     Modules created automatically will be saved as 'Auto Created'. NFPA Classification will be 
     saved as 'Choose a Classification'.</p>

  <p><span className="doc-subtitle">Fetch Data:</span></p>
  <p>
     Click the Fetch Data button to import the Excel sheet data to TechAdvance<sup>+</sup> 
     database and link it to the corresponding Equipment. On selecting this option the Data Import 
     page will be displayed with all the imported data listed and mapped to its corresponding fields 
     with a comment in the Comment column. User can click on cells to edit text parsed from Excel 
     file and click Import button to import the data. 
  </p>

  <p>
     While importing Equipment Data, if the Device Address is not provided then it will be imported as Module.
  </p>

  <p>
     If the file is linked correctly, TechAdvance<sup>+</sup> Comments column will show 'Data Saved'. Make/Model -While importing Equipment data, the values for 'Make /Model' will be accepted by TechAdvance<sup>+</sup> only if the appropriate format is used.
  </p>

  <p>
     If any error occurs while importing, the details of the error will be shown in TechAdvance<sup>+</sup> Comments column. User can validate the loaded data by selecting Validate button. On clicking Validate button, all the failed records will be displayed at the top of the Data Import page with its corresponding messages in TechAdvance<sup>+</sup> Comments column. All the failed records can be exported by selecting Export button.
  </p>

  <p>
     If duplicate Address is provided while Importing Data then the message, "Duplicate Address found" will be displayed in the corresponding Tech Advance<sup>+</sup> Comments column after validating the data in Data Import page and will be imported in the format "Duplicate address" - "Ascending Number". If the mandatory Address field is missing then in TechAdvance<sup>+</sup> Comments column, status will be displayed as 'Address cannot be empty', and user has to enter a valid address in the address column. If any special character is used, then "Special character 'special character' not allowed in 'Field name' " status will be displayed. 
  </p>

  <p>
     If the NFPA Classification is incorrect or not mapped then after validating the data in Data Import page, the message "NFPA Classification is not available for 'Equipment Type' " will be displayed in the corresponding Tech Advance<sup>+</sup> Comments column.
  </p>

  <p>
    If the NFPA Classification is mapped but the provided Classification value is incorrect
     while importing data, then the value provided for NFPA Classification will be imported to 
     XML Usage column. In this case, the column for NFPA Classification will display the value 
     "CHOOSE A CLASSIFICATION" in the Equipment List data grid after importing since the value 
     provided in the data import sheet is incorrect.
  </p>

  <p><span className="mb-2"><b>Display Settings:</b></span></p>
  <p>
     This option allows the user to change the visibility of the data fields displayed in 
     the data grid. On selecting this option, all the available data fields are displayed in a 
     indow. Display settings can be modified by selecting the required fields from the list. 
     The grid settings modified through this option will not be saved and will be effective only 
     for the current instance of the data grid. 
  </p>

  <p><span className="doc-subtitle"><b>Questions:</b></span></p>
  <p>
     Selecting Questions tab will display a tree view structure below the Panel drop down. 
     First Panel will be selected by default if available. User can select each node by clicking it. 
     Based on the node selected from the Tree view structure, options to enter details of each node 
     will be displayed at the right side of the page (Fields will be displayed based on 'Report 
     Selected' only). Panel nodes or Inspection fields displayed can be updated through 
     <span className="doc-subtitle"> Manage: Inspection Settings: Inspection Fields</span> option. 
     Save button will be displayed only for 'Active' inspection tasks.
  </p>

  <p><span className="doc-subtitle"><b>Summary:</b></span></p>
  <p>
     This is a Summary Report of Equipment based on NFPA or Health Classification. It shows the 
     total number of Equipment, Number of Equipment Functionally Tested, its Percentage, Number of 
     Equipment Visually Inspected ,its Percentage, Number of Equipment Failed and its Percentage. 
     Equipment with Classification 'N/A' and those without any Classification are ignored for the Report.
  </p>


  <p><span className="doc-subtitle"><b>Report Selected:</b></span></p>    
  <p>
     This option is used to view reports selected for the selected Inspection Task. The Reports listed 
     here are the reports uploaded through <span className="doc-subtitle">Report Mapping</span> option. 
     On selecting this option all the reports accessible to the inspection task will be listed with 
     its Name, Report Title and with check boxes against each report. The user can select the required 
     report and click Save Changes button to save the changes. 
     </p>

   </>
  );
}
export default InspectionTaskInspectionDetailsDocument;
