import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import ReportViewer from "../Report/ReportViewer";
import { useDispatch } from "react-redux";
import { createReportPreviewSession, setReportPreviewSession } from "../../../redux/pages/Administration/Site/siteSlice";
interface Props {
  showModal: boolean;
  setShowModal: (state: boolean) => void;
  selectedRow: any;
  title: string;
  isReportAll:boolean;
  reportPreviewSession:any;
  reportType:string,
  siteId: string
  reportParams?: any;
}
export const ReportModal: React.FC<Props> = ({
  showModal = false,
  setShowModal = () => {},
  selectedRow=[],
  title="",
  isReportAll=false,
  reportPreviewSession={},
  reportType="",
  siteId = "",
  reportParams="",

}) => {
    const [reportName, setReportName] = useState<any>(null);
   
    const dispatch = useDispatch<any>();

    useEffect(() => {
        if (reportPreviewSession === "") {
          let reportParameters = {};
          if(isReportAll){
            reportParameters = {
              reportParameters: {
                UniqueSiteId: siteId
              },
              reportType: reportType,
            };
            if (reportParams)
              reportParameters = reportParams
            dispatch(createReportPreviewSession(reportParameters));
          }else{
             reportParameters = {
              reportParameters: {
                UniqueSiteId: siteId,
                "UniqueFileIds":selectedRow.map(item=>item.attachmentId)
              },
              reportType: reportType,
            };
            if (reportParams)
              reportParameters = reportParams
            dispatch(createReportPreviewSession(reportParameters));
          }
        }
        return(()=>{
            dispatch(setReportPreviewSession(""));
        })
    },[])
    useEffect(() => {
        if (reportPreviewSession) {
            setReportName(reportPreviewSession.previewSessionId);
        }
    }, [reportPreviewSession])
  return (
      <Dialog
        header={title}
        visible={showModal}
        style={{ width: "90vw", maxHeight: "90vh"}}
        onHide={() => {
          setShowModal(false);
        }}
        className="report-modal sites-report-view"
      >
        <div className="content-box">
          <div className="report-wrapper">
            <ReportViewer reportId={reportName} />
          </div>
        </div>
      </Dialog>
  );
};
export default ReportModal;