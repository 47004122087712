import React from "react";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "constants/AppRoutes";
import contract from "assets/svgs/clarity_contract-line.svg";
import task from "assets/svgs/bx_task.svg";
import userActivity from "assets/svgs/carbon_user-activity.svg";
import fileUpload from "assets/svgs/carbon_user-file-upload.svg";
import user from "assets/svgs/bxs_user-detail.svg";
import location from "assets/svgs/map-location.svg";
import bxBuilding from "assets/svgs/bx_building.svg";
import carbon_floorplan from "assets/svgs/carbon_floorplan.svg";
import masterData from "assets/svgs/master-data.svg";
import ftp from "assets/svgs/ftp.svg";
import ExpiredContracts from "assets/svgs/expiryContacts.svg";
import userShape from "assets/svgs/user-shape.svg";
import salesActivity from "assets/svgs/sales-activity.svg";
import tapPerformance from "assets/svgs/tap-performance.svg";
import { showUI } from "service/GeneralUtility";
import { useAppSelector } from "../../redux/hooks";


const ReportView = () => {
    const navigate = useNavigate();
    const userPrivileges = useAppSelector((state) => state.user.userPrivileges);
    
    return (
        <div className="report-view">
            <div className="report-view-wrapper">

                <h1 className="main-title">Reports</h1>

                <div className="reports-tile" onClick={() => navigate(`/${APP_ROUTES.CONTRACTS_REPORT}`)}>
                    <div className="report-view-data">
                        <div className="col-img">
                            <img src={contract} alt="contract" />
                        </div>
                        <span>Contracts</span>
                    </div>
                </div>

                <div className="reports-tile" onClick={() => navigate(`/${APP_ROUTES.INSPECTION_TASK_REPORT}`)}>
                    <div className="report-view-data">
                        <div className="col-img">
                            <img src={task} alt="task" />
                        </div>
                        <span>Inspection Tasks</span>
                    </div>
                </div>

                <div className="reports-tile" onClick={() => navigate(`/${APP_ROUTES.INSPECTION_ACTIVITY_REPORT}`)}>
                    <div className="report-view-data">
                        <div className="col-img">
                            <img src={userActivity} alt="userActivity" />
                        </div>
                        <span>Inspection Activity</span>
                    </div>
                </div>

                <div className="reports-tile" onClick={() => navigate(`/${APP_ROUTES.UPLOADED_FINAL_REPORTS}`)}>
                    <div className="report-view-data">
                        <div className="col-img">
                            <img src={fileUpload} alt="fileUpload" />
                        </div>
                        <span>Uploaded Final Reports</span>
                    </div>
                </div>

                <div className="reports-tile"  onClick={() => navigate(`/${APP_ROUTES.USER_DETAILS_REPORT}`)}>
                    <div className="report-view-data">
                        <div className="col-img">
                            <img src={user} alt="user" />
                        </div>
                        <span>User Details</span>
                    </div>
                </div>

                {showUI(userPrivileges, 'SIETAP_REPORT_SITE_REPORT') &&
                    <div className="reports-tile" onClick={() => navigate(`/${APP_ROUTES.SITE_REPORT}`)}>
                        <div className="report-view-data">
                            <div className="col-img">
                                <img src={location} alt="location" />
                            </div>
                            <span>Sites</span>
                        </div>
                    </div>
                }

                {showUI(userPrivileges, 'SIETAP_REPORT_BUILDING_REPORT') &&
                    <div className="reports-tile" onClick={() => navigate(`/${APP_ROUTES.BUILDING_REPORT}`)}>
                        <div className="report-view-data">
                            <div className="col-img">
                                <img src={bxBuilding} alt="Building" />
                            </div>
                            <span>Buildings</span>
                        </div>
                    </div>
                }

                {showUI(userPrivileges, 'SIETAP_REPORT_FLOOR_REPORT') &&
                    <div className="reports-tile" onClick={() => navigate(`/${APP_ROUTES.FLOOR_REPORT}`)}>
                        <div className="report-view-data">
                            <div className="col-img">
                                <img src={carbon_floorplan} alt="Floor" />
                            </div>
                            <span>Floors</span>
                        </div>
                    </div>
                }

                {showUI(userPrivileges, 'SIETAP_REPORT_EXPIRED_CONTRACTS') &&
                    <div className="reports-tile" onClick={() => navigate(`/${APP_ROUTES.EXPIRED_CONTRACTS_REPORT}`)}>
                        <div className="report-view-data">
                            <div className="col-img">
                                <img src={ExpiredContracts} alt="Expired Contracts" />
                            </div>
                            <span>Expired Contracts</span>
                        </div>
                    </div>
                }

                <div className="reports-tile" onClick={() => navigate(`/${APP_ROUTES.MASTER_DATA_REPORT}`)}>
                    <div className="report-view-data">
                        <div className="col-img">
                            <img src={masterData} alt="Master Data" />
                        </div>
                        <span>Master Data</span>
                    </div>
                </div>

                {showUI(userPrivileges, 'SIETAP_DATA_IMPORTS') && 
                    <div className="reports-tile" onClick={() => navigate(`/${APP_ROUTES.SAP_IMPORT_STATUS_REPORT}`)}>
                        <div className="report-view-data">
                            <div className="col-img">
                                <img src={ftp} alt="SAP Import Status" />
                            </div>
                            <span>SAP Import Status</span>
                        </div>
                    </div>                
                }


                <div className="reports-tile"  onClick={() => navigate(`/${APP_ROUTES.USER_ACTIVITY_REPORT}`)}>
                    <div className="report-view-data">
                        <div className="col-img">
                            <img src={userShape} alt="User Activity Report" />
                        </div>
                        <span>User Activity</span>
                    </div>
                </div>

                {showUI(userPrivileges, 'SIETAP_REPORT_TAP_PERFORMANCE_REPORT') &&
                    <div className="reports-tile" onClick={() => navigate(`/${APP_ROUTES.TAP_PERFORMACE}`)}>
                        <div className="report-view-data">
                            <div className="col-img">
                                <img src={tapPerformance} alt="Life Safety Systems Report" />
                            </div>
                            <span>Life Safety Systems Report</span>
                        </div>
                    </div>  
                }

                {showUI(userPrivileges, 'SIETAP_REPORT_SALES_ACTIVITY_REPORT') &&
                  <div className="reports-tile"  onClick={() => navigate(`/${APP_ROUTES.SALES_ACTIVITY_REPORT}`)}>
                    <div className="report-view-data">
                        <div className="col-img">
                            <img src={salesActivity} alt="Sales Opportunity Report" />
                        </div>
                        <span>Sales Opportunity Report</span>
                    </div>
                </div>
                }

            </div>
        </div>
    );
};

export default ReportView;
