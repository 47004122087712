import React, { useState, useCallback, useRef, useEffect, memo } from "react";
import { useForm } from "react-hook-form";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import PopOver from "components/common/PopOver/PopOver";
import { ProgressSpinner } from 'primereact/progressspinner';
import { OverlayPanel } from "primereact/overlaypanel";
import moment from "moment";
import DisplaySetting from "components/common/DisplaySetting/DisplaySetting";
//icons
import { BsPaperclip, BsFillPencilFill, BsSubtract, BsSearch, BsTrashFill, BsZoomIn, BsBoxArrowInDownRight } from "react-icons/bs";
import { FaChevronDown, FaDownload } from "react-icons/fa";
//components
import DataTableComponent from "components/Table/DataTable";
import InformationBox from "components/common/Message/InformationBox/InformationBox";
import ConfirmationBox from "components/common/Message/ConfirmationBox/ConfirmationBox";
//data
import { DEFAULT_HEADER_LIST } from "../InspectionTasksData";
//redux
import { useDispatch } from "react-redux";
import {
    getEquipmentDatafields,
    onEquipmentDetailsUpdated,
    onEquipmentRemoved,
    onEquipmentUsageChecked,
    setEquipmentDatafields,
    setRemovedEquipment,
    setUpdatedEquipment,
    setIsDevReadingChanged,
	setIsTestedDateChanged
} from "redux/pages/Inspection/InspectionTask/InspectionDetails/Equipment/EquipmentSlice";
import { useAppSelector } from "redux/hooks";
//utility
import { showUI } from 'service/GeneralUtility';
import EquipmentListData from "../ManageInspectionTasks/InspectionDetails/Equipment/EquipmentListData";
import EditEquipment from "../ManageInspectionTasks/InspectionDetails/Equipment/Manage/EditEquipment/EditEquipment";
import EditEquipmentList from "../ManageInspectionTasks/InspectionDetails/Equipment/ManageEquipmentGrp/EditEquipmentGroup";
import { getEquipmentTypeList, getDrawingEquipmentList, setEquipmentSpecificList } from "redux/pages/Inspection/InspectionTask/inspectionDrawingSlice";
import { BiUnlink } from "react-icons/bi";
import { Dropdown } from "primereact/dropdown";
import AttachmentList from "../ManageInspectionTasks/InspectionDetails/Equipment/Manage/Attachments/AttachmentList";
import SearchAddress from "./SearchAddress";

import { onExportReport } from "redux/common/report/customizeReportSlice";
import EquipmentSpecificEquipmentReport from "./Report/EquipmentSpecificListReport";

interface ListItem {
    id: number;
    name: string;
    selected: boolean;
}


type EqpGroupFormData = {
    address: string;
    name: string;
    type: string;
};


interface EquipmentListPropType {
    selectedTask?: any;
    onShowZoomClick: (data: any,showZoomed:boolean) => void;
    onShowInDrawing: (data: any,showDrawing:boolean) => void;
    onDeleteClick: (data: any) => void;
    onDelinkClick: (data: any) => void;
}

const EquipmentSpecificList: React.FC<EquipmentListPropType> = (props) => {
    const { selectedTask ,onShowZoomClick, onShowInDrawing, onDeleteClick,onDelinkClick}= props;

    const [pageIndex, setPageIndex] = useState(0);
    const [currentStart, setCurrentStart] = useState(0);
    const [sortField, setSortField] = useState("");
    const [sortOrder, setSortOrder] = useState("");
    const [selectedRows, setSelectedRows] = useState<any>([]);
    const [headerList, setHeaderList] = useState<Array<ListItem>>(DEFAULT_HEADER_LIST);
    const [selectedEquipmentGroup, setSelectedEquipmentGroup] = useState<any>(null);
    const [selectedEquipmentType, setSelectedEquipmentType] = useState<any>(null);
    const [showFilterEquipment, setShowFilterEquipment] = useState(false);
    const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);
    const [showDisplaySetting, setShowDisplaySetting] = useState<boolean>(false);
    const settingRef = React.createRef<OverlayPanel>();
    const downloadRef = React.createRef<OverlayPanel>();
    const [columns, setColumns] = useState<any>([]);
    const [backupColumns, setBackupColumns] = useState<any>([]);
    const [filteredBody, setFilteredBody] = useState<any>();
    const [tableColumns, setTableColumns] = useState<Array<any>>(backupColumns);
    const [gridData, setGridData] = useState<any>([]);
    const [showEditEquipment, setShowEditEquipment] = useState(false);
    const [showDeletePrompt, setShowDeletePrompt] = useState(false);
    const [displayInfoModal, setDisplayInfoModal] = useState(false);
    const [message, setMessage] = useState("");
    const [pageDetails, setPageDetails] = useState<any>();
    const [showEditEquipmentList, setShowEditEquipmentList] = useState<boolean>(false);
    const [showIcon, setShowIcon] = useState(true);
    const [generalProperties, setGeneralProperties] = useState<any>([]);
    const [showReportModal, setShowReportModal] = useState(false);
    const [reportFlag, setReportFlag] = useState<any>('all');
    const [displayConfirmModal, setDisplayConfirmModal] = useState(false);
    const [isReset, setIsReset] = useState(false);
    const [showAttachmentListModal, setShowAttachmentListModal] = useState(false);
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [datafields, setDatafields] = useState<any>([]);
    const [dataLoaded,setDataLoaded]= useState(false);
    const [removedEquipmentIds, setRemovedEquipmentIds] = useState<any>([]);
    const [isDelete,setIsDelete]= useState(false);
    const [showInDrawing,setShowInDrawing]= useState(false);
    const [showZoomed,setShowZoomed]= useState(false);
    const [searchAddress, setSearchAddress] = useState<any>();
    const [isSearch, setIsSearch] = useState<boolean>(false)
    const [displaySearchModal, setDisplaySearchModal] = useState<boolean>(false)

    const dispatch = useDispatch<any>();
    const selectedTreeNode = useAppSelector((state) => state.inspectionDrawing.selectedTreeNode);
    const equipmentList = useAppSelector((state) => state.inspectionDrawing.equipmentSpecificList);
    const equipmentTypeList = useAppSelector((state) => state.inspectionDrawing.equipmentTypeList);
    const equipmentDatafields = useAppSelector((state) => state.equipment.equipmentDatafields);
    const updateEquipment = useAppSelector((state) => state.equipment.updatedEquipment);
    const userPrivileges = useAppSelector((state) => state.user.userPrivileges);
    const removedEquipment = useAppSelector((state) => state.equipment.removedEquipment);
    const isDevReadingChanged = useAppSelector((state) => state.equipment.isDevReadingChanged);
	const isTestDateChanged = useAppSelector((state) => state.equipment.IsTestDateChanged);
    const loggedInUserDetails = useAppSelector((state) => state.administration.loggedInUserDetails);
    const rowsPerPage = useAppSelector((state) => state.personalSettings.rowsPerPage);

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<EqpGroupFormData>({
        defaultValues: {
            address: "",
            name: "",
            type: "",
        },
    });

    useEffect(() => {
        if (backupColumns) {
            setTableColumns(columns);
        }
    }, [backupColumns]);


    useEffect(() => {
        if (tableColumns) {
            setColumns(tableColumns)
        }

    }, [tableColumns])

    useEffect(() => {
        dispatch(getEquipmentTypeList(selectedTask?.uniqueInspectionTaskId, selectedTreeNode[0]?.uniqueDrawingId, 1))
    }, [selectedTask]);

    useEffect(() => {
        if (rowsPerPage && rowsPerPage > 0) {
            const body = {
                searchText: '',
                FilterType: selectedEquipmentType,
                isCommon: true
            };
            dispatch(getDrawingEquipmentList(selectedTask?.uniqueInspectionTaskId, selectedTreeNode[0]?.uniqueDrawingId, "equipmentSpecific", body, 0, setDataLoaded, 1));
        }
    }, [rowsPerPage]);

    useEffect(() => {
        if (rowsPerPage && rowsPerPage > 0) {
            let modifiedPageDetails = { ...pageDetails, 'currentLength': rowsPerPage, 'pageCount': rowsPerPage };
            setPageDetails(modifiedPageDetails);
            setCurrentStart(1)
            setPageIndex(0)
        }
    }, [rowsPerPage]);

    const equipmentTypeChange = (event) => {
        setSelectedEquipmentType(event.value);
        if (selectedTask?.status !== "Active")
            setShowIcon(false)
        else
            setShowIcon(true)
        const body = {
            searchText: '',
            FilterType: event.value,
            isCommon: true
        };
        dispatch(getDrawingEquipmentList(selectedTask?.uniqueInspectionTaskId, selectedTreeNode[0]?.uniqueDrawingId, "equipmentSpecific",  body, 0, setDataLoaded, 1));
        setPageIndex(0);
        setDisplayInfoModal(false);
    }
    useEffect(()=>{
        if(isSearch) {
            const body = {
                searchText: searchAddress,
                FilterType: selectedEquipmentType,
                uniqueEquipmentClassId: "",
                isCommon: true
            };
            setGridData([])
            dispatch(getDrawingEquipmentList(selectedTask?.uniqueInspectionTaskId, selectedTreeNode[0]?.uniqueDrawingId, "equipmentSpecific",  body, 0, setDataLoaded, 1));
            setPageIndex(0);
            setDisplaySearchModal(false);
        }
    },[isSearch])

    useEffect(() => {
        if (equipmentList && dataLoaded) {
                setEquipmentData(equipmentList);
        }
    }, [equipmentList, dataLoaded]);

    const setEquipmentData = (list) => {
        setGridData([...list?.data]);
        setPageDetails(list?.pageDetails);
        setSelectedRows([list?.data[0]]);
        setGeneralProperties(list?.generalProperties);
        setShowFilterEquipment(false);
        setShowAdvancedSearch(false);
    }

    useEffect(() => {
		if (equipmentDatafields) {
			setDatafields(equipmentDatafields);
			dispatch(setEquipmentDatafields(""));
		}
	}, [equipmentDatafields])

    const onPageChanged = (options: any) => {
        if (options !== pageIndex) {
            setPageIndex(options);
            let currentStart = options * pageDetails?.currentLength + 1;
            setCurrentStart(currentStart);
            const body = {
                searchText: searchAddress?searchAddress:null,
                FilterType: selectedEquipmentType,
                isCommon: true
            };
            dispatch(getDrawingEquipmentList(selectedTask?.uniqueInspectionTaskId, selectedTreeNode[0]?.uniqueDrawingId, "equipmentSpecific", body, currentStart,setDataLoaded, 1));
        }
    };

    const onSort = (event) => {
        let sortDirectionValue: string;
        if (event.sortOrder === 1) sortDirectionValue = "ASC";
        else sortDirectionValue = "DESC";
        setSortField(event.sortField);
        setSortOrder(sortDirectionValue);
        let body = {
            searchText: searchAddress?searchAddress:null,
            FilterType: selectedEquipmentType,
            isCommon: true
        }
        dispatch(getDrawingEquipmentList(selectedTask?.uniqueInspectionTaskId, selectedTreeNode[0]?.uniqueDrawingId, "equipmentSpecific", body, currentStart, setDataLoaded, 1,  event?.sortField, sortDirectionValue));
    };

    const handleRowSelect = useCallback((data: any) => {
        setSelectedRows([...data]);
    }, []);

    const onAddEquipmentClick = () => {

    };


    const onEditEquipmentClick = (type) => {
        if (!selectedRows?.length) return;
        let body;
        body = {
                UniqueInspectionTaskId: selectedTask?.uniqueInspectionTaskId,
                UniqueEquipmentClassId: selectedRows[0]?.EquipmentTypeId,
                UniquePanelId: selectedRows[0]?.EquipmentGroupId,
                uniqueSystemId: selectedTask?.system?.uniqueSystemId,
        };
        if (selectedRows.length > 1) {
            setMessage("This operation can be performed only one row at a time");
            setDisplayInfoModal(true);
        } else if (selectedRows?.length < 1) {
            setMessage("Select an Equipment");
            setDisplayInfoModal(true);
        } else {
            body.EquipmentId = selectedRows?.[0]?.EquipmentId;
            dispatch(getEquipmentDatafields(body));
            setShowEditEquipment(true);
        }
    };

    const onDeleteEquipmentClick = () => {
        if (!selectedRows?.length) return;
		let list: any = [];
		if (selectedRows?.length) {
			for (let i = 0; i < selectedRows?.length; i++) {
				list.push(selectedRows[i].EquipmentId);
			}
		}
		const body = {
			equipmentIds: list,
			UniqueInspectionTaskId: selectedTask?.uniqueInspectionTaskId,
		};
		dispatch(onEquipmentUsageChecked(body, setShowDeletePrompt, setDisplayInfoModal, setMessage, setRemovedEquipmentIds));
    };




    const onEquipmentUpdate = useCallback((fields) => {
        const body = {
            ClassId: selectedRows?.[0]?.EquipmentTypeId,
            SystemId: selectedTask?.system?.uniqueSystemId,
            PanelId: selectedRows?.[0]?.EquipmentGroupId,
            NfpaClassificationId: 10,
            InspectionTaskstatus: 1,
            UniqueInspectionTaskId: selectedTask?.uniqueInspectionTaskId,
            FieldList: fields,
            DateChangedIdentifier:isDevReadingChanged,
			TestDateChangedIdentifier:isTestDateChanged
        };
        dispatch(onEquipmentDetailsUpdated(selectedTask?.uniqueInspectionTaskId, selectedRows?.[0]?.EquipmentId, body));
    },
        [
            selectedEquipmentType,
            selectedTask,
            selectedEquipmentGroup,
            selectedRows,
            selectedRows?.[0]?.EquipmentId,
            selectedRows?.[0]?.EquipmentTypeId,
            selectedRows?.[0]?.EquipmentGroupId,
            isDevReadingChanged,
			isTestDateChanged
        ]
    );

    useEffect(() => {
		if (updateEquipment) {
			if (updateEquipment.isSuccess === true) {
				const findIndex = gridData?.findIndex((item) => item.EquipmentId === updateEquipment?.data?.EquipmentId);
				if (findIndex >= 0) {
					gridData[findIndex] = updateEquipment.data;
					setGridData([...gridData]);
                    setShowEditEquipment(false);
					setMessage("Equipment updated");
					setDisplayInfoModal(true);
				}
			} else {
				setMessage(updateEquipment?.message);
				setDisplayInfoModal(true);
			}
			dispatch(setUpdatedEquipment(""));
		}
		setShowProgressBar(false);
	}, [updateEquipment]);

    const onDeleteEquipment = () => {
		const body = {
			equipmentIds: removedEquipmentIds,
			UniqueInspectionTaskId: selectedTask?.uniqueInspectionTaskId,
		};
		setShowProgressBar(true);
        setIsDelete(true)
		dispatch(onEquipmentRemoved(selectedTask?.uniqueInspectionTaskId, body));
		setShowDeletePrompt(false);
        onDeleteClick && onDeleteClick(selectedRows)
	};

    useEffect(() => {
		if (removedEquipment) {
			if (removedEquipment?.isSuccess) {
				for (let i = 0; i < removedEquipment?.equipmentIds?.length; i++) {
					const findIndex = gridData?.findIndex((item) => item.EquipmentId == removedEquipment?.equipmentIds[i]);
					if (findIndex >= 0) {
						gridData.splice(findIndex, 1);
					}
					setGridData([...gridData]);
					setShowDeletePrompt(false);
                    if (isDelete) {
					setMessage(removedEquipment?.returnMessage);
					setIsDelete(false)
					setDisplayInfoModal(true);
                    }
                    setSelectedRows([]);
					setRemovedEquipment([]);
				}
			} else {
				setDisplayInfoModal(true);
				setMessage(removedEquipment?.ReturnMessage);
			}
			dispatch(setRemovedEquipment(""));
			setShowProgressBar(false);
		}
	}, [removedEquipment]);


    const renderFooter = () => {
		return (
			<>
				<Button label="No" onClick={() => { setShowDeletePrompt(false); setRemovedEquipmentIds([]) }} className="cancel-btn" />
				<Button label="Yes" onClick={onDeleteEquipment} className="submit-btn" autoFocus />
			</>
		);
	};



    const onReportAllClick = () => {
        setReportFlag('all');
        setShowReportModal(true);
    };

    const onReportHighlightedClick = () => {
        setReportFlag('highlighted');
        setShowReportModal(true);
    };


    const handleTableColumns = useCallback((data: any) => {
        setColumns([...data]);
        setBackupColumns([...data]);
    }, []);

    const onRowEditClick = (rowData) => {
        let isEditable = false;
        if (selectedRows && selectedRows.length === 1) {
            const body = {
                UniqueInspectionTaskId: selectedTask?.uniqueInspectionTaskId,
                UniqueEquipmentClassId: selectedEquipmentType === "All" ? selectedRows[0]?.EquipmentTypeId : selectedEquipmentType,
                EquipmentId: rowData.EquipmentId,
                UniquePanelId: selectedEquipmentGroup === 0 ? selectedRows[0]?.EquipmentGroupId : selectedEquipmentGroup,
                uniqueSystemId: selectedTask?.system?.uniqueSystemId,
            };
            dispatch(getEquipmentDatafields(body));
            isEditable = true;
        }

        return isEditable;
    }

    const onRowEditValidation = (rowData) => {
        let isValidation = true;
        if (equipmentDatafields?.length) {
            equipmentDatafields.forEach(element => {
                if (element?.mandatory && !element?.disabled) {
                    if (!rowData[element?.dataFieldName]) {
                        setMessage(`${element?.dataFieldName} is required`);
                        setDisplayInfoModal(true);
                        isValidation = false;
                    }
                }
            });
        }

        return isValidation;
    }

    const onEquipmentRowEdited = (rowData) => {

        const testMetIndex = equipmentDatafields?.findIndex((item) => item.dataFieldName === "Test Method");
        const testResIndex = equipmentDatafields?.findIndex((item) => item.dataFieldName === "Test Result");

        let fieldDetails: any = [];
        if (equipmentDatafields?.length) {
            equipmentDatafields.forEach(element => {
                let item: any = {
                    dataFieldId: element?.uniqueDataFieldId,
                    value: rowData[element?.dataFieldName],
                    multipleValue: null,
                }
                if (element?.isValidated) {

                    const filterItem = element?.validatedValues?.filter((item) => item.dataFieldLookupId === rowData[element?.dataFieldName] || item.dataFieldLookupValue === rowData[element?.dataFieldName]);

                    if (filterItem?.length) {
                        if (element?.dataFieldName === "Test Result") {
                            const testMethod = equipmentDatafields[testMetIndex]?.validatedValues?.filter((item) => item.dataFieldLookupId === rowData["Test Method"] || item.dataFieldLookupValue === rowData["Test Method"]);
                            if (testMethod[0]?.dataFieldLookupId) {
                                const testRes = equipmentDatafields[testResIndex]?.validatedValues?.filter((item) => item.dataFieldLookupId === rowData["Test Result"] || item.dataFieldLookupValue === rowData["Test Result"]);
                                if (testRes[0]?.dataFieldLookupValue === "Not Tested") {
                                    const testRes = equipmentDatafields[testResIndex]?.validatedValues?.filter((item) => item.dataFieldLookupValue === "Passed");
                                    item.value = testRes[0]?.dataFieldLookupId;
                                }
                            } else {
                                const testRes = equipmentDatafields[testResIndex]?.validatedValues?.filter((item) => item.dataFieldLookupValue === "Not Tested");
                                item.value = testRes[0]?.dataFieldLookupId;
                            }
                        } else {
                            item.value = filterItem[0]?.dataFieldLookupId;
                        }
                    }
                } else if (element?.dataFieldName === "Test Date") {
                    const testMethod = equipmentDatafields[testMetIndex]?.validatedValues?.filter((item) => item.dataFieldLookupId === rowData["Test Method"] || item.dataFieldLookupValue === rowData["Test Method"]);

                    if (testMethod[0]?.dataFieldLookupId) {
                        item.value = moment(new Date()).utcOffset(loggedInUserDetails?.userDefaults?.siteOffset).format("YYYY-MM-DD HH:mm:ss");
                    } else {
                        item.value = "";
                    }
                }
                fieldDetails.push(item)
            });
        }

        const body = {
            ClassId: rowData?.EquipmentTypeId,
            SystemId: selectedTask?.system?.uniqueSystemId,
            PanelId: rowData?.EquipmentGroupId,
            NfpaClassificationId: 10,
            InspectionTaskstatus: 1,
            UniqueInspectionTaskId: selectedTask?.uniqueInspectionTaskId,
            FieldList: fieldDetails,
        };
        setShowProgressBar(true);
        dispatch(onEquipmentDetailsUpdated(selectedTask?.uniqueInspectionTaskId, rowData?.EquipmentId, body));

    }



    const renderDisplaySettings = () => {
        return (
            <li role="button" onClick={() => setShowDisplaySetting(true)}>Display Settings</li>
        )
    }

    const onConfirmClick = () => {
        setDisplayConfirmModal(false)
        onDelinkClick(selectedRows);
    }
    const onSearchIconClick = () => {
        setDisplaySearchModal(true)
    }

    const onSearch = (data:any) => {
        setSearchAddress(data);
        setIsSearch(true);
    }

    const onSearchClearClick = () => {
        const body = {
            searchText: "",
            FilterType: selectedEquipmentType,
            uniqueEquipmentClassId: "",
            isCommon: true
        };
        setGridData([])
        dispatch(getDrawingEquipmentList(selectedTask?.uniqueInspectionTaskId, selectedTreeNode[0]?.uniqueDrawingId, "equipmentSpecific",  body, 0, setDataLoaded, 1));
        setPageIndex(0);
        setIsSearch(false);
    }

    const onOkClick = () => {
        setIsReset(false);
    }
    const onShowInDrawingClick = () => {
        if (selectedRows?.length > 1) {
           
            setMessage("Select a single row of Equipment");
            setDisplayInfoModal(true);
        } else if (selectedRows?.length < 1) {
            setMessage("Select an Equipment");
            setDisplayInfoModal(true);
        } else {
            onShowInDrawing(selectedRows,!showInDrawing)
           setShowInDrawing(!showInDrawing);
        }
    };
    const onShowZoomedClick = () => {
        if (selectedRows?.length > 1) {
            setMessage("Select a single row of Equipment");
            setDisplayInfoModal(true);
        } else if (selectedRows?.length < 1) {
            setMessage("Select an Equipment");
            setDisplayInfoModal(true);
        } else {
            onShowZoomClick(selectedRows,!showZoomed)
            setShowZoomed(!showZoomed)
       }
    };
    const onAttachmentIconClick = () => {
        if (selectedRows?.length > 1) {
            setMessage("Select a single row of Equipment");
            setDisplayInfoModal(true);
        } else if (selectedRows?.length < 1) {
            setMessage("Select an Equipment");
            setDisplayInfoModal(true);
        } else {
            setShowAttachmentListModal(true);
        }
    };
    const getSelectedIds = () => {
		let params: any = [];
		selectedRows.map(item => {
			params.push(item.EquipmentId);
		});
		return params;
	};

    const onExportAll = () => {
		const reportParameters = {
            exportParameters: {
                uniqueInspectionTaskId: selectedTask?.uniqueInspectionTaskId,
                uniqueDrawingId:selectedTreeNode[0]?.uniqueDrawingId,
                uniqueSystemId: selectedTask?.system?.uniqueSystemId,
                filterType: selectedEquipmentType,
                sortColumn: sortField,
                sortOrder: sortOrder,
                search: searchAddress,
                selectedIds: ""
            },
            exportType: "EquipmentSpecific Equipment Report"
        };
		dispatch(onExportReport(reportParameters, "EquipmentSpecific EquipmentList"));
	};

	const onExportHighlightedClick = () => {
		const reportParameters = {
            exportParameters: {
                uniqueInspectionTaskId: selectedTask?.uniqueInspectionTaskId,
                uniqueDrawingId:selectedTreeNode[0]?.uniqueDrawingId,
                uniqueSystemId: selectedTask?.system?.uniqueSystemId,
                filterType: selectedEquipmentType,
                sortColumn: sortField,
                sortOrder: sortOrder,
                search: searchAddress,
                selectedIds: getSelectedIds()
            },
            exportType: "EquipmentSpecific Equipment Report"
        };
		dispatch(onExportReport(reportParameters, "EquipmentSpecific EquipmentList"));
	};

    const onDelinkIconClick = () => {
        if (selectedRows?.length > 1) {
            setMessage("Select a single row of Equipment");
            setDisplayInfoModal(true);
        } else if (selectedRows?.length < 1) {
            setMessage("Select an Equipment");
            setDisplayInfoModal(true);
        } else {
            setMessage("Are you sure you want to de-link the selected Equipment?")
            setDisplayConfirmModal(true)
        }
    };

    const inspectionGridColumnTemplate = (rowData, options) => {
		return (
			<div className="table-col">
				{options?.field == "Test Date" && rowData[options?.field]?.length > 0 || options?.field == "Dev Reading Date" && rowData[options?.field]?.length > 0 ? (
					<>
						<span className="table-col" dangerouslySetInnerHTML={{ __html: `${rowData[options?.field]}` }} />
					</>
				) : (
					<div>{rowData[options?.field]}</div>
				)}
					</div>
				);
		};

    return (
        <>
            {showProgressBar &&
                <div className='pre-loader equipment-list-pre'>
                    <ProgressSpinner />
                </div>
            }
            <div className="header-second-row">
                <div className="left-col">
                    <div className="select-box">
                        <div className="custom-float-field">
                            <div className="field">
                                <span className="p-float-label">
                                    <Dropdown
                                        inputId="equipment-group"
                                        className="w-100"
                                        value={selectedEquipmentType}
                                        onChange={(e) => {
                                            equipmentTypeChange(e)
                                        }}
                                        options={equipmentTypeList}
                                        optionLabel="text"
                                        optionValue="value"
                                    />
                                    <label className="mandatory" htmlFor="dropdown">Equipment Type Name</label>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="table-config">
                    <div className="action-btns">
                        {showIcon && showUI(userPrivileges, "SIETAP_INSPECTION_EQUIPMENT_MANAGE") &&
                            (<Button
                                className="button-icon"
                                tooltip="Edit"
                                disabled={gridData?.length ? false : true}
                                tooltipOptions={{ position: "top" }}
                                onClick={() => onEditEquipmentClick("EDIT")}
                            >
                                <BsFillPencilFill className="icon" />
                            </Button>)}

                        {showIcon && showUI(userPrivileges, "SIETAP_INSPECTION_EQUIPMENT_MANAGE") &&
                            (<Button
                                className="button-icon"
                                tooltip="Delete"
                                disabled={gridData?.length ? false : true}
                                tooltipOptions={{ position: "top" }}
                                onClick={() => onDeleteEquipmentClick()}>
                                <BsTrashFill className="icon" />
                            </Button>)}

                            {showIcon && <Button
                            className="button-icon"
                            tooltip="Attachments"
                            disabled={gridData?.length ? false : true}
                            onClick={onAttachmentIconClick}
                            tooltipOptions={{ position: "top" }}>
                            <BsPaperclip className="icon" />
                        </Button>}
                        {showIcon && <Button
                            className="button-icon"
                            tooltip="Show Drawing"
                            disabled={gridData?.length ? false : true}
                            onClick={onShowInDrawingClick}
                            tooltipOptions={{ position: "top" }}>
                            <BsBoxArrowInDownRight className="icon" />
                        </Button>}
                        {showIcon && <Button
                            className="button-icon"
                            tooltip="Show Zoomed"
                            disabled={gridData?.length ? false : true}
                            onClick={onShowZoomedClick}
                            tooltipOptions={{ position: "top" }}>
                            <BsZoomIn className="icon" />
                        </Button>}
                        {showIcon && <Button
                            className="button-icon"
                            tooltip="Delink"
                            disabled={gridData?.length ? false : true}
                            onClick={onDelinkIconClick}
                            tooltipOptions={{ position: "top" }}>
                            <BiUnlink className="icon" />
                        </Button>}
                        {isSearch ?
                        (
                        <Button
                            className="button-icon"
                            tooltip="Clear"
                            onClick={onSearchClearClick}
                            tooltipOptions={{ position: "top" }}>
                            <BsSearch className="icon" />
                            <span>&times;</span>
							 </Button>
                        ):(
                         <Button
                            className="button-icon"
                            tooltip="Search Address"
                            disabled={gridData?.length ? false : true}
                            onClick={onSearchIconClick}
                            tooltipOptions={{ position: "top" }}>
                            <BsSearch className="icon" />
                        </Button>
                       
                        )}
                         <Button
                            className="button-icon"
                            tooltip="Download"
                            disabled={gridData?.length ? false : true}
                            tooltipOptions={{ position: "top" }}
                            onClick={(e) => downloadRef.current?.toggle(e)}>
                            <FaDownload className="icon" />
                            <FaChevronDown className="p-button-text caret-icon" />
                        </Button>
                    </div>
                </div>
            </div>

            <>
                <EquipmentListData generalProperties={generalProperties} getTableColumns={handleTableColumns} uniqueEquipmentClassId={selectedEquipmentType} />
                {columns?.length > 0 && (
                    <DataTableComponent
                        rows={gridData}
                        cols={columns}
                        paginator={true}
                        dataKeyId="EquipmentId"
                        title={``}
                        pageDetails={pageDetails}
                        pageIndex={pageIndex}
                        onPageChanged={onPageChanged}
                        showCustomSearchCmp
                        getSelectedRowData={handleRowSelect}
                        onSortData={onSort}
                        defaultSelectedRows={selectedRows}
                        showroweditor={false}
                        onRowEditClick={onRowEditClick}
                        onRowEditValidation={onRowEditValidation}
                        onSaveRowEdit={onEquipmentRowEdited}
                        isSelectionFromParent={true}
                        selectedParentRows={selectedRows}
                        isTemplateMode={true}
						isTemplateFromParent={true}
						templateFromParent={inspectionGridColumnTemplate}
                    />
                )}

                <PopOver ref={settingRef}>
                    <ul>
                        <>
                            {renderDisplaySettings()}
                        </>
                    </ul>
                </PopOver>
                <PopOver ref={downloadRef}>
                    <ul>
                        <li onClick={onReportAllClick}>Report All</li>
                        <li onClick={onReportHighlightedClick}>Report Highlighted</li>
                        <li onClick={onExportAll}>Export All</li>
                        <li onClick={onExportHighlightedClick}>Export Highlighted</li>
                    </ul>
                </PopOver>
                {showEditEquipment && datafields && (
                    <EditEquipment
                    gridData={gridData}
                    selectedRow={selectedRows}
                    showModal={showEditEquipment}
                    setShowModal={setShowEditEquipment}
                    datafields={datafields}
                    onFormSubmit={onEquipmentUpdate}
                    isFromDrawing={true}
                    onHideModal={() => {
                        setShowEditEquipment(false);
                        dispatch(setEquipmentDatafields(""));
                    }}
                    uniqueEquipmentClassId={selectedRows[0]?.EquipmentTypeId}
                />
                )}
                {showDeletePrompt && (
                    <Dialog
                        header="Tech Advance +"
                        visible={showDeletePrompt}
                        style={{ width: "35vw" }}
                        footer={renderFooter()}
                        onHide={() => setShowDeletePrompt(false)}
                        className="delete-modal"
                    >
                        <p className="content">{message}</p>
                    </Dialog>
                )}



                {showEditEquipmentList &&
                    <EditEquipmentList
                        selectedPanel={selectedEquipmentGroup}
                        selectedTask={selectedTask}
                        showModal={showEditEquipmentList}
                        setShowModal={setShowEditEquipmentList} />}
                {showDisplaySetting && tableColumns?.length &&
                    <DisplaySetting
                        showModal={showDisplaySetting}
                        setShowModal={setShowDisplaySetting}
                        displayColumns={backupColumns}
                        setdisplayColumns={setTableColumns}
                        selectedCols={tableColumns}
                    />
                }
                {showAttachmentListModal ? (
					<AttachmentList
						showModal={showAttachmentListModal}
						setShowModal={setShowAttachmentListModal}
						uniqueInspectionTaskId={selectedTask?.uniqueInspectionTaskId}
						selectedEquipments={selectedRows}
					/>
				) : null}
                {showReportModal ? (
                <EquipmentSpecificEquipmentReport showModal={showReportModal}
                    setShowModal={setShowReportModal}
                    reportFlag={reportFlag}
                    selectedRows={selectedRows}
                    uniqueInspectionTaskId={selectedTask.uniqueInspectionTaskId}
                    filterType={selectedEquipmentType}
                    uniqueSystemId={selectedTask.system.uniqueSystemId}
                    uniqueDrawingId={selectedTreeNode[0]?.uniqueDrawingId}
                    sortByColumns={tableColumns}
                    searchKeyword={searchAddress}
                     />
                 ) : null}
                
                 <SearchAddress showModal={displaySearchModal} onSearch={onSearch} setShowModal={setDisplaySearchModal} ></SearchAddress>
                <InformationBox showInfoModal={displayInfoModal} setShowInfoModal={setDisplayInfoModal} okOnClick={onOkClick} message={message} />
                <ConfirmationBox showConfirmModal={displayConfirmModal} setShowConfirmModal={setDisplayConfirmModal} confirmAction={onConfirmClick} message={message} />
            </>
        </>
    );
};

export default memo(EquipmentSpecificList);