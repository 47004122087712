import React, { useCallback, useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { OverlayPanel } from "primereact/overlaypanel";
import PopOver from "components/common/PopOver/PopOver";
import InformationBox from "components/common/Message/InformationBox/InformationBox";
import ConfirmationBox from "components/common/Message/ConfirmationBox/ConfirmationBox";
import Table from "components/Table/Table";
import { CUSTOMIZATION_ATTRIBUTES_VALUE_COLS } from "../CommonAttibutesValuesData";
import AddCommonAttibutesValues from "../ManageCommonAttibutesValues/AddCommonAttibutesValues";
import EditCommonAttibutesValues from "../ManageCommonAttibutesValues/EditCommonAttibutesValues";
import moment from 'moment';
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../redux/hooks";
import { getCommonAttributeValues, onAddCommonAttributeValue, onEditCommonAttributeValue, checkIsAttributeValueInUse, setIsAttributeValueInUse, deleteCommonAttributeValue } from "../../../../redux/pages/Manage/Customization/Attributes/CommonAttributeSlice";
import CustomizationReport from "../Reports/CustomizationReports";
import { CUSTOMIZATION_ATTRIBUTES_TYPE } from "constants/common";
import { onExportReport } from "redux/common/report/customizeReportSlice";

interface Props {
    showModal: boolean;
    setShowModal: (state: boolean) => void;
    rowsSelected: any;
}

export const AttributesFieldValues: React.FC<Props> = ({
    showModal = false,
    setShowModal = () => { },
    rowsSelected = []
}) => {

    const dispatch = useDispatch<any>();
    const commonAttributeValues = useAppSelector((state) => state.commonAttribute.commonAttributeValues);
    const commonAttributeValuePageDetails = useAppSelector((state) => state.commonAttribute.commonAttributeValuePageDetails);
    const newCommonAtributeValue = useAppSelector((state) => state.commonAttribute.newCommonAtributeValue);
    const isAttributeValueInUse = useAppSelector((state) => state.commonAttribute.isAttributeValueInUse);
    
    const downloadRef = React.createRef<OverlayPanel>();

    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [pageIndex, setPageIndex] = useState(0);
    const [selectedRows, setSelectedRows] = useState<any>([]);
    const [message, setMessage] = useState("");
    const [displayInfoModal, setDisplayInfoModal] = useState(false);
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
    const [showAddCommonAttributeValue, setShowAddCommonAttributeValue] = useState(false);
    const [showEditCommonAttributeValue, setShowEditCommonAttributeValue] = useState(false);
    const [isCommonAttributeValueAdd, setIsCommonAttributeValueAdd] = useState(false);
    const [isAttributeDelete, setIsAttributeDelete] = useState(false);
    const [showReportModal, setShowReportModal] = useState(false);
    const [reportFlag, setReportFlag] = useState<any>('all');
    const [sortField, setSortField] = useState('')
    const [sortOrder, setSortOrder] = useState('');
    const [currentStart, setCurrentStart] = useState(1);

    useEffect(() => {
        if (rowsSelected && rowsSelected.length) {
            if (rowsSelected[0].uniqueAttributeId) {
                dispatch(getCommonAttributeValues(rowsSelected[0].uniqueAttributeId, 1,"","","", setMessage, setDisplayInfoModal, setIsLoaded));
            }
        }
    }, [rowsSelected]);

    useEffect(() => {
        if (newCommonAtributeValue) {
          setIsCommonAttributeValueAdd(true)
        }
    }, [newCommonAtributeValue])

    useEffect(() => {
        if (isAttributeValueInUse && isAttributeDelete) {
          if (isAttributeValueInUse?.isAttributeValueInUse) {
            setMessage("Selected Attribute Value is in use, cannot be deleted");
            setDisplayInfoModal(true);
          } else {
            setMessage("Are you sure you want to delete the selected Attribute Value?");
            setDisplayConfirmationModal(true);
          }
          setIsAttributeDelete(false);
          dispatch(setIsAttributeValueInUse(""))
        }
    }, [isAttributeValueInUse]);

    const handleRowSelect = useCallback((data: any) => {
        setSelectedRows([...data]);
    }, []);

    const onPageChange = (options) => {
        if (options !== pageIndex) {
            setPageIndex(options);
            let currentStart = options * commonAttributeValuePageDetails?.currentLength + 1;
            setCurrentStart(currentStart);
            dispatch(getCommonAttributeValues(rowsSelected[0].uniqueAttributeId, currentStart,"","","", setMessage, setDisplayInfoModal, setIsLoaded));
        }
    }

    const onSort = (event) => {
        if (event) {
            let sortDirection: string;
            if (event.sortOrder === 1)
                sortDirection = "ASC"
            else
                sortDirection = "DESC"
            setSortField(event.sortField);
            setSortOrder(sortDirection);
            dispatch(getCommonAttributeValues(rowsSelected[0].uniqueAttributeId, currentStart,"",event.sortField,sortDirection, setMessage, setDisplayInfoModal, setIsLoaded));
        }
    }

    const onAddCommonAttributeValueClick = (data) => {
        let value="";
        if (rowsSelected[0].attributeType==="Date") {
            value=  moment(data?.Value).format("yyyy-MM-DD").toString();
        } else if (rowsSelected[0].attributeType==="Date Time"){
            value=  moment(data?.Value).format("yyyy-MM-DD,h:mm:ss a").toString();
        } else {
            value = data.Value;
        }
        let body = {
            attributeValue: value,
        };
        dispatch(onAddCommonAttributeValue(body, rowsSelected[0].uniqueAttributeId, setMessage, setDisplayInfoModal, setShowAddCommonAttributeValue));
    };

    const editFieldValueHandler = () => {
        if (selectedRows && selectedRows.length) {
            if (selectedRows.length === 1) {
                if (selectedRows[0].attributeName === "Barcode") {
                    setMessage("This Attribute cannot be edited.");
                    setDisplayInfoModal(true);
                } else {
                    setShowEditCommonAttributeValue(true);
                }
            } else {
                setMessage("This operation can be performed only one row at a time");
                setDisplayInfoModal(true);
            }
        } else {
            setMessage("Select an Attribute Value");
            setDisplayInfoModal(true);
        }
    }

    const onEditCommonAttributeValueClick = (data) => {
        let value="";
        if (rowsSelected[0].attributeType==="Date") {
            value=  moment(data?.Value).format("yyyy-MM-DD").toString();
        } else if (rowsSelected[0].attributeType==="Date Time"){
            value=  moment(data?.Value).format("yyyy-MM-DD,h:mm:ss a").toString();
        } else {
            value = data.Value;
        }
        let body = {
            attributeValue: value,
        };
        dispatch(onEditCommonAttributeValue(rowsSelected[0].uniqueAttributeId, selectedRows[0].dataFieldLookUpId, body, setMessage, setDisplayInfoModal, setShowEditCommonAttributeValue));
    };

    const deleteFieldValueHandler = () => {
        if (selectedRows && selectedRows.length) {
            if (selectedRows.length === 1) {
                if (selectedRows[0].attributeName === "Barcode") {
                    setMessage("This Attribute cannot be deleted.");
                    setDisplayInfoModal(true);
                } else {
                    setIsAttributeDelete(true);
                    dispatch(checkIsAttributeValueInUse(rowsSelected[0].uniqueAttributeId, selectedRows[0].dataFieldLookUpId));
                }
            } else {
                setMessage("This operation can be performed only one row at a time");
                setDisplayInfoModal(true);
            }

        } else {
            setMessage("Select an Attribute Value");
            setDisplayInfoModal(true);
        }
    }

    const onDeleteConfirm = () => {
        setDisplayConfirmationModal(false);
        dispatch(deleteCommonAttributeValue(rowsSelected[0].uniqueAttributeId, selectedRows[0].dataFieldLookUpId, setSelectedRows, setMessage, setDisplayInfoModal));
    }

    const onOkClick = () => {
        if (isCommonAttributeValueAdd) {
            setShowAddCommonAttributeValue(true);
            setIsCommonAttributeValueAdd(false);
        }
    }

    const onReportAllClick = () => {
        setReportFlag('all');
        setShowReportModal(true);
    };

    const onReportHighlightedClick = () => {
        setReportFlag('highlighted');
        setShowReportModal(true);
    };

    const getSelectedValuesIds = () => {
        let params: any = [];
        selectedRows.map(item => {
            params.push(item.dataFieldLookUpId);
        });
        return params;
    };

    const onExportAll = () => {
        const reportParameters = {
            exportParameters: {
                uniqueDataFieldId: rowsSelected[0]?.uniqueAttributeId,
                uniqueAttributeId: "",
                attributeName: rowsSelected[0]?.attributeName,
                sortColumn: sortField,
                sortOrder: sortOrder,
                search: ''
            },
            exportType: "Object Attribute Values"
        };
        dispatch(onExportReport(reportParameters, "Common Attribute Values"));
    };

    const onExportHighlightedClick = () => {
        const reportParameters = {
            exportParameters: {
                uniqueDataFieldId: rowsSelected[0]?.uniqueAttributeId,
                uniqueAttributeId: getSelectedValuesIds(),
                attributeName: rowsSelected[0]?.attributeName,
                sortColumn: sortField,
                sortOrder: sortOrder,
                search: ''
            },
            exportType: "Object Attribute Values"
        };
        dispatch(onExportReport(reportParameters, "Common Attribute Values"));
    };

    return (
        <>
            <Dialog
                header="Common Attribute Values"
                visible={showModal}
                style={{ width: "50vw"}}
                onHide={() => setShowModal(false)}
                footer={false}
                className="display-settings personal selectsite"
            >
                {isLoaded ? (
                    <>
                        <div className="content-box mb-1">
                            <div className="header d-flex align-items-center">
                                <span className="title">Attribute Name:</span>
                                <span className="value">{rowsSelected[0]?.attributeName}</span>
                            </div>
                        </div>
                        <Table
                            rows={commonAttributeValues}
                            cols={CUSTOMIZATION_ATTRIBUTES_VALUE_COLS.COMMON_ATTRIBUTES_VALUE_COLS}
                            title={``}
                            dataKeyId="dataFieldLookUpId"
                            searchboxfield={false}
                            showroweditor={false}
                            paginator
                            pageDetails={commonAttributeValuePageDetails}
                            pageIndex={pageIndex}
                            onPageChanged={onPageChange}
                            onSortData={onSort}
                            getSelectedRowData={handleRowSelect}
                            onAddClick={() => setShowAddCommonAttributeValue(true)}
                            onEditClick={editFieldValueHandler}
                            onDeleteClick={deleteFieldValueHandler}
                            onDownloadClick={(e) => downloadRef.current?.toggle(e)}
                            defaultSelectedRows={commonAttributeValues.length > 0 ? [commonAttributeValues[0]] : []}
                        />
                    </>
                ) : (<></>)
                }

                {showAddCommonAttributeValue ? (
                    <AddCommonAttibutesValues
                        showModal={showAddCommonAttributeValue}
                        setShowModal={setShowAddCommonAttributeValue}
                        commonAttributeName={rowsSelected[0].attributeName}
                        commonAttributeType={rowsSelected[0].attributeType}
                        maxLength={rowsSelected[0].maxlength}
                        onAddCommonAttributeValueClick={onAddCommonAttributeValueClick}
                    />
                    ) : null
                }

                {showEditCommonAttributeValue ? (
                    <EditCommonAttibutesValues
                        showModal={showEditCommonAttributeValue}
                        selectedRows={selectedRows}
                        commonAttributeId={rowsSelected[0].uniqueAttributeId}
                        commonAttributeName={rowsSelected[0].attributeName}
                        commonAttributeType={rowsSelected[0].attributeType}
                        maxLength={rowsSelected[0].maxlength}
                        setShowModal={setShowEditCommonAttributeValue}
                        onEditCommonAttributeValueClick={
                        onEditCommonAttributeValueClick
                        }
                    />
                ) : null}

                <PopOver ref={downloadRef}>
                    <ul>
                        <li onClick={onReportAllClick}>Report All</li>
                        <li onClick={onReportHighlightedClick}>Report Highlighted</li>
                        <li onClick={onExportAll}>Export All</li>
					    <li onClick={onExportHighlightedClick}>Export Highlighted</li>
                    </ul>
                </PopOver>

                {showReportModal ? (
                    <CustomizationReport showModal={showReportModal}
                        setShowModal={setShowReportModal}
                        reportFlag={reportFlag}
                        selectedRows={selectedRows}
                        attributeId={rowsSelected[0]?.uniqueAttributeId}
                        attributeName={rowsSelected[0]?.attributeName}
                        selectedTab={CUSTOMIZATION_ATTRIBUTES_TYPE.COMMON_ATTRIBUTES_VALUES}
                        sortByColumns={CUSTOMIZATION_ATTRIBUTES_VALUE_COLS.COMMON_ATTRIBUTES_VALUE_COLS}
                        searchKeyword={''}
                        sortColumn={sortField}
                        sortOrder={sortOrder} />
                ) : null}

                <InformationBox
                    showInfoModal={displayInfoModal}
                    setShowInfoModal={setDisplayInfoModal}
                    message={message}
                    okOnClick={onOkClick}
                />

                <ConfirmationBox
                    showConfirmModal={displayConfirmationModal}
                    setShowConfirmModal={setDisplayConfirmationModal}
                    confirmAction={onDeleteConfirm}
                    message={message}
                />
            </Dialog>
        </>
    );
};

export default AttributesFieldValues;
