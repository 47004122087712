import React, { memo, useEffect, useRef, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ListGroup } from 'react-bootstrap';
import { BiDotsVerticalRounded } from 'react-icons/bi';

interface CustomizeReportFieldOrderPropType {
    onDragEnded?: (items) => void;
    data?: any;
}
const CustomizeReportFieldOrder: React.FC<CustomizeReportFieldOrderPropType> = (props) => {
    const { onDragEnded = () => { }, data = [] } = props;

    const [datafields, setDatafields] = useState<any>([]);
    const [activeKey, setActiveKey] = useState(null);
    const listGroupRef = useRef<any>(null);

    useEffect(() => {
        let covData = JSON.parse(JSON.stringify(data));
        setDatafields(covData);
    }, [data])

    const handleDragEnd = (result: any) => {
        if (!result.destination) return;
        const items = Array.from(datafields);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setDatafields(items);
        onDragEnded(items)
    };

    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="droppable">
                {(provided) => (
                    <div className="col-list__wrapper" ref={listGroupRef}>
                        <ListGroup {...provided.droppableProps} ref={provided.innerRef}>
                            {datafields &&
                                datafields.map((data: any, index: any) => {
                                    return (
                                        <Draggable key={index} draggableId={index.toString()} index={index}>
                                            {(provided) => (
                                                <ListGroup.Item
                                                    key={index}
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    className={`${index === activeKey ? "active" : ""}`}
                                                    onClick={() => setActiveKey(index)}
                                                    onMouseLeave={() => setActiveKey(null)}
                                                >
                                                    <div className="grip-dots">
                                                        <BiDotsVerticalRounded />
                                                        <BiDotsVerticalRounded />
                                                    </div>
                                                    <div className="col-name__wrapper">
                                                        <div className="col-name">
                                                            <span>{data?.name}</span>
                                                        </div>
                                                    </div>
                                                </ListGroup.Item>
                                            )}
                                        </Draggable>
                                    );
                                })}
                            {provided.placeholder}
                        </ListGroup>
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    )
}

export default memo(CustomizeReportFieldOrder)