import { InputText } from 'primereact/inputtext';

const textEditor = (options) => {
    return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
};
export const INSPECTION_REPORTSCONFIGS_COLS = {
    REPORTSCONFIGS_COLS: [
        {
            field: 'reportConfig',
            header: 'Report Config',
            editor: (options) => textEditor(options),
            style: { flex: '1 0 150px' },
            sortable: true,
        },
        {
            field: 'uploadedOn',
            header: 'Uploaded On',
            editor: (options) => textEditor(options),
            style: { flex: '1 0 150px' },
            sortable: true,
        }
        
    ]
};
