import { useEffect, useState, useRef, memo } from "react";
import { Controller, useForm } from "react-hook-form";
import { classNames } from "primereact/utils";
import { useNavigate } from "react-router-dom";

import Contracts from "./Contracts/Contracts";
import Systems from "./Systems/Systems";
import Questions from "./Questions/Questions";
import { useAppSelector } from "redux/hooks";
import { useDispatch } from "react-redux";
import { getReportGroups, setDisplayColumns, setIsDisplayColumnsReadyToUpdate } from "../../../redux/pages/Manage/InspectionSettings/ReportMapping/ReportMappingslice";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { FaDownload, FaSave } from "react-icons/fa";
import { IoIosFunnel } from "react-icons/io";
import { BsArrowRight } from "react-icons/bs";
import Breadcrumb from "components/common/Breadcrumb/Breadcrumb";
import { APP_ROUTES } from "constants/AppRoutes";
import { getCountryList } from "redux/pages/Administration/administrationSlice";

interface ListItem {
    id: number;
    name: string;
    selected: boolean;
}
type CountryListItem = {
    id: string | number;
    countryName: string;
    imgSrc: any;
    altText?: string;
    active: boolean;
};
export const DEFAULT_HEADER_LIST = [
    {
        id: 1,
        name: "Contract",
        selected: true,
    },
    {
        id: 2,
        name: "System",
        selected: false,
    },
    {
        id: 3,
        name: "Questions",
        selected: false,
    },
];
const ReportMapping = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<any>();
    const [filterCol, setfilterCol] = useState<any>(true);
    const [headerList, setHeaderList] = useState<Array<ListItem>>([]);
    const [selectedCountryId, setSelectedCountryId] = useState<number>(0);
    const [selectedGroupId, setselectedGroupId] = useState<any>();
    const checkBoxGridRef = useRef<any>();
    const [countryLookup, setCountryLookup] = useState<any>([]);
    const [selectedCountry, setSelectedCountry] = useState<string>("");
    const countryList = useAppSelector((state) => state.administration.countryList);
    const [selectedFields, setSelectedFields] = useState({ countryId:""});

    const {
        control,
        formState: { errors },
        handleSubmit,
        setValue,
        reset,
        watch,
    } = useForm();
    const reportGroups = useAppSelector((state) => state.reportMapping.reportGroups);
    useEffect(() => {
        if(selectedCountryId !== 0){
        dispatch(getReportGroups(selectedCountryId));
        dispatch(setDisplayColumns([]))
        dispatch(setIsDisplayColumnsReadyToUpdate(true))
        DEFAULT_HEADER_LIST.forEach(element => {
            if (element.id === 1)
                element.selected = true;
            else
                element.selected = false;
        });
        setHeaderList(DEFAULT_HEADER_LIST);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }
    }, [selectedCountryId]);

    useEffect(() => {
        dispatch(getCountryList());
      }, [dispatch]);

    useEffect(() => {
        if (countryList && countryList.countries.length > 0 && countryList.countries !== undefined) {
          let country: any = [];
          countryList.countries.map((countryItem: any, key: any) => {
            country.push({ "code": countryItem.countryId, "name": countryItem.countryName.trim() })
          });
          setSelectedFields({ countryId:country[0].code })
          setCountryLookup(country);
          setSelectedCountryId(Number(country[0].code));
          setSelectedCountry(country[0]);
        }
      }, [countryList]);
     
    useEffect(() => {
        if (reportGroups && reportGroups.length) {
            setValue("reportGroup", reportGroups[0]?.inspectionReportGroupId);
            setselectedGroupId(reportGroups[0]?.inspectionReportGroupId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportGroups]);
    const handleListClick = (id: number) => {
        const updatedList = headerList.map((item: ListItem) => {
            if (item?.id === id) {
                item.selected = true;
            } else {
                item.selected = false;
            }

            return item;
        });

        setHeaderList(updatedList);
    };
  
    const onReportGroupLookupChange = (data: any) => {
        dispatch(setIsDisplayColumnsReadyToUpdate(true))
        dispatch(setDisplayColumns([]))
        setselectedGroupId(data);
    };
    const onCountryChange = (data:any) => {
        dispatch(setIsDisplayColumnsReadyToUpdate(true))
        dispatch(setDisplayColumns([]))
            setSelectedCountryId(Number(data.code));
            dispatch(getReportGroups(data.code));
    }
    return (
        <div className="Parent">
            <section className="d-flex align-items-center">
                <Breadcrumb title="Inspection Settings" classNames="report-header" handleGoBackClick={() => navigate(`/${APP_ROUTES.MANAGE_VIEW}`)} />
                <div className="breadcrumb-right-arrow ml-3 mr-3">
                    <BsArrowRight className="icon" />
                </div>
                <div className="breadcrumb-sub-page-title">
                    <span>Report Mapping</span>
                </div>
            </section>
            <div className="table-header-wrapper">
                <div className="section-wrapper">
                    <div className="tab-col">
                        <ul>
                            {headerList?.length > 0 &&
                                headerList.map((item: ListItem) => (
                                    <li
                                        key={item?.id}
                                        role="button"
                                        className={classNames("item", { selected: item?.selected })}
                                        onClick={() => handleListClick(item?.id)}
                                    >
                                        {item?.name}
                                    </li>
                                ))}
                        </ul>
                    </div>
                    <div className="field mb-0">
                        <span className="p-float-label">
                            <Dropdown
                                    inputId="reportGroup"
                                    className={classNames("w-100 error-tooltip")}
                                    value={selectedCountry}
                                    onChange={(e) =>{  setSelectedCountry(e.value);onCountryChange(e.value)}}
                                    options={countryLookup}
                                     optionLabel="name" 
                                />
                                <label className="mandatory" htmlFor="dropdown">
                                    Country
                                </label>
                            </span>
                        </div>
                    <div className="field mb-0">
                        <span className="p-float-label">
                            <Controller
                                name="reportGroup"
                                control={control}
                                rules={{
                                    required: "This field is required.",
                                }}
                                render={({ field, fieldState }) => (
                                    <Dropdown
                                        inputId="reportGroup"
                                        className={classNames("w-100 error-tooltip", {
                                            error: fieldState.invalid,
                                        })}
                                        {...field}
                                        value={field.value}
                                        onChange={(e) => {
                                            field.onChange(e.value);
                                            onReportGroupLookupChange(e.value);
                                        }}
                                        options={reportGroups}
                                        optionLabel="inspectionReportGroupName"
                                        optionValue="inspectionReportGroupId"
                                    />
                                )}
                            />
                            <label className="mandatory" htmlFor="Country">
                                Report Group
                            </label>
                        </span>
                    </div>
                </div>
                <div className="action-btns">
                    <Button className="button-icon" tooltip="Save" tooltipOptions={{ position: "top" }} onClick={() => checkBoxGridRef?.current?.onSaveclick()}>
                        <FaSave className="icon" />
                    </Button>
                    <Button className="button-icon" tooltip="Download" tooltipOptions={{ position: "top" }} onClick={() => checkBoxGridRef?.current?.onDownloadclick()}>
                        <FaDownload className="icon" />
                    </Button>
                    <Button className="button-icon" tooltip="filter" tooltipOptions={{ position: "top" }} onClick={() => checkBoxGridRef?.current?.onShowDisplaySettings()}>
                        <IoIosFunnel className="icon" />
                    </Button>
                </div>
            </div>
            <div className="grid-wrapper">
                {headerList.map((item, key) => {
                    if (item.id == 1 && item.selected === true) {
                        return <Contracts ref={checkBoxGridRef} countryId={selectedCountryId} groupId={selectedGroupId} key={key} />;
                    } else if (item.id == 2 && item.selected === true) {
                        return <Systems ref={checkBoxGridRef} countryId={selectedCountryId} groupId={selectedGroupId} key={key} />;
                    } else if (item.id == 3 && item.selected === true) {
                        return <Questions ref={checkBoxGridRef} countryId={selectedCountryId} groupId={selectedGroupId} key={key} />;
                    }
                })}
            </div>
        </div>
    );
};

export default memo(ReportMapping);
