import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../redux/hooks";
import InformationBox from "components/common/Message/InformationBox/InformationBox";
import ConfirmationBox from "components/common/Message/ConfirmationBox/ConfirmationBox";
import { onCheckSystemEquipmentGroupInUse, onDeleteSystemEquipmentGroup, setPanelInUse, setSystemEquipmentGroups } from "redux/pages/Inspection/Systems/systemSlice";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

interface Props {
  selectedRows: any;
  showModal: boolean;
  setShowModal: (state: boolean) => void;
}

export const SystemEquipmentGroups: React.FC<Props> = ({
  selectedRows = [],
  setShowModal = () => { },
  showModal = false,
}) => {
  const systemEquipmemtGroups = useAppSelector((state) => state.system.systemEquipmemtGroups);
  const panelInUse = useAppSelector((state) => state.system.panelInUse);
  const [selectedEquipmentGroup, setSelectedEquipmentGroup] = useState<any>([]);
  const [displayInfoModal, setDisplayInfoModal] = useState(false);
  const [displayConfirmModal, setDisplayConfirmModal] = useState(false);
  const [message, setMessage] = useState("");
  const dispatch = useDispatch<any>();

  useEffect(() => {
    if (systemEquipmemtGroups && systemEquipmemtGroups.length) {
      setSelectedEquipmentGroup([systemEquipmemtGroups[0]]);
    } else {
      setSelectedEquipmentGroup([]);
    }
  }, [dispatch,systemEquipmemtGroups]);

  useEffect(() => {
    if (panelInUse && panelInUse !== undefined) {
      if (panelInUse.isPanelInUse === true) {
        setMessage("Selected Equipment Group is linked to an active inspection task, Close the Task to delete the Equipment Group");
        setDisplayInfoModal(true);
      }
      else if (panelInUse.isPanelInUse === false) {
        dispatch(onDeleteSystemEquipmentGroup(selectedRows[0]?.uniqueSystemId, selectedEquipmentGroup[0]?.equipmentId, setSelectedEquipmentGroup, setDisplayInfoModal, setMessage))
      }
    }
  }, [dispatch, panelInUse])

  const onOkClick = () => {
    setDisplayInfoModal(false);
    dispatch(setPanelInUse([]));
  }

  const onConfirmClick = () => {
    setDisplayConfirmModal(false);
    dispatch(setPanelInUse([]));
    dispatch(onCheckSystemEquipmentGroupInUse(selectedRows[0]?.uniqueSystemId, selectedEquipmentGroup[0]?.equipmentId))
  }

  const onCancelClick = () => {
    setDisplayInfoModal(false);
    setDisplayConfirmModal(false);
    setShowModal(false);
    dispatch(setPanelInUse([]));
  }

  const onDeleteClick = () => {
    if (selectedEquipmentGroup.length < 1) {
      setMessage("Select an Equipment Group");
      setDisplayInfoModal(true);
    } else if (selectedEquipmentGroup.length > 1) {
      setMessage("This operation can be performed only one row at a time");
      setDisplayInfoModal(true);
    } else if (selectedEquipmentGroup.length === 1) {
      setMessage("Selected Equipment Group and linked equipment will be lost from all Inspection Tasks. Do you want to proceed?");
      setDisplayConfirmModal(true);
    }
  }

  return (
    <>
      <Dialog
        header="System Equipment Groups"
        visible={showModal}
        style={{ width: "70vw" }}
        onHide={() => { setShowModal(false); dispatch(setSystemEquipmentGroups([])); }}
        className="s-modal equipment-groups"
      >
        <div className="content-box">
          <div className="header-text">
            <span className="label">System: </span>
            <span className="value">{selectedRows[0]?.systemName}</span>
          </div>
          <div className="table-wrap">       
            <div className="custom-table">
             <DataTable
                value={systemEquipmemtGroups}
                selection={selectedEquipmentGroup}
                selectionMode="multiple"
                onSelectionChange={(e) => {setSelectedEquipmentGroup(e.value)}}
                dataKey="equipmentId"
                responsiveLayout="scroll"
              >
                <Column field="address" header="Address"></Column>
                <Column field="panelType" header="Panel Type"></Column>
                <Column field="equipmentName" header="Equipment Name"></Column>
                <Column field="inspectionTask" header="Inspection Task(s)"></Column>
              </DataTable>
              </div>
          </div>
          <div className="footer text-left">
            <Button
              label="Cancel"
              onClick={() => {
                setShowModal(false);
                setSelectedEquipmentGroup([]);
                dispatch(setSystemEquipmentGroups([]));
              }}
              className="cancel-btn"
            />

            <Button
              label="Delete"
              onClick={onDeleteClick}
              className="submit-btn"
            />
          </div>
        </div>
      </Dialog>
      <InformationBox showInfoModal={displayInfoModal} setShowInfoModal={setDisplayInfoModal} okOnClick={onOkClick} message={message} />
      <ConfirmationBox showConfirmModal={displayConfirmModal} setShowConfirmModal={setDisplayConfirmModal} confirmAction={onConfirmClick} cancelAction={onCancelClick} message={message} />
    </>
  );
};

export default SystemEquipmentGroups;
