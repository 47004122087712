import React, { useState, useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";

import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";

import { classNames } from "primereact/utils";

import { BsExclamationCircleFill } from "react-icons/bs";

import { useAppSelector } from "../../../redux/hooks";
import AddModal from "components/Table/AddModal/AddModal";
import { useDispatch } from "react-redux";
import { getSalesOfficeLookup } from "redux/pages/Manage/OrganizationSettings/branchOfficeSlice";

interface IBranchOffice {
  showModal: boolean;
  setShowModal: (state: boolean) => void;
  onAddBranchOffice: (submitData: any) => void;
}

type FormData = {
  name: string;
  salesoffice: string;
  address: string;
  city: string;
  phone: string;
  fax: string;
  email: string;
  FALicenceNumber: string;
  SPKLicenceNumber: string;
  EXTLicenceNumber: string;
};

export const AddBranchOffice: React.FC<IBranchOffice> = ({
  showModal = false,
  setShowModal = () => {},
  onAddBranchOffice = () => {},
}) => {
  const addUserForm: any = useRef();
  const [salesLookup, setSalesLookup] = useState([{ name: "", code: "" }]);
  const salesOfficeList = useAppSelector(
    (state) => state.branchOffice.salesOfficeLookup
  );
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      name: "",
      salesoffice: "",
      address: "",
      city: "",
      phone: "",
      fax: "",
      email: "",
      FALicenceNumber: "",
      SPKLicenceNumber: "",
      EXTLicenceNumber: "",
    },
  });

  const dispatch = useDispatch<any>();

  useEffect(() => {
    dispatch(getSalesOfficeLookup())
  }, [dispatch])

  useEffect(() => {
    if (salesOfficeList && salesOfficeList?.length) {
      let salesOffice: { name: string; code: string }[] = [];
      salesOffice.push({ name: "Select", code: "Select"});
      salesOfficeList?.map((item: any) => {
        return salesOffice.push({ name: item.name, code: item.code });
      });
      setSalesLookup(salesOffice);
      setValue("salesoffice",salesOffice[0].name)
    }
   
  }, [salesOfficeList, setValue]);
  const formSubmitHandler = (data: any) => {
    addUserForm.current.requestSubmit();
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && (
        <span className="tooltip-text">
          <BsExclamationCircleFill />
          {errors[name].message}
        </span>
      )
    );
  };

  const onAddFormSubmit = (submitData: any) => {
    let salesId = "";
    salesOfficeList.map((salesItem: any, key: any) => {
      if (submitData.salesoffice === salesItem.name) {
        salesId = salesItem.uniqueSalesOfficeId;
      }
      return null;
    });
    let ReviseData = 
      {
        name: submitData.name.trim(),
        address: submitData.address.trim(),
        cityStateZip: submitData.city.trim(),
        phone: submitData.phone.trim(),
        email: submitData.email,
        fax: submitData.fax.trim(),
        FALicenceNumber: submitData.FALicenceNumber.trim(),
        SPKLicenceNumber: submitData.SPKLicenceNumber.trim(),
        EXTLicenceNumber: submitData.EXTLicenceNumber.trim(),
        uniqueSalesOfficeId: salesId,
      }
    onAddBranchOffice && onAddBranchOffice(ReviseData);
  };

  const onError = (errors: any, e: any) => {};

  return (
    <>
      <AddModal
        header="New Branch Office"
        showModal={showModal}
        isAdd={true}
        setShowModal={setShowModal}
        style={{ width: "35vw", maxHeight: "100%" }}
        OnSaveClick={formSubmitHandler}
      >
        <form
          onSubmit={handleSubmit(onAddFormSubmit, onError)}
          ref={addUserForm}
        >
          <div className="row px-2 pt-2">
            <div className="field col-12 md:col-2 mb-0">
              <span className="p-float-label">
                <Controller
                  name="name"
                  control={control}
                  rules={{
                    required: "Branch Office Name is required.",
                    validate: (value) => {
                      return value.trim().length<=0
                        ?"Branch Office Name is required.":true
                    }
                  }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id="name"
                      className={classNames("w-100 error-tooltip", {
                        error: fieldState.invalid,
                      })}
                      {...field}
                    />
                  )}
                />
                <label className="mandatory" htmlFor="inputtext">
                  Name
                </label>
                {getFormErrorMessage("name")}
              </span>
            </div>
          </div>

          <div className="row px-2">
            <div className="field col-12 md:col-2 mb-0">
              <span className="p-float-label">
                <Controller
                  name="address"
                  control={control}
                  rules={{
                    required: "Address is required.",
                    validate: (value) => {
                      return value.trim().length<=0
                        ?"Address is required.":true
                    }
                  }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id="address"
                      className={classNames("w-100 error-tooltip", {
                        error: fieldState.invalid,
                      })}
                      {...field}
                    />
                  )}
                />
                <label className="mandatory" htmlFor="inputtext">
                  Address
                </label>
                {getFormErrorMessage("address")}
              </span>
            </div>
          </div>

          <div className="row px-2">
            <div className="field col-12 md:col-2 mb-0">
              <span className="p-float-label">
                <Controller
                  name="city"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputText
                      id="city"
                      className={classNames("w-100 error-tooltip", {
                        error: fieldState.invalid,
                      })}
                      {...field}
                    />
                  )}
                />
                <label htmlFor="inputtext">City, State, Zip</label>
              </span>
            </div>
          </div>

          <div className="row px-2">
            <div className="field col-12 md:col-2 mb-0">
              <span className="p-float-label">
                <Controller
                  name="phone"
                  control={control}
                  rules={{pattern:{value:/^[0-9!@#$ %^&*()_+\-=\[\]{};':"\\|,.<>\/?NA]*$/,message:"Enter a valid Phone Number"}}}
                  render={({ field, fieldState }) => (
                    <InputText
                      id="phone"
                      className={classNames("w-100 error-tooltip", {
                        error: fieldState.invalid,
                      })}
                      {...field}
                    />
                  )}
                />
                <label htmlFor="inputtext">Phone</label>
                {getFormErrorMessage("phone")}
              </span>
            </div>
          </div>

          <div className="row px-2">
            <div className="field col-12 md:col-2 mb-0">
              <span className="p-float-label">
                <Controller
                  name="fax"
                  control={control}
                  rules={{pattern:{value:/^[0-9!@#$ %^&*()_+\-=\[\]{};':"\\|,.<>\/?NA]*$/,message:"Enter a valid Fax"}}}
                  render={({ field, fieldState }) => (
                    <InputText
                      id="fax"
                      className={classNames("w-100 error-tooltip", {
                        error: fieldState.invalid,
                      })}
                      {...field}
                    />
                  )}
                />
                <label htmlFor="inputtext">Fax</label>
                {getFormErrorMessage("fax")}
              </span>
            </div>
          </div>

          <div className="row px-2">
            <div className="field col-12 md:col-2 mb-0">
              <span className="p-float-label">
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    pattern:  { value: /^NA$|\S+@\S+\.\S+$/, message: 'Enter a valid Email' },
                }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id="email"
                      className={classNames("w-100 error-tooltip", {
                        error: fieldState.invalid,
                      })}
                      {...field}
                    />
                  )}
                />
                <label htmlFor="inputtext">Email</label>
                {getFormErrorMessage("email")}
              </span>
            </div>
          </div>

          <div className="row px-2">
            <div className="field col-12 md:col-2 mb-0">
              <span className="p-float-label">
                <Controller
                  name="FALicenceNumber"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputText
                      id="FA License Number"
                      className={classNames("w-100 error-tooltip", {
                        error: fieldState.invalid,
                      })}
                      {...field}
                    />
                  )}
                />
                <label htmlFor="inputtext">FA License Number</label>
                {getFormErrorMessage("FA License Number")}
              </span>
            </div>
          </div>

          <div className="row px-2">
            <div className="field col-12 md:col-2 mb-0">
              <span className="p-float-label">
                <Controller
                  name="SPKLicenceNumber"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputText
                      id="SPK License Number"
                      className={classNames("w-100 error-tooltip", {
                        error: fieldState.invalid,
                      })}
                      {...field}
                    />
                  )}
                />
                <label htmlFor="inputtext">SPK License Number</label>
                {getFormErrorMessage("SPK License Number")}
              </span>
            </div>
          </div>

          <div className="row px-2">
            <div className="field col-12 md:col-2 mb-0">
              <span className="p-float-label">
                <Controller
                  name="EXTLicenceNumber"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputText
                      id="EXT License Number"
                      className={classNames("w-100 error-tooltip", {
                        error: fieldState.invalid,
                      })}
                      {...field}
                    />
                  )}
                />
                <label htmlFor="inputtext">EXT License Number</label>
                {getFormErrorMessage("EXT License Number")}
              </span>
            </div>
          </div>

          <div className="row px-2">
            <div className="field col-12 md:col-2">
              <span className="p-float-label">
                <Controller
                  name="salesoffice"
                  control={control}
                  rules={{
                    required: "Sales Offie is required.",
                    validate:(value)=>{
                      return value==="Select"?"Select a Sales Office":undefined;
                    }
                  }}
                  render={({ field, fieldState }) => (
                    <Dropdown
                      inputId="salesoffice"
                      className={classNames("w-100 error-tooltip", {
                        error: fieldState.invalid,
                      })}
                      {...field}
                      value={field.value}
                      placeholder="Select"
                      onChange={(e) => field.onChange(e.value)}
                      options={salesLookup}
                      optionLabel="name"
                      optionValue="name"
                    />
                  )}
                />
                <label className="mandatory" htmlFor="Country">
                  Sales Office
                </label>
                {getFormErrorMessage("salesoffice")}
              </span>
            </div>
          </div>
        </form>
      </AddModal>
    </>
  );
};

export default AddBranchOffice;
