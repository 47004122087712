import React from "react";

const ReleaseNotesDocument = () => {
    return (
        <>
            <h4 className="doc-title mb-2">
                Release Notes
            </h4>
            
            <p>
                Release Notes provides the latest update information grouped by Update / release dates. 
                That is, all the updates done in a particular release will be listed under the release date.
            </p>

        </>
    );
}

export default ReleaseNotesDocument;