import React, { useEffect, useState,useCallback } from 'react';
import { useAppSelector } from 'redux/hooks';
import { DataTableComponent } from 'components/Table/DataTable';
import { DEFICIENCY_LIST_COLS } from './DeficiencyListData';
import { Dialog } from 'primereact/dialog';
import { onUpdateDeficiencyList,onGetInspectionDeficiencyList } from 'redux/pages/Reports/ProvenOutcomesSlice';
import { useDispatch } from "react-redux";
import InformationBox from 'components/common/Message/InformationBox/InformationBox';
import moment from 'moment';
interface Props {
	showModal: boolean;
	setShowModal: (state: boolean) => void;
	systemId: any;
	selectedProjectId: any;
}

export const DeficiencyList: React.FC<Props> = ({
	showModal = false,
	setShowModal = () => {},
	systemId = "",
	selectedProjectId = "",
}) => {

	const inspectionDeficiencyPageDetails = useAppSelector(
		(state) => state.provenOutcomes.inspectionDeficiencyPageDetails
	  );
	  const inspectionDeficiencyList = useAppSelector(
		(state) => state.provenOutcomes.inspectionDeficiencyList
	  );

	const [DeficiencyListData, setDeficiencyListData] = useState<any>([]);
	const [pageIndex, setPageIndex] = useState(0);
	const [selectedRows, setSelectedRows] = useState<any>([]);
	const [currentStart, setCurrentStart] = useState<any>([]);
	const [sortField, setSortField] = useState("");
	const [sortOrder, setSortOrder] = useState("");
	const [message, setMessage] = useState("");
	const [displayInfoModal, setDisplayInfoModal] = useState(false);
	
	useEffect(() => {
		dispatch(onGetInspectionDeficiencyList(selectedProjectId, systemId, 0, "", ""));
	}, []);

	useEffect(() => {
		if (inspectionDeficiencyList?.length) {
			setDeficiencyListData(inspectionDeficiencyList);
		}
	}, [inspectionDeficiencyList]);

	const handleRowSelect = useCallback((data: any) => {
		setSelectedRows(data)
	}, [])

	const dispatch = useDispatch<any>();

	const onRowEditClick = (rowData) => {
		let isEditable = false;
        if (selectedRows && selectedRows.length === 1) {
            isEditable = true;
        }
        return isEditable;
    }

	const onDeficiencyListRowEdit = (submitData: any) => {
		let uniqueProjectId: any;
		uniqueProjectId = submitData.projectId;
		if (submitData?.correctedDate == " ") {
			submitData.correctedDate = moment(new Date()).format("yyyy-MM-DD").toString();
		}
		let body = {
			systemId: systemId,
			projectId: selectedProjectId,
			equipmentId: submitData?.equipmentId,
			correctedDate: moment(submitData.correctedDate).format("yyyy-MM-DD").toString(),
			address: submitData.address,
			type: submitData.type,
			locationInBuilding: submitData.locationInBuilding,
			date: submitData.date,
			comments: submitData.comments
		}
		dispatch(onUpdateDeficiencyList(uniqueProjectId, body, setMessage, setDisplayInfoModal));
	};

	const onSort = (event) => {
		let sortDirection: string;
		if (event.sortOrder === 1)
			sortDirection = "ASC"
		else
			sortDirection = "DESC"
		setSortField(event.sortField)
		setSortOrder(sortDirection)
		dispatch(
			onGetInspectionDeficiencyList(
				selectedProjectId,
				systemId,
				pageIndex,
				event?.sortField,
				sortDirection
			)
		);
	};
	
	  const onPageChanged = (options: any) => {
		if (options !== pageIndex) {
		  let currentStart = (options * inspectionDeficiencyPageDetails?.currentLength) + 1;
		  setPageIndex(options);
		  setCurrentStart(currentStart);
		  dispatch(
			onGetInspectionDeficiencyList(
			  selectedProjectId,
			  systemId,
			  pageIndex,
			  sortField,
			  sortOrder
			)
		  );
		}
	  };

	return (

		<Dialog
        header="Deficiency List"
        visible={showModal}
        style={{ width: "70vw" }}
        onHide={() => setShowModal(false)}
        className="display-settings personal selectsite deficiency-list"
      >
        <div className="content-box">
                 <div className={`table-wrap`}>
						<DataTableComponent
							rows={DeficiencyListData}
							cols={DEFICIENCY_LIST_COLS}
							title={`Inspection Deficiency List`}
							dataKeyId="equipmentId"
							pageDetails={inspectionDeficiencyPageDetails}
							pageIndex={pageIndex}
							showroweditor={true}
							paginator={true}
							getSelectedRowData={handleRowSelect}
							onRowEditClick={onRowEditClick}
							onSaveRowEdit={onDeficiencyListRowEdit}
							onSortData={onSort}
							onPageChanged={onPageChanged}
						/>
						<InformationBox
						showInfoModal={displayInfoModal}
						setShowInfoModal={setDisplayInfoModal}
						message={message}
					/>
				</div>
        </div>
      </Dialog>

	);
}

export default DeficiencyList;


