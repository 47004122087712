import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from 'constants/AppRoutes';
import AddModal from 'components/Table/AddModal/AddModal'
import { Button } from 'primereact/button'

const InspectionDrawingsModal = (props) => {
  const navigate = useNavigate();
    const [showModal,setShowModal] = useState<boolean>(true)
    const onOpenDrawingsClick=()=>{
        navigate(`/${APP_ROUTES.INSPECTION_DRAWINGS}`)
        
    }
    const drawingListModalButtons=(
      <div>
      <Button label="Cancel" className="cancel-btn" onClick={() => {setShowModal(false)
      props.closeModal()}} />
      <Button label={"Open Drawings"} className="save-btn" onClick={onOpenDrawingsClick} />
  </div>
    )
  return (
    <>
    <AddModal
     header='Drawings List'
     showModal={showModal}
     isAdd={true}
     setShowModal={setShowModal}
     style={{ width: '35vw', maxHeight: '100%' }}
     OnSaveClick={onOpenDrawingsClick}
     customButtons={drawingListModalButtons}
     onCloseModal={()=>{props.closeModal()}}>
    
    </AddModal>
    </>
  )
}

export default InspectionDrawingsModal