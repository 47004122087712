import React, { useState, useEffect, useCallback } from "react";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { ScrollPanel } from "primereact/scrollpanel";
import { VscChromeMaximize } from "react-icons/vsc";
import { TREE_DATA } from "./TreeData";

import TreeView from "components/common/TreeView/TreeView";
import { CondtionalRender } from "./ConditionalRender";

interface Props {
    isGlobal: boolean;
    currentPage?: any;
    showModal: boolean;
    setShowModal: (state: boolean) => void;
}

export const Help: React.FC<Props> = ({
    isGlobal = false,
    currentPage = "",
    showModal = false,
    setShowModal = () => { } }) => {

    const [selectedItem, setSelectedItem] = useState<any>(TREE_DATA[0].key);
    const [expandedKeys, setExpandedKeys] = useState({});
    const [maximize, setMaximize] = useState(false);
    const [yammerLink, setYammerLink] = useState(true);

    const onNodeClick = useCallback((data) => {        
        if (data && data.key) {
            if (data.key !== selectedItem) {
                setSelectedItem(data.key);
                if (data.children && data.children.length) {
                    setExpandedKeys({ [data.key]: true });
                } else if (!data.parentGroupId) {
                    setExpandedKeys({});
                }
            } else {
                if (data.children && data.children.length) {
                    setSelectedItem(null);
                    setExpandedKeys({});
                }
            }
        }
    }, [selectedItem]);


    const renderHeader = () => {
        return (
            <>
                Tech Advance<sup>+</sup> Help
            </>
        );
    };
    useEffect(() => {
        if(window.location.href.indexOf('ca.')>-1)
        {
            setYammerLink(true);
        }
        else
        {
            setYammerLink(false);
        }    
    },[]);
    const renderFooter = () => {
        return (
            <>
                <Button label="Close" className="update btn" autoFocus onClick={() => setShowModal(false)} />
            </>
        );
    };

    const minimized = (
        <div className="header-minimize ">
            <Button className="btn-size" onClick={() => setMaximize(true)}>
                <span className="pi pi-minus"> </span>
            </Button>
        </div>
    );

    const maximized = (
        <div className="header-minimize ">
            <Button className="btn-size" onClick={() => setMaximize(false)}>
                <span>
                    <VscChromeMaximize />
                </span>
            </Button>
        </div>
    );

    return (
        <>
            {isGlobal ?
                <Dialog
                    header={renderHeader()}
                    icons={maximize ? maximized : minimized}
                    visible={showModal}
                    style={{ width: "65vw" }}
                    onHide={() => setShowModal(false)}
                    footer={renderFooter()}
                    className={`s-modal help-modal ${maximize ? "min" : "max"}`}
                    modal={maximize ? false : true}
                >
                    <div className="help-wrapper pl-0">
                        <div className="content-area h-100">
                            <div className="help-notes w-100 help-document">
                                <ScrollPanel className="help-notes-scroll">
                                    {currentPage && CondtionalRender(currentPage)}
                                </ScrollPanel>
                            </div>
                        </div>
                    </div>

                </Dialog>
                :
                <Dialog
                    header={renderHeader()}
                    icons={maximize ? maximized : minimized}
                    visible={showModal}
                    style={{ width: "65vw" }}
                    onHide={() => setShowModal(false)}
                    footer={renderFooter()}
                    className={`s-modal help-modal ${maximize ? "min" : "max"}`}
                    modal={maximize ? false : true}
                >

                    <div className="help-wrapper">
                        <div className="title-note">
                            <div className="title">
                                <p>
                                    Tech Advance<sup>+</sup> {'  '}
                                    <a className="cursor-pointer" href="https://siemensnam.sharepoint.com/teams/techadvance" target={"_blank"}>
                                        SharePoint
                                    </a>

                                    {'  '} site is a hosting item such as Training Material Announcements.<br></br>
                                    For assistance, please contact your support team via {'  '}                                    
                                    {yammerLink && (
                                    <a className="cursor-pointer" href="https://web.yammer.com/main/org/siemens.com/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiIxMjQ2OTkxMzE5MDQifQ/all" target={"_blank"}>
                                        Yammer
                                    </a>
                                    )}
                                    {!yammerLink && (
                                    <a className="cursor-pointer" href="https://web.yammer.com/main/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiI1NDUxMDg3ODcyIn0" target={"_blank"}>
                                        Yammer
                                    </a>
                                    )}
                                    
                                </p>
                            </div>
                        </div>
                        <div className="content-area">
                            <div className="tree-wrapper">
                                <ScrollPanel className="treeview-scroll">
                                    <TreeView
                                        selectionMode="single"
                                        treeData={TREE_DATA}
                                        selectedItem={selectedItem}
                                        onNodeClick={onNodeClick}
                                        expandedKeys={expandedKeys}
                                        onToggle={e => {
                                            setSelectedItem(null);
                                            setExpandedKeys(e.value);
                                        }}
                                    />
                                </ScrollPanel>
                            </div>
                            <div className="help-notes help-document">
                                <ScrollPanel className="help-notes-scroll">
                                    {selectedItem && CondtionalRender(selectedItem)}
                                </ScrollPanel>
                            </div>
                        </div>
                    </div>
                </Dialog>

            }
        </>
    );
};

export default Help;
