import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { zlink, baseURL } from "../../../../../service/HttpService";
import { AppThunk } from "../../../../store";

export interface EquipmentTypeAttributeValueState {
  errors: any;
  equipmentTypeNameList: any;
  equipmentTypeAttributeValues: any;
  equipmentAttributeNameList: any;
  equipmentTypeAttributeValueList: any;
  equipmentTypeAttributeValueDetails: any;
  equipmentTypeAttributeValuePageDetails: any;
  newEquipmentTypeAttributeValue: any;
}

let initialState: EquipmentTypeAttributeValueState = {
  errors: "",
  equipmentTypeNameList: [],
  equipmentAttributeNameList: [],
  equipmentTypeAttributeValues: [],
  equipmentTypeAttributeValueList: [],
  equipmentTypeAttributeValueDetails: {},
  equipmentTypeAttributeValuePageDetails: [],
  newEquipmentTypeAttributeValue: "",
};

const EquipmentTypeAttributeValueSlice = createSlice({
  name: "equipmentTypeAttributeValue",
  initialState,
  reducers: {
    setErrors: (state, { payload }: PayloadAction<any>) => {
      state.errors = payload;
    },
    setEquipmentTypeNameList: (state, { payload }: PayloadAction<any>) => {
      state.equipmentTypeNameList = payload;
    },
    setEquipmentAttributeNameList: (state, { payload }: PayloadAction<any>) => {
      state.equipmentAttributeNameList = payload;
    },
    setEquipmentTypeAttributeValueList: (state, { payload }: PayloadAction<any>) => {
      state.equipmentTypeAttributeValueList = payload;
    },
    setNewEquipmentTypeAttributeValueData: (state, { payload }: PayloadAction<any>) => {
      let newData: any = [];
      newData = [
        {
          dataFieldLookUpId: payload.dataFieldLookUpId,
          attributeValue: payload.attributeValue,
        },
      ];
      state.equipmentTypeAttributeValueList.unshift(newData[0]);
      state.newEquipmentTypeAttributeValue = newData[0];
    },
    setEquipmentTypeAttributeValueDetails: (state, { payload }: PayloadAction<any>) => {
      state.equipmentTypeAttributeValueDetails = payload;
    },
    setUpdatedEquipmentTypeAttributeValueData: (state, { payload }: PayloadAction<any>) => {
      let newData: any = [];
      newData = [
        {
          dataFieldLookUpId: payload.dataFieldLookUpId,
          attributeValue: payload.attributeValue,
        },
      ];
      let currentIndex = state.equipmentTypeAttributeValueList.findIndex(
        (item: any) => item.dataFieldLookUpId === payload?.dataFieldLookUpId
      );
      state.equipmentTypeAttributeValueList =
        state.equipmentTypeAttributeValueList.filter(
          (item: any) => item.dataFieldLookUpId !== payload?.dataFieldLookUpId
        );
      state.equipmentTypeAttributeValueList.splice(currentIndex, 0, newData[0]);
    },
    setDeletedEquipmentTypeAttributeValues(state, { payload }: PayloadAction<any>) {
      state.equipmentTypeAttributeValueList = state.equipmentTypeAttributeValueList.filter(
        (item: any) => item.dataFieldLookUpId !== payload
      );
    },
    setEquipmentTypeAttributeValuePageDetails: (state, { payload }: PayloadAction<any>) => {
      state.equipmentTypeAttributeValuePageDetails = payload;
    },
  },
});

export const getEquipmentTypeNameList = (): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.get(`${baseURL}api/v1/manage/customization/attributes/typeattribute/equipmenttypelist`);
    if (res.status === "done") {
      dispatch(setEquipmentTypeNameList(res?.data?.equipmentClassDetails));
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const getEquipmentAttributeNameList = (uniqueEquipmentTypeId: any, setIsEquipmentValue): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.get(`${baseURL}api/v1/manage/customization/equipmenttypes/${uniqueEquipmentTypeId}/attributes`);
    if (res.status === "done") {
      dispatch(setEquipmentAttributeNameList(res?.data?.attributeDetails));
      setIsEquipmentValue(true);
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const getEquipmentTypeAttributeValuesList = (uniqueAttributeId, currentStart,keyword: any = "",sortField: any = "", sortOrder: any = "", setMessage, setDisplayInfoModal, setIsLoaded): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.get(`${baseURL}api/v1/manage/customization/equipmenttypevalues/attributes/${uniqueAttributeId}/values?currentStart=${currentStart}&keyWord=${keyword}&sortColumn=${sortField}&sortOrder=${sortOrder}`);
    if (res.status === "done") {
      if (res.data) {
        dispatch(setEquipmentTypeAttributeValueList(res?.data?.attributeDetails));
        dispatch(setEquipmentTypeAttributeValuePageDetails(res?.data?.pageDetails));
        if (!res.data.attributeDetails.length) {
          setMessage('No Equipment Type Attribute Values exist')
          setDisplayInfoModal(true);
        }
      }
      if (setIsLoaded) {
        setIsLoaded(true);
      }
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const onAddEquipmentTypeAttributeValue = (body: any, uniqueAttributeId, setMessage, setDisplayInfoModal, setShowAddModal): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.post(`${baseURL}api/v1/attributes/${uniqueAttributeId}/values/add`, body);
    if (res.status === "done") {
      setShowAddModal(false);
      dispatch(setNewEquipmentTypeAttributeValueData(res.data));
      setMessage("Equipment Type Attribute Value added");
      setDisplayInfoModal(true);
    } else {
      dispatch(setErrors(res.error));
      setMessage(res.message);
      setDisplayInfoModal(true);
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const onEditEquipmentTypeAttributeValue =
  (
    attributeId,
    datafieldlookupid,
    body: any,
    setMessage,
    setDisplayInfoModal,
    setShowEditModal
  ): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.post(`${baseURL}api/v1/attributes/${attributeId}/values/${datafieldlookupid}`, body);
    if (res.status === "done") {
      dispatch(setUpdatedEquipmentTypeAttributeValueData(res.data));
      setShowEditModal(false);
      setMessage("Equipment Type Attribute Value updated");
      setDisplayInfoModal(true);
    } else {
      dispatch(setErrors(res.error));
      setMessage(res.message);
      setDisplayInfoModal(true);
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const getEquipmentTypeAttributeValueDetails = (attributeId: any, datafieldlookupid: any): AppThunk => async (dispatch) => {
  setEquipmentTypeAttributeValueDetails({});
  try {
    const res = await zlink.get(`${baseURL}api/v1/attributes/${attributeId}/values/${datafieldlookupid}`);
    if (res.status === "done") {
      dispatch(setEquipmentTypeAttributeValueDetails(res?.data));
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const deleteEquipmentTypeAttributeValue =(uniqueattributeid,datafieldlookupid, setSelectedRows, setMessage, setDisplayInfoModal): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.post(`${baseURL}api/v1/attributes/${uniqueattributeid}/values/${datafieldlookupid}/remove`, null);
    if (res.status === "done") {
      dispatch(setDeletedEquipmentTypeAttributeValues(datafieldlookupid));
      setSelectedRows([]);
      setMessage("Equipment Type Attribute Value deleted");
      setDisplayInfoModal(true);
    } else {
      setSelectedRows([]);
      setMessage(res.message);
      setDisplayInfoModal(true);
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const {
  setErrors,
  setEquipmentTypeNameList,
  setEquipmentAttributeNameList,
  setNewEquipmentTypeAttributeValueData,
  setUpdatedEquipmentTypeAttributeValueData,
  setEquipmentTypeAttributeValueDetails,
  setDeletedEquipmentTypeAttributeValues,
  setEquipmentTypeAttributeValueList,
  setEquipmentTypeAttributeValuePageDetails,
} = EquipmentTypeAttributeValueSlice.actions;

export default EquipmentTypeAttributeValueSlice.reducer;
