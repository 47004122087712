import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { BsExclamationCircleFill } from 'react-icons/bs';
import { Calendar } from 'primereact/calendar';

const statusList = [
  { label: 'Active', value: 'Active' },
  { label: 'Blocked', value: 'Blocked' },
  { label: 'Not Activated', value: 'Not Activated' }
];

const roleList = [
  { label: 'Super Administrator', value: 'Super Administrator' },
  { label: 'Support', value: 'Support' },
  { label: 'Admin', value: 'Admin' },
  { label: 'Admin-Tech', value: 'Admin-Tech' },
  { label: 'Tech', value: 'Tech' },
  { label: 'Branch', value: 'Branch' }
];

const getErrorMsg = options => {
  options.value = options.value ? options.value.trim() : '';
  const { field, value } = options;
  const FIELD_REQUIRED = 'This field is required';
  const EMAIL_VALID = 'Enter a valid Email';
  switch (field) {
    case 'userName':
    case 'status':
    case 'accountActivationDate':
    case 'accountExpiryDate': {
      if (!value?.trim())
        return FIELD_REQUIRED;
      break;
    }
    case 'email': {
      if (!isEmailValid(value))
        return EMAIL_VALID
      else if (!value.trim())
        return FIELD_REQUIRED
      break;
    }
    default: {
      return ''
    }
  }
};


const isEmailValid = (email) => {
  if (!email) {
    return true;
  }
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
}

const statusEditor = (options) => {
  return (
    <Dropdown value={options.value} options={statusList} optionLabel="label" optionValue="value"
      onChange={(e) => options.editorCallback(e.value)} placeholder="Select Status"
      itemTemplate={(option) => {
        return <span className='status-badge'>{option.label}</span>
      }} />
  );
}

const roleEditor = (options) => {
  return (
    <Dropdown disabled value={options.value} options={roleList} optionLabel="label" optionValue="value"
      onChange={(e) => options.editorCallback(e.value)} placeholder="Select Role"
      itemTemplate={(option) => {
        return <span className='role-badge'>{option.label}</span>
      }} />
  );
}

const textEditor = (options) => {
  const errorMsg = getErrorMsg(options);
  return <>
    <InputText className={`cell-error error-tooltip ${errorMsg ? 'error' : ""}`} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />
    {errorMsg && <span className="tooltip-text"><BsExclamationCircleFill />{errorMsg}</span>}
  </>
}

const loginNameEditor = (options) => {
  return <InputText type="text" disabled value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
}


const userNameEditor = (options) => {
  return <InputText type="text" disabled value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
}

const dateEditor = (options) => {
        let date = new Date(options.value)
        let timezoneoffset = date.getTimezoneOffset();
        let localDate = new Date();
        if(timezoneoffset >=0)
        localDate = new Date(date.getTime() + (timezoneoffset) * 60000)
        else
        localDate = new Date(date.getTime() - (timezoneoffset) * 60000)
  return <Calendar id="calendar" dateFormat="yy-mm-dd" readOnlyInput value={localDate} onChange={(e) => options.editorCallback(e.target.value)} />
}

const lastLoginEditor = (options) => {
  return <InputText type="text" disabled value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
}

export const USER_COLS = [
  {
    field: 'userName',
    header: 'User Name',
    editor: (options) => userNameEditor(options),
    style: { flex: '1 0 200px' },
    sortable: true,
  },
  {
    field: 'loginName',
    header: 'Login Name',
    editor: (options) => loginNameEditor(options),
    style: { flex: '1 0 200px' },
    sortable: true,
  },
  {
    field: 'userRole',
    header: 'User Role',
    editor: (options) => roleEditor(options),
    style: { flex: '1 0 200px' },
    sortable: true,
  },
  {
    field: 'status',
    header: 'Status',
    editor: (options) => statusEditor(options),
    style: { flex: '1 0 175px' },
    sortable: true,
  },
  {
    field: 'lastLogIn',
    header: 'Last Login',
    editor: (options) => lastLoginEditor(options),
    style: { flex: '1 0 175px' },
    sortable: true,
  },
  {
    field: 'accountActivationDate',
    header: 'Account Activation Date',
    editor: (options) => dateEditor(options),
    style: { flex: '1 0 150px' },
    sortable: true,
  },
  {
    field: 'accountExpiryDate',
    header: 'Account Expiry Date',
    editor: (options) => dateEditor(options),
    style: { flex: '1 0 200px' },
    sortable: true,
  },
  {
    field: 'email',
    header: 'Email',
    editor: (options) => textEditor(options),
    style: { flex: '1 0 250px' },
    sortable: true,
  }
];