import React, { memo, useEffect, useState,useRef } from 'react'
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
//icons
import { BsExclamationCircleFill } from 'react-icons/bs';
//redux
import { useDispatch } from 'react-redux';
import { getDatafieldValues, setAdditionalDatafieldValues } from 'redux/common/commonSlice';
import { useAppSelector } from 'redux/hooks';
import { setEquipmentDatafields } from 'redux/pages/Inspection/InspectionTask/InspectionDetails/Equipment/EquipmentSlice';
import { setIsDevReadingChanged, setIsTestedDateChanged } from 'redux/pages/Inspection/InspectionTask/InspectionDetails/Equipment/EquipmentSlice';
import moment from 'moment';
import { Button } from "primereact/button";
import { blockedNonKeyboardCharacters } from 'service/GeneralUtility';

interface props {   
    generalProperties?: any;
    getTableColumns?: (data, dataFields) => void;
    uniqueEquipmentClassId?: any;
    selectedRow?: any;
}
const EquipmentListData: React.FC<props> = (props) => {
    const { generalProperties, getTableColumns = () => { }, uniqueEquipmentClassId, selectedRow } = props;
    const [datafields, setDatafields] = useState<any>([]);
    const [devReadingDate, setDevReadingDate] = useState<any>([]);
    const [testDate, setTestDate] = useState<any>([]);
    const cal = React.createRef<Calendar>();
    const devCal = React.createRef<Calendar>();
    const testDateRef =React.createRef<Calendar>();
 
    let body = {
        uniqueEquipmentClassId: uniqueEquipmentClassId === "All" ? selectedRow?.[0]?.EquipmentTypeId : uniqueEquipmentClassId
    }

    const dispatch = useDispatch<any>()
    const equipmentDatafields = useAppSelector((state) => state.equipment.equipmentDatafields);
    const additionalFieldValues = useAppSelector((state) => state.common.additionalDatafieldValues);
    const loggedInUserDetails = useAppSelector((state) => state.administration.loggedInUserDetails);

    useEffect(() => {
        if (equipmentDatafields) {
            let lists = JSON.parse(JSON.stringify(equipmentDatafields));
            lists.forEach(element => { 
                const devReadingDateIndex = lists?.findIndex((item) => item.dataFieldName === "Dev Reading Date");
                if(devReadingDateIndex>0){
                if (element?.dataFieldName=== "Dev Reading") {
                    if(element?.fieldValue === ''){
                        lists[devReadingDateIndex].disabled = true; 
                    }
                   else{
                    lists[devReadingDateIndex].disabled = false;
                    }
                }
            }
                const testDateIndex = lists?.findIndex((item) => item.dataFieldName === "Test Date");
                if (element?.dataFieldName=== "Test Result") {
                    if(element?.validatedValues != null){
                    const slectedItem = element.validatedValues?.filter((item) => item?.isSelected);
                    if (slectedItem[0]?.dataFieldLookupValue === "Not Tested"){                  
                        lists[testDateIndex].disabled = true;}
                }
                else{
                    lists[testDateIndex].disabled = false;
                }
                }
                if (element?.dataFieldName=== "Test Method") {
                    if(element?.validatedValues != null){
                    const slectedItem = element.validatedValues?.filter((item) => item?.isSelected);
                    if (slectedItem[0]?.dataFieldLookupValue === "None"){                  
                            lists[testDateIndex].disabled = true;}
                }
                else{
                    lists[testDateIndex].disabled = false;
                }
                }
                if (element.defaultSelectNeeded)
                element.validatedValues.unshift({ dataFieldLookupId: -1, dataFieldLookupValue: "--Select--", isSelected: false });
            });
            setDatafields(lists);
            dispatch(setEquipmentDatafields(""));
        }
    }, [equipmentDatafields]);

    useEffect(() => {
        if (additionalFieldValues) {
            let lists = JSON.parse(JSON.stringify(datafields))
            let fieldValues = JSON.parse(JSON.stringify(additionalFieldValues));
            const findIndex = datafields?.findIndex((item) => item.uniqueDataFieldId === fieldValues[1]);
            if (findIndex >= 0) {
                if (lists[findIndex]?.defaultSelectNeeded) {
                    fieldValues[0].unshift({ dataFieldLookupId: -1, dataFieldLookupValue: "--Select--", isSelected: false });
                }
                datafields[findIndex].validatedValues = fieldValues[0];
            }
            setDatafields([...datafields]);
            dispatch(setAdditionalDatafieldValues(""));
        }
    }, [additionalFieldValues])

    useEffect(() => {
        const columns = getEquipmentColumn(generalProperties, datafields);
        getTableColumns(columns, datafields);
    }, [generalProperties, datafields, getTableColumns]);

    const checkTestMethodDropDown = (e) => {
        const testMetIndex = datafields?.findIndex((item) => item.dataFieldName === "Test Method");
        const testResIndex = datafields?.findIndex((item) => item.dataFieldName === "Test Result");
        const testDateIndex = datafields?.findIndex((item) => item.dataFieldName === "Test Date");

        datafields[testMetIndex]?.validatedValues?.forEach(element => {
            if (element?.dataFieldLookupId === e.value)
                element.isSelected = true;
            else
                element.isSelected = false;
        });
        if (e.value) {
            if (testResIndex >= 0) {
                datafields[testResIndex].disabled = false;
                const none = datafields[testResIndex]?.validatedValues?.filter((item) => item?.dataFieldLookupId !== 3);
                datafields[testResIndex].validatedValues = none;
                datafields[testResIndex]?.validatedValues?.forEach(element => {
                    if (element?.dataFieldLookupValue === "Passed")
                        element.isSelected = true;
                    else
                        element.isSelected = false;    
                });
            }
            if (testDateIndex >= 0) {
                datafields[testDateIndex].fieldValue = moment(new Date()).utcOffset((loggedInUserDetails?.userDefaults?.siteOffset)).format("YYYY-MM-DD HH:mm:ss");

            }
        } else {
            if (testResIndex >= 0) {
                datafields[testResIndex].disabled = true;
                datafields[testResIndex].validatedValues.push({
                    dataFieldLookupId: 3,
                    dataFieldLookupValue: "Not Tested",
                    isSelected: true
                })
                datafields[testResIndex]?.validatedValues?.forEach(element => {
                    if (element?.dataFieldLookupId === 3)
                        element.isSelected = true;
                    else
                        element.isSelected = false;
                });
            }
            if (testDateIndex >= 0) {
                datafields[testDateIndex].fieldValue = "";       
            }
        }
        setDatafields([...datafields]);
    }

    const checkTestResultDropDown = (e, fields) => {
        const testResIndex = datafields?.findIndex((item) => item.dataFieldName === "Test Result");
        datafields[testResIndex]?.validatedValues?.forEach(element => {
            if (element?.dataFieldLookupId === e.value)
                element.isSelected = true;
            else
                element.isSelected = false;
        });
        if (e.value) {
            const testDateIndex = datafields?.findIndex((item) => item.dataFieldName === "Test Date");
            const items = fields?.validatedValues?.filter((item) => item.dataFieldLookupId === e.value);
            if (items[0]?.dataFieldLookupValue !== "Not Tested") {
                datafields[testDateIndex].fieldValue = moment(new Date()).utcOffset((loggedInUserDetails?.userDefaults?.siteOffset)).format("YYYY-MM-DD HH:mm:ss");
            }
        }
        setDatafields([...datafields]);
    }

    const getErrorMsg = (fields, options) => {
        if (options?.value) {
            let invalidCharacters: any = blockedNonKeyboardCharacters(options?.value);
            if (invalidCharacters != null && invalidCharacters.length > 0) {
                return "Non-Keyboard character(s) " + invalidCharacters.join() + " is not allowed";
            }
        }
        if (options?.value && options?.value.length > fields?.maxLength){
            return 'Exceeds maximum character';
        }
        if (fields?.mandatory) {
            if (!options?.value)
                return 'This field is required';
        } else {
            return ''
        }
    };

    const getEquipmentColumn = (generalProperties, datafields) => {

        const columnInfo: any = [];
        if (generalProperties?.length) {
            for (var i = 0; i < generalProperties?.length; i++) {
                if (generalProperties[i]?.propertyType !== "INTERNAL") {
                    let data: any = {
                        field: generalProperties[i]?.name,
                        header: generalProperties[i]?.alias,
                        style: { flex: '1 0 200px' },
                        sortable: true,
                    }
                    if (datafields?.length) {
                        const filterData = datafields?.filter((item) => item?.uniqueDataFieldId === generalProperties[i]?.uniqueDataFieldId);
                        if (filterData?.length) {
                            if (["Text Box", "Text Area"].includes(filterData[0]?.dataEntryControl?.dataEntryControlName)) {
                                data.editor = (options) => {
                                    if (options?.field === "Address") {
                                        options.value = filterData[0]?.fieldValue
                                    }
                                    else if(options?.field != "Dev Reading"){                               
                                             options.value = filterData[0]?.fieldValue
                                    }
                                    else if (options?.field == "Dev Reading" && ((options.rowData["Dev Reading"] == "") || (options.rowData["Dev Reading"] == null))) {
                                        options.value = "";
                                    }
                                    return CustomTextEditor(options, filterData[0]);
                                }
                            } else if (filterData[0]?.dataEntryControl?.dataEntryControlName === "Date Picker") {
                                data.editor = (options) => CustomDateEditor(options, filterData[0]);
                            } else if (filterData[0]?.dataEntryControl?.dataEntryControlName === "Date Time Picker") {

                                if(filterData[0].dataFieldName === "Dev Reading Date") {
                                    data.editor = (options) =>CustomDevReadingDateTimeEditor(options, filterData[0]) 
                                } else if(filterData[0].dataFieldName === "Test Date") {
                                    data.editor = (options) =>CustomTestDateTimeEditor(options, filterData[0]) 
                                } 
                                else {
                                    data.editor = (options) => CustomDateTimeEditor(options, filterData[0])
                                }
                                    
                            } else if (filterData[0]?.dataEntryControl?.dataEntryControlName === "Drop-down list") {
                                data.editor = (options) => {
                                    const filterItem = filterData[0]?.validatedValues?.filter((item) => item.isSelected);
                                    if (filterItem?.length) {
                                        options.value = filterItem[0]?.dataFieldLookupId;
                                    }
                                    return CustomDropDownEditor(options, filterData[0])
                                }
                            } 
                        }
                    }
                    columnInfo.push(data);
                }
            }
        }
        return columnInfo;
    }

    //editor 
    const CustomTextEditor = (options, fields) => {
        const errorMsg = getErrorMsg(fields, options);

        const onChange = (e) => {
            if (options?.field === "Address") {
                const index = datafields?.findIndex((item) => item?.uniqueDataFieldId === fields?.uniqueDataFieldId);
                datafields[index].fieldValue = e.target.value;
                setDatafields(datafields)
            } else if(options?.field === "Dev Reading") {
                const index = datafields?.findIndex((item) => item?.dataFieldName === "Dev Reading Date");
                const devReadingDateindex = datafields?.findIndex((item) => item.dataFieldName === "Dev Reading Date");
                const devReadingindex = datafields?.findIndex((item) => item.dataFieldName === "Dev Reading");
                if(devReadingDateindex > 0){
                    if(e.currentTarget.value.length > 0) {
                        datafields[devReadingDateindex].disabled = false;
                        datafields[index].disabled = false;
                        datafields[index].fieldValue =moment(new Date()).utcOffset(loggedInUserDetails?.userDefaults?.siteOffset).format("YYYY-MM-DD HH:mm:ss");
                        setDevReadingDate(moment(new Date()).utcOffset(loggedInUserDetails?.userDefaults?.siteOffset)) 
                    }
                     else {
                        datafields[index].fieldValue = "";
                        datafields[index].disabled = true;
                        datafields[devReadingDateindex].disabled = true;
                        setDevReadingDate(undefined)
                    }
                }
                if (e.currentTarget.value.length === 0) {
                    datafields[devReadingindex].fieldValue = "";
                }
                setDatafields([...datafields])
            }
            else{
                const index = datafields?.findIndex((item) => item?.uniqueDataFieldId === fields?.uniqueDataFieldId);
                datafields[index].fieldValue = e.target.value;
                setDatafields(datafields)
            }
        }

        return (
            <>
                <InputText
                    className={`cell-error error-tooltip ${errorMsg ? 'error' : ""}`}
                    type="text"
                    disabled={fields?.disabled}
                    value={options.value}
                    onChange={(e) => {
                        options.editorCallback(e.target.value);
                        onChange(e)
                    }} />
                {errorMsg && <span className="tooltip-text"><BsExclamationCircleFill />{errorMsg}</span>}
            </>
        )
    }

    const CustomDateEditor = (options, fields) => {
        return (<Calendar
            id="calendar"
            dateFormat="yy-mm-dd"
            disabled={fields?.disabled}
            readOnlyInput
            value={options.value ? new Date(moment(options.value).format("YYYY/MM/DD")) : undefined}
            showButtonBar
            onClearButtonClick={() => options.editorCallback("")}
            onChange={(e) => options.editorCallback(e.target.value)
            } />)
    }
    const onCustomDateTimeChange = (e,fields) => {
        if (fields.dataFieldName === "Dev Reading Date") {
            dispatch(setIsDevReadingChanged('D'));
        } 
        if(fields.dataFieldName === "Test Date"){
            dispatch(setIsTestedDateChanged('T'));
        }
    }
    const CustomDateTimeEditor = (options, fields) => {
        return (<Calendar
            ref={cal}
            id="single"
            dateFormat="yy-mm-dd"
            readOnlyInput
            value={options.value ? new Date(options.value) : undefined}
            disabled={fields?.disabled}
            showTime
            showSeconds
            showButtonBar
            onClearButtonClick={() => options.editorCallback("")}
            onChange={(e) => options.editorCallback(e.target.value)}
            footerTemplate={() => <div>
                <Button className="confirm-btn" onClick={() => cal.current?.hide()}>Save</Button>
            </div>}
             />)
    }

    const CustomDevReadingDateTimeEditor = (options, fields) => {
        if (/^<span[^>]*>[\s\S]*<\/span>$/.test(options.value)) {
            options.value = options.value.replace(/<[^>]*>/g, '');
          }
        return (<Calendar
            ref={devCal}
            id="single"
            dateFormat="yy-mm-dd"
            disabled={fields?.disabled}
            showTime
            showSeconds
            showButtonBar
            clearButtonClassName ="clearbutton"    
            onTodayButtonClick ={(e:any) => options.editorCallback(moment(new Date()).utcOffset((loggedInUserDetails?.userDefaults?.siteOffset)).format("YYYY-MM-DD HH:mm:ss"))}
            value = { (options.rowData["Dev Reading"] === "")||(options.rowData["Dev Reading"] === null) ? "" : options.value === null ? new Date( moment(new Date()).utcOffset(loggedInUserDetails?.userDefaults?.siteOffset).format("YYYY-MM-DD HH:mm:ss")) : new Date(options.value)}
            onChange={(e) => { options.editorCallback(e.target.value); onCustomDateTimeChange(e, fields) }}
            footerTemplate={() => <div>
                <Button className="confirm-btn" onClick={() => devCal.current?.hide()}>Save</Button>
            </div>}
            />)
    }

    const CustomTestDateTimeEditor = (options, fields) => {
        if (/^<span[^>]*>[\s\S]*<\/span>$/.test(options.value)) {
            options.value = options.value.replace(/<[^>]*>/g, '');
        }
        return (<Calendar
            ref={testDateRef}
            id="single"
            dateFormat="yy-mm-dd"
            disabled={fields?.disabled}
            showTime
            showSeconds
            showButtonBar
            clearButtonClassName ="clearbutton"  
            onTodayButtonClick ={(e:any) => options.editorCallback( moment(new Date()).utcOffset(loggedInUserDetails?.userDefaults?.siteOffset).format("YYYY-MM-DD HH:mm:ss"))}
            value = {(options.rowData["Test Method"] === "None")||(options.rowData["Test Method"] === 0)? "" : options.value === null ?new Date( moment(new Date()).utcOffset(loggedInUserDetails?.userDefaults?.siteOffset).format("YYYY-MM-DD HH:mm:ss")) : new Date(options.value)}            
            onChange={(e) => { options.editorCallback(e.target.value); onCustomDateTimeChange(e, fields) }}
            footerTemplate={() => <div>
                <Button className="confirm-btn" onClick={() => testDateRef.current?.hide()}>Save</Button>
            </div>}
        />)
    }

    const CustomDropDownEditor = (options, fields) => {
        const onselectionchange = (e, field) => {
            if (field?.dataFieldRelation) {
                let service: any = field?.dataFieldRelation?.subSetValueService;
                if (service !== undefined) {
                    service = service.replace("{dataFieldLookupId}", e?.value);
                    dispatch(getDatafieldValues(service, field?.dataFieldRelation?.childDataFieldId, body))
                }
                const index = datafields?.findIndex((item) => item?.uniqueDataFieldId === field?.uniqueDataFieldId);
                datafields[index]?.validatedValues?.forEach(element => {
                    if (element?.dataFieldLookupId === e.value)
                        element.isSelected = true;
                    else
                        element.isSelected = false;
                });
                setDatafields(datafields);
            } else {
                if (field?.dataFieldName === "Test Method") {
                    checkTestMethodDropDown(e)
                    const index = datafields?.findIndex((item) => item?.dataFieldName === "Test Date");
                    const indexTestMethod = datafields?.findIndex((item) => item?.dataFieldName === "Test Method");
                    const items = datafields[indexTestMethod]?.validatedValues?.filter((item) => (e.target.value === item.dataFieldLookupId));
                    if (items[0]?.dataFieldLookupValue !== "None") {
                        datafields[index].disabled = false;
                    }
                    else{
                        datafields[index].disabled = true;
                    }
                    if(e.target.value === 1 || e.target.value === 2){
                        datafields[index].disabled = false;
                        datafields[index].fieldValue =moment(new Date()).utcOffset(loggedInUserDetails?.userDefaults?.siteOffset).format("YYYY-MM-DD HH:mm:ss");
                        setTestDate(moment(new Date()).utcOffset(loggedInUserDetails?.userDefaults?.siteOffset));
                    }
                    else{
                        datafields[index].fieldValue = "";
                        setTestDate(undefined)
                    }
                }
                 else if (field?.dataFieldName === "Test Result") {
                    checkTestResultDropDown(e, fields)
                    const index = datafields?.findIndex((item) => item?.dataFieldName === "Test Date");
                    if(e.target.value === 1 ||e.target.value === 2){
                        datafields[index].disabled = false;
                        datafields[index].fieldValue =moment(new Date()).utcOffset(loggedInUserDetails?.userDefaults?.siteOffset).format("YYYY-MM-DD HH:mm:ss");
                        setTestDate(moment(new Date()).utcOffset(loggedInUserDetails?.userDefaults?.siteOffset));
                    }
                    else{
                        datafields[index].fieldValue = "";
                        setTestDate(undefined)
                    }
                } else {
                    const index = datafields?.findIndex((item) => item?.uniqueDataFieldId === field?.uniqueDataFieldId);
                    datafields[index]?.validatedValues?.forEach(element => {
                        if (element?.dataFieldLookupId === e.value)
                            element.isSelected = true;
                        else
                            element.isSelected = false;
                    });
                    
                }
                setDatafields([...datafields])  
            }
        }

        return (<Dropdown
            value={options.value}
            options={fields.validatedValues}
            disabled={fields?.disabled}
            optionLabel="dataFieldLookupValue"
            optionValue="dataFieldLookupId"
            onChange={(e) => {
                options.editorCallback(e.value);
                onselectionchange(e, fields)
            }}
            placeholder="--Select--"
        />)
    }
    return (
        <></>
    )
}

export default memo(EquipmentListData)