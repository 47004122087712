import React from 'react'

const DataEntrySettingsDocument = () => {
  return (
    <>
        <h4 className="doc-title mb-2">
            Data Entry Settings 
        </h4>
        <p>
            This section provides options for the Super Administrator to customize the Data Entry Settings for data items in 
            TAP Web as well as TAP App. The inspection Equipment Type Attributes that are to be shown in 
            <span className="doc-subtitle"> Inspection Tasks: Inspection Details: Equipment Group/Equipment: Add/Edit </span> 
            option can be set using <span className="doc-subtitle">Data Entry Settings</span> option.</p>
        <p>
            Data Entry Settings have to be saved against United States and Canada separately. 
        </p>
      
    </>
  );
}
export default DataEntrySettingsDocument;