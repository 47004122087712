import React from 'react'

const CustomizationDocument = () => {
  return (
    <>
        <h4 className="doc-title mb-2">
            Customization 
        </h4>
        <p>
            Option to manage Attributes, Data Entry Settings and Display Settings are provided under 
            <span className="doc-subtitle"> Customization </span> menu.
        </p>
    </>
  )
}
export default CustomizationDocument;