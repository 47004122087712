import React from "react";
import { Button } from "primereact/button";
import { BsPlusLg } from "react-icons/bs";

const SecuritySettingsDocument = () => {
    return (
        <>
            <h4 className="doc-title mb-2">
                Security Settings
            </h4>
            <p>
                Options for setting the Password Policy and Mail Domains are
                grouped under <b>Security Settings</b> option.
            </p>

            <h4 className="mb-2">
                Password Policy
            </h4>
            <p>
                This option allows the Super Administrators to define a password policy for enhancing
                the security by imposing some conditions on the creation of passwords by the users.
                The following conditions are provided in the Password policy setup. Password policy
                once set is applicable to the entire application irrespective of the selected customer.
            </p>

            <p>
                <b className="doc-subtitle">Password Requirements</b>
            </p>
            <p>
                This section is for setting the Password for Users.
                Following are requirements to be met while creating the Password.
            </p>

            <p>
                <span className="doc-subtitle">Minimum length of the password</span>:- 
                The minimum length of the password can be specified using this option. 
                This can be set from 8 to 30.
            </p>

            <p>
                <span className="doc-subtitle">Case sensitive</span>:- 
                Through this option, it can be specified whether the password is case sensitive or not.
            </p>

            <p>
                <span className="doc-subtitle">Requires at least one upper case character</span>:- 
                If this option is checked, 
                then the password entered should have at least one upper case character (capital letter).
            </p>

            <p>
                <span className="doc-subtitle">Requires at least one lower case character</span>:- 
                If this option is checked, 
                then the password entered should have at least one lower case character (small letter).
            </p>

            <p>
                <span className="doc-subtitle">Requires at least one non-alphanumeric character</span>:- 
                If this option is checked, 
                then the password entered should have at least one special character like @, #, $, *, etc.
            </p>

            <p>
                <span className="doc-subtitle">Requires at least one numeric character</span>:- 
                If this option is checked, 
                then the password entered should have at least one numeric character (0-9).
            </p>

            <p>
                <span className="doc-subtitle">Password expiry</span>:- 
                Two options are provided for password expiry - Password Never Expires and Expire after (  ) days. 
                If the set option is 
                <span className="doc-subtitle"> Password Never Expires </span> 
                by disabling the Enable option in Password Expiry section, then the password will never expire, 
                otherwise the password will expire after the number of days entered in the text box. 
                If the password has expired, the system will display 'Your password has expired' at the 
                user's next login and prompt the user to change the password
            </p>

            <p>
                <span className="doc-subtitle">Lock inactive Accounts</span>:- 
                This option is used to set a policy for making the user account inactive if not logged in for a set period. 
                The Administrator can specify whether the user account has to be locked if the account is inactive 
                for the specified number of days  or Account Never locked if the account is inactive. 
            </p>
            
            <p>
                If Auto Disable Inactive Account is enabled / selected, the locked user account will be 
                enabled automatically after the Account inactive period specified in the policy has elapsed.
            </p>

            <p>
                <span className="doc-subtitle">Delete inactive Accounts</span>:- 
                This option is used to set a policy for making the user account 
                inactive if not logged in for a set period. The Administrator can 
                specify whether the user account has to be Deleted if the account is 
                inactive for the specified number of days  or Account Never Deleted if the account is inactive.
            </p>

            <p>
                If Auto Delete Inactive Account is enabled/selected, 
                the inactive user account will be deleted automatically 
                after the Account inactive period specified in the policy has elapsed.
            </p>

            <p>
                <span className="doc-subtitle">Minimum time between password changes</span>:- 
                This is the minimum time interval needed between two password changes. 
                Once a user has changed his/her password, the user will be allowed 
                to change the password again only after the specified time interval.
            </p>

            <p>
                <span className="doc-subtitle">Number of previous passwords that cannot be reused</span>:- 
                By specifying a number 'n' here, it can be ensured that when a user changes his/her password, 
                it will be different from the past 'n' passwords.
            </p>

            <p>
                <span className="doc-subtitle">Maximum number of consecutive failed attempts before the account gets locked</span>:- 
                This option is used to lock the user's account if the user has entered wrong passwords 
                for the specified number of consecutive attempts within a set Time Interval.
            </p>

            <p>
                <span className="doc-subtitle">Time Interval for counting failed attempts to lock the account</span>:- 
                This is the Time Interval within which consecutive failed attempts can lock an account.
            </p>

            <p>
                <span className="doc-subtitle">Account locked out period</span>:- 
                This option is used to specify a time period for keeping a user account disabled once it gets locked 
                due to repeated failed attempts to login as defined by the two options defined above. 
                The locked user account will be enabled automatically after the lock period specified here has elapsed.
            </p>

            <p>
                Click Save Changes button to save the changes. 
                After a password policy change, when a user logs in, 
                if his/her password does not comply with the new password policy, 
                he/she will be prompted to change the password.
            </p>

            <h4 className="mb-2">Mail Domains</h4>
            <p>
                This option is used to define email domains for the customer. 
                Emails created with the defined domains only will be accepted for Tech Advance<sup>+</sup> users. 
                This option allows the Administrators to identify the authenticated mail domains and helps to 
                eliminate mails from the system to unauthorized domains.  
            </p>

            <p>
                <b><span className="doc-subtitle">Add:</span></b>
            </p>
            <p>
                A new mail domain can be added by clicking the {' '}
                <span className="doc-subtitle">Add </span>
                <Button className="doc-button-icon"> <BsPlusLg className="icon" /> </Button> icon.
            </p>

            <p>
                <b><span className="doc-subtitle">Edit:</span> </b>
            </p>
            <p>
                Super Administrators can edit a mail domain by clicking {' '}
                <Button className="doc-button-icon"> 
                    <i className="pi pi-ellipsis-v icon"/> 
                </Button> {' '}
                icon on the right side of the mail domain and selecting 
                <span className="doc-subtitle"> Edit </span> option from it.
            </p>

            <p>
                <b><span className="doc-subtitle">Delete:</span></b>
            </p>
            <p>
                Super Administrators can delete a mail domain by clicking {' '}
                <Button className="doc-button-icon"> 
                    <i className="pi pi-ellipsis-v icon"/> 
                </Button> {' '} on the right side of the mail domain and  selecting the 
                <span className="doc-subtitle"> Delete </span> option from it. 
                Only mail domains which are not in use can be deleted.
            </p>

        </>
    )
}

export default SecuritySettingsDocument;