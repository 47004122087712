import React, { memo, useEffect, useState } from 'react'
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';

interface EntityModalType {
  showModal: boolean;
  onHide: (selectedUsers) => void;
  entities?: any;
  selectedEntities?: any;
}
const EntityModal: React.FC<EntityModalType> = (props) => {
  const { showModal, onHide = () => { }, entities, selectedEntities } = props;
  const [selection, setSelection] = useState(null)


  useEffect(() => {
    if (selectedEntities) {
      setSelection(selectedEntities)
    }
  }, [selectedEntities])

  return (
    <Dialog
      showHeader={false}
      visible={showModal}
      onHide={() => onHide(selection)}
      className="logbook-activity"
    >
      <div className="custom-header">
        <div className="row">
          <div className="col-6 box py-1 pr-0 pl-1">
            <div className="title">Entities</div>
          </div>

          <div className="col-6 box py-1 pl-0 align-items-end pr-1">
            <i className="pi pi-times" onClick={() => onHide(selection)}></i>
          </div>
        </div>
      </div>
      <div className='custom-table'>
        <DataTable
          value={entities}
          selection={selection}
          onSelectionChange={e => setSelection(e.value)}
          dataKey="entityId"
          responsiveLayout="scroll">
          <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>
          <Column field="entityName" header="Entity Name"></Column>
        </DataTable>
      </div>
    </Dialog>
  )
}

export default memo(EntityModal);