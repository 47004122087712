import React, { useState } from "react";

import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

import InformationBox from "components/common/Message/InformationBox/InformationBox";

interface DurationDate {
    showModal: boolean;
    setShowModal: (state: boolean) => void;
    periodStartDate: any;
    periodEndDate: any;
    setPeriodStartDate: (data: any) => void;
    setPeriodEndDate: (data: any) => void;
    setSelectedStatus: (data: any) => void;
    onDateUpdated: () => void;
}

export const DateSelectModal: React.FC<DurationDate> = ({
    showModal = false,
    setShowModal = () => { },
    periodStartDate = '',
    periodEndDate = '',
    setPeriodStartDate,
    setPeriodEndDate,
    setSelectedStatus = () => { },
    onDateUpdated = () => { },
}) => {

    const [displayInfoModal, setDisplayInfoModal] = useState(false);
    const [message, setMessage] = useState("");

    const onDateSubmit = () => {
        if (new Date(periodStartDate) > new Date(periodEndDate)) {
            setDisplayInfoModal(true)
            setMessage("End Date should be greater than Start Date")
        } else {
            onDateUpdated();
        }
    };

    const onCancel = () => {
        setSelectedStatus(0)
    };

    const renderFooter = () => {
        return (
            <>
                <Button
                    label="Cancel"
                    onClick={() => {
                        onCancel();
                        setShowModal(false);
                    }}
                    className="cancel btn"
                />
                <Button label="OK" className="update btn" autoFocus onClick={onDateSubmit} />
            </>
        );
    };

    return (
        <>
            <Dialog
                header="Select Period"
                visible={showModal}
                onHide={() => {
                    onCancel();
                    setShowModal(false);
                }}
                style={{ width: "35vw", maxHeight: "100%" }}
                footer={renderFooter()}
                className="display-settings personal selectsite"
            >
                <div className="row px-2 mt-1">
                    <div className="field col-12 md:col-2 mb-0">
                        <span className="p-float-label">
                            <Calendar
                                id="startDate"
                                className='w-100'
                                dateFormat="yy-mm-dd"
                                value={new Date(periodStartDate)}
                                onChange={(e) => setPeriodStartDate(e.value)} />
                            <label htmlFor="startDate">
                                Start Date
                            </label>
                        </span>
                    </div>
                </div>

                <div className="row px-2">
                    <div className="field col-12 md:col-2 mb-0">
                        <span className="p-float-label">
                            <Calendar
                                id="startDate"
                                className='w-100'
                                dateFormat="yy-mm-dd"
                                value={new Date(periodEndDate)}
                                onChange={(e) => setPeriodEndDate(e.value)} />
                            <label htmlFor="calendar">
                                End Date
                            </label>
                        </span>
                    </div>
                </div>

            </Dialog>
            <InformationBox showInfoModal={displayInfoModal} setShowInfoModal={setDisplayInfoModal} message={message} />
        </>
    );
};

export default DateSelectModal;
