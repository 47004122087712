import React, { useState, useCallback, useRef, useEffect, memo } from "react";
import { useForm, Controller } from "react-hook-form";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import PopOver from "components/common/PopOver/PopOver";
import { ProgressSpinner } from 'primereact/progressspinner';
import { OverlayPanel } from "primereact/overlaypanel";
import moment from "moment";
import DisplaySetting from "components/common/DisplaySetting/DisplaySetting";
//icons
import { BsExclamationCircleFill, BsPaperclip, BsPlusLg, BsFillPencilFill, BsTrashFill } from "react-icons/bs";
import { IoIosCopy, IoIosFunnel } from "react-icons/io";
import { FaChevronDown, FaCog, FaDownload } from "react-icons/fa";
import { BiRefresh } from "react-icons/bi";

//components
import DataTableComponent from "components/Table/DataTable";
import AddEquipment from "./Manage/AddEquipment/AddEquipment";
import EditEquipment from "./Manage/EditEquipment/EditEquipment";
import FilterEquipment from "./Manage/FilterEquipment/FilterEquipment";
import AdvancedSearch from "./Manage/AdvanceSearch/AdvancedSearch";
import AddEquipmentList from "./ManageEquipmentGrp/AddEquipmentGroup";
import EditEquipmentList from "./ManageEquipmentGrp/EditEquipmentGroup";
import EquipmentListDataImport from "./DataImport/EquipmentListDataImport";
import ImportEquipment from "pages/InspectionTasks/ImportEquipment/ImportEquipment";
import Connectivity from "pages/InspectionTasks/Connectivity/Connectivity";
import MultipleEditEquipment from "./Manage/MultipleEditEquipment/MultipleEditEquipment";
import CloneEquipment from "./Manage/CloneEquipment/CloneEquipment";
import InformationBox from "components/common/Message/InformationBox/InformationBox";
import ConfirmationBox from "components/common/Message/ConfirmationBox/ConfirmationBox";
import AttachmentList from "./Manage/Attachments/AttachmentList";
import EquipmentListData from "./EquipmentListData";
import InspectionDetailsReport from "../Report/InspectionDetailsReport";


//redux
import { useDispatch } from "react-redux";
import { useAppSelector } from "redux/hooks";
import {
	getEquipmentDatafields,
	getEquipmentList,
	getEquipmentPanelList,
	getEquipmentTypeListWithDefaultEquipmentClass,
	onEquipmentDetailsCloned,
	onEquipmentDetailsSaved,
	onEquipmentDetailsUpdated,
	onEquipmentImportCheck,
	onEquipmentUsageChecked,
	setEquipmentDatafields,
	setEquipmentImportCheckData,
	setEquipmentList,
	setNewEquipment,
	setRemovedEquipment,
	setUpdatedEquipment,
	onConnectAssociatedDevices,
	setUpdatedEquipmentAddress,
	onGetMultipleEditEquipmentData,
	onMultipleEditEquipmentDataUpdate,
	setMultipleUpdatedData,
	setMultipleEditDatafields,
	onGetArchievedSearches,
	setArchievedSearches,
	setNewEquipmentGroup,
	setUpdatedEquipmentGroup,
	setEquipmentPanelList,
	setUploadedExcels,
	setExcelMappingColumn,
	setWorksheets,
	onResetEquipments,
	onEquipmentRemoved,
	setFetchedEquipmentData,
	setIsDevReadingChanged,
	setIsTestedDateChanged,
	setEquipmentAttachmentlist,
	setUserDefaultEquipmentClass,
	setIsEquipmentClassDropdownChanged,
	addUserDefaultEquipmentClass
} from "redux/pages/Inspection/InspectionTask/InspectionDetails/Equipment/EquipmentSlice";
import { onExportReport } from "redux/common/report/customizeReportSlice";
import { setProgressFlag } from "redux/common/commonSlice";
import { setSelectedFailedEquipment } from "redux/pages/Dashboard/dashboardSlice";

//utility
import { blockedNonKeyboardCharacters, showUI } from 'service/GeneralUtility';

interface ListItem {
	id: number;
	name: string;
	selected: boolean;
}

interface IInspectionDetails {
	showModal: boolean;
	setShowModal: (value: boolean) => void;
}

type EqpGroupFormData = {
	address: string;
	name: string;
	type: string;
};

const EQPGROUP_ACTIVETAB = {
	ADD: "ADD",
	EDIT: "EDIT",
};

interface EquipmentListPropType {
	selectedTask?: any;
}

const EquipmentList: React.FC<EquipmentListPropType> = (props) => {
	const { selectedTask } = props;
	const equipmentListPageDetails = {
		currentLength: 100,
		currentStart: 0,
		totalCount: 500,
		pageCount: 100,
	};

	const [pageIndex, setPageIndex] = useState(0);
	const [currentStart, setCurrentStart] = useState(0);
	const [sortField, setSortField] = useState("");
	const [sortOrder, setSortOrder] = useState("");
	const [selectedRows, setSelectedRows] = useState<any>([]);
	const [selectedEquipmentGroup, setSelectedEquipmentGroup] = useState<any>(null);
	const [selectedEquipmentType, setSelectedEquipmentType] = useState<any>(null);
	const [showFilterEquipment, setShowFilterEquipment] = useState(false);
	const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);
	const [selectedReports, setSelectedReports] = useState<any>(null);
	const [showEqpGroupModal, setShowEqpGroupModal] = useState<boolean>(false);
	const [activeTab, setActiveTab] = useState<string>(EQPGROUP_ACTIVETAB.ADD);
	const [showImportEquipment, setShowImportEquipment] = useState(false);
	const [showDisplaySetting, setShowDisplaySetting] = useState<boolean>(false);
	const settingRef = React.createRef<OverlayPanel>();
	const eqpForm: any = useRef();
	const downloadRef = React.createRef<OverlayPanel>();
	const [columns, setColumns] = useState<any>([]);
	const [backupColumns, setBackupColumns] = useState<any>([]);
	const [filteredBody, setFilteredBody] = useState<any>(null);
	const [tableColumns, setTableColumns] = useState<Array<any>>(backupColumns);
	const [gridData, setGridData] = useState<any>([]);
	const [showAddEquipment, setShowAddEquipment] = useState(false);
	const [showEditEquipment, setShowEditEquipment] = useState(false);
	const [showCloneEquipment, setShowCloneEquipment] = useState(false);
	const [showDeletePrompt, setShowDeletePrompt] = useState(false);
	const [removedEquipmentIds, setRemovedEquipmentIds] = useState<any>([]);
	const [displayInfoModal, setDisplayInfoModal] = useState(false);
	const [message, setMessage] = useState("");
	const [pageDetails, setPageDetails] = useState<any>();
	const [editOrClone, setEditOrClone] = useState("");
	const [showAddEquipmentList, setShowAddEquipmentList] = useState<boolean>(false);
	const [showEquipmentListDatImport, setShowEquipmentListDataImport] = useState<boolean>(false);
	const [showEditEquipmentList, setShowEditEquipmentList] = useState<boolean>(false);
	const [showConnectivityModal, setShowConnectivityModal] = useState<boolean>(false);
	const [showConnectivityPrompt, setShowConnectivityPrompt] = useState<boolean>(false);
	const [excelDetails, setExcelDetails] = useState<any>();
	const [excelBodyInfo, setExcelBodyInfo] = useState();
	const [showIcon, setShowIcon] = useState(true);
	const [showMultipleEditEquipment, setShowMultipleEditEquipment] = useState(false);
	const [generalProperties, setGeneralProperties] = useState<any>([]);
	const [equipmentPanels, setEquipmentPanels] = useState<any>([]);
	const [showReportModal, setShowReportModal] = useState(false);
	const [reportFlag, setReportFlag] = useState<any>('all');
	const [displayConfirmModal, setDisplayConfirmModal] = useState(false);
	const [isReset, setIsReset] = useState(false);
	const [showAttachmentListModal, setShowAttachmentListModal] = useState(false);
	const [showProgressBar, setShowProgressBar] = useState(false);
	const [showModalProgress, setShowModalProgress] = useState(false);
	const [inLineDataFields, setInLineDataFields] = useState<any>([]);
	const [datafields, setDatafields] = useState<any>([]);

	const dispatch = useDispatch<any>();
	const equipmentList = useAppSelector((state) => state.equipment.equipmentList);
	const equipmentPanelList = useAppSelector((state) => state.equipment.equipmentPanelList);
	const equipmentTypeList = useAppSelector((state) => state.equipment.equipmentTypeList);
	const equipmentDatafields = useAppSelector((state) => state.equipment.equipmentDatafields);
	const newEquipment = useAppSelector((state) => state.equipment.newEquipment);
	const updateEquipment = useAppSelector((state) => state.equipment.updatedEquipment);
	const removedEquipment = useAppSelector((state) => state.equipment.removedEquipment);
	const equipmentImportCheckData = useAppSelector((state) => state.equipment.equipmentImportCheckData);
	const updatedEquipmentAddress = useAppSelector((state) => state.equipment.updatedEquipmentAddress);
	const multipleEditDatafields = useAppSelector((state) => state.equipment.multipleEditDatafields);
	const multipleUpdatedData = useAppSelector((state) => state.equipment.multipleUpdatedData);
	const archievedSearches = useAppSelector((state) => state.equipment.archievedSearches);
	const userPrivileges = useAppSelector((state) => state.user.userPrivileges);
	const newEquipmentGroup = useAppSelector((state) => state.equipment.newEquipmentGroup);
	const updatedEquipmentGroup = useAppSelector((state) => state.equipment.updatedEquipmentGroup);
	const typeOfEquipmentList = useAppSelector((state) => state.equipment.typeOfEquipmentList);
	const fetchedEquipmentData = useAppSelector((state) => state.equipment.fetchedEquipmentData);
	const selectedFailedEquipment = useAppSelector((state) => state.dashboard.selectedFailedEquipment);
	const isDevReadingChanged = useAppSelector((state) => state.equipment.isDevReadingChanged);
	const isTestDateChanged = useAppSelector((state) => state.equipment.IsTestDateChanged);
	const equipmentAttachmentlist = useAppSelector((state) => state.equipment.equipmentAttachmentlist);
	const loggedInUserDetails = useAppSelector((state) => state.administration.loggedInUserDetails);
	const userDefaultEquipmentClass = useAppSelector((state) => state.equipment.userDefaultEquipmentClass);

	const {
		control,
		handleSubmit,
		formState: { errors },
	} = useForm<EqpGroupFormData>({
		defaultValues: {
			address: "",
			name: "",
			type: "",
		},
	});
	
	useEffect(() => {
		if (backupColumns) {
			setTableColumns(columns);
		}
	}, [backupColumns]);


	useEffect(() => {
		if (tableColumns) {
			setColumns(tableColumns)
		}

	}, [tableColumns])


	useEffect(() => {
		if (selectedTask && selectedTask.uniqueInspectionTaskId) {
			if (selectedTask?.status === "Closed" || selectedTask?.status === "Completed" || selectedTask?.status === "Inactive")
				setShowIcon(false)
			else
				setShowIcon(true)
			dispatch(getEquipmentPanelList(selectedTask?.uniqueInspectionTaskId));
			dispatch(getEquipmentTypeListWithDefaultEquipmentClass(userDefaultEquipmentClass,selectedTask?.uniqueInspectionTaskId));
		}		
		setDisplayInfoModal(false);
	}, [selectedTask]);

	useEffect(() => {
		if (equipmentPanelList.length > 0) {
			setEquipmentPanels(equipmentPanelList)
			if (equipmentPanelList.length > 1)
				setSelectedEquipmentGroup(equipmentPanelList[1].equipmentId);
			else
				setSelectedEquipmentGroup(equipmentPanelList[0].equipmentId);
			dispatch(setEquipmentPanelList(""));
		}
		if (equipmentTypeList) {
			setSelectedEquipmentType(userDefaultEquipmentClass);
		}
	}, [equipmentPanelList, equipmentTypeList]);


   useEffect(() =>{
	if(equipmentAttachmentlist.length > 0 ){
		let equipmentData = JSON.parse(JSON.stringify(gridData));
		let currentIndex = gridData.findIndex((item: any) => item.EquipmentId === selectedRows[0].EquipmentId);
		equipmentData[currentIndex]["Edited By"] = equipmentAttachmentlist[0].uploadedBy
		setGridData(equipmentData)
		dispatch(setEquipmentAttachmentlist([]));
	}
   },[equipmentAttachmentlist])

	useEffect(() => {
		if (selectedEquipmentGroup != null && selectedEquipmentType != null) {
			const body = {
				uniquePanelId: selectedEquipmentGroup,
				uniqueEquipmentClassId: selectedEquipmentType !== "All" ? selectedEquipmentType : null,
				uniqueSystemId: selectedTask?.system?.uniqueSystemId,
				inspectionTaskStatus: selectedTask?.status,
				filterType: "NONE"
			};
			dispatch(getEquipmentList(selectedTask?.uniqueInspectionTaskId, body, 0));
			setPageIndex(0);
		}
	}, [selectedTask, selectedEquipmentGroup, selectedEquipmentType]);

	useEffect(() => {
		if (newEquipmentGroup) {
			let lists = JSON.parse(JSON.stringify(equipmentPanels))
			if (newEquipmentGroup?.equipmentId > 0) {
				const filtered = equipmentPanels?.filter((item) => item?.equipmentId === 0);
				if (lists.length && filtered?.length === 0) {
					lists.splice(0, 0, { equipmentId: 0, name: "All" });
				}
				lists.push(newEquipmentGroup)
				setMessage("Equipment data added");
				setEquipmentPanels(lists);
				setSelectedEquipmentGroup(newEquipmentGroup?.equipmentId);
				setShowAddEquipmentList(false);
			} else {
				setMessage("Address already in use, cannot be added");
			}
			setDisplayInfoModal(true);
			dispatch(setNewEquipmentGroup(""));
		}
	}, [newEquipmentGroup]);

	useEffect(() => {
		if (updatedEquipmentGroup) {
			let lists = JSON.parse(JSON.stringify(equipmentPanels));
			const index = lists?.findIndex((item) => item?.equipmentId === updatedEquipmentGroup?.equipmentId);
			if (index >= 0) {
				lists[index].name = updatedEquipmentGroup?.name;
			}
			setEquipmentPanels([...lists]);
			setMessage("Equipment data updated");
			setDisplayInfoModal(true);
			dispatch(setUpdatedEquipmentGroup(""));
			setShowEditEquipmentList(false)
		}
	}, [updatedEquipmentGroup])

	useEffect(() => {
		if (equipmentList) {
			if (typeOfEquipmentList !== "ADVANCED_FILTER") {
				setEquipmentData(equipmentList);
			} else {
				if (equipmentList?.data?.length) {
					setEquipmentData(equipmentList);
				}
			}
		}
	}, [equipmentList]);

	useEffect(() => {
		if (equipmentDatafields) {
			setDatafields(equipmentDatafields);
			dispatch(setEquipmentDatafields(""));
		}
	}, [equipmentDatafields])

	const setEquipmentData = (list) => {
		setGridData([...list?.data]);
		if (list?.data?.length) {
			if (selectedFailedEquipment) {
				let index = list?.data.findIndex(element => element.EquipmentId === +(selectedFailedEquipment));
				if (index !== -1) {
					setSelectedRows([list?.data[index]]);
				} else {
					setSelectedRows([list?.data[0]]);
				}
			} else {
				setSelectedRows([list?.data[0]]);
			}
		} else {
			setSelectedRows([]);
		}
		setPageDetails(list?.pageDetails);
		setGeneralProperties(list?.generalProperties)
		dispatch(setEquipmentList(""));
		setShowFilterEquipment(false);
		setShowAdvancedSearch(false);
		dispatch(setArchievedSearches(""));
	}

	useEffect(() => {
		if (newEquipment) {
			if (newEquipment.isSuccess === true) {
				gridData?.unshift(newEquipment.data);
				setGridData([...gridData]);
				setMessage("Equipment added");
				setDisplayInfoModal(true);
				setSelectedRows([newEquipment.data]);
			} else {
				setMessage(newEquipment?.message);
				setDisplayInfoModal(true);
			}
			dispatch(setNewEquipment(""));
			setShowModalProgress(false);
		}
	}, [newEquipment]);

	useEffect(() => {
		if (updateEquipment) {
			if (updateEquipment.isSuccess === true) {
				const findIndex = gridData?.findIndex((item) => item.EquipmentId === updateEquipment?.data?.EquipmentId);
				if (findIndex >= 0) {
					gridData[findIndex] = updateEquipment.data;
					setGridData([...gridData]);
					setSelectedRows([updateEquipment.data]);
					setMessage("Equipment updated");
					setDisplayInfoModal(true);
					setTimeout(() => {
						setMessage("");
						setDisplayInfoModal(false);
					}, 2000);
				}
			} else {
				setMessage(updateEquipment?.message);
				setDisplayInfoModal(true);
			}
			dispatch(setUpdatedEquipment(""));
		}
		setShowModalProgress(false);
		setShowProgressBar(false);
		setInLineDataFields([]);
	}, [updateEquipment]);

	useEffect(() => {
		if (multipleUpdatedData) {
			multipleUpdatedData?.forEach(element => {
				const index = gridData?.findIndex((item) => item.EquipmentId === element.EquipmentId);
				if (index >= 0) {
					gridData[index] = element;
				}
			});
			setGridData([...gridData]);
			if (isReset) {
				setMessage("Equipment Test Result values reset");
				setDisplayInfoModal(true);
			} else {
				setMessage("Equipment updated");
				setDisplayInfoModal(true);
			}
			dispatch(setMultipleUpdatedData(""));
		}
	}, [multipleUpdatedData])

	useEffect(() => {
		if (updatedEquipmentAddress) {
			if (updatedEquipmentAddress.address && updatedEquipmentAddress.equipmentId) {
				const findIndex = gridData?.findIndex((item) => item.EquipmentId === selectedRows[0]?.EquipmentId);
				if (findIndex >= 0) {
					gridData[findIndex] = { ...selectedRows[0], Address: updatedEquipmentAddress.address };
					setGridData([...gridData]);
					dispatch(setUpdatedEquipmentAddress(""));
				}
			}
		}
	}, [updatedEquipmentAddress]);

	useEffect(() => {
		if (removedEquipment) {
			if (removedEquipment?.isSuccess) {
				for (let i = 0; i < removedEquipment?.equipmentIds?.length; i++) {
					const findIndex = gridData?.findIndex((item) => item.EquipmentId == removedEquipment?.equipmentIds[i]);
					if (findIndex >= 0) {
						gridData.splice(findIndex, 1);
					}
					setGridData([...gridData]);
					setShowDeletePrompt(false);
					setMessage(removedEquipment?.returnMessage);
					setSelectedRows([]);
					setDisplayInfoModal(true);
					setRemovedEquipment([]);
				}
			} else {
				setDisplayInfoModal(true);
				setMessage(removedEquipment?.ReturnMessage);
			}
			dispatch(setRemovedEquipment(""));
			setShowProgressBar(false);
		}
	}, [removedEquipment]);

	useEffect(() => {
		if (equipmentImportCheckData) {
			if (equipmentImportCheckData.isSuccess === false) {
				if (selectedRows?.length > 1) {
					setMessage("Select a single row of Equipment");
					setDisplayInfoModal(true);
					return;
				}
				if (selectedEquipmentGroup !== null && selectedEquipmentType !== null && selectedEquipmentGroup !== 0 && selectedEquipmentType !== "All") {
					const body = {
						UniqueInspectionTaskId: selectedTask?.uniqueInspectionTaskId,
						UniqueEquipmentClassId: selectedEquipmentType,
						EquipmentId: selectedRows?.[0]?.EquipmentId,
						UniquePanelId: selectedEquipmentGroup,
						uniqueSystemId: selectedTask?.system?.uniqueSystemId,
					};
					dispatch(getEquipmentDatafields(body));
					setShowCloneEquipment(true);
				} else {
					setDisplayInfoModal(true);
					setMessage(
						selectedEquipmentGroup === null && selectedEquipmentType === null
							? "Select an Equipment Group and Equipment Type"
							: selectedEquipmentGroup === 0 && selectedEquipmentType === "All"
								? "Select an Equipment Group and Equipment Type"
								: selectedEquipmentGroup === null || selectedEquipmentGroup === 0
									? "Select an Equipment Group"
									: selectedEquipmentType === null || selectedEquipmentType === "All"
										? "Select an Equipment Type"
										: ""
					);
				}
			} else if (equipmentImportCheckData.isSuccess === true) {
				setShowCloneEquipment(false);
				setMessage("Equipment imported via XML Parsing/TAP Bridge cannot be cloned");
				setDisplayInfoModal(true);
			}
			dispatch(setEquipmentImportCheckData(""));
		}
	}, [equipmentImportCheckData]);

	useEffect(() => {
		if (fetchedEquipmentData?.status === "done") {
			setShowEquipmentListDataImport(true);
			setShowProgressBar(false);
		} else if (fetchedEquipmentData?.status === "error") {
			setShowProgressBar(false);
			dispatch(setFetchedEquipmentData(""));
		}
	}, [fetchedEquipmentData])

	const getFormErrorMessage = (name) => {
		return (
			errors[name] && (
				<span className="tooltip-text">
					<BsExclamationCircleFill />
					{errors[name].message}
				</span>
			)
		);
	};

	const onEquipmentFormSubmit = (data: any) => { };

	const onError = (errors: any, e: any) => { };

	const onPageChanged = (options: any) => {
		if (options !== pageIndex) {
			setPageIndex(options);
			let currentStart = options * pageDetails?.currentLength + 1;
			setCurrentStart(currentStart);
			if (selectedEquipmentGroup != null && selectedEquipmentType != null) {
				let body: any = {};
				if (typeOfEquipmentList === "ADVANCED_FILTER") {
					body = filteredBody
				} else if (typeOfEquipmentList === "FILTER") {
					body = filteredBody
				} else {
					body = {
						uniquePanelId: selectedEquipmentGroup,
						uniqueEquipmentClassId: selectedEquipmentType !== "All" ? selectedEquipmentType : null,
						uniqueSystemId: selectedTask?.system?.uniqueSystemId,
						inspectionTaskStatus: selectedTask?.status,
						filterType: "NONE"
					};
				};
				dispatch(getEquipmentList(selectedTask?.uniqueInspectionTaskId, body, currentStart, sortField, sortOrder));
			}
		}
	};

	const onSort = (event) => {
		let sortDirectionValue: string;
		if (event.sortOrder === 1) sortDirectionValue = "ASC";
		else sortDirectionValue = "DESC";
		setSortField(event.sortField);
		setSortOrder(sortDirectionValue);
		if (selectedEquipmentGroup != null && selectedEquipmentType != null) {
			let body: any = {}
			if (typeOfEquipmentList === "ADVANCED_FILTER") {
				body = filteredBody
			} else if (typeOfEquipmentList === "FILTER") {
				body = filteredBody
			} else {
				body = {
					uniquePanelId: selectedEquipmentGroup,
					uniqueEquipmentClassId: selectedEquipmentType !== "All" ? selectedEquipmentType : null,
					uniqueSystemId: selectedTask?.system?.uniqueSystemId,
					inspectionTaskStatus: selectedTask?.status,
					filterType: "NONE"
				}

			};
			dispatch(getEquipmentList(selectedTask?.uniqueInspectionTaskId, body, currentStart, event?.sortField, sortDirectionValue));
		}
	};

	const handleRowSelect = useCallback((data: any) => {
		if (data && data.length) {
			setSelectedRows([...data]);
		}
	}, []);

	const onEquipmentTypeDropDownChange = (data) => {
		dispatch(setUserDefaultEquipmentClass(data.value)); 
		setSelectedEquipmentType(data.value);
		dispatch(setIsEquipmentClassDropdownChanged(true))
        const body = {
            equipmentClassId:  data.value == "All" ? 0 : data.value,
            uniqueInspectionTaskId : selectedTask?.uniqueInspectionTaskId
        };
        dispatch(addUserDefaultEquipmentClass(body))
    }
	

	const onAddEquipmentClick = () => {
		if (selectedEquipmentGroup !== null && selectedEquipmentType !== null && selectedEquipmentGroup !== 0 && selectedEquipmentType !== "All") {
			const body = {
				UniqueInspectionTaskId: selectedTask?.uniqueInspectionTaskId,
				UniqueEquipmentClassId: selectedEquipmentType !== "All" ? selectedEquipmentType : null,
				EquipmentId: 0,
				UniquePanelId: selectedEquipmentGroup,
				uniqueSystemId: selectedTask?.system?.uniqueSystemId,
			};
			setShowAddEquipment(true);
			dispatch(getEquipmentDatafields(body));
		} else {
			setDisplayInfoModal(true);
			setMessage(
				selectedEquipmentGroup === null && selectedEquipmentType === null
					? "Select an Equipment Group and Equipment Type"
					: selectedEquipmentGroup === 0 && selectedEquipmentType === "All"
						? "Select an Equipment Group and Equipment Type"
						: selectedEquipmentGroup === null || selectedEquipmentGroup === 0
							? "Select an Equipment Group"
							: selectedEquipmentType === null || selectedEquipmentType === "All"
								? "Select an Equipment Type"
								: ""
			);
		}
	};

	const onShowImportExcel = () => {
		if (selectedEquipmentGroup !== null && selectedEquipmentGroup !== 0) {
			setShowImportEquipment(true)
		} else {
			setDisplayInfoModal(true);
			setMessage("Select an Equipment Group");
		}
	}

	const onEditEquipmentClick = (type) => {
		setEditOrClone(type);
		if (!selectedRows?.length) return;
		if (type === "EDIT") {
			let body;
			if (selectedRows.length > 1) {
				const equipmentIds = selectedRows.map(row => row.EquipmentId).join(',');
				body = {
					UniqueInspectionTaskId: selectedTask?.uniqueInspectionTaskId,
					UniqueEquipmentClassId: selectedEquipmentType === "All" ? "00000000-0000-0000-0000-000000000000" : selectedEquipmentType,
					UniquePanelId: selectedEquipmentGroup,
					uniqueSystemId: selectedTask?.system?.uniqueSystemId,
					EquipmentsIds: equipmentIds 
				};
			}
			else if (selectedRows.length === 1) {
				body = {
					UniqueInspectionTaskId: selectedTask?.uniqueInspectionTaskId,
					UniqueEquipmentClassId: selectedEquipmentType === "All" ? selectedRows[0]?.EquipmentTypeId : selectedEquipmentType,
					UniquePanelId: selectedEquipmentGroup === 0 ? selectedRows[0]?.EquipmentGroupId : selectedEquipmentGroup,
					uniqueSystemId: selectedTask?.system?.uniqueSystemId,
				};
			}
			if (selectedRows.length > 1) {
				dispatch(onGetMultipleEditEquipmentData(body))
				setShowMultipleEditEquipment(true);
			} else if (selectedRows?.length < 1) {
				setMessage("Select an Equipment");
				setDisplayInfoModal(true);
			} else {
				body.EquipmentId = selectedRows?.[0]?.EquipmentId;
				dispatch(getEquipmentDatafields(body));
				setShowEditEquipment(true);
			}
		} else if (type === "CLONE") {
			if (selectedRows?.length > 1) {
				setMessage("Select a single row of Equipment");
				setDisplayInfoModal(true);
				return;
			}
			dispatch(onEquipmentImportCheck(selectedTask?.uniqueInspectionTaskId, selectedRows?.[0]?.EquipmentId));
		}
	};

	const onDeleteEquipmentClick = () => {
		if (!selectedRows?.length) return;
		let list: any = [];
		if (selectedRows?.length) {
			for (let i = 0; i < selectedRows?.length; i++) {
				list.push(selectedRows[i].EquipmentId);
			}
		}
		const body = {
			equipmentIds: list,
			UniqueInspectionTaskId: selectedTask?.uniqueInspectionTaskId,
		};
		dispatch(onEquipmentUsageChecked(body, setShowDeletePrompt, setDisplayInfoModal, setMessage, setRemovedEquipmentIds));
	};

	const onEquipmentAdd = useCallback(
		(fields, reset) => {
			const body = {
				ClassId: selectedEquipmentType,
				SystemId: selectedTask?.system?.uniqueSystemId,
				PanelId: selectedEquipmentGroup,
				InspectionTaskstatus: 1,
				UniqueInspectionTaskId: selectedTask?.uniqueInspectionTaskId,
				FieldList: fields,
				DateChangedIdentifier:isDevReadingChanged
			};
			const dataFieldsBody = {
				UniqueInspectionTaskId: selectedTask?.uniqueInspectionTaskId,
				UniqueEquipmentClassId: selectedEquipmentType,
				EquipmentId: 0,
				UniquePanelId: selectedEquipmentGroup,
				uniqueSystemId: selectedTask?.system?.uniqueSystemId,
			}
			setShowModalProgress(true);			
			dispatch(
				onEquipmentDetailsSaved(body, reset, dataFieldsBody)
			);
		},
		[selectedEquipmentType, selectedTask, selectedEquipmentGroup, selectedTask,isDevReadingChanged]
	);

	const goToNextRow = useCallback(() => {
		const currentIndex = gridData?.findIndex((item) => item?.EquipmentId === selectedRows[0]?.EquipmentId);
		if (currentIndex < (gridData?.length - 1)) {
			let data = gridData[currentIndex + 1];
			let body: any = {
				UniqueInspectionTaskId: selectedTask?.uniqueInspectionTaskId,
				UniqueEquipmentClassId: selectedEquipmentType === "All" ? data?.EquipmentTypeId : selectedEquipmentType,
				UniquePanelId: selectedEquipmentGroup,
				uniqueSystemId: selectedTask?.system?.uniqueSystemId,
				EquipmentId: data?.EquipmentId,
			};
			dispatch(getEquipmentDatafields(body));
			setSelectedRows([data]);

		}
	}, [gridData, selectedRows])

	const goToPrevRow = useCallback(() => {
		const currentIndex = gridData?.findIndex((item) => item?.EquipmentId === selectedRows[0]?.EquipmentId);
		if (currentIndex > 0) {
			let data = gridData[currentIndex - 1];
			let body: any = {
				UniqueInspectionTaskId: selectedTask?.uniqueInspectionTaskId,
				UniqueEquipmentClassId: selectedEquipmentType === "All" ? data.EquipmentTypeId : selectedEquipmentType,
				UniquePanelId: selectedEquipmentGroup,
				uniqueSystemId: selectedTask?.system?.uniqueSystemId,
				EquipmentId: data?.EquipmentId,
			};
			dispatch(getEquipmentDatafields(body));
			setSelectedRows([data]);
		}
	}, [gridData, selectedRows])

	const onEquipmentUpdate = useCallback((fields) => {
		const body = {
			ClassId: selectedRows?.[0]?.EquipmentTypeId,
			SystemId: selectedTask?.system?.uniqueSystemId,
			PanelId: selectedRows?.[0]?.EquipmentGroupId,
			InspectionTaskstatus: 1,
			UniqueInspectionTaskId: selectedTask?.uniqueInspectionTaskId,
			FieldList: fields,
			DateChangedIdentifier:isDevReadingChanged,
			TestDateChangedIdentifier:isTestDateChanged
		};
		setShowModalProgress(true);
		dispatch(onEquipmentDetailsUpdated(selectedTask?.uniqueInspectionTaskId, selectedRows?.[0]?.EquipmentId, body));
	},
		[
			selectedEquipmentType,
			selectedTask,
			selectedEquipmentGroup,
			selectedRows,
			selectedRows?.[0]?.EquipmentId,
			selectedRows?.[0]?.EquipmentTypeId,
			selectedRows?.[0]?.EquipmentGroupId,
			isDevReadingChanged,
			isTestDateChanged
		]
	);

	const onEquipmentClone = useCallback((fields, reset, setValue, datafields) => {
		const address = datafields?.filter((item) => item?.dataFieldName === "Address");
		const body = {
			ClassId: selectedEquipmentType,
			SystemId: selectedTask?.system?.uniqueSystemId,
			PanelId: selectedEquipmentGroup.toString(),
			InspectionTaskstatus: 1,
			UniqueInspectionTaskId: selectedTask?.uniqueInspectionTaskId,
			FieldList: fields,
		};

		dispatch(onEquipmentDetailsCloned(body, setValue, address?.[0]?.uniqueDataFieldId));
	}, [selectedEquipmentType, selectedTask, selectedEquipmentGroup, selectedTask]);

	const onDeleteEquipment = () => {
		const body = {
			equipmentIds: removedEquipmentIds,
			UniqueInspectionTaskId: selectedTask?.uniqueInspectionTaskId,
		};
		setShowProgressBar(true);
		dispatch(onEquipmentRemoved(selectedTask?.uniqueInspectionTaskId, body));
		setShowDeletePrompt(false);
	};

	const onConnectDevices = (selectedModule, submitData) => {
		const filterBody = {
			uniquePanelId: selectedEquipmentGroup,
			uniqueEquipmentClassId: selectedEquipmentType,
			uniqueSystemId: selectedTask?.system?.uniqueSystemId,
			inspectionTaskStatus: selectedTask?.status,
		};
		dispatch(onConnectAssociatedDevices(selectedTask?.uniqueInspectionTaskId, selectedModule, submitData, selectedRows, filterBody, currentStart, setMessage, setDisplayInfoModal));
	};

	const onFetchedData = useCallback((excelDetails, body) => {
		setExcelDetails(excelDetails);
		setExcelBodyInfo(body);
		setShowProgressBar(true);
	}, [])

	const onMultipleEquipmentUpdate = useCallback((data, reset, setSelectedDatafield, setModifiedContent) => {
		let equipmentIds: any = [];
		selectedRows.forEach(element => {
			equipmentIds.push(element?.EquipmentId);
		});
		const body = {
			uniqueInspectionTaskId: selectedTask?.uniqueInspectionTaskId,
			uniqueEquipmentClassId: selectedEquipmentType !== "All" ? selectedEquipmentType : '00000000-0000-0000-0000-000000000000',
			uniquePanelId: selectedEquipmentGroup,
			uniqueSystemId: selectedTask?.system?.uniqueSystemId,
			equipmentIds: equipmentIds,
			uniqueDataFieldId: data?.uniqueDataFieldId,
			dataFieldValue: data?.dataFieldValue,
			dataFieldLookUpId: data?.dataFieldLookUpId,
			inspectionTestResultId: 1,
			dateChangedIdentifier:isDevReadingChanged,
            testDateChangedIdentifier:isTestDateChanged,
			testDateUniqueFieldId:data?.testDateUniqueFieldId

		}
		dispatch(onMultipleEditEquipmentDataUpdate(body, reset, setSelectedDatafield, setModifiedContent))
	}, [selectedTask, selectedEquipmentGroup, selectedEquipmentType, selectedRows,isDevReadingChanged,isTestDateChanged]);

	const onFiltered = useCallback((body) => {
		setFilteredBody(body);
		setPageIndex(0);
		dispatch(getEquipmentList(selectedTask?.uniqueInspectionTaskId, body, 0, sortField, sortOrder));

	}, [selectedTask, pageDetails, pageIndex]);

	const onHideModal = useCallback(() => {
		setShowAdvancedSearch(false);
		dispatch(setArchievedSearches(""));
	}, [])

	const onCloseDataImport = useCallback((flag, isImportDone) => {
		setShowEquipmentListDataImport(flag);
		if (isImportDone) {
			dispatch(setUploadedExcels(""));
			dispatch(setWorksheets(""));
			dispatch(setExcelMappingColumn(""));
			setShowImportEquipment(false);
		}
		// if (excelDetails?.classId === selectedEquipmentType) {
		// 	if (selectedEquipmentGroup != null && selectedEquipmentType != null) {
		const body = {
			uniquePanelId: selectedEquipmentGroup,
			uniqueEquipmentClassId: selectedEquipmentType !== "All" ? selectedEquipmentType : null,
			uniqueSystemId: selectedTask?.system?.uniqueSystemId,
			inspectionTaskStatus: selectedTask?.status,
			filterType: "NONE",
		};
		dispatch(getEquipmentList(selectedTask?.uniqueInspectionTaskId, body, 0));
		// 	}
		// }
	}, [selectedEquipmentGroup, selectedEquipmentType, selectedTask, excelDetails]);

	const refreshEquipmentList = () => {
		if (selectedEquipmentGroup != null && selectedEquipmentType != null) {
			let body: any = {};
			if (typeOfEquipmentList === "ADVANCED_FILTER") {
				body = filteredBody
			} else if (typeOfEquipmentList === "FILTER") {
				body = filteredBody
			} else {
				body = {
					uniquePanelId: selectedEquipmentGroup,
					uniqueEquipmentClassId: selectedEquipmentType !== "All" ? selectedEquipmentType : null,
					uniqueSystemId: selectedTask?.system?.uniqueSystemId,
					inspectionTaskStatus: selectedTask?.status,
					filterType: "NONE"
				};

			};
			dispatch(getEquipmentList(selectedTask?.uniqueInspectionTaskId, body, 0, sortField, sortOrder));
			setPageIndex(0);
		}
	}

	const renderFooter = () => {
		return (
			<>
				<Button label="No" onClick={() => { setShowDeletePrompt(false); setRemovedEquipmentIds([]) }} className="cancel-btn" />
				<Button label="Yes" onClick={onDeleteEquipment} className="submit-btn" autoFocus />
			</>
		);
	};

	const connectivityPromptFooter = () => {
		return (
			<>
				<Button label="No" onClick={() => setShowConnectivityPrompt(false)} className="cancel-btn" />
				<Button label="Yes" onClick={() => {
					setShowConnectivityPrompt(false);
					setShowConnectivityModal(true);
				}} className="submit-btn" autoFocus />
			</>
		);
	};

	const onAddEquipmentGroupClick = () => {
		setShowAddEquipmentList(true)
	}

	const onEditEquipmentGroupClick = () => {
		if (selectedEquipmentGroup === null || selectedEquipmentGroup === "ALL" || selectedEquipmentGroup === 0) {
			setMessage(
				selectedEquipmentGroup === null ? "Select an Equipment Group"
					: selectedEquipmentGroup === "ALL" ? "Select an Equipment Group"
						: selectedEquipmentGroup === 0 ? "Select an Equipment Group"
							: ""
			);
			setDisplayInfoModal(true);
		}
		else {
			setShowEditEquipmentList(true)
		}
	}	

	const onReportAllClick = () => {
		setReportFlag('all');
		setShowReportModal(true);
	};

	const onReportHighlightedClick = () => {
		setReportFlag('highlighted');
		setShowReportModal(true);
	};

	const getSelectedIds = () => {
		let params: any = [];
		selectedRows.map(item => {
			params.push(item.EquipmentId);
		});
		return params;
	};

	const onExportAll = () => {
		const reportParameters = {
			exportParameters: {
				uniqueInspectionTaskId: selectedTask?.uniqueInspectionTaskId,
				uniqueSystemId: selectedTask?.system?.uniqueSystemId,
				uniqueEquipmentTypeId: selectedEquipmentType,
				panelId: selectedEquipmentGroup,
				sortColumn: sortField,
				sortOrder: sortOrder,
				selectedIds: "",
				...filteredBody,
			},
			exportType: "Equipment Details Report"
		};

		dispatch(onExportReport(reportParameters, "EquipmentList"));
	}

	const onExportHighlightedClick = () => {
		const reportParameters = {
			exportParameters: {
				uniqueInspectionTaskId: selectedTask?.uniqueInspectionTaskId,
				uniqueSystemId: selectedTask?.system?.uniqueSystemId,
				uniqueEquipmentTypeId: selectedEquipmentType,
				panelId: selectedEquipmentGroup,
				sortColumn: sortField,
				sortOrder: sortOrder,
				selectedIds: getSelectedIds(),
				...filteredBody,
			},
			exportType: "Equipment Details Report"
		};
		dispatch(onExportReport(reportParameters, "EquipmentList"));
	}

	const onClickConnectivity = () => {
		if (selectedRows?.length < 1) {
			setMessage("Select an Equipment");
			setDisplayInfoModal(true);
		} else {
			setShowConnectivityPrompt(true);
		}
	};

	const handleTableColumns = useCallback((data: any, fields) => {
		setColumns([...data]);
		setBackupColumns([...data]);
		setInLineDataFields(fields)
	}, []);

	const onRowEditClick = (rowData, isData) => {
		let isEditable = true;
		if (rowData) {
			setSelectedRows([rowData])
			const body = {
				UniqueInspectionTaskId: selectedTask?.uniqueInspectionTaskId,
				UniqueEquipmentClassId: selectedEquipmentType === "All" ? rowData?.EquipmentTypeId : selectedEquipmentType,
				EquipmentId: rowData.EquipmentId,
				UniquePanelId: selectedEquipmentGroup === 0 ? rowData?.EquipmentGroupId : selectedEquipmentGroup,
				uniqueSystemId: selectedTask?.system?.uniqueSystemId,
			};
			if (isData) {
				dispatch(getEquipmentDatafields(body));
			}
			isEditable = true;
		}

		return isEditable;
	}

	const onRowEditValidation = (rowData) => {
		let isValidation = true;
		if (inLineDataFields?.length) {
			inLineDataFields.forEach(element => {
				if (element?.fieldValue) {
					let invalidCharacters: any = blockedNonKeyboardCharacters(element?.fieldValue);
					if (invalidCharacters != null && invalidCharacters.length > 0) {
						setMessage("Non-Keyboard character(s) " + invalidCharacters.join() + " is not allowed");
						setDisplayInfoModal(true);
						isValidation = false;
					}
				}
				if (rowData[element?.dataFieldName]?.length > element.maxLength) {
					setMessage(`${element?.dataFieldName} cannot exceed ${element.maxLength}`);
					setDisplayInfoModal(true);
					isValidation = false;
				}
				if (element?.mandatory && !element?.disabled) {
					if (!rowData[element?.dataFieldName]) {
						setMessage(`${element?.dataFieldName} is required`);
						setDisplayInfoModal(true);
						isValidation = false;
					}
				}

				if (element?.dataType.dataTypeId === 3) {
					if (isNaN(Number(element.fieldValue)) && element.fieldValue !== "") {
						setMessage(`${element?.dataFieldName} should be numeric`);
						setDisplayInfoModal(true);
						isValidation = false;
					}

				}

			});
		}
		return isValidation;
	}

	const onEquipmentRowEdited = (rowData) => {
		const testMetIndex = inLineDataFields?.findIndex((item) => item.dataFieldName === "Test Method");

		let fieldDetails: any = [];
		if (inLineDataFields?.length) {
			inLineDataFields.forEach(element => {
				let item: any = {
					dataFieldId: element?.uniqueDataFieldId,
					value: rowData[element?.dataFieldName],
					multipleValue: null,
				}
				if (element?.dataEntryControl?.dataEntryControlName === "Drop-down list") {
					const selectedItem = element?.validatedValues?.filter((item) => item?.isSelected);
					if (selectedItem?.length)
						item.value = selectedItem[0]?.dataFieldLookupId;
					else
						item.value = null;
				} else if (element?.dataFieldName === "Test Date")   {
					const index = datafields?.findIndex((item) => item?.dataFieldName === "Test Date");
					const testMethod = inLineDataFields[testMetIndex]?.validatedValues?.filter((item) => item.dataFieldLookupId === rowData["Test Method"] || item.dataFieldLookupValue === rowData["Test Method"]);
					if (testMethod[0]?.dataFieldLookupId && item.value == null) {
						item.value =  moment(new Date()).utc().format("YYYY-MM-DD HH:mm:ss");
					}
					if (testMethod[0]?.dataFieldLookupId === 0) {
						item.value ='';
					}
					else if (item.value != null && isTestDateChanged != "") {
						let newDate = new Date(item.value);
                        const siteTimeOffset = loggedInUserDetails?.userDefaults?.siteOffset
                        item.value = moment(new Date(newDate.getTime() - (siteTimeOffset * 60000))).format("YYYY-MM-DD HH:mm:ss");
					}
					else if (item.value != null && isTestDateChanged == "") {
						let newDate = new Date(inLineDataFields[index]?.fieldValue);
                        const siteTimeOffset = loggedInUserDetails?.userDefaults?.siteOffset
                        item.value = moment(new Date(newDate.getTime() - (siteTimeOffset * 60000))).format("YYYY-MM-DD HH:mm:ss");
					}
					else {
						item.value = "";
					}
				} else if (element?.dataFieldName === "Address") {
					item.value = element?.fieldValue;
				}
				else if (element?.dataFieldName === "Dev Reading Date")   {
					const index = datafields?.findIndex((item) => item?.dataFieldName === "Dev Reading Date");
					if (rowData["Dev Reading"] == "") {
						item.value = "";
					}
					else if (item.value == null) {
						item.value = moment(new Date()).utc().format("YYYY-MM-DD HH:mm:ss");
					} 
					else if (item.value != null && isDevReadingChanged != "") {
						let newDate = new Date(item.value);
                        const siteTimeOffset = loggedInUserDetails?.userDefaults?.siteOffset
                        item.value = moment(new Date(newDate.getTime() - (siteTimeOffset * 60000))).format("YYYY-MM-DD HH:mm:ss");					
					}
					else if (item.value != null && isDevReadingChanged == "") {
						let newDate = new Date(inLineDataFields[index]?.fieldValue);
                        const siteTimeOffset = loggedInUserDetails?.userDefaults?.siteOffset
                        item.value = moment(new Date(newDate.getTime() - (siteTimeOffset * 60000))).format("YYYY-MM-DD HH:mm:ss");
					}
					else {
						item.value = "";
					}
				}		
				else{
					if(item.value === undefined )
					 	item.value = element?.fieldValue
				}						
			 if((element.dataEntryControl.dataEntryControlName === "Text Box" ||element.dataEntryControl.dataEntryControlName === "Text Area")&& element.fieldValue!="")
			 {
				 if (typeof element.fieldValue === "string") {
					 element.fieldValue = element.fieldValue.trim();
				 }
				 if (typeof item.value === "string") {
					 item.value = item.value.trim();
				 }
				 else {
					 element.fieldValue = element.fieldValue;
					 item.value = item.value;
				 }
			 }
				if ((element?.dataFieldName === "NFPA Classification" && item.value === -1) || (element?.dataFieldName === "Health Classification" && item.value === -1)) {
					item.value = "";
				}	
				fieldDetails.push(item)
			});
		}

		const body = {
			ClassId: rowData?.EquipmentTypeId,
			SystemId: selectedTask?.system?.uniqueSystemId,
			PanelId: rowData?.EquipmentGroupId,
			InspectionTaskstatus: 1,
			UniqueInspectionTaskId: selectedTask?.uniqueInspectionTaskId,
			FieldList: fieldDetails,
			DateChangedIdentifier:isDevReadingChanged,
			TestDateChangedIdentifier:isTestDateChanged
		};
		dispatch(setProgressFlag(true));
		dispatch(onEquipmentDetailsUpdated(selectedTask?.uniqueInspectionTaskId, rowData?.EquipmentId, body));
		dispatch(setIsDevReadingChanged(''))
		dispatch(setIsTestedDateChanged(''))	
	}

	const renderConnectivityOption = () => {
		if (selectedTask && selectedTask?.status && selectedTask?.status === "Active") {
			if (selectedEquipmentGroup !== null && selectedEquipmentGroup !== 0) {
				if (equipmentTypeList && equipmentTypeList.length) {
					const type = equipmentTypeList.find(e => e.uniqueEquipmentClassId === selectedEquipmentType);
					if (type) {
						if (type.equipmentClassName === 'Devices' || type.equipmentClassName === 'Water Based Equipment') {
							return (
								<li role="button" onClick={onClickConnectivity}>Connectivity</li>
							)
						}
					}
				}
			}
		}
	};

	const renderConnectivityPromptMessage = () => {
		if (equipmentTypeList && equipmentTypeList.length) {
			const type = equipmentTypeList.find(e => e.uniqueEquipmentClassId === selectedEquipmentType);
			if (type) {
				if (type.equipmentClassName === 'Devices') {
					return (
						<p className="content">Do you want to link this Equipment to Module ?</p>
					)
				} else {
					return (
						<p className="content">Do you want to link this Equipment to Sprinkler ?</p>
					)
				}
			}
		}
	};

	const renderDisplaySettings = () => {
		return (
			<li role="button" onClick={() => setShowDisplaySetting(true)}>Display Settings</li>
		)
	}

	const renderResetOption = () => {
		if (selectedRows[0] !== undefined && selectedEquipmentType === "All" && selectedEquipmentGroup !== 0 && selectedTask?.status === "Active") {
			return (
				<li role="button" onClick={onReset}>Reset</li>
			)
		}
	}

	const renderImportEquipmentOption = () => {
		if (selectedEquipmentGroup !== 0 && selectedTask?.status === "Active") {
			return (
				<li role="button" onClick={() => onShowImportExcel()}>Import Equipment</li>
			)
		}
	}

	const onReset = () => {
		if (selectedRows?.length < 1) {
			setMessage("Select an Equipment");
			setDisplayInfoModal(true);
		} else {
			setMessage("Are you sure you want to reset Equipment Test Result values?");
			setIsReset(true);
			setDisplayConfirmModal(true);
		}
	}

	const onConfirmClick = () => {
		if (isReset) {
			setDisplayConfirmModal(false);
			let equipmentIds: any = [];
			selectedRows.forEach(element => {
				equipmentIds.push(element?.EquipmentId);
			});
			const body = {
				uniqueSystemId: selectedTask?.system?.uniqueSystemId,
				equipmentIds: equipmentIds
			}
			dispatch(onResetEquipments(selectedRows, selectedTask?.uniqueInspectionTaskId, selectedEquipmentGroup, body));
		}

	}

	const onOkClick = () => {
		setIsReset(false);
	}

	const onAttachmentIconClick = () => {
		if (selectedRows?.length > 1) {
			setMessage("Select a single row of Equipment");
			setDisplayInfoModal(true);
		} else if (selectedRows?.length < 1) {
			setMessage("Select an Equipment");
			setDisplayInfoModal(true);
		} else {
			setShowAttachmentListModal(true);
		}
	};

	const renderHeader = () => {
		return (
			<>
				Tech Advance<sup>+</sup>
			</>
		)
	}

	const inspectionGridColumnTemplate = (rowData, options) => {
		return (
			<div className="table-col">
				{options?.field == "Test Date" && rowData[options?.field]?.length > 0 || options?.field == "Dev Reading Date" && rowData[options?.field]?.length > 0 ? (
					<>
						<span className="table-col" dangerouslySetInnerHTML={{ __html: `${rowData[options?.field]}` }} />
					</>
				) : (
					<div>{rowData[options?.field]}</div>
				)}
					</div>
				);
		};
		
	return (
		<>
			{showProgressBar &&
				<div className='pre-loader equipment-list-pre'>
					<ProgressSpinner />
				</div>
			}
			<div className="container-fluid">
				<div className="second-row row">
					<div className="select-box pb-0 pt-0 col-7">
						<div className="action-section row custom-float-field mb-0">
							<div className="col-5 field mb-0">
								<span className="p-float-label">
									<Dropdown
										inputId="equipment-group"
										className="w-100"
										value={selectedEquipmentGroup}
										onChange={(e) => {
											setSelectedEquipmentGroup(e.value);
										}}
										options={equipmentPanels}
										optionLabel="name"
										optionValue="equipmentId"
									/>
									<label htmlFor="dropdown">Equipment Group</label>
								</span>
							</div>
							{showUI(userPrivileges, "SIETAP_INSPECTIONDETAILS_EQUIPMENTGROUP_MANAGE") && selectedTask?.status === "Active" && (
								<div className="col-1">
									<div className="icon-fields">
										<div className="field">
											<span className="p-float-label action-btns center">
												<Button tooltip="Add" tooltipOptions={{ position: "right" }} className="button-icon">
													<BsPlusLg className="icon" onClick={() => onAddEquipmentGroupClick()} />
												</Button>
											</span>
										</div>
										<div className="field mt-2">
											<span className="p-float-label action-btns center">
												<Button tooltip="Edit" tooltipOptions={{ position: "right" }} className="button-icon">
													<BsFillPencilFill className="icon" onClick={() => onEditEquipmentGroupClick()} />
												</Button>
											</span>
										</div>
									</div>
								</div>
							)}
							<div className="col-5 field mb-0">
								<span className="p-float-label">
									<Dropdown
										inputId="equipment-type"
										className="w-100"
										value={selectedEquipmentType}
										onChange={(e) =>{
											onEquipmentTypeDropDownChange(e);
										}}
										options={equipmentTypeList}
										optionLabel="equipmentClassName"
										optionValue="uniqueEquipmentClassId"
									/>
									<label htmlFor="dropdown">Equipment Type</label>
								</span>
							</div>
						</div>
					</div>

					<div className="icon-wrapper col-5">
						<div className=" table-config">
							<div className="action-btns">
								{typeOfEquipmentList === "FILTER" ?
									(
										<Button
											className="button-icon"
											tooltip="Clear"
											tooltipOptions={{ position: "top" }}
											onClick={() => {
												const body = {
													uniquePanelId: selectedEquipmentGroup,
													uniqueEquipmentClassId: selectedEquipmentType !== "All" ? selectedEquipmentType : null,
													uniqueSystemId: selectedTask?.system?.uniqueSystemId,
													inspectionTaskStatus: selectedTask?.status,
													filterType: "NONE"
												};
												dispatch(getEquipmentList(selectedTask?.uniqueInspectionTaskId, body, 0));
												setPageIndex(0);
												setFilteredBody(null);
											}}
										>
											<IoIosFunnel className="icon" />
											<span>&times;</span>
										</Button>
									) : (
										<Button
											className="button-icon"
											tooltip="Filter"
											disabled={gridData?.length ? false : true}
											tooltipOptions={{ position: "top" }}
											onClick={() => setShowFilterEquipment(true)}
										>
											<IoIosFunnel className="icon" />
										</Button>
									)
								}
								{typeOfEquipmentList === "ADVANCED_FILTER" ?
									(
										<Button
											className="button-icon"
											tooltip="Clear"
											tooltipOptions={{ position: "top" }}
											onClick={() => {
												const body = {
													uniquePanelId: selectedEquipmentGroup,
													uniqueEquipmentClassId: selectedEquipmentType !== "All" ? selectedEquipmentType : null,
													uniqueSystemId: selectedTask?.system?.uniqueSystemId,
													inspectionTaskStatus: selectedTask?.status,
													filterType: "NONE"
												};
												dispatch(getEquipmentList(selectedTask?.uniqueInspectionTaskId, body, 0));
												setPageIndex(0);
												setFilteredBody(null);
											}}
										>
											<IoIosFunnel className="icon" />
											<span>&times;</span>
										</Button>
									) : (
										<Button
											className="button-icon"
											tooltip="Advanced Search"
											disabled={gridData?.length ? false : true}
											tooltipOptions={{ position: "top" }}
											onClick={() => {
												setShowAdvancedSearch(true);
												dispatch(onGetArchievedSearches(selectedTask?.uniqueInspectionTaskId));
												const body = {
													UniqueInspectionTaskId: selectedTask?.uniqueInspectionTaskId,
													UniqueEquipmentClassId: selectedEquipmentType === "All" ? "00000000-0000-0000-0000-000000000000" : selectedEquipmentType,
													EquipmentId: selectedRows?.[0]?.EquipmentId,
													UniquePanelId: selectedEquipmentGroup,
													uniqueSystemId: selectedTask?.system?.uniqueSystemId,
													isFilter: true,
												};
												dispatch(getEquipmentDatafields(body));
											}}
										>
											<IoIosFunnel className="icon" />
											<span>+</span>
										</Button>
									)
								}


								{showIcon && selectedEquipmentType !== "All" && selectedEquipmentGroup !== 0 && showUI(userPrivileges, "SIETAP_INSPECTION_EQUIPMENT_MANAGE") &&
									(<Button
										className="button-icon"
										tooltip="Add"
										tooltipOptions={{ position: "top" }}
										onClick={() => onAddEquipmentClick()}>
										<BsPlusLg className="icon" />
									</Button>)}
								{showIcon && showUI(userPrivileges, "SIETAP_INSPECTION_EQUIPMENT_MANAGE") &&
									(<Button
										className="button-icon"
										tooltip="Edit"
										disabled={gridData?.length ? false : true}
										tooltipOptions={{ position: "top" }}
										onClick={() => onEditEquipmentClick("EDIT")}
									>
										<BsFillPencilFill className="icon" />
									</Button>)}
								{showIcon && selectedEquipmentType !== "All" && showUI(userPrivileges, "SIETAP_INSPECTION_EQUIPMENT_MANAGE") &&
									(<Button
										className="button-icon"
										tooltip="Clone"
										disabled={gridData?.length ? false : true}
										tooltipOptions={{ position: "top" }}
										onClick={() => onEditEquipmentClick("CLONE")}
									>
										<IoIosCopy className="icon" />
									</Button>)}
								{showIcon && showUI(userPrivileges, "SIETAP_INSPECTION_EQUIPMENT_MANAGE") && (
									<Button
										className="button-icon"
										tooltip="Delete"
										disabled={gridData?.length ? false : true}
										tooltipOptions={{ position: "top" }}
										onClick={() => onDeleteEquipmentClick()}>
										<BsTrashFill className="icon" />
									</Button>
								)}

								<Button
									className="button-icon"
									tooltip="Refresh"
									onClick={() => refreshEquipmentList()}
									tooltipOptions={{ position: "top" }}>
									<BiRefresh className="icon" />
								</Button>
								{selectedRows.length === 1 && selectedRows[0]?.["Test Result"] === "Failed" ?
									<Button
										className="button-icon"
										tooltip="Failed Equipment Images"
										disabled={gridData?.length ? false : true}
										onClick={onAttachmentIconClick}
										tooltipOptions={{ position: "top" }}>
										<BsPaperclip className="icon" />
									</Button>
									: null
								}
								<Button
									className="button-icon"
									tooltip="Download"
									disabled={gridData?.length ? false : true}
									tooltipOptions={{ position: "top" }}
									onClick={(e) => downloadRef.current?.toggle(e)}>
									<FaDownload className="icon" />
									<FaChevronDown className="p-button-text caret-icon" />
								</Button>
								<Button
									className="button-icon"
									tooltip="More"
									tooltipOptions={{ position: "top" }}
									onClick={(e) => settingRef.current?.toggle(e)}
								>
									<FaCog className="icon" />
									<FaChevronDown className="p-button-text caret-icon" />
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>

			<>
				<EquipmentListData
					selectedRow={selectedRows}
					generalProperties={generalProperties}
					getTableColumns={handleTableColumns}
					uniqueEquipmentClassId={selectedEquipmentType} />
				{columns?.length > 0 && (
					<DataTableComponent
						rows={gridData}
						cols={columns}
						paginator={true}
						dataKeyId="EquipmentId"
						title={`Equipment List`}
						pageDetails={pageDetails}
						pageIndex={pageIndex}
						onPageChanged={onPageChanged}
						showCustomSearchCmp
						getSelectedRowData={handleRowSelect}
						onSortData={onSort}
						defaultSelectedRows={selectedRows}
						showroweditor={selectedTask?.status === "Active" ? true : false}
						onRowEditClick={onRowEditClick}
						onRowEditValidation={onRowEditValidation}
						onSaveRowEdit={onEquipmentRowEdited}
						isSelectionFromParent={true}
						selectedParentRows={selectedRows}
						isTemplateMode={true}
						isTemplateFromParent={true}
						templateFromParent={inspectionGridColumnTemplate}
					/>
				)}

				<Dialog
					header="Manage Equipment Group"
					visible={showEqpGroupModal}
					onHide={() => setShowEqpGroupModal(false)}
					className="equipment-group-modal custom-modal"
					style={{ width: "30vw" }}
				>
					<div className="row px-2 pb-1">
						<div
							className={classNames("col-6 md:col-6 mb-0 pb-0 header-tab", { active: activeTab === EQPGROUP_ACTIVETAB.ADD })}
							role="button"
							onClick={() => setActiveTab(EQPGROUP_ACTIVETAB.ADD)}
						>
							Add Equipment Data
						</div>
						<div
							className={classNames("col-6 md:col-6 mb-0 pb-0 header-tab", { active: activeTab === EQPGROUP_ACTIVETAB.EDIT })}
							role="button"
							onClick={() => setActiveTab(EQPGROUP_ACTIVETAB.EDIT)}
						>
							Edit Equipment Data
						</div>
					</div>

					<form onSubmit={handleSubmit(onEquipmentFormSubmit, onError)} ref={eqpForm}>
						<div className="row px-2 pt-2">
							<div className="field col-12 md:col-12 mb-0 custom-float-field">
								{activeTab === EQPGROUP_ACTIVETAB.ADD ? (
									<span className="p-float-label">
										<Controller
											name="address"
											control={control}
											rules={{
												required: "This field is required.",
											}}
											render={({ field, fieldState }) => (
												<InputText
													id="address"
													className={classNames("w-100 error-tooltip", { error: fieldState.invalid })}
													{...field}
												/>
											)}
										/>
										<label className="mandatory" htmlFor="inputtext">
											Address
										</label>
										{getFormErrorMessage("address")}
									</span>
								) : (
									<span className="p-float-label text-container">
										<label htmlFor="inputtext" className="mandatory">
											Address
										</label>
										<Controller name="address" control={control} render={() => <span className="text-value">Address 01</span>} />
									</span>
								)}
							</div>
						</div>

						<div className="row px-2 pt-0">
							<div className="field col-12 md:col-12 mb-0 custom-float-field">
								<span className="p-float-label">
									<Controller
										name="name"
										control={control}
										rules={{
											required: "This field is required.",
										}}
										render={({ field, fieldState }) => (
											<InputText id="name" className={classNames("w-100 error-tooltip", { error: fieldState.invalid })} {...field} />
										)}
									/>
									<label className="mandatory" htmlFor="inputtext">
										Name
									</label>
									{getFormErrorMessage("name")}
								</span>
							</div>
						</div>

						<div className="row px-2">
							<div className="field col-12 md:col-12 mb-0 custom-float-field">
								{activeTab === EQPGROUP_ACTIVETAB.ADD ? (
									<span className="p-float-label">
										<Controller
											name="type"
											control={control}
											rules={{
												required: "This field is required.",
											}}
											render={({ field, fieldState }) => (
												<Dropdown
													inputId="type"
													className={classNames("w-100 error-tooltip", { error: fieldState.invalid })}
													{...field}
													value={field.value}
													onChange={(e) => field.onChange(e.value)}
													options={[
														{ name: "Devices", code: "DV" },
														{ name: `Bosch`, code: "BS" },
														{ name: `Fire Lite`, code: "FL" },
													]}
													optionLabel="name"
													optionValue="name"
												/>
											)}
										/>
										<label className="mandatory" htmlFor="dropdown">
											Type
										</label>
										{getFormErrorMessage("type")}
									</span>
								) : (
									<span className="p-float-label text-container">
										<label htmlFor="inputtext" className="mandatory">
											Type
										</label>
										<Controller name="type" control={control} render={() => <span className="text-value">Desigo Modular</span>} />
									</span>
								)}
							</div>
							<div className="required pt-2">
								<span className="asterisk mr-1">*</span>Required
							</div>
						</div>

						<div className="row px-2 pt-3">
							<div className="submit-cta">
								<Button label="Cancel" className="cancel-btn" onClick={() => setShowEqpGroupModal(false)} />
								<Button type="submit" label={activeTab === EQPGROUP_ACTIVETAB.ADD ? "Save" : "Save Changes"} className="save-btn" />
							</div>
						</div>
					</form>
				</Dialog>
				<PopOver ref={settingRef}>
					<ul>
						<>
							{showUI(userPrivileges, "SIETAP_INSPECTION_EQUIPMENT_MANAGE") ? renderResetOption() : null}
							{showUI(userPrivileges, "SIETAP_INSPECTION_EQUIPMENT_MANAGE") ? renderConnectivityOption() : null}
							{showUI(userPrivileges, "SIETAP_INSPECTION_EQUIPMENT_MANAGE") ? renderImportEquipmentOption() : null}
							{renderDisplaySettings()}
						</>
					</ul>
				</PopOver>
				<PopOver ref={downloadRef}>
					<ul>
						<li onClick={onReportAllClick}>Report All</li>
						<li onClick={onReportHighlightedClick}>Report Highlighted</li>
						<li onClick={onExportAll}>Export All</li>
						<li onClick={onExportHighlightedClick}>Export Highlighted</li>
					</ul>
				</PopOver>
				{showImportEquipment &&
					<ImportEquipment
						showImport={showImportEquipment}
						selectedEquipmentGroup={selectedEquipmentGroup}
						selectedTask={selectedTask}
						onHideModal={() => setShowImportEquipment(false)}
						onFetchedData={onFetchedData}
						selectedEuipmentType={selectedEquipmentType} />}
				{showAddEquipment && datafields && (
					<AddEquipment
						showModal={showAddEquipment}
						datafields={datafields}
						onFormSubmit={onEquipmentAdd}
						showProgressBar={showModalProgress}
						onHideModal={() => {
							setShowModalProgress(false);
							setShowAddEquipment(false);
							setDatafields([]);
							dispatch(setEquipmentDatafields(""));
						}}
						uniqueEquipmentClassId={selectedEquipmentType}
					/>
				)}
				{showEditEquipment && datafields && (
					<EditEquipment
						gridData={gridData}
						selectedRow={selectedRows}
						showModal={showEditEquipment}
						// setShowModal={setShowEditEquipment}
						datafields={datafields}
						onFormSubmit={onEquipmentUpdate}
						onClickNext={goToNextRow}
						onClickPrev={goToPrevRow}
						showProgressBar={showModalProgress}
						onHideModal={() => {
							setShowModalProgress(false);
							setShowEditEquipment(false);
							setDatafields([]);
							dispatch(setEquipmentDatafields(""));
						}}
						uniqueEquipmentClassId={selectedEquipmentType === "All" ? selectedRows[0]?.EquipmentTypeId : selectedEquipmentType}
					/>
				)}
				{showCloneEquipment && datafields && (
					<CloneEquipment
						header="Clone Equipment"
						showModal={showCloneEquipment}
						datafields={datafields}
						onFormSubmit={onEquipmentClone}
						onHideModal={() => {
							setShowCloneEquipment(false);
							setDatafields([])
						}}
						uniqueEquipmentClassId={selectedEquipmentType}
					/>
				)}
				{showDeletePrompt && (
					<Dialog
						header={renderHeader()}
						visible={showDeletePrompt}
						style={{ width: "35vw" }}
						footer={renderFooter()}
						onHide={() => setShowDeletePrompt(false)}
						className="delete-modal"
					>
						<p className="content">{message}</p>
					</Dialog>
				)}

				{showConnectivityPrompt && (
					<Dialog
						header="Tech Advance +"
						visible={showConnectivityPrompt}
						style={{ width: "35vw" }}
						footer={connectivityPromptFooter()}
						onHide={() => setShowConnectivityPrompt(false)}
						className="delete-modal"
					>
						{renderConnectivityPromptMessage()}
					</Dialog>
				)}

				{showAddEquipmentList &&
					<AddEquipmentList
						selectedTask={selectedTask}
						showModal={showAddEquipmentList}
						setShowModal={setShowAddEquipmentList} />}
				{showEquipmentListDatImport &&
					<EquipmentListDataImport
						selectedEquipmentType={selectedEquipmentType}
						selectedEquipmentGroup={selectedEquipmentGroup}
						selectedTask={selectedTask}
						excelDetails={excelDetails}
						bodyInfo={excelBodyInfo}
						showModal={showEquipmentListDatImport}
						setShowModal={(flag, isImportDone) => onCloseDataImport(flag, isImportDone)} />}
				{showEditEquipmentList &&
					<EditEquipmentList
						selectedPanel={selectedEquipmentGroup}
						selectedTask={selectedTask}
						showModal={showEditEquipmentList}
						setShowModal={setShowEditEquipmentList} />}
				{showFilterEquipment &&
					<FilterEquipment
						showModal={showFilterEquipment}
						generalProperties={generalProperties}
						uniquePanelId={selectedEquipmentGroup}
						uniqueEquipmentClassId={selectedEquipmentType}
						selectedTask={selectedTask}
						onSearched={onFiltered}
						setShowModal={setShowFilterEquipment} />}
				{showAdvancedSearch &&
					<AdvancedSearch
						showModal={showAdvancedSearch}
						selectedTask={selectedTask}
						archievedSearches={archievedSearches}
						setShowModal={onHideModal}
						onSearched={onFiltered}
						filterType={typeOfEquipmentList}
						equipmentList={equipmentList}
						uniquePanelId={selectedEquipmentGroup}
						uniqueEquipmentClassId={selectedEquipmentType}
						equipmentColumns={equipmentDatafields} />}
				{showConnectivityModal &&
					<Connectivity
						onHideModal={() => setShowConnectivityModal(false)}
						equipmentGroupId={selectedEquipmentGroup}
						inspectionTaskId={selectedTask?.uniqueInspectionTaskId}
						equipmentClassId={selectedEquipmentType}
						selectedRows={selectedRows}
						onConnectDevices={onConnectDevices}
					/>}
				{showMultipleEditEquipment &&
					<MultipleEditEquipment
						showModal={showMultipleEditEquipment}
						datafields={multipleEditDatafields}
						onFormSubmit={onMultipleEquipmentUpdate}
						onHideModal={() => {
							setShowMultipleEditEquipment(false);
							dispatch(setMultipleEditDatafields(""));
						}}
					/>
				}

				{showReportModal ? (
					<InspectionDetailsReport showModal={showReportModal}
						setShowModal={setShowReportModal}
						reportFlag={reportFlag}
						selectedRows={selectedRows}
						uniqueInspectionTaskId={selectedTask?.uniqueInspectionTaskId}
						uniqueSystemId={selectedTask?.system?.uniqueSystemId}
						selectedEquipmentGroup={selectedEquipmentGroup}
						selectedEquipmentType={selectedEquipmentType}
						filterBody={filteredBody}
						sortField={sortField}
						sortOrder={sortOrder}
					/>
				) : null}

				{showAttachmentListModal ? (
					<AttachmentList
						showModal={showAttachmentListModal}
						setShowModal={setShowAttachmentListModal}
						uniqueInspectionTaskId={selectedTask?.uniqueInspectionTaskId}
						selectedEquipments={selectedRows}
					/>
				) : null}
				{showDisplaySetting && tableColumns?.length &&
					<DisplaySetting
						showModal={showDisplaySetting}
						setShowModal={setShowDisplaySetting}
						displayColumns={backupColumns}
						setdisplayColumns={setTableColumns}
						selectedCols={tableColumns}
					/>
				}

				<InformationBox showInfoModal={displayInfoModal} setShowInfoModal={setDisplayInfoModal} okOnClick={onOkClick} message={message} />
				<ConfirmationBox showConfirmModal={displayConfirmModal} setShowConfirmModal={setDisplayConfirmModal} confirmAction={onConfirmClick} message={message} />
			</>
		</>
	);
};

export default memo(EquipmentList);