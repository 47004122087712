import React from 'react'
import { Button } from 'react-bootstrap'

const SearchNotificationNumberDocument = () => {
  return (
    <>
    <h4 className="doc-title mb-2">Search - Notification Number</h4>
    <p>
    Notification Number Search option allows the user to search 
    Inspection Tasks with Notification Number. User can type 
    minimum three characters of the Notification Number in the text 
    box provided and select the required Notification Number from the list displayed. Click on 
    <span className="doc-subtitle"> Search </span> {" "}
    
    <Button className="doc-button-icon" >
    <i className="pi pi-search icon" title="Search"  />
  </Button>{" "}
    
    icon. The page will be redirected to 
    <span className="doc-subtitle"> Inspection: Inspection Tasks </span> 
    where the selected Inspection Task with selected Notification Number will be listed.
    </p>
    
    </>
  );
}
export default SearchNotificationNumberDocument;
