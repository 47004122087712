
import React from "react";

const InspectionSettingsDocument = () => {
    return (
        <>
            <h4 className="doc-title mb-2">Inspection Settings</h4>

            <p>
                All Settings options related to Inspections such as defining new Attributes to Customer / Contract / Questions,
                managing the Report Setup options such as Report Groups, Report Templates, Report Configs, mapping these Attributes
                to various Reports are grouped under various menu options such as <span className="doc-subtitle">Inspection Fields,
                Reports</span> and <span className="doc-subtitle">Report Mapping</span> in the <span className="doc-subtitle">
                Inspection Settings </span> menu
            </p>
        </>
    );
}

export default InspectionSettingsDocument;