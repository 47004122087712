import React from "react";
import { Button } from "primereact/button";
import { BsFillPencilFill, BsPlusLg, BsTrashFill } from "react-icons/bs";
import { FaCog, FaDownload } from "react-icons/fa";

const UsersDocument = () => {
  return (
    <>
      <h4 className="doc-title mb-2">Users</h4>
      <p>
        Users page list all the Users added to the application. Users of
        different User Roles are listed under the corresponding User Role menu.{" "}
      </p>
      <p>
        Options to view/add/edit/delete Users in different User Roles are
        provided in the Users list.
      </p>
      <p>
        Options to restore the deleted user, Resetting of Password of the
        selected user, Quit Task, Download and Display Settings are also
        provided. Users can be converted from one User Role to another by
        selecting the Convert to other User Role option. This option is
        available for all User Roles other than Super Administrators.
      </p>
      <p>
        Users can search for the required User by entering the Login Name/First
        Name/Last Name in the Search textbox and clicking the{" "}
        <span className="doc-subtitle">Search </span>
        <Button className="doc-button-icon" >
            <i className="pi pi-search icon" title="Search" />
            </Button>{" "}icon. The User list will
        be filtered based on Login Name/ First Name/Last Name entered by the
        Administrator and this filtering will be effective only for the current
        instance of the page.
      </p>
      <p>
        The user roles in Tech Advance<sup>+</sup> are as follows:
      </p>

      <h5 className="doc-title mb-2">Super Administrator</h5>
      <p>
        Users with Super Administrator role will have access to all
        functionalities of all modules without any access restrictions. They
        also have access to field testing tools such as TAP Bridge or TAP App.
        When a Super Administrator logs into Tech Advance<sup>+</sup> or when the Super
        Administrator changes a Site, he/she will be directed to the
        Administration module.
      </p>
      <p>
        Super Administrators are allowed to add/edit/delete Users of other Roles
        namely Support, Admin, Admin-Tech, Technicians and Branch users.
      </p>
      <p>
        When the Super Administrator logs in for the first time, Select Site
        page will be displayed by default. Search for the required Site and
        select the Site. All other options and module will be displayed. Super
        Administrator has to ensure that the required Customer is
        created/selected and displayed against 'Site' at the top of the screen
        before doing any Site specific operations.
      </p>

      <h5 className="doc-title mb-2">Support</h5>
      <p>
        This User Role has almost same features as Super Admin. But they cannot
        perform design work on the application. They also have access to field
        testing tools such as TAP Bridge or TAP App.
      </p>
      <p>
        Users with Support role have the permission to add/edit/delete
        Customers, Admin, Admin-Tech, Technicians, Branch users, Sites,
        Buildings, Floors. Support User will have access to all basic
        functionalities in the other modules.
      </p>
      <p>
        When the Support user logs in for the first time, no Site will be
        selected by default. Support user will be directed to the Inspection
        module and Select Site page will be displayed. The Administrator module,
        Inspection module, As Builts module and Documents module will be
        displayed. Support user has to ensure that the required Customer is
        created/selected and displayed against 'Site' at the top of the screen
        before doing any Site specific operations.
      </p>
      <p>
        On adding Support users, the user has to enter the appropriate Email of
        the new Support user in the Login Name field. The password of the newly
        created Support user will be sent to that Email Id. When a Support user
        logs into Tech Advance<sup>+</sup> or when the Support user changes a Site, he/she
        will be directed to the Inspection module.{" "}
      </p>

      <h5 className="doc-title mb-2">Admin</h5>

      <p>
        These Users are mainly for on boarding the Technicians, Customers,
        Sites, and Contracts to TAP Web. They are able to
        create/review/delete/upload reports. Users with this role should not be
        performing inspections. Also they do not have access to field testing
        tools such as TAP Bridge or TAP App.
      </p>

      <p>
        Users with Admin role have the permission to add/edit/delete
        Customers, Technicians, Branch users, Sites, Buildings, Floors.
      </p>

      <p>
        When the Admin user logs in for the first time, no Site will be
        selected by default. Admin user will be directed to the Inspection
        module and Select Customer page will be displayed. The Administration
        module, Inspection module, As Builts module and Documents module will
        be displayed. Admin user has to ensure that the required Customer is
        created/selected and displayed against 'Site' at the top of the screen
        before doing any Site specific operations.
      </p>

      <p>
        On adding Admin users, the user has to enter the appropriate Email of
        the new Admin user in the Login Name field. The password of the newly
        created Admin user will be sent to that Email Id. When a Admin user
        logs into Tech Advance<sup>+</sup> or when the Admin user changes a Site, he/she
        will be directed to the Inspection module.
      </p>

      <h5 className="doc-title mb-2">Admin-Tech</h5>

      <p>
        This User Role is mainly for branches with limited office support. In
        such cases, they need Users who can perform the role of Admin and
        Tech. Such Users are grouped under Admin-Tech User Role. These Users
        onboard the Technicians, Customers, Sites, and Contracts to TAP Web.
        They are also able to create/review/delete/upload reports, onboard
        Systems, manage Tasks, create and upload Reports. They also have
        access to use TAP Bridge and TAP App to facilitate inspections. They
        have the ability to upload, manage, and facilitate the use of the
        Drawings.
      </p>
      <p>
        Users with Admin-Tech role have the permission to add/edit/delete
        Customers, Technicians, Branch users, Sites, Buildings, Floors.
      </p>
      <p>
        When the Admin-Tech user logs in for the first time, no Site will be
        selected by default. Admin-Tech user will be directed to the
        Inspection module and Select Site page will be displayed. The
        Administrator module, Inspection module, As Builts module and
        Documents module will be displayed. Admin-Tech user has to ensure that
        the required Site is created/selected and displayed against 'Site' at
        the top of the screen before doing any Site specific operations.
      </p>

      <p>
        On adding Admin-Tech users, the user has to enter the appropriate
        Email of the new Admin-Tech user in the Login Name field. The password
        of the newly created Admin-Tech user will be sent to that Email Id.
        When a Admin-Tech user logs into Tech Advance<sup>+</sup> or when the
        Admin-Tech user changes a site, he/she will be directed to the
        Inspection module.
      </p>

      <h5 className="doc-title mb-2">Technician</h5>

      <p>
        Technicians are the Primary users of TAP. They onboard Systems, manage
        Tasks, create and upload Reports. They have the access to use TAP
        Bridge and TAP App to facilitate inspections. They have the ability to
        upload, manage, and facilitate the use of the Drawings. They have
        access to field testing tools such as TAP Bridge or TAP App.
      </p>

      <p>
        Users with Technician role will be able to add Buildings and Floors.
        Technicians will have access to all basic functionalities needed for
        Inspection in Inspection module.
      </p>
      <p>
        When the Technician logs in for the first time, no Site will be
        selected by default. Technician will be directed to the Inspection
        module and Select Site page will be displayed. The Administrator
        module, Inspection module, As Builts module and Documents module will
        be displayed. Technician has to ensure that the required Site is
        selected and displayed against 'Site' at the top of the screen before
        doing any Site specific operations.
      </p>
      <p>
        On adding Technician, the user has to enter the appropriate Email of
        the new Technician in the Login Name field. The password of the newly
        created Technician will be sent to that Email Id. When a technician
        logs into Tech Advance<sup>+</sup> he/she will be directed to the
        Inspection module.
      </p>

      <h5 className="doc-title mb-2">Branch</h5>

      <p>
        Branch Users are Users who review the activities of the users,
        download reports, create Admin reports and manage Drawings.{" "}
      </p>
      <p>
        Users with Branch role will be able to add Buildings/Floors and access
        to all functionalities in As Builts module. In Inspection module
        Branch user have only access to reports like Administrative reports,
        Download Drafted Report and Download Final Report
      </p>
      <p>
        On adding Branch user, the user has to enter the appropriate Email of
        the new Branch user in the Login Name field. The password of the newly
        created Branch user will be sent to that Email Id. When a Branch user
        logs into Tech Advance<sup>+</sup> or when the Branch user changes a
        site, he/she will be directed to the Inspection module.
      </p>

      <p>
        Based on the user role selected, the data grid of the User page will
        be listed accordingly. On selecting the corresponding user role (Super
        Administrator, Support, Admin, Admin-Tech, Technicians and Branch
        user) listed under Users menu, the users with their corresponding
        login name, user role, status, etc. will be listed. The list can be
        sorted by clicking the column headers. Super Administrator, Support,
        Admin, Admin-Tech can add, edit, delete or restore users through this
        option. Technicians and Branch users have no access to this menu.
      </p>

      <p>
        <>
          <b>
            {" "}
            <span className="doc-subtitle">Add: </span>{" "}
          </b>
          A new user can be created by clicking the{" "}
          <span className="doc-subtitle">Add </span>{" "}
          <Button className="doc-button-icon">
            <BsPlusLg className="icon" title="Add" />
          </Button>{" "}
          icon. The Login name, First Name, Middle Name, Last Name, Account
          activation date, Account expiry date and Email have to be entered.
          User Role will be displayed as read only by default.
        </>
      </p>
      <p>
        (The login name for the Super Administrator is the name provided while
        creating the Super Administrator(s). The other user roles like
        Support, Admin, Admin-Tech, Technician and Branch user will have to
        enter the Email Id as their login name and the password will be sent
        to this Email Id. Password has to be changed with respect to the
        applied Password Policy for the first Login attempt.)
      </p>
      <p>
        Users can be created as Super Administrator, Support, Admin, Admin-Tech,
        Technician or Branch users, by selecting the corresponding User Role
        from the Users menu.
      </p>
      
      <p>
        Admin, Admin-Tech, Technicians and Branch users are to be linked to
        their respective Sales offices. One or more Sales Offices can be
        selected for Admin, Admin-Tech, Technicians and Branch users.
      </p>
      <p>
        On adding Admin, Admin-Tech, Technicians and Branch users,'Select
        Sales Office' link will be displayed. On clicking to this link, Update
        Sales Office page will be displayed listing the Sales Office names
        with Save Changes and Cancel buttons. User can select the Sales
        Offices that the Admin, Admin-Tech, Technicians and Branch users is
        associated with, and click Save Changes button to save the changes.
      </p>

      <p>
        <>
          Login Name availability can be checked by entering the Login Name and
          then clicking the{" "}
          <span className="doc-subtitle">Check Availability</span> button next
          to Login Name.{" "}
          <span className="doc-subtitle">Change Password on Next Login</span>{" "}
          option allows the user to change the password set by the
          Administrator, in the Next login of the User. This option will be
          selected by default. Enter the details and click Save buttton.
        </>
      </p>

      <p>
        <b>Note:</b> Only emails with the mail domains defined through{" "}
        <span className="doc-subtitle">
          {" "}
          Manage: Security Settings: Mail Domains
        </span>{" "}
        will be accepted.
      </p>

      <p>
        When adding a user, a random password will be generated and will be
        sent with the details of User Name and Password to the specific user's
        email account. User can login to Tech Advance<sup>+</sup> account with
        the User Name and Password received through Email. When logging in,
        the User will be prompted to Change Password with respect to the
        applied Password Policy for the first Login attempt.
      </p>
      <p>
        <b>Note:</b> For the first Login attempt there will be no Site
        selected by default. If a Site was selected in the previous login then
        that Site will remain as selected for the successive Login attempts
        until it's changed. Therefore users have to ensure that the
        appropriate Site selected after logging into Tech Advance<sup>+</sup>{" "}
        through "Site: 'Change Site' [Change]" option provided on the title
        bar of Tech Advance<sup>+</sup>.
      </p>
      <p>
        <>
          <b>
            {" "}
            <span className="doc-subtitle"> Edit: </span>{" "}
          </b>
          Edit a user by selecting the row and clicking the{" "}
          <span className="doc-subtitle">Edit </span>{" "}

          <Button className="doc-button-icon"> <BsFillPencilFill className="icon" title="Edit" /> </Button> {' '}

          details will be displayed in a new window. On selecting Technician/Admin-tech users, 'Select Sales Office' link will be
          displayed. On selecting this link the Update Sales Office page will be
          displayed listing the Sales Office names with Save Changes and Cancel
          buttons. Administrator can update the User Status. Make the necessary
          changes and click the Save Changes button to save the changes. All
          details except the login name of the user can be edited. While
          editing, the access privileges set earlier can also be set afresh as
          while creating a new user.
        </>
      </p>
      <p>
        The 'Select Sales Office' link can be used to identify and update the
        Sales offices associated to the selected Technician/Admin-tech users.
      </p>

      <b>
        <span className="doc-subtitle">Inline Edit:</span>
      </b>
      <p>
        <>
          Inline edit option enables user to edit a User by clicking the{" "}
          <span className="doc-subtitle">Edit</span>{" "}

          <Button className="doc-button-icon">
            <i className="pi pi-pencil icon" title="Inline Edit" />
          </Button>{" "} icon in the left hand side of the required row. On clicking{" "}
          <span className="doc-subtitle">Edit</span>{" "}

          <Button className="doc-button-icon">
            <i className="pi pi-pencil icon" title="Inline Edit" />
          </Button>{" "}

          selected row will be displayed as editable and Edit icon will be
          replaced with <span className="doc-subtitle">Save</span>{" "}
          <Button className="doc-button-icon">
            <i className="pi pi-save icon" title="Save" />
          </Button>{" "}
          and <span className="doc-subtitle"> Cancel </span>
          <Button className="doc-button-icon">
            <span className="pi pi-fw pi-times icon" title="Cancel"></span>
          </Button>{" "}
          icon. Do the necessary changes in the required fields and click on
          <span className="doc-subtitle"> Save </span>{" "}
          <Button className="doc-button-icon">
            <i className="pi pi-save icon" title="Save" />
          </Button>{" "}
          icon. To cancel the Inline edit operation click on{" "}
          <span className="doc-subtitle">Cancel </span>{" "}
          <Button className="doc-button-icon" title="Cancel">
            <span className="pi pi-fw pi-times icon"></span>
          </Button>{" "}
          icon{" "}
        </>
      </p>
      <p>
        <>
          <b>
            <span className="doc-subtitle">Delete: </span>
          </b>
          Delete a user by selecting the row and clicking the{" "}
          <span className="doc-subtitle">Delete </span>
          <Button className="doc-button-icon">
            <BsTrashFill className="icon" title="Delete" />
          </Button>{" "}
          icon.The selected user will be shown in the list with status Deleted.
          The logged-in user of this session cannot be deleted.
        </>
      </p>

      <b>
        <span className="doc-subtitle">Download </span>
      </b>
      <Button className="doc-button-icon">
        <FaDownload className="icon" title="Download" />
      </Button>
      <p>
        <>
          Following options are available under{" "}
          <span className="doc-subtitle">Download </span>{" "}
          <Button className="doc-button-icon">
            <FaDownload className="icon" title="Download" />
          </Button>{" "} menu.
        </>
      </p>

      <p>
        <span className="doc-subtitle">Report All: </span>
      </p>
      <p>
        This option is for generating a report with all the records in the data
        grid. The report can be saved to PDF, Text, Rich Text or Excel format by
        selecting the file format from the list under Save option, and then
        clicking OK button after selecting the required options from the Export
        Settings window.
      </p>
      <p>
        <span className="doc-subtitle">Report Highlighted: </span>
      </p>
      <p>
        <>
          This option is for generating a report with only selected records in the
          grid. Select the desired rows by holding down the control key and
          clicking on each of the records to be selected. Users can also select a
          set of rows using the shift key. The selected records will be
          highlighted. Click on the{" "}
          <span className="doc-subtitle">Report Highlighted </span> option in the{" "}
          <span className="doc-subtitle">Download </span>{" "}
          <Button className="doc-button-icon">
            {" "}
            <FaDownload className="icon" title="Download" />
          </Button>{" "}
          menu to generate the report. The report will be created with the
          highlighted records only. The report can be saved to PDF, Text, Rich
          Text or Excel format by selecting the file format from the list under
          Save option, and then clicking OK button after selecting the required
          options from the Export Settings window.
        </>
      </p>
      <p>
        <span className="doc-subtitle">Export All: </span>
      </p>
      <p>
        <>
          This option is for exporting all the records from the Data grid to a
          Text or Excel file. Click on the{" "}
          <span className="doc-subtitle">Export All </span> option in the
          <span className="doc-subtitle"> Download </span>{" "}
          <Button className="doc-button-icon">
            <FaDownload className="icon" title="Download" />
          </Button>{" "}
          Select the Save location and the File Format, enter the File Name, and
          click Save button.
        </>
      </p>
      <p>
        <span className="doc-subtitle">Export Highlighted: </span>
      </p>
      <p>
        <>
          This option is for exporting selected records only from the Data grid to
          a Text or Excel file. Select the desired rows by holding down the
          control key and clicking on each of the records to be selected. Users
          can also select a set of rows using the shift key. The selected records
          will be highlighted. Click on the{" "}
          <span className="doc-subtitle">Export Highlighted</span> option in the
          <span className="doc-subtitle"> Download </span>{" "}
          <Button className="doc-button-icon">
            <FaDownload className="icon" title="Download" />
          </Button>{" "}
          Select the Save location and the File Format, enter the File Name, and
          click Save button.
        </>
      </p>
      <p>
        <span className="doc-subtitle">Group Highlighted:</span>
      </p>
      <p>
        <>
          This option is to group together the highlighted or selected rows.
          Select the rows to be grouped by holding down the control key and
          clicking on each of the records to be selected. Then click on the{" "}
          <span className="doc-subtitle">Group Highlighted</span> option in the{" "}
          <span className="doc-subtitle">Download </span>{" "}
          <Button className="doc-button-icon">
            <FaDownload className="icon" title="Download" />
          </Button>{" "}
          highlighted rows will be grouped together, and will be moved to the top
          of the grid.
        </>
      </p>
      <p>
        <>
          <b>
            <span className="doc-subtitle">More </span>
          </b>{" "}
          <Button className="doc-button-icon">
            <FaCog className="icon" title="More" />
          </Button>
        </>
      </p>
      <p>
        For Super Administrators, Support, Admin and Admin-Tech{" "}
        <span className="doc-subtitle">More </span>{" "}
        <Button className="doc-button-icon">
          <FaCog className="icon" title="More" />
        </Button>{" "}
        menu lists Restore, Reset Password, Display Settings, Quit Task, Search
        and Convert to other user options. Branch and Technicians have no access
        to this menu.
      </p>

      <p>
        <span className="doc-subtitle">Restore: </span>
      </p>
      <p>
        <>
          Restore a user by selecting a deleted user and clicking the{" "}
          <span className="doc-subtitle">Restore</span> option from{" "}
          <span className="doc-subtitle">More </span>{" "}
          <Button className="doc-button-icon">
            <FaCog className="icon" title="More" />
          </Button>{" "}menu. The selected user will be shown in the list with status Active.
        </>
      </p>
      <p>
        <span className="doc-subtitle">Reset Password: </span>
      </p>
      <p>
        <>
          This option allows the users to reset their password. Reset the
          password of a user by selecting a user and clicking the{" "}
          <span className="doc-subtitle">Reset Password</span> option from{" "}
          <span className="doc-subtitle">More </span>{" "}

          <Button className="doc-button-icon">
            <FaCog className="icon" title="More" />
          </Button>{" "}

          menu. On selecting Reset Password menu, a random password will be
          generated and will be sent with the details of User Name and Password
          to the specific user's email account. User can login to the Tech
          Advance<sup>+</sup> account with the User Name and Password received
          through Email. When logging in, the User will be prompted to Change
          Password with respect to the applied Password Policy for the first
          Login attempt.
        </>
      </p>
      <p>
        <span className="doc-subtitle">Display Settings: </span>
      </p>
      <p>
        This option allows the user to change the visibility of the data
        fields displayed in the data grid. On selecting this option, all the
        available data fields are displayed in a window. Display settings can
        be modified by selecting the required fields from the list. The grid
        settings modified through this option will not be saved and will be
        effective only for the current instance of the data grid.
      </p>
      <p>
        <span className="doc-subtitle">Search: </span>
      </p>
      <p>
        Through this option, the records can be filtered based on a specified
        criterion. The filtering will be effective only for the current
        instance of the page. By default, all users will be listed.
      </p>
      <p>
        <span className="doc-subtitle">Quit Task </span>
      </p>
      <p>
        This option is used to quit the task of a technician who joined in the
        TAP app. This option is not available for Admin user and Branch users.
      </p>
      <p>
        <span className="doc-subtitle">Convert to other User Role </span>
      </p>
      <p>
        This option is mainly for the converting Users in one User Role to
        another. User Roles in the conversion page will be based on the
        privilege of the logged in User.
      </p>
      <p>
        Super Administrator can do all User Role conversions excluding Super
        Administrator role. Support users can convert User Roles such as
        Admin, Admin-Tech,Tech and Branch. Admin/Admin-Tech users can convert
        only Tech and Branch User Roles. Technicians/Branch users not able to
        do any of the User Role conversions.
      </p>
    </>
  );
};

export default UsersDocument;
