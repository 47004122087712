import { Button } from 'primereact/button'
import React from 'react'
import { BsFillPencilFill, BsPlusLg, BsTrashFill } from 'react-icons/bs'
import { FaCog, FaDownload } from 'react-icons/fa'

const BranchOfficeOrganizationSettingsDocument = () => {
  return (
    <>
      <h4 className="doc-title mb-2">Branch Office</h4>
      
      <p>
        Branch Office manages the inspection process. The Super Administrators can Add/Edit/Delete the Branch Office.
        An inspection is associated with a particular Branch Office. All the active Branch Offices will be displayed
        in this page.
      </p>
     
      <p>
        <span className="doc-subtitle"><b>Add: </b></span>
      </p>
     
      <p>
        Branch Office can be added by clicking <span className="doc-subtitle">Add </span>
        <Button className="doc-button-icon">
          <BsPlusLg className="icon" title="Add"/>
        </Button>{" "} icon. The user can enter the Branch Office details like Address, City, State, Zip, Phone, Fax, Email,
        FA License Number, SPK License Number and EXT License Number details and assign a Sales Office to Branch Office by
        selecting the Sales Office from the dropdown box. Then click Save button to save the newly added Branch Office.
      </p>
      
      <p>
        <span className="doc-subtitle"><b>Edit: </b></span>
      </p>
      
      <p>
        Edit Branch Office details by selecting it and clicking the <span className="doc-subtitle">Edit </span>
        <Button className="doc-button-icon"> <BsFillPencilFill className="icon" title="Edit" /> </Button> {' '}icon. Make required changes
        and click Save Changes button to save the changes.
      </p>
      
      <p>
        <span className="doc-subtitle"><b>Delete: </b></span>
      </p>
      
      <p>
        Delete a Branch Office by selecting it and clicking the <span className="doc-subtitle">Delete </span>
        <Button className="doc-button-icon">
          <BsTrashFill className="icon" title="Delete" />
        </Button>{" "} icon. Branch Office that is active for an Inspection cannot be deleted.
      </p>
      
      <p><b><span className="doc-subtitle">Download</span></b>{" "}
        <Button className="doc-button-icon">
          <FaDownload className="icon" title="Download" />
        </Button>{" "} 
      </p>
      
      <p>
        This option lists Report All, Report Highlighted, Export All, Export Highlighted and Group Highlighted.
      </p>
     
      <p>
        <span className="doc-subtitle">Report All: </span>
      </p>

      <p>
        This option is for generating a report with all the records in the data grid. The report can be saved to PDF, Text,
        Rich Text or Excel format by selecting the file format from the list under Save option, and then clicking OK button after
        selecting the required options from the Export Settings window.
      </p>
      
      <p>
        <span className="doc-subtitle">Report Highlighted: </span>
      </p>
     
      <p>
        This option is for generating a report with only selected records in the grid. Select the desired rows by holding down the
        control key and clicking on each of the records to be selected. Users can also select a set of rows using the shift key.
        The selected records will be highlighted. Click on the <span className="doc-subtitle">Report Highlighted </span> option in
        the <span className="doc-subtitle">Download </span>
        <Button className="doc-button-icon">
          <FaDownload className="icon"
          title="Download" />
        </Button>{" "} menu to generate the report. The report will be created with the highlighted records only. The report can be
        saved to PDF, Text, Rich Text or Excel format by selecting the file format from the list under Save option, and then clicking
        OK button after selecting the required options from the Export Settings window.
      </p>

      <p><b><span className="doc-subtitle">More </span>
        <Button className="doc-button-icon">
          <FaCog className="icon" 
          title="More"/>
        </Button>{" "}</b>
      </p>
      
      <p>
        This option lists only  Display Settings option
      </p>

      <p>
        <span className="doc-subtitle">Display Settings: </span>
      </p>
      
      <p>This option allows the user to change the visibility of the data fields displayed in the data grid. On selecting this option,
        all the available data fields are displayed in a window. Display settings can be modified by selecting the required fields from
        the list. The grid settings modified through this option will not be saved and will be effective only for the current instance of
        the data grid.
      </p>
    </>
  );
}
export default BranchOfficeOrganizationSettingsDocument;
