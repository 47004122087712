import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AppThunk } from "redux/store";
import { baseURL, zlink } from "service/HttpService";

export interface InspectionDrawingState {
    errors: any;
    drawingList: any;
    selectedTreeNode: any;
    selectedTask: any;
    unassignedEquipmentList: any;
    commonEquipmentList: any;
    equipmentSpecificList: any;
    equipmentTypeList: any;
    drawingFullData: any;
    unassignedEquipmentTypes: any;
    addressDetails: any;
    equipmentData: any;
}

const initialState: InspectionDrawingState = {
    errors: "",
    drawingList: "",
    selectedTreeNode: "",
    selectedTask: "",
    unassignedEquipmentList: "",
    commonEquipmentList: "",
    equipmentSpecificList: "",
    equipmentTypeList: "",
    drawingFullData: "",
    unassignedEquipmentTypes: "",
    addressDetails: "",
    equipmentData: "",
};

const InspectionDrawingSlice = createSlice({
    name: "inspectionDrawing",
    initialState,
    reducers: {
        setErrors: (state, { payload }: PayloadAction<any>) => {
            state.errors = payload;
        },
        setDrawingList: (state, { payload }: PayloadAction<any>) => {
            state.drawingList = payload;
        },
        setSelectedTreeNode: (state, { payload }: PayloadAction<any>) => {
			state.selectedTreeNode = payload;
		},
        setSelectedTask: (state, { payload }: PayloadAction<any>) => {
			state.selectedTask = payload;
		},
        setUnassignedEquipmentList: (state, { payload }: PayloadAction<any>) => {
            state.unassignedEquipmentList = payload;
        },
        setCommonEquipmentList: (state, { payload }: PayloadAction<any>) => {
            state.commonEquipmentList = payload;
        },
        setEquipmentSpecificList: (state, { payload }: PayloadAction<any>) => {
            state.equipmentSpecificList = payload;
        },
        setEquipmentTypeList: (state, { payload }: PayloadAction<any>) => {
            state.equipmentTypeList = payload;
        },
        setDrawingFullData: (state, { payload }: PayloadAction<any>) => {
            state.drawingFullData = payload;
        },
        setUnassignedEquipmentTypes: (state, { payload }: PayloadAction<any>) => {
            state.unassignedEquipmentTypes = payload;
        },
        setAddressDetails: (state, { payload }: PayloadAction<any>) => {
            state.addressDetails = payload;
        },
        setEquipmentData: (state, { payload }: PayloadAction<any>) => {
            state.equipmentData = payload;
        },
    },
});

export const getDrawingList = (uniqueInspectionTaskId, setMessage, setDisplayInfoModal): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.get(`${baseURL}api/v1/inspection/inspectiontask/${uniqueInspectionTaskId}/drawings`);
        if (res.status === "done") {
            if (res.data && res.data.siteDetails && res.data.siteDetails.length) {
                dispatch(setDrawingList(res?.data));
            } else {
                setMessage("No Drawings exist");
                setDisplayInfoModal(true);
            }
        } else {
            dispatch(setErrors(res.error));
        }
    } catch (error) {
        dispatch(setErrors(error));
    }
};

export const getDrawingEquipmentList = (uniqueInspectionTaskId,drawingId, tab: string, body, currentStart, setDataLoaded, target?: any, sortField: any = "", sortOrder: any = "", keyword: any = ""): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.post(`${baseURL}api/v1/inspection/inspectiontask/${uniqueInspectionTaskId}/drawings/${drawingId}/equipments?keyword=${keyword}&currentstart=${currentStart}&sortcolumn=${sortField}&sortorder=${sortOrder}&Target=${target}`, body);
            if (res.status === "done") {
                setDataLoaded(true)
                if (tab === "unassigned")
                    dispatch(setUnassignedEquipmentList(res?.data));
                else if (tab === "common")
                    dispatch(setCommonEquipmentList(res?.data));
                else if (tab === "equipmentSpecific")
                    dispatch(setEquipmentSpecificList(res?.data));
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

    export const getEquipmentTypeList = (uniqueInspectionTaskId,drawingId,target=0): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.post(`${baseURL}api/v1/inspection/inspectiontask/${uniqueInspectionTaskId}/drawings/${drawingId}/equipmentspecific?&Target=${target}`, null);
            if (res.status === "done") {
                dispatch(setEquipmentTypeList(res?.data));
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

    export const getDrawingFullData = (uniqueInspectionTaskId, drawingId): AppThunk => async (dispatch) => {
        try {
            const res = await zlink.get(`${baseURL}api/v1/inspection/inspectiontask/${uniqueInspectionTaskId}/drawings/${drawingId}/getfulldata`);
            if (res.status === "done") {
                dispatch(setDrawingFullData(res?.data));
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    }; 

    export const getUnassignedEquipmentTypes = (uniqueInspectionTaskId,drawingId): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.get(`${baseURL}api/v1/inspection/inspectiontask/${uniqueInspectionTaskId}/drawings/${drawingId}/unassignedequipmenttypes`);
            if (res.status === "done") {
                dispatch(setUnassignedEquipmentTypes(res?.data));
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

    export const getAddressDetails = (uniqueInspectionTaskId,drawingId, body: any): AppThunk => async (dispatch) => {
        try {
            const res = await zlink.post(`${baseURL}api/v1/inspection/inspectiontask/${uniqueInspectionTaskId}/drawings/${drawingId}/equipments/unassignedequipments`,body);
            if (res.status === "done") {
                dispatch(setAddressDetails(res?.data?.data));
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

    export const getEquipmentData = (uniqueInspectionTaskId,drawingId, equipmentId, body: any): AppThunk => async (dispatch) => {
        try {
            const res = await zlink.post(`${baseURL}api/v1/inspection/inspectiontask/${uniqueInspectionTaskId}/drawings/${drawingId}/equipments/${equipmentId}`,body);
            if (res.status === "done") {
                dispatch(setEquipmentData(res?.data));
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

export const { setErrors,
    setDrawingList,
    setSelectedTreeNode,
    setSelectedTask,
    setUnassignedEquipmentList,
    setCommonEquipmentList,
    setEquipmentSpecificList,
    setEquipmentTypeList,
    setDrawingFullData,
    setUnassignedEquipmentTypes,
    setAddressDetails,
    setEquipmentData } = InspectionDrawingSlice.actions;
export default InspectionDrawingSlice.reducer;
