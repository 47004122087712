import React, { useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import MultiSelect from "components/common/MultiSelect/MultiSelect";
//redux
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../redux/hooks";
import {
  getMapSiteList,
  getMapSiteSearchList,
  updateCustomerSiteMapping,
  fetchActiveSiteDetails,
  setMapSiteSearchList,
  fetchLoggedInUserDetails,
} from "../../../../redux/pages/Administration/administrationSlice";
import { setAsActiveSiteForSite } from "redux/pages/Administration/Site/siteSlice";
import InformationBox from "components/common/Message/InformationBox/InformationBox";
//routing
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "constants/AppRoutes";

interface Props {
  showModal: boolean;
  setShowModal: (state: boolean) => void;
  selectedRows: any;
}

export const SiteModal: React.FC<Props> = ({
  showModal = false,
  setShowModal = () => {},
  selectedRows = [],
}) => {
  const siteList = useAppSelector((state) => state.administration.mapSiteList);
  const siteSearchList = useAppSelector(
    (state) => state.administration.mapSiteSearchList
  );
  const activeSite = useAppSelector(
    (state) => state.administration.activeSiteDetails
  );
  const switchedSite = useAppSelector(
    (state) => state.administration.switchedSite
  );

  const [siteData, setSiteData] = useState<any>([]);
  const [showButton, setShowButton] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState<any>("");
  const [displayInfoModal, setDisplayInfoModal] = useState(false);
  const [message, setMessage] = useState("");
  const [isNavigate, setIsNavigate] = useState(false);

  const [customerName, setCustomerName] = useState<any>();
  const [uniqueCustomerId, setUniqueCustomerId] = useState<any>();
  const selectedSite = useRef<any>();
  const checkedRows = useRef<any>();
  const disabledRows = useRef<any>();

  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  
  useEffect(()=>{
    dispatch(fetchActiveSiteDetails());
  },[dispatch])

  useEffect(() => {
    if (selectedRows.length > 0) {
      dispatch(setMapSiteSearchList([]));
      dispatch(getMapSiteList(selectedRows[0].uniqueCustomerId));
      setCustomerName(
        selectedRows[0].customerName +
          "(" +
          selectedRows[0].customerNumber +
          ")"
      );
      setUniqueCustomerId(selectedRows[0].uniqueCustomerId);
      if (selectedRows[0].status === "Active") 
        setShowButton(true);
      else 
        setShowButton(false);
    }
  }, [dispatch, selectedRows]);

  useEffect(() => {
    if (siteList?.length > 0 && showModal === true) {
      let siteArray: any = [];
      siteList.map((item: any) => {
        return siteArray.push({
          id: item.uniqueSiteId,
          siteName: item.siteName,
          checked: item.isSelected,
          selected: false,
          disabled: item.isDisabled,
        });
      });
      setSiteData(siteArray);
    }
  }, [siteList, selectedRows, showModal]);

  useEffect(() => {
    if (showModal) {
      var siteNewList: any = [];
      siteList.map((item: any) => {
        return siteNewList.push({
          id: item.uniqueSiteId,
          siteName: item.siteName,
          checked: item.isSelected,
          selected: false,
          disabled: item.isDisabled,
        });
      });
      var siteNewSearchList: any = [];
      siteSearchList.map((item: any) => {
        if (
          !siteNewList.find((element: any) => element.id === item.uniqueSiteId)
        )
          return siteNewSearchList.push({
            id: item.uniqueSiteId,
            siteName: item.siteName,
            checked: item.isSelected,
            selected: false,
            disabled: item.isDisabled,
          });
      });

      setSiteData([...siteNewSearchList, ...siteNewList]);
    }
  }, [siteSearchList, siteList]);

  useEffect(() => {
    if (isNavigate) {
      dispatch(fetchLoggedInUserDetails());
      navigate(`/${APP_ROUTES.INSPECTION_TASKS}`);
      setIsNavigate(false);
      setShowModal(false);
    }
  }, [switchedSite, navigate, isNavigate]);

  const mapSiteSearchList = () => {
    if (searchKeyword.length === 0) {
      var siteNewList: any = [];
      siteList.map((item: any) => {
        return siteNewList.push({
          id: item.uniqueSiteId,
          siteName: item.siteName,
          checked: item.isSelected,
          selected: false,
          disabled: item.isDisabled,
        });
      });
      setSiteData(siteNewList);
    } else {
      dispatch(getMapSiteSearchList(uniqueCustomerId, searchKeyword));
    }
    setSearchKeyword("");
  };

  const searchSiteByEnterKey = (e: any) => {
    if (e.keyCode === 13) {
      mapSiteSearchList();
    }
  };

  const updateSelectedSites = () => {
    let siteIds: any = [];
    let allCheckedSites = [...checkedRows.current, ...disabledRows.current];
    allCheckedSites.map((item: any) => {
      siteIds.push(item.id);
      return null;
    });
    dispatch(
      updateCustomerSiteMapping(
        uniqueCustomerId,
        siteIds,
        setDisplayInfoModal,
        setMessage
      )
    );
  };

  const changeToActiveSite = () => {

    
    if (selectedSite.current.length === 0) {
      setShowModal(true);
      setMessage("No Site selected");
      setDisplayInfoModal(true);
    } else if (selectedSite.current.length === 1) {
      if (activeSite.uniqueSiteId === selectedSite.current[0].id) {
        setShowModal(true);
        setMessage("You are currently working in the selected Site");
        setDisplayInfoModal(true);
      } else {
        dispatch(setAsActiveSiteForSite(selectedSite?.current[0]?.id, setDisplayInfoModal, setMessage, setIsNavigate));
      }
    }
  };

  return (
    <div>
      <Dialog
        header="Map Sites"
        visible={showModal}
        style={{ width: "50vw", maxHeight: "100%" }}
        onHide={() => {
          setSiteData([]);
          setShowModal(false);
        }}
        className="site-modal"
      >
        <div className="content-box">
          <div className="header d-flex align-items-center">
            <span className="title">Customer:</span>
            <span className="value">{customerName}</span>
          </div>
          <div className="sub-header">
            Note: New Site(s) required for Customer - Site Mapping can be found
            through Search option
          </div>
          <div className="search-bar text-center">
            <div className="p-input-icon-right inputgroup">
              <i className="pi pi-search" onClick={mapSiteSearchList} />
              <InputText
                value={searchKeyword}
                onChange={(event) => setSearchKeyword(event?.target.value)}
                onKeyDown={searchSiteByEnterKey}
                placeholder="Search Site Number / Name"
              />
            </div>
          </div>
          <MultiSelect
            singleSelect
            list={siteData}
            getCheckedItems={(checkedItems) => {
              checkedRows.current = checkedItems;
            }}
            getSelectedItems={(selectedItems) => {
              selectedSite.current = selectedItems;
            }}
            getDisabledItems={(disabledItems) => {
              disabledRows.current = disabledItems;
            }}
          />
          <div className="footer text-left">
            <Button
              label="Cancel"
              onClick={() => {
                setShowModal(false);
                dispatch(getMapSiteList(selectedRows[0].uniqueCustomerId));
              }}
              className="cancel-btn"
            />
            {showButton && (
              <Button
                label="Set as Active Site"
                onClick={changeToActiveSite}
                className="cancel-btn"
              />
            )}
            {showButton && (
              <Button
                label="Save Changes"
                onClick={updateSelectedSites}
                className="submit-btn"
              />
            )}
          </div>
        </div>
      </Dialog>
      <InformationBox
        showInfoModal={displayInfoModal}
        setShowInfoModal={setDisplayInfoModal}
        message={message}
      />
    </div>
  );
};

export default SiteModal;
