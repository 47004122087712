import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';

const textEditor = (options) => {
    return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
};
const CountryEditor = (options) => {
    return (
        <Dropdown value={options.value} options={Countrylist} optionLabel="label" optionValue="value"
            onChange={(e) => options.editorCallback(e.value)} placeholder="Select Country"
            itemTemplate={(option) => {
                return <span className='role-badge'>{option.label}</span>
            }} />
    );
}
const SalesOfficeEditor = (options) => {
    return (
        <Dropdown value={options.value} options={SalesOfficelist} optionLabel="label" optionValue="value"
            onChange={(e) => options.editorCallback(e.value)} placeholder="Select SalesOffice"
            itemTemplate={(option) => {
                return <span className='role-badge'>{option.label}</span>
            }} />
    );
}
const Countrylist = [
    { label: 'Ontario', value: 'Ontario' },
    { label: 'British Columbia', value: 'British Columbia' },
    { label: 'Manitoba', value: 'Manitoba' },
    { label: 'New Brunswick', value: 'New Brunswick' },
    { label: 'Nova Scotia', value: 'Nova Scotia' },
    { label: 'Prince Edward Island', value: 'Prince Edward Island' }
];
const SalesOfficelist = [
    { label: 'Ontario', value: 'Ontario' },
    { label: 'British Columbia', value: 'British Columbia' },
    { label: 'Manitoba', value: 'Manitoba' },
    { label: 'New Brunswick', value: 'New Brunswick' },
    { label: 'Nova Scotia', value: 'Nova Scotia' },
    { label: 'Prince Edward Island', value: 'Prince Edward Island' }
];
export const ORG_COLS = {
    ZONE_COLS: [
        {
            field: 'name',
            header: 'Name',
            editor: (options) => textEditor(options),
            style: { flex: '1 0 200px' },
            sortable: true,
        },
        {
            field: 'country',
            header: 'Country',
            editor: (options) => CountryEditor(options),
            style: { flex: '1 0 200px' },
            sortable: true,
        }
    ],
    SALES_COLS: [
        {
            field: 'name',
            header: 'Name',
            editor: (options) => textEditor(options),
            style: { flex: '1 0 200px' },
            sortable: true,
        },
        {
            field: 'code',
            header: 'Code',
            editor: (options) => textEditor(options),
            style: { flex: '1 0 200px' },
            sortable: true,
        },
        {
            field: 'zone',
            header: 'Zone',
            editor: (options) => textEditor(options),
            style: { flex: '1 0 200px' },
            sortable: true,
        },
        {
            field: 'country',
            header: 'Country',
            editor: (options) => CountryEditor(options),
            style: { flex: '1 0 200px' },
            sortable: true,
        }
    ],
    BRANCH_COLS: [
        {
            field: 'name',
            header: 'Name',
            editor: (options) => textEditor(options),
            style: { flex: '1 0 200px' },
            sortable: true,
        },
        {
            field: 'address',
            header: 'Address',
            editor: (options) => textEditor(options),
            style: { flex: '1 0 200px' },
            sortable: true,
        },
        {
            field: 'cityStateZip',
            header: 'City, State, Zip',
            editor: (options) => textEditor(options),
            style: { flex: '1 0 200px' },
            sortable: true,
        },
        {
            field: 'phone',
            header: 'Phone',
            editor: (options) => textEditor(options),
            style: { flex: '1 0 200px' },
            sortable: true,
        },
        {
            field: 'email',
            header: 'Email',
            editor: (options) => textEditor(options),
            style: { flex: '1 0 200px' },
            sortable: true,
        },
        {
            field: 'fax',
            header: 'Fax',
            editor: (options) => textEditor(options),
            style: { flex: '1 0 200px' },
            sortable: true,
        },
        {
            field: 'faLicenceNumber',
            header: 'FA Licence Number',
            editor: (options) => textEditor(options),
            style: { flex: '1 0 200px' },
            sortable: true,
        },
        {
            field: 'spkLicenceNumber',
            header: 'SPK Licence Number',
            editor: (options) => textEditor(options),
            style: { flex: '1 0 200px' },
            sortable: true,
        },
        {
            field: 'extLicenceNumber',
            header: 'EXT Licence Number',
            editor: (options) => textEditor(options),
            style: { flex: '1 0 200px' },
            sortable: true,
        },
        {
            field: 'salesOffice',
            header: 'Sales Office',
            editor: (options) => SalesOfficeEditor(options),
            style: { flex: '1 0 200px' },
            sortable: true,
        }
    ]
};

export const ORG_ROWS = {
    ZONE_ROWS: [
        {
            id: 1,
            name: 'Atlantic',
            country: 'United States',
        },
        {
            id: 2,
            name: 'Enterprise',
            country: 'United States',
        },
        {
            id: 3,
            name: 'Hong Kong',
            country: 'Canada',
        },
        {
            id: 4,
            name: 'India',
            country: 'United States',
        },
        {
            id: 5,
            name: 'North West',
            country: 'United States',
        },
        {
            id: 6,
            name: 'South',
            country: 'Canada',
        },
        {
            id: 7,
            name: 'A/Anti-Freeze',
            country: 'Canada',
        },
        {
            id: 8,
            name: 'Atlantic',
            country: 'United States',
        },
        {
            id: 9,
            name: 'Atlantic',
            country: 'United States',
        },
        {
            id: 10,
            name: 'A/Anti-Freeze',
            country: 'United States',
        },
        {
            id: 11,
            name: 'A/Anti-Freeze',
            country: 'United States',
        },
        {
            id: 12,
            name: 'A/Anti-Freeze',
            country: 'United States',
        },
        {
            id: 13,
            name: 'Atlantic',
            country: 'United States',
        },
        {
            id: 14,
            name: 'Enterprise',
            country: 'Canada',
        },
        {
            id: 15,
            name: 'Atlantic',
            country: 'United States',
        },
        {
            id: 16,
            name: 'Atlantic',
            country: 'United States',
        },
        {
            id: 17,
            name: 'India',
            country: 'Canada',
        },
        {
            id: 18,
            name: 'Hong Kong',
            country: 'United States',
        },
        {
            id: 19,
            name: 'Atlantic',
            country: 'Canada',
        },
        {
            id: 20,
            name: 'Hong Kong',
            country: 'Canada',
        }
    ],
    SALES_ROWS: [
        {
            id: 1,
            name: 'BT University',
            code: 'BNY3',
            zone: 'Northeast',
            country: 'United States',
        },
        {
            id: 2,
            name: 'BT University',
            code: 'BNY3',
            zone: 'Northeast',
            country: 'United States',
        },
        {
            id: 3,
            name: 'BT University',
            code: 'BNY3',
            zone: 'Northeast',
            country: 'United States',
        },
        {
            id: 4,
            name: 'BT University',
            code: 'BNY3',
            zone: 'Northeast',
            country: 'United States',
        },
        {
            id: 5,
            name: 'BT University',
            code: 'BNY3',
            zone: 'Northeast',
            country: 'United States',
        },
        {
            id: 6,
            name: 'BT University',
            code: 'BNY3',
            zone: 'Northeast',
            country: 'United States',
        },
        {
            id: 7,
            name: 'BT University',
            code: 'BNY3',
            zone: 'Northeast',
            country: 'United States',
        },
        {
            id: 8,
            name: 'BT University',
            code: 'BNY3',
            zone: 'Northeast',
            country: 'United States',
        },
        {
            id: 9,
            name: 'BT University',
            code: 'BNY3',
            zone: 'Northeast',
            country: 'United States',
        },
        {
            id: 10,
            name: 'BT University',
            code: 'BNY3',
            zone: 'Northeast',
            country: 'United States',
        },
        {
            id: 11,
            name: 'BT University',
            code: 'BNY3',
            zone: 'Northeast',
            country: 'United States',
        },
        {
            id: 12,
            name: 'BT University',
            code: 'BNY3',
            zone: 'Northeast',
            country: 'United States',
        },
        {
            id: 13,
            name: 'BT University',
            code: 'BNY3',
            zone: 'Northeast',
            country: 'United States',
        },
        {
            id: 14,
            name: 'BT University',
            code: 'BNY3',
            zone: 'Northeast',
            country: 'United States',
        },
        {
            id: 15,
            name: 'BT University',
            code: 'BNY3',
            zone: 'Northeast',
            country: 'United States',
        },
        {
            id: 16,
            name: 'BT University',
            code: 'BNY3',
            zone: 'Northeast',
            country: 'United States',
        },
        {
            id: 17,
            name: 'BT University',
            code: 'BNY3',
            zone: 'Northeast',
            country: 'United States',
        },
        {
            id: 18,
            name: 'BT University',
            code: 'BNY3',
            zone: 'Northeast',
            country: 'United States',
        },
        {
            id: 19,
            name: 'BT University',
            code: 'BNY3',
            zone: 'Northeast',
            country: 'United States',
        },
        {
            id: 20,
            name: 'BT University',
            code: 'BNY3',
            zone: 'Northeast',
            country: 'United States',
        }
    ],
    BRANCH_ROWS: [
        {
            id: 1,
            name: 'BT University',
            address: 'Fernwood Rd',
            cityStateZip: 'Austin, TX, 7265',
            phone: '615-832-123',
            email: 'nominal@zlinkcorp.com',
            fax: '888-33333-4443',
            faLicenseNo: 'TN345',
            spkLicenseNo: 'TN345'
        },
        {
            id: 2,
            name: 'BT University',
            address: 'Fernwood Rd',
            cityStateZip: 'Austin, TX, 7265',
            phone: '615-832-123',
            email: 'nominal@zlinkcorp.com',
            fax: '888-33333-4443',
            faLicenseNo: 'TN345',
            spkLicenseNo: 'TN345'
        },
        {
            id: 3,
            name: 'BT University',
            address: 'Fernwood Rd',
            cityStateZip: 'Austin, TX, 7265',
            phone: '615-832-123',
            email: 'nominal@zlinkcorp.com',
            fax: '888-33333-4443',
            faLicenseNo: 'TN345',
            spkLicenseNo: 'TN345'
        },
        {
            id: 4,
            name: 'BT University',
            address: 'Fernwood Rd',
            cityStateZip: 'Austin, TX, 7265',
            phone: '615-832-123',
            email: 'nominal@zlinkcorp.com',
            fax: '888-33333-4443',
            faLicenseNo: 'TN345',
            spkLicenseNo: 'TN345'
        },
        {
            id: 5,
            name: 'BT University',
            address: 'Fernwood Rd',
            cityStateZip: 'Austin, TX, 7265',
            phone: '615-832-123',
            email: 'nominal@zlinkcorp.com',
            fax: '888-33333-4443',
            faLicenseNo: 'TN345',
            spkLicenseNo: 'TN345'
        },
        {
            id: 6,
            name: 'BT University',
            address: 'Fernwood Rd',
            cityStateZip: 'Austin, TX, 7265',
            phone: '615-832-123',
            email: 'nominal@zlinkcorp.com',
            fax: '888-33333-4443',
            faLicenseNo: 'TN345',
            spkLicenseNo: 'TN345'
        },
        {
            id: 7,
            name: 'BT University',
            address: 'Fernwood Rd',
            cityStateZip: 'Austin, TX, 7265',
            phone: '615-832-123',
            email: 'nominal@zlinkcorp.com',
            fax: '888-33333-4443',
            faLicenseNo: 'TN345',
            spkLicenseNo: 'TN345'
        },
        {
            id: 8,
            name: 'BT University',
            address: 'Fernwood Rd',
            cityStateZip: 'Austin, TX, 7265',
            phone: '615-832-123',
            email: 'nominal@zlinkcorp.com',
            fax: '888-33333-4443',
            faLicenseNo: 'TN345',
            spkLicenseNo: 'TN345'
        },
        {
            id: 9,
            name: 'BT University',
            address: 'Fernwood Rd',
            cityStateZip: 'Austin, TX, 7265',
            phone: '615-832-123',
            email: 'nominal@zlinkcorp.com',
            fax: '888-33333-4443',
            faLicenseNo: 'TN345',
            spkLicenseNo: 'TN345'
        },
        {
            id: 10,
            name: 'BT University',
            address: 'Fernwood Rd',
            cityStateZip: 'Austin, TX, 7265',
            phone: '615-832-123',
            email: 'nominal@zlinkcorp.com',
            fax: '888-33333-4443',
            faLicenseNo: 'TN345',
            spkLicenseNo: 'TN345'
        },
        {
            id: 11,
            name: 'BT University',
            address: 'Fernwood Rd',
            cityStateZip: 'Austin, TX, 7265',
            phone: '615-832-123',
            email: 'nominal@zlinkcorp.com',
            fax: '888-33333-4443',
            faLicenseNo: 'TN345',
            spkLicenseNo: 'TN345'
        },
        {
            id: 12,
            name: 'BT University',
            address: 'Fernwood Rd',
            cityStateZip: 'Austin, TX, 7265',
            phone: '615-832-123',
            email: 'nominal@zlinkcorp.com',
            fax: '888-33333-4443',
            faLicenseNo: 'TN345',
            spkLicenseNo: 'TN345'
        },
        {
            id: 13,
            name: 'BT University',
            address: 'Fernwood Rd',
            cityStateZip: 'Austin, TX, 7265',
            phone: '615-832-123',
            email: 'nominal@zlinkcorp.com',
            fax: '888-33333-4443',
            faLicenseNo: 'TN345',
            spkLicenseNo: 'TN345'
        },
        {
            id: 14,
            name: 'BT University',
            address: 'Fernwood Rd',
            cityStateZip: 'Austin, TX, 7265',
            phone: '615-832-123',
            email: 'nominal@zlinkcorp.com',
            fax: '888-33333-4443',
            faLicenseNo: 'TN345',
            spkLicenseNo: 'TN345'
        },
        {
            id: 15,
            name: 'BT University',
            address: 'Fernwood Rd',
            cityStateZip: 'Austin, TX, 7265',
            phone: '615-832-123',
            email: 'nominal@zlinkcorp.com',
            fax: '888-33333-4443',
            faLicenseNo: 'TN345',
            spkLicenseNo: 'TN345'
        },
        {
            id: 16,
            name: 'BT University',
            address: 'Fernwood Rd',
            cityStateZip: 'Austin, TX, 7265',
            phone: '615-832-123',
            email: 'nominal@zlinkcorp.com',
            fax: '888-33333-4443',
            faLicenseNo: 'TN345',
            spkLicenseNo: 'TN345'
        },
        {
            id: 17,
            name: 'BT University',
            address: 'Fernwood Rd',
            cityStateZip: 'Austin, TX, 7265',
            phone: '615-832-123',
            email: 'nominal@zlinkcorp.com',
            fax: '888-33333-4443',
            faLicenseNo: 'TN345',
            spkLicenseNo: 'TN345'
        },
        {
            id: 18,
            name: 'BT University',
            address: 'Fernwood Rd',
            cityStateZip: 'Austin, TX, 7265',
            phone: '615-832-123',
            email: 'nominal@zlinkcorp.com',
            fax: '888-33333-4443',
            faLicenseNo: 'TN345',
            spkLicenseNo: 'TN345'
        },
        {
            id: 19,
            name: 'BT University',
            address: 'Fernwood Rd',
            cityStateZip: 'Austin, TX, 7265',
            phone: '615-832-123',
            email: 'nominal@zlinkcorp.com',
            fax: '888-33333-4443',
            faLicenseNo: 'TN345',
            spkLicenseNo: 'TN345'
        },
        {
            id: 20,
            name: 'BT University',
            address: 'Fernwood Rd',
            cityStateZip: 'Austin, TX, 7265',
            phone: '615-832-123',
            email: 'nominal@zlinkcorp.com',
            fax: '888-33333-4443',
            faLicenseNo: 'TN345',
            spkLicenseNo: 'TN345'
        }
    ]
}