import React, { memo, useEffect, useState } from 'react'
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';


interface UserModalType {
    showModal: boolean;
    onHide: (selectedUsers) => void;
    users?: any;
    selectedUsers?: any;
}


const UserModal: React.FC<UserModalType> = (props) => {
    const { showModal, onHide = () => { }, users, selectedUsers } = props;
    const [selection, setSelection] = useState(null)


    useEffect(() => {
        if (selectedUsers) {
            setSelection(selectedUsers)
        }
    }, [selectedUsers])


    return (
        <Dialog
            showHeader={false}
            visible={showModal}
            onHide={() => onHide(selection)}
            className="logbook-activity"
        >
            <div className="custom-header">
                <div className="row">
                    <div className="col-6 box py-1 pr-0 pl-1">
                        <div className="title">Users</div>
                    </div>

                    <div className="col-6 box py-1 pl-0 align-items-end pr-1">
                        <i className="pi pi-times" onClick={() => onHide(selection)}></i>
                    </div>
                </div>
            </div>
            <div className='custom-table'>
                <DataTable
                    value={users}
                    selection={selection}
                    onSelectionChange={e => setSelection(e.value)}
                    dataKey="userId"
                    responsiveLayout="scroll">
                    <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>
                    <Column field="userName" header="User Name"></Column>
                    <Column field="userRole" header="User Role"></Column>
                    <Column field="status" header="Status"></Column>
                </DataTable>
            </div>
        </Dialog>
    )
}

export default memo(UserModal);