import { Button } from 'primereact/button';
import React from 'react'
import { BsPaperclip, BsTrashFill } from 'react-icons/bs';
import { FaDownload } from 'react-icons/fa';

const InspectionReportsReportsDocument = () => {
  return (
    <>
      <h4 className="doc-title mb-2">
        Reports
      </h4>


    <p>
      This option is for listing the Reports generated through 
      <span className="doc-subtitle"> Generate Reports</span> option.
    </p>

    <p>
      On selecting this option, a page with the System drop down will be displayed with the 
      last selected System name loaded by default. On selecting a System from the corresponding drop 
      down, <span className="doc-subtitle">View Draft Report, View Final Report, 
      Download Draft Report </span> and <span className="doc-subtitle">Download Final Report </span> 
      buttons and with <span className="doc-subtitle">Delete</span>{" "}
      <Button className="doc-button-icon"> 
        <BsTrashFill className="icon" title='Delete'/> 
      </Button>{" "} and 
      <span className="doc-subtitle"> Attachments (Upload Final Report) </span> {" "}
      <Button className="doc-button-icon">
        <BsPaperclip className="icon" title="Upload Final Report"/>
      </Button> {" "} icons and 
      <span className="doc-subtitle"> Download </span>{" "}
      <Button className="doc-button-icon">
        <FaDownload className="icon" title='Download'/>
      </Button>{" "} menu. The page will display details like 'Template', 'Report by', 'Group(s)', 'Customer', 'Contract', 'Building Name(s)', 'Date & Time Created', 'Created by', 
      'Date & Time Uploaded', 'Added by', 'Send to Service Portal'.
    </p>

    <p>
      <span className="doc-subtitle"><b> View Draft Report:</b></span>
    </p>
    <p>
      This option is to view the Draft Report of the selected Report. To view the Draft Report, 
      select <span className="doc-subtitle">View Draft Report </span> 
      button after selecting the required report. The Drafted Report will be displayed without 
      downloading them. 
    </p>

    <p>
      <span className="doc-subtitle"><b> View Final Report:</b></span>
    </p>
    <p>
      This option is to view the Final Report of the selected Report.To view the Final Report, 
      select <span className="doc-subtitle">View Final Report </span> 
      option after selecting the required report. The Final Report will be displayed without 
      downloading them.
    </p>

    <p>
      <span className="doc-subtitle"><b>Download Draft Report: </b></span>
    </p>
    <p>
      This option is to view the Draft Report of the selected Report. On clicking 
      <span className="doc-subtitle"> Download Draft Report</span> button, 
      the Drafted Reports generated for the selected System will be downloaded to the user&#39;s computer. 
    </p>

    <p>
      <span className="doc-subtitle"><b>Download Final Report: </b></span>
    </p>
    <p>
      This option is to view the Final Report of the selected Report. To download the final report, 
      select <span className="doc-subtitle">Download Final Report </span> 
      option after selecting the required report. The Final Report uploaded for the selected report 
      will be downloaded to the user&#39;s computer.  
    </p>


    <p>
      Multiple Drafted / Final Reports can be downloaded as ZIP file
    </p>

    <p>
      <span className="doc-subtitle"><b>Delete: </b></span>
    </p>
    <p> 
      User can delete the selected report using 
      <span className="doc-subtitle"> Delete</span>{" "}
      <Button className="doc-button-icon"> 
        <BsTrashFill className="icon" title='Delete'/> 
      </Button>{" "} icon.
    </p>
    <p>
      <span className="doc-subtitle"><b>Upload Final Report:</b></span>
    </p>
    <p>
      User can upload the Final Reports through <span className="doc-subtitle">Attachments</span>{" "}
      <Button className="doc-button-icon">
        <BsPaperclip className="icon" title="Upload Final Report"/>
      </Button> {" "} icon. On selecting <span className="doc-subtitle">Upload Final Report</span> {" "}
      <Button className="doc-button-icon">
        <BsPaperclip className="icon" title="Upload Final Report"/>
      </Button> {" "} icon, Upload Final Report window will be displayed with the option to browse 
      for the required file. Select the appropriate file and click Upload button.  
    </p>

    </>
  );
}
export default InspectionReportsReportsDocument;
