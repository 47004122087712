import React, { useEffect, useRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';

import { BsExclamationCircleFill } from 'react-icons/bs';

import AddModal from 'components/Table/AddModal/AddModal';
import { useDispatch } from 'react-redux';
import { getAllReportGroups } from 'redux/pages/Manage/InspectionSettings/Reports/ReportsSlice';
import { useAppSelector } from 'redux/hooks';

interface Props {
    showModal: boolean,
    setShowModal: (state: boolean) => void,
    selectedCountry: string,
    selectedCountryId: any,
    onAddReportClick: (data: any) => void,
}

type FormData = {
    countryId: string,
    reportName: string,
    reportTitle: string,
    reportGroupId: string
};

export const AddReports: React.FC<Props> = ({
    showModal = false,
    setShowModal = () => { },
    selectedCountry = '',
    selectedCountryId,
    onAddReportClick = () => { },
}) => {

   

    const addUserForm: any = useRef();

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<FormData>({
        defaultValues: {
            countryId: selectedCountryId,
            reportName: '',
            reportTitle: '',
            reportGroupId: ''
        }
    });

    const reportGroupsData = useAppSelector((state) => state.report.reportGroupsData);

    const [reportGroupLookup, setReportGroupLookup] = useState([{}]);

    const dispatch = useDispatch<any>();

    useEffect(() => {
        dispatch(getAllReportGroups())
    }, [dispatch])

    useEffect(() => {
        if (reportGroupsData) {
            let reportGroup: any = [];
            reportGroupsData.map((item: any) => {
                return reportGroup.push({ name: item.reportGroupName, code: item.reportGroupId })
            })
            setReportGroupLookup(reportGroup)
        }
    }, [reportGroupsData])

    const formSubmitHandler = (data: any) => {
        addUserForm.current.requestSubmit();
    };

    const getFormErrorMessage = (name) => {
        return errors[name] && <span className="tooltip-text"><BsExclamationCircleFill />{errors[name].message}</span>
    };

    const onAddFormSubmit = (data: any) => {
        data.reportGroupId = data.reportGroupId.code
        onAddReportClick && onAddReportClick(data);
    };

    const onError = (errors: any, e: any) => { };

    return (
        <>
            <AddModal
                header='New Report'
                showModal={showModal}
                isAdd={true}
                setShowModal={setShowModal}
                style={{ width: '35vw', height: '55vh' }}
                OnSaveClick={formSubmitHandler}
            >
                <form onSubmit={handleSubmit(onAddFormSubmit, onError)} ref={addUserForm}>
                  

                   

                    <div className="row px-2">
                        <div className="field col-12 md:col-2 mb-0">
                            <span className="p-float-label text-container">
                                <label htmlFor="inputtext">Country</label>
                                <Controller
                                    name="countryId"
                                    control={control}
                                    render={() => (
                                        <span className='text-value'>{selectedCountry}</span>
                                    )} />
                            </span>
                        </div>
                    </div>

                  

                    <div className="row px-2 pt-2">
                        <div className="field col-12 md:col-2 mb-0">
                            <span className="p-float-label">
                                <Controller
                                    name="reportName"
                                    control={control}
                                    rules={{
                                        required: 'Report Name is required.'
                                    }}

                                    render={({ field, fieldState }) => (
                                        <InputText
                                            id="reportName"
                                            maxLength={100}
                                            className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })}
                                            {...field}
                                        />
                                    )} />
                                <label className='mandatory' htmlFor="inputtext">Report Name</label>
                                {getFormErrorMessage('reportName')}
                            </span>
                        </div>
                    </div>

               
                  

                   
                    <div className="row px-2 py-2">
                        <div className="field col-12 md:col-2 mb-0 pb-0">
                            <span className="p-float-label">
                                <Controller
                                    name="reportTitle"
                                    control={control}

                                    render={({ field, fieldState }) => (
                                        <InputText
                                            id="reportTitle"
                                            maxLength={150}
                                            className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })}
                                            {...field}
                                        />
                                    )} />
                                <label htmlFor="inputtext">Report Title</label>
                                {getFormErrorMessage('reportTitle')}
                            </span>
                        </div>
                   
                    </div>

              

                    <div className="row px-2 pt-2 pb-2">
                        <div className="field col-12 md:col-2 pb-0 mb-0">
                            <span className="p-float-label">
                                <Controller
                                    name="reportGroupId"
                                    control={control}
                                    rules={{
                                        required: 'Report Group is required.'
                                    }}
                                    render={({ field, fieldState }) => (
                                        <Dropdown
                                            inputId="reportGroup"
                                            className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })}
                                            {...field}
                                            value={field.value}
                                            onChange={(e) => field.onChange(e.value)}
                                            options={reportGroupLookup}
                                            optionLabel="name"
                                        />
                                    )} />
                                <label className='mandatory' htmlFor="dropdown">Report Group</label>
                                {getFormErrorMessage('reportGroupId')}
                            </span>
                        </div>
                        
                    </div>
                </form>
            </AddModal>
        </>
    );
}

export default AddReports;