import React, { useState, useCallback, useEffect, useRef } from "react";
import { Button } from "primereact/button";

import { useNavigate } from "react-router-dom";

import { BsCloud,  BsChatLeftText, BsPencil, BsTrashFill } from "react-icons/bs";
import {  FaSlash, FaBackward, FaDownload, FaChevronDown } from "react-icons/fa";
import { BiText } from "react-icons/bi";
import { FiEdit, FiSave } from "react-icons/fi";
import { GiSave } from "react-icons/gi"
import { HiOutlinePencilAlt } from "react-icons/hi";
import { APP_ROUTES } from "constants/AppRoutes";
import {  BiRectangle, BiMove, BiLayer } from "react-icons/bi";

import ConfirmationBox from "components/common/Message/ConfirmationBox/ConfirmationBox";
import InformationBox from "components/common/Message/InformationBox/InformationBox";

import Drawing from 'pages/Common/Drawing';
import Comments from "./Comments";
import DrawingMarkupList from "./DrawingMarkupList";
import Export from 'pages/Common/DrawingExport';
import SaveDrawing from 'pages/Common/SaveDrawing';

import { useDispatch } from "react-redux";
import { useAppSelector } from "redux/hooks";
import { insertDrawingmarkup, setAddMarkup,loadXmldata,setXmlData,getDrawingList } from "redux/pages/AsBuilts/AsBuiltsSlice";
import DrawingToolbar from "components/common/Toolbar/DrawingToolbar";
import { IoEllipseOutline } from "react-icons/io5";
import { ColorPicker } from 'primereact/colorpicker';

import  {SaveMarkup} from '../MarkupSave'
import { truncate } from "fs";

interface Props {
selectedDrawing:any;
bIsResizeDrawing:boolean;
selectedBuilding: any;
selectedSite: any;
selectedMarkupRow?: any;
closeDrawingView?:()=>void;
showMarkupTools?:boolean;
selectedRevisionRow?: any;
currentStart?:any;
}

const AsBuiltsDrawing: React.FC<Props> = (props) => {
   const addMarkup=useAppSelector((state) => state.asbuilts.addMarkup);
    const xmlData=useAppSelector((state) => state.asbuilts.xmlData);
    
   const [displayConfirmModal, setDisplayConfirmModal] = useState(false);
    const [displayInfoModal, setDisplayInfoModal] = useState(false);
    const [message, setMessage] = useState("");
   const [toolData, setToolData] = useState<any>([]);
    const [textColor, setTextColor] = useState<any>("FF0000");
   

    const [showComments, setShowComments] = useState<boolean>(false);
    const [showDrawingMarkupList, setShowDrawingMarkupList] = useState<boolean>(false);
     const [markupLayersArray,setMarkupLayersArray] = useState<any>([]);
    const [visibleMarkupIds,setVisibleMarkupIds] = useState<any>([]);
    const [showDrawingExport, setShowDrawingExport] = useState(false);
    const [showDrawingSave, setShowDrawingSave] = useState(false);
   
   
    const [textPosition,setTextPosition]=useState<any>([]);    
    const [DrawingObj, setDrawingObj] = useState<any>([])
    const [isDrawingOpen,setisDrawingOpen]=useState(false);
    const [isTextEdit,setIsTextEdit]=useState(false);
    const [isCalloutComment,setIsCalloutComment]=useState(false);
    const [editTextData, setEditTextData] = useState<any>([]);
    const [editCommentEntityHandle,setEditCommentEntityHandle] = useState<any>("");
    const [markupColor1,setMarkupColor]=useState("FF0000");
    const [deleteMarkup, setDeleteMarkup] = useState("")
    const [showMarkupSave,setShowMarkupSave]=useState(false);
    const [selectedMarkup,setSelectedMarkup]=useState<any>([]);
    const [markupData,setMarkupData]=useState<any>([]);
    const [markupLayer,setMarkupLayer]= useState<any>("");
    const [layerNames_Markup,setLayerNames_Markup]= useState<any>("");
    const [pageTitle,setPageTitle]= useState<any>("");
    const [cadPlanId,setCadPlanId]= useState<any>("");
    const [markupLineThickness,setMarkupLineThickness]= useState<any>(1);
    const [revisionNo,setRevision]= useState<any>(0);
    const [drawingId,setDrawingId]= useState<any>(0);

    const toolBarRef = useRef<any>(null);

    const columnDelimiter = "µ";
	const rowDelimiter = "§";
    
    const navigate = useNavigate();

  
    let tools = [
        {
          name: "Rectangle",
          icons: <BiRectangle className="icon" />,
        },
        {
          name: "Ellipse",
          icons: <IoEllipseOutline className="icon" />,
        },
        {
          name: "Line",
          icons: <FaSlash className="icon" />,
        },
        {
          name: "Cloud",
          icons: <BsCloud className="icon" />,
        },
        {
          name: "Free Hand",
          icons: <BsPencil className="icon" />,
        },
      ];

    const dispatch = useDispatch<any>();
    useEffect(() => {
    setToolData(tools);
    }, [])
    useEffect(() => {
        if(isDrawingOpen){
      var layerName=CreateLayerName();
      setMarkupLayer(layerName);  
        }
    }, [markupLayer,isDrawingOpen])
    useEffect(() => {
        if(props.selectedRevisionRow &&props.selectedRevisionRow.length>0){
       setCadPlanId(props.selectedRevisionRow[0].uniqueDrawingId);  
       setRevision(props.selectedRevisionRow[0].revisions)
       setDrawingId(props.selectedRevisionRow[0].drawingId);
        }
    }, [props.selectedRevisionRow])
    useEffect(() => {
        if(props.selectedDrawing && !props.selectedRevisionRow){
       setCadPlanId(props.selectedDrawing.uniqueDrawingId);  
       setRevision(props.selectedDrawing.revisions); 
       setDrawingId(props.selectedDrawing.drawingId); 
        }
    }, [props.selectedDrawing])
    
    useEffect(() => {
        if(isDrawingOpen && props.selectedMarkupRow&& props.selectedMarkupRow?.length>0 && props.selectedMarkupRow[0].markupId){
     let body = {
            FileName:"", 
            RevisionNo:props.selectedDrawing.revisions,
            IsDWGBased: true,
            XmlString:""
    
        }
        dispatch(loadXmldata(props.selectedBuilding?.id,props.selectedDrawing?.drawingId,props.selectedMarkupRow[0]?.markupId,body));
    }
    }, [isDrawingOpen])

    useEffect(() => {
        
        if (xmlData && xmlData[0].xmlString && xmlData[0].markupId && props.selectedMarkupRow?.length>0) {
        let xmlStringModified: string = "";
        let markupLayerArray:any=[];
        xmlStringModified=xmlData[0].xmlString.replace(/0x5c/g, "\\");
                         
        if (xmlStringModified != "File not found") {
              let layerName = xmlStringModified.substring(xmlStringModified.search("<LAYERNAME>") + 11, xmlStringModified.search("</LAYERNAME>"));
              markupLayerArray.push({ markupId: xmlData[0].markupId, layerName: layerName });
              DrawingObj.loadXMLString(xmlStringModified, function (retCode) {
                  if (retCode != 0) {
                      console.log("loadXMLString failed due to ", retCode)
                  }
                });
            }
            else{
            setMessage("File not found")
            setDisplayInfoModal(true);
            }
      
      }
      dispatch(setXmlData(""))
    }, [xmlData])
    const CreateLayerName=()=>
    {

        let dateTime = new Date();
        let date = dateTime.getDate() + "-" + dateTime.getMonth() + "-" + dateTime.getFullYear();

        let hours = dateTime.getHours() > 12 ? dateTime.getHours() - 12 : dateTime.getHours().toString();
        let am_pm = dateTime.getHours() >= 12 ? "PM" : "AM";
        hours = hours < 10 ? "0" + hours : hours;
        let minutes = dateTime.getMinutes() < 10 ? "0" + dateTime.getMinutes() : dateTime.getMinutes();
        let seconds = dateTime.getSeconds() < 10 ? "0" + dateTime.getSeconds() : dateTime.getSeconds();
        let time = hours + "-" + minutes + "-" + seconds + "_" + am_pm;

        let Layername =  "$" +  date + "_" + time;

        if(layerNames_Markup.indexOf(Layername) == -1)
        {
            setLayerNames_Markup(layerNames_Markup + Layername + rowDelimiter)
        }


         return Layername;
    }
    


    useEffect(() => {
        if (addMarkup) {
          let newData = JSON.parse(JSON.stringify(addMarkup));
         if (newData) {
          if(newData.markupId>0){
          setMessage("Markup added");
		  setDisplayInfoModal(true);
            DrawingObj.deleteLayer(markupLayer, function (retCode: any) {
            DrawingObj.regenerate();
        });
          setMarkupLayer("");
          setLayerNames_Markup("");
          }
          if(visibleMarkupIds.length>0){
            hideMarkupLayer(0);
          }
          }
          dispatch(setAddMarkup(""))
        }
      }, [addMarkup])

      const hideMarkupLayer =  (arrayCount) =>{
        if(visibleMarkupIds.length>arrayCount){
        let findIndex=markupLayersArray.findIndex((item: any) => item.markupId === visibleMarkupIds[arrayCount]);
        if(findIndex>=0){
            DrawingObj.setLayerVisibility(markupLayersArray[findIndex].layerName, false, function (retCode) {
              if(retCode==0){}
          });
      }
      arrayCount++;
      hideMarkupLayer(arrayCount)
      }
      else
      setSelectedMarkup([]);
      setVisibleMarkupIds([]);
         
    }

      useEffect(() => {
        if (selectedMarkup.length>0) {
            if(visibleMarkupIds.length>0){
                let markupIds=visibleMarkupIds;
                let length=visibleMarkupIds.length;
                for(let i=0;i<length;i++){
                let index=selectedMarkup.findIndex((item: any) => item.markupId === markupIds[i]);
          if(index==-1){
            
            let findIndex=markupLayersArray.findIndex((item: any) => item.markupId === markupIds[i]);
            visibleMarkupIds.splice(i,1)
          if(findIndex>=0){
          DrawingObj.setLayerVisibility(markupLayersArray[findIndex].layerName, false, function (retCode) {
            if(retCode==0){}
        });
    }
          }
        }
        markupIds=visibleMarkupIds;
        setVisibleMarkupIds(markupIds);
            }
            
           
            let body = {
                FileName:"", 
                RevisionNo:props.selectedDrawing.revisions,
                IsDWGBased: true,
                XmlString:""
        
            }
            loadXmlDataString(selectedMarkup, 0,body, function (retCode) {
            });
        }
        else if(visibleMarkupIds.length>0){
             let markupIds=visibleMarkupIds;
                for(let i=0;i<markupIds.length;i++){
          
            let findIndex=markupLayersArray.findIndex((item: any) => item.markupId === markupIds[i]);
          if(findIndex>=0){
          DrawingObj.setLayerVisibility(markupLayersArray[findIndex].layerName, false, function (retCode) {
            if(retCode==0){}
        });
          }
          }
          setVisibleMarkupIds([]);
               
        }
    }, [selectedMarkup,visibleMarkupIds])

            const loadXmlDataString =  (selectedMarkup, arrayCount,body, resCallback) =>{

                if (selectedMarkup.length > arrayCount) {
                   
                    let findIndex=markupLayersArray.findIndex((item: any) => item.markupId === selectedMarkup[arrayCount].markupId);
                if(findIndex==-1)  {  
             dispatch(loadXmldata(props.selectedBuilding?.id,props.selectedDrawing?.drawingId,selectedMarkup[arrayCount].markupId,body))
                 
            }
             else{
                if(selectedMarkup[arrayCount].rgb!=""){
                    visibleMarkupIds.push(selectedMarkup[arrayCount].markupId);
                    let clrId=parseInt(selectedMarkup[arrayCount].colorId);
                    let rgbColor =selectedMarkup[arrayCount].rgb.replace('#','')
                    let isColorChange=selectedMarkup[arrayCount].colorChange
                    hexToRGB(rgbColor, function(r, g, b){
                        DrawingObj.rgbToColorIndex(r, g, b, function(retCode, colorIndex){
                            if(colorIndex!=clrId && isColorChange ){
                                DrawingObj.setDisplay(false);
                                DrawingObj.setLayerVisibility(markupLayersArray[findIndex].layerName, true, function (retCode) {
                                DrawingObj.setEntitiesColor(markupLayersArray[findIndex].layerName, colorIndex, function (retCode) {
                                DrawingObj.setDisplay(true);
                                });
                            });
                            }
                            else{
                                DrawingObj.setLayerVisibility(markupLayersArray[findIndex].layerName, true, function (retCode) {
                                    DrawingObj.setDisplay(true);
                                })
                            }
                        
                    });
                   });
                }
                else{
                    DrawingObj.setLayerVisibility(markupLayersArray[findIndex].layerName, true, function (retCode) {
        
                });
            }
             }
             arrayCount++;
             loadXmlDataString(selectedMarkup, arrayCount,body, resCallback);
                }
          };
       

      useEffect(() => {
        if (xmlData && xmlData[0].xmlString && xmlData[0].markupId && selectedMarkup.length>0) {
           viewMarkup(xmlData[0].markupId,xmlData[0].xmlString, function (retCode) {
          });
          }
          dispatch(setXmlData(""))
        }, [xmlData])

        const viewMarkup =  (showMarkupId,xmlString, resCallback) =>{
            let visibleMarkupIdsArray=visibleMarkupIds;
            let markupLayerArray=markupLayersArray;
               var xmlStringModified: string = "";
                 var selectedMarkupId = showMarkupId;
                
                  xmlStringModified=xmlString.replace(/0x5c/g, "\\"); 
                             
                       if (xmlStringModified != "File not found") {
                        visibleMarkupIdsArray.push(selectedMarkupId);
                        const markupDataInfo= selectedMarkup.find((item: any) => item?.markupId === selectedMarkupId);
                             let layerName = xmlStringModified.substring(xmlStringModified.search("<LAYERNAME>") + 11, xmlStringModified.search("</LAYERNAME>"));
                             let markup_ColorIndex = xmlStringModified.substring(xmlStringModified.lastIndexOf("<COLOR>") + 7, xmlStringModified.lastIndexOf("</COLOR>"));
                
                             markupLayerArray.push({ markupId: selectedMarkupId, layerName: layerName });
                             DrawingObj.setDisplay(false);
                             DrawingObj.loadXMLString(xmlStringModified, function (retCode) {
                                 if (retCode != 0) {
                                     console.log("loadXMLString failed due to ", retCode)
                                     DrawingObj.setDisplay(truncate);
                            
                                }
                              else{
                               if(markupDataInfo["rgb"]!=""){

                                var rgbColor =markupDataInfo["rgb"].replace('#','')
                                hexToRGB(rgbColor, function(r, g, b){
                                    DrawingObj.rgbToColorIndex(r, g, b, function(retCode, colorIndex){
                                        if(colorIndex!=parseInt(markup_ColorIndex) && markupDataInfo["colorChange"] ){

                                        DrawingObj.setEntitiesColor(layerName, colorIndex, function (retCode) {
                                            DrawingObj.setDisplay(true);
                                            });
                   
                                        }
                                        else{
                                            DrawingObj.setDisplay(true); 
                                        }
                                    });
                                });
                               }
                                else
                                DrawingObj.setDisplay(true);
                              }  
                             });
                             setMarkupLayersArray(markupLayerArray);
                             setVisibleMarkupIds(visibleMarkupIdsArray);
                            }
                        
                
                      resCallback(0)
             }
   


     
  
             const openHandler = (objiWhizApi) => {
                setDrawingObj(objiWhizApi);
                setisDrawingOpen(true);
                setMarkupLayersArray([]);
                setVisibleMarkupIds([]);
            };
    

    const onCancel=()=>{
        setDisplayConfirmModal(false);
        setDeleteMarkup("");
    }

    const onConfirmAction = () => {
        setDisplayConfirmModal(false);
        if(deleteMarkup)
        {
            DrawingObj.deleteEntity(deleteMarkup, function () {
            });
        }
     }

 
   
     const markupListSubmit = (markupDatas: any) => {
        setSelectedMarkup(markupDatas)
        setShowDrawingMarkupList(false);
        
    }

    const onMapEvent = (event: any, name: any) => {
        if(isDrawingOpen){
        switch (name) {
            case "Rectangle":
                createRectangleMarkup();
            break;
            case "Ellipse":
                createEllipseMarkup();
            break;
            
            case "Cloud":
                createCloudMarkup();
            break;
            case "Line":
                var points = "",
				tempx = 0,
				tempy = 0,
				pointcount = 0;
			    createPolylineMarkup(points, tempx, tempy, pointcount);
                break;
            case "Free Hand":
                createFreeHandOnClick();
                break;
            case "Line Thickness":
                setMarkupLineThickness(event);
                break;
          default:
            break;
        }
    }
      }
      const createRectangleMarkup = () => {
		var currentStatus = [0];
        DrawingObj.isWaitMode(currentStatus);
        if (currentStatus[0]) {
            DrawingObj.exitWaitMode();

        }
		var ratio = [0];
		DrawingObj.getCurrentClientDWGRatio(ratio);        
        hexToRGB(markupColor1, function(r, g, b){
            DrawingObj.rgbToColorIndex(r, g, b, function(retCode, colorIndex){
                DrawingObj.drawRectangle(markupLayer, colorIndex, markupLineThickness * ratio[0], "", 1, function (returnCode: any, EntityHandle: any) {
			
                });
            });
        });  
	};
    const  hexToRGB = (hex, resCallback) => {
        hex = '0x' + hex
        let r = hex >> 16 & 0xFF
        let g = hex >> 8 & 0xFF
        let b = hex & 0xFF
        return resCallback(r, g, b);
      };
    const createEllipseMarkup = () => {
		var currentStatus = [0];
      DrawingObj.isWaitMode(currentStatus);
      if (currentStatus[0]) {
        DrawingObj.exitWaitMode();

      }
      DrawingObj.setCursor(2);
		var ratio = [0];
		DrawingObj.getCurrentClientDWGRatio(ratio);
        hexToRGB(markupColor1, function(r, g, b){
            DrawingObj.rgbToColorIndex(r, g, b, function(retCode, colorIndex){
                DrawingObj.getRectangle(function (returnCode: any, leftTop: any, bottomRight: any) {
                    DrawingObj.setCursor(1);
                    if (returnCode == 0) {
                        var CenterX, CenterY, endX, endY;
                        CenterX = (leftTop.x + bottomRight.x) / 2;
                        CenterY = (leftTop.y + bottomRight.y) / 2;
                        if (Math.abs(bottomRight.x - leftTop.x) > Math.abs(leftTop.y - bottomRight.y)) {
                            endX = bottomRight.x;
                            endY = CenterY;
                        } else {
                            endX = CenterX;
                            endY = bottomRight.y;
                        }
                        DrawingObj.drawEllipseMarkup(
                            markupLayer,
                            colorIndex,
                            CenterX,
                            CenterY,
                            endX,
                            endY,
                            markupLineThickness * ratio[0],
                            "",
                            1,
                            false,
                            function (retCode: any, entityHandle: any) {
                            }
                        );
                    }
                });
            });
        });  		
	};
    const createCloudMarkup = () => {
		var currentStatus = [0];
      DrawingObj.isWaitMode(currentStatus);
      if (currentStatus[0]) {
        DrawingObj.exitWaitMode();

      }
		var ratio = [0];
		DrawingObj.getCurrentClientDWGRatio(ratio);
        hexToRGB(markupColor1, function(r, g, b){
            DrawingObj.rgbToColorIndex(r, g, b, function(retCode, colorIndex){
                DrawingObj.getRectangle(function (returnCode: any, leftTop: any, bottomRight: any) {
                    DrawingObj.setCursor(1);
                    if (returnCode == 0) {
                        var cloudPoints =leftTop.x +columnDelimiter +leftTop.y +rowDelimiter +bottomRight.x +columnDelimiter +leftTop.y +rowDelimiter +bottomRight.x +columnDelimiter +bottomRight.y +rowDelimiter +leftTop.x +columnDelimiter +bottomRight.y +rowDelimiter;
                            DrawingObj.drawCloudMarkup(markupLayer,colorIndex,20 * ratio[0],cloudPoints,markupLineThickness * ratio[0],"",1,true,
                            function (retCode: any, entityHandle: any) {
                            }
                        );
                    }
                });
            });
        });  		
	};
    const typeToolOnClick = () => {
        setPageTitle("New Comment")
        setIsCalloutComment(false); 
        setEditTextData([]);
        setIsTextEdit(false); 
        createTextMarkup();
    }
    const createCalloutComment = () => {
        setPageTitle("New Callout Comment")
        setIsCalloutComment(true);
        setEditTextData([]);
        setIsTextEdit(false);
        createTextMarkup();

    }
    const editCalloutComment = () => {
        setPageTitle("Edit Callout Comment")
        setIsCalloutComment(true);
        editTextOnClick(true);

    }
    const editTextComment = () => {
        setPageTitle("Edit Comment")
        setIsCalloutComment(false);
        editTextOnClick(false);

    }
    const createTextMarkup = () => {
        setEditTextData([]);
        setIsTextEdit(false);
        var currentStatus = [0];
        DrawingObj.isWaitMode(currentStatus);
        if (currentStatus[0]) {
          DrawingObj.exitWaitMode();
  
        }
        DrawingObj.setCursor(2);
		DrawingObj.getDWGPoint(function (retCode: any, x: any, y: any) {
			if (retCode == 0) {
                setTextPosition({X:x,Y:y});
			var clientX = [x],
					clientY = [y],
					clientZ = [0];
                    DrawingObj.dwgToClient(clientX, clientY, clientZ);
			setShowComments(true)
            
        }
		});
	};
    
    const CloseDrawing = () => {  
       if(DrawingObj && isDrawingOpen){
            DrawingObj.close(function () {  
                setisDrawingOpen(false);
                 props.closeDrawingView &&  props.closeDrawingView();
             });
             dispatch(getDrawingList(props.selectedSite?.uniqueSiteId,props.selectedBuilding?.id, props.currentStart, "", "", "", setMessage, setDisplayInfoModal));
        }
       else
       {
        props.closeDrawingView &&  props.closeDrawingView();
       }
    };
    const DrawingExportClick=()=>{
        setShowDrawingSave(false);
        setShowDrawingExport(true);
      }
      const DrawingSaveClick=()=>{
        setShowDrawingSave(true);
        setShowDrawingExport(false);
      }
    const editTextOnClick = (isCallout) => {
        setDisplayInfoModal(false);
        var currentStatus = [0];
        setIsTextEdit(true);
        DrawingObj.isWaitMode(currentStatus);
        if (currentStatus[0]) {
          DrawingObj.exitWaitMode();
        }
        if(isCallout){
           hasCalloutText((isText:any)=> {
                if (isText) {
                    editCallOut((result:any) =>{
                    setIsCalloutComment(false);
                    });  
                } 
                else{
                    setMessage("No Comments with Leader Line (Callouts) exist");
                setDisplayInfoModal(true);
                setIsCalloutComment(false);
                }       
        });
    }
        else{
		hasTextMarkup((hasText: any)=> {
			if (hasText) {
				DrawingObj.setCursor(12);
				DrawingObj.getDWGPoint(function (returnCode: any, x: any, y: any) {
					DrawingObj.getEntityWithDWGPoint(markupLayer, 6, x, y, 12, function (retCode: any, entityHandle: any) {
						DrawingObj.setCursor(1);
						if (retCode == 0) {
							var handle = entityHandle.substring(0, entityHandle.length - 1);
							DrawingObj.getContentText(handle, function (res: any, textContent: any) {
								if (res == 0) {
									DrawingObj.getTextProperties(
										handle,
										function (res: any, isBold: any, isItalic: any, isUnderLine: any, textAngle: any, fontName: any) {
											if (res == 0) {
												DrawingObj.getTextSize(handle, function (res: any, textSize: any) {
													if (res == 0) {
														setEditCommentEntityHandle(handle);
														var ratio = [0];
														DrawingObj.clientDWGAreaRatio(ratio);
														textSize = Math.round(textSize / ratio[0]);
                                                        
                                                        DrawingObj.isTextHighlighted(handle, function (result: any, isExist: any) {
                                                            if (result == 0) {
                                                        setEditTextData([{"bold":isBold,"italics":isItalic,"font":fontName,"fontSize":textSize,"comment":textContent,"highlight":isExist}]);
                                                        setShowComments(true);
                                                            }
                                                            else console.log("isTextHighlighted returned with error code : ", result);
                                                        });
                                                    } else console.log("getTextSize returned with error code : ", res);
												});
											} else console.log("getTextProperties returned with error code : ", res);
										}
									);
								} else console.log("getContentText returned with error code : ", res);
							});
						} else {
							console.log("getEntityWithDWGPoint returned with error code : ", retCode);
						}
					});
				});
			} else {
				setMessage("No Comments Exist")
                setDisplayInfoModal(true);
			}
		});
    }
	}
    const hasTextMarkup = (resCallback: any) => {
		var handles = [];
		DrawingObj.getAllEntities(markupLayer, function (res: any, entityhandles: any) {
			if (res == 0) {
				if (entityhandles != "") {
					handles = entityhandles.split(rowDelimiter);
					handles.pop();
					hasMulitilineText(handles, resCallback);
				} else resCallback(false);
			} else {
				console.log("getAllEntities faild due to ", res);
				resCallback(false);
			}
		});
	};
    const hasCalloutText = (resCallback: any) => {
        var handles = [];
        var hasTxt: boolean = false;
        DrawingObj.getAllEntities(markupLayer, function (res: any, entityhandles: any) {
            if (res == 0) {
                if (entityhandles != "") {
                    handles = entityhandles.split(rowDelimiter);
					handles.pop();
					 hasCallout(handles, hasTxt, function (isText) {
                        resCallback(isText);
                    });
                }
                else
                    resCallback(hasTxt);
            }
            else {
                console.log("getAllEntities faild due to ", res);
                resCallback(hasTxt);
            }
        });
    }
    const hasCallout= (entityhandles: any,hasTxt:any, resCallback: any) => {
        if (entityhandles.length > 0) {
            let textEntityhandle = entityhandles[entityhandles.length - 1];
            DrawingObj.getEntityType(textEntityhandle, function (res: any, typeid: any) {
				if (res == 0) {
					entityhandles.pop();
					 if (typeid == 12) {
                        hasTxt = true;
                        resCallback(hasTxt);
                    }
                    else {
                        hasCallout(entityhandles, hasTxt, resCallback);
                    }

                }
                else
                    alert("getEntityType faild due to " + res);
            });
        }
        else
        resCallback(hasTxt);
    }
    const hasMulitilineText = (entityhandles: any, resCallback: any) => {
		if (entityhandles.length > 0) {
			let textEntityhandle = entityhandles[entityhandles.length - 1];
			DrawingObj.getEntityType(textEntityhandle, function (res: any, typeid: any) {
				if (res == 0) {
					entityhandles.pop();
					if (typeid == 6) {
						resCallback(true);
					} else {
						hasMulitilineText(entityhandles, resCallback);
					}
				} else alert("getEntityType faild due to " + res);
			});
		} else resCallback(false);
	};
    const editCallOut = (resCallback)=> {

        DrawingObj.setCursor(12);
        DrawingObj.getDWGPoint(function (returnCode: any, x: any, y: any) {
            DrawingObj.getEntityWithDWGPoint(markupLayer, 12, x, y, 12, function (retCode: any, entityHandle: any) {
                DrawingObj.setCursor(1);
                if (retCode == 0) {
                    var handle = entityHandle.substring(0, entityHandle.length - 1);
							DrawingObj.getContentText(handle, function (res: any, textContent: any) {
								if (res == 0) {
									DrawingObj.getTextProperties(
										handle,
										function (res: any, isBold: any, isItalic: any, isUnderLine: any, textAngle: any, fontName: any) {
                                            if (res == 0) {
												DrawingObj.getTextSize(handle, function (res: any, textSize: any) {
													if (res == 0) {
														setEditCommentEntityHandle(handle);
														var ratio = [0];
														DrawingObj.clientDWGAreaRatio(ratio);
														textSize = Math.round(textSize / ratio[0]);
                                                        setEditTextData([{"bold":isBold,"italics":isItalic,"font":fontName,"fontSize":textSize,"comment":textContent,"highlight":false}]);
                                                        setShowComments(true);
                                                    }
                                        else
                                            console.log("getTextSize returned with error code : ", res);
                                    });
                                }
                                else
                                    console.log("getTextProperties returned with error code : ", res);
                            });

                        }
                        else
                            console.log("getContentText returned with error code : ", res);
                    });

                }
                else {
                    console.log("getEntityWithDWGPoint returned with error code : ", retCode);
                    DrawingObj.enableRubberband(0, 0, 0, false);
                }
            });
        });
    }
    const saveMarkupComment= (comment: any) => {
        let body = {
            FileName:markupLayer, 
            Description:comment,
            RevisionNo:props.selectedDrawing?.revisions,
            IsDWGBased: true,
            XmlString:markupData[0].exportedxmlstring
    
        }
        dispatch(insertDrawingmarkup(body, props.selectedSite?.uniqueSiteId, props.selectedBuilding?.id,props.selectedDrawing?.drawingId ,setMessage, setDisplayInfoModal))
                   
        setShowMarkupSave(false);
    }
    
    
	const addTextData = (data: any) => {
    	
        var boldText = data.bold ? "b1" : "b0";
        var italicText = data.italics ? "i1" : "i0";
		var formatedText: string;
        var fontStyle=data.font;
        var text=data.comments;
        var fontSize=data.fontSize
		var ratio = [0];
        var hightlightColor=0;
        if(data.font=="Caliberi")
        fontStyle="Calibri";
        if(data.highlight)
        hightlightColor=2;
		DrawingObj.clientDWGAreaRatio(ratio);
		if (isTextEdit == false) {
			formatedText = "{\\f" + fontStyle + "|" + boldText + "|" + italicText + "|c0|p18;" + text + "}";
			hexToRGB(textColor, function(r, g, b){
                DrawingObj.rgbToColorIndex(r, g, b, function(retCode, colorIndex){
                    DrawingObj.createMultilineText(
                        markupLayer,
                        colorIndex,
                        textPosition.X,
                        textPosition.Y,
                        0,
                        ratio[0] * fontSize,
                        0,
                        1,
                        formatedText,
                        "Standard",
                        0,
                        function (retCode: any, entityHandle: any) {
                            if (retCode != 0) {
                                console.log("createMultilineText returned with error code : ", retCode);
                            }
                            else{
                                if(!isCalloutComment){
                                DrawingObj.setTransparentBackground(entityHandle,hightlightColor,150, function (retCode) {   //Setting highlight
                                        if (retCode != 0) {
                                            console.log("setTransparentBackground returned with error code : " , retCode);
                                        }
                                     });
                                }
                            }
                            setShowComments(false);
                
                            if(isCalloutComment){
                                setMessage("Click to specify origin of the Callout");
                                setDisplayInfoModal(true);
                                var rubx: any = [], ruby: any = [], rubz: any = []
        
                                rubx[0] = textPosition.X; ruby[0] = textPosition.Y; rubz[0] = 0;
                                DrawingObj.dwgToClient(rubx, ruby, rubz);
                                DrawingObj.enableRubberband(rubx[0], ruby[0], 0, true);
                                DrawingObj.getDWGPoint(function (rtCode: any, endX: any, endY: any) {
                                  if (rtCode == 0) {
                                    DrawingObj.enableRubberband(rubx[0], ruby[0], 0, false);
                                      var arrowSize = 5 * ratio[0];
                                            var doglegLength = 2.0 * ratio[0];
                                            var landingGap = 1.0 * ratio[0];
                                            hexToRGB(textColor, function(r, g, b){
                                                DrawingObj.rgbToColorIndex(r, g, b, function(retCode, colorIndex){
                                                  
                                            DrawingObj.createMultiLeader(markupLayer, colorIndex, textPosition.X, textPosition.Y, endX, endY, arrowSize, doglegLength,
                                                landingGap, entityHandle, function (retCode, entityHandle) {
                                                });
                                            });
                                            });
                                        }
                                        else {
                                            DrawingObj.deleteEntity(entityHandle, function () {
                                           });
                                        }
                                    });
                                
                                setIsCalloutComment(false);
                            }
                        }
                    );
                });
            });             
		} else {
			if (editCommentEntityHandle != "") {
                hexToRGB(textColor, function(r, g, b){
                    DrawingObj.rgbToColorIndex(r, g, b, function(retCode, colorIndex){
                      
				formatedText =
					"{\\f" + fontStyle + "|" + boldText + "|" + italicText + "|c0|p18;\\C" + colorIndex + "\\H" + ratio[0] * fontSize + ";" + text + "}";
                    DrawingObj.setText(editCommentEntityHandle, formatedText, function (retCode: any) {
					if (retCode != 0) {
						console.log("setText returned with error code : ", retCode);
                    }
                    if(!isCalloutComment){
                        DrawingObj.setTransparentBackground(editCommentEntityHandle,hightlightColor,150, function (retCode) {   //Setting highlight
                                if (retCode != 0) {
                                    console.log("setTransparentBackground returned with error code : " , retCode);
                                }
                             });
                        }
                        setEditCommentEntityHandle("");
                        setIsTextEdit(false);
                        setIsCalloutComment(false);
                });
            });
				});
                setShowComments(false);
        
			 }
            }
            setIsTextEdit(false)
            setIsCalloutComment(false);
        };

        const saveMarkUps = (resCallback) =>{
            var exportedxmlstring = "";
            var layerNamesArr = layerNames_Markup.split(rowDelimiter);
            layerNamesArr.pop();
            var tempArr = [];
            saveMarkUpHandler(layerNamesArr, tempArr, function(exportedxmlstringArr){
                resCallback(exportedxmlstringArr);
            });
           
        }
        const saveMarkUpHandler = (layerNamesArr, exportedxmlstringArr, resCallback)=>
        {
            if(layerNamesArr.length > 0)
            {
                let getFromFile=true;
                DrawingObj.exportToXML(true,getFromFile, layerNamesArr[layerNamesArr.length-1], function (returnCode, exportedxmlstring) {
                    
                    exportedxmlstringArr.push({LayerName: layerNamesArr[layerNamesArr.length-1], XmlString: exportedxmlstring});
                    layerNamesArr.pop();
                    saveMarkUpHandler(layerNamesArr, exportedxmlstringArr, resCallback);
                });
            }
            else
            {
                resCallback(exportedxmlstringArr);
            }
        }

        const onSaveMarkUp = () => {
            var currentStatus = [0];
            DrawingObj.isWaitMode(currentStatus);
            if (currentStatus[0]) {
              DrawingObj.exitWaitMode();
      
            }
            hasMarkups((hasMarkup: any)=> {
                if (hasMarkup) {
            saveMarkUps( (exportedxmlstringArr) =>{
                var k = exportedxmlstringArr.length-1;
                var xmlString = exportedxmlstringArr[k].XmlString;
                var xmlStringModified = xmlString.replace(/\\/g, "0x5c");
                setMarkupData([{"markupLayer":markupLayer,"exportedxmlstring":xmlStringModified}])
                           
            });
        }
        else {
            setMessage("No Markups Exist");
            setDisplayInfoModal(true);
        }
    });
        };
        useEffect(() => {
            if (markupData.length>0) {
                setShowMarkupSave(true);
                      
            }
        }, [markupData])
        const createFreeHandOnClick = () => {
            var currentStatus = [0];
            DrawingObj.isWaitMode(currentStatus);
            if (currentStatus[0]) {
              DrawingObj.exitWaitMode();
      
            }
            var ratio = [0];
            DrawingObj.getCurrentClientDWGRatio(ratio);
            hexToRGB(markupColor1, function(r, g, b){
                DrawingObj.rgbToColorIndex(r, g, b, function(retCode, colorIndex){
                   
            DrawingObj.addMarkup(
                markupLayer,
                0,
                markupLineThickness * ratio[0],
                "",
                1,
                colorIndex,
                function (retCode: any, entityHandle: any, actualpoints: any) {
                    if (retCode != 0) {
                        console.log("drawFreeHand returned with error code : " + retCode);
                    } 
                }
            );
                });
            });
        };
        const deleteMarkupOnClick = () => {
            setDisplayInfoModal(false);
            var currentStatus = [0];
            DrawingObj.isWaitMode(currentStatus);
            if (currentStatus[0]) {
              DrawingObj.exitWaitMode();
      
            }
             hasMarkups((hasMarkup: any)=> {
                if (hasMarkup) {
                    DrawingObj.setCursor(12);
                    DrawingObj.getDWGPoint(function (returnCode: any, x: any, y: any) {
                        var rubx:any = [],
                            ruby:any = [],
                            rubz:any = [];
                        rubx[0] = x;
                        ruby[0] = y;
                        rubz[0] = 0;
                        DrawingObj.dwgToClient(rubx, ruby, rubz);
    
                        DrawingObj.getEntityWithDWGPoint(markupLayer, 0, x, y, 12, function (retCode: any, entityHandle: any) {
                            DrawingObj.setCursor(1);
                            if (retCode == 0) {
                                setDisplayConfirmModal(true);
                                setMessage("Are you sure you want to delete the selected Markup?")
                                setDeleteMarkup(entityHandle);
                            } else {
                                console.log("getEntityWithDWGPoint returned with error code : " + retCode);
                                DrawingObj.regenerate();
                            }
                        });
                    });
                } else {
                    setMessage("No Unsaved Markups exist to delete");
                    setDisplayInfoModal(true);
                }
            });
        };
        const moveOnClick = () => {
            setDisplayInfoModal(false);
            var currentStatus = [0];
            DrawingObj.isWaitMode(currentStatus);
            if (currentStatus[0]) {
              DrawingObj.exitWaitMode();
      
            }
             hasMarkups((hasMarkup: any)=> {
                if (hasMarkup) {
                    DrawingObj.setCursor(12);
                    DrawingObj.getDWGPoint(function (returnCode: any, x: any, y: any) {
                        var rubx:any = [],
                            ruby:any = [],
                            rubz:any = [];
                        rubx[0] = x;
                        ruby[0] = y;
                        rubz[0] = 0;
                        DrawingObj.dwgToClient(rubx, ruby, rubz);
                        DrawingObj.enableRubberband(rubx[0], ruby[0], 0, true);
    
                        DrawingObj.getEntityWithDWGPoint(markupLayer, 0, x, y, 12, function (retCode: any, entityHandle: any) {
                            DrawingObj.setCursor(1);
                            if (retCode == 0) {
                                DrawingObj.getDWGPoint(function (returnCode: any, nextX: any, nextY: any) {
                                    if (entityHandle != "" && returnCode == 0) {
                                        entityHandle = entityHandle.split(rowDelimiter);
                                        DrawingObj.moveEntity(entityHandle[0], nextX, nextY, function (retCode: any) {
                                            DrawingObj.enableRubberband(0, 0, 0, false);
                                            DrawingObj.regenerate();
                                            if (retCode != 0)
                                             console.log("moveEntity returned with error code : " + retCode);
                                        });
                                    } else {
                                        DrawingObj.enableRubberband(0, 0, 0, false);
                                        DrawingObj.regenerate();
                                    }
                                });
                            } else {
                                console.log("getEntityWithDWGPoint returned with error code : " + retCode);
                                DrawingObj.enableRubberband(0, 0, 0, false);
                                DrawingObj.regenerate();
                            }
                        });
                    });
                } else {
                    setMessage("No Unsaved Markups exist to move");
                    setDisplayInfoModal(true);
                }
            });
        };
        const hasMarkups = (resCallback: any) => {
            DrawingObj.getAllEntities(markupLayer, function (retCode: any, entityhandles: any) {
                if (retCode == 0) {
                    if (entityhandles != "") {
                        resCallback(true);
                    } else resCallback(false);
                } else if (retCode == 21) {
                    resCallback(false);
                } else {
                    console.log("getAllEntities faild due to ", retCode);
                    resCallback(false);
                }
            });
        };
        const createPolylineMarkup = (points: any, tempx: any, tempy: any, pointcount: any) => {
            var currentStatus = [0];
            DrawingObj.isWaitMode(currentStatus);
            if (currentStatus[0]) {
              DrawingObj.exitWaitMode();
      
            }
            var result = DrawingObj.getDWGPoint(function (retCode: any, x: any, y: any) {
                if (retCode == 0) {
                    points = points + x + columnDelimiter + y + rowDelimiter;
                    pointcount++;
                    var rubx:any = [],
                        ruby:any = [],
                        rubz:any = [];
                    rubx[0] = x;
                    ruby[0] = y;
                    rubz[0] = 0;
                    DrawingObj.dwgToClient(rubx, ruby, rubz);
                    DrawingObj.enableRubberband(rubx[0], ruby[0], 0, true);
    
                    if (pointcount > 1) {
                        hexToRGB(markupColor1, function(r, g, b){
                            DrawingObj.rgbToColorIndex(r, g, b, function(retCode, colorIndex){
                        DrawingObj.createLine("temp", colorIndex, tempx, tempy, x, y, function (retCode: any, entityHandle: any) {
                            if (retCode != 0) {
                                console.log("createLine returned with error code : ", retCode);
                                DrawingObj.enableRubberband(x, y, 0, false);
                                return;
                            } else {
                                createPolylineMarkup(points, x, y, pointcount);
                            }
                        });
                    });
                    });
                    } else {
                        createPolylineMarkup(points, x, y, pointcount);
                    }
                } else if (retCode == 8) {
                    var ratio = [0];
                    DrawingObj.getCurrentClientDWGRatio(ratio);
                    hexToRGB(markupColor1, function(r, g, b){
                        DrawingObj.rgbToColorIndex(r, g, b, function(retCode, colorIndex){
                           
                    DrawingObj.createPolyline(
                        markupLayer,
                        colorIndex,
                        points,
                        markupLineThickness * ratio[0],
                        "",
                        1,
                        true,
                        function (retCode: any, entityHandle: any) {
                            points = "";
                            pointcount = 0;
                            DrawingObj.enableRubberband(x, y, 0, false);
                            if (retCode != 0) console.log("createPolyline returned with error code : ", retCode);
                            else {
                                console.log("Entity handle: ", entityHandle);
                             }
                             DrawingObj.deleteLayer("temp", function (retCode: any) {
                                DrawingObj.regenerate();
                            });
                        }
                    );
                        });
                    });
                } else {
                    console.log("getDWGPoint faild due to ", retCode);
                    DrawingObj.enableRubberband(0, 0, 0, false);
                    DrawingObj.regenerate();
                }
            });
       
	};


 
    const onCancelClick = () => {
        setShowComments(false);
        setIsCalloutComment(false);
        setIsTextEdit(false);
        setShowMarkupSave(false);
        setShowDrawingSave(false);
        setShowDrawingExport(false);
    }
    
    return (
       
           <>     
                       
                        <div className="drawing-data">
                            
                            <div className="toolbar">
                                {props.showMarkupTools && <div className="left-toolbox">
                                    <div className="comments-icon">
                                        <Button className="button-icon" tooltip="Type Tool" tooltipOptions={{ position: "top" }}>
                                            <BiText className="icon" onClick={() =>typeToolOnClick()}/>
                                        </Button>
                                        <Button className="button-icon" tooltip="Edit Comment" tooltipOptions={{ position: "top" }}>
                                            <FiEdit className="icon" onClick={() =>editTextComment()}/>
                                        </Button>
                                        <Button className="button-icon" tooltip="Callout" tooltipOptions={{ position: "top" }}>
                                            <BsChatLeftText className="icon" onClick={() =>createCalloutComment()}/>
                                        </Button>
                                        <Button className="button-icon" tooltip="Edit Callout" tooltipOptions={{ position: "top" }}>
                                            <HiOutlinePencilAlt className="icon" onClick={() =>editCalloutComment()}/>
                                        </Button>
                                        
                                        <ColorPicker defaultColor="#FF0000"  value={textColor} onChange={(e) => setTextColor(e.value)}></ColorPicker>
                                    </div>
                                    <DrawingToolbar
                                        ref={toolBarRef}
                                        orientation="horizontal"
                                        tools={toolData}
                                        onClick={onMapEvent}
                                        showColorPicker={true}
                                        showLineThicknessDropdown={true}
                                        onColorPickerChange={(e) => setMarkupColor(e)}
                                    />
                                    <div className="comments-icon">
                                        <Button className="button-icon" tooltip="Markups" tooltipOptions={{ position: "top" }}>
                                            <BiLayer className="icon"  onClick={() => setShowDrawingMarkupList(true)}/>
                                        </Button>
                                        <Button className="button-icon" tooltip="Move" tooltipOptions={{ position: "top" }}>
                                            <BiMove className="icon"  onClick={() => moveOnClick()}/>
                                        </Button>
                                        <Button className="button-icon" tooltip="Delete" tooltipOptions={{ position: "top" }}>
                                            <BsTrashFill className="icon" onClick={() => deleteMarkupOnClick()}/>
                                        </Button>
                                        <Button className="button-icon" tooltip="Save Markup" tooltipOptions={{ position: "top" }}>
                                            <GiSave className="icon" onClick={() => onSaveMarkUp()}/>
                                        </Button>
                                        
                                    </div>
                                </div>}
                                <div className="right-toolbox">
                                    <Button className="button-icon" tooltip="Save" tooltipOptions={{ position: "top" }}>
                                        <FiSave className="icon"onClick={() => DrawingSaveClick()}/>
                                    </Button>
                                    <Button className="button-icon" tooltip="Download" tooltipOptions={{ position: "top" }}>
                                        <FaDownload className="icon" onClick={() => DrawingExportClick()}/>
                                        <FaChevronDown className="p-button-text caret-icon" />
                                    </Button> 
                                    <Button className="button-icon" tooltip="Back" tooltipOptions={{ position: "top" }}>
                                            <FaBackward className="icon" onClick={() => CloseDrawing()} />
                                        </Button>
                                </div>
                            </div>
                            <div className="drawing-wrapper">
                                { cadPlanId!="" && <Drawing
                                    key={""}
                                    cadPlanId={cadPlanId}
                                    openHandler={openHandler}
                                    bIsResizeDrawing={props.bIsResizeDrawing}
                                    revisionNo={revisionNo}
                                    drawingId={drawingId}
                                /> }
                            </div>
                        </div>
             
            
            {showComments? (  <Comments
				showModal={showComments}
                isCalloutComment={isCalloutComment}
				setShowModal={setShowComments}
                onTextData={addTextData}
               cancelAction={onCancelClick}
                editTextData={editTextData}
                pageTitle={pageTitle}
			/>) : null}
            {showMarkupSave? (  <SaveMarkup
				showModal={showMarkupSave}
				setShowModal={setShowMarkupSave}
                onSaveMarkup={saveMarkupComment}
                cancelAction={onCancelClick}
            />) : null}
            
            {showDrawingMarkupList? (  <DrawingMarkupList
				showModal={showDrawingMarkupList}
				setShowModal={setShowDrawingMarkupList}
                selectedDrawing={[props.selectedDrawing]}
                selectedBuilding={props.selectedBuilding}
                selectedMarkup={selectedMarkup}
                markupListSubmit={markupListSubmit}
			/>) : null} 
             {showDrawingExport? (  <Export
      showModal={showDrawingExport}
      setShowModal={setShowDrawingExport}
      objOpenDrawing={DrawingObj}
      cancelAction={onCancelClick}
    />) : null}
     {showDrawingSave? (  <SaveDrawing
      showModal={showDrawingSave}
      setShowModal={setShowDrawingSave}
      objOpenDrawing={DrawingObj}
      cancelAction={onCancelClick}
    />) : null}
           
            <InformationBox
                showInfoModal={displayInfoModal}
                setShowInfoModal={setDisplayInfoModal}
                message={message}
            />
            <ConfirmationBox
                showConfirmModal={displayConfirmModal}
                setShowConfirmModal={setDisplayConfirmModal}
                confirmAction={onConfirmAction}
                cancelAction={onCancel}
                message={message}
            />
       </>     
        
    );
};

export default AsBuiltsDrawing;












