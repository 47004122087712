import React from "react";
import { Button } from "primereact/button";
import { BsPlusLg, BsFillPencilFill, BsTrashFill } from "react-icons/bs";

const LinksDocument = () => {
    return (
        <>
            <h4 className="doc-title mb-2">
                Links
            </h4>
            <p>
                Links provides options to upload or download the Links (in .pdf/mp4 format). 
                The options to Add, Edit, Delete Links are provided and these options 
                will be available to Super Administrators only. 
            </p>

            <p>
                <span className="doc-subtitle">Add: </span>
            </p>

            <p>
                New Link can be added by clicking the 
                <span className="doc-subtitle"> Add </span>
                <Button className="doc-button-icon"> <BsPlusLg className="icon" title="Add"/> </Button> {' '} icon.
            </p>

            <p>
                <span className="doc-subtitle">Edit: </span>
            </p>
            <p>
                Existing Link can be updated by selecting the Link and then clicking the 
                <span className="doc-subtitle"> Edit </span>
                <Button className="doc-button-icon"> <BsFillPencilFill className="icon" title="Edit"/> </Button> {' '} icon.
            </p>

            <p>
                <span className="doc-subtitle">Delete: </span>
            </p>
            <p>
                Existing Link can be deleted by selecting the Link and then clicking the 
                <span className="doc-subtitle"> Delete </span>
                <Button className="doc-button-icon"> <BsTrashFill className="icon" title="Delete"/> </Button>  {' '} icon.
            </p>
        </>
    )
}

export default LinksDocument;