import React, { useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
//icons
import { BsExclamationCircleFill } from "react-icons/bs";
//prime-react
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { classNames } from "primereact/utils";
import { Dialog } from "primereact/dialog";
//components
import InformationBox from "components/common/Message/InformationBox/InformationBox";
//redux
import { onAddLink } from "redux/common/linkSlice";


interface Props {
    showModal: boolean;
    setShowModal: (state: boolean) => void;
    selectedRows: any;
}

type FormData = {
    hyperLink: string;
    description: string;
};

export const AddNewLinks: React.FC<Props> = ({ showModal = false, setShowModal = () => {}, selectedRows = [] }) => {
    const addLinkForm: any = useRef();
    const dispatch = useDispatch<any>();

    const[displayInfoModal,setDisplayInfoModal]=useState(false);
    const[message,setMessage]=useState("");
    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<FormData>({
        defaultValues: {
            hyperLink: "",
            description: "",
        },
    });

    const formSubmitHandler = (data: any) => {
        addLinkForm.current.requestSubmit();
    };

    const getFormErrorMessage = (name) => {
        return (
            errors[name] && (
                <span className="tooltip-text">
                    <BsExclamationCircleFill />
                    {errors[name].message}
                </span>
            )
        );
    };

    const onAddFormSubmit = (data: any) => {
        dispatch(onAddLink(data,reset,setDisplayInfoModal,setMessage));

    };
    const renderFooter = () => {
        return (
            <>
                <Button label="Cancel" onClick={() => setShowModal(false)} className="cancel btn" />
                <Button label="Save" className="update btn" autoFocus onClick={formSubmitHandler}/>
            </>
        );
    };

    const onError = (errors: any, e: any) => {};

    return (
        <>
            <Dialog
                header="New Link"
                visible={showModal}
                style={{ width: '40vw', height: '45vh' }}
                onHide={() => setShowModal(false)}
                footer={renderFooter()}
                className="display-settings personal selectsite"
            >
                <form onSubmit={handleSubmit(onAddFormSubmit, onError)} ref={addLinkForm}>

                    <div className="row px-2 pt-1">
                        <div className="field col-12 md:col-2 mb-0">
                            <span className="p-float-label">
                                <Controller
                                    name="hyperLink"
                                    control={control}
                                    rules={{
                                        required: "Link is required.",
                                    }}
                                    render={({ field, fieldState }) => (
                                        <InputText
                                            id="hyperLink"
                                            className={classNames("w-100 error-tooltip", {
                                                error: fieldState.invalid,
                                            })}
                                            {...field}
                                        />
                                    )}
                                />
                                <label className="mandatory" htmlFor="inputtext">
                                    Link
                                </label>
                                {getFormErrorMessage("hyperLink")}
                            </span>
                        </div>
                    </div>

                    <div className="row px-2 pt-1">
                        <div className="field col-12 md:col-2 mb-0">
                            <span className="p-float-label">
                                <Controller
                                    name="description"
                                    control={control}
                                    rules={{
                                        required: "Description is required.",
                                    }}
                                    render={({ field, fieldState }) => (
                                        <InputTextarea id={field.name} {...field} maxLength={4000}  className={classNames("w-100 error-tooltip", {error: fieldState.invalid})} />
                                    )}
                                />
                                <label className="mandatory" htmlFor="inputtext">
                                    Description
                                </label>
                                {getFormErrorMessage("description")}
                            </span>
                            <span className="textarea-txt-count">Max 4000 Chars</span>
                        </div>
                    </div>

                </form>
                <InformationBox
                    showInfoModal={displayInfoModal}
                    setShowInfoModal={setDisplayInfoModal}
                    message={message}
                />
            </Dialog>
       
        </>
    );
};

export default AddNewLinks;
