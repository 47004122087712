import React from 'react'
import { BsFillEyeFill, BsFillPencilFill, BsPlusLg, BsTrashFill } from 'react-icons/bs';
import { FaDownload } from 'react-icons/fa';
import { Button } from "primereact/button";

const ReportsTapPerformanceDocument = () => {
  return (
    <>
      <h4 className="doc-title mb-2">Life Safety Systems Report</h4>
      <p>
      Life Safety Systems Report page is used to create projects which are used to generate Life Safety Systems Reports. Super Admin, Support and Admin users can use Life Safety Systems Report page to generate reports. Option to Add, Edit, Delete Project and option to View, Download and Generate Report are provided in the Life Safety Systems Report page.
      </p>
      <p><span className="doc-subtitle"><b>Add: </b></span></p>
          <p>
              Life Safety Systems Report can be generated only after creating a new Project and
              then defining all the details in the Project. So,<p><span className="doc-subtitle">Add </span>
                  <Button className="doc-button-icon">
                      <BsPlusLg className="icon" title='Add' />
                  </Button> option provided in the Life Safety Systems Report helps to create
                  the project where all the required details like Project Details, Goals,
                  System Details and Comments are added. Users can add and save Project Name,
                  Project Owner name, Email, selecting the required Branch Office, System and
                  Inspection Task, Start Date and End Date in Project Details page. Life Safety Systems
                  Goals can be added in the Goals page and comments can be added in Comments page.
                  Users can select the required system from System Details to add Software version and
                  update details
                  and also to view
                  Inspection Report Turnaround and Inspection Deficiency List details.
              </p>
          </p>

          <p className="doc-subtitle">
              <b>Edit: </b>
          </p>
          <p> <span className="doc-subtitle"> Edit </span>
              {" "}<Button className="doc-button-icon">
                  <BsFillPencilFill className="icon" title='Edit' />
              </Button>{" "}  option is provided to edit and make the necessary changes to the Project. To edit a Project, select the required Project and click Edit icon. The changes made will be reflected only in newly generated reports and not in already generated reports.
          </p>

          <p className="doc-subtitle">
              <b>Delete: </b>
          </p>

          <p>
          <span className="doc-subtitle"> Delete </span> 
          <Button className="doc-button-icon"> 
            <BsTrashFill className="icon" title='Delete' /> 
          </Button>
          option is provided to delete the Project.To delete a Project, select the required Project and click Delete icon.
          </p>

          <p className="doc-subtitle">
              <b>View: </b>
          </p>
          <p>
          <span className="doc-subtitle"> View </span>
          <Button className="doc-button-icon">
                    <BsFillEyeFill className="icon" title="View" />
                </Button>{" "}  option is provided to view the Reports generated against Project. Report will be opened in a PDF / Doc format when View option is clicked.Reports can only be viewed if reports are generated for the selected Project.
          </p>

          <p className="doc-subtitle">
              <b>Download: </b>
          </p>        
          <p>
          <span className="doc-subtitle"> Download </span>
                <Button className="doc-button-icon ">
                    <FaDownload className="icon" title="Download" />          
                </Button>
                {" "} option is provided the download the generated report in .docx format.To download the Report generated for a Project, select the required Project and click Download icon.
          </p>

          <p className="doc-subtitle">
              <b>Generate:<Button
                  label="Generate"
                  className="generate-btn">
                    </Button> </b>
          </p>
          <p>
            <span className="doc-subtitle"> Generate</span>
            {"    "} option is provided to generate Life Safety Systems Report for the selected Project.
          </p>
          
    </>
  );
}
export default ReportsTapPerformanceDocument;