import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { OverlayPanel } from "primereact/overlaypanel";
import PopOver from "components/common/PopOver/PopOver";
import { Timeline } from "primereact/timeline";
import { Card } from "primereact/card";
import ListGroup from "react-bootstrap/ListGroup";
import { ReleaseNoteData } from "../ReleaseNote/releaseNote";
import Links from "./Links";
import Plugins from "./Plugin";
import { Button } from "primereact/button";
import {
  BsPlusLg,
  BsFillPencilFill,
  BsTrashFill,
} from "react-icons/bs";
import {
  NavItemProps,
  DEFAULT_DASHBOARD_NAVLIST,
  DEFAULT_ADMINISTRATION_NAVLIST,
  DEFAULT_INSPECTION_NAVLIST,
  DEFAULT_ASBUILTS_NAVLIST,
} from "constants/common";
import { MdOutlineBrightness5, MdOutlineListAlt } from "react-icons/md";
import { BsFillGrid3X3GapFill } from "react-icons/bs";
import { ScrollPanel } from "primereact/scrollpanel";
import links from "assets/svgs/links.svg";
import plugins from "assets/svgs/plugins.svg";
import bell from "assets/svgs/bell.svg";

import { useAppSelector } from "../../../redux/hooks";
import { showUI } from 'service/GeneralUtility';

import { APP_ROUTES } from "constants/AppRoutes";
import useOnClickOutside from "hooks/useOnClickOutside";


interface Props { }

const releasenoteref = React.createRef<OverlayPanel>();
const linkref = React.createRef<OverlayPanel>();
const pluginref = React.createRef<OverlayPanel>();

const PageMenuBar: React.FC<Props> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dropDownref = useRef(null);
  
  const userPrivileges = useAppSelector((state) => state.user.userPrivileges);

  const [navList, setNavList] = useState(DEFAULT_DASHBOARD_NAVLIST);
  const [bgWhite, setBgWhite] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);
  const [showLink, setShowLink] = useState(false);
  const [showPlugin, setShowPlugin] = useState(false);
  const [activeModule, setActiveModule] = useState<any>([]);
  const [administrationNavList, setAdministrationNavList] = useState<any>();
  const buildVersion = useAppSelector((state) => state.common.buildVersion);
  useOnClickOutside(dropDownref, () => setShowDropDown(false));

  useEffect(() => {
    if (userPrivileges && userPrivileges.length) {
      filterAdminNavList();
    }
  }, [userPrivileges])

  const filterAdminNavList = () => {
    let adminNavList: Array<NavItemProps> = [];
    userPrivileges.map(element => {
      if (element === 'SIETAP_CLIENTS_MANAGE') {
  
        adminNavList.push({
          id: 2,
          name: 'Customers',
          url: APP_ROUTES.CUSTOMERS,
          active: false
        })
      } else if (element === 'SIETAP_USERS_MANAGE') {

        adminNavList.push({
          id: 3,
          name: 'Users',
          url: APP_ROUTES.USERS,
          active: false
        })
      } else if (element === 'SIETAP_SITES_VIEW' || element === 'SIETAP_SITES_MANAGE') {
  
        adminNavList.push({
          id: 4,
          name: 'Sites',
          url: APP_ROUTES.SITES,
          active: false
        })
      }
    });
    adminNavList.push({
      id: 5,
      name: 'Search',
      url: `${APP_ROUTES.ADMINISTRATION}-search`,
      active: false
    })
    adminNavList=adminNavList.sort((a,b) => (a.id - b.id))
    adminNavList[1].active = true;
    setAdministrationNavList(adminNavList);

  }


  const updateActiveNav = useCallback(
    (navTabList: NavItemProps[], currentPageRoute: string) => {
      if (navTabList && navTabList.length) {
        const navListCopy = JSON.parse(JSON.stringify(navTabList));
        const activeRoute = currentPageRoute.split("/") ?? [
          "",
          APP_ROUTES.DASHBOARD,
        ];
        const pageInfo = {
          isHomePage: activeRoute?.[1] === "",
          isDashboardPage: activeRoute?.[1] === APP_ROUTES.DASHBOARD,
          isAdministrationPage: activeRoute?.[1] === APP_ROUTES.ADMINISTRATION,
          isInspectionPage: activeRoute?.[1] === APP_ROUTES.INSPECTION,
        };
        const bgWhite =
          pageInfo.isHomePage || activeRoute?.[1] === APP_ROUTES.DASHBOARD;

        if (pageInfo.isHomePage) {
          navListCopy[0]["active"] = true;
        }

        if (pageInfo.isAdministrationPage || pageInfo.isInspectionPage) {
          navListCopy[1]["active"] = true;
        }

        if (
          !pageInfo.isHomePage &&
          !pageInfo.isAdministrationPage &&
          !pageInfo.isInspectionPage
        ) {
          navListCopy.map((item) => {
            if (item.url === activeRoute?.[1]) {
              item["active"] = true;
            } else {
              item["active"] = false;
            }

            return item;
          });
        }

        setNavList(navListCopy);
        setBgWhite(bgWhite);
      }
    }, []);

  useEffect(() => {
    const currentPageRoute = location.pathname;
    const activeModules: any = location.pathname.split("/");
    if ((activeModules[1] === "dashboard") ||
      (activeModules[1] === "administration") ||
      (activeModules[1] === "inspection")
    ) {
      setActiveModule([]);
      setActiveModule((state: any) => [...state, activeModules[1]]);
    }
    let navTabList: NavItemProps[] = [];
    switch (currentPageRoute) {
      case "/":
      case "/dashboard": {
        navTabList = DEFAULT_DASHBOARD_NAVLIST;
        break;
      }
      case "/administration":
      case "/customers":
      case "/users":
      case "/sites":
      case "/administration-search": {
        navTabList = administrationNavList;
        break;
      }

      case "/inspection":
      case "/inspection-tasks":
      case "/contracts":
      case "/systems":
      case "/inspection-reports":
      case "/inspection-search":
      case "/inspection-reports/reports":
      case '/inspection-drawings': {
        navTabList = DEFAULT_INSPECTION_NAVLIST;
        break;
      }

      case "/as-builts":
        navTabList = DEFAULT_ASBUILTS_NAVLIST;
        break;
      default: {
        if (activeModule[0] === "inspection") {
          navTabList = DEFAULT_INSPECTION_NAVLIST;
        }
        else if (activeModule[0] === "administration") {
          navTabList = administrationNavList;
        }
        else if (activeModule[0] === "dashboard") {
          navTabList = DEFAULT_DASHBOARD_NAVLIST;
        }
        break;
      }
    }
    updateActiveNav(navTabList, currentPageRoute);
  }, [location.pathname, updateActiveNav, administrationNavList]);

  const isManageViewScreen = () => {
    return (
      (location.pathname &&
        location.pathname.includes(APP_ROUTES.MANAGE_VIEW)) ||
      false
    );
  };
  const isReportViewScreen = () => {
    return (
      (location.pathname &&
        location.pathname.includes(APP_ROUTES.REPORT_VIEW)) ||
      false
    );
  };

  const handleManageRedirect = () => {
    if (isManageViewScreen()) {
      navigate(-1);
    } else {
      navigate(APP_ROUTES.MANAGE_VIEW);
    }
  };
  const handleReportRedirect = () => {
    if (isReportViewScreen()) {
      navigate(-1);
    } else {
      navigate(APP_ROUTES.REPORT_VIEW);
    }
  };

  const onLinkClick = (e) => {
    setShowLink(true);
    linkref.current?.toggle(e)
  }

  const onPluginClick = (e) => {
    setShowPlugin(true);
    pluginref.current?.toggle(e)
  }

  const customizedContent = (item) => {
    return (
      <Card title={item.header}>
        <ListGroup as="ol" numbered>
          {item?.content?.map((el: any, key) => (
            <ListGroup.Item as="li" key={key}>
              {el.text && 
                <span dangerouslySetInnerHTML={{__html: `${el.text}`}}></span>
              }
              {el.subContent ? (
                <ListGroup as="ul" key={key}>
                  {el.subContent?.map((data: any, key) => (
                    <ListGroup.Item as="li" key={key}>
                      {data.subText}
                    </ListGroup.Item>
                  ))}
                </ListGroup>) : null}
            </ListGroup.Item>))}

        </ListGroup>
      </Card>
    );
  };



  return (
    <>
      <div className={`menu-bar-container ${bgWhite ? "bg-white" : ""}`}>
        <div className="nav-links">
          <ul>
            {navList.map((item, index) => (
              <li
                key={index}
                className={item.active ? "active" : ""}
                role="button"
                onClick={() => navigate(`/${item?.url}`)}
              >
                {item?.name}
              </li>
            ))}
          </ul>
        </div>
        <div className="config-section">
          <ul>
            {showUI(userPrivileges, "SIETAP_FS_MANAGE") &&
              <li
                role="button"
                className={isManageViewScreen() ? "active" : ""}
                onClick={handleManageRedirect}
              >
                <MdOutlineBrightness5 style={{ color: "#50BED7" }} />
                Manage
              </li>
            }
            <li role="button" className={isReportViewScreen() ? "active" : ""} onClick={handleReportRedirect}>
              <MdOutlineListAlt style={{ color: "#AF2E5F" }} />
              Reports
            </li>
            <li role="button" onClick={() => setShowDropDown(!showDropDown)}>
              <BsFillGrid3X3GapFill />
            </li>
          </ul>
          {showDropDown && (
            <ul className="extra-menus" ref={dropDownref}>
              <li
                role="button"
                onClick={(e) => releasenoteref.current?.toggle(e)}
              >
                <img src={bell} alt="bell" />
                Release Notes
              </li>

              {showUI(userPrivileges, "SIETAP_PLUGIN_MANAGE") &&
                <li role="button" onClick={(e) => onPluginClick(e)}>
                  <img src={plugins} alt="plugins" />
                  Plugins
                </li>
              }

              <li role="button" onClick={(e) => onLinkClick(e)}>
                <img src={links} alt="links" />
                Links
              </li>

              <li className="build-version">
                <i className="pi pi-info-circle"></i>
                <i> &nbsp; Build Version</i>
                <span className="tooltiptext">{buildVersion}</span>
              </li>
            </ul>
          )}
        </div>

        <PopOver ref={releasenoteref}>
          <div className="release-note" style={{ width: "60vw" }}>
            <div className="d-flex pb-2 justify-content-between">
              <div className="title">Release Notes</div>
              <div className="close-icon text-right">
              <i className="pi pi-times" onClick={() => releasenoteref.current?.hide()}></i>
            </div>
            </div>
            <ScrollPanel style={{ width: "100%", height: "420px" }}>
              <Timeline
                value={ReleaseNoteData}
                align="left"
                className="customized-timeline"
                content={customizedContent}
              />
            </ScrollPanel>
          </div>
        </PopOver>

        <PopOver ref={pluginref}>
          {showPlugin && (<Plugins showModal={showPlugin} setShowModal={setShowPlugin}></Plugins>)}
        </PopOver>
        <PopOver ref={linkref}>
          {showLink && (<Links showModal={showLink} setShowModal={setShowLink}></Links>)}
        </PopOver>
      </div>
    </>
  );
};

export default PageMenuBar;

