import React, { useState, useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { BsExclamationCircleFill } from "react-icons/bs";
import InformationBox from 'components/common/Message/InformationBox/InformationBox';

interface Props {
    showModal: boolean;
    setShowModal: (state: boolean) => void;
    objOpenDrawing: Function | any;

    cancelAction?:()=>void,
}
type FormData = {
    export: any;
    exportList:any;
};
export const Export: React.FC<Props> = ({
    showModal = false,
    setShowModal = () => {},
    objOpenDrawing,
    cancelAction=()=>{},


}) => {
  
    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
        watch,
    } = useForm<FormData>({
        defaultValues: {
            export: null,
            exportList:null
        },
    });
    const exportDrawing = watch("export");
    const addForm: any = useRef();
    const EXPORT_LIST = [
        { name: "BMP ", code: "1" },
        { name: "DWF", code: "2" },
        { name: "DXB", code: "3" },
        { name: "DXF", code: "4" },
        { name: "JPEG", code: "5" },
        { name: "PDF", code: "6" },
        { name: "SVG", code: "7" },
      ];
    const [selectedExport, setSelectedExport] = useState<any>(
        );
    const [message, setMessage] = useState("");
  const [displayInfoModal, setDisplayInfoModal] = useState(false);

    const renderFooter = () => {
        return (
            <>
                <Button label="Cancel" onClick={() =>{cancelAction(); setShowModal(false)} }className="cancel btn" />
                <Button label="OK" className="update btn" autoFocus onClick={() =>{onOKExport();}}/>
            </>
        );
    };
    const onExportChange = (e: { value: any }) => {
        setSelectedExport(e.value);
      };
      const onOKExport = () => {
        if(selectedExport){
            switch (selectedExport.code) {

              
                case "1"://bmp
                objOpenDrawing.exportToBMP(function (returnCode) {
                                  if (returnCode == 0)
                                  setMessage("Drawing exported");
                                  setDisplayInfoModal(true)
                              });
                              break;
                          
                case "2"://"dwf"
                objOpenDrawing.exportToDWF(1, function (returnCode) {
                                  if (returnCode == 0){
                                    setDisplayInfoModal(false)
                                  setMessage("Drawing exported");
                                  setDisplayInfoModal(true)
                                  }
                              });
                              break;
          
                case "3":// "dxb"
                objOpenDrawing.exportToDXB(1, function (returnCode) {
                                  if (returnCode == 0){
                                    setDisplayInfoModal(false)
                                  setMessage("Drawing exported");
                                  setDisplayInfoModal(true)
                                  }
                              });
                              break;
          
                case "4" ://"dxf"
                objOpenDrawing.exportToDXF(1, function (returnCode) {
                  if (returnCode == 0){
                    setDisplayInfoModal(false)
                                  setMessage("Drawing exported");
                                  setDisplayInfoModal(true)
                  }
                              });
                              break;
          
                case "5"://"jpeg"
                              var result = objOpenDrawing.exportToJPEG(function (returnCode) {
                                if (returnCode == 0){
                                  setDisplayInfoModal(false)
                                  setMessage("Drawing exported");
                                  setDisplayInfoModal(true)
                                }
                              });
                              break;
                              
                case "6"://"pdf"
                objOpenDrawing.exportToPDF(false, "", function (returnCode) {
                  if (returnCode == 0){
                    setDisplayInfoModal(false)
                                  setMessage("Drawing exported");
                                  setDisplayInfoModal(true)
                  }
                              });
                              break;
          
                case "7"://"svg"
                objOpenDrawing.exportToSVG(function (returnCode) {
                  if (returnCode == 0){
                    setDisplayInfoModal(false)
                                  setMessage("Drawing exported");
                                  setDisplayInfoModal(true)
                  }
                              });
                              break;
                                                                       
                default:
                               break;
                      }
        }
    }
    const getFormErrorMessage = (name) => {
        return (
          errors[name] && (
            <span className="tooltip-text">
              <BsExclamationCircleFill />
              {errors[name].message}
            </span>
          )
        );
      };

      const formSubmitHandler = (data: any) => {
        addForm.current.requestSubmit();
      };

      const onFormSubmit = (data: any) => {
       
     };
    
      const onError = (errors: any, e: any) => {};


    return (
        <>
            <Dialog
                header="Export"
                visible={showModal}
                style={{ width: "40vw" }}
                onHide={() => setShowModal(false)}
                footer={renderFooter()}
                className="display-settings selectsite"
            >
               
                            <p className="pl-2">Do you want to export this drawing? </p>
                <form
                 onSubmit={handleSubmit(onFormSubmit, onError)}
                 ref={addForm}
                >
           
                        
                        <div className="field col-12 md:col-4">
                        <span className="p-float-label">
                            <Controller
                                name="exportList"
                                control={control}
                                rules={{ required: "Country Name is required." }}
                                render={({ field, fieldState }) => (
                                    <Dropdown
                                        inputId="dropdown"
                                        className={classNames("w-100 error-tooltip", {
                                            error: fieldState.invalid,
                                        })}
                                        {...field}
                                        value={selectedExport}
                  options={EXPORT_LIST}
                  onChange={onExportChange}
                  optionLabel="name"
                  placeholder="-Select-"
                                    />
                                )}
                            />
                            <label className="mandatory" htmlFor="dropdown">
                            -Select-
                            </label>
                            {getFormErrorMessage("exportDrawing")}
                        </span>
                    </div>   


                </form>
            </Dialog>

        </>
    );
};

export default Export;
