import React from 'react';
import { useNavigate } from "react-router-dom";
import { Row, Col } from 'react-bootstrap';

import { BsDiagram2 } from 'react-icons/bs';
import { ImSearch, ImClipboard } from 'react-icons/im';
import { IoIosConstruct } from 'react-icons/io';
import { MdManageAccounts, MdSettings } from 'react-icons/md';

import Card from 'components/common/Card/Card';

import { APP_ROUTES } from 'constants/AppRoutes';
import { ORGANIZATION_TYPES, SECURITY_SETTINGS_TYPE } from 'constants/common';
//redux
import { useAppSelector } from 'redux/hooks';
//utility
import { showUI } from 'service/GeneralUtility';

interface Props { }

export const ManageView: React.FC<Props> = () => {
    const navigate = useNavigate();
    const privileges = useAppSelector((state) => state.user.userPrivileges);

    const handleOrganizationRedirect = (from) => {
        navigate(`/${APP_ROUTES.MANAGE_ORGANIZATION}`, {
            state: {
                from
            }
        })
    };

    const handleSecuritySettingRedirect = (from) => {
        navigate(`/${APP_ROUTES.SECURITY_SETTINGS}`, {
            state: {
                from
            }
        })
    };

    return (
        <Card classNames='manage-view' title='Manage'>
            <Row>
                {showUI(privileges, "SIETAP_GENERAL_SETUP") &&
                    <Col md='3' className='box d-flex align-items-start'>
                        <MdManageAccounts className='me-3 modal-icons' />
                        <div className='d-flex flex-column py-1'>
                            <div className='heading'>Security Settings</div>
                            <span className='sub-heading' onClick={() => handleSecuritySettingRedirect(SECURITY_SETTINGS_TYPE.PASSWORD_POLICY)}>Password Policy</span>
                            <span className='sub-heading' onClick={() => handleSecuritySettingRedirect(SECURITY_SETTINGS_TYPE.MAIL_DOMAINS)}>Mail Domains</span>
                        </div>
                    </Col>
                }
                {showUI(privileges, 'SIETAP_ORGANIZATION_SETTINGS') &&
                    <Col md='3' className='box d-flex align-items-start'>
                        <BsDiagram2 className='me-3 modal-icons' />
                        <div className='d-flex flex-column py-1'>
                            <div className='heading'>Organization Settings</div>
                            {showUI(privileges, 'SIETAP_ZONE_MANAGE') &&
                                <span className='sub-heading' role="button" onClick={() => handleOrganizationRedirect(ORGANIZATION_TYPES.ZONE)}>
                                    Zone
                                </span>
                            }
                            {showUI(privileges, 'SIETAP_SALES_OFFICE_MANAGE') &&
                                <span className='sub-heading' role="button" onClick={() => handleOrganizationRedirect(ORGANIZATION_TYPES.SALES)}>
                                    Sales Office
                                </span>
                            }
                            {showUI(privileges, 'SIETAP_BRANCHES_MANAGE') &&
                                <span className='sub-heading' role="button" onClick={() => handleOrganizationRedirect(ORGANIZATION_TYPES.BRANCH)}>
                                    Branch Office
                                </span>
                            }
                        </div>
                    </Col>
                }
                {showUI(privileges, 'SIETAP_LOOKUPS') &&
                    <Col md='3' className='box d-flex align-items-start'>
                        <ImSearch className='me-3 modal-icons' />
                        <div className='d-flex flex-column py-1'>
                            <div className='heading'>Lookups</div>
                            <span className='sub-heading' role="button" onClick={() => navigate(`/${APP_ROUTES.MANAGE_LOOKUPS_HEALTHCLASSIFICATION}`)}>Health Classifications</span>
                            <span className='sub-heading' role="button" onClick={() => navigate(`/${APP_ROUTES.MANAGE_LOOKUPS_NFPA_CLASSIFICATION}`)}>NFPA Classifications</span>
                        </div>
                    </Col>
                }
                {showUI(privileges, 'SIETAP_INSPECTIONS_SETTINGS_MANAGE') &&
                    <Col md='3' className='box d-flex align-items-start'>
                        <ImClipboard className='me-3 modal-icons' />
                        <div className='d-flex flex-column py-1'>
                            <div className='heading'>Inspection Settings</div>
                            <span className='sub-heading' onClick={() => navigate(`/${APP_ROUTES.INSPECTION_FIELDS}`)}>Inspection Fields</span>
                            {showUI(privileges, "SIETAP_FS_REPORT_MANAGE") &&
                                <span className='sub-heading' role="button" onClick={() => navigate(`/${APP_ROUTES.MANAGE_INSPECTION_REPORTS}`)}>
                                    Reports
                                </span>
                            }
                            {showUI(privileges, "SIETAP_FS_REPORT_MAPPING") &&
                                <span className='sub-heading' role="button" onClick={() => navigate(`/${APP_ROUTES.MANAGE_INSPECTION_REPORT_MAPPING}`)}>
                                    Report Mapping
                                </span>
                            }
                        </div>
                    </Col>
                }
            </Row>

            <Row className='pt-3'>
                {showUI(privileges, "SIETAP_FS_TOOLS") &&
                    <Col md='3' className='box d-flex align-items-start'>
                        <IoIosConstruct className='me-3 modal-icons' />
                        <div className='d-flex flex-column py-1'>
                            <div className='heading'>Tools</div>
                            <span className='sub-heading' onClick={() => navigate(`/${APP_ROUTES.TOOLS_COPY}`)}>Copy</span>
                            <span className='sub-heading' onClick={() => navigate(`/${APP_ROUTES.TOOLS_Move}`)}>Move</span>
                            <span className='sub-heading' onClick={() => navigate(`/${APP_ROUTES.TOOLS_COPY_MOVE_LOGS}`)}>Copy / Move Logs</span>
                            <span className='sub-heading' onClick={() => navigate(`/${APP_ROUTES.SAP_IMPORT_STATUS}`)}>SAP Import Status</span>
                        </div>
                    </Col>
                }
                {showUI(privileges, "SIETAP_CUSTOMIZATION") &&
                    <Col md='3' className='box d-flex align-items-start'>
                        <MdSettings className='me-3 modal-icons' />
                        <div className='d-flex flex-column py-1'>
                            <div className='heading'>Customization</div>
                            <span className='sub-heading' role="button" onClick={() => navigate(`/${APP_ROUTES.CUSTOMIZATION_ATTRIBUTES}`)}>Attributes</span>
                            <span className='sub-heading' onClick={() => navigate(`/${APP_ROUTES.MANAGE_DISPLAY_SETTINGS}`)}>Display Settings</span>
                            <span className='sub-heading' onClick={() => navigate(`/${APP_ROUTES.MANAGE_DATAENTRY_SETTINGS}`)}>Data Entry Settings</span>
                        </div>
                    </Col>
                }
            </Row>
        </Card >
    );
}

export default ManageView;
