import React, { useEffect, useRef, useState } from 'react'
//components
import EquipmentForm from 'components/common/Forms/GeneralForm/EquipmentForm';
import AddModal from 'components/Table/AddModal/AddModal';

interface CloneEquipmentPropType {
    header?: string | any;
    showModal: boolean;
    datafields?: any;
    onHideModal?: () => void;
    onFormSubmit?: (body, reset, setValue, datafields) => void;
    uniqueEquipmentClassId?: any;
}
const CloneEquipment: React.FC<CloneEquipmentPropType> = (props) => {
    const { showModal, datafields, header, onHideModal = () => { }, onFormSubmit = () => { }, uniqueEquipmentClassId } = props;
    const editForm = useRef<any>(null);
    const [dataFields, setDataFields] = useState<any>(null)

    let body = {
        uniqueEquipmentClassId: uniqueEquipmentClassId
    }

    useEffect(() => {
        if (datafields?.length) {
            let fieldList = JSON.parse(JSON.stringify(datafields));
            fieldList.forEach((element) => {
                if (element?.dataFieldName === "Address")
                    element.fieldValue = "";
            });
            const filterItem = fieldList.filter((item) => !["Test Method", "Test Result", "Test Date"].includes(item.dataFieldName));
            setDataFields(filterItem)
        }
    }, [datafields]);

    const formSubmitHandler = () => {
        editForm?.current?.requestSubmit();
    }

    const submit = (fields, reset, setValue) => {
        onFormSubmit(fields, reset, setValue, dataFields)
    }

    return (
        <AddModal
            header={header}
            showModal={showModal}
            setShowModal={onHideModal}
            OnSaveClick={formSubmitHandler}
            isAdd={false}
            style={{ width: "35vw", maxHeight: "95%" }}
        >
            <EquipmentForm ref={editForm} datafields={dataFields} isEditForm={true} ddAppendTo='self' onFormSubmit={submit} body={body} />
        </AddModal>
    )
}

export default CloneEquipment