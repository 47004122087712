import { Button } from "primereact/button";
import React from "react";
import { BsPlusLg } from "react-icons/bs";

const MoveDocument = () => {
    return (
        <>
           <h4  className="doc-title mb-2">Move</h4>

           <p>
              This option is used to Move All Systems in a Contract, 
              System and Inspection Task from a Site (Source Site) 
              to another Site (Destination Site).
           </p>
           <p>
             <i>Note: </i>Move is not allowed if any Active Inspection 
             Task available under the selected Site/System.
           </p>

           <b  className="doc-title mb-2">Move All Systems in a Contract</b>
           <p>
              On selecting Move All Systems in a Contract, options to 
              select Customer (source and destination), Site (source and destination) 
              and Contract will be displayed. Make necessary selections and click Move button. 
              All data including Systems, Inspection Tasks, Reports etc of the selected 
              Contract will be moved to the Destination Site. If No Sites exist in the Destination, 
              New Site can be added by clicking <span className="doc-subtitle"> Add </span>  
              <Button className="doc-button-icon"> <BsPlusLg className="icon" title="Add" /> </Button> icon 
              provided next to the Destination Site field.
           </p>
           <p>
             <i>Note: </i>Systems in the selected Contract cannot be moved if the selected 
             Contract is linked to multiple Sites.
           </p>

           <b className="doc-title mb-2">Move System</b>
           <p>
              On selecting Move System, options to select Customer (source and destination), 
              Site (source and destination), System (Source) and Contract (Destination)  will be 
              displayed. Make necessary selections and click Move button.  All data including 
              Inspection Tasks, Reports etc of the selected System will be moved to the selected 
              Contract. If No Sites exist in the Destination, New Site can be added by clicking 
              <span className="doc-subtitle"> Add </span>  
              <Button className="doc-button-icon"> <BsPlusLg className="icon" title="Add"/> </Button> icon 
              provided next to the Destination Site field. If no Contract exists in the Destination, 
              New Contract can be added by clicking 
              <span className="doc-subtitle"> Add </span>  
              <Button className="doc-button-icon"> <BsPlusLg className="icon" title="Add" /> </Button> icon 
              provided next to the Destination Contract field.
           </p>
        </>
    );
}

export default MoveDocument;