import React, { useEffect, useRef, useState } from 'react';
//components
import AddModal from 'components/Table/AddModal/AddModal';
import GeneralForm from 'components/common/Forms/GeneralForm/GeneralForm';
//redux
import { useDispatch } from 'react-redux';
import { onGetEquipmentGroupDatafields, onNewEquipmentGroupAdd } from 'redux/pages/Inspection/InspectionTask/InspectionDetails/Equipment/EquipmentSlice';
import { useAppSelector } from 'redux/hooks';

interface Props {
    showModal: boolean,
    setShowModal: (state: boolean) => void;
    selectedTask?: any;
}
export const AddEquipmentList: React.FC<Props> = (props) => {

    const { showModal, setShowModal = () => { }, selectedTask } = props;
    const dispatch = useDispatch<any>();
    const datafields = useAppSelector((state) => state.equipment.equipmentGroupDatafields);
    const addform = useRef<any>(null);
    const [loader, setLoader] = useState<boolean>(false);

    useEffect(() => {
        if (selectedTask && selectedTask.uniqueInspectionTaskId) {
            dispatch(onGetEquipmentGroupDatafields(0, selectedTask.uniqueInspectionTaskId));
        }
    }, []);

    const onFormSubmit = (data) => {
        if (selectedTask && selectedTask.uniqueInspectionTaskId) {
            setLoader(true);
            const body = {
                uniqueSystemId: selectedTask?.system?.uniqueSystemId,
                fieldDetails: data
            }
            dispatch(onNewEquipmentGroupAdd(body, selectedTask.uniqueInspectionTaskId, setLoader)); 
        }
    }

    const formSubmitHandler = () => {
        addform?.current?.requestSubmit();
    };

    return (
        <>
            <AddModal
                header='New Equipment Data'
                showModal={showModal}
                isAdd={true}
                setShowModal={setShowModal}
                style={{ width: '35vw', maxHeight: '100%' }}
                OnSaveClick={formSubmitHandler}
                loader={loader}
            >
                <GeneralForm ref={addform} datafields={datafields} isEditForm={false} onFormSubmit={onFormSubmit} />
            </AddModal>
        </>
    );
}

export default AddEquipmentList;