export const USATaskPattern = [
    {
        id: 1,
        pattern: 'USA Pattern',
        format: '5xxxxxxx',
        description: '10 digits starting with 5'
    }
]
export const CanadaTaskPattern=[
    {
        id: 1,
        pattern: 'Canada Pattern',
        format: '600xxxxxx',
        description: '9 digits starting with 600'
    },
    {
        id: 2,
        pattern: 'Canada Pattern',
        format: '5xxxxxxxxx',
        description: '10 digits starting with 5'
    }
]