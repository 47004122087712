import React from 'react'

const SearchDocument = () => {
  return (
    <>
<h4 className="doc-title mb-2">Search</h4>

<p>
Search option enables Global Search of Sites, Contracts, 
Systems and Inspection Tasks. The different menu options that are 
available under Search menu are Sites, Contracts, Systems, Inspection 
Tasks, Notification Number and Search User.
</p>

</>
  );
}
export default SearchDocument;
