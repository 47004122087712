import React from 'react'

const ReportsBuildingsDocument = () => {
  return (
    <>
      <h4 className="doc-title mb-2">
        Reports - Buildings
      </h4>
      
      <p>
        This report shows the list of buildings with their Building Name, Code, Site Name, 
        Site Code and Floor count etc. The reports can be filtered using the Status as required with their values.
      </p>
  </>
  );
}
export default ReportsBuildingsDocument;
