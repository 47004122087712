export const USAPattern = [
    {
        id: 1,
        pattern: "USA Pattern",
        format: "3xxxxxxx",
        description: "8 digits starting with 3",
    },
    {
        id: 2,
        pattern: "USA Pattern",
        format: "1xxxxxxx",
        description: "8 digits starting with 1",
    },
]
export const CanadaPattern=[
    {
        id:1,
        pattern:"Canada Pattern",
        format:"6xxxxx",
        description:"6 digits starting with 6",
    },
]