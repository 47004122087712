import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {zlink, baseURL} from "../../../../service/HttpService"
import { AppThunk } from "../../../store";

export interface SelectSiteState {
    errors: any;
    zoneList:any;
    customerList:any;
    siteList:any;
}

let initialState: SelectSiteState = {
	errors: "",
    zoneList:[],
    customerList:[],
    siteList:[],
};

const SelectSiteSlice = createSlice({
	name: "selectSite",
	initialState,
	reducers: {
		setErrors: (state, { payload }: PayloadAction<any>) => {
			state.errors = payload;
		},
        setZoneList: (state, {payload}: PayloadAction<any>) => {
			state.zoneList = payload;
		},
        setCustomerList: (state, {payload}: PayloadAction<any>) => {
			state.customerList = payload;
		},
        setSiteList: (state, {payload}: PayloadAction<any>) => {
			state.siteList = payload;
		},
    }
})

export const getZoneDetails = (countryId:any): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.get(`${baseURL}api/v1/country/${countryId}/zones`);
		if (res.status === "done") {
			dispatch(setZoneList(res?.data?.zoneDetails));
		} else {
			dispatch(setErrors(res.error));
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const getCustomerDetails = (countryId:any,body:any): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.post(`${baseURL}api/v1/country/${countryId}/customers`,body);
		if (res.status === "done") {
			dispatch(setCustomerList(res?.data?.customerDetails));
		} else {
			dispatch(setErrors(res.error));
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const getSiteDetails = (countryId:any,body:any): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.post(`${baseURL}api/v1/country/${countryId}/sites`,body);
		if (res.status === "done") {
			dispatch(setSiteList(res?.data?.siteDetails));
		} else {
			dispatch(setErrors(res.error));
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const { 
    setErrors,
    setZoneList,
    setCustomerList,
    setSiteList} = SelectSiteSlice.actions;

export default SelectSiteSlice.reducer;