import { InputText } from 'primereact/inputtext';


const textEditor = (options) => {
    return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
};


export const CUSTOMIZATION_EQUIPMENT_TYPE_ATTRIBUTES_VALUES_COLS = {
    EQUIPMENT_TYPE_ATTRIBUTES_VALUES_COLS: [
        {
            field: 'attributeValue',
            header: 'Value',
            editor: (options) => textEditor(options),
            style: { flex: '1 0 200px' },
            sortable: true,
        }
    ]
    
};

export const CUSTOMIZATION_EQUIPMENT_TYPE_ATTRIBUTES_VALUES_ROWS = {
    EQUIPMENT_TYPE_ATTRIBUTES_VALUES_ROWS: [
        {
            id: 1,
            Value: 'T1',
        },
        {
            id: 2,
            Value: 'T2',
        }
    ]
}