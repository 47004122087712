
import { Button } from 'primereact/button';
import React, { memo, useRef, useState } from 'react'
import { useNavigate } from "react-router-dom";
//components
import Breadcrumb from "components/common/Breadcrumb/Breadcrumb";
import Contract from './Contract/Contract';
import Question from './Question/Question';
import System from './System/System';
import { APP_ROUTES } from "constants/AppRoutes";
//redux
import { useDispatch } from 'react-redux';
//icon
import { BsArrowRight } from "react-icons/bs";

interface InspectionFieldsPropTypes { }
const InspectionFields: React.FC<InspectionFieldsPropTypes> = (props) => {
    const { } = props;
    const dispatch = useDispatch<any>();
    const [navKey, setNavKey] = useState('contract');
    const questionRef = useRef<any>(null);
    const navigate = useNavigate();

    return (

        <div className='inspection-fields'>
            <section className="d-flex align-items-center">
                <Breadcrumb title="Inspection Settings" classNames="report-header" handleGoBackClick={() => navigate(`/${APP_ROUTES.MANAGE_VIEW}`)} />
                <div className="breadcrumb-right-arrow ml-3 mr-3">
                    <BsArrowRight className="icon" />
                </div>
                <div className="breadcrumb-sub-page-title">
                    <span>Inspection Fields</span>
                </div>
            </section>
            {/* header start */}
            <div className="header-wrapper">
                <div className="btn-wrapper-left">                   
                    <Button className={`box-btn ${navKey === "contract" && "active"}`} onClick={() => setNavKey('contract')} >Contract</Button>
                    <Button className={`box-btn ${navKey === "system" && "active"}`} onClick={() => setNavKey('system')}>System</Button>
                    <Button className={`box-btn ${navKey === "question" && "active"}`} onClick={() => setNavKey('question')}>Questions</Button>
                </div>
                <div className="btn-wrapper-right">
                    {navKey === "question" &&
                        <Button className="box-btn" onClick={() => questionRef?.current?.requestSubmit()}>Set as Default</Button>
                    }
                </div>
            </div>
            <>
                {navKey === 'contract' ? (
                    <Contract />
                ) : navKey === 'system' ? (
                    <System />
                ) : (
                    <Question ref={questionRef}/>
                )}
            </>
        </div>
    );
};

export default memo(InspectionFields);
