import React from 'react'

const ReportsExpiredContractsDocument = () => {
  return (
    <>
    <h4 className="doc-title mb-2">
      Expired Contracts
    </h4>
    
    <p>
      This report shows the list of Expired Contract details, 
      which have active or inactive Tasks with Customer Name, Contract Name, 
      Inspection Task Name, Task Status, Contract Expired Date, 
      Inspection Start Date and Inspection End Date from the list. 
      This report is only accessible to Super Administrators.
    </p>
  </>
  );
}
export default ReportsExpiredContractsDocument;
