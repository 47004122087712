import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { classNames } from "primereact/utils";
import { ScrollPanel } from 'primereact/scrollpanel';
import { BsExclamationCircleFill } from 'react-icons/bs';
import { InputTextarea } from "primereact/inputtextarea";
import { useAppSelector } from "redux/hooks";
import { useDispatch } from "react-redux";
import { UpdatesComments, addComments, getProjectComments, setComment, setTabChangeMessage, setTabChangeMessageHandler, setcheckIsValueChanged } from "redux/pages/Reports/ProvenOutcomesSlice";
import InformationBox from "components/common/Message/InformationBox/InformationBox";



interface Props {
	selectedProjectId: any;
	isAdd:boolean;
}
type FormData = {

	Comments: string;
};

export const Comments= forwardRef<any, Props>((props, ref) => {

	const {selectedProjectId = "",isAdd} = props;
	const [commentText, setCommentText] = useState('');
	const [displayInfoModal, setDisplayInfoModal] = useState(false);
    const [displayConfirmModal, setDisplayConfirmModal] = useState(false);
	const [message, setMessage] = useState("");
	const commentDetailsForm: any = useRef(null);

	const newlyAddedData = useAppSelector((state) => state.provenOutcomes.newlyAddedData);

	useImperativeHandle(ref, () => ({
		requestSubmit: () => {
			commentDetailsForm?.current?.requestSubmit();
		},
	}));

	const commnentList = useAppSelector((state) => state.provenOutcomes.comments);

	const dispatch = useDispatch<any>();

	const {
		control,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm<FormData>({
		defaultValues: {
			Comments: "",
		},
	});
	useEffect(() => {     
		if (commnentList?.length) {
			setValue("Comments",commnentList);
		}
		dispatch(setComment(''));
	}, [commnentList]);

	  useEffect(() => {
			  dispatch(getProjectComments(selectedProjectId, setMessage, setDisplayInfoModal));
	  }, [])

	const onCommentAddEdit = (Comment: any) => {
		if(isAdd){
		  let data = {
			comment:(Comment.Comments).trim(),
			uniqueProjectId:newlyAddedData?.uniqueProjectId
		  }
		  setCommentText(data.comment);
		  setDisplayConfirmModal(true);
		  dispatch(setTabChangeMessageHandler(true))
		  dispatch(setTabChangeMessage('Comments saved'))
		  dispatch(addComments(data,setMessage));	
	  }
	  else{
		let data = {
			comment:(Comment.Comments).trim(),
			uniqueProjectId:selectedProjectId
		  }
		setCommentText(data.comment);
		setDisplayConfirmModal(true);
		dispatch(setTabChangeMessageHandler(true))
		dispatch(setTabChangeMessage('Comments updated'))
		dispatch(UpdatesComments(data,setMessage));	
	  }
	  dispatch(setcheckIsValueChanged(false))
	}
	

	const getFormErrorMessage = (name) => {
		return (
			errors[name] && (
				<span className="tooltip-text">
					<BsExclamationCircleFill />
					{errors[name].message}
				</span>
			)
		);
	};


	return (
		<div className="step-item">
			<h5 className="title">Comments</h5>
			{}

			<div className="content-area">
				<ScrollPanel>
				<form onSubmit={handleSubmit(onCommentAddEdit)} ref={commentDetailsForm}>
					<div className="comments custom-float-field">
						<div className="row">
							<div className="field col-12">
								<span className="p-float-label">
									<Controller
										name="Comments"
										control={control}
										rules={{
											required: "Comments is required.",
											validate: (value) => {
												return value.trim().length <= 0
													? "This field is required" : true
											},										
										}}
										render={({ field, fieldState }) => (
											<InputTextarea
												id="Textarea"
												
												className={classNames("w-100 error-tooltip", {
													error: fieldState.invalid,	
																								
												
												})}
												{...field}
												onChange={(e) => {
													field.onChange(e.target.value);
													if((e.target.value.length) > 0) {
														dispatch(setcheckIsValueChanged(true))}
													else{
														dispatch(setcheckIsValueChanged(false))
													}
												}}
											/>
										)}
									/>
									<label className="mandatory" htmlFor="inputtext">
										Comments
									</label>
									{getFormErrorMessage("Comments")}
								</span>
							</div></div>
					</div>
					</form>
					<InformationBox showInfoModal={displayInfoModal} setShowInfoModal={setDisplayInfoModal} message={message} />
				</ScrollPanel>
			</div>
		</div>
	);
});

export default Comments;