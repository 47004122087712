import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { Dropdown } from 'primereact/dropdown';
import { ScrollPanel } from 'primereact/scrollpanel';
import { BsExclamationCircleFill } from 'react-icons/bs';
import { Checkbox } from "primereact/checkbox";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { useAppSelector } from "redux/hooks";
import { getProjectDetails, getSystemTaskList, onAddProjectDetails, onUpdateProjectDetails, setSystemTaskList, setTabChangeMessage, setTabChangeMessageHandler, setTapPerformanceReportList, setTapPerformanceReportListPageDetails, setcheckIsValueChanged, } from "redux/pages/Reports/ProvenOutcomesSlice";
import InformationBox from "components/common/Message/InformationBox/InformationBox";
import { useDispatch } from "react-redux";


interface ProjectDetailsProp {
isAdd:boolean;
selectedProjectId?: any;
}

type FormData = {
	hyperLink: string;
	description: string;
	startDate: any;
	endDate: any;
	projectName: string;
	projectOwner: string;
	email: string;
	branchOfficeId: string;
	systemTasks: any;
};

export const ProjectDetails = forwardRef<any, ProjectDetailsProp>((props, ref) => {

	const {isAdd,selectedProjectId } = props;
	const branches = useAppSelector((state) => state.provenOutcomes.branches);
	const systemtasklist = useAppSelector((state) => state.provenOutcomes.systemtasklist);
	const [message, setMessage] = useState("");
	const [branchLookup, setBranchLookup] = useState<any>();
	const projectDetailsForm: any = useRef(null);
	const [systemTaskLookup, setSystemTaskLookup] = useState<any>();
	const [additionalMessage, setAdditionalMessage] = useState("");
	const [displayInfoModal, setDisplayInfoModal] = useState(false);
	const [selectedSystemTask, setSelectedSystemTask] = useState<any>();
	const projectDetail = useAppSelector((state) => state.provenOutcomes.projectDetails);
	const [newProjectData, setNewProjectData] = useState<any>();
	const [isBranchDisabled, setisBranchDisabled] = useState<boolean>(false);
	const dispatch = useDispatch<any>();

	const tapPerformanceReportList = useAppSelector((state) => state.provenOutcomes.tapPerformanceReportList);
	const newlyAddedData = useAppSelector((state) => state.provenOutcomes.newlyAddedData);
	const tapPerformanceReportPageDetails = useAppSelector((state) => state.provenOutcomes.tapPerformanceReportPageDetails);
	
	const {
		control,
		handleSubmit,
		setValue, 
		formState: { errors },
	} = useForm<FormData>({
		defaultValues: {
			startDate: null,
			endDate: "",
			projectName: "",
			projectOwner: "",
			email: "",
			branchOfficeId: "",
			systemTasks: [],
		},
	});

	

	useImperativeHandle(ref, () => ({
		requestSubmit: () => {
			projectDetailsForm?.current?.requestSubmit();
		},
	}));

	const setDate = useCallback(() => {
		let todayDate = new Date()
		let day = todayDate.getDate();
		let month = todayDate.getMonth();
		let year = todayDate.getFullYear();
		setValue("startDate", new Date(year, month - 1, day));
		setValue("endDate", new Date(year, month , day));
		setValue("branchOfficeId", "--Select--");

	}, [setValue])

	useEffect(() => {
		if (newProjectData) {
			let projectList: { branch: string; uniqueProjectId: string; projectOwner: string; projectName: string; }[] = [];
			projectList.push({
				branch: newProjectData.branchName,
				uniqueProjectId: newProjectData.uniqueProjectId,
				projectOwner: newProjectData.projectOwner,
				projectName: newProjectData.projectName
			})

			tapPerformanceReportList?.map((item: any) => {
				projectList.push({
					branch: item.branch,
					uniqueProjectId: item.uniqueProjectId,
					projectOwner: item.projectOwner,
					projectName: item.projectName
				})
			})

			dispatch(setTapPerformanceReportList(projectList));
			if (tapPerformanceReportPageDetails == null) {
				let projectDetail: { currentLength: any; currentStart: any; pageCount: any; totalCount: any; } = {
					currentLength: 1,
					currentStart: 1,
					pageCount: 100,
					totalCount: 1
				};
				dispatch(setTapPerformanceReportListPageDetails(projectDetail))
			}
		}

	}, [newProjectData]);

		useEffect(() =>{

     if(!isAdd)
		{
			 setSystemTaskLookup([]);
			 dispatch(getProjectDetails(selectedProjectId));
		}
	},[])

	useEffect(() =>{
		if(newlyAddedData)
		{
			setValue("branchOfficeId", newlyAddedData?.uniqueBranchOfficeId);	
			setValue("email", newlyAddedData.email);
			setValue("projectOwner", newlyAddedData?.projectOwner);
			setValue("projectName", newlyAddedData?.projectName);
			setValue("startDate",new Date (newlyAddedData?.startDate));
			setValue("endDate",new Date (newlyAddedData?.endDate));
		}
	   },[newlyAddedData])

	useEffect(() => {
		if (!isAdd) {
			setisBranchDisabled(true)
		}
		if (newlyAddedData) {
			setisBranchDisabled(true)
		}
	}, [newlyAddedData, isAdd])

		
	useEffect(() => {
		if(!isAdd)
		{
		if (projectDetail?.length != 0) {
		  setValue("projectName", projectDetail?.projectName);
		  setValue("projectOwner", projectDetail?.projectOwner);
		  setValue("email", projectDetail?.email);
		  setValue("branchOfficeId", projectDetail?.uniqueBranchOfficeId);
		  setValue("startDate",new Date(projectDetail?.startDate));
		  setValue("endDate",new Date(projectDetail?.endDate));
		}
	}
	  }, [projectDetail, setValue]);


useEffect(() => {
	if(isAdd &&  !newlyAddedData) {
	setDate();
	}
	if (branches?.length) {		
	let branch: { name: string; branchOfficeId: string }[] = [];
			branch.push({ name: "--Select--", branchOfficeId: "--Select--" });
			branches?.map((item: any) => {
				branch.push({
					name: item.name,
					branchOfficeId: item.uniqueBranchOfficeId,
				});

				return null;
			});
			setBranchLookup(branch);
		}
	}, [branches])

	useEffect(() => {
		if(isAdd && !newlyAddedData){
		if (systemtasklist?.length != 0) {
			let systemtask: { isSelected: boolean; systemName: string; systemId: string; tasklist: any; }[] = [];
			systemtasklist?.map((item: any) => {
				let inspectionTask: { taskName: string, taskId: string,isSelected : boolean}[] = [];
				inspectionTask.push({ taskName: "--Select--", taskId: "--Select--",isSelected:false});

				item.inspectionTaskList?.map((task: any) => {
					inspectionTask.push({
						taskName: task.inspectiponTaskName,
						taskId: task.inspectionTaskId,
						isSelected : false
					});
				});
				systemtask.push({
					isSelected: false,
					systemName: item.systemName,
					systemId: item.systemId,
					tasklist: inspectionTask,
				});

	  		 return null;
			}); 
		setSystemTaskLookup(systemtask)
		}
		else
		{
			setSystemTaskLookup([])
		}

	}

	},[systemtasklist])

	useEffect(() => {
		if(isAdd){
		if (newlyAddedData) {
			let systemtask: { isSelected: boolean; systemName: string; systemId: string; tasklist: any; }[] = [];
			newlyAddedData.systemInspectionTaskList?.map((item: any) => {
				let inspectionTask: { taskName: string, taskId: string,isSelected : boolean}[] = [];
				inspectionTask.push({ taskName: "--Select--", taskId: "--Select--",isSelected:false});

				item.inspectionTaskList?.map((task: any) => {
					inspectionTask.push({
						taskName: task.inspectiponTaskName,
						taskId: task.inspectionTaskId,
						isSelected : task.isTaskSelected
					});
				});
				systemtask.push({
					isSelected: item.isSystemSelected,
					systemName: item.systemName,
					systemId: item.systemId,
					tasklist: inspectionTask,
				});

	  		 return null;
			}); 
		setSystemTaskLookup(systemtask)
		}
	}

	},[newlyAddedData])

	useEffect(() => {

		if (!isAdd) {

			if (projectDetail?.length != 0) {
				let systemtask: { isSelected: boolean; systemName: string; systemId: string; tasklist: any; }[] = [];
				let selectedTasks: { systemId: string, inspectionTaskId: string }[] = [];
				projectDetail.systemInspectionTaskList.map((data: any) => {
					let inspectionTask: { taskName: string, taskId: string, isSelected: boolean }[] = [];
					inspectionTask.push({ taskName: "--Select--", taskId: "--Select--", isSelected: false });

					data.inspectionTaskList?.map((task: any) => {
						inspectionTask.push({
							taskName: task.inspectiponTaskName,
							taskId: task.inspectionTaskId,
							isSelected: task.isTaskSelected
						});
						if (task.isTaskSelected) {
							selectedTasks.push(

								{
									systemId: data.systemId,
									inspectionTaskId: task.inspectionTaskId
								}
							)
						}
					});
					systemtask.push({
						isSelected: data.isSystemSelected,
						systemName: data.systemName,
						systemId: data.systemId,
						tasklist: inspectionTask,
					});


				});

				setSystemTaskLookup(systemtask)
				setSelectedSystemTask(selectedTasks);
			}
		}
	}, [projectDetail]);

	useEffect(() => {
		if (!isAdd) {
			if (systemTaskLookup?.length) {
				systemTaskLookup.map((systemItem) => {
					systemItem.tasklist.map((taskItem) => {
						if (taskItem.isSelected) {
							setValue(systemItem?.systemId.toString(), taskItem?.taskId)
						}
					})
				})

			}
		}

	}, [systemTaskLookup])


	const onBranchChange = (data) => {
		setSystemTaskLookup([])
		setSelectedSystemTask([])
		if (data != "--Select--") {
			dispatch(getSystemTaskList(data))
		}
		if (systemTaskLookup?.length) {
			systemTaskLookup.map((systemItem) => {
				systemItem.tasklist.map((taskItem) => {
					setValue(systemItem?.systemId.toString(), "--Select--")
				})
			})
		}
	}

	useEffect(() => {
		if (isAdd) {
			if (systemTaskLookup?.length) {
				systemTaskLookup.map((systemItem) => {
					systemItem.tasklist.map((taskItem) => {
						if (taskItem.isSelected) {
							setValue(systemItem?.systemId.toString(), taskItem?.taskId)
						}
					})
				})
			}
		}
	}, [systemTaskLookup])

	const onFormSubmit = (data: any) => {
		if (!selectedSystemTask?.length) {
			setDisplayInfoModal(true)
			setMessage("Atleast one task need to be selected for the Project")
		}
		else if (new Date(data.startDate) > new Date(data.endDate)) {
			setDisplayInfoModal(true)
			setMessage("End Date should be greater than Start Date")
		}
		else {
			let submitData: {
				projectName: string;
				ProjectOwner: string;
				endDate: any;
				startDate: any;
				email: string;
				selectedTaskList: any;
				branchId: string
			} = {
				projectName: data.projectName?.trim(),
				ProjectOwner: data.projectOwner?.trim(),
				endDate: moment(data?.endDate).format("yyyy-MM-DD").toString(),
				startDate: moment(data?.startDate).format("yyyy-MM-DD").toString(),
				email: data.email?.trim(),
				selectedTaskList: selectedSystemTask,
				branchId: data.branchOfficeId?.trim()
			};

			onAddUpdateProjectDetailsClick && onAddUpdateProjectDetailsClick(submitData);
			dispatch(setcheckIsValueChanged(false))
		}
	}

	const onAddUpdateProjectDetailsClick = (submitData: any) => {
		submitData = { ...submitData, "projectName": submitData.projectName.replace(/\s+/g, ' ')?.trim() };
		isAdd?
		dispatch(
			onAddProjectDetails(
				submitData,
				setMessage,
				setNewProjectData
			)
		):dispatch(
			onUpdateProjectDetails(
				selectedProjectId,
				false,
				submitData,
				setMessage,				
			));
	};
	const getFormErrorMessage = (name) => {
		return (
			errors[name] && (
				<span className="tooltip-text">
					<BsExclamationCircleFill />
					{errors[name].message}
				</span>
			)
		);
	};

	const getSelectedSystemTask = (selectedSystem, taskId) => {
		if(!isAdd)
		{
			selectedSystem.tasklist.forEach(i => {
				i.isSelected = false
			  });

		}
		let selectedTask: { systemId: string, inspectionTaskId: string }[] = [];
		selectedSystemTask?.map((item: any) => {
			if (!(item.systemId == selectedSystem.systemId)) {
				selectedTask.push({
					systemId: item.systemId,
					inspectionTaskId: item.inspectionTaskId
				});
			}
		});

		let systemtask: { isSelected: boolean; systemName: string; systemId: string; tasklist: any ; }[] = [];
		systemTaskLookup?.map((item: any) => {

			if (item.systemId == selectedSystem.systemId && taskId != "--Select--") {
				systemtask.push({
					isSelected: true,
					systemName: item.systemName,
					systemId: item.systemId,
					tasklist: item.tasklist,

				});
			}
			else if (item.systemId == selectedSystem.systemId && taskId == "--Select--") {
				systemtask.push({
					isSelected: false,
					systemName: item.systemName,
					systemId: item.systemId,
					tasklist: item.tasklist,
				});
			} else {
				systemtask.push({
					isSelected: item.isSelected,
					systemName: item.systemName,
					systemId: item.systemId,
					tasklist: item.tasklist,


				});
			}
			return null;
		});
		setSystemTaskLookup(systemtask);

		if (taskId != "--Select--") {
			selectedTask.push({
				systemId: selectedSystem.systemId,
				inspectionTaskId: taskId
			});
			dispatch(setcheckIsValueChanged(true))
		}
		setSelectedSystemTask(selectedTask);
		
	};

	const CustomDropDown = (props,key) =>{
    return (
		<div key={key} className="item">
				<div className="field-checkbox">
					<Checkbox
						inputId={props.systemId}
						checked={props.isSelected}
						name  = {props.systemName}
						disabled={true}
					/>
					<label>{props.systemName} </label>
				</div>
				<Controller
					name={props.systemId.toString()}
					key={key}
					control={control}

					defaultValue={props?.tasklist[0].taskId}
					render={({ field, fieldState }) => (
						<div className="field-select">
							{props?.tasklist &&
								<Dropdown
									className={classNames("w-100 error-tooltip", {
										error: fieldState.invalid,
									})}
									inputId={field.name}
									{...field}
									value={field.value}
									onChange={(e) => {
										field.onChange(e.value);
										getSelectedSystemTask(props, e.value)

									}}
									options={
										props?.tasklist
									}

									optionLabel="taskName"
									optionValue="taskId"
								/>}
						</div>
					)}
				/>
			</div>
	)
	}
	



	return (

		<div className="step-item">
			<h5 className="title">Project Details</h5>
			<div className="content-area">
				<ScrollPanel>
					<form onSubmit={handleSubmit(onFormSubmit)} ref={projectDetailsForm}>
						<div className="project-details custom-float-field">
							<div className="row">
								<div className="field col-12">
									<span className="p-float-label">
										<Controller
											name="projectName"
											control={control}
											rules={{ required: "Project Name is required.",
											validate: (value) => {
												return value.trim().length<=0
												  ?"Project Name is required.":true
											  },
										
										}}
											
											render={({ field, fieldState }) => (
												<InputText
													id="projectName"
													maxLength={250}
													className={classNames("w-100 error-tooltip", {
														error: fieldState.invalid
													})}
													{...field}
													onChange={(e) => {														
														field.onChange(e.target.value);
														if((e.target.value.length) > 0) {
															dispatch(setcheckIsValueChanged(true))}
														else{
															dispatch(setcheckIsValueChanged(false))
														}
														
													}}
												/>
											)}
										/>
										<label className="mandatory" htmlFor="inputtext">
											Project Name
										</label>
										{getFormErrorMessage("projectName")}
									</span>
								</div>
								<div className="field col-12">
									<span className="p-float-label">
										<Controller
											name="projectOwner"
											control={control}
											rules={{ required: "Project Owner is required.",
											validate: (value) => {
												return value.trim().length<=0
												  ?"Project Owner is required.":true
											  },
										}}
											render={({ field, fieldState }) => (
												<InputText
													id="projectOwner"
													maxLength={250}
													className={classNames("w-100 error-tooltip", {
														error: fieldState.invalid,
													})}
													{...field}
													onChange={(e) => {
														field.onChange(e.target.value);
														if((e.target.value.length) > 0) {
															dispatch(setcheckIsValueChanged(true))}
														else{
															dispatch(setcheckIsValueChanged(false))
														}
													}}
												/>
											)}
										/>
										<label className="mandatory" htmlFor="inputtext">
											Project Owner
										</label>
										{getFormErrorMessage("projectOwner")}
									</span>
								</div>
								<div className="field col-12">
									<span className="p-float-label">
										<Controller
											name="email"
											control={control}
											rules={{
												required: 'Email is required.',
												pattern: { value: /^\s*[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\s*$\s*/i, message: 'Enter a valid Email' }
											}}
											render={({ field, fieldState }) => (
												<InputText
													id="email"
													className={classNames("w-100 error-tooltip", {
														error: fieldState.invalid,
													})}
													{...field}
													onChange={(e) => {
														field.onChange(e.target.value);		
														let pattern = RegExp(/^\s*[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\s*$\s*/i);
														let input = e.target.value;
														if (pattern.test(input)) {
															dispatch(setcheckIsValueChanged(true))
														}
														else {
															dispatch(setcheckIsValueChanged(false))
														}
													}}
												/>
											)}
										/>
										<label className="mandatory" htmlFor="inputtext">
											Email
										</label>
										{getFormErrorMessage("email")}
									</span>
								</div>
								<div className="field col-12">
									<span className="p-float-label">
										<Controller
											name="branchOfficeId"
											control={control}
											rules={{
												required: "Branch is required.",
												validate: (value) => {
													return value === "--Select--" ? "Select a branch" : undefined;
												}
											}}
											render={({ field, fieldState }) => (
												<Dropdown
													className={classNames("w-100 error-tooltip", {
														error: fieldState.invalid,
													})}
													{...field}
													value={field.value}
													disabled = {isBranchDisabled}
													onChange={(e) => {
														field.onChange(e.value);														
														onBranchChange((e.value))
													}}
													options={branchLookup}
													optionLabel="name"
													optionValue="branchOfficeId"
												/>
											)}
										/>
										<label className="mandatory" htmlFor="inputtext">
											Branch
										</label>
										{getFormErrorMessage("branchOfficeId")}
									</span>
								</div>
								<div className="field col-12">
									<div className="border-box">
										<label className="box-title" htmlFor="radiobutton">
											System
										</label>
										<InformationBox
											showInfoModal={displayInfoModal}
											setShowInfoModal={setDisplayInfoModal}
											message={message}
											setAdditionalMessage={setAdditionalMessage}
										/>
										<ScrollPanel>
										
											{systemTaskLookup && systemTaskLookup.length
												?
												systemTaskLookup.map((propsItem: any, key: any) => (
													<span>{CustomDropDown(propsItem,key)}</span>

												)) : <span>No Systems Found</span>}

										</ScrollPanel>
									</div>
								</div>
								<div className="field part-col col-12">
									<span className="p-float-label left">
										<Controller
											name="startDate"
											control={control}
											rules={{
												required: 'Start Date is required.'
											}}
											render={({ field, fieldState }) => (
												<Calendar
												    appendTo="self"
													id="startDate"
													className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })}
													dateFormat="yy-mm-dd"
													readOnlyInput
													{...field}
													value={field.value}
													onChange={(e) => {field.onChange(e.value);
														dispatch(setcheckIsValueChanged(true))
													}
													}
												/>
											)} />

										<label className="mandatory" htmlFor="calendar">
											Start Date
										</label>
										{getFormErrorMessage("startDate")}
									</span>
									<span className="p-float-label right">
										<Controller
											name="endDate"
											control={control}
											rules={{
												required: 'End Date is required.'
											}}
											render={({ field, fieldState }) => (
												<Calendar
												    appendTo="self"
													id="endDate"
													className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })}
													dateFormat="yy-mm-dd"
													readOnlyInput
													{...field}
													value={field.value}
													onChange={(e) => {field.onChange(e.value);
														dispatch(setcheckIsValueChanged(true))}} />
											)} />
										<label className="mandatory" htmlFor="calendar">
											End Date
										</label>
										{getFormErrorMessage("endDate")}
									</span>
								</div>
							</div>
						</div>
					</form>
				</ScrollPanel>
			</div>
		</div>
	);
})

export default ProjectDetails;




