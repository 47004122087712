import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import Table from "components/Table/Table";
import {ContractTableData } from './ContractTableData';
import EditContract from "./ManageContract/EditContract";
import AddContract from "./ManageContract/AddContract";
import { useAppSelector } from 'redux/hooks';
import { checkContractInUse, deleteContractData, getContractList, getContractSearchList, onAddContract, onEditContract,checkContractForClose,onCloseContract,onActivateContract, setCheckContractClose} from 'redux/pages/Inspection/Contracts/contractSlice';
import { useDispatch } from 'react-redux';
import InformationBox from 'components/common/Message/InformationBox/InformationBox';
import ConfirmationBox from 'components/common/Message/ConfirmationBox/ConfirmationBox';
import { SiteModalContract } from './ManageContract/SiteModal/ContractSiteModal'
import { showUI } from 'service/GeneralUtility';
import ContractReport from 'pages/Contracts/Report/ContractReport';
import PopOver from 'components/common/PopOver/PopOver';
import { OverlayPanel } from 'primereact/overlaypanel';
import { onExportReport } from 'redux/common/report/customizeReportSlice';
interface Props {

}

export const Contracts: React.FC<Props> = () => {

    const contractList = useAppSelector((state) => state.contract.contractList);
    const contractPageDetails = useAppSelector((state) => state.contract.contractPageDetails)
    const contractInUse = useAppSelector((state) => state.contract.contractInUse);
    const contractSearchId = useAppSelector((state) => state.contract.contractSearchId)
    const newContractData = useAppSelector((state) => state.contract.newContractData);
    const loggedInUserData = useAppSelector((state) => state.administration.loggedInUserDetails);
    const userPrivileges = useAppSelector((state) => state.user.userPrivileges);
    const checkContractClose = useAppSelector((state)=>state.contract.checkContractClose);
    const rowsPerPage = useAppSelector((state) => state.personalSettings.rowsPerPage);
    
    const [pageDetails, setPageDetails] = useState<any>({});
    const [tableColumns, setTableColumns] = useState<Array<any>>([]);
    const [showContractGrid, setShowContractGrid] = useState(false)
    const [showSiteModal, setShowSiteModal] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [pageIndex, setPageIndex] = useState(0)
    const [filterExpanded] = useState<any>(true);
    const [selectedRows, setSelectedRows] = useState<any>([]);
    const [displayInfoModal, setDisplayInfoModal] = useState(false);
    const [displayConfirmModal, setDisplayConfirmModal] = useState(false);
    const [message, setMessage] = useState("");
    const [isDelete, setIsDelete] = useState(false)
    const [isSearch, setIsSearch] = useState(false);
    const [currentStart, setCurrentStart] = useState(1);
    const [sortField, setSortField] = useState('');
    const [sortOrder, setSortOrder] = useState('');
    const [keyWord, setKeyWord] = useState('');
    const [selectedContract, setSelectedContract] = useState<any>([]);
    const [isSiteMapping, setIsSiteMapping] = useState(false);
    const [isAddContract, setIsAddContract] = useState(false);
    const [countryId, setCountryId] = useState<any>(220)
    const [settingListItems,setSettingListItems]=useState<any>()
    const [showReportModal, setShowReportModal] = useState(false);
    const [reportFlag, setReportFlag] = useState<any>('all');
    const [isClose, setIsClose] = useState(false)
    const [isActivate, setIsActivate] = useState(false)

    const dispatch = useDispatch<any>();
    const downloadRef = React.createRef<OverlayPanel>();

    useEffect(() => {
        if (contractSearchId !== "") {
            setIsSearch(true)
            dispatch(getContractSearchList(setShowContractGrid, 1, contractSearchId))
        }
        else if (!isSearch)
            dispatch(getContractList(setShowContractGrid, 1, keyWord, sortField, sortOrder))
    }, [dispatch, contractSearchId,isSearch, rowsPerPage])

    useEffect(() => {
        if (contractPageDetails) {
          setPageDetails(contractPageDetails);
        }
      }, [contractPageDetails]);

      useEffect(() => {
        if (rowsPerPage && rowsPerPage > 0) {
          let modifiedPageDetails = { ...pageDetails, 'currentLength': rowsPerPage, 'pageCount': rowsPerPage };
          setPageDetails(modifiedPageDetails);
          setCurrentStart(1)
          setPageIndex(0)
        }
      }, [rowsPerPage]);

    const onPageChanged = (options: any) => {
        if (options !== pageIndex) {
            let currentStart = (options * pageDetails?.currentLength) + 1;
            setCurrentStart(currentStart);
            setPageIndex(options);
            dispatch(getContractList(setShowContractGrid, currentStart, keyWord, sortField, sortOrder));
        }
    }

    const onGlobalSearch = (filter) => {
        setKeyWord(filter)
        dispatch(getContractList(setShowContractGrid, 1, filter, sortField, sortOrder))
    }

    const onSort = (event) => {
        let sortDirection: string;
        if (event.sortOrder === 1)
        sortDirection = "ASC"
        else
        sortDirection = "DESC"
        setSortField(event.sortField)
        setSortOrder(sortDirection)
        dispatch(getContractList(setShowContractGrid, currentStart, keyWord, event.sortField, sortDirection))
    }

    useEffect(() => {
        if (loggedInUserData) {
            setCountryId(loggedInUserData.userDefaults?.countryId)
        }
    }, [loggedInUserData])
     
    useEffect(()=>{
        if(userPrivileges && userPrivileges.length){
          filterSettingItemList();
        }
      },[userPrivileges, selectedRows])
    
      const filterSettingItemList =()=>{
           const settingList:any = [];
          userPrivileges.map(element => {
              if (element === 'SIETAP_CONTRACTS_SITEMAPPING') {
                  settingList.push({
                      id: 1,
                      name: 'Activate',
                      onClick: () => {onActivateContractClick() }
                  },
                      {
                          id: 2,
                          name: 'Close',
                          onClick: () => { onCloseContractClick()}
                      },
                      {
                          id: 3,
                          name: 'Sites',
                          onClick: () => { onSiteIconClick(selectedRows) }
                      })
              }         
          });
          setSettingListItems(settingList)    
      }
    useEffect(() => {
        if (contractInUse !== "" && isDelete === true){
            if (contractInUse?.isContractInUse){
                setMessage("Inspection Task(s) exist for the selected Contract, cannot be deleted")
                setDisplayInfoModal(true);
                setIsDelete(false)
            }else{
                setMessage("Are you sure you want to delete the selected Contract?");
                setDisplayConfirmModal(true);
            }
        }

    }, [contractInUse, isDelete])

    const onAddContractClick = (submitData: any) => {
        setIsAddContract(true);
        dispatch(
          onAddContract(
            submitData,
            setMessage,
            setDisplayInfoModal,
            setShowAddModal,
            setShowContractGrid
          ))
      }

    useEffect(() => {
       if (newContractData && isAddContract) {
            setIsSiteMapping(true)
            setDisplayInfoModal(false);
            setMessage("Do you want to add Site(s) to this Contract?")
            setIsAddContract(false);
            setDisplayConfirmModal(true);
            setSelectedContract(newContractData)
            setSelectedRows([newContractData])
        }
    }, [newContractData])

    const handleRowSelect = useCallback((data: any) => {
        setSelectedRows([...data]);
      }, []);

      const handleTableColumns = useCallback((data: any) => {
        setTableColumns([...data]);
      }, []);
   

    const editIconClick = () => {
        if (selectedRows.length < 1) {
            setMessage("Select a Contract");
            setDisplayInfoModal(true);
        }else if (selectedRows.length > 1){
            setMessage("This operation can be performed only one row at a time");
            setDisplayInfoModal(true);
        }else{
            switch (selectedRows[0].status) {
                case "Closed":
                    setMessage("Selected Contract is closed, cannot be edited");
                    setDisplayInfoModal(true);
                    break;
                default:
                    setShowEditModal(true);
                    break;
            }
        }
      }
    
    const onEditContractClick = (submitData: any) => {
        let uniqueContractId: any;
        if (submitData.uniqueContractId) 
            uniqueContractId = submitData.uniqueContractId;
        else
            uniqueContractId = selectedRows[0].uniqueContractId
        if (!submitData.salesOffice){
            submitData.salesOffice = submitData.salesOfficeName;}
        submitData.contractName = submitData.contractName?.trim();
        submitData.notes = submitData.notes?.trim();
        if( moment(submitData?.startDate).format("YYYY-MM-DD").toString()!==submitData.startDate)
        {
            submitData.startDate =moment(submitData?.startDate).format("YYYY-MM-DD").toString();
        }
        if( moment(submitData?.endDate).format("YYYY-MM-DD").toString()!==submitData.endDate)
        {
            submitData.endDate =moment(submitData?.endDate).format("YYYY-MM-DD").toString();
        }
        dispatch(
            onEditContract(
                submitData,
                uniqueContractId,
                setMessage,
                setDisplayInfoModal,
                setShowEditModal,
                setSelectedRows
            ))
    }

    const onRowEditClick = (rowData) =>{
        let isEditable = true;
        switch (rowData.status) {
            case "Closed":
                setMessage("Selected Contract is closed, cannot be edited");
                setDisplayInfoModal(true);
                isEditable = false;
                break;
            default:
                isEditable = true;
                break;
        }
        return isEditable;
    }

    const onRowEditValidation = (rowData: any) => {
        let value = true;
        
        if (!rowData.contractName || !rowData.contractNumber || !rowData.customerName ||
            !rowData.salesOfficeName || !isPatternValid(rowData.contractNumber) || !isLengthValid(rowData.contractName)) {
          value = false;
        }
        if (new Date(rowData.startDate) > new Date(rowData.endDate)) {
            setDisplayInfoModal(true)
            setMessage("End Date should be greater than Start Date")
            value = false;
        } 
        if (new Date(rowData.endDate) <= new Date()) {
            setDisplayInfoModal(true)
            setMessage("End Date should be a future date")
            value = false;
        } 
        return value;
      }

      const isPatternValid = (contractNumber) => {
        if (!contractNumber) {
          return true;
        }
        if (countryId === 220) {
            return /^([5]\d{9}|44OP[-]\d{6}|26\d{8}|44OC[-]\d{7})$/.test(contractNumber);
        } else {
            return /^(([4]\d{7})|((60|13)\d{5})|([a-zA-Z][-][0-9]{2}[-][a-zA-Z][-][0-9]{4})|([a-zA-Z][-]SI[-][0-9]{5}))$/.test(contractNumber);
        }
      }

    const isLengthValid = (contractName) => {
        if (!contractName) {
            return true;
        } else {
            if(contractName?.length > 100) {
                return false;
            } else {
                return true;
            }
        }
    }

    const deleteIconClick = () => {
        if (selectedRows.length < 1) {
            setMessage("Select a Contract");
            setDisplayInfoModal(true);
        }
        else if (selectedRows.length === 1) {
            switch (selectedRows[0].status) {
                case "Active":
                    setMessage("Selected Contract is active, cannot be deleted");
                    setDisplayInfoModal(true);
                    break;
                default:
                    setIsDelete(true);
                    dispatch(checkContractInUse(selectedRows[0].uniqueContractId));
                    break;
            }
        }
        else if (selectedRows.length > 1) {
                setMessage("This operation can be performed only one row at a time");
                setDisplayInfoModal(true);
        }

    };

    const onSiteIconClick = (selectedRows) => {
        if (selectedRows.length < 1) {
            setMessage("Select a Contract");
            setDisplayInfoModal(true);
        }
        else if (selectedRows.length === 1) {
            setSelectedContract(selectedRows[0])
            setShowSiteModal(true)
        }
        else if (selectedRows.length > 1) {
            setMessage("This operation can be performed only one row at a time");
            setDisplayInfoModal(true);
        }
    }

    const onUpdateContractSite = () => {
        setShowSiteModal(false);
        setMessage("Site(s) updated");
	    setDisplayInfoModal(true);
    }

    const onConfirmClick = () => {
        if (isSiteMapping) {
            setDisplayConfirmModal(false)
            setShowSiteModal(true)
            setIsSiteMapping(false);           
        } else if (isDelete) {
            setDisplayConfirmModal(false);
            let uniqueContractId = selectedRows[0].uniqueContractId;
            dispatch(
                deleteContractData({
                    uniqueContractId,
                    setSelectedRows,
                    setMessage,
                    setDisplayInfoModal,
                })
            );
            setIsDelete(false);
        }
        else if (isClose) {
            setDisplayConfirmModal(false);
            let uniqueContractId = selectedRows[0].uniqueContractId;
            dispatch(onCloseContract(uniqueContractId,setSelectedRows,setMessage,setDisplayInfoModal));
        }
        else if(isActivate){
            setDisplayConfirmModal(false);
            let uniqueContractId = selectedRows[0].uniqueContractId;
            dispatch(onActivateContract(uniqueContractId,setSelectedRows,setMessage,setDisplayInfoModal));
        }
    }

    const onReportAllClick = () => {
        setReportFlag('all');
        setShowReportModal(true);
    };

    const onReportHighlightedClick = () => {
        setReportFlag('highlighted');
        setShowReportModal(true);
    };

    const getSelectedContractsId = () => {
        let params: any = [];
        selectedRows.map(item => {
            params.push(item.uniqueContractId);
        });
        return params;
    };

    const onExportAll = () => {
		const reportParameters = {
            exportParameters: {
                uniqueContractId: "",
                sortColumn: sortField,
                sortOrder: sortOrder,
                search: keyWord,
                selectedIds: ""
            },
            exportType: "Contract Details Report"
        };
		dispatch(onExportReport(reportParameters, "Contracts"));
	};

	const onExportHighlightedClick = () => {
		const reportParameters = {
            exportParameters: {
                uniqueContractId: "",
                sortColumn: sortField,
                sortOrder: sortOrder,
                search: keyWord,
                selectedIds: getSelectedContractsId()
            },
            exportType: "Contract Details Report"
        };
		dispatch(onExportReport(reportParameters, "Contracts"));
	};

    const onCloseContractClick =()=>{
        if (selectedRows.length < 1) {
            setMessage("Select a Contract");
            setDisplayInfoModal(true);
        }
        else if (selectedRows.length > 1) {
            setMessage("This operation can be performed only one row at a time");
            setDisplayInfoModal(true);
        }
        else if (selectedRows.length === 1) {
            switch (selectedRows[0].status) {
                case "Closed":
                    setMessage("Contract is already closed");
                    setDisplayInfoModal(true);
                    break;
                default:
                    setIsClose(true);
                    dispatch(checkContractForClose(selectedRows[0].uniqueContractId));
                    break;
            }
        }
    }

    const onActivateContractClick=()=>{
        if (selectedRows.length < 1) {
            setMessage("Select a Contract");
            setDisplayInfoModal(true);
        }
        else if (selectedRows.length > 1) {
            setMessage("This operation can be performed only one row at a time");
            setDisplayInfoModal(true);
        }
        else if (selectedRows.length === 1) {
            switch (selectedRows[0].status) {
                case "Active":
                    setMessage("Contract is already Active");
                    setDisplayInfoModal(true);
                    break;
                default:   
                    setIsActivate(true);
                    setMessage("Are you sure you want to activate the selected Contract?");              
                    setDisplayConfirmModal(true);
                    break;
            }
        }
    }

    useEffect(() => {
        if (checkContractClose) {
            if (checkContractClose?.isTechnicianJoined === true) {
                setMessage("Selected Contract is in use, cannot be closed");
                setDisplayInfoModal(true);
            }
            else if (checkContractClose?.isActiveInspectionTaskExist === true) {
                setMessage("Inspection Task(s) of the selected Contract is in progress, are you sure you want to close the Contract?");
                if (isClose)
                    setDisplayConfirmModal(true);
            }
            else if (checkContractClose?.isSystemActive === true) {
                setMessage("System(s) of the selected Contract is Active, are you sure you want to close the Contract?");
                if (isClose)
                    setDisplayConfirmModal(true);
            }
            else if (checkContractClose?.isContractInUse === false) {
                setMessage("Are you sure you want to close the selected Contract?");
                if (isClose)
                    setDisplayConfirmModal(true);
            }
    }
    },[checkContractClose])

    const onCancelClick=()=>{
        setIsActivate(false);
        setIsClose(false);
        setIsDelete(false);
        setIsSiteMapping(false);
    }

    const onOkClick=()=>{
        setIsClose(false);
        setIsActivate(false);
        dispatch(setCheckContractClose(''));
    }

    return (
        <div className='contracts'>
            <div className='contract-container'>
                <ContractTableData getTableColumns={handleTableColumns}/>
                <section className={`${filterExpanded ? 'expanded' : ''} contract-table`}>
                    {showContractGrid && tableColumns? (
                    <Table
                        rows={contractList}
                        cols={tableColumns}
                        title='Contracts'
                        dataKeyId='uniqueContractId'
                        paginator
                        pageDetails={pageDetails}
                        onPageChanged={onPageChanged}
                        pageIndex={pageIndex}
                        searchText='Enter Contract Name / Number'
                        getSelectedRowData={handleRowSelect}
                        onAddClick={() => setShowAddModal(true)}
                        onDeleteClick={deleteIconClick}
                        onEditClick={editIconClick}
                        onRowEditValidation={onRowEditValidation}
                        onRowEditClick={onRowEditClick}
                        onSaveRowEdit={onEditContractClick}
                        onGlobalSearch={onGlobalSearch}
                        onSortData={onSort}
                        showroweditor={showUI(userPrivileges, 'SIETAP_FS_CONTRACTS_MANAGE')}
                        showAddIcon={showUI(userPrivileges, 'SIETAP_FS_CONTRACTS_MANAGE')}
                        showEditIcon={showUI(userPrivileges, 'SIETAP_FS_CONTRACTS_MANAGE')}
                        showDeleteIcon={showUI(userPrivileges, 'SIETAP_FS_CONTRACTS_MANAGE')}
                        defaultSelectedRows={selectedRows}
                        settingListItems={settingListItems}
                        onDownloadClick={(e) => downloadRef.current?.toggle(e)}
                    />) : null}
                </section>
            </div>

            {showAddModal? (<AddContract showModal={showAddModal} setShowModal={setShowAddModal} onAddContractClick={(data) =>onAddContractClick(data)}/>) : null}
            {showEditModal? (<EditContract showModal={showEditModal} setShowModal={setShowEditModal} selectedRows={selectedRows} onEditContractClick={(data) =>onEditContractClick(data)}/>): null}
            {showSiteModal? (<SiteModalContract showModal={showSiteModal} setShowModal={setShowSiteModal}  selectedRows={selectedContract} onUpdateContractSite={onUpdateContractSite} setSelectedRows={setSelectedRows}/>) : null}
            <InformationBox
                showInfoModal={displayInfoModal}
                setShowInfoModal={setDisplayInfoModal}
                message={message}
                okOnClick={onOkClick}
            />
            <ConfirmationBox
                showConfirmModal={displayConfirmModal}
                setShowConfirmModal={setDisplayConfirmModal}
                confirmAction={onConfirmClick}
                message={message}
                cancelAction={onCancelClick}
            />
            <PopOver ref={downloadRef}>
                <ul>
                    <li onClick={onReportAllClick}>Report All</li>
                    <li onClick={onReportHighlightedClick}>Report Highlighted</li>
                    <li onClick={onExportAll}>Export All</li>
					<li onClick={onExportHighlightedClick}>Export Highlighted</li>
                </ul>
            </PopOver>
            {showReportModal ? (
                <ContractReport showModal={showReportModal}
                    setShowModal={setShowReportModal}
                    reportFlag={reportFlag}
                    selectedRows={selectedRows}
                    sortByColumns={tableColumns}
                    searchKeyword={keyWord} />
            ) : null}
        
        </div>
    );
} 

export default Contracts;