import React, { useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";

import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { Dialog } from "primereact/dialog";
import { ChangePassword } from "./ChangePassword";
import { BsExclamationCircleFill } from "react-icons/bs";
import InformationBox from "components/common/Message/InformationBox/InformationBox";

import { useAppSelector } from "redux/hooks";
import {
  getPersonalSettings,
  onEditPersonalSettings,
} from "redux/pages/Dashboard/UserMenu/personalSettingsSlice";
import { getTimeZoneList } from "../../redux/pages/Administration/administrationSlice";
import { useDispatch } from "react-redux";

interface IPersonalSettings {
  showModal: boolean;
  setShowModal: (state: boolean) => void;
}

type FormData = {
  name: string;
  loginName: string;
  contract: string;
  firstname: string;
  lastname: string;
  middlename: string;
  PhoneNo1: string;
  PhoneNo2: string;
  Email: string;
  TimeZone: string;
  rowpage: any;
  rowpageinspection: number;
  showall: boolean;
};

export const PersonalSettings: React.FC<IPersonalSettings> = ({
  showModal = false,
  setShowModal = () => {},
}) => {
  const editUserForm: any = useRef();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      name: "",
      loginName: "",
      firstname: "",
      lastname: "",
      middlename: "",
      Email: "",
      contract: "",
      PhoneNo1: "",
      PhoneNo2: "",
      rowpageinspection: 100,
      TimeZone: "",
      rowpage: "",
      showall: false,
    },
  });
  const [showChangePassword, setShowChangePassword] = useState<boolean>(false);
  const [message, setMessage] = useState<boolean>(false);
  const [displayInfoModal, setDisplayInfoModal] = useState(false);
  const [selectedTimeZone, setSelectedTimeZone] = useState({
    name: "",
    code: "",
  });
  const [showAll, setShowAll] = useState(false);
  const timeZoneList = useAppSelector(
    (state) => state.administration.TimeZoneList
  );

  const [userName, setUserName] = useState("");
  const personalSettings = useAppSelector(
    (state) => state.personalSettings.personalSettings
  );
  const [timeZoneLookup, setTimeZoneLookup] = useState([
    { name: "", code: "" },
  ]);
  const dispatch = useDispatch<any>();

  const formSubmitHandler = (data: any) => {
    editUserForm.current.requestSubmit();
  };


  useEffect(() => {
    dispatch(getPersonalSettings());
    dispatch(getTimeZoneList());

  }, [dispatch]);
  useEffect(() => {
    if (timeZoneList) {
      let timeZone: { name: string; code: string }[] = [];
      timeZoneList?.timeZones.map((item: any) => {
        return timeZone.push({
          name: item.timeZoneName,
          code: item.timeZoneId,
        });
      });
      setTimeZoneLookup(timeZone);
    }
  }, [timeZoneList]);
  useEffect(() => {
    if (personalSettings?.length != 0) {
      setUserName(personalSettings?.loginName);
      setValue("firstname", personalSettings?.firstName);
      setValue("middlename", personalSettings?.middleName);
      setValue("lastname", personalSettings?.lastName);
      setValue("Email", personalSettings?.email);
      setValue("PhoneNo1", personalSettings?.phoneNo1);
      setValue("PhoneNo2", personalSettings?.phoneNo2);
      setValue("rowpage", personalSettings?.rowsPerPage);
      if(personalSettings?.rowsPerPageForInspectiontasks===null){
        setValue("rowpageinspection",100)
      } else {
        setValue("rowpageinspection",personalSettings?.rowsPerPageForInspectiontasks);
      }
      setValue("showall", personalSettings?.showAllRows);
      setShowAll(personalSettings?.showAllRows)
      setValue("TimeZone", personalSettings?.timeZone.timeZoneName);
      let timeZone = {
        name: personalSettings?.timeZone.timeZoneName,
        code: personalSettings?.timeZone.timeZoneId,
      };
      setSelectedTimeZone(timeZone);
    }
  }, [personalSettings, setValue]);

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && (
        <span className="tooltip-text">
          <BsExclamationCircleFill />
          {errors[name].message}
        </span>
      )
    );
  };

  const onEditFormSubmit = (submitData: any) => {
    let body = {
      firstName: submitData.firstname,
      middleName: submitData.middlename,
      lastName: submitData.lastname,
      email: submitData.Email,
      phoneNo1: submitData.PhoneNo1,
      phoneNo2: submitData.PhoneNo2,
      rowsPerPage: submitData.rowpage,
      rowsPerPageForInspectiontasks: showAll===false?submitData.rowpageinspection:null,
      showAllRows: showAll,
      timeZone: {
        timeZoneId: selectedTimeZone.code,
        timeZoneName: selectedTimeZone.name,
      },
    };
    dispatch(
      onEditPersonalSettings(
        body,
        setMessage,
        setDisplayInfoModal,
        setShowModal
      )
    );
  };
  const timeZoneChangeHandler = (e) => {
    let timeZone = { name: "", code: "" };
    timeZoneLookup.map((item) => {
      if (item.name === e.value) {
        timeZone.name = e.value;
        timeZone.code = item.code;
      }
    });
    setSelectedTimeZone(timeZone);
  };
  const renderFooter = () => {
    return (
      <>
        <Button
          label="Save Changes"
          className="update btn"
          onClick={formSubmitHandler}
        />
        <Button
          label="Change Password"
          className="change-password btn"
          onClick={() => setShowChangePassword(true)}
          autoFocus
        />
      </>
    );
  };

  const onError = (errors: any, e: any) => {};

  return (
    <>
      <Dialog
        header="Personal Settings"
        visible={showModal}
        style={{ width: "40vw" }}
        onHide={() => setShowModal(false)}
        footer={renderFooter()}
        className="display-settings personal selectsite"
      >
        <form
          onSubmit={handleSubmit(onEditFormSubmit, onError)}
          ref={editUserForm}
        >
          <div className="row px-2">
            <div className="field col-12 md:col-2 mb-0 py-0">
              <span className="p-float-label text-container">
                <label htmlFor="inputtext">Login Name</label>
                <Controller
                  name="loginName"
                  control={control}
                  render={() => <span className="text-value">{userName}</span>}
                />
              </span>
            </div>
          </div>

          <div className="row px-2 pt-1">
            <div className="field col-12 md:col-2 mb-0">
              <span className="p-float-label">
                <Controller
                  name="firstname"
                  control={control}
                  rules={{
                    required: "This field is required.",
                  }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id="name"
                      className={classNames("w-100 error-tooltip", {
                        error: fieldState.invalid,
                      })}
                      {...field}
                    />
                  )}
                />
                <label className="mandatory" htmlFor="inputtext">
                  First Name
                </label>
                {getFormErrorMessage("firstname")}
              </span>
            </div>
          </div>
          <div className="row px-2 pt-1">
            <div className="field col-12 md:col-2 mb-0">
              <span className="p-float-label">
                <Controller
                  name="middlename"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputText
                      id="name"
                      className={classNames("w-100 error-tooltip", {
                        error: fieldState.invalid,
                      })}
                      {...field}
                    />
                  )}
                />
                <label htmlFor="inputtext">Middle Name</label>
                {getFormErrorMessage("middlename")}
              </span>
            </div>
          </div>

          <div className="row px-2 pt-1">
            <div className="field col-12 md:col-2 mb-0">
              <span className="p-float-label">
                <Controller
                  name="lastname"
                  control={control}
                  rules={{
                    required: "This field is required.",
                  }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id="name"
                      className={classNames("w-100 error-tooltip", {
                        error: fieldState.invalid,
                      })}
                      {...field}
                    />
                  )}
                />
                <label className="mandatory" htmlFor="inputtext">
                  Last Name
                </label>
                {getFormErrorMessage("lastname")}
              </span>
            </div>
          </div>

          <div className="row px-2 pt-1">
            <div className="field col-12 md:col-2 mb-0">
              <span className="p-float-label">
                <Controller
                  name="Email"
                  control={control}
                  rules={{
                    required: "This field is required.",
                  }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id="Email"
                      className={classNames("w-100 error-tooltip", {
                        error: fieldState.invalid,
                      })}
                      {...field}
                    />
                  )}
                />
                <label className="mandatory" htmlFor="inputtext">
                  Email
                </label>
                {getFormErrorMessage("Email")}
              </span>
            </div>
          </div>

          <div className="row px-2 pt-1">
            <div className="field col-12 md:col-2 mb-0">
              <span className="p-float-label">
                <Controller
                  name="PhoneNo1"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputText
                      id="PhoneNo1"
                      className={classNames("w-100 error-tooltip", {
                        error: fieldState.invalid,
                      })}
                      {...field}
                    />
                  )}
                />
                <label htmlFor="inputtext">
                  Phone No1 (Including Country Code)
                </label>
                {getFormErrorMessage("PhoneNo1")}
              </span>
            </div>
          </div>

          <div className="row px-2 pt-1">
            <div className="field col-12 md:col-2 mb-0">
              <span className="p-float-label">
                <Controller
                  name="PhoneNo2"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputText
                      id="PhoneNo1"
                      className={classNames("w-100 error-tooltip", {
                        error: fieldState.invalid,
                      })}
                      {...field}
                    />
                  )}
                />
                <label htmlFor="inputtext">Phone No2</label>
                {getFormErrorMessage("PhoneNo2")}
              </span>
            </div>
          </div>

          <div className="row px-2 pt-1">
            <div className="field col-12 md:col-2 mb-0 pb-0">
              <span className="p-float-label">
                <Controller
                  name="TimeZone"
                  control={control}
                  rules={{
                    required: "This field is required.",
                  }}
                  render={({ field, fieldState }) => (
                    <Dropdown
                      inputId="TimeZone"
                      className={classNames("w-100 error-tooltip", {
                        error: fieldState.invalid,
                      })}
                      {...field}
                      value={field.value}
                      onChange={(e) => {
                        timeZoneChangeHandler(e);
                        field.onChange(e.value);
                      }}
                      options={timeZoneLookup}
                      optionLabel="name"
                      optionValue="name"
                    />
                  )}
                />
                <label className="mandatory" htmlFor="dropdown">
                  Time Zone
                </label>
                {getFormErrorMessage("TimeZone")}
              </span>
            </div>
          </div>

          <div className="row px-2 pt-1">
            <div className="field col-12 md:col-2 mb-0 pb-0">
              <span className="p-float-label">
                <Controller
                  name="rowpage"
                  control={control}
                  rules={{
                    required: "This field is required.",
                    min: {
                      value: 1,
                      message: "Enter a value greater than 0 for Rows per Page",
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id="rowpager"
                      className={classNames("w-100 error-tooltip", {
                        error: fieldState.invalid,
                      })}
                      {...field}
                    />
                  )}
                />
                <label className="mandatory" htmlFor="inputtext">
                  Rows per Page
                </label>
                {getFormErrorMessage("rowpage")}
              </span>
            </div>
          </div>

          <div className="row px-2 pt-1">
           <div className="field col-10 md:col-2 mb-0 pb-0">
           {showAll===false? <span className="p-float-label">
                <Controller
                  name="rowpageinspection"
                  control={control}
                  rules={{
                    required: "This field is required.",
                    pattern: {
                      value: /^\d*\.?\d*$/,
                      message: "This is a Numeric Field",
                    },
                    min: {
                      value: 1,
                      message: "Enter a value greater than 0 for Rows per Page for Inspection Task",
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id="rowpager"
                      className={classNames("w-100 error-tooltip", {
                        error: fieldState.invalid,
                      })}
                      {...field}
                    />
                  )}
                />
                <label className="mandatory" htmlFor="inputtext">
                  Rows per Page for Inspection Task
                </label>
                {getFormErrorMessage("rowpageinspection")}
              </span>:<label className="mandatory" htmlFor="inputtext">
                  <b>Show All Inspection Tasks</b>
                </label>}
            </div>
            <div className="field col-2 md:col-2 mb-0 p-0 d-flex flex-column justify-content-center">
              <span className="p-float-label action-btns">
                <div className="field-checkbox px-2">
                  <Controller
                    name="showall"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        inputId="binary"
                        checked={showAll}
                        {...field}
                        onChange={(e) => setShowAll(e.checked)}
                      />
                    )}
                  />
                  <label className="ml-4" htmlFor="binary">
                    Show All
                  </label>
                </div>
              </span>
            </div>
          </div>
        </form>
        {showChangePassword ? (
          <ChangePassword
            showModal={showChangePassword}
            loginName={userName}
            setShowModal={setShowChangePassword}
          />
        ) : null}
        <InformationBox
          showInfoModal={displayInfoModal}
          setShowInfoModal={setDisplayInfoModal}
          message={message}
          okOnClick={() => setShowModal(false)}
        />
      </Dialog>
    </>
  );
};

export default PersonalSettings;
