import React, {memo,useEffect,useRef} from "react";
import { Calendar } from "primereact/calendar";
import { classNames } from "primereact/utils";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Checkbox } from "primereact/checkbox";
import { Controller } from "react-hook-form";
import { RadioButton } from "primereact/radiobutton";
import { BsExclamationCircleFill } from "react-icons/bs";
import { Dropdown } from "primereact/dropdown";
import moment from "moment";
import { useAppSelector } from "redux/hooks";
import { blockedNonKeyboardCharacters } from "service/GeneralUtility";

interface FormElementPropType {
    ddAppendTo?: 'self' | undefined;
    data?: any;
    control?: any;
    setValue?: any;
    errors?: any;
    watch?: any;
    fieldFocus?: any;
    calendarAppendTo?: boolean;
    isMandatory? : boolean;
    onSelectionChange?: (data, id) => void;
    onTextBoxChange?: (data,e) => void;
    checkIsValueChanged?: (e) => void;
    onDateTimeChanged?: (data,e) => void;
    setFieldFocus?:any; 
}
interface ErrorMessageProps {
    data: any;
    inputRef?: React.RefObject<HTMLInputElement | HTMLTextAreaElement>;
}
const FormElement: React.FC<FormElementPropType> = (props) => {
    const { data, control, errors, setValue, watch, onSelectionChange = () => { },onTextBoxChange=() =>{ },onDateTimeChanged=() =>{ }, checkIsValueChanged = () => { }, ddAppendTo = undefined, calendarAppendTo = false,isMandatory = false , fieldFocus = false,setFieldFocus} = props;
    const cal = React.createRef<Calendar>();
    const textBoxInputRef = useRef<any>(null);
    const textAreaInputRef = useRef<any>(null);
    const loggedInUserDetails = useAppSelector((state) => state.administration.loggedInUserDetails);

    const onCheckBoxChanged = (datafieldId, value) => {
        const checkedItem = watch(datafieldId);
        for (let i = 0; i < checkedItem?.length; i++) {
            if (checkedItem?.[i]?.[value] !== undefined) {
                checkedItem[i][value] = !checkedItem?.[i]?.[value];
            }
        }
        setValue(datafieldId, checkedItem);
    };

    const getCheckBoxActive = (datafieldId, value) => {
        let flag = false;
        const checkedItem = watch(datafieldId);
        for (let i = 0; i < checkedItem?.length; i++) {
            if (checkedItem[i][value] !== undefined) {
                flag = checkedItem?.[i]?.[value];
            }
        }
        return flag;
    };
    
    const getTextType = (types) => {
        if (types.mandatory) {
            if ([4, 1, 16].includes(types?.dataType?.dataTypeId)) {
                return "decimal";
            } else if (types?.dataType?.dataTypeId === 3) {
                return "integer";
            }
            else {
                return "text";
            }
        }
        else if ([4, 1, 16].includes(types?.dataType?.dataTypeId)) {
            return "decimal";
        } else if (types?.dataType?.dataTypeId === 3) {
            return "integer";
        } 
        else if (types?.dataType?.dataTypeId === 5 || types?.dataType?.dataTypeId === 6 || types?.dataType?.dataTypeId === 17) {
            return "text";
        }
        else {
            return undefined;
        }
    };

    const getTextBoxType = (types) => {
        if ([4, 1, 16].includes(types?.dataType?.dataTypeId)) {
            return "decimal";
        } else if (types?.dataType?.dataTypeId === 3) {
            return "integer";
        }
        else {
            return "text";
        }
    }

    const ErrorMessage: React.FC<ErrorMessageProps> = ({ data, inputRef = null }) => {
        useEffect(() => {
            if (inputRef && inputRef.current && errors[data?.uniqueDataFieldId]?.type === "required") {
                setTimeout(() => {
                    inputRef.current?.focus();
                }, 0);
            }
        }, [errors, data, inputRef]);
        return (
            errors[props?.data?.uniqueDataFieldId] && (
                <span className="tooltip-text">
                    <BsExclamationCircleFill />
                    {errors[props?.data?.uniqueDataFieldId]?.type === "maxLength" ? (
                        <span>{`${props.data.dataFieldName} cannot exceed ${props.data.maxLength}`}</span>
                    ) : errors[props?.data?.uniqueDataFieldId]?.type === "required" ? (
                        <span>This field is required</span>
                    ) : errors[props?.data?.uniqueDataFieldId]?.type === "validate" ? (
                        <span>{errors[props?.data?.uniqueDataFieldId]?.message}</span>
                    ) : null}
                </span>
            )
        );
    };
   
    const decimalValidate = (value) => {
        if (value) {
            if (isNaN(value)) {
              return "Enter a Numeric value";
            }
            if (parseFloat(value) == value) {
              return true;
        } else {
            return "Enter a Numeric value";
        }
    }
    };

    const integerValidate = (value) => {
        if (value) {
          if (isNaN(value)) {
            return "Enter an integer value";
          }
          if (parseInt(value) == value && value % 1 === 0) {
            return true;
          } else {
            return "Enter an integer value";
          }
        }
      };

    const textValidate = (value) => {
        if (value) {
            let invalidCharacters: any = blockedNonKeyboardCharacters(value);
            if (invalidCharacters != null && invalidCharacters.length > 0) {
                return "Non-Keyboard character(s) " + invalidCharacters.join() + " is not allowed";
            }
            else {
                return !!value;
            }
        }
    }

    const preventChangeOnKeyDown = (event: any,type?) => {
        const charCode = (event.which) ? event.which : event.keyCode;
        if ((charCode == 38) || (charCode == 40)) {
            event.preventDefault();
            event.target.blur();
        }else if(event.key == 'e' || event.key == 'E'){
            if(type == 3){
                event.preventDefault();
                event.target.blur();
            }
        }
    }

    const CustomTextBox = (props) => {

        return (
            <div className="field">
                <Controller
                    name={props?.item?.uniqueDataFieldId}
                    control={control}
                    rules={{
                        required: props?.isMandatory ? false : props?.item?.mandatory,
                        maxLength: props?.item?.maxLength,
                        validate: getTextType(props?.item) === "integer" ? integerValidate : getTextType(props?.item) === "decimal" ? decimalValidate : getTextType(props?.item) === "text" ? textValidate : undefined,
                    }}
                    render={({ field, fieldState }) => (
                        <span className="p-float-label">
                            <InputText
                                ref={textBoxInputRef}
                                id={field.name}
                                value={field.value}
                                onChange={(e: any) => {
                                    field.onChange(e.target.value.trimStart());
                                    onTextBoxChange(props.item,e);
                                    checkIsValueChanged(e);
                                    setTimeout(() => {
                                        textBoxInputRef.current.focus();
                                      }, 0);
                                }}
                                autoFocus={fieldFocus && fieldState?.invalid}
                                step="any"
                                type={getTextBoxType(props?.item) === "text" ? "text" : "number"}
                                maxLength={props?.item?.maxLength}
                                className={classNames({ "p-invalid w-100 error-tooltip error": fieldState.invalid })}
                                onWheel={(e: any) => {
                                    // to prevent value changes on scroll
                                    e.target.blur();
                                }}
                                onKeyDown={(e: any) => preventChangeOnKeyDown(e,props?.item.dataType.dataTypeId)}
                                disabled={props?.item?.disabled} />
                            <label htmlFor={props?.item?.uniqueDataFieldId} className={`${props?.item?.mandatory ? "mandatory" : ""}`}>
                                <span>{props?.item?.dataFieldName}</span>
                            </label>
                            <ErrorMessage data={props?.item} inputRef={textBoxInputRef} />                        </span>
                    )}
                />
            </div>
        );
    };

    const CustomTextArea = (props) => {

        return (
            <div className="field">
                <Controller
                    name={props?.item?.uniqueDataFieldId}
                    control={control}
                    rules={{
                        required: props?.isMandatory ? false : props?.item?.mandatory,
                        maxLength: props?.item?.maxLength,
                        validate: getTextType(props?.item) === "text" ? textValidate : undefined,
                    }}
                    render={({ field, fieldState }) => (
                        <span className="p-float-label">
                            <InputTextarea
                             ref={textAreaInputRef}
                                id={field.name}
                                value={field.value}
                                onChange={(e: any) => {
                                    const { selectionStart, selectionEnd } = e.target;
                                    field.onChange(e.target.value.trimStart());
                                    checkIsValueChanged(e);
                                    setTimeout(() => {
                                        textAreaInputRef.current.focus();
                                        textAreaInputRef.current.setSelectionRange(selectionStart,selectionEnd);                                      
                                      }, 0);
                                }}
                                autoFocus={fieldFocus && fieldState?.invalid}
                                className={classNames({ "p-invalid w-100 error-tooltip error": fieldState.invalid })}
                                disabled={props?.item?.disabled} />
                            <label htmlFor={props?.item?.uniqueDataFieldId} className={`${props?.item?.mandatory ? "mandatory" : ""}`}>
                                <span>{props?.item?.dataFieldName}</span>
                            </label>
                            
                            <ErrorMessage data={props?.item} inputRef={textAreaInputRef} />                        </span>
                    )}
                />
            </div>
        );
    };

    const CustomRadioButton = (props) => {
       
        return (
            <div className={`field `}>
                <Controller
                    name={props?.item?.uniqueDataFieldId}
                    control={control}
                    rules={{ required:  props?.isMandatory ? false : props?.item?.mandatory }}
                    render={({ field, fieldState }) => (
                        <div className={`radio-section ${props?.item?.isVerticalList ? "" : "if-horizontal"}`}>
                            {props?.item?.validatedValues.map((item: any, key: any) => (
                                <>
                                    <span key={key} className="field-radiobutton">
                                        <RadioButton
                                            id={item?.dataFieldLookupId}
                                            inputId={item?.dataFieldLookupId}
                                            name={field.name}
                                            value={item?.dataFieldLookupId}
                                            disabled={props?.item?.disabled}
                                            checked={item?.dataFieldLookupId === watch(props?.item?.uniqueDataFieldId) ? true : false}
                                            onChange={(e) => {
                                                field.onChange(e.value);
                                                checkIsValueChanged(e);
                                            }}
                                            onDoubleClick={(e) => {

                                                field.onChange('')
                                            }}
                                           
                                            className={classNames({ "p-invalid": fieldState.invalid })} />
                                        <label
                                            htmlFor={item?.dataFieldLookupId}
                                            className={`${props?.item?.mandatory ? "mandatory" : " "}`}
                                            title={item?.dataFieldLookupValue.length > 60 ? item?.dataFieldLookupValue : ""}
                                        >
                                            {item?.dataFieldLookupValue.length > 60
                                                ? `${item?.dataFieldLookupValue.slice(0, 60)}...`
                                                : item?.dataFieldLookupValue}
                                        </label>
                                    </span>
                                    {watch(props?.item?.uniqueDataFieldId) === item?.dataFieldLookupId &&
                                        item?.childDataField?.map((child, key) => (
                                            <span key={key} className="child-all">
                                                {child?.dataEntryControl?.dataEntryControlName === "Text Box" ? (
                                                    <CustomTextBox item={child} />
                                                ) : child?.dataEntryControl?.dataEntryControlName === "Text Area" ? (
                                                    <CustomTextArea item={child} />
                                                ) : child?.dataEntryControl?.dataEntryControlName === "Date Picker" && child?.dataType?.dataTypeName === "Date" ? (
                                                    <CustomDatePicker item={child} />
                                                ) : child?.dataEntryControl?.dataEntryControlName === "Date Picker" && child?.dataType?.dataTypeName === "Date Time" ? (
                                                    <CustomDateTimePicker item={child} />
                                                ) : child?.dataEntryControl?.dataEntryControlName === "Date Time Picker" && child?.dataType?.dataTypeName === "Date Time" ? (
                                                    <CustomDateTimePicker item={child} />
                                                ) : child?.dataEntryControl?.dataEntryControlName === "Drop-down list" ? (
                                                    <CustomDropDown item={child} />
                                                ) : null}
                                            </span>
                                        ))}
                                    <ErrorMessage data={props?.item} />
                                </>
                            ))}
                        </div>
                    )}
                />
            </div>
        );
    };

    const CustomCheckBox = (props) => {
        return (
            <div className={`field ${props?.item?.isVerticalList ? "" : "if-horizontal"}`}>
                <Controller
                    name={props?.item?.uniqueDataFieldId}
                    control={control}
                    rules={{ required:  props?.isMandatory ? false : props?.item?.mandatory }}
                    render={({ field, fieldState }) => (
                        <>
                            {props?.item?.isValidated ? (
                                props?.item?.validatedValues.map((data, key) => (
                                    <>
                                        <span className="field-checkbox" key={key}>
                                            <Checkbox
                                                id={field.name}
                                                inputId={data?.dataFieldLookupId}
                                                name={field.name}
                                                disabled={props?.item?.disabled}
                                                onChange={(_e) => {
                                                    onCheckBoxChanged(props?.item?.uniqueDataFieldId, data?.dataFieldLookupId);
                                                    checkIsValueChanged(_e);
                                                }}
                                                checked={getCheckBoxActive(props?.item?.uniqueDataFieldId, data?.dataFieldLookupId)}
                                                value={data?.dataFieldLookupId}
                                                className={classNames({ "p-invalid": fieldState.invalid })} />
                                           <label 
                                            htmlFor={data?.dataFieldLookupId} 
                                            className={`${props?.item?.mandatory ? "mandatory" : ""}`}
                                            title={data?.dataFieldLookupValue} 
                                        >
                                            {data?.dataFieldLookupValue.length > 60
                                                ? `${data?.dataFieldLookupValue.substring(0, 60)}...` 
                                                : data?.dataFieldLookupValue}
                                        </label>
                                        </span>

                                        {watch(props?.item?.uniqueDataFieldId)?.[key]?.[data?.dataFieldLookupId] &&
                                            data?.childDataField?.map((child, key) => (
                                                <span key={key} className="child-all">
                                                    {child?.dataEntryControl?.dataEntryControlName === "Text Box" ? (
                                                        <CustomTextBox item={child} />
                                                    ) : child?.dataEntryControl?.dataEntryControlName === "Text Area" ? (
                                                        <CustomTextArea item={child} />
                                                    ) : child?.dataEntryControl?.dataEntryControlName === "Date Picker" && child?.dataType?.dataTypeName === "Date" ? (
                                                        <CustomDatePicker item={child} />
                                                    ) : child?.dataEntryControl?.dataEntryControlName === "Date Picker" && child?.dataType?.dataTypeName === "Date Time" ? (
                                                        <CustomDateTimePicker item={child} />
                                                    ) : child?.dataEntryControl?.dataEntryControlName === "Date Time Picker" && child?.dataType?.dataTypeName === "Date Time" ? (
                                                        <CustomDateTimePicker item={child} />
                                                    ) : child?.dataEntryControl?.dataEntryControlName === "Drop-down list" ? (
                                                        <CustomDropDown item={child} />
                                                    ) : null}
                                                </span>
                                            ))}

                                    </>

                                ))
                            ) : (
                                <span className="field-checkbox">
                                    <Checkbox
                                        inputId={field.name}
                                        id={field.name}
                                        name={field.name}
                                        onChange={(e) => {
                                            field.onChange(e.checked);
                                            checkIsValueChanged(e);
                                        }}
                                        disabled={props?.item?.disabled}
                                        checked={field.value}
                                        className={classNames({ "p-invalid": fieldState.invalid })}
                                    />
                                   <label 
                                    htmlFor={props?.item?.uniqueDataFieldId} 
                                    className={`${props?.item?.mandatory ? "mandatory" : ""}`}
                                    title={props?.item?.dataFieldName} 
                                >
                                    {props?.item?.dataFieldName.length > 60 
                                        ? `${props?.item?.dataFieldName.substring(0, 60)}...` 
                                        : props?.item?.dataFieldName}
                                </label>
                                </span>
                            )}
                            <ErrorMessage data={props?.item} />
                        </>
                    )}
                />
            </div>
        );
    };

    const CustomDatePicker = (props) => {

        return (
            <div className="field">
                <Controller
                    name={props?.item?.uniqueDataFieldId}
                    control={control}
                    rules={{ required:  props?.isMandatory ? false : props?.item?.mandatory }}
                    render={({ field, fieldState }) => (
                        <span className="p-float-label">
                            <Calendar
                                id={field.name}
                                inputId={field.name}
                                className={classNames("w-100 error-tooltip", { error: fieldState.invalid })}
                                value={field.value === undefined || field.value === "" ? "" : new Date(field.value.replace(/-/g, '\/'))}
                                onChange={(e: any) => {
                                    setValue(props?.item?.uniqueDataFieldId, moment(e.value).format("YYYY-MM-DD"));
                                    checkIsValueChanged(e);
                                }}
                                onClearButtonClick={() => setValue(props?.item?.uniqueDataFieldId, "")}
                                selectionMode="single"
                                disabled={props?.item?.disabled}
                                dateFormat="yy-mm-dd"
                                readOnlyInput
                                showButtonBar
                                appendTo={calendarAppendTo ? undefined : 'self'}
                            />
                            <label htmlFor={props?.item?.uniqueDataFieldId} className={`${props?.item?.mandatory ? "mandatory" : ""}`}>
                                <span>{props?.item?.dataFieldName}</span>
                            </label>
                            <ErrorMessage data={props?.item} />
                        </span>
                    )}
                />
            </div>
        );
    };

    const CustomDateTimePicker = (props) => {

        return (
            <div className="field">
                <Controller
                    name={props?.item?.uniqueDataFieldId}
                    control={control}
                    rules={{ required:  props?.isMandatory ? false : props?.item?.mandatory }}
                    render={({ field, fieldState }) => (
                        <span className="p-float-label">
                            <Calendar
                                ref={cal}
                                id={field.name}
                                inputId={field.name}
                                className={classNames("w-100 error-tooltip", { error: fieldState.invalid })}
                                value={field.value === undefined || field.value === "" ? "" : new Date(field.value)}
                                onChange={(e: any) => {
                                    setValue(props?.item?.uniqueDataFieldId, moment(e.value).format("YYYY-MM-DD HH:mm:ss"));
                                    checkIsValueChanged(e);
                                    onDateTimeChanged(data,e);
                                }}
                                onClearButtonClick={() => setValue(props?.item?.uniqueDataFieldId, "")}
                                selectionMode="single"
                                disabled={props?.item?.disabled}
                                readOnlyInput
                                footerTemplate={() => <div>
                                    <span className="save-btn" onClick={() => cal.current?.hide()}>Save</span>
                                </div>}
                                showTime
                                showSeconds
                                showButtonBar
                                appendTo={calendarAppendTo ? undefined : 'self'}
                                dateFormat="yy-mm-dd"
                                hourFormat="24"
                            />
                            <label htmlFor={props?.item?.uniqueDataFieldId} className={`${props?.item?.mandatory ? "mandatory" : ""}`}>
                                <span>{props?.item?.dataFieldName}</span>
                            </label>
                            <ErrorMessage data={props?.item} />
                        </span>
                    )}
                />
            </div>
        );
    };
    const CustomDateTimePickerDevAndTestDate = (props) => {

        return (
            <div className="field">
                <Controller
                    name={props?.item?.uniqueDataFieldId}
                    control={control}
                    rules={{ required:  props?.isMandatory ? false : props?.item?.mandatory }}
                    render={({ field, fieldState }) => (
                        <span className="p-float-label">
                            <Calendar
                                ref={cal}
                                id={field.name}
                                inputId={field.name}
                                className={classNames("w-100 error-tooltip", { error: fieldState.invalid })}
                                value={field.value === undefined || field.value === "" ? "" : new Date(field.value)}
                                onChange={(e: any) => {
                                    setValue(props?.item?.uniqueDataFieldId, moment(e.value).format("YYYY-MM-DD HH:mm:ss"));
                                    checkIsValueChanged(e);
                                    onDateTimeChanged(data,e);
                                }}
                                onClearButtonClick={() => setValue(props?.item?.uniqueDataFieldId, "")}
                               onTodayButtonClick ={(e:any) => setValue(props?.item?.uniqueDataFieldId, moment(e.value).utcOffset((loggedInUserDetails?.userDefaults?.siteOffset)).format("YYYY-MM-DD HH:mm:ss"))}
                                clearButtonClassName ="clearbutton" 
                                selectionMode="single"
                                disabled={props?.item?.disabled}
                                readOnlyInput
                                footerTemplate={() => <div>
                                <span className="save-btn" onClick={() => cal.current?.hide()}>Save</span>
                            </div>}
                                showTime
                                showSeconds
                                showButtonBar
                                touchUI
                                dateFormat="yy-mm-dd"
                                hourFormat="24"
                            />
                            <label htmlFor={props?.item?.uniqueDataFieldId} className={`${props?.item?.mandatory ? "mandatory" : ""}`}>
                                <span>{props?.item?.dataFieldName}</span>
                            </label>
                            <ErrorMessage data={props?.item} />
                        </span>
                    )}
                />
            </div>
        );
    };

    const CustomDropDown = (props) => {

        return (
            <div className="field">
                <Controller
                    name={props?.item?.uniqueDataFieldId}
                    control={control}
                    rules={{
                        required:  props?.isMandatory ? false : props?.item?.mandatory,
                        validate: {
                            required: (val) => {
                                if (props?.item?.mandatory) {
                                    if (val === -1) return "This field is required";
                                }
                            },
                        },
                    }}
                    render={({ field, fieldState }) => (
                        <span className="p-float-label">
                            <Dropdown
                                id={field.name}
                                appendTo={ddAppendTo}
                                inputId={field.name}
                                className={classNames("w-100 error-tooltip ", { error: fieldState.invalid })}
                                {...field}
                                value={field.value}
                                onChange={(e) => {
                                    field.onChange(e.value);
                                    onSelectionChange(props.item, e.value);
                                    checkIsValueChanged(e);
                                }}
                                options={props?.item?.validatedValues}
                                optionLabel="dataFieldLookupValue"
                                optionValue="dataFieldLookupId"
                                disabled={props?.item?.disabled}
                            />
                            <label htmlFor={props?.item?.uniqueDataFieldId} className={`${props?.item?.mandatory ? "mandatory" : ""}`}>
                                <span>{props?.item?.dataFieldName}</span>
                            </label>
                            <ErrorMessage data={props?.item} />
                        </span>
                    )}
                />
            </div>
        );
    };

    return (
        <>
            {data?.dataEntryControl?.dataEntryControlName === "Text Box" ? (
                <CustomTextBox item={data} isMandatory={isMandatory} />
            ) : data?.dataEntryControl?.dataEntryControlName === "Text Area" ? (
                <CustomTextArea item={data} isMandatory={isMandatory} />
            ) : data?.dataEntryControl?.dataEntryControlName === "Check Box(s)" ? (
                <CustomCheckBox item={data} isMandatory={isMandatory} />
            ) : data?.dataEntryControl?.dataEntryControlName === "Radio Buttons" ? (
                <CustomRadioButton item={data} isMandatory={isMandatory} />
            ) : data?.dataEntryControl?.dataEntryControlName === "Date Picker" && data?.dataType?.dataTypeName === "Date" ? (
                <CustomDatePicker item={data} isMandatory={isMandatory} />
            ) : data?.dataEntryControl?.dataEntryControlName === "Date Picker" && data?.dataType?.dataTypeName === "Date Time" ? (
                <CustomDateTimePicker item={data} isMandatory={isMandatory} />
            ) : data?.dataEntryControl?.dataEntryControlName === "Date Time Picker" && data?.dataType?.dataTypeName === "Date Time" && (data?.dataFieldName === "Dev Reading Date" || data?.dataFieldName === "Test Date" )? (
                <CustomDateTimePickerDevAndTestDate item={data} />
            ) : data?.dataEntryControl?.dataEntryControlName === "Date Picker" && data?.dataType?.dataTypeName === "Date Time" && (data?.dataFieldName === "Dev Reading Date" || data?.dataFieldName === "Test Date" )? (
                <CustomDateTimePickerDevAndTestDate item={data} isMandatory = {isMandatory}/>
            ) : data?.dataEntryControl?.dataEntryControlName === "Date Time Picker" && data?.dataType?.dataTypeName === "Date Time" ? (
                <CustomDateTimePicker item={data} />
            ) : data?.dataEntryControl?.dataEntryControlName === "Drop-down list" ? (
                <CustomDropDown item={data} isMandatory={isMandatory} />
            ) : null}
        </>
    );
};

export default memo(FormElement);
