import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import { BsExclamationCircleFill } from "react-icons/bs";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import ConfirmationBox from "components/common/Message/ConfirmationBox/ConfirmationBox";
import { classNames } from "primereact/utils";
import { Checkbox } from "primereact/checkbox";
import { onImportPreviousTaskDetails } from "redux/pages/Inspection/InspectionTask/inspectionTaskSlice";
interface Props {
    showModal: boolean;
    setShowModal: (state: boolean) => void;
    displayColumns: Array<any>;
    setdisplayColumns: (data) => void;
    setMessage:any;
    setDisplayInfoModal:(state: boolean) => void;
    previousTaskDetails:any;
    selectedRows:any;
    setShowImportPreviousTaskDetails:(state: boolean) => void;
}
type FormData = {
    sourceUniqueTaskId:any;
    isImportTestResult: boolean,
    isImportSystemQuestions:boolean
  };

export const ImportPreviousTaskDetails: React.FC<Props> = ({
    showModal = false,
    setShowModal = () => {},
    setMessage="",
    setDisplayInfoModal=()=>{},
    previousTaskDetails = [],
    selectedRows = [],
    setShowImportPreviousTaskDetails=()=>{},
}) => {
    const [displayImportPreviousTaskConfirmModal, setDisplayImportPreviousTaskConfirmModal] = useState<any>(false);
  const [importMessage, setImportMessage] = useState("");

  const dispatch = useDispatch<any>();

    const {
        control,
        formState: { errors },
        handleSubmit,
        getValues,
        watch
    } = useForm<FormData>({
        defaultValues: {
            sourceUniqueTaskId:"",
            isImportTestResult: false,
            isImportSystemQuestions:false
        },
      });

      const isImportTestResult=watch('isImportTestResult')
      const isImportSystemQuestions=watch('isImportSystemQuestions')

      const getFormErrorMessage = (name) => {
        return (
          errors[name] && (
            <span className="tooltip-text">
              <BsExclamationCircleFill />
              {errors[name].message}
            </span>
          )
        );
      };

    const onSubmit = () => {
        setImportMessage("Are you sure you want to Import from the selected Task?")
        setDisplayImportPreviousTaskConfirmModal(true)
    };

    const onError = (errors: any, e: any) => {};

    return (
        <>
            <Dialog
                header="Import Previous Task Details"
                visible={showModal}
                style={{ width: "50vw" }}
                onHide={() => setShowModal(false)}
                className="display-settings"
            >
                <form onSubmit={handleSubmit(onSubmit,onError)}>
                <div className="row px-2 custom-float-field ">

                    <div className="field col-12 md:col-2 mb-0 ">
                        <div className="field-checkbox">
                            <Controller
                                name="isImportTestResult"
                                control={control}
                                render={({ field }) => <Checkbox inputId="binary" checked={field.value} {...field} />}
                                rules={{
                                    validate: (value) => {
                                      return !value && !isImportSystemQuestions
                                        ? "Atleast one option needs to be selected"
                                        : undefined;
                                    },
                                  }}
                            />
                            <label className="mandatory" htmlFor="binary">
                                Import Equipment Test Result
                            </label>
                        </div>
                    </div>
                    <div className="field col-12 md:col-2 mb-0">
                        <div className="field-checkbox">
                            <Controller
                                name="isImportSystemQuestions"
                                control={control}
                                render={({ field }) => <Checkbox inputId="binary" checked={field.value} {...field} />}
                                rules={{
                                    validate: (value) => {
                                      return !value && !isImportTestResult
                                        ? "Atleast one option needs to be selected"
                                        : undefined;
                                    },
                                  }}
                            />
                            <label className="mandatory" htmlFor="binary">
                                Import System and Questions Data
                            </label>
                        </div>
                    </div>

                    <div className="field col-12 md:col-2 mb-0 mt-2">
                        <span className="p-float-label">
                            <Controller
                                name="sourceUniqueTaskId"
                                control={control}
                                rules={{ required: "Please select a Task" }}
                                render={({ field, fieldState }) => (
                                    <Dropdown
                                        inputId="dropdown"
                                        className={classNames("w-100 error-tooltip")}
                                        {...field}
                                        value={field.value}
                                        optionLabel="inspectionTaskName"
                                        options={previousTaskDetails}
                                    />
                                )}
                            />
                            <label className="mandatory" htmlFor="dropdown">
                                Select Task
                            </label>
                            {getFormErrorMessage("sourceUniqueTaskId")}
                        </span>
                    </div>
                    <div className="text-danger text-sm">{errors["isImportTestResult"]&&errors["isImportTestResult"].message&&errors["isImportSystemQuestions"]&&errors["isImportSystemQuestions"].message}</div>
                    <div className="footer text-left">
                        <Button label="Cancel" onClick={() => setShowModal(false)} className="cancel-btn" />
                        <Button label="Import" type="submit" className="submit-btn" />
                    </div>
                </div>
                </form>
            </Dialog>
            <ConfirmationBox
        showConfirmModal={displayImportPreviousTaskConfirmModal}
        setShowConfirmModal={setDisplayImportPreviousTaskConfirmModal}
        confirmAction={()=>{
          let data=  getValues()
            let body = {
                sourceUniqueTaskId: data?.sourceUniqueTaskId?.uniqueInspectionTaskId,
                uniqueSystemId: selectedRows[0]?.system?.uniqueSystemId,
                isImportTestResult: data?.isImportTestResult,
                isImportSystemQuestions:data?.isImportSystemQuestions
              };
              dispatch(
                onImportPreviousTaskDetails(
                  body,
                  selectedRows[0].uniqueInspectionTaskId,
                  setMessage,
                  setDisplayInfoModal,
                  setShowImportPreviousTaskDetails
                )
              );
        }}
        message={importMessage}
      />
        </>
    );
};

export default ImportPreviousTaskDetails;
