import React from "react";

const ToolsDocument = () => {
    return (
        <>
           <h4 className="doc-title mb-2">Tools</h4>

           <p>Options to Copy and Move Systems and Contracts are provided under the 
            <span className="doc-subtitle"> Tools</span> menu</p>

        </>
    );
}

export default ToolsDocument;