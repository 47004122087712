export const USAPattern = [
    {
        id: 1,
        pattern: 'USA Pattern',
        format: '26xxxxxxxx',
        description: '10 digits starting with 26'
    },
    {
        id: 2,
        pattern: 'USA Pattern',
        format: '5xxxxxxxxx',
        description: '10 digits starting with 5'
    },
    {
        id: 3,
        pattern: 'USA Pattern',
        format: '44OP-xxxxxx',
        description: '44OP-6digits'
    },
    {
        id: 4,
        pattern: 'USA Pattern',
        format: '44OC-xxxxxxx',
        description: '44OC-7digits'
    }

]
export const CanadaPattern=[
    {
        id:1,
        pattern:"Canada Pattern",
        format:"4xxxxxxx",
        description:"8 digits starting with 4 (agreement)",
    },
    {
        id: 2,
        pattern: "Canada Pattern",
        format: "60xxxxx",
        description: "7 digits starting with 60 (small work)",
    },
    {
        id: 3,
        pattern: "Canada Pattern",
        format: "<A>-xx-<A>-xxxx",
        description: "1letter-2digits-1letter-4digits (project number)",
    },
    {
        id: 4,
        pattern: "Canada Pattern",
        format: "13xxxxx",
        description: "7 digits starting with 13 (project sales order)",
    },
    {
        id: 2,
        pattern: "Canada Pattern",
        format: "<A>-SI-xxxxx",
        description: "1letter-SI-5digits (solutions contract)",
    },
]