import React, { useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Button } from "primereact/button";
import { baseURL } from "../../../service/HttpService";
import { FilePond, registerPlugin } from "react-filepond";
import FileUploadValidate from "filepond-plugin-file-validate-type";
import "filepond/dist/filepond.min.css";

import { BsFillTrashFill } from "react-icons/bs";
//redux

import UploadModal from "components/Table/UploadModal/UploadModal";
import ConfirmationBox from "components/common/Message/ConfirmationBox/ConfirmationBox";
import moment from "moment";
import InformationBox from "components/common/Message/InformationBox/InformationBox";

interface Props {
  showModal: boolean;
  setShowModal: (state: boolean) => void;
  onUploadXmlClick?: (data: any) => void;
  uploadedFileList?: any;
  acceptedFileTypes?: string[];
  onDeleteFileClick?: (data: any) => void;
  errorMessage?:string;
  title: string;
  typeFlag: string;
}

type FormData = {
  uploaddate: string;
};

export const UploadXML: React.FC<Props> = ({
  showModal = false,
  setShowModal = () => {},
  onUploadXmlClick = () => {},
  uploadedFileList = "",
  acceptedFileTypes =["xml"],
  onDeleteFileClick = () => {},
  errorMessage = "Select an XML file.",
  title = "",
  typeFlag = "",
}) => {
  registerPlugin(FileUploadValidate);
  const editForm: any = useRef();
  const [enableUpload, setEnableUpload] = useState(true);
  const [files, setFiles] = useState<any>([]);
  const [fileName, setFileName] = useState("");
  const [uploadedDate, setUploadedDate] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [displayConfirmModal, setDisplayConfirmModal] = useState(false);
  const [displayInfoModal, setDisplayInfoModal] = useState(false);
  const [message, setMessage] = useState("");
  const [disableDelete, setDisableDelete] = useState(true);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      uploaddate: "",
    },
  });

  const onEditFormSubmit = (data: any) => {};

  useEffect(() => {
    if (uploadedFileList && uploadedFileList.fileName) {
      let uploadedFile = JSON.stringify(uploadedFileList?.fileName);
     let date = moment(uploadedFileList?.uploadedOn).format("YYYY-MM-DD").toString();
   
      setFileName(uploadedFile);
      setUploadedDate(date);
      setUploadedFiles(uploadedFileList);
      setDisableDelete(false);
    } else {
      setFileName("");
      setUploadedDate("");
      setUploadedFiles([]);
    }
  }, [uploadedFileList]);

  const UploadClick = () => {
    let response = JSON.parse(JSON.parse(JSON.stringify(files.serverId)));
    if (response?.status === "done") {
      let body = {
        TempStorageDirectory: response.data?.uniqueDirectoryId[0],
      };
      onUploadXmlClick && onUploadXmlClick(body);
    }
  };

  const onprocessfile = (error: any, file: any) => {
    setEnableUpload(false);
    setFiles(file);
    let uploadedFile = JSON.stringify(file.filename)
    let date = moment(new Date()).format("YYYY-MM-DD").toString();
    setFileName(uploadedFile);
    setUploadedDate(date);
  };

  const onRemoveFile = () =>{
    setEnableUpload(true)
    setFileName("")
    setUploadedDate("");
  }

  const onError = (error) => {
    setMessage(error && error.sub ? error.sub : `${typeFlag} File upload failed due to Network Error. Select the file to be uploaded.` );
    setDisplayInfoModal(true);
  }

  const onDeleteClick = () => {
    setDisplayConfirmModal(true)
  }


  const OnConfirmClick = () => {
    setDisplayConfirmModal(false);
    setDisableDelete(true);
    onDeleteFileClick && onDeleteFileClick(uploadedFiles);
  }

  const fileTypeValidation = (source: File, type: string) => new Promise<string>((resolve, reject) => {
    const uploadedFileExtension = source.name.split('.').pop();
    const allowedFileType = acceptedFileTypes.find(fileType => fileType.split('.').pop() === uploadedFileExtension);

    if (allowedFileType) {
      resolve(allowedFileType);
    } else {
      reject('.' + uploadedFileExtension);
    }
  });

  return (
    <UploadModal
      header={title}
      showModal={showModal}
      setShowModal={setShowModal}
      OnSaveClick={UploadClick}
      isAdd={false}
      style={{ width: "40vw", maxHeight: "95%" }}
      buttonDisable={enableUpload}
    >
      <form onSubmit={handleSubmit(onEditFormSubmit)} ref={editForm}>
        <div className="row px-2 pt-1">
          <div className="field col-12 md:col-2 mb-0 d-flex w-100 align-items-center">
            <div className="left-sider-label upload-left">
              <div className="p-float-label">
                <p className="label">Attachment</p>
              </div>
            </div>
            <div className="right-side-field">
              <FilePond
                allowMultiple={false}
                maxFiles={1}
                server={`${baseURL}api/v1/common/files/upload`}
                onprocessfile={onprocessfile}
                onremovefile={onRemoveFile}
                onerror={onError}
                name="files"
                credits={false}
                labelIdle="Click to choose a file or drag and drop a file"
                labelFileLoading=""
                labelTapToUndo=""
                labelFileProcessing=""
                labelFileProcessingComplete=""
                labelFileProcessingAborted=""
                labelFileProcessingError=""
                labelButtonAbortItemProcessing=""
                labelButtonUndoItemProcessing=""
                labelButtonRetryItemProcessing=""
                labelButtonProcessItem=""
                labelTapToCancel=""
                labelFileRemoveError=""
                labelTapToRetry=""
                allowFileTypeValidation={true}
                acceptedFileTypes={acceptedFileTypes}
                fileValidateTypeDetectType={fileTypeValidation}
                labelFileLoadError={errorMessage}
                fileValidateTypeLabelExpectedTypes={errorMessage}
              />
            </div>
          </div>
        </div>
        <div className="row px-2 align-items-center">
          <div className="field col-11 md:col-2 mb-0 d-flex">
            <div className="left-sider-label upload-left">
              <div className="p-float-label">
                <p className="label">Uploaded File Name</p>
              </div>
            </div>
            <div className="right-side-field">
              <span className="p-float-label text-container">
                <Controller
                  name="uploaddate"
                  control={control}
                  render={() => (
                    <span className="text-value ml-0">
                      {fileName}
                    </span>
                  )}
                />
              </span>
            </div>
          </div>
          <div className="field col-1 md:col-2 mb-0 p-0">
            <span className="p-float-label action-btns">
              <Button disabled={disableDelete} className="button-icon" tooltip="Delete">
                <BsFillTrashFill className="icon" onClick={onDeleteClick}/>
              </Button>
            </span>
          </div>
        </div>
        <div className="field col-12 md:col-2 mb-0 d-flex">
            <div className="left-sider-label upload-left">
              <div className="p-float-label">
                <p className="label">Uploaded Date</p>
              </div>
            </div>
            <div className="right-side-field">
              <span className="p-float-label text-container">
                <Controller
                  name="uploaddate"
                  control={control}
                  render={() => (
                    <span className="text-value ml-0">
                      {uploadedDate}
                    </span>
                  )}
                />
              </span>
            </div>
          </div>
        
      </form>
      <ConfirmationBox
        showConfirmModal={displayConfirmModal}
        setShowConfirmModal={setDisplayConfirmModal}
        confirmAction={OnConfirmClick}
        message="Are you sure you want to delete the uploaded File?"/>
      <InformationBox
        showInfoModal={displayInfoModal}
        setShowInfoModal={setDisplayInfoModal}
        message={message} />
    </UploadModal>
    
  );
};

export default UploadXML;
