
export const ATTACHMENT_LIST_ROWS = [
    {
        id: 1,
        fileName: 'sample.pdf',
        fileSize: '100KB',
        description: 'demo',
        uploadedOn:'null',
        uploadedBy:'xyz'
    },
    {
        id: 2,
        fileName: 'sample.pdf',
        fileSize: '100KB',
        description: 'demo',
        uploadedOn:'null',
        uploadedBy:'xyz'
    }
];

export const ATTACHMENT_LIST_COLS = {
    LIST_COLS: [
        
        {
            field: 'inspectionTask',
            header: 'Inspection Task',
            // editor: (options) => textEditor(options),
            style: { flex: '1 0 200px' },
            sortable: true,
        },
        {
            field: 'fileName',
            header: 'File Name',
            // editor: (options) => textEditor(options),
            style: { flex: '1 0 200px' },
            sortable: true,
        },
        {
            field: 'fileSize',
            header: 'File Size (KB)',
           // editor: (options) => textEditor(options),
            style: { flex: '1 0 200px' },
            sortable: true,
        },
        {
            field: 'description',
            header: 'Description',
            //editor: (options) => textEditor(options),
            style: { flex: '1 0 200px' },
            sortable: true,
        },
        {
            field: 'uploadedOn',
            header: 'Uploaded On',
            //editor: (options) => textEditor(options),
            style: { flex: '1 0 200px' },
            sortable: true,
        },
        {
            field: 'uploadedBy',
            header: 'Uploaded By',
            //editor: (options) => reportEditor(options),
            style: { flex: '1 0 150px' },
            sortable: true,
        }
       
    ]
};