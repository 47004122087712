import React, { useEffect, useState } from 'react'
import { useDispatch } from "react-redux";
import { APP_ROUTES } from "constants/AppRoutes";
import { useNavigate } from "react-router-dom";
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { ListGroup } from 'react-bootstrap';
import Breadcrumb from "components/common/Breadcrumb/Breadcrumb";
import ReportViewer from 'components/common/Report/ReportViewer'
import { useAppSelector } from "../../redux/hooks";
import { createReportPreviewSession, setReportPreviewSession } from "../../redux/pages/Administration/Site/siteSlice";
import { Controller, useForm } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { getStatusList } from 'redux/pages/Administration/administrationSlice';
import CustomizeReport from './CustomizeReport/CustomizeReport';
import { onExportReport, setIsAppliedDone } from 'redux/common/report/customizeReportSlice';
import InformationBox from 'components/common/Message/InformationBox/InformationBox';
//icons
import { BsArrowRight, BsFileEarmarkPdf } from "react-icons/bs";
import { AiOutlineFileText } from 'react-icons/ai';
import { GrDocumentRtf } from 'react-icons/gr';
import { RiFileExcel2Line } from 'react-icons/ri';

type FormData = {
	filter: string;
	statusId: number;
};

const SiteReport = () => {

	const {
		control,
		formState: { errors },
	} = useForm<FormData>({
		defaultValues: {
			filter: "status",
			statusId: 0,
		},
	});

	const [reportName, setReportName] = useState<any>(null);
	const [filterOption, setFilterOption] = useState<any>('status');
	const [selectedStatus, setSelectedStatus] = useState<number>(0);
	const [statusList, setStatusList] = useState<any>([]);
	const [showCustomizeReport, setShowCustomizeReport] = useState(false);
	const [message, setMessage] = useState("");
	const [displayInfoModal, setDisplayInfoModal] = useState(false);
	const [customizationType, setCustomizationType] = useState('user');

	const reportPreviewSession = useAppSelector((state) => state.site.reportPreviewSession);
	const { status } = useAppSelector((state) => state.administration.statusList);
	const isAppliedDone = useAppSelector((state) => state.customizeReport.isAppliedDone);

	const navigate = useNavigate();
	const dispatch = useDispatch<any>();

	useEffect(() => {
		dispatch(getStatusList());
	}, []);

	useEffect(() => {
		if (status && status.length) {
			let tempList = [{ statusId: 0, statusCode: "All_Users", statusName: "All" },
			...status.filter(status => [1, 2, 3].includes(status.statusId))]
			setStatusList(tempList);
		}
	}, [status]);

	const viewSiteReport = () => {
		const reportParameters = {
			reportParameters: {
				StatusId: selectedStatus,
				customizationType: customizationType
			},
			reportType: "Sites"
		};
		dispatch(createReportPreviewSession(reportParameters));
	  };

	useEffect(() => {
		viewSiteReport();

		return (() => {
			dispatch(setReportPreviewSession(""));
		});
	}, [selectedStatus, filterOption]);

	useEffect(() => {
		if(isAppliedDone) viewSiteReport();
	}, [isAppliedDone]);

	useEffect(() => {
		if (reportPreviewSession) {
			setReportName(reportPreviewSession.previewSessionId);
		}
	}, [reportPreviewSession])

	useEffect(() => {
		if (isAppliedDone) {
			dispatch(setIsAppliedDone(false));
			setShowCustomizeReport(false);
			setMessage("Report settings saved");
			setDisplayInfoModal(true);
		}
	}, [isAppliedDone]);

	const exportAsExcel = () => {
		const reportParameters = {
			exportParameters: {
				StatusId: selectedStatus,
				customizationType: customizationType
			},
			exportType: "Sites"
		};
		dispatch(onExportReport(reportParameters, "Sites"));
	};

	return (

		<div className='site-reports'>
			<section className="d-flex align-items-center">
				<Breadcrumb
					title="Reports"
					classNames="report-header"
					handleGoBackClick={() => navigate(`/${APP_ROUTES.REPORT_VIEW}`)}
				/>
				<div className="breadcrumb-right-arrow ml-3 mr-3">
					<BsArrowRight className="icon" />
				</div>
				<div className="breadcrumb-sub-page-title">
					<span>Sites</span>
				</div>
			</section>

			<div className="report-sort-field pt-2 pb-2">
				<div className="report-export-main d-flex">
					<div className="row px-2">
						<div className="report-field col-12 md:col-2">
							<div className="field  mb-0">
								<span className="p-float-label">
									<Controller
										name="filter"
										control={control}
										render={({ field, fieldState }) => (
											<Dropdown
												inputId="filterDropdown"
												className={classNames("w-100 error-tooltip", {
													error: fieldState.invalid,
												})}
												{...field}
												value={filterOption}
												onChange={(e) => {
													setFilterOption(e.value);
												}}
												options={[{ name: 'Status', value: 'status' }]}
												optionLabel="name"
												optionValue="value"
											/>
										)}
									/>
									<label htmlFor="filterDropdown">Filter on field </label>
								</span>
							</div>
						</div>
						<div className="report-field col-12 md:col-2">
							<div className="field  mb-0">
								<span className="p-float-label">
									<Controller
										name="statusId"
										control={control}
										render={({ field, fieldState }) => (
											<Dropdown
												inputId="dropdown"
												className={classNames("w-100 error-tooltip", {
													error: fieldState.invalid,
												})}
												{...field}
												value={selectedStatus}
												onChange={(e) => {
													setSelectedStatus(e.value);
													dispatch(setReportPreviewSession(""));
												}}
												options={statusList}
												optionLabel="statusName"
												optionValue="statusId"
											/>
										)}
									/>
									<label htmlFor="dropdown">with value</label>
								</span>
							</div>
						</div>
					</div>
					<div className="report-buttons-main d-flex">
						<div className="report-buttons">
							<ListGroup horizontal>
								<ListGroup.Item>
									<Button label="Customize" className="report-btn" onClick={() => setShowCustomizeReport(true)} />
								</ListGroup.Item>
							</ListGroup>
						</div>
						<div className='export-report-buttons'>
							<ListGroup horizontal>
								<span className='expor-title'>Export to</span>
								<ListGroup.Item>
									<Button className="export-btn" onClick={() => exportAsExcel()}> <RiFileExcel2Line className="icon" /> Excel </Button>
								</ListGroup.Item>
								{/* <ListGroup.Item>
									<Button className="export-btn"> <BsFileEarmarkPdf className="icon" /> PDF </Button>
								</ListGroup.Item>
								<ListGroup.Item>
									<Button className="export-btn"> <GrDocumentRtf className="icon" /> RTF </Button>
								</ListGroup.Item>
								<ListGroup.Item>
									<Button className="export-btn"> <AiOutlineFileText className="icon" /> Text </Button>
								</ListGroup.Item> */}
							</ListGroup>
						</div>
					</div>
				</div>
			</div>

			<ReportViewer reportId={reportName} />
			<InformationBox
				showInfoModal={displayInfoModal}
				setShowInfoModal={setDisplayInfoModal}
				message={message}
			/>
			{showCustomizeReport &&
				<CustomizeReport
					reportType="SITE_LIST"
					showModal={showCustomizeReport}
					onHideModal={() => setShowCustomizeReport(false)}
					customizationType={customizationType}
					setCustomizationType={setCustomizationType} />
			}
		</div>

	)
}

export default SiteReport