import { APP_ROUTES } from 'constants/AppRoutes';

export interface NavItemProps {
    id: number,
    name: string,
    url: string,
    active: boolean
}

export const DEFAULT_DASHBOARD_NAVLIST: Array<NavItemProps> = [
    {
        id: 1,
        name: 'Dashboard',
        url: APP_ROUTES.DASHBOARD,
        active: true
    }
];

export const DEFAULT_ADMINISTRATION_NAVLIST: Array<NavItemProps> = [
    {
        id: 2,
        name: 'Customers',
        url: APP_ROUTES.CUSTOMERS,
        active: true
    },
    {
        id: 3,
        name: 'Users',
        url: APP_ROUTES.USERS,
        active: false
    },
    {
        id: 4,
        name: 'Sites',
        url: APP_ROUTES.SITES,
        active: false
    },
    {
        id: 5,
        name: 'Search',
        url: `${APP_ROUTES.ADMINISTRATION}-search`,
        active: false
    }
];

export const DEFAULT_INSPECTION_NAVLIST: Array<NavItemProps> = [
    {
        id: 2,
        name: 'Inspection Tasks',
        url: APP_ROUTES.INSPECTION_TASKS,
        active: true
    },
    {
        id: 3,
        name: 'Contracts',
        url: APP_ROUTES.CONTRACTS,
        active: false
    },
    {
        id: 4,
        name: 'Systems',
        url: APP_ROUTES.SYSTEMS,
        active: false
    },
    {
        id: 5,
        name: 'Inspection Reports',
        url: APP_ROUTES.INSPECTION_REPORTS,
        active: false
    },
    {
        id: 6,
        name: 'Search',
        url: `${APP_ROUTES.INSPECTION}-search`,
        active: false
    }
];

export const DEFAULT_ASBUILTS_NAVLIST: Array<NavItemProps> = [
    {
        id: 2,
        name: 'Drawings',
        url: APP_ROUTES.AS_BUILTS,
        active: true
    }
];

export const ORGANIZATION_TYPES = {
    ZONE: 'ZONE',
    SALES: 'SALES',
    BRANCH: 'BRANCH'
};

export const INSPECTION_REPORT_TYPES = {
    REPORT_GROUPS: 'Report Groups',
    REPORTS: 'Reports',
    REPORT_TEMPLATES: 'Report Templates',
    REPORT_CONFIGS: 'Report Configs'
};
export const SECURITY_SETTINGS_TYPE = {
    PASSWORD_POLICY: 'Password Policy',
    MAIL_DOMAINS: 'Mail Domains'
};
export const CUSTOMIZATION_ATTRIBUTES_TYPE = {
    COMMON_ATTRIBUTES: 'Common Attributes',
    COMMON_ATTRIBUTES_VALUES: 'Common Attribute Values',
    EQUIPMENT_TYPE_ATTRIBUTES: 'Equipment Type Attributes',
    EQUIPMENT_TYPE_ATTRIBUTES_VALUES: 'Equipment Type Attribute Values'
};
