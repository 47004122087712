import { InputText } from 'primereact/inputtext';

const textEditor = (options) => {
    return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
};
export const INSPECTION_REPORTSTEMPLATE_COLS = {
    REPORTSTEMPLATE_COLS: [
        {
            field: 'reportTemplate',
            header: 'Report Template',
            editor: (options) => textEditor(options),
            style: { flex: '1 0 150px' },
            sortable: true,
        },
        {
            field: 'uploadedOn',
            header: 'Uploaded On',
            editor: (options) => textEditor(options),
            style: { flex: '1 0 150px' },
            sortable: true,
        }
        
    ]
};

export const INSPECTION_REPORTSTEMPLATE_ROWS = {
    REPORTSTEMPLATE_ROWS: [
        {
            id: 1,
            ReportTemplate: 'NFPA25_3-Year_Sprinkler_Template',
            UploadedOn: '03 Jan 2022 08.24 PM',
        },
        {
            id: 2,
            ReportTemplate: 'NFPA25_3-Year_Sprinkler_Template',
            UploadedOn: '03 Jan 2022 08.24 PM',
        },
        {
            id: 3,
            ReportTemplate: 'NFPA25_3-Year_Sprinkler_Template',
            UploadedOn: '03 Jan 2022 08.24 PM',
        },
        {
            id: 4,
            ReportTemplate: 'NFPA25_3-Year_Sprinkler_Template',
            UploadedOn: '03 Jan 2022 08.24 PM',
        },
        {
            id: 5,
            ReportTemplate: 'NFPA25_3-Year_Sprinkler_Template',
            uploadedOn: '03 Jan 2022 08.24 PM',
        },
        {
            id: 6,
            ReportTemplate: 'NFPA25_3-Year_Sprinkler_Template',
            UploadedOn: '03 Jan 2022 08.24 PM',
        },
        {
            id: 7,
            ReportTemplate: 'NFPA25_3-Year_Sprinkler_Template',
            UploadedOn: '03 Jan 2022 08.24 PM',
        },
        {
            id: 8,
            ReportTemplate: 'NFPA25_3-Year_Sprinkler_Template',
            UploadedOn: '03 Jan 2022 08.24 PM',
        },
        {
            id: 9,
            ReportTemplate: 'NFPA25_3-Year_Sprinkler_Template',
            UploadedOn: '03 Jan 2022 08.24 PM',
        },
        {
            id: 10,
            ReportTemplate: 'NFPA25_3-Year_Sprinkler_Template',
            UploadedOn: '03 Jan 2022 08.24 PM',
        },
        {
            id: 11,
            ReportTemplate: 'NFPA25_3-Year_Sprinkler_Template',
            UploadedOn: '03 Jan 2022 08.24 PM',
        },
        {
            id: 12,
            ReportTemplate: 'NFPA25_3-Year_Sprinkler_Template',
            UploadedOn: '03 Jan 2022 08.24 PM',
        },
        {
            id: 13,
            ReportTemplate: 'NFPA25_3-Year_Sprinkler_Template',
            UploadedOn: '03 Jan 2022 08.24 PM',
        },
        {
            id: 14,
            ReportTemplate: 'NFPA25_3-Year_Sprinkler_Template',
            UploadedOn: '03 Jan 2022 08.24 PM',
        },
        {
            id: 15,
            ReportTemplate: 'NFPA25_3-Year_Sprinkler_Template',
            UploadedOn: '03 Jan 2022 08.24 PM',
        },
        {
            id: 16,
            ReportTemplate: 'NFPA25_3-Year_Sprinkler_Template',
            UploadedOn: '03 Jan 2022 08.24 PM',
        },
        {
            id: 17,
            ReportTemplate: 'NFPA25_3-Year_Sprinkler_Template',
            UploadedOn: '03 Jan 2022 08.24 PM',
        },
        {
            id: 18,
            ReportTemplate: 'NFPA25_3-Year_Sprinkler_Template',
            UploadedOn: '03 Jan 2022 08.24 PM',
        },
        {
            id: 19,
            ReportTemplate: 'NFPA25_3-Year_Sprinkler_Template',
            UploadedOn: '03 Jan 2022 08.24 PM',
        },
        {
            id: 20,
            ReportTemplate: 'NFPA25_3-Year_Sprinkler_Template',
            UploadedOn: '03 Jan 2022 08.24 PM',
        }
    ]
}