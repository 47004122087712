import React, { useState } from "react";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import InformationBox from "components/common/Message/InformationBox/InformationBox";

interface Props {
  showModal: boolean;
  setShowModal: (state: boolean) => void;
  displayColumns: Array<any>;
  setdisplayColumns: (data) => void;
  selectedCols: Array<any>;
  header?: string;
}

export const DisplaySetting: React.FC<Props> = ({
  showModal = false,
  setShowModal = () => { },
  displayColumns = [],
  setdisplayColumns = () => { },
  selectedCols = [],
  header = "Display Settings"
}) => {

  const [selectedColumns, setSelectedColumns] = useState<Array<any>>(selectedCols);
  const [displayInfoModal, setDisplayInfoModal] = useState(false);
  const [message, setMessage] = useState("");

  const handleSubmit = () => {
    if (selectedColumns.length === 0) {
      setMessage("Atleast one column needs to be selected");
      setDisplayInfoModal(true);
    } else {
      const colOrder = displayColumns.map(item => item?.field);
      const colMap = new Map();
      colOrder.forEach((item, index) => colMap.set(item, index));
      const prioritySortedCols = selectedColumns.sort((a, b) => colMap.get(a?.field) - colMap.get(b?.field));
      setdisplayColumns(prioritySortedCols);
      setShowModal(false);
    }
  };

  return (
    <>
      <Dialog
        header={header}
        visible={showModal}
        style={{ width: "30vw" }}
        onHide={() => setShowModal(false)}
        className="display-settings common_display_settings"
      >
        <div className="content-box">
          <div className="table-columns">
            <DataTable
              value={displayColumns}
              selectionMode="checkbox"
              selection={selectedColumns}
              onSelectionChange={(selection) => {setSelectedColumns(selection.value);}}
              dataKey="header"
            >
              <Column
                selectionMode="multiple"
                headerStyle={{ width: "2rem" }}
              ></Column>
              <Column field="header" header="Select All"></Column>
            </DataTable>
          </div>
          <div className="footer text-left">
            <Button
              label="Cancel"
              onClick={() => setShowModal(false)}
              className="cancel-btn"
            />
            <Button label="OK" onClick={handleSubmit} className="submit-btn" />
          </div>
        </div>
      </Dialog>
      <InformationBox
        showInfoModal={displayInfoModal}
        setShowInfoModal={setDisplayInfoModal}
        message={message}
      />
    </>
  );
};

export default DisplaySetting;
