import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { zlink, baseURL } from "../../../../service/HttpService";
import { AppThunk } from "../../../store";

export interface sendMailState {
    errors: any;
    mailData: any;
}

let initialState: sendMailState = {
    errors: "",
    mailData: {}
};

const sendMailSlice = createSlice({
    name: "sendMail",
    initialState,
    reducers: {
        setErrors: (state, { payload }: PayloadAction<any>) => {
            state.errors = payload;
        },
        setMailData: (state, { payload }: PayloadAction<any>) => {
            state.mailData = payload;
        }
    }
});

export const getMailData = (): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.get(`${baseURL}api/v1/sendmail`);
        if (res.status === "done") {
            if (res.data) {
                dispatch(setMailData(res.data));
            }
        } else {
            dispatch(setErrors(res.error));
        }
    } catch (error) {
        dispatch(setErrors(error));
    }
};

export const sendAttachment = (fileData: any, body: any, setLoader, setShowSendMail, setMessage, setDisplayInfoModal): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.post(`${baseURL}api/v1/sendmail/files/add`, fileData);
        if (res.status === "done") {
            if (res.data) {
                if (res.data.files && res.data.files.length) {
                    body.hasAttachment = true;
                    body.attachmentName = res.data.files[0].fileName;
                }
            }
            dispatch(sendMail(body, setLoader, setShowSendMail, setMessage, setDisplayInfoModal));
        } else {
            dispatch(setErrors(res.error));
            if (res.message) {
                setMessage(res.message);
                setDisplayInfoModal(true);
            }
            setLoader(false);
        }
    } catch (error) {
        dispatch(setErrors(error));
        setLoader(false)
    }
};


export const sendMail = (body: any, setLoader, setShowSendMail, setMessage, setDisplayInfoModal): AppThunk => async (dispatch) => {
    try {
      const res = await zlink.post(`${baseURL}api/v1/sendmail`, body);
      if (res.status === "done") {
        setShowSendMail(false)
        setMessage("Mail has been Sent");
        setDisplayInfoModal(true);
      } else {
        dispatch(setErrors(res.error));
        if (res.message) {
          setMessage(res.message);
          setDisplayInfoModal(true);
        }
      }
      setLoader(false);
    } catch (error) {
      dispatch(setErrors(error));
      setLoader(false);
    }
};

export const {
    setErrors,
    setMailData
} = sendMailSlice.actions;

export default sendMailSlice.reducer;