import React, { useState, useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { DataTable } from "primereact/datatable";
import { BsExclamationCircleFill } from "react-icons/bs";
import { Column } from "primereact/column";
import { SketchPicker } from "react-color";
import { useDispatch } from "react-redux";
import { getSavedMarkups,setMarkupData } from "redux/pages/AsBuilts/AsBuiltsSlice";
import { useAppSelector } from "redux/hooks";
import InformationBox from "components/common/Message/InformationBox/InformationBox";

interface Props {
    showModal: boolean;
    setShowModal: (state: boolean) => void;
    selectedDrawing: any;
    selectedBuilding: any;
    selectedMarkup?: any;
    markupListSubmit?: (data: any) => void;
}

export const DrawingMarkupList: React.FC<Props> = ({
    showModal = false,
    setShowModal = () => { },
    selectedDrawing = [],
    selectedBuilding = [],
    selectedMarkup = [],
    markupListSubmit = () => { },
}) => {
    


    const [selectedRowData, setSelectedRowData] = useState<any>(null);
    const [message, setMessage] = useState("");
    const [displayInfoModal, setDisplayInfoModal] = useState<boolean>(false)
    const [markupDatas, setMarkupDatas] = useState<any>([]);

    const loggedInUserData = useAppSelector((state) => state.administration.loggedInUserDetails);
    const markupList = useAppSelector((state) => state.asbuilts.markupData);
   
    const dispatch = useDispatch<any>();

    document.addEventListener("mousedown", (e: any) => {
        let element = document.getElementById("color-picker");
        if (!element?.contains(e.target)) {
            setSelectedRowData(null);
        }
    });

    useEffect(() => {
        let body = {
            drawingId:selectedDrawing[0]?.drawingId,
            revisionNo: selectedDrawing[0]?.revisions,
       };
       dispatch(getSavedMarkups(loggedInUserData?.userDefaults?.uniqueSiteId, selectedBuilding?.id, selectedDrawing[0]?.drawingId, body, 1,setMessage,setDisplayInfoModal))
   
     }, [selectedDrawing])


    useEffect(() => {
        if (markupList && markupList.markupDataList) {
            let markupInfo = JSON.parse(JSON.stringify(markupList.markupDataList));
            if (selectedMarkup.length === 0) {
                markupInfo.forEach((element) => {
                    element["isChecked"] = false;
                    element["colorChange"] = false;
                 });
            } else {
                markupInfo.forEach((element) => {
                    const index= selectedMarkup.findIndex((item: any) => item?.markupId === element.markupId);
                          
                    if (index>=0){
                        element["isChecked"] = true;
                        element["colorChange"] = false;
                    }
                    else{
                        element["isChecked"] = false;
                        element["colorChange"] = false;
                    }
                });
            }
            setMarkupDatas(markupInfo)
            dispatch(setMarkupData(""));
        }
    }, [markupList])

    const renderFooter = () => {
        return (
            <>
                <Button label="Cancel" onClick={() => setShowModal(false)} className="cancel btn" />
                <Button label="OK" className="update btn" autoFocus onClick={onSubmitClick}/>
            </>
        );
    };


    const onSubmitClick = () => {
        let markup: any = [];
        let filterItem: any = []
        filterItem = markupDatas?.filter((item) => item.isChecked);
        filterItem.map((item: any) => {
            markup.push(item)
        })
        markupListSubmit && markupListSubmit(markup)
    };


    const descriptionTemplate = (rowData: any) => {
        return (
            <div className="checkbox-wrapper field-checkbox">
                <div>{rowData.description}</div>
            </div>
        );
    };

    const uploadedByTemplate = (rowData: any) => {
        return (
            <div className="checkbox-wrapper field-checkbox">
                <div>{rowData.uploadedBy}</div>
            </div>
        );
    };

    const uploadedOnTemplate = (rowData: any) => {
        return (
            <div className="checkbox-wrapper field-checkbox">
                <div>{rowData.uploadedOn}</div>
            </div>
        );
    };
    
    
    const template = (rowData: any, columnDetails: any) => {
        return (
            <div className="checkbox-wrapper field-checkbox">
                <Checkbox
                    className="check-box"
                    id={rowData.markupId}
                    checked={rowData.isChecked}
                    onChange={(e) => onCheckboxChanged(e, columnDetails, rowData)}
                />
            </div>
        );
    };

    const onCheckboxChanged = (e, details, rowData) => {
        markupDatas[details?.rowIndex][details?.field] = e.checked;
        setMarkupDatas([...markupDatas]);
    };

    const headerTemlpate = () => {
        return (
            <div>
                <Checkbox
                    className="check-box mr-2"
                    id="show-in-drawing"
                    checked={onGetAllChecked()}
                    onChange={(e) => onHeaderCheckBoxChanged(e)}
                />
            </div>
        );
    };

    const onGetAllChecked = () => {
        let checked = false;
            const filterItem = markupDatas?.filter((item) => item.isChecked);
            if (filterItem?.length === markupDatas?.length) checked = true;
            else checked = false;
        return checked;
    };

    const onHeaderCheckBoxChanged = (e) => {
            markupDatas.forEach((element) => {
                element.isChecked = e.checked;
            });
        
        setMarkupDatas([...markupDatas]);
    };


    const colorTemplate = (rowData: any, columnDetails) => {
        return (
            <div className="color-select__wrapper">
                    <>
                        <div
                            className="color-select"
                            style={{ backgroundColor: rowData?.rgb, }}
                            onClick={() => {
                                setSelectedRowData(rowData.markupId);
                            }}
                        >
                        </div>
                        {selectedRowData === rowData.markupId && (
                            <div id="color-picker">
                                <SketchPicker color={rowData?.rgb} onChange={(e: any) => onChangeColor(e, columnDetails)} />
                            </div>
                        )}
                    </>
            </div>
        );
    };

    const onChangeColor = (e: any, details) => {
    
        markupDatas[details.rowIndex][details.field] = e.hex;
        markupDatas[details.rowIndex]["colorChange"] = true;
        setMarkupDatas([...markupDatas]);
    };

    const onError = (errors: any, e: any) => {};

    return (
        <>
            <Dialog
                header="Markup List"
                visible={showModal}
                style={{ width: "60vw", height:"70%" }}
                onHide={() => setShowModal(false)}
                footer={renderFooter()}
                className="s-modal drawing-markup-list"
            >
                <div className="custom-table">
                    <DataTable dataKey="markupId" value={markupDatas}>
                        <Column field="isChecked" header={headerTemlpate} body={template} />
                        <Column field="description" header="Description" body={descriptionTemplate}/>
                        <Column field="uploadedBy" header="Uploaded By" body={uploadedByTemplate}/>
                        <Column field="uploadedOn" header="Uploaded On" body={uploadedOnTemplate}/>
                        <Column field="rgb" header="Color" body={colorTemplate} />
                    </DataTable>
                </div>
            </Dialog>
            <InformationBox
            showInfoModal={displayInfoModal}
            setShowInfoModal={setDisplayInfoModal}
            message={message} />
        </>
    );
};

export default DrawingMarkupList;


