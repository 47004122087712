import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { zlink, baseURL } from "../../../../service/HttpService";
import { AppThunk } from "../../../store";

export interface ZoneState {
  errors: any;
  branchOfficeList: any;
  branchOfficeDetails: any;
  branchOfficePageDetails: any;
  salesOfficeLookup: any;
  allBranchOfficeList: any;
}

let initialState: ZoneState = {
  errors: "",
  branchOfficeList: [],
  branchOfficeDetails: "",
  branchOfficePageDetails: [],
  salesOfficeLookup: "",
  allBranchOfficeList: [],
};
const BranchOfficeSlice = createSlice({
  name: "branchOffice",
  initialState,
  reducers: {
    setErrors: (state, { payload }: PayloadAction<any>) => {
      state.errors = payload;
    },
    setBranchOfficeList: (state, { payload }: PayloadAction<any>) => {
      state.branchOfficeList = payload;
    },
    setDeletedBranchOfficeList(state, { payload }: PayloadAction<any>) {
			state.branchOfficeList = state.branchOfficeList.filter((item: any) => item.uniqueBranchOfficeId !== payload);
    },
    setUpdatedBranchOffice: (state, { payload }: PayloadAction<any>) => {
      let currentIndex = state.branchOfficeList.findIndex(
        (item: any) =>
          item.uniqueBranchOfficeId === payload?.uniqueBranchOfficeId
      );
      state.branchOfficeList = state.branchOfficeList.filter(
        (item: any) =>
          item.uniqueBranchOfficeId !== payload?.uniqueBranchOfficeId
      );
      state.branchOfficeList.splice(currentIndex, 0, payload);
    },
    setBranchOfficeDetails: (state, { payload }: PayloadAction<any>) => {
      state.branchOfficeDetails = payload;
    },
    setBranchOfficePageDetails: (state, { payload }: PayloadAction<any>) => {
      state.branchOfficePageDetails = payload;
    },
    setNewBranchOfficeData: (state, { payload }: PayloadAction<any>) => {
      state.branchOfficeList.unshift(payload);
    },
    setSalesOfficeLookup: (state, { payload }: PayloadAction<any>) => {
      state.salesOfficeLookup = payload;
    },
    setAllBranchOfficeList: (state, { payload }: PayloadAction<any>) => {
      state.allBranchOfficeList = payload;
    },
  },
});

export const getBranchOfficeList =
  (setShowGrid, currentStart, keyword: any = "", sortField: any = "", sortOrder: any = ""): AppThunk =>
  async (dispatch) => {
    try {
      const res = await zlink.get(
        `${baseURL}api/v1/organization/branchoffices?keyword=${keyword}&currentstart=${currentStart}&sortcolumn=${sortField}&sortorder=${sortOrder}`
      );
      if (res.status === "done") {
        dispatch(setBranchOfficeList(res?.data?.branchOfficeList));
        dispatch(setBranchOfficePageDetails(res?.data?.pageDetails));
        setShowGrid(true);
      } else {
        dispatch(setErrors(res.error));
      }
    } catch (error) {
      dispatch(setErrors(error));
    }
  };
export const onAddBranchOffice =
  (
    body: any,
    setMessage,
    setDisplayInfoModal,
    setShowAddBranchOfficeModal
  ): AppThunk =>
  async (dispatch) => {
    try {
      const res = await zlink.post(
        `${baseURL}api/v1/organization/branchoffices/add`,
        body
      );
      if (res.status === "done") {
        dispatch(setNewBranchOfficeData(res.data));
        setMessage("Branch Office added");
        setShowAddBranchOfficeModal(false);
        setDisplayInfoModal(true);
      } else {
        dispatch(setErrors(res.error));
        setMessage(res.message);
        setDisplayInfoModal(true);
      }
    } catch (error) {
      dispatch(setErrors(error));
    }
  };
export const onEditBranchOffice =
  (
    body: any,
    uniqueBranchOfficeId,
    setMessage,
    setDisplayInfoModal,
    setShowEditModal
  ): AppThunk =>
  async (dispatch) => {
    try {
      const res = await zlink.post(
        `${baseURL}api/v1/organization/branchoffices/${uniqueBranchOfficeId}`,
        body
      );
      if (res.status === "done") {
        dispatch(setUpdatedBranchOffice(res.data));
        setMessage("Branch office updated");
        setShowEditModal(false);
        setDisplayInfoModal(true);
      } else {
        dispatch(setErrors(res.error));
        setMessage(res.message);
        setDisplayInfoModal(true);
      }
    } catch (error) {
      dispatch(setErrors(error));
    }
  };
export const getBranchOfficeDetails =
  (uniqueBranchOfficeId): AppThunk =>
  async (dispatch) => {
    try {
      const res = await zlink.get(
        `${baseURL}api/v1/organization/branchOffices/${uniqueBranchOfficeId}`
      );
      if (res.status === "done") {
        dispatch(setBranchOfficeDetails(res.data));
      } else {
        dispatch(setErrors(res.error));
      }
    } catch (error) {
      dispatch(setErrors(error));
    }
  };

  export const deleteBranchOffice = ({ uniqueBranchOfficeId, setSelectedRows, setMessage, setDisplayInfoModal }): AppThunk => async (dispatch) => {
    try {
      const res = await zlink.post(`${baseURL}api/v1/organization/branchoffices/${uniqueBranchOfficeId}/remove`, null);
      if (res.status === "done") {
        dispatch(setDeletedBranchOfficeList(uniqueBranchOfficeId));
        setSelectedRows([]);
        setMessage("Branch Office deleted");
        setDisplayInfoModal(true);
      } else {
        setSelectedRows([]);
        setMessage(res.message);
        setDisplayInfoModal(true);
      }
    } catch (error) {
      dispatch(setErrors(error));
    }
  };

  export const getSalesOfficeLookup =
  (): AppThunk =>
  async (dispatch) => {
    try {
      const res = await zlink.get(
        `${baseURL}api/v1/organization/salesoffices?isAllDetails=true`
      );
      if (res.status === "done") {
        dispatch(setSalesOfficeLookup(res?.data?.salesOfficeDetails));
      } else {
        dispatch(setErrors(res.error));
      }
    } catch (error) {
      dispatch(setErrors(error));
    }
  };

export const getAllBranchOfficeList = (): AppThunk =>
  async (dispatch) => {
    try {
      const res = await zlink.get(`${baseURL}api/v1/organization/branchoffices?isALLDetails=true`);
      if (res.status === "done") {
        if (res.data) {
          dispatch(setAllBranchOfficeList(res?.data?.branchOfficeList));
        }
      } else {
        dispatch(setErrors(res.error));
      }
    } catch (error) {
      dispatch(setErrors(error));
    }
  };
  
export const {
  setErrors,
  setBranchOfficeList,
  setUpdatedBranchOffice,
  setBranchOfficeDetails,
  setDeletedBranchOfficeList,
  setBranchOfficePageDetails,
  setNewBranchOfficeData,
  setSalesOfficeLookup,
  setAllBranchOfficeList,
} = BranchOfficeSlice.actions;
export default BranchOfficeSlice.reducer;
