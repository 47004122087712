import React from 'react'
import { Button } from 'react-bootstrap'

const SearchCustomerDocument = () => {
  return (
    <>
    <h4 className="doc-title mb-2">Search - Customer</h4>
    
    <p>
    Customer Search option allows the user to search 
    for the required Customer. User can type minimum three 
    characters of the Customer Name / Customer Number in the 
    text box provided and select the required Contract from the 
    list displayed. Click on <span className="doc-subtitle"> Search </span> {" "}
    
    <Button className="doc-button-icon" >
    <i className="pi pi-search icon" title="Search"  />
  </Button>{" "}   
    
    icon. The page will be redirected to <span> Inspection: Contracts </span> 
    page where the selected Customer will be listed.
    </p>
    </>
    
  );
}
export default SearchCustomerDocument;
