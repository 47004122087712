import React, { memo, useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { DataTableComponent } from "components/Table/DataTable";
import { BarChart, Bar, Cell, XAxis, Tooltip, Legend, ResponsiveContainer, PieChart, Pie } from "recharts";
import { PERFORMANCE_TABLE_COL } from "../../DashboardData";
import { customPieChartTooltip, customInspectionDetailsChartTooltip, customContractChartTooltip } from "../../utility";

import { useDispatch } from "react-redux";
import { useAppSelector } from "redux/hooks";
import { getInspectionDetails, getContractDetails, getSystemTypes } from "redux/pages/Dashboard/dashboardSlice";

interface PropType {
	defaultSite?: any;
}
const PerformaceIndicator: React.FC<PropType> = (props) => {

	const { defaultSite } = props;

	const colors = ['#009999', '#56C692', '#F4D03F', '#0090B2', '#6A6DB6', '#985397', '#AC3D67', '#FF8749', '#95B1B0', '#B47F2A'];
	const [key, setKey] = useState("graphView");
	const [pieChartData, setPieChartData] = useState<any>([]);
	const [tableData, setTableData] = useState<any>([]);

	const dispatch = useDispatch<any>();
	const inspectionDetails = useAppSelector((state) => state.dashboard.inspectionDetails);
	const contractDetails = useAppSelector((state) => state.dashboard.contractDetails);
	const systemTypes = useAppSelector((state) => state.dashboard.systemTypes);

	useEffect(() => {
		dispatch(getInspectionDetails());
		dispatch(getContractDetails());
		dispatch(getSystemTypes());
	}, [dispatch]);

	useEffect(() => {
		if (systemTypes && systemTypes.panelTypes) {
			let panelTypes = [...systemTypes.panelTypes];
			setTableData([...systemTypes.panelTypes]);
			if (panelTypes.length && panelTypes.length > 10) {
				panelTypes.length = 10;
				setPieChartData(panelTypes);
			} else {
				setPieChartData(panelTypes);
			}
		}
	}, [systemTypes]);

	const changeTab = (tab) => {
		if (tab !== key) {
			setKey(tab);
		}
	}

	const onSort = (event) => {
		if (tableData && tableData.length && (key === "tableView")) {
			if (event && event.sortField) {
				let sortDirection: string;
				if (event.sortOrder === 1) {
					sortDirection = "ASC";
				} else {
					sortDirection = "DESC";
				}
				let data = [...tableData];
				if (event.sortField === "panelTypeName") {
					const collator = new Intl.Collator("en", {
						numeric: true,
						sensitivity: "base"
					});
					if (sortDirection === "ASC") {
						data.sort((a, b) => collator.compare(a.panelTypeName, b.panelTypeName));
					} else if (sortDirection === "DESC") {
						data.sort((a, b) => collator.compare(b.panelTypeName, a.panelTypeName));
					}
				} else if (event.sortField === "usage") {
					if (sortDirection === "ASC") {
						data.sort((a, b) => a.usage - b.usage);
					} else if (sortDirection === "DESC") {
						data.sort((a, b) => b.usage - a.usage);
					}
				}
				setTableData(data);
			}
		}
	}

	return (
		<div className="performance-indicator">
			<div className="left">
				<div className="top">
					<div className="dashboard-charts__area">
						<div className="task-completion__wrapper">
							{(inspectionDetails && inspectionDetails.length) ?
								(<>
									<div className="title">
										<h6>90 Days Inspection Activity</h6>
									</div>
									<div className="bar-chart">
										<ResponsiveContainer width="100%" height="100%">
											<BarChart data={inspectionDetails}>
												<XAxis dataKey="name" axisLine={false} tick={false} height={0} />
												<Tooltip content={customInspectionDetailsChartTooltip} />
												<Legend margin={{ top: 16 }} />
												<Bar dataKey="Active" stackId="a" fill="#1981BE" />
												<Bar dataKey="Closed" stackId="a" fill="#95B1B0" />
												<Bar dataKey="Completed" stackId="a" fill="#009999" />
												<Bar dataKey="Inactive" stackId="a" fill="#FF856C" />
											</BarChart>
										</ResponsiveContainer>
									</div>
								</>) : (<></>)
							}
						</div>
					</div>
				</div>
				<div className="bottom">
					<div className="contracts-percentage">
						{(contractDetails && contractDetails.length) ?
							(<>
								<div className="title">
									<h6>Percentage of Contract Qty using TAP</h6>
								</div>
								<ResponsiveContainer width="100%" height="100%">
									<BarChart data={contractDetails}>
										<XAxis dataKey="name" axisLine={false} tick={false} height={0} />
										<Tooltip content={customContractChartTooltip} />
										<Legend margin={{ top: 16 }} />
										<Bar dataKey="SAP Contracts (Opportunity)" fill="#009999" />
										<Bar dataKey="Inspection Contracts (Used)" fill="#7886C2" />
									</BarChart>
								</ResponsiveContainer>
							</>) : (<></>)
						}
					</div>
				</div>
			</div>
			<div className="right">
				<div className="pie-graph__wrapper">
					{(pieChartData && pieChartData.length) ?
						(<>
							<div className="title">
								<h6>Mix of Systems Inspected (Last 12 Months)</h6>
							</div>
							<div className="tab-area">
								<Tabs id="pie-tab" activeKey={key} onSelect={changeTab} mountOnEnter={true} unmountOnExit={false}>
									<Tab eventKey="graphView" title="Graph View">
										<div className="pie-chart">
											<ResponsiveContainer>
												<PieChart>
													<Legend layout="horizontal" verticalAlign="top" align="center" wrapperStyle={{ fontSize: "10px" }} />
													<Pie
														data={pieChartData}
														cx="50%"
														cy="50%"
														startAngle={360}
														endAngle={0}
														labelLine={false}
														fill="#8884d8"
														dataKey="usage"
													>
														{pieChartData.map((entry, index) => (
															<Cell name={entry.panelTypeName} key={`cell-${index}`} fill={colors[index % colors.length]} />
														))}
													</Pie>
													<Tooltip content={customPieChartTooltip} />
												</PieChart>
											</ResponsiveContainer>
										</div>
									</Tab>
									<Tab eventKey="tableView" title="Table View">
										<div className="table-view-table h-100">
											<DataTableComponent
												title={``}
												rows={tableData}
												cols={PERFORMANCE_TABLE_COL}
												dataKeyId="uniquePanelTypeId"
												onSortData={onSort}
											/>
										</div>
									</Tab>
								</Tabs>
							</div>
						</>) : (<></>)
					}
				</div>
			</div>
		</div>
	);
};

export default memo(PerformaceIndicator);

