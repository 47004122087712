import React, { useEffect, useState } from 'react'
import { APP_ROUTES } from "constants/AppRoutes";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "components/common/Breadcrumb/Breadcrumb";
import ReportViewer from 'components/common/Report/ReportViewer'
import { Button } from 'primereact/button';
import { ListGroup } from 'react-bootstrap';
//icons
import { BsArrowRight, BsFileEarmarkPdf } from "react-icons/bs";
import { RiFileExcel2Line } from 'react-icons/ri';
import { useAppSelector } from 'redux/hooks';
import { useDispatch } from 'react-redux';
import { createReportPreviewSession, setReportPreviewSession } from 'redux/pages/Administration/Site/siteSlice';
import { onExportReport, setIsAppliedDone } from 'redux/common/report/customizeReportSlice';
import InformationBox from 'components/common/Message/InformationBox/InformationBox';
import { FiExternalLink } from 'react-icons/fi';
import CommonFilter from './CommonFilter';
import { getBranchOfficeListForSalesActivity, getEquipmentTypeList, getModelList, getPanelTypeList, setSelectedBranchOfficeData, setSelectedEquipmentTypeData, setSelectedModelData, setSelectedPanelTypedata } from 'redux/pages/Reports/SalesActivitySlice';

const SalesActivityReport = () => {
  const branchOffices = useAppSelector((state) => state.salesActivity.branchOfficeListForSalesActivity);
  const panelTypes = useAppSelector((state) => state.salesActivity.panelTypeData);
  const equipmentTypes = useAppSelector((state) => state.salesActivity.equipmentTypeData);
  const models = useAppSelector((state) => state.salesActivity.modelData);
  const selectedBranchOfficeData = useAppSelector((state) => state.salesActivity.selectedBranchOfficeData);
  const selectedPanelTypedata = useAppSelector((state) => state.salesActivity.selectedPanelTypedata);
  const selectedEquipmentTypeData = useAppSelector((state) => state.salesActivity.selectedEquipmentTypeData);
  const selectedModelData = useAppSelector((state) => state.salesActivity.selectedModelData);
  const reportPreviewSession = useAppSelector((state) => state.site.reportPreviewSession);

  const [selectedData, setSelectedData] = useState<any>([]);
  const [gridData, setGridData] = useState<any>([]);
  const [dataKey, setDataKey] = useState<string>("");
  const [field, setField] = useState<string>("");
  const [header, setHeader] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [filterIdentifier, setFilterIdentifier] = useState<string>("");
  const [showFilterModal, setShowFilterModal] = useState<boolean>(false);
  const [displayInfoModal, setDisplayInfoModal] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [reportName, setReportName] = useState<any>(null);

  const navigate = useNavigate();
  const dispatch = useDispatch<any>();

  useEffect(() => {
    dispatch(setSelectedBranchOfficeData([]));
    dispatch(setSelectedPanelTypedata([]));
    dispatch(setSelectedEquipmentTypeData([]));
    dispatch(setSelectedModelData([]));
  }, [])

  useEffect(() => {
    return (() => {
      dispatch(setReportPreviewSession(""));
    })
  }, []);

  useEffect(() => {
    if (filterIdentifier && filterIdentifier === "Branch Offices") {
      dispatch(getBranchOfficeListForSalesActivity());
    } else if (filterIdentifier && filterIdentifier === "Panel Types") {
      let branchOfficeNames: any = [];
      selectedBranchOfficeData?.forEach(element => {
        branchOfficeNames.push(element?.name.trim());
      });
      let combinedBranchOffice : any =[];
      combinedBranchOffice = branchOfficeNames.join('µ');
      const body = {
        branchOfficeNames: [combinedBranchOffice],
        panelTypeNames: [],
        equipmentClassNames: [],
        modelNames: [],
      }
      dispatch(getPanelTypeList(body));
    } else if (filterIdentifier && filterIdentifier === "Equipment Types") {
      let branchOfficeNames: any = [];
      selectedBranchOfficeData?.forEach(element => {
        branchOfficeNames.push(element?.name.trim());
      });
      let panelTypeNames: any = [];
      selectedPanelTypedata?.forEach(element => {
        panelTypeNames.push(element?.panelTypeName)
      })
      let combinedBranchOffice : any =[];
      let combinedPanelTypes : any =[];
      combinedBranchOffice = branchOfficeNames.join('µ');
      combinedPanelTypes = panelTypeNames.join('µ');
      const body = {
        branchOfficeNames: [combinedBranchOffice],
        panelTypeNames: [combinedPanelTypes],
        equipmentClassNames: [],
        modelNames: [],
      }
      dispatch(getEquipmentTypeList(body));
    } else if (filterIdentifier && filterIdentifier === "Models") {
      let branchOfficeNames: any = [];
      selectedBranchOfficeData?.forEach(element => {
        branchOfficeNames.push(element?.name.trim());
      });
      let panelTypeNames: any = [];
      selectedPanelTypedata?.forEach(element => {
        panelTypeNames.push(element?.panelTypeName.trim())
      })
      let equipmentClassNames: any = [];
      selectedEquipmentTypeData?.forEach(element => {
        equipmentClassNames.push(element?.equipmentClassName.trim())
      })
      let combinedBranchOffice : any =[];
      let combinedPanelTypes : any =[];
      let combinedEquipmentClassNames : any =[];
      combinedBranchOffice = branchOfficeNames.join('µ');
      combinedPanelTypes = panelTypeNames.join('µ');
      combinedEquipmentClassNames = equipmentClassNames.join('µ');
      const body = {
        branchOfficeNames: [combinedBranchOffice],
        panelTypeNames: [combinedPanelTypes],
        equipmentClassNames: [combinedEquipmentClassNames],
        modelNames: [],
      }
      dispatch(getModelList(body));
    }
  }, [filterIdentifier])

  useEffect(() => {
    if (filterIdentifier === "Branch Offices") {
      if (branchOffices)
        setGridData(branchOffices);
      else
        setGridData([]);
    }
    else if (filterIdentifier === "Panel Types") {
      if (panelTypes)
        setGridData(panelTypes);
      else
        setGridData([]);
    }
    else if (filterIdentifier === "Equipment Types") {
      if (equipmentTypes)
        setGridData(equipmentTypes);
      else
        setGridData([]);
    }
    else if (filterIdentifier === "Models") {
      if (models)
        setGridData(models);
      else
        setGridData([]);
    }
  }, [branchOffices, panelTypes, equipmentTypes, models])

  useEffect(() => {
    if (reportPreviewSession) {
      setReportName(reportPreviewSession.previewSessionId);
    }
  }, [reportPreviewSession])

  const onBranchOfficeFilterClick = () => {
    setTitle("Branch Offices");
    setHeader("Branch Office");
    setField("name");
    setDataKey("rowIndex");
    setFilterIdentifier("Branch Offices");
    setSelectedData(selectedBranchOfficeData);
    setShowFilterModal(true);
  }

  const onPanelTypeFilterClick = () => {
    if (!selectedBranchOfficeData?.length) {
      setDisplayInfoModal(true);
      setMessage("Select atleast one Branch Office");
      return;
    }
    setTitle("Panel Types");
    setHeader("Panel Type");
    setField("panelTypeName");
    setDataKey("rowIndex");
    setFilterIdentifier("Panel Types");
    setSelectedData(selectedPanelTypedata);
    setShowFilterModal(true);
  }

  const onEquipmentTypeFIlterClick = () => {
    if (!selectedBranchOfficeData?.length) {
      setDisplayInfoModal(true);
      setMessage("Select atleast one Branch Office");
      return;
    }
    if(!selectedPanelTypedata?.length)
    {
      setDisplayInfoModal(true);
      setMessage("Select atleast one Panel Type ");
      return;
    }
    setTitle("Equipment Types");
    setHeader("Equipment Type");
    setField("equipmentClassName");
    setDataKey("rowIndex");
    setFilterIdentifier("Equipment Types");
    setSelectedData(selectedEquipmentTypeData);
    setShowFilterModal(true);
  }

  const onModelFilterClick = () => {
    if (!selectedBranchOfficeData?.length) {
      setDisplayInfoModal(true);
      setMessage("Select atleast one Branch Office");
      return;
    }
    if(!selectedPanelTypedata?.length)
    {
      setDisplayInfoModal(true);
      setMessage("Select atleast one Panel Type ");
      return;
    }
    setTitle("Make / Model");
    setHeader("Model");
    setField("modelName");
    setDataKey("rowIndex");
    setFilterIdentifier("Models");
    setSelectedData(selectedModelData);
    setShowFilterModal(true);
  }

  const onApply = () => {
    if (!selectedBranchOfficeData?.length) {
      setDisplayInfoModal(true);
      setMessage("Select atleast one Branch Office");
      return;
    }
    if(!selectedPanelTypedata?.length)
    {
      setDisplayInfoModal(true);
      setMessage("Select atleast one Panel Type ");
      return;
    }
    let type = "SECTION_REPORT";
    let reportParameters = getReportParametersForReportandExcelExport(type);
    dispatch(createReportPreviewSession(reportParameters))
  };

  const exportAsExcel = () => {
    if (!selectedBranchOfficeData?.length) {
      setDisplayInfoModal(true);
      setMessage("Select atleast one Branch Office");
      return;
    }
    if(!selectedPanelTypedata?.length)
    {
      setDisplayInfoModal(true);
      setMessage("Select atleast one Panel Type ");
      return;
    }

    let type = "EXCEL";
    let exportParameters = getReportParametersForReportandExcelExport(type);
    dispatch(onExportReport(exportParameters, "Sales Opportunity Report"));
  };

  const getReportParametersForReportandExcelExport = (type: any) => {
    let branchOfficeNames: any = [];
    selectedBranchOfficeData?.forEach(element => {
      branchOfficeNames.push(element?.name.trim());
    });
    let panelTypeNames: any = [];
    selectedPanelTypedata?.forEach(element => {
      panelTypeNames.push(element?.panelTypeName.trim())
    })
    let equipmentClassNames: any = [];
    selectedEquipmentTypeData?.forEach(element => {
      equipmentClassNames.push(element?.equipmentClassName.trim())
    })
    let modelNames: any = [];
    selectedModelData?.forEach(element => {
        modelNames.push(element?.modelName.trim())
    })
    const reportParameters = {
      reportParameters: {
        branchOffices: branchOfficeNames.join('µ'),
        panelTypes: panelTypeNames.join('µ'),
        equipmentTypes: equipmentClassNames.join('µ'),
        models: modelNames.join('µ')
      },
      reportType: "Sales Opportunity Report"
    };
    const exportParameters = {
      exportParameters: {
        branchOffices: branchOfficeNames.join('µ'),
        panelTypes: panelTypeNames.join('µ'),
        equipmentTypes: equipmentClassNames.join('µ'),
        models: modelNames.join('µ')
      },
      exportType: "Sales Opportunity Report"
    };
    if (type === "SECTION_REPORT")
      return reportParameters;
    else if (type === "EXCEL")
      return exportParameters;
  }

  return (

    <div className='salesactivity-reports'>
      <section className="d-flex align-items-center">
        <Breadcrumb
          title="Reports"
          classNames="report-header"
          handleGoBackClick={() => navigate(`/${APP_ROUTES.REPORT_VIEW}`)}
        />
        <div className="breadcrumb-right-arrow ml-3 mr-3">
          <BsArrowRight className="icon" />
        </div>
        <div className="breadcrumb-sub-page-title">
          <span>Sales Opportunity Report</span>
        </div>
      </section>

      <div className="report-sort-field pt-2 pb-2">
        <div className="report-export-main">

          <div className='btn-wrapper'>
            <div>
              <Button className='box-btn' onClick={() => onBranchOfficeFilterClick()}>
                Branch Office&nbsp;<label style={{ color: 'red' }}>*</label>
                <FiExternalLink />
              </Button>
              <Button className='box-btn' onClick={() => onPanelTypeFilterClick()}>
                Panel Type&nbsp;<label style={{ color: 'red' }}>*</label><FiExternalLink />
              </Button>
              <Button className='box-btn' onClick={() => onEquipmentTypeFIlterClick()}>
                Equipment Type <FiExternalLink />
              </Button>
              <Button className='box-btn' onClick={() => onModelFilterClick()}>
                Model<FiExternalLink />
              </Button>
            </div>
            <div className='apply-button'>
              <Button label="Apply" className="submit-btn ml-2" autoFocus onClick={() => onApply()} />
            </div>
          </div>
          <div className="report-buttons-main d-flex">
            <div className='export-report-buttons'>
              <ListGroup horizontal>
                <span className='expor-title'>Export to</span>
                <ListGroup.Item>
                  <Button className="export-btn" onClick={() => exportAsExcel()}> <RiFileExcel2Line className="icon" /> Excel </Button>
                </ListGroup.Item>
              </ListGroup>
            </div>
          </div>
        </div>
      </div>
      {showFilterModal ? <CommonFilter
        showModal={showFilterModal}
        gridData={gridData}
        selectedData={selectedData}
        onHide={(checkedData) => {
          setFilterIdentifier("");
          setShowFilterModal(false);
          setSelectedData(checkedData)
        }}
        field={field}
        header={header}
        title={title}
        dataKey={dataKey}
      /> : null}
      <ReportViewer reportId={reportName} />
      <InformationBox
        showInfoModal={displayInfoModal}
        setShowInfoModal={setDisplayInfoModal}
        message={message}
      />
    </div>
  )
}

export default SalesActivityReport;