import React from "react";
import { TabView, TabPanel } from 'primereact/tabview';
import { useAppSelector } from "redux/hooks";
import UnassignedEquipments from "./UnassignedEquipments";
import CommonEquipments from "./CommonEquipments";
import EquipmentSpecificList from "./EquipmentSpecificList";


export interface NavItemProps {
  id: number;
  name: string;
  url: string;
  active: boolean;
}
interface IInspectionDrawingList {
  selectedTask?: any;
  onShowZoomClick: (data: any,showZoomed:boolean) => void;
  onShowInDrawing: (data: any,showDrawing:boolean) => void;
  onAddEquipment: (data: any) => void;
  onDelinkClick: (data: any) => void;
  onDeleteClick: (data: any) => void;
  disableDrawingButtons?:any;
 }

export const InspectionDrawingList: React.FC<IInspectionDrawingList> = (props) => {
	const { selectedTask, onShowZoomClick,onAddEquipment,onShowInDrawing, onDelinkClick, onDeleteClick,disableDrawingButtons } = props;

  const userPrivileges = useAppSelector((state) => state.user.userPrivileges);
 
  return (
    <div className="inspection-reports-drawing as-build-table">
      <TabView>
         <TabPanel header="Common">
         <CommonEquipments selectedTask={selectedTask} onShowZoomClick={props.onShowZoomClick} onShowInDrawing={props.onShowInDrawing} onDelinkClick={props.onDelinkClick} onDeleteClick={onDeleteClick} disableDrawingButtons={disableDrawingButtons}/>
        </TabPanel> 
        <TabPanel header="Equipment Specific">
          <EquipmentSpecificList selectedTask={selectedTask} onShowZoomClick={props.onShowZoomClick} onShowInDrawing={props.onShowInDrawing} onDeleteClick={onDeleteClick} onDelinkClick={props.onDelinkClick} />
        </TabPanel>
        <TabPanel header="Unassigned">
          <UnassignedEquipments selectedTask={selectedTask} onAddEquipment={props.onAddEquipment} disableDrawingButtons={disableDrawingButtons}/>
        </TabPanel>
      </TabView>
 

    </div>
  );
};

export default InspectionDrawingList;
