import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { useDispatch } from 'react-redux';
import { getInspectionReportList, checkLinkedReports, setLinkedReportsData } from 'redux/pages/Inspection/InspectionTask/inspectionTaskSlice';
import { useAppSelector } from 'redux/hooks';
import { showUI } from 'service/GeneralUtility';
import { ProgressBar } from 'primereact/progressbar';
import InformationBox from 'components/common/Message/InformationBox/InformationBox';

interface Props {
    showModal: boolean,
    setShowModal: (data: boolean) => void,
    selectedReports: any,
    isAdd?: boolean,
    showInfoCard?: boolean
    saveReportClick?: (selection) => void,
    selectedRows?: any,
}
export const InspectionReports: React.FC<Props> = ({
    
    showModal = false,
    setShowModal = () => { },
    selectedReports = [],
    isAdd = true,
    showInfoCard = false,
    saveReportClick = () => { },
    selectedRows = ''
}) => {

    const inspectionReportList = useAppSelector((state) => state.inspectionTask.inspectionReportList);
    const userPrivileges = useAppSelector((state) => state.user.userPrivileges);
    const linkedReportsData = useAppSelector((state) => state.inspectionTask.linkedReportsData);

    const [selection, setSelection] = useState<any>(null);
    const [linkedReportSelection, setLinkedReportSelection] = useState<any>(null)
    const [reports, setReports] = useState<any>([]);
    const [reportList, setReportList] = useState([]);
    const [taskName, setTaskName] = useState("");
    const [technician, setTechnician] = useState("");
    const [displayInfoModal, setDisplayInfoModal] = useState(false);
    const [message, setMessage] = useState("");
    const dispatch = useDispatch<any>();
    const [fetchComplete, setFetchComplete] = useState(false);
    const [isLinkedReports, setIsLinkedReports] = useState(false);
    const [linkedReports, setLinkedReports] = useState<any>([]);
    useEffect(() => {
        if (selectedReports) {
            setSelection(selectedReports);
        }
    }, [selectedReports]);

    useEffect(() => {
        if (linkedReportsData) {
            if (linkedReportsData?.isLinkedReportsExists) {
                if (isLinkedReports) {
                    setMessage("Select at least one report in each category to proceed");
                    setDisplayInfoModal(true)
                } else {
                    let reportData: any = [];
                    linkedReportsData.linkedReportDetails.map((item: any) => {
                        reportData.push(item)
                        return true;
                    })
                    setFetchComplete(true)
                    setIsLinkedReports(true)
                    setLinkedReports(reportData)
                }
            } else if (linkedReportsData?.isLinkedReportsExists === false) {
                let mergedData = selection?.concat(linkedReportSelection);
                let updatedSelection: any = [];
                inspectionReportList?.reportGroupDetails?.map((item: any) => {
                    item?.inspectionReport?.map((reportItem) => {
                        mergedData.map((selectionItem) => {
                            if (selectionItem?.inspectionReportId === reportItem?.inspectionReportId ||
                                selectionItem?.nfpaInspectionReportId === reportItem?.inspectionReportId) {
                                updatedSelection.push(reportItem)
                            }
                        })
                    })
                })
                if (updatedSelection?.length) {
                    saveReportClick(updatedSelection)
                }
            }
        }
    }, [linkedReportsData])

    useEffect(() => {
        dispatch(getInspectionReportList())
        if (!isAdd) {
            setTaskName(selectedRows[0].inspectionTaskName)
            setTechnician(selectedRows[0].leadTechnician)
        }
    }, [dispatch, isAdd, selectedRows])

    useEffect(() => {
        if (inspectionReportList.reportGroupDetails?.length > 0) {

            let reportData: any = [];
            let reportGroupData: any = [];
            inspectionReportList.reportGroupDetails.map((item: any) => {
                if ([2, 4, 7].includes(item?.reportGroupId)) {
                    reportData.push.apply(reportData, item.inspectionReport)
                    reportGroupData.push({ id: item.reportGroupId, value: item.reportGroupName, checked: true })
                } else {
                    var isData = false;
                    item?.inspectionReport?.forEach(element => {
                        const data = selectedReports?.filter((item) => item.inspectionReportId === element?.inspectionReportId);
                        if (data?.length)
                            isData = true;
                    });
                    if (isData)
                        reportData.push.apply(reportData, item.inspectionReport)
                    reportGroupData.push({ id: item.reportGroupId, value: item.reportGroupName, checked: isData })
                }
            })
            setFetchComplete(true);
            setReports(reportGroupData);
          
            reportData.sort((a, b) => a.positionNo-b.positionNo);
            setReportList(reportData);
        }
    }, [inspectionReportList, selectedReports])

    const onReportsChange = (e: { value: any, checked: boolean }) => {
        let reportGroup = [...reports];
        let reportListCopy: any = [...inspectionReportList.reportGroupDetails];

        reportGroup.find((item: any) => item.value === e.value).checked = e.checked
        let checkedReportGroup = reportGroup.filter((el: any) => el.checked === true);
        let checkedReportList: any = [];
        reportListCopy.map((item: any) => {
            if (checkedReportGroup.find((el: any) => el.id === item.reportGroupId)) {
                checkedReportList.push.apply(checkedReportList, item.inspectionReport)
            }
            return checkedReportList;
        })
        checkedReportList.sort((a, b) => a.positionNo-b.positionNo);
        setReportList(checkedReportList);
        setReports(reportGroup);
    }

    const onSaveReports = () => {
        if (selection === null || selection?.length === 0) {
            setMessage("Selected Inspection Task is active, atleast one report should be mapped")
            setDisplayInfoModal(true);
        }
        else {
            if (!isLinkedReports) {
                let selectedReportIds = selection.map((item) => item.inspectionReportId)
                let body = {
                    reportIds: selectedReportIds
                };
                dispatch(checkLinkedReports(body))
            } else {
                let selectedReportIds = selection.map((item) => item.inspectionReportId)
                let selected_linkedReportIds = linkedReportSelection.map((item) => item.nfpaInspectionReportId);
                let mergedIds = selectedReportIds?.concat(selected_linkedReportIds);
                let body = {
                    reportIds: mergedIds
                };
                dispatch(checkLinkedReports(body))
            }

        }
    }

    const changesSaved = () => {
        if (!isLinkedReports) return;
        if (!linkedReportSelection?.length) {
            setDisplayInfoModal(true);
            setMessage("At least one of the following Linked Reports should be selected before updating.");
            return;
        }

        let selectedReportIds = selection.map((item) => item.inspectionReportId)
        let selected_linkedReportIds = linkedReportSelection.map((item) => item.nfpaInspectionReportId);
        let mergedIds = selectedReportIds?.concat(selected_linkedReportIds);
        let body = {
            reportIds: mergedIds
        };
        dispatch(checkLinkedReports(body));
    }

    const resetModal = () => {
        dispatch(setLinkedReportsData(""))
        setIsLinkedReports(false);
        setReportList([])

    }
    return (
    
        <Dialog
            header="Reports Accessible to an Inspection Task"
            visible={showModal}
            style={{ width: '55vw', maxHeight: '100%' }}
            onHide={() => { setShowModal(false); resetModal() }}
            className='reports-modal'
        >
            {fetchComplete ?
                (<div className="content-box">
                    {!isLinkedReports && <div className="report-list">
                        {showInfoCard && <div className="card info-section">
                            <div className="box">
                                <div className="title">Inspection Task</div>
                                <div className="value">{taskName}</div>
                            </div>
                            <div className="box">
                                <div className="title">Lead Technician</div>
                                <div className="value">{technician}</div>
                            </div>
                        </div>}
                        <div className={`card select-section ${showInfoCard ? '' : ''}`}>
                            {reports && reports.length ?
                                (reports.map((item: any, key: any) => (
                                    <div className="field-checkbox" key={key}>
                                        <Checkbox tooltip={item.value} inputId={item.id} id={item.id} name={item.id} value={item.value} onChange={onReportsChange} checked={item.checked} />
                                        <label htmlFor={item.id}>{item.value}</label>
                                    </div>
                                ))) : null
                            }
                        </div>
                    </div>

                    }
                    {isLinkedReports && <div className='sub-header'> Note: At least one of the following Linked Reports should be selected before updating.</div>}
                    <div className="report-table mt-3">
                        {!isLinkedReports &&
                            <DataTable
                                value={reportList}
                                selectionMode="checkbox"
                                selection={selection}
                                onSelectionChange={e => setSelection(e.value)}
                                dataKey="inspectionReportId"
                            >
                                <Column selectionMode="multiple" headerStyle={{ width: '2rem' }}></Column>
                                <Column field="inspectionReportName" header="Name" />
                                <Column field="reportTitle" header="Report Title" />
                            </DataTable>
                        }
                        {isLinkedReports &&
                            <DataTable
                                value={linkedReports}
                                selectionMode="checkbox"
                                selection={linkedReportSelection}
                                onSelectionChange={e => setLinkedReportSelection(e.value)}
                                dataKey="nfpaInspectionReportId"
                            >
                                <Column selectionMode="multiple" headerStyle={{ width: '2rem' }}></Column>
                                <Column field="nfpaInspectionReportName" header="NFPA_InspectionReports" />
                                <Column field="reportTitle" header="Linked Reports" />
                                <Column field="ep_dnv_InspectionReports" header="EP_DNV_InspectionReports" />
                            </DataTable>
                        }
                    </div>
                    <div className='footer text-left'>
                        <Button label="Cancel" onClick={() => { resetModal(); setShowModal(false); }} className='cancel-btn' />
                        {isLinkedReports ?
                            <Button label="Save Changes" onClick={changesSaved} className='submit-btn' /> :
                            (
                                <>
                                    {selectedRows?.length > 0 ?
                                        showUI(userPrivileges, "SIETAP_INSPECTION_REPORTSSELECTED_UPDATE") &&
                                        selectedRows[0].status != "Closed" &&
                                        selectedRows[0]?.status != "Completed" &&
                                        selectedRows[0]?.status != "Inactive" &&
                                        <Button label="Save Changes" onClick={onSaveReports} className='submit-btn' /> :
                                        showUI(userPrivileges, "SIETAP_INSPECTION_REPORTSSELECTED_UPDATE") &&
                                        <Button label="Save Changes" onClick={onSaveReports} className='submit-btn' />}
                                </>
                            )
                        }
                    </div>
                </div>) :
                (<div className="common-progressbar">
                    <ProgressBar mode="indeterminate" />
                </div>)}
            <InformationBox showInfoModal={displayInfoModal} setShowInfoModal={setDisplayInfoModal} okOnClick={() => setDisplayInfoModal(false)} message={message} />
        </Dialog>
    );
}

export default InspectionReports;