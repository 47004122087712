import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import Breadcrumb from "components/common/Breadcrumb/Breadcrumb";
import InformationBox from "components/common/Message/InformationBox/InformationBox";
import ConfirmationBox from "components/common/Message/ConfirmationBox/ConfirmationBox";
import { BsArrowRight } from "react-icons/bs";
import Table from "components/Table/Table";
import Filters from "components/Filters/Filters";

import { ORGANIZATION_TYPES } from "constants/common";
import { APP_ROUTES } from "constants/AppRoutes";

import AddZone from "./ManageZone/AddZone";
import AddBranchOffice from "./ManageBranchOffice/AddBranchOffice";

import EditSalesOffice from "./ManageSalesOffice/EditSalesOffice";

import AddSalesOffice from "./ManageSalesOffice/AddSalesOffice";
import Techs from "./ManageSalesOffice/Techs"
import EditZone from "./ManageZone/EditZone";

import EditBranch from "./ManageBranchOffice/EditBranchOffice";

import { ORG_COLS } from "./OrganizationTableData";

import { useDispatch } from "react-redux";
import { useAppSelector } from "../../redux/hooks";
import { getZoneList, onAddZone, onEditZone, deleteZone} from "redux/pages/Manage/OrganizationSettings/zoneSlice";
import { getSalesOfficeList, onAddSalesOffice, onEditSalesOffice, deleteSalesOffice, assignTechnicians,setSalesOfficeList} from "redux/pages/Manage/OrganizationSettings/salesOfficeSlice";
import { getBranchOfficeList, onAddBranchOffice, onEditBranchOffice, deleteBranchOffice} from "redux/pages/Manage/OrganizationSettings/branchOfficeSlice";
import OrganizationReport from "pages/Organization/Reports/OrganizationReports";
import PopOver from "components/common/PopOver/PopOver";
import { OverlayPanel } from "primereact/overlaypanel";
import { onExportReport } from "redux/common/report/customizeReportSlice";
interface IOrganization {}

export const Organization: React.FC<IOrganization> = () => {
	const navigate = useNavigate();

	let {
		state: { from },
	} = useLocation() as any;
	const dispatch = useDispatch<any>();
	const downloadRef = React.createRef<OverlayPanel>();

	const [showEditBranchModal, setShowEditBranchModal] = useState<boolean>(false);
	const [showEditSalesModal, setShowEditSalesModal] = useState<boolean>(false);

	const zoneData = useAppSelector((state) => state.zone.zoneList);
	const zonePageDetails = useAppSelector((state) => state.zone.zonePageDetails);
	const salesOfficeData = useAppSelector((state) => state.salesOffice.salesOfficeList);
	const salesOfficePageDetails = useAppSelector((state) => state.salesOffice.salesOfficePageDetails);
	const branchofficeData = useAppSelector((state) => state.branchOffice.branchOfficeList);
	const branchOfficePageDetails = useAppSelector((state) => state.branchOffice.branchOfficePageDetails);
	const rowsPerPage = useAppSelector((state) => state.personalSettings.rowsPerPage);

	const [pageIndex, setPageIndex] = useState(0);
	const [tableColumns, setTableColumns] = useState<any>([]);
	const [tableRows, setTableRows] = useState<any>([]);
	const [showGrid, setShowGrid] = useState(false);
	const [selectedRows, setSelectedRows] = useState<any>([]);
	const [message, setMessage] = useState("");
	const [isDelete, setIsDelete] = useState(false);
	const [showTechs, setShowTechs] = useState(false);
	const [displayInfoModal, setDisplayInfoModal] = useState(false);
	const [displayConfirmModal, setDisplayConfirmModal] = useState(false);
	const [showAddZoneModal, setShowAddZoneModal] = useState(false);
	const [showAddSalesModal, setShowAddSalesModal] = useState(false);
	const [showAddBranchModal, setShowAddBranchModal] = useState(false);
	const [showEditZoneModal, setShowEditZoneModal] = useState(false);
	const [currentStart, setCurrentStart] = useState(1);
    const [sortField, setSortField] = useState('')
    const [sortOrder, setSortOrder] = useState('');
    const [keyWord, setKeyWord] = useState('');
	const [zoneSearchkeyWord, setZoneSearchKeyWord] = useState('');
	const [isLazyLoading, setIsLazyLoading] = useState(true);
	const [showReportModal, setShowReportModal] = useState(false);
	const [reportFlag, setReportFlag] = useState<any>('all');
	const showSubtitle = useRef("Zone");
	const [zonePagination, setZonePagination] = useState<any>({});
	const [salesOfficePagination, setSalesOfficePagination] = useState<any>({});
	const [branchOfficePagination, setBranchOfficePagination] = useState<any>({});

	useEffect(() => {
		if(salesOfficeData.length>0)
		dispatch(setSalesOfficeList([]));
		setPageIndex(0);
		switch (from) {
			case ORGANIZATION_TYPES.ZONE:
				setIsLazyLoading(true);
				dispatch(getZoneList(setShowGrid, 1, sortField, sortOrder));
				break;
			case ORGANIZATION_TYPES.SALES:
				setIsLazyLoading(true);
				dispatch(getSalesOfficeList(setShowGrid, 1, sortField, sortOrder));
				break;
			case ORGANIZATION_TYPES.BRANCH:
				setIsLazyLoading(true);
				dispatch(getBranchOfficeList(setShowGrid, 1, sortField, sortOrder));
				break;
			default:
				setIsLazyLoading(false);
				dispatch(getZoneList(setShowGrid, 1, sortField, sortOrder));
				break;
		}
	}, [dispatch, from, rowsPerPage]);

	useEffect(() => {
		if (zonePageDetails) {
		  setZonePagination(zonePageDetails);
		}
		if (salesOfficePageDetails) {
		  setSalesOfficePagination(salesOfficePageDetails);
		}
		if (branchOfficePageDetails) {
		  setBranchOfficePagination(branchOfficePageDetails);
		}
	  }, [zonePageDetails, salesOfficePageDetails, branchOfficePageDetails]);

	useEffect(() => {
		if (from && rowsPerPage && rowsPerPage > 0) {
			let modifiedPageDetails: any = {};
			switch (from) {
				case ORGANIZATION_TYPES.ZONE:
					modifiedPageDetails = { ...zonePagination, 'currentLength': rowsPerPage, 'pageCount': rowsPerPage };
					setZonePagination(modifiedPageDetails);
					setCurrentStart(1)
					setPageIndex(0)
					break;
				case ORGANIZATION_TYPES.SALES:
					modifiedPageDetails = { ...salesOfficePagination, 'currentLength': rowsPerPage, 'pageCount': rowsPerPage };
					setSalesOfficePagination(modifiedPageDetails);
					setCurrentStart(1)
					setPageIndex(0)
					break;
				case ORGANIZATION_TYPES.BRANCH:
					modifiedPageDetails = { ...branchOfficePagination, 'currentLength': rowsPerPage, 'pageCount': rowsPerPage };
					setBranchOfficePagination(modifiedPageDetails);
					setCurrentStart(1)
					setPageIndex(0)
					break;
				default:
					modifiedPageDetails = { ...zonePagination, 'currentLength': rowsPerPage, 'pageCount': rowsPerPage };
					setZonePagination(modifiedPageDetails);
					setCurrentStart(1)
					setPageIndex(0)
					break;
			}
		}
	  }, [rowsPerPage])

	const onPageChanged = (options: any) => {
		if (options !== pageIndex) {
			let currentStart = options * getOrgPageDetails()?.currentLength + 1;
			setCurrentStart(currentStart)
			setPageIndex(options);
			switch (from) {
				case ORGANIZATION_TYPES.ZONE:
					dispatch(getZoneList(setShowGrid, currentStart,keyWord, sortField, sortOrder));
					break;
				case ORGANIZATION_TYPES.SALES:
					dispatch(getSalesOfficeList(setShowGrid, currentStart, keyWord, sortField, sortOrder));
					break;
				case ORGANIZATION_TYPES.BRANCH:
					dispatch(getBranchOfficeList(setShowGrid, currentStart, keyWord, sortField, sortOrder));
					break;
				default:
					dispatch(getZoneList(setShowGrid, currentStart, keyWord, sortField, sortOrder));
					break;
			}
		}
	};

	const onGlobalSearch = (filter) => {
        setKeyWord(filter)
		switch (from) {
			case ORGANIZATION_TYPES.SALES:
				dispatch(getSalesOfficeList(setShowGrid, 1, filter, sortField, sortOrder));
				break;
			case ORGANIZATION_TYPES.BRANCH:
				dispatch(getBranchOfficeList(setShowGrid, 1, filter, sortField, sortOrder));
				break;
			default:
				dispatch(getZoneList(setShowGrid, 1, filter, sortField, sortOrder));
				break;
		}
    }

	const onGridDataSearch = (searchData) => {
		switch (from) {
			case ORGANIZATION_TYPES.ZONE:
				setZoneSearchKeyWord(searchData);
				break;
			case ORGANIZATION_TYPES.SALES:
				setKeyWord(searchData);
				break;
			case ORGANIZATION_TYPES.BRANCH:
				setKeyWord(searchData);
				break;
			default:
				setZoneSearchKeyWord(searchData);
				break;
		}
	}

    const onSort = (event) => {
        let sortDirection: string;
        if (event.sortOrder === 1)
        sortDirection = "ASC"
        else
        sortDirection = "DESC"
        setSortField(event.sortField)
        setSortOrder(sortDirection)
		switch (from) {
			case ORGANIZATION_TYPES.ZONE:
				dispatch(getZoneList(setShowGrid, currentStart, keyWord, event.sortField, sortDirection));
				break;
			case ORGANIZATION_TYPES.SALES:
				dispatch(getSalesOfficeList(setShowGrid, currentStart, keyWord, event.sortField, sortDirection));
				break;
			case ORGANIZATION_TYPES.BRANCH:
				dispatch(getBranchOfficeList(setShowGrid, currentStart, keyWord, event.sortField, sortDirection));
				break;
			default:
				dispatch(getZoneList(setShowGrid, currentStart, keyWord, event.sortField, sortDirection));
				break;
		}
    }

	const handleTabChange = (from) => {
		navigate(`/${APP_ROUTES.MANAGE_ORGANIZATION}`, {
			state: {
				from,
			},
		});
	};

	useEffect(() => {
		setShowGrid(false);
		setTableColumns([]);
		setKeyWord("");
		setZoneSearchKeyWord('');
		setSortField("");
		setSortOrder("");
		setTableColumns(getOrgTableCols(from));
	}, [from]);

	useMemo(() => {
		const getOrgTableRows = () => {
			switch (from) {
				case ORGANIZATION_TYPES.ZONE: {
					return zoneData;
				}
				case ORGANIZATION_TYPES.SALES: {
					return salesOfficeData;
				}
				case ORGANIZATION_TYPES.BRANCH: {
					return branchofficeData;
				}
				default: {
					return zoneData;
				}
			}
		};
		setTableRows(getOrgTableRows());
	}, [from, zoneData, salesOfficeData, branchofficeData]);

	useEffect(() => {
		setShowGrid(true);
	}, [tableColumns]);

	const handleRowSelect = useCallback((data: any) => {
		setSelectedRows([...data]);
	}, []);

	const onAddZoneClick = (submitData: any) => {
		dispatch(onAddZone(submitData, setMessage, setDisplayInfoModal, setShowAddZoneModal));
	};
	const onEditZoneClick = (submitData: any) => {
		dispatch(onEditZone(submitData, selectedRows[0].uniqueBusinessZoneId, setMessage, setDisplayInfoModal, setShowEditZoneModal));
	};
	const deleteZoneClick = () => {
		if (selectedRows.length > 1) {
			setMessage("This operation can be performed only one row at a time");
			setDisplayInfoModal(true);
		} else if (selectedRows.length < 1) {
			setMessage("Select a Zone");
			setDisplayInfoModal(true);
		} else if (selectedRows.length === 1) {
			setIsDelete(true);
			zoneData.map((item: any) => {
				if (selectedRows[0].uniqueBusinessZoneId === item.uniqueBusinessZoneId) {
					if (item.isZoneInUse === true) {
						setMessage("Zone is in use, cannot be deleted");
						setDisplayInfoModal(true);
					} else {
						setMessage("Are you sure you want to delete the selected Zone?");
						setDisplayConfirmModal(true);
					}
				}
				return null;
			});
		}
	};

	const onEditIconClick = () => {
		if (selectedRows.length > 1) {
			setMessage("This operation can be performed only one row at a time");
			setDisplayInfoModal(true);
		} else {
			switch (from) {
				case ORGANIZATION_TYPES.SALES: {
					if (selectedRows.length < 1) {
						setMessage("Select a Sales Office");
						setDisplayInfoModal(true);
					} else
						setShowEditSalesModal(true);
					break;
				}
				case ORGANIZATION_TYPES.ZONE: {
					if (selectedRows.length < 1) {
						setMessage("Select a Zone");
						setDisplayInfoModal(true);
					} else
						setShowEditZoneModal(true);

					break;
				}
				case ORGANIZATION_TYPES.BRANCH: {
					if (selectedRows.length < 1) {
						setMessage("Select a Branch Office");
						setDisplayInfoModal(true);
					} else
						setShowEditBranchModal(true);
					break;
				}
			}
		}
	}


	const onAddSalesOfficeClick = (submitData: any) => {
		dispatch(onAddSalesOffice(submitData, setMessage, setDisplayInfoModal, setShowAddSalesModal));
	};
	const onEditSalesOfficeClick = (submitData: any) => {
		dispatch(onEditSalesOffice(submitData, selectedRows[0].uniqueSalesOfficeId, setMessage, setDisplayInfoModal, setShowEditSalesModal));
	};
	const deleteSalesOfficeClick = () => {
		if (selectedRows.length > 1) {
			setMessage("This operation can be performed only one row at a time");
			setDisplayInfoModal(true);
		} else if (selectedRows.length < 1) {
			setMessage("Select a Sales Office");
			setDisplayInfoModal(true);
		} else if (selectedRows.length === 1) {
			setIsDelete(true);
			salesOfficeData.map((item: any) => {
				if (selectedRows[0].uniqueSalesOfficeId === item.uniqueSalesOfficeId) {
					if (item.isSalesOfficeInUse === true) {
						setMessage("Sales Office is in use, cannot be deleted");
						setDisplayInfoModal(true);
					} else {
						setMessage("Are you sure you want to delete the selected Sales Office?");
						setDisplayConfirmModal(true);
					}
				}
				return null;
			});
		}
	};
	const onAddBranchOfficeClick = (submitData: any) => {
		dispatch(onAddBranchOffice(submitData, setMessage, setDisplayInfoModal, setShowAddBranchModal));
	};
	const onEditBranchOfficeClick = (submitData: any) => {
		dispatch(onEditBranchOffice(submitData, selectedRows[0].uniqueBranchOfficeId, setMessage, setDisplayInfoModal, setShowEditBranchModal));
	};
	const deleteBranchOfficeClick = () => {
		if (selectedRows.length > 1) {
			setMessage("This operation can be performed only one row at a time");
			setDisplayInfoModal(true);
		} else if (selectedRows.length < 1) {
			setMessage("Select a Branch Office");
			setDisplayInfoModal(true);
		} else if (selectedRows.length === 1) {
			setIsDelete(true);
			branchofficeData.map((item: any) => {
				if (selectedRows[0].uniqueBranchOfficeId === item.uniqueBranchOfficeId) {
					setMessage("Are you sure you want to delete the selected Branch Office?");
					setDisplayConfirmModal(true);
				}
				return null;
			});
		}
	};
	const OnConfirmClick = () => {
		if (isDelete) {
			setDisplayConfirmModal(false);
			if (selectedRows.length === 1) {
				switch (from) {
					case ORGANIZATION_TYPES.ZONE: {
						const uniqueBusinessZoneId = selectedRows[0].uniqueBusinessZoneId;
						dispatch(
							deleteZone({
								uniqueBusinessZoneId,
								setSelectedRows,
								setMessage,
								setDisplayInfoModal,
							})
						);
						break;
					}
					case ORGANIZATION_TYPES.SALES: {
						const uniqueSalesOfficeId = selectedRows[0].uniqueSalesOfficeId;
						dispatch(
							deleteSalesOffice({
								uniqueSalesOfficeId,
								setSelectedRows,
								setMessage,
								setDisplayInfoModal,
							})
						);
						break;
					}
					case ORGANIZATION_TYPES.BRANCH: {
						const uniqueBranchOfficeId = selectedRows[0].uniqueBranchOfficeId;
						dispatch(
							deleteBranchOffice({
								uniqueBranchOfficeId,
								setSelectedRows,
								setMessage,
								setDisplayInfoModal,
							})
						);
						break;
					}
				}
			}
			setIsDelete(false);
		}
	};
	const getOrgTableCols = (from) => {
		switch (from) {
			case ORGANIZATION_TYPES.ZONE: {
				return ORG_COLS.ZONE_COLS;
			}
			case ORGANIZATION_TYPES.SALES: {
				return ORG_COLS.SALES_COLS;
			}
			case ORGANIZATION_TYPES.BRANCH: {
				return ORG_COLS.BRANCH_COLS;
			}
			default: {
				return ORG_COLS.ZONE_COLS;
			}
		}
	};

	const getOrgPageDetails = useCallback(() => {
		if (from) {
			switch (from) {
				case ORGANIZATION_TYPES.ZONE: {
					return zonePagination;
				}
				case ORGANIZATION_TYPES.SALES: {
					return salesOfficePagination;
				}
				case ORGANIZATION_TYPES.BRANCH: {
					return branchOfficePagination;
				}
				default: {
					return zonePagination;
				}
			}
		}
	}, [from, zonePagination, salesOfficePagination, branchOfficePagination]);

	const getOrgTableTitle = useCallback(() => {
		switch (from) {
			case ORGANIZATION_TYPES.ZONE: {
				showSubtitle.current = "Zone";
				return "Zone";
			}
			case ORGANIZATION_TYPES.SALES: {
				showSubtitle.current = "Sales Office";
				return "Sales Office";
			}
			case ORGANIZATION_TYPES.BRANCH: {
				showSubtitle.current = "Branch Office";
				return "Branch Office";
			}
			default: {
				return "Zone";
			}
		}
	}, [from]);

	const getOrgTableDatakey = useCallback(() => {
		switch (from) {
			case ORGANIZATION_TYPES.ZONE: {
				return "uniqueBusinessZoneId";
			}
			case ORGANIZATION_TYPES.SALES: {
				return "uniqueSalesOfficeId";
			}
			case ORGANIZATION_TYPES.BRANCH: {
				return "uniqueBranchOfficeId";
			}
			default: {
				return "Zone";
			}
		}
	}, [from]);

	const getSettingsItem = useCallback((rowSelected) => {
		switch (from) {
			case ORGANIZATION_TYPES.SALES: {
				return [
					{
						id: 1,
						name: "Techs",
						onClick: () => {
							if (rowSelected && rowSelected.length) {
								if (rowSelected.length === 1) {
									setShowTechs(true);
								} else {
									setMessage("This operation can be performed only one row at a time");
									setDisplayInfoModal(true);
								}
							}
						},
					},
				];
			}
		}
	}, [from]);

	const onSelectTechnician = ((salesOfficeSelected, body, setLoader) => {
		dispatch(assignTechnicians(salesOfficeSelected, body, setLoader, setShowTechs, setMessage, setDisplayInfoModal));
	});
	
	const onReportAllClick = () => {
		setReportFlag('all');
		setShowReportModal(true);
	};

	const onReportHighlightedClick = () => {
		setReportFlag('highlighted');
		setShowReportModal(true);
	};

	const getSelectedZonesId = () => {
        let params: any = [];
        selectedRows.map(item => {
            params.push(item.uniqueBusinessZoneId);
        });
        return params;
    };

    const getSelectedSalesOfficeId = () => {
        let params: any = [];
        selectedRows.map(item => {
            params.push(item.uniqueSalesOfficeId);
        });
        return params;
    };

    const setSelectedBranchOfficeId = () => {
        let params: any = [];
        selectedRows.map(item => {
            params.push(item.uniqueBranchOfficeId);
        });
        return params;
    };

	const onExportAll = () => {
		switch (from) {
            case ORGANIZATION_TYPES.ZONE: {
                const reportParameters = {
                    exportParameters: {
                        businessZoneId: "",
                        sortColumn: sortField,
						sortOrder: sortOrder,
						search: zoneSearchkeyWord
                    },
                    exportType: "ZONE"
                };
                dispatch(onExportReport(reportParameters, "Zone"));
                break;
            }
            case ORGANIZATION_TYPES.SALES: {
                const reportParameters = {
                    exportParameters: {
                        uniqueSalesOfficeId: "",
                        sortColumn: sortField,
						sortOrder: sortOrder,
						search: keyWord
                    },
                    exportType: "Salesoffice Grid Report"
                };
				dispatch(onExportReport(reportParameters, "Sales Office"));
                break;
            }
            case ORGANIZATION_TYPES.BRANCH: {
                const reportParameters = {
                    exportParameters: {
                        uniqueBranchOfficeId: "",
                        sortColumn: sortField,
						sortOrder: sortOrder,
						search: keyWord
                    },
                    exportType: "Testing Organization Grid Report"
                };
                dispatch(onExportReport(reportParameters, "Branch Office"));
                break;
            }
            default: {
                const reportParameters = {
                    reportParameters: {
                        businessZoneId: "",
                        sortColumn: sortField,
						sortOrder: sortOrder,
						search: zoneSearchkeyWord
                    },
                    exportType: "ZONE"
                };
                dispatch(onExportReport(reportParameters, "Zone"));
                break;
            }
        }
	};

	const onExportHighlightedClick = () => {
		switch (from) {
            case ORGANIZATION_TYPES.ZONE: {
                const reportParameters = {
                    exportParameters: {
                        businessZoneId: getSelectedZonesId(),
                        sortColumn: sortField,
						sortOrder: sortOrder,
						search: zoneSearchkeyWord
                    },
                    exportType: "ZONE"
                };
                dispatch(onExportReport(reportParameters, "Zone"));
                break;
            }
            case ORGANIZATION_TYPES.SALES: {
                const reportParameters = {
                    exportParameters: {
                        uniqueSalesOfficeId: getSelectedSalesOfficeId(),
                        sortColumn: sortField,
						sortOrder: sortOrder,
						search: keyWord
                    },
                    exportType: "Salesoffice Grid Report"
                };
				dispatch(onExportReport(reportParameters, "Sales Office"));
                break;
            }
            case ORGANIZATION_TYPES.BRANCH: {
                const reportParameters = {
                    exportParameters: {
                        uniqueBranchOfficeId: setSelectedBranchOfficeId(),
                        sortColumn: sortField,
						sortOrder: sortOrder,
						search: keyWord
                    },
                    exportType: "Testing Organization Grid Report"
                };
                dispatch(onExportReport(reportParameters, "Branch Office"));
                break;
            }
            default: {
                const reportParameters = {
                    reportParameters: {
                        businessZoneId: getSelectedZonesId(),
                        sortColumn: sortField,
						sortOrder: sortOrder,
						search: zoneSearchkeyWord
                    },
                    exportType: "ZONE"
                };
                dispatch(onExportReport(reportParameters, "Zone"));
                break;
            }
        }
	};

	return (
		<div className="organization-zone">
			<section className="d-flex align-items-center">
				<Breadcrumb title="Organization Settings" classNames="organization-header" handleGoBackClick={() => navigate(`/${APP_ROUTES.MANAGE_VIEW}`)} />
				<div className="breadcrumb-right-arrow ml-3 mr-3">
					<BsArrowRight className="icon" />
				</div>
				<div className="breadcrumb-sub-page-title">
					<span>{showSubtitle.current}</span>
				</div>
			</section>
			<div className="table-container d-flex">
				<section className="organization-filter expanded d-flex flex-column align-items-center">
					<Filters handleTabChange={handleTabChange} from={from} organizationFilter filterExpanded />
				</section>
				<section className="organization-table expanded">
					{showGrid && (
						<Table
							rows={tableRows}
							cols={tableColumns}
							paginator
							dataKeyId={getOrgTableDatakey()}
							title={getOrgTableTitle()}
							searchText="Search"
							pageDetails={getOrgPageDetails()}
							pageIndex={pageIndex}
							onPageChanged={onPageChanged}
							getSelectedRowData={handleRowSelect}
							onGlobalSearch={onGlobalSearch}
							onGridDataSearch={onGridDataSearch}
                        	onSortData={onSort}
							isLazyLoading={isLazyLoading}
							showroweditor={false}
							onAddClick={() => {
								switch (from) {
									case ORGANIZATION_TYPES.ZONE: {
										setShowAddZoneModal(true);
										break;
									}
									case ORGANIZATION_TYPES.BRANCH: {
										setShowAddBranchModal(true);
										break;
									}

									case ORGANIZATION_TYPES.SALES: {
										setShowAddSalesModal(true);
										break;
									}
								}
							}}
							onEditClick={onEditIconClick}
							onDeleteClick={() => {
								switch (from) {
									case ORGANIZATION_TYPES.ZONE: {
										deleteZoneClick();
										break;
									}
									case ORGANIZATION_TYPES.SALES: {
										deleteSalesOfficeClick();
										break;
									}
									case ORGANIZATION_TYPES.BRANCH: {
										deleteBranchOfficeClick();
										break;
									}
								}
							}}
							settingListItems={getSettingsItem(selectedRows)}
							onDownloadClick={(e) => downloadRef.current?.toggle(e)}
						/>
					)}
				</section>
			</div>

			{showEditBranchModal && (
				<EditBranch
					showModal={showEditBranchModal}
					selectedRows={selectedRows}
					address={selectedRows[0]?.address}
					city={selectedRows[0]?.cityStateZip}
					phone={selectedRows[0]?.phone}
					email={selectedRows[0]?.email}
					Fax={selectedRows[0]?.fax}
					salesOffice={selectedRows[0]?.salesOffice}
					setShowModal={setShowEditBranchModal}
					onEditBranchOffice={(data) => onEditBranchOfficeClick(data)}
				/>
			)}

			{showAddBranchModal && (
				<AddBranchOffice
					showModal={showAddBranchModal}
					setShowModal={setShowAddBranchModal}
					onAddBranchOffice={(data) => onAddBranchOfficeClick(data)}
				/>
			)}

			{showAddZoneModal && <AddZone showModal={showAddZoneModal} setShowModal={setShowAddZoneModal} onAddZone={(data) => onAddZoneClick(data)} />}
			{showAddSalesModal && (
				<AddSalesOffice showModal={showAddSalesModal} setShowModal={setShowAddSalesModal} onAddSalesOffice={(data) => onAddSalesOfficeClick(data)} />
			)}
			{showEditSalesModal && (
				<EditSalesOffice
					showModal={showEditSalesModal}
					selectedRows={selectedRows}
					setShowModal={setShowEditSalesModal}
					zone={selectedRows[0]?.zone}
					code={selectedRows[0]?.code}
					onEditSalesOffice={(data) => onEditSalesOfficeClick(data)}
				/>
			)}
			{showEditZoneModal && (
				<EditZone
					showModal={showEditZoneModal}
					setShowModal={setShowEditZoneModal}
					selectedRows={selectedRows}
					country={selectedRows[0]?.country}
					onEditZone={(data) => onEditZoneClick(data)}
				/>
			)}
			{showTechs && (
				<Techs
					showModal={showTechs}
					setShowModal={setShowTechs}
					selectedRows={selectedRows}
					onSelectTechnician={onSelectTechnician}
				/>
			)}
			<InformationBox showInfoModal={displayInfoModal} setShowInfoModal={setDisplayInfoModal} message={message} />
			<ConfirmationBox
				showConfirmModal={displayConfirmModal}
				setShowConfirmModal={setDisplayConfirmModal}
				confirmAction={OnConfirmClick}
				message={message}
			/>
			<PopOver ref={downloadRef}>
				<ul>
					<li onClick={onReportAllClick}>Report All</li>
					<li onClick={onReportHighlightedClick}>Report Highlighted</li>
					<li onClick={onExportAll}>Export All</li>
					<li onClick={onExportHighlightedClick}>Export Highlighted</li>
				</ul>
			</PopOver>
			{showReportModal ? (
				<OrganizationReport showModal={showReportModal}
					setShowModal={setShowReportModal}
					reportFlag={reportFlag}
					selectedRows={selectedRows}
					sortByColumns={tableColumns}
					searchKeyword={from === ORGANIZATION_TYPES.ZONE ? zoneSearchkeyWord : keyWord} />
			) : null}
		</div>
	);
};

export default Organization;
