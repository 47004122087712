import React, { useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";

import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { Dialog } from "primereact/dialog";
import { BsExclamationCircleFill } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { useAppSelector } from "redux/hooks";
import { getStatusList } from "redux/pages/Administration/administrationSlice";

interface Props {
  showModal: boolean;
  setShowModal: (state: boolean) => void;
  userRoleId: any;
  searchId: any;
  setStatusId: (data: any) => void;
  onSearchClick: (data: any) => void;
  setUserSearchAppliedFlag: any;
}

type FormData = {
  StatusId: string;
  sortColumn: String;
  sortDirection: String;
  searchAttribute: String;
  userRoleId: number;
};

export const UserSearch: React.FC<Props> = ({
  showModal = false,
  setShowModal = () => { },
  userRoleId= '',
  searchId = '',
  setStatusId = () => {},
  onSearchClick = () => { },
  setUserSearchAppliedFlag = () => {},
}) => {

  const userSearchForm: any = useRef();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      sortColumn: "[User Name]",
      sortDirection: "ASC",
      searchAttribute: "",
      StatusId: searchId,
      userRoleId: userRoleId
    },
  });

  const { status } = useAppSelector((state) => state.administration.statusList);
  const dispatch = useDispatch<any>();

  useEffect(() => {
    dispatch(getStatusList());
  }, [dispatch]);

  const formSubmitHandler = (data: any) => {
    userSearchForm.current.requestSubmit();
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && (
        <span className="tooltip-text">
          <BsExclamationCircleFill />
          {errors[name].message}
        </span>
      )
    );
  };

  const onUserSearchFormSubmit = (data: any) => {
    setStatusId(data.StatusId);
    onSearchClick && onSearchClick(data);
  };

  const renderFooter = () => {
    return (
      <>
        <Button
          label="Cancel"
          onClick={() => setShowModal(false)}
          className="cancel btn"
        />
        <Button label="OK" className="update btn" autoFocus onClick={formSubmitHandler} />
      </>
    );
  };

  const onError = (errors: any, e: any) => { };

  return (
    <>
      <Dialog
        header="Search User "
        visible={showModal}
        style={{ width: "40vw" }}
        onHide={() => {
          setUserSearchAppliedFlag(false);
          setShowModal(false);
        }}
        footer={renderFooter()}
        className="display-settings personal selectsite"
      >
        <form
          onSubmit={handleSubmit(onUserSearchFormSubmit, onError)}
          ref={userSearchForm}
        >
          <div className="row px-2">
            <div className="field col-12 md:col-2">
              <span className="p-float-label">
                {status &&
                  <Controller
                    name="StatusId"
                    control={control}
                    rules={{
                      required: "This field is required.",
                    }}
                    render={({ field, fieldState }) => (
                      <Dropdown
                        inputId="Status"
                        className={classNames("w-100 error-tooltip", {
                          error: fieldState.invalid,
                        })}
                        {...field}
                        value={field.value}
                        onChange={(e) => field.onChange(e.value)}
                        options={
                          [{ statusId: 0, statusCode: "All_Users", statusName: "All Users" },
                          ...status.filter(status => [1, 2, 4, 5, 6, 7].includes(status.statusId))]
                        }
                        optionLabel="statusName"
                        optionValue="statusId"
                      />
                    )}
                  />}
                <label className="mandatory" htmlFor="dropdown">
                  Status
                </label>
                {getFormErrorMessage("StatusId")}
              </span>
            </div>
          </div>
        </form>
      </Dialog>
    </>
  );
};

export default UserSearch;
