
export const INSPECTION_TASKS_COL = [
	{
		field: "inspectionTaskName",
		header: "Inspection Task Name",
		style: { flex: "1 0 200px" },
		sortable: true,
	},
	{
		field: "customerName",
		header: "Sold to Customer",
		style: { flex: "1 0 200px" },
		sortable: true,
	},
	{
		field: "reportsCreated",
		header: "Report(s) Created",
		style: { flex: "1 0 200px" },
		sortable: true,
	},
	{
		field: "reportsUploaded",
		header: "Report(s) Uploaded",
		style: { flex: "1 0 200px" },
		sortable: true,
	},
	{
		field: "configFile",
		header: "Config File",
		style: { flex: "1 0 200px" },
		sortable: true,
	},
	{
		field: "leadTechnician",
		header: "Lead Tech",
		style: { flex: "1 0 200px" },
		sortable: true,
	},
];

export const EQUIPMENT_COLS = [
	{
		field: 'equipmentType',
		header: 'Equipment Type',
		style: { flex: '1 0 150px' },
		sortable: true,
	},
	{
		field: 'equipmentName',
		header: 'Equipment',
		style: { flex: '1 0 150px' },
		sortable: true,
	},
	{
		field: 'systemName',
		header: 'System',
		style: { flex: '1 0 350px' },
		sortable: true,
	},
	{
		field: 'inspectionTaskName',
		header: 'Inspection Task',
		style: { flex: '1 0 350px' },
		sortable: true,
	},
	{
		field: 'contractName',
		header: 'Contract',
		style: { flex: '1 0 350px' },
		sortable: true,
	},
	{
		field: 'customerName',
		header: 'Customer',
		style: { flex: '1 0 350px' },
		sortable: true,
	},
	{
		field: 'siteName',
		header: 'Site',
		style: { flex: '1 0 350px' },
		sortable: true,
	},
	{
		field: 'testedDate',
		header: 'Test Date',
		style: { flex: '1 0 300px' },
		sortable: true,
	},
	{
		field: 'notificationNumber',
		header: 'Notification Number',
		style: { flex: '1 0 300px' },
		sortable: true,
	},
	{
		field: 'comment',
		header: 'Comment',
		style: { flex: '1 0 300px' },
		sortable: true,
	},
	{
		field: 'inspectionTaskStatus',
		header: 'Task Status',
		style: { flex: '1 0 300px' },
		sortable: true,
	},
	{
		field: 'inspectionTaskLeadTechName',
		header: 'Lead Tech',
		style: { flex: '1 0 300px' },
		sortable: true,
	},
	{
		field: 'inspectionTaskLeadTechMail',
		header: 'Lead Tech Email',
		style: { flex: '1 0 300px' },
		sortable: true,
	}
];


export const CONTRACT_COLS = [
	{
		field: 'customerName',
		header: 'Customer',
		style: { flex: '1 0 150px' },
		sortable: true,
	},
	{
		field: 'contractNumber',
		header: 'Contract Number',
		style: { flex: '1 0 150px' },
		sortable: true,
	},
	{
		field: 'contractName',
		header: 'Contract Name',
		style: { flex: '1 0 200px' },
		sortable: true,
	},
	{
		field: 'startDate',
		header: 'Start Date',
		style: { flex: '1 0 150px' },
		sortable: true,
	},
	{
		field: 'endDate',
		header: 'End Date',
		style: { flex: '1 0 150px' },
		sortable: true,
	},
	{
		field: 'notes',
		header: 'Notes',
		style: { flex: '1 0 150px' },
		sortable: true,
	},
	{
		field: 'status',
		header: 'Status',
		style: { flex: '1 0 150px' },
		sortable: true,
	},
	{
		field: 'salesOfficeName',
		header: 'Sales Office',
		style: { flex: '1 0 150px' },
		sortable: true,
	},

];

export const PERFORMANCE_TABLE_COL = [
	{
		field: "panelTypeName",
		header: "System Type",
        style: { flex: '1 0 300px' },
		sortable: true
	},
	{
		field: "usage",
		header: "Usage (%)",
        style: { flex: '1 0 80px' },
		align: "right",
		sortable: true
	}
];