import React, { useState, useEffect, useTransition } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";
import { showUI } from 'service/GeneralUtility';

import { MdTableRows } from "react-icons/md";
import { MdOutlineSort } from "react-icons/md";
import { BsCardChecklist } from "react-icons/bs";
import { BsHouseDoorFill } from "react-icons/bs";
import { BsFileEarmarkBarGraph } from "react-icons/bs";
import { MdDashboard } from "react-icons/md";

import AsBuiltsIcon from "./AsBuiltsIcon";

import { APP_ROUTES } from "constants/AppRoutes";

interface NavItemProps {
	name: string;
	iconName: any;
	url: string;
	active: boolean;
}

interface Props {}

const Sidebar: React.FC<Props> = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const userPrivileges = useAppSelector((state) => state.user.userPrivileges);

	const [sidebarLinks, setSidebarLinks] = useState<any>();
	const [isExpanded, setIsExpanded] = useState(false);
	const [, startTransition] = useTransition();

	useEffect(()=>{
		if (userPrivileges && userPrivileges.length) {
			filterSideBarLinkList();
		}
	},[userPrivileges])
	  const filterSideBarLinkList = () => {
		let LinkList: Array<NavItemProps> = [];
		LinkList.push({
			name: "Dashboard",
			iconName: <MdDashboard />,
			url: APP_ROUTES.DASHBOARD,
			active: false,
		})
		LinkList.push({
				name: "Administration",
				iconName: <BsHouseDoorFill />,
				url: showUI(userPrivileges,"SIETAP_CLIENTS_MANAGE") ? APP_ROUTES.CUSTOMERS:APP_ROUTES.SITES,
				active: false,
		})

		LinkList.push({
			name: "Inspection",
			iconName: <BsCardChecklist />,
			url: APP_ROUTES.INSPECTION_TASKS,
			active: true,
		})
		LinkList.push({
			name: "As Builts",
			iconName:<AsBuiltsIcon/>,
			url: APP_ROUTES.AS_BUILTS,
			active: false,
		})
		setSidebarLinks(LinkList)
	  }

	const handleSidebarClick = (item: NavItemProps) => {
		const updateSelectedLink = () => {
			const name = item?.name;
			const url = item?.url;
			const updatedSidebarLinks = sidebarLinks.map((item) => {
				if (item.name === name) {
					item.active = true;
				} else {
					item.active = false;
				}

				return item;
			});
			setSidebarLinks(updatedSidebarLinks);
			navigate(url);
		};
		startTransition(() => {
			updateSelectedLink();
			setIsExpanded(false);
		});
	};

	useEffect(() => {
		if(sidebarLinks && sidebarLinks.length){
		const pathName = location?.pathname;
		const isHomeOrDashboardUrl = pathName === APP_ROUTES.HOME || pathName?.includes(APP_ROUTES.INSPECTION);
		if (isHomeOrDashboardUrl) {
			setSidebarLinks((sidebarLinks) =>
				sidebarLinks.map((item) => {
					if (item.name === "Inspection") {
						item.active = true;
					} else {
						item.active = false;
					}
					return item;
				})
			);
		}
	}
	}, [userPrivileges,location]);

	return (
		<div className={`sidebar-container ${isExpanded ? "expanded" : ""}`}>
			<div className="ham-icon" role="button" onClick={() => setIsExpanded((isExpanded) => !isExpanded)}>
				{isExpanded ? <MdOutlineSort /> : <MdTableRows />}
			</div>
			<div className="sidebar-links">
				<ul>
					{sidebarLinks && sidebarLinks.map((item, index) => (
						<li key={index} className={item?.active ? "active" : ""} onClick={() => handleSidebarClick(item)}>
							{item?.iconName}
							{isExpanded && <span>{item?.name}</span>}
						</li>
					))}
				</ul>
			</div>
		</div>
	);
};
export default Sidebar;
