import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';

const textEditor = (options) => {
    return <InputText type="text" disabled value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
}
const dateEditor = (options) => {
    return <Calendar id="calendar" dateFormat="yy-mm-dd" readOnlyInput value={options.value ==" "||options.value==undefined ?new Date():new Date(options.value)} onChange={(e) => options.editorCallback(e.target.value)} />
  }

export const DEFICIENCY_LIST_COLS = [
    {
        field: 'address',
        header: 'Address',
        editor: (options) => textEditor(options),
        style: { flex: '1 0 100px' },
        sortable: true
    },
    {
        field: 'type',
        header: 'Type',
        editor: (options) => textEditor(options),
        style: { flex: '1 0 100px' },
        sortable: true,
    },
    {
        field: 'locationInBuilding',
        header: 'Location In Building',
        editor: (options) => textEditor(options),
        style: { flex: '1 0 150px' },
        sortable: true,
    },
    {
        field: 'date',
        header: 'Date',
        editor: (options) => textEditor(options),
        style: { flex: '1 0 100px' },
        sortable: true,
    },
    {
        field: 'comments',
        header: 'Comments',
        editor: (options) => textEditor(options),
        style: { flex: '1 0 100px' },
        sortable: true,
    },
    {
        field: 'correctedDate',
        header: 'Corrected Date',
        editor: (options) => dateEditor(options),
        style: { flex: '1 0 150px' },
        sortable: true,
    }

];