import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import MultiSelect from "components/common/MultiSelect/MultiSelect";
import { getTechnicians } from "redux/pages/Manage/OrganizationSettings/salesOfficeSlice";

interface Props {
  showModal: boolean;
  setShowModal: (state: boolean) => void;
  selectedRows: any;
  onSelectTechnician: (salesOfficeSelected: any, body: any, setLoader: any) => void;
}

export const Techs: React.FC<Props> = ({
  showModal = false,
  setShowModal = () => { },
  selectedRows = [],
  onSelectTechnician = () => {}
}) => {

  const dispatch = useDispatch<any>();

  const [isLoaded, setIsLoaded] = useState(false);
  const [salesOfficeName, setSalesOfficeName] = useState("");
  const [technicians, setTechnicians] = useState<any>([]);
  const [selectedSalesOffice, setSelectedSalesOffice] = useState("");
  const [loader, setLoader] = useState<boolean>(false);

  const selectedSite = useRef<any>();
  const checkedRows = useRef<any>();
  const disabledRows = useRef<any>();

  useEffect(() => {
    if (selectedRows && selectedRows.length) {
      setSelectedSalesOffice(selectedRows[0].uniqueSalesOfficeId)
      dispatch(getTechnicians(selectedRows[0].uniqueSalesOfficeId, setSalesOfficeName, setTechnicians, setIsLoaded));
    }
  }, [dispatch, selectedRows])

  const assignTechnicians = () => {
    setLoader(true);
    let selectedUsers: any = [];
    let allUsers = [...checkedRows.current, ...disabledRows.current]; 
    if (allUsers && allUsers.length) {
      allUsers.map((item: any) => {
        selectedUsers.push(item.id);
        return null;
      });
    }
    let body = {
      uniqueTechnicianIds: selectedUsers
    }
    onSelectTechnician(selectedSalesOffice, body, setLoader);
  };

  const renderFooter = () => {
    return (
      <>
        <Button
          label="Cancel"
          onClick={() => setShowModal(false)}
          className="cancel btn"
        />
        <Button
          label="Save Changes"
          className="update btn"
          disabled={loader}
          autoFocus
          onClick={assignTechnicians}
        />
      </>
    );
  };

  return (
    <>
      <Dialog
        header="Update Technicians"
        visible={showModal}
        style={{ width: "40vw", height: "50%" }}
        onHide={() => setShowModal(false)}
        footer={isLoaded ? renderFooter() : null}
        className="display-settings personal selectsite"
      >
        <div className="content-box">
          {isLoaded && 
            <>
              <div className="header d-flex align-items-center">
                <span className="title">Sales Office:</span>
                <span className="value">{salesOfficeName}</span>
              </div>
              <div className="row px-2 pt-1">
                <div className="field col-12 md:col-2 mb-0 py-0  pl-1">
                  <MultiSelect
                    singleSelect
                    list={technicians}
                    headerName="Name"
                    getCheckedItems={(checkedItems) => {
                      checkedRows.current = checkedItems;
                    }}
                    getSelectedItems={(selectedItems) => {
                      selectedSite.current = selectedItems;
                    }}
                    getDisabledItems={(disabledItems) => {
                      disabledRows.current = disabledItems;
                    }}
                  />
                </div>
              </div>
            </>
          }
        </div>
      </Dialog>
    </>
  );
};

export default Techs;
