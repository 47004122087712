import React, { useEffect, useRef, useState } from "react";

import { useForm, Controller } from "react-hook-form";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { RadioButton } from "primereact/radiobutton";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { classNames } from "primereact/utils";
import { BsExclamationCircleFill } from "react-icons/bs";

import { useDispatch } from "react-redux";
import { useAppSelector } from "../../redux/hooks";
import { getMailData } from "redux/pages/Dashboard/UserMenu/sendMailSlice";

import { baseURL } from "service/HttpService";
import { FilePond } from 'react-filepond';
import "filepond/dist/filepond.min.css"


interface Props {
  showModal: boolean;
  setShowModal: (state: boolean) => void;
  onSendMail: (attachmentData: any, sendMailInfo: any, setLoader: any) => void;
}

type FormData = {
  fromName: string;
  subject: string;
  message: string;
  mail: string;
  typeofEnquiry: any;
};

export const SendMailUserinfo: React.FC<Props> = ({
  showModal = false,
  setShowModal = () => { },
  onSendMail = () => { }
}) => {

  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [to, setTo] = useState("Yes");
  const [files, setFiles] = useState<any>([]);
  const [attachmentInfo, setAttachmentInfo] = useState("");
  const [fileSize, setFileSize] = useState("");
  const [loader, setLoader] = useState<boolean>(false);

  const dispatch = useDispatch<any>();
  const mailData = useAppSelector((state) => state.sendMail.mailData);

  const enquiryTypes: any = [
    { name: 'General Inquiry', key: 'General Inquiry' },
    { name: 'Sales', key: 'Sales' },
    { name: 'Technical Support', key: 'Technical Support' }
  ];
  
  const sendMailForm: any = useRef();

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    register,
    unregister,
    formState: { errors },
    reset
  } = useForm<FormData>({
    defaultValues: {
      subject: "",
      message: "",
      fromName: "",
      mail: "",
      typeofEnquiry: enquiryTypes[0].key,
    },
    mode: "onBlur",
    reValidateMode: "onBlur",
    shouldUnregister: true
  });

  useEffect(() => {
    dispatch(getMailData())
  }, [dispatch]);

  useEffect(() => {
    if (mailData && mailData.fromName) {
      setValue('fromName', mailData.fromName);
      setIsLoaded(true);
    }
  }, [mailData]);

  useEffect(() => {
    if (to === 'Yes') {
      setValue('mail', '');
      reset({ ...getValues(), mail: ''});
      unregister(["mail"]);
    } else {
      register("mail", { 
        required: 'This field is required.',  
        pattern: {
          value: /^$|^\S+@\S+\.\S+$/,
          message: "Enter a valid Email",
        }
      });
    }
  }, [to]);

  const onprocessfile = (error: any, file: any) => {
    if (file) {
      setFiles(file);
      setAttachmentInfo(JSON.stringify(file.serverId));
      let totalBytes = +JSON.stringify(file.fileSize);
      if (totalBytes) {
        let size = (totalBytes / (1024 ** 2)).toFixed(2);
        setFileSize(`${size} MB`);
      }
    }
  }

  const onRemoveFile = () => {
    setFileSize("");
    setAttachmentInfo("");
  }

  const formSubmitHandler = (data: any) => {
    sendMailForm.current.requestSubmit();
  };

  const onAddFormSubmit = (data: any) => {
    setLoader(true);
    let body = {};
    let info: any = {
      fromName: mailData.fromName,
      senderEmailId: mailData.senderEmailId,
      message: data.message,
      subject: data.subject,
    };
    if (to === 'Yes') {
      info.isSender = true;
      info.enquiryType = data.typeofEnquiry
    } else if (to === 'No') {
      info.isSender = false;
      if (data.mail) {
        info.toOthersMailId = data.mail
      }
    }
    if (attachmentInfo) {
      let response = JSON.parse(JSON.parse(attachmentInfo));   
      if (response.status === "done") {
        if (response.data) {
          if (response.data.uniqueDirectoryId && response.data.uniqueDirectoryId.length) {
            body = {
              TempStorageDirectory: response.data.uniqueDirectoryId[0],
            };
          }
        }
      }
    }
    onSendMail(body, info, setLoader);
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && (
        <span className="tooltip-text">
          <BsExclamationCircleFill />
          {errors[name].message}
        </span>
      )
    );
  };

  const renderFooter = () => {
    return (
      <>
        <Button
          label="Cancel"
          onClick={() => setShowModal(false)}
          className="cancel btn"
        />
        <Button label="Send" className="update btn" disabled={loader} autoFocus onClick={formSubmitHandler}/>
      </>
    );
  };

  return (
    <>
      <Dialog
        header="Send Mail"
        visible={showModal}
        style={{ width: "40vw", height: "82vh"  }}
        onHide={() => setShowModal(false)}
        footer={isLoaded ? renderFooter() : null}
        className="display-settings personal selectsite"
      >
        {isLoaded &&
          <form onSubmit={handleSubmit(onAddFormSubmit)} ref={sendMailForm}>
            <div className="row px-2 pt-1">
              <div className="field col-12 md:col-2 mb-0 d-flex w-100 align-items-center">
                <div className="left-sider-label">
                  <div className="p-float-label">
                    <p className="label">From</p>
                  </div>
                </div>
                <div className="right-side-field">
                  <span className="p-float-label text-container">

                    <Controller
                      name="fromName"
                      control={control}
                      render={() => <span className="text-value ml-0">{mailData.fromName}</span>}
                    />
                  </span>
                </div>
              </div>
            </div>

            <div className="row px-2 pt-1">
              <div className="field col-12 md:col-2 mb-0 d-flex w-100">
                <div className="left-sider-label">
                  <div className="p-float-label">
                    <p className="label mandatory">To</p>
                  </div>
                </div>
                <div className="right-side-field">
                  <div className="radio-section">
                    <div className="field-radiobutton d-flex pr-4 align-items-center  mb-0">
                      <RadioButton inputId="NetworkSelectYes" name="networkSelected" value="Yes" onChange={(e) => { setTo(e.value) }} checked={to === 'Yes'} />
                      <label htmlFor="city1">zLink, Inc.</label>
                    </div>
                    <div className="field-radiobutton d-flex align-items-center mb-0">
                      <RadioButton inputId="NetworkSelectYes" name="networkSelected" value="No" onChange={(e) => { setTo(e.value) }} checked={to === 'No'} />
                      <label className="ml-0 w-100 mt-2" htmlFor="city2">
                        <div className="field col-12 md:col-2 mb-0 pr-0">
                          <span className="p-float-label">
                            <Controller
                              name="mail"
                              control={control}
                              rules={{
                                required: (to == 'No') ? 'This field is required.' : false,
                                pattern: (to == 'No') ? {
                                  value: /^$|^\S+@\S+\.\S+$/,
                                  message: "Enter a valid Email",
                                } : undefined
                              }}
                              render={({ field, fieldState }) => (
                                <InputTextarea
                                  id={field.name}
                                  {...field}
                                  className={classNames({
                                    "p-invalid w-100 error-tooltip": fieldState.invalid,
                                  })}
                                  disabled={(to === 'Yes') ? true : false}
                                />
                              )}
                            />
                            {getFormErrorMessage("mail")}
                          </span>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row px-2 pt-1">
              <div className="field col-12 md:col-2 mb-0 d-flex">
                <div className="left-sider-label">
                  <div className="p-float-label">
                    <p className="label mandatory">Subject</p>
                  </div>
                </div>
                <div className="right-side-field">
                  <span className="p-float-label">
                    <Controller
                      name="subject"
                      control={control}
                      rules={{
                        required: "This field is required.",
                      }}
                      render={({ field, fieldState }) => (
                        <InputText
                          id="name"
                          className={classNames("w-100 error-tooltip", {
                            error: fieldState.invalid,
                          })}
                          {...field}
                        />
                      )}
                    />

                    {getFormErrorMessage("subject")}
                  </span>
                </div>
              </div>
            </div>

            {(to === 'Yes') && 
              <div className="row px-2 pt-1">
                <div className="field col-12 md:col-4 mb-0 d-flex">
                  <div className="left-sider-label">
                    <div className="p-float-label">
                      <p className="label mandatory">Type of Enquiry</p>
                    </div>
                  </div>
                  <div className="right-side-field">
                    <span className="p-float-label">
                      <Controller
                        name="typeofEnquiry"
                        control={control}
                        rules={{
                          required: "This field is required.",
                        }}
                        render={({ field, fieldState }) => (
                          <Dropdown
                            inputId="dropdown"
                            className={classNames("w-100 error-tooltip", {
                              error: fieldState.invalid,
                            })}
                            {...field}
                            value={field.value}
                            options={enquiryTypes}
                            optionLabel="name"
                            optionValue="key"
                            placeholder="Select"
                          />
                        )}
                      />
                    </span>
                  </div>
                </div>
              </div>
            }

            <div className="row px-2 pt-1">
              <div className="field col-12 md:col-2 mb-0 d-flex w-100 align-items-center">
                <div className="left-sider-label">
                  <div className="p-float-label">
                    <p className="label">Attachment</p>
                  </div>
                </div>
                <div className="right-side-field">
                  <FilePond
                    allowMultiple={false}
                    maxFiles={1}
                    server={`${baseURL}api/v1/common/files/upload`}
                    onprocessfile={onprocessfile}
                    onremovefile={onRemoveFile}
                    name="files"
                    credits={false}
                    labelIdle='Choose File'
                    labelFileLoading=""
                    labelTapToUndo=""
                    labelFileProcessing=""
                    labelFileProcessingComplete=""
                    labelFileProcessingAborted=""
                    labelFileProcessingError=""
                    labelButtonAbortItemProcessing=""
                    labelButtonUndoItemProcessing=""
                    labelButtonRetryItemProcessing=""
                    labelButtonProcessItem=""
                    labelTapToCancel=""
                    labelFileRemoveError=""
                    labelTapToRetry=""
                    allowFileTypeValidation={true}
                    acceptedFileTypes={[]}
                  />
                </div>
              </div>
            </div>

            {fileSize && 
              <div className="row px-2 pt-1">
                <div className="field col-12 md:col-2 mb-0 d-flex w-100 align-items-center">
                  <div className="left-sider-label">
                    <div className="p-float-label">
                      <p className="label">File Size</p>
                    </div>
                  </div>
                  <div className="right-side-field">
                    <p>
                      <b>{fileSize}</b>
                    </p>
                  </div>
                </div>
              </div>
            }

            <div className="row px-2 pt-1">
              <div className="field col-12 md:col-2 mb-0 d-flex">
                <div className="left-sider-label">
                  <div className="p-float-label">
                    <p className="label mandatory">Message</p>
                  </div>
                </div>
                <div className="right-side-field">
                  <span className="p-float-label">
                    <Controller
                      name="message"
                      control={control}
                      rules={{
                        required: "This field is required.",
                      }}
                      render={({ field, fieldState }) => (
                        <InputTextarea
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid w-100 error-tooltip": fieldState.invalid,
                          })}
                        />
                      )}
                    />
                    {getFormErrorMessage("message")}
                  </span>
                </div>
              </div>
            </div>
          </form>
        }
      </Dialog>
    </>
  );
};

export default SendMailUserinfo;
