import React, { useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { BsExclamationCircleFill, BsPlusLg } from "react-icons/bs";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { classNames } from "primereact/utils";
import { FilePond, registerPlugin } from "react-filepond";
import { baseURL } from "service/HttpService";

import FileUploadValidate from "filepond-plugin-file-validate-type";

import { useAppSelector } from "redux/hooks";
import { useDispatch } from "react-redux";
import { getDrawingCategory, getDrawingTypes, getFloorList } from "redux/pages/AsBuilts/AsBuiltsSlice";

import InformationBox from "components/common/Message/InformationBox/InformationBox";
import AddFloors from "pages/Sites/ManageFloors/AddFloor";
import { onAddFloor, setNewFloorData } from "redux/pages/Administration/Floor/floorSlice";

interface Props {
    showModal: boolean;
    setShowModal: (state: boolean) => void;
    selectedRows: any;
    selectedBuilding: any;
    selectedSite: any;
    onAddDrawing: (data: any) => void;
}

type FormData = {
    floorId: string;
    drawingCategoryId: string;
    drawingTypeId: string;
    description: string;
};

export const AddAsBuilts: React.FC<Props> = ({
    showModal = false,
    setShowModal = () => { },
    selectedRows = [],
    selectedBuilding = [],
    selectedSite = [],
    onAddDrawing = () => { },
}) => {

    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<FormData>({
        defaultValues: {
            floorId: "",
            drawingCategoryId: "",
            drawingTypeId: "",
            description: "",
        },
    });
    
    registerPlugin(FileUploadValidate);

    const floorList = useAppSelector((state) => state.asbuilts.floorList);
    const drawingTypes = useAppSelector((state) => state.asbuilts.drawingTypes);
    const drawingCategory = useAppSelector((state) => state.asbuilts.drawingCategory);
    const newFloorData =  useAppSelector((state) => state.floor.newFloorData);

    const [floorLookup, setFloorLookup] = useState<any>();
    const [displayInfoModal, setDisplayInfoModal] = useState(false);
    const [message, setMessage] = useState("");
    const [drawingDetails, setDrawingDetails] = useState<any>("");
    const [showAddFloorsModal, setShowAddFloorsModal] = useState<boolean>(false);
    const [acceptedFileTypes, setAcceptedFileTypes] = useState(["dwg"])

    const addForm: any = useRef();
    const dispatch = useDispatch<any>();


    useEffect(() => {
        dispatch(getFloorList(selectedSite?.uniqueSiteId, selectedBuilding.id))
        dispatch(getDrawingCategory());
        dispatch(getDrawingTypes());
    }, [])

    useEffect(() => {
        if (floorList) {
            let floor: any = [];
            floorList.map((item: any) => {
                floor.push(item)
            })
            setFloorLookup(floor)
        }
    }, [floorList])

    const getFormErrorMessage = (name) => {
        return (
            errors[name] && (
                <span className="tooltip-text">
                    <BsExclamationCircleFill />
                    {errors[name].message}
                </span>
            )
        );
    };

    const renderFooter = () => {
        return (
            <>
                <Button label="Cancel" className="cancel btn" autoFocus onClick={() => setShowModal(false)} />
                <Button label="Save" className="Upload btn" autoFocus onClick={formSubmitHandler} />
            </>
        );
    };

    const onRemoveFile = () => {
        setDrawingDetails("");
    }

    const formSubmitHandler = (data: any) => {
        addForm.current.requestSubmit();
    };

    const onAddFormSubmit = (data: any) => {
        if (drawingDetails === "") {
            setMessage("Select either a DWG File or PDF File.")
            setDisplayInfoModal(true);
        } else {
            let payload = {
                floorId: data.floorId.uniqueFloorId,
                drawingCategoryId: drawingCategory[0].floorPlanTypeId,
                drawingTypeId: drawingTypes[0].floorPlanModelTypeId,
                description: data.description.trim(),
                TempStorageDirectory: drawingDetails
            }
            onAddDrawing && onAddDrawing(payload);
        }
    };

    const onAddFloorClick = (event) => {
        event?.preventDefault();
        setShowAddFloorsModal(true)
    }

    const onAddFloorData = (data: any) => {
        dispatch(onAddFloor(data, selectedSite?.uniqueSiteId, setMessage, setDisplayInfoModal))
      }
    
      useEffect(() => {
        if (newFloorData) {
            let floor: any = floorLookup;
            floor.push(newFloorData)
            setFloorLookup(floor)
            setValue("floorId", newFloorData)
            dispatch(setNewFloorData(""))
            setShowAddFloorsModal(false);
        }
      }, [newFloorData])

      const beforeAddFile= (item: any) => {
        if (item.fileExtension === "dwg" || item.fileExtension === "DWG"|| item.fileExtension ==="PDF"|| item.fileExtension ==="pdf")
        return true;
        else {
            setMessage("Select either a DWG File or PDF File.")
            setDisplayInfoModal(true);
            return false;
        }
      }

    const onError = (errors: any, e: any) => { };
    
    const getTooltipMessage = (fieldState) => {
        if (fieldState.value.trim().length <= 0) {
            return "Description is required.";
        }
        return undefined;
    };
    
    return (
        <>
            <Dialog
                header="Add Drawing"
                visible={showModal}
                style={{ width: "32vw" }}
                onHide={() => setShowModal(false)}
                footer={renderFooter()}
                className={`s-modal`}
            >
                <form
                    onSubmit={handleSubmit(onAddFormSubmit, onError)}
                    ref={addForm}
                >
                    <div className="row">
                        <div className="d-flex align-items-center px-0">
                            <div className="field col-4">
                                <span className="p-float-label text-container">
                                    <label htmlFor="inputtext">Building</label>
                                </span>
                            </div>
                            <div className="field col-8">
                                <span className="p-float-label text-container">
                                    <span className="text-value">{selectedBuilding?.name}</span>
                                </span>
                            </div>
                        </div>
                        <div className="d-flex align-items-center px-0">
                            <div className="field col-4">
                                <span className="p-float-label text-container">
                                    <label>Floor</label>
                                </span>
                            </div>
                            <div className="field col-7">
                                <span className="p-float-label">
                                    <Controller
                                        name="floorId"
                                        control={control}
                                        rules={{
                                            required: 'Floor is required.'
                                        }}
                                        render={({ field, fieldState }) =>
                                            <Dropdown
                                                inputId="dropdown"
                                                className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })}
                                                {...field}
                                                value={field.value}
                                                onChange={(e) => field.onChange(e.value)}
                                                options={floorLookup}
                                                optionLabel="floorName" />}
                                    />
                                    <label className="mandatory" htmlFor="dropdown">
                                        Select Floor
                                    </label>
                                    {getFormErrorMessage("floorId")}
                                </span>
                            </div>
                            <div className="field col-1">
                                <span className="p-float-label action-btns">
                                    <Button tooltip="Add" tooltipOptions={{ position: "top" }} className='button-icon' onClick={onAddFloorClick}>
                                        <BsPlusLg className="icon" />
                                    </Button>
                                </span>
                            </div>
                        </div>
                        <div className="d-flex align-items-center px-0">
                            <div className="field col-4">
                                <span className="p-float-label text-container">
                                    <label htmlFor="inputtext">Drawing Category </label>
                                </span>
                            </div>
                            <div className="field col-8">
                                <span className="p-float-label text-container">
                                    <span className="text-value">Fire Protection</span>
                                </span>
                            </div>
                        </div>
                        <div className="d-flex align-items-center px-0">
                            <div className="field col-4">
                                <span className="p-float-label text-container">
                                    <label htmlFor="inputtext">Drawing Type</label>
                                </span>
                            </div>
                            <div className="field col-8">
                                <span className="p-float-label text-container">
                                    <span className="text-value">2D Drawing</span>
                                </span>
                            </div>
                        </div>
                        <div className="field col-12">
                            <span className="p-float-label">
                                <Controller
                                    name="description"
                                    control={control}
                                    rules={{
                                        required: 'Description is required.',
                                        validate: (value) => {
                                            return value.trim().length <= 0
                                                ? "Description is required." : true
                                        },
                                    }}
                                    render={({ field, fieldState }) => (
                                        <InputTextarea
                                            id="Textarea"
                                            className={classNames("w-100 error-tooltip", { error: fieldState.invalid })}
                                            tooltip={getTooltipMessage(field)}
                                            tooltipOptions={{ className: 'p-tooltip-custom', position: 'bottom', event: 'focus' }}
                                            maxLength={4000}
                                            {...field}
                                        />
                                    )}
                                />
                                <label className='mandatory' htmlFor="inputtext">Description</label>
                            </span>
                            <span className="textarea-txt-count">Max 4000 Chars</span>
                        </div>
                        <div className="d-flex align-items-center px-0">
                            <div className="field col-4">
                                <span className="p-float-label text-container">
                                    <label className='mandatory'>File</label>
                                </span>
                            </div>
                            <div className="field col-8">
                                        <FilePond
                                            allowMultiple={false}
                                            maxFiles={1}
                                            allowDrop
                                            server={{
                                                process: {
                                                    url: `${baseURL}api/v1/common/files/upload`,
                                                    method: 'POST',
                                                    withCredentials: false,
                                                    onload: (response) => {
                                                        const res = JSON.parse(response);
                                                        if (res.status === "done") {
                                                            setDrawingDetails(res.data.uniqueDirectoryId[0]);
                                                        }
                                                        return response.key
                                                    },
                                                    onerror: (response) => response.data,
                                                    ondata: (formData) => formData
                                                },
                                            }}
                                            name="files"
                                            credits={false}
                                            labelIdle="Click to choose a file or drag and drop a file"
                                            labelFileLoading=""
                                            labelTapToUndo=""
                                            labelFileProcessing=""
                                            labelFileProcessingComplete=""
                                            labelFileProcessingAborted=""
                                            labelFileProcessingError=""
                                            labelButtonAbortItemProcessing=""
                                            labelButtonUndoItemProcessing=""
                                            labelButtonRetryItemProcessing=""
                                            labelButtonProcessItem=""
                                            labelTapToCancel=""
                                            labelFileRemoveError=""
                                            labelTapToRetry=""
                                            allowFileTypeValidation={true}
                                            onremovefile={onRemoveFile}
                                            //acceptedFileTypes={[".dwg", "application/octet-stream"]}
                                            beforeAddFile={beforeAddFile}
                                        />
                            </div>
                        </div>
                    </div>
                </form>
            </Dialog>
            <InformationBox
                showInfoModal={displayInfoModal}
                setShowInfoModal={setDisplayInfoModal}
                message={message}
            />
            {showAddFloorsModal && (
          <AddFloors
            showModal={showAddFloorsModal}
            setShowModal={setShowAddFloorsModal}
            onAddFloor={onAddFloorData}
            selectedSite={selectedSite}
            fromDrawing={true}
            uniqueBuildingId={selectedBuilding.id}
          />
        )}
        </>
    );
};

export default AddAsBuilts;

