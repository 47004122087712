import { Button } from 'primereact/button'
import React from 'react'
import { BsFillPencilFill, BsTrashFill } from 'react-icons/bs'

 const InspectionReportGroupsDocument = () => {
  return (
    <>
      <h4 className="doc-title mb-2">Report Groups</h4>
      
      <p>
        User can add/update Report Groups which can be used for grouping inspection reports 
        using this option. It can be done only be Super Admin users.
     </p>

     <p>
        These Report Groups will be listed in the Report Selected option against each Inspection 
        Task in <span className="doc-subtitle">Inspections: Inspection Tasks </span> page.
     </p>

     <p><span className="doc-subtitle"><b>Add:</b></span></p>
     
     <p>
       User can create a Report Group by clicking the Add New Report Group button. User can enter 
       the Report Group name in Report Group text box and click Save button to save it.
     </p>

     <p><span className="doc-subtitle"><b>Edit:</b></span></p>
     <p>
       User can update a Report Group by clicking   on the right side of the Report Group, and 
       selecting <span className="doc-subtitle"> Edit </span>
       <Button className="doc-button-icon"><BsFillPencilFill className="icon" title='Edit'/></Button> icon 
       from it. After making necessary changes click the Save Changes button to save the changes.
     </p>

     <p><span className="doc-subtitle"><b>Delete:</b></span></p>
     <p>
        Report Group can be deleted by clicking on the right side of the Report Group, and selecting 
        <span className="doc-subtitle"> Delete </span> 
        <Button className="doc-button-icon"><BsTrashFill className="icon" title='Delete' /></Button> icon 
        from it. Report Group can be deleted only if it's not used in Reports.
     </p>
    </>
  );
}
export default InspectionReportGroupsDocument;
