import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { FilePond, registerPlugin } from "react-filepond";
import FileUploadValidate from "filepond-plugin-file-validate-type";
import "filepond/dist/filepond.min.css";

//redux

import UploadModal from "components/Table/UploadModal/UploadModal";
import { baseURL } from "service/HttpService";
import InformationBox from "components/common/Message/InformationBox/InformationBox";

interface Props {
  showModal: boolean;
  setShowModal: (state: boolean) => void;
  onAddReportTemplate?: (data: any) => void;
}

type FormData = {
  uploaddate: string;
};

export const AddReportTemplates: React.FC<Props> = ({
  showModal = false,
  setShowModal = () => {},
  onAddReportTemplate = () => {},
}) => {
  registerPlugin(FileUploadValidate);
  const editForm: any = useRef();
  const fileUploadRef: any = useRef();
  const [enableUpload, setEnableUpload] = useState(true);
  const [files, setFiles] = useState<any>([]);
  const [displayInfoModal, setDisplayInfoModal] = useState(false);
  const [message, setMessage] = useState("");

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      uploaddate: "",
    },
  });

  const onEditFormSubmit = (data: any) => {};

  const UploadClick = () => {
    if (files.length) {
      let data: string = "";
      files.map((item: any) => {
        let response = JSON.parse(JSON.parse(JSON.stringify(item.serverId)));
        if (response?.status === "done") {
          data += response.data?.uniqueDirectoryId[0] + ","
        }
      })
      data = data.slice(0, -1)
      let body = {
        TempStorageDirectory: data
      };
      setEnableUpload(true);
      onAddReportTemplate && onAddReportTemplate(body);
    } else {
      setDisplayInfoModal(true);
      setMessage('Select a File to upload');
    }
  };

  const onprocessfiles = () => {
    setEnableUpload(false);
  };

  const beforeAddFile = (file) => {
    let data = fileUploadRef.current.props.files
    if (data.length > 0) {
      data.map((item: any) => {
        if (file?.filename === item.filename && file?.fileSize === item.fileSize) {
          fileUploadRef.current.removeFiles(item.id)
        }
      })
    }
    return true
  }

  const onWarning = (error: any) => {
    if (error && error.body === 'Max files') {
      setDisplayInfoModal(true);
      setMessage('Maximum of 30 files can only be uploaded at a time.');
    }
  };

  const onRemoveFiles = (error: any, file: any) => {
    if (file?.status === 8 || file?.status === 6) {
      const reaminingFiles = fileUploadRef.current.getFiles();
      if (reaminingFiles.length) {
        let fileCount: number = 0;
        reaminingFiles.map((item: any) => {
          if (item.status !== 5) {
            fileCount += fileCount + 1;
          }
        });

        if (fileCount > 0) {
          setEnableUpload(true);
        } else {
          setEnableUpload(false);
        }
      } else {
        setEnableUpload(true);
      }
    }
  };

  const onFileError = (error: any, file?: any, status?: any) => {
    if(error) {
      setEnableUpload(true);
    }
  };

  const onProcessFileStart = (file: any) => {
    setEnableUpload(true);
  };

  useEffect(() => {
    if (!files?.length) setEnableUpload(true);
  }, [files]);

  return (
    <>
      <UploadModal
        header="Upload Report Template"
        showModal={showModal}
        setShowModal={setShowModal}
        OnSaveClick={UploadClick}
        isAdd={false}
        style={{ width: "40vw", maxHeight: "95%" }}
        buttonDisable={enableUpload}
      >
        <form onSubmit={handleSubmit(onEditFormSubmit)} ref={editForm}>
          <div className="row px-2 pt-1">
            <div className="field col-12 md:col-2 mb-0 d-flex w-100 align-items-center">
              <div className="left-sider-label upload-left">
                <div className="p-float-label">
                  <p className="label">Attachment</p>
                </div>
              </div>
              <div className="right-side-field">
                <FilePond
                  ref={fileUploadRef}
                  allowMultiple={true}
                  maxFiles={30}
                  files={files}
                  onupdatefiles={setFiles}
                  server={`${baseURL}api/v1/common/files/upload`}
                  onprocessfiles={onprocessfiles}
                  beforeAddFile={beforeAddFile}
                  onwarning={onWarning}
                  onremovefile={onRemoveFiles}
                  onerror={onFileError}
                  onprocessfilestart={onProcessFileStart}
                  name="files"
                  credits={false}
                  labelIdle="Click to choose files or drag and drop files here to upload."
                  labelFileLoading=""
                  labelTapToUndo=""
                  labelFileProcessing=""
                  labelFileProcessingComplete=""
                  labelFileProcessingAborted=""
                  labelFileProcessingError=""
                  labelButtonAbortItemProcessing=""
                  labelButtonUndoItemProcessing=""
                  labelButtonRetryItemProcessing=""
                  labelButtonProcessItem=""
                  labelTapToCancel=""
                  labelFileRemoveError=""
                  labelTapToRetry=""
                  allowFileTypeValidation={true}
                  acceptedFileTypes={["application/xlsx", "application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"]}
                />
              </div>
            </div>
          </div>
        </form>
      </UploadModal>
      <InformationBox
        showInfoModal={displayInfoModal}
        setShowInfoModal={setDisplayInfoModal}
        message={message}
      />
    </>
  );
};

export default AddReportTemplates;
