import React, { useEffect, useRef } from 'react';
//components
import AddModal from 'components/Table/AddModal/AddModal';
import GeneralForm from 'components/common/Forms/GeneralForm/GeneralForm';
//redux
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'redux/hooks';
import { onEquipmentGroupUpdate, onGetEquipmentGroupDatafields } from 'redux/pages/Inspection/InspectionTask/InspectionDetails/Equipment/EquipmentSlice';

interface Props {
    showModal: boolean,
    setShowModal: (state: boolean) => void;
    selectedTask?: any;
    selectedPanel?: any;
}
export const EditEquipmentList: React.FC<Props> = (props) => {
    const { showModal, setShowModal = () => { }, selectedTask, selectedPanel } = props;

    const dispatch = useDispatch<any>();
    const datafields = useAppSelector((state) => state.equipment.equipmentGroupDatafields);
    const addform = useRef<any>(null);

    useEffect(() => {
        dispatch(onGetEquipmentGroupDatafields(selectedPanel, selectedTask?.uniqueInspectionTaskId));
    }, [])

    const onFormSubmit = (data) => {
        const body = {
            uniqueSystemId: selectedTask?.system?.uniqueSystemId,
            fieldDetails: data
        }
        dispatch(onEquipmentGroupUpdate(body, selectedTask?.uniqueInspectionTaskId, selectedPanel))
    }

    const formSubmitHandler = (data: any) => {
        addform.current.requestSubmit();
    };

    return (
        <>
            <AddModal
                header='Edit Equipment Data'
                showModal={showModal}
                isAdd={false}
                setShowModal={setShowModal}
                style={{ width: '35vw', maxHeight: '100%' }}
                OnSaveClick={formSubmitHandler}
            >
                <GeneralForm ref={addform} datafields={datafields} isEditForm={false} onFormSubmit={onFormSubmit} />
            </AddModal>
        </>
    );
}

export default EditEquipmentList;