import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { useDispatch } from "react-redux";
import { getAssociatedDevices, setAssociatedDevicesData } from "redux/pages/Inspection/InspectionTask/InspectionDetails/Equipment/EquipmentSlice";
import { useAppSelector } from "redux/hooks";

interface Prop {
	onHideModal?: () => void;
	equipmentGroupId: any;
	inspectionTaskId: any;
	equipmentClassId: any;
	selectedRows: any;
	onConnectDevices: (id: any, data: any) => void;
};

const Connectivity: React.FC<Prop> = ({
	onHideModal = () => { },
	equipmentGroupId = '',
	inspectionTaskId = '',
	equipmentClassId = '',
	selectedRows = [],
	onConnectDevices = () => { },
}) => {

	const dispatch = useDispatch<any>();
	const connectivityForm = useRef<any>(null);

	const [selectedModule, setSelectedModule] = useState<any>(0);
	const [equipmentList, setEquipmentList] = useState<any>([]);
	const [showModal, setShowModal] = useState<boolean>(false);

	const associatedDeviceData = useAppSelector((state) => state.equipment.associatedDevicesData);

	const {
		control,
		handleSubmit,
	} = useForm({});

	const getSelectedEquipmentIds = () => {
		let params: any = [];
		selectedRows.map(item => {
			params.push(item.EquipmentId);
		});
		return params;
	};


	useEffect(() => {
		const body = {
			panelId: equipmentGroupId,
			equipmentIds: getSelectedEquipmentIds()
		};
		dispatch(getAssociatedDevices(inspectionTaskId, equipmentClassId, body, setShowModal));
	}, [dispatch, inspectionTaskId, equipmentClassId]);

	useEffect(() => {
		if (selectedRows && selectedRows.length > 1) {
			setSelectedModule(0);
		} else if (selectedRows && selectedRows.length === 1) {
			if (associatedDeviceData && associatedDeviceData.equipmentList && associatedDeviceData.equipmentList.length) {
				const foundEquipment = associatedDeviceData?.equipmentList?.find(e => e.isSelected === true);
				if (foundEquipment) {
					setSelectedModule(foundEquipment?.equipmentId)
				} else {
					setSelectedModule(0);
				}
			}
		}
	}, [selectedRows, associatedDeviceData]);

	useEffect(() => {
		if (associatedDeviceData && associatedDeviceData.equipmentList && associatedDeviceData.equipmentList.length) {
			setEquipmentList(associatedDeviceData.equipmentList);
		} else {
			setEquipmentList([]);
		}
	}, [associatedDeviceData]);

	const formSubmitHandler = () => {
		connectivityForm.current.requestSubmit();
	};

	const onConnectivityFormSubmit = () => {
		if (selectedRows && selectedRows.length) {
			let equipmentIds: any = [];
			selectedRows.map((item: any) => {
				equipmentIds.push(item.EquipmentId);
				return null;
			});
			const body = {
				panelId: equipmentGroupId,
				uniqueEquipmentClassId: equipmentClassId,
				equipmentIds: equipmentIds
			};
			onConnectDevices && onConnectDevices(selectedModule, body);
		}
	};

	const renderFooter = () => {
		return (
			<div>
				<div>
					<Button label="Cancel" className="cancel-btn" onClick={() => {
						onHideModal();
						setSelectedModule(0);
						dispatch(setAssociatedDevicesData([]));
					}} />
					<Button
						label="Save"
						className="save-btn"
						onClick={formSubmitHandler}
					/>
				</div>
			</div>
		);
	};

	return (
		<>
			<Dialog
				header={"Set Connectivity"}
				visible={showModal}
				onHide={() => {
					setSelectedModule(0);
					dispatch(setAssociatedDevicesData([]));
					setShowModal(false);
					onHideModal();
				}}
				style={{ width: "40vw" }}
				footer={renderFooter()}
				className="add-overlay connectivity-modal"
			>
				<form onSubmit={handleSubmit(onConnectivityFormSubmit)} ref={connectivityForm}>
					<div className="field col-12 md:col-4 ">
						<div className="row">
							<div className="col-4 d-flex align-items-center">
								<p className="p-label">System</p>
							</div>
							<div className="col-8">
								<p className="p-value">{associatedDeviceData?.system?.systemName}</p>
							</div>
						</div>
						<div className="row">
							<div className="col-4 d-flex align-items-center">
								<p className="p-label">Equipment Group</p>
							</div>
							<div className="col-8">
								<p className="p-value">{associatedDeviceData?.panel?.panelName}</p>
							</div>
						</div>
						<div className="row">
							<div className="col-4 d-flex align-items-center">
								<p className="p-label">{associatedDeviceData?.associatedClass?.equipmentClassName}</p>
							</div>
							<div className="col-8">
								<span className="p-float-label">
									<Controller
										name="associatedDeviceId"
										control={control}
										render={({ field, fieldState }) => (
											<Dropdown
												inputId="associatedDeviceId"
												className={classNames("w-100 error-tooltip ", { error: fieldState.invalid })}
												{...field}
												value={selectedModule}
												onChange={(e) => {
													setSelectedModule(e.value);
												}}
												options={[{ address: '-Select-', equipmentId: 0 }, ...equipmentList]}
												optionLabel="address"
												optionValue="equipmentId"
											/>
										)}
									/>
								</span>
							</div>
						</div>
					</div>

				</form>
			</Dialog>
		</>
	);
};

export default Connectivity;
