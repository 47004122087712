import React from 'react'

const DisplaySettingsDocument = () => {
  return (
    <>
      <h4 className="doc-title mb-2">
        Display Settings 
      </h4>
      <p>
        This option is used to customize the Data Display Settings of data items in Inspection Details page. 
        The Inspection Equipment Type Attributes that are to be shown in the Drawing, Tooltip, Data Grid in 
        <span className="doc-subtitle"> Inspection Tasks: Drawing</span> and 
        <span className="doc-subtitle"> Inspection Tasks: Inspection Details </span> 
        option are displayed here. Inspection Equipment type Attributes will be listed here with check boxes 
        against Show in Drawing, Show in Tooltip and Show in Grid. The desired boxes may be checked, and 
        the setting can be saved by clicking the <span className="doc-subtitle">Update </span> button. 
        The font size and color of the text displayed in the Inspection drawing can be set by entering the 
        required font size in the text box and selecting the desired color provided against each data field. 
      </p>

      <p>
        <span className="doc-subtitle"><b>Field Order: </b></span>The order, 
        in which the Equipment Type Attributes will be displayed, can be changed by clicking on the 
        <span className="doc-subtitle"> Field Order</span> button. 
        When the Fields are displayed, the order can be changed by selecting the Fields one by one, 
        and moving them Up and Down using the arrow buttons provided. Save this Field Order by clicking 
        on the Save Changes button.
      </p>

      <p>
        <span className="doc-subtitle"><b>Save Changes: </b></span>
        The default factory settings can be modified as required and saved as the default settings by 
        clicking on the Save Changes button. The fields in the Equipment Details in Inspection Tasks option
        will be displayed according to this settings.
      </p>
       
      
    </>
  );
}
export default DisplaySettingsDocument;