export function toTreeViewData(reportData: any) {
  const resultData: any = [];
  reportData.forEach((element: any) => {
    let reportDetails: any = {
      key: element?.inspectionGroupSectionId,
      label: element?.inspectionGroupSectionName,
      ...(element?.isSection === true) && { children: toTreeViewData(element?.inspectionGroupSections) }
    }
    resultData.push(reportDetails);
  });

  return resultData;
}

export function toCheckBoxGridData(reportData: any) {
  const resultData: any = [];
  reportData.forEach((element: any, index: number) => {
    let reportDetails: any = {
      dataFieldId: element?.dataFieldId,
      dataFieldName: element?.dataFieldName,
      // colId:element?.reports.length>0 ? getColumnDetails(element?.reports):[],
      ...(element?.isSection === true) && { reportFields: toCheckBoxGridData(element?.reportFields) }

    }

    resultData.push(reportDetails);
  });

  return resultData;
}

export const getFirstElement = (values, elements) => {
  values?.forEach(element => {
    if (element?.reportFields?.length) {
      elements.push(element);
    } else {
      getFirstElement(element?.children, elements);
    }
  });
  return elements[0];
}

export const modifiedUpdatedData = (treeData, selectedNode, updatedData) => {
  treeData?.forEach(element => {
    if (element?.key === selectedNode) {
      element.reportFields = updatedData;
    } else {
      if (element?.children?.length) {
        modifiedUpdatedData(element?.children, selectedNode, updatedData)
      }
    }
  });
  return treeData;
}