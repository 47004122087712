import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AppThunk } from "redux/store";
import { baseURL, zlink } from "service/HttpService";

export interface LogBookState {
    errors: any;
    userRoles: any;
    modules: any;
    users: any;
    entities: any;
    activities: any;
    logBookList: any;
    logPageDetails:any;
}

let initialState: LogBookState = {
    errors: "",
    userRoles: "",
    modules: "",
    users: "",
    entities: "",
    activities: "",
    logBookList: "",
    logPageDetails:[],
}

const LogBookSLice = createSlice({
    name: "logbook",
    initialState,
    reducers: {
        setErrors: (state, { payload }: PayloadAction<any>) => {
            state.errors = payload;
        },
        setUserRoles: (state, { payload }: PayloadAction<any>) => {
            state.userRoles = payload;
        },
        setModules: (state, { payload }: PayloadAction<any>) => {
            state.modules = payload;
        },
        setUsers: (state, { payload }: PayloadAction<any>) => {
            state.users = payload;
        },
        setEntities: (state, { payload }: PayloadAction<any>) => {
            state.entities = payload;
        },
        setActivities: (state, { payload }: PayloadAction<any>) => {
            state.activities = payload;
        },
        setLogBookList: (state, { payload }: PayloadAction<any>) => {
            state.logBookList = payload;
        },
        setLogPageDetails: (state, {payload}: PayloadAction<any>) => {
			state.logPageDetails = payload;
		},
    }
});

export const onGetUserRoles = (): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.get(`${baseURL}api/v1/common/user/userroles`);
        if (res.status === "done") {
            let roleList = res?.data?.userRoles
            roleList.splice(0, 0, { userRoleId: "0", userRoleName: "All Users" });
            dispatch(setUserRoles(roleList));
        } else {
            dispatch(setErrors(res.error));
        }
    } catch (error) {
        dispatch(setErrors(error));
    }
};

export const onGetModules = (): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.get(`${baseURL}api/v1/modules`);
        if (res.status === "done") {
            dispatch(setModules(res?.data?.cloudDetails));
        } else {
            dispatch(setErrors(res.error));
        }
    } catch (error) {
        dispatch(setErrors(error));
    }
};

export const onGetUsers = (body): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.post(`${baseURL}api/v1/logbook/users`, body);
        if (res.status === "done") {
            dispatch(setUsers(res?.data?.userDetails));
        } else {
            dispatch(setErrors(res.error));
        }
    } catch (error) {
        dispatch(setErrors(error));
    }
};

export const onGetEntities = (body): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.post(`${baseURL}api/v1/logbook/entities`, body);
        if (res.status === "done") {
            dispatch(setEntities(res?.data?.entityDetails));
        } else {
            dispatch(setErrors(res.error));
        }
    } catch (error) {
        dispatch(setErrors(error));
    }
};

export const onGetActivities = (body): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.post(`${baseURL}api/v1/logbook/events`, body);
        if (res.status === "done") {
            dispatch(setActivities(res?.data?.eventDetails));
        } else {
            dispatch(setErrors(res.error));
        }
    } catch (error) {
        dispatch(setErrors(error));
    }
};

export const onGetLogBookList = (body,currentStart, sortField: any = "", sortOrder: any = ""): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.post(`${baseURL}api/v1/logbook/logs?currentstart=${currentStart}&sortcolumn=${sortField}&sortorder=${sortOrder}`, body);
        if (res.status === "done") {
            dispatch(setLogPageDetails(res?.data?.pageDetails));
            dispatch(setLogBookList(res?.data));
        } else {
            dispatch(setErrors(res.error));
        }
    } catch (error) {
        dispatch(setErrors(error));
    }
};

export const onLogBookExport = (body: any): AppThunk => async (dispatch) => {
    try {
        const request: any = {
            method: "POST",
            cache: "no-store",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                Authorization: sessionStorage.getItem("accesstoken"),
            },
            body: JSON.stringify(body)
        };
        const downloadUrl = (window as any).envSettings.baseAPIUrl + `api/v1/logbook/export`;
        await fetch(downloadUrl, request).then((response: any) => {
            if (response.ok) {
                response.blob().then((blob: any) => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement("a");
                    a.href = url;
                    a.download = "Log Details";
                    a.click();
                });
            }
        });
    } catch (error) {
        dispatch(setErrors(error));
    }
};

export const {
    setErrors,
    setUserRoles,
    setModules,
    setUsers,
    setEntities,
    setActivities,
    setLogBookList,
    setLogPageDetails,
} = LogBookSLice.actions;

export default LogBookSLice.reducer;