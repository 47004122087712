export const customBarChartTooltip = ({ active, payload, label }: any) => {	
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <div className="tooltip-title"> {label} </div>       
        {payload.reverse().map((item, index) => (
          <p key={index} style={{color: `${item.fill ? item.fill : item.color}`}}>
            {`${item.name}: ${item.value}%`}
          </p>
        ))}
      </div>
    );
  }
  return null;
};

export const customPieChartTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p style={{ color: `${payload[0].payload?.fill}` }}>{`${payload[0].name}: ${payload[0].value}%`}</p>
      </div>
    );
  }
  return null;
};

export const customInspectionDetailsChartTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <div className="tooltip-title"> {label} </div>       
        {payload.reverse().map((item, index) => (
          <p key={index} style={{color: `${item.fill ? item.fill : item.color}`}}>
             {`${item.name}: ${item.name == "Active" ? item?.payload?.activeCount:(item.name == "Closed"?item?.payload?.closeCount:(item.name == "Completed"?item?.payload?.completedCount:item?.payload?.inactiveCount))}`}
          </p>
        ))}
      </div>
    );
  }
  return null;
};

export const customContractChartTooltip = ({ active, payload, label }: any) => {	
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <div className="tooltip-title"> {label} </div>       
        {payload.map((item, index) => (
          <p key={index} style={{color: `${item.fill ? item.fill : item.color}`}}>
            {`${item.name}: ${item.value}%`}
          </p>
        ))}
      </div>
    );
  }
  return null;
};

