import React, { useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";

import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { Calendar } from "primereact/calendar";
import { BsExclamationCircleFill } from "react-icons/bs";

import { useDispatch } from "react-redux";
import { useAppSelector } from "redux/hooks";
import { getCommonAttributeValueDetails, setCommonAttributeValueDetails } from "redux/pages/Manage/Customization/Attributes/CommonAttributeSlice";
import AddModal from "components/Table/AddModal/AddModal";
import { Button } from "primereact/button";

interface Props {
  showModal: boolean;
  commonAttributeId: any;
  commonAttributeType: any;
  commonAttributeName: any;
  maxLength: any;
  selectedRows: any;
  setShowModal: (state: boolean) => void;
  onEditCommonAttributeValueClick: (data: any) => void;
}

type FormData = {
  AttributeName: string;
  Value: any;
};

export const EditCommonAttibutesValues: React.FC<Props> = ({
  showModal = false,
  setShowModal = () => {},
  onEditCommonAttributeValueClick = () => {},
  commonAttributeId = "",
  commonAttributeType = "",
  commonAttributeName = "",
  maxLength = "",
  selectedRows = [],
}) => {
  const commonAttributeValueDetails = useAppSelector(
    (state) => state.commonAttribute.commonAttributeValueDetails
  );

  const dispatch = useDispatch<any>();
  const cal = React.createRef<Calendar>();

  const editForm: any = useRef();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      AttributeName: "",
      Value: "",
    },
  });

  useEffect(() => {
    dispatch(getCommonAttributeValueDetails(commonAttributeId, selectedRows[0].dataFieldLookUpId));
    return (() => {
      dispatch(setCommonAttributeValueDetails({}));
    });
  }, [dispatch, selectedRows, commonAttributeId]);

  useEffect(() => {
    if (commonAttributeValueDetails) {
      if(commonAttributeType==="Date Time" || commonAttributeType==="Date"){
      setValue("Value",new Date(commonAttributeValueDetails?.attributeValue?.replace(/-/g, '\/')));
    }
      else{
        setValue("Value", commonAttributeValueDetails?.attributeValue);
      }
    
    }
  }, [commonAttributeValueDetails, setValue]);

  const formSubmitHandler = (data: any) => {
    editForm.current.requestSubmit();
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && (
        <span className="tooltip-text">
          <BsExclamationCircleFill />
          {errors[name].message}
        </span>
      )
    );
  };

  const onEditFormSubmit = (submitData: any) => {
    onEditCommonAttributeValueClick &&
      onEditCommonAttributeValueClick(submitData);
  };

  const onError = (errors: any, e: any) => {};

  return (
    <>
      <AddModal
        header="Edit Common Attribute Value"
        showModal={showModal}
        isAdd={false}
        setShowModal={setShowModal}
        style={{ width: "35vw", height: '30vh' }}
        OnSaveClick={formSubmitHandler}
      >
        <form onSubmit={handleSubmit(onEditFormSubmit, onError)} ref={editForm}>
          <div className="row px-2 pt-2">
            <div className="field col-12 md:col-2 mb-0">
              <span className="p-float-label text-container">
                <label htmlFor="inputtext">Attribute Name</label>
                <Controller
                  name="AttributeName"
                  control={control}
                  render={() => (
                    <span className="text-value">{commonAttributeName}</span>
                  )}
                />
              </span>
            </div>
          </div>
          {commonAttributeType === "Single Line Text" ? (
            <div className="row px-2 pb-2">
              <div className="field col-12 md:col-2 mb-0">
                <span className="p-float-label">
                  <Controller
                    name="Value"
                    control={control}
                    rules={{
                      required: "Enter Value",
                    }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id="name"
                        maxLength={maxLength}
                        className={classNames("w-100 error-tooltip", {
                          error: fieldState.invalid,
                        })}
                        {...field}
                        aria-autocomplete='none'
                      />
                    )}
                  />
                  <label className="mandatory" htmlFor="inputtext">
                    Value
                  </label>

                  {getFormErrorMessage("Value")}
                </span>
              </div>
            </div>
          ) : null}
          {commonAttributeType === "Date" ? (
            <div className="row px-2 pb-2">
              <div className="field col-12 md:col-2 mb-0">
                <span className="p-float-label">
                  <Controller
                    name="Value"
                    control={control}
                    rules={{
                      required: "Enter Value",
                    }}
                    render={({ field, fieldState }) => (
                      <Calendar
                        id="Date"
                        className={classNames("w-100 error-tooltip", {
                          error: fieldState.invalid,
                        })}
                        dateFormat="yy-mm-dd"
                        {...field}
                        value={field.value}
                        onChange={(e) => field.onChange(e.value)}
                      />
                    )}
                  />
                  <label className="mandatory" htmlFor="calendar">
                    Value
                  </label>

                  {getFormErrorMessage("Value")}
                </span>
              </div>
            </div>
          ) : null}
          {commonAttributeType === "Date Time" ? (
            <div className="row px-2 pb-2">
              <div className="field col-12 md:col-2 mb-0">
                <span className="p-float-label">
                  <Controller
                    name="Value"
                    control={control}
                    rules={{
                      required: "Enter Value",
                    }}
                    render={({ field, fieldState }) => (
                      <Calendar
                        id="Date Time"
                        {...field}
                        ref={cal}
                        className={classNames("w-100 error-tooltip", {
                          error: fieldState.invalid,
                        })}
                        dateFormat="yy-mm-dd"
                        showTime
                        showSeconds
                        value={field.value}
                        onChange={(e) => field.onChange(e.value)}
                        footerTemplate={() => <div>
                          <Button className="confirm-btn" onClick={() => cal.current?.hide()}>Save</Button>
                      </div>}
                      />
                    )}
                  />
                  <label className="mandatory" htmlFor="calendar">
                    Value
                  </label>

                  {getFormErrorMessage("Value")}
                </span>
              </div>
            </div>
          ) : null}
          {commonAttributeType === "Integer" ? (
            <div className="row px-2 pb-2">
              <div className="field col-12 md:col-2 mb-0">
                <span className="p-float-label">
                  <Controller
                    name="Value"
                    control={control}
                    rules={{
                      required: "Enter Value",
                      pattern: {
                        value: /^[0-9]+$/,
                        message: " 'Value' is an Integer Field",
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id="name"
                        maxLength={maxLength}
                        className={classNames("w-100 error-tooltip", {
                          error: fieldState.invalid,
                        })}
                        {...field}
                        aria-autocomplete='none'
                      />
                    )}
                  />
                  <label className="mandatory" htmlFor="inputtext">
                    Value
                  </label>

                  {getFormErrorMessage("Value")}
                </span>
              </div>
            </div>
          ) : null}
          {commonAttributeType === "Numeric" ? (
            <div className="row px-2 pb-2">
              <div className="field col-12 md:col-2 mb-0">
                <span className="p-float-label">
                  <Controller
                    name="Value"
                    control={control}
                    rules={{
                      required: "Enter Value",
                      pattern: {
                        value: /^\d*\.?\d*$/,
                        message: "'Value' is a Numeric Field",
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id="name"
                        maxLength={maxLength}
                        className={classNames("w-100 error-tooltip", {
                          error: fieldState.invalid,
                        })}
                        {...field}
                        aria-autocomplete='none'
                      />
                    )}
                  />
                  <label className="mandatory" htmlFor="inputtext">
                    Value
                  </label>

                  {getFormErrorMessage("Value")}
                </span>
              </div>
            </div>
          ) : null}
          {commonAttributeType === "Multi Line Text" ? (
            <div className="row px-2 pb-2">
              <div className="field col-12 md:col-2 mb-0">
                <span className="p-float-label">
                  <Controller
                    name="Value"
                    control={control}
                    rules={{
                      required: "Enter Value",
                    }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id="name"
                        maxLength={maxLength}
                        className={classNames("w-100 error-tooltip", {
                          error: fieldState.invalid,
                        })}
                        {...field}
                        aria-autocomplete='none'
                      />
                    )}
                  />
                  <label className="mandatory" htmlFor="inputtext">
                    Value
                  </label>

                  {getFormErrorMessage("Value")}
                </span>
              </div>
            </div>
          ) : null}
        </form>
      </AddModal>
    </>
  );
};

export default EditCommonAttibutesValues;
