export const USAPattern = [
    {
        id: 1,
        pattern: "USA Pattern",
        format: "4433-xxxxx-xxxxx",
        description: "4433-5digits-5digits",
    },
    {
        id: 2,
        pattern: "USA Pattern",
        format: "4433-xxxxx-xxxx",
        description: "4433-5digits-4digits",
    },
    {
        id: 3,
        pattern: "USA Pattern",
        format: "44OP-xxxxxx",
        description: "44OP-6digits",
    },
    {
        id: 4,
        pattern: "USA Pattern",
        format: "44OC-xxxxxxx",
        description: "44OC-7digits",
    }
]
