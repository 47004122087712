import React, { useState, useEffect, memo, useRef } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";
import { OverlayPanel } from "primereact/overlaypanel";
import { Overlay, Tooltip } from "react-bootstrap";
//components
import DataTableComponent from "components/Table/DataTable";
import InformationBox from "components/common/Message/InformationBox/InformationBox";
//icons
import { AiOutlineCloseCircle } from "react-icons/ai";
import { FaDownload, FaUpload } from "react-icons/fa";
import { HiOutlineBadgeCheck } from "react-icons/hi";
//utility
import { getImportEquipmentColumns } from "../utility";
//redux
import { useAppSelector } from "redux/hooks";
import { useDispatch } from "react-redux";
import {
	onExportEquipmentData,
	onImportEquipmentData,
	onVerifiedFetchedData,
	setFetchedEquipmentData,
	setImportedEquipmentData,
	setVerifiedFetchedData,
} from "redux/pages/Inspection/InspectionTask/InspectionDetails/Equipment/EquipmentSlice";

interface Props {
	showModal: boolean;
	setShowModal: (state: boolean, isImportDone: boolean) => void;
	excelDetails?: any;
	bodyInfo?: any;
	selectedEquipmentType?: any;
	selectedEquipmentGroup?: any;
	selectedTask?: any;
}

type FormData = {
	customer: string;
	contract: string;
	name: string;
	system: string;
	leadTech: any;
	plannedStartDate: any;
	plannedEndDate: any;
	notificationNumber: any;
	techQty: string;
	techRequired: boolean;
};

export const EquipmentListDataImport: React.FC<Props> = ({
	showModal = false,
	excelDetails,
	bodyInfo,
	selectedEquipmentType,
	selectedEquipmentGroup,
	selectedTask,
	setShowModal = () => { },
}) => {
	const [gridData, setGridData] = useState<any>([]);
	const [columns, setColumns] = useState<any>([]);
	const [pageDetails, setPageDetails] = useState<any>();
	const [pageIndex, setPageIndex] = useState(0);
	const [displayInfoModal, setDisplayInfoModal] = useState(false);
	const [message, setMessage] = useState<string>("");
	const [importProgress, setImportProgress] = useState({ isValidationDone: false, isImportDone: false });
	//tooltip
	const [target, setTarget] = useState(null);
	const [rowNumber, setRowNumber] = useState("");
	const [showProgress, setShowProgress] = useState(false);
	const [progressMessage, setProgressMessage] = useState("");
	const settingRef = React.createRef<OverlayPanel>();

	const dispatch = useDispatch<any>();
	const fetchedEquipmentData = useAppSelector((state) => state.equipment.fetchedEquipmentData);
	const verifiedFetchedData = useAppSelector((state) => state.equipment.verifiedFetchedData);
	const importedEquipmentData = useAppSelector((state) => state.equipment.importedEquipmentData);

	useEffect(() => {
		// setProgressMessage("Fetching Data..");
		// setShowProgress(true);
	}, [])

	useEffect(() => {
		if (fetchedEquipmentData?.status === "done") {
			const conv = JSON.parse(JSON.stringify(fetchedEquipmentData?.data));
			const column = getImportEquipmentColumns(conv?.data[0], false);
			setColumns([...column]);
			setGridData([...conv?.data]);
			setPageDetails(conv?.pageDetails);
			dispatch(setFetchedEquipmentData(""));
			setProgressMessage("");
			setShowProgress(false);
		}
	}, [fetchedEquipmentData]);

	useEffect(() => {
		if (verifiedFetchedData) {
			const column = getImportEquipmentColumns(verifiedFetchedData?.data[0], true);
			setColumns([...column]);
			setGridData([...verifiedFetchedData?.data]);
			setPageDetails(verifiedFetchedData?.pageDetails);
			setMessage("Validation completed");
			setDisplayInfoModal(true);
			dispatch(setVerifiedFetchedData(""));
			setImportProgress((flag) => ({ ...flag, isValidationDone: true }));
			setProgressMessage("");
			setShowProgress(false);
		}
	}, [verifiedFetchedData]);

	useEffect(() => {
		if (importedEquipmentData) {
			const column = getImportEquipmentColumns(importedEquipmentData?.data[0], true);
			setColumns([...column]);
			setGridData([...importedEquipmentData?.data]);
			setPageDetails(importedEquipmentData?.pageDetails);
			setMessage(importedEquipmentData?.status);
			setDisplayInfoModal(true);
			dispatch(setImportedEquipmentData(""));
			setImportProgress((flag) => ({ ...flag, isImportDone: true }));
			setShowProgress(false);
			setProgressMessage("");
		}
	}, [importedEquipmentData]);

	const onPageChanged = (options: any) => {
		if (options !== pageIndex) {
			setPageIndex(options);
		}
	};

	const onDataVerify = () => {
		const finalData = gridData?.map(({ RowNumber, ValidationStatusId, ...rest }: any) => {
			return rest;
		});

		const body = {
			uniqueInspectionTaskId: selectedTask?.uniqueInspectionTaskId,
			uniqueSystemId: selectedTask?.system?.uniqueSystemId,
			EquipmentId: 0,
			PanelId: selectedEquipmentGroup,
			WorksheetId: excelDetails?.sheetName,
			columnDetails: bodyInfo.columnDetails,
			rowDetails: finalData,
		};
		dispatch(onVerifiedFetchedData(excelDetails.classId, excelDetails.uploadedId, excelDetails.sheetIndex, body));
		setProgressMessage("Validating");
		setShowProgress(true);
	};

	const onDataImport = () => {
		const finalData = gridData?.map(({ RowNumber, ValidationStatusId, ...rest }: any) => {
			return rest;
		});

		const body = {
			uniqueInspectionTaskId: selectedTask?.uniqueInspectionTaskId,
			uniqueSystemId: selectedTask?.system?.uniqueSystemId,
			EquipmentId: 0,
			PanelId: selectedEquipmentGroup,
			columnDetails: bodyInfo.columnDetails,
			rowDetails: finalData,
			workSheetId: excelDetails?.sheetName,
		};
		dispatch(onImportEquipmentData(excelDetails.classId, excelDetails.uploadedId, excelDetails.sheetIndex, body));
		setShowProgress(true);
		setProgressMessage("Import in Progress")
	};

	const onDataExport = () => {
		if (!importProgress?.isImportDone && !importProgress?.isValidationDone) {
			setMessage("No error data to export");
			setDisplayInfoModal(true);
			return;
		}
		const finalData = gridData?.map(({ RowNumber, ...rest }: any) => {
			return rest;
		});
		let rowDetails = [];
		if (!importProgress?.isImportDone && importProgress?.isValidationDone) {
			rowDetails = finalData?.filter((item) => item?.["Tech Advance + Comments"] !== "Passed");
		} else if (importProgress?.isImportDone && !importProgress?.isValidationDone) {
			rowDetails = finalData?.filter((item) => item?.["Tech Advance + Comments"] !== "Data saved");
		} else if (importProgress?.isImportDone && importProgress?.isValidationDone) {
			rowDetails = finalData;
		}

		const body = {
			uniqueInspectionTaskId: selectedTask?.uniqueInspectionTaskId,
			uniqueSystemId: selectedTask?.system?.uniqueSystemId,
			EquipmentId: 0,
			PanelId: selectedEquipmentGroup,
			columnDetails: bodyInfo.columnDetails,
			rowDetails: rowDetails,
			workSheetId: excelDetails?.sheetName,
		};
		dispatch(onExportEquipmentData(excelDetails.classId, excelDetails.uploadedId, excelDetails.sheetIndex, body));
	};

	const onRowEditClick = (rowData) => {
		return true;
	};

	const onRowEditValidation = (rowData) => {
		return true;
	};

	const onImportEquipmentRowEdit = (rowData, index) => {
		gridData[index] = rowData;
		setGridData([...gridData]);
	};

	const onShowToolTip = (e, indexData, options) => {
		if (options?.field === "Tech Advance + Comments" && indexData?.[options?.field] != "Passed") {
			setTarget(e.currentTarget);
			setRowNumber(indexData?.RowNumber);
		}
	};

	const onHideToolTip = () => {
		setTarget(null);
		setRowNumber("");
	};

	const bodyTemplate = (rowData, options) => {
		return (
			<div className="table-col">
				{options?.field === "Tech Advance + Comments" ? (
					<>
						<Overlay show={rowNumber === rowData?.RowNumber} key={rowData.RowNumber} placement="left" target={target}>
							<Tooltip id={`tooltip${rowData?.RowNumber}`} className="data-import-tooltip">
								<div id="tooltip-description">{rowData?.[options?.field]}</div>
							</Tooltip>
						</Overlay>
						<span
							onMouseEnter={(e: any) => onShowToolTip(e, rowData, options)}
							onMouseLeave={() => onHideToolTip()}
							className={rowData?.["ValidationStatusId"] === "0" ? "warning" : rowData?.["ValidationStatusId"] === "-1" ? "failed" : ""}
						>
							{" "}
							{rowData?.["Tech Advance + Comments"]}
						</span>
					</>
				) : (
					<div className={rowData?.["ValidationStatusId"] === "0" ? "warning" : rowData?.["ValidationStatusId"] === "-1" ? "failed" : ""}>
						{rowData?.[options?.field]}
					</div>
				)}
			</div>
		);
	};

	return (
		<>
			<Dialog showHeader={false} visible={showModal} onHide={() => setShowModal(false, importProgress.isImportDone)} className="equi-list-data-import">
				<div className="custom-header">
					<div className="row">
						<div className="col-6 box py-1 pr-0 pl-1">
							<div className="title">Data Import</div>
						</div>

						<div className="col-6 box py-1 pl-0 align-items-end pr-1">
							<i className="pi pi-times" onClick={() => setShowModal(false, importProgress.isImportDone)}></i>
						</div>
					</div>
				</div>

				<div className="container-fluid ">
					<div className="second-row row">
						<div className="icon-wrapper col-12">
							<div className=" table-config">
								<div className="action-btns">
									<Button className="button-icon" tooltip="Validate" tooltipOptions={{ position: "top" }} onClick={() => onDataVerify()}>
										<HiOutlineBadgeCheck className="icon" />
									</Button>
									<Button className="button-icon" tooltip="Import" tooltipOptions={{ position: "top" }} onClick={() => onDataImport()}>
										<FaUpload className="icon" />
									</Button>
									<Button className="button-icon" tooltip="Export" tooltipOptions={{ position: "top" }} onClick={() => onDataExport()}>
										<FaDownload className="icon" />
									</Button>
									<Button
										className="button-icon"
										tooltip="Close"
										tooltipOptions={{ position: "top" }}
										onClick={() => setShowModal(false, importProgress.isImportDone)}
									>
										<AiOutlineCloseCircle className="icon" />
									</Button>
								</div>
							</div>
						</div>
					</div>
				</div>
				{showProgress && (
					<div className="pre-loader">
						<ProgressSpinner />
						<h6>{progressMessage}</h6>
					</div>
				)}
				{columns?.length > 0 && (
					<DataTableComponent
						rows={gridData}
						cols={columns}
						paginator={true}
						dataKeyId="RowNumber"
						title={``}
						pageDetails={pageDetails}
						pageIndex={pageIndex}
						onPageChanged={onPageChanged}
						showCustomSearchCmp
						settingRef={settingRef}
						showroweditor={true}
						isLazyLoading={false}
						onRowEditClick={onRowEditClick}
						onRowEditValidation={onRowEditValidation}
						onSaveRowEdit={onImportEquipmentRowEdit}
						templateFromParent={bodyTemplate}
						isTemplateMode={true}
						isTemplateFromParent={true}
						settingListItems={[
							{
								id: 1,
								name: "Close",
								onClick: () => {
									setShowModal(false, importProgress.isImportDone);
								},
							},
						]}
					/>
				)}
			</Dialog>
			<InformationBox showInfoModal={displayInfoModal} setShowInfoModal={setDisplayInfoModal} message={message} />
		</>
	);
};

export default memo(EquipmentListDataImport);
