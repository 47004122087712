import { Button } from 'primereact/button'
import React from 'react'
import { BsFillPencilFill, BsPlusLg, BsTrashFill } from 'react-icons/bs'
import { FaCog, FaDownload } from 'react-icons/fa'

const EquipmentTypeAttributesDocument = () => {
  return (
    <>
        <h4 className="doc-title mb-2">
            Equipment Type Attributes
        </h4>

        <p>
            Equipment Type Attributes are those attributes of Equipment which are not common to all equipment. 
            These have to be defined separately for each Equipment Type. Users can add/edit/delete inspection 
            Equipment Type Attributes through this option. The Attribute Name, Field Type 
            (Date, Date Time, Integer, Multi Line Text, Numeric or Single Line Text), whether Mandatory or 
            not and whether a Validated field or not have to be specified. For Validated Attributes, the set 
            of allowed values can be specified through the Equipment Type Attribute Value option. 
            These Equipment Type Attributes values are used in for listing the Equipment details in 
            Inspection: Inspection Task: Inspection Details option. 
        </p>

        <p>
            The Equipment Type combo box will be displayed in the page listing all the Equipment Types. 
            The Equipment Types listed are AHU, Air Sampling Systems, Devices, Doors, Elevators, Emergency Communications, 
            Emergency Lighting, Fire Pumps, Gaseous Extinguishing Systems, Kitchen and Dry Chemical Ext Systems, Modules, NAC Panels, 
            Portable Extinguishers, Sprinklers and Water Based Equipments.  On selecting an Equipment Type from the combo box, 
            all the Equipment Type Attributes (if any) associated with it will be displayed in the data grid.  
        </p>

        <p>
            <b><span className="doc-subtitle">Add:</span></b>
        </p>
        <p> 
            An Equipment Type Attribute can be created by clicking the 
            <span className="doc-subtitle"> Add</span>{" "}
            <Button className="doc-button-icon"> 
                <BsPlusLg className="icon" title='Add'/> 
            </Button> {" "} icon.
        </p>

        <p>
            <b><span className="doc-subtitle">Edit:</span></b>
        </p>
        <p>
            Edit an Equipment Type Attribute by selecting it and then clicking the 
            <span className="doc-subtitle"> Edit</span>{' '}
            <Button className="doc-button-icon"> 
                <BsFillPencilFill className="icon" title='Edit'/> 
            </Button> {' '} icon. 
        </p>

        <p>
            <b><span className="doc-subtitle">Delete:</span></b>
        </p>
        <p>
            Delete an Equipment Type Attribute by selecting it and then clicking the 
            <span className="doc-subtitle"> Delete</span> {" "}
            <Button className="doc-button-icon"> 
                <BsTrashFill className="icon" title='Delete'/> 
            </Button> {" "} icon.
        </p> 


        <p>
            <b><span className="doc-subtitle">Download</span></b> {" "}
            <Button className="doc-button-icon">
                <FaDownload className="icon" title='Download'/>
            </Button>{" "}
        </p>
        
        <p>
            This option lists Report All, Report Highlighted, Export All, Export Highlighted and Group Highlighted. 
        </p>

        <p>
            <span className="doc-subtitle">Report All: </span>
        </p>

        <p>
            This option is for generating a report with all the records in the data grid. The report can be saved to PDF, 
            Text, Rich Text or Excel format by selecting the file format from the list under Save option, and then clicking OK button 
            after selecting the required options from the Export Settings window.
        </p>

        <p>
            <span className="doc-subtitle">Report Highlighted: </span>
        </p>
        <p>
            This option is for generating a report with only selected records in the grid. 
            Select the desired rows by holding down the control key and clicking on each of the records to be selected. 
            Users can also select a set of rows using the shift key. The selected records will be highlighted. Click on 
            the <span className="doc-subtitle">Report Highlighted </span> option in the <span className="doc-subtitle">Download </span>{" "}
            <Button className="doc-button-icon"><FaDownload className="icon" title='Download'/></Button>{" "} menu to generate the report. 
            The report will be created with the highlighted records only. The report can be saved to PDF, Text, 
            Rich Text or Excel format by selecting the file format from the list under Save option, and then clicking OK button after 
            selecting the required options from the Export Settings window.
        </p>


        <p>
            <b><span className="doc-subtitle">More: </span></b>{' '}
            <Button className="doc-button-icon">
                <FaCog className="icon" title='More'/>
            </Button>{" "}
        </p>
        <p>
            This option lists Field Values and Display Settings option. 
        </p>


        <p>
            <span className="doc-subtitle">Field Values:</span>
        </p>
        <p>
            For validated fields, a set of allowed values will also have to be specified through this option. 
            See <span className="doc-subtitle">Setup: Attributes: Equipment Type Attribute Values</span> for more information.
        </p>


        <p>
            <span className="doc-subtitle">Display Settings</span>
        </p>
        <p>
            This option allows the user to change the visibility of the data fields displayed in the data grid. 
            On selecting this option, all the available data fields are displayed in a window. Display settings can be modified by 
            selecting the required fields from the list. The grid settings modified through this option will not be saved and will be 
            effective only for the current instance of the data grid. 
        </p>


    </>
  );
}
export default EquipmentTypeAttributesDocument;
