import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface EventControllerState {
    isEmptyServiceCallQueue: boolean;
}

const initialState: EventControllerState = {
    isEmptyServiceCallQueue: false
};

const EventControllerSlice = createSlice({
    name: "eventController",
    initialState,
    reducers: {
        setIsEmptyServiceCallQueue(state, { payload }: PayloadAction<boolean>) {
            state.isEmptyServiceCallQueue = payload;
        },
    },
});

export const { setIsEmptyServiceCallQueue } =
    EventControllerSlice.actions;
export default EventControllerSlice.reducer;