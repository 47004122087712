import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AppThunk } from "redux/store";
import { baseURL, zlink } from "service/HttpService";


export interface inspectionDetailsState {
    errors: any;
    inspectionQuestion: any;
    updatedQuestionDatafields: any;
    inspectionContracts: any;
    inspectionSystems: any;
    updatedContractDatafields: any;
    updatedSystemDatafields: any;
    inspectionSystemFieldValues: any;
    inspectionContractFieldValues: any;
    inspectionQuestionFieldValues: any;
    contractUpdated: boolean;
    monitoringValues: any;
    typeOfTriggerValue: any;
}

let initialState: inspectionDetailsState = {
    errors: "",
    inspectionQuestion: "",
    updatedQuestionDatafields: "",
    inspectionContracts: "",
    inspectionSystems: "",
    updatedContractDatafields: "",
    updatedSystemDatafields: "",
    inspectionSystemFieldValues: "",
    inspectionContractFieldValues: "",
    inspectionQuestionFieldValues: "",
    contractUpdated: false,
    monitoringValues: "",
    typeOfTriggerValue: "",
}

const InspectionDetailsSlice = createSlice({
    name: "inspectionDetails",
    initialState,
    reducers: {
        setErrors: (state, { payload }: PayloadAction<any>) => {
            state.errors = payload;
        },
        setInspectionQuestion: (state, { payload }: PayloadAction<any>) => {
            state.inspectionQuestion = payload;
        },
        setUpdatedQuestionDatafields: (state, { payload }: PayloadAction<any>) => {
            state.updatedQuestionDatafields = payload;
        },
        setInspectionContracts: (state, { payload }: PayloadAction<any>) => {
            state.inspectionContracts = payload;
        },
        setInspectionSystems: (state, { payload }: PayloadAction<any>) => {
            state.inspectionSystems = payload;
        },
        setUpdatedContractDatafields: (state, { payload }: PayloadAction<any>) => {
            state.updatedContractDatafields = payload;
        },
        setUpdatedSystemDatafields: (state, { payload }: PayloadAction<any>) => {
            state.updatedSystemDatafields = payload;
        },
        setInspectionSystemFieldValues: (state, { payload }: PayloadAction<any>) => {
            state.inspectionSystemFieldValues = payload;
        },
        setInspectionContractFieldValues: (state, { payload }: PayloadAction<any>) => {
            state.inspectionContractFieldValues = payload;
        },
        setInspectionQuestionFieldValues: (state, { payload }: PayloadAction<any>) => {
            state.inspectionQuestionFieldValues = payload;
        },
        setContractUpdated: (state, { payload }: PayloadAction<any>) => {
            state.contractUpdated = payload;
        },
        setMonitoringValues: (state, { payload }: PayloadAction<any>) => {
            state.monitoringValues = payload;
        },
        setTypeOfTriggerValue: (state, { payload }: PayloadAction<any>) => {
            state.typeOfTriggerValue = payload;
        },
    }
})

export const getInspectionQuestions = (taskId: any, panelId: any): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.get(`${baseURL}api/v1/inspection/inspectiontask/${taskId}/equipments/panels/${panelId}/inspectionquestions`);
        if (res.status === "done") {
            dispatch(setInspectionQuestion(res?.data?.inspectionGroupSections));
        } else {
            dispatch(setErrors(res.error));
        }
    } catch (error) {
        dispatch(setErrors(error));
    }
};

export const onSaveInspectionQuestion = (taskId: any, panelId: any, body, setShowProgress): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.post(`${baseURL}api/v1/inspection/inspectiontask/${taskId}/equipments/panels/${panelId}/inspectionquestions`, body);
            if (res.status === "done") {
                dispatch(setUpdatedQuestionDatafields(body));
                setShowProgress(false);
            } else {
                dispatch(setErrors(res.error));
                setShowProgress(false);
            }
        } catch (error) {
            dispatch(setErrors(error));
            setShowProgress(false);
        }
    };

export const getInspectionContracts = (taskId: any, systemId: any): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.get(`${baseURL}api/v1/inspection/inspectiontask/${taskId}/systems/${systemId}/contractfields`);
            if (res.status === "done") {
                let fileds = { inspectionGroupFields: res?.data?.contractDetails }
                dispatch(setInspectionContracts(fileds));
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

export const getInspectionSystems = (taskId: any, systemId: any): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.get(`${baseURL}api/v1/inspection/inspectiontask/${taskId}/systems/${systemId}/systemfields`);
            if (res.status === "done") {
                let fileds = { inspectionGroupFields: res?.data?.systemDetails }
                dispatch(setInspectionSystems(fileds));
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

export const onSaveInspectionContract = (taskId: any, systemId: any, body: any, taskWithOutBranch: boolean, setShowProgress): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.post(`${baseURL}api/v1/inspection/inspectiontask/${taskId}/systems/${systemId}/contractfields`, body);
            if (res.status === "done") {
                dispatch(setUpdatedContractDatafields(body));
                if (taskWithOutBranch) {
                    dispatch(setContractUpdated(true));
                }
                setShowProgress(false);
            } else {
                dispatch(setErrors(res.error));
                setShowProgress(false);
            }
        } catch (error) {
            dispatch(setErrors(error));
            setShowProgress(false);
        }
    };

export const onSaveInspectionSystem = (taskId: any, systemId: any, body: any, setShowProgress): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.post(`${baseURL}api/v1/inspection/inspectiontask/${taskId}/systems/${systemId}/systemfields`, body);
            if (res.status === "done") {
                dispatch(setUpdatedSystemDatafields(body));
                setShowProgress(false);
            } else {
                dispatch(setErrors(res.error));
                setShowProgress(false);
            }
        } catch (error) {
            dispatch(setErrors(error));
            setShowProgress(false);
        }
    };

export const onGetInspectionContractFieldValues = (taskId: any, setProgress, typeValue): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.get(`${baseURL}api/v1/inspection/inspectiontask/${taskId}/contractfields/values`);
            if (res.status === "done") {
                dispatch(setInspectionContractFieldValues(res.data.values));
                dispatch(setTypeOfTriggerValue(typeValue));
                setProgress(false);
            } else {
                dispatch(setErrors(res.error));
                setProgress(false);
            }
        } catch (error) {
            dispatch(setErrors(error));
            setProgress(false);
        }
    };

export const onGetRefreshInspectionContract = (taskId: any, setProgress, typeValue): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.get(`${baseURL}api/v1/inspection/inspectiontask/${taskId}/contractfields/refreshvalues`);
            if (res.status === "done") {
                dispatch(setInspectionContractFieldValues(res.data.values));
                dispatch(setTypeOfTriggerValue(typeValue))
                setProgress(false);
            } else {
                dispatch(setErrors(res.error));
                setProgress(false);
            }
        } catch (error) {
            dispatch(setErrors(error));
            setProgress(false);
        }
    };

export const onGetInspectionSystemFieldValues = (taskId: any, setProgress): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.get(`${baseURL}api/v1/inspection/inspectiontask/${taskId}/systemfields/values`);
            if (res.status === "done") {
                dispatch(setInspectionSystemFieldValues(res.data.values));
                setProgress(false);
            } else {
                dispatch(setErrors(res.error));
                setProgress(false);
            }
        } catch (error) {
            dispatch(setErrors(error));
            setProgress(false);
        }
    };

export const onGetInspectionQuestionFieldValues = (taskId: any, panelId: any, setShowProgress, handleEquipmentGroupChange?: any): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.get(`${baseURL}api/v1/inspection/inspectiontask/${taskId}/equipments/panels/${panelId}/inspectionquestions/values`);
            if (res.status === "done") {
                dispatch(setInspectionQuestionFieldValues(res.data.values));
                handleEquipmentGroupChange();
                setShowProgress(false);
            } else {
                dispatch(setErrors(res.error));
                setShowProgress(false);
            }
        } catch (error) {
            dispatch(setErrors(error));
            setShowProgress(false);
        }
    };

export const onGetMonitoringValues = (taskId: any, panelId: any, setShowProgress, checked): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.get(`${baseURL}api/v1/inspection/inspectiontask/${taskId}/equipments/panels/${panelId}/inspectionquestions/monitorinfovalues`);
            if (res.status === "done") {
                res?.data?.values?.forEach(element => {
                    element.multipleFieldValue = checked ? element.multipleFieldValue : "";
                    element.fieldValue = checked ? element.fieldValue : "";
                });
                dispatch(setMonitoringValues(res?.data?.values));
                setShowProgress(false);
            } else {
                dispatch(setErrors(res.error));
                setShowProgress(false);
            }
        } catch (error) {
            dispatch(setErrors(error));
            setShowProgress(false);
        }
    };

export const {
    setErrors,
    setInspectionQuestion,
    setUpdatedQuestionDatafields,
    setInspectionContracts,
    setInspectionSystems,
    setUpdatedContractDatafields,
    setUpdatedSystemDatafields,
    setInspectionSystemFieldValues,
    setInspectionContractFieldValues,
    setInspectionQuestionFieldValues,
    setContractUpdated,
    setMonitoringValues,
    setTypeOfTriggerValue,
} = InspectionDetailsSlice.actions;
export default InspectionDetailsSlice.reducer;