import React, { useState } from 'react'


import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";
import { BiDotsVertical } from "react-icons/bi";
import { useAppSelector } from 'redux/hooks';

import InspectionDrawings from './InspectionDrawings'
import InspectionDrawingsList from './InspectionDrawingsList'


const InspectionDrawingsMain = () => {

  const selectedTask = useAppSelector((state) => state.inspectionDrawing.selectedTask);  

  const [leftToggle, setLeftToggle] = useState(false);  
  const [rightToggle, setRightToggle] = useState(false);
  const [drawingresize, setDrawingresize] = useState(false);
  const [showZoomData, setShowZoomData] = useState<any>();
  const [addEquipment, setAddEquipment] = useState<any>();
  const [showInDrawing, setShowInDrawing] = useState<any>();
  const [isShowDrawing, setIsShowDrawing] = useState(false);
  const [isShowZoomed, setIsShowZoomed] = useState(false);
  const [delinkData, setDelinkData] = useState<any>();
  const [deletedData, setDeletedData] = useState<any>();

  const onShowZoomClick = (rowData: any,showZoomed:boolean) => {
   setShowZoomData(rowData);
    setIsShowZoomed(showZoomed);
  }
  const onAddEquipment = (rowData: any) => {
    setAddEquipment(rowData);
  }
  const onShowInDrawing = (rowData: any,showDrawing:boolean) => {
   setShowInDrawing(rowData);
    setIsShowDrawing(showDrawing)
    

  }

  const onDelinkClick = (rowData: any) => {
    setDelinkData(rowData);
  }

  const onDeleteClick = (rowData: any) => {
    setDeletedData(rowData);
  }
  return (
    
    <>
    <div className="inspection-drawing-parent">
            <div className={`asBuild-wrapper ${leftToggle ? "drawing-f" : ""} ${rightToggle ? "table-f" : ""}`}>
                <div className="as-build-drawing__wrapper">
                    <div className="asBuild-drawing">
                        <div
                            className="drawing-expand-btn"
                            onClick={() => {
                                setLeftToggle(!leftToggle);
                                setRightToggle(false);
                                setDrawingresize(!leftToggle);
                            }}
                            title="Expand Drawing"
                        >
                            {rightToggle===false?(<AiOutlineDoubleRight className="icon right" />):<BiDotsVertical className="icon left" />}
                        </div>
                        
                            <InspectionDrawings bIsResizeDrawing={drawingresize} showZoomData={showZoomData} addEquipment={addEquipment} showInDrawing={showInDrawing} isShowDrawing={isShowDrawing} isShowZoomed={isShowZoomed} delinkData={delinkData} deletedData={deletedData} setDisableDrawingButtons={setIsShowDrawing}/>
                        
                    </div>
                </div>
                <div className="as-build-table__wrapper">
                    <div
                        className="table-expand-btn"
                        onClick={() => {
                            setRightToggle(!rightToggle);
                            setLeftToggle(false);
                        }}
                        title="Expand Table"
                    >
                        {leftToggle===false?(<AiOutlineDoubleLeft className="icon left" />):<BiDotsVertical className="icon left" />}
                    </div>
                    < InspectionDrawingsList selectedTask={selectedTask} onShowZoomClick={onShowZoomClick} onAddEquipment={onAddEquipment} onShowInDrawing={onShowInDrawing} onDelinkClick={onDelinkClick} onDeleteClick={onDeleteClick} disableDrawingButtons={isShowDrawing} />
                </div>
            </div>
        </div>
    </>
  )
}

export default InspectionDrawingsMain