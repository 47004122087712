import React from 'react'

const InspectionReportsGenerateReportsDocument = () => {
  return (
    <>
      <h4 className="doc-title mb-2">
        Generate Reports
      </h4>
      <p>
        This option is used to Generate Inspection Reports for the 
        selected Equipment Group/ Building/ System.
      </p>
      <p>
        The Reports mapped against the Inspection task will be listed. 
        Users can provide details and generate required reports and 
        upload the Final Reports.
      </p>
      <p>
        User can select the System from the corresponding drop down. 
        Based on the selection, the Equipment Group of the selected 
        System and the Reports associated to it (if any) will be 
        listed in Equipment Group and Report list boxes with checkbox 
        against it. User can select the required Reports and edit the 
        Start Date and End Date according to the Inspection Start date 
        and End date. By default the Start will be populated with a 
        date 2 weeks prior to the current date. After selecting all 
        required data, click <b>Generate</b> button. 
        If the user generate multiple reports with same date range, 
        all reports will be generated and saved in Drafted Reports.
      </p>
      <p>
        If user selects 'Send Log file to mail while generating 
        reports' checkbox while generating reports, Log file will be 
        send to logged in user's email. This option is available only 
        for Super Administrators. 
      </p>

      <p>
        <i>Note: </i> 
        Branch Details are mandatory for generating reports and Branch 
        details should be updated from 
        <span className="doc-subtitle"> Inspection: Inspection Tasks: Inspection Details: Contracts</span>.
      </p>

    </>
  );
}
export default InspectionReportsGenerateReportsDocument;
