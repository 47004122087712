import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "redux/hooks";
import { getLinkList, onDeleteLink } from "redux/common/linkSlice";
import { ListGroup } from "react-bootstrap";
//prime-react
import { ScrollPanel } from "primereact/scrollpanel";
import { Tooltip } from 'primereact/tooltip';
//icons
import { BsFillPencilFill, BsPlusLg, BsTrashFill } from "react-icons/bs";
//components
import AddNewLinks from "./ManageLinks/AddNewLinks";
import EditLinks from "./ManageLinks/EditLinks";
import InformationBox from "../Message/InformationBox/InformationBox";
import ConfirmationBox from "../Message/ConfirmationBox/ConfirmationBox";
//service
import { showUI } from "service/GeneralUtility";

interface ILink {
    showModal: boolean;
    setShowModal: (state: boolean) => void;
}

export const Links: React.FC<ILink> = ({ showModal = true, setShowModal = () => {} }) => {
    const linkList = useAppSelector((state) => state.link.linkList);
    const userPrivileges = useAppSelector((state) => state.user.userPrivileges);

    const [linkData, setLinkData] = useState([]);
    const dispatch = useDispatch<any>();
    const [showAddNewLinks, setShowAddNewLinks] = useState<boolean>(false);
    const [showEditLinks, setShowEditLinks] = useState<boolean>(false);
    const [selectedRows,setSelectedRows] = useState<any>([]);
    const [displayConfirmModal, setDisplayConfirmModal] = useState(false);
    const [displayInfoModal, setDisplayInfoModal] = useState<boolean>(false);
    const [message, setMessage] = useState("");
    const [activeLinkId, setActiveLinkId] = useState<any>();

    useEffect(() => {
        dispatch(getLinkList());
    }, []);

    useEffect(() => {
        if (linkList) {
            setLinkData(linkList);
        }
    }, [linkList]);

    const onCloseClick = () => {
        setShowModal(false);
    };

    const onEditLinkClick = () => {
        if (selectedRows.length < 1) {
            setMessage("Select a Link");
            setDisplayInfoModal(true);
        }
        else{
            setShowEditLinks(true);
        }
    };

    const onDeleteLinkClick = () => {
        if (selectedRows.length < 1) {
            setMessage("Select a Link");
            setDisplayInfoModal(true);
        }
        else {
            setMessage("Are you sure you want to delete the selected Link?");
            setDisplayConfirmModal(true);
        }
    }

    const onConfirmClick = () => {
        setDisplayConfirmModal(false);
        dispatch(onDeleteLink(selectedRows?.linkId,setDisplayInfoModal,setMessage));
        setSelectedRows([]);
        }
    

    return (
        <>
            <div className="release-note links" style={{ width: "70vw", height: "50vh" }}>
                <div className="d-flex pb-2 justify-content-between">
                <div className="title">Links</div>
                <div className="close-icon text-right" onClick={onCloseClick}>
                    <i className="pi pi-times"></i>
                </div>
                </div>
                <div className="link-table">
                    <div className="table-head">
                        <ListGroup horizontal>
                            <ListGroup.Item className="des th">Description</ListGroup.Item>
                            <ListGroup.Item className="add-on th">Added on</ListGroup.Item>
                            <ListGroup.Item className="add-by th">Added by</ListGroup.Item>
                        </ListGroup>
                    </div>
                    <div className="link-list">
                        <ScrollPanel style={{ width: "100%", height: "100%" }}>
                            {linkData && linkData.length
                                ? linkData.map((item: any, key) => (
                                      <div key={key} className="row-list">
                                        <ListGroup className={selectedRows?.linkId === item?.linkId ? "active" : ""} id={item.linkId} horizontal onClick={()=>{setSelectedRows(item);setActiveLinkId(null)}}>
                                              <ListGroup.Item action href={item.hyperLink} target="_blank" className="des" active={activeLinkId === item?.linkId ? true : false} onClick={()=>setActiveLinkId(item.linkId)}>
                                                  {item.description}
                                              </ListGroup.Item>
                                              <ListGroup.Item className="add-on">{item.addedOn}</ListGroup.Item>
                                              <ListGroup.Item className="add-by">{item.addedBy}</ListGroup.Item>
                                          </ListGroup>
                                      </div>
                                  ))
                                : "No Data Found"}
                        </ScrollPanel>
                    </div>
                    {showUI(userPrivileges,"SIETAP_LINKS_MANAGE") &&
                    <div className="icons-link-table">
                        <span className="mr-3">
                            {" "}
                            <Tooltip target="#addLink" content={"Add Link"}></Tooltip>
                            <BsPlusLg id="addLink" className="icon" onClick={() => setShowAddNewLinks(true)} />
                        </span>
                        <span className="mr-3">
                            {" "}
                            <Tooltip target="#editLink" content={"Edit Link"}></Tooltip>
                            <BsFillPencilFill id="editLink" className="icon" onClick={onEditLinkClick} />
                        </span>
                        <span className="mr-3">
                            {" "}
                            <Tooltip target="#removeLink" content={"Delete Link"}></Tooltip>
                            <BsTrashFill id="removeLink" className="icon" onClick={onDeleteLinkClick}/>
                        </span>
                    </div>
                    }
                </div>
            </div>
            <InformationBox
                showInfoModal={displayInfoModal}
                setShowInfoModal={setDisplayInfoModal}
                message={message}
            />
            <ConfirmationBox
                showConfirmModal={displayConfirmModal}
                setShowConfirmModal={setDisplayConfirmModal}
                confirmAction={onConfirmClick}
                message={message}
            />
            {showAddNewLinks ? <AddNewLinks showModal={showAddNewLinks} setShowModal={setShowAddNewLinks} selectedRows={selectedRows} /> : null}
            {showEditLinks ? <EditLinks showModal={showEditLinks} setShowModal={setShowEditLinks} selectedRows={selectedRows} /> : null}
        </>
    );
};

export default Links;
