import { Button } from 'primereact/button';
import React from 'react'
import { BsFillPencilFill, BsPlusLg, BsTrashFill } from 'react-icons/bs';
import { FaCog, FaDownload } from 'react-icons/fa';

const InspectionContractsDocument = () => {
  return (
    <>
        <h4 className='doc-title mb-2'>
          Contracts
        </h4>

        <p>
          Contracts are legal agreements that are executed between Siemens and its Customers to 
          carry out the Fire and Life Safety inspection process for the required equipment. 
          This option allows the user to manage all inspection contracts which comes under each 
          Sales Office. For creating a Contract, the user has Select the Customer, enter the 
          Contract Number, Contract Name, the Start and End period of the contract, 
          select the Sales Office associated with the Contract. A Contract can have multiple sites, 
          and a Site can have multiple contracts (for different periods). 
        </p>
        <p>
          The user can add/edit/delete Contracts and link Sites to the corresponding Contract 
          using this option. 
        </p>
        <p>
          <i>Note: </i> <span className="doc-subtitle">Add </span> 
            <Button className="doc-button-icon"> 
              <BsPlusLg className="icon" title='Add' /> 
            </Button> icon will not be displayed for Technicians as they donot have the privilege to 
            add Contracts. 
        </p>

        <p className="doc-subtitle">
          <b>Add: </b>
        </p>
        <p>
          A new Contract can be added by clicking <span className="doc-subtitle">Add </span> 
          <Button className="doc-button-icon"> 
              <BsPlusLg className="icon" title='Add' /> 
            </Button> icon. User can select the Customer, enter Contract Number, Contract Name, Note, Start Date, 
          End Date of the Contract and can select the Sales Office from the combo box, Start Date 
          will be displayed by default as the Current Date and End Date will be displayed by 
          default as the Current Date + 365 days. User can change the dates as required. 
          A <span className="doc-subtitle">View Pattern </span> link is provided next to the Contract 
          Number field to view the valid Number Patterns for Contract Number. If the Number Pattern 
          for United States is selected by the Customer, then Contract Number will also follow the 
          Number Pattern for United States. Similarly if the Number Pattern for Canada is selected 
          by the Customer, Number Pattern for Canada has to be followed in Contract Number also. 
          Enter the required details and click Save button.
        </p>

        <p className="doc-subtitle">
          <b>Edit: </b>
        </p>
        <p>
          Edit a Contract by selecting it and clicking the <span className="doc-subtitle"> Edit </span> 
          {" "}<Button className="doc-button-icon"> 
              <BsFillPencilFill className="icon" title='Edit'/> 
          </Button>{" "} icon. 
          Make required changes and click Save Changes button to save the changes.
        </p>

        <p>
          <b> <span className="doc-subtitle">Inline Edit:</span></b>
        </p>
        <p>
          Inline edit option enables user to edit a Contract by clicking the 
          <span className="doc-subtitle"> Edit </span> 
          <Button className="doc-button-icon">
            <i className="pi pi-pencil icon"  title='Inline Edit'/>
          </Button> icon in the left hand side of the required row. On clicking 
          <span className="doc-subtitle"> Edit </span> 
          <Button className="doc-button-icon">
            <i className="pi pi-pencil icon"  title='Inline Edit'/>
          </Button> icon, the selected row will be displayed as editable and Edit icon will be replaced with <span className="doc-subtitle"> Save </span> 
          <Button className="doc-button-icon"> <i className="pi pi-save icon" title='Save' /></Button> and 
          <span className="doc-subtitle"> Cancel </span>
          <Button className="doc-button-icon">
            <span className="pi pi-fw pi-times icon" title='Cancel'></span>
          </Button> icon.Do the necessary changes in the required fields and click on 
          <span className="doc-subtitle"> Save </span> 
          <Button className="doc-button-icon"> <i className="pi pi-save icon" title='Save' /></Button> icon. 
          To cancel the Inline edit operation click on <span className="doc-subtitle"> Cancel </span>
          <Button className="doc-button-icon">
            <span className="pi pi-fw pi-times icon" title='Cancel'></span>
          </Button> icon
        </p>


        <p className="doc-subtitle">
          <b>Delete: </b>
        </p>
        <p>
          Delete a Contract by selecting it and clicking the
          <span className="doc-subtitle"> Delete </span> 
          <Button className="doc-button-icon"> 
            <BsTrashFill className="icon" title='Delete' /> 
          </Button> icon. Contracts with Inspection Tasks assigned or in use cannot be deleted.
        </p>

        <p className="doc-subtitle">
          <b>Download: </b>
          <Button className="doc-button-icon"><FaDownload className="icon" title='Download' /></Button>
        </p>
        <p>
          This option lists Report All, Report Highlighted, Export All, Export Highlighted and 
          Group Highlighted. 
        </p>

        <p>
          <span className="doc-subtitle">Report All: </span>
        </p>

        <p>
          This option is for generating a report with all the records in the data grid. 
          The report can be saved to PDF, Text, Rich Text or Excel format by selecting the file 
          format from the list under Save option, and then clicking OK button after selecting the 
          required options from the Export Settings window.
        </p>

        <p>
          <span className="doc-subtitle">Report Highlighted: </span>
        </p>

        <p>
          This option is for generating a report with only selected records in the grid. 
          Select the desired rows by holding down the control key and clicking on each 
          of the records to be selected. Users can also select a set of rows using the shift key. 
          The selected records will be highlighted. Click on the
          <span className="doc-subtitle"> Report Highlighted </span> option in the
          <span className="doc-subtitle"> Download </span> 
          <Button className="doc-button-icon"><FaDownload className="icon" title='Download' /></Button> menu to generate the report. 
          The report will be created with the highlighted records only. The report can be saved to PDF,
           Text, Rich Text or Excel format by selecting the file format from the list under 
           Save option, and then clicking OK button after selecting the required options from the 
           Export Settings window.
        </p>


        <p className="doc-subtitle">
          <b>More: </b>
          <Button className="doc-button-icon">
            <FaCog className="icon" title='More' />
          </Button>
        </p>

        <p>
          This option lists Close, Activate, Sites and Display Settings option. 
        </p>

        <p>
          <span className="doc-subtitle">Close: </span>
        </p>

        <p>
          Close a Contract by selecting it and clicking the 
          <span className="doc-subtitle"> Close </span> option from
          <span className="doc-subtitle"> More </span>
          <Button className="doc-button-icon">
            <FaCog className="icon" title='More' />
          </Button> icon. On closing a Contract its corresponding status will be displayed as Closed in the data grid. 
          These closed Contracts cannot be accessed through Inspection Task option. 
        </p>

        <p>
          On closing the Contract all Systems related to the contract 
          (created under the customer who is linked to the selected contract) and Inspection Tasks 
          created under that contract will be closed automatically. If the Inspection Task of the 
          selected Contract is in progress, then while closing the contract it will show a message 
          'Inspection Task(s) of the selected Contract is in progress, are you sure you want to close 
          the Contract? and on clicking Yes to the message the selected contract will be closed. 
          Inspection Tasks under a closed Contract cannot be edited/activated again.
        </p>

        <p>
          <span className="doc-subtitle">Activate: </span>
        </p>

        <p>
          Activate a closed Contract by selecting it and clicking the 
          <span className="doc-subtitle"> Activate</span> option from 
          <span className="doc-subtitle"> More </span>
          <Button className="doc-button-icon">
            <FaCog className="icon" title='More' />
          </Button> icon. On activating a Contract its corresponding status will be changed from 
          'Closed' to 'Active' status, and displayed accordingly in the data grid. 
        </p>

        <p>
          <span className="doc-subtitle">Sites: </span>
        </p>

        <p>
          This option allows the users to link Sites associated to the selected Contract. 
          On selecting this option from <span className="doc-subtitle">More </span>
          <Button className="doc-button-icon">
              <FaCog className="icon" title='More' />
          </Button> icon, Update Sites page with Save Changes and Cancel buttons.  
          By default, all active Sites of the Customer will be listed with check boxes against each and the user can edit it if necessary.
        </p>

        <p>
          The user can select/remove necessary Sites and click Save Changes button to save the changes.
           Sites that are active for an inspection cannot be removed.
        </p>

        <p>
          <span className="doc-subtitle">Display Settings</span>
        </p>

        <p>
          This option allows the user to change the visibility of the data fields displayed in the data
          grid. On selecting this option, all the available data fields are displayed in a window. 
          Display settings can be modified by selecting the required fields from the list. 
          The grid settings modified through this option will not be saved and will be effective 
          only for the current instance of the data grid. 
        </p>

        <p>
          <i>Note:</i> Contracts will be listed for Admin, Admin-Tech, Tech and Branch Users based on their Sales Office access only.
          Also, while adding contract, Sales Office dropdown will list data only based on Sales Office 
          access. Contract Number starting with '51' is not allowed allowed to have 
          Notification Number in its Inspection Task.
        </p>
    </>
        
  );
}
export default InspectionContractsDocument;
