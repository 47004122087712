import React, { useState, useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";

import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";

import { classNames } from "primereact/utils";

import { BsExclamationCircleFill } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../redux/hooks";
import { getBranchOfficeDetails, getSalesOfficeLookup } from "redux/pages/Manage/OrganizationSettings/branchOfficeSlice";
import AddModal from "components/Table/AddModal/AddModal";

interface IBranchEdit {
  showModal: boolean;
  selectedRows: any;
  setShowModal: (state: boolean) => void;
  address: string;
  city: string;
  phone: string;
  email: string;
  Fax: string;
  salesOffice: string;
  onEditBranchOffice: (data: any) => void;
}

type FormData = {
  name: string;
  salesOffice: string;
  address: string;
  city: string;
  phone: string;
  fax: string;
  email: string;
  FALicenceNumber: string;
  SPKLicenceNumber: string;
  EXTLicenceNumber: string;
};

export const EditBranchOffice: React.FC<IBranchEdit> = ({
  showModal = false,
  selectedRows = [],
  address = "",
  city = "",
  phone = "",
  email = "",
  Fax = "",
  salesOffice = "",
  onEditBranchOffice = () => {},
  setShowModal = () => {},
}) => {
  const branchOfficeDetails = useAppSelector(
    (state) => state.branchOffice.branchOfficeDetails
  );
  const salesOfficeList = useAppSelector(
    (state) => state.branchOffice.salesOfficeLookup
  );

  const [salesOfficeLookup, setSalesOfficeLookup] = useState([
    { name: "", code: "" },
  ]);
  const EditBranchForm: any = useRef();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      name: "",
      salesOffice: "",
      address: "",
      city: "",
      phone: "",
      fax: "",
      email: "",
      FALicenceNumber: "",
      SPKLicenceNumber: "",
      EXTLicenceNumber: "",
    },
  });

  const formSubmitHandler = (data: any) => {
    EditBranchForm.current.requestSubmit();
  };
  const dispatch = useDispatch<any>();
  
  useEffect(() => {
    dispatch(getSalesOfficeLookup())
    dispatch(getBranchOfficeDetails(selectedRows[0]?.uniqueBranchOfficeId));
  }, [dispatch, selectedRows]);

  useEffect(() => {
    if (branchOfficeDetails) {
      setValue("address", address);
      setValue("city", city);
      setValue("phone", phone);
      setValue("email", email);
      setValue("FALicenceNumber", branchOfficeDetails?.faLicenceNumber);
      setValue("SPKLicenceNumber", branchOfficeDetails?.spkLicenceNumber);
      setValue("EXTLicenceNumber", branchOfficeDetails?.extLicenceNumber);
      setValue("fax", Fax);
      setValue("salesOffice", salesOffice);
      setValue("name", branchOfficeDetails?.name);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchOfficeDetails, setValue]);
  
  useEffect(() => {
    if (salesOfficeList && salesOfficeList?.length && branchOfficeDetails) {
      let salesOffice: { name: string; code: string }[] = [];
      salesOfficeList?.map((item: any) => {
        return salesOffice.push({ name: item.name, code: item.code });
      });
      setSalesOfficeLookup(salesOffice);
      const filterData: any = salesOffice.filter(
        (item: any) =>
          item.name === branchOfficeDetails.salesOffice.salesOfficeName
      );
      if (filterData.length > 0) {
        setValue("salesOffice", filterData[0].name);
      }
    }
  }, [salesOfficeList, branchOfficeDetails, setValue]);
  const getFormErrorMessage = (name) => {
    return (
      errors[name] && (
        <span className="tooltip-text">
          <BsExclamationCircleFill />
          {errors[name].message}
        </span>
      )
    );
  };

  const onEditFormSubmit = (submitData: any) => {
    let salesOfficeId = "";
    salesOfficeList.map((salesOfficeItem: any, key: any) => {
      if (submitData.salesOffice === salesOfficeItem.name) {
        salesOfficeId = salesOfficeItem.uniqueSalesOfficeId;
      }
      return null;
    });
    let ReviseData = 
      {
        name: submitData?.name.trim(),
        address: submitData?.address.trim(),
        cityStateZip: submitData?.city.trim(),
        phone: submitData?.phone.trim(),
        email: submitData?.email,
        fax: submitData?.fax.trim(),
        FALicenceNumber: submitData.FALicenceNumber !==null?submitData.FALicenceNumber.trim():submitData.FALicenceNumber,
        SPKLicenceNumber:submitData.SPKLicenceNumber!==null?submitData.SPKLicenceNumber.trim():submitData.SPKLicenceNumber,
        EXTLicenceNumber: submitData.EXTLicenceNumber!==null?submitData?.EXTLicenceNumber.trim():submitData?.EXTLicenceNumber,
        uniqueSalesOfficeId: salesOfficeId,
      }
    onEditBranchOffice && onEditBranchOffice(ReviseData);
  };

  const onError = (errors: any, e: any) => {};

  return (
    <>
      <AddModal
        header="Edit Branch Office"
        showModal={showModal}
        isAdd={false}
        setShowModal={setShowModal}
        style={{ width: "35vw", maxHeight: "100%" }}
        OnSaveClick={formSubmitHandler}
      >
        <form
          onSubmit={handleSubmit(onEditFormSubmit, onError)}
          ref={EditBranchForm}
        >
          <div className="row px-2 pt-2">
            <div className="field col-12 md:col-2 mb-0">
              <span className="p-float-label">
                <Controller
                  name="name"
                  control={control}
                  rules={{
                    required: "Branch Office Name is required.",
                    validate: (value) => {
                      return value.trim().length<=0
                        ?"Branch Office Name is required.":true
                    }
                  }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id="name"
                      className={classNames("w-100 error-tooltip", {
                        error: fieldState.invalid,
                      })}
                      {...field}
                    />
                  )}
                />
                <label className="mandatory" htmlFor="inputtext">
                  Name
                </label>
                {getFormErrorMessage("name")}
              </span>
            </div>
          </div>

          <div className="row px-2">
            <div className="field col-12 md:col-2 mb-0">
              <span className="p-float-label">
                <Controller
                  name="address"
                  control={control}
                  rules={{
                    required: "Address is required.",
                    validate: (value) => {
                      return value.trim().length<=0
                        ?"Address is required.":true
                    }
                  }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id="address"
                      className={classNames("w-100 error-tooltip", {
                        error: fieldState.invalid,
                      })}
                      {...field}
                    />
                  )}
                />
                <label className="mandatory" htmlFor="inputtext">
                  Address
                </label>
                {getFormErrorMessage("address")}
              </span>
            </div>
          </div>

          <div className="row px-2">
            <div className="field col-12 md:col-2 mb-0">
              <span className="p-float-label">
                <Controller
                  name="city"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputText
                      id="city"
                      className={classNames("w-100 error-tooltip", {
                        error: fieldState.invalid,
                      })}
                      {...field}
                    />
                  )}
                />
                <label htmlFor="inputtext">City, State, Zip</label>
                {getFormErrorMessage("city")}
              </span>
            </div>
          </div>

          <div className="row px-2">
            <div className="field col-12 md:col-2 mb-0">
              <span className="p-float-label">
                <Controller
                  name="phone"
                  control={control}
                  rules={{pattern:{value:/^[0-9!@#$ %^&*()_+\-=\[\]{};':"\\|,.<>\/?NA]*$/,message:"Enter a valid Phone Number"}}}
                  render={({ field, fieldState }) => (
                    <InputText
                      id="phone"
                      className={classNames("w-100 error-tooltip", {
                        error: fieldState.invalid,
                      })}
                      {...field}
                    />
                  )}
                />
                <label htmlFor="inputtext">Phone</label>
                {getFormErrorMessage("phone")}
              </span>
            </div>
          </div>

          <div className="row px-2">
            <div className="field col-12 md:col-2 mb-0">
              <span className="p-float-label">
                <Controller
                  name="fax"
                  control={control}
                  rules={{pattern:{value:/^[0-9!@#$ %^&*()_+\-=\[\]{};':"\\|,.<>\/?NA]*$/,message:"Enter a valid Fax"}}}
                  render={({ field, fieldState }) => (
                    <InputText
                      id="fax"
                      className={classNames("w-100 error-tooltip", {
                        error: fieldState.invalid,
                      })}
                      {...field}
                    />
                  )}
                />
                <label htmlFor="inputtext">Fax</label>
                {getFormErrorMessage("fax")}
              </span>
            </div>
          </div>

          <div className="row px-2">
            <div className="field col-12 md:col-2 mb-0">
              <span className="p-float-label">
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    pattern:  { value: /^NA$|\S+@\S+\.\S+$/, message: 'Enter a valid Email' },
                }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id="email"
                      className={classNames("w-100 error-tooltip", {
                        error: fieldState.invalid,
                      })}
                      {...field}
                    />
                  )}
                />
                <label htmlFor="inputtext">Email</label>
                {getFormErrorMessage("email")}
              </span>
            </div>
          </div>

          <div className="row px-2">
            <div className="field col-12 md:col-2 mb-0">
              <span className="p-float-label">
                <Controller
                  name="FALicenceNumber"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputText
                      id="FA License Number"
                      className={classNames("w-100 error-tooltip", {
                        error: fieldState.invalid,
                      })}
                      {...field}
                    />
                  )}
                />
                <label htmlFor="inputtext">FA License Number</label>
                {getFormErrorMessage("FA License Number")}
              </span>
            </div>
          </div>

          <div className="row px-2">
            <div className="field col-12 md:col-2 mb-0">
              <span className="p-float-label">
                <Controller
                  name="SPKLicenceNumber"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputText
                      id="SPK License Number"
                      className={classNames("w-100 error-tooltip", {
                        error: fieldState.invalid,
                      })}
                      {...field}
                    />
                  )}
                />
                <label htmlFor="inputtext">SPK License Number</label>
                {getFormErrorMessage("SPK License Number")}
              </span>
            </div>
          </div>

          <div className="row px-2">
            <div className="field col-12 md:col-2 mb-0">
              <span className="p-float-label">
                <Controller
                  name="EXTLicenceNumber"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputText
                      id="EXT License Number"
                      className={classNames("w-100 error-tooltip", {
                        error: fieldState.invalid,
                      })}
                      {...field}
                    />
                  )}
                />
                <label htmlFor="inputtext">EXT License Number</label>
                {getFormErrorMessage("EXT License Number")}
              </span>
            </div>
          </div>
          
          <div className="row px-2">
            <div className="field col-12 md:col-2">
              <span className="p-float-label">
                <Controller
                  name="salesOffice"
                  control={control}
                  rules={{
                    required: "Sales Office is required.",
                  }}
                  render={({ field, fieldState }) => (
                    <Dropdown
                      inputId="salesOffice"
                      className={classNames("w-100 error-tooltip", {
                        error: fieldState.invalid,
                      })}
                      {...field}
                      value={field.value}
                      onChange={(e) => field.onChange(e.value)}
                      options={salesOfficeLookup}
                      optionLabel="name"
                      optionValue="name"
                    />
                  )}
                />
                <label className="mandatory" htmlFor="Country">
                  Sales Office
                </label>
                {getFormErrorMessage("salesOffice")}
              </span>
            </div>
          </div>
        </form>
      </AddModal>
    </>
  );
};

export default EditBranchOffice;
