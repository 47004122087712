import { Button } from "primereact/button"

const AdministrationSearchInspectionTaskDocument = () => {
    return (
    <>
        <h4 className="doc-title mb-2">Search - Inspection Task</h4>
        <p>
            Inspection Tasks Search option allows the user to search for 
            Inspection Tasks. User can type minimum three characters of the 
            Inspection Task Name in the text box provided and select the required 
            Inspection Task from the list displayed. Click on 
            <span className="doc-subtitle"> Search </span> {" "}
            <Button className="doc-button-icon" >
            <i className="pi pi-search icon" title="Search"  />
            </Button>{" "}icon. The page will be redirected to 
            <span className="doc-subtitle"> Inspection: Inspection Tasks </span> 
            where the selected Inspection Task will be listed.
        </p>
    </>
    );
}

export default AdministrationSearchInspectionTaskDocument ;