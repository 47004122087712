
import React, { useCallback, useEffect, useState} from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { BsArrowLeft, BsFillEyeFill, BsFillPencilFill, BsTrashFill } from "react-icons/bs";
import { FaChevronDown, FaCog, FaDownload } from "react-icons/fa";
import { OverlayPanel } from 'primereact/overlaypanel';
//components
import PopOver from 'components/common/PopOver/PopOver';
import ConfirmationBox from "components/common/Message/ConfirmationBox/ConfirmationBox";
//
import ListMarkups from "../ListMarkups";
import ListRevisions from "../ListRevisions";
import EditListRevisions from "../EditListRevisions";
import EditListMarkups from "../EditListMarkups";
import InformationBox from "components/common/Message/InformationBox/InformationBox";
import { deleteMarkup, downloadDrawing, onUpdateMarkup, onUpdateRevision,getDrawingList } from "redux/pages/AsBuilts/AsBuiltsSlice";
import { useDispatch } from "react-redux";
import { useAppSelector } from "redux/hooks";
import PdfDrawingViewer from "pages/AsBuilts/ManageDrawings/PdfDrawingViewer";

interface Props {
    modelName:string;
    showModal?: boolean;
    setShowModal?: (state: boolean) => void;
    selectedRowData?: any;
    selectedBuilding?: any;
    showViewModel?:any;
}

export const CommonDialog: React.FC<Props> = ({ showModal = false, setShowModal = () => {}, selectedRowData = [],modelName="", selectedBuilding = []}) => {

    const [displayConfirmModal, setDisplayConfirmModal] = useState(false);
    const [message, setMessage] = useState("");
    const [showEditModel, setShowEditModel] = useState<boolean>(false);
    const [showMarkupViewModal, setShowMarkupViewModal] = useState<boolean>(false);
    const [showRevisionViewModal, setShowRevisionViewModal] = useState<boolean>(false);
    const [showListMarkups, setShowListMarkups] = useState<boolean>(false);
    const [dialogModelName, setDialogModelName] = useState<any>([]);
    const [selectedMarkupRow, setSelectedMarkupRow] = useState<any>([]);
    const [selectedRevisionRow, setSelectedRevisionRow] = useState<any>([]);
    const [displayInfoModal, setDisplayInfoModal] = useState(false);
    const [fromRevision, setFromRevision] = useState(false);
    const [showPdfViewModal, setShowPdfViewModal] = useState<any>(false);
	const [filePdfName, setPdfFileName] = useState("");

    const loggedInUserData = useAppSelector((state) => state.administration.loggedInUserDetails);

    const downloadRef = React.createRef<OverlayPanel>();
    const settingRef = React.createRef<OverlayPanel>();

    const dispatch = useDispatch<any>();

    useEffect(() => {
        setDialogModelName(modelName)
    }, [])
    

    const deleteIconClick = () => {
        if (selectedMarkupRow.length > 1) {
            setMessage("This operation can be performed only one row at a time");
            setDisplayInfoModal(true);
        } else if (selectedMarkupRow.length < 1) {
            setMessage("Select a Markup");
            setDisplayInfoModal(true);
        } else {
            setDisplayConfirmModal(true);
            setMessage("Are you sure you want to delete the selected Markup?")
        }
    };

    const handleMarkupRowSelect = useCallback((data: any) => {
        setSelectedMarkupRow([...data]);
    }, []);

    const handleRevisionRowSelect = useCallback((data: any) => {
        setSelectedRevisionRow([...data]);
    }, []);
    
    const onPreviousButtonClick =()=>{
        setShowListMarkups(false)
        setDialogModelName("ListRevisions")
        setFromRevision(false);
    }

    const markupListClick = () => {
        setShowListMarkups(true);
        setDialogModelName("ListMarkups"); 
        setFromRevision(true);
        settingRef.current?.hide()
    }

const onCloseClick = () => {
    if(dialogModelName === "ListMarkups"){
    dispatch(getDrawingList(loggedInUserData?.userDefaults?.uniqueSiteId,selectedBuilding?.id, 1, "", "", "", setMessage, setDisplayInfoModal));}
    setShowModal(false)
}

    const editIconClick = () => {
        if (dialogModelName === "ListRevisions"){
            if (selectedRevisionRow.length > 1) {
                setMessage("This operation can be performed only one row at a time");
                setDisplayInfoModal(true);
            } else if (selectedRevisionRow.length < 1) {
                setMessage("Select a Revision");
                setDisplayInfoModal(true);
            } else {
                setShowEditModel(true)
            }
        }else {
            if (selectedMarkupRow.length > 1) {
                setMessage("This operation can be performed only one row at a time");
                setDisplayInfoModal(true);
            } else if (selectedMarkupRow.length < 1) {
                setMessage("Select a Markup");
                setDisplayInfoModal(true);
            } else {
                setShowEditModel(true)
            }
        }
        
    }
    const viewIconClick = () => {
        if (dialogModelName === "ListRevisions"){
            if (selectedRevisionRow.length > 1) {
                setMessage("This operation can be performed only one row at a time");
                setDisplayInfoModal(true);
            } else if (selectedRevisionRow.length < 1) {
                setMessage("Select a Revision");
                setDisplayInfoModal(true);
            } else {
                setPdfFileName(selectedRevisionRow[0]?.dwgFileName);
                if(selectedRevisionRow[0]?.dwgFileName.includes(".dwg")||(selectedRevisionRow[0]?.dwgFileName.includes(".DWG")))
                {
                setShowRevisionViewModal(true)
            }
            else
                {
                    setShowPdfViewModal(true);

                }
            }
        }else {
            if (selectedMarkupRow.length > 1) {
                setMessage("This operation can be performed only one row at a time");
                setDisplayInfoModal(true);
            } else if (selectedMarkupRow.length < 1) {
                setMessage("Select a Markup");
                setDisplayInfoModal(true);
            } else {
                setShowMarkupViewModal(true);
            }
        }
        
    }

    const onMarkupEdit = (data) => {
        dispatch(onUpdateMarkup(loggedInUserData?.userDefaults?.uniqueSiteId, selectedBuilding?.id, selectedRowData[0]?.drawingId, selectedMarkupRow[0].markupId, data, setMessage, setDisplayInfoModal, setSelectedMarkupRow, setShowEditModel))
    }
    
    const onRevisionEdit = (data) => {
        dispatch(onUpdateRevision(loggedInUserData?.userDefaults?.uniqueSiteId, selectedBuilding?.id, selectedRowData[0]?.drawingId, data, setMessage, setDisplayInfoModal, setSelectedRevisionRow, setShowEditModel))
    }

    const onCancel=()=>{
        setDisplayConfirmModal(false)
    }

    const onConfirmAction = () => {
        setDisplayConfirmModal(false);
        dispatch(deleteMarkup(loggedInUserData?.userDefaults?.uniqueSiteId, selectedBuilding?.id, selectedRowData[0]?.drawingId, selectedMarkupRow[0].markupId, setSelectedMarkupRow, setMessage, setDisplayInfoModal))
    }

    const onDownload=()=>{
        dispatch(downloadDrawing(loggedInUserData?.userDefaults?.uniqueSiteId, selectedBuilding?.id,selectedRowData[0]?.drawingId, selectedRowData[0]?.dwgFileName, setMessage, setDisplayInfoModal))
    }

    return (
    
            <Dialog showHeader={false} visible={showModal} onHide={() => setShowModal(false)} className="list-markups">
            <div className="header">
                <div className="box-left">
                    <div className="title">{dialogModelName==="ListRevisions"?"List Revisions":"List Markups"}</div>
                </div>
                <div className="box-right">
                    <i className="pi pi-times" onClick={() => onCloseClick()}></i>
                </div>
            </div>

            <div className="container-fluid">
				<div className="second-row row">
                    <div className="task-name col-6">
                         {showListMarkups?(
                            <span
                            className="button-icon"
                            onClick={onPreviousButtonClick}
                            >
                            <BsArrowLeft className="icon"/>
                        </span>
                         ):null}
                        
                        <h6>{ showListMarkups===false?"":` ${selectedMarkupRow[0].inspectionTaskName}`}</h6>
                    </div>
					<div className="icon-wrapper col-6">
						<div className=" table-config">
							<div className="action-btns" hidden={showMarkupViewModal ||showRevisionViewModal}>
                                <Button
                                    className="button-icon"
                                    tooltip="Edit"
                                    tooltipOptions={{ position: "top" }}
                                    disabled={dialogModelName === "ListRevisions"?selectedRowData&& selectedRowData[0].revisions===0:selectedRowData[0].markups===""?true:false}
                                >
                                    <BsFillPencilFill className="icon" onClick={editIconClick}/>
                                </Button>
                                {dialogModelName === "ListMarkups" &&<Button
                                    className="button-icon"
                                    tooltip="Delete"
                                    onClick={deleteIconClick}
                                    tooltipOptions={{ position: "top" }}
                                    disabled={selectedRowData&&selectedRowData[0].markups===""?true:false}>
                                    <BsTrashFill className="icon" />
                                </Button>}
                                <Button 
                                    className="button-icon" 
                                    tooltip="View" 
                                    onClick={viewIconClick}
                                    tooltipOptions={{ position: "top" }}
                                    disabled={dialogModelName === "ListRevisions"?selectedRowData&& selectedRowData[0].revisions===0:selectedRowData[0].markups===""?true:false}>
                                    <BsFillEyeFill className="icon" />
                                </Button>
								<Button
									className="button-icon"
									tooltip="Download"
                                    onClick={(e) => downloadRef.current?.toggle(e)}
									tooltipOptions={{ position: "top" }}
                                    disabled={dialogModelName === "ListRevisions"?selectedRowData&&selectedRowData[0].revisions===0:selectedRowData[0].markups===""?true:false}
                                    hidden={true}>
									<FaDownload className="icon" />
									<FaChevronDown className="p-button-text caret-icon" />
								</Button>
								<Button
									className="button-icon"
									tooltip="More"
                                    onClick={(e)=>settingRef.current?.toggle(e)}
									tooltipOptions={{ position: "top" }}
                                    disabled={dialogModelName === "ListRevisions"?selectedRowData&&selectedRowData[0].revisions===0:selectedRowData[0].markups===""?true:false}
                                    hidden={true}>
									<FaCog className="icon" />
									<FaChevronDown className="p-button-text caret-icon" />
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>

            {dialogModelName==="ListRevisions"? (<ListRevisions selectedBuilding={selectedBuilding} selectedRowData={selectedRowData} settingsRef={settingRef} getSelectedData={handleRevisionRowSelect} markupListClick={markupListClick}showDrawing={showRevisionViewModal} setShowDrawingModal={setShowRevisionViewModal}/>) : null} 
            {dialogModelName==="ListMarkups"||showListMarkups? (<ListMarkups selectedBuilding={selectedBuilding} selectedRowData={selectedRowData} getSelectedData={handleMarkupRowSelect} settingsRef={settingRef} fromRevision={fromRevision} revisionRow={selectedRevisionRow}showDrawing={showMarkupViewModal} setShowDrawingModal={setShowMarkupViewModal}/>) : null} 
            <PopOver ref={downloadRef}>
                {dialogModelName === "ListRevisions" ? (
                    <ul>
                        <li onClick={onDownload}>DWG File</li>
                        <li>Export All</li>
                        <li>Export Highlighted</li>
                    </ul>
                ) : (
                    <ul>
                        <li>Export All</li>
                        <li>Export Highlighted</li>
                    </ul>
                )}
            </PopOver>
            <ConfirmationBox
                showConfirmModal={displayConfirmModal}
                setShowConfirmModal={setDisplayConfirmModal}
                confirmAction={onConfirmAction}
                cancelAction={onCancel}
                message={message}
            />
            <InformationBox
                showInfoModal={displayInfoModal}
                setShowInfoModal={setDisplayInfoModal}
                message={message}
            />
             {showPdfViewModal &&
				<PdfDrawingViewer
					showModal={showPdfViewModal}
					setShowModal={setShowPdfViewModal}
					fileName={filePdfName}
					setFileName={setPdfFileName}
					drawingId={selectedRevisionRow[0]?.drawingId}
                    revisionNumber={selectedRevisionRow[0]?.revisions}
				/>
			}
            {showEditModel && dialogModelName==="ListRevisions"? (  <EditListRevisions
				showModal={showEditModel}
				setShowModal={setShowEditModel}
				selectedRows={selectedRevisionRow}
                selectedBuilding={selectedBuilding}
                selectedDrawing={selectedRowData}
                onRevisionEdit={onRevisionEdit}
			/>) : null}
            {showEditModel && dialogModelName==="ListMarkups"? (  <EditListMarkups
		 		showModal={showEditModel}
	 		    setShowModal={setShowEditModel}
		 		selectedRows={selectedMarkupRow}
                selectedBuilding={selectedBuilding}
                selectedDrawing={selectedRowData}
                onMarkupEdit={onMarkupEdit}
		 	/>) : null}
            {/* {showMarkupViewModal && dialogModelName==="ListMarkups"? ( <Dialog        header={"Markup"}        visible={showMarkupViewModal}        style={{ width: "80vw" }}        onHide={() => setShowMarkupViewModal(false)}        className="display-settings common_display_settings"      > <AsbuiltsDrawing
		 		selectedMarkupRow={selectedMarkupRow}
                selectedBuilding={selectedBuilding}
                selectedDrawing={selectedRowData[0]}
                bIsResizeDrawing={false}
                selectedSite={[]}
                
                
           /> </Dialog>) : null}  */}
          
        </Dialog>
    );
};

export default CommonDialog;

