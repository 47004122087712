import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useAppSelector } from "redux/hooks";
import { BsPaperclip, BsSearch, BsSubtract, BsTrashFill } from "react-icons/bs";
import { FaChevronDown, FaCog, FaDownload, FaSave, FaBackward } from "react-icons/fa";
import { GiArrowCursor } from "react-icons/gi";
import { RiAlarmWarningLine } from "react-icons/ri";
import { BiUnlink } from "react-icons/bi";
import { OverlayPanel } from 'primereact/overlaypanel';
import { APP_ROUTES } from "constants/AppRoutes";
import { useNavigate } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { ProgressSpinner } from 'primereact/progressspinner';

import DrawingToolbar from 'components/common/Toolbar/DrawingToolbar';
import PopOver from 'components/common/PopOver/PopOver';
import Drawing from 'pages/Common/Drawing';
import Export from 'pages/Common/DrawingExport';
import SaveDrawing from 'pages/Common/SaveDrawing';
import InformationBox from 'components/common/Message/InformationBox/InformationBox';
import ConfirmationBox from "components/common/Message/ConfirmationBox/ConfirmationBox";
import { deassignEquipment, updateObjectBlockHandle, getEquipmentTooltip, getDrawingdisplaySettings,setEquipmentTooltip,setDrawingdisplaySettings } from "redux/pages/Inspection/InspectionTask/inspectionTaskSlice";
import { useDispatch } from "react-redux";
import TextSearch from './TextSearch';
import Totalize from './Totalize';
import EditEquipment from '../ManageInspectionTasks/InspectionDetails/Equipment/Manage/EditEquipment/EditEquipment';
import { downloadEquipmentAttachment, getEquipmentDatafields, onEquipmentDetailsUpdated, setEquipmentDatafields, setUpdatedEquipment } from 'redux/pages/Inspection/InspectionTask/InspectionDetails/Equipment/EquipmentSlice';
import AttachmentList from '../ManageInspectionTasks/InspectionDetails/Equipment/Manage/Attachments/AttachmentList';
import AssignBlock from './AssignBlock'; 
import { onEquipmentRemoved, onEquipmentUsageChecked } from "redux/pages/Inspection/InspectionTask/InspectionDetails/Equipment/EquipmentSlice";
import { getEquipmentTypeList, setCommonEquipmentList, setEquipmentSpecificList, setUnassignedEquipmentList } from 'redux/pages/Inspection/InspectionTask/inspectionDrawingSlice';
interface Props {
  bIsResizeDrawing: boolean;
  showZoomData?: any;
  addEquipment?: any;
  showInDrawing?: any;
  delinkData?: any;
  deletedData?: any;
  isShowDrawing:boolean;
  isShowZoomed:boolean;  
  setDisableDrawingButtons?: (data: any) => void;
}
let intLegendTextSize = 5;
let dblDwgRatio: any;
let strTextStyleName = "Standard";
let points = "";
let pointcount = 0;
let tempx: any;
let tempy: any;
let measureLayerName = "$Measure";
let dataInDrawingTextHandles: any = [];
let strBlockRefHandle="";
let moveLegendHandle= "";
let g_legendCount=0;


const InspectionDrawings: React.FC<Props> = (props) => {


  const { bIsResizeDrawing,setDisableDrawingButtons = () => { } } = props;

  const toolBarRef = useRef<any>(null);
  const equipmentRef = React.createRef<OverlayPanel>();
  const settingsRef = React.createRef<OverlayPanel>();
  const [showTextSearch, setShowTextSearch] = useState(false);
  const [showDrawingExport, setShowDrawingExport] = useState(false);
  const [showDrawingSave, setShowDrawingSave] = useState(false);
  const [showAssignBlock, setShowAssignBlock] = useState(false);
  const [showTotalize, setShowTotalize] = useState(false);

  const selectedTreeNode = useAppSelector((state) => state.inspectionDrawing.selectedTreeNode);
  const selectedTask = useAppSelector((state) => state.inspectionDrawing.selectedTask);


  const [inspectionDrgObj, setInspectionDrgObj] = useState<any>([])
  const [message, setMessage] = useState("");
  const [displayInfoModal, setDisplayInfoModal] = useState(false);
  const [displayConfirmModal, setDisplayConfirmModal] = useState(false);
  const [objectSelectedBlocks, setObjectSelectedBlocks] = useState<any>("");
  const [isClose, setIsClose] = useState(false)
  const [isActivate, setIsActivate] = useState(false)
  const [isShowLayer, setIsShowLayer] = useState(false)
  const [isHideLayer, setIsHideLayer] = useState(false)
  const [showLayerSelected, setShowLayerSelected] = useState("")
  const [hideLayerSelected, setHideLayerSelected] = useState("")
  const [isLegendExist, setIsLegendExist] = useState(false)
  const [selectEquipment, setSelectEquipment] = useState(false)
  const [showEditEquipment, setShowEditEquipment] = useState(false);
  const [datafields, setDatafields] = useState<any>([]);
  const [toolTipDatafields, setToolTipDatafields] = useState<any>("");
  const [delinkObjectSelected, setDelinkObjectSelected] = useState("");
  const [deleteObjectSelected, setDeleteObjectSelected] = useState("");
  const [showAttachmentListModal, setShowAttachmentListModal] = useState(false);
  const [selectedEquipment, setSelectedEquipment] = useState<any>([]);
  const [isDrawingOpen,setisDrawingOpen]=useState(false);
  const  [displaySettingDetails,setDisplaySettingDetails] = useState<any>("");
  const [blockRefHandle,setblockRefHandles] = useState<any>([]);
 const [toolData, setToolData] = useState<any>([]);
 const [showIcon, setShowIcon] = useState(true);
 const [showDeletePrompt, setShowDeletePrompt] = useState(false);
 const [removedEquipmentIds, setRemovedEquipmentIds] = useState<any>([]);
 const [showProgressBar, setShowProgressBar] = useState(false);
 const [isEquipmentUpdate, setIsEquipmentUpdate] = useState(false);
 const [equipmentData, setEquipmentData] = useState(false);
	const rowDelimiter = '§';
	const columnDelimeter='µ'; 
  const navigate = useNavigate();

  const dispatch = useDispatch<any>();
  const equipmentTypeList = useAppSelector((state) => state.inspectionDrawing.equipmentTypeList);
  const equipmentDatafields = useAppSelector((state) => state.equipment.equipmentDatafields);
  const drawingDisplaySettingsData = useAppSelector((state) => state.inspectionTask.drawingDisplaySettingsData);
  const equipmentTooltip = useAppSelector((state) => state.inspectionTask.equipmentTooltip);
  const updateEquipment = useAppSelector((state) => state.equipment.updatedEquipment);
  const unassignedEquipmentList = useAppSelector((state) => state.inspectionDrawing.unassignedEquipmentList);
  const commonEquipmentList = useAppSelector((state) => state.inspectionDrawing.commonEquipmentList);
  const equipmentList = useAppSelector((state) => state.inspectionDrawing.equipmentSpecificList);

  useEffect(() => {
    dataInDrawingTextHandles = [];
    dispatch(getEquipmentTooltip(selectedTask?.uniqueInspectionTaskId, selectedTreeNode[0]?.uniqueDrawingId))
    dispatch(getEquipmentTypeList(selectedTask?.uniqueInspectionTaskId, selectedTreeNode[0]?.uniqueDrawingId, 1))

    const body = {
      uniquePanelId: "",
      uniqueEquipmentClassId: null,
      uniqueSystemId: null,
      inspectionTaskStatus: "",
      search: "",
      searchItem: "",
      testDateFrom: "",
      testDateTo: "",
      showUntestedEquipments: false,
      showWithAttachmentsOnly: false,
      isFromXMLImported: false,
      isFromManuallyAdded: true,
      filterType: "",
      searchId: 0,
    };
    dispatch(getDrawingdisplaySettings("0", body))
    if (selectedTask?.status !== "Active") {
      setShowIcon(false)
      setToolData(inactiveTools)
    }
    else {
      setShowIcon(true)
      setToolData(tools)
    }

  }, [selectedTreeNode]);
  useEffect(() => {
    if (equipmentTooltip) {
      setToolTipDatafields(equipmentTooltip.data);
      var blockRefHandleArray: any = [];
      let equipmentData: any = [];
      for (var i = 0; i < equipmentTooltip?.data?.length; i++) {
        if (equipmentTooltip.data[i].BlockRefHandle != null) {
          if (!blockRefHandleArray.includes(equipmentTooltip.data[i].BlockRefHandle))
            blockRefHandleArray.push(equipmentTooltip.data[i].BlockRefHandle);
            strBlockRefHandle=strBlockRefHandle+equipmentTooltip.data[i].BlockRefHandle+rowDelimiter;
            equipmentData.push(equipmentTooltip.data[i]);
               
        }
      }
      setEquipmentData(equipmentData)
      setblockRefHandles(blockRefHandleArray)
    }
  }, [equipmentTooltip]);

 
  const setBlock = (blockHandle: any,arrayCount) => {
    if (blockHandle.length > arrayCount) {
      inspectionDrgObj.setBlockRefHandle(blockHandle[arrayCount], function (retCode, invalidHandles) {
       if(retCode!=0)
        console.log("setBlockrefHandle faild due to " + retCode);
        arrayCount++
        setBlock(blockRefHandle,arrayCount);
      });
    }
  }
  useEffect(() => {
    if (drawingDisplaySettingsData)
      setDisplaySettingDetails(drawingDisplaySettingsData.displaySettingDetails);

  }, [drawingDisplaySettingsData]);
  useEffect(() => {
    if (props.showZoomData) {
      var Handle = toolTipDatafields?.find((item) => item.EquipmentId == props.showZoomData[0].EquipmentId).BlockRefHandle
      inspectionDrgObj.deleteEntities("$LEGEND", function (retCode: any) {
        setIsLegendExist(false);
      });

      inspectionDrgObj.removeBlinkers();
      inspectionDrgObj.zoomEntity(Handle, function (rtCode: any) {

      });
    }

  }, [props.showZoomData,props.isShowZoomed]);

  useEffect(() => {
    if (props.showInDrawing) {
      var Handle = toolTipDatafields?.find((item) => item.EquipmentId == props.showInDrawing[0].EquipmentId).BlockRefHandle
      var blinkHandle: any = [];
    inspectionDrgObj.deleteEntities("$LEGEND", function (retCode: any) {
        setIsLegendExist(false);

      });
      inspectionDrgObj.removeBlinkers();
      inspectionDrgObj.zoomExtents(function (ret: any) {
        if (ret == 0) {
          blinkHandle[0] = Handle
          inspectionDrgObj.blinkEntitiesByHandles(blinkHandle, 255, 0, 0, 10, 1000, function (returnCode: any) {


          });
        }
      });
    }
  }, [props.showInDrawing,props.isShowDrawing]);

  useEffect(() => {
    if (props.addEquipment) {
      if(props.addEquipment[0]["Equipment Type"] !== "Modules"){
      var currentStatus = [0];
      inspectionDrgObj.isWaitMode(currentStatus);
      if (currentStatus[0]) {
        inspectionDrgObj.exitWaitMode();

      }

      inspectionDrgObj.deleteEntities("$LEGEND", function (retCode: any) {
        setIsLegendExist(false);
        setObjectSelectedBlocks([]);
      });
      setMessage("Select a Block to assign Equipment");
      setDisplayInfoModal(true);
      multipleassignment(props.addEquipment, (result: any) => {
        if (result == 0) {
          let unassignedData = JSON.parse(JSON.stringify(unassignedEquipmentList))
          let commonData = JSON.parse(JSON.stringify(commonEquipmentList))
          let assignedData = unassignedData?.data?.filter((item: any) => item.EquipmentId === props.addEquipment[0].EquipmentId);
          let data = unassignedData?.data.filter((item: any) => item.EquipmentId !== props.addEquipment[0].EquipmentId);
          unassignedData.data = data;
          commonData?.data?.push(assignedData[0]);
          dispatch(setUnassignedEquipmentList(unassignedData))
          dispatch(setCommonEquipmentList(commonData));
          setMessage("Block assigned to Equipment");
          setDisplayInfoModal(true);
          dispatch(getEquipmentTypeList(selectedTask?.uniqueInspectionTaskId, selectedTreeNode[0]?.uniqueDrawingId, 1))
        }        
      })
    }
    else{
        setMessage("Selected Equipment is Module, cannot be assigned");
        setDisplayInfoModal(true);

      }
    }
  }, [props.addEquipment]);

  const multipleassignment = (rawData: any, resCallback: any) => {
    if (rawData.length > 0) {
      setObjectToBlocks(2, rawData, (result: any) => {
        resCallback(result);
      });
    }
  }

  useEffect(() => {
    if (props.delinkData) {
      setDisplayConfirmModal(false);
      var Handle = toolTipDatafields?.find((item) => item.EquipmentId == props.delinkData[0].EquipmentId).BlockRefHandle
      const body = {
        uniquePanelId: "",
        uniqueEquipmentClassId: null,
        uniqueSystemId: null,
        inspectionTaskStatus: "",
        search: "",
        searchItem: "",
        testDateFrom: "",
        testDateTo: "",
        showUntestedEquipments: false,
        showWithAttachmentsOnly: false,
        isFromXMLImported: false,
        isFromManuallyAdded: true,
        filterType: "",
        searchId: 0
      };

      dispatch(deassignEquipment(selectedTask?.uniqueInspectionTaskId, selectedTreeNode[0]?.uniqueDrawingId, props.delinkData[0].EquipmentId, body))
      var layername = "";
      const Index = blockRefHandle.indexOf(Handle);
      if (Index >= 0) {

        blockRefHandle.splice(Index, 1);
      }
      const findex = dataInDrawingTextHandles?.findIndex((item: any) => item.blockRefHandle === Handle);
      if (findex >= 0) {
        layername = dataInDrawingTextHandles[findex].TextLayerName;
        dataInDrawingTextHandles.splice(findex, 1);
      

      }
     
      if (layername != "") {
        var isExist = [0];
        inspectionDrgObj.layerExists(layername, isExist);
        if (isExist[0])
          inspectionDrgObj.deleteLayer(layername, function (returncodeCode) { //delete layer
          });
      }
      inspectionDrgObj.releaseBlockRefHandle(Handle, function (retCode: any) { //release the blockrefhandle
        if (retCode == 0) {
          inspectionDrgObj.removeBlinkers();
          let commonData = JSON.parse(JSON.stringify(commonEquipmentList))
          let data = commonData?.data?.filter((item: any) => item.EquipmentId !== props.delinkData[0].EquipmentId);
          commonData.data = data;
          setDisplayConfirmModal(false)
          dispatch(setCommonEquipmentList(commonData))
          if (equipmentList) {
            let equipmentData = JSON.parse(JSON.stringify(equipmentList));
            let equipmentSpecific = equipmentData?.data?.filter((item: any) => item.EquipmentId !== props.delinkData[0].EquipmentId);
            equipmentData.data = equipmentSpecific;
            dispatch(setEquipmentSpecificList(equipmentData));
          }
          setDelinkObjectSelected("");
          setMessage("Selected Equipment has been de-linked");
          setDisplayInfoModal(true);
        }
        else {
          console.log("releaseBlockrefHandle faild due to ", retCode);
        }

      });
    }
  }, [props.delinkData]);

  useEffect(() => {
    if (props.deletedData) {
      var Handle = toolTipDatafields?.find((item) => item.EquipmentId == props.deletedData[0].EquipmentId).BlockRefHandle
      var layername = "";
      const Index = blockRefHandle.indexOf(Handle);
      if (Index >= 0) {

        blockRefHandle.splice(Index, 1);
      }
      const findex = dataInDrawingTextHandles?.findIndex((item: any) => item.blockRefHandle === Handle);
      if (findex >= 0) {
        layername = dataInDrawingTextHandles[findex].TextLayerName;
        dataInDrawingTextHandles.splice(findex, 1);
       

      }
      inspectionDrgObj.deleteLayer(layername, function (returncodeCode: any) { //delete layer
        if (returncodeCode == 0) {
          inspectionDrgObj.releaseBlockRefHandle(Handle, function (retCode: any) { //release the blockrefhandle
            if (retCode == 0) {
      
              inspectionDrgObj.removeBlinkers();
              let commonData = JSON.parse(JSON.stringify(commonEquipmentList))
              let data = commonData?.data?.filter((item: any) => item.EquipmentId !== props.deletedData[0].EquipmentId);
              commonData.data = data;
              dispatch(setCommonEquipmentList(commonData))
              if (equipmentList) {
                let equipmentSpecificData = JSON.parse(JSON.stringify(equipmentList));
                let equipmentSpecificList = equipmentSpecificData?.data?.filter((item: any) => item.EquipmentId !== props.deletedData[0].EquipmentId);
                equipmentSpecificData.data = equipmentSpecificList;
                dispatch(setEquipmentSpecificList(equipmentSpecificData));
              }
              setDeleteObjectSelected("");
              setDisplayConfirmModal(false);

            }
            else {
              console.log("releaseBlockrefHandle faild due to ", retCode);
            }

          });
        }
      });
    }
  }, [props.deletedData]);

  const TextSearchClick = () => {
    setShowTextSearch(true);
  };
  const DrawingExportClick=()=>{
    setShowDrawingExport(true);
  }
  const DrawingSaveClick=()=>{
    setShowDrawingSave(true);
  }
  const AssignBlockClick = () => {
    setShowAssignBlock(true);
  };
  const onMapEvent = (event: any, name: any) => {
    setShowTextSearch(false);
    setShowDrawingExport(false);
    setShowDrawingSave(false);
    switch (name) {
      case "Assign Block":
        setObjectToBlocks(1, [], (result: any) => { });
        break;
      case "Select Equipment":
        equipmentRef.current?.toggle(event)
        break;
      case "Delete":
        deleteEquipment();
        break;
      case "Search Text":
        TextSearchClick();
        break;
      case "Attachments":
        equipmentAattachment();
        break;
      case "Delink":
        delinkEquipment();
        break;
      case "Stop Blink":
        stopBlink();
        break;
      case "Download":
        DrawingExportClick();
        break;
      case "Save":
        DrawingSaveClick();
        break;
      case "More":
        settingsRef.current?.toggle(event)
        break;
      case "Back":
        CloseDrawing();
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    if (equipmentDatafields) {
      setDatafields(equipmentDatafields);
      dispatch(setEquipmentDatafields(""));
    }
  }, [equipmentDatafields])

  const openHandler = (objiWhizApi) => {
    setInspectionDrgObj(objiWhizApi);
    setisDrawingOpen(true);
    setDisableDrawingButtons(true);

  };
  
  useEffect(() => {
    if (isDrawingOpen && toolTipDatafields && displaySettingDetails) {
      if (
        dataInDrawingTextHandles.length == 0 &&
        objectSelectedBlocks.length == 0
      ) {
        initialCreateObjectText((resCallBack: any) => {
          if (blockRefHandle.length > 0) {
            setBlock(blockRefHandle, 0);
          }
        });
      } else {
        if (objectSelectedBlocks.length > 0) {
          const findIndex = toolTipDatafields?.findIndex(
            (item) => item.BlockRefHandle == objectSelectedBlocks
          );
          if (findIndex >= 0) {
            inspectionDrgObj.removeBlinkers();
            var blinkHandle: any = [];
            blinkHandle[0] = objectSelectedBlocks[0];
            CreateObjectText(objectSelectedBlocks[0], (result: any) => {
              if (result == 0) {
                inspectionDrgObj.setBlockRefHandle(
                  objectSelectedBlocks[0],
                  function (retCode, invalidHandles) {
                    inspectionDrgObj.blinkEntitiesByHandles(
                      blinkHandle,
                      255,
                      0,
                      0,
                      10,
                      1000,
                      function (returCode: any) {}
                    );
                  }
                );
              }
            });
          }
          setObjectSelectedBlocks([]);
        }
      }
    }
    }, [isDrawingOpen, toolTipDatafields, displaySettingDetails]);

  const stopBlink = () => {
    inspectionDrgObj.deleteEntities("$LEGEND", function (retCode) {
      setIsLegendExist(false);
      setObjectSelectedBlocks([]);
      inspectionDrgObj.removeBlinkers();
      setSelectedEquipment([]);
    });
  };
  const exportDrawing = (target:any) => {
   
  };

  const setObjectToBlocks = (target: any, rawData: any, resCallBack: any) => {
    var arrEntityHandles = [];
    var intTypeId = 1;
    var strLayerName = "";

    inspectionDrgObj.selectEntity(strLayerName, intTypeId, 12, function (retCode: any, selectedHandles: any) {
      arrEntityHandles = selectedHandles.split(rowDelimiter);
      arrEntityHandles.pop();
      if (retCode == 0) {
        if (target == 1) {
          getUnassignedBlocks(arrEntityHandles, rawData);
        }
        else {
          getUnassignedBlocksGrid(arrEntityHandles, rawData, (result: any) => {
            resCallBack(result);
          });
        }
      }
      else if (retCode == 8)//right click
      {
        resCallBack(retCode)
      }
      else if (retCode == 28) {
        if(target==1) {
          setMessage("Selected Entity is not a Block, select a Block to assign Equipment");
          setObjectToBlocks(1, rawData, (result: any) => { });
          setDisplayInfoModal(true);
          resCallBack(retCode);
         }
         else if(target==2){
          setMessage("Selected Entity is not a Block, select a Block to assign Equipment");
          setObjectToBlocks(2, rawData, (result: any) => { });
          setDisplayInfoModal(true);
          resCallBack(retCode);
         }
        }
        else {
          if(target==1){
          setMessage("Selected Entity is not a Block, select a Block to assign Equipment");
          setObjectToBlocks(1, rawData, (result: any) => { });
          setDisplayInfoModal(true);
          resCallBack(retCode);
          }
          else if(target==2){
            setMessage("Selected Entity is not a Block, select a Block to assign Equipment");
            setObjectToBlocks(2, rawData, (result: any) => { });
            setDisplayInfoModal(true);
            resCallBack(retCode);
          }
        }
    });
  }

  const getUnassignedBlocks = (arrayHandles: any, rawData: any) => {
    inspectionDrgObj.getEntityType(arrayHandles[0], function (res: any, getEntityTypeId: any) {
      if (getEntityTypeId == 1) {

        if (blockRefHandle.indexOf(arrayHandles[0]) == -1) {
          setObjectSelectedBlocks(arrayHandles)
          AssignBlockClick()

        }
        else {
          setMessage("Selected block is already assigned to an Equipment, select another Block");
          setDisplayInfoModal(true);
          setObjectToBlocks(1, rawData, (result: any) => { });
        }
      }
      if (getEntityTypeId == 9) {
        setMessage("Selected Entity is not a Block, select a Block to assign Equipment");
        setDisplayInfoModal(true);
        setObjectToBlocks(1, rawData, (result: any) => { });
      }
    });
  }
  const getUnassignedBlocksGrid = (arrayHandles: any, rawData: any, resCallBack: any) => {
    inspectionDrgObj.getEntityType(arrayHandles[0], function (res: any, getEntityTypeId: any) {
      if (getEntityTypeId == 1) {
        if (blockRefHandle.indexOf(arrayHandles[0]) == -1) {
          setObjectSelectedBlocks(arrayHandles);

          const body = {
            uniquePanelId: "",
            uniqueEquipmentClassId: null,
            uniqueSystemId: null,
            inspectionTaskStatus: "",
            search: "",
            searchItem: "",
            testDateFrom: "",
            testDateTo: "",
            showUntestedEquipments: false,
            showWithAttachmentsOnly: false,
            isFromXMLImported: false,
            isFromManuallyAdded: true,
            filterType: "",
            searchId: 0
          };
          dispatch(updateObjectBlockHandle(selectedTask?.uniqueInspectionTaskId, selectedTreeNode[0]?.uniqueDrawingId, rawData[0].EquipmentId, arrayHandles[0], body))
          resCallBack(0);
        }
        else {
          setMessage("Selected block is already assigned to an Equipment, select another Block");
          setDisplayInfoModal(true);
          setObjectToBlocks(2, rawData, (result: any) => { });
        }
      }
      else if (getEntityTypeId == 9) {
        setMessage("Selected Entity is not a Block, select a Block to assign Equipment");
        setDisplayInfoModal(true);
        setObjectToBlocks(2, rawData, (result: any) => { });

      }
    });

  }

  const equipmentAattachment = () => {
    inspectionDrgObj.deleteEntities("$LEGEND", function (retCode: any) {
      setIsLegendExist(false);
      setObjectSelectedBlocks([]);
    });
    setMessage("Select the Equipment to View/Add Attachments");
    setDisplayInfoModal(true);

    var intTypeId = 1;
    var strLayerName = "";
    inspectionDrgObj.selectEntity(strLayerName, intTypeId, 12, function (retCode, selectedHandles) {
      if (retCode == 0) {
        var arrEntityHandles = [];
        arrEntityHandles = selectedHandles.split(rowDelimiter);
        arrEntityHandles.pop();
        let equipment: any = [];
        const findIndex = toolTipDatafields?.findIndex((item) => item.BlockRefHandle ===arrEntityHandles[0]);
            if (findIndex >= 0) {
              equipment =toolTipDatafields[findIndex];
         setSelectedEquipment([equipment])
        setShowAttachmentListModal(true);
            }
            else
            {
             setMessage("No Equipment is assigned to the selected Entity");
             setDisplayInfoModal(true);
            }
      }
    });

  }
  const deleteEquipment = () => {
    var currentStatus = [0];
    inspectionDrgObj.isWaitMode(currentStatus);
    if (currentStatus[0]) {
      inspectionDrgObj.exitWaitMode();

    }
    inspectionDrgObj.deleteEntities("$LEGEND", function (retCode: any) {
      setIsLegendExist(false);
      setObjectSelectedBlocks([]);
    });
    setMessage("Select the Equipment to be deleted");
    setDisplayInfoModal(true);
    var  intTypeId = 1;
    var strLayerName = "" ;
    inspectionDrgObj.selectEntity(strLayerName,intTypeId,12, function (retCode:any, selectedHandles:any)
    {
      if(retCode==0)
      {
          var arrEntityHandles=selectedHandles.split(rowDelimiter);
          arrEntityHandles.pop();
          setDeleteObjectSelected(arrEntityHandles[0])
          inspectionDrgObj.getEntityType(arrEntityHandles[0], function (res:any, getEntityTypeId:any) {
          if(getEntityTypeId==1){
            const findIndex = toolTipDatafields?.findIndex((item) => item.BlockRefHandle ===arrEntityHandles[0]);
            if(findIndex>=0){  
              const body = {
                equipmentIds:[toolTipDatafields[findIndex].EquipmentId],
                UniqueInspectionTaskId: selectedTask?.uniqueInspectionTaskId,
              };
              dispatch(onEquipmentUsageChecked(body, setShowDeletePrompt, setDisplayInfoModal, setMessage, setRemovedEquipmentIds));
             }
            }
          });
        }
      });
  }
  const onDeleteEquipment = () => {
    const findIndex = toolTipDatafields?.findIndex((item) => item.BlockRefHandle === deleteObjectSelected);
      if(findIndex>=0){  
		const body = {
			equipmentIds: removedEquipmentIds,
			UniqueInspectionTaskId: selectedTask?.uniqueInspectionTaskId,
		};
		dispatch(onEquipmentRemoved(selectedTask?.uniqueInspectionTaskId, body));
		setShowDeletePrompt(false);
    const Index = blockRefHandle.indexOf(deleteObjectSelected);
        var layername = "";
        if (Index >= 0) {

          blockRefHandle.splice(Index, 1);
        }
        const findex = dataInDrawingTextHandles?.findIndex((item: any) => item.blockRefHandle === deleteObjectSelected);
        if (findex >= 0) {
          layername = dataInDrawingTextHandles[findex].TextLayerName;
          dataInDrawingTextHandles.splice(findex, 1);
        

        }
        inspectionDrgObj.deleteLayer(layername, function (returncodeCode: any) { //delete layer
          if (returncodeCode == 0) {
            inspectionDrgObj.releaseBlockRefHandle(deleteObjectSelected, function (retCode: any) { //release the blockrefhandle
              if (retCode == 0) {
                setDisplayConfirmModal(false);
                inspectionDrgObj.removeBlinkers();
                let commonData = JSON.parse(JSON.stringify(commonEquipmentList))
                let unAssignedData = JSON.parse(JSON.stringify(unassignedEquipmentList))
                let data = commonData?.data?.filter((item: any) => item.EquipmentId !== toolTipDatafields[findIndex].EquipmentId);
                commonData.data = data;
                if (unAssignedData)
                dispatch(setCommonEquipmentList(commonData))
                dispatch(setUnassignedEquipmentList(unAssignedData));
                setDeleteObjectSelected("");
                setMessage("Selected Equipment has been deleted");
                setDisplayInfoModal(true);

              }
              else {
                console.log("releaseBlockrefHandle faild due to ", retCode);
              }

            });
          }
        });
      }
	};

const delinkEquipment=()=>{
var currentStatus = [0];
inspectionDrgObj.isWaitMode(currentStatus);
    if(currentStatus[0])
    {
      inspectionDrgObj.exitWaitMode();

    }
    inspectionDrgObj.deleteEntities("$LEGEND", function (retCode: any) {
      setIsLegendExist(false);
      setObjectSelectedBlocks([]);
    });
    setMessage("Select the Equipment to de-link");
    setDisplayInfoModal(true);
    var intTypeId = 1;
    var strLayerName = "";
    inspectionDrgObj.selectEntity(strLayerName, intTypeId, 12, function (retCode: any, selectedHandles: any) {
      if (retCode == 0) {
        var arrEntityHandles = selectedHandles.split(rowDelimiter);
        arrEntityHandles.pop();
        inspectionDrgObj.getEntityType(arrEntityHandles[0], function (res: any, getEntityTypeId: any) {
          if (getEntityTypeId == 1) {
            setMessage("Are you sure you want to de-link the selected Equipment?");
            setDisplayConfirmModal(true);
            setDelinkObjectSelected(arrEntityHandles[0]);

          }
        });
      }
    });

  }
  const showLayer = () => {
    setMessage("Select a Layer");
    setDisplayInfoModal(true);
    var intTypeId = 0;
    var strLayerName = "";
    inspectionDrgObj.selectEntity(strLayerName, intTypeId, 12, function (retCode: any, selectedHandles: any) {
      if (retCode == 0) {
        var arrhandle = [];
        arrhandle = selectedHandles.split(rowDelimiter);
        var selectedHandle = arrhandle[0];
        var result = inspectionDrgObj.getEntityLayer(selectedHandle, function (result: any, layerName: any) {
          if (result != 0) {
            console.log("faild due to ", result);
          }
          else {
            setIsShowLayer(true);
            setMessage(layerName);
            setDisplayInfoModal(true);
            setShowLayerSelected(layerName);
          }
        });
      }
    });


  }

  const hideLayer = () => {
    setMessage("Select a Layer");
    setDisplayInfoModal(true);
    var intTypeId = 0;
    var strLayerName = "";
    inspectionDrgObj.selectEntity(strLayerName, intTypeId, 12, function (retCode: any, selectedHandles: any) {
      if (retCode == 0) {
        var arrhandle = [];
        arrhandle = selectedHandles.split(rowDelimiter);
        var selectedHandle = arrhandle[0];
        var result = inspectionDrgObj.getEntityLayer(selectedHandle, function (result: any, layerName: any) {
          if (result != 0) {
            console.log("faild due to ", result);
          }
          else {
            setIsHideLayer(true);
            setMessage(layerName);
            setDisplayInfoModal(true);
            setHideLayerSelected(layerName);

          }
        });
      }
    });
  }
  const totalize = () => {    
    if(selectedEquipment?.length > 0){
      setShowTotalize(true);
    }
    else{
      setMessage("No Equipment Selected");
      setDisplayInfoModal(true);
    }
  }

  const addLegend = () => {
    setShowTotalize(false);
    var ratio = [0];
    var itemsForCreateTextBlock: any = [];
    inspectionDrgObj.clientDWGAreaRatio(ratio);
    var currentStatus = [0];
    inspectionDrgObj.isWaitMode(currentStatus);
    if (currentStatus[0]) {
      inspectionDrgObj.exitWaitMode();
    }
    inspectionDrgObj.setCursor(2);
    let selectedEquipments: any = [];
    equipmentTypeList?.map((data: any, index: any) => {
      let selectedCount: any = 1;
      let totalCount: any = 0;
      let selected: any = "";
      commonEquipmentList.data?.map((datas: any) => {
        if (datas["Equipment Type"] == data.text) {
          totalCount++;
        }
      })
      selectedEquipment[0]?.map((equipment: any) => {
        if (data.text == equipment["Equipment Type"]) {
          selected = { equipmentType: data.text, selectedCount: selectedCount++, totalCount: totalCount };
        }
      });
      if (selected != "") {
        selectedEquipments.push(selected);
      }
    });

    var formatedText = "\n";
    selectedEquipments.map((item: any) => formatedText += "\\f|" + "b1" + "|" + "i1" + "|c0|p18;\\C" + "4" + ";\\H" + "15" + ";" + item.equipmentType + " : " + item.selectedCount + "(" + item.totalCount + ")" + "\\n");
    formatedText += "\\f|" + "b1" + "|" + "i1" + "|c0|p18;\\C" + "5" + ";\\H" + "15" + ";" + "Total No. of Equipment :" + selectedEquipment[0]?.length + "(" + commonEquipmentList.data?.length + ")" + "\\n";
    setMessage("Select an insertion point to add legend");
    setDisplayInfoModal(true);
    inspectionDrgObj.getDWGPoint((retCode: any, legendXPosition: any, legendYPosition: any) => {
      inspectionDrgObj.setCursor(1);
      if (retCode == 0) {
        createDrawingDetailsLegend(legendXPosition, legendYPosition, function (startY: any, titleEntityHandle: any) {
          inspectionDrgObj.createMultilineText("$LEGEND", 1, legendXPosition, startY, 0, intLegendTextSize,
            0, 1, formatedText, "Standard", 0, function (retCode: any, entityHandle: any) {
              if (retCode != 0)
                console.log("createMultilineText returned with error code : ", retCode);
              else {
                var legendHandles = titleEntityHandle + rowDelimiter + entityHandle + rowDelimiter;
                inspectionDrgObj.createBlock("LEGENDBLOCK_" + (g_legendCount++), legendHandles, function (retCode, blockHandle, blockRefHandle) {
                  moveLegendHandle = blockRefHandle;
                });
              }
            });
        });
      }
      else
        console.log("getDWGPoint failed due to ", retCode);
    });
  }
  const createDrawingDetailsLegend = (xPos, yPos,  resCallback) => {
   
    let   startX = xPos;
    let startY = yPos;
    var detailsText = "Site: " + selectedTreeNode[0]?.siteName + ", Building: " + selectedTreeNode[0]?.buildingName  + ", Floor: " + selectedTreeNode[0]?.floorName;
    calculateDwgRatio();
                   
    inspectionDrgObj.createText("$LEGEND", 7, startX, startY, 0, intLegendTextSize,
        1, detailsText, strTextStyleName, 1, function (retCode:any, entityHandle:any) {
            if (retCode != 0)
                console.log("createText returned with error code : ", retCode);
            else {
                console.log("Entity handle: ", entityHandle);
                startY = startY - (intLegendTextSize + (2 * dblDwgRatio));
            }
            resCallback(startY, entityHandle);
        });
}
  const DeHatch = () => {
    deleteLayerIfExists("$HatchBlockRef", function (retCode: any) {
      deleteLayerIfExists("$Measure", function (retCode: any) {
        deleteLayerIfExists("$LEGEND", function (retCode: any) {
        inspectionDrgObj.removeBlinkers();
        });
      });
    });
  };

  const CloseDrawing = () => {
    if(inspectionDrgObj.length>0 && isDrawingOpen){
      dispatch(setEquipmentTooltip(""));
      dispatch(setDrawingdisplaySettings(""));
    inspectionDrgObj.close(function () {
      setisDrawingOpen(false);
      navigate(`/${APP_ROUTES.INSPECTION_TASKS}`)
    });
  }
  else{
   dispatch(setEquipmentTooltip(""));
   dispatch(setDrawingdisplaySettings(""));
  navigate(`/${APP_ROUTES.INSPECTION_TASKS}`);
  }
  };

  const moveLegend = () => {
    setMessage("Select an insertion point to move legend");
    setDisplayInfoModal(true);
    if (moveLegendHandle != "") {
      inspectionDrgObj.setCursor(2);
      inspectionDrgObj.setEntityVisibility(moveLegendHandle, false, function (ret) {
        inspectionDrgObj.getDWGPoint(function (retCode, xPos, yPos) {
                if (retCode == 0) {
                  inspectionDrgObj.moveEntity(moveLegendHandle, xPos, yPos, function (retCode) {
                    inspectionDrgObj.setEntityVisibility(moveLegendHandle, true, function (ret) {
                        });
                    });
                }
            });
        });
    } 

  };
  const selectSingleEquipment = () => {
    inspectionDrgObj.deleteEntities("$LEGEND", function (retCode: any) {
      setIsLegendExist(false);
      setObjectSelectedBlocks([]);
    });
    setMessage("Select an Equipment");
    setDisplayInfoModal(true);
    var intTypeId = 1;
    var strLayerName = "";
    var currentStatus = [0];
    inspectionDrgObj.isWaitMode(currentStatus);
    if (currentStatus[0]) {
      inspectionDrgObj.exitWaitMode();
    }
    inspectionDrgObj.selectEntity(strLayerName, intTypeId, 12, function (retCode, selectedHandles) {
      if (retCode == 0) {
        var arrSelectedHandles = selectedHandles.split(rowDelimiter);
        arrSelectedHandles.pop();
        inspectionDrgObj.getEntityType(arrSelectedHandles[0], function (res, getEntityTypeId) {
          if (getEntityTypeId == 1) {
            let equipment: any = [];
            const findIndex = toolTipDatafields?.findIndex((item) => item.BlockRefHandle === arrSelectedHandles[0]);
            if (findIndex >= 0) {
              equipment =toolTipDatafields[findIndex];
           setSelectedEquipment([equipment])
            setMessage("Do you want to view/edit Equipment data?");
            setDisplayConfirmModal(true);
            setSelectEquipment(true);
            setObjectSelectedBlocks(arrSelectedHandles)
          }
          else
             {
              setMessage("No Equipment is assigned to the selected Entity");
              setDisplayInfoModal(true);
             }
        }
        });
      }
      else if (retCode == 28) {
        setMessage("No Equipment is assigned to the selected Entity");
        setDisplayInfoModal(true);
      }
    });


  };
  const selectMultipleEquipment = (target: any, resCallBack: any) => {
    inspectionDrgObj.deleteEntities("$LEGEND", function (retCode: any) {
      setIsLegendExist(false);
      setObjectSelectedBlocks([]);
    });
    if (target == 1) {
      setMessage("Click to select/deselect Equipment. Right click to exit.");
      setDisplayInfoModal(true);
    }
    var intTypeId = 1;
    var strLayerName = "";
    var currentStatus = [0];
    inspectionDrgObj.isWaitMode(currentStatus);
    if (currentStatus[0]) {
      inspectionDrgObj.exitWaitMode();
    }
    inspectionDrgObj.selectEntity(strLayerName, intTypeId, 12, function (retCode, selectedHandles) {
      if (retCode == 0) {
        var arrSelectedHandles = selectedHandles.split(rowDelimiter);
        arrSelectedHandles.pop();
        inspectionDrgObj.getEntityType(arrSelectedHandles[0], function (res, getEntityTypeId) {
          if (getEntityTypeId == 1) {
            let equipment: any = [];
            let equipmentList: any = [];
            equipmentList = selectedEquipment;
            const findIndex = toolTipDatafields?.findIndex((item) => item.BlockRefHandle === arrSelectedHandles[0]);
            if (findIndex >= 0) {
              equipment = toolTipDatafields[findIndex];
              let isequipentExist: Boolean = false;
              selectedEquipment?.map((data: any, index: any) => { if (data.EquipmentId == equipment.EquipmentId) { isequipentExist = true } });
              if (!(isequipentExist)) {
                equipmentList.push(equipment);
              }
              setSelectedEquipment([equipmentList])
              setObjectSelectedBlocks(arrSelectedHandles)
              inspectionDrgObj.blinkEntitiesByHandles(arrSelectedHandles, 255, 0, 0, 10, 1000, function (returCode: any) {

              });
              selectMultipleEquipment(2, (result: any) => { });
              resCallBack(retCode);

            }
            else {
              setMessage("No Equipment is assigned to the selected Entity");
              setDisplayInfoModal(true);
              selectMultipleEquipment(2, (result: any) => { });
              resCallBack(retCode);
            }
          }
        });
      }
      else if (retCode == 28) {
        setMessage("No Equipment is assigned to the selected Entity");
        setDisplayInfoModal(true);
        selectMultipleEquipment(2, (result: any) => { });
        resCallBack(retCode);
      }
    });
  };
  const SelectEqupements = (resCallback: any) => {
  
      var ObArray;
      inspectionDrgObj.setCursor(12); 
      inspectionDrgObj.getDWGPoint((retCode, dblNewXPosition, dblNewYPosition)=>
      {

          if (retCode == 0)
          {

            inspectionDrgObj.getBlockRefWithDWGPoint(dblNewXPosition,dblNewYPosition,false,function (retCode, selectedHandles) {
                  if(retCode==0)
                  {
                    var objectIdArray:any=[];
                      ObArray=selectedHandles;
                      const findex = dataInDrawingTextHandles?.findIndex((item: any) => item.blockRefHandle === ObArray);
                      if (findex >= 0) {

                     
                        const index1 = selectedEquipment?.findIndex((item: any) => item === dataInDrawingTextHandles[findex].ObjectId);
                     
                          if(index1==-1)
                          {

                              objectIdArray.push(dataInDrawingTextHandles[findex].ObjectId);
                          }
                          setSelectedEquipment(objectIdArray);
                          const index2 = objectSelectedBlocks?.findIndex((item: any) => item === dataInDrawingTextHandles[findex].blockRefHandle);
                     
                         
                          if(index2==-1)
                          {

                              objectSelectedBlocks.push(dataInDrawingTextHandles[findex].blockRefHandle);
                          }

                          inspectionDrgObj.blinkEntitiesByHandles(objectSelectedBlocks, 255,0,0,10,1000, function (returCode)
                          {
                              SelectEqupements(resCallback); //recursive call till right click


                          });

                      }
                  }
                  else
                      SelectEqupements(resCallback); 
                                  });

          }
          else if (retCode == 8)
          {
              //rightclick
              inspectionDrgObj.setCursor(1); 
              if(selectedEquipment.length>0)
              {
                //  SelectRow(selectedEquipment);
              }
              else
              {
                 inspectionDrgObj.removeBlinkers();
             }
             resCallback();
          }
          else //if not rightclick repeat the same function
          {
              SelectEqupements(resCallback);
          }


      });

  }
  const selectMultipleEquipmentbyWindow = () => {
  };


  const searchDataload = (filter: any) => {
    inspectionDrgObj.searchForText(filter, false, function (res: any, textHandles: any) {
      if (res == 0) {
        var textHandleArray = textHandles.split(rowDelimiter);
        textHandleArray.pop();
        inspectionDrgObj.blinkEntitiesByHandles(textHandleArray, 255, 0, 0, 10, 1000, function (returCode: any) {
          if (returCode == 0) {
            console.log(" Successful Search : returCode - " + returCode);
          }
          else {
            console.log(" Blinking failed due to returCode - " + returCode);
          }
        });
      } else if (res == 37) {
        setMessage("No Text(s) exists");
        setDisplayInfoModal(true);

      }
      else
        console.log("searchForText faild due to ", res);

    });
    setShowTextSearch(false);
  }


  const onOkClick = () => {
    setIsClose(false);
    setIsActivate(false);
    setDisplayInfoModal(false);
    if (isShowLayer) {
      if (showLayerSelected) {
        setMessage("Do you want to show selected layer(s) only?");
        setDisplayConfirmModal(true);
      }
      setIsShowLayer(false);
    }
    else if (isHideLayer) {
      if (hideLayerSelected) {
        setMessage("Do you want to hide selected layer(s) only?");
        setDisplayConfirmModal(true);
      }
      setIsHideLayer(false);
    }
  }
  const onCancelClick = () => {
    setIsActivate(false);
    setIsClose(false);
    setIsShowLayer(false);
    setIsHideLayer(false);
    setShowLayerSelected("");
    setHideLayerSelected("");
    setDisplayConfirmModal(false);
    setShowTextSearch(false);
    setShowDrawingExport(false);
    setShowDrawingSave(false);
    setShowTotalize(false);
  }
  const onConfirmClick = () => {
    if (showLayerSelected) {
      inspectionDrgObj.hideAllLayers(function (retCode: any) {
        inspectionDrgObj.setLayerVisibility(showLayerSelected, true, function (returnCode: any) {
          setShowLayerSelected("");
          setIsShowLayer(false);
        });
      });
    }
    else if (hideLayerSelected) {
      inspectionDrgObj.setLayerVisibility(hideLayerSelected, false, function (returnCode: any) {
        setHideLayerSelected("");
        setIsHideLayer(false);
      });
    }
   else if (delinkObjectSelected) {
      const findIndex = toolTipDatafields?.findIndex((item) => item.BlockRefHandle === delinkObjectSelected);
      if (findIndex >= 0) {
        const body = {
          uniquePanelId: "",
          uniqueEquipmentClassId: null,
          uniqueSystemId: null,
          inspectionTaskStatus: "",
          search: "",
          searchItem: "",
          testDateFrom: "",
          testDateTo: "",
          showUntestedEquipments: false,
          showWithAttachmentsOnly: false,
          isFromXMLImported: false,
          isFromManuallyAdded: true,
          filterType: "",
          searchId: 0
        };

        dispatch(deassignEquipment(selectedTask?.uniqueInspectionTaskId, selectedTreeNode[0]?.uniqueDrawingId, toolTipDatafields[findIndex].EquipmentId, body))

        var layername = "";
        const Index = blockRefHandle.indexOf(delinkObjectSelected);
        if (Index >= 0) {

          blockRefHandle.splice(Index, 1);
        }
        const findex = dataInDrawingTextHandles?.findIndex((item: any) => item.blockRefHandle === delinkObjectSelected);
        if (findex >= 0) {
          layername = dataInDrawingTextHandles[findex].TextLayerName;
          dataInDrawingTextHandles.splice(findex, 1);
        

        }
        if (layername != "") {
          var isExist = [0];
          inspectionDrgObj.layerExists(layername, isExist);
          if (isExist[0])
            inspectionDrgObj.deleteLayer(layername, function (returncodeCode) { //delete layer
            });
        }

        inspectionDrgObj.releaseBlockRefHandle(delinkObjectSelected, function (retCode: any) { //release the blockrefhandle
          if (retCode == 0) {
            inspectionDrgObj.removeBlinkers();
            setDisplayConfirmModal(false);
            let commonData = JSON.parse(JSON.stringify(commonEquipmentList))
            let unAssignedData = JSON.parse(JSON.stringify(unassignedEquipmentList))
            let delinkedData = commonData?.data?.filter((item: any) => item.EquipmentId === toolTipDatafields[findIndex].EquipmentId);
            let data = commonData?.data?.filter((item: any) => item.EquipmentId !== toolTipDatafields[findIndex].EquipmentId);
            commonData.data = data;
            unAssignedData?.data?.unshift(delinkedData[0])
            dispatch(setCommonEquipmentList(commonData))
            dispatch(setUnassignedEquipmentList(unAssignedData));
            setMessage("Selected Equipment has been de-linked");
            setDisplayInfoModal(true);
          }
          else {
            console.log("releaseBlockrefHandle faild due to ", retCode);
          }

        });
      }
      setDelinkObjectSelected("");
    }
    else if (selectEquipment) {
      let body;
      body = {
        UniqueInspectionTaskId: selectedTask?.uniqueInspectionTaskId,
        UniqueEquipmentClassId: selectedEquipment[0]?.EquipmentTypeId,
        UniquePanelId: selectedEquipment[0]?.EquipmentGroupId,
        uniqueSystemId: selectedTask?.system?.uniqueSystemId,
      };
      body.EquipmentId = selectedEquipment[0]?.EquipmentId;
      dispatch(getEquipmentDatafields(body));
      setShowEditEquipment(true);
    }

    setDisplayConfirmModal(false);

  }
  const onSearchClick = (data: any) => {
    var isExist = [0];

    inspectionDrgObj.isBlinkExists(isExist);
    inspectionDrgObj.isBlink = false;
    if (isExist[0])
      inspectionDrgObj.removeBlinkers();
    inspectionDrgObj.regenerate();

    searchDataload(data);
  }
  const deleteLayerIfExists = (layerName: any, callBack: any) => {
    var isExists = [0];
    inspectionDrgObj.layerExists(layerName, isExists);
    if (isExists[0]) {
      inspectionDrgObj.deleteLayer(layerName, function (retCode: any) {
        callBack();
      });
    } else {
      callBack();
    }
  };

  const measureDistance = () => {
    inspectionDrgObj.deleteEntities("$LEGEND", function (retCode: any) {
      setIsLegendExist(false);
      setMessage("Select points to Measure Distance")
      setDisplayInfoModal(true);
      var currentStatus = [0];
      inspectionDrgObj.isWaitMode(currentStatus);
      if (currentStatus[0]) {
        inspectionDrgObj.exitWaitMode();
      }
      inspectionDrgObj.setCursor(2);
      inspectionDrgObj.getDWGPoint(function (retCode: any, startX: any, startY: any) {
        if (retCode == 0) {
          var rubx: any = [], ruby: any = [], rubz: any = []

          rubx[0] = startX; ruby[0] = startY; rubz[0] = 0;
          inspectionDrgObj.dwgToClient(rubx, ruby, rubz);
          inspectionDrgObj.enableRubberband(rubx[0], ruby[0], 0, true);
          inspectionDrgObj.getDWGPoint(function (rtCode: any, endX: any, endY: any) {
            if (rtCode == 0) {
              inspectionDrgObj.enableRubberband(startX, startX, 0, false);
              var distance = [];
              inspectionDrgObj.measureDistance(0, startX, startY, endX, endY, distance);
              var midX = (startX + endX) / 2;
              var midY = (startY + endY) / 2;
              var distanceWithUnit = (distance[0] / 12).toFixed(2) + " " + "Ft.";
              calculateDwgRatio();
              var intSize = 15;
              var measureLineAutocadColor = 150;
              inspectionDrgObj.createLine("$Measure", measureLineAutocadColor, startX, startY, endX, endY, function (retCode, entityHandle) {
                if (retCode != 0)
                  console.log("createLine returned with error code : ", retCode);
                else {
                  inspectionDrgObj.createText("$Measure", 7, midX, midY, 0, intLegendTextSize,
                    1, distanceWithUnit, strTextStyleName, 8, function (retCode: any, entityHandle: any) {
                      if (retCode != 0)
                        console.log("createText returned with error code : ", retCode);
                      inspectionDrgObj.setCursor(1);
                    });
                }

              });
            }
          });
        }
      });
    });
  };
  const calculateDwgRatio = () => {
    var ratio = [0];
    inspectionDrgObj.clientDWGAreaRatio(ratio);
    dblDwgRatio = ratio[0];
    intLegendTextSize = (3 * 1 * (ratio[0]));
  };
  const measureArea = (resCallback: any) => {
    inspectionDrgObj.deleteEntities("$LEGEND", function (retCode: any) {
      setIsLegendExist(false);
      setMessage("Click and draw to Measure Area. Right click or 'Esc' to exit")
      setDisplayInfoModal(true);
      var currentStatus = [0];
      inspectionDrgObj.isWaitMode(currentStatus);
      if (currentStatus[0]) {
        inspectionDrgObj.exitWaitMode();
      }
      inspectionDrgObj.setCursor(2);

      drawPloyline(function (returnCode, polylineHandle) {
        if (returnCode == 0) {
          inspectionDrgObj.getEntityArea(polylineHandle, function (returnCode: any, resultarea: any) {
            if (returnCode == 0) {
              var area = (resultarea / 144).toFixed(2) + " " + "Sq.Ft.";
              inspectionDrgObj.getEntityMidpoint(polylineHandle, function (returnCode: any, midX: any, midY: any) {
                calculateDwgRatio();
                var intSize = 15;
                inspectionDrgObj.createText("$Measure", 7, midX, midY, 0, intLegendTextSize,
                  1, area, strTextStyleName, 8, function (retCode: any, entityHandle: any) {
                    if (retCode != 0)
                      console.log("createText returned with error code : ", retCode);
                    inspectionDrgObj.setCursor(1);
                  });
              });
            }
          });
        }
        else
          resCallback(1);
      });
    });
  }
  const drawPloyline = (resCallback: any) => {
    var measureLineAutocadColor = 150;
    var measureLineThickness = 1;
    inspectionDrgObj.getDWGPoint(function (retCode: any, x: any, y: any) {
      if (retCode == 0) {
        points = points + x + columnDelimeter + y + rowDelimiter;
        pointcount++;
        var rubx: any = [], ruby: any = [], rubz: any = []
        rubx[0] = x; ruby[0] = y; rubz[0] = 0;
        inspectionDrgObj.dwgToClient(rubx, ruby, rubz);
        inspectionDrgObj.enableRubberband(rubx[0], ruby[0], 0, true);

        if (pointcount > 1) {
          inspectionDrgObj.createLine("temp", measureLineAutocadColor, tempx, tempy, x, y, function (retCode: any, entityHandle: any) {
            if (retCode != 0) {
              console.log("createLine returned with error code : ", retCode);
              inspectionDrgObj.enableRubberband(x, y, 0, false);
              return;
            }
            else {
              tempx = x;
              tempy = y;
              drawPloyline(resCallback);
            }
          });
        }
        else {
          tempx = x;
          tempy = y;
          drawPloyline(resCallback);
        }
      }
      else if (retCode == 8) {
        var ratio = [0];
        inspectionDrgObj.getCurrentClientDWGRatio(ratio);
        inspectionDrgObj.createPolyline(measureLayerName, measureLineAutocadColor, points, measureLineThickness * ratio[0],
          "", 1, true,
          function (retCode: any, entityHandle: any) {
            points = "";
            pointcount = 0;
            inspectionDrgObj.enableRubberband(x, y, 0, false);
            if (retCode != 0)
              console.log("createPolyline returned with error code : ", retCode);
            else {
              console.log("Entity handle: ", entityHandle);
            }
            inspectionDrgObj.deleteLayer("temp", function (retCode: any) {
              inspectionDrgObj.regenerate();
              resCallback(0, entityHandle);

            });

          });
      }
      else {
        console.log("getDWGPoint faild due to ", retCode);
        inspectionDrgObj.enableRubberband(0, 0, 0, false);
        inspectionDrgObj.regenerate();
        resCallback(1);
      }
    });
  };

  const onEquipmentUpdate = useCallback((fields) => {
    const body = {
      ClassId: selectedEquipment[0]?.EquipmentTypeId,
      SystemId: selectedTask?.system?.uniqueSystemId,
      PanelId: selectedEquipment[0]?.EquipmentGroupId,
      NfpaClassificationId: 10,
      InspectionTaskstatus: 1,
      UniqueInspectionTaskId: selectedTask?.uniqueInspectionTaskId,
      FieldList: fields,
    };
    setIsEquipmentUpdate(true)
    dispatch(onEquipmentDetailsUpdated(selectedTask?.uniqueInspectionTaskId, selectedEquipment[0]?.EquipmentId, body));
  }, [selectedTask, selectedEquipment]);

  useEffect(() => {
    if (updateEquipment) {
      var handleArray:any=[];
      dispatch(getEquipmentTooltip(selectedTask?.uniqueInspectionTaskId, selectedTreeNode[0]?.uniqueDrawingId))
      if (updateEquipment.isSuccess === true) {
        var Handle = toolTipDatafields?.find((item) => item.EquipmentId == updateEquipment.data.EquipmentId)?.BlockRefHandle
        handleArray[0]=Handle;
        if (Handle) {
          if (objectSelectedBlocks.length==0)
            setObjectSelectedBlocks(handleArray);
          DelExistingLayerCreateNew(Handle);
        }
        
      } else {
        setMessage(updateEquipment?.message);
        setDisplayInfoModal(true);
      }
      dispatch(setUpdatedEquipment(""));
    }
  }, [updateEquipment]);

  const DelExistingLayerCreateNew=(selectedBlocks: any) => {

    
          var layername="";
          const findIndex = dataInDrawingTextHandles?.findIndex((item) => item.blockRefHandle == selectedBlocks);
           
        if(findIndex>=0){
                  layername=dataInDrawingTextHandles[findIndex].TextLayerName;
                  inspectionDrgObj.deleteLayer(layername, function (returncodeCode)
              { 
                  if(returncodeCode==0)
                  {
                      dataInDrawingTextHandles.splice(findIndex, 1);
                      const Index = blockRefHandle.indexOf(selectedBlocks);
                      if (Index >= 0) {
              
                        blockRefHandle.splice(Index, 1);
                      }
                          }
                      });
                    }
  }

  const assignBlockFromDrawing = (event: any) => {
    const { selectedEquipmentId, equipmentData } = event;
    const body = {
      uniquePanelId: "",
      uniqueEquipmentClassId: null,
      uniqueSystemId: null,
      inspectionTaskStatus: "",
      search: "",
      searchItem: "",
      testDateFrom: "",
      testDateTo: "",
      showUntestedEquipments: false,
      showWithAttachmentsOnly: false,
      isFromXMLImported: false,
      isFromManuallyAdded: true,
      filterType: "",
      searchId: 0
    };
    dispatch(updateObjectBlockHandle(selectedTask?.uniqueInspectionTaskId, selectedTreeNode[0]?.uniqueDrawingId, selectedEquipmentId, objectSelectedBlocks, body))
    setShowAssignBlock(false);
    setMessage("Block assigned to Equipment");
    setDisplayInfoModal(true);
    dispatch(getEquipmentTypeList(selectedTask?.uniqueInspectionTaskId, selectedTreeNode[0]?.uniqueDrawingId, 1))
    let commonData = JSON.parse(JSON.stringify(commonEquipmentList))
    let unAssignedData = JSON.parse(JSON.stringify(unassignedEquipmentList))
    let data = unAssignedData?.data?.filter((item: any) => item.EquipmentId !== selectedEquipmentId);
    if (data)
    unAssignedData.data = data;
    commonData?.data?.push(equipmentData[0])
    dispatch(setCommonEquipmentList(commonData))
    dispatch(setUnassignedEquipmentList(unAssignedData));
    let equipmentSpecificData = JSON.parse(JSON.stringify(equipmentList));
    equipmentSpecificData?.data?.push(equipmentData[0]);
    dispatch(setEquipmentSpecificList(equipmentSpecificData));  
  }

  const CreateObjectText = (selectedblockRefHandle, resCallback: any) => {
    var ratio = [0];
    var formattedTextArray: any = [], extentsArray:any = [];
    var itemsForCreateTextBlock: any = [];
    inspectionDrgObj.clientDWGAreaRatio(ratio);
    toolTipDatafields?.forEach(element => {
      if (element["BlockRefHandle"] == selectedblockRefHandle) {
        if (element["EquipmentId"] != null) {
          var intObjectId = element["EquipmentId"];
          var ClassId = element["EquipmentClassId"];
          var blockHandle = element["BlockRefHandle"];

          var formatedText = "";
          var textSize = "";
          displaySettingDetails?.forEach(item => {
            if (item["isShowInDrawing"] == true) {
              var boldText = item["isBold"] ? "b1" : "b0";
              var italicText = item["isItalics"] ? "i1" : "i0";
              var textContent = element[item["dataFieldName"]];
              if (textContent == "null" || textContent == null)
                textContent = "-";
                item["size"] == 0 ?textSize = "5":textSize =item["size"]
              if ((item["objectClassId"] != 0) && (item["objectClassId"] != 0)) {
                if (item["objectClassId"] != element["EquipmentClassId"]) {
                  textContent = "";
                }
              }
              if (textContent != "")
                formatedText += "\\f|" + boldText + "|" + italicText + "|c0|p18;\\C" + item["colorId"] + ";\\H" + textSize + ";" + textContent + "\\n";
            }
          });
          var formatedTextFinal = "{" + formatedText + "}";
          formattedTextArray.push(formatedText);
          extentsArray.push(intObjectId);
       
          itemsForCreateTextBlock.push([formatedTextFinal, blockHandle, "$TL_" + intObjectId]); //pushing details into an array
          inspectionDrgObj.setApplicationLayer("$TL_" + intObjectId, (rtcode) => { });
        }
      }
    });
    if (itemsForCreateTextBlock.length != 0) {
      inspectionDrgObj.createBlockDataText(itemsForCreateTextBlock, 1, 0, 3, 0, 1, "Standard", 0, function (retCode, entityHandles, layerDetails) {
        if (retCode == 0) {
          var textHandleArray = entityHandles.split('§');
          var i = 0;
          for (i = 0; i < itemsForCreateTextBlock.length; i++) {
            const findIndex = dataInDrawingTextHandles?.findIndex((item) => item.blockRefHandle == itemsForCreateTextBlock[i][1]);
            if (findIndex == -1) {
              dataInDrawingTextHandles.push
                ({
                  ObjectId: extentsArray[i],
                  blockRefHandle: itemsForCreateTextBlock[i][1],
                  TextHandle: textHandleArray[i],
                  TextLayerName: itemsForCreateTextBlock[i][2]
                });
              resCallback(0);
            }
          }

        }
        else
          resCallback(retCode);
      });
    }

  };
  const renderFooter = () => {
		return (
			<>
				<Button label="No" onClick={() => { setShowDeletePrompt(false); setRemovedEquipmentIds([]) }} className="cancel-btn" />
				<Button label="Yes" onClick={onDeleteEquipment} className="submit-btn" autoFocus />
			</>
		);
    }
  const initialCreateObjectText = (resCallback: any) => {
    var ratio = [0];
    var symbolHandleArray = [], formattedTextArray: any = [], extentsArray: any = [], itemsForCreateTextBlock: any = [];
    inspectionDrgObj.clientDWGAreaRatio(ratio);
    toolTipDatafields?.forEach(element => {

      if (element["EquipmentId"] != null) {
        var intObjectId = element["EquipmentId"];
        var ClassId = element["EquipmentClassId"];
        var blockrefHandle = element["BlockRefHandle"];
        var formatedText = "";
        var textSize = "";
        displaySettingDetails?.forEach(item => {
          if (item["isShowInDrawing"] == true) {

            var boldText = item["isBold"] ? "b1" : "b0";
            var italicText = item["isItalics"] ? "i1" : "i0";
            var textContent = element[item["dataFieldName"]];
            if (textContent == "null" || textContent == null)
              textContent = "-";
              item["size"] == 0 ?textSize = "5":textSize =item["size"]
            if ((item["objectClassId"] != 0) && (item["objectClassId"] != 0)) {
              if (item["objectClassId"] != element["EquipmentClassId"]) {
                textContent = "";
              }
            }
            if (textContent != "")
              formatedText += "\\f|" + boldText + "|" + italicText + "|c0|p18;\\C" + item["colorId"] + ";\\H" + textSize + ";" + textContent + "\\n";



          }

        });
        var formatedTextFinal = "{" + formatedText + "}";

        formattedTextArray.push(formatedText);
        extentsArray.push(intObjectId);
        itemsForCreateTextBlock.push([formatedTextFinal, blockrefHandle, "$TL_" + intObjectId]); //pushing details into an array
        inspectionDrgObj.setApplicationLayer("$TL_" + intObjectId, function (rtcode) {  //otherwise the layername will be displayed in the layer box                        
        });
      }
    });
    if (itemsForCreateTextBlock.length != 0) {
      inspectionDrgObj.createBlockDataText(itemsForCreateTextBlock, 1, 0, 3, 0, 1, "Standard", 0, function (retCode, entityHandles, layerDetails) {

        if (retCode == 0) {
          var textHandleArray = entityHandles.split('§');
          var i = 0;
          for (i = 0; i < itemsForCreateTextBlock.length; i++) {
            const findIndex = dataInDrawingTextHandles?.findIndex((item) => item.blockRefHandle == itemsForCreateTextBlock[i][1]);
            if (findIndex == -1) {
              dataInDrawingTextHandles.push
                ({
                  ObjectId: extentsArray[i],
                  blockRefHandle: itemsForCreateTextBlock[i][1],
                  TextHandle: textHandleArray[i],
                  TextLayerName: itemsForCreateTextBlock[i][2]
                });
            }
          }
          resCallback(0);

        }
        resCallback(retCode);
      });
    }
    else {
      resCallback();
    }
  
  };
 
  return (
    <>
      <InformationBox
        showInfoModal={displayInfoModal}
        setShowInfoModal={setDisplayInfoModal}
        message={message}
        okOnClick={onOkClick}
      />
      <ConfirmationBox
        showConfirmModal={displayConfirmModal}
        setShowConfirmModal={setDisplayConfirmModal}
        confirmAction={onConfirmClick}
        cancelAction={onCancelClick}
        message={message}
      />
      <div className="type-list">
        <div className="item">
          <h6>Site</h6> <span>{selectedTreeNode[0]?.siteName}</span>
        </div>
        <div className="item">
          <h6>Building</h6>
          <span>{selectedTreeNode[0]?.buildingName}</span>
        </div>
        <div className="item">
          <h6>Floor</h6>
          <span>{selectedTreeNode[0]?.floorName}</span>
        </div>
      </div>
      <div className="drawing-data">
        <div className="toolbar">
          <DrawingToolbar
            ref={toolBarRef}
            orientation="horizontal"
            tools={toolData}
            onClick={onMapEvent}
          />
        </div>
        <div className="drawing-wrapper">
          <Drawing
            key={""}
            cadPlanId={selectedTreeNode[0]?.uniqueDrawingId}
            openHandler={openHandler}
            bIsResizeDrawing={bIsResizeDrawing}
            revisionNo={selectedTreeNode[0]?.revisionNo}
            drawingId={selectedTreeNode[0]?.drawingId}
            equipmentData={equipmentData}
          />
        </div>
      </div>
      {showEditEquipment && datafields && (
        <EditEquipment
          showModal={showEditEquipment}
          setShowModal={setShowEditEquipment}
          datafields={datafields}
          onFormSubmit={onEquipmentUpdate}
          onHideModal={() => {
            setShowEditEquipment(false);
            setDatafields([])
          }}
          uniqueEquipmentClassId={selectedEquipment[0]?.EquipmentTypeId}
          isFromDrawing={true}
        />
      )}
      {showAttachmentListModal ? (
        <AttachmentList
          showModal={showAttachmentListModal}
          setShowModal={setShowAttachmentListModal}
          uniqueInspectionTaskId={selectedTask?.uniqueInspectionTaskId}
          selectedEquipments={selectedEquipment}
        />
      ) : null}
      <PopOver ref={equipmentRef}>
        <ul>
          <li onClick={() => selectSingleEquipment()}>Single Equipment</li>
          <li onClick={() => selectMultipleEquipment(1,(result: any) => { })}>Multiple Equipment</li>
          {/* <li onClick={() => selectMultipleEquipmentbyWindow()}>Multiple Equipment by Window</li> */}
       
       </ul>
      </PopOver>  
      <PopOver ref={settingsRef}>
        <ul>

          <li onClick={() => showLayer()}>Show Layer</li>
          {showIcon && <li onClick={() => hideLayer()} >Hide Layer</li>}
          <li onClick={() => totalize()} >Totalize</li>
          <li onClick={() => moveLegend()} >Move Legend</li>
          {showIcon && <li onClick={() => measureDistance()} >Measure Distance</li>}
          {showIcon && <li onClick={() => measureArea(function (resCallBack: any) { })} >Measure Area</li>}
          {showIcon && <li onClick={() => DeHatch()} >De-Hatch</li>}
        </ul>
      </PopOver>

      {showTextSearch? (  <TextSearch
      showModal={showTextSearch}
      setShowModal={setShowTextSearch}
      onSearch={onSearchClick}
      cancelAction={onCancelClick}
    />) : null}

    {showTotalize? (  <Totalize
      showModal={showTotalize}
      setShowModal={setShowTotalize}
      addLegend={addLegend}
      cancelAction={onCancelClick}
      selectedEquipment = {selectedEquipment}
      commonEquipmentList = {commonEquipmentList}
    />) : null}

     {showDrawingExport? (  <Export
      showModal={showDrawingExport}
      setShowModal={setShowDrawingExport}
      objOpenDrawing={inspectionDrgObj}
      cancelAction={onCancelClick}
    />) : null}
     {showDrawingSave? (  <SaveDrawing
      showModal={showDrawingSave}
      setShowModal={setShowDrawingSave}
      objOpenDrawing={inspectionDrgObj}
      cancelAction={onCancelClick}
    />) : null}
    {showAssignBlock? (  <AssignBlock
      showModal={showAssignBlock}
      setShowModal={setShowAssignBlock}
      assignBlock={assignBlockFromDrawing}
    />) : null}
    {showDeletePrompt && (
					<Dialog
						header="Tech Advance +"
						visible={showDeletePrompt}
						style={{ width: "35vw" }}
						footer={renderFooter()}
						onHide={() => setShowDeletePrompt(false)}
						className="delete-modal"
					>
						<p className="content">{message}</p>
					</Dialog>
				)}
    {showProgressBar &&
                <div className='pre-loader equipment-list-pre'>
                    <ProgressSpinner />
                </div>
            }
    </>

  )



}

export default InspectionDrawings

const tools = [
  {
    name: "Assign Block",
    icons: <BsSubtract className="icon" />,
  },
  {
    name: "Select Equipment",
    icons: <GiArrowCursor className="icon" />,
    subIcon: <FaChevronDown className="p-button-text caret-icon" />
  },
  {
    name: "Delete",
    icons: <BsTrashFill className="icon" />,
  },
  {
    name: "Search Text",
    icons: <BsSearch className="icon" />,
  },
  {
    name: "Attachments",
    icons: <BsPaperclip className="icon" />,
  },
  {
    name: "Delink",
    icons: <BiUnlink />,
  },
  {
    name: "Stop Blink",
    icons: <RiAlarmWarningLine className="icon" />,
  },
   {
    name: "Download",
    icons: <FaDownload className="icon" />,
  },
  {
    name: "Save",
    icons: <FaSave className="icon" />,
  },
  {
    name: "More",
    icons: <FaCog className="icon" />,
    subIcon: <FaChevronDown className="p-button-text caret-icon" />
  },
  {
    name: "Back",
    icons: <FaBackward className="icon" />,
  },
];

const inactiveTools = [
  {
    name: "Search Text",
    icons: <BsSearch className="icon" />,
  },
  {
    name: "Stop Blink",
    icons: <RiAlarmWarningLine className="icon" />,
  },
  {
    name: "More",
    icons: <FaCog className="icon" />,
    subIcon: <FaChevronDown className="p-button-text caret-icon" />
  },
  {
    name: "Back",
    icons: <FaBackward className="icon" />,
  },
];

