
export const ATTACHMENT_LIST_ROWS = [
    {
        id: 1,
        fileName: 'sample.pdf',
        fileSize: '100KB',
        description: 'demo',
        uploadedOn:'null',
        uploadedBy:'xyz'
    },
    {
        id: 2,
        fileName: 'sample.pdf',
        fileSize: '100KB',
        description: 'demo',
        uploadedOn:'null',
        uploadedBy:'xyz'
    }
];

export const ATTACHMENT_LIST_COLS = {
    LIST_COLS: [
        
        {
            field: 'fileName',
            header: 'File Name',
            style: { flex: '1 0 200px' },
            sortable: true,
        },
        {
            field: 'fileSize',
            header: 'File Size (KB)',
            style: { flex: '1 0 110px' },
            sortable: true,
            align:"right",
        },
        // {
        //     field: 'description',
        //     header: 'Description',
        //     style: { flex: '1 0 200px' },
        //     sortable: true,
        // },
        {
            field: 'uploadedOn',
            header: 'Uploaded on',
            style: { flex: '1 0 200px' },
            sortable: true,
        },
        {
            field: 'uploadedBy',
            header: 'Uploaded by',
            style: { flex: '1 0 150px' },
            sortable: true,
        }
       
    ]
};