import React, { forwardRef } from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';

interface Props {
    children: React.ReactNode
}

export const PopOver = forwardRef<OverlayPanel, Props>((props, ref) => {

    return (
        <OverlayPanel
            ref={ref}
            className="custom-overlay"
        >
            {props.children}
        </OverlayPanel>
    );
});

export default PopOver;