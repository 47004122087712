import React from 'react'
import { Row, Col, Modal } from 'react-bootstrap';

interface Props {
    title: string,
    classNames?: string,
    children: React.ReactNode
}

export const Card: React.FC<Props> = ({
    title = '',
    classNames = '',
    children = null
}) => {
    return (
        <div className={`${classNames} custom-card`}>
            <Row className='title-section'>
                <Col>
                    <span className='title'>{title}</span>
                </Col>
            </Row>
            <div className="body-section">
                <Modal.Dialog>
                    <Modal.Body>
                        {children}
                    </Modal.Body>
                </Modal.Dialog>
            </div>
        </div>
    );
}

export default Card;