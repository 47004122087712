import React from 'react'

const ReportsSitesDocument = () => {
  return (
    <>
      <h4 className="doc-title mb-2">Reports - Sites</h4>
      <p>
        This report shows the list of all Sites with the Site Name, Site Code, Building Count
        and Status by default and all other Site Fields in the Customize option.
        Fields to display in the report can be customized by selecting the Customize button.
      </p>
      <p>The reports can be filtered by the Status for a desired value.</p>
    </>
  );
}
export default ReportsSitesDocument;