import React from "react";
import { Button } from "primereact/button";
import { BsPlusLg } from "react-icons/bs";

const MailDomainsDocument = () => {
    return (
        <>
            <h4 className="doc-title mb-2">
                Mail Domains
            </h4>
            <p>
                This option is used to define email domains for the customer. 
                Emails created with the defined domains only will be accepted for Tech Advance<sup>+</sup> users. 
                This option allows the Administrators to identify the authenticated mail domains and helps to 
                eliminate mails from the system to unauthorized domains.  
            </p>

            <p>
                <b><span className="doc-subtitle">Add:</span></b>
            </p>
            <p>
                A new mail domain can be added by clicking the {' '}
                <span className="doc-subtitle"> Add </span>
                <Button className="doc-button-icon" title="Add"> <BsPlusLg className="icon" /> </Button> icon.
            </p>

            <p>
                <b><span className="doc-subtitle">Edit:</span></b>
            </p>
            <p>
                Super Administrators can edit a mail domain by clicking {' '} 
                <Button className="doc-button-icon"> 
                    <i className="pi pi-ellipsis-v icon" title="More"/> 
                </Button> {' '}
                icon on the right side of the mail domain and selecting  
                <span className="doc-subtitle"> Edit </span> option from it.
            </p>

            <p>
                <b><span className="doc-subtitle">Delete:</span></b>
            </p>
            <p>
                Super Administrators can delete a mail domain by clicking {' '}
                <Button className="doc-button-icon"> 
                    <i className="pi pi-ellipsis-v icon" title="More"/> 
                </Button> {' '}
                on the right side of the mail domain and  selecting the 
                <span className="doc-subtitle"> Delete </span> option from it. 
                Only mail domains which are not in use can be deleted.
            </p>
        </>
    );
}
export default MailDomainsDocument;