import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { zlink, baseURL } from "../../../../service/HttpService";
import { AppThunk } from "../../../store";

export interface InspectionTaskState {
  errors: any;
  inspectionSearchId: any;
  notificationSearchId: any;
  inspectionTaskList: any;
  inspectionPageDetails: any;
  reportUploadedStatus: any;
  systemsData: any;
  technicianData: any;
  newInspectionData: any;
  inspectionReportList: any;
  selectedReportList: any;
  inspectionTaskDetails: any;
  updatedInspectionData: any;
  updatedContractDates: any;
  checkedDataForComplete: any;
  checkedDataForClose: any;
  inspetctionTaskContracts: any;
  inspectionTaskTechnicians: any;
  inspectionSiteCustomers: any;
  taskCompletionRate: any;
  panelTypeKPIs: any;
  deviceKPIs: any;
  taskKPIs: any;
  activeInspectionTasks: any;
  closedInspectionTasks: any;
  completedInspectionTasks: any;
  expiredContractList: any;
  isReportUpdated: boolean;
  linkedReportsData:any;
  updateObjectBlockHandle:any;
  drawingDisplaySettingsData: any;
  equipmentTooltip: any;
  isInspectionTaskDeleted: string;
  inspectionTaskStatus:any;
  inspectionTaskProgressBar:boolean;
  activatedSystemDetails:any;
}

let initialState: InspectionTaskState = {
  errors: "",
  inspectionSearchId: "",
  notificationSearchId: "",
  inspectionTaskList: [],
  inspectionPageDetails: [],
  reportUploadedStatus: [],
  systemsData: [],
  technicianData: [],
  newInspectionData: "",
  inspectionReportList: "",
  selectedReportList: "",
  inspectionTaskDetails: "",
  updatedInspectionData: "",
  updatedContractDates: "",
  inspetctionTaskContracts: [],
  checkedDataForComplete: [],
  checkedDataForClose: [],
  inspectionSiteCustomers: [],
  inspectionTaskTechnicians: [],
  taskCompletionRate: "",
  panelTypeKPIs: [],
  deviceKPIs: "",
  taskKPIs: "",
  activeInspectionTasks: {},
  closedInspectionTasks: {},
  completedInspectionTasks: {},
  expiredContractList: [],
  isReportUpdated: false,
  updateObjectBlockHandle:"",
  drawingDisplaySettingsData:"",
  equipmentTooltip:"",
  linkedReportsData:"",
  isInspectionTaskDeleted: "",
  inspectionTaskStatus: {},
  inspectionTaskProgressBar: false,
  activatedSystemDetails : ""
};

const InspectionTaskSlice = createSlice({
  name: "inspectionTask",
  initialState,
  reducers: {
    setErrors: (state, { payload }: PayloadAction<any>) => {
      state.errors = payload;
    },
    setInspectionSearchId: (state, { payload }: PayloadAction<any>) => {
      state.inspectionSearchId = payload;
    },
    setNotificationSearchId: (state, { payload }: PayloadAction<any>) => {
      state.notificationSearchId = payload;
    },
    setInspectionTaskList: (state, { payload }: PayloadAction<any>) => {
      state.inspectionTaskList = payload;
    },
    setInspectionPageDetails: (state, { payload }: PayloadAction<any>) => {
      state.inspectionPageDetails = payload;
    },
    setReportUploadedStatus: (state, { payload }: PayloadAction<any>) => {
      state.reportUploadedStatus = payload;
    },
    setCheckedDataForClose: (state, { payload }: PayloadAction<any>) => {
      state.checkedDataForClose = payload;
    },
    setCheckedDataForComplete: (state, { payload }: PayloadAction<any>) => {
      state.checkedDataForComplete = payload;
    },
    setLinkedReportsData: (state, { payload }: PayloadAction<any>) => {
      state.linkedReportsData = payload;
    },
    setSystemData: (state, { payload }: PayloadAction<any>) => {
      state.systemsData = payload;
    },
    setTechnicianData: (state, { payload }: PayloadAction<any>) => {
      state.technicianData = payload;
    },
    setNewInspectionData: (state, { payload }: PayloadAction<any>) => {
      state.newInspectionData = payload;
    },
    setInspectionReportList: (state, { payload }: PayloadAction<any>) => {
      state.inspectionReportList = payload;
    },
    setSelectedReportList: (state, { payload }: PayloadAction<any>) => {
      state.selectedReportList = payload;
    },
    setInspectionTaskDetails: (state, { payload }: PayloadAction<any>) => {
      state.inspectionTaskDetails = payload;
    },
    setUpdatedInspectionData: (state, { payload }: PayloadAction<any>) => {
      state.updatedInspectionData = payload;
    },
    setUpdatedContractDates: (state, { payload }: PayloadAction<any>) => {
      state.updatedContractDates = payload;
    },
    setInspetctionTaskContracts: (state, { payload }: PayloadAction<any>) => {
      state.inspetctionTaskContracts = payload;
    },
    setInspectionTaskTechnicians: (state, { payload }: PayloadAction<any>) => {
      state.inspectionTaskTechnicians = payload;
    },
    setInspectionSiteCustomers: (state, { payload }: PayloadAction<any>) => {
      state.inspectionSiteCustomers = payload;
    },
    setTaskCompletionRate: (state, { payload }: PayloadAction<any>) => {
      state.taskCompletionRate = payload;
    },
    setPanelTypeKPIs: (state, { payload }: PayloadAction<any>) => {
      state.panelTypeKPIs = payload;
    },
    setDeviceKPIs: (state, { payload }: PayloadAction<any>) => {
      state.deviceKPIs = payload;
    },
    setTaskKPIs: (state, { payload }: PayloadAction<any>) => {
      state.taskKPIs = payload;
    },
    setActiveInspectionTasks: (state, { payload }: PayloadAction<any>) => {
      state.activeInspectionTasks = payload;
    },
    setClosedInspectionTasks: (state, { payload }: PayloadAction<any>) => {
      state.closedInspectionTasks = payload;
    },
    setCompletedInspectionTasks: (state, { payload }: PayloadAction<any>) => {
      state.completedInspectionTasks = payload;
    },
    setExpiredContractList: (state, { payload }: PayloadAction<any>) => {
      payload?.contractDetails?.map((element: any) => {
        element.endDate = moment(element.endDate).format("YYYY-MM-DD").toString()
        element.startDate = moment(element.startDate).format("YYYY-MM-DD").toString()
        return null; 
      });
      state.expiredContractList = payload;
    },
    setInspectionTaskStatus: (state, { payload }: PayloadAction<any>) => {
      state.inspectionTaskStatus = payload;
    },
    setIsReportUpdated: (state, { payload }: PayloadAction<any>) => {
      state.isReportUpdated = payload;
    },
    setDrawingdisplaySettings: (state, { payload }: PayloadAction<any>) => {
        state.drawingDisplaySettingsData = payload;
    },
    setEquipmentTooltip: (state, { payload }: PayloadAction<any>) => {
        state.equipmentTooltip = payload;
    },
    setInspectionTaskDeletedData: (state, { payload }: PayloadAction<any>) => {
      state.isInspectionTaskDeleted = payload;
    },
    setInspectionTaskProgressBar: (state, { payload }: PayloadAction<any>) => {
      state.inspectionTaskProgressBar = payload;
    },
    setActivatedSystemDetails: (state, { payload }: PayloadAction<any>) => {
      state.activatedSystemDetails = payload;
    }
  },
});

//<------------------------ Purposely Commented ---------------------------------------------->
// export const getInspectionTaskList = (body, currentStart, keyword: any, sortField: any, sortOrder: any): AppThunk => async (dispatch) => {
//   try {
//     let requestUrl = `${baseURL}api/v1/inspection/inspectiontask/inspectiontasklist`;
//     if (currentStart) {
//       requestUrl += `?currentstart=${currentStart}`;
//     }
//     if (keyword) {
//       requestUrl += `&keyword=${keyword}`;
//     }
//     if (sortField && sortOrder) {
//       requestUrl += `&sortcolumn=${sortField}&sortorder=${sortOrder}`;
//     }
//     const res = await zlink.post(`${requestUrl}`, body);
//     if (res.status === "done") {
//       dispatch(setInspectionTaskList(res?.data?.inspectionTaskDetails));
//       dispatch(setInspectionPageDetails(res?.data?.pageDetails));
//     } else {
//       dispatch(setErrors(res.error));
//     }
//   } catch (error) {
//     dispatch(setErrors(error));
//   }
// };

export const getInspectionTaskList = (body, currentStart, keyword: any, sortField: any, sortOrder: any, controller?: any): AppThunk => async (dispatch) => {
  try {
    let requestUrl = `${baseURL}api/v1/inspection/inspectiontask/inspectiontasklist`;
    if (currentStart) {
      requestUrl += `?currentstart=${currentStart}`;
    }
    if (keyword) {
      requestUrl += `&keyword=${keyword}`;
    }
    if (sortField && sortOrder) {
      requestUrl += `&sortcolumn=${sortField}&sortorder=${sortOrder}`;
    }
    const request: any = {
      method: "POST",
      cache: "no-store",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: sessionStorage.getItem("accesstoken"),
      },
      body: JSON.stringify(body),
      signal: controller.signal
    }
    dispatch(setInspectionTaskProgressBar(true));
    const data = await fetch(`${requestUrl}`, request)
      .then(response => response.json())
      .then(res => {
        if (res.status === "done") {
          dispatch(setInspectionTaskList(res?.data?.inspectionTaskDetails));
          dispatch(setInspectionPageDetails(res?.data?.pageDetails));
          dispatch(setInspectionTaskProgressBar(false));
        } else {
          dispatch(setErrors(res.error));
        }
      })
      .catch(error => {
        dispatch(setErrors(error));
      });
  }
  catch (error) {
    dispatch(setErrors(error));
  }
};

export const getInspectionSearchList = (uniqueTaskId:any,searchBy:any): AppThunk => async (dispatch) => {
  try {
    let requestUrl = `${baseURL}api/v1/inspection/inspectiontasks`;
    if (uniqueTaskId) {
      requestUrl += `?selectedTask=${uniqueTaskId}` + `&searchBy=${searchBy}`;
    }
    const res = await zlink.get(requestUrl);
    if (res.status === "done") {
      dispatch(setInspectionTaskList(res?.data?.inspectionTaskDetails));
      dispatch(setInspectionPageDetails(res?.data?.pageDetails));
      dispatch(setNotificationSearchId(""));
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const checkFinalReportUploadStatus = (uniqueInspectionTaskId: any): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.get(`${baseURL}api/v1/inspection/inspectiontask/${uniqueInspectionTaskId}/checkfinalreportuploaded`);
    if (res.status === "done") {
      dispatch(setReportUploadedStatus(res?.data));
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const checkInspectionTaskForClose = (uniqueInspectionTaskId: any,body): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.post(`${baseURL}api/v1/inspection/inspectiontask/${uniqueInspectionTaskId}/checkinspectiontaskforclose`, body);
    if (res.status === "done") {
      dispatch(setCheckedDataForClose(res?.data));
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const checkInspectionTaskForComplete = (body, uniqueInspectionTaskId: any): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.post(`${baseURL}api/v1/inspection/inspectiontask/${uniqueInspectionTaskId}/checkinspectiontaskforcomplete`, body);
    if (res.status === "done") {
      dispatch(setCheckedDataForComplete(res?.data));
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};
export const checkLinkedReports = (body): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.post(`${baseURL}api/v1/inspection/reports/checklinkedreports`, body);
    if (res.status === "done") {
      dispatch(setLinkedReportsData(res?.data));
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};
export const onCloseInspectionTask = (body, uniqueInspectionTaskId: any, setMessage, setDisplayInfoModal, setAdditionalMessage): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.post(`${baseURL}api/v1/inspection/inspectiontask/${uniqueInspectionTaskId}/close`, body);
    if (res.status === "done") {
      dispatch(setUpdatedInspectionData(res.data));
      setMessage("Inspection Task closed.")
      setAdditionalMessage("Reminder: All Final Reports should be signed and uploaded before completing the Task")
      setDisplayInfoModal(true);
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const onCompleteInspectionTask = (body, uniqueInspectionTaskId: any, setMessage, setDisplayInfoModal): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.post(`${baseURL}api/v1/inspection/inspectiontask/${uniqueInspectionTaskId}/complete`, body);
    if (res.status === "done") {
      dispatch(setUpdatedInspectionData(res.data));
      setMessage("Inspection Task Completed")
      setDisplayInfoModal(true);
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const deleteInspectionTask =
  ({
    uniqueInspectionTaskId,
    setSelectedRows,
    setMessage,
    setDisplayInfoModal,
  }): AppThunk =>
    async (dispatch) => {
      try {
        const res = await zlink.post(
          `${baseURL}api/v1/inspection/inspectiontask/${uniqueInspectionTaskId}/remove`,
          null
        );
        if (res.status === "done") {
          dispatch(setInspectionTaskDeletedData(uniqueInspectionTaskId));
          setSelectedRows([]);
        } else {
          setSelectedRows([]);
          setMessage(res.message);
          setDisplayInfoModal(true);
        }
      } catch (error) {
        dispatch(setErrors(error));
      }
    };

export const getSystems = (): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.get(`${baseURL}api/v1/inspection/systems?isALLDetails=true`);
    if (res.status === "done") {
      dispatch(setSystemData(res?.data?.systemDetails));
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const getTechnicians = (body): AppThunk =>async (dispatch) => {
  try {
    const res = await zlink.post(`${baseURL}api/v1/inspection/technicians`, body);
    if (res.status === "done") {
      dispatch(setTechnicianData(res?.data?.technicianDetails));
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const onAddInspectionTask = (body: any, setMessage, setDisplayInfoModal, setShowAddModal): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.post(`${baseURL}api/v1/inspection/inspectiontask/add`, body);
    if (res.status === "done") {
      if(res?.data && res?.data?.inspectionTaskDetail) {
        dispatch(setNewInspectionData(res?.data?.inspectionTaskDetail));
        setShowAddModal(false);
      }
      if(res?.data && res?.data?.pageDetails) {
        dispatch(setInspectionPageDetails(res?.data?.pageDetails));
      }
    } else {
      dispatch(setErrors(res.error));
      setMessage(res.message);
      setDisplayInfoModal(true);
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const getInspectionReportList = (): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.get(
      `${baseURL}api/v1/inspection/reports/groups?reportlist=yes`
    );
    if (res.status === "done") {
      dispatch(setInspectionReportList(res?.data));
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const getSelectedReportList = (inspectionTaskId: any): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.get(
      `${baseURL}api/v1/inspection/inspectiontask/${inspectionTaskId}/reports`
    );
    if (res.status === "done") {
      dispatch(setSelectedReportList(res?.data));
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const getInspectionTaskDetails = (inspectionTaskId: any): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.get(
      `${baseURL}api/v1/inspection/inspectiontask/${inspectionTaskId}`
    );
    if (res.status === "done") {
      dispatch(setInspectionTaskDetails(res?.data));
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const onEditInspectionTask = ( body: any, inspectionTaskId, setMessage, setDisplayInfoModal, setShowEditModal): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.post(
      `${baseURL}api/v1/inspection/inspectiontask/${inspectionTaskId}/update`,
      body
    );
    if (res.status === "done") {
      dispatch(setUpdatedInspectionData(res.data));
      setShowEditModal(false);
    } else {
      dispatch(setErrors(res.error));
      setMessage(res.message);
      setDisplayInfoModal(true);
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const onUpdateReportSelected = (body: any, inspectionTaskId,activeSiteId, setMessage, setDisplayInfoModal, setShowReportsModal,selections,setSelections ): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.post(`${baseURL}api/v1/inspection/inspectiontask/${inspectionTaskId}/reports/${activeSiteId}`, body);
    if (res.status === "done") {
      dispatch(setIsReportUpdated(true));
      setShowReportsModal(false);
      setMessage("Report Field(s) updated");
      setDisplayInfoModal(true);
      setSelections(selections);
      dispatch(setLinkedReportsData(""))
    } else {
      dispatch(setErrors(res.error));
      setMessage(res.message);
      setDisplayInfoModal(true);
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const onEditContractDates = (body: any, contractId): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.post(
      `${baseURL}api/v1/inspection/contracts/${contractId}/updatecontractdate`,
      body
    );
    if (res.status === "done") {
      dispatch(setUpdatedContractDates(res.data));
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const getInspectionContracts = (uniqueCustomerId: any): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.get(`${baseURL}api/v1/inspection/customers/${uniqueCustomerId}/contracts`);
    if (res.status === "done") {
      dispatch(setInspetctionTaskContracts(res.data?.contractDetails));
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const getInspectionTaskTechnicians = (inspectionTaskId: any, setMessage, setDisplayInfoModal, setShowQuitTaskModal): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.get(`${baseURL}api/v1/inspection/inspectiontask/${inspectionTaskId}/users`,);
    if (res.status === "done") {
      if (res.data) {
        if (res.data.technicians && res.data.technicians.length) {
          dispatch(setInspectionTaskTechnicians(res.data.technicians));
        } else {
          setMessage('No Technicians joined for Inspection');
          setDisplayInfoModal(true);
          setShowQuitTaskModal(false);
        }
      }
    } else {
      dispatch(setErrors(res.error));
      setMessage(res.message);
      setDisplayInfoModal(true);
      setShowQuitTaskModal(false);
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
}

export const quitTask = (formData: any, setMessage, setDisplayInfoModal, setShowQuitTaskModal): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.post(`${baseURL}api/v1/inspection/inspectiontask/${formData.inspectionTaskId}/quit`, formData.body);
    if (res.status === "done") {
      dispatch(setInspectionTaskTechnicians([]));
      setShowQuitTaskModal(false);
      setMessage("Selected Technician(s) quit from the Inspection Task");
      setDisplayInfoModal(true);
    } else {
      dispatch(setErrors(res.error));
      if (res.message) {
        dispatch(setInspectionTaskTechnicians([]));
        setShowQuitTaskModal(false);
        setMessage(res.message);
        setDisplayInfoModal(true);
      }
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const getInspectionSitesCustomers = (keyword?: any): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.get(`${baseURL}api/v1/inspection/sites/customers?keyword=${keyword ? keyword : ''}`);
    if (res.status === "done") {
      dispatch(setInspectionSiteCustomers(res.data?.customerDetails));
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const getImportPreviousTaskDetails = (uniqueSystemId: any, setMessage: any, setDisplayInfoModal: any, setShowImportPreviousTaskDetails: any, setPreviousTaskDetails: any, setDisplayPreviousTaskConfirmModal: any): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.get(
      `${baseURL}api/v1/inspection/inspectionTask/systems/${uniqueSystemId}/completedtasks`
    );
    if (res.status === "done") {
      if (res.data?.inspectionTaskDetails.length > 0) {
        setPreviousTaskDetails(res.data?.inspectionTaskDetails)
        setMessage("Warning: All previous test results will be imported over existing test results.");
        setDisplayPreviousTaskConfirmModal(true);
      } else {
        setDisplayPreviousTaskConfirmModal(false)
        setMessage("No Completed Task exists for the System");
        setDisplayInfoModal(true);
      }
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const onImportPreviousTaskDetails = (body: any, uniqueInspectionTaskId, setMessage, setInfoModal, setShowImportPreviousTaskDetails): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.post(`${baseURL}api/v1/inspection/inspectionTask/${uniqueInspectionTaskId}/import`, body );
    if (res.status === "done") {
      setShowImportPreviousTaskDetails(false)
      setMessage('Task data is imported');
      setInfoModal(true)
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

// Currently not using this api after dashboard is changed to new UI
export const getTaskCompletionRate = (currentstart: any, count: any): AppThunk => async (dispatch) => {
  try {
    let requestUrl = `${baseURL}api/v1/inspection/users/inspectioncompletionrate`;
    if (currentstart) {
      requestUrl += `?currentstart=${currentstart}`;
    }
    if (count) {
      requestUrl +=  `&count=${count}`;
    }
    const res = await zlink.get(`${requestUrl}`);
    if (res.status === "done") {
      dispatch(setTaskCompletionRate(res.data));
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

// Currently not using this api after dashboard is changed to new UI
export const getPanelTypeKPIs = (): AppThunk =>async (dispatch) => {
  try {
    const res = await zlink.get(`${baseURL}api/v1/inspection/inspectionkpis/paneltypekpis`);
    if (res.status === "done") {
      if (res.data) {
        dispatch(setPanelTypeKPIs(res.data));
      }
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

// Currently not using this api after dashboard is changed to new UI
export const getDeviceKPIs = (): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.get(`${baseURL}api/v1/inspection/inspectionkpis/devicekpis`);
    if (res.status === "done") {
      dispatch(setDeviceKPIs(res.data));
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

// Currently not using this api after dashboard is changed to new UI
export const getTaskKPIs = (): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.get(`${baseURL}api/v1/inspection/inspectionkpis/taskkpis`);
    if (res.status === "done") {
      if (res.data) {
        dispatch(setTaskKPIs(res.data));
      }
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const getActiveInspectionTasks = (currentstart: any, sortField: any, sortDirection: any, isAllTask: boolean, isRequiredQuiting: boolean): AppThunk => async (dispatch) => {
  try {
    let requestUrl = `${baseURL}api/v1/inspection/inspectiontasks/active`;
    if (currentstart) {
      requestUrl += `?currentstart=${currentstart}`
    }
    if (sortField && sortDirection) {
      requestUrl += `&sortcolumn=${sortField}&sortorder=${sortDirection}`;
    }
    if (isAllTask) {
      requestUrl += `&isAllTask=${isAllTask}`;
    } else {
      requestUrl += `&isRequiredQuitting=${isRequiredQuiting}`;
    }
    const res = await zlink.get(`${requestUrl}`);
    if (res.status === "done") {
      if (res.data) {
        dispatch(setActiveInspectionTasks(res.data));
      }
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const getClosedInspectionTasks = (currentstart: any, sortField: any, sortDirection: any): AppThunk => async (dispatch) => {
  try {
    let requestUrl = `${baseURL}api/v1/inspection/inspectiontasks/closed`;
    if (currentstart) {
      requestUrl += `?currentstart=${currentstart}`
    }
    if (sortField && sortDirection) {
      requestUrl += `&sortcolumn=${sortField}&sortorder=${sortDirection}`;
    }
    const res = await zlink.get(`${requestUrl}`);
    if (res.status === "done") {
      if (res.data) {
        dispatch(setClosedInspectionTasks(res.data));
      }
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const getCompletedInspectionTasks = (currentstart: any, sortField: any, sortDirection: any): AppThunk => async (dispatch) => {
  try {
    let requestUrl = `${baseURL}api/v1/inspection/inspectiontasks/completed`;
    if (currentstart) {
      requestUrl += `?currentstart=${currentstart}`
    }
    if (sortField && sortDirection) {
      requestUrl += `&sortcolumn=${sortField}&sortorder=${sortDirection}`;
    }
    const res = await zlink.get(`${requestUrl}`);
    if (res.status === "done") {
      if (res.data) {
        dispatch(setCompletedInspectionTasks(res.data));
      }
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const getExpiredContractList = (currentstart: any, sortField: any, sortDirection: any): AppThunk => async (dispatch) => {
  try {
    let requestUrl = `${baseURL}api/v1/inspection/contract/contractlist/expired`;
    if (currentstart) {
      requestUrl += `?currentstart=${currentstart}`
    }
    if (sortField && sortDirection) {
      requestUrl += `&sortcolumn=${sortField}&sortorder=${sortDirection}`;
    }
    const res = await zlink.get(`${requestUrl}`);
    if (res.status === "done") {
      if (res.data) {
        dispatch(setExpiredContractList(res.data));
      }
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const inActivateInspectionTask = (taskSelected, setSelectedRows, setMessage, setDisplayInfoModal,updatedDate): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.post(`${baseURL}api/v1/inspection/inspectiontask/${taskSelected.uniqueInspectionTaskId}/inactivate`, {})
    if (res.status === "done") {
      setSelectedRows(prev => {
        let arr = prev;
        arr[0]={...taskSelected, status: "Inactive", statusUpdateDate: updatedDate};
        return arr;
      });
      setMessage("Inspection Task inactivated");
      setDisplayInfoModal(true);
      dispatch(setInspectionTaskStatus({...taskSelected, status: "Inactive",statusUpdateDate:updatedDate}));
    } else {
      dispatch(setErrors(res.error));
      if (res.error.message) {
        setMessage(res.error.message);
        setDisplayInfoModal(true);
      }
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
}

export const compareTasks = (body, currentStart, sortData: any, setComparisonData: any, setMessage: any, setDisplayInfoModal: any): AppThunk => async (dispatch) => {
  try {
    let requestUrl = `${baseURL}api/v1/inspection/inspectiontasks/compare?&currentstart=${currentStart}`;
    if (sortData) {
      if (sortData.sortField && sortData.sortDirection) {
        requestUrl += `&sortcolumn=${sortData.sortField}&sortorder=${sortData.sortDirection}`;
      }
    }
    const res = await zlink.post(`${requestUrl}`, body);
    if (res.status === "done") {
      if (res.data) {
        dispatch(setComparisonData(res.data));
      }
    } else {
      dispatch(setErrors(res.error));
      if (res.error.message) {
        setMessage(res.error.message);
        setDisplayInfoModal(true);
      }
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
}

export const setDefaultFontSize = (body, setMessage: any, setDisplayInfoModal: any): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.post(`${baseURL}api/v1/users/me/fontsize`, body)
    if (res.status === "done") {

    } else {
      dispatch(setErrors(res.error));
      if (res.error.message) {
        setMessage(res.error.message);
        setDisplayInfoModal(true);
      }
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
}

export const getEquipmentsummary = (uniqueInspectionTaskId: any, setEquipmentsummary: any): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.get(`${baseURL}api/v1/inspection/inspectiontask/${uniqueInspectionTaskId}/equipments/equipmentsummary`)
    if (res.status === "done") {
      if (res.data && res.data.equipmentSummary) {
        setEquipmentsummary(res.data.equipmentSummary)
      }
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
}
export const deassignEquipment = (uniqueInspectionTaskId, drawingId, equipmentId, body: any): AppThunk => async (dispatch) => {
  try {
      const res = await zlink.post(`${baseURL}api/v1/inspection/inspectiontask/${uniqueInspectionTaskId}/drawings/${drawingId}/equipments/${equipmentId}/deassign`,body);
      if (res.status === "done") {
      } else {
          dispatch(setErrors(res.error));
      }
  } catch (error) {
      dispatch(setErrors(error));
  }
}
     
export const updateObjectBlockHandle = (uniqueInspectionTaskId, drawingId, equipmentId,blockRefHandles, body): AppThunk => async (dispatch) => {
  try {
      const res = await zlink.post(`${baseURL}api/v1/inspection/inspectiontask/${uniqueInspectionTaskId}/drawings/${drawingId}/equipments/${equipmentId}/blockRefHandles/${blockRefHandles}/assignequipment`, body);
      if (res.status === "done") {
        dispatch(getEquipmentTooltip(uniqueInspectionTaskId, drawingId));   
       
      } else {
          dispatch(setErrors(res.error));
      }
  } catch (error) {
      dispatch(setErrors(error));
  }
};
export const getDrawingdisplaySettings = (uniqueequipmentclassid,body): AppThunk =>
async (dispatch) => {
  try {
      const res = await zlink.post(`${baseURL}api/v1/inspection/equipments/${uniqueequipmentclassid}/displaysettings`, body);
      if (res.status === "done") {
          dispatch(setDrawingdisplaySettings(res?.data));
      } else {
          dispatch(setErrors(res.error));
      }
  } catch (error) {
      dispatch(setErrors(error));
  }
};
export const getEquipmentTooltip = (uniqueinspectiontaskid, drawingId): AppThunk =>
  async (dispatch) => {
      try {
          const res = await zlink.post(`${baseURL}api/v1/inspection/inspectiontask/${uniqueinspectiontaskid}/drawings/${drawingId}/equipmenttooltip`, null);
          if (res.status === "done") {
              dispatch(setEquipmentTooltip(res?.data));
          } else {
              dispatch(setErrors(res.error));
          }
      } catch (error) {
          dispatch(setErrors(error));
      }
};

export const activateSystem = (selectedSystem, setMessage, setDisplayInfoModal): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.post(`${baseURL}api/v1/inspection/systems/${selectedSystem}/activate`, {});
    if (res) {
      dispatch(setActivatedSystemDetails(res?.data))
      setMessage('System activated');
      setDisplayInfoModal(true);
    } else {
      dispatch(setErrors(res.error));
      if (res.message) {
        setMessage(res.message);
        setDisplayInfoModal(true);
      }
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
}

export const getSystemListForTaskAdd = (): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.get(`${baseURL}api/v1/inspection/inspectiontask/systemlist`);
    if (res.status === "done") {
      dispatch(setSystemData(res?.data?.systemDetails));
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const {
  setErrors,
  setInspectionSearchId,
  setNotificationSearchId,
  setInspectionTaskList,
  setInspectionPageDetails,
  setReportUploadedStatus,
  setSystemData,
  setTechnicianData,
  setNewInspectionData,
  setInspectionReportList,
  setSelectedReportList,
  setInspectionTaskDetails,
  setUpdatedInspectionData,
  setUpdatedContractDates,
  setCheckedDataForComplete,
  setCheckedDataForClose,
  setInspetctionTaskContracts,
  setInspectionSiteCustomers,
  setInspectionTaskTechnicians,
  setTaskCompletionRate,
  setPanelTypeKPIs,
  setDeviceKPIs,
  setTaskKPIs,
  setActiveInspectionTasks,
  setClosedInspectionTasks,
  setCompletedInspectionTasks,
  setExpiredContractList,
  setInspectionTaskStatus,
  setIsReportUpdated,
  setLinkedReportsData,
  setEquipmentTooltip,
  setDrawingdisplaySettings,
  setInspectionTaskDeletedData,
  setInspectionTaskProgressBar,
  setActivatedSystemDetails
} = InspectionTaskSlice.actions;

export default InspectionTaskSlice.reducer;
