import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "redux/store";
import { baseURL, zlink } from "service/HttpService";


export interface customizeReportState {
    errors: any;
    isAppliedDone: boolean;
}

let initialState: customizeReportState = {
    errors: "",
    isAppliedDone: false,
};

const CustomizeReportSlice = createSlice({
    name: "reportCustomize",
    initialState,
    reducers: {
        setErrors: (state, { payload }: PayloadAction<any>) => {
            state.errors = payload;
        },
        setIsAppliedDone: (state, { payload }: PayloadAction<any>) => {
            state.isAppliedDone = payload;
        },
    }
})

export const getCustomizeReportDataFields = (reportType, customizationType, setFields, setSettings): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.get(`${baseURL}api/v1/reports/${reportType}/datafields?customizationType=${customizationType}`);
            if (res.status === "done") {
                setFields(res?.data?.dataFieldDetails);
                setSettings({ isLandscapeOriented: res?.data?.isLandscapeOriented, isSlNoRequired: res?.data?.isSlNoRequired, isSystemDefault: res?.data?.isSystemDefault });
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

export const onApplied = (reportType, isTemporary, body): AppThunk =>
    async (dispatch) => {
        try {
            let requestUrl = `${baseURL}api/v1/reports/${reportType}`;
            if (isTemporary) {
                requestUrl += `/setcustomize`
            }
            const res = await zlink.post(`${requestUrl}`, body);
            if (res.status === "done") {
                dispatch(setIsAppliedDone(true));
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

export const onGetLoadData = (reportType, type, setFields, setSettings, setMessage, setDisplayInfoModal): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.get(`${baseURL}api/v1/reports/${reportType}?defaultType=${type}`);
            if (res.status === "done") {
                setFields(res?.data?.dataFieldDetails);
                setSettings({ isLandscapeOriented: res?.data?.isLandscapeOriented, isSlNoRequired: res?.data?.isSlNoRequired, isSystemDefault: res?.data?.isSystemDefault });               
            } else {
                dispatch(setErrors(res.error));
                if (res.message) {
                    setDisplayInfoModal(true);
                    setMessage(res.message);
                }
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };
    export const onExportReport = (body,filename): AppThunk => async (dispatch) => {
        try {
            const request: any = {
                method: "POST",
                cache: "no-store",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    Authorization: sessionStorage.getItem("accesstoken"),
                },
                body: JSON.stringify(body)
            };
            const downloadUrl = (window as any).envSettings.baseAPIUrl + `api/v1/data/export`;
            await fetch(downloadUrl, request).then((response: any) => {
                if (response.ok) {
                    response.blob().then((blob: any) => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement("a");
                        a.href = url;
                        a.download = filename;
                        a.click();
                    });
                }
            });
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

export const {
    setErrors,
    setIsAppliedDone,
} = CustomizeReportSlice.actions;

export default CustomizeReportSlice.reducer;