import React from "react";

const LogbookDocument = () => {
    return (
        <>
            <h4 className="doc-title mb-2">
                Logbook
            </h4>
        </>
    );
}

export default LogbookDocument;