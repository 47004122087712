
import React, { forwardRef, memo, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Button } from "primereact/button";
import { ColorPicker } from "primereact/colorpicker";
import { Dropdown } from "primereact/dropdown";

interface IDrawingToolbar {
	onClick?: Function | any;
	orientation?: string;
	tools?: any;
	onCancel?: Function | any;
	onSaveLayer?: Function | any;
	closeBlinkFlag?: any;
	setStopBlinkingCloseButton?: Function | any;
	onLayerColorChanged?: Function | any;
	showColorPicker?: boolean;
	onColorPickerChange?: Function | any;
	showLineThicknessDropdown?: boolean;
}
const DrawingToolbar = forwardRef<any, IDrawingToolbar>((props, ref) => {
	const { onClick, tools, onCancel, onSaveLayer, showColorPicker=false, onColorPickerChange ,showLineThicknessDropdown} = props;
	const [cancelLabel, setCancelLabel] = useState("Close");
	const [showSave, setShowSave] = useState(false);
	const [showSearchBar, setShowSearchBar] = useState(false);
	const toolRef: any = useRef(null);
	const SectoolRef: any = useRef(null);

    const [active, setActive] = useState("init");
	const [currentPosition, setCurrentPosition] = useState("");
	const [color, setColor] = useState<any>("FF0000");

	const onMapEvent = (e: any, name: any) => {
		onClick(e, name);
	};

	const [selectedItem, setSelectedItem] = useState(null);
    const items = Array.from({ length: 10 }).map((_, i) => ({ label: ` ${i+1}`, value: i+1 }));
    const onItemChange = (e) => {
        setSelectedItem(e.value)
		onMapEvent(e.value,"Line Thickness");
    }

	return (
		<>
			{tools ? (
				<div className="tools">
				{tools.map((item: any, key: any) => (
						<Button
							tooltip={item.name}
							key={key}
                            className="button-icon"
                            tooltipOptions={{ position: "top" }}
							onMouseEnter={() => setCurrentPosition(item.name)}
							onMouseLeave={() => setCurrentPosition("")}
							onClick={(e) => onMapEvent(e, item.name)}
						>
							{item.icons}
                            {item.subIcon? item.subIcon: null}
						</Button>
				))}
					{showLineThicknessDropdown && <div className="select-col">
						<Dropdown value={selectedItem} options={items} onChange={onItemChange} virtualScrollerOptions={{ itemSize: 38 }} placeholder="1" />
					</div>}
				{showColorPicker && (<ColorPicker defaultColor="#FF0000" value={color} onChange={(e) => {setColor(e.value); onColorPickerChange(e.value)}} ></ColorPicker>)}
			</div>
			) : null}
		</>
	);
});

export default memo(DrawingToolbar);
