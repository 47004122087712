import React, { useState, useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

import { classNames } from "primereact/utils";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { BsExclamationCircleFill } from "react-icons/bs";
import { InputTextarea } from "primereact/inputtextarea";

interface Props {
    showModal: boolean;
    isCalloutComment:boolean;
    setShowModal: (state: boolean) => void;
    onTextData: (data: any) => void,
    cancelAction?:()=>void,
    editTextData?:any,
    pageTitle:any;
}
type FormData = {
    comments: any;
    fontSize: number,
    font: String,
    bold: boolean,
    italics: boolean,
    highlight: boolean,
};
export const Comments: React.FC<Props> = ({
    showModal = false,
    isCalloutComment=false,
    setShowModal = () => { },
    onTextData = () => {},
    editTextData=[],
    cancelAction=()=>{},
    pageTitle="",
}) => {
    const [saveLabel, setSaveLabel] = useState("Save");
   
    const {
        control,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm<FormData>({
        defaultValues: {
            comments: "",
            fontSize: 12,
            font: "Arial",
            bold: false,
            italics: false,
            highlight: false,

        },
    });

    const addForm: any = useRef();
    useEffect(() => {
       if(editTextData.length>0){
        setValue("comments",editTextData[0].comment);
        setValue("fontSize",editTextData[0].fontSize);
        setValue("font",editTextData[0].font);
        setValue("bold",editTextData[0].bold);
        setValue("italics",editTextData[0].italics);
        setValue("highlight",editTextData[0].highlight);
        setSaveLabel("Save Changes")
       }
        
    }, [])
    const renderFooter = () => {
        return (
            <>
                <Button label="Close" onClick={() => setShowModal(false)} className="cancel btn" />
                <Button label={saveLabel} className="update btn" autoFocus onClick={formSubmitHandler}/>
            </>
        );
    };

    const getFormErrorMessage = (name) => {
        return (
            errors[name] && (
                <span className="tooltip-text">
                    <BsExclamationCircleFill />
                    {errors[name].message}
                </span>
            )
        );
    };

    const formSubmitHandler = (data: any) => {
        addForm.current.requestSubmit();
    };

    const onAddFormSubmit = (data: any) => {
       onTextData && onTextData(data);
    }

   
    

    const onError = (errors: any, e: any) => {};

    return (
        <>
            <Dialog
                header={pageTitle}
                visible={showModal}
                style={{ width: "40vw" }}
                onHide={() => setShowModal(false)}
                footer={renderFooter()}
                className="display-settings selectsite"
            >
                <form
                 onSubmit={handleSubmit(onAddFormSubmit, onError)}
                 ref={addForm}
                >
                    <div className="field col-12 md:col-2 d-flex justify-content-center">
                        <div className="field-checkbox pt-3 mr-3">
                            <Controller
                                name="bold"
                                control={control}
                                render={({ field }) => <Checkbox inputId="Bold" checked={field.value} {...field} />}
                            />
                            <label htmlFor="Bold">Bold</label>
                        </div>
                        <div className="field-checkbox pt-3 mr-3">
                            <Controller
                                name="italics"
                                control={control}
                                render={({ field }) => <Checkbox inputId="Italics" checked={field.value} {...field} />}
                            />
                            <label htmlFor="Italics">Italics</label>
                        </div>
                        {!isCalloutComment?(<div className="field-checkbox pt-3 mr-3">
                            <Controller
                            
                                name="highlight"
                                control={control}
                                render={({ field }) => <Checkbox  inputId="Bold" checked={field.value} {...field} />}
                            />
                            <label htmlFor="Bold">Highlight</label>
                        </div>
                        ) : null} 
                    </div>
                    <div className="field col-12 md:col-2">
                        <span className="p-float-label">
                            <Controller
                                name="font"
                                control={control}
                                rules={{ required: "Font is required." }}
                                render={({ field, fieldState }) => (
                                    <Dropdown
                                        inputId="dropdown"
                                        className={classNames("w-100 error-tooltip", {
                                            error: fieldState.invalid,
                                        })}
                                        {...field}
                                        value={field.value}
                                        options={fontList}
                                        optionLabel="label"
                                        optionValue="label"
                                    />
                                )}
                            />
                            <label className="mandatory" htmlFor="dropdown">
                                Font
                            </label>
                            {getFormErrorMessage("font")}
                        </span>
                    </div>
                    <div className="field col-12 md:col-4">
                        <span className="p-float-label">
                            <Controller
                                name="fontSize"
                                control={control}
                                rules={{ required: "Font Size is required." }}
                                render={({ field, fieldState }) => (
                                    <Dropdown
                                        inputId="dropdown"
                                        className={classNames("w-100 error-tooltip", {
                                            error: fieldState.invalid,
                                        })}
                                        {...field}
                                        value={field.value}
                                        options={fontSize}
                                        optionLabel="label"
                                        optionValue="label"
                                    />
                                )}
                            />
                            <label className="mandatory" htmlFor="dropdown">
                                Font Size
                            </label>
                            {getFormErrorMessage("fontSize")}
                        </span>
                    </div>
                    <div className="field col-12 md:col-4">
                        <span className="p-float-label">
                            <Controller
                                name="comments"
                                control={control}
                                rules={{
                                    required: "Comment is required",
                                }}
                                render={({ field, fieldState }) => (
                                    <InputTextarea
                                        id="comments"
                                        className={classNames("w-100 error-tooltip", { error: fieldState.invalid })}
                                        {...field}
                                    />
                                )}
                            />
                            <label className="mandatory" htmlFor="comments">
                                Comments
                            </label>
                            {getFormErrorMessage("comments")}
                        </span>
                    </div>


                </form>
            </Dialog>

        </>
    );
};

export default Comments;

const fontList = [
    { label: 'Arial', value: 1 },
    { label: 'Courier New', value: 2 },
    { label: 'Tahoma', value: 3 },
    { label: 'Times New Roman', value: 4 },
    { label: 'Verdana', value: 5 },
    { label: 'Trebuchet MS', value: 6 }
  ];

  const fontSize = [
    { label: 1, value: 1 },
    { label: 2, value: 2 },
    { label: 3, value: 3 },
    { label: 4, value: 4 },
    { label: 5, value: 5 },
    { label: 6, value: 6 },
    { label: 7, value: 7 },
    { label: 8, value: 8 },
    { label: 9, value: 9 },
    { label: 10, value: 10 },
    { label: 11, value: 11 },
    { label: 12, value: 12 },
    { label: 13, value: 13 },
    { label: 14, value: 14 },
    { label: 15,value: 15 },
    { label: 16, value: 16 },
    { label: 17, value: 17 },
    { label: 18, value: 18 },
    { label: 19, value: 19 },
    { label: 20, value: 20 }
  ];