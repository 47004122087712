import { textEditor } from "pages/InspectionTasks/InspectionTasksData";


export function getEquipmentListGridColumns(generalProperties: any) {
    const columnInfo: any = [];
    if (generalProperties != null) {
        for (var i = 0; i < generalProperties?.length; i++) {
            if (generalProperties[i]?.propertyType !== "INTERNAL") {
                let data = {
                    field: generalProperties[i]?.name,
                    header: generalProperties[i]?.alias,
                    style: { flex: '1 0 200px' },
                    sortable: true,
                }
                columnInfo.push(data);
            }
        }
    }
    return columnInfo;
}

export const getImportEquipmentColumns = (data: any, isFetched: boolean) => {
    const columnInfo: any = [];
    if (data) {
        for (const obj in data) {
            if (obj !== "RowNumber" && obj !== "ValidationStatusId") {
                let item: any = {
                    field: obj,
                    header: obj,
                    style: { flex: '1 0 200px' },
                    sortable: true,
                }
                if (obj !== "Tech Advance + Comments") {
                    item.editor = (options) => textEditor(options);
                }
                columnInfo.push(item);
            }
        }
    }
    return columnInfo;
}

export const getFirstDataFieldItem = (values, elements) => {
    values?.forEach(element => {
        if (element?.inspectionGroupFields?.length) {
            elements.push(element);
        } else {
            getFirstDataFieldItem(element?.children, elements);
        }
    });
    return elements[0];
}