import React, { useEffect, useState } from "react";
import { PieChart, Pie, ResponsiveContainer, Legend, Cell, Tooltip } from "recharts";
import { customPieChartTooltip } from "./utility";

import { useDispatch } from "react-redux";
import { getEquipmentsummary } from "redux/pages/Inspection/InspectionTask/inspectionTaskSlice";

interface SummaryPropType {
    selectedTask?: any;
}

const Summary: React.FC<SummaryPropType> = (props) => {

	const { selectedTask } = props;

	const dispatch = useDispatch<any>();
	const colors = ["#27B099", "#bd6482", "#95B1B0"];
	const [equipmentSummary, setEquipmentSummary] = useState<any>([]);

	useEffect(() => {
		if (selectedTask && selectedTask.uniqueInspectionTaskId) {
			dispatch(getEquipmentsummary(selectedTask.uniqueInspectionTaskId, setEquipmentSummary));
		}	
	}, [dispatch, selectedTask]);

	return (
		<div className="summary-wrapper">
			<div className="reports-generated">
				{(equipmentSummary && equipmentSummary.length) ? 
					(<>
						<div className="title">
							<h5>Equipment Summary</h5>
						</div>
						<div className="summary-chart">
							<ResponsiveContainer>
								<PieChart>
									<Legend layout="horizontal" verticalAlign="top" align="center" wrapperStyle={{ fontSize: "10px" }} />
									<Pie
										data={equipmentSummary}
										cx="50%"
										cy="50%"
										startAngle={360}
										endAngle={0}
										labelLine={false}
										fill="#8884d8"
										dataKey="count"
									>
										{equipmentSummary.map((entry, index) => (
											<Cell name={entry.name} key={`cell-${index}`} fill={colors[index % colors.length]} />
										))}
									</Pie>
									<Tooltip content={customPieChartTooltip} />
								</PieChart>
							</ResponsiveContainer>
						</div>
					
					</>)
					: (<></>)
				}
			</div>
		</div>
	);
};

export default Summary;
