import React, { useState, useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { AutoComplete } from "primereact/autocomplete";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { Dialog } from "primereact/dialog";
//redux
import {getZoneDetails,getCustomerDetails,getSiteDetails} from '../../redux/pages/Dashboard/Header/selectSiteSlice'
import { getCountryList} from 'redux/pages/Administration/administrationSlice';
import {setAsActiveSiteForSite} from "../../redux/pages/Administration/Site/siteSlice";
import { fetchLoggedInUserDetails } from "redux/pages/Administration/administrationSlice";
import {setCustomerReviseList} from "redux/pages//Inspection/InspectionTask/inspectionTaskFilterSlice";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../redux/hooks";
//routes
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "constants/AppRoutes";
import InformationBox from "components/common/Message/InformationBox/InformationBox";
import { setInspectionSearchId } from "redux/pages/Inspection/InspectionTask/inspectionTaskSlice";

interface Props {
  showModal: boolean;
  setShowModal: (state: boolean) => void;
  hasDefaultSite: boolean;
}
type FormData = {
  Country: any;
  Zone: any;
  SelectCustomer: string;
  SelectSite:string;
};
export const SelectUserSite: React.FC<Props> = ({
  showModal = false,
  setShowModal = () => {},
  hasDefaultSite= true,
}) => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const countryList = useAppSelector((state) =>  state.administration.countryList);
  const zoneList = useAppSelector((state) => state.selectSite.zoneList);
  const customerList = useAppSelector((state) => state.selectSite.customerList);
  const siteList = useAppSelector((state) => state.selectSite.siteList);
  const switchedSite = useAppSelector((state) => state.site.activeSite);

  const [activeSiteId,setActiveSiteId]=useState<any>("")
  const [isNavigate, setIsNavigate] = useState(false);
  const[selectedCountryId,setSelectedCountryId]=useState<any>(null);
  const[selectedZoneId,setSelectedZoneId]=useState<any>(null);
  const[selectedCustomerId,setSelectedCustomerId]=useState<any>(null);
  const [countryLookup, setCountryLookup] = useState([{ name: "", code: "" }]);
  const [zoneLookup, setZoneLookup] = useState([{ name: "", code: "" }]);
  const[customerLookup,setCustomerLookup] = useState([{ name: "", code: "" }]);
  const[siteLookup,setSiteLookup] = useState([{ name: "", code: "" }]);
  const [customerSearch, setCustomerSearch] = useState<any>(null);
  const [siteSearch, setSiteSearch] = useState<any>(null);
  const [, setSelectedSearch] = useState<any>({});
  const [filteredSearch, setFilteredSearch] = useState<any>(null);
  const [displayInfoModal, setDisplayInfoModal] = useState(false);
  const [message, setMessage] = useState("");

  const searchContainerRef = useRef(null);
  const SelectUserSiteForm: any = useRef();
  const siteListRef: any = useRef([]);
  const customerListRef: any = useRef([]);

  const {
    control,
    setValue,
  } = useForm<FormData>({
    defaultValues: {
      Country:null,
      Zone: null,
      SelectCustomer:"",
      SelectSite:""
    },
  });
 
  useEffect(()=>{
    dispatch(getCountryList());  
  },[dispatch])

  useEffect(()=>{
    dispatch(getZoneDetails(selectedCountryId));  
  },[dispatch,selectedCountryId]);

  useEffect(()=>{
    if (customerSearch?.length>2) {
      let body ={"uniqueBusinessZoneId":selectedZoneId,"searchText":customerSearch};
      dispatch(getCustomerDetails(selectedCountryId,body));
    }
  },[dispatch,customerSearch,selectedCountryId,selectedZoneId]);

  useEffect(()=>{
    if (siteSearch?.length>2) {
      let body ={"uniqueBusinessZoneId":selectedZoneId,"uniqueCustomerId":selectedCustomerId, "searchText":siteSearch};
      dispatch(getSiteDetails(selectedCountryId,body));
    }
  },[dispatch,siteSearch,selectedZoneId,selectedCustomerId,selectedCountryId]);

  useEffect(()=>{
    if (countryList?.countries && countryList?.countries.length) {
      let country: { name: string, code: string }[] = [];
      country.push({name:'--Select--', code:'' });
      countryList?.countries.map((item: any) => {    
         return country.push({ name: item.countryName, code: item.countryId })
      });    
      setValue("Country",country[0])  
      setCountryLookup(country)
    }
  },[countryList,setValue])

  useEffect(()=>{
    if(zoneList && zoneList?.length){
      let zone: { name: string, code: string }[] = [];
      zone.push({name:'--Select--', code:'' });
      zoneList?.map((item: any) => {    
         return zone.push({ name: item.name, code: item.uniqueBusinessZoneId })
      });  
      setValue("Zone",zone[0])    
      setZoneLookup(zone)
    }
  },[zoneList,setValue]);

  useEffect(()=>{
    if(customerList && customerList?.length){
      let customer: { name: string, code: string }[] = [];
      customerList?.map((item: any) => {    
         return customer.push({ name: item.customerName, code: item.uniqueCustomerId })
      });   
      customerListRef.current = customer;
      setCustomerLookup(customer)
    } else {
      customerListRef.current = [];
    }
  },[customerList]);

  useEffect(()=>{    
    if(siteList && siteList?.length){
      let site: { name: string, code: string }[] = [];
      siteList?.map((item: any) => {    
         return site.push({ name: item.siteName, code: item.uniqueSiteId })
      });   
      siteListRef.current = site;
      setSiteLookup(site)
    } else {
      siteListRef.current = [];
    }
  },[siteList]);


  useEffect(() => {
    if (isNavigate && switchedSite?.uniqueSiteId === activeSiteId) {
      setIsNavigate(false)
      dispatch(setInspectionSearchId(""));
      navigate(`/${APP_ROUTES.INSPECTION_TASKS}`);
      dispatch(fetchLoggedInUserDetails());
      dispatch(setCustomerReviseList([]));
      setShowModal(false);  
    }
  },[dispatch,switchedSite,navigate,isNavigate,activeSiteId,setShowModal]);

  const onSearchCustomer = (event: { query: string }) => {
        setTimeout(() => {
            let filteredSearches;
            if (!event.query.trim().length) {
              filteredSearches = [...customerListRef.current];
            }
            else {
              filteredSearches = customerListRef.current.filter((search) => {
                    return search.name.toLowerCase().includes(event.query.toLowerCase());
                });
            }
            setFilteredSearch(filteredSearches);
        }, 500);
  };

  const onSearchSite = (event: { query: string }) => {
        setTimeout(() => {
            setFilteredSearch([...siteListRef.current]);
        }, 800);
  };

const itemTemplate = (item: any) => {
    return (
        <div className="data-item">
            <div>{item.name}</div>
        </div>
    );
}

  const onCountryChange=(e:any)=>{
    setSelectedCountryId(e.code);
    setValue("Zone",zoneLookup[0])
    setCustomerSearch("")
    setSiteSearch("")
  }

  const switchToSelectedSite=(e:any)=>{
    setIsNavigate(true);
    setActiveSiteId(e.code);
    dispatch(setAsActiveSiteForSite(e.code,"", ""));
  }

  const onCloseClick = () => {
    if (!hasDefaultSite) {
      setDisplayInfoModal(true);
      setMessage("Select a Site to proceed")
    } else {
      setShowModal(false);
    }
  }

  return (
    <>
      <Dialog
        header="Select Site"
        visible={showModal}
        style={{ width: "40vw" }}
        onHide={onCloseClick}
        className="display-settings selectsite"
      >
        <form ref={SelectUserSiteForm}>
          <div className="row px-2">
            <div className="field col-12 md:col-2 mb-0">
              <span className="p-float-label">
                <Controller
                  name="Country"
                  control={control}
                  rules={{
                    required: "This field is required.",
                  }}
                  render={({ field, fieldState }) => (
                    <Dropdown
                      inputId=""
                      className={classNames("w-100 error-tooltip", {
                        error: fieldState.invalid,
                      })}
                      {...field}
                      value={field.value}
                      onChange={(e) => {field.onChange(e.value);onCountryChange(e.value) }}
                      options={countryLookup}
                      optionLabel="name"
                    />
                  )}
                />
                <label htmlFor="Country">
                  Country
                </label>
              </span>
            </div>
          </div>
          <div className="row px-2 pt-1">
            <div className="field col-12 md:col-2 mb-0">
              <span className="p-float-label">
                <Controller
                  name="Zone"
                  control={control}
                  rules={{
                    required: "This field is required.",
                  }}
                  render={({ field, fieldState }) => (
                    <Dropdown
                      inputId=""
                      className={classNames("w-100 error-tooltip", {
                        error: fieldState.invalid,
                      })}
                      {...field}
                      value={field.value}
                      onChange={(e) => {field.onChange(e.value); setSelectedZoneId(e.value.code)}}
                      options={zoneLookup}
                      optionLabel="name"
                    />
                  )}
                />
                <label htmlFor="Zone">
                  Zone
                </label>
              </span>
            </div>
          </div>
          <div className="row px-2 pt-1">
            <div className="field col-12 md:col-2 mb-0">
              <span className="p-float-label">
                <Controller
                  name="SelectCustomer"
                  control={control}
                  rules={{
                    required: "This field is required.",
                  }}
                  render={({ field, fieldState }) => (
                    <AutoComplete minLength={3} value={customerSearch} suggestions={filteredSearch} completeMethod={onSearchCustomer} field="name"  itemTemplate={itemTemplate} onChange={(e) => {setCustomerSearch(e.value);setSelectedCustomerId(e.value.code)}} onSelect={e => setSelectedSearch(e.value)} aria-label="Customers" appendTo={searchContainerRef?.current} />
 
                  )}
                />
                <label  htmlFor="Select Customer">
                 Select Customer
                </label>
              </span>
            </div>
          </div>

          <div className="row px-2 pt-1">
            <div className="field col-12 md:col-2 mb-0">
              <span className="p-float-label">
                <Controller
                  name="SelectSite"
                  control={control}
                  rules={{
                    required: "This field is required.",
                  }}
                  render={({ field, fieldState }) => (
                    <AutoComplete minLength={3} value={siteSearch} suggestions={filteredSearch} completeMethod={onSearchSite} field="name"  itemTemplate={itemTemplate} onChange={(e) => {setSiteSearch(e.value)}} onSelect={e => {setSelectedSearch(e.value);switchToSelectedSite(e.value)}} aria-label="Sites" appendTo={searchContainerRef?.current} />
                  )}
                />
                <label  htmlFor="Select Site">
                 Select Site
                </label>
              </span>
            </div>
          </div>
        </form>
      </Dialog>
      <InformationBox
        showInfoModal={displayInfoModal}
        setShowInfoModal={setDisplayInfoModal}
        message={message}
      />
    </>
  );
};

export default SelectUserSite;
