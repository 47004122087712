import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "redux/store";
import { baseURL, zlink } from "service/HttpService";

export interface ReportMappingState {
  errors: any;
  subItems: any;
  reportGroups: any;
  reportDetailsForContract: any;
  reportMappingSavedDataForContract: any;
  reportDetailsForSystems: any;
  reportMappingSavedDataForSystems: any;
  reportDetailsForQuestion: any;
  reportMappingSavedDataForQuestion: any;
  selectedDisplayColumns :any;
  displayColumns: any;
  isDisplayColumnsReadyToUpdate :any;
}

let initialState: ReportMappingState = {
  errors: "",
  subItems: "",
  reportGroups: "",
  reportDetailsForContract: "",
  reportMappingSavedDataForContract: "",
  reportDetailsForSystems: "",
  reportMappingSavedDataForSystems: "",
  reportDetailsForQuestion: "",
  reportMappingSavedDataForQuestion: "",
  selectedDisplayColumns:[],
  displayColumns : [],
  isDisplayColumnsReadyToUpdate :true,
};

const ReportMappingSlice = createSlice({
  name: "reporttemplate",
  initialState,
  reducers: {
    setErrors: (state, { payload }: PayloadAction<any>) => {
      state.errors = payload;
    },
    setSubItems: (state, { payload }: PayloadAction<any>) => {
      state.subItems = payload;
    },
    setReportGroups: (state, { payload }: PayloadAction<any>) => {
      state.reportGroups = payload;
    },
    setReportDetailsForContract: (state, { payload }: PayloadAction<any>) => {
      state.reportDetailsForContract = payload;
    },
    setReportMappingSavedDataForContract: (state, { payload }: PayloadAction<any>) => {
      state.reportMappingSavedDataForContract = payload;
    },
    clearReportMappingSavedDataForContract: (state, { payload }: PayloadAction<any>    ) => {
      state.reportMappingSavedDataForContract = payload;
    },
    setReportDetailsForSystem: (state, { payload }: PayloadAction<any>) => {
      state.reportDetailsForSystems = payload;
    },

    setReportMappingSavedDataForSystem: (state, { payload }: PayloadAction<any>) => {
      state.reportMappingSavedDataForSystems = payload;
    },
    clearReportMappingSavedDataForSystem: (state, { payload }: PayloadAction<any>) => {
      state.reportMappingSavedDataForSystems = payload;
    },
    setReportDetailsForQuestion: (state, { payload }: PayloadAction<any>) => {
      state.reportDetailsForQuestion = payload;
    },

    setReportMappingSavedDataForQuestion: (state, { payload }: PayloadAction<any>) => {
      state.reportMappingSavedDataForQuestion = payload;
    },
    clearReportMappingSavedDataForQuestion: (state, { payload }: PayloadAction<any>) => {
      state.reportMappingSavedDataForQuestion = payload;
    },
    setDisplayColumns: (state, { payload }: PayloadAction<any>) => {
      state.displayColumns = payload;
    },
    setIsDisplayColumnsReadyToUpdate: (state, { payload }: PayloadAction<any>) => {
      state.isDisplayColumnsReadyToUpdate = payload;
    },
    setSelectedDisplayColumns: (state, { payload }: PayloadAction<any>) => {
      state.selectedDisplayColumns = payload;
    },
  },
});

export const getReportSubItemList =
  (id: any): AppThunk =>
    async (dispatch) => {
      try {
        const res = await zlink.get(`${baseURL}api/v1/reportmapping`);
        if (res.status === "done") {
          let data = {
            id: id,
            data: res.data.reportMappingDetails,
          };
          dispatch(setSubItems(data));
        } else {
          dispatch(setErrors(res.error));
        }
      } catch (error) {
        dispatch(setErrors(error));
      }
    };
export const getReportGroups =
  (countryId: any): AppThunk =>
    async (dispatch) => {
      try {
        const res = await zlink.get(
          `${baseURL}api/v1/country/${countryId}/reportgroups`
        );
        if (res.status === "done") {
          dispatch(setReportGroups(res?.data?.reportGroupDetails));
        } else {
          dispatch(setErrors(res.error));
        }
      } catch (error) {
        dispatch(setErrors(error));
      }
    };

export const getReportDetailsForContract =
  (countryId: any, reportGroupId: any): AppThunk =>
    async (dispatch) => {
      try {
        const res = await zlink.get(
          `${baseURL}api/v1/country/${countryId}/reportfields/reportgroups/${reportGroupId}/contracts/reportfields`
        );
        if (res.status === "done") {
          dispatch(setReportDetailsForContract(res?.data?.reportMappingDetails));
        } else {
          dispatch(setErrors(res.error));
        }
      } catch (error) {
        dispatch(setErrors(error));
      }
    };

export const onSavecontractReportMappingDataForContract =
  (countryId: any, reportGroupId: any, body: any): AppThunk =>
    async (dispatch) => {
      try {
        const res = await zlink.post(
          `${baseURL}api/v1/country/${countryId}/reportfields/reportgroups/${reportGroupId}/contracts/reportfields/current`, body
        );
        if (res.status === "done") {
          dispatch(setReportMappingSavedDataForContract(res?.data?.reportGroupFields));
        } else {
          dispatch(setErrors(res.error));
        }
      } catch (error) {
        dispatch(setErrors(error));
      }
    };
export const getReportDetailsForSystem =
  (countryId: any, reportGroupId: any): AppThunk =>
    async (dispatch) => {
      try {
        const res = await zlink.get(
          `${baseURL}api/v1/country/${countryId}/reportfields/reportgroups/${reportGroupId}/systems/reportfields`
        );
        if (res.status === "done") {
          dispatch(setReportDetailsForSystem(res?.data?.reportMappingDetails));
        } else {
          dispatch(setErrors(res.error));
        }
      } catch (error) {
        dispatch(setErrors(error));
      }
    };

export const onSavecontractReportMappingDataForSystem =
  (countryId: any, reportGroupId: any, body: any): AppThunk =>
    async (dispatch) => {
      try {
        const res = await zlink.post(
          `${baseURL}api/v1/country/${countryId}/reportfields/reportgroups/${reportGroupId}/systems/reportfields/current`, body
        );
        if (res.status === "done") {
          dispatch(setReportMappingSavedDataForSystem(res?.data?.reportGroupFields));
        } else {
          dispatch(setErrors(res.error));
        }
      } catch (error) {
        dispatch(setErrors(error));
      }
    };
export const getReportDetailsForQuestion =
  (countryId: any, reportGroupId: any): AppThunk =>
    async (dispatch) => {
      try {
        const res = await zlink.get(
          `${baseURL}api/v1/country/${countryId}/reportfields/reportgroups/${reportGroupId}/questions/reportfields`
        );
        if (res.status === "done") {
          dispatch(setReportDetailsForQuestion(res?.data?.reportMappingDetails));
        } else {
          dispatch(setErrors(res.error));
        }
      } catch (error) {
        dispatch(setErrors(error));
      }
    };

export const onSavecontractReportMappingDataForQuestion =
  (countryId: any, reportGroupId: any, body: any): AppThunk =>
    async (dispatch) => {
      try {
        const res = await zlink.post(
          `${baseURL}api/v1/country/${countryId}/reportfields/reportgroups/${reportGroupId}/questions/reportfields/current`, body
        );
        if (res.status === "done") {
          dispatch(setReportMappingSavedDataForQuestion(res?.data?.reportGroupFields));
        } else {
          dispatch(setErrors(res.error));
        }
      } catch (error) {
        dispatch(setErrors(error));
      }
    };
export const onDownloadReportMappingDetailsForContracts =
  (countryId: any, reportGroupId: any,body): AppThunk =>
    async (dispatch) => {
      try {
        const request: any = {
          method: "POST",
          cache: "no-store",
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: sessionStorage.getItem("accesstoken"),
          },
          body: JSON.stringify(body)
        };
        const downloadUrl = (window as any).envSettings.baseAPIUrl + `api/v1/country/${countryId}/reportfields/reportgroups/${reportGroupId}/contracts/reportfields/download`;
        await fetch(downloadUrl, request).then((response: any) => {
          if (response.ok) {
            response.blob().then((blob: any) => {
              let url = window.URL.createObjectURL(blob);
              let a = document.createElement("a");
              a.href = url;
              a.download = "ContractReportMapping";
              a.click();

            });
          }
        });
      } catch (error) {
        dispatch(setErrors(error));
      }
    };
export const onDownloadReportMappingDetailsForQuestions =
  (countryId: any, reportGroupId: any,body): AppThunk =>
    async (dispatch) => {

      try {
        const request: any = {
          method: "POST",
          cache: "no-store",
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: sessionStorage.getItem("accesstoken"),
          },
          body: JSON.stringify(body)
        };
        const downloadUrl = (window as any).envSettings.baseAPIUrl + `api/v1/country/${countryId}/reportfields/reportgroups/${reportGroupId}/questions/reportfields/download`;
        await fetch(downloadUrl, request).then((response: any) => {
          if (response.ok) {
            response.blob().then((blob: any) => {
              let url = window.URL.createObjectURL(blob);
              let a = document.createElement("a");
              a.href = url;
              a.download = "QuestionReportMapping";
              a.click();

            });
          }
        });
      } catch (error) {
        dispatch(setErrors(error));
      }
    };
export const onDownloadReportMappingDetailsForSystems =
  (countryId: any, reportGroupId: any,body): AppThunk =>
    async (dispatch) => {

      try {
        const request: any = {
          method: "POST",
          cache: "no-store",
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: sessionStorage.getItem("accesstoken"),
          },
          body: JSON.stringify(body)
        };
        const downloadUrl = (window as any).envSettings.baseAPIUrl + `api/v1/country/${countryId}/reportfields/reportgroups/${reportGroupId}/systems/reportfields/download`;
        await fetch(downloadUrl, request).then((response: any) => {
          if (response.ok) {
            response.blob().then((blob: any) => {
              let url = window.URL.createObjectURL(blob);
              let a = document.createElement("a");
              a.href = url;
              a.download = "SystemReportMapping";
              a.click();

            });
          }
        });
      } catch (error) {
        dispatch(setErrors(error));
      }
    };

export const {
  setErrors,
  setSubItems,
  setReportGroups,
  setReportDetailsForContract,
  setReportMappingSavedDataForContract,
  clearReportMappingSavedDataForContract,
  setReportDetailsForSystem,
  setReportMappingSavedDataForSystem,
  clearReportMappingSavedDataForSystem,
  setReportDetailsForQuestion,
  setReportMappingSavedDataForQuestion,
  clearReportMappingSavedDataForQuestion,
  setDisplayColumns,
  setSelectedDisplayColumns,
  setIsDisplayColumnsReadyToUpdate
} = ReportMappingSlice.actions;
export default ReportMappingSlice.reducer;
