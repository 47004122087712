import { Button } from 'primereact/button'
import React from 'react'
import { BsPlusLg, BsTrashFill } from 'react-icons/bs'
import { FaDownload } from 'react-icons/fa'


 const InspectionReportTemplatesDocument = () => {
    return(
        <>
        <h4 className="doc-title mb-2">Report Templates</h4>

        <p>
           Report Templates option allows user to add Report Templates which are 
           used for saving data while generation reports after inspection.
        </p>

        <p><span className="doc-subtitle"><b>Add:</b></span></p>
        <p>
            User can upload one or more Report Templates by clicking the 
            <span className="doc-subtitle"> Add </span> 
            <Button className="doc-button-icon"> <BsPlusLg className="icon" title='Add'/> </Button> icon. 
            User can select the valid report template files (.doc, .docx., .xls and .xlsx) 
            and click Upload button to save it. 'Uploaded on' column will display date and 
            time of the uploaded files.
        </p>

        <p><span className="doc-subtitle"><b>Delete:</b></span></p>
        <p>
            User can delete a Report Template by clicking the 
            <span className="doc-subtitle"> Delete </span> 
            <Button className="doc-button-icon"><BsTrashFill className="icon" title='Delete'/> </Button> icon. 
        </p>

        <p><span className="doc-subtitle"><b>Download:</b></span></p>
        <p>
            User can download one or more Report Templates by selecting the required Templates 
            and then clicking the <span className="doc-subtitle"> Download </span> 
            <Button className="doc-button-icon"><FaDownload className="icon" title='Download'/></Button> icon. If multiple templates are selected, the files will be downloaded to a Zip file.
        </p>
        </>
    );
 }
 export default InspectionReportTemplatesDocument;