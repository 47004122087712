import React from 'react'

const ReportsUploadedFinalReportsDocument = () => {
  return (
    <>
      <h4 className="doc-title mb-2">Uploaded Final Reports</h4>
      <p>
        This report shows the list of the Systems for which final reports are uploaded 
        for the selected Time period. It includes the details such as User Name, Customer Name, 
        Customer Number, Site Number, Site Name and System details.
      </p>

      <p>
        User can filter the Uploaded Final Reports based on From date and To date and then clicking Apply button. 
        The report can be customized by selecting Customize button.
      </p>
    </>
  );
}
export default ReportsUploadedFinalReportsDocument;