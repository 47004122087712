import React from "react";

const AsBuiltsDocument = () => {
    return (
        <>
            <h4 className="doc-title mb-2">
                Overview of As Builts
            </h4>

            <p>
                Tech Advance<sup>+</sup> As Builts module provides an online secure central repository of drawings,
                organized by site, building, floor and discipline. It facilitates sharing of drawings with professionals
                like engineers, construction managers, project managers, etc.
                Stakeholders who are given access privileges to these drawings can markup the drawings with their ideas and
                comments for discussion and make decisions. As Builts module also provides for controlling revisions,
                keeping track of all the changes that occur, and maintaining an archive of all past versions.
                When a user logs in for the first time, no Site will be selected by default.
                Once a user has logged in and selected a Site through the link
                <span className="doc-subtitle"> "Site: 'Selected Site' [Change]" </span>
                provided on the top of the screen, for all subsequent logins, the last selected Site will be selected by default.
                Users have to ensure that the required Site is displayed against the Selected Site at the top of the screen before
                doing any Site specific operations like adding Buildings, Floors, Drawings etc...
            </p>
           

        </>
    );
}

export default AsBuiltsDocument;