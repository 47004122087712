import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "components/common/Breadcrumb/Breadcrumb";
import { BsArrowRight } from "react-icons/bs";
import Filters from "components/Filters/Filters";
import Table from "components/Table/Table";
import { CUSTOMIZATION_ATTRIBUTES_TYPE } from "constants/common";
import { APP_ROUTES } from "constants/AppRoutes";
import { CUSTOMIZATION_ATTRIBUTES_COLS } from "./CommonAttributesData";
import { CUSTOMIZATION_ATTRIBUTES_VALUE_COLS } from "./CommonAttibutesValuesData";

import { CUSTOMIZATION_EQUIPMENT_TYPE_ATTRIBUTES_VALUES_COLS } from "./EquipmentTypeAttributesValuesData";

import { CUSTOMIZATION_EQUIPMENT_TYPE_COLS } from "./EquipmentTypeAttributesData";

import { useDispatch } from "react-redux";
import {
  getEquipmentTypeList,
  getEquipmentTypeAttributeList,
  onAddEquipmentTypeAttribute,
  onEditEquipmentTypeAttribute,
  checkIsEquipmentTypeAttributeInUse,
  deleteEquipmentTypeAttribute,
  setIsEquipmentTypeAttributeInUse,
  setEquipmentTypeAttributeListPageDetails,
} from "redux/pages/Manage/Customization/Attributes/EquipmentTypeAttributeSlice";
import {
  checkIsCommonAttributeInUse,
  checkIsAttributeValueInUse,
  deleteCommonAttribute,
  deleteCommonAttributeValue,
  getAttributeNameList,
  getCommonAttributeList,
  getCommonAttributeValues,
  onAddCommonAttribute,
  onAddCommonAttributeValue,
  onEditCommonAttribute,
  onEditCommonAttributeValue,
  setIsCommonAttributeInUse,
  setIsAttributeValueInUse,
  setCommonAttributePageDetails,
  setCommonAttributeValuePageDetails,
} from "redux/pages/Manage/Customization/Attributes/CommonAttributeSlice";
import {
  getEquipmentTypeNameList,
  getEquipmentAttributeNameList,
  getEquipmentTypeAttributeValuesList,
  onAddEquipmentTypeAttributeValue,
  onEditEquipmentTypeAttributeValue,
  deleteEquipmentTypeAttributeValue,
  setEquipmentTypeAttributeValuePageDetails,
} from "redux/pages/Manage/Customization/Attributes/EquipmentTypeAttributeValueSlice";
import { useAppSelector } from "redux/hooks";

import InformationBox from "components/common/Message/InformationBox/InformationBox";

import AddCommonAttributes from "./ManageCommonAttributes/AddCommonAttributes";
import EditCommonAttributes from "./ManageCommonAttributes/EditCommonAttributes";
import AttributesFieldValues from "./ManageCommonAttributes/FieldValues";

import AddCommonAttibutesValues from "./ManageCommonAttibutesValues/AddCommonAttibutesValues";
import EditCommonAttibutesValues from "./ManageCommonAttibutesValues/EditCommonAttibutesValues";

import AddEquipmentTypeAttribute from "./ManageEquipmentTypeAttributes/AddEquipmentTypeAttributes";
import EditEquipmentTypeAttribute from "./ManageEquipmentTypeAttributes/EditEquipmentTypeAttribute";
import EquipmentTypeAttributesFieldValues from "./ManageEquipmentTypeAttributes/FileldValues"

import AddEquipmentTypeAttributeValues from "./ManageEquipmentTypeAttributeValues/AddEquipmentTypeAttributeValues";
import EditEquipmentTypeAttributeValues from "./ManageEquipmentTypeAttributeValues/EditEquipmentTypeAttributeValues";


import ConfirmationBox from "components/common/Message/ConfirmationBox/ConfirmationBox";
import PopOver from "components/common/PopOver/PopOver";
import { OverlayPanel } from "primereact/overlaypanel";
import CustomizationReport from "./Reports/CustomizationReports";
import moment from 'moment';
import { onExportReport } from "redux/common/report/customizeReportSlice";

interface Props { }

export const CustomizationAttributes: React.FC<Props> = () => {
  const navigate = useNavigate();

  const commonAttributeList = useAppSelector((state) => state.commonAttribute.commonAttributeList);
  const commonAttributePageDetails = useAppSelector((state) => state.commonAttribute.commonAttributePageDetails);
  const equipmentTypeList = useAppSelector((state) => state.EquipmentTypeAttribute.equipmentTypeList);
  const equipmentTypeAttributeList = useAppSelector((state) => state.EquipmentTypeAttribute.equipmentTypeAttributeList);
  const equipmentTypeAttributeListPageDetails = useAppSelector((state) => state.EquipmentTypeAttribute.equipmentTypeAttributeListPageDetails);
  const commonAttributeNameList = useAppSelector((state) => state.commonAttribute.attributeNameList);
  const commonAttributeValues = useAppSelector((state) => state.commonAttribute.commonAttributeValues);
  const commonAttributeValuePageDetails = useAppSelector((state) => state.commonAttribute.commonAttributeValuePageDetails);
  const equipmentTypeNameList = useAppSelector((state) => state.equipmentTypeAttributeValue.equipmentTypeNameList);
  const equipmentAttributeNameList = useAppSelector((state) => state.equipmentTypeAttributeValue.equipmentAttributeNameList);
  const equipmentTypeAttributeValueList = useAppSelector((state) => state.equipmentTypeAttributeValue.equipmentTypeAttributeValueList);
  const equipmentTypeAttributeValuePageDetails = useAppSelector((state) => state.equipmentTypeAttributeValue.equipmentTypeAttributeValuePageDetails);
  const isCommonAttributeInUse = useAppSelector((state) => state.commonAttribute.isCommonAttributeInUse);
  const isAttributeValueInUse = useAppSelector((state) => state.commonAttribute.isAttributeValueInUse);
  const isEquipmentTypeAttributeInUse = useAppSelector((state) => state.EquipmentTypeAttribute.isEquipmentTypeAttributeInUse);
  const newCommonAtributeValue = useAppSelector((state) => state.commonAttribute.newCommonAtributeValue);
  const newEquipmentTypeAttributeValue = useAppSelector((state) => state.equipmentTypeAttributeValue.newEquipmentTypeAttributeValue);
  const rowsPerPage = useAppSelector((state) => state.personalSettings.rowsPerPage);

  const [equipmentTypeNameLookup, setEquipmentTypeNameLookup] = useState([]);
  const [equipmentAttributeNameLookup, setEquipmentAttributeNameLookup] = useState([]);
  const [equipmentTypeAttributeValueDetails, setEquipmentTypeAttributeDetails] = useState([]);

  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [selectedTab, setSelectedTab] = useState(CUSTOMIZATION_ATTRIBUTES_TYPE.COMMON_ATTRIBUTES);
  const [pageIndex, setPageIndex] = useState(0);
  const [commonAttributeLookup, setCommonAttributeLookup] = useState([]);
  const [commonAttributeType, setCommonAttributeType] = useState<any>([]);
  const [equipmentAttributeType, setEquipmentAttributeType] = useState<any>([]);
  const [equipmentTypeLookup, setEquipmentTypeLookup] = useState([]);
  const [showGrid, setShowGrid] = useState(false);
  const [attributeId, setAttributeId] = useState();
  const [attributeName, setAttributeName] = useState();
  const [maxLength, setMaxLength] = useState();
  const [equipmentTypeId, setEquipmentTypeId] = useState();
  const [fetchAttributeName, setFetchAttributeName] = useState(false);
  const [fetchEquipmentTypeName, setFetchEquipmentType] = useState(false);
  const [isEquipmentValue, setIsEquipmentValue] = useState(false);
  const [commonAttributeValuesData, setCommonAttributeValuesData] = useState([]);
  const [equipmentTypeAttributesData, setEquipmentTypeAttributesData] = useState([]);
  const [displayInfoModal, setDisplayInfoModal] = useState(false);
  const [message, setMessage] = useState("");
  const [showAddEquipmentTypeAttributes, setShowAddEquipmentTypeAttributes] = useState(false);
  const [showAddCommonAttribute, setShowAddCommonAttribute] = useState(false);
  const [showEditCommonAttribute, setShowEditCommonAttribute] = useState(false);
  const [showAttributesFieldValues, setShowAttributesFieldValues] = useState(false);

  const [showAddCommonAttributeValue, setShowAddCommonAttributeValue] = useState(false);
  const [showEditCommonAttributeValue, setShowEditCommonAttributeValue] = useState(false);

  const [showEditEquipmentTypeAttribute, setShowEditEquipmentTypeAttribute] = useState(false);
  const [showEquipmentFieldValues, setShowEquipmentFieldValues] = useState(false);

  const [showAddEquipmentTypeAttributeValues, setShowAddEquipmentTypeAttributeValues] = useState(false);
  const [showEditEquipmentTypeAttributeValue, setShowEditEquipmentTypeAttributeValue] = useState(false);

  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);

  const [isAttributeDelete, setIsAttributeDelete] = useState(false);
  const [isCommonAttributeValueAdd, setIsCommonAttributeValueAdd] = useState(false);
  const [isEquipmentTypeAttributeValueAdd, setIsEquipmentTypeAttributeValueAdd] = useState(false);
  const [disableAddIcon, setDisableAddIcon] = useState(true);

  const [showReportModal, setShowReportModal] = useState(false);
  const [reportFlag, setReportFlag] = useState<any>('all');
  const [equipmentTypeName, setEquipmentTypeName] = useState<any>("");
  const [selectedEquipmentid, setSelectedEquipmentId] = useState<any>("");
  const [sortField, setSortField] = useState('')
  const [sortOrder, setSortOrder] = useState('');
  const [keyWord, setKeyWord] = useState('');
  const [currentStart, setCurrentStart] = useState(1);
  const [commonAttributesPagination, setCommonAttributesPagination] = useState<any>({});
  const [commonAttributeValuesPagination, setCommonAttributeValuesPagination] = useState<any>({});
  const [equipmentTypeAttributesPagination, setEquipmentTypeAttributesPagination] = useState<any>({});
  const [equipmentTypeAttributeValuesPagination, setEquipmentTypeAttributeValuesPagination] = useState<any>({});

  const dispatch = useDispatch<any>();
  const downloadRef = React.createRef<OverlayPanel>();

  useEffect(() => {
    setShowGrid(true);
    setPageIndex(0);
    switch (selectedTab) {
      case CUSTOMIZATION_ATTRIBUTES_TYPE.COMMON_ATTRIBUTES: {
        dispatch(getCommonAttributeList(1));
        break;
      }
      case CUSTOMIZATION_ATTRIBUTES_TYPE.EQUIPMENT_TYPE_ATTRIBUTES: {
        setFetchEquipmentType(true);
        dispatch(getEquipmentTypeList());
        break;
      }
      case CUSTOMIZATION_ATTRIBUTES_TYPE.COMMON_ATTRIBUTES_VALUES: {
        dispatch(getAttributeNameList(setFetchAttributeName));
        break;
      }
      case CUSTOMIZATION_ATTRIBUTES_TYPE.EQUIPMENT_TYPE_ATTRIBUTES_VALUES: {
        dispatch(getEquipmentTypeNameList());
        break;
      }
    }
  }, [dispatch, selectedTab]);

  useEffect(() => {
    if (attributeId && equipmentTypeId && selectedTab && rowsPerPage && rowsPerPage > 0) {
      switch (selectedTab) {
        case CUSTOMIZATION_ATTRIBUTES_TYPE.COMMON_ATTRIBUTES: {
          dispatch(getCommonAttributeList(1));
          break;
        }
        case CUSTOMIZATION_ATTRIBUTES_TYPE.COMMON_ATTRIBUTES_VALUES: {
          dispatch(getCommonAttributeValues(attributeId, 1, "", sortField, sortOrder, setMessage, setDisplayInfoModal, null));
          break;
        }
        case CUSTOMIZATION_ATTRIBUTES_TYPE.EQUIPMENT_TYPE_ATTRIBUTES: {
          dispatch(getEquipmentTypeAttributeList(equipmentTypeId, 1, "", sortField, sortOrder));
          break;
        }
        case CUSTOMIZATION_ATTRIBUTES_TYPE.EQUIPMENT_TYPE_ATTRIBUTES_VALUES: {
          dispatch(getEquipmentTypeAttributeValuesList(attributeId, 1, "", sortField, sortOrder, setMessage, setDisplayInfoModal, null));
          break;
        }
      }
    }
  }, [dispatch, rowsPerPage]);

  useEffect(() => {
    if (commonAttributePageDetails) {
      setCommonAttributesPagination(commonAttributePageDetails);
    }
    if (commonAttributeValuePageDetails) {
      setCommonAttributeValuesPagination(commonAttributeValuePageDetails);
    }
    if (equipmentTypeAttributeListPageDetails) {
      setEquipmentTypeAttributesPagination(equipmentTypeAttributeListPageDetails);
    }
    if (equipmentTypeAttributeValuePageDetails) {
      setEquipmentTypeAttributeValuesPagination(equipmentTypeAttributeValuePageDetails);
    }
  }, [commonAttributePageDetails, commonAttributeValuePageDetails, equipmentTypeAttributeListPageDetails, equipmentTypeAttributeValuePageDetails]);

  useEffect(() => {
    if (selectedTab && rowsPerPage && rowsPerPage > 0) {
      let modifiedPageDetails: any = {};
      switch (selectedTab) {
        case CUSTOMIZATION_ATTRIBUTES_TYPE.COMMON_ATTRIBUTES: {
          modifiedPageDetails = { ...commonAttributesPagination, 'currentLength': rowsPerPage, 'pageCount': rowsPerPage };
          setCommonAttributesPagination(modifiedPageDetails);
          setCurrentStart(1)
          setPageIndex(0)
          break;
        }
        case CUSTOMIZATION_ATTRIBUTES_TYPE.COMMON_ATTRIBUTES_VALUES: {
          modifiedPageDetails = { ...commonAttributeValuesPagination, 'currentLength': rowsPerPage, 'pageCount': rowsPerPage };
          setCommonAttributeValuesPagination(modifiedPageDetails);
          setCurrentStart(1)
          setPageIndex(0)
          break;
        }
        case CUSTOMIZATION_ATTRIBUTES_TYPE.EQUIPMENT_TYPE_ATTRIBUTES: {
          modifiedPageDetails = { ...equipmentTypeAttributesPagination, 'currentLength': rowsPerPage, 'pageCount': rowsPerPage };
          setEquipmentTypeAttributesPagination(modifiedPageDetails);
          setCurrentStart(1)
          setPageIndex(0)
          break;
        }
        case CUSTOMIZATION_ATTRIBUTES_TYPE.EQUIPMENT_TYPE_ATTRIBUTES_VALUES: {
          modifiedPageDetails = { ...equipmentTypeAttributeValuesPagination, 'currentLength': rowsPerPage, 'pageCount': rowsPerPage };
          setEquipmentTypeAttributeValuesPagination(modifiedPageDetails);
          setCurrentStart(1)
          setPageIndex(0)
          break;
        }
      }
    }
  }, [rowsPerPage]);

  useEffect(() => {
    if (fetchAttributeName) {
      if (commonAttributeNameList?.length > 0) {
        let lookup: any = [];
        commonAttributeNameList.map((item: any) => {
          return lookup.push({
            name: item.attributeName,
            code: item.uniqueAttributeId,
            type: item.dataType.dataTypeName,
            maxLength: item.maxLength,
          });
        });
        setCommonAttributeLookup(lookup);
      } else {
        setMessage("No Validated Common Attributes exist");
        setDisplayInfoModal(true);
      }
      setFetchAttributeName(false);
    }
  }, [fetchAttributeName, commonAttributeNameList]);

  useEffect(() => {
    if (equipmentTypeNameList?.length > 0) {
      let lookup: any = [];
      equipmentTypeNameList.map((item: any) => {
        return lookup.push({
          name: item.equipmentClassName,
          code: item.uniqueEquipmentClassId,
        });
      });
      setEquipmentTypeNameLookup(lookup);
      setFetchAttributeName(false);
    }
  }, [equipmentTypeNameList]);

  useEffect(() => {
    if (isEquipmentValue) {
      if (equipmentAttributeNameList?.length > 0) {
        let lookup: any = [];
        equipmentAttributeNameList.map((item: any) => {
          return lookup.push({
            name: item.attributeName,
            code: item.uniqueAttributeId,
            type: item.dataType.dataTypeName,
            maxLength: item.maxlength,
          });
        });
        setEquipmentAttributeNameLookup(lookup);
      } else {
        setMessage("No Validated Equipment Type Attributes exist");
        setDisplayInfoModal(true);
      }
      setIsEquipmentValue(false);
    }
  }, [isEquipmentValue, equipmentAttributeNameList]);

  useEffect(() => {
    if (equipmentTypeAttributeValueList) {
      setEquipmentTypeAttributeDetails(equipmentTypeAttributeValueList);
    }
  }, [equipmentTypeAttributeValueList]);

  useEffect(() => {
    if (fetchEquipmentTypeName) {
      if (equipmentTypeList?.length > 0) {
        let lookup: any = [];
        equipmentTypeList.map((item: any) => {
          return lookup.push({
            name: item.equipmentClassName,
            code: item.uniqueEquipmentClassId,
          });
        });
        setEquipmentTypeLookup(lookup);
      } else {
        setMessage("No Equipment Types exists");
        setDisplayInfoModal(true);
      }
      setFetchEquipmentType(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipmentTypeList]);

  useEffect(() => {
    if (commonAttributeValues) {
      setCommonAttributeValuesData(commonAttributeValues);
    }
  }, [commonAttributeValues]);

  useEffect(() => {
    if (equipmentTypeAttributeList) {
      setEquipmentTypeAttributesData(equipmentTypeAttributeList);
    }
  }, [equipmentTypeAttributeList]);

  const onPageChanged = (options: any) => {
    if (options !== pageIndex) {
      setPageIndex(options);
      switch (selectedTab) {
        case CUSTOMIZATION_ATTRIBUTES_TYPE.COMMON_ATTRIBUTES: {
          let currentStart = options * commonAttributesPagination?.currentLength + 1;
          setCurrentStart(currentStart);
          dispatch(getCommonAttributeList(currentStart,keyWord,sortField,sortOrder));
          break;
        }
        case CUSTOMIZATION_ATTRIBUTES_TYPE.COMMON_ATTRIBUTES_VALUES: {
          let currentStart = options * commonAttributeValuesPagination?.currentLength + 1;
          setCurrentStart(currentStart);
          dispatch(getCommonAttributeValues(attributeId, currentStart,keyWord,sortField, sortOrder, setMessage, setDisplayInfoModal, null));
          break;
        }
        case CUSTOMIZATION_ATTRIBUTES_TYPE.EQUIPMENT_TYPE_ATTRIBUTES_VALUES: {
          let currentStart = options * equipmentTypeAttributeValuesPagination?.currentLength + 1;
          setCurrentStart(currentStart);
          dispatch(getEquipmentTypeAttributeValuesList(attributeId, currentStart,keyWord,sortField, sortOrder, setMessage, setDisplayInfoModal, null));
          break;
        }
        case CUSTOMIZATION_ATTRIBUTES_TYPE.EQUIPMENT_TYPE_ATTRIBUTES: {
          let currentStart = options * equipmentTypeAttributesPagination?.currentLength + 1;
          setCurrentStart(currentStart);
          dispatch(getEquipmentTypeAttributeList(equipmentTypeId, currentStart,keyWord,sortField, sortOrder));
          break;
        }
      }
    }
  };

  const onHeaderDropdownChange = (item: any) => {
    let dataFieldId = item.value.code;
    switch (selectedTab) {
      case CUSTOMIZATION_ATTRIBUTES_TYPE.COMMON_ATTRIBUTES_VALUES: {
        setDisableAddIcon(false);
        let attributeName = item.value?.name;
        let maxLength = item.value?.maxLength;
        let dataType = item.value?.type;
        setCommonAttributeValuesData([]);
        setAttributeId(dataFieldId);
        setAttributeName(attributeName);
        setMaxLength(maxLength);
        setCommonAttributeType(dataType);
        dispatch(getCommonAttributeValues(dataFieldId, 1 ,"",sortField,sortOrder,setMessage, setDisplayInfoModal, null));
        setKeyWord('');
        break;
      }
      case CUSTOMIZATION_ATTRIBUTES_TYPE.EQUIPMENT_TYPE_ATTRIBUTES: {
        setDisableAddIcon(false);
        setEquipmentTypeAttributesData([]);
        setEquipmentTypeId(dataFieldId);
        setEquipmentTypeName(item.value?.name);
        dispatch(getEquipmentTypeAttributeList(dataFieldId, 1,"",sortField,sortOrder));
        setKeyWord('');
        break;
      }
      case CUSTOMIZATION_ATTRIBUTES_TYPE.EQUIPMENT_TYPE_ATTRIBUTES_VALUES: {
        if (item.name === "Equipment Type Name") {
          setSelectedEquipmentId(item.value?.code);
          setEquipmentTypeName(item.value?.name);
          setEquipmentAttributeNameLookup([]);
          dispatch(getEquipmentAttributeNameList(dataFieldId, setIsEquipmentValue));
          setEquipmentTypeAttributeDetails([]);
        }
        break;
      }
    }
  };

  const onHeaderAttributeNameChange = (item: any) => {
    let dataFieldId: any;
    setDisableAddIcon(false);
    if (item.value) dataFieldId = item.value.code;
    else dataFieldId = item.code;
    let attributeName = item.value ? item.value.name : item.name;
    let maxLength = item.value ? item.value.maxLength : item.maxLength;
    let dataType = item.value ? item.value.type : item.type;
    setAttributeId(dataFieldId);
    setAttributeName(attributeName);
    setMaxLength(maxLength);
    setEquipmentAttributeType(dataType);
    setEquipmentTypeAttributeDetails([]);
    dispatch(getEquipmentTypeAttributeValuesList(dataFieldId, 1, "", sortField, sortOrder, setMessage, setDisplayInfoModal, null));
    setKeyWord('');
  };

  const handleTabChange = (selectedAttributeTab) => {
    setDisableAddIcon(true);
    if (selectedAttributeTab !== selectedTab) {
      switch (selectedAttributeTab) {
        case CUSTOMIZATION_ATTRIBUTES_TYPE.COMMON_ATTRIBUTES_VALUES: {
          setCommonAttributeValuesData([]);
          break;
        }
        case CUSTOMIZATION_ATTRIBUTES_TYPE.EQUIPMENT_TYPE_ATTRIBUTES: {
          setEquipmentTypeAttributesData([]);
          break;
        }
        case CUSTOMIZATION_ATTRIBUTES_TYPE.EQUIPMENT_TYPE_ATTRIBUTES_VALUES: {
          setEquipmentTypeAttributeDetails([]);
          setEquipmentAttributeNameLookup([]);
          setEquipmentTypeNameLookup([]);
          break;
        }
      }
      setShowGrid(false);
    }
    setSelectedTab(selectedAttributeTab);
  };

  const handleRowSelect = useCallback((data: any) => {
    if (data && data.length > 0) {
      //To resolve useEffect dependency
      setSelectedRows([...data]);
    }
  }, []);

  

  const onSort = (event) => {
    if (event) {
      let sortDirection: string;
      if (event.sortOrder === 1)
        sortDirection = "ASC"
      else
        sortDirection = "DESC"
      setSortField(event.sortField);
      setSortOrder(sortDirection);

      switch (selectedTab) {
        case CUSTOMIZATION_ATTRIBUTES_TYPE.COMMON_ATTRIBUTES: {
          dispatch(getCommonAttributeList(currentStart,keyWord,event?.sortField, sortDirection));
          break;
        }
        case CUSTOMIZATION_ATTRIBUTES_TYPE.COMMON_ATTRIBUTES_VALUES: {
          dispatch(getCommonAttributeValues(attributeId, currentStart,keyWord,event?.sortField, sortDirection, setMessage, setDisplayInfoModal, null));
          break;
        }
        case CUSTOMIZATION_ATTRIBUTES_TYPE.EQUIPMENT_TYPE_ATTRIBUTES: {
          dispatch(getEquipmentTypeAttributeList(equipmentTypeId, currentStart,keyWord,event?.sortField, sortDirection));
          break;
        }
        case CUSTOMIZATION_ATTRIBUTES_TYPE.EQUIPMENT_TYPE_ATTRIBUTES_VALUES: {
          dispatch(getEquipmentTypeAttributeValuesList(attributeId, currentStart,keyWord,sortField, sortOrder, setMessage, setDisplayInfoModal, null));
          break;
        }
      }
      
    }
  }

  const onGlobalSearch = (filter) => {
   
    setKeyWord(filter)
    switch (selectedTab) {
      case CUSTOMIZATION_ATTRIBUTES_TYPE.COMMON_ATTRIBUTES: {
        dispatch(getCommonAttributeList(currentStart,filter,sortField, sortOrder));
        break;
      }
      case CUSTOMIZATION_ATTRIBUTES_TYPE.COMMON_ATTRIBUTES_VALUES: {
        dispatch(getCommonAttributeValues(attributeId, currentStart,filter,sortField, sortOrder, setMessage, setDisplayInfoModal, null));
        break;
      }
      case CUSTOMIZATION_ATTRIBUTES_TYPE.EQUIPMENT_TYPE_ATTRIBUTES: {
        dispatch(getEquipmentTypeAttributeList(equipmentTypeId, currentStart,filter,sortField, sortOrder));
        break;
      }
      case CUSTOMIZATION_ATTRIBUTES_TYPE.EQUIPMENT_TYPE_ATTRIBUTES_VALUES: {
        dispatch(getEquipmentTypeAttributeValuesList(attributeId, currentStart,filter,sortField, sortOrder, setMessage, setDisplayInfoModal, null));
        break;
      }
    }
}

  const onAddCommonAttributeClick = (data) => {
    dispatch(onAddCommonAttribute(data, setMessage, setDisplayInfoModal, setShowAddCommonAttribute));
  };

  const onAddCommonAttributeValueClick = (data) => {
    let Value=""
    if(commonAttributeType==="Date"){
      Value=moment(data?.Value).format("yyyy-MM-DD").toString()
    }
    else if(commonAttributeType==="Date Time"){
      Value=moment(data?.Value).format("yyyy-MM-DD,h:mm:ss a").toString()
    }
    else
    {
      Value=data.Value
    }
    let body = {
      attributeValue: Value,
    };
    dispatch(onAddCommonAttributeValue(body, attributeId, setMessage, setDisplayInfoModal, setShowAddCommonAttributeValue));
  };

  const onAddEquipmentTypeAttributeClick = (data) => {
    dispatch(onAddEquipmentTypeAttribute(data, equipmentTypeId, setMessage, setDisplayInfoModal, setShowAddEquipmentTypeAttributes));
  };

  const onAddEquipmentTypeAttributeValueClick = (data) => {
    let Value:any = ""
    if (equipmentAttributeType === "Date") {
      Value = moment(data?.Value).format("yyyy-MM-DD").toString()
    }
    else if (equipmentAttributeType === "Date Time") {
      Value = moment(data?.Value).format("yyyy-MM-DD,h:mm:ss a").toString()
    }
    else if (equipmentAttributeType === "Integer" || equipmentAttributeType === "Numeric") {
      Value = parseFloat(data?.Value);
    }
    else {
      Value = data.Value
    }
    let body = {
      attributeValue: Value,
    };
    dispatch(onAddEquipmentTypeAttributeValue(body, attributeId, setMessage, setDisplayInfoModal, setShowAddEquipmentTypeAttributeValues));
  };

  useEffect(() => {
    if (newCommonAtributeValue) {
      setIsCommonAttributeValueAdd(true)
    }
  }, [newCommonAtributeValue])

  useEffect(() => {
    if (newEquipmentTypeAttributeValue) {
      setIsEquipmentTypeAttributeValueAdd(true)
    }
  }, [newEquipmentTypeAttributeValue])

  const okInfoOnClick = () => {
    if (isCommonAttributeValueAdd) {
      setShowAddCommonAttributeValue(true);
      setIsCommonAttributeValueAdd(false)
    } else if (isEquipmentTypeAttributeValueAdd) {
      setShowAddEquipmentTypeAttributeValues(true);
      setIsEquipmentTypeAttributeValueAdd(false);
    }
  }

  const editIconClick = () => {
    if (selectedRows.length < 1) {
      switch (selectedTab) {
        case CUSTOMIZATION_ATTRIBUTES_TYPE.COMMON_ATTRIBUTES:
        case CUSTOMIZATION_ATTRIBUTES_TYPE.EQUIPMENT_TYPE_ATTRIBUTES: {
          setMessage("Select an Attribute");
          break;
        }
        case CUSTOMIZATION_ATTRIBUTES_TYPE.COMMON_ATTRIBUTES_VALUES:
        case CUSTOMIZATION_ATTRIBUTES_TYPE.EQUIPMENT_TYPE_ATTRIBUTES_VALUES: {
          setMessage("Select an Attribute Value");
          break;
        }
        default: {
          setMessage("Select an Attribute");
        }
      }
      setDisplayInfoModal(true);
    } else if (selectedRows.length > 1) {
      setMessage("This operation can be performed only one row at a time");
      setDisplayInfoModal(true);
    } else if (selectedRows[0].attributeName === "Barcode") {
      setMessage("This Attribute cannot be edited.");
      setDisplayInfoModal(true);
    } else {
      switch (selectedTab) {
        case CUSTOMIZATION_ATTRIBUTES_TYPE.COMMON_ATTRIBUTES_VALUES: {
          setShowEditCommonAttributeValue(true);
          break;
        }
        case CUSTOMIZATION_ATTRIBUTES_TYPE.EQUIPMENT_TYPE_ATTRIBUTES: {
          dispatch(checkIsEquipmentTypeAttributeInUse(selectedRows[0].uniqueAttributeId));
          setShowEditEquipmentTypeAttribute(true);
          break;
        }
        case CUSTOMIZATION_ATTRIBUTES_TYPE.EQUIPMENT_TYPE_ATTRIBUTES_VALUES: {
          setShowEditEquipmentTypeAttributeValue(true);
          break;
        }
        default: {
          dispatch(checkIsCommonAttributeInUse(selectedRows[0].uniqueAttributeId));
          setShowEditCommonAttribute(true);
        }
      }
    }
  };

  const onEditCommonAttributeClick = (data) => {
    dispatch(onEditCommonAttribute(selectedRows[0].uniqueAttributeId, data, setMessage, setDisplayInfoModal, setShowEditCommonAttribute));
  };

  const onEditCommonAttributeValueClick = (data) => {
    let Value=""
    if(commonAttributeType==="Date"){
      Value=moment(data?.Value).format("yyyy-MM-DD").toString()
    }
    else if(commonAttributeType==="Date Time"){
      Value=moment(data?.Value).format("yyyy-MM-DD,h:mm:ss a").toString()
    }
    else
    {
      Value=data.Value
    }
    let body = {
      attributeValue: Value,
    };
    dispatch(onEditCommonAttributeValue(attributeId, selectedRows[0].dataFieldLookUpId, body, setMessage, setDisplayInfoModal, setShowEditCommonAttributeValue));
  };

  const onEditEquipmentTypeAttributeValueClick = (data) => {
    let Value:any=""
    if(equipmentAttributeType==="Date"){
      Value=moment(data?.Value).format("yyyy-MM-DD").toString()
    }
    else if(equipmentAttributeType==="Date Time"){
      Value=moment(data?.Value).format("yyyy-MM-DD,h:mm:ss a").toString()
    }
    else if (equipmentAttributeType === "Integer" || equipmentAttributeType === "Numeric") {
      Value = parseFloat(data?.Value);
    }
    else
    {
      Value=data.Value
    }
    let body = {
      attributeValue: Value,
    };
    dispatch(onEditEquipmentTypeAttributeValue(attributeId, selectedRows[0].dataFieldLookUpId, body, setMessage, setDisplayInfoModal, setShowEditEquipmentTypeAttributeValue));
  };

  const onEditEquipmentTypeAttributeClick = (data) => {
    dispatch(onEditEquipmentTypeAttribute(selectedRows[0], equipmentTypeId, data, setMessage, setDisplayInfoModal, setShowEditEquipmentTypeAttribute,setSelectedRows));
  };

  const deleteIconClick = () => {
    if (selectedRows.length < 1) {
      switch (selectedTab) {
        case CUSTOMIZATION_ATTRIBUTES_TYPE.COMMON_ATTRIBUTES:
        case CUSTOMIZATION_ATTRIBUTES_TYPE.EQUIPMENT_TYPE_ATTRIBUTES: {
          setMessage("Select an Attribute");
          break;
        }
        case CUSTOMIZATION_ATTRIBUTES_TYPE.COMMON_ATTRIBUTES_VALUES:
        case CUSTOMIZATION_ATTRIBUTES_TYPE.EQUIPMENT_TYPE_ATTRIBUTES_VALUES: {
          setMessage("Select an Attribute Value");
          break;
        }
        default: {
          setMessage("Select an Attribute");
        }
      }
      setDisplayInfoModal(true);
    } else if (selectedRows.length > 1) {
      setMessage("This operation can be performed only one row at a time");
      setDisplayInfoModal(true);
    } else if (selectedRows[0].attributeName === "Barcode") {
      setMessage("This Attribute cannot be deleted.");
      setDisplayInfoModal(true);
    } else {
      switch (selectedTab) {
        case CUSTOMIZATION_ATTRIBUTES_TYPE.COMMON_ATTRIBUTES: {
          dispatch(checkIsCommonAttributeInUse(selectedRows[0].uniqueAttributeId));
          break;
        }
        case CUSTOMIZATION_ATTRIBUTES_TYPE.COMMON_ATTRIBUTES_VALUES: {
          dispatch(checkIsAttributeValueInUse(attributeId, selectedRows[0].dataFieldLookUpId));
          break;
        }
        case CUSTOMIZATION_ATTRIBUTES_TYPE.EQUIPMENT_TYPE_ATTRIBUTES: {
          dispatch(
            checkIsEquipmentTypeAttributeInUse(selectedRows[0].uniqueAttributeId));
          break;
        }
        case CUSTOMIZATION_ATTRIBUTES_TYPE.EQUIPMENT_TYPE_ATTRIBUTES_VALUES: {
          dispatch(checkIsAttributeValueInUse(attributeId, selectedRows[0].dataFieldLookUpId));
          break;
        }
        default: {
          return null;
        }
      }
      setIsAttributeDelete(true);
    }
  };

  useEffect(() => {
    if (isCommonAttributeInUse && isAttributeDelete) {
      if (
        isCommonAttributeInUse?.isAttributeInUse
      ) {
        setMessage("Selected Attribute is in use, cannot be deleted");
        setDisplayInfoModal(true);
      } else {
        setMessage("Are you sure you want to delete the selected Attribute?");
        setDisplayConfirmationModal(true);
      }
      setIsAttributeDelete(false);
      dispatch(setIsCommonAttributeInUse(""))
    }
  }, [isCommonAttributeInUse]);

  useEffect(() => {
    if (isEquipmentTypeAttributeInUse && isAttributeDelete) {
      if (
        isEquipmentTypeAttributeInUse?.isAttributeInUse
      ) {
        setMessage("Selected Attribute is in use, cannot be deleted");
        setDisplayInfoModal(true);
      } else {
        setMessage("Are you sure you want to delete the selected Attribute?");
        setDisplayConfirmationModal(true);
      }
      setIsAttributeDelete(false);
      dispatch(setIsEquipmentTypeAttributeInUse(""))
    }
  }, [isEquipmentTypeAttributeInUse]);

  useEffect(() => {
    if (isAttributeValueInUse && isAttributeDelete) {
      if (isAttributeValueInUse?.isAttributeValueInUse) {
        setMessage("Selected Attribute Value is in use, cannot be deleted");
        setDisplayInfoModal(true);
      } else {
        setMessage("Are you sure you want to delete the selected Attribute Value?");
        setDisplayConfirmationModal(true);
      }
      setIsAttributeDelete(false);
      dispatch(setIsAttributeValueInUse(""))
    }
  }, [isAttributeValueInUse]);

  useEffect(() => {
    setKeyWord("");
    setSortField("");
    setSortOrder("");
    dispatch(setCommonAttributePageDetails([]));
    dispatch(setCommonAttributeValuePageDetails([]));
    dispatch(setEquipmentTypeAttributeListPageDetails([]));
    dispatch(setEquipmentTypeAttributeValuePageDetails([]));
  }, [selectedTab]);

  const onDeleteConfirm = () => {
    setDisplayConfirmationModal(false);
    switch (selectedTab) {
      case CUSTOMIZATION_ATTRIBUTES_TYPE.COMMON_ATTRIBUTES: {
        dispatch(deleteCommonAttribute(selectedRows[0].uniqueAttributeId, setSelectedRows, setMessage, setDisplayInfoModal));
        break;
      }
      case CUSTOMIZATION_ATTRIBUTES_TYPE.COMMON_ATTRIBUTES_VALUES: {
        dispatch(deleteCommonAttributeValue(attributeId, selectedRows[0].dataFieldLookUpId, setSelectedRows, setMessage, setDisplayInfoModal));
        break;
      }
      case CUSTOMIZATION_ATTRIBUTES_TYPE.EQUIPMENT_TYPE_ATTRIBUTES: {
        dispatch(deleteEquipmentTypeAttribute(selectedRows[0].uniqueAttributeId, setSelectedRows, setMessage, setDisplayInfoModal));
        break;
      }
      case CUSTOMIZATION_ATTRIBUTES_TYPE.EQUIPMENT_TYPE_ATTRIBUTES_VALUES: {
        dispatch(deleteEquipmentTypeAttributeValue(attributeId, selectedRows[0].dataFieldLookUpId, setSelectedRows, setMessage, setDisplayInfoModal));
        break;
      }
      default: {
        return null;
      }
    }
  };

  const onReportAllClick = () => {
    setReportFlag('all');
    setShowReportModal(true);
  };

  const onReportHighlightedClick = () => {
    setReportFlag('highlighted');
    setShowReportModal(true);
  };

  const getSelectedAttributeIds = () => {
    let params: any = [];
    selectedRows.map(item => {
      params.push(item.uniqueAttributeId);
    });
    return params;
  };

  const getSelectedValuesIds = () => {
    let params: any = [];
    selectedRows.map(item => {
      params.push(item.dataFieldLookUpId);
    });
    return params;
  };

  const onExportAll = () => {
    switch (selectedTab) {
      case CUSTOMIZATION_ATTRIBUTES_TYPE.COMMON_ATTRIBUTES: {
        const reportParameters = {
          exportParameters: {
            uniqueEquipmentClassId: "",
            needCommonAttribute: "",
            sortColumn: sortField,
            sortOrder: sortOrder,
            search: keyWord
          },
          exportType: "Object Attribute Grid Report"
        };
        dispatch(onExportReport(reportParameters, "Common Attributes"));
        break;
      }
      case CUSTOMIZATION_ATTRIBUTES_TYPE.COMMON_ATTRIBUTES_VALUES: {
        const reportParameters = {
          exportParameters: {
            uniqueDataFieldId: attributeId,
            uniqueAttributeId: "",
            attributeName: attributeName,
            sortColumn: sortField,
            sortOrder: sortOrder,
            search: keyWord
          },
          exportType: "Object Attribute Values"
        };
        dispatch(onExportReport(reportParameters, "Common Attribute Values"));
        break;
      }
      case CUSTOMIZATION_ATTRIBUTES_TYPE.EQUIPMENT_TYPE_ATTRIBUTES: {
        const reportParameters = {
          exportParameters: {
            uniqueEquipmentClassId: equipmentTypeId,
            needCommonAttribute: false,
            attributeName: equipmentTypeName,
            selectedIds: "",
            sortColumn: sortField,
            sortOrder: sortOrder,
            search: keyWord
          },
          exportType: "Equipment Type Attributes Report"
        };
        dispatch(onExportReport(reportParameters, "Equipment Type Attributes"));
        break;
      }
      case CUSTOMIZATION_ATTRIBUTES_TYPE.EQUIPMENT_TYPE_ATTRIBUTES_VALUES: {
        const reportParameters = {
          exportParameters: {
            uniqueDataFieldId: equipmentTypeId,
            uniqueAttributeId: attributeId,
            attributeName: attributeName,
            equipmentTypeName: equipmentTypeName,
            selectedIds: "",
            needCommonAttribute: false,
            sortColumn: sortField,
            sortOrder: sortOrder,
            search: keyWord
          },
          exportType: "Equipment Type Attributes Values Report"
        };
        dispatch(onExportReport(reportParameters, "Equipment Type Attribute Values"));
        break;
      }
      default: {
        const reportParameters = {
          exportParameters: {
            uniqueEquipmentClassId: "",
            needCommonAttribute: "",
            sortColumn: sortField,
            sortOrder: sortOrder,
            search: keyWord
          },
          exportType: "Object Attribute Grid Report"
        };
        dispatch(onExportReport(reportParameters, "Common Attributes"));
        break;
      }
    }
  };

  const onExportHighlightedClick = () => {
    switch (selectedTab) {
      case CUSTOMIZATION_ATTRIBUTES_TYPE.COMMON_ATTRIBUTES: {
        const reportParameters = {
          exportParameters: {
            uniqueEquipmentClassId: getSelectedAttributeIds(),
            needCommonAttribute: "",
            sortColumn: sortField,
            sortOrder: sortOrder,
            search: keyWord
          },
          exportType: "Object Attribute Grid Report"
        };
        dispatch(onExportReport(reportParameters, "Common Attributes"));
        break;
      }
      case CUSTOMIZATION_ATTRIBUTES_TYPE.COMMON_ATTRIBUTES_VALUES: {
        const reportParameters = {
          exportParameters: {
            uniqueDataFieldId: attributeId,
            uniqueAttributeId: getSelectedValuesIds(),
            attributeName: attributeName,
            sortColumn: sortField,
            sortOrder: sortOrder,
            search: keyWord
          },
          exportType: "Object Attribute Values"
        };
        dispatch(onExportReport(reportParameters, "Common Attribute Values"));
        break;
      }
      case CUSTOMIZATION_ATTRIBUTES_TYPE.EQUIPMENT_TYPE_ATTRIBUTES: {
        const reportParameters = {
          exportParameters: {
            uniqueEquipmentClassId: equipmentTypeId,
            needCommonAttribute: false,
            attributeName: equipmentTypeName,
            sortColumn: sortField,
            sortOrder: sortOrder,
            search: keyWord,
            selectedIds: getSelectedAttributeIds(),
          },
          exportType: "Equipment Type Attributes Report"
        };
        dispatch(onExportReport(reportParameters, "Equipment Type Attributes"));
        break;
      }
      case CUSTOMIZATION_ATTRIBUTES_TYPE.EQUIPMENT_TYPE_ATTRIBUTES_VALUES: {
        const reportParameters = {
          exportParameters: {
            uniqueDataFieldId: equipmentTypeId,
            uniqueAttributeId: attributeId,
            attributeName: attributeName,
            equipmentTypeName: equipmentTypeName,
            selectedIds: getSelectedValuesIds(),
            needCommonAttribute: false,
            sortColumn: sortField,
            sortOrder: sortOrder,
            search: keyWord
          },
          exportType: "Equipment Type Attributes Values Report"
        };
        dispatch(onExportReport(reportParameters, "Equipment Type Attribute Values"));
        break;
      }
      default: {
        const reportParameters = {
          exportParameters: {
            uniqueEquipmentClassId: getSelectedAttributeIds(),
            needCommonAttribute: "",
            sortColumn: sortField,
            sortOrder: sortOrder,
            search: keyWord
          },
          exportType: "Object Attribute Grid Report"
        };
        dispatch(onExportReport(reportParameters, "Common Attributes"));
        break;
      }
    }
  };


  const commonAttributeFieldValueHandler = (rowsSelected: any) => {
    if (rowsSelected && rowsSelected.length) {
      if (rowsSelected.length === 1) {
        if (rowsSelected[0].isValidated === 'True') {
          setShowAttributesFieldValues(true);
        } else if (rowsSelected[0].isValidated === 'False') {
          setMessage('Not a Validated Field');
          setDisplayInfoModal(true);
        }
      } else {
        setMessage('This operation can be performed only one row at a time');
        setDisplayInfoModal(true);
      }
    }
  }

  const equipmentFieldValueHandler = (rowsSelected: any) => {
    if (rowsSelected && rowsSelected.length) {
      if (rowsSelected.length === 1) {
        if (rowsSelected[0].isValidated === 'True') {
          setShowEquipmentFieldValues(true);
        } else if (rowsSelected[0].isValidated === 'False') {
          setMessage('Not a Validated Field');
          setDisplayInfoModal(true);
          
        }
      } else {
        setMessage('This operation can be performed only one row at a time');
        setDisplayInfoModal(true);
      }
    }
  }

  const localSearchFirstItem = (selectedItem) => {
		setSelectedRows(selectedItem);
	};

  const getMainAttributesContent = () => {
    switch (selectedTab) {
      case CUSTOMIZATION_ATTRIBUTES_TYPE.COMMON_ATTRIBUTES: {
        return (
          <>
            <div className="common-attributes-table">
              {showGrid && (
                <Table
                  rows={commonAttributeList}
                  cols={CUSTOMIZATION_ATTRIBUTES_COLS.COMMON_ATTRIBUTES_COLS}
                  paginator
                  pageIndex={pageIndex}
                  dataKeyId="uniqueAttributeId"
                  title={`Common Attributes`}
                  pageDetails={commonAttributesPagination}
                  searchText="Search"
                  onPageChanged={onPageChanged}
                  getSelectedRowData={handleRowSelect}
                  onSortData={onSort}
                  onGlobalSearch = {onGlobalSearch}
                  onAddClick={() => setShowAddCommonAttribute(true)}
                  onEditClick={editIconClick}
                  onDeleteClick={deleteIconClick}
                  showroweditor={false}
                  onDownloadClick={(e) => downloadRef.current?.toggle(e)}
                  localSearchFirstItem={localSearchFirstItem}
                  settingListItems={[
                    {
                      id: 1,
                      name: 'Field Values',
                      onClick: () => { commonAttributeFieldValueHandler(selectedRows); }
                    }
                  ]}
                  defaultSelectedRows={commonAttributeList.length > 0 ? [commonAttributeList[0]] : []}
                />
              )}
              <InformationBox
                showInfoModal={displayInfoModal}
                setShowInfoModal={setDisplayInfoModal}
                message={message}
              />
              <ConfirmationBox
                showConfirmModal={displayConfirmationModal}
                setShowConfirmModal={setDisplayConfirmationModal}
                confirmAction={onDeleteConfirm}
                message={message}
              />
            </div>

            {showAddCommonAttribute ? (
              <AddCommonAttributes
                showModal={showAddCommonAttribute}
                setShowModal={setShowAddCommonAttribute}
                onAddCommonAttributeClick={onAddCommonAttributeClick}
              />
              ) : null
            }

            {showEditCommonAttribute ? (
              <EditCommonAttributes
                showModal={showEditCommonAttribute}
                setShowModal={setShowEditCommonAttribute}
                selectedRows={selectedRows}
                onEditCommonAttributeClick={onEditCommonAttributeClick}
              />
              ) : null
            }

            {showAttributesFieldValues ? (
              <AttributesFieldValues
                showModal={showAttributesFieldValues}
                setShowModal={setShowAttributesFieldValues}
                rowsSelected={selectedRows}
              />
              ) : null
            }

            <PopOver ref={downloadRef}>
              <ul>
                <li onClick={onReportAllClick}>Report All</li>
                <li onClick={onReportHighlightedClick}>Report Highlighted</li>
                <li onClick={onExportAll}>Export All</li>
					      <li onClick={onExportHighlightedClick}>Export Highlighted</li>
              </ul>
            </PopOver>
            
            {showReportModal ? (
              <CustomizationReport showModal={showReportModal}
                setShowModal={setShowReportModal}
                reportFlag={reportFlag}
                selectedTab={selectedTab}
                selectedRows={selectedRows}
                sortByColumns={CUSTOMIZATION_ATTRIBUTES_VALUE_COLS.COMMON_ATTRIBUTES_VALUE_COLS}
                searchKeyword={keyWord}
                sortColumn={sortField}
                sortOrder={sortOrder} />
            ) : null}
          </>
        );
      }
      case CUSTOMIZATION_ATTRIBUTES_TYPE.COMMON_ATTRIBUTES_VALUES: {
        return (
          <>
            <div className="common-attributes-value-table">
              {showGrid && (
                <Table
                  rows={commonAttributeValuesData}
                  cols={CUSTOMIZATION_ATTRIBUTES_VALUE_COLS.COMMON_ATTRIBUTES_VALUE_COLS}
                  title={`Common Attribute Values`}
                  paginator
                  pageIndex={pageIndex}
                  dataKeyId="dataFieldLookUpId"
                  pageDetails={commonAttributeValuesPagination}
                  onPageChanged={onPageChanged}
                  searchText="Search"
                  onSortData={onSort}
                  onGlobalSearch = {onGlobalSearch}
                  showCommonAttributeDropDown
                  getSelectedRowData={handleRowSelect}
                  attributeLookup={commonAttributeLookup}
                  onHeaderDropdownChange={onHeaderDropdownChange}
                  onAddClick={() => setShowAddCommonAttributeValue(true)}
                  onEditClick={editIconClick}
                  onDeleteClick={deleteIconClick}
                  showroweditor={false}
                  disableAddIcon={disableAddIcon}
                  onDownloadClick={(e) => downloadRef.current?.toggle(e)}
                  localSearchFirstItem={localSearchFirstItem}
                  defaultSelectedRows={commonAttributeValuesData.length > 0 ? [commonAttributeValuesData[0]] : []}
                />
              )}
              <InformationBox
                showInfoModal={displayInfoModal}
                setShowInfoModal={setDisplayInfoModal}
                message={message}
                okOnClick={okInfoOnClick}
              />
              <ConfirmationBox
                showConfirmModal={displayConfirmationModal}
                setShowConfirmModal={setDisplayConfirmationModal}
                confirmAction={onDeleteConfirm}
                message={message}
              />
            </div>

            {showEditCommonAttributeValue ? (
              <EditCommonAttibutesValues
                showModal={showEditCommonAttributeValue}
                selectedRows={selectedRows}
                commonAttributeId={attributeId}
                commonAttributeName={attributeName}
                commonAttributeType={commonAttributeType}
                maxLength={maxLength}
                setShowModal={setShowEditCommonAttributeValue}
                onEditCommonAttributeValueClick={
                  onEditCommonAttributeValueClick
                }
              />
            ) : null}

            {showAddCommonAttributeValue ? (
              <AddCommonAttibutesValues
                showModal={showAddCommonAttributeValue}
                setShowModal={setShowAddCommonAttributeValue}
                commonAttributeName={attributeName}
                commonAttributeType={commonAttributeType}
                maxLength={maxLength}
                onAddCommonAttributeValueClick={onAddCommonAttributeValueClick}
              />
            ) : null}
            <PopOver ref={downloadRef}>
              <ul>
                <li onClick={onReportAllClick}>Report All</li>
                <li onClick={onReportHighlightedClick}>Report Highlighted</li>
                <li onClick={onExportAll}>Export All</li>
					      <li onClick={onExportHighlightedClick}>Export Highlighted</li>
              </ul>
            </PopOver>
            {showReportModal ? (
              <CustomizationReport showModal={showReportModal}
                setShowModal={setShowReportModal}
                reportFlag={reportFlag}
                selectedRows={selectedRows}
                attributeId={attributeId}
                attributeName={attributeName}
                selectedTab={selectedTab}
                sortByColumns={CUSTOMIZATION_ATTRIBUTES_VALUE_COLS.COMMON_ATTRIBUTES_VALUE_COLS}
                searchKeyword={keyWord} 
                sortColumn={sortField}
                sortOrder={sortOrder} />
            ) : null}
          </>
        );
      }
      case CUSTOMIZATION_ATTRIBUTES_TYPE.EQUIPMENT_TYPE_ATTRIBUTES: {
        return (
          <>
            <div className="equipment-type-table">
              {showGrid && (
                <Table
                  rows={equipmentTypeAttributesData}
                  cols={CUSTOMIZATION_EQUIPMENT_TYPE_COLS.EQUIPMENT_TYPE_COLS}
                  title={`Equipment Type Attributes`}
                  paginator
                  pageIndex={pageIndex}
                  dataKeyId="uniqueAttributeId"
                  pageDetails={equipmentTypeAttributesPagination}
                  onPageChanged={onPageChanged}
                  searchText="Search"
                  showEquipmentDropdown
                  equipmentTypeLookup={equipmentTypeLookup}
                  onSortData={onSort}
                  onGlobalSearch = {onGlobalSearch}
                  getSelectedRowData={handleRowSelect}
                  onHeaderDropdownChange={onHeaderDropdownChange}
                  onAddClick={() => setShowAddEquipmentTypeAttributes(true)}
                  onEditClick={editIconClick}
                  onDeleteClick={deleteIconClick}
                  showroweditor={false}
                  disableAddIcon={disableAddIcon}
                  onDownloadClick={(e) => downloadRef.current?.toggle(e)}
                  localSearchFirstItem={localSearchFirstItem}
                  settingListItems={[
                    {
                      id: 1,
                      name: 'Field Values',
                      onClick: () => { equipmentFieldValueHandler(selectedRows); }
                    }
                  ]}
                  defaultSelectedRows={equipmentTypeAttributesData.length > 0 ? [equipmentTypeAttributesData[0]] : []}
                />
              )}
              <InformationBox
                showInfoModal={displayInfoModal}
                setShowInfoModal={setDisplayInfoModal}
                message={message}
              />
              <ConfirmationBox
                showConfirmModal={displayConfirmationModal}
                setShowConfirmModal={setDisplayConfirmationModal}
                confirmAction={onDeleteConfirm}
                message={message}
              />
            </div>


            {showAddEquipmentTypeAttributes ? (
              <AddEquipmentTypeAttribute
                showModal={showAddEquipmentTypeAttributes}
                setShowModal={setShowAddEquipmentTypeAttributes}
                onAddEquipmentTypeAttributeClick={
                  onAddEquipmentTypeAttributeClick
                }
              />
            ) : null}

            {showEditEquipmentTypeAttribute ? (
              <EditEquipmentTypeAttribute
                showModal={showEditEquipmentTypeAttribute}
                setShowModal={setShowEditEquipmentTypeAttribute}
                selectedRows={selectedRows}
                onEditEquipmentTypeAttributeClick={onEditEquipmentTypeAttributeClick}
              />
            ) : null}

            {showEquipmentFieldValues ? (
              <EquipmentTypeAttributesFieldValues
                showModal={showEquipmentFieldValues}
                setShowModal={setShowEquipmentFieldValues}
                equipmentTypeId={equipmentTypeId}
                rowsSelected={selectedRows}
                equipmentTypeName={equipmentTypeName}
              />
            ) : null}

            <PopOver ref={downloadRef}>
              <ul>
                <li onClick={onReportAllClick}>Report All</li>
                <li onClick={onReportHighlightedClick}>Report Highlighted</li>
                <li onClick={onExportAll}>Export All</li>
					      <li onClick={onExportHighlightedClick}>Export Highlighted</li>
              </ul>
            </PopOver>
            {showReportModal ? (
              <CustomizationReport showModal={showReportModal}
                setShowModal={setShowReportModal}
                reportFlag={reportFlag}
                selectedRows={selectedRows}
                equipmentTypeId={equipmentTypeId}
                equipmentTypeName={equipmentTypeName}
                selectedTab={selectedTab}
                sortByColumns={CUSTOMIZATION_EQUIPMENT_TYPE_COLS.EQUIPMENT_TYPE_COLS}
                searchKeyword={keyWord}
                sortColumn={sortField}
                sortOrder={sortOrder} />
            ) : null}

          </>
        );
      }
      case CUSTOMIZATION_ATTRIBUTES_TYPE.EQUIPMENT_TYPE_ATTRIBUTES_VALUES: {
        return (
          <>
            <div className="Equipment-attributes-value-table">
              {showGrid && (
                <Table
                  rows={equipmentTypeAttributeValueDetails}
                  cols={CUSTOMIZATION_EQUIPMENT_TYPE_ATTRIBUTES_VALUES_COLS.EQUIPMENT_TYPE_ATTRIBUTES_VALUES_COLS}
                  title={`Equipment Type Attribute Values`}
                  paginator
                  pageIndex={pageIndex}
                  dataKeyId="dataFieldLookUpId"
                  pageDetails={equipmentTypeAttributeValuesPagination}
                  onPageChanged={onPageChanged}
                  searchText="Search"
                  onSortData={onSort}
                  onGlobalSearch = {onGlobalSearch}
                  showAttributeDropdown
                  showEquipmentDropdown
                  equipmentTypeLookup={equipmentTypeNameLookup}
                  attributeLookup={equipmentAttributeNameLookup}
                  getSelectedRowData={handleRowSelect}
                  onHeaderDropdownChange={onHeaderDropdownChange}
                  onHeaderAttributeNameChange={onHeaderAttributeNameChange}
                  onAddClick={() => setShowAddEquipmentTypeAttributeValues(true)}
                  onEditClick={editIconClick}
                  onDeleteClick={deleteIconClick}
                  showroweditor={false}
                  disableAddIcon={disableAddIcon}
                  onDownloadClick={(e) => downloadRef.current?.toggle(e)}
                  localSearchFirstItem={localSearchFirstItem}
                  defaultSelectedRows={equipmentTypeAttributeValueDetails.length > 0 ? [equipmentTypeAttributeValueDetails[0]] : []}
                />
              )}
              <InformationBox
                showInfoModal={displayInfoModal}
                setShowInfoModal={setDisplayInfoModal}
                message={message}
                okOnClick={okInfoOnClick}
              />
              <ConfirmationBox
                showConfirmModal={displayConfirmationModal}
                setShowConfirmModal={setDisplayConfirmationModal}
                confirmAction={onDeleteConfirm}
                message={message}
              />
            </div>

            {showEditEquipmentTypeAttributeValue ? (
              <EditEquipmentTypeAttributeValues
                showModal={showEditEquipmentTypeAttributeValue}
                selectedRows={selectedRows}
                equipmentAttributeId={attributeId}
                equipmentAttributeName={attributeName}
                equipmentAttributeType={equipmentAttributeType}
                maxLength={maxLength}
                setShowModal={setShowEditEquipmentTypeAttributeValue}
                onEditEquipmentTypeAttributeValueClick={onEditEquipmentTypeAttributeValueClick}
              />
            ) : null}

            {showAddEquipmentTypeAttributeValues ? (
              <AddEquipmentTypeAttributeValues
                showModal={showAddEquipmentTypeAttributeValues}
                setShowModal={setShowAddEquipmentTypeAttributeValues}
                equipmentAttributeName={attributeName}
                equipmentAttributeType={equipmentAttributeType}
                maxLength={maxLength}
                onAddEquipmentTypeAttributeValueClick={onAddEquipmentTypeAttributeValueClick}
              />
            ) : null}

            <PopOver ref={downloadRef}>
              <ul>
                <li onClick={onReportAllClick}>Report All</li>
                <li onClick={onReportHighlightedClick}>Report Highlighted</li>
                <li onClick={onExportAll}>Export All</li>
					      <li onClick={onExportHighlightedClick}>Export Highlighted</li>
              </ul>
            </PopOver>
            {showReportModal ? (
              <CustomizationReport showModal={showReportModal}
                setShowModal={setShowReportModal}
                reportFlag={reportFlag}
                selectedRows={selectedRows}
                equipmentTypeId={selectedEquipmentid}
                attributeId={attributeId}
                attributeName={attributeName}
                equipmentTypeName={equipmentTypeName}
                selectedTab={selectedTab}
                sortByColumns={CUSTOMIZATION_EQUIPMENT_TYPE_ATTRIBUTES_VALUES_COLS.EQUIPMENT_TYPE_ATTRIBUTES_VALUES_COLS}
                searchKeyword={keyWord} 
                sortColumn={sortField}
                sortOrder={sortOrder} />
            ) : null}
          </>
        );
      }
      default: {
        return <h4>Common Attributes</h4>;
      }
    }
  };
  return (
    <div className="common-attributes-list">
      <section className="d-flex align-items-center">
        <Breadcrumb
          title="Customization"
          classNames="report-header"
          handleGoBackClick={() => navigate(`/${APP_ROUTES.MANAGE_VIEW}`)}
        />
        <div className="breadcrumb-right-arrow ml-3 mr-3">
          <BsArrowRight className="icon" />
        </div>
        <div className="breadcrumb-sub-page-title">
          <span>Attributes</span>
        </div>
      </section>
      <div className="table-container d-flex">
        <section className="common-attributes-filter expanded d-flex flex-column align-items-center">
          <Filters
            getSelectedReportsTab={handleTabChange}
            attributesFilter
            filterExpanded
          />
        </section>
        <section className="common-attributes-table expanded">
          {getMainAttributesContent()}
        </section>
      </div>
    </div>
  );
};
export default CustomizationAttributes;
