import React from 'react'

const ReportsInspectionTasksDocuments = () => {
  return (
    <>
      <h4 className="doc-title mb-2">Inspection Tasks</h4>

      <p>
        This report shows the list of Inspection Tasks with the Customer Number, 
        Customer Name, Site Name, Site Number, Inspection Task, Contract Name, 
        Contract Number, Inspection Task Start Date, Inspection Task End Date and Lead Technician details. 
      </p>

      <p>
        User can filter the Tasks based on Planned Start and End Date. 
        Select the Planned Start Date and End Date in the report header and 
        click Apply button to view the report. 
        The report can be customized by selecting Customize button.
      </p>
    </>

  );
}
export default ReportsInspectionTasksDocuments;