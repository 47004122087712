import React from "react";
import { Button } from "primereact/button";
import { BsFillPencilFill, BsPaperclip, BsPlusLg, BsTrashFill } from "react-icons/bs";
import { FaCog, FaDownload } from "react-icons/fa";

const InspectionSystemsDocument = () => {
  return (
    <>
      <h4 className="doc-title mb-2">
        Systems
      </h4>
      <p>
        System is a logical grouping of one or more Equipment Groups. Inspection can be done
        for all equipment connected to these Equipment Groups as a single inspection task.
        This option is used to define Systems in a Site.  A System can be associated with multiple
        inspection tasks, but only one inspection task can be active for a system at a time.
        System can be of different Equipment Group types based on whether it has a Equipment Group
        of type MXL/XLS/Desigo FS20/Desigo FV20/Desigo Modular/Cerberus Pro Panels or Other types.
      </p>

      <p>
        For MXL/XLS/Desigo FS20/Desigo FV20/Desigo Modular system/Cerberus Pro Panels,
        its configuration file has to be uploaded before activating the inspection task.
        The configuration file is an XML file containing the details of all the equipment in the
        System. The Inspection process of these Systems will be an automated process.
        The device to be tested will be triggered, and the system will send Test results,
        which will be received by TAP Bridge and sent to TechAdvance<sup>+</sup> and TAP App.
      </p>

      <p>
        Systems with Equipment Group Type other than MXL/XLS/Desigo FS20/Desigo FV20/Desigo
        Modular/Cerberus Pro Panels have no configuration file or TAP Bridge interaction for
        inspection. The Equipment under such Systems has to be inspected manually. The connectivity
        of the Equipment Groups and Modules with such Systems has to be defined manually using the
        Connectivity option in <span className="doc-subtitle">Inspection: Inspection Tasks:
          Inspection Details: Equipment </span> page while adding Devices.&nbsp;
      </p>

      <p className="doc-subtitle"><b>Add: </b></p>

      <p>
        A new System can be added by clicking <span className="doc-subtitle">Add</span>{" "}
        <Button className="doc-button-icon">
          <BsPlusLg className="icon" title="Add" />
        </Button>
        {" "} icon. Enter the System Name, select the System Type from the combo box and
        Preferred Language. On selecting System Type as "MXL" or "Desigo FS20" or "Desigo FV20",
        the Networked radio buttons will be enabled with Yes/No options and Preferred Language
        radio buttons will be enabled with Base/Alternate options. On selecting Equipment Group
        Type as "XLS" or "Desigo Modular", the Networked radio buttons will be enabled with Yes / No
        options, "HNET; for single node XLS, or a multi-node XLS with only one GPMI" radio button and
        "XNET; for a multi-node XLS with a least two GPMI with control, or a Management Station
        (NCC or DCC) and Preferred Language radio buttons will be enabled with Base / Alternate
        options. On selecting Equipment Group Type other than MXL/XLS/Desigo FS20/Desigo FV20/Desigo
        Modular/Cerberus Pro Panels”, the radio buttons in Networked and Preferred Language fields
        will be disabled. Select the required values and click Save button.
      </p>

      <p className="doc-subtitle"><b>Edit: </b></p>

      <p>
        Edit a System by selecting it and clicking the 
        <span className="doc-subtitle"> Edit </span>
        <Button className="doc-button-icon">
          <BsFillPencilFill className="icon" title="Edit" />
        </Button> 
        {' '}icon. 
        Make required modifications and click Save Changes button to save the
        changes. System data can be edited only after closing it's Inspection Task. If Equipment
        exists in the system, System Type cannot be changed. User has to delete inspection task for
        changing Equipment Group Type of the System. If a System with Active Inspection Task is
        selected for Edit, a confirmation message "System cannot be edited as Active Task exists for
        the selected System. Do you want to close the Active Task?" with Yes and No button will be
        displayed. On clicking Yes, System can be edited. After editing the System, User can
        re-activate the task by clicking Yes to the confirmation message to re-activate the closed
        Task.
      </p>

      <p>
        <b> <span className="doc-subtitle">Inline Edit:</span></b>
      </p>

      <p>
        Inline edit option enables user to edit a System by clicking the
        <span className="doc-subtitle"> Edit </span>
        <Button className="doc-button-icon">
          <i className="pi pi-pencil icon" title="Inline Edit" />
        </Button>
        {" "}  icon in the left hand side of the required row. On clicking
        <span className="doc-subtitle"> Edit </span>{" "}
        <Button className="doc-button-icon">
          <i className="pi pi-pencil icon" title="Inline Edit" />
        </Button>
        {" "}icon, the selected row will be displayed as editable and Edit icon will be replaced with 
        <span className="doc-subtitle"> Save </span>
        <Button className="doc-button-icon">
          <i className="pi pi-save icon" title="Save" />
        </Button>
        {" "} and 
        <span className="doc-subtitle"> Cancel </span>
        <Button className="doc-button-icon">{" "}
          <i className="pi pi-times icon" title="Cancel" />{" "}
        </Button>
        {" "}  icon. Do the necessary changes in the required fields and click on
        <span className="doc-subtitle"> Save </span>{" "}
        <Button className="doc-button-icon">
          <i className="pi pi-save icon" title="Save" />
        </Button>
        {" "} icon. 

        To cancel the Inline edit operation click on
        <span className="doc-subtitle"> Cancel </span>
        <Button className="doc-button-icon">{" "}
          <i className="pi pi-times icon" title="Cancel" />
        </Button>
        {" "}  icon.
      </p>


      <p className="doc-subtitle"><b>Delete: System</b></p>

      <p>
        Delete a System by selecting it and clicking the <span className="doc-subtitle">Delete
        </span>{" "}
        <Button className="doc-button-icon">
          <BsTrashFill className="icon" title="Delete" />
        </Button>
        {" "}icon. A System that is associated with an inspection task cannot be deleted.
      </p>


      <p className="doc-subtitle"><b>Delete: System Equipment Groups</b></p>

      <p>
        This option is used to delete unused Equipment Groups of a System. Equipment Groups linked
        with an Active Inspection Task cannot be deleted. This option is available only for Super
        Administrators.
      </p>

      <p className="doc-subtitle"><b>Delete: Unlinked Equipment Groups</b></p>

      <p>
        This option is used to delete unlinked Equipment Groups of a System. Systems with Equipment
        Group Type other than MXL/XLS/Desigo FS20/Desigo FV20/Desigo Modular/Cerberus pro Panels can
        only be able to Unlink.  This option is available only for Super Administrators.
      </p>

      <p className="doc-subtitle"><b>Upload XML: </b></p>

      <p>
        XML File can be added to a selected System by selecting 
        <span className="doc-subtitle"> Upload XML File </span> 
        option under{" "} 
        <Button className="doc-button-icon">
          <BsPaperclip className="icon" title="Attachments" />
        </Button>
        {" "} icon. 
        Upload XML File options can be used to upload XML File of the System.
        XML files contain all the data of the Equipment Groups, Modules and Equipment which are
        connected and can communicate with the Equipment Group. User can upload a new XML File by
        delete an existing one. XML file cannot be replaced if the selected System is active for an
        Inspection. A System can have only one XML File. If wrong XML File is uploaded,
        TechAdvance+ should display a validation message.
      </p>

      <p>
        If a new XML file is uploaded against an existing System with Active Task
        (which is re-activated after uploading the new XML file), the application starts to parse
        the new XML and the Equipment will be imported to the server. The progress of XML importing
        will be shown in the Inspection Details page.
      </p>

      <p className="doc-subtitle"><b>Upload Config File: </b></p>

      <p>
        Configuration File can be added to a selected System by selecting
        <span className="doc-subtitle"> Upload Config File </span> option under{" "}
        <Button className="doc-button-icon">
          <BsPaperclip className="icon" title="Attachments" />
        </Button>
        {" "}icon. 
        Upload Configuration File options can be used to upload Configuration
        File of the System. Configuration files contain all the data of the Equipment Groups,
        Modules and Equipment which are connected and can communicate with the Equipment Group.
        User can upload a new Configuration File by delete an existing one. Configuration file
        cannot be replaced if the selected System is active for an Inspection.
        A System can have only one Configuration File.
      </p>

      <p>
        <i>Note: </i>
        Only ZIP files can be uploaded as Configuration File.
      </p>

      <p className="doc-subtitle"><b>Download: </b>{" "}
        <Button className="doc-button-icon">
          <FaDownload className="icon" title="Download" />
        </Button>
      </p>

      <p>
        This option lists Report All, Report Highlighted, Export All, Export Highlighted,
        Group Highlighted, Download XML and Download Config File.
      </p>

      <p>
        <span className="doc-subtitle">Report All: </span>
      </p>

      <p>
        This option is for generating a report with all the records in the data grid.
        The report can be saved to PDF, Text, Rich Text or Excel format by selecting the file
        format from the list under Save option, and then clicking OK button after selecting
        the required options from the Export Settings window.
      </p>

      <p>
        <span className="doc-subtitle">Report Highlighted: </span>
      </p>

      <p>
        This option is for generating a report with only selected records in the grid.
        Select the desired rows by holding down the control key and clicking on each of
        the records to be selected. Users can also select a set of rows using the shift key.
        The selected records will be highlighted. Click on the 
        <span className="doc-subtitle"> Report Highlighted </span> 
        option in the 
        <span className="doc-subtitle"> Download </span>
        <Button className="doc-button-icon">
          <FaDownload className="icon" title="Download" />
        </Button>
        {" "} menu to generate the report. 
        The report will be created with the highlighted records only. 
        The report can be saved to PDF, Text, Rich Text or Excel format by selecting
        the file format from the list under Save option, and then clicking OK button after
        selecting the required options from the Export Settings window.
      </p>

      <p className="doc-subtitle"><b>Download XML: </b></p>

      <p>This option is to download the XML file uploaded for a System.</p>

      <p className="doc-subtitle"><b>Download Config File: </b></p>

      <p>
        This option is to download the Configuration file uploaded for a System.
      </p>

      <p>
        <i>Note: </i>Only Super Administrators can download XML File and Configuration
        File using Download XML/Configuration File options.
      </p>

      <p className="doc-subtitle">
        <b>More: </b>{" "}
        <Button className="doc-button-icon">
          <FaCog className="icon" title="More" />
        </Button>{" "}
      </p>

      <p>
        This option lists Close, Activate, Equipment Groups, Master Data, Proven Outcomes
        and Display Settings option.
      </p>

      <p><span className="doc-subtitle">Close:</span> </p>

      <p>
        This option is used to close a System with 'Active' status. The status of the
        selected System will change to 'Closed' status. Close a System by selecting it and
        clicking the <span className="doc-subtitle">Close</span> option from
        <span className="doc-subtitle"> More: </span>{" "}
        <Button className="doc-button-icon">
          <FaCog className="icon" title="More" />
        </Button>
        {" "}icon. 
        On closing a system all the associated Inspection Tasks under
        that System will be closed automatically. If any Inspection Tasks of the selected
        System is in progress then while closing the System it will show a message
        'Are you sure you want to close the selected System and it's Inspection Task?' ,
        and on clicking Yes to the message the selected System will be closed. Inspection
        Tasks under a closed system cannot be edited/activated again, without activating
        the System.
      </p>

      <p>
        <span className="doc-subtitle">Activate:</span> 
      </p>

      <p>
        Activate a System by selecting it and clicking the 
        <span className="doc-subtitle"> Activate </span> 
        option from 
        <span className="doc-subtitle"> More </span>
        <Button className="doc-button-icon">
          <FaCog className="icon" title="More" />
        </Button>
        {" "}icon. 
        This option is used to activate a System that is in 'Closed' status.
      </p>


      <p><span className="doc-subtitle">Equipment Groups: </span> </p>

      <p>
        Fire Alarm Equipment Groups manage and control FLS equipment. Equipment Groups are
        associated with a System. This option is used to update the Equipment Group list in a System.
        Equipment Groups can be Siemens MXL/XLS/Desigo Modular/Desigo FS20/Desigo FV20/Cerberus
        Pro Equipment Groups or third party Equipment Groups. MXL/XLS/Desigo Modular/
        Desigo FS20/Desigo FV20/Cerberus Pro Equipment Groups can be controlled using
        the Siemens TAP Bridge software. Third party Equipment Groups can be controlled
        manually by an onsite technician only.
      </p>

      <p>
        On selecting this option from 
        <span className="doc-subtitle"> More </span>
        <Button className="doc-button-icon">
          <FaCog className="icon" title="More" />
        </Button>
        {" "}icon, the Equipment Groups that are automatically linked to the System
        while receiving the equipment list from the TAP Bridge will be listed. The manually
        added Equipment Groups, if any, will also be listed here. Equipment Groups of a System
        cannot be modified if the inspection task has started for the System. The user can
        select the required Equipment Groups and click Save Changes button to save the changes.
      </p>


      <p>
        <span className="doc-subtitle">Master Data:</span>
      </p>

      <p>
        This option is used to view the Master Equipment Data which is the collection of all
        Equipment existing under different Inspection Task of a selected System. Equipment
        deleted from any specific Inspection Task of this selected System will not be deleted
        from Master Equipment Data unless there is only one Inspection Task in the System.
        On selecting this option, the latest updated details of Master Equipment Data
        associated with the selected System will be listed Equipment Group wise. A drop down
        ''Equipment Type' to select the Equipment Type such as AHU, Air Sampling Systems,
        Devices, Doors, Elevators, Emergency Communications, Emergency Lightings, Modules,
        Fire Pumps, Kitchen Extinguishers, NAC Panels, Sprinklers, Water Based Equipments
        etc is also provided, so that the Equipment list will be filtered based on the
        selected Equipment Type. The first Equipment Group will be selected by default
        and Equipment of that Equipment Group will be displayed in the grid
        with &#39;All&#39; option selected by default in Equipment to Display drop down.
        &nbsp;  The Attachments icon allows the user to View/Download the attachments
        associated to each equipment.
      </p>

      <p>
        <span className="doc-subtitle">Proven Outcomes:</span>
      </p>

      <p>
        When user click on <span className="doc-subtitle"> Proven Outcomes</span>,
        internally an excel will be created and send email to the logged in user.
        This excel contains all the details of last closed task of the selcted System.
        The generated excel file will have a file name, which will be in the format
        ‘ProvenOutcomes_[SystemName]_[FileGeneratedDateTime]’ in YYYYMMDDHH24MMSS’ format.
      </p>

      <p>The excel file may contain 6 sheets of information such as </p> 
      <ol className="decimal-list">
        <li>
          <span className="doc-subtitle">Source:</span> This is the first sheet of excel file
          which  contains the basic information about the task in which it belongs such as Customer
          Name, Customer Number, Site Name, Site Number, Contract Name, Contract Number and
          System Name.<br />
        </li>
        <li>
          <span className="doc-subtitle">System:</span> The system sheet shows the type
          of equipment and quantity of each type of the last closed inspection task of the
          system <br />
        </li>
        <li>
          <span className="doc-subtitle">Deficiencies:</span> This excel sheet Deficiencies
          shows all devices which are failed, i.e, all equipment of last closed task of the
          system will be listed here <br />
        </li>
        <li>
          <span className="doc-subtitle">Sensitivities:</span>  This excel sheet Sensitivities
          shows only the 10 smoke devices with highest sensitivity values<br />
        </li>
        <li>
          <span className="doc-subtitle">Batteries:</span> This excel sheet Batteries shows
          all NAC Equipment Groups in the last closed task. It has fields like location, address
          and Date Installed <br />
        </li>
        <li>
          <span className="doc-subtitle">Equipment Group(s)Data:</span> This excel sheet
          shows all Equipment Groups in the last closed task of the selected system as Equipment
          Group(s)Data. <br />
        </li>
      </ol>

      <br />

      <p><span className="doc-subtitle">Display Settings:</span></p>

      <p>
        This option allows the user to change the visibility of the data fields displayed in
        the data grid. On selecting this option, all the available data fields are displayed
        in a window. Display settings can be modified by selecting the required fields from the list.
        The grid settings modified through this option will not be saved and will be effective only
        for the current instance of the data grid.
      </p>

    </>
  );
}
export default InspectionSystemsDocument;
