import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";

import ReportViewer from "components/common/Report/ReportViewer";
import { useAppSelector } from "redux/hooks";
import { useDispatch } from "react-redux";
import { createReportPreviewSession, setReportPreviewSession } from "redux/pages/Administration/Site/siteSlice";

interface Props {
    showModal: boolean;
    setShowModal: (state: boolean) => void;
    reportFlag: string;
    selectedRows: any;
    sortByColumns: any;
    searchText: string;
    active: boolean;
    closed: boolean;
    completed: boolean;
    dataFrom: any;
    sortField: any,
    sortOrder: any,
}

export const InspectionTaskReport: React.FC<Props> = ({
    showModal = false,
    setShowModal = () => { },
    reportFlag = '',
    selectedRows = [],
    sortByColumns = [],
    searchText = '',
    active = true,
    closed = true,
    completed = true,
    dataFrom = '',
    sortField = '',
    sortOrder = '',
}) => {
    const [reportId, setReportId] = useState<any>(null);
    const reportPreviewSession = useAppSelector((state) => state.site.reportPreviewSession);
    const isCustomerAll = useAppSelector((state) => state.inspectionTaskFilter.isCustomerAll);
    const isContractAll = useAppSelector((state) => state.inspectionTaskFilter.isContractAll);
    const selectedCustomerId = useAppSelector((state) => state.inspectionTaskFilter.selectedCustomerId);
    const selectedContractId = useAppSelector((state) => state.inspectionTaskFilter.selectedContractId);
    const dispatch = useDispatch<any>();

    const getSelectedInspectionTaskIds = () => {
        let params: any = [];
        selectedRows.map(item => {
            params.push(item.uniqueInspectionTaskId);
        });
        return params;
    };

    const getContractStatus = () => {
        let  contractFlag = true
        if(!isCustomerAll){
            if(isContractAll){
                contractFlag = true
            } else {
                contractFlag = false
            }
        };
        return contractFlag;
    };

    useEffect(() => {
        let params;
        if (reportFlag === 'all') {
            params = "";
        } else {
            params = getSelectedInspectionTaskIds();
        }
        const reportParameters = {
            reportParameters: {
                uniqueCustomerId: isCustomerAll ? '' : selectedCustomerId,
                uniqueContractId: getContractStatus() ? '' : selectedContractId,
                uniqueInspectionTaskId: "",
                statusId: 1,
                recentOrAll: dataFrom,
                uniqueSystemId: "",
                allCustomers: isCustomerAll,
                allContracts: getContractStatus(),
                selectedIds: params,
                search: searchText,
                active: active,
                closed: closed,
                completed: completed,
                sortColumn: sortField,
                sortOrder: sortOrder,
            },
            reportType: "Inspection Task Grid Report"
        };
        dispatch(createReportPreviewSession(reportParameters));
    }, []);

    useEffect(() => {
        if (reportPreviewSession) {
            setReportId(reportPreviewSession.previewSessionId);
        }
    }, [reportPreviewSession])

    return (
        <>
            <Dialog
                header="Report"
                visible={showModal}
                onHide={() => {
                    dispatch(setReportPreviewSession(""));
                    setShowModal(false);
                }}
                className="display-settings report selectsite"
                style={{ width: "90vw", height: "600px" }}
            >
                <div className="report-export-main d-flex mb-3">
                    <div className="report-buttons-main d-flex">
                    </div>
                </div>

                <ReportViewer reportId={reportId} />
            </Dialog>
        </>
    );
};

export default InspectionTaskReport;
