import React from 'react'

const ReportsFloorsDocument = () => {
  return (
    <>
      <h4 className="doc-title mb-2">Reports - Floors</h4>
      <p>
        This report shows the list of all floors with their Site Name, Building name and Floor name.
      </p>
    </>
  );
}
export default ReportsFloorsDocument;
