import React, { useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";

import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";

import { BsExclamationCircleFill } from "react-icons/bs";

import { useDispatch } from "react-redux";
import { getReportGroupDetails } from "redux/pages/Manage/InspectionSettings/Reports/ReportGroupSlice";
import { useAppSelector } from "redux/hooks";

import AddModal from "components/Table/AddModal/AddModal";

interface Props {
  showModal: boolean;
  setShowModal: (state: boolean) => void;
  seletedRows: any;
  onEditReportGroup: (data: any) => void;
}

type FormData = {
  reportGroupName: string;
};

export const EditReportGroups: React.FC<Props> = ({
  showModal = false,
  setShowModal = () => { },
  seletedRows = [],
  onEditReportGroup = () => { },
}) => {

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      reportGroupName: "",
    },
  });

  const reportGroupDetails = useAppSelector(
    (state) => state.reportGroup.reportGroupDetails
  );

  const editForm: any = useRef();

  const dispatch = useDispatch<any>();

  useEffect(() => {
    dispatch(getReportGroupDetails(seletedRows?.reportGroupId))
  }, [dispatch])

  useEffect(() => {
    if (reportGroupDetails) {
      setValue("reportGroupName", reportGroupDetails?.reportGroupName)
    }
  }, [reportGroupDetails])

  const formSubmitHandler = (data: any) => {
    editForm.current.requestSubmit();
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && (
        <span className="tooltip-text">
          <BsExclamationCircleFill />
          {errors[name].message}
        </span>
      )
    );
  };

  const onEditFormSubmit = (data: any) => {
    onEditReportGroup && onEditReportGroup(data)
  };

  const onError = (errors: any, e: any) => { };

  return (
    <>
      <AddModal
        header="Edit Report Group"
        showModal={showModal}
        isAdd={false}
        setShowModal={setShowModal}
        style={{ width: "35vw", height: "25vh" }}
        OnSaveClick={formSubmitHandler}
      >
        <form onSubmit={handleSubmit(onEditFormSubmit, onError)} ref={editForm}>
          <div className="row px-2 pt-3 pb-3">
            <div className="field col-12 md:col-2 mb-0 py-0">
              <span className="p-float-label">
                <Controller
                  name="reportGroupName"
                  control={control}
                  rules={{
                    required: "Report Group is required.",
                  }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id="reportGroupName"
                      maxLength={100}
                      className={classNames("w-100 error-tooltip", {
                        error: fieldState.invalid,
                      })}
                      {...field}
                    />
                  )}
                />
                <label className="mandatory" htmlFor="inputtext">
                  Report Group
                </label>
                {getFormErrorMessage("reportGroupName")}
              </span>
            </div>
          </div>
        </form>
      </AddModal>
    </>
  );
};

export default EditReportGroups;
