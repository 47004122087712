import React from "react";
import { Button } from "primereact/button";
import { BsFillPencilFill, BsPlusLg, BsTrashFill } from "react-icons/bs";
import { FaCog, FaDownload } from "react-icons/fa";

const administrationCustomers = () => {
  return (
    <>
      <h4 className="doc-title mb-2">Sold to Customers</h4>

      <p>
        This option helps the Super Administrator, Support, Admin and Admin-Tech
        to add/edit/delete the Customers. When clicking this menu, all customers
        will be listed with their Name, Address, Status, Account expiry date and
        other contact details in the data grid.
      </p>

      <p>
        <b><span className="doc-subtitle">Add:</span></b>
      </p>

      <p>
        Customer can be created by clicking the{" "}
        <span className="doc-subtitle">Add</span>{" "}
        
        <Button className="doc-button-icon"><BsPlusLg className="icon" title="Add"/></Button>{" "}

        icon.Users can enter details like Number Pattern for, Number, Name,
        Address, Phone, Fax, Email, Contact, Status and Account Expiry Date for
        a Customer and. A link  <span className="doc-subtitle">View Pattern </span> is provided next to
        Customer Number field to view the valid Number Pattern for Customer
        Number. United States and Canada will be listed in the Number Pattern
        for drop down and Number Pattern for United States will be selected by
        default to add a new Customer, enter the customer details and click{" "}
        <span className="doc-subtitle">Save</span> button.
      </p>

      <p>
        <b><span className="doc-subtitle">Edit:</span></b>
      </p>
      
      <p>
        Edit a customer by selecting the row and clicking the
        <span className="doc-subtitle"> Edit </span>{" "}

        <Button className="doc-button-icon"> 
          <BsFillPencilFill className="icon" title="Edit"/> 
        </Button>{" "}

        icon. The details will be displayed in Edit Customer page. Make the
        required changes and click the{" "} <span className="doc-subtitle">Save Changes </span> button to save the
        changes.
      </p>

      <p>
        <b><span className="doc-subtitle">Inline Edit:</span></b>
      </p>

      <p>
        Inline edit option enables user to edit a Customer by clicking the
        <span className="doc-subtitle"> Edit </span>

        <Button className="doc-button-icon">
          <i className="pi pi-pencil icon" title="Inline Edit"/>
        </Button>{" "}

        icon in the left hand side of the required row. On clicking{" "}
        <span className="doc-subtitle">Edit</span>{" "}

        <Button className="doc-button-icon">
        <i className="pi pi-pencil icon" title="Inline Edit"/>
        </Button>{" "}

        icon, the selected row will be displayed as editable and Edit icon will
        be replaced with <span className="doc-subtitle">Save</span>{" "}

        <Button className="doc-button-icon">
          <i className="pi pi-save icon" title="Save"/>
        </Button>{" "}
        
        and<span className="doc-subtitle"> Cancel </span>
        
        <Button className="doc-button-icon">
          <span className="pi pi-times icon" title="Cancel"> </span>
        </Button>

        {" "} icon. Do the necessary changes in the required fields and click on{" "}
        <span className="doc-subtitle">Save </span>{" "}

        <Button className="doc-button-icon">
          <i className="pi pi-save icon" title="Save"/>
        </Button>{" "}

        icon. To cancel the Inline edit operation click on
        <span className="doc-subtitle"> Cancel </span>
        
        <Button className="doc-button-icon">
          <span className="pi pi-times icon" title="Cancel"></span>
        </Button> icon.
      
      </p>

      <p>
        <b><span className="doc-subtitle">Delete: </span></b>
      </p>
      
      <p>
        Delete a customer by selecting the row and clicking the{" "}
        <span className="doc-subtitle">Delete</span>{" "}

        <Button className="doc-button-icon">
          <BsTrashFill className="icon" title="Delete"/>
        </Button>.
        The selected customer will be shown in the list with status Deleted.
        The logged-in customer of the current user session cannot be deleted.
      </p>

      <p>
        <b><span className="doc-subtitle">Download: </span></b>
        
        <Button className="doc-button-icon">{" "}
          <FaDownload className="icon" title="Download"/>{' '}
        </Button>
      
      </p>

      <p>
        This option lists Report All, Report Highlighted, Export All, Export
        Highlighted and Group Highlighted.
      </p>

      <p>
        <span className="doc-subtitle">Report All: </span>
      </p>

      <p>
        This option is for generating a report with all the records in the data
        grid. The report can be saved to PDF, Text, Rich Text or Excel format by
        selecting the file format from the list under Save option, and then
        clicking OK button after selecting the required options from the Export
        Settings window.
      </p>

      <p>
        <span className="doc-subtitle">Report Highlighted: </span>
      </p>

      <p>
        This option is for generating a report with only selected records in the
        grid. Select the desired rows by holding down the control key and
        clicking on each of the records to be selected. Users can also select a
        set of rows using the shift key. The selected records will be
        highlighted. Click on the{" "}<span className="doc-subtitle">Report Highlighted </span>  option in the{" "}
        <span className="doc-subtitle">Download </span>{" "}

        <Button className="doc-button-icon">{" "}
        <FaDownload className="icon" title="Download"/>
        </Button>{" "}
         
         menu to generate the report. The report will be created with the highlighted
        records only. The report can be saved to PDF, Text, Rich Text or Excel
        format by selecting the file format from the list under Save option, and
        then clicking OK button after selecting the required options from the
        Export Settings window.
      </p>

      <p>
        <b><span className="doc-subtitle">More: </span></b>
        <Button className="doc-button-icon"><FaCog className="icon" title="More"/></Button>
      </p>

      <p>This option lists Sites and Display Settings option.</p>

      <p>
        <span className="doc-subtitle">Sites: </span>
      </p>

      <p>
        This option allows the user to update Sites to the selected Customer and
        also be able to switch between Sites. User can also search Sites for
        Update/Switch Sites for the selected Customer. Already linked Sites will
        be displayed as checked in the page. If the linked Sites to the selected
        Customer have Active Tasks, then the checkbox will be displayed as
        disabled otherwise it will be enabled
      </p>

      <p>
        In Order to switch to a Site, select the row corresponding to the
        required Site and then click on{" "}
        <span className="doc-subtitle">Set as Active Site </span> button
      </p>

      <p>
       <span className="doc-subtitle">Display Settings: </span>
      </p>

      <p>
        This option allows the user to change the visibility of the data fields
        displayed in the data grid. On selecting this option, all the available
        data fields are displayed in a window. Display settings can be modified
        by selecting the required fields from the list. The grid settings
        modified through this option will not be saved and will be effective
        only for the current instance of the data grid.
      </p>

    </>
  );
};

export default administrationCustomers;
