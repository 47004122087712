import React, {forwardRef, useEffect, useImperativeHandle,useRef, useState,useCallback} from "react";
import { useForm, Controller } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { ScrollPanel } from 'primereact/scrollpanel';
import { Button } from "primereact/button";
import { BsExclamationCircleFill, BsPlusLg, BsTrashFill,} from 'react-icons/bs';
import DataTable, { DataTableComponent } from "components/Table/DataTable";
import { useAppSelector } from "redux/hooks";
import { GOAL_COLS } from "../ReportComponents/GoalData";
import { useDispatch } from "react-redux";
import { addGoal, onGetGoalList, onGoalUpdated, setgoalList,deleteSelectedProjectGoal, clearDeletedGoalIds,setcheckIsValueChanged, setTabChangeMessageHandler, setTabChangeMessage } from "redux/pages/Reports/ProvenOutcomesSlice";
import InformationBox from "components/common/Message/InformationBox/InformationBox";
import { Overlay, Tooltip } from "react-bootstrap";
import Table from "components/Table/Table";
import ConfirmationBox from "components/common/Message/ConfirmationBox/ConfirmationBox";



interface Props {
	selectedProjectId: any;
}
type FormData = {
	goal: string;
	description: string;
	startDate: string;
	endDate: string;
};

export const Goals = forwardRef<any, Props>((props, ref) => {
	const {selectedProjectId } = props;

	const [pageIndex, setPageIndex] = useState(0);
    const [message, setMessage] = useState("");
    const [displayInfoModal, setDisplayInfoModal] = useState(false);
    const [displayConfirmModal, setDisplayConfirmModal] = useState(false);
	const [selectedRows, setSelectedRows] = useState<any>([]);
	const [currentStart, setCurrentStart] = useState<any>([]);
	const [sortField, setSortField] = useState('');
	const [sortOrder, setSortOrder] = useState('');
	const [newGoal, setnewGoal] = useState<any>('');
	const [target, setTarget] = useState(null);
  const [rowNumber, setRowNumber] = useState("");
  const [field, SetField] = useState("");
  const[disableDeleteButton ,setDisableDeleteButton] = useState(false);

	
	const goalList = useAppSelector((state) => state.provenOutcomes.goalList);
    const goalListDetails = useAppSelector((state) => state.provenOutcomes.goalListDetails);
	const deletedGoalIds = useAppSelector((state) => state.provenOutcomes.deletedGoalIds);


	const addForm = useRef<any>(null);
	const dispatch = useDispatch<any>();
	const {
		control,
		formState: { errors },
		handleSubmit,
		setValue, 
	} = useForm<FormData>({
		defaultValues: {
			goal: ""
		}
	});

	useImperativeHandle(ref, () => ({
		requestSubmit: () => {
			addForm?.current?.requestSubmit();
		},
	}));

	useEffect(() => {
		dispatch(onGetGoalList(selectedProjectId,1));
	  }, [dispatch])

	useEffect(() => {
		if (goalList?.length)  {
			if(selectedRows && selectedRows?.length)
			{
				setSelectedRows([goalList[0]]);
			}
			else if (newGoal) {
				setSelectedRows([newGoal])
			  }	
		setDisableDeleteButton(false);
		}
		else{
			setDisableDeleteButton(true);
		}
	}, [goalList]);

	useEffect(() => { 	 
		if(newGoal?.uniqueProjectGoalId) {      
		let goal: { uniqueProjectGoalId: string; projectId: any; projectGoals: string; projectGoalId: any; }[] = [];
		 goal.push({
			uniqueProjectGoalId: newGoal.uniqueProjectGoalId,
			projectId: newGoal.projectId,
			projectGoals : newGoal.projectGoals,
			projectGoalId :newGoal.projectGoalId
		});
		
		goalList?.map((item: any) => {
			goal.push({
			uniqueProjectGoalId: item.uniqueProjectGoalId,
			projectId: item.projectId,
			projectGoals :  item.projectGoals,
			projectGoalId :item.projectGoalId
			});
		})
		dispatch(setgoalList(goal));
	}
	}, [newGoal]);

	useEffect(() => {
		if (deletedGoalIds?.length) {
		  let datas = JSON.parse(JSON.stringify(goalList));
		  	deletedGoalIds?.map((element) => {
			datas = datas.filter( (item: any) => item.uniqueProjectGoalId !== element);  });
			dispatch(setgoalList(datas))
		  	dispatch(clearDeletedGoalIds([]))
	
		}
	  }, [deletedGoalIds]);
	
	  const handleRowSelect = useCallback((data: any) => {
		setSelectedRows([...data]);
	}, []);
	
	  const onSort = (event) => {
		let sortDirection: string;
		if (event.sortOrder === 1)
		  sortDirection = "ASC"
		else
		  sortDirection = "DESC"
		setSortField(event.sortField)
		setSortOrder(sortDirection)
		dispatch(onGetGoalList(selectedProjectId,currentStart, event?.sortField, sortDirection)); 
	  }

	const getFormErrorMessage = (goal) => {
		return (
			errors[goal] && (
				<span className="tooltip-text">
					<BsExclamationCircleFill />
					{errors[goal].message}
				</span>
			)
		);
	};
	
	const onPageChanged = (options: any) => {
		if (options !== pageIndex) { 
			let currentStart = (options * goalListDetails?.currentLength) + 1;
			setPageIndex(options)
			setCurrentStart(currentStart)
		  dispatch(onGetGoalList(selectedProjectId,currentStart,sortField,sortOrder));  
		}
	};

	const onGoalAdd = (data: any) => {
		  let body = {
			projectgoals:(data?.goal).trim(),
			uniqueProjectId:selectedProjectId
		  }		
		  dispatch(addGoal(body,setMessage,setnewGoal));
		  setValue("goal","")
		  dispatch(setcheckIsValueChanged(false))
		  dispatch(setTabChangeMessageHandler(true))
		  dispatch(setTabChangeMessage('Goal added'))			
	}
	const updateGoal = (submitData: any) => {
        let body = {
			projectGoals:(submitData?.projectGoals).trim(),
			uniqueProjectGoalId:submitData.uniqueProjectGoalId
        }
        dispatch(onGoalUpdated(body,setMessage,setDisplayInfoModal));
    };

	const onDeleteClick = () =>{		
		setMessage("Are you sure you want to delete the selected Goal(s)?")
		setDisplayConfirmModal(true)
	}
	const deleteGoals = () => {
		let list:{}[]=[];
		selectedRows.map((item:any)=>{
			list.push(item.uniqueProjectGoalId)
		},[])
		setDisplayConfirmModal(false)
		dispatch(deleteSelectedProjectGoal(list, setMessage,setDisplayInfoModal))
	};
	const onRowEditValidation = (rowData: any) => {
		let value = true;		
		if((rowData.projectGoals).trim().length<=0 || !rowData.projectGoals){
			setMessage(`Goal is required`);
			setDisplayInfoModal(true);
			value = false;
		}
		return value;
	  };

	  const onShowToolTip = (e, indexData, options) => {
		if (options?.field === "projectGoals"&& e.currentTarget.offsetWidth > e.currentTarget.offsetParent.offsetWidth  ) {
		  setTarget(e.currentTarget);
		  setRowNumber(indexData?.RowNumber);
		  SetField( indexData?.["projectGoals"])
		}
	  };
	
	  const onHideToolTip = () => {
		setTarget(null);
		setRowNumber("");
		SetField("");
	  };

	  const parentTemplate = (rowData, options) => {
  
		return (  
		  <div className="table-col">
			  <>
				<Overlay show={rowNumber == rowData?.RowNumber } key={rowData.RowNumber} placement="left" target={target}>
				  <Tooltip id={`tooltip${rowData?.RowNumber}`} className="data-import-tooltip">
				  <div id="tooltip-description"> {field} </div>
				  </Tooltip>
				</Overlay>
				<span
				  onMouseEnter={(e: any) => onShowToolTip(e, rowData, options)}
				  onMouseLeave={() => onHideToolTip()}
				  className="table-col"
				>
				{rowData?.["projectGoals"]}
				</span>
			  </>
			
		  </div>
		);
			};
		

		

	return (
    <>
      <div className="step-item">
        <h5 className="title">GOALS</h5>
        <div className="content-area">
          <ScrollPanel>
            <form
              className="w-100"
              onSubmit={handleSubmit(onGoalAdd)}
              ref={addForm}
            >
              <div className="goal-details custom-float-field">
                <div className="row mb-2">
                  <div className="col-9">
                    <span className="p-float-label">
                      <Controller
                        name="goal"
                        control={control}
                        rules={{
                          required: "Goal is required.",
                          validate: (value) => {
                            return value.trim().length <= 0
                              ? "Goal is required."
                              : true;
                          },
                        }}
                        render={({ field, fieldState }) => (
                          <InputText
                            id="goal"
                            maxLength={500}
                            className={classNames("w-100 error-tooltip", {
                              error: fieldState.invalid,
                            })}
                            {...field}
							onChange={(e) => {
								field.onChange(e.target.value);
								dispatch(setcheckIsValueChanged(true))
							}}
                          />
                        )}
                      />
                      <label className="mandatory" htmlFor="inputtext">
                        {" "}
                        Enter a Goal
                      </label>
                      {getFormErrorMessage("goal")}
                    </span>
                  </div>
                  <div className="col-3">
                    <div className="wizard-footer p-0">
                      {/* <Button label="Add" className="submit-btn"  /> */}
                      <div
                        className={classNames(
                          "d-flex  justify-content-between",
                          {
                            "align-items-center justify-content-between": false,
                          }
                        )}
                      >
                        {
                          <div className="search-section d-flex align-items-center">
                            <div className="attributes-dropdowns d-flex align-items-center"></div>
                          </div>
                        }

                        <div
                          className={classNames("table-config", {
                            "custom-align": false,
                          })}
                        >
                          {
                            <div className="action-btns">
                              <Button
                                className="button-icon"
                                tooltip="Add"
                                tooltipOptions={{ position: "top" }}
                                disabled={false}
                              >
                                <BsPlusLg className="icon" />
                              </Button>

                              <Button
                                className="button-icon"
                                tooltip="Delete"
                                type="button"
                                disabled={disableDeleteButton}
                                onClick={onDeleteClick}
                                tooltipOptions={{ position: "top" }}
                              >
                                <BsTrashFill className="icon" />
                              </Button>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <DataTableComponent
              rows={goalList}
              cols={GOAL_COLS}
              title={`Life Safety Systems Goals`}
              dataKeyId="uniqueProjectGoalId"
              pageDetails={goalListDetails}
              pageIndex={pageIndex}
              showroweditor={true}
              onPageChanged={onPageChanged}
              onSortData={onSort}
              getSelectedRowData={handleRowSelect}
              onRowEditValidation={onRowEditValidation}
              onSaveRowEdit={updateGoal}
              paginator={true}
              defaultSelectedRows={selectedRows}
              templateFromParent={parentTemplate}
              isTemplateFromParent={true}
              isTemplateMode={true}
            />
            <InformationBox
              showInfoModal={displayInfoModal}
              setShowInfoModal={setDisplayInfoModal}
              message={message}
            />
            <ConfirmationBox
              showConfirmModal={displayConfirmModal}
              setShowConfirmModal={setDisplayConfirmModal}
              confirmAction={deleteGoals}
              message={message}
            />
          </ScrollPanel>
        </div>
      </div>
    </>
  );
})

export default Goals;
