import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";

import Layout from 'pages/Layout';
import { useAppSelector } from "./redux/hooks";
import PrivateRoute from 'components/common/PrivateRoute/PrivateRoute';
import { getToken } from "./redux/pages/Login/loginSlice";

import './App.scss';
import { setServiceClassDispatcher, setSessionExpiryMessage } from 'service/HttpService';
import Help from 'components/Header/Help/Help';
import InformationBox from 'components/common/Message/InformationBox/InformationBox'; 

const RouteGuard = ({ component: Component }) => <PrivateRoute><Component /></PrivateRoute>

function App() {

  const dispatch = useDispatch<any>();
  setServiceClassDispatcher(dispatch);
  const token = useAppSelector((state) => state.login.accessToken);
  const appBaseURL = (window as any).envSettings.appBaseUrl;
  const [showHelp, setShowHelp] = useState<boolean>(false);
  const [pathName, setPathName] = useState('');
  const [message, setMessage] = useState<any>();
  const [displayInfoModal, setDisplayInfoModal] = useState<boolean>(false)

  useEffect(() => {
    if (token == null) {
      dispatch(getToken());
    } else {
      if (token.accessToken == null) {
        document.location.href = `${appBaseURL}/api/v1/auth/signin`;
      }
    }
  }, [token]);

  useEffect(() => {
    document.addEventListener('keydown', handleF1Key);
  }, [])

  const handleF1Key = (e) => {
    if (e.key == 'F1') {
      e.preventDefault()
      const pathNameWithOutSlash = window.location.pathname.replace("/", '');
      setPathName(pathNameWithOutSlash)
      setShowHelp(true) 
    }
  }

  const signOutUser = () => {
    (window as any).location =`${appBaseURL}/api/v1/auth/signin`
  }

  setSessionExpiryMessage(() => {
    setMessage("Your Session has timed out. You will be directed to Login page.");
    setDisplayInfoModal(true);
  }); 

  return (
    token == null || token.accessToken == null ?
      <div className="app-container">
        Validating User Session
      </div> :
      <div className="app-container">
        <RouteGuard component={Layout} />
        {showHelp ? (<Help
          isGlobal={true}
          currentPage={pathName}
          showModal={showHelp}
          setShowModal={setShowHelp}
        />) : null}
       <InformationBox
        showInfoModal={displayInfoModal}
        setShowInfoModal={setDisplayInfoModal}
        message={message}
        okOnClick={signOutUser}
        onHide={signOutUser}
      />
      </div>
  )
}

export default App;