import React from "react";
import { Button } from "primereact/button";
import { BsFillPencilFill, BsPlusLg, BsTrashFill } from "react-icons/bs";
import { FaCog, FaDownload } from "react-icons/fa";

const OrganizationSettingsDocument = () => {
  return (
    <>
      
      <h4 className="doc-title mb-2">
        Organizational Settings
      </h4>


      <p> 
        Options to manage Zone, Sales Offices and Branch Offices are provided under <b>Organizational Settings</b> menu.
      </p>

      <h4 className="mb-2">Zone</h4>

      <p>
        This option is used to define the geographical area where the Sales Offices are situated. 
        Super Administrator can add/edit/delete the Zone details and link Sales Office to a Zone through the Sales Office page. 
      </p>

      <p>
        <span className="doc-subtitle" ><b>Add: </b></span>
      </p>

      <p>
        Zone can be added by clicking <span className="doc-subtitle">Add </span> {' '}
        <Button className="doc-button-icon"> <BsPlusLg className="icon" title="Add" /> </Button> {' '} icon.
        Select a country and enter the Zone Name and Code. Click Save button. 
      </p>

      <p>
        <span className="doc-subtitle" ><b>Edit: </b></span>
      </p>

      <p>
        Edit a Zone by selecting it and clicking the <span className="doc-subtitle">Edit </span> {' '}
        <Button className="doc-button-icon"> <BsFillPencilFill className="icon" title="Edit" /> </Button> {' '} icon. 
        Make required changes and click Save Changes button to save the changes.
      </p>

      <p>
        <span className="doc-subtitle"><b>Delete: </b></span>
      </p>

      <p>
        Delete a Zone by selecting it and clicking the <span className="doc-subtitle">Delete </span> {" "}
        <Button className="doc-button-icon"> <BsTrashFill className="icon" title="Delete"/> </Button>{" "} icon. 
        Zone that is linked to a Sales Office cannot be deleted.
      </p>


      <p>
        <b><span className="doc-subtitle" >Download</span></b> {" "}
        <Button className="doc-button-icon">
          <FaDownload className="icon" title="Download" />
        </Button>{" "}
      </p>
      
  	  <p>
        This option lists Report All, Report Highlighted, Export All, Export Highlighted and Group Highlighted. 
      </p>

      <p>
        <span className="doc-subtitle">Report All: </span>
      </p>

      <p>
        This option is for generating a report with all the records in the data grid. 
        The report can be saved to PDF, Text, Rich Text or Excel format by selecting the file format from the list under Save option, 
        and then clicking OK button after selecting the required options from the Export Settings window.
      </p>

      <p>
        <span className="doc-subtitle">Report Highlighted: </span>
      </p>
      <p>
        This option is for generating a report with only selected records in the grid. 
        Select the desired rows by holding down the control key and clicking on each of the records to be selected. 
        Users can also select a set of rows using the shift key. The selected records will be highlighted. 
        Click on the <span className="doc-subtitle">Report Highlighted </span> option in the <span className="doc-subtitle">Download </span> {" "}
        <Button className="doc-button-icon">
          <FaDownload className="icon" title="Download"/>
        </Button>{" "} menu to generate the report. 
        The report will be created with the highlighted records only. 
        The report can be saved to PDF, Text, Rich Text or Excel format by selecting the file format from the list under Save option, 
        and then clicking OK button after selecting the required options from the Export Settings window.
      </p>

      <p>
        <b><span className="doc-subtitle">More </span> {" "}
        <Button className="doc-button-icon">
          <FaCog className="icon" title="More"/>
        </Button>{" "} </b>
      </p>
      <p>
        This option list only Display Settings option
      </p>

      <p>
        <span className="doc-subtitle" >Display Settings: </span>
      </p>
      <p>This option allows the user to change the visibility of the data fields displayed in the data grid. 
        On selecting this option, all the available data fields are displayed in a window. 
        Display settings can be modified by selecting the required fields from the list. 
        The grid settings modified through this option will not be saved and will be effective only for the current instance of the data grid. 
      </p>


      <h4 className="mb-2">Sales Office</h4>
      <p>
        This option allows the Super Administrator to Add/Edit/Delete Sales Office and assigns each Sales Office to a Zone. 
        Administrators can also link Technicians to Sales Office(s) through this option. 
        Sales Office manages the Contracts that are part of inspection. 
        A Contract will have exactly one Sales Office and it will be under that particular Sales Office. 
        A Sales Office can have one or more Branch Offices. All Sales Offices added will be listed in this page. 
      </p>

      <p>
        <span className="doc-subtitle"><b>Add: </b></span>
      </p>
      <p>
        Sales Office can be added by clicking <span className="doc-subtitle" >Add </span>{' '}
        <Button className="doc-button-icon"> <BsPlusLg className="icon" title="Add"/> </Button> {' '}icon. 
        Enter the Name, Code and select a Zone for the Sales Office from the Zone drop down box. Click Save button.
      </p>

      <p>
        <span className="doc-subtitle"><b>Edit: </b></span></p>
      <p>
        Edit the details of a Sales Office by selecting it and clicking the 
        <span className="doc-subtitle"> Edit </span> {' '}
        <Button className="doc-button-icon"> <BsFillPencilFill className="icon" title="Edit"/> </Button> {' '}icon. 
        Make required changes and click Save Changes button to save the changes.
      </p>

  	  <p>
        <span className="doc-subtitle"><b>Delete: </b></span>
      </p>
      <p>
        Delete a Sales Office by selecting it and clicking the 
        <span className="doc-subtitle"> Delete </span>{" "}
        <Button className="doc-button-icon"> <BsTrashFill className="icon" title="Delete"/> </Button>{" "} icon. 
        Sales Office that is active for an Inspection cannot be deleted.
      </p>


      <p>
        <b><span className="doc-subtitle">Download</span></b> {" "}
        <Button className="doc-button-icon">
          <FaDownload className="icon" title="Download"/>
        </Button>{" "}
      </p>
      
      <p>
        This option lists Report All, Report Highlighted, Export All, Export Highlighted and Group Highlighted. 
      </p>

      <p>
        <span className="doc-subtitle">Report All: </span>
      </p>

      <p>
        This option is for generating a report with all the records in the data grid. 
        The report can be saved to PDF, Text, Rich Text or Excel format by selecting the file format from the list under Save option, 
        and then clicking OK button after selecting the required options from the Export Settings window.
      </p>

      <p>
        <span className="doc-subtitle">Report Highlighted: </span>
      </p>
      <p>
        This option is for generating a report with only selected records in the grid. 
        Select the desired rows by holding down the control key and clicking on each of the records to be selected. 
        Users can also select a set of rows using the shift key. The selected records will be highlighted. Click on the 
        <span className="doc-subtitle"> Report Highlighted </span> option in the <span className="doc-subtitle">Download </span>{" "}
        <Button className="doc-button-icon"><FaDownload className="icon" title="Download"/></Button>{" "} menu to generate the report. 
        The report will be created with the highlighted records only. The report can be saved to PDF, Text, 
        Rich Text or Excel format by selecting the file format from the list under Save option, 
        and then clicking OK button after selecting the required options from the Export Settings window.
      </p>

      <p>
        <b><span className="doc-subtitle">More </span> {' '}
        <Button className="doc-button-icon">
          <FaCog className="icon" title="More"/>
        </Button>{" "} </b>
      </p>
      <p>
        This option lists Technicians and Display Settings option.
      </p>


      <p>
        <span className="doc-subtitle">Techs: </span>
      </p>
      <p>Technicians are linked to Sales offices. Multiple Sales offices can be linked to a Technician. 
        This option is used to link Technicians to the selected Sales Office. 
        Select the required Sales Office and click <span className="doc-subtitle">Techs</span> option from <span className="doc-subtitle">More </span>{" "}
        <Button className="doc-button-icon"> <FaCog className="icon" title="More"/></Button>{" "} menu. 
        By default all the Technicians will be listed with check boxes against each and the assigned Technician (if any) will be displayed 
        as selected in Update Technicians page. Select the required Technicians to be link with the selected Sales Office and 
        click Save Changes button. 
  	  </p>

      <p>
        If a Technician is displayed as checked and read only, then that Technician will be an Active technician 
        in one or more Inspection Tasks.
      </p>


      <p>
        <span className="doc-subtitle">Display Settings: </span>
      </p>
      <p>
        This option allows the user to change the visibility of the data fields displayed in the data grid. 
        On selecting this option, all the available data fields are displayed in a window. 
        Display settings can be modified by selecting the required fields from the list. 
        The grid settings modified through this option will not be saved and will be effective only for the current instance of the data grid.
      </p>


      <h4 className="mb-2">
        Branch Office
      </h4>
      <p>
        Branch Office manages the inspection process. The Super Administrators can Add/Edit/Delete the Branch Office. 
        An inspection is associated with a particular Branch Office. All the active Branch Offices will be displayed in this page.
      </p>

      <p>
        <span className="doc-subtitle"><b>Add: </b></span>
      </p>
      <p>
        Branch Office can be added by clicking <span className="doc-subtitle">Add </span>{' '}
        <Button className="doc-button-icon"> <BsPlusLg className="icon" title="Add"/> </Button> {' '}icon.
        The user can enter the Branch Office details like Address, City, State, Zip, Phone, Fax, Email, FA License Number, 
        SPK License Number and EXT License Number details and assign a Sales Office to Branch Office by selecting the Sales Office from 
        the dropdown box. Then click Save button to save the newly added Branch Office. 
      </p>

      <p>
        <span className="doc-subtitle"><b>Edit: </b></span>
      </p>
      <p>
        Edit Branch Office details by selecting it and clicking the <span className="doc-subtitle">Edit </span>{' '}
        <Button className="doc-button-icon"> <BsFillPencilFill className="icon" title="Edit"/> </Button> {' '}icon. Make required changes and click Save Changes button to save 
        the changes.
      </p>

      <p>
        <span className="doc-subtitle"><b>Delete: </b></span>
      </p>
      <p>
        Delete a Branch Office by selecting it and clicking the <span className="doc-subtitle">Delete </span>{" "}
        <Button className="doc-button-icon"> <BsTrashFill className="icon" title="Delete"/> </Button>{" "} icon. Branch Office that is active for an Inspection cannot be deleted.
      </p>

      <p>
        <b><span className="doc-subtitle">Download</span></b>{" "}
        <Button className="doc-button-icon"><FaDownload className="icon" title="Download"/></Button>{" "}
      </p>
      
      <p>
        This option lists Report All, Report Highlighted, Export All, Export Highlighted and Group Highlighted. 
      </p>

      <p>
        <span className="doc-subtitle">Report All: </span>
      </p>

      <p>
        This option is for generating a report with all the records in the data grid. The report can be saved to PDF, 
        Text, Rich Text or Excel format by selecting the file format from the list under Save option, 
        and then clicking OK button after selecting the required options from the Export Settings window.
      </p>

      <p>
        <span className="doc-subtitle">Report Highlighted: </span>
      </p>
      <p>
        This option is for generating a report with only selected records in the grid. 
        Select the desired rows by holding down the control key and clicking on each of the records to be selected. 
        Users can also select a set of rows using the shift key. The selected records will be highlighted. 
        Click on the <span className="doc-subtitle">Report Highlighted </span> option in the <span className="doc-subtitle">Download </span> 
        {" "}<Button className="doc-button-icon"><FaDownload className="icon" title="Download"/></Button>{" "} menu to generate the report. 
        The report will be created with the highlighted records only. The report can be saved to PDF, Text, 
        Rich Text or Excel format by selecting the file format from the list under Save option, and then clicking OK button after 
        selecting the required options from the Export Settings window.
      </p>

      <p>
        <b><span className="doc-subtitle">More </span> {' '}
        <Button className="doc-button-icon">
          <FaCog className="icon" title="More" />
        </Button>{" "} </b>
      </p>
      <p>
        This option lists only  Display Settings option
      </p>

      <p>
        <span className="doc-subtitle">Display Settings: </span>
      </p>
      <p>This option allows the user to change the visibility of the data fields displayed in the data grid.
         On selecting this option, all the available data fields are displayed in a window. 
         Display settings can be modified by selecting the required fields from the list. 
         The grid settings modified through this option will not be saved and will be effective only for the current instance of the data grid.
      </p>
    </>
  );
}
export default OrganizationSettingsDocument;
