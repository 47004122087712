import React, { useState, useRef, useEffect } from 'react';
import { useForm, Controller } from "react-hook-form";
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { RadioButton } from 'primereact/radiobutton';

import { classNames } from 'primereact/utils';

import { BsExclamationCircleFill } from 'react-icons/bs';

//redux


import AddModal from 'components/Table/AddModal/AddModal';
import { useAppSelector } from 'redux/hooks';
import { useDispatch } from 'react-redux';
import { getSystemDetails, getSystemTypes, setSystemDetails } from 'redux/pages/Inspection/Systems/systemSlice';



interface Props {
    showModal: boolean,
    setShowModal: (state: boolean) => void,
    selectedRows: any;
    onEditSystemClick: (data: any) => void,
}

type FormData = {
    systemName: string,
    systemType: string,
    isNetworked: boolean,
    SingleGlobalPMI: boolean,
    isAlternateLanguage: boolean,
    xmlFile: boolean,
    configFile: boolean,
    status: string,
    activeTask: string,
    activeSiteId:any
};


export const EditSystem: React.FC<Props> = ({
    showModal = false,
    setShowModal = () => { },
    selectedRows = [],
    onEditSystemClick = () => { },
}) => {

    const editForm: any = useRef();

    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<FormData>({
        defaultValues: {
            systemName: '',
            systemType: '',
            isNetworked: false,
            SingleGlobalPMI: false,
            isAlternateLanguage: false,
            xmlFile: false,
            configFile: false,
            status: 'Active',
            activeTask: '',
            activeSiteId:''
        }
    });
    
    const systemTypes = useAppSelector((state) => state.system.systemTypes);
    const systemDetails = useAppSelector((state) => state.system.systemDetails);
    const loggedInUserDetails = useAppSelector((state) => state.administration.loggedInUserDetails);
    
    const [networkSelected, setNetworkSelected] = useState('');
    const [languageSelected, setLanguageSelected] = useState('');
    const [nodeSelected, setNodeSelected] = useState('');
    const [disableNetwork, setDisableNetwork] = useState(true);
    const [disableNode, setDisableNode] = useState(true);
    const [disableLanguage, setDisableLanguage] = useState(true);
    const [disableSystemType, setDisableSystemType] = useState(false);
    const [selectedSystemType, setSelectedSystemType] = useState<any>()
    const [systemLookup, setSystemLookup] = useState<any>([])
    const [systemTypeLookup, setSystemTypeLookup] = useState([{ name: "", code: "" , isSiemens: false}]);

    const dispatch = useDispatch<any>()


    useEffect(() => {
        setValue("systemType", selectedRows[0]?.systemType)
        dispatch(getSystemTypes())
        dispatch(getSystemDetails(selectedRows[0]?.uniqueSystemId))
    }, [dispatch, selectedRows])

    useEffect(() => {
        if (systemTypes?.length) {
            let systemType: { name: string, code: string, isSiemens: boolean }[] = [];
            systemTypes?.map((item: any) => {
                return systemType.push({ name: item.panelTypeName, code: item.uniquePanelTypeId, isSiemens: item.isSiemens });
            })
            setSystemLookup(systemType)
            setSystemTypeLookup(systemType)
        }

    }, [systemTypes])

    useEffect(() => {
        if (systemDetails !== "" && systemLookup.length > 0) {
            let system = systemLookup.find((item: any) => item.name === systemDetails.systemType)
            setSelectedSystemType(system);
            setValue("systemName", systemDetails.systemName)
            setValue("systemType", systemDetails.systemType)
            setLanguageSelected(systemDetails.preferredLanguage)
            if (system?.isSiemens) {
                setDisableNetwork(false);
                setDisableLanguage(false);
                setNetworkSelected(systemDetails.isNetworked ? "Yes" : "No")
                setLanguageSelected(systemDetails.preferredLanguage)
                if (system?.name === "XLS") {
                    if (systemDetails?.isNetworked) {
                    setDisableNode(false);
                    setNodeSelected(systemDetails.globalPMI === "1 Global PMI" ? "HNET" : "XNET")
                    }
                    let lookup = systemLookup.filter((item: any) => item.name === "XLS" || item.name === "Desigo Modular")
                    setSystemTypeLookup(lookup)
                    
                } else if (system?.name === "Desigo Modular") {
                    setSystemTypeLookup(systemLookup)
                    setDisableSystemType(true)
                    if (systemDetails?.isNetworked) {
                    setDisableNode(false);
                    setNodeSelected(systemDetails.globalPMI === "1 Global PMI" ? "HNET" : "XNET")
                    }
                } else {
                    setSystemTypeLookup(systemLookup)
                    setDisableSystemType(true)
                }
            } else {
                let lookup = systemLookup.filter((item: any) => item.isSiemens === false)
                setSystemTypeLookup(lookup)
            }
            dispatch(setSystemDetails(""))
        }
    }, [systemDetails, systemLookup, setValue])

    const onSystemChange = (system) => {
        let systemType = systemTypeLookup.find((item: any) => item.name === system)
        setSelectedSystemType(systemType)
        setDisableNode(true);
        setNetworkSelected('');
        setNodeSelected('');
        setLanguageSelected('Base')

        if (systemType?.isSiemens){
            setDisableNetwork(false);
            setDisableLanguage(false);
        } else {
            setDisableNetwork(true);
            setDisableLanguage(true);
        }
    }

    const onNetworkChanged = (network) => {
        if (selectedSystemType.name === "XLS" || selectedSystemType.name === "Desigo Modular") {
            if (network === "Yes") {
                setDisableNode(false);
                setNodeSelected('XNET')
            } else {
                setDisableNode(true);
                setNodeSelected('')
            }
        }
    }
  
    const onEditFormSubmit = (data: any) => {
        data.isAlternateLanguage = languageSelected === "Base" ? false : true;
        data.isNetworked = networkSelected === "Yes" ? true : false;
        data.SingleGlobalPMI = nodeSelected === "HNET" ? true : false;
        data.systemName=data.systemName.trim();
        data.activeSiteId= loggedInUserDetails?.userDefaults?.uniqueSiteId;
        onEditSystemClick && onEditSystemClick(data);
    };

    const formSubmitHandler = (data: any) => {
        editForm.current.requestSubmit();
    };

    const getFormErrorMessage = (name) => {
        return errors[name] && <span className="tooltip-text"><BsExclamationCircleFill />{errors[name].message}</span>
    };

    //handle form validation errors
    const onError = (errors: any, e: any) => { };

    return (

        <AddModal header='Edit System' showModal={showModal} setShowModal={setShowModal} OnSaveClick={formSubmitHandler} isAdd={false} style={{ width: '35vw', maxHeight: '95%' }}>
            <form onSubmit={handleSubmit(onEditFormSubmit, onError)} ref={editForm}>
                
            <div className="field col-12 md:col-4">
                   
            <span className="p-float-label">
                       <Controller name="systemName" control={control} rules={{ required: 'System Name is required.',
                       validate: (value) => {
                        return value.trim().length<=0
                          ?"System Name is required.":true
                      }}} render={({ field, fieldState }) => (
                           <InputText id="inputtext" maxLength={100} className={classNames('w-100 error-tooltip', {'error': fieldState.invalid})} {...field} />
                       )} />
                       <label className='mandatory' htmlFor="inputtext">System Name</label>
                       {getFormErrorMessage('systemName')}
                   </span>
               </div>
                <div className="field col-12 md:col-4">
                    <span className="p-float-label">
                        <Controller name="systemType" control={control} rules={{ required: 'System Type is required.' }} render={({ field, fieldState }) => (
                            <Dropdown inputId="dropdown" disabled={disableSystemType} className={classNames('w-100 error-tooltip', {'error': fieldState.invalid})}  {...field} value={field.value} onChange={(e) =>{field.onChange(e.value); onSystemChange(e.value)} } options={systemTypeLookup} optionLabel="name" optionValue='name'/>
                        )} />
                        <label className='mandatory' htmlFor="dropdown">System Type</label>
                        {getFormErrorMessage('systemType')}
                        
                    </span>
                </div>
               
                <div className="field col-12 md:col-4 mb-0 pb-0">
                <label className='mandatory pop-subhead' htmlFor="radiobutton"><b>Networked</b></label>
                <div className='radio-section d-flex align-items-center pt-2'>
                    <div className="field-radiobutton">
                        <RadioButton disabled={disableNetwork} inputId="NetworkSelectYes" name="networkSelected" value="Yes" onChange={(e) => {setNetworkSelected(e.value); onNetworkChanged(e.value)}} checked={networkSelected === 'Yes'} />
                        <label htmlFor="networkSelected">Yes</label>
                    </div>
                    <div className="field-radiobutton pl-2">
                        <RadioButton disabled={disableNetwork} inputId="NetworkSelectYes" name="networkSelected" value="No" onChange={(e) => {setNetworkSelected(e.value); onNetworkChanged(e.value)}} checked={networkSelected === 'No'} />
                        <label htmlFor="networkSelected">No</label>
                    </div>
               </div>
               <div className="field-radiobutton">
                    <RadioButton disabled={disableNode} inputId="HNET" name="nodeSelected" value="HNET" onChange={(e) => setNodeSelected(e.value)} checked={nodeSelected === 'HNET'} />
                    <label htmlFor="HNET">HNET for testing one XLS node at a time</label>
                </div>
                    <div className="field-radiobutton">
                    <RadioButton disabled={disableNode} inputId="XNET" name="nodeSelected" value="XNET" onChange={(e) => setNodeSelected(e.value)} checked={nodeSelected === 'XNET'} />
                    <label htmlFor="XNET">XNET for testing multi-node XLS systems</label>
                </div>
             
                </div>

                <div className="field col-12 md:col-4">
            <label className='mandatory  pop-subhead' htmlFor="radiobutton"><b>Preferred Language</b></label>
                <div className='radio-section d-flex align-items-center pt-2'>
                <div className="field-radiobutton">
                    <RadioButton disabled={disableLanguage} inputId="base" name="language" value="Base" onChange={(e) => setLanguageSelected(e.value)} checked={languageSelected === 'Base'} />
                    <label htmlFor="Base">Base</label>
                </div>
                <div className="field-radiobutton pl-2">
                    <RadioButton disabled={disableLanguage} inputId="alternate" name="language" value="Alternate" onChange={(e) => setLanguageSelected(e.value)} checked={languageSelected === 'Alternate'} />
                    <label htmlFor="alternate">Alternate</label>
                </div>
                        </div>
                </div>
            </form>
        </AddModal>

    );
}

export default EditSystem;