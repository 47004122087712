import React, { useEffect, useState } from "react";
import { useAppSelector } from "redux/hooks";
import { DataTableComponent } from "components/Table/DataTable";
import { TRUNAROUND_COLS } from "./TurnAroundData";
import { Dialog } from "primereact/dialog";
import { onGetInspectionTurnAroundList } from "redux/pages/Reports/ProvenOutcomesSlice";
import { useDispatch } from "react-redux";
interface Props {
  showModal: boolean;
  setShowModal: (state: boolean) => void;
  systemId: any;
  selectedProjectId: any;
}
export const TurnAroundList: React.FC<Props> = ({
  showModal = false,
  setShowModal = () => {},
  systemId = "",
  selectedProjectId = "",
}) => {
  const [TurnAroundData, setTurnAroundData] = useState<any>([]);
  const TurnAroundPageDetails = useAppSelector(
    (state) => state.provenOutcomes.inspectionTurnAroundPageDetails
  );
  const inspectionTurnAroundList = useAppSelector(
    (state) => state.provenOutcomes.inspectionTurnAroundList
  );

  const [pageIndex, setPageIndex] = useState(0);
  const dispatch = useDispatch<any>();
  const [pageDetails, setPageDetails] = useState(null);
  const [currentStart, setCurrentStart] = useState<any>([]);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("");

  
  const onSort = (event) => {
    let sortDirection: string;
    if (event.sortOrder === 1) sortDirection = "ASC";
    else sortDirection = "DESC";
    setSortField(event.sortField);
    setSortOrder(sortDirection);
    dispatch(
      onGetInspectionTurnAroundList(
        selectedProjectId,
        systemId,
        pageIndex,
        event?.sortField,
        sortDirection
      )
    );
  };

  const onPageChanged = (options: any) => {
    if (options !== pageIndex) {
      let currentStart = (options * TurnAroundPageDetails?.currentLength) + 1;
      setPageIndex(options);
      setCurrentStart(currentStart);
      dispatch(
        onGetInspectionTurnAroundList(
          selectedProjectId,
          systemId,
          pageIndex,
          sortField,
          sortOrder
        )
      );
    }
  };
  useEffect(() => {
    if (systemId) dispatch(onGetInspectionTurnAroundList(selectedProjectId,systemId, 1));
  }, []);

  useEffect(() => {
		if (inspectionTurnAroundList?.length) {
			setPageDetails(TurnAroundPageDetails);
			setTurnAroundData(inspectionTurnAroundList);
	}}, [inspectionTurnAroundList]);
	

  return (
    <Dialog
      header="TurnAround List"
      visible={showModal}
      style={{ width: "70vw" }}
      onHide={() => setShowModal(false)}
      className="display-settings personal selectsite turnaround-list"
    >
      <div className="content-box">
        <div className={`table-wrap`}>
          <DataTableComponent
            rows={TurnAroundData}
            cols={TRUNAROUND_COLS}
            title={`Inspection Turnaround List`}
            dataKeyId="systemPanelInspectionReportId"
            pageDetails={pageDetails}
            pageIndex={pageIndex}
            showroweditor={false}
            onSortData={onSort}
            onPageChanged={onPageChanged}
            paginator={true}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default TurnAroundList;
