import React from 'react';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

interface Props {
    showConfirmModal: boolean,
    setShowConfirmModal: (state: boolean) => void,
    confirmAction: () => void,
    cancelAction?:()=>void,
    onHideAction?:()=>void,
    message:any,
}

export const ConfirmationBox: React.FC<Props> = ({
    showConfirmModal = false,
    setShowConfirmModal = () => { },
    confirmAction = () => { },
    cancelAction=()=>{},
    onHideAction=()=>{},
    message="",
}) => {

    const renderHeader = () => {
        return(
            <>
                Tech Advance<sup>+</sup>
            </>
        )
    }

    const renderFooter = () => {
        return (
            <>
                <Button label="No" onClick={() => {cancelAction();setShowConfirmModal(false)}} className='cancel-btn' />
                <Button label="Yes" onClick={confirmAction} className='submit-btn' autoFocus />
            </>
        );
    }

    return (
        <Dialog
            header={renderHeader()}
            visible={showConfirmModal}
            style={{ width: '35vw' }}
            footer={renderFooter()}
            onHide={() => {onHideAction();setShowConfirmModal(false)}}
            className='delete-modal'
        >
            <p className='content'>{message}</p>
        </Dialog>
    );
}

export default ConfirmationBox;