import React, { useState, useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { RadioButton } from "primereact/radiobutton";
import { classNames } from "primereact/utils";

import { BsExclamationCircleFill } from "react-icons/bs";
import AddModal from "components/Table/AddModal/AddModal";
import { useDispatch } from "react-redux";
import { getSystemTypes } from "redux/pages/Inspection/Systems/systemSlice";
import { useAppSelector } from "redux/hooks";



//redux

interface Props {
    showModal: boolean;
    setShowModal: (state: boolean) => void;
    onAddSystemClick: (data: any) => void;
}

type FormData = {
    systemName: string;
    systemType: string;
    isNetworked: boolean;
    SingleGlobalPMI: boolean;
    isAlternateLanguage: boolean;
    xmlFile: boolean;
    configFile: boolean;
    status: string;
    activeTask: string;
    activeSiteId:string;
};

export const AddSystems: React.FC<Props> = ({ showModal = false, setShowModal = () => {}, onAddSystemClick = () => {} }) => {
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<FormData>({
        defaultValues: {
            systemName: "",
            systemType: "",
            isNetworked: false,
            SingleGlobalPMI: false,
            isAlternateLanguage: false,
            xmlFile: false,
            configFile: false,
            status: "Active",
            activeTask: "",
            activeSiteId: "",
        },
    });

    const systemTypes = useAppSelector((state) => state.system.systemTypes);
    const loggedInUserDetails = useAppSelector((state) => state.administration.loggedInUserDetails);
    
    const [networkSelected, setNetworkSelected] = useState(null);
    const [languageSelected, setLanguageSelected] = useState("Base");
    const [nodeSelected, setNodeSelected] = useState("");
    const [disableNetwork, setDisableNetwork] = useState(true);
    const [disableNode, setDisableNode] = useState(true);
    const [disableLanguage, setDisableLanguage] = useState(true);
    const [selectedSystemType, setSelectedSystemType] = useState<any>();
    const [systemTypeLookup, setSystemTypeLookup] = useState([{ name: "", code: "", isSiemens: false }]);
    const addForm: any = useRef();

    const dispatch = useDispatch<any>();

    useEffect(() => {
        dispatch(getSystemTypes());
    }, [dispatch]);

    useEffect(() => {
        if (systemTypes?.length) {
            let systemType: { name: string; code: string; isSiemens: boolean }[] = [];
            systemTypes?.map((item: any) => {
                return systemType.push({ name: item.panelTypeName, code: item.uniquePanelTypeId, isSiemens: item.isSiemens });
            });
            setSystemTypeLookup(systemType);
        }
    }, [systemTypes]);

    const onSystemChange = (system) => {
        setSelectedSystemType(system);
        setDisableNode(true);
        setNetworkSelected(null);
        setNodeSelected("");
        setLanguageSelected("Base");

        if (system.isSiemens) {
            setDisableNetwork(false);
            setDisableLanguage(false);
        } else {
            setDisableNetwork(true);
            setDisableLanguage(true);
        }
    };

    const onNetworkChanged = (network) => {
        if (selectedSystemType.name === "XLS" || selectedSystemType.name === "Desigo Modular" || 
        selectedSystemType.name === "Cerberus PRO Modular") {
            if (network === "Yes") {
                setDisableNode(false);
                setNodeSelected("XNET");
            } else {
                setDisableNode(true);
                setNodeSelected("");
            }
        }
    };

    const onAddFormSubmit = (data: any) => {
        data.isAlternateLanguage = languageSelected === "Base" ? false : true;
        data.isNetworked = networkSelected === "Yes" ? true : false;
        data.SingleGlobalPMI = nodeSelected === "HNET" ? true : false;
        data.systemType = data.systemType.name;
        data.systemName= data.systemName.trim();
        data.activeSiteId= loggedInUserDetails?.userDefaults?.uniqueSiteId;
        onAddSystemClick && onAddSystemClick(data);
    };

    const formSubmitHandler = (data: any) => {
        addForm.current.requestSubmit();
    };

    const getFormErrorMessage = (name) => {
        return (
            errors[name] && (
                <span className="tooltip-text">
                    <BsExclamationCircleFill />
                    {errors[name].message}
                </span>
            )
        );
    };

    //handle form validation errors
    const onError = (errors: any, e: any) => {};

    return (
        <AddModal
            header="New System"
            showModal={showModal}
            setShowModal={setShowModal}
            OnSaveClick={formSubmitHandler}
            isAdd={true}
            style={{ width: "35vw", maxHeight: "95%" }}
        >
            <form onSubmit={handleSubmit(onAddFormSubmit, onError)} ref={addForm}>
                <div className="field col-12 md:col-4">
                    <span className="p-float-label">
                        <Controller
                            name="systemName"
                            control={control}
                            rules={{ required: "System Name is required.",
                            validate: (value) => {
                                return value.trim().length<=0
                                  ?"System Name is required.":true
                              }, }}
                            render={({ field, fieldState }) => (
                                <InputText id="inputtext" maxLength={100} className={classNames("w-100 error-tooltip", { error: fieldState.invalid })} {...field} />
                            )}
                        />
                        <label className="mandatory" htmlFor="inputtext">
                            System Name
                        </label>
                        {getFormErrorMessage("systemName")}
                    </span>
                </div>
                <div className="field col-12 md:col-4">
                    <span className="p-float-label">
                        <Controller
                            name="systemType"
                            control={control}
                            rules={{ required: "System Type is required." }}
                            render={({ field, fieldState }) => (
                                <Dropdown
                                    inputId="dropdown"
                                    className={classNames("w-100 error-tooltip", { error: fieldState.invalid })}
                                    {...field}
                                    value={field.value}
                                    onChange={(e) => {
                                        field.onChange(e.value);
                                        onSystemChange(e.value);
                                    }}
                                    options={systemTypeLookup}
                                    optionLabel="name"
                                />
                            )}
                        />
                        <label className="mandatory" htmlFor="dropdown">
                            System Type
                        </label>
                        {getFormErrorMessage("systemType")}
                    </span>
                </div>

                <div className="field col-12 md:col-4 mb-0 pb-0">
                    <label className="mandatory pop-subhead" htmlFor="radiobutton">
                        <b>Networked</b>
                    </label>
                    <div className="radio-section d-flex align-items-center pt-2">
                        <div className="field-radiobutton">
                            <RadioButton
                                disabled={disableNetwork}
                                inputId="NetworkSelectYes"
                                name="networkSelected"
                                value="Yes"
                                onChange={(e) => {
                                    setNetworkSelected(e.value);
                                    onNetworkChanged(e.value);
                                }}
                                checked={networkSelected === "Yes"}
                            />
                            <label htmlFor="networkSelected">Yes</label>
                        </div>
                        <div className="field-radiobutton pl-2">
                            <RadioButton
                                disabled={disableNetwork}
                                inputId="NetworkSelectYes"
                                name="networkSelected"
                                value="No"
                                onChange={(e) => {
                                    setNetworkSelected(e.value);
                                    onNetworkChanged(e.value);
                                }}
                                checked={networkSelected === "No"}
                            />
                            <label htmlFor="networkSelected">No</label>
                        </div>
                    </div>
                    <div className="field-radiobutton">
                        <RadioButton
                            disabled={disableNode}
                            inputId="HNET"
                            name="nodeSelected"
                            value="HNET"
                            onChange={(e) => setNodeSelected(e.value)}
                            checked={nodeSelected === "HNET"}
                        />
                        <label htmlFor="HNET">HNET for testing one XLS node at a time</label>
                    </div>
                    <div className="field-radiobutton">
                        <RadioButton
                            disabled={disableNode}
                            inputId="XNET"
                            name="nodeSelected"
                            value="XNET"
                            onChange={(e) => setNodeSelected(e.value)}
                            checked={nodeSelected === "XNET"}
                        />
                        <label htmlFor="XNET">XNET for testing multi-node XLS systems</label>
                    </div>
                </div>

                <div className="field col-12 md:col-4">
                    <label className="mandatory pop-subhead" htmlFor="radiobutton">
                        <b>Preferred Language</b>
                    </label>
                    <div className="radio-section d-flex align-items-center pt-2">
                        <div className="field-radiobutton">
                            <RadioButton
                                disabled={disableLanguage}
                                inputId="base"
                                name="language"
                                value="Base"
                                onChange={(e) => setLanguageSelected(e.value)}
                                checked={languageSelected === "Base"}
                            />
                            <label htmlFor="Base">Base</label>
                        </div>
                        <div className="field-radiobutton pl-2">
                            <RadioButton
                                disabled={disableLanguage}
                                inputId="alternate"
                                name="language"
                                value="Alternate"
                                onChange={(e) => setLanguageSelected(e.value)}
                                checked={languageSelected === "Alternate"}
                            />
                            <label htmlFor="alternate">Alternate</label>
                        </div>
                    </div>
                </div>
            </form>
        </AddModal>
    );
};

export default AddSystems;
