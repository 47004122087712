import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { zlink, baseURL } from "../../../../service/HttpService";
import { AppThunk } from "../../../store";

export interface PersonalSettingState {
  errors: any;
  personalSettings: any;
  rowsPerPage:number;
  inspectionTasksRowsPerPage:number;
}

let initialState: PersonalSettingState = {
  errors: "",
  personalSettings: [],
  rowsPerPage:0,
  inspectionTasksRowsPerPage:0
};

const personalSettingsSlice = createSlice({
  name: "personalSettings",
  initialState,
  reducers: {
    setErrors: (state, { payload }: PayloadAction<any>) => {
      state.errors = payload;
    },
    setPersonalSettings: (state, { payload }: PayloadAction<any>) => {
      state.personalSettings = payload;
    },
    setRowsPerPage: (state, { payload }: PayloadAction<any>) => {
      state.rowsPerPage = payload;
    },
    setInspectionTasksRowsPerPage: (state, { payload }: PayloadAction<any>) => {
      state.inspectionTasksRowsPerPage = payload;
    },
  },
});

export const getPersonalSettings = (): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.get(`${baseURL}api/v1/users/me/personalsettings`);
    if (res.status === "done") {
      dispatch(setPersonalSettings(res?.data));
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const onEditPersonalSettings =
  (body: any, setMessage, setDisplayInfoModal, setShowModal): AppThunk =>
  async (dispatch) => {
    try {
      const res = await zlink.post(
        `${baseURL}api/v1/users/me/personalsettings`,
        body
      );
      if (res.status === "done") {
        setMessage("User Personal Settings updated");
        setDisplayInfoModal(true);
        dispatch(setRowsPerPage(res?.data?.rowsPerPage));
        dispatch(setInspectionTasksRowsPerPage(res?.data?.rowsPerPageForInspectiontasks));
      } else {
        dispatch(setErrors(res.error));
        setMessage(res.message);
        setDisplayInfoModal(true);
      }
    } catch (error) {
      dispatch(setErrors(error));
    }
  };

export const onUpdatePassword =
  (body: any, setMessage, setDisplayInfoModal, setShowModal): AppThunk =>
  async (dispatch) => {
    try {
      const res = await zlink.post(
        `${baseURL}api/v1/users/me/changePassword`,
        body
      );
      if (res.status === "done") {
        setMessage("Password updated successfully");
       
        setDisplayInfoModal(true);
      } else {
        dispatch(setErrors(res.error));
        setMessage(res.message);
        setDisplayInfoModal(true);
      }
    } catch (error) {
      dispatch(setErrors(error));
    }
  };
export const { 
  setErrors, 
  setPersonalSettings,
  setRowsPerPage,
  setInspectionTasksRowsPerPage
} = personalSettingsSlice.actions;

export default personalSettingsSlice.reducer;
