import React, { useEffect, useState } from 'react'
import { useDispatch } from "react-redux";
import { APP_ROUTES } from "constants/AppRoutes";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "components/common/Breadcrumb/Breadcrumb";
import ReportViewer from 'components/common/Report/ReportViewer'
import { useAppSelector } from "../../redux/hooks";
import { createReportPreviewSession } from "../../redux/pages/Administration/Site/siteSlice";
import { Controller, useForm } from 'react-hook-form';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { ListGroup } from 'react-bootstrap';
import { Button } from 'primereact/button';
import { USER_ACTIVITY_REPORT_COLS } from './UserActivityReportTabeData';
import { getStatusList } from 'redux/pages/Administration/administrationSlice';
import CustomizeReport from './CustomizeReport/CustomizeReport';
import { onExportReport, setIsAppliedDone } from 'redux/common/report/customizeReportSlice';
import InformationBox from 'components/common/Message/InformationBox/InformationBox';
//icons
import { BsArrowRight, BsFileEarmarkPdf } from "react-icons/bs";
import { AiOutlineFileText } from 'react-icons/ai';
import { GrDocumentRtf } from 'react-icons/gr';
import { RiFileExcel2Line } from 'react-icons/ri';
import DateSelectModal from './UserActivityDateModal';
import moment from 'moment';

type FormData = {
	sortBy: string;
	statusId: number;
};

const UserActivityReport = () => {

	const {
		control,
		handleSubmit,
		formState: { errors },
	} = useForm<FormData>({
		defaultValues: {
			sortBy: "",
			statusId: 0,
		},
	});

	const [reportName, setReportName] = useState<any>(null);
	const [sortColumn, setSortColumn] = useState<any>("");
	const [statusList, setStatusList] = useState<any>([]);
	const [selectedStatus, setSelectedStatus] = useState<any>(0);
	const [showCustomizeReport, setShowCustomizeReport] = useState(false);
	const [message, setMessage] = useState("");
	const [displayInfoModal, setDisplayInfoModal] = useState(false);
	const [customizationType, setCustomizationType] = useState('user');
	const [startDate, setStartDate] = useState<any>(new Date().setDate((new Date()).getDate() - 30));
	const [endDate, setEndDate] = useState<any>(new Date());
	const [showDateModal, setShowDateModal] = useState<boolean>(false);

	const { status } = useAppSelector((state) => state.administration.statusList);
	const reportPreviewSession = useAppSelector((state) => state.site.reportPreviewSession);
	const isAppliedDone = useAppSelector((state) => state.customizeReport.isAppliedDone);

	const navigate = useNavigate();
	const dispatch = useDispatch<any>();

	const dropdownDateItems = [
		{ statusId: 1001, statusCode: "Deleted_Users_During_Period", statusName: "Deleted Users During Period" },
		{ statusId: 1002, statusCode: "Active_Users_During_Period", statusName: "Active Users During Period" },
	];

	useEffect(() => {
		dispatch(getStatusList());
	}, [dispatch]);

	const viewUserActivityReport = () => {
		const report = status?.find(item => item?.statusId === selectedStatus);
		const periodStartDate: string = moment(startDate).format("yyyy-MM-DD");
		const periodEndDate: string = moment(endDate).format("yyyy-MM-DD");

		const statusName = (selectedStatus === 1001) ? `Deleted Users during ${periodStartDate} and ${periodEndDate}`
			: (selectedStatus === 1002) ? `Active Users during ${periodStartDate} and ${periodEndDate}`
				: report ? report?.statusName + ' Users'
					: 'All Users';

		const statusId = (selectedStatus === 1001) ? 68 : (selectedStatus === 1002) ? 10 : selectedStatus;
		const reportParameters = {
			reportParameters: {
				sortColumn: sortColumn,
				statusId: statusId,
				customizationType: customizationType,
				statusName: statusName,
				startDate: (selectedStatus === 1001 || selectedStatus === 1002) ? periodStartDate : '',
				endDate: (selectedStatus === 1001 || selectedStatus === 1002) ? periodEndDate : ''
			},
			reportType: "USER_ACTIVITY_REPORTS"
		};
		dispatch(createReportPreviewSession(reportParameters))
	};

	useEffect(() => {
		viewUserActivityReport();
	}, [sortColumn]);

	useEffect(() => {
		setStartDate(new Date().setDate((new Date()).getDate() - 30));
		setEndDate(new Date());
		if (selectedStatus === 1001 || selectedStatus === 1002) {
			setShowDateModal(true);
		} else {
			viewUserActivityReport();
		}
	}, [selectedStatus]);

	useEffect(() => {
		if (isAppliedDone) viewUserActivityReport();
	}, [isAppliedDone]);

	useEffect(() => {
		if (reportPreviewSession) {
			setReportName(reportPreviewSession.previewSessionId);
		}
	}, [reportPreviewSession])

	useEffect(() => {
		if (status && status.length) {
			let tempList = [{ statusId: 0, statusCode: "All_Users", statusName: "All Users" },
			...status.filter(status => [1, 2, 4, 5, 6, 7].includes(status.statusId)), ...dropdownDateItems]
			setStatusList(tempList);
		}
	}, [status])

	useEffect(() => {
		if (reportPreviewSession) {
			setReportName(reportPreviewSession.previewSessionId);
		}
	}, [reportPreviewSession])

	useEffect(() => {
		if (isAppliedDone) {
			dispatch(setIsAppliedDone(false));
			setShowCustomizeReport(false);
			setMessage("Report settings saved");
			setDisplayInfoModal(true);
		}
	}, [isAppliedDone]);

	const exportAsExcel = () => {
		const report = status?.find(item => item?.statusId === selectedStatus);
		const periodStartDate: string = moment(startDate).format("yyyy-MM-DD");
		const periodEndDate: string = moment(endDate).format("yyyy-MM-DD");
		const statusId = (selectedStatus === 1001) ? 68 : (selectedStatus === 1002) ? 10 : selectedStatus;

		const statusName = (selectedStatus === 1001) ? `Deleted Users during ${periodStartDate} and ${periodEndDate}`
			: (selectedStatus === 1002) ? `Active Users during ${periodStartDate} and ${periodEndDate}`
				: report ? report?.statusName + ' Users'
					: 'All Users';

		const reportParameters = {
			exportParameters: {
				sortColumn: sortColumn,
				statusId: statusId,
				customizationType: customizationType,
				statusName: statusName,
				startDate: (selectedStatus === 1001 || selectedStatus === 1002) ? periodStartDate : '',
				endDate: (selectedStatus === 1001 || selectedStatus === 1002) ? periodEndDate : ''
			},
			exportType: "USER_ACTIVITY_REPORTS"
		};
		dispatch(onExportReport(reportParameters, statusName));
	};

	const onApplyPeriodDate = () => {
		viewUserActivityReport();
		setShowDateModal(false);
	};

	return (
		<div className='site-reports site-report-dropdown'>
			<section className="d-flex align-items-center">
				<Breadcrumb
					title="Reports"
					classNames="report-header"
					handleGoBackClick={() => navigate(`/${APP_ROUTES.REPORT_VIEW}`)}
				/>
				<div className="breadcrumb-right-arrow ml-3 mr-3">
					<BsArrowRight className="icon" />
				</div>
				<div className="breadcrumb-sub-page-title">
					<span>User Activity</span>
				</div>
			</section>

			<div className="report-sort-field pt-2 pb-2">
				<div className="report-export-main d-flex">
					<div className="row px-2">
						<div className="report-field col-12 md:col-2">
							<div className="field  mb-0">
								<span className="p-float-label">
									<Controller
										name="statusId"
										control={control}
										render={({ field, fieldState }) => (
											<Dropdown
												inputId="statusDropdown"
												className={classNames("w-100 error-tooltip", {
													error: fieldState.invalid,
												})}
												{...field}
												value={selectedStatus}
												onChange={(e) => {
													setSelectedStatus(e.value);
												}}
												options={statusList}
												optionLabel="statusName"
												optionValue="statusId"
											/>
										)}
									/>
									<label htmlFor="statusDropdown">Filter by</label>
								</span>
							</div>
						</div>
						<div className="report-field col-12 md:col-2">
							<div className="field  mb-0">
								<span className="p-float-label">
									<Controller
										name="sortBy"
										control={control}
										render={({ field, fieldState }) => (
											<Dropdown
												inputId="dropdown"
												className={classNames("w-100 error-tooltip", {
													error: fieldState.invalid,
												})}
												{...field}
												value={sortColumn}
												onChange={(e) => {
													setSortColumn(e.value);
												}}
												options={USER_ACTIVITY_REPORT_COLS}
												optionLabel="header"
												optionValue="field"
											/>
										)}
									/>
									<label htmlFor="dropdown">Sort by</label>
								</span>
							</div>
						</div>
					</div>
					<div className="report-buttons-main d-flex">
						<div className="report-buttons">
							<ListGroup horizontal>
								<ListGroup.Item><Button label="Customize" className="report-btn" onClick={() => setShowCustomizeReport(true)} /></ListGroup.Item>
							</ListGroup>
						</div>
						<div className='export-report-buttons'>
							<ListGroup horizontal>
								<span className='expor-title'>Export to</span>
								<ListGroup.Item>
									<Button className="export-btn" onClick={() => exportAsExcel()}> <RiFileExcel2Line className="icon" /> Excel </Button>
								</ListGroup.Item>
								{/* <ListGroup.Item>
									<Button className="export-btn"> <BsFileEarmarkPdf className="icon" /> PDF </Button>
								</ListGroup.Item>
								<ListGroup.Item>
									<Button className="export-btn"> <GrDocumentRtf className="icon" /> RTF </Button>
								</ListGroup.Item>
								<ListGroup.Item>
									<Button className="export-btn"> <AiOutlineFileText className="icon" /> Text </Button>
								</ListGroup.Item> */}
							</ListGroup>
						</div>
					</div>
				</div>
			</div>

			<ReportViewer reportId={reportName} />
			<InformationBox
				showInfoModal={displayInfoModal}
				setShowInfoModal={setDisplayInfoModal}
				message={message}
			/>
			{showCustomizeReport &&
				<CustomizeReport
					reportType="USER_ACTIVITY_REPORTS"
					showModal={showCustomizeReport}
					onHideModal={() => setShowCustomizeReport(false)}
					customizationType={customizationType}
					setCustomizationType={setCustomizationType} />
			}

			{showDateModal &&
				<DateSelectModal
					showModal={showDateModal}
					setShowModal={setShowDateModal}
					periodStartDate={startDate}
					periodEndDate={endDate}
					setPeriodStartDate={setStartDate}
					setPeriodEndDate={setEndDate}
					setSelectedStatus={setSelectedStatus}
					onDateUpdated={onApplyPeriodDate} />
			}
		</div>
	)
}

export default UserActivityReport;