import React, { memo, useEffect, useState } from "react";


interface ARViewer {
  openReport: (id: string) => void;
}

const ReportViewer: React.FC<{ reportId: string }> = ({ reportId }) => {
  const [viewerInstance, setViewerInstance] = useState<ARViewer | undefined>(undefined);
  useEffect(() => {
    if (!viewerInstance) {
      const viewer = (window as any).GrapeCity.ActiveReports.JSViewer.create({
        element: "#viewerContainer",
        displayMode: 'Continuous'
      });
      // viewer.toolbar.addItem(pdfExportButton);
      setViewerInstance(viewer);
    }
    if (reportId) {
      viewerInstance?.openReport(reportId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportId]);
  return <div id="viewerContainer" />;
};

export default memo(ReportViewer);