import React from 'react'

const SendMailDocuments = () => {
  return (
    <>
       <h4 className="doc-title mb-2">Send Mail</h4>
       <p>
         This option allows the user to send emails related to sales, technical support, 
         general inquiry, etc.
      </p>
      <p>
         Emails can be sent to zLink Inc. or to any other email Id. This can be done by 
         selecting the corresponding radio button provided. Mails to zLink Inc. are categorized 
         into Sales, Technical Support and General Inquiry. If the 'Other' radio button is selected, 
         the Type of Enquiry drop down will be disabled.
      </p>
      <p>
         Attachment can also be uploaded by clicking the Choose File button against the Attachment field.
      </p>
      <p>
        Enter the required message details and click Send button. The Mail will be sent to the recipient 
        according to the email id provided or to zLink Inc.
      </p>
    </>
  );
}
export default SendMailDocuments;
