import { InputText } from 'primereact/inputtext';

const textEditor = (options) => {
    return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
};

export const HEALTH_CLASSIFICATION_COLS = {
    HEALTH_COLS: [
        
        {
            field: 'healthClassification',
            header: 'Health Classification',
            editor: (options) => textEditor(options),
            style: { flex: '1 0 200px' },
            sortable: true,
        },
        {
            field: 'equipmentType',
            header: 'Equipment Type',
            editor: (options) => textEditor(options),
            style: { flex: '1 0 200px' },
            sortable: true,
        },
       
    ]
};

export const hEALTH_CLASSIFICATION_ROWS = {
    HEALTH_ROWS: [
        {
            id: 1,
            HealthClassification: 'ABC/Dry Chemical',
            EquipmentType: 'Portable Extinguishers',
        },
        {
            id: 2,
            HealthClassification: 'ABC/Dry Chemical',
            EquipmentType: 'Portable Extinguishers',
        },
        {
            id: 3,
            HealthClassification: 'ABC/Dry Chemical',
            EquipmentType: 'Portable Extinguishers',
        },
        {
            id: 4,
            HealthClassification: 'ABC/Dry Chemical',
            EquipmentType: 'Portable Extinguishers',
        },
        {
            id: 5,
            HealthClassification: 'ABC/Dry Chemical',
            EquipmentType: 'Portable Extinguishers',
        },
        {
            id: 6,
            HealthClassification: 'ABC/Dry Chemical',
            EquipmentType: 'Portable Extinguishers',
        },
        {
            id: 7,
            HealthClassification: 'ABC/Dry Chemical',
            EquipmentType: 'Portable Extinguishers',
        },
        {
            id: 8,
            HealthClassification: 'ABC/Dry Chemical',
            EquipmentType: 'Portable Extinguishers',
        },
        {
            id: 9,
            HealthClassification: 'ABC/Dry Chemical',
            EquipmentType: 'Portable Extinguishers',
        },
        {
            id: 10,
            HealthClassification: 'ABC/Dry Chemical',
            EquipmentType: 'Portable Extinguishers',
        },
        {
            id: 11,
            HealthClassification: 'ABC/Dry Chemical',
            EquipmentType: 'Portable Extinguishers',
        },
        {
            id: 12,
            HealthClassification: 'ABC/Dry Chemical',
            EquipmentType: 'Portable Extinguishers',
        },
        {
            id: 13,
            HealthClassification: 'ABC/Dry Chemical',
            EquipmentType: 'Portable Extinguishers',
        },
        {
            id: 14,
            HealthClassification: 'ABC/Dry Chemical',
            EquipmentType: 'Portable Extinguishers',
        },
        {
            id: 15,
            HealthClassification: 'ABC/Dry Chemical',
            EquipmentType: 'Portable Extinguishers',
        },
        {
            id: 16,
            HealthClassification: 'ABC/Dry Chemical',
            EquipmentType: 'Portable Extinguishers',
        },
        {
            id: 17,
            HealthClassification: 'ABC/Dry Chemical',
            EquipmentType: 'Portable Extinguishers',
        },
        {
            id: 18,
            HealthClassification: 'ABC/Dry Chemical',
            EquipmentType: 'Portable Extinguishers',
        },
        {
            id: 19,
            HealthClassification: 'ABC/Dry Chemical',
            EquipmentType: 'Portable Extinguishers',
        },
        {
            id: 20,
            HealthClassification: 'ABC/Dry Chemical',
            EquipmentType: 'Portable Extinguishers',
        }
    ]
}