import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";

import { Button } from "primereact/button";
import MultiSelect from "components/common/MultiSelect/MultiSelect";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { useDispatch } from "react-redux";
import { useAppSelector } from "redux/hooks";
import { getSiteCustomerList, setSiteCustomerList } from "redux/pages/Administration/Site/siteSlice";
import InformationBox from "components/common/Message/InformationBox/InformationBox";

interface Props {
  showModal: boolean;
  setShowModal: (state: boolean) => void;
  selectedRows: any;
  updateSiteMapCustomer: (siteId: any, data: any) => void;
}

export const SiteMapCustomer: React.FC<Props> = ({
  showModal = false,
  setShowModal = () => { },
  selectedRows = [],
  updateSiteMapCustomer = () => { },
}) => {

  const siteCustomerMapForm: any = useRef();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
    },
  });

  const customerList = useAppSelector((state) => state.site.siteCustomerList);
  const [siteName, setSiteName] = useState<any>();
  const [message, setMessage] = useState("");
  const [displayInfoModal, setDisplayInfoModal] = useState(false);
  const [customerListData, setCustomerListData] = useState<any>([]);
  const [uniqueSiteId, setUniqueSiteId] = useState<any>();
  const [searchKeyword, setSearchKeyword] = useState<any>("");
  const dispatch = useDispatch<any>();
  const selectedCustomer = useRef<any>();
  const checkedRows = useRef<any>();
  const disabledRows = useRef<any>();

  useEffect(() => {
    dispatch(getSiteCustomerList(selectedRows[0]?.uniqueSiteId));
    setSiteName(selectedRows[0].siteName + " (" + selectedRows[0].siteNumber + ")");
    setUniqueSiteId(selectedRows[0]?.uniqueSiteId);
  }, [dispatch, selectedRows]);

  useEffect(() => {
    if (customerList?.length > 0 && showModal === true) {
      let siteArray: any = [];
      customerList.map((item: any) => {
        return siteArray.push({
          id: item.uniqueCustomerId,
          siteName: item.customerName,
          checked: item.isSelected,
          selected: false,
          disabled: item.isDisabled,
        });
      });
      setCustomerListData(siteArray);
    } else {
      setCustomerListData([]);
    }
  }, [customerList, selectedRows, showModal]);

  const mapCustomerSearchList = () => {
    if (searchKeyword.length === 0) {
      let customerNewList: any = [];
      customerList.map((item: any) => {
        return customerNewList.push({
          id: item.uniqueCustomerId,
          siteName: item.customerName,
          checked: item.isSelected,
          selected: false,
          disabled: item.isDisabled,
        });
      });
      setCustomerListData(customerNewList);
    } else {
      dispatch(getSiteCustomerList(selectedRows[0]?.uniqueSiteId, searchKeyword));
    }
    setSearchKeyword("");
  };

  const searchSiteByEnterKey = (e: any) => {
    if (e.keyCode === 13) {
      mapCustomerSearchList();
    }
  };

  const formSubmitHandler = (data: any) => {
    siteCustomerMapForm.current.requestSubmit();
  };

  const updateSelectedCustomers = () => {

      let customerIds: any = [];
      let allCheckedCustomers = [...checkedRows.current, ...disabledRows.current];
      allCheckedCustomers.map((item: any) => {
        customerIds.push(item.id);
        return null;
      });
      const data = {
        CustomerIds: customerIds
      };
      updateSiteMapCustomer && updateSiteMapCustomer(uniqueSiteId, data);
    
  };

  const renderFooter = () => {
    return (
      <>
        <Button
          label="Cancel"
          onClick={() => {
            setShowModal(false);
            setCustomerListData([]);
            dispatch(setSiteCustomerList([]));
          }}
          className="cancel btn"
        />
        {!(selectedRows[0]?.status === 'Closed' || selectedRows[0]?.status === 'Blocked') &&
          <Button label="Save Changes" className="update btn" autoFocus onClick={formSubmitHandler} />}
      </>
    );
  };

  const onError = (errors: any, e: any) => { };

  return (
    <>
      <Dialog
        header="Map Customers"
        visible={showModal}
        style={{ width: "40vw" }}
        onHide={() => {
          setShowModal(false);
          setCustomerListData([]);
          dispatch(setSiteCustomerList([]));
        }}
        footer={renderFooter()}
        className="site-modal selectsite"
      >
        <div className="content-box">
          <div className="header d-flex align-items-center">
            <span className="title">Site:</span>
            <span className="value">{siteName}</span>
          </div>
          <div className="sub-header">
            Note: New Customer(s) required for Site-Customer mapping can be found through Search option
          </div>
          <div className="search-bar text-center">
            <div className="p-input-icon-right inputgroup">
              <i className="pi pi-search" onClick={mapCustomerSearchList} />
              <InputText
                onChange={(event) => setSearchKeyword(event?.target.value)}
                onKeyDown={searchSiteByEnterKey}
                placeholder="Search Customer Number / Name"
              />
            </div>
          </div>
        </div>
        <form
          onSubmit={handleSubmit(updateSelectedCustomers, onError)}
          ref={siteCustomerMapForm}
        >
          <div className="row px-2 pt-1">
            <div className="field col-12 md:col-2 mb-0 py-0">
              <MultiSelect
                list={customerListData}
                getCheckedItems={(checkedItems) => {
                  checkedRows.current = checkedItems;
                }}
                getSelectedItems={(selectedItems) => {
                  selectedCustomer.current = selectedItems;
                }}
                getDisabledItems={(disabledItems) => {
                  disabledRows.current = disabledItems;
                }}
                headerName="Customer Name"
              />
            </div>
          </div>
        </form>
      </Dialog>
      <InformationBox
        showInfoModal={displayInfoModal}
        setShowInfoModal={setDisplayInfoModal}
        message={message}
      />
    </>
  );
};

export default SiteMapCustomer;
