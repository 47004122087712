import { Button } from 'primereact/button'
import React from 'react'
import { BsFillPencilFill, BsPlusLg, BsTrashFill } from 'react-icons/bs'

const InspectionFieldsDocument = () => {
  return (
    <>
      <h4 className="doc-title mb-2">Inspection Fields</h4>

      <p>
        Inspection Fields are a set of fields that are defined to record the details of inspection.
        This option allows the Administrator to create a dynamic tree view structure detailing the Equipment 
        Group and Inspection information. Using this tree view, user can explore the Inspection Equipment Group
        and create nodes/fields dynamically.
      </p>

      <p>
      These Inspection fields are then mapped against corresponding fields required for the statutory Reports 
      through Report Mapping process.
      </p>

      <p>
        By default, two root nodes, 'Equipment Group and Inspection' and 'Test Results', will be displayed with 
        sub node 'Equipment' under the root node 'Test Results'. These root nodes and the sub node cannot be deleted. 
        Nodes that have child nodes with values/fields in it cannot be deleted.
      </p>

      <p>
        Administrators can add/edit/delete the required sub nodes using the corresponding <span className="doc-subtitle">Add</span>{" "}
        <Button className="doc-button-icon">
          <BsPlusLg className="icon" title="Add" />
        </Button>{" "} , <span className="doc-subtitle">Edit</span>{" "}
        <Button className="doc-button-icon">
          <BsFillPencilFill className="icon" title="Edit"/>
        </Button> {' '} and <span className="doc-subtitle">Delete</span>{" "}
        <Button className="doc-button-icon">
          <BsTrashFill className="icon" title="Delete" />
        </Button>{" "}icons displayed on the left section of the page. On expanding this root node all the defined sub nodes 
        (if any) will be listed in the tree view.  The tree view/nodes can be expanded or collapsed by clicking arrow marks
         displayed beside nodes.
      </p>

      <p>
        On selecting the required node from the tree view structure, the associated details corresponding to the selected node
        will be displayed in the right side of the page with add, edit and delete icons for each Inspection field (if any). 
        Administrators can add/edit/delete Inspection data fields to this section of the page.
      </p>

      <p><span className="doc-subtitle"><b>Add: </b></span>
        <Button className="doc-button-icon">
          <BsPlusLg className="icon" title="Add" />
        </Button>{" "}</p>

      <p>
        Inspection Field(s) can be created by clicking the <span className="doc-subtitle">Add </span>    
        <Button className="doc-button-icon">
          <BsPlusLg className="icon" title="Add" />
        </Button>{" "} icon. The Add Inspection Field window will display two radio buttons Data Item and Data Group. 
        Data Item option is used to define Name, Input Type and Field Type selection like Text Box, Date Picker, Text Area,
        Radio buttons, Check Box(s) and its related details. Data Group option is used to define a Data group in the corresponding
        Inspection node. Enter the required details and click Save button.
      </p>

      <p><span className="doc-subtitle"><b>Edit: </b></span>
        <Button className="doc-button-icon">
          <BsFillPencilFill className="icon" title="Edit" />
        </Button> {' '}</p>

      <p>
        Edit Inspection data field(s) by selecting it and then clicking the <span className="doc-subtitle">Edit </span>
        <Button className="doc-button-icon">
          <BsFillPencilFill className="icon" title="Edit"/>
        </Button> {' '} icon associated to it. Make necessary modifications and click Save Changes button to save the changes.
      </p>

      <p><span className="doc-subtitle"><b>Delete: </b></span> 
      <Button className="doc-button-icon">
          <BsTrashFill className="icon" title="Delete" />
        </Button>{" "}</p>

      <p>
        Delete Inspection data field(s) by selecting it and then clicking the <span className="doc-subtitle">Delete </span>  
        <Button className="doc-button-icon">
          <BsTrashFill className="icon" title="Delete"/>
        </Button>{" "}icon associated to it.
      </p>

      <p><span className="doc-subtitle"><b>Set as Default: </b></span></p>

      <p>
        By clicking <span className="doc-subtitle">Set as Default </span> button, administrators can set default values to the fields. 
        This option is only available in <span className="doc-subtitle">Questions </span> tab.
      </p>

      <p>
        The Tree view structure and its corresponding pages can be accessed through menu option <span className="doc-subtitle">
        Inspection Tasks: Inspection Details </span>  where the user can enter and save the fields corresponding to Equipment 
        Group details.
      </p>
    </>
  );
}
export default InspectionFieldsDocument;
