import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { zlink, baseURL } from "../../../../../service/HttpService";
import { AppThunk } from "../../../../store";

export interface HealthClassificationState {
  errors: any;
  healthClassificationList: any;
  healthClassificationPageDetails: any;
  healthClassificationDetails: any;
  healthClassifictionInUse: any;
}

let initialState: HealthClassificationState = {
  errors: "",
  healthClassificationList: [],
  healthClassificationPageDetails: [],
  healthClassificationDetails: [],
  healthClassifictionInUse: "",
};

const HealthClassificationSlice = createSlice({
  name: "healthClassification",
  initialState,
  reducers: {
    setErrors: (state, { payload }: PayloadAction<any>) => {
      state.errors = payload;
    },
    setHealthClassificationList: (state, { payload }: PayloadAction<any>) => {
      state.healthClassificationList = payload;
    },
    setHealthClassificationPageDetails: (
      state,
      { payload }: PayloadAction<any>
    ) => {
      state.healthClassificationPageDetails = payload;
    },
    setNewHealthClassificationData: (
      state,
      { payload }: PayloadAction<any>
    ) => {
      state.healthClassificationList.unshift(payload);
    },
    setUpdatedHealthClassification: (
      state,
      { payload }: PayloadAction<any>
    ) => {
      let currentIndex = state.healthClassificationList.findIndex(
        (item: any) =>
          item.uniqueHealthClassificationId ===
          payload?.uniqueHealthClassificationId
      );
      state.healthClassificationList = state.healthClassificationList.filter(
        (item: any) =>
          item.uniqueHealthClassificationId !==
          payload?.uniqueHealthClassificationId
      );
      state.healthClassificationList.splice(currentIndex, 0, payload);
    },
    setDeletedHealthClassificationList(state, { payload }: PayloadAction<any>) {
      state.healthClassificationList = state.healthClassificationList.filter(
        (item: any) => item.uniqueHealthClassificationId !== payload
      );
    },
    setHealthClassificationDetails: (
      state,
      { payload }: PayloadAction<any>
    ) => {
      state.healthClassificationDetails = payload;
    },
    setHealthClassifictionInUse: (state, { payload }: PayloadAction<any>) => {
      state.healthClassifictionInUse = payload;
    },
  },
});

export const getHealthClassificationList =
  (selectedCountry: any, currentStart: any, keyword: any = "", sortField: any = "", sortOrder: any = "", setShowGrid?): AppThunk =>
  async (dispatch) => {
    try {
      let isAllDetails: boolean = false;
      if (currentStart === 0)
       isAllDetails = true;
      const res = await zlink.post(
        `${baseURL}api/v1/health/healthclassificationlist?keyword=${keyword}&currentstart=${currentStart}&sortcolumn=${sortField}&sortorder=${sortOrder}&isAllDetails=${isAllDetails}`,
        selectedCountry
      );
      if (res.status === "done") {
        dispatch(
          setHealthClassificationList(res?.data?.healthClassificationDetails)
        );
        dispatch(setHealthClassificationPageDetails(res?.data?.pageDetails));
        setShowGrid(true);
      } else {
        dispatch(setErrors(res.error));
      }
    } catch (error) {
      dispatch(setErrors(error));
    }
  };
export const onAddHealthClassification =
  (
    body: any,
    uniqueCountryId,
    setMessage,
    setDisplayInfoModal,
    setShowAddModal
  ): AppThunk =>
  async (dispatch) => {
    try {
      const res = await zlink.post(
        `${baseURL}api/v1/country/${uniqueCountryId}/healthclassifications/add`,
        body
      );
      if (res.status === "done") {
        dispatch(setNewHealthClassificationData(res.data));
        setMessage("Health Classification added");
        setShowAddModal(false);
        setDisplayInfoModal(true);
      } else {
        dispatch(setErrors(res.error));
        setMessage(res.message);
        setDisplayInfoModal(true);
      }
    } catch (error) {
      dispatch(setErrors(error));
    }
  };
export const onEditHealthClassification =
  (
    body: any,
    uniqueCountryId,
    uniqueHealthClassificationId,
    setMessage,
    setDisplayInfoModal,
    setShowEditModal
  ): AppThunk =>
  async (dispatch) => {
    try {
      const res = await zlink.post(
        `${baseURL}api/v1/country/healthclassifications/${uniqueHealthClassificationId}/edit`,
        body
      );
      if (res.status === "done") {
        dispatch(setUpdatedHealthClassification(res.data));
        setMessage("Health Classification updated");
        setShowEditModal(false);
        setDisplayInfoModal(true);
      } else {
        dispatch(setErrors(res.error));
        setMessage(res.message);
        setDisplayInfoModal(true);
      }
    } catch (error) {
      dispatch(setErrors(error));
    }
  };
export const getHealthClassificationDetails =
  (uniqueHealthClassificationId): AppThunk =>
  async (dispatch) => {
    try {
      const res = await zlink.get(
        `${baseURL}api/v1/country/healthclassifications/${uniqueHealthClassificationId}`
      );
      if (res.status === "done") {
        dispatch(setHealthClassificationDetails(res.data));
      } else {
        dispatch(setErrors(res.error));
      }
    } catch (error) {
      dispatch(setErrors(error));
    }
  };
export const deleteHealthClassification =
  ({
    uniqueHealthClassificationId,
    setSelectedRows,
    setMessage,
    setDisplayInfoModal,
  }): AppThunk =>
  async (dispatch) => {
    try {
      const res = await zlink.post(
        `${baseURL}api/v1/country/healthclassifications/${uniqueHealthClassificationId}/remove`,
        null
      );
      if (res.status === "done") {
        dispatch(
          setDeletedHealthClassificationList(uniqueHealthClassificationId)
        );
        setSelectedRows([]);
        setMessage("Health Classification deleted");
        setDisplayInfoModal(true);
      } else {
        setSelectedRows([]);
        setMessage(res.message);
        setDisplayInfoModal(true);
      }
    } catch (error) {
      dispatch(setErrors(error));
    }
  };

  export const checkHealthClassifictionInUse = (uniqueHealthClassificationId): AppThunk => async (dispatch) => {
    try {
      const res = await zlink.get(`${baseURL}api/v1/country/healthclassifications/${uniqueHealthClassificationId}/checkinuse`);
      if (res.status === "done") {
        dispatch(setHealthClassifictionInUse(res?.data));
      } else {
        dispatch(setErrors(res.error));
      }
    } catch (error) {
      dispatch(setErrors(error));
    }
  };

export const {
  setErrors,
  setHealthClassificationList,
  setUpdatedHealthClassification,
  setHealthClassificationDetails,
  setNewHealthClassificationData,
  setDeletedHealthClassificationList,
  setHealthClassificationPageDetails,
  setHealthClassifictionInUse
} = HealthClassificationSlice.actions;

export default HealthClassificationSlice.reducer;
