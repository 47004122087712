import CheckBoxGridView from "components/Inspection/ReportMapping/CheckBoxGridView";
import { useEffect, useState, useRef, forwardRef, useImperativeHandle, memo, useCallback } from "react";
import { useAppSelector } from "redux/hooks";
import { useDispatch } from "react-redux";
import {
    clearReportMappingSavedDataForSystem,
    getReportDetailsForSystem,
    onDownloadReportMappingDetailsForSystems,
    onSavecontractReportMappingDataForSystem,
    setDisplayColumns,
    setIsDisplayColumnsReadyToUpdate,
    setReportDetailsForSystem,
    setSelectedDisplayColumns,
} from "../../../../redux/pages/Manage/InspectionSettings/ReportMapping/ReportMappingslice";
import InformationBox from "components/common/Message/InformationBox/InformationBox";
interface ISystem {
    countryId: number;
    groupId: any;
}
const Systems = forwardRef<any, ISystem>(
    (props, ref) => {
        useImperativeHandle(ref, () => ({
            onSaveclick: () => {
                checkBoxGridRef?.current?.onSaveclick()
            },
            onDownloadclick: () => {
                onDownload();
            },
            onShowDisplaySettings: () => {
                checkBoxGridRef?.current?.onShowDisplaySettings();
            }
        }));
        const dispatch = useDispatch<any>();
        const checkBoxGridRef = useRef<any>();
        const [displayInfoModal, setDisplayInfoModal] = useState(false);
        const [message, setMessage] = useState("");
        const [reportRowData, setReportRowData] = useState<any>([]);
        const [selectedColumns, setSelectedColumns] = useState([]);

        const reportDetails: any = useAppSelector((state) => state.reportMapping.reportDetailsForSystems);
        const savedSystemsReportMappingData: any = useAppSelector((state) => state.reportMapping.reportMappingSavedDataForSystems);
        const columnsReadyToUpdate : any = useAppSelector((state) => state.reportMapping.isDisplayColumnsReadyToUpdate);
        const displayColumns: any = useAppSelector((state) => state.reportMapping.displayColumns)

        useEffect(() => {
            if (props.countryId && props.groupId !== undefined) {
                setReportRowData([])
                dispatch(getReportDetailsForSystem(props.countryId, props.groupId));
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [props.countryId, props.groupId]);
        useEffect(() => {
            if (reportDetails) {
                let convItem = JSON.parse(JSON.stringify(reportDetails?.reportDetails));
                convItem?.forEach(element => {
                    element.header = element?.reportName;
                });
                if(columnsReadyToUpdate){
                    dispatch(setDisplayColumns(convItem))
                }
                setReportRowData(reportDetails?.reportGroupFields);
                dispatch(setReportDetailsForSystem(""));
                // eslint-disable-next-line react-hooks/exhaustive-deps
            }
        }, [reportDetails]);

        useEffect(() => {
            if(displayColumns && displayColumns.length) {
              if(columnsReadyToUpdate)
              dispatch(setSelectedDisplayColumns(displayColumns));
              dispatch(setIsDisplayColumnsReadyToUpdate(false))
            }
          },[displayColumns])

        useEffect(() => {
            if (savedSystemsReportMappingData) {
                setDisplayInfoModal(true);
                setMessage("System Report Mapping Saved");
                dispatch(clearReportMappingSavedDataForSystem(""));
                // eslint-disable-next-line react-hooks/exhaustive-deps
            }
        }, [savedSystemsReportMappingData]);
        const onSaveContractReportMapping = (data: any) => {
            const body = {
                reportGroupFields: data,
            };
            dispatch(onSavecontractReportMappingDataForSystem(props.countryId, props.groupId, body));
        };

        const getColumns = useCallback((columns) => {
            setSelectedColumns(columns);
        }, []);

        const onDownload = () => {
            if (!selectedColumns?.length) return;
            let reportIds: any = [];
            selectedColumns.forEach((element: any) => {
                reportIds.push(element.reportId)
            });
            const body = {
                reportIds: reportIds
            }
            dispatch(onDownloadReportMappingDetailsForSystems(props.countryId, props.groupId, body))
        }

        return (
            <>
                <div className="report-mapping-grid">
                    <CheckBoxGridView
                        ref={checkBoxGridRef}
                        gridData={reportRowData}
                        columnData={displayColumns}
                        onSaveCheckBoxData={onSaveContractReportMapping}
                        getColumns={getColumns}
                    />
                </div>
                <InformationBox showInfoModal={displayInfoModal} setShowInfoModal={setDisplayInfoModal} message={message} />
            </>
        );
    });

export default memo(Systems);
