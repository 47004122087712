import React from "react";
import { Button } from "primereact/button";

const PluginsTapBridgeDocument = () => {
    return (
        <>
            <h4 className="doc-title mb-2">
                Plugins - TAP Bridge
            </h4>
            <p></p>
            <p>
                This option is used to upload the latest TAP Bridge file to Tech Advance<sup>+</sup> by Super Administrator using
                <span className="doc-subtitle"> Upload </span> 
                <Button className="doc-button-icon">
                    <i className="pi pi-upload icon" title="Upload" />
                </Button>
                {" "} icon. 
                This can then be downloaded to Branch Administrator/Technician's laptop through 
                <span className="doc-subtitle"> Download </span> 
                <Button className="doc-button-icon">
                    <i className="pi pi-download icon" title="Download"/>
                </Button>
                {" "} button.
            </p>

            <p>
                The Super Administrator can update the latest version of the TAP Bridge file by selecting the 
                <span className="doc-subtitle"> Upload </span>
                <Button className="doc-button-icon">
                    <i className="pi pi-upload icon" title="Upload" />
                </Button>
                {" "} icon displayed against it. 
                On selecting <span className="doc-subtitle"> Upload </span>
                <Button className="doc-button-icon">
                    <i className="pi pi-upload icon" title="Upload"/>
                </Button> 
                {" "} icon, TAP Bridge window will be displayed with Tap Bridge File, 
                Version and Minimum Compatible Version fields loaded by default. 
                Browse for the zip file to be uploaded by selecting Browse button, 
                enter description if necessary and click Upload button to upload the file. 
                The details: 'Selected File'| 'Version' of the uploaded file (if any) 
                will be displayed as read only.
            </p>

            <p>
                The updated file can be downloaded by the Technician or Branch Administrator by selecting the 
                <span className="doc-subtitle"> Download </span> 
                <Button className="doc-button-icon">
                    <i className="pi pi-download icon" title="Download"/>
                </Button> 
                {" "} icon. Technicians and Branch Administrators can download the TAP Bridge whenever 
                the TAP Bridge file version in their laptop gets expired or is of an older version. 
                The Version and Minimum Compatible Version of the application is verified while downloading 
                the file and warning messages will be displayed.
            </p>


        </>
    );
}

export default PluginsTapBridgeDocument;