import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../redux/hooks";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { Checkbox } from "primereact/checkbox";
import { ScrollPanel } from "primereact/scrollpanel";
import InformationBox from "components/common/Message/InformationBox/InformationBox";
import ConfirmationBox from "components/common/Message/ConfirmationBox/ConfirmationBox";

interface Props {
    showModal: boolean;
    setShowModal: (state: boolean) => void;
    selectedRows: any;
    getInspectionTechnicians: (data: any) => void;
    onQuitTask: (data: any) => void;
}

export const QuitTask: React.FC<Props> = ({ 
    showModal = false, 
    setShowModal = () => {}, 
    selectedRows = [],
    getInspectionTechnicians = () => {},
    onQuitTask = () => {}
}) => {

    const [message, setMessage] = useState("");
    const [displayInfoModal, setDisplayInfoModal] = useState(false);
    const [displayConfirmModal, setDisplayConfirmModal] = useState(false);
    const [allChecked, setAllChecked] = useState(false);
    const technicians = useAppSelector( (state) => state.inspectionTask.inspectionTaskTechnicians);
    const [selectedTechnicians, setSelectedTechnicians] = useState<any>([]);

    useEffect(() => {
        if (selectedRows && selectedRows.length) {
            getInspectionTechnicians({uniqueInspectionTaskId: selectedRows[0]?.uniqueInspectionTaskId});
        }
    }, [selectedRows]);

    const onTechnicianChange = (e: { value: any, checked: boolean }) => {
        let users = [...selectedTechnicians];
        if (e.checked) {
            users.push(e.value);
        } else {
            users.splice(users.indexOf(e.value), 1);
            setAllChecked(false);
        }
        if (users.length === technicians.length) {
            setAllChecked(true);
        }
        setSelectedTechnicians(users);
    }

    const handleSubmit = () => {
        if (selectedTechnicians.length) {
            let techniciansNames = '';
            selectedTechnicians.map((element, currentIndex) => {
                let item = technicians.find(tech => (tech.id === element));
                if (item && item.name) {
                    if (currentIndex === 0) {
                        techniciansNames += item.name;
                    }
                    else {
                        techniciansNames += " ," + item.name;
                    }
                }
            });
            let toasterMessage = `Unsynced data from the TAP App will be lost, are you sure want to quit selected Technician(s)`;
            if (techniciansNames !== '') {
                toasterMessage += ` (${techniciansNames})`;
            }
            toasterMessage += '?'
            setMessage(`${toasterMessage}`);
            setDisplayConfirmModal(true);
        } else {
            setMessage("Select User(s)");
            setDisplayInfoModal(true);
        }
    };

    const onConfirmAction = () => {
        let requestData = {
            inspectionTaskId: selectedRows[0]?.uniqueInspectionTaskId,
            body: {
                uniqueUserIds: selectedTechnicians
            }
        };
        onQuitTask(requestData);
    }

    const handleAllCheckClick = (e: any) => {
        setAllChecked(e.checked);
        let techs: any = [];
        if (e.checked) {
            for (let i = 0; i < technicians.length; i++) {
                techs.push(technicians[i].id)
            }
        }
        else {
            techs = [];
        }
        setSelectedTechnicians(techs);
    };

    return (
        <>
            <Dialog header="Quit Task" visible={showModal} style={{ width: "30vw" }} onHide={() => setShowModal(false)} className="display-settings">
                <div className="row px-2 custom-float-field ">                 
                    <div className="field col-12 md:col-2 mb-0">
                        <div className="field-checkbox site-header">
                            <label className="ml-0 mb-3">
                                Inspection Task : <b>{selectedRows[0]?.inspectionTaskName}</b>
                            </label>
                        </div>
                        <div className="quit-task">                           
                            <ScrollPanel className="quit-task-scroll">
                            <div className={classNames('field-checkbox site-header selected', { 'selected': allChecked })}>
                            <Checkbox checked={allChecked} onChange={handleAllCheckClick} />
                            <label htmlFor={'staff'}>User</label>
                            </div>
                                {(technicians && technicians.length) ?
                                    (technicians.map((item: any) => (
                                        <div className={classNames("field-checkbox site-item")} key={item.id}>
                                            <Checkbox inputId={'staff' + item.id} name={item.name} value={item.id} onChange={onTechnicianChange} checked={selectedTechnicians.indexOf(item.id) !== -1} disabled={item.disabled} />
                                            <label htmlFor={'staff' + item.id}>{item.name}</label>
                                        </div>
                                    ))) 
                                    : 
                                    null
                                }

                            </ScrollPanel>
                        </div>
                    </div>

                    { technicians && technicians.length ? 
                        (
                            <div className="footer text-left">
                                <Button label="Close" onClick={() => setShowModal(false)} className="cancel-btn" />
                                <Button label="Quit" onClick={handleSubmit} className="submit-btn" />
                            </div>
                        ) 
                        : 
                        null 
                    }

                    
                </div>
            </Dialog>

            <InformationBox
                showInfoModal={displayInfoModal}
                setShowInfoModal={setDisplayInfoModal}
                message={message}
            />

            <ConfirmationBox
                showConfirmModal={displayConfirmModal}
                setShowConfirmModal={setDisplayConfirmModal}
                confirmAction={onConfirmAction}
                message={message}
            />
        </>
    );
};

export default QuitTask;
