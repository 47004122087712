import React from 'react';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

interface Props {
    showInfoModal: boolean,
    setShowInfoModal: (state: boolean) => void,
    okOnClick?:() => void,
    message:any,
    additionalMessage?:any,
    onHide?:() => void,
    setAdditionalMessage?: (state: string) => void,
}

export const InformationBox: React.FC<Props> = ({
    showInfoModal = false,
    setShowInfoModal = () => { },
    okOnClick=()=>{},
    message="",
    additionalMessage="",
    setAdditionalMessage = () => { },
    onHide = () => { },
}) => {

    const renderHeader = () => {
        return(
            <>
                Tech Advance<sup>+</sup>
            </>
        )
    }

    const renderFooter = () => {
        return (
            <>
                <Button label="OK"
                    onClick={() => {
                        okOnClick();
                        setShowInfoModal(false);
                        setAdditionalMessage("");
                    }}
                    className='submit-btn' autoFocus />
            </>
        );
    }

    return (
        <Dialog
            header={renderHeader()}
            visible={showInfoModal}
            style={{ width: '35vw' }}
            footer={renderFooter()}
            onHide={() => {
                setShowInfoModal(false);
                setAdditionalMessage("");
                onHide();
            }}
            className='delete-modal'
        >
            <p className='content'>{message}</p>
            {additionalMessage?(
                <p>{additionalMessage}</p>
            ):null}    
        </Dialog>
    );
}

export default InformationBox;