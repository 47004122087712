import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

interface Props {
    header: string,
    showModal: boolean,
    setShowModal: (state) => void,
    children: React.ReactNode,
    style?: object,
    OnSaveClick: Function | any,
    isAdd?: boolean,
    buttonDisable?: boolean,
    buttonCondition?: boolean
}

export const UploadModal: React.FC<Props> = ({
    header = '',
    showModal = false,
    setShowModal = () => { },
    children = null,
    style = {},
    OnSaveClick,
    buttonDisable=false,
    buttonCondition ,
}) => {

    const renderFooter = () => {
        return (
            <div>
                <Button label="Cancel" className="cancel-btn" onClick={() => setShowModal(false)} />
                {buttonCondition == false ?
                    <Button label="Save Changes" className="save-btn" onClick={OnSaveClick} disabled={buttonDisable}/>:
                    <Button label="Upload" className="save-btn" onClick={OnSaveClick} disabled={buttonDisable}/>
                }
            </div>
        );
    }

    return (
        <Dialog
            header={header}
            visible={showModal}
            onHide={() => setShowModal(false)}
            breakpoints={{ '960px': '75vw' }}
            style={style}
            footer={renderFooter()}
            className='add-overlay selectsite'
        >
            {children}
        </Dialog>
    );
}

export default UploadModal;