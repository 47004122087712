import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { showUI } from 'service/GeneralUtility';
import { BiRefresh } from "react-icons/bi";
//components
import InspectionForms from "components/common/Forms/InspectionForm/InspectionForms";
import InformationBox from "components/common/Message/InformationBox/InformationBox";
import ConfirmationBox from "components/common/Message/ConfirmationBox/ConfirmationBox";
//redux
import { useDispatch } from "react-redux";
import { setIsReportUpdated } from "redux/pages/Inspection/InspectionTask/inspectionTaskSlice";
import { getInspectionContracts, onGetInspectionContractFieldValues, onGetRefreshInspectionContract, onSaveInspectionContract, setInspectionContractFieldValues, setInspectionContracts, setUpdatedContractDatafields } from "redux/pages/Inspection/InspectionTask/InspectionDetails/InspectionDetails/InspectionDetailsSlice";
import { useAppSelector } from "redux/hooks";
//utility
import { addDefaultDataValues, convertInspectionFields } from "pages/ManageInspection/InspectionFields/Utility/InspectionFieldUtility";
import { ProgressSpinner } from "primereact/progressspinner";

interface ContractPropType {
    selectedTask: any;
    setIsValueChanged?: any;
    displayConfirmModal?: any;
    setDisplayConfirmModal?: any;
    cancelUpdate?: () => void;
    confirmMessage?: any;
    hideConfirmModal?: any;
    reportBtnClicked?: any;
}

const Contracts: React.FC<ContractPropType> = (props) => {

    const {
        selectedTask,
        setIsValueChanged,
        displayConfirmModal,
        setDisplayConfirmModal,
        cancelUpdate = () => { },
        confirmMessage,
        hideConfirmModal,
        reportBtnClicked } = props;

    const inspectionFormRef = useRef<any>(null);
    const [inspectionFormSetup, setInspectionFormSetup] = useState<any>([]);
    const [message, setMessage] = useState<string>("");
    const [displayInfoModal, setDisplayInfoModal] = useState(false);
    const [showProgress, setShowProgress] = useState(false);//progress bar
    const [branchOffice, setBranchOffice] = useState({ uniueDatafieldId: '', dataFieldLookupId: -1 });
    const [resetFormField, setResetFormField] = useState<boolean>(false);
    const [fieldFocus, setFieldFocus] = useState<boolean>(false);

    const dispatch = useDispatch<any>();
    const inspectionContracts = useAppSelector((state) => state.inspectionDetails.inspectionContracts);
    const updatedDatafields = useAppSelector((state) => state.inspectionDetails.updatedContractDatafields);
    const inspectionContractFieldValues = useAppSelector((state) => state.inspectionDetails.inspectionContractFieldValues);
    const isReportUpdated = useAppSelector((state) => state.inspectionTask.isReportUpdated);
    const userPrivileges = useAppSelector((state) => state.user.userPrivileges);
    const typeOfTrigger = useAppSelector((state) => state.inspectionDetails.typeOfTriggerValue);

    const body = {
        uniqueTaskId: selectedTask?.uniqueInspectionTaskId,
        uniqueSystemId: selectedTask?.uniqueSystemId,
    }

    useEffect(() => {
        if (inspectionFormSetup?.inspectionGroupFields) {
            setShowProgress(true);
            dispatch(onGetInspectionContractFieldValues(selectedTask?.uniqueInspectionTaskId, setShowProgress, "FIELD_VALUE"));
        }

    }, [inspectionFormSetup]);

    useEffect(() => {
        if (inspectionContractFieldValues?.length && typeOfTrigger === "FIELD_VALUE") {
            inspectionFormSetup?.inspectionGroupFields?.forEach(element => {
                if (element?.dataFieldName === "Branch Office") {
                    let data = element?.inspectionGroupFields[0];
                    let slectedItem: any = [];
                    if (inspectionContractFieldValues?.length)
                        slectedItem = inspectionContractFieldValues?.filter((item) => item?.uniqueDataFieldId === data?.uniqueDataFieldId);
                    if (slectedItem?.length) {
                        let validatedValues = data?.validatedValues?.filter((item) => item?.dataFieldLookupId === slectedItem[0]?.fieldValue);
                        if (validatedValues?.length)
                            setBranchOffice((pre) => ({ ...pre, uniueDatafieldId: data?.uniqueDataFieldId, dataFieldLookupId: slectedItem[0].fieldValue }))
                        else
                            setBranchOffice((pre) => ({ ...pre, uniueDatafieldId: data?.uniqueDataFieldId, dataFieldLookupId: -1 }))
                    } else {
                        setBranchOffice((pre) => ({ ...pre, uniueDatafieldId: data?.uniqueDataFieldId, dataFieldLookupId: -1 }))
                    }
                }
            });
        }
    }, [inspectionContractFieldValues,typeOfTrigger])

    useEffect(() => {
        if (selectedTask) {
            dispatch(getInspectionContracts(selectedTask?.uniqueInspectionTaskId, selectedTask?.uniqueSystemId));
            dispatch(setIsReportUpdated(false));
        }
    }, [selectedTask, isReportUpdated, resetFormField]);

    useEffect(() => {
        if (inspectionContracts) {
            let items = JSON.parse(JSON.stringify(inspectionContracts));
            let convFields = convertInspectionFields(items)
            setInspectionFormSetup(convFields);
            dispatch(setInspectionContracts(""));
        }
    }, [inspectionContracts]);

    useEffect(() => {
        if (updatedDatafields) {
            let conv = JSON.parse(JSON.stringify(inspectionContractFieldValues))
            const result = addDefaultDataValues(conv, updatedDatafields?.fieldDetails);
            dispatch(setInspectionContractFieldValues(result));
            if (!reportBtnClicked) {
                setMessage("Contract updated");
                setDisplayInfoModal(true);
            }
            cancelUpdate();
            dispatch(dispatch(setUpdatedContractDatafields("")));
        }
    }, [updatedDatafields])

    const onInspectionSubmit = useCallback((data) => {
        const body = {
            fieldDetails: data,
        }
        let taskWithOutBranch = (selectedTask.hasBranchMapping && !selectedTask.hasBranch) ? true : false;
        setShowProgress(true);
        dispatch(onSaveInspectionContract(selectedTask?.uniqueInspectionTaskId, selectedTask?.uniqueSystemId, body, taskWithOutBranch, setShowProgress));
        setFieldFocus(false);
    }, []);

    const onRefreshTrigger = () => {
        setShowProgress(true);
        dispatch(onGetRefreshInspectionContract(selectedTask?.uniqueInspectionTaskId, setShowProgress, "REFRESH_VALUE"));
        inspectionFormRef?.current?.onBranchOffieSelectionInDynamically(branchOffice.uniueDatafieldId, branchOffice.dataFieldLookupId);
    }

    const onSelectionChanged = useCallback((data, id) => {
        setBranchOffice((pre) => ({ ...pre, uniueDatafieldId: data?.uniqueDataFieldId, dataFieldLookupId: id }))
    }, [])

    const checkIsValueChanged = useCallback((e) => {
        if (e) {
            setIsValueChanged(true);
            setFieldFocus(false);
        }
    }, [])

    const onCancel = () => {
        if (reportBtnClicked) {
            setResetFormField(!resetFormField);
        }
        cancelUpdate();
    }

    const onConfirmClick = () => {
        inspectionFormRef?.current?.requestSubmit();
        setDisplayConfirmModal(false);
    }

    const onInspectionFieldError = (error) => {
        if(error) {
            setMessage(error);
            setDisplayInfoModal(true);
            setFieldFocus(true);
        }
    };

    return (
        <>
            {showProgress &&
                (
                    <div className="pre-loader inspection-contracts">
                        <ProgressSpinner />
                    </div>
                )
            }
            <div className="contracts-parent ">
                <div className={`questions-wrap`}>
                    {selectedTask?.status === "Active" &&
                        (
                            <div className="question-refresh action-btns">
                                <Button
                                    className="button-icon"
                                    tooltip="Refresh"
                                    onClick={() => onRefreshTrigger()}
                                    tooltipOptions={{ position: "top" }}>
                                    <BiRefresh className="icon" />
                                </Button>
                            </div>
                        )
                    }
                    <InspectionForms
                        type='CONTRACT'
                        ref={inspectionFormRef}
                        isAddAction={true}
                        isCheckedAction={true}
                        showAction={false}
                        formElements={inspectionFormSetup}
                        inspectionFieldValues={inspectionContractFieldValues}
                        body={body}
                        onInspectionSubmit={onInspectionSubmit}
                        checkIsValueChanged={checkIsValueChanged}
                        onSelectionChanged={onSelectionChanged}
                        onInspectionFieldError={onInspectionFieldError}
                        fieldFocus={fieldFocus}
                    />
                    {inspectionFormSetup?.inspectionGroupFields?.length > 0 && selectedTask?.status === "Active" && (
                        <div className="footer-btn text-left">
                            {showUI(userPrivileges, "SIETAP_INSPECTIONDETAILS_CONTRACT_SAVE") &&
                                <Button
                                    label="Save"
                                    className={`save-btn`}
                                    onClick={() => inspectionFormRef?.current?.requestSubmit()}
                                />
                            }
                        </div>
                    )}
                </div>

                <InformationBox
                    showInfoModal={displayInfoModal}
                    setShowInfoModal={setDisplayInfoModal}
                    message={message}
                />

                <ConfirmationBox
                    showConfirmModal={displayConfirmModal}
                    setShowConfirmModal={setDisplayConfirmModal}
                    cancelAction={() => onCancel()}
                    onHideAction={hideConfirmModal}
                    confirmAction={onConfirmClick}
                    message={confirmMessage}
                />
            </div>
        </>
    );
};

export default Contracts;
