import React from 'react'

const ReportsUserActivityReportsDocument = () => {
  return (
    <>        
    <h4 className="doc-title mb-2">User Activity</h4>

    <p>
      This report shows the list of Users with Name, Email address, User Role, 
      Account created on, First Login, Last Login, Status, Sales Office Count, 
      No. of days since Last login and Activity. The report can be filtered by 
      selecting the required criteria, like All Users, Active, Blocked, Deleted, 
      Expired Deleted during a period and Active during a Period etc, from the list. 
      This report is accessible only to Super Administrators.
    </p>

    <p>
      Fields to display in the report can be customized by selecting the Customize button.
    </p>

    </>
  );
}
export default ReportsUserActivityReportsDocument;