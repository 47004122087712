import React, { useState, useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { BsExclamationCircleFill } from "react-icons/bs";

interface Props {
    showModal: boolean;
    setShowModal: (state: boolean) => void;
    onSearch: (data: any) => void;
    cancelAction?:()=>void,
}
type FormData = {
    searchtext: any;
};
export const TextSearch: React.FC<Props> = ({
    showModal = false,
    setShowModal = () => {},
    onSearch = () => {},
    cancelAction=()=>{},

}) => {
  
    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
        watch,
    } = useForm<FormData>({
        defaultValues: {
            searchtext: null,
        },
    });
    const searchText = watch("searchtext");
    const addForm: any = useRef();
    const renderFooter = () => {
        return (
            <>
                <Button label="Cancel" onClick={() =>{cancelAction(); setShowModal(false)} }className="cancel btn" />
                <Button label="OK" className="update btn" autoFocus onClick={formSubmitHandler}/>
            </>
        );
    };

    const getFormErrorMessage = (name) => {
        return (
          errors[name] && (
            <span className="tooltip-text">
              <BsExclamationCircleFill />
              {errors[name].message}
            </span>
          )
        );
      };

      const formSubmitHandler = (data: any) => {
        addForm.current.requestSubmit();
      };

      const onFormSubmit = (data: any) => {
        onSearch && onSearch(searchText)
      };
    
      const onError = (errors: any, e: any) => {};


    return (
        <>
            <Dialog
                header="Search Address"
                visible={showModal}
                style={{ width: "40vw" }}
                onHide={() => setShowModal(false)}
                footer={renderFooter()}
                className="display-settings selectsite"
            >
                <form
                 onSubmit={handleSubmit(onFormSubmit, onError)}
                 ref={addForm}
                >

                    <div className="field col-12 md:col-4 mt-2 pt-3">
                        <span className="p-float-label">
                            <Controller
                                name="searchtext"
                                control={control}
                                rules={{
                                    required: "Enter Address",
                                }}
                                render={({ field, fieldState }) => (
                                    <InputText
                                        id="searchtext"
                                        className={classNames("w-100 error-tooltip", { error: fieldState.invalid })}
                                        {...field}
                                    />
                                )}
                            />
                            <label className="mandatory" htmlFor="searchtext">
                                Address
                            </label>
                            {getFormErrorMessage("searchtext")}
                        </span>
                    </div>


                </form>
            </Dialog>

        </>
    );
};

export default TextSearch;
