import React from 'react'
import { Button } from 'react-bootstrap'

const SearchUserDocument = () => {
  return (
    <>

      <h4 className="doc-title mb-2">Search - User</h4>

      <p>
        User Search option allows the user to search for User.
        User can type minimum three characters of the Users Name in
        the text box provided and select the required User from the list
        displayed. Click on <span className="doc-subtitle"> Search </span> {" "}

        <Button className="doc-button-icon" >
          <i className="pi pi-search icon" title="Search" />
        </Button>{" "}

        icon. The page will be redirected to
        <span className="doc-subtitle"> Administration: Users </span> page where the
        selected User will be listed in the corresponding User Role menu.
      </p>

    </>

  );
}

export default SearchUserDocument;
