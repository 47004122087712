
import zLinkHttpClient from 'zlink-http-client';
import { setIsEmptyServiceCallQueue } from 'redux/common/event/eventControllerSlice';
let alertCounter = 1;
const ActiveRequestQueue: any = [];

const onPreRequest = (requestId: any) => {
  ActiveRequestQueue.push(requestId);
  serviceClassDispatcher(setIsEmptyServiceCallQueue(true));
};

const onPostRequest = (requestId: any) => {
  const index = ActiveRequestQueue.indexOf(requestId, 0);
  if (index > -1) {
    ActiveRequestQueue.splice(index, 1);
  }
  if (ActiveRequestQueue.length <= 0) {
    serviceClassDispatcher(setIsEmptyServiceCallQueue(false));
  }
};

let serviceClassDispatcher: any = null;
let errorCallback = () => {

}
export const setSessionExpiryMessage=(callback:any)=>{
   errorCallback=callback
}

export const zlink = new zLinkHttpClient((error: any) => {
  if (alertCounter === 1 && error.messageCode === 401) {
      alertCounter += 1;
      errorCallback(); 
  } 
}, { "Authorization": sessionStorage.getItem("accesstoken") },onPreRequest, onPostRequest);

export const baseURL = (window as any).envSettings.baseAPIUrl;

export const genericAPIbaseURL = (window as any).envSettings.generalUtilityAPIUrl;

export const setAuthHeader = () => {
  zlink.customHeaders = { "Authorization": sessionStorage.getItem("accesstoken") };
} 

export const setServiceClassDispatcher = (dispatcher:any) => {
  serviceClassDispatcher = dispatcher;
}
