import React, { useEffect, useState } from 'react'
import { useDispatch } from "react-redux";
import { APP_ROUTES } from "constants/AppRoutes";
import { useNavigate } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import Breadcrumb from "components/common/Breadcrumb/Breadcrumb";
import ReportViewer from 'components/common/Report/ReportViewer'
import { useAppSelector } from "../../redux/hooks";
import { createReportPreviewSession, setReportPreviewSession } from "../../redux/pages/Administration/Site/siteSlice";
import moment from 'moment';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import InformationBox from 'components/common/Message/InformationBox/InformationBox';
import { ListGroup } from 'react-bootstrap';
import { RiFileExcel2Line } from 'react-icons/ri';
import { onExportReport } from 'redux/common/report/customizeReportSlice';

const SapImportStatusReport = () => {

	const [reportName, setReportName] = useState<any>(null);
	const [startDate, setStartDate] = useState<any>(new Date().setDate((new Date()).getDate() - 30));
	const [endDate, setEndDate] = useState<any>(new Date());
	const [message, setMessage] = useState("");
	const [displayInfoModal, setDisplayInfoModal] = useState(false);
	const reportPreviewSession = useAppSelector((state) => state.site.reportPreviewSession);

	const dispatch = useDispatch<any>();
	const navigate = useNavigate();

	const viewSapImportStatusReport = () => {
		const reportParameters = {
			reportParameters: {
				"startDate": moment(startDate).format("yyyy-MM-DD").toString(),
				"endDate": moment(endDate).format("yyyy-MM-DD").toString()
			},
			reportType: "SAP_IMPORT_STATUS"
		};
		dispatch(createReportPreviewSession(reportParameters));
	};

	useEffect(() => {
		viewSapImportStatusReport();
		return(()=>{
			dispatch(setReportPreviewSession(""));
		})
	},[])

	useEffect(() => {
		if (reportPreviewSession) {
			setReportName(reportPreviewSession.previewSessionId);
		}
	}, [reportPreviewSession])

	const onApplyDateFilter = () => {
		if (startDate && endDate) {
			if (new Date(startDate) < new Date(endDate)) {
				if ((moment(startDate).diff(moment(endDate), 'years')) === 0) {
					viewSapImportStatusReport();
				} else {
					setDisplayInfoModal(true);
					setMessage('Selected Date range should not exceed one year');
				}
			} else {
				setDisplayInfoModal(true);
				setMessage('Uploaded from Date must be less than Uploaded to Date');
			}
		}
	};

	const exportAsExcel = () => {
		const reportParameters = {
			exportParameters: {
				"startDate": moment(startDate).format("yyyy-MM-DD").toString(),
				"endDate": moment(endDate).format("yyyy-MM-DD").toString()
			},
			exportType: "SAP_IMPORT_STATUS"
		};
		dispatch(onExportReport(reportParameters, "SAP Import Status"));
	};

	return (
		<div className='site-reports site-report-dropdown'>
			<section className="d-flex align-items-center">
				<Breadcrumb
					title="Reports"
					classNames="report-header"
					handleGoBackClick={() => navigate(`/${APP_ROUTES.REPORT_VIEW}`)}
				/>
				<div className="breadcrumb-right-arrow ml-3 mr-3">
					<BsArrowRight className="icon" />
				</div>
				<div className="breadcrumb-sub-page-title">
					<span>SAP Import Status</span>
				</div>
			</section>

			<div className="report-sort-field pt-2 pb-2">
				<div className="report-export-main d-flex">
					<div className="custom-float-field d-flex calender-input">
						<span className="p-float-label ml-2">
							<Calendar
								id="startDate"
								className='w-100'
								dateFormat="yy-mm-dd"
								value={new Date(startDate)}
								onChange={(e) => setStartDate(e.value)} />
							<label htmlFor="calendar">Uploaded from</label>
						</span>
						<span className="p-float-label ml-2">
							<Calendar
								id="endDate"
								className='w-100'
								dateFormat="yy-mm-dd"
								value={new Date(endDate)}
								onChange={(e) => setEndDate(e.value)} />
							<label htmlFor="calendar">Uploaded to</label>
						</span>
						<Button label="Apply" className="submit-btn ml-2" autoFocus onClick={onApplyDateFilter} />
					</div>
					<div className="report-buttons-main d-flex">
						<div className="report-buttons">
						</div>
						<div className='export-report-buttons'>
							<ListGroup horizontal>
								<span className='expor-title'>Export to</span>
								<ListGroup.Item>
									<Button className="export-btn" onClick={() => exportAsExcel()}> <RiFileExcel2Line className="icon" /> Excel </Button>
								</ListGroup.Item>
								{/* <ListGroup.Item>
									<Button className="export-btn"> <BsFileEarmarkPdf className="icon" /> PDF </Button>
								</ListGroup.Item>
								<ListGroup.Item>
									<Button className="export-btn"> <GrDocumentRtf className="icon" /> RTF </Button>
								</ListGroup.Item>
								<ListGroup.Item>
									<Button className="export-btn"> <AiOutlineFileText className="icon" /> Text </Button>
								</ListGroup.Item> */}
							</ListGroup>
						</div>
					</div>
				</div>
			</div>
			<ReportViewer reportId={reportName} />
			<InformationBox
				showInfoModal={displayInfoModal}
				setShowInfoModal={setDisplayInfoModal}
				message={message}
			/>
		</div>
	)
}

export default SapImportStatusReport;