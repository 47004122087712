import React, { memo, useEffect, useRef } from 'react'
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { Controller, useForm } from 'react-hook-form';
//icons
import { BsExclamationCircleFill } from 'react-icons/bs';
//components
import AddModal from 'components/Table/AddModal/AddModal';
import { blockedNonKeyboardCharacters } from 'service/GeneralUtility';

interface EditInspectionGroupSectionPropType {
    showModal: boolean;
    selectedNode?: any;
    onHideModal?: () => void;
    onNodeUpdated?: (body) => void;
}
const EditInspectionGroupSection: React.FC<EditInspectionGroupSectionPropType> = (props) => {
    const { showModal, selectedNode, onHideModal = () => { }, onNodeUpdated = () => { } } = props;
    const addForm = useRef<any>(null);
    const {
        control,
        formState: { errors },
        handleSubmit,
        setValue,
    } = useForm({
        defaultValues: {
            name: "",
        },
    });

    useEffect(() => {
        if (selectedNode) {
            setValue("name", selectedNode?.label)
        }
    }, [selectedNode]);

    const formSubmitHandler = () => {
        addForm?.current?.requestSubmit();
    };

    const onSubmit = (data) => {
        const body = {
            inspectionGroupSectionName: data?.name,
        }
        onNodeUpdated(body);
    }

    const getFormErrorMessage = (name) => {
        return (
            errors[name] && (
                <span className="tooltip-text">
                    <BsExclamationCircleFill />
                    {errors[name].message}
                </span>
            )
        );
    };

    const textValidate = (value) => {
        if (value) {
            let invalidCharacters: any = blockedNonKeyboardCharacters(value);
            if (invalidCharacters != null && invalidCharacters.length > 0) {
                return "Non-Keyboard character(s) " + invalidCharacters.join() + " is not allowed";
            } else {
                return true;
            }
        }
    }

    return (
        <AddModal
            header={"Edit Node"}
            showModal={showModal}
            setShowModal={onHideModal}
            OnSaveClick={formSubmitHandler}
            isAdd={true}
            style={{ width: "37vw", maxHeight: "100%" }}
        >
            <form onSubmit={handleSubmit(onSubmit)} className="p-fluid" ref={addForm}>
                <div className="field col-12 md:col-4 mt-2 pb-5 pt-3">
                    <span className="p-float-label">
                        <Controller
                            name="name"
                            control={control}
                            rules={{
                                required: "This field is required.",
                                validate: (value) => {
                                    return (value.trim().length <= 0? "This field is required." : textValidate(value))
                                },
                                maxLength: {
                                    value : 250,
                                    message : "Maximum 250 characters allowed"
                                }
                            }}
                            render={({ field, fieldState }) => (
                                <InputText
                                    id="name"
                                    className={classNames("w-100 error-tooltip", { error: fieldState.invalid })}
                                    {...field}
                                />
                            )}
                        />
                        <label className="mandatory" htmlFor="name">
                            Name
                        </label>
                        {getFormErrorMessage("name")}
                    </span>
                </div>
            </form>
        </AddModal>
    )
}

export default memo(EditInspectionGroupSection);