import React from "react";
import { Button } from "primereact/button";
import { BsFillPencilFill, BsTrashFill, BsPaperclip, BsSearch, BsSubtract, BsBoxArrowInDownRight, BsZoomIn } from 'react-icons/bs';
import { FaChevronDown, FaCog, FaDownload, FaSave, FaBackward } from "react-icons/fa";
import { BiUnlink } from "react-icons/bi";
import { GiArrowCursor } from "react-icons/gi";
import { RiAlarmWarningLine } from "react-icons/ri";

const InspectionDrawingsDocument = () => {
    return(
        <>
            <h4 className="doc-title mb-2">
                Drawings
            </h4>
            
            <p>
                This option provides access to the Drawings uploaded. 
                On selecting this option, Drawing List page will be displayed listing all the Drawings 
                uploaded for the selected active Inspection Task with Open Drawing and Close button.
                All drawings uploaded for the Selected Site will be listed with details of Floor name, 
                Floor Category, File Name, File and File Size. A drop down to select the Building is 
                provided and on selecting the Building, all Drawings of the selected Building will be listed. 
                Select the required Drawing and click on Open Drawing button to view the Drawing.
            </p>

            <p>
                Assign Block, Select Equipment, Delete, Search Text, Attachments, Delink, Stop Blink, 
                Download, Save, More and also Tabular view of Equipment list options are accessible 
                for users while opening drawings from the Inspection: More: Drawing View.
            </p>

            <p>
                <span className="doc-subtitle">Assign Block: </span>
                Through this <span className="doc-subtitle">Assign Block </span>
                <Button className="doc-button-icon">
                    <BsSubtract className="icon" title="Assign Block" />
                </Button>
                {" "} icon, users can assign equipment to blocks in the Drawings.
            </p>

            <p>
                <span className="doc-subtitle">Select Equipment: </span>
                Through this <span className="doc-subtitle">Select Equipment </span>
                <Button className="doc-button-icon w-36">
                    <GiArrowCursor className="icon" title="Select Equipment" />
                    <FaChevronDown className="caret-small" />
                </Button>
                {" "} icon, users can view & edit the selected equipment from drawing.
            </p>

            <p>
                <span className="doc-subtitle">Delete: </span>
                Through this <span className="doc-subtitle">Delete </span>
                <Button className="doc-button-icon">
                    <BsTrashFill className="icon" title="Delete" />
                </Button>
                {" "} icon, users can delete equipment from Drawing.
            </p>

            <p>
                <span className="doc-subtitle">Search Text: </span>
                Through this <span className="doc-subtitle">Search Text </span>
                <Button className="doc-button-icon">
                    <BsSearch className="icon" title="Search Text" />
                </Button>
                {" "} icon, users can search text from Drawing.
            </p>

            <p>
                <span className="doc-subtitle">Attachments: </span>
                Through this <span className="doc-subtitle">Attachments </span>
                <Button className="doc-button-icon">
                    <BsPaperclip className="icon" title="Attachments" />
                </Button>
                {" "} icon, users can view and add attachments to the selected equipment in the Drawing.
            </p>

            <p>
                <span className="doc-subtitle">Delink: </span>
                Through this <span className="doc-subtitle">Delink </span>
                <Button className="doc-button-icon">
                    <BiUnlink className="icon" title="Delink" />
                </Button>
                {" "} icon, users can unassigned an equipment from the drawing. 
                The unlinked equipment will be moved to the Unassigned tab in tabular view.
            </p>

            <p>
                <span className="doc-subtitle">Stop Blink: </span>
                Through this <span className="doc-subtitle">Stop Blinking </span>
                <Button className="doc-button-icon">
                    <RiAlarmWarningLine className="icon" title="Stop Blink" />
                </Button>
                {" "} icon, users can stop the blinking of all the equipment in the drawing.
            </p>

            <p>
                <span className="doc-subtitle">Download: </span>
                Through this <span className="doc-subtitle">Download </span>
                <Button className="doc-button-icon">
                    <FaDownload className="icon" title="Download" />
                </Button>
                {" "} icon, users can export the drawing in different available formats..
            </p>

            <p>
                <span className="doc-subtitle">Save: </span>
                Through this <span className="doc-subtitle">Save </span>
                <Button className="doc-button-icon">
                    <FaSave className="icon" title="Save" />
                </Button>
                {" "} icon, users can Save the drawing in different available formats.
            </p>

            <p>
                <span className="doc-subtitle">More: </span>
                Through this <span className="doc-subtitle">More </span>
                <Button className="doc-button-icon w-36" title="More" >
                    <FaCog className="icon" />
                    <FaChevronDown className="caret-small" />
                </Button>
                {" "} icon, users can manage the options Show Layer, Hide Layer, Measure Area, Measure Distance, De-Hatch.
            </p>

            <p>
                <span className="doc-subtitle">Back: </span>
                Through this <span className="doc-subtitle">Back </span>
                <Button className="doc-button-icon">
                    <FaBackward className="icon" title="Back"/>
                </Button>
                {" "} icon, Users can go back to Inspection Page.
            </p>

            <p className="doc-subtitle">
                <b>Common:</b>
            </p>
            <p>
                All the equipment assigned to the drawing will be listed under the Common tab. 
                The options to Edit, Delete, view/add Attachments, 
                Show the Drawing, Show Zoomed, and Delink are available under this tab.
            </p>

            <p>
                <span className="doc-subtitle">Edit: </span>
                Selected equipment can be edited by clicking the
                <span className="doc-subtitle"> Edit </span>
                <Button className="doc-button-icon">
                    <BsFillPencilFill className="icon" title="Edit" />
                </Button>{" "} icon.
            </p>

            <p>
                <span className="doc-subtitle">Delete: </span>
                Users can delete Equipment by clicking the
                <span className="doc-subtitle"> Delete </span>
                <Button className="doc-button-icon">
                    <BsTrashFill className="icon" title="Delete" />
                </Button>{" "} icon.
            </p>

            <p>
                <span className="doc-subtitle">Attachments: </span>
                Users can view/add attachments by clicking the
                <span className="doc-subtitle"> Delete </span>
                <Button className="doc-button-icon">
                    <BsPaperclip className="icon" title="Attachments" />
                </Button>{" "} icon.
            </p>

            <p>
                <span className="doc-subtitle">Show Drawing: </span>
                Users can view the selected equipment as blinking in the drawing using the
                <span className="doc-subtitle"> Show Drawing </span>
                <Button className="doc-button-icon">
                    <BsBoxArrowInDownRight className="icon" title="Show Drawing" />
                </Button>{" "} icon.
            </p>

            <p>
                <span className="doc-subtitle">Show Zoomed: </span>
                By selecting
                <span className="doc-subtitle"> Show Zoomed </span>
                <Button className="doc-button-icon">
                    <BsZoomIn className="icon" title="Show Zoomed" />
                </Button>
                {" "} icon, users can view the selected equipment as zoomed in the drawing.
            </p>

            <p>
                <span className="doc-subtitle">Delink: </span>
                Through this
                <span className="doc-subtitle"> Delink </span>
                <Button className="doc-button-icon">
                    <BsZoomIn className="icon" title="Show Zoomed" />
                </Button>
                {" "} icon, users can unassign an equipment from the drawing. 
                The unlinked equipment will be moved to the Unassigned tab in tabular view.
            </p>

            <p className="doc-subtitle">
                <b>Equipment Specific:</b> 
            </p>
            <p>
                All the equipment assigned to the drawing will be listed as equipment specific under this tab. 
                The options to Edit, Delete, view/add Attachments are available under this tab.
            </p>
            <p>
                <span className="doc-subtitle">Edit: </span>
                Selected equipment can be edited by clicking the 
                <span className="doc-subtitle"> Edit </span>
                <Button className="doc-button-icon">
                    <BsFillPencilFill className="icon" title="Edit" />
                </Button>
                {" "} icon.
            </p>
            <p>
                <span className="doc-subtitle">Delete: </span>
                Users can delete Equipment by clicking the
                <span className="doc-subtitle"> Delete </span>
                <Button className="doc-button-icon">
                    <BsTrashFill className="icon" title="Delete" />
                </Button>
                {" "} icon.
            </p>
            <p>
                <span className="doc-subtitle">Attachments: </span>
                Users can view/add attachments by clicking the
                <span className="doc-subtitle"> Attachments </span>
                <Button className="doc-button-icon">
                    <BsPaperclip className="icon" title="Attachments" />
                </Button>
                {" "} icon.
            </p>
            
            <p className="doc-subtitle">                
                <b>Unassigned:</b> 
            </p>
            <p>
                All the unassigned equipment in the drawing will be listed under the Unassigned tab. 
                The options to Assign Block, Edit, Delete, view/add Attachments are available under this tab.
            </p>
            <p>
                <span className="doc-subtitle">Assign Block: </span>
                Through this <span className="doc-subtitle">Assign Block </span>
                <Button className="doc-button-icon">
                    <BsSubtract className="icon" title="Assign Block" />
                </Button>
                {" "} icon, users can assign equipment to blocks in the Drawings.
            </p>
            <p>
                <span className="doc-subtitle">Edit: </span>
                Selected equipment can be edited by clicking the 
                <span className="doc-subtitle"> Edit </span>
                <Button className="doc-button-icon">
                    <BsFillPencilFill className="icon" title="Edit" />
                </Button>
                {" "} icon.
            </p>
            <p>
                <span className="doc-subtitle">Delete: </span>
                Users can delete Equipment by clicking the
                <span className="doc-subtitle"> Delete </span>
                <Button className="doc-button-icon">
                    <BsTrashFill className="icon" title="Delete" />
                </Button>
                {" "} icon.
            </p>
            <p>
                <span className="doc-subtitle">Attachments: </span>
                Users can view/add attachments by clicking the
                <span className="doc-subtitle"> Attachments </span>
                <Button className="doc-button-icon">
                    <BsPaperclip className="icon" title="Attachments" />
                </Button>
                {" "} icon.
            </p>            

        </>
    )
}

export default InspectionDrawingsDocument;