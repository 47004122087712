import React from 'react'

const FeedbackDocument = () => {
  return (
   <>
     <h4 className="doc-title mb-2">Feedback</h4>
     <p>Users can send their feedback to zLink through this option. On clicking this, 
      Feedback page will be displayed with the logged in user's name displayed against User Name. 
      Enter Subject, Feedback and click Submit button, and the feedback will be sent to zLink.</p>
   </>
  );
}
export default FeedbackDocument;
