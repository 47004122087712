import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "redux/store";
import { baseURL, zlink } from "service/HttpService";

export interface CommonState {
    errors: any;
    additionalDatafieldValues: any;
    additionalDatafieldSets: any;
    uploadedFileId: any;
    progressFlag: boolean;
    permittedFiles:any;
    isDownloadInProgress:boolean;
    buildVersion: any;

}

let initialState: CommonState = {
    errors: "",
    additionalDatafieldValues: "",
    additionalDatafieldSets: "",
    uploadedFileId: "",
    progressFlag: false,
    permittedFiles: [],
    isDownloadInProgress:false,
    buildVersion: ""
};

const CommonSlice = createSlice({
    name: "common",
    initialState,
    reducers: {
        setErrors: (state, { payload }: PayloadAction<any>) => {
            state.errors = payload;
        },
        setAdditionalDatafieldValues: (state, { payload }: PayloadAction<any>) => {
            state.additionalDatafieldValues = payload;
        },
        setAdditionalDatafieldSets: (state, { payload }: PayloadAction<any>) => {
            state.additionalDatafieldSets = payload;
        },
        setUploadedFileId: (state, { payload }: PayloadAction<any>) => {
            state.uploadedFileId = payload;
        },
        setProgressFlag: (state, { payload }: PayloadAction<any>) => {
            state.progressFlag = payload;
        },
        setPermittedFiles: (state, {payload}: PayloadAction<any>) => {
			state.permittedFiles = payload;
		},
        setIsDownloadInProgress: (state, {payload}: PayloadAction<any>) => {
			state.isDownloadInProgress = payload;
		},
        setBuildVersion: (state, {payload}: PayloadAction<any>) => {
			state.buildVersion = payload;
		},
    }
})

export const getDatafieldValues = (serviceUrl: any, fieldId: any, body: any): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.post(`${baseURL}api/${serviceUrl}`, body);
            if (res.status === "done") {
                dispatch(setAdditionalDatafieldValues([res.data?.validatedValues, fieldId]));
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

export const getAdditionalDatafieldSets = (serviceUrl: any, body: any): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.post(`${baseURL}api/${serviceUrl}`, body);
            if (res.status === "done") {
                dispatch(setAdditionalDatafieldSets(res.data?.dataFields));
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };
export const uploadFile =
    (file: any): AppThunk =>
        async (dispatch) => {
            let formData = new FormData();
            formData.append('invoiceFile', file);
            const response = await fetch(`${baseURL}api/v1/common/files/upload`,
                {
                    method: 'POST',
                    body: formData
                },
            );
        };

export const onDownloadFile = (fileId: any, fileName: any, filePath: any, setMessage: any, setDisplayInfoModal: any): AppThunk => async (dispatch) => {
    try {
        const request: any = {
            method: "POST",
            cache: "no-store",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                Authorization: sessionStorage.getItem("accesstoken"),
            },
            body: JSON.stringify({
                FullPath: filePath
            })
        };

        const downloadUrl = `${baseURL}api/v1/common/files/${fileId}/download`
        await fetch(downloadUrl, request).then((response: any) => {

            if (response && response.status) {
                if (response.status === 200) {
                    const res = new Response(
                        new ReadableStream({
                            async start(controller) {
                                const reader = response.body.getReader();
                                for (; ;) {
                                    const { done, value } = await reader.read();
                                    if (done) break;
                                    controller.enqueue(value);
                                }
                                controller.close();
                            },
                        })
                    );

                    res.blob().then((blob) => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement("a");
                        a.href = url;
                        a.download = fileName;
                        a.click();
                        dispatch(setIsDownloadInProgress(false));
                    });

                } else {
                    setMessage("File not found");
                    setDisplayInfoModal(true);
                    dispatch(setIsDownloadInProgress(false));
                }
            }
        });
    } catch (error) {
        dispatch(setIsDownloadInProgress(false));
        dispatch(setErrors(error));
    }
};

export const onDownloadPluginFile = (fileId: any, fileName: any, filePath: any, setMessage: any, setDisplayInfoModal: any): AppThunk => async (dispatch) => {
    try {
        const request: any = {
            method: "POST",
            cache: "no-store",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                Authorization: sessionStorage.getItem("accesstoken"),
            },
            body: JSON.stringify({
                FullPath: filePath
            })
        };

        const downloadUrl = `${baseURL}api/v1/common/files/${fileId}/download`
        await fetch(downloadUrl, request).then(async (response: any) => {
            let chunks: any = [];
          
            if (response && response.status) {
                if (response.status === 200) {
                      const reader = response.body.getReader();
                    while(true) {
                        const {done, value} = await reader.read();
                      
                        if (done) {
                            dispatch(setIsDownloadInProgress(false));
                          break;
                        }
                      
                        chunks.push(value);
                      
                      }
                    
                    let blob = new Blob(chunks);
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement("a");
                    a.href = url;
                    a.download = fileName;
                    a.click();
                                  
          
                } else {
                    setMessage("Error when downloading file ");
                    setDisplayInfoModal(true);
                    dispatch(setIsDownloadInProgress(false));
                }
            }
        });

    } catch (error) {
        dispatch(setIsDownloadInProgress(false));
        dispatch(setErrors(error));
    }
};

export const onDownloadByFileName = (fileName: any, filePath: any, setMessage: any, setDisplayInfoModal: any): AppThunk => async (dispatch) => {
    try {
        const request: any = {
            method: "POST",
            cache: "no-store",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                Authorization: sessionStorage.getItem("accesstoken"),
            },
            body: JSON.stringify({
                FullPath: filePath
            })
        };

        const downloadUrl = `${baseURL}api/v1/common/files/download/${fileName}`
        await fetch(downloadUrl, request).then((response: any) => {

            if (response && response.status) {
                if (response.status === 200) {
                    const res = new Response(
                        new ReadableStream({
                            async start(controller) {
                                const reader = response.body.getReader();
                                for (; ;) {
                                    const { done, value } = await reader.read();
                                    if (done) break;
                                    controller.enqueue(value);
                                }
                                controller.close();
                            },
                        })
                    );

                    res.blob().then((blob) => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement("a");
                        a.href = url;
                        a.download = fileName;
                        a.click();
                    });
                } else {
                    setMessage("File not found");
                    setDisplayInfoModal(true);
                }
            }
        });
    } catch (error) {
        dispatch(setErrors(error));
    }
};

export const getPermittedFiles = (uniqueId?): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.get(`${baseURL}api/v1/common/files/filetypes`);
		if (res.status === "done") {
			dispatch(setPermittedFiles(res?.data?.attachmentList));
		} else {
			dispatch(setErrors(res.error));
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const getBuildVersion = (): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.get(`${baseURL}api/v1/buildVersion`);
		if (res.status === "done") {
			dispatch(setBuildVersion(res.data));
		} else {
			dispatch(setErrors(res.error));
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};


export const {
    setErrors,
    setAdditionalDatafieldValues,
    setAdditionalDatafieldSets,
    setUploadedFileId,
    setProgressFlag,
    setPermittedFiles,
    setIsDownloadInProgress,
    setBuildVersion
} = CommonSlice.actions;

export default CommonSlice.reducer;