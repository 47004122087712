import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { zlink, baseURL } from "../../../../../service/HttpService";
import { AppThunk } from "../../../../store";
import { onDownloadByFileName } from "redux/common/commonSlice";

export interface ReportConfigState {
  errors: any;
  reportConfigList: any;
  reportConfigPageDetails: any;
  newReportConfigData: any;
  deletedReportConfigData: any;
}

let initialState: ReportConfigState = {
  errors: "",
  reportConfigList: [],
  reportConfigPageDetails: [],
  newReportConfigData: "",
  deletedReportConfigData: "",
};

const ReportConfigSlice = createSlice({
  name: "reportConfig",
  initialState,
  reducers: {
    setErrors: (state, { payload }: PayloadAction<any>) => {
      state.errors = payload;
    },
    setReportConfigList: (state, { payload }: PayloadAction<any>) => {
      state.reportConfigList = payload; 
    },
    setReportConfigPageDetails: (state, { payload }: PayloadAction<any>) => {
      state.reportConfigPageDetails = payload;
    },
    setNewReportConfigData: (state, { payload }: PayloadAction<any>) => {
      state.newReportConfigData = payload; 
    },
    setDeletedReportConfigData: (state, { payload }: PayloadAction<any>) => {
      state.deletedReportConfigData = payload; 
    }
  },
});

export const getReportConfigList =
  (currentStart,keyword,sortField,sortOrder): AppThunk =>
  async (dispatch) => {
    try {
      const res = await zlink.get(`${baseURL}api/v1/reportconfigs?currentStart=${currentStart}&keyword=${keyword}&sortcolumn=${sortField}&sortorder=${sortOrder}`);
      if (res.status === "done") {
        dispatch(setReportConfigList(res?.data?.reportConfigDetails));
        dispatch(setReportConfigPageDetails(res?.data?.pageDetails));
      } else {
        dispatch(setErrors(res.error));
      }
    } catch (error) {
      dispatch(setErrors(error));
    }
  };
  export const onAddReportConfig =
  (body,setMessage,setDisplayInfoModal): AppThunk =>
    async (dispatch) => {
      try {
        const res = await zlink.post(`${baseURL}api/v1/reportconfigs/add`, body);
        if (res.status === "done") {
          dispatch(setNewReportConfigData(res?.data?.reportConfigDetails ));
          setDisplayInfoModal(true);
          setMessage("Report Config added");
        } else {
          setDisplayInfoModal(true);
          setMessage(res.message);
        }
      } catch (error) {
        dispatch(setErrors(error));
      }
};

export const deleteReportConfig = (body, setMessage, setDisplayInfoModal): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.post(`${baseURL}api/v1/reportconfigs/remove`, body);
    if (res.status === "done") {
      dispatch(setDeletedReportConfigData(res))
      setMessage("Report Config deleted");
      setDisplayInfoModal(true);
    } else {
      setMessage(res.message);
      setDisplayInfoModal(true);
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const getDownloadConfigFilePath = (body:any, setMessage: any, setDisplayInfoModal: any,): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.post(`${baseURL}api/v1/reportconfigs/file`,body);
            if (res.status === "done") {
                dispatch(onDownloadByFileName(res?.data?.fileName, res?.data?.fullPath, setMessage, setDisplayInfoModal));
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

export const { setErrors,
     setReportConfigList,
    setReportConfigPageDetails,
    setNewReportConfigData,
    setDeletedReportConfigData } =
ReportConfigSlice.actions;
export default ReportConfigSlice.reducer;
