import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { zlink, baseURL } from "../../../../service/HttpService";
import { AppThunk } from "../../../store";

export interface ZoneState {
  errors: any;
  zoneList: any;
  zonePageDetails: any;
  zoneDetails: any;
  zonesByCountry: any;
}

let initialState: ZoneState = {
  errors: "",
  zoneList: [],
  zoneDetails: [],
  zonePageDetails: [],
  zonesByCountry: []
};
const ZoneSlice = createSlice({
  name: "zone",
  initialState,
  reducers: {
    setErrors: (state, { payload }: PayloadAction<any>) => {
      state.errors = payload;
    },
    setZoneList: (state, { payload }: PayloadAction<any>) => {
      state.zoneList = payload;
    },
    setUpdatedZone: (state, { payload }: PayloadAction<any>) => {
      let currentIndex = state.zoneList.findIndex(
        (item: any) =>
          item.uniqueBusinessZoneId === payload?.uniqueBusinessZoneId
      );
      state.zoneList = state.zoneList.filter(
        (item: any) =>
          item.uniqueBusinessZoneId !== payload?.uniqueBusinessZoneId
      );
      state.zoneList.splice(currentIndex, 0, payload);
    },
    setDeletedZoneList(state, { payload }: PayloadAction<any>) {
			state.zoneList = state.zoneList.filter((item: any) => item.uniqueBusinessZoneId !== payload);
    },
    setZoneDetails: (state, { payload }: PayloadAction<any>) => {
      state.zoneDetails = payload;
    },
    setZonePageDetails: (state, { payload }: PayloadAction<any>) => {
      state.zonePageDetails = payload;
    },
    setNewZoneData: (state, { payload }: PayloadAction<any>) => {
      state.zoneList.unshift(payload);
    },
    setZonesByCountryId: (state, { payload }: PayloadAction<any>) => {
      state.zonesByCountry = payload;
    }
  }
});

export const getZoneList =
  (setShowGrid, currentStart, keyword: any = "", sortField: any = "", sortOrder: any = ""): AppThunk =>
  async (dispatch) => {
    try {
      const res = await zlink.get(
        `${baseURL}api/v1/organization/zones?currentStart=${currentStart}&keyword=${keyword}&sortcolumn=${sortField}&sortorder=${sortOrder}`
      );
      if (res.status === "done") {
        dispatch(setZoneList(res?.data?.zoneDetails));
        dispatch(setZonePageDetails(res?.data?.pageDetails));
        setShowGrid(true);
      } else {
        dispatch(setErrors(res.error));
      }
    } catch (error) {
      dispatch(setErrors(error));
    }
  };
export const onAddZone =
  (
    body: any,
    setMessage,
    setDisplayInfoModal,
    setShowAddZoneModal
  ): AppThunk =>
  async (dispatch) => {
    try {
      const res = await zlink.post(
        `${baseURL}api/v1/organization/zones/add`,
        body
      );
      if (res.status === "done") {
        dispatch(setNewZoneData(res.data));
        setMessage("Zone added");
        setShowAddZoneModal(false);
        setDisplayInfoModal(true);
      } else {
        dispatch(setErrors(res.error));
        setMessage(res.message);
        setDisplayInfoModal(true);
      }
    } catch (error) {
      dispatch(setErrors(error));
    }
  };
export const onEditZone =
  (
    body: any,
    uniqueBusinessZoneId,
    setMessage,
    setDisplayInfoModal,
    setShowEditModal
  ): AppThunk =>
  async (dispatch) => {
    try {
      const res = await zlink.post(
        `${baseURL}api/v1/organization/zones/${uniqueBusinessZoneId}`,
        body
      );
      if (res.status === "done") {
        dispatch(setUpdatedZone(res.data));
        setMessage("Zone updated");
        setShowEditModal(false);
        setDisplayInfoModal(true);
      } else {
        dispatch(setErrors(res.error));
        setMessage(res.message);
        setDisplayInfoModal(true);
      }
    } catch (error) {
      dispatch(setErrors(error));
    }
  };
export const getZoneDetails =
  (uniqueBusinessZoneId): AppThunk =>
  async (dispatch) => {
    try {
      const res = await zlink.get(
        `${baseURL}api/v1/organization/zones/${uniqueBusinessZoneId}`
      );
      if (res.status === "done") {
        dispatch(setZoneDetails(res.data));
      } else {
        dispatch(setErrors(res.error));
      }
    } catch (error) {
      dispatch(setErrors(error));
    }
  };

  export const deleteZone = ({ uniqueBusinessZoneId, setSelectedRows, setMessage, setDisplayInfoModal }): AppThunk => async (dispatch) => {
    try {
      const res = await zlink.post(`${baseURL}api/v1/organization/zones/${uniqueBusinessZoneId}/remove`, null);
      if (res.status === "done") {
        dispatch(setDeletedZoneList(uniqueBusinessZoneId));
        setSelectedRows([]);
        setMessage("Zone deleted");
        setDisplayInfoModal(true);
      } else {
        setSelectedRows([]);
        setMessage(res.message);
        setDisplayInfoModal(true);
      }
    } catch (error) {
      dispatch(setErrors(error));
    }
  };

export const getZonesByCountryId = (countryId) : AppThunk => async (dispatch) => {
  try {
    const res = await zlink.get(`${baseURL}api/v1/country/${countryId}/zones`);
    if (res.status === "done") {
      dispatch(setZonesByCountryId(res?.data?.zoneDetails));
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const {
  setErrors,
  setZoneList,
  setZonePageDetails,
  setDeletedZoneList,
  setNewZoneData,
  setUpdatedZone,
  setZoneDetails,
  setZonesByCountryId
} = ZoneSlice.actions;
export default ZoneSlice.reducer;
