import React, { memo, useEffect, useRef, useState } from 'react'
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { Controller, useForm } from 'react-hook-form';
//components
import AddModal from 'components/Table/AddModal/AddModal';
//icons
import { BsExclamationCircleFill, BsTrash } from 'react-icons/bs';
import { FaSave } from 'react-icons/fa';
import { MdOutlineEdit } from 'react-icons/md';

interface AddInspectionFieldValuesPropType {
    selectedField?: any;
    showModal: boolean;
    fieldValueList?: any;
    onHideModal?: () => void;
    onAddFieldValue?: (data,reset) => void;
    onUpdateFieldValue?: (data) => void;
    onDeleteFieldValue?: (data) => void;
}
const AddInspectionFieldValues: React.FC<AddInspectionFieldValuesPropType> = (props) => {
    const { showModal, fieldValueList, selectedField, onHideModal = () => { }, onAddFieldValue = () => { }, onUpdateFieldValue = () => { }, onDeleteFieldValue = () => { } } = props;

    const [showDeletePrompt, setShowDeletePrompt] = useState(false);
    const [editingRows, setEditingRows] = useState<any>(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [showErrorTooltip, setShowErrorTooltip] = useState(false);
    const addForm = useRef<any>();
    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm({
        defaultValues: {
            fieldValue: "",
        },
    });

    useEffect(() => {
        setShowDeletePrompt(false);
        setEditingRows(null);
    }, [fieldValueList]);

    const addFieldValues = (data) => {
        onAddFieldValue(data?.fieldValue,reset);        
    };

    document.addEventListener("mousedown", (e: any) => {
        const tableEl = document.getElementById(`field-table`);
        if (!tableEl?.contains(e.target)) {
            setEditingRows(null);
            setShowErrorTooltip(false);
        }
    });

    const onUpdate = (data) => {
        if (data?.dataFieldLookupValue) {
            onUpdateFieldValue(data)
        } else {
            setShowErrorTooltip(true);
        }

    }

    const checkType = () => {
        if (selectedField?.dataType?.dataTypeName === "Numeric") {
            return "number";
        } else if(selectedField?.dataType?.dataTypeName === "Integer") {
            return "integer"
        }
        else{
            return "text";
        }
    }

    const renderFooter = () => {
        return (
            <>
                <Button label="No" onClick={() => setShowDeletePrompt(false)} className='cancel-btn' />
                <Button label="Yes" onClick={() => onDeleteFieldValue(selectedItem)} className='submit-btn' autoFocus />
            </>
        );
    }

    const nameTemplate = (rowData) => {
        return (
            <div>
                {editingRows?.dataFieldLookupId === rowData?.dataFieldLookupId ?
                    (<div className='field pl-0 mb-0' >
                        <span className="p-float-label">
                            <InputText
                                type={checkType()}
                                value={editingRows.dataFieldLookupValue}
                                className={classNames("w-100 error-tooltip",)}
                                onChange={(e) => {
                                    setEditingRows((dt) => ({ ...dt, dataFieldLookupValue: e.target.value }));
                                    setShowErrorTooltip(false)
                                }} />
                            {getGridErrorMessage(rowData?.dataFieldLookupId)}
                        </span>
                    </div>) : (
                        <span>{rowData.dataFieldLookupValue}</span>
                    )
                }
            </div>
        )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className='btn-wrap'>
                {editingRows?.dataFieldLookupId === rowData?.dataFieldLookupId ?
                    (
                        <Button className='button-icon' onClick={() => onUpdate(editingRows)} ><FaSave className='icon' /></Button>
                    ) : (
                        <Button className='button-icon' onClick={() => setEditingRows(rowData)} ><MdOutlineEdit className='icon' /></Button>
                    )
                }
                <Button className='button-icon' onClick={() => {
                    setShowDeletePrompt(true);
                    setSelectedItem(rowData);
                }} ><BsTrash className='icon' /></Button>
            </div>
        );
    }

    const getFormErrorMessage = (name) => {
        return (
            errors[name] && (
                <span className="tooltip-text">
                    <BsExclamationCircleFill />
                    {errors[name].message}
                </span>
            )
        );
    };

    const getGridErrorMessage = (name) => {
        return (
            showErrorTooltip && (
                <span className="tooltip-text">
                    <BsExclamationCircleFill />
                    This field is required
                </span>
            )
        );
    };

    const integerValidate = (value: any) => {
        if (value) {
            if (value % 1 != 0) {
                return "Enter an integer value";
            } else {
                return !!value;
            }
        }
    };

    const decimalValidate = (value) => {
        if (!isNaN(value)) {
            return !!value;
        } else {
            return "Enter a Numeric value";
        }
    };


    return (
        <AddModal
            header={"Field Values"}
            showModal={showModal}
            setShowModal={onHideModal}
            isAdd={true}
            style={{ width: "35vw", maxHeight: "95%" }}
            showAdd={false}
        >
            <div className="section-col">
                <section className="d-flex align-items-center">
                    <div className="sub-page-title ml-2">
                        <span>General</span>
                    </div>
                </section>
                <div className="field mt-2 d-flex align-center">
                    <form className='w-100' onSubmit={handleSubmit(addFieldValues)} ref={addForm}>
                        <span className="p-float-label">
                            <Controller
                                name="fieldValue"
                                control={control}
                                rules={{
                                    required: "This field is required.",
                                    validate: checkType() === "integer" ? integerValidate : checkType() === "number" ? decimalValidate :undefined,
                                }}
                                render={({ field, fieldState }) => (
                                    <InputText
                                        id="fieldValue"
                                        className={classNames("w-100 error-tooltip", { error: fieldState.invalid })}
                                        {...field}
                                    />
                                )}
                            />
                            <label className="mandatory" htmlFor="fieldValue">
                                Name
                            </label>
                            {getFormErrorMessage("fieldValue")}
                        </span>
                    </form>


                    <div className=" p-dialog-footer pt-0 d-flex justify-content-end pr-0">
                        <Button label="Add" className="save-btn mr-0" onClick={() => addForm?.current?.requestSubmit()} />
                    </div>

                </div>

                <div className="field-Value-List">
                    <div className="main-wrapper">
                        <div className="box">
                            <div className="p-fluid">
                                <DataTable
                                    value={fieldValueList}
                                    id="field-table"
                                    dataKey="dataFieldLookupId"
                                    responsiveLayout="scroll">
                                    <Column field="dataFieldLookupValue" header="Field Value" style={{ width: '100%' }} body={nameTemplate}></Column>
                                    <Column headerStyle={{ width: '10%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'center' }} body={actionBodyTemplate} ></Column>
                                </DataTable>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {showDeletePrompt &&
                <Dialog
                    header="Tech Advance +"
                    visible={showModal}
                    style={{ width: '35vw' }}
                    footer={renderFooter()}
                    onHide={() => setShowDeletePrompt(false)}
                    className='delete-modal'
                >
                    <p className='content'>Are you sure you want to delete the field Value ?</p>
                </Dialog>
            }

        </AddModal>
    )
}

export default memo(AddInspectionFieldValues);