import React, { useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";

import { BsExclamationCircleFill } from "react-icons/bs";

import AddModal from "components/Table/AddModal/AddModal";

interface Props {
  showModal: boolean;
  setShowModal: (state: boolean) => void;
  selectedSite: any;
  onAddBuildingClick: (data: any) => void;
}

type FormData = {
  uniqueSiteId: string;
  buildingName: string;
};

export const AddBuildings: React.FC<Props> = ({
  showModal = false,
  setShowModal = () => {},
  selectedSite = [],
  onAddBuildingClick= () => {},
}) => {
  const addForm: any = useRef();

  const [siteNumber, setSiteNumber] = useState("")

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      uniqueSiteId: selectedSite?.uniqueSiteId,
      buildingName: "",
    },
  });
  

  useEffect(() => {
    if (selectedSite?.siteNumber)
      setSiteNumber(selectedSite?.siteNumber)
    else {
      let number = selectedSite?.siteName.substring(selectedSite?.siteName.indexOf('(') + 1, selectedSite?.siteName.indexOf(')'));
      setSiteNumber(number)
    }
  }, [])

  const formSubmitHandler = (data: any) => {
    addForm.current.requestSubmit();
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && (
        <span className="tooltip-text">
          <BsExclamationCircleFill />
          {errors[name].message}
        </span>
      )
    );
  };

  const onAddFormSubmit = (data: any) => {
    data.buildingName=data.buildingName?.trim();
    onAddBuildingClick && onAddBuildingClick(data);
  };

  const onError = (errors: any, e: any) => {};

  return (
    <>
      <AddModal
        header="New Building"
        showModal={showModal}
        isAdd={true}
        setShowModal={setShowModal}
        style={{ width: "35vw", height: "35vh" }}
        OnSaveClick={formSubmitHandler}
      >
        <form
          onSubmit={handleSubmit(onAddFormSubmit, onError)}
          ref={addForm}
        >
          <div className="row px-2">
            <div className="field col-12 md:col-2 mb-0">
              <span className="p-float-label text-container">
                <label htmlFor="inputtext">Site Number</label>
                <Controller
                  name="uniqueSiteId"
                  control={control}
                  render={() => <span className="text-value">{siteNumber}</span>}
                />
              </span>
            </div>
          </div>

          <div className="row px-2 pt-1 pb-2">
            <div className="field col-12 md:col-2 mb-0">
              <span className="p-float-label">
                <Controller
                  name="buildingName"
                  control={control}
                  rules={{
                    required: "Building Name is required.",
                    validate:(value) => {
                    return value?.includes(",")
                    ?"Comma is not allowed in building name":true
                     }
                  }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id="name"
                      maxLength={100}
                      className={classNames("w-100 error-tooltip", {
                        error: fieldState.invalid,
                      })}
                      {...field}
                      onChange={(e: any) => {
                        field.onChange(e.target.value.trimStart());
                    }}
                    />
                  )}
                />
                <label className="mandatory" htmlFor="inputtext">
                  Building Name
                </label>
                {getFormErrorMessage("buildingName")}
              </span>
            </div>
          </div>
        </form>
      </AddModal>
    </>
  );
};

export default AddBuildings;
