import React, { memo, useCallback, useEffect, useState } from 'react'
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
//components
import AddInspectionField from '../Manage/AddInspectionField/AddInspectionField';
import EditInspectionField from '../Manage/EditInspectionField/EditInspectionField';
import InspectionForms from 'components/common/Forms/InspectionForm/InspectionForms';
import InformationBox from 'components/common/Message/InformationBox/InformationBox';
import AddInspectionFieldValues from '../Manage/AddInspectionFieldValues/AddInspectionFieldValues';
//utility
import { addFieldValues, addNewInspectionField, deleteInspectionQuestion, removeFieldValue, updateFieldValue, updateInspectionQuestion } from '../Utility/InspectionFieldUtility';
//redux
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'redux/hooks';
import { checkUsageOfContractInspectionField, checkUsageOfContractInspectionFieldValue, onAddContractInspectionField, onAddNewContractInspectionFieldValue, onGetInspectionContracts, onUpdateContractInspectionField, onUpdateContractInspectionFieldValue, setDeletedContractInspectionField, setDeletedFieldValue, setInspectionContracts, setIsCheckInUse, setNewContractInspectionField, setNewFieldValue, setUpdatedContractInspectionField, setupdatedFieldValue } from 'redux/pages/Manage/InspectionSettings/InspectionFields/InspectionFieldSlice';

interface ContractPropTypes {

}
const Contract: React.FC<ContractPropTypes> = (props) => {
    const { } = props;

    const [inspectionFormSetup, setInspectionFormSetup] = useState<any>({});
    const [showAddInspectionField, setShowAddInspectionField] = useState(false);
    const [selectedInspection, setSelectedInspection] = useState<any>(null);
    const [showEditInspectionField, setShowEditInspectionField] = useState(false);
    const [showDeletePrompt, setShowDeletePrompt] = useState(false);
    const [showAddInspectionFieldValues, setShowAddInspectionFieldValues] = useState(false);
    const [fieldValueList, setFieldValueList] = useState<any>([]);
    const [displayInfoModal, setDisplayInfoModal] = useState(false);
    const [message, setMessage] = useState<string>("");

    const dispatch = useDispatch<any>();
    const inspectionContracts = useAppSelector((state) => state.InspectionField.inspectionContracts);
    const newContractInspectionField = useAppSelector((state) => state.InspectionField.newContractInspectionField);
    const newFieldValue = useAppSelector((state) => state.InspectionField.newFieldValue);
    const updatedFieldValue = useAppSelector((state) => state.InspectionField.updatedFieldValue);
    const deletedFieldValue = useAppSelector((state) => state.InspectionField.deletedFieldValue);
    const updatedContractInspectionField = useAppSelector((state) => state.InspectionField.updatedContractInspectionField);
    const deletedContractInspectionField = useAppSelector((state) => state.InspectionField.deletedContractInspectionField);
    const isCheckInUse = useAppSelector((state) => state.InspectionField.isCheckInUse);

    useEffect(() => {
        dispatch(onGetInspectionContracts());
    }, []);

    useEffect(() => {
        if (newContractInspectionField) {
            if (newContractInspectionField?.isSuccess) {
                let convNewField = JSON.parse(JSON.stringify(newContractInspectionField?.data))
                setShowAddInspectionField(false);
                const result = addNewInspectionField(selectedInspection, inspectionFormSetup?.inspectionGroupFields, convNewField);
                inspectionFormSetup.inspectionGroupFields = result;
                setInspectionFormSetup({ ...inspectionFormSetup });
                if (convNewField?.isValidated) {
                    setShowAddInspectionFieldValues(true);
                } else {
                    setMessage("Data Field Added");
                    setDisplayInfoModal(true);
                    dispatch(setNewContractInspectionField(""));
                }
            } else {
                setMessage(newContractInspectionField?.message);
                setDisplayInfoModal(true);
            }
        }
    }, [newContractInspectionField]);

    useEffect(() => {
        if (newFieldValue) {
            if (newFieldValue?.isSuccess) {
                let newField = JSON.parse(JSON.stringify(newFieldValue?.validatedFields))
                const selectedFields = newContractInspectionField?.data ? newContractInspectionField?.data : selectedInspection;
                const result = addFieldValues(selectedFields, inspectionFormSetup?.inspectionGroupFields, newField, fieldValueList);
                inspectionFormSetup.inspectionGroupFields = result;
                setInspectionFormSetup({ ...inspectionFormSetup });
                setFieldValueList([...fieldValueList]);
                setMessage("Additional Data Field Value added");
                setDisplayInfoModal(true);
            } else {
                setMessage(newFieldValue?.message);
                setDisplayInfoModal(true);
            }
            dispatch(setNewFieldValue(""));
        }
    }, [newFieldValue]);

    useEffect(() => {
        if (inspectionContracts) {
            let value: any = JSON.stringify(inspectionContracts);
            value = value.replace(/\"inspectionGroupSectionId\":/g, '"key":');
            value = value.replace(/\"inspectionGroupSectionName\":/g, '"label":');
            value = value.replace(/\"inspectionGroupSections\":/g, '"children":');
            value = JSON.parse(value);
            setInspectionFormSetup(value);
            dispatch(setInspectionContracts(""))
        }
    }, [inspectionContracts])

    useEffect(() => {
        if (updatedFieldValue) {
            if (updatedFieldValue?.isSuccess) {
                const selectedFields = newContractInspectionField?.data ? newContractInspectionField?.data : selectedInspection;
                const result = updateFieldValue(selectedFields, inspectionFormSetup?.inspectionGroupFields, updatedFieldValue?.validatedFields, fieldValueList);
                inspectionFormSetup.inspectionGroupFields = result;
                setInspectionFormSetup({ ...inspectionFormSetup });
                setFieldValueList([...fieldValueList]);
                setMessage("Additional Data Field Value updated");
                setDisplayInfoModal(true);
            } else {
                setMessage(updatedFieldValue?.message);
                setDisplayInfoModal(true);
            }
            dispatch(setupdatedFieldValue(""));
        }
    }, [updatedFieldValue]);

    useEffect(() => {
        if (deletedFieldValue) {
            const selectedFields = newContractInspectionField?.data ? newContractInspectionField?.data : selectedInspection;
            const result = removeFieldValue(selectedFields, inspectionFormSetup?.inspectionGroupFields, deletedFieldValue, fieldValueList);
            inspectionFormSetup.inspectionGroupFields = result;
            setInspectionFormSetup({ ...inspectionFormSetup });
            dispatch(setDeletedFieldValue(""));
            setFieldValueList([...fieldValueList]);
            setMessage("Selected Additional Data Field Value deleted");
            setDisplayInfoModal(true);
            setShowDeletePrompt(false);
        }
    }, [deletedFieldValue]);

    useEffect(() => {
        if (updatedContractInspectionField) {
            if (updatedContractInspectionField?.isSuccess) {
                let convField = JSON.parse(JSON.stringify(updatedContractInspectionField?.data))
                const result = updateInspectionQuestion(selectedInspection, inspectionFormSetup?.inspectionGroupFields, convField);
                inspectionFormSetup.inspectionGroupFields = result;
                setInspectionFormSetup({ ...inspectionFormSetup });
                setShowEditInspectionField(false);
                setMessage("Data Field Updated");
                setDisplayInfoModal(true);
            } else {
                setMessage(newContractInspectionField?.message);
                setDisplayInfoModal(true);
            }
            dispatch(setUpdatedContractInspectionField(""));
        }
    }, [updatedContractInspectionField]);

    useEffect(() => {
        if (deletedContractInspectionField) {
            const result = deleteInspectionQuestion(selectedInspection, inspectionFormSetup?.inspectionGroupFields, deletedContractInspectionField);
            inspectionFormSetup.inspectionGroupFields = result;
            setInspectionFormSetup({ ...inspectionFormSetup });
            dispatch(setDeletedContractInspectionField(""));
            setShowDeletePrompt(false);
            setMessage("Data Field Deleted");
            setDisplayInfoModal(true);
        }
    }, [deletedContractInspectionField])

    useEffect(() => {
        if (isCheckInUse) {
            setMessage("Selected Data Item is in use, cannot be deleted");
            setDisplayInfoModal(true);
            setShowDeletePrompt(false);
            dispatch(setIsCheckInUse(false));
        }
    }, [isCheckInUse])

    const onAddClick = useCallback((data) => {
        if (data?.isValidated) {
            setShowAddInspectionFieldValues(true);
            setFieldValueList(data?.validatedValues)
        } else {
            setShowAddInspectionField(true);
        }
        setSelectedInspection(data);
    }, [showAddInspectionField, selectedInspection, showAddInspectionFieldValues, fieldValueList]);

    const onEditClick = useCallback((data) => {
        if (data) {
            setShowEditInspectionField(true);
            setSelectedInspection(data);
        }
    }, [selectedInspection, showEditInspectionField]);

    const onDeleteClick = useCallback((data) => {
        if (data) {
            setShowDeletePrompt(true);
            setSelectedInspection(data);
        }
    }, [showDeletePrompt])

    const onSubmitInspection = useCallback((body) => {
        dispatch(onAddContractInspectionField(body));
    }, []);

    const onUpdateInspection = useCallback((body) => {
        dispatch(onUpdateContractInspectionField(body?.uniqueDataFieldId, body));
    }, []);

    const onDeleteInspectionField = useCallback((deletedItem) => {
        let body = {
            isSection: deletedItem?.isSection
        }
        dispatch(checkUsageOfContractInspectionField(deletedItem?.uniqueDataFieldId, body, deletedItem))
    }, [])

    const onAddFieldValue = useCallback((fieldName, reset) => {
        const selectedFields = newContractInspectionField?.data ? newContractInspectionField?.data : selectedInspection;
        let body = {
            fieldvalue: fieldName,
            dataEntryControlId: selectedFields?.dataEntryControl?.dataEntryControlId
        };
        dispatch(onAddNewContractInspectionFieldValue(selectedFields?.uniqueDataFieldId, body, reset));
    }, [newContractInspectionField, selectedInspection]);

    const onUpdateFieldValue = useCallback((newData) => {
        const selectedFields = newContractInspectionField?.data ? newContractInspectionField?.data : selectedInspection;
        let body = {
            fieldvalue: newData?.dataFieldLookupValue
        }
        dispatch(onUpdateContractInspectionFieldValue(selectedFields?.uniqueDataFieldId, newData.dataFieldLookupId, body, newData));
    }, [selectedInspection, newContractInspectionField]);

    const onDeleteFieldValue = useCallback((deletedItem) => {
        const selectedFields = newContractInspectionField?.data ? newContractInspectionField?.data : selectedInspection;
        dispatch(checkUsageOfContractInspectionFieldValue(selectedFields?.uniqueDataFieldId, deletedItem?.dataFieldLookupId, deletedItem));
    }, [selectedInspection, newContractInspectionField]);

    const renderFooter = () => {
        return (
            <>
                <Button label="No" onClick={() => setShowDeletePrompt(false)} className='cancel-btn' />
                <Button label="Yes" onClick={() => onDeleteInspectionField(selectedInspection)} className='submit-btn' autoFocus />
            </>
        );
    }

    return (
        <div className="question">
            <InspectionForms
                type='CONTRACT'
                isExpanded={false}
                isAddAction={true}
                isCheckedAction={true}
                formElements={inspectionFormSetup}
                onAddClicked={onAddClick}
                onEditClicked={onEditClick}
                onDeleteClicked={onDeleteClick} />
            {showAddInspectionField &&
                <AddInspectionField
                    type='CONTRACT'
                    selectedNode={inspectionFormSetup}
                    parentItem={selectedInspection}
                    showModal={showAddInspectionField}
                    onSubmitInspection={onSubmitInspection}
                    onHideModal={() => setShowAddInspectionField(false)}
                />
            }
            {showEditInspectionField &&
                <EditInspectionField
                    type='CONTRACT'
                    selectedItem={selectedInspection}
                    selectNode={inspectionFormSetup}
                    showModal={showEditInspectionField}
                    onUpdate={onUpdateInspection}
                    onHideModal={() => setShowEditInspectionField(false)} />
            }
            {showDeletePrompt &&
                <Dialog
                    header="Tech Advance +"
                    visible={showDeletePrompt}
                    style={{ width: '35vw' }}
                    footer={renderFooter()}
                    onHide={() => setShowDeletePrompt(false)}
                    className='delete-modal'
                >
                    {selectedInspection?.isSection ?
                        <p className='content'>Are you sure you want to delete the selected Data Group ?</p> :
                        <p className='content'>Are you sure you want to delete the selected Data Item ?</p>
                    }
                </Dialog>
            }
            {showAddInspectionFieldValues &&
                <AddInspectionFieldValues
                    selectedField={newContractInspectionField?.data ? newContractInspectionField?.data : selectedInspection}
                    fieldValueList={fieldValueList}
                    onAddFieldValue={onAddFieldValue}
                    onUpdateFieldValue={onUpdateFieldValue}
                    onDeleteFieldValue={onDeleteFieldValue}
                    onHideModal={() => {
                        setShowAddInspectionFieldValues(false);
                        dispatch(setNewContractInspectionField(""));
                        setFieldValueList([]);
                    }}
                    showModal={showAddInspectionFieldValues} />
            }
            <InformationBox
                showInfoModal={displayInfoModal}
                setShowInfoModal={setDisplayInfoModal}
                message={message} />
        </div>
    )
}

export default memo(Contract);