import { Button } from "primereact/button";
import React from "react";
import { BsFillPencilFill, BsPersonCheckFill, BsPersonCircle, BsPlusLg } from "react-icons/bs";
import { FaCog, FaDownload } from "react-icons/fa";
const AdministrationFaqDocument = () => {
    return (
        <>
            <h4 className="doc-title mb-2">
                FAQ in Administration Module
            </h4>
            <br />
            <p>
                <b>1. What are the key features of Tech Advance<sup>+</sup>? </b>
            </p>
            <br />
            <ol className="font-14">
                <li>1. Creation of Sites and their Customers, Buildings and Floor details.</li>
	            <li>2. Creation of Users in different user roles like Super Administrator, Admin, Admin-Tech, Technician and Branch.</li>
	            <li>3. Creation of Contracts, Systems and Inspection Tasks for Inspection.</li>
	            <li>4. Uploading of XML file for the System under Inspection for getting the Equipment details automatically.</li>
	            <li>5. Inspection of Equipment/Devices connected to MXL/XLS/Desigo Modular/Desigo FS20/Desigo FV20/Cerberus Pro Panels and Other Equipment Groups through <span className="doc-subtitle"> Inspection </span> menu and updating Test Status.</li>
	            <li>6. View Drawings (Floor plans) of the Buildings selected for inspection.</li>
	            <li>7. Linking of Barcodes to Equipment for easy selection.</li>
	            <li>8. Viewing Equipment and its Test Status in Floor Plan Drawings.</li>
	            <li>9. Viewing and Updating System and Equipment Group Details.</li>
	            <li>10. Generation of Inspection Reports and uploading of Final reports for Sites.</li>
	            <li>11. Sharing of relevant Documents to Sites.</li>
	            <li>12. Upload/Download option of latest versions of TAP Bridge and TAP App.</li>
            </ol>
            <br />


            <p>
                <b>2. How to login to Tech Advance<sup>+</sup>? </b>
            </p>
            <br />
            <ol className="font-14">
	            <li>1. Open Google Chrome/Firefox/Microsoft Edge.</li>
	            <li>2. Enter url address of the application <span className="doc-subtitle">(www.sietap.com) </span> in the address bar.</li>
	            <li>3. Login page will be displayed with Login Name, Password, Forgot Password and LOG IN button.</li>
	            <li>4. Enter a valid Login Name and Password and click the LOG IN button.</li>
	            <li>5. User will be logged into Tech Advance<sup>+</sup>.</li>
            </ol>
            <br />
            <p>
                <b>3. How to logout from Tech Advance<sup>+</sup>? </b>
            </p>
            <br/>
            <ol className="font-14">
                <li>1. Click on the <span className="doc-subtitle">Profile </span><Button className="doc-button-icon"><BsPersonCircle className="icon" title="Profile"/></Button> icon on the top right corner of the page and click <span className="doc-subtitle">Sign Out</span> menu.</li>
	            <li>2. User will be logged out from the Application.</li>
            </ol>
            <br/> 
  
            <p>
                <b>4. How to reset a forgotten password?</b>
            </p>
            <br/>
            <ol className="font-14">
	            <li>1. Click the <span className="doc-subtitle">"Forgot Password ?" </span> option displayed in the login page.</li>
	            <li>2. Retrieve Forgotten Password page will be opened with option to enter the Email Address and <span className="doc-subtitle">Proceed</span> button.</li>
	            <li>3. Enter the Email Address and click <span className="doc-subtitle">Proceed</span>  button.</li>
	            <li>4. A system generated Password will be sent to the Email Id registered with the User's account. </li>
	            <li>5. Access the appropriate mail account and retrieve the new password generated by Tech Advance<sup>+</sup>.</li>
	            <li>6. Login to Tech Advance<sup>+</sup> with this system generated password. </li> 
	            <li>7. The system will prompt the user to change the password by displaying a message "Password has to be changed at this login. Please change your password now".</li>
	            <li>8. Click 'Yes' to the message, and change the password as per your choice and according to the password policy required by the system.</li>
            </ol>
            <br />
	  
            <p>
                <b>5. How to unlock a locked user?</b>
            </p>
            <br/>
    

	        <p>
                A user account gets locked when a user attempts to login with a wrong password 
                three times consecutively within a short time. This happens when a user forgets 
                his/her password, or when another user tries to break into his/her account. 
                A locked user will be unlocked automatically after a predefined interval as set 
                in the password policy.A Super Administrator, Support, Admin, Admin-Tech can 
                unlock a user by doing the following steps. 
            </p>
	        <ol className="font-14">
	            <li>1. Select <span className="doc-subtitle"> Users </span> menu in Administration module.</li>
	            <li>2. Select the type of the user role of the locked user. </li> 
	            <li>3. Select the locked user from the data grid and click <span className="doc-subtitle">Edit </span>{' '}
                    <Button className="doc-button-icon"><i className="pi pi-pencil icon" title="Inline Edit" /></Button> {' '} icon. </li>
	            <li>4. Select the Active radio button and click <span className="doc-subtitle">Save Changes</span> button. </li>
	            <li>5. The user account will be unlocked when this is done.</li>
	            <li>6. After the account is unlocked, the user can use the <span className="doc-subtitle">"Forgot your password ?"</span> option to reset the password if he has forgotten the password</li>
            </ol>
            <br />
	  
            <p>
                <b>6. How to switch to another Site by a user?</b>
            </p>
            <br/>
            <ol className="font-14">
	            <li>1. Click on <span className="doc-subtitle">Change Site / Site Name </span> link displayed at the top right corner of the page </li>
	            <li>2. A Select Site page will be displayed along with Country, Zone, Select Customer, Select Site </li>
	            <li>3. Enter the required details and Select Site from dropdown list. </li>
	            <li>4. User will be switched to the selected site</li>
            </ol>
            <br/>      
	  
            <p>
                <b>7. How to create a new customer ?</b>
            </p>
            <br/>
            <ol className="font-14">
	            <li>1. Select <span className="doc-subtitle"> Administration: Customers </span> </li>
	            <li>2. Sold to Customers page will be displayed with all added customers and their details with Add, Edit, Delete, Download and More icons </li>
	            <li>3. Click <span className="doc-subtitle"> Add </span><Button className="doc-button-icon"> <BsPlusLg className="icon" title="Add" /> </Button> icon </li>
	            <li>4. A New Sold to Customer page will be displayed with options to enter Country, Customer Number, Customer Name, Address, City, State, Zip, Phone, Fax, Email, Contact, Status and Account Expiry Date with Save and Cancel buttons.</li>
	            <li>5. Enter the required details. </li>
	            <li>6. Click <span className="doc-subtitle">Save</span> button.</li>
            </ol>	 
            <br />

            <p>
                <b>8. How to Map Sites to a Customer?</b>
            </p>
            <br/>
            <ol className="font-14"> 
	            <li>1. Select <span className="doc-subtitle"> Administration: Customers </span> </li>
	            <li>2. Sold to Customers page will be displayed with all added customers and their details with Add, Edit, Delete, Download and More icons </li>
	            <li>3. Select a Customer by clicking on the required row. </li>
	            <li>4. Select <span className="doc-subtitle">Sites</span> option from <span className="doc-subtitle">More </span>
                    <Button className="doc-button-icon"> 
                        <FaCog className="icon" title="More"/>
                    </Button> icon
                </li>
	            <li>5. A Popup will be opened with Sites and Display Settings </li>
	            <li>6. Select option <span className="doc-subtitle"> Sites </span></li>
	            <li>7. Map Sites page will be displayed with a Search field, Cancel, Set as Active Site and Save Changes buttons </li>
	            <li>8. Search for the required Sites and Select them from the list and click <span className="doc-subtitle">Save Changes </span> button</li>
            </ol>
            <br/>  
	
            <p>
                <b>9. How to create a New Site and set it as active Site?</b>
            </p>
            <br/>
    		<ol className="font-14">
       			<li>1. Select <span className="doc-subtitle"> Administration: Sites </span> </li>
	   			<li>2. Sites page will be displayed with all added Sites and its details with Add, Edit, Delete, Set as Active Site, Download, and More icons. </li>
	   			<li>3. Click <span className="doc-subtitle">Add </span> 
                    <Button className="doc-button-icon"> 
                        <BsPlusLg className="icon" title="Add"/> 
                    </Button> icon.</li>
	   			<li>4. A New Site page will be displayed with Country, Site Number, Site Name, State, Time Zone and Description with Save and Cancel buttons.</li>
	   			<li>5. Enter the required details and click Save button.</li>
	   			<li>6. Select the newly added Site and click <span className="doc-subtitle">Set as Active Site </span> 
                    <Button className="doc-button-icon">
                        <BsPersonCheckFill className="icon" title="Set as Active Site"/>
                    </Button> icon.
                </li>
	   			<li>7. Selected Site will be activated and the logged n User will be switched to the new Site.</li>
        
			</ol>
            <br />

			<p>
				<b>10. How to create a new Technician?</b>
			</p>
            <br/>
   			<ol className="font-14"> 
				<li>1. Select <span className="doc-subtitle"> Administration: Users: Tech </span> </li>
				<li>2. Techs page will be displayed with all added Technicians and their details with Add, Edit, Delete, Download and More icons. </li>
				<li>3. Click <span className="doc-subtitle"> Add </span> 
                    <Button className="doc-button-icon"> 
                        <BsPlusLg className="icon" title="Add"/> 
                    </Button> icon.
                </li>
				<li>4. A New User page will be displayed with Login Name,First Name, Middle Name, Last Name, Email etc.with <span className="doc-subtitle"> Save </span> and <span className="doc-subtitle"> Cancel </span> button.</li>
				<li>5. Enter the required details.</li>
				<li>6. Click on <span className="doc-subtitle">Select Sales Office</span> link</li>
				<li>7. Update Sales Office page will be displayed with option to select Country and Zone with <span className="doc-subtitle">Cancel</span> and <span className="doc-subtitle">Save Changes</span> buttons.</li>
				<li>8. Select the required Sales Offices and click <span className="doc-subtitle">Save Changes</span> button and close the Update Sales Office page</li>
				<li>9. Click <span className="doc-subtitle">Save</span> button in New User page.</li>
				<li>10. New Technician will be created.</li>
            </ol>
            <br/> 

	        <p>
                <b>11. How to assign a Technician to a Sales Office?</b>
            </p>
            <br/>
    

	        <p> 
                A technician has to be associated or linked to a Sales Office to perform an inspection under a contract managed by the Sales Office.<br/> 
                There are two options by which a user can link a Technician to a Sales Office.<br/> 
            </p>
	        <p> 
                <i>Option 1 (through Technician menu):</i>
            </p>

            <ol className="font-14">
                <li>1. If Technician is already created, Go to <span className="doc-subtitle">  Administration: Users: Technicians</span> and select the required Technician and click <span className="doc-subtitle"> Edit </span>
                    <Button className="doc-button-icon"> 
                        <BsFillPencilFill className="icon" title="Edit"/> 
                    </Button> icon. 
                </li>
	            <li>2. Click the <span className="doc-subtitle">Select Sales Office</span> link  </li>
	            <li>3. Update Sales Office page will be displayed with option to select Country and Zone with <span className="doc-subtitle">Cancel</span> and <span className="doc-subtitle">Save Changes</span> buttons.</li>
	            <li>4. Select the required Sales Offices and then click <span className="doc-subtitle">Save Changes</span> button.</li>
	        </ol>
            <p> </p>
            <p>
                OR
            </p>
            <ol className="font-14">
	            <li>1. If Technician is not created, Go to <span className="doc-subtitle"> Administration: Users: Technicians</span>  and create a new Technician using <span className="doc-subtitle">Add </span>
                    <Button className="doc-button-icon"> 
                        <BsPlusLg className="icon" title="Add"/> 
                    </Button> icon.
                </li>
	            <li>2. A New User page will be displayed with Login Name, First Name, Middle Name, Last Name, Email etc. with <span className="doc-subtitle">Save</span> and  <span className="doc-subtitle">Cancel</span> button.</li>
	            <li>3. Enter the required details and click the l<span className="doc-subtitle"> Select Sales Office</span> link</li>
	            <li>4. Update Sales Office page will be displayed with option to select Country and Zone with <span className="doc-subtitle">Cancel</span> and <span className="doc-subtitle">Save Changes</span> buttons.</li>
	            <li>5. Select the required Sales Offices and click <span className="doc-subtitle">Save Changes</span> button.</li>
            </ol>
            <br />

	        <p>
                <i> Option 2 (through Sales Office menu):</i>
            </p>

            <ol className="font-14">
	            <li>1. Go the <span className="doc-subtitle"> Manage: Organizational Settings: Sales Office</span>.</li>
	            <li>2. Select the Sales Office to be linked to the Technician. </li>
	            <li>3. Click on the <span className="doc-subtitle">Tech</span> option from <span className="doc-subtitle">More </span>
                    <Button className="doc-button-icon">
                        <FaCog className="icon" title="More"/>
                    </Button> icon.
                </li>
	            <li>4. Update Technicians page will be displayed with all the Technicians added to the Sales Office through <span className="doc-subtitle">Administrator: Users: Technicians</span> option with <span className="doc-subtitle">Cancel</span> and 
                    <span className="doc-subtitle"> Save Changes</span> buttons.</li>
	            <li>5. Select the required Technicians and click <span className="doc-subtitle">Save Changes</span> button.</li>
	        </ol>
            <br/>  
       
	  
            <p>
                <b>12. How to view the Sales Offices linked to a technician?</b>
            </p>
            <br/>
            <ol className="font-14">
	            <li>1. Go to <span className="doc-subtitle"> Administration: Users: Tech</span> menu.</li>
	            <li>2. Select the required Technician and then click on <span className="doc-subtitle">Edit </span>
                    <Button className="doc-button-icon"> 
                        <BsFillPencilFill className="icon" title="Edit"/> 
                    </Button> icon. </li>
	            <li>3. Edit User page will be displayed with <span className="doc-subtitle">'Select Sales Office'</span> link and with <span className="doc-subtitle">Save Changes</span> and <span className="doc-subtitle">Cancel</span> buttons.</li>
	            <li>4. Click on <span className="doc-subtitle">Select Sales Office </span> link</li>
	            <li>5. Update Sales Office page will be displayed listing the Sales Office names with <span className="doc-subtitle">Cancel</span> and <span className="doc-subtitle">Save Changes</span> buttons.</li>
	            <li>6. The Sales Offices already linked to the technician will be displayed as checked</li>
	        </ol>
            <br/>
	  
	        <p>
                <b>13.	How to convert to a User in one User Role to another?</b>
            </p>
            <br/>
            <ol className="font-14">
	            <li>1. Login to  Tech Advance<sup>+</sup>  as Super Administrator.</li>
	            <li>2. Go to <span className="doc-subtitle">Administration: Users.</span></li>
	            <li>3. Select the required Active user from Support/Admin/Tech/Admin-Tech/Branch menu.</li>
	            <li>4. Choose <span className="doc-subtitle">Convert to other User Role</span> option from <span className="doc-subtitle">More </span>
                <Button className="doc-button-icon">
                    <FaCog className="icon" title="More"/></Button> icon.</li>
	            <li>5. Convert to other User Role page will be displayed with an option to select 'To User Role' and with <span className="doc-subtitle">Cancel</span> and <span className="doc-subtitle">Convert</span> buttons. <span className="doc-subtitle">Select Sales Office</span> link will be displayed if any User Role other than Super Administrator / Support is selected </li>
	            <li>6. Select the required User Role, Select the Sales Office required and click the <span className="doc-subtitle">Convert</span> button.</li>
	            <li>7. A Confirmation message will be displayed with Ok button. </li>
	            <li>8. Click <span className="doc-subtitle">Ok</span> to the Confirmation message.</li>
            </ol>
            <br/> 
	   
	        <p>
                <b>14.	How to download and Install TAP App from TAP Web?</b>
            </p>
            <br/>
            <ol className="font-14">
	            <li>1. Select <span className="doc-subtitle">Plugins</span> option from <span className="doc-subtitle">More</span> <Button className="doc-button-icon">
                    <FaCog className="icon" title="More"/></Button> icon.</li>
	            <li>2. Plugins page will be displayed with link to download TAP Bridge and TAP App.</li>
	            <li>3. Click on the <span className="doc-subtitle">Download</span> <Button className="doc-button-icon"> 
                    <i className="pi pi-download icon" title="Download" /> 
                </Button>  icon under the TAP App section to download the APK.</li>
	            <li>4. After downloading the Apk, install it on the required device.</li>
	        </ol>
            <br/> 
       
	  
	        <p>
                <b>15.	How to download and install TAP Bridge?</b>
            </p>
            <br/>
            <ol className="font-14">
	            <li>1. Select <span className="doc-subtitle">Plugins</span> option from <span className="doc-subtitle">More </span> <Button className="doc-button-icon">
                    <FaCog className="icon" title="More"/></Button> icon.</li>
	            <li>2. Plugins page will be displayed with link to download TAP Bridge and TAP App.</li>
	            <li>3. Click on the <span className="doc-subtitle">Download </span> 
                <Button className="doc-button-icon"> 
                    <i className="pi pi-download icon" title="Download" /> 
                </Button>  icon under the TAP Bridge section to download.</li>
	            <li>4. The latest TAP Bridge file uploaded by the Super Administrator through Plugins: TAP Bridge &gt; Upload will be downloaded to the user's computer as a ZIP file.</li>
	            <li>5. Click the <span className="doc-subtitle">Save</span> option to Save the TAP Bridge in the required location.</li>
	            <li>6. Extract the ZIP file contents to the appropriate location. Double click the TAP Bridge icon from the saved location.</li>
	            <li>7. Follow the instructions and install it by clicking the <span className="doc-subtitle">Install</span> button.</li>
            </ol>
            <br/> 
	  
	     
	  
	        <p>
                <b>16.	How to update a new version of TAP Bridge?</b>
            </p>
            <br/>
            <ol className="font-14">
                <li>1. Select the option <span className="doc-subtitle">Inspection: Plugins: TAP Bridge: Download.</span></li>
	            <li>2. The latest TAP Bridge file uploaded by the Super Administrator through <span className="doc-subtitle">Inspection: Plugins: TAP Bridge: Upload</span>  will be downloaded to the user's computer as a ZIP file.</li>
	            <li>3. Click the <span className="doc-subtitle">Save</span> option to Save the TAP Bridge in the required location.</li>
	            <li>4. Extract the ZIP file contents to the appropriate location. Double click the TAP Bridge icon from the saved location.</li>
	            <li>5. Follow the instruction and install it by clicking the <span className="doc-subtitle">Install</span> button. </li>
            </ol>
        </>
    );
}

export default AdministrationFaqDocument;