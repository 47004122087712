export const APP_ROUTES = {
  HOME: '/inspection-tasks',
  DASHBOARD: 'dashboard',
  ADMINISTRATION: 'administration',
  CUSTOMERS: 'customers',
  USERS: 'users',
  SITES: 'sites',
  SEARCH: ':searchType',
  INSPECTION: 'inspection',
  INSPECTION_TASKS: 'inspection-tasks',
  CONTRACTS: 'contracts',
  SYSTEMS: 'systems',
  INSPECTION_REPORTS: 'inspection-reports',
  MANAGE_VIEW: 'manage-view',
  MANAGE_ORGANIZATION: 'organization',
  MANAGE_INSPECTION_REPORTS: 'manage-inspection-reports',
  MANAGE_LOOKUPS_HEALTHCLASSIFICATION: 'manage-lookups-healthclassification',
  MANAGE_LOOKUPS_NFPA_CLASSIFICATION: 'manage-lookups-nfpa-classification',
  REPORT_VIEW: 'report-view',
  SITE_REPORT: 'site-report',
  CONTRACTS_REPORT: 'contracts-report',
  INSPECTION_TASK_REPORT: 'inspection-task-report',
  USER_ACTIVITY_REPORT: 'user-activity',
  USER_DETAILS_REPORT: 'user-details',
  UPLOADED_FINAL_REPORTS: 'uploaded-final-reports',
  SAP_IMPORT_STATUS_REPORT: 'sap-import-status-report',
  INSPECTION_ACTIVITY_REPORT: 'inspection-activity-report',
  MASTER_DATA_REPORT: 'master-data-report',
  EXPIRED_CONTRACTS_REPORT: 'expired-contracts-report',
  SECURITY_SETTINGS: 'security-settings',

  MANAGE_INSPECTION_REPORT_MAPPING: 'manage-inspection-report-mapping',
  TOOLS_COPY: 'copy',
  TOOLS_Move: 'move',
  TOOLS_COPY_MOVE_LOGS: 'copy-move-logs',
  SAP_IMPORT_STATUS: 'sap-import-status',
  MANAGE_INSPECTION_REPORT_MAPPING_SYSTEM: 'system',
  MANAGE_INSPECTION_REPORT_MAPPING_CONTRACT: 'contract',
  MANAGE_INSPECTION_REPORT_MAPPING_QUESTIONS: 'questions',
  CUSTOMIZATION_ATTRIBUTES: 'customization-attributes',
  GENERATE_INSPECTION_REPORTS: 'generate-reports',
  INSPECTIONREPORTS_REPORTS: 'reports',
  INSPECTION_DRAWINGS: 'inspection-drawings',
  INSPECTION_FIELDS: 'inspection-fields',
  MANAGE_DISPLAY_SETTINGS: 'display-settings',
  MANAGE_DATAENTRY_SETTINGS: 'dataEntry-settings',
  LOG_BOOK: 'log-book',
  AS_BUILTS: 'as-builts',
  BUILDING_REPORT: 'building-report',
  FLOOR_REPORT: 'floor-report',
  TAP_PERFORMACE: 'Tap-Performance',
  SALES_ACTIVITY_REPORT: 'sales-activity-report',
};