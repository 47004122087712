import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AppThunk } from "redux/store";
import { baseURL, zlink } from "service/HttpService";

export interface FloorState {
    errors: any;
    floorList: any;
    floorPageDetails: any;
    newFloorData: any;
    buildingList: any;
    floorDetails: any;
    updatedFloor: any;
    attachmentList: any;
	attachmentPageDetails:any;
	floorAttachmentCountList:any;
}

let initialState: FloorState = {
    errors: "",
    floorList: "",
    floorPageDetails: "",
    newFloorData: "",
    buildingList: "",
    floorDetails: "",
    updatedFloor: "",
    attachmentList: [],
	attachmentPageDetails: [],
	floorAttachmentCountList:[],

}

const FloorSlice = createSlice({
    name: "floor",
    initialState,
    reducers: {
        setErrors: (state, { payload }: PayloadAction<any>) => {
            state.errors = payload;
        },
        setFloorList: (state, { payload }: PayloadAction<any>) => {
			state.floorList = payload;
		},
        setFloorPageDetails: (state, { payload }: PayloadAction<any>) => {
			state.floorPageDetails = payload;
		},
        setNewFloorData: (state, { payload }: PayloadAction<any>) => {
			state.newFloorData = payload;
		},
        setBuildingList: (state, {payload}: PayloadAction<any>) => {
            state.buildingList = payload;
        },
        setFloorDetails: (state, {payload}: PayloadAction<any>) => {
            state.floorDetails = payload;
        },
        setUpdatedFloor: (state, {payload}: PayloadAction<any>) => {
            state.updatedFloor = payload;
        },
        setDeletedFloor: (state, { payload }: PayloadAction<any>) => {
			state.floorList = state.floorList.filter((item: any) => item.uniqueFloorId !== payload);
		},
        setAttachmentList: (state, {payload}: PayloadAction<any>) => {
			state.attachmentList = payload;
		},
		setAttachmentPageDetails: (state, {payload}: PayloadAction<any>) => {
			state.attachmentPageDetails = payload;
		},
		setNewAttachmentData: (state, { payload }: PayloadAction<any>) => {
			state.attachmentList.unshift(...payload);
		},
		setUpdatedAttachement: (state, { payload }: PayloadAction<any>) => {
			let currentIndex = state.attachmentList.findIndex((item: any) => item.attachmentId === payload?.attachmentId);
			state.attachmentList = state.attachmentList.filter((item: any) => item.attachmentId !== payload?.attachmentId);
			state.attachmentList.splice(currentIndex, 0, payload);
		},
		setDeletedAttachmentList(state, { payload }: PayloadAction<any>) {
			state.attachmentList = state.attachmentList.filter((item: any) => item.attachmentId !== payload);
		},
		setFloorAttachmentCountList(state, { payload }: PayloadAction<any>) {
			let currentIndex = state.floorList.findIndex((item: any) => item.uniqueFloorId === payload?.uniqueFloorId);
			state.floorList = state.floorList.filter((item: any) => item.uniqueFloorId !== payload?.uniqueFloorId);
			state.floorList.splice(currentIndex, 0, payload);
		},
		setNewAttachmentList(state, { payload }: PayloadAction<any>) {
			payload.map((element: any) => {	
			let currentIndex = state.floorList.findIndex((item: any) => item.uniqueFloorId === element?.uniqueFloorId);
			state.floorList = state.floorList.filter((item: any) => item.uniqueFloorId !== element.uniqueFloorId);
			state.floorList.splice(currentIndex, 0, element);
			});
		}
			 
		},
    }
);

export const getFloorList = (uniqueSiteId,uniqueBuildingId: any="", currentStart, keyword: any = "", sortField: any = "", sortOrder: any = ""): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.get(`${baseURL}api/v1/sites/${uniqueSiteId}/buildings/${uniqueBuildingId}/floors?keyword=${keyword}&currentstart=${currentStart}&sortcolumn=${sortField}&sortorder=${sortOrder}`);
        if (res.status === "done") {
            dispatch(setFloorList(res?.data?.floorDetails));
            dispatch(setFloorPageDetails(res?.data?.pageDetails));
        } else {
            dispatch(setErrors(res.error));
        }
    } catch (error) {
        dispatch(setErrors(error));
    }
};

export const getBuildingListLookup = (uniqueSiteId, body: any): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.post(`${baseURL}api/v1/floor/sites/${uniqueSiteId}/buildings`, body);
        if (res.status === "done") {
            dispatch(setBuildingList(res?.data?.buildingDetails));
        } else {
            dispatch(setErrors(res.error));
        }
    } catch (error) {
        dispatch(setErrors(error));
    }
};

export const onAddFloor =
	(body: any, uniqueSiteId, setMessage, setDisplayInfoModal): AppThunk =>
		async (dispatch) => {
			try {
				const res = await zlink.post(`${baseURL}api/v1/sites/${uniqueSiteId}/buildings/${body?.uniqueBuildingId}/floors/add`, body);
				if (res.status === "done") {
					dispatch(setNewFloorData(res.data));
					setMessage("Floor added");
					setDisplayInfoModal(true);
				} else {
					dispatch(setErrors(res.error));
					setMessage(res.message);
					setDisplayInfoModal(true);
				}
			} catch (error) {
				dispatch(setErrors(error));
			}
		};

export const getFloorDetails = (uniqueSiteId, uniqueBuildingId, uniqueFloorId): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.get(`${baseURL}api/v1/sites/${uniqueSiteId}/buildings/${uniqueBuildingId}/floors/${uniqueFloorId}`);
        if (res.status === "done") {
            dispatch(setFloorDetails(res?.data));
        } else {
            dispatch(setErrors(res.error));
        }
    } catch (error) {
        dispatch(setErrors(error));
    }
};

export const onUpdateFloor =
    (body: any, uniqueFloorId: any,uniqueSiteId: any, setMessage, setDisplayInfoModal): AppThunk =>
        async (dispatch) => {
            try {
                const res = await zlink.post(`${baseURL}api/v1/sites/${uniqueSiteId}/buildings/${body?.uniqueBuildingId}/floors/${uniqueFloorId}`, body);
                if (res.status === "done") {
                    dispatch(setUpdatedFloor(res.data));
                    setMessage("Floor updated");
                    setDisplayInfoModal(true);
                } else {
                    dispatch(setErrors(res.error));
                    setMessage(res.message);
                    setDisplayInfoModal(true);
                }
            } catch (error) {
                dispatch(setErrors(error));
            }
        };

export const deleteFloor = (uniqueSiteId, uniqueBuildingId, uniqueFloorId, setSelectedRows, setMessage, setDisplayInfoModal): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.post(`${baseURL}api/v1/sites/${uniqueSiteId}/buildings/${uniqueBuildingId}/floors/${uniqueFloorId}/remove`, null);
        if (res.status === "done") {
            dispatch(setDeletedFloor(uniqueFloorId));
            setMessage("Floor deleted");
            setDisplayInfoModal(true);
            setSelectedRows([]);
        } else {
            setMessage(res.message);
            setDisplayInfoModal(true);
        }
    } catch (error) {
        dispatch(setErrors(error));
    }
};

export const getAttachmentList = (uniqueFloorId, sortField: any = "", sortOrder: any = ""): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.get(`${baseURL}api/v1/floor/${uniqueFloorId}/attachments?sortcolumn=${sortField}&sortorder=${sortOrder}`);
		if (res.status === "done") {
			dispatch(setAttachmentList(res?.data?.attachmentList));
			dispatch(setAttachmentPageDetails(res?.data?.pageDetails));
		} else {
			dispatch(setErrors(res.error));
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};


export const onUploadFloorAttachment =
    (uniqueFloorId,selectedRows,body,setShowAddModal,setMessage,setDisplayInfoModal, setEnableUpload?: any): AppThunk =>
      async (dispatch) => {
        try {
          const res = await zlink.post(`${baseURL}api/v1/floor/${uniqueFloorId}/attachments/add`, body);
         
          if (res.status === "done") {
			  if (res.data && res.data.attachmentList && res.data.attachmentList.length) {
				  var count = Number(selectedRows.attachmentCount) + res.data.attachmentList.length;
				  dispatch(setFloorAttachmentCountList({ ...selectedRows, 'attachmentCount': count }));
				  dispatch(setNewAttachmentData(res.data?.attachmentList));
				  setShowAddModal(false);
				  setMessage("Attachment uploaded");
				  setDisplayInfoModal(true);
			  }
          } else {
			setEnableUpload(false);
			setDisplayInfoModal(true);
            setMessage(res.message);
          }
        } catch (error) {
          dispatch(setErrors(error));
        }
};

export const onUploadMultipleFloorAttachment =(selectedRows,body,setShowAddModal,setMessage,setDisplayInfoModal, setEnableUpload?: any, setSelectedRows?:any): AppThunk =>
      async (dispatch) => {
        try {
          const res = await zlink.post(`${baseURL}api/v1/floor/attachments/${selectedRows?.[0]?.uniqueBuildingId}/add`, body);
          if (res.status === "done") {
			  if (res.data && res.data.floorDetails && res.data.floorDetails.length) {
		          dispatch(setNewAttachmentList(res.data?.floorDetails))
				  setShowAddModal(false);
				  setSelectedRows(selectedRows);
				  setMessage("Attachment uploaded");
				  setDisplayInfoModal(true);
			  }
          } else {
			setEnableUpload(false);
			setDisplayInfoModal(true);
            setMessage(res.message);
          }
        } catch (error) {
          dispatch(setErrors(error));
        }
};

export const onEditAttachment =
    (uniqueFloorId,attachmentId: any = "",body:any,setShowEditModal,setMessage: any = "",setDisplayInfoModal, setEnableUpload?: any): AppThunk =>
      async (dispatch) => {
        try {
          const res = await zlink.post(`${baseURL}api/v1/floor/${uniqueFloorId}/attachments/${attachmentId}`, body);
         
          if (res.status === "done") {
            dispatch(setUpdatedAttachement(res?.data));
            setShowEditModal(false);
            setMessage("Attachment uploaded");
			setDisplayInfoModal(true);
          } else {
			setEnableUpload(false);
            setMessage(res.message);
			setDisplayInfoModal(true);
          }
        } catch (error) {
          dispatch(setErrors(error));
        }
};

export const onDeleteAttachment = (uniqueFloorId, attachmentId,fullPath,setMessage,setDisplayConfirmModal,setSelectedRows,setDisplayInfoModal,selectedRows): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.post(`${baseURL}api/v1/floor/${uniqueFloorId}/attachments/${attachmentId}/remove`,{filePath:fullPath});
		if (res.status === "done") {
			var count = Number(selectedRows.attachmentCount) - 1;
			dispatch(setFloorAttachmentCountList({...selectedRows,'attachmentCount':count}));
			dispatch(setDeletedAttachmentList(attachmentId));
			setSelectedRows([]);
			setMessage("Attachment deleted")
			setDisplayInfoModal(true)
			setDisplayConfirmModal(false)
		} else {
			dispatch(setErrors(res.error));
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const getAttachmentPath = (uniqueFloorId, attachmentId,setMessage,setDisplayConfirmModal,setSelectedRows,setDisplayInfoModal,selectedRows): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.get(`${baseURL}api/v1/floor/${uniqueFloorId}/attachments/${attachmentId}/file`);
		if (res.status === "done") {
		 dispatch(onDeleteAttachment(uniqueFloorId, attachmentId,res.data?.fullPath,setMessage,setDisplayConfirmModal,setSelectedRows,setDisplayInfoModal,selectedRows))
		} else {
			dispatch(setErrors(res.error));
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};
export const onDownloadAttachmentFile = (attachmentId, FullPath: any, setDisplayInfoModal, setMessage, renderFlag: boolean, setStreamData?: any, fileName?: any): AppThunk => async (dispatch) => {
	try {
		const request: any = {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "application/json; charset=utf-8",
				Authorization: sessionStorage.getItem("accesstoken"),
			},
			body: JSON.stringify({ FullPath: FullPath })
		};
		const downloadUrl = `${baseURL}api/v1/common/files/${attachmentId}/download`
		await fetch(downloadUrl, request).then((response: any) => {

			if (response.status === 200) {
				const res = new Response(
					new ReadableStream({
						async start(controller) {
							const reader = response.body.getReader();
							for (; ;) {
								const { done, value } = await reader.read();
								if (done) break;
								controller.enqueue(value);
							}
							controller.close();
						},
					})
				);
				if (!renderFlag) {
					res.blob().then((blob) => {
						let url = window.URL.createObjectURL(blob);
						let a = document.createElement("a");
						a.href = url;
						a.download = fileName;
						a.click();
					});
				} else {
					res.blob().then((blob) => {
						var result: any
						var reader = new FileReader();
						reader.onload = function (e) {
							result = e?.target?.result;
							setStreamData(result)
						}
						reader.readAsDataURL(blob);

					});
				}
			}
			else {
				setDisplayInfoModal(true)
				setMessage("File not found")
			}
		});

	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const onDownloadAttachment = (uniqueFloorId, attachmentId:any,setDisplayInfoModal,setMessage, viewFlag: boolean, setStreamData?:any,fileName?:any, setShowViewModal?: any): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.get(`${baseURL}api/v1/floor/${uniqueFloorId}/attachments/${attachmentId}/file`);
		if (res.status === "done") {
			if (viewFlag) {
				if (fileName) {
					if (fileName.split('.').pop() === "png" || fileName.split('.').pop() === "jpg" || fileName.split('.').pop() === "jpeg") {
						setShowViewModal(true);
						dispatch(onDownloadAttachmentFile(attachmentId, res.data?.fullPath, setDisplayInfoModal, setMessage, true, setStreamData, fileName));
					} else {
						dispatch(onDownloadAttachmentFile(attachmentId, res.data?.fullPath, setDisplayInfoModal, setMessage, false, setStreamData, fileName));
					}
				}
			} else {
				dispatch(onDownloadAttachmentFile(attachmentId, res.data?.fullPath, setDisplayInfoModal, setMessage, false, setStreamData, fileName));
			}
		} else {
			dispatch(setErrors(res.error));
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const {
    setErrors,
    setFloorList,
    setFloorPageDetails,
    setNewFloorData,
    setBuildingList,
    setUpdatedFloor,
    setFloorDetails,
    setDeletedFloor,
    setAttachmentList,
	setAttachmentPageDetails,
	setNewAttachmentData,
	setUpdatedAttachement,
	setNewAttachmentList,
	setDeletedAttachmentList,
	setFloorAttachmentCountList,
} = FloorSlice.actions;

export default FloorSlice.reducer;