import React from 'react'

const LookUpsDocument = () => {
  return (
    <>
      <h4 className="doc-title mb-2">
        Look Ups
      </h4>

      <p>
        Options to manage Health Classifications and NFPA Classifications are grouped under Look Ups menu
      </p>
    </>
  );
}
export default LookUpsDocument;
