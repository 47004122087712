import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { zlink, baseURL } from "../../../../service/HttpService";
import { AppThunk } from "../../../store";

export interface feedbackState {
  errors: any;
  feedbackData: any;
}

let initialState: feedbackState = {
  errors: "",
  feedbackData: {}
};

const feedbackSlice = createSlice({
  name: "feedback",
  initialState,
  reducers: {
    setErrors: (state, { payload }: PayloadAction<any>) => {
      state.errors = payload;
    },
    setFeedbackData: (state, { payload }: PayloadAction<any>) => {
      state.feedbackData = payload;
    }
  },
});

export const getFeedback = (): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.get(`${baseURL}api/v1/feedback`);
    if (res.status === "done") {
      if (res.data) {
        dispatch(setFeedbackData(res.data));
      }
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const sendFeedback = (body: any, setLoader, setShowFeedback, setMessage, setDisplayInfoModal): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.post(`${baseURL}api/v1/feedback`, body);
    if (res.status === "done") {
      setShowFeedback(false)
      setMessage("Feedback Sent");
      setDisplayInfoModal(true);
    } else {
      dispatch(setErrors(res.error));
      if (res.message) {
        setMessage(res.message);
        setDisplayInfoModal(true);
      }
    }
    setLoader(false);
  } catch (error) {
    dispatch(setErrors(error));
    setLoader(false);
  }
};

export const {
  setErrors,
  setFeedbackData
} = feedbackSlice.actions;

export default feedbackSlice.reducer;