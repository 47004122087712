
import React, { memo, useCallback, useEffect, useState } from 'react'
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
//components
import AddInspectionField from '../Manage/AddInspectionField/AddInspectionField';
import EditInspectionField from '../Manage/EditInspectionField/EditInspectionField';
import InspectionForms from 'components/common/Forms/InspectionForm/InspectionForms';
import InformationBox from 'components/common/Message/InformationBox/InformationBox';
import AddInspectionFieldValues from '../Manage/AddInspectionFieldValues/AddInspectionFieldValues';
//utility
import { addFieldValues, addNewInspectionField, deleteInspectionQuestion, removeFieldValue, updateFieldValue, updateInspectionQuestion } from '../Utility/InspectionFieldUtility';
//redux
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'redux/hooks';
import { checkUsageOfSystemInspectionField, checkUsageOfSystemInspectionFieldValue, onAddNewSystemInspectionFieldValue, onAddSystemInspectionField, onGetInspectionSystems, onUpdateSystemInspectionField, onUpdateSystemInspectionFieldValue, setDeletedFieldValue, setDeletedSystemInspectionField, setInspectionSystems, setIsCheckInUse, setNewFieldValue, setNewSystemInspectionField, setupdatedFieldValue, setUpdatedSystemInspectionField,setUpdatedInspectionDatafieldOrder} from 'redux/pages/Manage/InspectionSettings/InspectionFields/InspectionFieldSlice';

interface SystemPropType {

}
const System: React.FC<SystemPropType> = (props) => {
  const { } = props;

  const [inspectionFormSetup, setInspectionFormSetup] = useState<any>({});
  const [showAddInspectionField, setShowAddInspectionField] = useState(false);
  const [selectedInspection, setSelectedInspection] = useState<any>(null);
  const [showEditInspectionField, setShowEditInspectionField] = useState(false);
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [showAddInspectionFieldValues, setShowAddInspectionFieldValues] = useState(false);
  const [fieldValueList, setFieldValueList] = useState<any>([]);
  const [displayInfoModal, setDisplayInfoModal] = useState(false);
  const [message, setMessage] = useState<string>("");

  const dispatch = useDispatch<any>();
  const inspectionSystems = useAppSelector((state) => state.InspectionField.inspectionSystems);
  const newSystemInspectionField = useAppSelector((state) => state.InspectionField.newSystemInspectionField);
  const newFieldValue = useAppSelector((state) => state.InspectionField.newFieldValue);
  const updatedFieldValue = useAppSelector((state) => state.InspectionField.updatedFieldValue);
  const deletedFieldValue = useAppSelector((state) => state.InspectionField.deletedFieldValue);
  const updatedSystemInspectionField = useAppSelector((state) => state.InspectionField.updatedSystemInspectionField);
  const deletedSystemInspectionField = useAppSelector((state) => state.InspectionField.deletedSystemInspectionField);
  const isCheckInUse = useAppSelector((state) => state.InspectionField.isCheckInUse);
  const updatedInspectionDatafieldOrder = useAppSelector((state) => state.InspectionField.updatedInspectionDatafieldOrder);

  useEffect(() => {
    dispatch(onGetInspectionSystems());
  }, []);

  useEffect(() => {
    if (newSystemInspectionField) {
      if (newSystemInspectionField?.isSuccess) {
        let convNewField = JSON.parse(JSON.stringify(newSystemInspectionField?.data))
        setShowAddInspectionField(false);
        const result = addNewInspectionField(selectedInspection, inspectionFormSetup?.inspectionGroupFields, convNewField);
        inspectionFormSetup.inspectionGroupFields = result;
        setInspectionFormSetup({ ...inspectionFormSetup });
        if (convNewField?.isValidated) {
          setShowAddInspectionFieldValues(true);
        } else {
          setMessage("Data Field Added");
          setDisplayInfoModal(true);
          dispatch(setNewSystemInspectionField(""));
        }
      } else {
        setMessage(newSystemInspectionField?.message);
        setDisplayInfoModal(true);
      }
    }
  }, [newSystemInspectionField]);

  useEffect(() => {
    if (newFieldValue) {
      if (newFieldValue?.isSuccess) {
        let newField = JSON.parse(JSON.stringify(newFieldValue?.validatedFields));
        const selectedFields = newSystemInspectionField?.data ? newSystemInspectionField?.data : selectedInspection;
        const result = addFieldValues(selectedFields, inspectionFormSetup?.inspectionGroupFields, newField, fieldValueList);
        inspectionFormSetup.inspectionGroupFields = result;
        setInspectionFormSetup({ ...inspectionFormSetup });
        setFieldValueList([...fieldValueList]);
        setMessage("Additional Data Field Value added");
        setDisplayInfoModal(true);
      } else {
        setMessage(newFieldValue?.message);
        setDisplayInfoModal(true);
      }
      dispatch(setNewFieldValue(""));
    }
  }, [newFieldValue]);

  useEffect(() => {
    if (updatedFieldValue) {
      if (updatedFieldValue?.isSuccess) {
        const selectedFields = newSystemInspectionField?.data ? newSystemInspectionField?.data : selectedInspection;
        const result = updateFieldValue(selectedFields, inspectionFormSetup?.inspectionGroupFields, updatedFieldValue?.validatedFields, fieldValueList);
        inspectionFormSetup.inspectionGroupFields = result;
        setInspectionFormSetup({ ...inspectionFormSetup });
        setFieldValueList([...fieldValueList]);
        setMessage("Additional Data Field Value updated");
        setDisplayInfoModal(true);
      } else {
        setMessage(updatedFieldValue?.message);
        setDisplayInfoModal(true);
      }
      dispatch(setupdatedFieldValue(""));
    }
  }, [updatedFieldValue]);

  useEffect(() => {
    if (inspectionSystems) {
      let value: any = JSON.stringify(inspectionSystems);
      value = value.replace(/\"inspectionGroupSectionId\":/g, '"key":');
      value = value.replace(/\"inspectionGroupSectionName\":/g, '"label":');
      value = value.replace(/\"inspectionGroupSections\":/g, '"children":');
      value = JSON.parse(value);
      setInspectionFormSetup(value);
      dispatch(setInspectionSystems(""));
    }
  }, [inspectionSystems])

  useEffect(() => {
    if (deletedFieldValue) {
      const selectedFields = newSystemInspectionField?.data ? newSystemInspectionField?.data : selectedInspection;
      const result = removeFieldValue(selectedFields, inspectionFormSetup?.inspectionGroupFields, deletedFieldValue, fieldValueList);
      inspectionFormSetup.inspectionGroupFields = result;
      setInspectionFormSetup({ ...inspectionFormSetup });
      dispatch(setDeletedFieldValue(""));
      setFieldValueList([...fieldValueList]);
      setMessage("Selected Additional Data Field Value deleted");
      setDisplayInfoModal(true);
      setShowDeletePrompt(false);
    }
  }, [deletedFieldValue]);

  useEffect(() => {
    if (updatedSystemInspectionField) {
      if (updatedSystemInspectionField?.isSuccess) {
        let convField = JSON.parse(JSON.stringify(updatedSystemInspectionField?.data));
        const result = updateInspectionQuestion(selectedInspection, inspectionFormSetup?.inspectionGroupFields, convField);
        inspectionFormSetup.inspectionGroupFields = result;
        setInspectionFormSetup({ ...inspectionFormSetup });
        setShowEditInspectionField(false);
        setMessage("Data Field Updated");
        setDisplayInfoModal(true);
      } else {
        setMessage(updatedSystemInspectionField?.message);
        setDisplayInfoModal(true);
      }
      dispatch(setUpdatedSystemInspectionField(""));
    }
  }, [updatedSystemInspectionField]);

  useEffect(() => {
    if (deletedSystemInspectionField) {
      const result = deleteInspectionQuestion(selectedInspection, inspectionFormSetup?.inspectionGroupFields, deletedSystemInspectionField);
      inspectionFormSetup.inspectionGroupFields = result;
      setInspectionFormSetup({ ...inspectionFormSetup });
      dispatch(setDeletedSystemInspectionField(""));
      setShowDeletePrompt(false);
      setMessage("Data Field Deleted");
      setDisplayInfoModal(true);
    }
  }, [deletedSystemInspectionField])

  useEffect(() => {
    if (isCheckInUse) {
      setMessage("Selected Data Item is in use, cannot be deleted");
      setDisplayInfoModal(true);
      dispatch(setDeletedSystemInspectionField(""));
      setShowDeletePrompt(false);
      dispatch(setIsCheckInUse(false));
    }
  }, [isCheckInUse])

  useEffect(() => {

    if (updatedInspectionDatafieldOrder) {
        setDisplayInfoModal(true);
        setMessage("Field Order updated");
        dispatch(setUpdatedInspectionDatafieldOrder(""));

        updatedInspectionDatafieldOrder?.forEach((element) => {
            const index = inspectionFormSetup.inspectionGroupFields.findIndex((item) => item.dataFieldId === element.DataFieldId);
            if (index >= 0) {
              inspectionFormSetup.inspectionGroupFields[index].position = element.position;
            }
        });

        const dd= [...inspectionFormSetup.inspectionGroupFields].sort((a, b) => a.position - b.position);
        inspectionFormSetup.inspectionGroupFields = dd;
        setInspectionFormSetup({ ...inspectionFormSetup});
    }
}, [updatedInspectionDatafieldOrder]);

  const onAddClick = useCallback((data) => {
    if (data?.isValidated) {
      setShowAddInspectionFieldValues(true);
      setFieldValueList(data?.validatedValues)
    }
    else
      setShowAddInspectionField(true);
    setSelectedInspection(data);
  }, [showAddInspectionField, selectedInspection, showAddInspectionFieldValues, fieldValueList]);

  const onEditClick = useCallback((data) => {
    if (data) {
      setShowEditInspectionField(true);
      setSelectedInspection(data);
    }
  }, [selectedInspection, showEditInspectionField]);

  const onDeleteClick = useCallback((data) => {
    if (data) {
      setShowDeletePrompt(true);
      setSelectedInspection(data);
    }
  }, [showDeletePrompt])

  const onSubmitInspection = useCallback((body) => {
    dispatch(onAddSystemInspectionField(body));
  }, []);

  const onUpdateInspection = useCallback((body) => {
    dispatch(onUpdateSystemInspectionField(body?.uniqueDataFieldId, body));
  }, []);

  const onDeleteInspectionField = useCallback((deletedItem) => {
    let body = {
      isSection: deletedItem?.isSection
    }
    dispatch(checkUsageOfSystemInspectionField(deletedItem?.uniqueDataFieldId, body, deletedItem))
  }, [])

  const onAddFieldValue = useCallback((fieldName, reset) => {
    const selectedFields = newSystemInspectionField?.data ? newSystemInspectionField?.data : selectedInspection;
    let body = {
      fieldvalue: fieldName,
      dataEntryControlId: selectedFields?.dataEntryControl?.dataEntryControlId
    };
    dispatch(onAddNewSystemInspectionFieldValue(selectedFields?.uniqueDataFieldId, body, reset));
  }, [selectedInspection, newSystemInspectionField]);

  const onUpdateFieldValue = useCallback((newData) => {
    const selectedFields = newSystemInspectionField?.data ? newSystemInspectionField?.data : selectedInspection;
    let body = {
      fieldvalue: newData?.dataFieldLookupValue
    }
    dispatch(onUpdateSystemInspectionFieldValue(selectedFields?.uniqueDataFieldId, newData.dataFieldLookupId, body, newData));
  }, [selectedInspection, newSystemInspectionField]);

  const onDeleteFieldValue = useCallback((deletedItem) => {
    const selectedFields = newSystemInspectionField?.data ? newSystemInspectionField?.data : selectedInspection;
    dispatch(checkUsageOfSystemInspectionFieldValue(selectedFields?.uniqueDataFieldId, deletedItem?.dataFieldLookupId, deletedItem));
  }, [selectedInspection, newSystemInspectionField]);

  const renderFooter = () => {
    return (
      <>
        <Button label="No" onClick={() => setShowDeletePrompt(false)} className='cancel-btn' />
        <Button label="Yes" onClick={() => onDeleteInspectionField(selectedInspection)} className='submit-btn' autoFocus />
      </>
    );
  }

  return (
    <div className="question">
      <InspectionForms
        type='SYSTEM'
        isExpanded={false}
        isAddAction={true}
        isCheckedAction={true}
        formElements={inspectionFormSetup}
        onAddClicked={onAddClick}
        onEditClicked={onEditClick}
        onDeleteClicked={onDeleteClick} />
      {showAddInspectionField &&
        <AddInspectionField
          type='SYSTEM'
          selectedNode={inspectionFormSetup}
          parentItem={selectedInspection}
          showModal={showAddInspectionField}
          onSubmitInspection={onSubmitInspection}
          onHideModal={() => setShowAddInspectionField(false)}
        />
      }
      {showEditInspectionField &&
        <EditInspectionField
          type='SYSTEM'
          selectedItem={selectedInspection}
          selectNode={inspectionFormSetup}
          showModal={showEditInspectionField}
          onUpdate={onUpdateInspection}
          onHideModal={() => setShowEditInspectionField(false)} />
      }
      {showDeletePrompt &&
        <Dialog
          header="Tech Advance +"
          visible={showDeletePrompt}
          style={{ width: '35vw' }}
          footer={renderFooter()}
          onHide={() => setShowDeletePrompt(false)}
          className='delete-modal'
        >
          {selectedInspection?.isSection ?
            <p className='content'>Are you sure you want to delete the selected Data Group ?</p> :
            <p className='content'>Are you sure you want to delete the selected Data Item ?</p>
          }
        </Dialog>
      }
      {showAddInspectionFieldValues &&
        <AddInspectionFieldValues
          selectedField={newSystemInspectionField?.data ? newSystemInspectionField?.data : selectedInspection}
          fieldValueList={fieldValueList}
          onAddFieldValue={onAddFieldValue}
          onUpdateFieldValue={onUpdateFieldValue}
          onDeleteFieldValue={onDeleteFieldValue}
          onHideModal={() => {
            setShowAddInspectionFieldValues(false);
            setFieldValueList([]);
            dispatch(setNewSystemInspectionField(""));
          }}
          showModal={showAddInspectionFieldValues} />
      }
      <InformationBox
        showInfoModal={displayInfoModal}
        setShowInfoModal={setDisplayInfoModal}
        message={message} />
    </div>
  )
}

export default memo(System);