import React from 'react'

const ReportsContractDocument = () => {
  return (
    <>
      <h4 className="doc-title mb-2">Contracts Report</h4>
      <p>
        This report shows the list of all Contracts with the Customer Number, Site Number, 
        Contract Name, Contract Number, Customer Name, Site Name, Start Date and End Date details. 
        The report can be customized by selecting Customize button.
      </p>

      <p>
        Drop downs for Status and Branch are provided in the Reports page, in order to filter 
        the Contracts based on Status and Branch Office.
      </p>
    </>
  );
}
export default ReportsContractDocument;