import React, { useState, useRef, useCallback, useEffect } from "react";
import { AutoComplete } from "primereact/autocomplete";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../redux/hooks";
import { setContractSearchId } from 'redux/pages/Inspection/Contracts/contractSlice';
import { setUserList, setUserRoleIdOnSearch, setUserSearchId } from 'redux/pages/Administration/User/userSlice';
import { fetchLoggedInUserDetails, setCustomerSearchId } from "redux/pages/Administration/administrationSlice";
import { setInspectionSearchId, setNotificationSearchId } from 'redux/pages/Inspection/InspectionTask/inspectionTaskSlice';
import {
  getSiteSearchDetails,
  getCustomerSearchDetails,
  getContractSearchDetails,
  getUserSearchDetails,
  getInspectionDetails,
  getNotificationNumDetails,
  getSystemSearchDetails,
} from "../../redux/pages/Administration/Search/searchSlice";
import {
  setAsActiveSiteForSite,
  setSiteSearchId,
} from "../../redux/pages/Administration/Site/siteSlice";
import {setSystemSearchId,} from "../../redux/pages/Inspection/Systems/systemSlice";
//routing
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "constants/AppRoutes";
import { setDefaultValues } from "redux/pages/Inspection/InspectionTask/inspectionTaskFilterSlice";

interface Props {}

export const Search: React.FC<Props> = () => {
  const switchedSite = useAppSelector((state) => state.site.activeSite);
  const siteDetails = useAppSelector((state) => state.search.siteDetails);
  const customerDetails = useAppSelector((state) => state.search.customerDetails);
  const systemDetails =  useAppSelector((state) => state.search.systemDetails);
  const userDetails = useAppSelector((state) => state.search.userDetails);
  const contractDetails = useAppSelector((state) => state.search.contractSearchDetails);
  const inspectionDetails = useAppSelector((state) => state.search.inspectionDetails);
  const notificationNumDetails = useAppSelector((state) => state.search.notificationNumDetails);
  const userPrivileges = useAppSelector((state) => state.user.userPrivileges);

  const [roleList, setRoleList] = useState<Array<any>>([]);
  const [search, setsearch] = useState<any>(null);
  const [filteredSearchs, setFilteredSearchs] = useState<any>(null);
  const [searchType, setSearchType] = useState<any>("Site");
  const [placeholder, setPlaceHolder] = useState<any>("Enter Site Name / Number");
  const [searchList, setSearchList] = useState<any>([]);
  const [isNavigate, setIsNavigate] = useState<boolean>(false);
  const [activeSiteId, setActiveSiteId] = useState<any>("");
  const [isCustomerSelected, setIsCustomerSelected] = useState(false);
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  useEffect(()=>{
    if(userPrivileges && userPrivileges.length){
      filterSearchTabList();
    }
  },[userPrivileges])

  useEffect(() => {
    dispatch(setInspectionSearchId(''));
  }, []);
  const filterSearchTabList = () => {
    let searchTabs: any = [];
    searchTabs.push({ name: "Site", code: "Si", selected: true, id: 1 });
    searchTabs.push({ name: "Contract", code: "CO", selected: false, id: 3 });
    searchTabs.push({ name: "System", code: "SY", selected: false, id: 4 });
    searchTabs.push({ name: "Inspection Task", code: "IN", selected: false, id: 5 });
    searchTabs.push({ name: "Notification Number", code: "NO", selected: false, id: 6 });
    userPrivileges.map(element => {
      if (element === 'SIETAP_CLIENTS_MANAGE') {
        searchTabs.push({ name: "Customer", code: "CU", selected: false, id: 2 })
      } else if (element === 'SIETAP_USERS_MANAGE') {
        searchTabs.push({ name: "Users", code: "SE", selected: false, id: 7 })
      }
    });
    searchTabs = searchTabs.sort((a, b) => (a.id - b.id))
    setRoleList(searchTabs);
  }

  useEffect(() => {
    if (search?.length >= 3) {
      switch (searchType) {
        case "Site":
          dispatch(getSiteSearchDetails(search));
          break;
        case "Customer":
          if (!isCustomerSelected)
          dispatch(getCustomerSearchDetails(search));
          break;
        case "System":
          dispatch(getSystemSearchDetails(search));
          break;
        case "Contract":
            dispatch(getContractSearchDetails(search));
            break;
        case "Users":
            dispatch(getUserSearchDetails(search));
            break;
        case "Inspection Task":
            dispatch(getInspectionDetails(search));
            break
        case "Notification Number":
            dispatch(getNotificationNumDetails(search));
            break;
        default:
          dispatch(getSiteSearchDetails(search));
      }
    }
  }, [dispatch, search, searchType, isCustomerSelected]);

  useEffect(() => {
    setSearchList([]);
    setsearch("")
    switch (searchType) {
      case "Site":
        setPlaceHolder("Enter Site Name / Number");
        break;
      case "Customer":
        setPlaceHolder("Enter Customer Name / Number");
        break;
      case "Contract":
        setPlaceHolder("Enter Contract Name / Number");
        break;
      case "System":
        setPlaceHolder("Enter System Name");
        break;
      case "Inspection Task":
        setPlaceHolder("Enter Inspection Task Name");
        break;
      case "Notification Number":
        setPlaceHolder("Enter Notification Number");
        break;
      case "Users":
        setPlaceHolder("Enter User Name");
        break;
      default:
        setPlaceHolder("Enter Site Name / Number");
        break;
    }
  }, [searchType]);

  useEffect(() => {
    if (searchType === "Site") {
      let site: { name: string; siteId: string }[] = [];
      siteDetails?.map((item: any) => {
        return site.push({ name: item.siteName, siteId: item.uniqueSiteId });
      });
      setSearchList(site);
    } else if (searchType === "Customer") {
      let Customer:  any[] = [];
      customerDetails?.map((item: any) => {
        return Customer.push({name: item.customerName,code: item.uniqueCustomerId, siteId:item.uniqueSiteId});
      });
      setSearchList(Customer);
    } else if(searchType === "Contract"){
        let contract: any[] = [];
        contractDetails?.map((item:any)=>{
            return contract.push({ name: item.contractName, code: item.uniqueContractId, siteId: item.uniqueSiteId });
        });
        setSearchList(contract);
    } else if(searchType === "Users"){
      let user: any[] = [];
      userDetails?.map((item:any)=>{
        return user.push({ name: item.name, code: item.uniqueUserId, UserRole:item.userRole });
      });
      setSearchList(user);
    } else if (searchType === "System") {
      let system: any[] = [];
      systemDetails?.map((item: any) => {
        return system.push({ name: item.systemName, code: item.uniqueSystemId, siteId:item.uniqueSiteId });
      });
      setSearchList(system);
    } else if (searchType === "Inspection Task") {
        let inspection: any[] = [];
        inspectionDetails?.map((item:any)=>{
            return inspection.push({ name: item.taskName, code: item.uniqueInspectionTaskId, siteId: item.uniqueSiteId, uniqueCustomerId: item.uniqueCustomerId, uniqueContractId: item.uniqueContractId });
        });
        setSearchList(inspection);
    } else if (searchType === "Notification Number") {
        let notification: any[] = [];
        notificationNumDetails?.map((item:any)=>{
          return notification.push({ name: item.taskName, code: item.uniqueInspectionTaskId, siteId: item.uniqueSiteId, uniqueCustomerId: item.uniqueCustomerId, uniqueContractId: item.uniqueContractId });
        });
        setSearchList(notification);
    }   
  }, [siteDetails, customerDetails,contractDetails,systemDetails,userDetails,inspectionDetails, notificationNumDetails, searchType]);

  useEffect(() => {
    if (isNavigate && switchedSite.uniqueSiteId === activeSiteId) {
      setIsNavigate(false);
      dispatch(fetchLoggedInUserDetails());
      switch (searchType) {
        case "Site":
          navigate(`/${APP_ROUTES.SITES}`);
          break;
        case "Customer":
          navigate(`/${APP_ROUTES.CUSTOMERS}`);
          break;
        case "System":
          navigate(`/${APP_ROUTES.SYSTEMS}`);
          break;
        case "Contract":
          navigate(`/${APP_ROUTES.CONTRACTS}`);
          break;
        case "Users":
          navigate(`/${APP_ROUTES.USERS}`);
          break;
        case "Inspection Task":
        case "Notification Number":
          navigate(`/${APP_ROUTES.INSPECTION_TASKS}`);
          break;
        default:
          navigate(`/${APP_ROUTES.DASHBOARD}`);
          break;
      }
    }
  }, [switchedSite, navigate, isNavigate, searchType, dispatch, activeSiteId]);

  const searchContainerRef = useRef(null);

  useEffect(() => {   
    let searchResults = [];
    if (search?.length && searchList?.length) {
      searchResults = searchList.filter((item) => {
        return item?.name.toLowerCase().includes(search?.toLowerCase());
      });
      setTimeout(() => {
        setFilteredSearchs(searchResults);
      }, 200);
    } else {
      setFilteredSearchs(searchResults);
    }
  }, [searchList]);

    
  const searchAdmin = (event: { query: string }) => {
    if (!event.query.trim().length) {
      setFilteredSearchs(searchList);
    }
  };

  const itemTemplate = (item: any) => {
    return (
      <div className="data-item">
        <div>{item.name}</div>
      </div>
    );
  };

  const handleRoleSelect = useCallback((selectedItem) => {
    setSearchType(selectedItem.name);
    const modifiedRoleList = roleList.map((item) => {
      if (item.code === selectedItem.code) {
        item["selected"] = !item["selected"];
      } else {
        item["selected"] = false;
      }

      return item;
    });
    setRoleList(modifiedRoleList);
  }, [roleList]);

  const getSelectedValue = (data: any) => {   
    setIsNavigate(true);
    setActiveSiteId(data.siteId);
    let defaultBody: any;
    switch (searchType) {
      case "Site":
        dispatch(setSiteSearchId(data.siteId));
        dispatch(setAsActiveSiteForSite(data.siteId, "", ""));
        break;
      case "Customer":
        setIsCustomerSelected(true)
        dispatch(setCustomerSearchId(data.code));
        dispatch(setAsActiveSiteForSite(data.siteId, "", ""));
        break;
      case "System":
        dispatch(setSystemSearchId(data.code));
        dispatch(setAsActiveSiteForSite(data.siteId, "", ""));
        break;
      case "Contract":
        dispatch(setContractSearchId(data.code))
        dispatch(setAsActiveSiteForSite(data.siteId,"", ""));
        break;  
      case "Users":
        dispatch(setUserSearchId(data.code))
        dispatch(setUserRoleIdOnSearch(data.UserRole))
        dispatch(setUserList([]))
        navigate(`/${APP_ROUTES.USERS}`);
        break; 
      case "Inspection Task":
       dispatch(setInspectionSearchId([{uniqueInspectionTaskId:data.code,searchBy:"INSPECTION_TASK_SEARCH"}]))
        defaultBody = {
          ActiveCustomerId: data.uniqueCustomerId,
          ActiveContractId: data.uniqueContractId,
        };
        dispatch(setDefaultValues(defaultBody));
        dispatch(setAsActiveSiteForSite(data.siteId,"", ""));
        break
      case "Notification Number":
        defaultBody = {
          ActiveCustomerId: data.uniqueCustomerId,
          ActiveContractId: data.uniqueContractId,
        };
        dispatch(setDefaultValues(defaultBody));
        dispatch(setNotificationSearchId(data.code))
        dispatch(setAsActiveSiteForSite(data.siteId,"", ""));
        dispatch(setInspectionSearchId([{uniqueInspectionTaskId:data.code,searchBy:"NOTIFICATION_NUMBER_SEARCH"}]))
        break;
      default:
        dispatch(setAsActiveSiteForSite(data.code, "", ""));
        break;
    }
  };

  const onPasteHandler = (event : any) => { 
    event.clipboardData.items[0].getAsString(text=>{
      if (text) {
        setsearch(text)
      }
    });
  } 

  const renderSearch = () => {
    return (
      <>
        <div className="administration-search-container d-flex">
          <section className="administration-search-leftsidebar d-flex flex-column">
            <div className="administration-search-listname">
              <div className="main">
                <ul className="list-items">
                  {roleList.map((item) => (
                    <li
                      key={item.code}
                      className={item.selected ? "active" : ""}
                      onClick={() => handleRoleSelect(item)}
                    >
                      {item.name}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </section>
          <section className="administration-search-expanded">
            <div className="search-administration-field">
              <div className="main-search" ref={searchContainerRef}>
                <AutoComplete
                  value={search}
                  suggestions={filteredSearchs}
                  completeMethod={searchAdmin}
                  field="name"
                  dropdown
                  dropdownIcon="pi pi-search"
                  forceSelection={false}
                  itemTemplate={itemTemplate}
                  onChange={(e) => setsearch(e.value)}
                  onPaste={(e:any) =>onPasteHandler(e)}   
                  onSelect={(e) => {
                    getSelectedValue(e.value);
                  }}
                  aria-label="Countries"
                  appendTo={searchContainerRef?.current}
                  placeholder={placeholder}
                  minLength={3}
                />
              </div>
            </div>
          </section>
        </div>
      </>
    );
  };

  return <div className="search-container">{renderSearch()}</div>;
};

export default Search;
