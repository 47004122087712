import React, { useState, useRef, useEffect } from 'react';
import { useForm, Controller } from "react-hook-form";
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { classNames } from 'primereact/utils';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import { BsExclamationCircleFill } from 'react-icons/bs';
import moment from 'moment';

//redux


import { useDispatch } from 'react-redux';
import { useAppSelector } from 'redux/hooks';
import { getContractDetails, getCustomerLookup, getSalesOfficeLookup } from 'redux/pages/Inspection/Contracts/contractSlice';

import InformationBox from 'components/common/Message/InformationBox/InformationBox';
import AddModal from 'components/Table/AddModal/AddModal';
import {
    USAPattern,
    CanadaPattern,
} from "./ContractNumberPattern";


interface Props {
    showModal: boolean,
    setShowModal: (state: boolean) => void,
    selectedRows: any;
    onEditContractClick: (data: any) => void,
}

type FormData = {
    uniqueCustomerId: string,
    contractNumber: string,
    contractName: string,
    startDate: any,
    endDate: any,
    notes: string,
    status: string,
    salesOffice: string
};

export const EditContract: React.FC<Props> = ({
    showModal = false,
    setShowModal = () => { },
    selectedRows = [],
    onEditContractClick = () => { },
}) => {

    const {
        control,
        handleSubmit,
        getValues,
        setValue,
        formState: { errors },
    } = useForm<FormData>({
        defaultValues: {
            uniqueCustomerId: '',
            contractNumber: '',
            contractName: '',
            startDate: null,
            endDate: null,
            notes: '',
            status: '',
            salesOffice: ''
        }
    });

    const contractDetails = useAppSelector((state) => state.contract.contractDetails)
    const customerData = useAppSelector((state) => state.contract.customerData);
    const loggedInUserData = useAppSelector((state) => state.administration.loggedInUserDetails)
    const salesOfficeData = useAppSelector((state) => state.contract.salesOfficeData);
    const [displayInfoModal, setDisplayInfoModal] = useState(false);
    const [message, setMessage] = useState("");
    const [customerLookup, setCustomerLookup] = useState([{ name: "", code: "" }]);
    const [salesOfficeLookup, setSalesOfficeLookup] = useState([{ name: "", code: "" }]);
    const [showPatternModal, setShowPatternModal] = useState(false);
    const [pattern, setPattern] = useState<any>([]);
    const [countryId, setCountryId] = useState<any>(220)
    const [contractNumberPattern, setContractNumberPattern] = useState<any>();
    const editForm: any = useRef();

    const dispatch = useDispatch<any>()

    useEffect(() => {
        const body = {
            uniqueSiteId: null
        }
        dispatch(getCustomerLookup(body));
        dispatch(getSalesOfficeLookup());
        setValue("startDate",new Date(selectedRows[0]?.startDate?.replace(/-/g, '\/')))
        setValue("endDate",new Date(selectedRows[0]?.endDate?.replace(/-/g, '\/')))
        dispatch(getContractDetails(selectedRows[0]?.uniqueContractId))
    }, [dispatch, selectedRows])

    useEffect(() => {
        if (customerData?.length) {
            let customer: { name: string, code: string }[] = [];
            customerData?.map((item: any) => {
                return customer.push({ name: item.customerName, code: item.uniqueCustomerId });
            })
            setCustomerLookup(customer)
        }

    }, [customerData])

    useEffect(() => {
        if (salesOfficeData?.length) {
            let salesOffice: { name: string, code: string }[] = [];
            salesOfficeData?.map((item: any) => {
                return salesOffice.push({ name: item.salesOfficeName, code: item.uniqueSalesOfficeId });
            })
            setSalesOfficeLookup(salesOffice)
        }

    }, [salesOfficeData])

    useEffect(() => {
        if (contractDetails !== "") {
            setValue("uniqueCustomerId", contractDetails?.customerName)
            setValue("contractNumber", contractDetails?.contractNumber)
            setValue("contractName", contractDetails?.contractName)
            setValue("notes", contractDetails?.notes)
            setValue("salesOffice", contractDetails?.salesOfficeName)
        }

    }, [contractDetails, setValue])
    useEffect(() => {
        if (loggedInUserData) {
            setCountryId(loggedInUserData.userDefaults?.countryId)
        }
    }, [loggedInUserData])
    useEffect(() => {
        if (countryId === 220) {
            setPattern(USAPattern);
            setContractNumberPattern(/^([5]\d{9}|44OP[-]\d{6}|26\d{8}|44OC[-]\d{7})$/);
        } else {
            setPattern(CanadaPattern);
            setContractNumberPattern(/^(([4]\d{7})|((60|13)\d{5})|([a-zA-Z][-][0-9]{2}[-][a-zA-Z][-][0-9]{4})|([a-zA-Z][-]SI[-][0-9]{5}))$/);
        }
    }, [countryId]);

    const onEditFormSubmit = (data: any) => {
        if (new Date(data.startDate) > new Date(data.endDate)) {
            setMessage("End Date should be greater than Start Date")
            setDisplayInfoModal(true);
        } else {
            let customer = customerLookup.find((item: any) => item.name === data.uniqueCustomerId)
            if (customer)
                data.uniqueCustomerId = customer.code;
            data.status = selectedRows[0]?.status;
            data.startDate = moment(data?.startDate).format("YYYY-MM-DD").toString()
            data.endDate = moment(data?.endDate).format("YYYY-MM-DD").toString()
            data.contractName = data.contractName?.trim();
            data.notes = data.notes?.trim();
            onEditContractClick && onEditContractClick(data)
        }
    };

    const formSubmitHandler = (data: any) => {
        editForm.current.requestSubmit();
    };

    const getFormErrorMessage = (name) => {
        return errors[name] && <span className="tooltip-text"><BsExclamationCircleFill />{errors[name].message}</span>
    };

    //handle form validation errors


    return (
        <div>
            <AddModal header='Edit Contract' showModal={showModal} setShowModal={setShowModal} OnSaveClick={formSubmitHandler} isAdd={false} style={{ width: '35vw', maxHeight: '95%' }}>
                <form onSubmit={handleSubmit(onEditFormSubmit)} ref={editForm}>
                    <Dialog
                        header="Contract Number"
                        visible={showPatternModal}
                        onHide={() => setShowPatternModal(false)}
                        className='pattern-overlay'
                        style={{ width: '32vw' }}
                    >
                        <DataTable
                            showGridlines={false}
                            value={pattern}
                        >
                            <Column field="pattern" header="Pattern" />
                            <Column field="format" header="Format" />
                            <Column field="description" header="Description" />
                        </DataTable>
                    </Dialog>

                    <div className="field col-12 md:col-4">
                        <span className="p-float-label">
                            <Controller name="uniqueCustomerId" control={control} rules={{ required: 'Customer is required.' }} render={({ field, fieldState }) => (
                                <Dropdown disabled inputId="dropdown" className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })}  {...field} value={field.value} onChange={(e) => field.onChange(e.value)} options={customerLookup} optionLabel="name" optionValue="name" />
                            )} />
                            <label className='mandatory' htmlFor="dropdown">Customer</label>
                            {getFormErrorMessage('uniqueCustomerId')}
                        </span>
                    </div>

                    <div className="field col-12 md:col-4 pt-0">
                        <span className="pattern-view d-flex justify-content-end pb-1" role='button' onClick={() => setShowPatternModal(true)}>View Pattern</span>
                        <span className="p-float-label">
                            <Controller name="contractNumber" control={control} rules={{
                                required: 'Contract Number is required.',
                                pattern: { value: contractNumberPattern, message: "Enter a valid Contract Number" }
                            }} render={({ field, fieldState }) => (
                                <InputText id="inputtext" className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })} {...field} />
                            )} />
                            <label className='mandatory' htmlFor="inputtext">Contract Number</label>
                            {getFormErrorMessage('contractNumber')}
                        </span>
                    </div>

                    <div className="field col-12 md:col-4 mb-0">
                        <span className="p-float-label">
                            <Controller name="contractName" control={control} rules={{ required: 'Contract Name is required.' }} render={({ field, fieldState }) => (
                                <InputText id="inputtext" maxLength={100} className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })}  {...field} />
                            )} />
                            <label className='mandatory' htmlFor="inputtext">Contract Name</label>
                            {getFormErrorMessage('contractName')}
                        </span>
                    </div>

                    <div className="field col-12 md:col-4">
                        <span className="p-float-label">
                            <Controller name="startDate" control={control} render={({ field, fieldState }) => (
                                <Calendar id="calendar" className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })} dateFormat="yy-mm-dd"  {...field} value={field.value} onChange={(e) => field.onChange(e.value)} />
                            )} />
                            <label htmlFor="calendar">Start Date</label>
                            {getFormErrorMessage('startDate')}
                        </span>
                    </div>

                    <div className="field col-12 md:col-4">
                        <span className="p-float-label">
                            <Controller name="endDate" control={control} render={({ field, fieldState }) => (
                                <Calendar id="calendar" className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })} dateFormat="yy-mm-dd"  {...field} value={field.value} onChange={(e) => field.onChange(e.value)} />
                            )} />
                            <label htmlFor="calendar">End Date</label>
                            {getFormErrorMessage('endDate')}
                        </span>
                    </div>

                    <div className="field col-12 md:col-4">
                        <span className="p-float-label">
                            <Controller name="notes" control={control} rules={{

                            }} render={({ field, fieldState }) => (
                                <InputTextarea id="Textarea" className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })} maxLength={4000} {...field} />
                            )} />
                            <label htmlFor="inputtext">Notes</label>
                            {getFormErrorMessage('notes')}
                        </span>
                        <span className='textarea-txt-count'>Max 4000 Chars</span>
                    </div>
                    <div className="field col-12 md:col-4">
                        <span className="p-float-label">
                            <Controller name="salesOffice" control={control} rules={{ required: 'Sales Office is required.' }} render={({ field, fieldState }) => (
                                <Dropdown inputId="dropdown" appendTo="self" className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })}  {...field} value={field.value} onChange={(e) => field.onChange(e.value)} options={salesOfficeLookup} optionLabel="name" optionValue="name" />
                            )} />
                            <label className='mandatory' htmlFor="dropdown">Sales Office</label>
                            {getFormErrorMessage('salesOffice')}
                        </span>
                    </div>


                </form>
            </AddModal>
            <InformationBox
                showInfoModal={displayInfoModal}
                setShowInfoModal={setDisplayInfoModal}
                message={message}
            />
        </div>
    );
}

export default EditContract;