import React, { useEffect, useState} from "react";

import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "constants/AppRoutes";

import PopOver from 'components/common/PopOver/PopOver';
import InformationBox from "components/common/Message/InformationBox/InformationBox";
import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';
import { MdPersonOutline } from 'react-icons/md';
import usLogo from "assets/svgs/logo.svg";
import Yammerlogo from "assets/svgs/logo-yammer.svg";
import { Tooltip } from 'primereact/tooltip';


import SendMailUserinfo from "./SendMail"
import SelectUserSite from "./SelectUserSite";
import FeedbackUserinfo from "./Feedback";

//redux
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../redux/hooks";
import {fetchLoggedInUserDetails} from "../../redux/pages/Administration/administrationSlice";
import { PersonalSettings } from "./PersonalSettings";
import { showUI } from 'service/GeneralUtility';
import { sendFeedback } from "../../redux/pages/Dashboard/UserMenu/feedbackSlice";
import { sendMail, sendAttachment } from "../../redux/pages/Dashboard/UserMenu/sendMailSlice";
import { ProgressBar } from "primereact/progressbar";
import { Help } from "./Help/Help";
import { getBuildVersion } from "redux/common/commonSlice";
import AddNotificationMessage from "./AddNotificationMessage";
import { sendNotifcationMessage } from "redux/pages/Dashboard/UserMenu/NotificationMessageSlice";

interface Props { }

const Header: React.FC<Props> = (props: any) => {

	const appBaseURL = (window as any).envSettings.appBaseUrl;
	const userinforef = React.createRef<OverlayPanel>();
	const navigate = useNavigate();
	const dispatch = useDispatch<any>();

	const [showHelp, setShowHelp] = useState<boolean>(false);
	const [showFeedback, setShowFeedback] = useState<boolean>(false);
	const [showSendMail, setShowSendMail] = useState<boolean>(false);
	const [showSelectUserSite, setShowSelectUserSite] = useState<boolean>(false);
	const [showPersonalSettings, setShowPersonalSettings] = useState<boolean>(false);
	const [userName, setUserName] = useState('');
	const [userRole, setUserRole] = useState('');
	const [siteName, setSiteName] = useState('');
	const [yammerLink, setYammerLink] = useState('');
	const [hasDefaultSite, setHasDefaultSite] = useState(false);
	const [message, setMessage] = useState("");
	const [displayInfoModal, setDisplayInfoModal] = useState(false);
	const [showAddNotificationMessage, setShowAddNotificationMessage] = useState<boolean>(false);

	const loggedInUserData = useAppSelector((state) => state.administration.loggedInUserDetails);
	const loggedInUserDetailsStatus = useAppSelector((state) => state.administration.loggedInUserDetailsStatus);
	const activeSite = useAppSelector((state)=>state.site.activeSite)
	const userPrivileges = useAppSelector((state) => state.user.userPrivileges);
	const progress = useAppSelector((state) => state.event.isEmptyServiceCallQueue);
	const inspectionTaskProgressBar = useAppSelector((state) => state.inspectionTask.inspectionTaskProgressBar);
	const [logo, setLogo] = useState<any>();

	useEffect(() => {
		dispatch(fetchLoggedInUserDetails())
		dispatch(getBuildVersion())
    }, [dispatch, activeSite]);

	useEffect(() => {
		if (loggedInUserData) {
			let yammerLinkUrl = loggedInUserData?.yammerLink?loggedInUserData?.yammerLink:'';
			setYammerLink(yammerLinkUrl);
			setUserName(loggedInUserData.userName);
			setUserRole(loggedInUserData.userRole);
			if (loggedInUserData.userDefaults && loggedInUserData.userDefaults.siteName) {
				setHasDefaultSite(true);
				setSiteName(loggedInUserData.userDefaults.siteName);
				setShowSelectUserSite(false);
			} else {
				setHasDefaultSite(false);
				setSiteName("Select Site");
				setShowSelectUserSite(true);
			}
		}
	}, [loggedInUserData]);
	useEffect(() => {
			setLogo(usLogo);   
    },[]);

	useEffect(() => {
		if (loggedInUserDetailsStatus && (loggedInUserDetailsStatus === 'error')) {
			setHasDefaultSite(false);
			setSiteName("Select Site");
			setShowSelectUserSite(true);
		}
	}, [loggedInUserDetailsStatus]);


	const SignOutUser = (()=>{
		document.location.href=`${appBaseURL}/api/v1/auth/signout`
	});

	const onSendFeedback = (feedbackInfo: any, setLoader: any) => {
		if (feedbackInfo && feedbackInfo.uniqueUserId) {
			dispatch(sendFeedback(feedbackInfo, setLoader, setShowFeedback, setMessage, setDisplayInfoModal));
		}
	}

	const onSendMail = (attachmentData: any, sendMailInfo: any, setLoader: any) => {
		if (attachmentData && attachmentData.TempStorageDirectory) {
			dispatch(sendAttachment(attachmentData, sendMailInfo, setLoader, setShowSendMail, setMessage, setDisplayInfoModal));
		} else {
			dispatch(sendMail({...sendMailInfo, hasAttachment: false}, setLoader, setShowSendMail, setMessage, setDisplayInfoModal));
		}
	}

	const onSendNotificationMessage = (info: any, setShowModal: any) => {
		dispatch(sendNotifcationMessage(info,setDisplayInfoModal,setMessage,setShowModal))
	}

	return (
		<div className="header-container">
			<div className="logo" role='button' onClick={() => navigate(APP_ROUTES.DASHBOARD)}>
				<img src={logo} alt='logo' />
			</div>
			<div className="info-section">				
				<div className="user-info">
					<div className="name">	
						{userName} 
						<span className="role"> {userRole ? `(${userRole})` : ''}</span>
					</div>
					<Tooltip target=".location" content={"Change Site"}></Tooltip>
					<div className="location" onClick={() => setShowSelectUserSite(true)}>
						{siteName ? siteName : 'Select Site'}
					</div>
				</div>
				<div className="avatar" onClick={(e) => userinforef.current?.toggle(e)}>
					<MdPersonOutline />
				</div>
				<div className="info-icon" onClick={() => setShowHelp(true)}>
					?
				</div>
				<div className="yammer-link">
					<a href={yammerLink} target="_blank"><img src={Yammerlogo} alt="Yammer-logo" /></a>
					
				</div>
			</div>
			<PopOver ref={userinforef} >
				<ul>
					<li onClick={() => setShowPersonalSettings(true)}>Personal Settings</li>
					{showUI(userPrivileges,"SIETAP_MANAGE_NOTIFICATIONS") && <li onClick={() => setShowAddNotificationMessage(true)}>Notification Message</li> }
					<li onClick={() => setShowFeedback(true)}>Feedback</li>
					<li onClick={() => setShowSendMail(true)}>Send Mail</li>
					{showUI(userPrivileges,"SIETAP_LOGBOOK") && <li onClick={() => navigate(APP_ROUTES.LOG_BOOK)}>Logbook</li>}
				</ul>
				<div className="pt-2 pb-1">
					<Button label="Sign Out" onClick={SignOutUser} className="sign-out" />
				</div>
      		</PopOver>
			{(progress || inspectionTaskProgressBar) &&
					<div className="header-progressbar">
						<ProgressBar mode="indeterminate" />
					</div>
				}
			{showSelectUserSite? (  
				<SelectUserSite
					showModal={showSelectUserSite}
					setShowModal={setShowSelectUserSite}
					hasDefaultSite={hasDefaultSite}
				/>
			) : null}

			{showPersonalSettings? (  <PersonalSettings
				showModal={showPersonalSettings}
				setShowModal={setShowPersonalSettings}
			/>) : null}

			{showSendMail? (  <SendMailUserinfo
				showModal={showSendMail}
				setShowModal={setShowSendMail}
				onSendMail={onSendMail}
			/>) : null}

			{showFeedback? (  <FeedbackUserinfo
				showModal={showFeedback}
				setShowModal={setShowFeedback}
				onSendFeedback={onSendFeedback}
			/>) : null}
			{showHelp? (  <Help
				isGlobal = {false}
				showModal={showHelp}
				setShowModal={setShowHelp}
			/>) : null}
			{showAddNotificationMessage? (  <AddNotificationMessage
				showModal={showAddNotificationMessage}  
				setShowModal={setShowAddNotificationMessage}
				onSendNotificationMessage={onSendNotificationMessage}
			/>) : null}

			<InformationBox
				showInfoModal={displayInfoModal}
				setShowInfoModal={setDisplayInfoModal}
				message={message}
			/>
		</div>
	);
};

export default Header;
