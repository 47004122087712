export const addNewInspectionFieldFromTree = (selectedParent, selectedNode, inspectionFormData, newInspectionFormData) => {
    for (let i = 0; i < inspectionFormData?.length; i++) {
        if (inspectionFormData[i].key === selectedNode?.key) {
            if (selectedParent) {
                addNewInspectionField(selectedParent, inspectionFormData[i]?.inspectionGroupFields, newInspectionFormData);
                break;
            }
            else {
                if (newInspectionFormData?.isValidated) {
                    if (inspectionFormData[i]?.inspectionGroupFields?.length >= 0)
                        inspectionFormData[i]?.inspectionGroupFields?.push(newInspectionFormData);
                    else
                        inspectionFormData[i].inspectionGroupFields = [newInspectionFormData]
                    break;
                } else {
                    if (inspectionFormData[i]?.inspectionGroupFields?.length >= 0)
                        inspectionFormData[i]?.inspectionGroupFields?.push(newInspectionFormData);
                    else
                        inspectionFormData[i].inspectionGroupFields = [newInspectionFormData];
                    break;
                }
            }
        } else if (inspectionFormData[i]?.children?.length) {
            addNewInspectionFieldFromTree(selectedParent, selectedNode, inspectionFormData[i]?.children, newInspectionFormData);
        }
    }
    return [inspectionFormData, selectedNode];
}


export const addNewInspectionField = (selectedParent, inspectionFormData, newInspectionFormData) => {
    if (selectedParent === undefined) {
        if (newInspectionFormData?.isValidated) {
            inspectionFormData?.push(newInspectionFormData);
        } else {
            if (inspectionFormData?.length)
                inspectionFormData?.push(newInspectionFormData);
            else
                inspectionFormData = [newInspectionFormData];
        }
    } else {
        for (let i = 0; i < inspectionFormData?.length; i++) {
            if (inspectionFormData[i].uniqueDataFieldId === selectedParent?.uniqueDataFieldId) {
                if (newInspectionFormData?.isValidated) {
                    if (inspectionFormData[i]?.inspectionGroupFields?.length)
                        inspectionFormData[i]?.inspectionGroupFields?.push(newInspectionFormData);
                    else
                        inspectionFormData[i].inspectionGroupFields = [newInspectionFormData];
                    break;
                } else {
                    if (inspectionFormData[i]?.inspectionGroupFields?.length)
                        inspectionFormData[i]?.inspectionGroupFields?.push(newInspectionFormData);
                    else
                        inspectionFormData[i].inspectionGroupFields = [newInspectionFormData]
                    break;
                }
            } else {
                addNewInspectionField(selectedParent, inspectionFormData[i].inspectionGroupFields, newInspectionFormData);
            }
        }
    }
    return inspectionFormData;
}

export const addFieldValuesWithTree = (selectParent, selectedNode, inspectionFormData, newFieldValue, fieldValue) => {
    for (let i = 0; i < inspectionFormData?.length; i++) {
        if (inspectionFormData[i].key === selectedNode?.key) {
            if (selectParent) {
                addFieldValues(selectParent, inspectionFormData[i]?.inspectionGroupFields, newFieldValue, fieldValue);
            }
        } else if (inspectionFormData[i]?.children?.length) {
            addFieldValuesWithTree(selectParent, selectedNode, inspectionFormData[i]?.children, newFieldValue, fieldValue)
        }
    }

    return [inspectionFormData, selectedNode];
}

export const addFieldValues = (selectParent, inspectionFormData, newFieldValue, fieldValues) => {
    for (let i = 0; i < inspectionFormData?.length; i++) {
        if (inspectionFormData[i].uniqueDataFieldId === selectParent?.uniqueDataFieldId) {
            fieldValues.push(newFieldValue);
            inspectionFormData[i].validatedValues = fieldValues;
        } else {
            addFieldValues(selectParent, inspectionFormData[i].inspectionGroupFields, newFieldValue, fieldValues)
        }
    }

    return inspectionFormData
}

export const updateFieldValueWithTree = (selectParent, selectedNode, inspectionFormData, updatedValue, fieldValue) => {
    for (let i = 0; i < inspectionFormData?.length; i++) {
        if (inspectionFormData[i].key === selectedNode?.key) {
            if (selectParent) {
                updateFieldValue(selectParent, inspectionFormData[i]?.inspectionGroupFields, updatedValue, fieldValue);
            }
        } else if (inspectionFormData[i]?.children?.length) {
            updateFieldValueWithTree(selectParent, selectedNode, inspectionFormData[i]?.children, updatedValue, fieldValue)
        }
    }
    return [inspectionFormData, selectedNode];
}

export const updateFieldValue = (selectParent, inspectionFormData, updatedValue, fieldValue) => {
    for (let i = 0; i < inspectionFormData?.length; i++) {
        if (inspectionFormData[i].uniqueDataFieldId === selectParent?.uniqueDataFieldId) {
            const findIndex = fieldValue?.findIndex((item) => item.dataFieldLookupId == updatedValue?.dataFieldLookupId);
            if (findIndex >= 0) {
                fieldValue[findIndex] = updatedValue;
                inspectionFormData[i].validatedValues = fieldValue;
            }
        } else {
            updateFieldValue(selectParent, inspectionFormData[i].inspectionGroupFields, updatedValue, fieldValue)
        }
    }
    return inspectionFormData;
}

export const removeFieldValueWithTree = (selectParent, selectedNode, inspectionFormData, deletedValue, fieldValue) => {
    for (let i = 0; i < inspectionFormData?.length; i++) {
        if (inspectionFormData[i].key === selectedNode?.key) {
            if (selectParent) {
                removeFieldValue(selectParent, inspectionFormData[i]?.inspectionGroupFields, deletedValue, fieldValue);
            }
        } else if (inspectionFormData[i]?.children?.length) {
            removeFieldValueWithTree(selectParent, selectedNode, inspectionFormData[i]?.children, deletedValue, fieldValue)
        }
    }
    return [inspectionFormData, selectedNode];
}

export const removeFieldValue = (selectParent, inspectionFormData, deletedValue, fieldValue) => {
    for (let i = 0; i < inspectionFormData?.length; i++) {
        if (inspectionFormData[i].uniqueDataFieldId === selectParent?.uniqueDataFieldId) {
            const index = fieldValue?.findIndex((item) => item.dataFieldLookupId === deletedValue?.dataFieldLookupId);
            if (index >= 0) {
                fieldValue.splice(index, 1);
                inspectionFormData[i].validatedValues = fieldValue;
            }
        } else {
            removeFieldValue(selectParent, inspectionFormData[i].inspectionGroupFields, deletedValue, fieldValue)
        }
    }
    return inspectionFormData;
}

export const updateInspectionQuestionWithTree = (selectParent, selectedNode, inspectionFormData, updatedValue) => {
    for (let i = 0; i < inspectionFormData?.length; i++) {
        if (inspectionFormData[i].key === selectedNode?.key) {
            if (selectParent) {
                updateInspectionQuestion(selectParent, inspectionFormData[i]?.inspectionGroupFields, updatedValue);
            }
        } else if (inspectionFormData[i]?.children?.length) {
            updateInspectionQuestionWithTree(selectParent, selectedNode, inspectionFormData[i]?.children, updatedValue)
        }
    }
    return [inspectionFormData, selectedNode];
}

export const updateInspectionQuestion = (selectParent, inspectionFormData, updatedValue) => {
    for (let i = 0; i < inspectionFormData?.length; i++) {
        if (inspectionFormData[i].uniqueDataFieldId === selectParent?.uniqueDataFieldId) {
            updatedValue.inspectionGroupFields = inspectionFormData[i].inspectionGroupFields;
            inspectionFormData[i] = updatedValue;
        } else {
            updateInspectionQuestion(selectParent, inspectionFormData[i].inspectionGroupFields, updatedValue)
        }
    }
    return inspectionFormData;
}

export const deleteInspectionQuestionWithTree = (selectParent, selectedNode, inspectionFormData, deletedValue) => {
    for (let i = 0; i < inspectionFormData?.length; i++) {
        if (inspectionFormData[i].key === selectedNode?.key) {
            if (selectParent) {
                deleteInspectionQuestion(selectParent, inspectionFormData[i]?.inspectionGroupFields, deletedValue);
            }
        } else if (inspectionFormData[i]?.children?.length) {
            deleteInspectionQuestionWithTree(selectParent, selectedNode, inspectionFormData[i]?.children, deletedValue)
        }
    }
    return [inspectionFormData, selectedNode];
}

export const deleteInspectionQuestion = (selectParent, inspectionFormData, deletedValue) => {
    for (let i = 0; i < inspectionFormData?.length; i++) {
        if (inspectionFormData[i].uniqueDataFieldId === selectParent?.uniqueDataFieldId) {
            inspectionFormData.splice(i, 1);
        } else {
            deleteInspectionQuestion(selectParent, inspectionFormData[i].inspectionGroupFields, deletedValue)
        }
    }
    return inspectionFormData;
}

export const addGroupSectionWithTree = (selectedNode, inspectionFormData, newGroupSection) => {
    for (let i = 0; i < inspectionFormData?.length; i++) {
        if (inspectionFormData[i].key === selectedNode?.key) {
            const data = {
                key: newGroupSection?.inspectionGroupSectionId,
                label: newGroupSection?.inspectionGroupSectionName,
                children: newGroupSection?.inspectionGroupSections,
                inspectionGroupFields: newGroupSection?.inspectionGroupFields,
                level:newGroupSection?.level,
                position:newGroupSection?.position,
                parentGroupId:newGroupSection?.parentGroupId,
                isTreeNode:newGroupSection?.isTreeNode,
                hasDataFields:newGroupSection?.hasDataFields
            }
            if (inspectionFormData[i].children?.length)
                inspectionFormData[i].children.push(data);
            else
                inspectionFormData[i].children = [data];
        } else if (inspectionFormData[i]?.children?.length) {
            addGroupSectionWithTree(selectedNode, inspectionFormData[i]?.children, newGroupSection)
        }
    }
    return [inspectionFormData, selectedNode];
}

export const updateGroupSectionWithTree = (selectedNode, inspectionFormData, updatedGroupSection) => {
    for (let i = 0; i < inspectionFormData?.length; i++) {
        if (inspectionFormData[i].key === selectedNode?.key) {
            inspectionFormData[i].label = updatedGroupSection?.inspectionGroupSectionName;
        } else if (inspectionFormData[i]?.children?.length) {
            updateGroupSectionWithTree(selectedNode, inspectionFormData[i]?.children, updatedGroupSection)
        }
    }
    return [inspectionFormData, selectedNode];
}

export const deleteGroupSectionFromTree = (selectedNode, inspectionFormData, deletedGroupSection) => {
    for (let i = 0; i < inspectionFormData?.length; i++) {
        if (inspectionFormData[i].key === selectedNode?.key) {
            inspectionFormData.splice(i, 1);
        } else if (inspectionFormData[i]?.children?.length) {
            deleteGroupSectionFromTree(selectedNode, inspectionFormData[i]?.children, deletedGroupSection)
        }
    }
    return [inspectionFormData,inspectionFormData[0]];
}

export const addDefaultDataValues = (inspectionFieldValues, deafaultValues) => {

    if (deafaultValues?.length) {
        for (let i = 0; i < deafaultValues.length; i++) {
            const index = inspectionFieldValues?.findIndex((item) => item.uniqueDataFieldId === deafaultValues[i]?.dataFieldId);
            if (index >= 0) {
                inspectionFieldValues[index].multipleFieldValue = deafaultValues[i]?.multipleValue;
                inspectionFieldValues[index].fieldValue = deafaultValues[i]?.value;
            } else {
                const item = {
                    uniqueDataFieldId: deafaultValues[i]?.dataFieldId,
                    fieldValue: deafaultValues[i]?.value,
                    multipleFieldValue: deafaultValues[i]?.multipleValue,
                }
                inspectionFieldValues.push(item)
            }
        }
    }
    return inspectionFieldValues;
}



export const convertInspectionFields = (data) => {
    if (data?.inspectionGroupFields?.length) {
        data?.inspectionGroupFields.forEach(element => {
            if (element?.validatedValues?.length) {
                element.validatedValues.forEach(item => {
                    item.dataFieldLookupId = item?.dataFieldLookupId?.toString();
                });
            }
            if (element?.inspectionGroupFields?.length) {
                convertInspectionFields(element)
            }
        });
    }
    return data;
}