import React, { memo, useState } from "react";
import { RadioButton } from "primereact/radiobutton";
import { useNavigate } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
//components
import Breadcrumb from "components/common/Breadcrumb/Breadcrumb";
import CopySystems from "./Manage/CopySystems";
import CopyAllSystems from "./Manage/CopyAllSystems";
import { APP_ROUTES } from "constants/AppRoutes";

interface CopyPropTypes { }

const Copy: React.FC<CopyPropTypes> = (props) => {
  const navigate = useNavigate();
  const [key, setKey] = useState("system");

  return (
    <div className="copy-container">
      <section className="d-flex align-items-center">
        <Breadcrumb title="Tools" classNames="report-header" handleGoBackClick={() => navigate(`/${APP_ROUTES.MANAGE_VIEW}`)} />
        <div className="breadcrumb-right-arrow ml-3 mr-3">
            <BsArrowRight className="icon" />
        </div>
        <div className="breadcrumb-sub-page-title">
            <span>Copy</span>
        </div>
      </section>
      <div className="copy-main">
        <div className="system-select d-flex pt-3">
          <div className="field-radiobutton d-flex pr-4 align-items-center  mb-0">
            <RadioButton
              inputId="system"
              name="system"
              value="system"
              onChange={(e) => setKey(e.value)}
              checked={key === "system"}
            />
            <label htmlFor="city1">System</label>
          </div>
          <div className="field-radiobutton d-flex align-items-center mb-0">
            <RadioButton
              inputId="all-system"
              name="all-system"
              value="all-system"
              onChange={(e) => setKey(e.value)}
              checked={key === "all-system"}
            />
            <label className="ml-2" htmlFor="city2">
              All Systems in a Contract
            </label>
          </div>
        </div>
        {key === "system" ?
          <CopySystems systemType={key} /> :
          <CopyAllSystems systemType={key} />
        }
      </div>
    </div>
  );
};

export default memo(Copy);
