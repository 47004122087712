import { InputText } from 'primereact/inputtext';

const textEditor = (options) => {
    return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
};

export const INSPECTION_REPORTS_COLS = {
    REPORTS_COLS: [
        {
            field: 'reportName',
            header: 'Report Name',
            editor: (options) => textEditor(options),
            style: { flex: '1 0 200px' },
            sortable: true,
        },
        {
            field: 'reportTitle',
            header: 'Report Title',
            editor: (options) => textEditor(options),
            style: { flex: '1 0 200px' },
            sortable: true,
        },
        {
            field: 'reportGroup',
            header: 'Report Group',
            editor: (options) => textEditor(options),
            style: { flex: '1 0 200px' },
            sortable: true,
        },        
        {
            field: 'reportStatus',
            header: 'Report Status',
            editor: (options) => textEditor(options),
            style: { flex: '1 0 200px' },
            sortable: true,
        }
    ]
    
};

export const INSPECTION_REPORTS_ROWS = {
    REPORTS_ROWS: [
        {
            id: 1,
            reportName: 'NFPA10 Annual Portable Extingushers',
            reportTitle: 'NFPA10 Annual Portable Extingushers',
            reportGroup: 'NFPA Reports'
        },
        {
            id: 2,
            reportName: 'NFPA10 Annual Portable Extingushers',
            reportTitle: 'NFPA10 Annual Portable Extingushers',
            reportGroup: 'NFPA Reports'
        },
        {
            id: 3,
            reportName: 'NFPA10 Annual Portable Extingushers',
            reportTitle: 'NFPA10 Annual Portable Extingushers',
            reportGroup: 'NFPA Reports'
        },
        {
            id: 4,
            reportName: 'NFPA10 Annual Portable Extingushers',
            reportTitle: 'NFPA10 Annual Portable Extingushers',
            reportGroup: 'NFPA Reports'
        },
        {
            id: 5,
            reportName: 'NFPA10 Annual Portable Extingushers',
            reportTitle: 'NFPA10 Annual Portable Extingushers',
            reportGroup: 'NFPA Reports'
        },
        {
            id: 6,
            reportName: 'NFPA10 Annual Portable Extingushers',
            reportTitle: 'NFPA10 Annual Portable Extingushers',
            reportGroup: 'NFPA Reports'
        },
        {
            id: 7,
            reportName: 'NFPA10 Annual Portable Extingushers',
            reportTitle: 'NFPA10 Annual Portable Extingushers',
            reportGroup: 'NFPA Reports'
        },
        {
            id: 8,
            reportName: 'NFPA10 Annual Portable Extingushers',
            reportTitle: 'NFPA10 Annual Portable Extingushers',
            reportGroup: 'NFPA Reports'
        },
        {
            id: 9,
            reportName: 'NFPA10 Annual Portable Extingushers',
            reportTitle: 'NFPA10 Annual Portable Extingushers',
            reportGroup: 'NFPA Reports'
        },
        {
            id: 10,
            reportName: 'NFPA10 Annual Portable Extingushers',
            reportTitle: 'NFPA10 Annual Portable Extingushers',
            reportGroup: 'NFPA Reports'
        },
        {
            id: 11,
            reportName: 'NFPA10 Annual Portable Extingushers',
            reportTitle: 'NFPA10 Annual Portable Extingushers',
            reportGroup: 'NFPA Reports'
        },
        {
            id: 12,
            reportName: 'NFPA10 Annual Portable Extingushers',
            reportTitle: 'NFPA10 Annual Portable Extingushers',
            reportGroup: 'NFPA Reports'
        },
        {
            id: 13,
            reportName: 'NFPA10 Annual Portable Extingushers',
            reportTitle: 'NFPA10 Annual Portable Extingushers',
            reportGroup: 'NFPA Reports'
        },
        {
            id: 14,
            reportName: 'NFPA10 Annual Portable Extingushers',
            reportTitle: 'NFPA10 Annual Portable Extingushers',
            reportGroup: 'NFPA Reports'
        },
        {
            id: 15,
            reportName: 'NFPA10 Annual Portable Extingushers',
            reportTitle: 'NFPA10 Annual Portable Extingushers',
            reportGroup: 'NFPA Reports'
        },
        {
            id: 16,
            reportName: 'NFPA10 Annual Portable Extingushers',
            reportTitle: 'NFPA10 Annual Portable Extingushers',
            reportGroup: 'NFPA Reports'
        },
        {
            id: 17,
            reportName: 'NFPA10 Annual Portable Extingushers',
            reportTitle: 'NFPA10 Annual Portable Extingushers',
            reportGroup: 'NFPA Reports'
        },
        {
            id: 18,
            reportName: 'NFPA10 Annual Portable Extingushers',
            reportTitle: 'NFPA10 Annual Portable Extingushers',
            reportGroup: 'NFPA Reports'
        },
        {
            id: 19,
            reportName: 'NFPA10 Annual Portable Extingushers',
            reportTitle: 'NFPA10 Annual Portable Extingushers',
            reportGroup: 'NFPA Reports'
        },
        {
            id: 20,
            reportName: 'NFPA10 Annual Portable Extingushers',
            reportTitle: 'NFPA10 Annual Portable Extingushers',
            reportGroup: 'NFPA Reports'
        }
    ]
}