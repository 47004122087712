import React, { useState, useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";

import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";

import { classNames } from "primereact/utils";

import { BsExclamationCircleFill } from "react-icons/bs";

import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../redux/hooks";

import AddModal from "components/Table/AddModal/AddModal";
import { getCountryList } from "redux/pages/Administration/administrationSlice";

interface IZone {
  showModal: boolean;
  setShowModal: (state: boolean) => void;
  onAddZone: (submitData: any) => void;
}

export const AddZone: React.FC<IZone> = ({
  showModal = false,
  setShowModal = () => {},
  onAddZone = () => {},
}) => {
  const countryList = useAppSelector(
    (state) => state.administration.countryList
  );

  const [countryLookup, setCountryLookup] = useState([{ name: "", code: "" }]);
  const dispatch = useDispatch<any>();

  const addForm: any = useRef();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    dispatch(getCountryList());
  }, [dispatch]);

  useEffect(() => {
    if (countryList?.countries && countryList?.countries.length) {
      let country: { name: string; code: string }[] = [];
      countryList?.countries.map((item: any) => {
        return country.push({ name: item.countryName, code: item.countryCode });
      });
      setCountryLookup(country);
      setValue("countryName", countryList?.countries[0].countryName);
    }
  }, [countryList, setValue]);

  const formSubmitHandler = (data: any) => {
    addForm?.current?.requestSubmit();
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && (
        <span className="tooltip-text" style={{position:"sticky",width:"50%"}}>
          <BsExclamationCircleFill />
          {errors[name].message}
        </span>
      )
    );
  };

  const onAddFormSubmit = (submitData: any) => {
    let countryId = "";
    countryList.countries.map((countryItem: any, key: any) => {
      if (submitData.countryName === countryItem.countryName) {
        countryId = countryItem.countryId;
      }
      return null;
    });
    let ReviseData = 
      {
        countryId: countryId,
        zoneName: submitData.zoneName.trim(),
      }
    onAddZone && onAddZone(ReviseData);
  };

  const onError = (errors: any, e: any) => {};

  return (
    <>
      <AddModal
        header="New Zone"
        showModal={showModal}
        isAdd={true}
        setShowModal={setShowModal}
        style={{ width: "35vw", height: "35vh" }}
        OnSaveClick={formSubmitHandler}
      >
        <form onSubmit={handleSubmit(onAddFormSubmit, onError)} ref={addForm}>
          <div className="row px-2 pt-2 mb-0">
            <div className="field col-12 md:col-2">
              <span className="p-float-label">
                <Controller
                  name="countryName"
                  control={control}
                  rules={{
                    required: "Country Name is required.",
                  }}
                  render={({ field, fieldState }) => (
                    <Dropdown
                      inputId="inputtext"
                      className={classNames("w-100 error-tooltip", {
                        error: fieldState.invalid,
                      })}
                      {...field}
                      value={field.value}
                      onChange={(e) => field.onChange(e.value)}
                      options={countryLookup}
                      optionLabel="name"
                      optionValue="name"
                    />
                  )}
                />
                <label className="mandatory" htmlFor="Country">
                  Country
                </label>
                {getFormErrorMessage("countryName")}
              </span>
            </div>
          </div>

          <div className="row px-2 pt-2">
            <div className="field col-12 md:col-2 mb-0">
              <span className="p-float-label">
                <Controller
                  name="zoneName"
                  control={control}
                  rules={{
                    required: "Zone Name is required.",
                    validate: (value) => {
                      return value.trim().length<=0
                        ?"Zone Name is required.":true
                    }
                  }}
                  
                  render={({ field, fieldState }) => (
                    <InputText
                      id="inputtext"
                      maxLength={100}
                      className={classNames("w-100 error-tooltip", {
                        error: fieldState.invalid,
                      })}
                      {...field}
                    />
                  )}
                />
                <label className="mandatory" htmlFor="inputtext">
                  Name
                </label>
                {getFormErrorMessage("zoneName")}
              </span>
            </div>
          </div>
        </form>
      </AddModal>
    </>
  );
};

export default AddZone;
