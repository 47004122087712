import { Button } from "primereact/button";
import React from "react";
import { FaDownload } from "react-icons/fa";
import { MdOutlineListAlt } from "react-icons/md";

const ReportSAPImportStatusDocument = () => {
    return (
        <>
          <h4 className="doc-title mb-2">SAP Import Status</h4>
          <p>
             This option allows the Super Administrator to view the list of files imported from SAP. 
             This page will be displayed with File Name, File Type, Date of Integration and Status. 
             User can select Uploaded from and Uploaded to dates for displaying the list within the 
             specified time period. There will be two icons, one for showing Status Details and 
             another for downloading the Status Details.
          </p>
          <h4 className="doc-subtitle">Status Details</h4>
          <p></p>
          <p>
             Select a record from the list and click on <span className="doc-subtitle">
                Status Details </span>
                <Button className="doc-button-icon"><MdOutlineListAlt className="icon" title="Status Details"/></Button> icon, 
             a new page will load with details of the selected file.
          </p>
          <h4 className="doc-subtitle">Download</h4> 
          <p></p>
          <p>
             Download option is provided to export the Status Details to an Excel file. 
             Click the <span className="doc-subtitle">Download </span>
             <Button className="doc-button-icon"><FaDownload className="icon" title="Download"/></Button> icon,
             the details displayed in the grid will be saved to the user's computer.
         </p>

        </>
    );
}

export default ReportSAPImportStatusDocument;