import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { zlink, baseURL } from "../../../../../service/HttpService";
import { AppThunk } from "../../../../store";

export interface EquipmentTypeAttributeState {
  errors: any;
  equipmentTypeList: any;
  equipmentTypeAttributeList: any;
  equipmentTypeAttributeDetails: any;
  equipmentTypeAttributeListPageDetails: any;
  isEquipmentTypeAttributeInUse: any;
}

let initialState: EquipmentTypeAttributeState = {
  errors: "",
  equipmentTypeList: [],
  equipmentTypeAttributeList: [],
  equipmentTypeAttributeDetails: {},
  equipmentTypeAttributeListPageDetails: [],
  isEquipmentTypeAttributeInUse: "",
};

const EquipmentTypeAttributeSlice = createSlice({
  name: "equipmentTypeAttribute",
  initialState,
  reducers: {
    setErrors: (state, { payload }: PayloadAction<any>) => {
      state.errors = payload;
    },
    setEquipmentTypeList: (state, { payload }: PayloadAction<any>) => {
      state.equipmentTypeList = payload;
    },
    setEquipmentTypeAttributeList: (state, { payload }: PayloadAction<any>) => {
      let ReviseData: any = [];
      payload.map((element: any, key: any) => {
        ReviseData = [
          ...ReviseData,
          {
            uniqueAttributeId: element.uniqueAttributeId,
            attributeName: element.attributeName,
            attributeType: element.attributeType,
            isMandatory: element.isMandatory ? "True" : "False",
            isValidated: element.isValidated ? "True" : "False",
            maxlength: element.maxlength,
          },
        ];
        return null;
      });
      state.equipmentTypeAttributeList = ReviseData;
    },
    setNewEquipmentTypeAttributeData: (
      state,
      { payload }: PayloadAction<any>
    ) => {
      let newData: any = [];
      newData = [
        {
          uniqueAttributeId: payload.uniqueAttributeId,
          attributeName: payload.attributeName,
          attributeType: payload.attributeType,
          isMandatory: payload.isMandatory ? "True" : "False",
          isValidated: payload.isValidated ? "True" : "False",
          maxlength: payload.maxlength,
        },
      ];
      state.equipmentTypeAttributeList.unshift(newData[0]);
    },
    setEquipmentTypeAttributeDetails: (
      state,
      { payload }: PayloadAction<any>
    ) => {
      state.equipmentTypeAttributeDetails = payload;
    },
    setIsEquipmentTypeAttributeInUse: (
      state,
      { payload }: PayloadAction<any>
    ) => {
      state.isEquipmentTypeAttributeInUse = payload;
    },
    setDeletedEquipmentTypeAttribute(state, { payload }: PayloadAction<any>) {
      state.equipmentTypeAttributeList = state.equipmentTypeAttributeList.filter(
        (item: any) => item.uniqueAttributeId !== payload
      );
    },
    setUpdatedEquipmentTypeAttributeData: (
      state,
      { payload }: PayloadAction<any>
    ) => {
      let newData: any = [];
      newData = [
        {
          uniqueAttributeId: payload.uniqueAttributeId,
          attributeName: payload.attributeName,
          attributeType: payload.attributeType,
          isMandatory: payload.isMandatory ? "True" : "False",
          isValidated: payload.isValidated ? "True" : "False",
          maxlength: payload.maxlength,
        },
      ];
      let currentIndex = state.equipmentTypeAttributeList.findIndex(
        (item: any) => item.uniqueAttributeId === payload?.uniqueAttributeId
      );
      state.equipmentTypeAttributeList =
        state.equipmentTypeAttributeList.filter(
          (item: any) => item.uniqueAttributeId !== payload?.uniqueAttributeId
        );
      state.equipmentTypeAttributeList.splice(currentIndex, 0, newData[0]);
    },
    setEquipmentTypeAttributeListPageDetails: (
      state,
      { payload }: PayloadAction<any>
    ) => {
      state.equipmentTypeAttributeListPageDetails = payload;
    },
  },
});

export const getEquipmentTypeList = (): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.get(
      `${baseURL}api/v1/manage/customization/attributes/typeattribute/equipmenttypelist`
    );
    if (res.status === "done") {
      dispatch(setEquipmentTypeList(res?.data?.equipmentClassDetails));
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const getEquipmentTypeAttributeList = (uniqueEquipmentClassId: any, currentStart: any,keyword: any = "",sortField: any = "", sortOrder: any = ""): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.get(`${baseURL}api/v1/manage/customization/attributes/typeattribute/${uniqueEquipmentClassId}/equipmenttypeattributelist?currentStart=${currentStart}&keyWord=${keyword}&sortColumn=${sortField}&sortOrder=${sortOrder}`);
    if (res.status === "done") {
      if (res.data) {
        dispatch(setEquipmentTypeAttributeList(res?.data?.attributeDetails));
        dispatch(setEquipmentTypeAttributeListPageDetails(res?.data?.pageDetails));
      }
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const getEquipmentTypeAttributeDetails = (attributeId: any): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.get(
      `${baseURL}api/v1/equipmenttypes/equipmenttypeid/attributes/${attributeId}`
    );
    if (res.status === "done") {
      dispatch(setEquipmentTypeAttributeDetails(res?.data));
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const checkIsEquipmentTypeAttributeInUse =
  (attributeId): AppThunk =>
  async (dispatch) => {
    try {
      const res = await zlink.get(
        `${baseURL}api/v1/equipmenttypes/equipmenttypeid/attributes/${attributeId}/checkequipmenttypeattributeinuse`
      );
      if (res.status === "done") {
        dispatch(setIsEquipmentTypeAttributeInUse(res?.data));
      } else {
        dispatch(setErrors(res.error));
      }
    } catch (error) {
      dispatch(setErrors(error));
    }
  };
export const onAddEquipmentTypeAttribute =
  (
    body: any,
    uniqueEquipmentTypeId,
    setMessage,
    setDisplayInfoModal,
    setShowAddModal
  ): AppThunk =>
  async (dispatch) => {
    try {
      const res = await zlink.post(
        `${baseURL}api/v1/equipmenttypes/${uniqueEquipmentTypeId}/attributes/add`,
        body
      );
      if (res.status === "done") {
        dispatch(setNewEquipmentTypeAttributeData(res.data));
        setShowAddModal(false);
        setMessage("Equipment Type Attribute added");
        setDisplayInfoModal(true);
      } else {
        dispatch(setErrors(res.error));
        setMessage(res.message);
        setDisplayInfoModal(true);
      }
    } catch (error) {
      dispatch(setErrors(error));
    }
  };

  export const onEditEquipmentTypeAttribute =
  (
    selectedEquipmentTypeAttribute,
    equipmentTypeId,
    body: any,
    setMessage,
    setDisplayInfoModal,
    setShowAddModal,
    setselectedRows
  ): AppThunk =>
  async (dispatch) => {
    try {
      const res = await zlink.post(
        `${baseURL}api/v1/equipmenttypes/${equipmentTypeId}/attributes/${selectedEquipmentTypeAttribute?.uniqueAttributeId} `,
        body
      );
      if (res.status === "done") {
        dispatch(setUpdatedEquipmentTypeAttributeData(res.data));
        setselectedRows(prev => {
          let equipmentTypeAttributes = prev;
          equipmentTypeAttributes[0]={...selectedEquipmentTypeAttribute, isValidated:res.data?.isValidated?"True":"False"};
          return equipmentTypeAttributes;
        });
        setShowAddModal(false);
        setMessage("Equipment Type Attribute updated");
        setDisplayInfoModal(true);
      } else {
        dispatch(setErrors(res.error));
        setMessage(res.message);
        setDisplayInfoModal(true);
      }
    } catch (error) {
      dispatch(setErrors(error));
    }
  };
  export const deleteEquipmentTypeAttribute =
  (attributeId, setSelectedRows, setMessage, setDisplayInfoModal): AppThunk =>
  async (dispatch) => {
    try {
      const res = await zlink.post(
        `${baseURL}api/v1/equipmenttypes/equipmenttypeid/attributes/${attributeId}/remove`,
        null
      );
      if (res.status === "done") {
        dispatch(setDeletedEquipmentTypeAttribute(attributeId));
        setSelectedRows([]);
        setMessage("Equipment Type Attribute deleted");
        setDisplayInfoModal(true);
      } else {
        setSelectedRows([]);
        setMessage(res.message);
        setDisplayInfoModal(true);
      }
    } catch (error) {
      dispatch(setErrors(error));
    }
  };
export const {
  setErrors,
  setEquipmentTypeList,
  setEquipmentTypeAttributeList,
  setEquipmentTypeAttributeDetails,
  setDeletedEquipmentTypeAttribute,
  setUpdatedEquipmentTypeAttributeData,
  setNewEquipmentTypeAttributeData,
  setEquipmentTypeAttributeListPageDetails,
  setIsEquipmentTypeAttributeInUse,
} = EquipmentTypeAttributeSlice.actions;

export default EquipmentTypeAttributeSlice.reducer;
