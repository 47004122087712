import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { zlink, baseURL } from "../../../../../service/HttpService";
import { AppThunk } from "../../../../store";

export interface ReportsState {
  errors: any;
  reportList: any;
  reportPageDetails: any;
  newReportData: any;
  reportGroupsData: any;
  deletedReportData: any;
  reportDetails: any;
  updatedReportData: any;
  isReportInUse: any;
  reportFieldOrder: any;
  updatedFieldOrder: any;
}

let initialState: ReportsState = {
  errors: "",
  reportList: [],
  reportPageDetails: [],
  newReportData: "",
  reportGroupsData: "",
  deletedReportData: "",
  reportDetails: "",
  updatedReportData: "",
  isReportInUse: "",
  reportFieldOrder: [],
  updatedFieldOrder: "",
};

const ReportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    setErrors: (state, { payload }: PayloadAction<any>) => {
      state.errors = payload;
    },
    setReportList: (state, { payload }: PayloadAction<any>) => {
      state.reportList = payload;


    },
    setReportPageDetails: (state, { payload }: PayloadAction<any>) => {
      state.reportPageDetails = payload;
    },
    setNewReportData: (state, { payload }: PayloadAction<any>) => {
      state.newReportData = payload
    },
    setReportGroupsData: (state, { payload }: PayloadAction<any>) => {
      state.reportGroupsData = payload;
    },
    setDeletedReportData: (state, { payload }: PayloadAction<any>) => {
      state.deletedReportData = payload
    },
    setReportDetails: (state, { payload }: PayloadAction<any>) => {
      state.reportDetails = payload;
    },
    setUpdatedReportData: (state, { payload }: PayloadAction<any>) => {
      state.updatedReportData = payload
    },
    setIsReportInUse: (state, { payload }: PayloadAction<any>) => {
      state.isReportInUse = payload
    },
    setReportFieldOrder: (state, { payload }: PayloadAction<any>) => {
      state.reportFieldOrder = payload
    },
    setUpdatedFieldOrder: (state, { payload }: PayloadAction<any>) => {
      state.updatedFieldOrder = payload
    },
  },
});

export const getReportList =
  (body, currentStart, keyword: any = "", sortField: any = "", sortOrder: any = ""): AppThunk =>
    async (dispatch) => {
      try {
        const res = await zlink.post(`${baseURL}api/v1/manage/inspsettings/reports/reportlist?keyword=${keyword}&currentstart=${currentStart}&sortcolumn=${sortField}&sortorder=${sortOrder}`, body);
        if (res.status === "done") {
          dispatch(setReportList(res?.data?.reportDetails));
          dispatch(setReportPageDetails(res?.data?.pageDetails));
        } else {
          dispatch(setErrors(res.error));
        }
      } catch (error) {
        dispatch(setErrors(error));
      }
    };

export const getAllReportGroups =
  (): AppThunk =>
    async (dispatch) => {
      try {
        const res = await zlink.get(`${baseURL}api/v1/reportgroups`);
        if (res.status === "done") {
          dispatch(setReportGroupsData(res?.data?.reportGroupDetails));
        } else {
          dispatch(setErrors(res.error));
        }
      } catch (error) {
        dispatch(setErrors(error));
      }
    };

export const onAddReport =
  (body: any, countryId: any, setMessage, setDisplayInfoModal): AppThunk =>
    async (dispatch) => {
      try {
        const res = await zlink.post(`${baseURL}api/v1/country/${countryId}/reports/add`, body);
        if (res.status === "done") {
          dispatch(setNewReportData(res.data));
          setDisplayInfoModal(true);
          setMessage("Report added");
        } else {
          dispatch(setErrors(res.error));
          setDisplayInfoModal(true);
          setMessage(res.message);
        }
      } catch (error) {
        dispatch(setErrors(error));
      }
    };

export const deleteReport = (reportId: any, countryId: any, setMessage, setDisplayInfoModal): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.post(`${baseURL}api/v1/country/${countryId}/reports/${reportId}/remove`, null);
    if (res.status === "done") {
      dispatch(setDeletedReportData(res))
      setMessage("Report deleted");
      setDisplayInfoModal(true);
    } else {
      setMessage(res.message);
      setDisplayInfoModal(true);
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const getReportDetails =
  (reportId: any, countryId: any): AppThunk =>
    async (dispatch) => {
      try {
        const res = await zlink.get(`${baseURL}api/v1/country/${countryId}/reports/${reportId}`);
        if (res.status === "done") {
          dispatch(setReportDetails(res?.data));
        } else {
          dispatch(setErrors(res.error));
        }
      } catch (error) {
        dispatch(setErrors(error));
      }
    };

export const onUpdateReport =
  (body: any, reportId: any, countryId: any, setMessage, setDisplayInfoModal): AppThunk =>
    async (dispatch) => {
      try {
        const res = await zlink.post(`${baseURL}api/v1/country/${countryId}/reports/${reportId}`, body);
        if (res.status === "done") {
          dispatch(setUpdatedReportData(res.data));
          setDisplayInfoModal(true);
          setMessage("Report updated");
        } else {
          dispatch(setErrors(res.error));
          setDisplayInfoModal(true);
          setMessage(res.message);
        }
      } catch (error) {
        dispatch(setErrors(error));
      }
    };

export const checkIsReportInUse =
  (reportId: any, countryId: any): AppThunk =>
    async (dispatch) => {
      try {
        const res = await zlink.get(`${baseURL}api/v1/country/${countryId}/reports/${reportId}/checkreportinuse`);
        if (res.status === "done") {
          dispatch(setIsReportInUse(res?.data));
        } else {
          dispatch(setErrors(res.error));
        }
      } catch (error) {
        dispatch(setErrors(error));
      }
    };

export const onGetFieldOrder = (countryId): AppThunk =>
  async (dispatch) => {
    try {
      const res = await zlink.get(`${baseURL}api/v1/country/${countryId}/reports/order`);
      if (res.status === "done") {
        dispatch(setReportFieldOrder(res?.data?.reportDetails));
      } else {
        dispatch(setErrors(res.error));
      }
    } catch (error) {
      dispatch(setErrors(error));
    }
  };

export const onUpdateFieldOrder = (countryId, body): AppThunk =>
  async (dispatch) => {
    try {
      const res = await zlink.post(`${baseURL}api/v1/country/${countryId}/reports/order`, body);
      if (res.status === "done") {
        dispatch(setUpdatedFieldOrder(body?.dataFields));
      } else {
        dispatch(setErrors(res.error));
      }
    } catch (error) {
      dispatch(setErrors(error));
    }
  };

export const { setErrors,
  setReportList,
  setReportPageDetails,
  setNewReportData,
  setReportGroupsData,
  setDeletedReportData,
  setReportDetails,
  setUpdatedReportData,
  setIsReportInUse,
  setReportFieldOrder,
  setUpdatedFieldOrder } = ReportSlice.actions;
export default ReportSlice.reducer;
