import React, { useRef } from "react";
import { useForm, Controller } from "react-hook-form";

import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { classNames } from "primereact/utils";

import { BsExclamationCircleFill } from "react-icons/bs";

import AddModal from "components/Table/AddModal/AddModal";
import { Button } from "primereact/button";
import { blockedNonKeyboardCharacters } from "service/GeneralUtility";

interface IEquipmentAttributeValue {
  showModal: boolean;
  equipmentAttributeType: any;
  equipmentAttributeName: any;
  maxLength: any;
  setShowModal: (state: boolean) => void;
  onAddEquipmentTypeAttributeValueClick: (data: any) => void;
}

type FormData = {
  Value: any;
  AttributeName: string;
};

export const AddEquipmentTypeAttributeValues: React.FC<IEquipmentAttributeValue> = ({
  showModal = false,
  setShowModal = () => {},
  onAddEquipmentTypeAttributeValueClick = () => {},
  equipmentAttributeType = "",
  equipmentAttributeName = "",
  maxLength = "",
}) => {
  const addUserForm: any = useRef();
  const cal = React.createRef<Calendar>();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      Value: "",
      AttributeName: "",
    },
  });

  const formSubmitHandler = (data: any) => {
    addUserForm.current.requestSubmit();
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && (
        <span className="tooltip-text">
          <BsExclamationCircleFill />
          {errors[name].message}
        </span>
      )
    );
  };

  const onAddFormSubmit = (submitData: any) => {
    onAddEquipmentTypeAttributeValueClick &&
      onAddEquipmentTypeAttributeValueClick(submitData);
  };

  const textValidate = (value) => {
    if (value) {
      let invalidCharacters: any = blockedNonKeyboardCharacters(value);
      if (invalidCharacters != null && invalidCharacters.length > 0) {
        return "Non-Keyboard character(s) " + invalidCharacters.join() + " is not allowed";
      }
      else {
        return true;
      }
    }
  }

  const onError = (errors: any, e: any) => {};

  return (
    <>
      <AddModal
        header="New Equipment Type Attribute Value"
        showModal={showModal}
        isAdd={true}
        setShowModal={setShowModal}
        style={{ width: "35vw", height: '30vh' }}
        OnSaveClick={formSubmitHandler}
      >
        <form
          onSubmit={handleSubmit(onAddFormSubmit, onError)}
          ref={addUserForm}
        >
          <div className="row px-2 pt-2">
            <div className="field col-12 md:col-2 mb-0">
              <span className="p-float-label text-container">
                <label htmlFor="inputtext">Attribute Name</label>
                <Controller
                  name="AttributeName"
                  control={control}
                  render={() => (
                    <span className="text-value">{equipmentAttributeName}</span>
                  )}
                />
              </span>
            </div>
          </div>

          {equipmentAttributeType === "Single Line Text" ? (
            <div className="row px-2 pb-2">
              <div className="field col-12 md:col-2 mb-0">
                <span className="p-float-label">
                  <Controller
                    name="Value"
                    control={control}
                    rules={{
                      required: "Enter Value",
                      validate: (value) => {
                        return (value.trim().length <= 0 ? "This field is required." : textValidate(value))
                      }
                    }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id="name"
                        maxLength={maxLength}
                        className={classNames("w-100 error-tooltip", {
                          error: fieldState.invalid,
                        })}
                        {...field}
                        aria-autocomplete='none'
                      />
                    )}
                  />
                  <label className="mandatory" htmlFor="inputtext">
                    Value
                  </label>

                  {getFormErrorMessage("Value")}
                </span>
              </div>
            </div>
          ) : null}
          {equipmentAttributeType === "Date" ? (
            <div className="row px-2 pb-2">
              <div className="field col-12 md:col-2 mb-0">
                <span className="p-float-label">
                  <Controller
                    name="Value"
                    control={control}
                    rules={{
                      required: "Enter Value",
                    }}
                    render={({ field, fieldState }) => (
                      <Calendar
                        id="Date"
                        className={classNames("w-100 error-tooltip", {
                          error: fieldState.invalid,
                        })}
                        dateFormat="yy-mm-dd"
                        {...field}
                        value={field.value}
                        onChange={(e) => field.onChange(e.value)}
                      />
                    )}
                  />
                  <label className="mandatory" htmlFor="calendar">
                    Value
                  </label>

                  {getFormErrorMessage("Value")}
                </span>
              </div>
            </div>
          ) : null}
          {equipmentAttributeType === "Date Time" ? (
            <div className="row px-2 pb-2">
              <div className="field col-12 md:col-2 mb-0">
                <span className="p-float-label">
                  <Controller
                    name="Value"
                    control={control}
                    rules={{
                      required: "Enter Value",
                    }}
                    render={({ field, fieldState }) => (
                      <Calendar
                        id="Date Time"
                        {...field}
                        ref={cal}
                        className={classNames("w-100 error-tooltip", {
                          error: fieldState.invalid,
                        })}
                        dateFormat="yy-mm-dd"
                        showTime
                        showSeconds
                        value={field.value}
                        onChange={(e) => field.onChange(e.value)}
                        footerTemplate={() => <div>
                          <Button className="confirm-btn" onClick={() => cal.current?.hide()}>Save</Button>
                      </div>}
                      />
                    )}
                  />
                  <label className="mandatory" htmlFor="calendar">
                    Value
                  </label>

                  {getFormErrorMessage("Value")}
                </span>
              </div>
            </div>
          ) : null}
          {equipmentAttributeType === "Integer" ? (
            <div className="row px-2 pb-2">
              <div className="field col-12 md:col-2 mb-0">
                <span className="p-float-label">
                  <Controller
                    name="Value"
                    control={control}
                    rules={{
                      required: "Enter Value",
                      pattern: {
                        value: /^[0-9]+$/,
                        message: "Enter an Integer Value",
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id="name"
                        maxLength={maxLength}
                        className={classNames("w-100 error-tooltip", {
                          error: fieldState.invalid,
                        })}
                        {...field}
                        aria-autocomplete='none'
                      />
                    )}
                  />
                  <label className="mandatory" htmlFor="inputtext">
                    Value
                  </label>

                  {getFormErrorMessage("Value")}
                </span>
              </div>
            </div>
          ) : null}
          {equipmentAttributeType === "Numeric" ? (
            <div className="row px-2 pb-2">
              <div className="field col-12 md:col-2 mb-0">
                <span className="p-float-label">
                  <Controller
                    name="Value"
                    control={control}
                    rules={{
                      required: "Enter Value",
                      pattern: {
                        value: /^\d*\.?\d*$/,
                        message: "Enter a Numeric Value",
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id="name"
                        maxLength={maxLength}
                        className={classNames("w-100 error-tooltip", {
                          error: fieldState.invalid,
                        })}
                        {...field}
                        aria-autocomplete='none'
                      />
                    )}
                  />
                  <label className="mandatory" htmlFor="inputtext">
                    Value
                  </label>

                  {getFormErrorMessage("Value")}
                </span>
              </div>
            </div>
          ) : null}
          {equipmentAttributeType === "Multi Line Text" ? (
            <div className="row px-2 pb-2">
              <div className="field col-12 md:col-2 mb-0">
                <span className="p-float-label">
                  <Controller
                    name="Value"
                    control={control}
                    rules={{
                      required: "Enter Value",
                      validate: (value) => {
                        return (value.trim().length <= 0 ? "This field is required." : textValidate(value))
                      }
                    }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id="name"
                        maxLength={maxLength}
                        className={classNames("w-100 error-tooltip", {
                          error: fieldState.invalid,
                        })}
                        {...field}
                        aria-autocomplete='none'
                      />
                    )}
                  />
                  <label className="mandatory" htmlFor="inputtext">
                    Value
                  </label>

                  {getFormErrorMessage("Value")}
                </span>
              </div>
            </div>
          ) : null}
        </form>
      </AddModal>
    </>
  );
};

export default AddEquipmentTypeAttributeValues;
