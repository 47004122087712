import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from "react-router-dom";

import { MdOutlineSort } from 'react-icons/md';
import { BsChevronDown, BsChevronUp, BsList } from 'react-icons/bs';

import { AutoComplete } from 'primereact/autocomplete';

import { APP_ROUTES } from 'constants/AppRoutes';
import { ORGANIZATION_TYPES, INSPECTION_REPORT_TYPES, CUSTOMIZATION_ATTRIBUTES_TYPE,SECURITY_SETTINGS_TYPE } from 'constants/common';

import InspectionTasksFilter from '../../pages/InspectionTasks/InspectionTasksFilter';

import { useAppSelector } from "../../redux/hooks";
import { useDispatch } from "react-redux";
import {setUserFilterRoles} from "redux/pages/Administration/User/userSlice";

interface Props {
    filterExpanded: boolean,
    setFilterExpanded?: (state) => void,
    userFilter?: boolean,
    organizationFilter?: boolean,
    onUserRoleChange?: (data: any) => void,
    from?: any,
    reportFilter?: boolean,
    attributesFilter?: boolean,
    securitysettingFilter?: boolean,
    handleTabChange?: (from: any) => void,
    getSelectedReportsTab?: (selectedItem: string) => void,
    inspectionTasksFilter?: boolean,
    setShowAddInspectionTaskModal?: (state) => void;
}

export const Filters: React.FC<Props> = ({
    filterExpanded = false,
    setFilterExpanded = () => { },
    userFilter = false,
    organizationFilter = false,
    onUserRoleChange = () => { },
    from = '',
    reportFilter = false,
    attributesFilter = false,
    securitysettingFilter = false,
    handleTabChange = () => { },
    getSelectedReportsTab = () => { },
    inspectionTasksFilter = false,
    setShowAddInspectionTaskModal = () => { },
}) => {
    const COUNTRY_LIST = [
        { name: 'USA', code: 'US' },
        { name: 'CANADA', code: 'CA' }
    ];

    const ZONE_LIST = [
        { name: 'ZONE1', code: 'Z1' },
        { name: 'ZONE2', code: 'Z2' }
    ];

    const SALESOFFICE_LIST = [
        { name: 'SALES1', code: 'S1' },
        { name: 'SALES2', code: 'S2' }
    ];

    const BRANCHOFFICE_LIST = [
        { name: 'BRANCH1', code: 'B1' },
        { name: 'BRANCH2', code: 'B2' }
    ];

    const USER_LIST = [
        { name: 'ranga(R, Ranga - Super Administrator)', code: 'RR', role: 'Super Administrator' },
        { name: 'britto(T, Britto - Admin)', code: 'BT', role: 'Admin' },
        { name: 'anjali(S, Anjali - Tech)', code: 'AS', role: 'Tech' },
    ];


    const ORGANIZATION_ZONE_LIST = [
        { name: 'Zone', code: 'ZN' },
        { name: 'Sales Office', code: 'SO' },
        { name: 'Branch Office', code: 'BO' },
    ];

    const INSPECTION_REPORTS_LIST = [
        { name: INSPECTION_REPORT_TYPES.REPORT_GROUPS, code: 'RG', active: true },
        { name: INSPECTION_REPORT_TYPES.REPORTS, code: 'RP', active: false },
        { name: INSPECTION_REPORT_TYPES.REPORT_TEMPLATES, code: 'RT', active: false },
        { name: INSPECTION_REPORT_TYPES.REPORT_CONFIGS, code: 'RC', active: false },
    ];

    const SECURITY_SETTINGS_TYPE_LIST = [
        { name: SECURITY_SETTINGS_TYPE.PASSWORD_POLICY, code: 'PP', active: true },
        { name: SECURITY_SETTINGS_TYPE.MAIL_DOMAINS, code: 'MD', active: false },
    ];
    const CUSTOMIZATION_ATTRIBUTES_LIST = [
        { name: CUSTOMIZATION_ATTRIBUTES_TYPE.COMMON_ATTRIBUTES, code: 'CM', active: true },
        { name: CUSTOMIZATION_ATTRIBUTES_TYPE.COMMON_ATTRIBUTES_VALUES, code: 'CMV', active: false },
        { name: CUSTOMIZATION_ATTRIBUTES_TYPE.EQUIPMENT_TYPE_ATTRIBUTES, code: 'ETA', active: false },
        { name: CUSTOMIZATION_ATTRIBUTES_TYPE.EQUIPMENT_TYPE_ATTRIBUTES_VALUES, code: 'ETAV', active: false },
    ];


    const navigate = useNavigate();
    const dispatch = useDispatch<any>();

    const userPrivileges = useAppSelector((state) => state.user.userPrivileges);
    const userRoleIdOnSearch = useAppSelector( (state) => state.user.userRoleIdOnSearch);

    const [countryExpanded, setCountryExpanded] = useState(false);
    const [zoneExpanded, setZoneExpanded] = useState(false);
    const [salesOfficeExpanded, setSalesOfficeExpanded] = useState(false);
    const [branchOfficeExpanded, setBranchOfficeExpanded] = useState(false);

    const [country, setCountry] = useState<any>(null);
    const [zone, setZone] = useState<any>(null);
    const [salesOffice, setSalesOffice] = useState<any>(null);
    const [branchOffice, setBranchOffice] = useState<any>(null);

    const [selectedCountry, setSelectedCountry] = useState<any>({});
    const [selectedZone, setSelectedZone] = useState<any>({});
    const [selectedSalesOffice, setSelectedSalesOffice] = useState<any>({});
    const [selectedBranchOffice, setSelectedBranchOffice] = useState<any>({});

    const [filteredCountries, setFilteredCountries] = useState<any>(null);
    const [filteredZones, setFilteredZones] = useState<any>(null);
    const [filteredSalesOffice, setFilteredSalesOffice] = useState<any>(null);
    const [filteredBranchOffice, setFilteredBranchOffice] = useState<any>(null);

    const [userRole, setUserRole] = useState<any>(null);
    const [userRolesList,setUserRolesList]=useState<any>();
    const [selectedUserRole, setSelectedUserRole] = useState<any>();
    const [filteredUserRoles, setFilteredUserRoles] = useState<any>(null);

    const [organizationZones, setOrganizationZones] = useState<Array<any>>([]);

    const [inspectionReports, setInspectionReports] = useState<Array<any>>(INSPECTION_REPORTS_LIST);

    const [CustomizationAttributes, setCustomizationAttributes] = useState<Array<any>>(CUSTOMIZATION_ATTRIBUTES_LIST);

    const [SecuritySetting, setSecuritySetting] = useState<Array<any>>(SECURITY_SETTINGS_TYPE_LIST);

    useEffect(()=>{
      if(userPrivileges && userPrivileges.length){
        filterDefaultRoleList();
      }
    },[userPrivileges])

    useEffect(() => {
        if (userRoleIdOnSearch) {
            setSelectedUserRole(userRoleIdOnSearch)
        }
    }, [userRoleIdOnSearch])

    const filterDefaultRoleList =()=>{
         let userRoles:any = [];
        userPrivileges.map(element => {
            if(element === 'SIETAP_SUPER_ADMIN_MANAGE'){               
               userRoles.push({name: 'Super Administrators', code: 'SA',id:1})
            } else if(element === 'SIETAP_SUPPORT_USER_MANAGE'){               
               userRoles.push({name: 'Support', code: 'SO', id:2})
            } else if(element === 'SIETAP_ADMIN_USER_MANAGE'){               
               userRoles.push({name: 'Admin', code: 'AM',id:3 })
            } else if(element === 'SIETAP_TECH_ADMIN_MANAGE'){               
               userRoles.push({name: 'Admin-Tech', code: 'AT',id:4 })
            } else if(element === 'SIETAP_TECH_MANAGE'){               
               userRoles.push({name: 'Tech', code: 'TE',id:5 })
            } else if(element === 'SIETAP_BRANCH_ADMIN_MANAGE'){               
               userRoles.push({name: 'Branch', code: 'BR',id:6 })
            }
        });
        userRoles=userRoles.sort((a,b) => (a.id - b.id))
        setUserRolesList(userRoles)
        dispatch(setUserFilterRoles(userRoles))
        setSelectedUserRole(userRoles[0]?.name);     
    }
    const searchCountry = (event: { query: string }) => {
        setTimeout(() => {
            let _filteredCountries;
            if (!event.query.trim().length) {
                _filteredCountries = [...COUNTRY_LIST];
            }
            else {
                _filteredCountries = COUNTRY_LIST.filter((country) => {
                    return country.name.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }

            setFilteredCountries(_filteredCountries);
        }, 250);
    };

    const searchZone = (event: { query: string }) => {
        setTimeout(() => {
            let _filteredZones;
            if (!event.query.trim().length) {
                _filteredZones = [...ZONE_LIST];
            }
            else {
                _filteredZones = ZONE_LIST.filter((zone) => {
                    return zone.name.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }

            setFilteredZones(_filteredZones);
        }, 250);
    };


    const searchSalesOffice = (event: { query: string }) => {
        setTimeout(() => {
            let _filteredSalesOffice;
            if (!event.query.trim().length) {
                _filteredSalesOffice = [...SALESOFFICE_LIST];
            }
            else {
                _filteredSalesOffice = SALESOFFICE_LIST.filter((office) => {
                    return office.name.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }

            setFilteredSalesOffice(_filteredSalesOffice);
        }, 250);
    };


    const searchBranchOffice = (event: { query: string }) => {
        setTimeout(() => {
            let _filteredBranchOffice;
            if (!event.query.trim().length) {
                _filteredBranchOffice = [...BRANCHOFFICE_LIST];
            }
            else {
                _filteredBranchOffice = BRANCHOFFICE_LIST.filter((zone) => {
                    return zone.name.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }

            setFilteredBranchOffice(_filteredBranchOffice);
        }, 250);
    };

    const searchUserRoles = (event: { query: string }) => {
        setTimeout(() => {
            let _filteredUserRoles;
            if (!event.query.trim().length) {
                _filteredUserRoles = [...USER_LIST];
            }
            else {
                _filteredUserRoles = USER_LIST.filter((userRole) => {
                    return userRole.name.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }

            setFilteredUserRoles(_filteredUserRoles);
        }, 250);
    };

    const itemTemplate = (item: any) => {
        return (
            <div className="data-item">
                <div>{item.name}</div>
            </div>
        );
    };

    const userRoleClick = (event: any) => {
        setSelectedUserRole(event.name)
        onUserRoleChange && onUserRoleChange(event.name)
    }

    const getFilterTemplate = () => {
        if (userFilter) {
            return <>
                <ul className='list-items'>
                    {userRolesList?.map(item => (                 
                        <li
                            key={item.code}
                            className={selectedUserRole === item.name ? 'active' : ''}
                            onClick={() => userRoleClick(item)}
                        >{item.name}</li>
                    ))}
                </ul>
            </>
        }

        if (organizationFilter) {
            return <>
                <ul className='list-items'>
                    {organizationZones.map(item => (
                        <li
                            key={item.code}
                            className={item['active'] ? 'active' : ''}
                            onClick={organizationRoleClick}
                        >{item.name}</li>
                    ))}
                </ul>
            </>
        }

        if (reportFilter) {
            return <>
                <ul className='list-items'>
                    {inspectionReports.map(item => (
                        <li
                            key={item.code}
                            className={item['active'] ? 'active' : ''}
                            onClick={inspectionReportsClick}
                        >{item.name}</li>
                    ))}
                </ul>
            </>
        }

        if (inspectionTasksFilter) {
            return <InspectionTasksFilter setFilterExpanded={setFilterExpanded} setShowAddInspectionTaskModal={setShowAddInspectionTaskModal}/>;
        }

        if (attributesFilter) {
            return <>
                <ul className='list-items'>
                    {CustomizationAttributes.map(item => (
                        <li
                            key={item.code}
                            className={item['active'] ? 'active' : ''}
                            onClick={customizationattributesClick}
                        >{item.name}</li>
                    ))}
                </ul>
            </>
        }

        if (securitysettingFilter) {
            return <>
                <ul className='list-items'>
                    {SecuritySetting.map(item => (
                        <li
                            key={item.code}
                            className={item['active'] ? 'active' : ''}
                            onClick={securitysettingsClick}
                        >{item.name}</li>
                    ))}
                </ul>
            </>
        }
        return (
            <>
                <div className="heading">
                    <MdOutlineSort onClick={() => setFilterExpanded(false)} />
                    <span>Filters</span>
                </div>
                <div className="main">
                    <ul>
                        <li className="item">
                            <div className="d-flex align-items-center justify-content-between cursor-pointer" role='button' onClick={() => setCountryExpanded(!countryExpanded)}>
                                {(!countryExpanded && selectedCountry.name) ? <span className='selected-data'>{selectedCountry.name}</span> : <span>Select Country</span>}
                                {countryExpanded ? <BsChevronUp /> : <BsChevronDown />}
                            </div>
                            {countryExpanded &&
                                <AutoComplete value={country} suggestions={filteredCountries} completeMethod={searchCountry} field="name" dropdown dropdownIcon='pi pi-search' forceSelection itemTemplate={itemTemplate} onChange={(e) => setCountry(e.value)} onSelect={e => setSelectedCountry(e.value)} aria-label="Countries" />
                            }
                            {countryExpanded && selectedCountry.name && <div className='selected-item d-flex align-items-center justify-content-between'>
                                <div className="item-detail d-flex align-items-center">
                                    <span className="code">{selectedCountry.code}</span>
                                    <span className="name">{selectedCountry.name}</span>
                                </div>
                                <i className="pi pi-times" onClick={() => { setSelectedCountry({}); setCountry(null); }} />
                            </div>}
                        </li>

                        <li className="item">
                            <div className="d-flex align-items-center justify-content-between cursor-pointer" role='button' onClick={() => setZoneExpanded(!zoneExpanded)}>
                                {(!zoneExpanded && selectedZone.name) ? <span className='selected-data'>{selectedZone.name}</span> : <span>Select Zone</span>}
                                {zoneExpanded ? <BsChevronUp /> : <BsChevronDown />}
                            </div>
                            {zoneExpanded &&
                                <AutoComplete value={zone} suggestions={filteredZones} completeMethod={searchZone} field="name" dropdown dropdownIcon='pi pi-search' forceSelection itemTemplate={itemTemplate} onChange={(e) => setZone(e.value)} onSelect={e => setSelectedZone(e.value)} aria-label="Zones" />
                            }
                            {zoneExpanded && selectedZone.name && <div className='selected-item d-flex align-items-center justify-content-between'>
                                <div className="item-detail d-flex align-items-center">
                                    <span className="code">{selectedZone.code}</span>
                                    <span className="name">{selectedZone.name}</span>
                                </div>
                                <i className="pi pi-times" onClick={() => { setSelectedZone({}); setZone(null); }} />
                            </div>}
                        </li>

                        <li className="item">
                            <div className="d-flex align-items-center justify-content-between cursor-pointer" role='button' onClick={() => setSalesOfficeExpanded(!salesOfficeExpanded)}>
                                {(!salesOfficeExpanded && selectedSalesOffice.name) ? <span className='selected-data'>{selectedSalesOffice.name}</span> : <span>Select Sales Office</span>}
                                {salesOfficeExpanded ? <BsChevronUp /> : <BsChevronDown />}
                            </div>
                            {salesOfficeExpanded &&
                                <AutoComplete value={salesOffice} suggestions={filteredSalesOffice} completeMethod={searchSalesOffice} field="name" dropdown dropdownIcon='pi pi-search' forceSelection itemTemplate={itemTemplate} onChange={(e) => setSalesOffice(e.value)} onSelect={e => setSelectedSalesOffice(e.value)} aria-label="Sales Office" />
                            }
                            {salesOfficeExpanded && selectedSalesOffice.name && <div className='selected-item d-flex align-items-center justify-content-between'>
                                <div className="item-detail d-flex align-items-center">
                                    <span className="code">{selectedSalesOffice.code}</span>
                                    <span className="name">{selectedSalesOffice.name}</span>
                                </div>
                                <i className="pi pi-times" onClick={() => { setSelectedSalesOffice({}); setSalesOffice(null); }} />
                            </div>}
                        </li>

                        <li className="item">
                            <div className="d-flex align-items-center justify-content-between cursor-pointer" role='button' onClick={() => setBranchOfficeExpanded(!branchOfficeExpanded)}>
                                {(!branchOfficeExpanded && selectedBranchOffice.name) ? <span className='selected-data'>{selectedBranchOffice.name}</span> : <span>Select Branch Office</span>}
                                {branchOfficeExpanded ? <BsChevronUp /> : <BsChevronDown />}
                            </div>
                            {branchOfficeExpanded &&
                                <AutoComplete value={branchOffice} suggestions={filteredBranchOffice} completeMethod={searchBranchOffice} field="name" dropdown dropdownIcon='pi pi-search' forceSelection itemTemplate={itemTemplate} onChange={(e) => setBranchOffice(e.value)} onSelect={e => setSelectedBranchOffice(e.value)} aria-label="Branch Office" />
                            }
                            {branchOfficeExpanded && selectedBranchOffice.name && <div className='selected-item d-flex align-items-center justify-content-between'>
                                <div className="item-detail d-flex align-items-center">
                                    <span className="code">{selectedBranchOffice.code}</span>
                                    <span className="name">{selectedBranchOffice.name}</span>
                                </div>
                                <i className="pi pi-times" onClick={() => { setSelectedBranchOffice({}); setBranchOffice(null); }} />
                            </div>}
                        </li>
                    </ul>
                </div>
            </>
        )
    };

    const inspectionReportsClick = (event: any) => {
        const modifiedInspectionReports = inspectionReports.map(item => {
            if (item.name === event?.target?.innerText) {
                item['active'] = true;
            } else {
                item['active'] = false;
            }
            return item;
        });

        const selectedItem = modifiedInspectionReports.find(item => item.active);
        setInspectionReports(modifiedInspectionReports);
        getSelectedReportsTab(selectedItem?.name || '');
    };



    const customizationattributesClick = (event: any) => {
        const modifiedcustomizationattributes = CustomizationAttributes.map(item => {
            if (item.name === event?.target?.innerText) {
                item['active'] = true;
            } else {
                item['active'] = false;
            }
            return item;
        });

        const selectedItem = modifiedcustomizationattributes.find(item => item.active);
        setCustomizationAttributes(modifiedcustomizationattributes);
        getSelectedReportsTab(selectedItem?.name || '');
    };

    
    const securitysettingsClick = (event: any) => {
        const modifiedSecuritySetting = SecuritySetting.map(item => {
            if (item.name === event?.target?.innerText) {
                item['active'] = true;
            } else {
                item['active'] = false;
            }
            return item;
        });

        const selectedItem = modifiedSecuritySetting.find(item => item.active);
        setSecuritySetting(modifiedSecuritySetting);
        getSelectedReportsTab(selectedItem?.name || '');
    };


    const handleTabRedirect = useCallback((activeTabName) => {
        const getActiveTab = () => {
            switch (activeTabName) {
                case 'Zone': {
                    return ORGANIZATION_TYPES.ZONE;
                }
                case 'Sales Office': {
                    return ORGANIZATION_TYPES.SALES;
                }
                case 'Branch Office': {
                    return ORGANIZATION_TYPES.BRANCH;
                }
                default: {
                    return ORGANIZATION_TYPES.ZONE;
                }
            }
        };

        navigate(`/${APP_ROUTES.MANAGE_ORGANIZATION}`, {
            state: {
                from: getActiveTab()
            }
        });
    }, [navigate]);

    const organizationRoleClick = useCallback((event: any) => {
        handleTabRedirect(event?.target?.innerText);
    }, [handleTabRedirect]);

    const selectActiveTab = useCallback((event: any) => {
        const modifiedOrgZones = ORGANIZATION_ZONE_LIST.map(item => {
            if (item.name === event?.target?.innerText) {
                item['active'] = true;
            } else {
                item['active'] = false;
            }

            return item;
        });
        setOrganizationZones(modifiedOrgZones);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getActiveEvent = useCallback(() => {
        const event = { target: { innerText: '' } };

        switch (from) {
            case ORGANIZATION_TYPES.ZONE: {
                event.target.innerText = 'Zone';
                break;
            }
            case ORGANIZATION_TYPES.SALES: {
                event.target.innerText = 'Sales Office';
                break;
            }
            case ORGANIZATION_TYPES.BRANCH: {
                event.target.innerText = 'Branch Office';
                break;
            }
            default: {
                event.target.innerText = 'Zone';
            }
        }

        return event;
    }, [from]);

    useEffect(() => {
        selectActiveTab(getActiveEvent());
    }, [selectActiveTab, getActiveEvent]);

    return (
        <div className={`${filterExpanded ? 'expanded' : ''} filters`}>
            {
                (filterExpanded  )? getFilterTemplate() : <div className="ham-closed">
                    <BsList onClick={() => setFilterExpanded(true)} />
                </div>
            }
        </div>
    );
}

export default Filters;