import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { onDownloadFile } from "redux/common/commonSlice";
import { AppThunk } from "redux/store";
import { baseURL, zlink } from "service/HttpService";
import { setProgressFlag } from "redux/common/commonSlice";

export interface AsBuiltsState {
    errors: any;
    drawingList: any;
    drawingPageDetails: any;
    buildingList: any;
    floorList: any;
    drawingTypes: any;
    drawingCategory: any;
    newDrawing: any;
    addMarkup: any;
    drawingDetails: any;
    updatedDrawing: any;
    previousDrawing: any;
    revisedDrawing: any;
    replacedDrawing: any;
    revisionList: any;
    revisionPageDetails: any;
    markupList: any;
    markupPageDetails: any;
    markupDetails: any;
    xmlData:any;
    markupData: any;
    markupPageData:any;
    updatedMarkup: any;
    deletedMarkupData: any;
    updatedRevision: any;
}

const initialState: AsBuiltsState = {
    errors: "",
    drawingList: "",
    drawingPageDetails: "",
    buildingList: "",
    floorList: "",
    drawingTypes: "",
    drawingCategory: "",
    newDrawing: "",
    addMarkup:"",
    drawingDetails: "",
    updatedDrawing: "",
    previousDrawing:"",
    revisedDrawing: "",
    replacedDrawing: "",
    revisionList: "",
    revisionPageDetails: "",
    markupList: "",
    markupPageDetails: "",
    markupDetails: "",
    xmlData:"",
    markupPageData:"",
    markupData: "",
    updatedMarkup: "",
    deletedMarkupData: "",
    updatedRevision: "",
};

const AsBuiltsSlice = createSlice({
    name: "asbuilts",
    initialState,
    reducers: {
        setErrors: (state, { payload }: PayloadAction<any>) => {
            state.errors = payload;
        },
        setDrawingList: (state, { payload }: PayloadAction<any>) => {
            state.drawingList = payload;
        },
        setDrawingPageDetails: (state, { payload }: PayloadAction<any>) => {
            state.drawingPageDetails = payload;
        },
        setBuildingList: (state, { payload }: PayloadAction<any>) => {
            state.buildingList = payload;
        },
        setFloorList: (state, { payload }: PayloadAction<any>) => {
            state.floorList = payload;
        },
        setDrawingTypes: (state, { payload }: PayloadAction<any>) => {
            state.drawingTypes = payload;
        },
        setDrawingCategory: (state, { payload }: PayloadAction<any>) => {
            state.drawingCategory = payload;
        },
        setNewDrawing: (state, { payload }: PayloadAction<any>) => {
            state.newDrawing = payload;
        },
        setAddMarkup: (state, { payload }: PayloadAction<any>) => {
            state.addMarkup = payload;
        },
        setDrawingDetails: (state, { payload }: PayloadAction<any>) => {
            state.drawingDetails = payload;
        },
        setUpdatedDrawing: (state, { payload }: PayloadAction<any>) => {
            state.updatedDrawing = payload;
        },
        setPreviousDrawing: (state, { payload }: PayloadAction<any>) => {
			state.previousDrawing = payload;
		},
        setRevisedDrawing: (state, { payload }: PayloadAction<any>) => {
            state.revisedDrawing = payload;
        },
        setReplacedDrawing: (state, { payload }: PayloadAction<any>) => {
            state.replacedDrawing = payload;
        },
        setRevisionList: (state, { payload }: PayloadAction<any>) => {
            state.revisionList = payload;
        },
        setRevisionPageDetails: (state, { payload }: PayloadAction<any>) => {
            state.revisionPageDetails = payload;
        },
        setmarkupList: (state, { payload }: PayloadAction<any>) => {
            state.markupList = payload;
        },
        setMarkupPageDetails: (state, { payload }: PayloadAction<any>) => {
            state.markupPageDetails = payload;
        },
        setMarkupPageData: (state, { payload }: PayloadAction<any>) => {
            state.markupPageData = payload;
        },
        setMarkupDetails: (state, { payload }: PayloadAction<any>) => {
            state.markupDetails = payload;
        },
        setXmlData: (state, { payload }: PayloadAction<any>) => {
            state.xmlData = payload;
        },
        setMarkupData: (state, { payload }: PayloadAction<any>) => {
            state.markupData = payload;
        },
        setUpdatedMarkup: (state, { payload }: PayloadAction<any>) => {
            state.updatedMarkup = payload;
        },
        setDeletedMarkupData: (state, { payload }: PayloadAction<any>) => {
            state.deletedMarkupData = payload;
        },
        setUpdatedRevision: (state, { payload }: PayloadAction<any>) => {
            state.updatedRevision = payload;
        },
    },
});

export const getDrawingList = (uniqueSiteId,uniqueBuildingId: any="", currentStart, keyword: any = "", sortField: any = "", sortOrder: any = "", setMessage?: any, setDisplayInfoModal?: any): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.get(`${baseURL}api/v1/asbuilts/sites/${uniqueSiteId}/buildings/${uniqueBuildingId}/drawings?keyword=${keyword}&currentstart=${currentStart}&sortcolumn=${sortField}&sortorder=${sortOrder}`);
        if (res.status === "done") {
            dispatch(setDrawingList(res?.data?.drawingDetailsList));
            dispatch(setDrawingPageDetails(res?.data?.pageDetails));
            if (!res?.data?.drawingDetailsList?.length) {
                setMessage("No Drawings exist");
                setDisplayInfoModal(true);
            }
        } else {
            dispatch(setErrors(res.error));
        }
    } catch (error) {
        dispatch(setErrors(error));
    }
};

export const getBuildingList = (uniqueSiteId, body: any): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.post(`${baseURL}api/v1/floor/sites/${uniqueSiteId}/buildings`, body);
        if (res.status === "done") {
            dispatch(setBuildingList(res?.data?.buildingDetails));
        } else {
            dispatch(setErrors(res.error));
        }
    } catch (error) {
        dispatch(setErrors(error));
    }
};

export const getFloorList = (uniqueSiteId, uniqueBuildingId): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.get(`${baseURL}api/v1/asbuilts/sites/${uniqueSiteId}/buildings/${uniqueBuildingId}/floors `);
        if (res.status === "done") {
            dispatch(setFloorList(res?.data?.floorDetails));
        } else {
            dispatch(setErrors(res.error));
        }
    } catch (error) {
        dispatch(setErrors(error));
    }
};

export const getDrawingTypes = (): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.get(`${baseURL}api/v1/asbuilts/drawingtypes `);
        if (res.status === "done") {
            dispatch(setDrawingTypes(res?.data));
        } else {
            dispatch(setErrors(res.error));
        }
    } catch (error) {
        dispatch(setErrors(error));
    }
};
export const getDrawingCategory = (): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.get(`${baseURL}api/v1/asbuilts/drawingcategories`);
        if (res.status === "done") {
            dispatch(setDrawingCategory(res?.data));
        } else {
            dispatch(setErrors(res.error));
        }
    } catch (error) {
        dispatch(setErrors(error));
    }
};

export const insertDrawingmarkup =
	(body: any, uniqueSiteId, uniqueBuildingId,DrawingId, setMessage, setDisplayInfoModal): AppThunk =>
		async (dispatch) => {
			try {
               const res = await zlink.post(`${baseURL}api/v1/asbuilts/sites/${uniqueSiteId}/buildings/${uniqueBuildingId}/drawings/${DrawingId}/markup/insertdrawingmarkup`, body);
				if (res.status === "done") {
                    dispatch(setAddMarkup(res.data));
				} else {
					dispatch(setErrors(res.error));
					setMessage(res.message);
					setDisplayInfoModal(true);
				}
			} catch (error) {
				dispatch(setErrors(error));
			}
		};
        export const onAddDrawing =
        (body: any, uniqueSiteId, uniqueBuildingId, setMessage, setDisplayInfoModal): AppThunk =>
            async (dispatch) => {
                try {
                    const res = await zlink.post(`${baseURL}api/v1/asbuilts/sites/${uniqueSiteId}/buildings/${uniqueBuildingId}/drawings/add`, body);
                    if (res.status === "done") {
                        dispatch(setNewDrawing(res.data));
                        dispatch(setProgressFlag(false));
                    } else {
                        dispatch(setProgressFlag(false));
                        dispatch(setErrors(res.error));
                        setMessage(res.message);
                        setDisplayInfoModal(true);
                    }
                } catch (error) {
                    dispatch(setProgressFlag(false));
                    dispatch(setErrors(error));
                }
            };

export const getDrawingDetails = (uniqueSiteId, uniqueBuildingId, drawingId, body): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.post(`${baseURL}api/v1/asbuilts/sites/${uniqueSiteId}/buildings/${uniqueBuildingId}/drawings/${drawingId}`, body);
        if (res.status === "done") {
            dispatch(setDrawingDetails(res?.data));
        } else {
            dispatch(setErrors(res.error));
        }
    } catch (error) {
        dispatch(setErrors(error));
    }
};

export const onUpdateDrawing =
	(uniqueSiteId, uniqueBuildingId, drawingId, body: any, setMessage, setDisplayInfoModal, setSelectedRows, inlineEdit: boolean): AppThunk =>
		async (dispatch) => {
			try {
				const res = await zlink.post(`${baseURL}api/v1/asbuilts/sites/${uniqueSiteId}/buildings/${uniqueBuildingId}/drawings/${drawingId}/update`, body);
				if (res.status === "done") {
					dispatch(setUpdatedDrawing(res?.data));
					if (!inlineEdit) {
						setSelectedRows([res?.data])
					}
					setMessage("Drawing updated");
					setDisplayInfoModal(true);
				} else {
					dispatch(setErrors(res.error));
					setMessage(res.message);
					setDisplayInfoModal(true);
				}
			} catch (error) {
				dispatch(setErrors(error));
			}
		};

export const deleteDrawing = (uniqueSiteId, uniqueBuildingId, drawingId,revisionNo, setSelectedRows, setMessage, setDisplayInfoModal): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.post(`${baseURL}api/v1/asbuilts/sites/${uniqueSiteId}/buildings/${uniqueBuildingId}/drawings/${drawingId}/revision/${revisionNo}/remove`, null);
        if (res.status === "done") {
            dispatch(setPreviousDrawing(res?.data));
            setMessage("Drawing deleted");
            setDisplayInfoModal(true);
            setSelectedRows([]);
        } else {
            setMessage(res.message);
            setDisplayInfoModal(true);
        }
    } catch (error) {
        dispatch(setErrors(error));
    }
};

export const onDrawingRevise =
	(body: any, uniqueSiteId, uniqueBuildingId, drawingId, setMessage, setDisplayInfoModal): AppThunk =>
		async (dispatch) => {
			try {
				const res = await zlink.post(`${baseURL}api/v1/asbuilts/sites/${uniqueSiteId}/buildings/${uniqueBuildingId}/drawings/${drawingId}/revise`, body);
				if (res.status === "done") {
                    dispatch(setProgressFlag(false));
					dispatch(setRevisedDrawing(res.data));
				} else {
                    dispatch(setProgressFlag(false));
					dispatch(setErrors(res.error));
					setMessage(res.message);
					setDisplayInfoModal(true);
				}
			} catch (error) {
                dispatch(setProgressFlag(false));
				dispatch(setErrors(error));
			}
		};

export const onDrawingReplace =
    (body: any, uniqueSiteId, uniqueBuildingId, drawingId, setMessage, setDisplayInfoModal): AppThunk =>
        async (dispatch) => {
            try {
                const res = await zlink.post(`${baseURL}api/v1/asbuilts/sites/${uniqueSiteId}/buildings/${uniqueBuildingId}/drawings/${drawingId}/replace`, body);
                if (res.status === "done") {
                    dispatch(setProgressFlag(false));
                    dispatch(setReplacedDrawing(res.data));
                } else {
                    dispatch(setProgressFlag(false));
                    dispatch(setErrors(res.error));
                    setMessage(res.message);
                    setDisplayInfoModal(true);
                }
            } catch (error) {
                dispatch(setProgressFlag(false));
                dispatch(setErrors(error));
            }
        };

export const getRevisionList = (uniqueSiteId, uniqueBuildingId: any = "", drawingId,  currentStart,setDisplayInfoModal,setMessage, keyword: any = "", sortField: any = "", sortOrder: any = ""): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.get(`${baseURL}api/v1/asbuilts/sites/${uniqueSiteId}/buildings/${uniqueBuildingId}/drawings/${drawingId}/revisions?keyword=${keyword}&currentstart=${currentStart}&sortcolumn=${sortField}&sortorder=${sortOrder}`);
        if (res.status === "done") {
            if(res?.data?.drawingDetailsList.length>0){
            dispatch(setRevisionList(res?.data?.drawingDetailsList));
        }
        else{
            setMessage("No Revisions added")
            setDisplayInfoModal(true);
        }
        dispatch(setRevisionPageDetails(res?.data?.pageDetails));
        } else {
            dispatch(setErrors(res.error));
        }
    } catch (error) {
        dispatch(setErrors(error));
    }
};

export const getMarkupList = (uniqueSiteId, uniqueBuildingId: any = "", drawingId, body,  currentStart,setDisplayInfoModal,setMessage, keyword: any = "", sortField: any = "", sortOrder: any = ""): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.post(`${baseURL}api/v1/asbuilts/sites/${uniqueSiteId}/buildings/${uniqueBuildingId}/drawings/${drawingId}/markups?keyword=${keyword}&currentstart=${currentStart}&sortcolumn=${sortField}&sortorder=${sortOrder}`, body);
        if (res.status === "done") {
            if(res?.data?.markupDataList.length>0){
            dispatch(setmarkupList(res?.data?.markupDataList));
            }
            else{
                setMessage("No Markups added")
                setDisplayInfoModal(true);
            }
            dispatch(setMarkupPageDetails(res?.data?.pageDetails));
        } else {
            dispatch(setErrors(res.error));
        }
    } catch (error) {
        dispatch(setErrors(error));
    }
};
export const getSavedMarkups = (uniqueSiteId, uniqueBuildingId: any = "", drawingId, body,  currentStart,setMessage,setDisplayInfoModal, keyword: any = "", sortField: any = "", sortOrder: any = ""): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.post(`${baseURL}api/v1/asbuilts/sites/${uniqueSiteId}/buildings/${uniqueBuildingId}/drawings/${drawingId}/savedmarkups`, body);
         if (res.status === "done") {
            if(res?.data?.markupDataList.length>0){
            dispatch(setMarkupData(res?.data));
            dispatch(setMarkupPageData(res?.data?.pageDetails));
           }
            else{
                setMessage("No Markups added")
                setDisplayInfoModal(true);  
            }
        } else {
            dispatch(setErrors(res.error));
        }
    } catch (error) {
        dispatch(setErrors(error));
    }
};
export const getMarkup = (uniqueSiteId, uniqueBuildingId: any = "", drawingId, body,  currentStart, keyword: any = "", sortField: any = "", sortOrder: any = ""): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.post(`${baseURL}api/v1/asbuilts/sites/${uniqueSiteId}/buildings/${uniqueBuildingId}/drawings/${drawingId}/markups`, body);
         if (res.status === "done") {
            dispatch(setMarkupData(res?.data));
            dispatch(setMarkupPageData(res?.data?.pageDetails));
        } else {
            dispatch(setErrors(res.error));
        }
    } catch (error) {
        dispatch(setErrors(error));
    }
};
export const loadXmldata = (uniqueBuildingId, drawingId, markupId,body): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.post(`${baseURL}api/v1/asbuilts/buildings/${uniqueBuildingId}/drawings/${drawingId}/markup/${markupId}/loadxmldata`,body);
        if (res.status === "done") {
            let markupXmlData:any=[];
            markupXmlData.push({"xmlString":res?.data,"markupId":markupId}) 
            dispatch(setXmlData(markupXmlData));
        } else {
            dispatch(setErrors(res.error));
        }
    } catch (error) {
        dispatch(setErrors(error));
    }
};
        export const getMarkupDetails = (uniqueSiteId, uniqueBuildingId, drawingId, markupId): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.get(`${baseURL}api/v1/asbuilts/sites/${uniqueSiteId}/buildings/${uniqueBuildingId}/drawings/${drawingId}/markups/${markupId}`);
        if (res.status === "done") {
            dispatch(setMarkupDetails(res?.data));
        } else {
            dispatch(setErrors(res.error));
        }
    } catch (error) {
        dispatch(setErrors(error));
    }
};

export const onUpdateMarkup =
	(uniqueSiteId, uniqueBuildingId, drawingId, markupId, body: any, setMessage, setDisplayInfoModal, setSelectedRows,setShowEditModel): AppThunk =>
		async (dispatch) => {
			try {
				const res = await zlink.post(`${baseURL}api/v1/asbuilts/sites/${uniqueSiteId}/buildings/${uniqueBuildingId}/drawings/${drawingId}/markups/${markupId}/edit`, body);
				if (res.status === "done") {
					dispatch(setUpdatedMarkup(res?.data));
					setShowEditModel(false);
					setMessage("Drawing description updated");
					setDisplayInfoModal(true);
				} else {
					dispatch(setErrors(res.error));
					setMessage(res.message);
					setDisplayInfoModal(true);
				}
			} catch (error) {
				dispatch(setErrors(error));
			}
		};

export const deleteMarkup = (uniqueSiteId, uniqueBuildingId, drawingId, markupId, setSelectedRows, setMessage, setDisplayInfoModal): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.post(`${baseURL}api/v1/asbuilts/sites/${uniqueSiteId}/buildings/${uniqueBuildingId}/drawings/${drawingId}/markups/${markupId}/remove`, null);
        if (res.status === "done") {
            dispatch(setDeletedMarkupData(markupId));
            setMessage("Markup deleted");
            setDisplayInfoModal(true);
            setSelectedRows([]);
        } else {
            setMessage(res.message);
            setDisplayInfoModal(true);
        }
    } catch (error) {
        dispatch(setErrors(error));
    }
};

export const onUpdateRevision =
	(uniqueSiteId, uniqueBuildingId, drawingId, body: any, setMessage, setDisplayInfoModal, setSelectedRows,setShowEditModel): AppThunk =>
		async (dispatch) => {
			try {
				const res = await zlink.post(`${baseURL}api/v1/asbuilts/sites/${uniqueSiteId}/buildings/${uniqueBuildingId}/drawings/${drawingId}/updaterevision`, body);
				if (res.status === "done") {
					dispatch(setUpdatedRevision(res?.data));
					setShowEditModel(false);
					setMessage("Drawing description updated");
					setDisplayInfoModal(true);
				} else {
					dispatch(setErrors(res.error));
					setMessage(res.message);
					setDisplayInfoModal(true);
				}
			} catch (error) {
				dispatch(setErrors(error));
			}
		};

export const downloadDrawing = (uniqueSiteId, uniqueBuildingId,drawingId: any, fileName: any, setMessage: any, setDisplayInfoModal: any,): AppThunk =>
    async (dispatch) => {
        try {
            const res = await zlink.get(`${baseURL}api/v1/asbuilts/sites/${uniqueSiteId}/buildings/${uniqueBuildingId}/drawings/${drawingId}`);
            if (res.status === "done") {
                dispatch(onDownloadFile(drawingId, fileName, res?.data?.fullPath, setMessage, setDisplayInfoModal));
            } else {
                dispatch(setErrors(res.error));
            }
        } catch (error) {
            dispatch(setErrors(error));
        }
    };

export const { setErrors,
    setDrawingList,
    setDrawingPageDetails,
    setBuildingList,
    setFloorList,
    setDrawingTypes,
    setDrawingCategory,
    setNewDrawing,
    setAddMarkup,
    setDrawingDetails,
    setUpdatedDrawing,
    setPreviousDrawing,
    setRevisedDrawing,
    setReplacedDrawing,
    setRevisionList,
    setRevisionPageDetails,
    setmarkupList,
    setMarkupPageDetails,
    setMarkupDetails,
    setXmlData,
    setMarkupData,
    setMarkupPageData,
    setUpdatedMarkup,
    setDeletedMarkupData,
    setUpdatedRevision, } = AsBuiltsSlice.actions;
export default AsBuiltsSlice.reducer;
