import React, { useState, useRef, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';

import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { classNames } from 'primereact/utils';
import { InputNumber } from 'primereact/inputnumber';
import { BsExclamationCircleFill } from 'react-icons/bs';

import { useDispatch } from "react-redux";
import { useAppSelector } from "redux/hooks"
import { getFieldTypesData } from 'redux/pages/Manage/Customization/Attributes/CommonAttributeSlice';

import AddModal from 'components/Table/AddModal/AddModal';

interface ICommonAttributes {
    showModal: boolean,
    setShowModal: (state: boolean) => void,
    onAddCommonAttributeClick: (data: any) => void,
}

type FormData = {
    attributeName: string,
    attributeTypeId: any,
    maxLength: number | null,
    isMandatory: boolean,
    isValidated: boolean
};


export const AddCommonAttributes: React.FC<ICommonAttributes> = ({
    showModal = false,
    setShowModal = () => { },
    onAddCommonAttributeClick = () => { },
}) => {

    const {
        control,
        handleSubmit,
        watch,
        setValue,
        formState: { errors },
    } = useForm<FormData>({
        defaultValues: {
            attributeName: '',
            attributeTypeId: null,
            maxLength: undefined,
            isMandatory: false,
            isValidated: false
        }
    });

    const attributeType = watch("attributeTypeId");

    const fieldTypeData = useAppSelector((state) => state.commonAttribute.fieldTypesData);

    const [fieldTypeLookup, setFieldTypeLookup] = useState([{}]);
    const [maxLengthDisabled, setMaxLengthDisabled] = useState(false);
    const [maxLengthClass, setMaxLengthClass] = useState('mandatory');
    const [maxLength, setMaxLength] = useState<any>();
    const [disableValidatedField, setDisableValidatedField] = useState(false);

    const dispatch = useDispatch<any>();


    const addForm: any = useRef();

    useEffect(() => {
        dispatch(getFieldTypesData())
    }, [dispatch])

    useEffect(() => {
        if (fieldTypeData) {
            let fieldType: any = [];
            fieldTypeData.map((item: any) => {
                return fieldType.push({ name: item.dataTypeName, code: item.dataTypeId })
            })
            setFieldTypeLookup(fieldType)
        }
    }, [fieldTypeData])

    useEffect(() => {
        if (attributeType) {
            let attributeTypeId = attributeType?.code
            setDisableValidatedField(false);
            switch (attributeTypeId) {
                case 2:
                    setMaxLength(15);
                    setMaxLengthDisabled(true);
                    setMaxLengthClass('');
                    setValue("maxLength", 15);
                    break;
                case 3:
                    setMaxLength(9);
                    setMaxLengthDisabled(true);
                    setMaxLengthClass('');
                    setValue("maxLength", 9);
                    break;
                case 4:
                    setMaxLength(14);
                    setMaxLengthDisabled(true);
                    setMaxLengthClass('');
                    setValue("maxLength", 14);
                    break;
                case 5:
                    setMaxLengthDisabled(false);
                    setMaxLengthClass('mandatory');
                    setValue("maxLength", null);
                    break;
                case 6:
                    setDisableValidatedField(true);
                    setValue("isValidated", false);
                    setMaxLengthDisabled(false);
                    setMaxLengthClass('mandatory');
                    setValue("maxLength", null);
                    break;
                case 7:
                    setMaxLength(30);
                    setMaxLengthDisabled(true);
                    setMaxLengthClass('');
                    setValue("maxLength", 30);
                    break;
                default:
                    setMaxLengthDisabled(false);
                    setMaxLengthClass('mandatory');
                    break;
            }
        }
    }, [attributeType])

    const formSubmitHandler = (data: any) => {
        addForm?.current?.requestSubmit();
    };

    const getFormErrorMessage = (name) => {
        return errors[name] && <span className="tooltip-text"><BsExclamationCircleFill />{errors[name].message}</span>
    };

    const onAddFormSubmit = (submitData: any) => {
        submitData.attributeTypeId = submitData.attributeTypeId?.code
        if (submitData.maxLength === undefined)
            submitData.maxLength = maxLength;
        onAddCommonAttributeClick && onAddCommonAttributeClick(submitData)
    };

    const onError = (errors: any, e: any) => { };

    const maxLengthValidation = (value) => {
        if (attributeType.code === 5 && (value > 100 || value < 1))
            return "Enter value between 1 and 100";
        else if (attributeType.code === 6 && (value < 101 || value > 4000))
            return "Enter value between 101 and 4000";
    }

    const textValidate = (value) => {
        if (value) {
          const regex = /^[a-zA-Z0-9 ]+$/;
          if(regex.test(value))
            return true
        else
        return "Special character(s) and non-keyboard characters are not allowed "
        }
      }

    return (
        <>
            <AddModal
                header='New Common Attribute'
                showModal={showModal}
                isAdd={true}
                setShowModal={setShowModal}
                style={{ width: '35vw', height: '50vh' }}
                OnSaveClick={formSubmitHandler}
            >
                <form onSubmit={handleSubmit(onAddFormSubmit, onError)} ref={addForm}>

                    <div className="row px-2 pt-2">
                        <div className="field col-12 md:col-2 mb-0">
                            <span className="p-float-label">
                                <Controller
                                    name="attributeName"
                                    control={control}
                                    rules={{
                                        required: 'Enter Field Name.',
                                        validate: (value) => {
                                            return (value.trim().length <= 0 ? "This field is required." : textValidate(value))
                                          }
                                    }}

                                    render={({ field, fieldState }) => (
                                        <InputText
                                            id="inputtext"
                                            className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })}
                                            {...field}
                                            aria-autocomplete='none'
                                        />
                                    )} />
                                <label className='mandatory' htmlFor="inputtext">Field Name</label>
                                {getFormErrorMessage('attributeName')}
                            </span>
                        </div>
                    </div>

                    <div className="row px-2 pt-2">
                        <div className="field col-12 md:col-2">
                            <span className="p-float-label">
                                <Controller
                                    name="attributeTypeId"
                                    control={control}
                                    rules={{
                                        required: 'Enter Field Type.'
                                    }}
                                    render={({ field, fieldState }) => (
                                        <Dropdown
                                            inputId="inputtext"
                                            className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })}
                                            {...field}
                                            value={field.value}
                                            onChange={(e) => field.onChange(e.value)}
                                            options={fieldTypeLookup}
                                            optionLabel="name"
                                        />
                                    )} />
                                <label className='mandatory' htmlFor="Country">Field Type</label>
                                {getFormErrorMessage('attributeTypeId')}
                            </span>
                        </div>

                    </div>



                    <div className="row px-2">
                        <div className="field col-12 md:col-2 mb-0">
                            <span className="p-float-label">
                                <Controller
                                    name="maxLength"
                                    control={control}
                                    rules={{
                                        required: maxLengthClass === 'mandatory' ? 'Enter Max Characters Allowed.' : false,
                                        validate: (value) => maxLengthValidation(value)
                                    }}

                                    render={({ field, fieldState }) => (
                                        <InputNumber
                                            disabled={maxLengthDisabled}
                                            inputId="inputnumber"
                                            className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid && maxLengthClass === 'mandatory' })}
                                            {...field}
                                            value={field.value}
                                            onChange={(e) => field.onChange(e.value)}
                                            aria-autocomplete='none'
                                            />
                                    )} />
                                <label className={maxLengthClass} htmlFor="inputtext">Max Characters Allowed</label>
                                {maxLengthClass === 'mandatory' ? getFormErrorMessage('maxLength') : null}
                            </span>
                        </div>
                    </div>

                    <div className="row px-2">
                        <div className="field col-12 md:col-4 pt-0">
                            <div className="field-checkbox pt-2">
                                <Controller
                                    name="isMandatory"
                                    control={control}
                                    render={({ field }) => (
                                        <Checkbox
                                            inputId="binary"
                                            checked={field.value}
                                            {...field}
                                        />
                                    )} />
                                <label htmlFor="binary">Mandatory</label>
                            </div>
                        </div>
                    </div>

                    <div className="row px-2">
                        <div className="field col-12 md:col-4 pt-0">
                            <div className="field-checkbox pt-2">
                                <Controller
                                    name="isValidated"
                                    control={control}
                                    render={({ field }) => (
                                        <Checkbox
                                            disabled={disableValidatedField}
                                            inputId="binary"
                                            checked={field.value}
                                            {...field}
                                        />
                                    )} />
                                <label htmlFor="binary">Validated</label>
                            </div>
                        </div>
                    </div>
                </form>
            </AddModal>
        </>
    );
}

export default AddCommonAttributes;

