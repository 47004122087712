import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "redux/store";
import { baseURL, zlink } from "service/HttpService";

export interface MailDomainState {
    errors: any;
    mailDomainList: any;
    newMailDomain:any;
}

let initialState: MailDomainState = {
    errors: "",
    mailDomainList: "",
    newMailDomain:"",
};

const MailDomainSlice = createSlice({
    name: "mailDomain",
    initialState,
    reducers: {
        setErrors: (state, { payload }: PayloadAction<any>) => {
            state.errors = payload;
        },
        setMailDomainList: (state, { payload }: PayloadAction<any>) => {
            state.mailDomainList = payload;
        },
        setNewMailDomain: (state, { payload }: PayloadAction<any>) => {
            state.mailDomainList.unshift(payload);
        },
        setUpdatedMailDomain: (state, { payload }: PayloadAction<any>) => {
            state.mailDomainList.unshift(payload);
        },
        setDeletedMailDomain: (state, { payload }: PayloadAction<any>) => {
            state.mailDomainList = state.mailDomainList.filter((item: any) => item.accountMailDomainId !== payload);
        },
    }
})

export const getMailDomainList = (): AppThunk => async (dispatch) => {
    try {
		const res = await zlink.get(`${baseURL}api/v1/maildomains`);
        if (res.status === "done") {
			dispatch(setMailDomainList(res?.data?.mailDomains));
		} else {
			dispatch(setErrors(res.error));
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const onAddMailDomain = (body:any,reset,setDisplayInfoModal,setMessage): AppThunk => async (dispatch) => {
    try {
		const res = await zlink.post(`${baseURL}api/v1/maildomains/add`,body);
        if (res.status === "done") {
			dispatch(setNewMailDomain(res?.data));
            reset();
            setDisplayInfoModal(true);
            setMessage("Mail Domain added");
		} else {
			dispatch(setErrors(res.error));
            setDisplayInfoModal(true);
            setMessage(res.message);
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const onEditMailDomain = (body:any,mailDomainId:any,setDisplayInfoModal,setMessage,setShowEditMailDomainModal): AppThunk => async (dispatch) => {
    try {
		const res = await zlink.post(`${baseURL}api/v1/maildomains/${mailDomainId}`,body);
        if (res.status === "done") {
			dispatch(setUpdatedMailDomain(res?.data));
            dispatch(setDeletedMailDomain(mailDomainId));
            setDisplayInfoModal(true);
            setMessage("Mail Domain updated");
            setShowEditMailDomainModal(false);
		} else {
			dispatch(setErrors(res.error));
            setDisplayInfoModal(true);
            setMessage(res.message);
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const onDeleteMailDomain = (mailDomainId:any,setDisplayInfoModal,setMessage): AppThunk => async (dispatch) => {
    try {
		const res = await zlink.post(`${baseURL}api/v1/maildomains/${mailDomainId}/remove`,null);
        if (res.status === "done") {
			dispatch(setDeletedMailDomain(mailDomainId));
            setMessage("Mail Domain deleted");
            setDisplayInfoModal(true);
		} else {
			dispatch(setErrors(res.error));
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const {
    setErrors,
    setMailDomainList,
    setNewMailDomain,
    setUpdatedMailDomain,
    setDeletedMailDomain
} = MailDomainSlice.actions;

export default MailDomainSlice.reducer;