import React from "react";
import { Button } from "primereact/button";
import { BsFillPencilFill, BsGridFill, BsPaperclip, BsPlusLg, BsTrashFill } from "react-icons/bs";
import { FaCog, FaCopy, FaDownload, FaFilter } from "react-icons/fa";
const InspectionFAQDocument = () => {
    return (
        <>

            <h4 className="doc-title mb-2" >FAQs in Inspection Module</h4><br />
            <h5><span className="doc-subtitle">A. TechAdvance<sup>+</sup> Installation,
                Pre-requisites and Features</span>
            </h5>
            <br />
            <p>
                <b>A1. What are the pre-requisites for creating an Inspection Task? </b>
            </p>

            <ol className="font-14">
                <li>1. Create Contract for the Customer(s).</li>
                <ul className="bullet-list">
                    <li>Click <span className="doc-subtitle">Contracts: Add{" "}
                        <Button className="doc-button-icon">
                            <BsPlusLg className="icon" title='Add' />
                        </Button> </span> icon.</li>
                    <li>Create Contract with valid details.</li>
                    <li>Select the Sales Office to which the Contract is to be linked.</li>
                </ul>
                <li>2. Create Systems to be inspected.</li>
                <ul className="bullet-list">
                    <li>Click <span className="doc-subtitle">Systems: Add{" "}
                        <Button className="doc-button-icon">
                            <BsPlusLg className="icon" title='Add' />
                        </Button> </span> icon.</li>
                    <li>Upload a valid .xml Configuration File to the System, if the System is of MXL/XLS/Desigo Modular/Desigo FS20/Desigo FV20/Cerberus Pro FC92/Cerberus Pro FV92/Cerberus Pro Modular  Type.</li>
                </ul>
                <li>3. Create the Inspection Task for the System.</li>
                <ul className="bullet-list">
                    <li>Click <span className="doc-subtitle">Inspection Tasks</span> option.</li>
                    <li>Select the required Site and Contract from the corresponding drop downs displayed in the page.</li>
                    <li>Click <span className="doc-subtitle">Inspection Tasks: Add{" "}
                        <Button className="doc-button-icon">
                            <BsPlusLg className="icon" title='Add' />
                        </Button> </span> icon and add Inspection Task with required details.</li>
                    <li>Select the required Inspection reports through the link 'Report Selected'.</li>
                    <li>Select the Lead Technician </li>
                    <li>For MXL/XLS/Desigo Modular/Desigo FS20/Desigo FV20 /Cerberus Pro FC92/Cerberus Pro FV92/Cerberus Pro Modular  systems, the activated Inspection Task will be listed in TAP Bridge for the Lead Technician.</li>
                </ul>


            </ol>
            <br />
            <p><b>A2. What are the pre-requisites required for starting an Inspection Task? </b></p>

            <p>The user has to ensure that the following pre-requisites are met before starting an Inspection Task:</p>
            <ol className="font-14">
                <li>1. Contract, System and Inspection Task should be created in Tech Advance<sup>+</sup>.</li>
                <li>2. Inspection Task should be activated in Tech Advance<sup>+</sup>.</li>
                <li>3. Technicians doing the inspection must be linked to the Sales Office managing the Contract in Tech Advance<sup>+</sup>. </li>
                <li>4. For an automated inspection (MXL, XLS, Desigo Modular, Desigo FS20, Desigo FV20 systems, Desigo FV20, Cerberus Pro FC92, Cerberus Pro FV92, Cerberus Pro Modular  only), Lead Technician should initiate TAP Bridge, start the required Inspection Task in TAP Bridge,  and make sure that the Equipment list is sent to TechAdvance<sup>+</sup>. For manual inspection this is not required.  </li>
                <li>5. Latest TAP application should be installed in the mobile device. </li>
                <li>6. Network Connection should be available for joining (and quitting) the Inspection Task and for downloading data from Tech Advance<sup>+</sup>.</li>
            </ol>


            <br />
            <h4><span className="doc-subtitle">B. Inspection and Equipment List</span></h4>

            <br />
            <p><b>B1. How to add Contracts? </b></p>
            <ol className="font-14">
                <li>1. Select <span className="doc-subtitle"> Inspection: Contracts </span> menu.</li>
                <li>2. Click <span className="doc-subtitle">Contract: Add{" "}
                    <Button className="doc-button-icon">
                        <BsPlusLg className="icon" title='Add' />
                    </Button>  </span> icon.</li>
                <li>3. New Contract window will be displayed with Save and Cancel buttons.</li>
                <li>4. Add/Select the Customer, Enter the Contract Number, Enter Contract Name, Note, Start Date and End Date of the Contract.</li>
                <li>5. Select the Sales Office from the drop down. Start Date will be displayed by default.</li>
                <li>6. Select the required fields and click Save button</li>
            </ol>
            <p> </p>
            <p> OR</p>
            <ol className="font-14">
                <li>1. Select<span className="doc-subtitle"> Inspection: Inspection Task </span>menu.</li>
                <li>2. Select the required Customer.</li>
                <li>3. Click <span className="doc-subtitle">Add{" "}
                    <Button className="doc-button-icon">
                        <BsPlusLg className="icon" title='Add' />
                    </Button>  </span> icon near the Contract selection dropdown. New Contract window will be displayed with Save and Cancel buttons.</li>
                <li>4. Enter the Contract Number, Contract Name, Note, Start Date and End Date of the Contract.</li>
                <li>5. Select the Sales Office from the drop down. Start Date will be displayed by default.</li>
                <li>6. Select the required fields and click Save button.</li>
                <li>7. The newly added Contract will be auto selected in the dropdown.</li>
            </ol>

            <br />
            <p><b>B2.How to link the Sites to a Contract? </b></p>
            <ol className="font-14">
                <li>1. Select<span className="doc-subtitle"> Inspection: Contracts </span>menu.</li>
                <li>2. Select the required Contract from the data grid. (Assumption: Contracts already added)</li>
                <li>3. Click <span className="doc-subtitle">Sites </span> from<span className="doc-subtitle"> More{" "}
                    <Button className="doc-button-icon">
                        <FaCog className="icon" title='More' />
                    </Button>  </span> icon.</li>
                <li>4. By default all active Sites of the Customer will be listed with check boxes against each Site.</li>
                <li>5. Select required Sites and click Save Changes button to save the changes.</li>
            </ol>
            <p>Note: Site(s) with active Inspection Task(s) linked to it cannot be removed</p>

            <br />
            <p><b>B3.How to close a Contract? </b></p>
            <ol className="font-14">
                <li>1. Select <span className="doc-subtitle">Inspection: Contracts </span>menu.</li>
                <li>2. Select the required Contract from the data grid. (Assumption: Contracts already added)</li>
                <li>3. Click <span className="doc-subtitle">Close </span>option from <span className="doc-subtitle">More{" "}
                    <Button className="doc-button-icon">
                        <FaCog className="icon" title='More' />
                    </Button> </span> icon.
                </li>
                <li>4. If any Inspection Task of the selected Contract is in Active status then a confirmation message, “Inspection Task(s) of the selected Contract is in progress, are you sure you want to close the Contract?” with Yes or No buttons will be displayed.</li>
                <li>5. If the Inspection Task of the selected Contract is not in progress then a confirmation message, “Are you sure you want to close the selected Contract?” with Yes or No buttons will be displayed.</li>
                <li>6.Click 'Yes' to the message. The selected Contract will be closed and the status will be displayed as 'Closed' in the data grid.</li>
                <li>7. All the Systems and active Inspection Tasks associated with the selected Contract also will get closed automatically. (Completed Inspection Task will remains as same.)</li>

            </ol>


            <br />
            <p><b>B4.What happens when a Contract is closed? </b></p>

            <ol className="font-14">
                <li>1. The status of the Contract will be displayed as 'Closed' in the data grid.</li>
                <li>2. On closing the Contract all Systems related to the Contract (created under the site which is linked to the selected Contract) will be closed automatically.</li>
                <li>3. Cannot able to add new Inspection Task under the closed Contract.</li>
                <li>4. The status of the active Inspection Task(s) created under the closed Contract will change to 'Closed'.(Completed Inspection Task will remains as same.).</li>
                <li>5. Inspection Tasks under a closed Contract cannot be edited/activated.</li>
            </ol>



            <br />
            <p><b>B5.How to activate a closed Contract? </b></p>
            <ol className="font-14">
                <li>1. Select <span className="doc-subtitle">Inspection: Contracts </span> menu.</li>
                <li>2. Select the closed Contract from the data grid.</li>
                <li>3. Click <span className="doc-subtitle">Activate </span>from <span className="doc-subtitle">More{" "}
                    <Button className="doc-button-icon">
                        <FaCog className="icon" title='More' />
                    </Button> </span> icon.</li>
                <li>4. A confirmation message, “Are you sure you want to activate the selected Contract?” with Yes or No buttons will be displayed.</li>
                <li>5. Click 'Yes' to the message. The selected Contract will be activated and its corresponding status will be displayed as 'Active' in the data grid.</li>
            </ol>
            <p>Note: Even if the Contract is Activated, all associated System and Inspection Tasks will be in closed/completed status only, unless it is changed manually.</p>

            <br />
            <p><b>B6.How to add a System to a Site? </b></p>
            <ol className="font-14">
                <li>1. Select <span className="doc-subtitle">Inspection: Systems </span> menu.</li>
                <li>2. Click <span className="doc-subtitle">Add{" "}
                    <Button className="doc-button-icon">
                        <BsPlusLg className="icon" title='Add' />
                    </Button> </span> icon. New System window will be displayed with Save and Cancel buttons</li>
                <li>3. Enter the System Name.</li>
                <li>4. Select the System Type from the drop down.</li>
                <li>5. If System Type selected is Desigo Modular/XLS, the Networked radio buttons will be enabled with Yes/No options. Specify whether Networked or not, and whether MKB5 Keyboard is there or not.</li>
                <li>6. Select the Preferred Language and click Save button.</li>

            </ol>

            <br />
            <p><b>B7.How to upload XML file to a System? </b></p>
            <ol className="font-14">
                <li>1. Select <span className="doc-subtitle">Inspection: Systems </span> menu.</li>
                <li>2. Select the required System from the data grid. (Assumption: System already added and no Configuration file is uploaded to it.)</li>
                <li>3. To upload <span className="doc-subtitle">XML</span> file, Click <span className="doc-subtitle">Upload XML</span> option from <span className="doc-subtitle"> Attachments   </span>icon. Upload File window will be displayed with  <span className="doc-subtitle"> Upload </span> and <span className="doc-subtitle"> Cancel </span> buttons.</li>
                <li>4. Click Attachment field and select the required XML file from the user's computer.</li>
                <li>5. On uploading the file, the Uploaded File Name and Uploaded Date will be displayed against the corresponding fields.</li>
                <li>6. Click Upload button. The XML file will be uploaded to the selected System.</li>
            </ol>
            <p>Note: XML file cannot be replaced if the selected System is active for an Inspection.</p>

            <br />
            <p><b>B8.How to upload Configuration file to a System? </b></p>
            <ol className="font-14">
                <li>1. Select <span className="doc-subtitle"> Inspection: Systems </span>menu.</li>
                <li>2. Select the required System from the data grid. (Assumption: System already added and no Configuration file is uploaded to it.)</li>
                <li>3. To upload Configuration file, Click <span className="doc-subtitle"> Upload Config File </span> option from <span className="doc-subtitle"> Attachments </span>
                    <Button className="doc-button-icon">
                        <BsPaperclip className="icon" title="Upload" />
                    </Button>{" "} icon. Upload File window will be displayed with Upload and Cancel buttons.</li>
                <li>4. Click Attachment field and select the required Configuration file from the user's computer.</li>
                <li>5. On uploading the file,the Uploaded File Name and Uploaded Date will be displayed against the corresponding fields.</li>
                <li>6. Click Upload button. The Configuration file will be uploaded to the selected System.</li>
            </ol>
            <p>Note: Configuration file cannot be replaced if the selected System is active for an Inspection.</p>


            <br />
            <p><b>B9.How to edit System details? </b></p>

            <ol className="font-14">
                <li>1. Select <span className="doc-subtitle">Inspection: Systems </span>menu.</li>
                <li>2. Select the required System from the data grid (status should be 'Active').</li>
                <li>3. Click <span className="doc-subtitle">Edit{" "}
                    <Button className="doc-button-icon">
                        <BsFillPencilFill className="icon" title="Edit" />
                    </Button> {' '} </span> icon. Edit System window will be displayed with<span className="doc-subtitle"> Save Changes </span> and <span className="doc-subtitle"> Cancel </span> buttons.</li>
                <li>4. Modify the required fields and click <span className="doc-subtitle">Save Changes </span> button.</li>

            </ol>

            <br />
            <p><b>B10.How to close a System? </b></p>

            <ol className="font-14">
                <li>1. Select <span className="doc-subtitle">Inspection: Systems </span>menu.</li>
                <li>2. Click <span className="doc-subtitle">Close </span> option from <span className="doc-subtitle">More </span>{" "}
                    <Button className="doc-button-icon">
                        <FaCog className="icon" title='More' />
                    </Button> icon.</li>
                <li>3. If the System has an active Inspection Task, then a confirmation message, “Do you want to quit all the tasks of the Technician(s) and close the Inspection Task and System?” with Yes or No buttons will be displayed.</li>
                <li>4. If there are no active Inspection Tasks, or no Inspection tasks have been created for the selected System then a confirmation message, “Are you sure you want to close the selected System?” with Yes or No buttons will be displayed.</li>
                <li>5. Click 'Yes' to the message. The selected System will be closed and the status will be displayed as 'Closed' in the data grid.</li>
            </ol>

            <br />
            <p><b>B11.What happens when a System is closed? </b></p>
            <ol className="font-14">
                <li>1. The status of the System will be displayed as 'Closed' in the data grid.</li>
                <li>2. On closing a System all the associated Inspection Tasks under that System will be closed automatically.</li>
                <li>3. The status of the Inspection Task(s) created under the closed System will change to 'Closed'.(Completed Inspection Task will remain same.)</li>
                <li>4. Inspection Tasks under a closed System cannot be edited /activated unless the System is activated again.</li>

            </ol>

            <br />
            <p><b>B12.How to activate a closed System? </b></p>
            <ol className="font-14">
                <li>1. Select <span className="doc-subtitle"> Inspection: Systems </span> menu.</li>
                <li>2. Select the closed System from the data grid.</li>
                <li>3. Click <span className="doc-subtitle"> Activate </span> option from <span className="doc-subtitle"> More </span>{" "}
                    <Button className="doc-button-icon">
                        <FaCog className="icon" title='More' />
                    </Button> icon.</li>
                <li>4. A confirmation message, “System Activated” will be displayed. Click OK to the message.</li>
                <li>5. The selected System will be activated and its status will be displayed as 'Active' in the data grid.</li>

            </ol>

            <br />
            <p><b>B13.How to create an Inspection Task? </b></p>
            <p><u>Assumptions</u></p>
            <ul className="bullet-list">
                <li>Contract for inspection is already created with appropriate Customer, Sales office.</li>
                <li>System created under the Site.</li>
                <li>Valid Configuration file is uploaded to the System if the selected System is of Equipment Group MXL/XSL/Desigo Modular/Desigo FS20/Desigo FV20/Cerberus Pro FC92/Cerberus Pro FV92/Cerberus Pro Modular.</li>
            </ul>

            <ol className="font-14">
                <li>1. Click <span className="doc-subtitle">Inspection: Inspection Tasks </span> menu.</li>
                <li>2. Select the required Customer and Contract from the corresponding drop down displayed in the Inspection Task page.</li>
                <li>3. Add an Inspection Task through <span className="doc-subtitle">Inspection: Inspection Tasks: Add  </span>{" "}
                    <Button className="doc-button-icon">
                        <BsPlusLg className="icon" title='Add' />
                    </Button> icon.</li>
                <li>4. New Inspection Task page will be displayed. Enter the required details and map the Reports accessible to the Inspection through the link 'Report Selected'.</li>
                <li>5. On selecting the reports, Lead Technician drop down will be displayed. Select the required Lead Technician.</li>
                <li>6. Click on <span className="doc-subtitle">Save </span> button.</li>
                <li>7. An activated Inspection Task will be added with status as 'Active'.</li>
            </ol>


            <br />
            <p><b>B14.How to Activate an Inactive/Closed/Completed Inspection Task? </b></p>
            <p><u>Assumptions</u></p>
            <ul className="bullet-list">
                <li>Contract for inspection is already created with appropriate Customer, Sales office.</li>
                <li>System created under the Site.</li>
                <li>Valid Configuration file is uploaded to the System if the selected System is of Equipment Group MXL/XSL/Desigo Modular/Desigo FS20/Desigo FV20/Cerberus Pro FC92/Cerberus Pro FV92/Cerberus Pro Modular.</li>
                <li>An Inspection Task is created and is in Inactive/Closed/Completed status.</li>
            </ul>

            <ol className="font-14">
                <li>1. Click<span className="doc-subtitle"> Inspection: Inspection Tasks</span>  menu.</li>
                <li>2. Select the required Customer and Contract from the corresponding drop down displayed in the Inspection Task page.</li>
                <li>3. Click <span className="doc-subtitle"> Edit </span>{" "}
                    <Button className="doc-button-icon">
                        <BsFillPencilFill className="icon" title="Edit" />
                    </Button> {' '} icon.</li>
                <li>4. Edit Inspection Task page will be displayed with Activate check box. Enter the required details and map the Reports accessible to the Inspection through the link  <span className="doc-subtitle">'Reports Selected'</span>.</li>
                <li>5. Select the required Lead Technician.</li>
                <li>6. Select the <span className="doc-subtitle"> Activate </span> check box and click Save Changes button.</li>
                <li>7. Inspection Task will be activated.</li>


                <br />
            </ol>
            <p><b>B15.How to assign Technician(s) to join in an Inspection Task? </b></p>
            <ol className="font-14">
                <li>1. Click <span className="doc-subtitle">Manage: Sales Office</span> menu.</li>
                <li>2. Select the required Sales Office from the data grid and click <span className="doc-subtitle">Techs </span> menu from <span className="doc-subtitle"> More </span>{" "}
                    <Button className="doc-button-icon">
                        <FaCog className="icon" title='More' />
                    </Button> icon. [Assumption: Technicians already added]</li>
                <li>3. All the active technicians added in Tech Advance<sup>+</sup> will be listed with check boxes against each Technician. Any Technician already assigned will be displayed as selected in Update Technicians page.</li>
                <li>4. Select the required Technicians to be assigned to the selected Sales Office and click <span className="doc-subtitle">Save Changes </span> button. These technicians can join the Inspection Tasks created with the selected Sales Office.</li>
            </ol>
            <p>Note: These technicians will be listed in the Lead Technicians drop down while activating an Inspection.</p>


            <br />
            <p><b>B16.How to assign Lead Technician to an Inspection Task? </b></p>
            <ol className="font-14">
                <li>1. Select <span className="doc-subtitle">Inspection: Inspection Tasks</span> menu.</li>
                <li>2. Select the required Customer and Contract from its corresponding drop down displayed in the Inspection Task page. [Assumption: Selected Contract is linked to the Sales office to which the Technicians were assigned.]</li>
                <li>3. Click <span className="doc-subtitle"> Edit {" "}
                    <Button className="doc-button-icon">
                        <BsFillPencilFill className="icon" title="Edit" />
                    </Button> {' '} </span> icon.</li>
                <li>4. All the Technicians assigned to the selected Sales Office will be listed in Lead Technician field in <span className="doc-subtitle"> Edit Inspection Task </span>page.</li>
                <li>5. Select the required Lead Technician from the list box.</li>
                <li>6. Click <span className="doc-subtitle">Save Changes</span> button.</li>
            </ol>


            <br />
            <p><b>B17.How to map Reports to an Inspection Task? </b></p>

            <ol className="font-14">
                <li>1. Select <span className="doc-subtitle">Inspection: Inspection Tasks </span> menu.</li>
                <li>2. Select the required Inspection Task for which the reports need to be mapped.</li>
                <li>3. Select <span className="doc-subtitle">Reports Selected </span>option from <span className="doc-subtitle">More{" "}
                    <Button className="doc-button-icon">
                        <FaCog className="icon" title='More' />
                    </Button> </span> icon.</li>
                <li>4. A Reports Accessible to an Inspection Task page will be displayed with Cancel and Save Changes button.</li>
                <li>5. Select the required reports and click<span className="doc-subtitle"> Save Changes </span>button.</li>

            </ol>


            <br />
            <p><b>B18.Why the created Technician is not listed in the Inspection Task? </b></p>
            <p>
                While creating Contracts for Inspection Tasks, Sales Office has to be linked and the Technicians for the Inspection also have to be linked to the same Sales Office.
                <br /> If the Technician is not linked to the same Sales Office of that Contract, then the Technician will not be listed in the Lead Technician drop down in the Inspection Task.
                <br />
            </p>




            <br />
            <p><b>B19.How to add Equipment Groups manually to Inspection Tasks? </b></p>
            <ol className="font-14">
                <li>1. Select<span className="doc-subtitle"> Inspection: Inspection Tasks </span> menu.</li>
                <li>2. Select the required Customer and Contract from its corresponding Dropdown displayed in the Inspection Task page. [Assumption: Active Inspection Task exists for the selected Contract.]</li>
                <li>
                    3. Select the required Inspection Task and click 
                    <span className="doc-subtitle"> Inspection Details </span>
                    <Button className="doc-button-icon">
                        <BsGridFill className="icon" title="Inspection Details"/>
                    </Button>
                    {" "} icon.
                </li>
                <li>
                    4. A window will be displayed  with Contract, System, Questions, Equipment and Summary buttons.
                </li>
                <li>5. Select the Add{" "}
                    <Button className="doc-button-icon">
                        <BsPlusLg className="icon" title='Add' />
                    </Button> icon displayed against the Equipment Group Drop down.</li>
                <li>6. The New Equipment Data window will be displayed with Save and Cancel buttons.</li>
                <li>7. Enter the Address, Name and select the Type.</li>
                <li>8. Click <span className="doc-subtitle">Save </span> button. Added <span className="doc-subtitle"> Equipment Group </span> will be selected in the Equipment Group drop down by default.</li>
            </ol>


            <br />
            <p><b>B20.How to edit an Equipment Group? </b></p>

            <p>[Assumption: Required Inspection Task is in 'Active' status]</p>
            <ol className="font-14">
                <li>1. Select <span className="doc-subtitle"> Inspection: Inspection Tasks </span> menu.</li>
                <li>2. Select the required Customer and Contract from its corresponding Drop down displayed in the Inspection Task page. [Assumption: Active Inspection Task exists for the selected Contract.]</li>
                <li>
                    3. Select the required Inspection Task and click 
                    <span className="doc-subtitle"> Inspection Details </span>
                    <Button className="doc-button-icon">
                        <BsGridFill className="icon" title="Inspection Details"/>
                    </Button>
                    {" "} icon.
                </li>
                <li>4. A window will be displayed  with Contract, System, Questions, Equipment and Summary buttons.</li>
                <li>5. Select <span className="doc-subtitle">Equipment </span> button, All the added equipment’s will be displayed in the grid.</li>
                <li>6. On selecting the required Equipment Group and Equipment Type from the drop down, the corresponding details will be displayed in the grid. </li>
                <li>7. Select the <span className="doc-subtitle">Edit </span>{" "}
                    <Button className="doc-button-icon">
                        <BsFillPencilFill className="icon" title="Edit" />
                    </Button> {' '} icon displayed against the Equipment Group drop down.</li>
                <li>8. The Edit Equipment Data window will be displayed with details, Save Changes and Cancel buttons.</li>
                <li>9. Edit the Address or Name or Type.</li>
                <li>10. Click <span className="doc-subtitle"> Save Changes </span> button.</li>

            </ol>


            <br />
            <p><b>B21.How to view Inspection Details of Equipment? </b></p>

            <p>[Assumption: Required Inspection Task is in 'Active' status]</p>
            <ol className="font-14">
                <li>
                    1. Select <span className="doc-subtitle">Inspection: Inspection Tasks </span> menu.
                </li>
                <li>
                    2. Select the required Customer and Contract from its corresponding Drop down displayed in the Inspection Task page. 
                    [Assumption: Active Inspection Task exists for the selected Contract.]
                </li>
                <li>
                    3. Select the required Inspection Task and click
                    <span className="doc-subtitle"> Inspection Details </span>
                    <Button className="doc-button-icon">
                        <BsGridFill className="icon" title="Inspection Details"/>
                    </Button> 
                    {" "} icon.
                </li>
                <li>4. A window will be displayed  with Contract, System, Questions, Equipment and Summary buttons.</li>
                <li>5. Select Equipment button, All the added equipment’s will be displayed in the grid.</li>
                <li>6. On selecting the required Equipment Group and Equipment Type from the drop down, the corresponding details will be displayed in the grid. </li>

            </ol>


            <br />
            <p><b>B22.How to view Equipment list? </b></p>

            <p>[Assumption: Required Inspection Task is in 'Active' status]</p>
            <ol className="font-14">
                <li>1. Select <span className="doc-subtitle">Inspection: Inspection Tasks </span> menu.</li>
                <li>2. Select the required Customer and Contract from its corresponding Drop down displayed in the Inspection Task page. [Assumption: Active Inspection Task exists for the selected Contract.]</li>
                <li>
                    3. Select the required Inspection Task and click 
                    <span className="doc-subtitle">Inspection Details </span>
                    <Button className="doc-button-icon">
                        <BsGridFill className="icon" title="Inspection Details"/>
                    </Button>
                    {" "} icon.
                </li>
                <li>4. A window will be displayed  with Contract, System, Questions, Equipment and Summary buttons.</li>
                <li>5. Select Equipment button, All the added equipment’s will be displayed in the grid.</li>
                <li>6. On selecting the required Equipment Group and Equipment Type from the drop down, the corresponding details will be displayed in the grid. </li>
            </ol>


            <br />
            <p><b>B23.How to Import Equipment details from an Excel sheet ? </b></p>
            <ol className="font-14">
                <li>1. Select <span className="doc-subtitle">Inspection: Inspection Tasks </span> menu.</li>
                <li>2. Select the required Customer and Contract from its corresponding Dropdown displayed in the Inspection Task page. [Assumption: Active Inspection Task exists for the selected Contract.]</li>
                <li>
                    3. Select the required Inspection Task and click
                    <span className="doc-subtitle"> Inspection Details  </span>
                    <Button className="doc-button-icon">
                        <BsGridFill className="icon" title="Inspection Details"/>
                    </Button>
                    {" "} icon.
                </li>
                <li>4. A window will be displayed  with Contract, System, Questions, Equipment and Summary buttons.</li>
                <li>5. Select <span className="doc-subtitle">Equipment </span> button, All the added equipment’s will be displayed in the grid.</li>
                <li>6. On selecting the required Equipment Group and Equipment Type from the drop down, the corresponding details will be displayed in the grid. </li>
                <li>7. The Equipment node will be selected by default with the Equipment details listed.</li>
                <li>8. Select any value except 'All' from the Equipment Type drop down. </li>
                <li>9. Click <span className="doc-subtitle">Equipment: Import Equipment </span> option from <span className="doc-subtitle"> More  </span>{" "}
                    <Button className="doc-button-icon">
                        <FaCog className="icon" title='More' />
                    </Button> icon.</li>
                <li>10. Import Equipment page will be displayed with the options to browse the Attachment,  select Worksheet and Equipment Specific. Click Choose File button and select the Attachment from the user's computer.</li>
                <li>11. The work sheets in the excel file will be listed by name in the Work Sheet drop down automatically.</li>
                <li>12. Select the required Worksheet and the radio button: Common or Equipment Specific. On selecting the required worksheet a table with “Excel Fields” and “Tech Advance<sup>+</sup> fields” columns will be displayed .</li>
                <li>13. Map Fire and Safety Details window will be displayed. Map the Excel fields with the corresponding Tech Advance<sup>+</sup> Fields and click Save button. A confirmation message” Column Mapping saved” will be displayed.</li>
                <li>14. Click <span className="doc-subtitle">Fetch Data </span>button in Import Equipment page.</li>
                <li>15. Import Equipment page will be displayed with all the imported data listed and will be mapped to its corresponding fields with a comment in the Comment column.</li>
                <li>16. User can click on <span className="doc-subtitle"> Inline Edit </span>{" "}
                    <Button className="doc-button-icon">
                        <i className="pi pi-pencil icon" title="Inline Edit" />
                    </Button>{" "} icon provided in the grid to edit the data imported from the excel file and click on <span className="doc-subtitle"> Save{" "}
                        <Button className="doc-button-icon">
                            <i className="pi pi-save icon" title="Save" />
                        </Button>{" "} </span> icon to save the data.</li>
                <li>17. Click <span className="doc-subtitle"> Import </span> button to import the data.</li>

            </ol>


            <br />
            <p><b>B24.How to link Equipment to a Module? </b></p>

            <p>[Assumption: Required Inspection Task is in 'Active' status]</p>
            <ol className="font-14">
                <li>1. Select <span className="doc-subtitle">Inspection: Inspection Tasks</span> menu.</li>
                <li>2. Select the required Customer and Contract from its corresponding Dropdown displayed in the Inspection Task page. [Assumption: Active Inspection Task exists for the selected Contract.]</li>
                <li>
                    3. Select the required Inspection Task and click 
                    <span className="doc-subtitle"> Inspection Details </span> 
                    <Button className="doc-button-icon">
                        <BsGridFill className="icon" title="Inspection Details"/>
                    </Button>
                    {" "} icon.</li>
                <li>4. A window will be displayed  with Contract, System, Questions, Equipment and Summary buttons.</li>
                <li>5. Select <span className="doc-subtitle">Equipment </span> button, All the added equipment’s will be displayed in the grid.</li>
                <li>6. On selecting the required <span className="doc-subtitle">Equipment Group </span> and <span className="doc-subtitle"> Equipment Type </span>from the drop down, the corresponding details will be displayed in the grid. </li>
                <li>7. Select <span className="doc-subtitle">Devices </span>option from <span className="doc-subtitle"> Equipment Type </span> drop down.</li>
                <li>8. Select the Equipment(s) to be linked (Multiple Equipment can be linked together.)</li>
                <li>9. Click <span className="doc-subtitle">Equipment: Connectivity </span>option from <span className="doc-subtitle"> more{" "}
                    <Button className="doc-button-icon">
                        <FaCog className="icon" title='More' />
                    </Button> icon</span> and a message "Do you want to link this Equipment to Module?" will be displayed.</li>
                <li>10. Click 'Yes' to the message. Set Connectivity window will be displayed with System, Equipment Group, Devices and Cancel, Add buttons.</li>
                <li>11. Select the required Device from the drop down.</li>
                <li>12. The text boxes System and Equipment Group will be displayed as read only and cannot be edited. [Assumption: Modules already added]</li>
                <li>13. Set the connectivity by clicking <span className="doc-subtitle">Add </span>button.</li>
                <li>14. Tech Advance <sup>+</sup> window will be displayed with “Added Connectivity” message and OK button.</li>
            </ol>




            <br />
            <p><b>B25.How to add new Equipment in Tech Advance<sup>+</sup>? </b></p>

            <p>[Assumption: Required Inspection Task is in 'Active' status]</p>
            <ol className="font-14">
                <li>1. Select <span className="doc-subtitle">Inspection: Inspection Tasks </span>menu.</li>
                <li>2. Select the required Customer and Contract from its corresponding Dropdown displayed in the Inspection Task page. [Assumption: Active Inspection Task exists for the selected Contract.]</li>
                <li>
                    3. Select the required Inspection Task and click 
                    <span className="doc-subtitle"> Inspection Details </span>
                    <Button className="doc-button-icon">
                        <BsGridFill className="icon" title="Inspection Details"/>
                    </Button>
                    {" "} icon. 
                </li>
                <li>4. A window will be displayed  with Contract, System, Questions, Equipment and Summary buttons.</li>
                <li>5. Select <span className="doc-subtitle">Equipment </span>button, All the added equipment’s will be displayed in the grid.</li>
                <li>6. On selecting the required Equipment Group and Equipment Type from the drop down, the corresponding details will be displayed in the grid.</li>
                <li>7. Select any Equipment Type such as AHU/Devices/Doors/Modules/NAC Panels/Kitchen Extinguishers/Stand Pipes/Fire Pumps from Equipment Type drop down. (Not allowed to add Equipment through the All option.)</li>
                <li>8.
                    Click <span className="doc-subtitle"> Add </span>{" "}
                    <Button className="doc-button-icon">
                        <BsPlusLg className="icon" title='Add' />
                    </Button>
                    <span className="doc-subtitle"> icon.</span>
                </li>
                <li>9. The New Equipment window with Equipment Group, Module, Address, Barcode etc will be displayed with <span className="doc-subtitle"> Save </span> and <span className="doc-subtitle"> Cancel </span> buttons.</li>
                <li>10. Enter the Equipment details and click <span className="doc-subtitle"> Save </span> button.</li>
                <li>11. Equipment Added message will be displayed with OK button.</li>
                <li>12. The new Equipment will be listed in the data grid.</li>
            </ol>


            <br />
            <p><b>B26.How to filter Equipment List in the Inspection Task? </b></p>
            <ol className="font-14">
                <li>1. Select <span className="doc-subtitle"> Inspection: Inspection Tasks </span> menu.</li>
                <li>2. Select the required Customer and Contract from its corresponding Dropdown displayed in the Inspection Task page. [Assumption: Active Inspection Task exists for the selected Contract.]</li>
                <li>
                    3. Select the required Inspection Task and click 
                    <span className="doc-subtitle">Inspection Details </span> 
                    <Button className="doc-button-icon">
                        <BsGridFill className="icon" title="Inspection Details"/>
                    </Button>
                    {" "}icon.
                </li>
                <li>4. A window will be displayed  with Contract, System, Questions, Equipment and Summary buttons.</li>
                <li>5. Select<span className="doc-subtitle"> Equipment </span> button, All the added equipment’s will be displayed in the grid.</li>
                <li>6. On selecting the required Equipment Group and Equipment Type from the drop down, the corresponding details will be displayed in the grid.</li>
                <li>7. Click <span className="doc-subtitle">Filter{" "}
                    <Button className="doc-button-icon">
                        <FaFilter className="icon" title="Filter" />
                    </Button></span> icon.</li>
                <li>8. Filter window will be displayed with Planned Start Date, Planned End Date, Data Fields drop downs etc. with Cancel and Go buttons.</li>
                <li>9. Enter required details and click <span className="doc-subtitle">Go </span>button.</li>
                <li>10. Equipment list will be filtered according to the values entered.</li>


            </ol>


            <br />
            <p><b>B27. How to filter Equipment list with Attachments/XML imported/ Manually added Equipment ? </b></p>
            <ol className="font-14">
                <li>1. The Equipment list page will be displayed on clicking the Panel option from the Inspection Details menu option with all Equipment listed by default.</li>
                <li>2. Below the filter section three checkboxes are provided: Attachments Only, XML Imported and Manually Added.</li>
                <li>3. If the selected check box is “Attachments Only” then the data grid will list only those Equipment with attachments added to it.</li>
                <li>4. If the selected check box is “XML Imported” then the data grid will list only those Equipment that were imported using XML file while running the TAP Bridge.</li>
                <li>5. If the selected check box is “Manually Added” then the data grid will list with only those Equipment that were manually added to the selected Panel.</li>
                <li>6. Select the required check box to filter the Equipment List accordingly.</li>
            </ol>
            <p>Note: XML Imported and Manually Added checkboxes will be selected by default.</p>


            <br />
            <p><b>B28.How to link barcode to Equipment in the list? </b></p>
            <ol className="font-14">
                <li>1. Select<span className="doc-subtitle"> Inspection: Inspection </span>Tasks menu.</li>
                <li>2. Select the required Customer and Contract from its corresponding Dropdown displayed in the Inspection Task page. [Assumption: Active Inspection Task exists for the selected Contract.]</li>
                <li>
                    3. Select the required Inspection Task and click 
                    <span className="doc-subtitle"> Inspection Details </span> 
                    <Button className="doc-button-icon">
                        <BsGridFill className="icon" title="Inspection Details"/>
                    </Button>
                    {" "} icon.
                </li>
                <li>4. A window will be displayed  with Contract, System, Questions, Equipment and Summary buttons.</li>
                <li>5. Select <span className="doc-subtitle">Equipment</span> button, All the added equipment’s will be displayed in the grid.</li>
                <li>6. On selecting the required Equipment Group and Equipment Type from the drop down, the corresponding details will be displayed in the grid.</li>
                <li>7. Select Equipment Type: AHU/Devices/Doors/Modules/NAC Panels etc.</li>
                <li>8. Select the required Equipment from the data grid and click<span className="doc-subtitle"> Edit{" "}
                    <Button className="doc-button-icon">
                        <BsFillPencilFill className="icon" title="Edit" />
                    </Button> {' '} </span> icon.</li>
                <li>9. The Edit Equipment window will be displayed with equipment details and <span className="doc-subtitle"> Save Changes </span> and <span className="doc-subtitle"> Cancel </span> buttons.</li>
                <li>10. The Barcode textbox for the selected Equipment will be displayed, if enabled in Common Attributes and Data Entry display settings. </li>
                <li>11. Enter the Barcode details and click<span className="doc-subtitle"> Save Changes </span> button.</li>

            </ol>


            <br />
            <p><b>B29.How to Clone an Equipment in Tech Advance<sup>+</sup>? </b></p>
            <ol className="font-14">
                <li>1. Select <span className="doc-subtitle">Inspection: Inspection Tasks </span> menu.</li>
                <li>2. Select the required Customer and Contract from its corresponding Dropdown displayed in the Inspection Task page. [Assumption: Active Inspection Task exists for the selected Contract.]</li>
                <li>
                    3. Select the required Inspection Task and click 
                    <span className="doc-subtitle">Inspection Details </span>
                    <Button className="doc-button-icon">
                        <BsGridFill className="icon" title="Inspection Details"/>
                    </Button>
                    {" "}  icon.
                </li>
                <li>4. A window will be displayed  with Contract, System, Questions, Equipment and Summary buttons.</li>
                <li>5. Select <span className="doc-subtitle">Equipment</span> button, All the added equipment’s will be displayed in the grid.</li>
                <li>6. On selecting the required Equipment Group and Equipment Type from the drop down, the corresponding details will be displayed in the grid.</li>
                <li>7. Select the Equipment Type and required Equipment which is not an XML parsed equipment.</li>
                <li>8. Click on the <span className="doc-subtitle"> Clone {""}
                    <Button className="doc-button-icon">
                        <FaCopy className="icon" title="Clone" />
                    </Button>  </span> icon, The Clone Equipment Page will be displayed with Equipment Details and <span className="doc-subtitle"> Save Changes </span> and <span className="doc-subtitle"> Cancel </span> button.</li>
                <li>9. Enter the required details and click<span className="doc-subtitle"> Save Changes </span> button.</li>
            </ol>

            <br />
            <p><b>B30.How to delete Equipment from Tech Advance<sup>+</sup>? </b></p>

            <p>[Assumption: Required Inspection Task is in 'Active' status]</p>
            <ol className="font-14">
                <li>1. Select <span className="doc-subtitle">Inspection: Inspection Tasks </span> menu.</li>
                <li>2. Select the required Customer and Contract from its corresponding Dropdown displayed in the Inspection Task page. [Assumption: Active Inspection Task exists for the selected Contract.]</li>
                <li>
                    3. Select the required Inspection Task and click 
                    <span className="doc-subtitle">Inspection Details </span>
                    <Button className="doc-button-icon">
                        <BsGridFill className="icon" title="Inspection Details"/>
                    </Button>
                    {" "} icon.
                </li>
                <li>4. A window will be displayed  with Contract, System, Questions, Equipment and Summary buttons.</li>
                <li>5. Select <span className="doc-subtitle">Equipment </span>button, All the added equipment’s will be displayed in the grid.</li>
                <li>6. On selecting the required Equipment Group and Equipment Type from the drop down, the corresponding details will be displayed in the grid.</li>
                <li>7. Select the Equipment to be deleted from the data grid. Multiple equipments can be selected if required.</li>
                <li>8. Click<span className="doc-subtitle"> Delete{" "}
                    <Button className="doc-button-icon">
                        <BsTrashFill className="icon" title="Delete" />
                    </Button>{" "} </span> icon and a message “Are you sure you want to delete the selected Equipment?” will be displayed with Yes or No buttons.</li>
                <li>9. Click 'Yes' to the message and the selected Equipment will be deleted.</li>
            </ol>

            <p>Note: Modules and Sprinklers with connectivity cannot be deleted.</p>

            <br />
            <p><b>B31.How to reset Test Result values of Equipment ? </b></p>

            <p>[Assumption: Required Inspection Task is in 'Active' status]</p>
            <ol className="font-14">
                <li>1. Select <span className="doc-subtitle">Inspection: Inspection Tasks </span> menu.</li>
                <li>2. Select the required Customer and Contract from its corresponding Dropdown displayed in the Inspection Task page. [Assumption: Active Inspection Task exists for the selected Contract.]</li>
                <li>
                    3. Select the required Inspection Task and click<span className="doc-subtitle"> Inspection Details </span> 
                    <Button className="doc-button-icon">
                        <BsGridFill className="icon" title="Inspection Details"/>
                    </Button>
                    {" "} icon.
                </li>
                <li>4. A window will be displayed  with Contract, System, Questions, Equipment and Summary buttons.</li>
                <li>5. Select <span className="doc-subtitle">Equipment </span>button, All the added equipment’s will be displayed in the grid.</li>
                <li>6. On selecting the required Equipment Group and Equipment Type from the drop down, the corresponding details will be displayed in the grid.</li>
                <li>7. Select <span className="doc-subtitle">'All' </span>option from Equipment Type drop down and select the required Equipment from the data grid.</li>
                <li>8. Click Equipment: Reset option and a message “Are you sure you want to reset Equipment Test Result values?” will be displayed with Yes or No buttons.</li>
                <li>9. Click 'Yes' to the message and the Equipment Test Result values of the selected Equipment will reset.</li>
            </ol>

            <br />
            <p><b>B32.How to edit the data of Equipment? </b></p>

            <p>[Assumption: Required Inspection Task is in 'Active' status]</p>
            <ol className="font-14">
                <li>1. Select<span className="doc-subtitle"> Inspection: Inspection Tasks </span> menu.</li>
                <li>2. Select the required Customer and Contract from its corresponding Dropdown displayed in the Inspection Task page. [Assumption: Active Inspection Task exists for the selected Contract.]</li>
                <li>
                    3. Select the required Inspection Task and click<span className="doc-subtitle"> Inspection Details </span> 
                    <Button className="doc-button-icon">
                        <BsGridFill className="icon" title="Inspection Details"/>
                    </Button>
                    {" "} icon.
                </li>
                <li>4. A window will be displayed  with Contract, System, Questions, Equipment and Summary buttons.</li>
                <li>5. Select <span className="doc-subtitle">Equipment </span>button, All the added equipment’s will be displayed in the grid.</li>
                <li>6. On selecting the required Equipment Group and Equipment Type from the drop down, the corresponding details will be displayed in the grid.</li>
                <li>7. Select the required Equipment from the data grid and click and click <span className="doc-subtitle">Edit{" "}
                    <Button className="doc-button-icon">
                        <BsFillPencilFill className="icon" title="Edit" />
                    </Button> {' '}  </span> icon </li>
                <li>8. The Edit Equipment Data window will be displayed with <span className="doc-subtitle"> Save Changes </span> and <span className="doc-subtitle"> Cancel </span> buttons. The details of the selected Equipment will be loaded in its corresponding fields.</li>
                <li>9. Update the required fields and click <span className="doc-subtitle"> Save Changes </span> button. </li>
                <li>10. The Equipment Data will be deleted.</li>
            </ol>

            <br />
            <p><b>B33.How to edit the address of Equipment? </b></p>

            <p>[Assumption: Required Inspection Task is in 'Active' status]</p>
            <ol className="font-14">
                <li>
                    1. Select <span className="doc-subtitle"> Inspection: Inspection Tasks </span> menu.
                </li>
                <li>2. Select the required Customer and Contract from its corresponding Dropdown displayed in the Inspection Task page. [Assumption: Active Inspection Task exists for the selected Contract.]</li>
                <li>
                    3. Select the required Inspection Task and click<span className="doc-subtitle"> Inspection Details </span> 
                    <Button className="doc-button-icon">
                        <BsGridFill className="icon" title="Inspection Details"/>
                    </Button>
                    {" "} icon.
                </li>
                <li>4. A window will be displayed  with Contract, System, Questions, Equipment and Summary buttons.</li>
                <li>5. Select<span className="doc-subtitle"> Equipment </span>button, All the added equipment’s will be displayed in the grid.</li>
                <li>6. On selecting the required Equipment Group and Equipment Type from the drop down, the corresponding details will be displayed in the grid.</li>
                <li>7. Select any value except 'All' from Equipment Type drop down.</li>
                <li>8. Select the manually added or imported Equipment from the data grid and click <span className="doc-subtitle"> Edit </span>{" "}
                    <Button className="doc-button-icon">
                        <BsFillPencilFill className="icon" title="Edit" />
                    </Button> {' '} icon.</li>
                <li>9. The Edit Equipment Data window will be displayed with <span className="doc-subtitle" >Save Changes </span> and <span className="doc-subtitle"> Cancel </span> buttons. </li>
                <li>10. The details of the selected Equipment will be loaded in its corresponding fields.</li>
                <li>11. Edit the Address field, click the <span className="doc-subtitle"> Save Changes </span> button. </li>
                <li>12. The Address will be updated.</li>
            </ol>
            <p>Note: Selected Equipment should be manually added or imported through Import from Excel option. Address of the Equipment loaded through TAP Bridge cannot be edited.</p>

            <br />
            <p><b>B34.How to edit details of Multiple Equipment? </b></p>

            <p>[Assumption: Required Inspection Task is in 'Active' status]</p>
            <ol className="font-14">
                <li>1. Select<span className="doc-subtitle"> Inspection: Inspection Tasks</span> menu.</li>
                <li>2. Select the required Customer and Contract from its corresponding Dropdown displayed in the Inspection Task page. [Assumption: Active Inspection Task exists for the selected Contract.]</li>
                <li>
                    3. Select the required Inspection Task and click<span className="doc-subtitle"> Inspection Details </span> 
                    <Button className="doc-button-icon">
                        <BsGridFill className="icon" title="Inspection Details"/>
                    </Button>
                    {" "} icon.
                </li>
                <li>4. A window will be displayed  with Contract, System, Questions, Equipment and Summary buttons.</li>
                <li>5. Select <span className="doc-subtitle">Equipment </span>button, All the added equipment’s will be displayed in the grid.</li>
                <li>6. On selecting the required Equipment Group and Equipment Type from the drop down, the corresponding details will be displayed in the grid.</li>
                <li>7. Select any value except 'All' from Equipment Type drop down.</li>
                <li>8. Select the required equipments from the data grid by selecting multiple records using control and shift keys first, then clicking the <span className="doc-subtitle"> Edit  </span>{" "}
                    <Button className="doc-button-icon">
                        <BsFillPencilFill className="icon" title="Edit" />
                    </Button> {' '}  icon.</li>
                <li>9. The Equipment Data window will be displayed with option to select the Field Name and Value to be edited.</li>
                <li>10. Select the Field Name to be modified and enter/select the new value.</li>
                <li>11. Click <span className="doc-subtitle"> Save </span> button.</li>
                <li>12. Selected field value of all the selected equipment will be modified with the new value.</li>
            </ol>

            <br />
            <p><b>B35.How to update Test Status of Equipment or do Manual Inspection? </b></p>

            <p>[Assumption: Required Inspection Task is in 'Active' status]</p>
            <ol className="font-14">
                <li>1. Select <span className="doc-subtitle">Inspection: Inspection Tasks </span>menu.</li>
                <li>2. Select the required Customer and Contract from its corresponding Drop down displayed in the Inspection Task page. [Assumption: Active Inspection Task exists for the selected Contract.]</li>
                <li>
                    3. Select the required Inspection Task and click<span className="doc-subtitle"> Inspection Details </span> 
                    <Button className="doc-button-icon">
                        <BsGridFill className="icon" title="Inspection Details"/>
                    </Button>
                    {" "} icon.
                </li>
                <li>4. A window will be displayed  with Contract, System, Questions, Equipment and Summary buttons.</li>
                <li>5. Select <span className="doc-subtitle">Equipment </span> button, All the added equipment’s will be displayed in the grid.</li>
                <li>6. On selecting the required Equipment Group and Equipment Type from the drop down, the corresponding details will be displayed in the grid.</li>
                <li>7. Select any value except 'All' from Equipment Type drop down.</li>
                <li>8. Click<span className="doc-subtitle"> Edit </span>{" "} <Button className="doc-button-icon">
                    <BsFillPencilFill className="icon" title="Edit" />
                </Button> {' '} icon.</li>
                <li>9. Edit Equipment window will be displayed with Save Changes  and Cancel buttons. The Address of the selected Equipment will be displayed as read only.</li>
                <li>10. Update the Test Method, Test Result and Dev. Reading fields with the required values. If the Update Test Date and Update Dev. Reading Date check boxes are checked, the Test Date and Dev. Reading Date will be updated to the current date by default.</li>
                <li>11. Make the required changes and click <span className="doc-subtitle">Save Changes </span> button.</li>
                <li>12. A confirmation message “Equipment updated” will be displayed.</li>
            </ol>

            <br />
            <p><b>B36.How to upload Attachments to Equipment? </b></p>

            <p>[Assumption: Required Inspection Task is in 'Active' status]</p>
            <ol className="font-14">
                <li>1. Select <span className="doc-subtitle">Inspection: Inspection Tasks </span>menu.</li>
                <li>2. Select the required Customer and Contract from its corresponding Drop down displayed in the Inspection Task page. [Assumption: Active Inspection Task exists for the selected Contract.]</li>
                <li>
                    3. Select the required Inspection Task and click<span className="doc-subtitle"> Inspection Details </span> 
                    <Button className="doc-button-icon">
                        <BsGridFill className="icon" title="Inspection Details"/>
                    </Button>
                    {" "} icon.
                </li>
                <li>4. A window will be displayed  with Contract, System, Questions, Equipment and Summary buttons.</li>
                <li>5. Select <span className="doc-subtitle">Equipment </span>button, All the added equipment’s will be displayed in the grid.</li>
                <li>6. On selecting the required Equipment Group and Equipment Type from the drop down, the corresponding details will be displayed in the grid.</li>
                <li>7. Click Attachments option and the Equipment Attachments window will be displayed with Attachment, Tools and Close menu.</li>
                <li>8. Select <span className="doc-subtitle">Attachment: Add </span>{" "}
                    <Button className="doc-button-icon">
                        <BsPlusLg className="icon" title='Add' />
                    </Button> icon. The Upload Attachment window will be displayed with <span className="doc-subtitle"> Upload </span> and <span className="doc-subtitle"> Cancel </span> buttons.</li>
                <li>9. Select the File Type and select the file through the file open dialog by clicking on the <span className="doc-subtitle">Browse </span>button.</li>
                <li>10. Click<span className="doc-subtitle"> Upload </span>button and the selected file will be uploaded as an attachment to the selected Equipment.</li>
            </ol>

            <br />
            <p><b>B37.How to view tested/untested equipment list? </b></p>

            <p>[Assumption: Required Inspection Task is in 'Active' status]</p>
            <ol className="font-14">
                <li>1. Select<span className="doc-subtitle"> Inspection: Inspection Tasks </span> menu.</li>
                <li>2. Select the required Customer and Contract from its corresponding Drop down displayed in the Inspection Task page. [Assumption: Active Inspection Task exists for the selected Contract.]</li>
                <li>
                    3. Select the required Inspection Task and click<span className="doc-subtitle"> Inspection Details </span> 
                    <Button className="doc-button-icon">
                        <BsGridFill className="icon" title="Inspection Details"/>
                    </Button>
                    {" "} icon.
                </li>
                <li>4. A window will be displayed  with Contract, System, Questions, Equipment and summary buttons.</li>
                <li>5. Select<span className="doc-subtitle"> Equipment</span> button, All the added equipment’s will be displayed in the grid.</li>
                <li>6. On selecting the required Equipment Group and Equipment Type from the drop down, the corresponding details will be displayed in the grid.</li>
                <li>7. Select the <span className="doc-subtitle"> Filter </span>{" "}
                    <Button className="doc-button-icon">
                        <FaFilter className="icon" title="Filter" />
                    </Button> icon. The Filter window will be displayed with Go and Cancel buttons.</li>
                <li>8. Select 'Show only Untested Equipments' check box displayed in the Filter window and click <span className="doc-subtitle"> Go </span> button.</li>
                <li>9. All the Equipment with Test Result status as 'Not Tested' will be listed in the data grid.</li>
            </ol>

            <br />
            <p><b>B38.How to download/Export Equipment Data to an Excel Sheet? </b></p>

            <p>[Assumption: Required Inspection Task is in 'Active' status]</p>
            <ol className="font-14">
                <li>1. Select <span className="doc-subtitle">Inspection: Inspection Tasks</span> menu.</li>
                <li>2. Select the required Customer and Contract from its corresponding Dropdown displayed in the Inspection Task page. [Assumption: Active Inspection Task exists for the selected Contract.]</li>
                <li>
                    3. Select the required Inspection Task and click<span className="doc-subtitle"> Inspection Details </span> 
                    <Button className="doc-button-icon">
                        <BsGridFill className="icon" title="Inspection Details"/>
                    </Button>
                    {" "} icon.
                </li>
                <li>4. A window will be displayed  with Contract, System, Questions, Equipment and Summary buttons.</li>
                <li>5. Select <span className="doc-subtitle">Equipment </span> button, All the added equipment’s will be displayed in the grid.</li>
                <li>6. On selecting the required Equipment Group and Equipment Type from the drop down, the corresponding details will be displayed in the grid.</li>
                <li>7. Click <span className="doc-subtitle"> Export All </span> option from <span className="doc-subtitle"> Download  </span>{" "}
                    <Button className="doc-button-icon">
                        <FaDownload className="icon" title="Download" />
                    </Button>{" "} icon.</li>
                <li>8. View Downloads window will be displayed listing the details of the Downloaded files.</li>
                <li>9. All the Equipment details will be available in an Excel. </li>
            </ol>

            <br />
            <p><b> B39.How to view the Equipment Test Summary of an Inspection Task? </b></p>
            <ol className="font-14">
                <li>1. Go to <span className="doc-subtitle"> Inspection: Inspection Tasks </span>.</li>
                <li>2. Select <span className="doc-subtitle">Equipment Summary </span> option from <span className="doc-subtitle"> More </span>{" "}
                    <Button className="doc-button-icon">
                        <FaCog className="icon" title="More" />
                    </Button>{" "}icon.</li>
                <li>3. A Report page will be displayed showing the Equipment Summary.</li>
            </ol>

            <br />
            <p><b>B40.How to quit Job from Desktop application? </b></p>

            <p>[Assumption: Required Inspection Task is in 'Active' status]</p>
            <ol className="font-14">
                <li>1. Click<span className="doc-subtitle"> Inspection: Inspection Tasks</span> menu.</li>
                <li>2. Select the required Customer and Contract from its corresponding drop down displayed in the Inspection Task page.</li>
                <li>3. Select Quit Task option from <span className="doc-subtitle">More </span>{" "}
                    <Button className="doc-button-icon">
                        <FaCog className="icon" title="More" />
                    </Button>{" "} icon.</li>
                <li>4. Quit Job window will be displayed listing all the Technicians who have joined the Inspection Task with check box against each Technician with Quit Task and Cancel buttons.</li>
                <li>5. Select the required Technician and click<span className="doc-subtitle"> Quit Task </span> button.</li>
                <li>6. The selected Technician will be quit from the Job which is started in the Inspection App.</li>
            </ol>

            <br />
            <p><b>B41.How to open a Drawing in Tech Advance+ ?</b></p>

            <p>Pre-requisite: The concerned System should have an active Inspection Task.</p>
            <ol className="font-14">
                <li>1. Click<span className="doc-subtitle"> Inspection: Inspection Tasks </span>menu.</li>
                <li>2. Select the required Customer and Contract from its corresponding drop down displayed in the Inspection Task page.</li>
                <li>3. Select <span className="doc-subtitle">Drawings </span> menu.</li>
                <li>4. Drawing List page will be displayed listing all the Drawings uploaded for the selected active Inspection Task with Open Drawing and Close button.</li>
                <li>5. Select the required Drawing and click Open Drawing button to view the Drawing.</li>
            </ol>

            <br />
            <p><b>B42.How  to Generate Reports? </b></p>

            <p>Generate Reports option is used to generate Inspection Reports for the selected System/Equipment Group/Building.</p>

            <p>The steps to be followed to generate reports are as follows:</p>
            <ol className="font-14">
                <li>1. Select <span className="doc-subtitle">Generate Reports</span> tab from <span className="doc-subtitle"> Inspection: Inspection Reports </span>.</li>
                <li>2. Select the System from the corresponding drop down in Generate Reports page.</li>
                <li>3. Select the Panel(s) and Reports to be generated.</li>
                <li>4. Make sure the Start Date and End Date displayed by default will be the required Inspection Start and End Date.</li>
                <li>5. Click <span className="doc-subtitle"> Generate </span> button.</li>
                <li>6. Generated Reports will be available in the <span className="doc-subtitle"> Reports </span> tab in <span className="doc-subtitle">Inspection: Inspection Reports </span>.</li>
                <li>7. Select the required System from the corresponding drop down and select the required report for the data grid.</li>
                <li>8. Click <span className="doc-subtitle"> Download Draft Report </span> button and the associated Generated Report will be downloaded to the user's computer.</li>
            </ol>


            <br />
            <p><b>B43.How  to Upload Final Reports? </b></p>

            <p> Assumption: All required Reports are already generated through <span className="doc-subtitle">Inspection: Inspection Reports: Generate Reports </span>.</p>

            <p>The steps to be followed to upload Final reports are as follows:</p>
            <ol className="font-14">
                <li>1. Go to <span className="doc-subtitle"> Reports </span> tab in <span className="doc-subtitle"> Inspection: Inspection Reports </span>.</li>
                <li>2. Select the required System from the corresponding drop down and select the required report for the data grid.</li>
                <li>
                    3. Click <span className="doc-subtitle">Upload Final Reports</span>
                    <Button className="doc-button-icon">
                        <BsPaperclip className="icon" title="Upload" />
                    </Button>{" "}
                    icon.
                </li>
                <li>4. Upload Final Report page will be displayed with option to select the File, enter Description and with Upload and Cancel buttons</li>
                <li>5. Click on the Attachment field and drag the required File to the Field. Then click Upload button.</li>
                <li>6. Final Report will be uploaded against the selected Report</li>
            </ol>

            <br />
            <h4>C Drawing View and Equipment Update from Drawing</h4>
            <br />
            <p><b>C1.How to view the assigned/linked Equipment in a Drawing? </b></p>

            <p>[Assumption: The selected Inspection Task is in Active status]</p>
            <ol className="font-14">
                <li>1. Select <span className="doc-subtitle"> Inspection: Inspection Tasks: Drawings </span> menu.</li>
                <li>2. Drawing List page will be displayed listing all the Drawings uploaded for the selected active Inspection Task with Open Drawing and Close button.</li>
                <li>3. Select the required Drawing and click on <span className="doc-subtitle"> Open Drawing </span> button to view the Drawing.</li>
                <li>4. Select the required Equipment from the Data grid in the Drawings page.[Assumption: Equipment already added]</li>
                <li>
                    5. Click Show <span className="doc-subtitle"> Drawing </span>
                    <img alt="Show Drawing" src="../../Images/TapV6/Show Drawing_data_button.png" /> option.
                </li>
                <li>6. The Equipment will be selected and will be displayed as hatched in the drawing.</li>
            </ol>

            <br />
            <p><b>C2.How to assign/link unassigned Equipment to the Drawing? </b></p>

            <p>[Assumption: The selected Inspection Task is in Active status]</p>
            <ol className="font-14">
                <li>1. Select<span className="doc-subtitle"> Inspection: Inspection Tasks: Drawings </span>menu.</li>
                <li>2. Drawing List page will be displayed listing all the Drawings uploaded for the selected active Inspection Task with Open Drawing and Close button.</li>
                <li>3. Select the required Drawing and click on <span className="doc-subtitle">Open Drawing</span> button to view the Drawing.</li>
                <li>4. Select 'Unassigned' tab from the Data grid in the Drawings page.</li>
                <li>5. All the unassigned Equipment will be listed in the data grid.</li>
                <li>6. Select the required unassigned Equipment from the data grid.</li>
                <li>7. Click<span className="doc-subtitle"> Equipment: Assign Block  </span><img alt="Assign Block" src="../../Images/TapV6/Show Block_button.png" /> option if it is a symbol based component.</li>
                <li>
                    8. The Equipment will be selected and "Select the point where the "Equipment (Address) is to be placed" message will be displayed with OK button.</li>
                <li>9. Click OK button and select the point where the Equipment is to be placed.</li>
                <li>10. The Equipment will be placed there and a confirmation message, " 'Equipment (Address)'placed" will be displayed.</li>
                <li>11. Click <span className="doc-subtitle">Associate Block  </span><img alt="Assign Block" src="../../Images/TapV6/Show Block_button.png" />option if the equipment is a block based equipment.</li>
                <li>12. Select the Block to be linked as per address of the Equipment displayed in the message.</li>
                <li>13. The Equipment will be associated to the selected block.</li>
            </ol>


            <br />
            <p><b>C3.How to edit the data of Equipment from Drawing? </b></p>

            <p>[Assumption: The selected Inspection Task is in Active status]</p>
            <ol className="font-14">
                <li>1. Select <span className="doc-subtitle">Inspection: Inspection Tasks: Drawings </span>menu.</li>
                <li>2. Drawing List page will be displayed listing all the Drawings uploaded for the selected active Inspection Task with Open Drawing and Close button.</li>
                <li>3. Select the required Drawing and click on <span className="doc-subtitle">Open Drawing </span> button to view the Drawing.</li>
                <li>4. Click <span className="doc-subtitle">Equipment: Edit </span>{" "}
                    <Button className="doc-button-icon">
                        <BsFillPencilFill className="icon" title="Edit" />
                    </Button> {' '} icon from the Data grid.</li>
                <li>5. The Equipment Data window will be displayed with <span className="doc-subtitle"> Save Changes </span> and <span className="doc-subtitle"> Cancel </span> buttons. The details of the selected Equipment will be loaded in its corresponding fields.</li>
                <li>6. Click<span className="doc-subtitle"> Save Changes </span> button. The modified changes will be updated.</li>
            </ol>


            <br />
            <p><b>C4.How to update Test Status of Equipment from Drawing? </b></p>

            <p>[Assumption: The selected Inspection Task is in Active status]</p>
            <ol className="font-14">
                <li>1. Select <span className="doc-subtitle">Inspection: Inspection Tasks: Drawings </span> menu.</li>
                <li>2. Drawing List page will be displayed listing all the Drawings uploaded for the selected active Inspection Task with Open Drawing and Close button.</li>
                <li>3. Select the required Drawing and click on <span className="doc-subtitle"> Open Drawing </span> button to view the Drawing.</li>
                <li>4. Select the required Equipment from the data grid.</li>
                <li>5. Click <span className="doc-subtitle">Equipment: Edit  </span>{" "}
                    <Button className="doc-button-icon">
                        <BsFillPencilFill className="icon" title="Edit" />
                    </Button> {' '} icon from the Data grid.</li>
                <li>6. Update Status window will be displayed with<span className="doc-subtitle"> Save Changes </span> and <span className="doc-subtitle"> Cancel </span> buttons. The Address of the selected Equipment will be displayed as read only.</li>
                <li>7. Update the Test Method, Test Result and Dev. Reading fields with the required values. If the Update Test Date and Update Dev. Reading Date check boxes are checked, the Test Date and Dev. Reading Date will be updated to the current date.</li>
                <li>8. Enter the required changes and click <span className="doc-subtitle"> Save Changes </span> button.</li>
                <li>9. A confirmation message "Equipment updated" will be displayed.</li>
            </ol>

            <br />
            <h4>D TAP Bridge</h4>

            <br />
            <p><b>D1.How to download and install TAP Bridge ? </b></p>
            <ol className="font-14">
                <li>1. Select the option<span className="doc-subtitle"> Inspection: Plugins: TAP Bridge: Download.</span></li>
                <li>2. The latest TAP Bridge file uploaded by the Super Administrator through <span className="doc-subtitle"> Plugins: TAP Bridge  &gt; Upload </span> will be downloaded to the user's computer as a ZIP file.</li>
                <li>3. Click the <span className="doc-subtitle">Save </span>option to Save the TAP Bridge in the required location.</li>
                <li>4. Extract the ZIP file contents to the appropriate location. Double click the<span className="doc-subtitle"> TAP Bridge </span> icon from the saved location.</li>
                <li>5. Follow the instruction and install it by clicking the<span className="doc-subtitle"> Install </span> button.</li>
            </ol>

            <br />
            <p><b>D2.How to update a new version of TAP Bridge? </b></p>
            <ol className="font-14">
                <li>1. Select the option <span className="doc-subtitle">Inspection: Plugins: TAP Bridge: Download.</span></li>
                <li>2. The latest TAP Bridge file uploaded by the Super Administrator through <span className="doc-subtitle"> Plugins: TAP Bridge &gt; Upload </span> will be downloaded to the user's computer as a ZIP file.</li>
                <li>3. Click the <span className="doc-subtitle"> Save </span> option to Save the TAP Bridge in the required location.</li>
                <li>4. Extract the ZIP file contents to the appropriate location. Double click the <span className="doc-subtitle"> TAP Bridge</span> icon from the saved location.</li>
                <li>5. Follow the instruction and install it by clicking the <span className="doc-subtitle">Install</span> button.</li>
            </ol>

            <br />
            <p><b>D3.What are the pre-requisites to run TAP Bridge? </b></p>
            <ol className="font-14">
                <p>Three different modes are available in the TAP Bridge for three different functionalities namely Inspection, Report Generation and Trouble shooting.</p>

                <p>Following are to be ensured, before running the TAP Bridge:</p>

                <li>1. Latest <span className="doc-subtitle">TAP Bridge </span>is installed in the computer.</li>
                <li>2. Active Inspection Tasks for MXL or XLS Systems should be created and assigned to corresponding Lead Technicians.</li>
            </ol>

            <br />
            <p><b>D4.How to run TAP Bridge in Inspection mode? </b></p>

            <ol className="font-14">
                <p>Steps to run TAP Bridge in Inspection mode are as follows:</p>

                <li>1. Double click on the<span className="doc-subtitle"> TAP Bridge </span> icon in the Technician's Laptop.</li>
                <li>2. TAP Bridge window will be displayed with option to enter the User name and Password and with Internet Connection button. Different modes are provided as radio buttons, where Inspection Mode is selected by default. [If not , select the Inspection Mode radio button]</li>
                <li>3. Enter User Name and Password and click Internet Connection button. (User should be a Lead Technician)</li>
                <li>4. If more than one Active Inspection Tasks are assigned to the user (as Lead Technician), Select Inspection window will be displayed listing all the active Inspection Tasks assigned to the user, with OK button.</li>
                <li>5. Select the required Inspection Task and click OK button. The Start button will be enabled.</li>

                <p>Note: If only one active Inspection Task is assigned to the user (as Lead Technician), no window will be popped up. Instead, the Start button will be enabled directly by selecting the Active task by default.</p>

                <li>6. Click <span className="doc-subtitle">Start </span> button.</li>
                <li>7. After establishing the connection with TechAdvance<sup>+</sup>, Equipment List will be sent to Tech Advance<sup>+</sup> Equipment list will be later downloaded to the mobile device for Inspection. Printer logs and Event logs will be sent to the Panel Control in the mobile device.</li>
                <li>8. Equipment status is updated automatically if it is in online mode or by manually updating the Equipment from the Inspection App if it is in offline mode.</li>
            </ol>
            <br />
            <p><b>D5.How to run TAP Bridge in Troubleshooting mode? </b></p>

            <ol className="font-14">
                <p>Steps to run TAP Bridge in Troubleshooting mode are as follows:</p>

                <li>1. Double click on the <span className="doc-subtitle">TAP Bridge </span>icon in the Technician's Laptop.</li>
                <li>2. TAP Bridge window will be displayed with option to enter the User name and Password and with Internet Connection button. Different modes are provided as radio buttons, where Inspection Mode is selected by default. A Panel Type drop down is also provided in the window.</li>
                <li>3. The Technician should enter his User Name and Password, select the Trouble shooting Radio button and click Internet Connection button.</li>
                <li>4. Once the session is initiated, Start button will be enabled.</li>
                <li>5. Choose a Panel Type (MXL or XLS) and click <span className="doc-subtitle">Start</span> button.</li>
                <li>6. After establishing the connection, another Technician can login to the mobile device in Troubleshooting mode. The name of the Technician who is connected to the Panel through TAP Bridge should be selected to identify the Panel to be diagnosed. Printer logs will be sent to the Panel Control in the mobile device. Only Panel Control page with corresponding key pad will be displayed in the Inspection App.</li>
            </ol>

        </>
    );
}

export default InspectionFAQDocument;