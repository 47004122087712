import { Button } from "primereact/button";
import React from "react";

import { BsFillPencilFill, BsPaperclip, BsPlusLg, BsTrashFill } from "react-icons/bs";
import { FaCog, FaDownload } from "react-icons/fa";

const AdministrationBuildingsDocument = () => {
    return (
        <>
            <h4 className="doc-title mb-2">Buildings</h4>

            <p>
                This option allows Super Administrators, Support, Admin, Admin-Tech,
                Technicians and Branch users to add/edit/delete Buildings. Attachments
                can be assigned to the selected Buildings by using this option.
            </p>

            <p>
                All buildings will be listed with their Site Number, Building Name,
                building details, attachments common area etc. Users can add/edit/delete Buildings.
            </p>

            <p>
                <b><span className="doc-subtitle">Add:</span></b>
            </p>

            <p>
                Buildings can be created by clicking the
                <span className="doc-subtitle"> Add </span>
                <Button className="doc-button-icon"> <BsPlusLg className="icon" title="Add" /> </Button>{" "}
                icon. Select the Site from the list to which the building is to be added.
                Enter Site Number, Building Name, Building Code, Status and select Building
                Photo if required. Click on Save button to add a new building.
            </p>

            <p>
                <b><span className="doc-subtitle">Edit: </span></b>
            </p>

            <p>
                The selected Building details can be edited by clicking on the
                <span className="doc-subtitle"> Edit </span>

                <Button className="doc-button-icon"> <BsFillPencilFill className="icon" title="Edit" /> </Button> {" "}
                icon.
            </p>

            <p>
                <b> <span className="doc-subtitle">Inline Edit: </span></b>
            </p>

            <p>
                Inline edit option enables user to edit a Building by clicking the
                <span className="doc-subtitle"> Edit </span>

                <Button className="doc-button-icon">
                    <i className="pi pi-pencil icon" title="Inline Edit" />
                </Button>{" "}

                icon in the left hand side of the required row. On clicking
                <span className="doc-subtitle"> Edit </span>

                <Button className="doc-button-icon">
                    <i className="pi pi-pencil icon" title="Inline Edit" />
                </Button>{" "}

                icon, the selected row will be displayed as editable and Edit icon will be replaced with
                <span className="doc-subtitle"> Save </span>

                <Button className="doc-button-icon">
                    <i className="pi pi-save icon" title="Save" />
                </Button>{" "}
                and <span className="doc-subtitle"> Cancel </span>

                <Button className="doc-button-icon">
                    <span className="pi pi-times icon " title="Cancel"></span>
                </Button> {" "}

                icon. Do the necessary changes in the required fields and click on
                <span className="doc-subtitle"> Save </span>

                <Button className="doc-button-icon">
                    <i className="pi pi-save icon" title="Save" />
                </Button>{" "}

                icon. To cancel the Inline edit operation click on
                <span className="doc-subtitle"> Cancel </span>

                <Button className="doc-button-icon">
                    <span className="pi pi-times icon" title="Cancel"></span>
                </Button> {" "}icon.

            </p>



            <p>
                <b><span className="doc-subtitle">Delete:</span></b>
            </p>

            <p>
                A Building can be deleted by selecting it and then clicking on the
                <span className="doc-subtitle"> Delete </span>

                <Button className="doc-button-icon">
                    <BsTrashFill className="icon" title="Delete" />
                </Button>{" "}

                icon. It is not possible to delete a building having floors added to it.
            </p>


            <p>
                <b><span className="doc-subtitle">Attachments:</span></b>
            </p>


            <p>
                Super Administrators, Support, Admin, Admin-Tech, Technicians and
                Branch users can add/edit/delete/view/download attachments to a
                building using this option. Select the required Building and click the
                Attachments menu. The Attachment List and Upload options open up in the
                drop down menu. The files that can be attached will be restricted to data
                files like Word, Excel, pdf, jpg, gif, bmp etc.
            </p>

            <p><b>Attachments: List</b></p>

            <p>
                The File attachments window opens up on selecting <span className="doc-subtitle"> List </span> option from
                <span className="doc-subtitle"> Attachments </span>

                <Button className="doc-button-icon">
                    <BsPaperclip className="icon" title="Attachments" />
                </Button>{" "}

                icon,  showing any files that are already attached to this Building.
                Attachments can now be added to the selected Building.
            </p>

            <p>
                <span className="doc-subtitle">Add:</span>
            </p>

            <p>
                An attachment can be added by clicking the
                <span className="doc-subtitle"> Add </span>

                <Button className="doc-button-icon">
                    <BsPlusLg className="icon" title="Add" />
                </Button>{" "}

                icon. Browse for the required file. The attachment will be uploaded on
                selecting Upload button.Upload Attachment page will be displayed on
                clicking Add icon. Then Open page to select the File will be displayed on
                clicking the Attachment field. Then browse for the required file. User can
                either select the File and click Open button or drag and drop the file to the
                Attachment Field. Enter the description. The attachment will be uploaded on selecting Upload button.
            </p>

            <p>
                <span className="doc-subtitle">Edit:</span>
            </p>

            <p>
                Edit an Attachment by selecting it and clicking the
                <span className="doc-subtitle"> Edit </span>

                <Button className="doc-button-icon">
                    <BsFillPencilFill className="icon" title="Edit" />
                </Button> {" "}
                icon.
            </p>

            <p> Select an attachment and click
                <span className="doc-subtitle"> Edit </span>

                <Button className="doc-button-icon">
                    <BsFillPencilFill className="icon" title="Edit" />
                </Button> {" "}
                icon.
                The Edit Attachment window will be displayed with the current file
                name. The user can replace the selected attachment with a new file if
                necessary or just update the Description. If the User needs to replace
                the File, choose Yes to the confirmation and select the file by clicking
                inside the Attachment field and then dragging and dropping the required
                file to the Attachment field or select the file and click Open button.
            </p>

            <p> If the user does not need to replace the already uploaded File, choose
                No to the confirmation question and modify the description and click Upload button
            </p>

            <p>
                <span className="doc-subtitle">Delete:</span>
            </p>

            <p>
                Delete an attachment by selecting the required file and clicking the
                <span className="doc-subtitle"> Delete </span>

                <Button className="doc-button-icon">
                    <BsTrashFill className="icon" title="Delete" />
                </Button>{" "}

                icon.
            </p>

            <p>
                <span className="doc-subtitle">View:</span></p>
            <p>
                The attachment can be viewed by selecting the file and clicking the
                <span className="doc-subtitle"> View </span>option from
                <span className="doc-subtitle"> More </span>


                <Button className="doc-button-icon">
                    <FaCog className="icon" title="More" />
                </Button>{" "} menu.
            </p>

            <p>
                <span className="doc-subtitle">Download:</span>
            </p>

            <p>
                Attachment can be downloaded and saved in the user's computer through
                <span className="doc-subtitle"> Download </span>
                option from <span className="doc-subtitle"> Download </span>


                <Button className="doc-button-icon">{" "}
                    <FaDownload className="icon" title="Download" />
                </Button>{" "}
                menu.
            </p>


            <p><b>Attachments: Upload</b></p>

            <p>
                Attachments can be added to Buildings (single/multiple) without going to the Attachments
                window, by clicking <span className="doc-subtitle"> Upload </span> option from
                <span className="doc-subtitle"> Attachments </span>

                <Button className="doc-button-icon">
                    <BsPaperclip className="icon" title="Attachments" />
                </Button>{" "}

                icon. Select the required Buildings and Click<span className="doc-subtitle"> Upload </span>
                option from <span className="doc-subtitle"> Attachments </span>

                <Button className="doc-button-icon">
                    <BsPaperclip className="icon" title="Attachments" />
                </Button>{" "}

                icon, Upload Attachment window will be displayed. Select icking the Browse button.
                Then Open page to select the File will be displayed on clicking the Attachment field.
                Then browse for the required file. User can either select the File and click Open button
                or drag and drop the file to the Attachment Field. Enter the description.
                The attachment will be uploaded on selecting Upload button.
            </p>

            <p>
                <b><span className="doc-subtitle"> Download </span></b>

                <Button className="doc-button-icon">{" "}
                    <FaDownload className="icon" title="Download" />
                </Button>

            </p>

            <p>
                This option lists Report All, Report Highlighted, Export All, Export Highlighted and Group Highlighted.
            </p>

            <p>
                <span className="doc-subtitle">Report All:</span>
            </p>

            <p>
                This option is for generating a report with all the records in the data grid.
                The report can be saved to PDF, Text, Rich Text or Excel format by selecting the
                file format from the list under Save option, and then clicking OK button after selecting
                the required options from the Export Settings window.
            </p>

            <p>
                <span className="doc-subtitle">Report Highlighted: </span>
            </p>

            <p>
                This option is for generating a report with only selected records in the grid.
                Select the desired rows by holding down the control key and clicking on
                each of the records to be selected. Users can also select a set of rows using
                the shift key. The selected records will be highlighted. Click on the
                <span className="doc-subtitle"> Report Highlighted </span>option in the
                <span className="doc-subtitle"> Download </span>

                <Button className="doc-button-icon">
                    <FaDownload className="icon" title="Download" />
                </Button>{" "}

                menu to generate the report. The report will be created with the highlighted records only.
                The report can be saved to PDF, Text, Rich Text or Excel format by selecting the file format
                from the list under Save option, and then clicking OK button after selecting the required options
                from the Export Settings window.
            </p>

            <p>
                <b><span className="doc-subtitle">More </span></b>
                <Button className="doc-button-icon">
                    <FaCog className="icon" title="More" />
                </Button>{" "}
            </p>

            <p>
                This option list Floors and Display Settings option.
            </p>

            <p>
                <span className="doc-subtitle">Floors</span></p>
            <p>
                Floors can be added, edited or deleted by selecting the required building
                and clicking the <span className="doc-subtitle"> Floors </span> option from
                <span className="doc-subtitle"> More </span>

                <Button className="doc-button-icon">
                    <FaCog className="icon" title="More" />
                </Button>{" "}

                menu. See
                <span className="doc-subtitle"> Floors </span>
                for more details
            </p>

            <p>
                <span className="doc-subtitle">Display Settings</span>
            </p>
            <p>
                This option allows the user to change the visibility of the data fields displayed
                in the data grid. On selecting this option, all the available data fields are
                displayed in a window. Display settings can be modified by selecting the
                required fields from the list. The grid settings modified through this option
                will not be saved and will be effective only for the current instance of the data grid.
            </p>
        </>
    );
}

export default AdministrationBuildingsDocument;