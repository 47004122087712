import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';


const statusList = [
    { label: 'Active', value: 'Active' },
    { label: 'Blocked', value: 'Blocked' },
    { label: 'Deleted', value: 'Deleted' },
    { label: 'Not Activated', value: 'Not Activated' }
];

const NetworkList = [
    { label: 'True', value: 'True' },
    { label: 'False', value: 'False' }
];

const languageList = [
    { label: 'Base', value: 'Base' },
    { label: 'Alternate', value: 'Alternate' }
];


const networkEditor = (options) => {
    return (
        <Dropdown value={options.value} options={NetworkList} optionLabel="label" optionValue="value"
            itemTemplate={(option) => {
                return <span className='status-badge'>{option.label}</span>
            }} />
    );
}

const statusEditor = (options) => {
    return (
        <Dropdown value={options.value} options={statusList} disabled optionLabel="label" optionValue="value"
            placeholder="Select Status"
            itemTemplate={(option) => {
                return <span className='status-badge'>{option.label}</span>
            }} />
    );
}

const languageEditor = (options) => {
    return (
        <Dropdown value={options.value} options={languageList} optionLabel="label" optionValue="value"
            itemTemplate={(option) => {
                return <span className='status-badge'>{option.label}</span>
            }} />
    );
}

const textEditor = (options) => {
    return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
}
const disableEditor = (options) => {
    return <InputText type="text" value={options.value} disabled onChange={(e) => options.editorCallback(e.target.value)} />;
}

export const REPORTS_ROWS = [
    {
        Name: 'Systems',
        SystemType: 'MXL',
        IsNetworked: 'True',
        GlobalPMI: '2+Global PMI or 1+NC',
        PreferredLanguage: 'Base',
        Status: 'Active',
        XMLFile: 'Yes',
        ConfigFile: 'No',
        ActiveTask: 'Task 106',
    },
    {
        Name: 'Systems',
        SystemType: 'XLS',
        IsNetworked: 'False',
        GlobalPMI: '2+Global PMI or 1+NC',
        PreferredLanguage: 'Base',
        Status: 'Active',
        XMLFile: 'Yes',
        ConfigFile: 'No',
        ActiveTask: 'Task 106',
    },
    {
        Name: 'Systems',
        SystemType: 'XLS',
        IsNetworked: 'True',
        GlobalPMI: '2+Global PMI or 1+NC',
        PreferredLanguage: 'Base',
        Status: 'Active',
        XMLFile: 'Yes',
        ConfigFile: 'No',
        ActiveTask: 'Task 106',
    },
    {
        Name: 'Systems',
        SystemType: 'Desigo Modular',
        IsNetworked: 'False',
        GlobalPMI: '2+Global PMI or 1+NC',
        PreferredLanguage: 'Base',
        Status: 'Active',
        XMLFile: 'Yes',
        ConfigFile: 'No',
        ActiveTask: 'Task 106',
    },

];

export const REPORTS_COLS = [
    {
        field: 'template',
        header: 'Template',
        editor: (options) => textEditor(options),
        style: { flex: '1 0 150px' },
        sortable: true,
    },
    {
        field: 'reportBy',
        header: 'Report by',
        editor: (options) => disableEditor(options),
        style: { flex: '1 0 100px' },
        sortable: true,
    },
    {
        field: 'groups',
        header: 'Groups',
        editor: (options) => networkEditor(options),
        style: { flex: '1 0 100px' },
        sortable: true,
    },
    {
        field: 'customer',
        header: 'Customer',
        editor: (options) => disableEditor(options),
        style: { flex: '1 0 250px' },
        sortable: true,
    },
    {
        field: 'contract',
        header: ' Contract',
        editor: (options) => languageEditor(options),
        style: { flex: '1 0 300px' },
        sortable: true,
    },
    {
        field: 'buildingNames',
        header: 'Buildings Name(s)',
        editor: (options) => statusEditor(options),
        style: { flex: '1 0 110px' },
        sortable: true,
    },
    {
        field: 'dateAndTimeCreated',
        header: 'Date and Time created',
        editor: (options) => disableEditor(options),
        style: { flex: '1 0 150px' },
        sortable: true,
    },
    {
        field: 'createdBy',
        header: 'Created by',
        editor: (options) => disableEditor(options),
        style: { flex: '1 0 150px' },
        sortable: true,
    },

    {
        field: 'dateAndTimeUploaded',
        header: 'Date and Time uploaded',
        editor: (options) => disableEditor(options),
        style: { flex: '1 0 160px', color: '#443DF6' },
        sortable: true,
        headerClassName: 'date-time'

    },
    {
        field: 'daysToUpload',
        header: 'Days to upload',
        editor: (options) => disableEditor(options),
        style: { flex: '1 0 120px', display: 'flex', justifyContent: 'end' },
        sortable: true,

    },
    {
        field: 'addedBy',
        header: 'Added by',
        editor: (options) => disableEditor(options),
        style: { flex: '1 0 150px', color: '#443DF6' },
        sortable: true,
        headerClassName: 'date-time'

    },
    {
        field: 'sendToServicePortal',
        header: 'Sent to Service Portal',
        editor: (options) => disableEditor(options),
        style: { flex: '1 0 150px' },
        sortable: true,

    },

];