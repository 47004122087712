import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AppThunk } from "redux/store";
import { baseURL, zlink } from "service/HttpService";

export interface BuildingState {
    errors: any;
    buildingList: any;
    buildingPageDetails: any;
    newBuilding: any;
    buildngDetails: any;
    updatedBuildingData: any;
    buildingInUse: any;
    deletedBuilding: any;
    attachmentList: any;
	attachmentPageDetails:any;
	buildingAttachmentCountList:any;
}

let initialState: BuildingState = {
    errors: "",
    buildingList: "",
    buildingPageDetails: "",
    newBuilding: "",
    buildngDetails: "",
    updatedBuildingData: "",
    buildingInUse: "",
    deletedBuilding: "",
    attachmentList: [],
	attachmentPageDetails: [],
	buildingAttachmentCountList:[]
}

const BuildingSLice = createSlice({
    name: "building",
    initialState,
    reducers: {
        setErrors: (state, { payload }: PayloadAction<any>) => {
            state.errors = payload;
        },
        setBuildingList: (state, { payload }: PayloadAction<any>) => {
			state.buildingList = payload;
		},
        setBuildinPageDetails: (state, { payload }: PayloadAction<any>) => {
			state.buildingPageDetails = payload;
		},
        setNewBuilding: (state, { payload }: PayloadAction<any>) => {
			state.newBuilding = payload;
		},
        setBuildngDetails: (state, { payload }: PayloadAction<any>) => {
			state.buildngDetails = payload;
		},
        setUpdatedBuildingData: (state, { payload }: PayloadAction<any>) => {
			state.updatedBuildingData = payload;
		},
        setBuildingInUse: (state, { payload }: PayloadAction<any>) => {
			state.buildingInUse = payload;
		},
        setDeletedBuilding: (state, { payload }: PayloadAction<any>) => {
			state.buildingList = state.buildingList.filter((item: any) => item.uniqueBuildingId !== payload);
		},
        setAttachmentList: (state, {payload}: PayloadAction<any>) => {
			state.attachmentList = payload;
		},
		setAttachmentPageDetails: (state, {payload}: PayloadAction<any>) => {
			state.attachmentPageDetails = payload;
		},
		setNewAttachmentData: (state, { payload }: PayloadAction<any>) => {
			state.attachmentList.unshift(...payload);
		},
		setUpdatedAttachement: (state, { payload }: PayloadAction<any>) => {
			let currentIndex = state.attachmentList.findIndex((item: any) => item.attachmentId === payload?.attachmentId);
			state.attachmentList = state.attachmentList.filter((item: any) => item.attachmentId !== payload?.attachmentId);
			state.attachmentList.splice(currentIndex, 0, payload);
		},
		setDeletedAttachmentList(state, { payload }: PayloadAction<any>) {
			state.attachmentList = state.attachmentList.filter((item: any) => item.attachmentId !== payload);
		},
		setBuildingAttachmentCountList(state, { payload }: PayloadAction<any>) {
			let currentIndex = state.buildingList.findIndex((item: any) => item.uniqueBuildingId === payload?.uniqueBuildingId);
			state.buildingList = state.buildingList.filter((item: any) => item.uniqueBuildingId !== payload?.uniqueBuildingId);
			state.buildingList.splice(currentIndex, 0, payload);
		},
		setNewAttachmentList(state, { payload }: PayloadAction<any>) {
			payload.map((element: any) =>  {
				let currentIndex = state.buildingList.findIndex((item: any) => item.uniqueBuildingId === element?.uniqueBuildingId);
				state.buildingList = state.buildingList.filter((item: any) => item.uniqueBuildingId !== element?.uniqueBuildingId);
				state.buildingList.splice(currentIndex, 0, element);
				});
		},
    }
});

export const getSiteBuildingList = (uniqueSiteId, currentStart, keyword: any = "", sortField: any = "", sortOrder: any = ""): AppThunk => async (dispatch) => {
    try {
		let requestUrl = `${baseURL}api/v1/sites/${uniqueSiteId}/buildings`;
		if (keyword) {
			requestUrl += `?keyword=${keyword}`;
		}
		if (currentStart) {
			requestUrl += keyword ? `&currentstart=${currentStart}` : `?currentstart=${currentStart}`;
		}
		if (sortField && sortOrder) {
			requestUrl += `&sortcolumn=${sortField}&sortorder=${sortOrder}`
		}
        const res = await zlink.get(`${requestUrl}`);
        if (res.status === "done") {
            dispatch(setBuildingList(res?.data?.buildingDetails));
            dispatch(setBuildinPageDetails(res?.data?.pageDetails));
        } else {
            dispatch(setErrors(res.error));
        }
    } catch (error) {
        dispatch(setErrors(error));
    }
};

export const onAddBuilding =
	(body: any, setMessage, setDisplayInfoModal): AppThunk =>
		async (dispatch) => {
			try {
				const res = await zlink.post(`${baseURL}api/v1/sites/${body?.uniqueSiteId}/buildings/add`, body);
				if (res.status === "done") {
					dispatch(setNewBuilding(res.data));
					setMessage("Building added");
					setDisplayInfoModal(true);
				} else {
					dispatch(setErrors(res.error));
					setMessage(res.message);
					setDisplayInfoModal(true);
				}
			} catch (error) {
				dispatch(setErrors(error));
			}
		};

export const getBuildingDetails = (uniqueSiteId, uniquebuildingId): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.get(`${baseURL}api/v1/sites/${uniqueSiteId}/buildings/${uniquebuildingId}`);
        if (res.status === "done") {
            dispatch(setBuildngDetails(res?.data));
        } else {
            dispatch(setErrors(res.error));
        }
    } catch (error) {
        dispatch(setErrors(error));
    }
};

export const onUpdateBuilding =
	(body: any, uniquebuildingId:any, setMessage, setDisplayInfoModal): AppThunk =>
		async (dispatch) => {
			try {
				const res = await zlink.post(`${baseURL}api/v1/sites/${body?.uniqueSiteId}/buildings/${uniquebuildingId}`, body);
				if (res.status === "done") {
					dispatch(setUpdatedBuildingData(res.data));
					setMessage("Building updated");
					setDisplayInfoModal(true);
				} else {
					dispatch(setErrors(res.error));
					setMessage(res.message);
					setDisplayInfoModal(true);
				}
			} catch (error) {
				dispatch(setErrors(error));
			}
		};

export const checkBuildigInUse = (uniqueSiteId, uniqueBuildingId): AppThunk => async (dispatch) => {
    try {
        const res = await zlink.get(`${baseURL}api/v1/sites/${uniqueSiteId}/buildings/${uniqueBuildingId}/checkbuildinginuse`);
        if (res.status === "done") {
            dispatch(setBuildingInUse(res?.data));
        } else {
            dispatch(setErrors(res.error));
        }
    } catch (error) {
        dispatch(setErrors(error));
    }
};

export const deleteBuilding = ( uniqueSiteId, uniqueBuildingId, setSelectedRows ,setMessage, setDisplayInfoModal ): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.post(`${baseURL}api/v1/sites/${uniqueSiteId}/buildings/${uniqueBuildingId}/remove`, null);
		if (res.status === "done") {
			dispatch(setDeletedBuilding(uniqueBuildingId));
			setMessage("Building deleted");
			setDisplayInfoModal(true);
            setSelectedRows([]);
		} else {
			setMessage(res.message);
			setDisplayInfoModal(true);
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const getAttachmentList = (uniquesiteid, uniquebuildingid, sortField: any = "", sortOrder: any = ""): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.get(`${baseURL}api/v1/sites/${uniquesiteid}/buildings/${uniquebuildingid}/attachments?sortcolumn=${sortField}&sortorder=${sortOrder}`);
		if (res.status === "done") {
			dispatch(setAttachmentList(res?.data?.attachmentList));
			dispatch(setAttachmentPageDetails(res?.data?.pageDetails));
		} else {
			dispatch(setErrors(res.error));
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};


export const onUploadBuildingAttachment =
    (uniquebuildingid,selectedRows,body,setShowAddModal,setMessage,setDisplayInfoModal, setEnableUpload?: any): AppThunk =>
      async (dispatch) => {
        try {
          const res = await zlink.post(`${baseURL}api/v1/building/${uniquebuildingid}/attachments/add`, body);
         
          if (res.status === "done") {
			  if (res.data && res.data.attachmentList && res.data.attachmentList.length) {
				  var count = Number(selectedRows.attachmentCount) + res.data.attachmentList.length;
				  dispatch(setBuildingAttachmentCountList({ ...selectedRows, 'attachmentCount': count }));
				  dispatch(setNewAttachmentData(res.data?.attachmentList));
				  setShowAddModal(false);
				  setMessage("Attachment uploaded");
				  setDisplayInfoModal(true);
			  }
          } else {
			setEnableUpload(false);
			setDisplayInfoModal(true);
            setMessage(res.message);
          }
        } catch (error) {
          dispatch(setErrors(error));
        }
};

export const onUploadMultipleBuildingAttachment = (selectedRows,body,setShowAddModal,setMessage,setDisplayInfoModal, setEnableUpload?: any,setSelectedRows?:any): AppThunk =>
      async (dispatch) => {
        try {
          const res = await zlink.post(`${baseURL}api/v1/building/attachments/${selectedRows?.[0]?.uniqueSiteId}/add`, body);
          if (res.status === "done") {
			if (res.data && res.data.buildingDetails && res.data.buildingDetails.length) {				
				dispatch(setNewAttachmentList(res.data?.buildingDetails));
				  setShowAddModal(false);
				  setSelectedRows(selectedRows);
				  setMessage("Attachment uploaded");
				  setDisplayInfoModal(true);
			  }
          } else {
			setEnableUpload(false);
			setDisplayInfoModal(true);
            setMessage(res.message);
          }
        } catch (error) {
          dispatch(setErrors(error));
        }
};


export const onEditAttachment =
    (uniquebuildingid,attachmentId: any = "",body:any,setShowEditModal,setMessage: any = "",setDisplayInfoModal, setEnableUpload?: any): AppThunk =>
      async (dispatch) => {
        try {
          const res = await zlink.post(`${baseURL}api/v1/building/${uniquebuildingid}/attachments/${attachmentId}`, body);
         
          if (res.status === "done") {
            dispatch(setUpdatedAttachement(res?.data));
            setShowEditModal(false);
            setMessage("Attachment uploaded");
			setDisplayInfoModal(true);
          } else {
			setEnableUpload(false);
            setMessage(res.message);
			setDisplayInfoModal(true);
          }
        } catch (error) {
          dispatch(setErrors(error));
        }
};

export const onDeleteAttachment = (uniquebuildingid, attachmentId,fullPath,setMessage,setDisplayConfirmModal,setSelectedRows,setDisplayInfoModal,selectedRows): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.post(`${baseURL}api/v1/building/${uniquebuildingid}/attachments/${attachmentId}/remove`,{filePath:fullPath});
		if (res.status === "done") {			
			var count = Number(selectedRows.attachmentCount) - 1;
			dispatch(setBuildingAttachmentCountList({...selectedRows,'attachmentCount':count}));
			dispatch(setDeletedAttachmentList(attachmentId));
			setSelectedRows([]);
			setMessage("Attachment deleted")
			setDisplayInfoModal(true)
			setDisplayConfirmModal(false)
		} else {
			dispatch(setErrors(res.error));
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const getAttachmentPath = (uniqueSiteId, uniqueBuildingId, attachmentId,setMessage,setDisplayConfirmModal,setSelectedRows,setDisplayInfoModal,selectedRows): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.get(`${baseURL}api/v1/sites/${uniqueSiteId}/buildings/${uniqueBuildingId}/attachments/${attachmentId}/file`);
		if (res.status === "done") {
		 dispatch(onDeleteAttachment(uniqueBuildingId, attachmentId,res.data?.fullPath,setMessage,setDisplayConfirmModal,setSelectedRows,setDisplayInfoModal,selectedRows))
		} else {
			dispatch(setErrors(res.error));
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const onDownloadAttachmentFile = (attachmentId, FullPath: any, setDisplayInfoModal, setMessage, renderFlag: boolean, setStreamData?: any, fileName?: any): AppThunk => async (dispatch) => {
	try {
		const request: any = {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "application/json; charset=utf-8",
				Authorization: sessionStorage.getItem("accesstoken"),
			},
			body: JSON.stringify({ FullPath: FullPath })
		};
		const downloadUrl = `${baseURL}api/v1/common/files/${attachmentId}/download`
		await fetch(downloadUrl, request).then((response: any) => {

			if (response.status === 200) {
				const res = new Response(
					new ReadableStream({
						async start(controller) {
							const reader = response.body.getReader();
							for (; ;) {
								const { done, value } = await reader.read();
								if (done) break;
								controller.enqueue(value);
							}
							controller.close();
						},
					})
				);
				if (!renderFlag) {
					res.blob().then((blob) => {
						let url = window.URL.createObjectURL(blob);
						let a = document.createElement("a");
						a.href = url;
						a.download = fileName;
						a.click();
					});
				} else {
					res.blob().then((blob) => {
						var result: any
						var reader = new FileReader();
						reader.onload = function (e) {
							result = e?.target?.result;
							setStreamData(result)
						}
						reader.readAsDataURL(blob);

					});
				}
			}
			else {
				setDisplayInfoModal(true)
				setMessage("File not found")
			}
		});

	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const onDownloadAttachment = (uniqueSiteId, uniqueBuildingId, attachmentId:any,setDisplayInfoModal,setMessage, viewFlag: boolean, setStreamData?:any,fileName?:any, setShowViewModal?: any): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.get(`${baseURL}api/v1/sites/${uniqueSiteId}/buildings/${uniqueBuildingId}/attachments/${attachmentId}/file`);
		if (res.status === "done") {
			if (viewFlag) {
				if (fileName) {
					if (fileName.split('.').pop() === "png" || fileName.split('.').pop() === "jpg" || fileName.split('.').pop() === "jpeg") {
						setShowViewModal(true);
						dispatch(onDownloadAttachmentFile(attachmentId, res.data?.fullPath, setDisplayInfoModal, setMessage, true, setStreamData, fileName));
					} else {
						dispatch(onDownloadAttachmentFile(attachmentId, res.data?.fullPath, setDisplayInfoModal, setMessage, false, setStreamData, fileName));
					}
				}
			} else {
				dispatch(onDownloadAttachmentFile(attachmentId, res.data?.fullPath, setDisplayInfoModal, setMessage, false, setStreamData, fileName));
			}
		} else {
			dispatch(setErrors(res.error));
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const {
    setErrors,
    setBuildingList,
    setBuildinPageDetails,
    setNewBuilding,
    setBuildngDetails,
    setUpdatedBuildingData,
    setBuildingInUse,
    setDeletedBuilding,
    setAttachmentList,
	setAttachmentPageDetails,
	setNewAttachmentData,
	setUpdatedAttachement,
	setDeletedAttachmentList,
	setBuildingAttachmentCountList,
	setNewAttachmentList,
} = BuildingSLice.actions;

export default BuildingSLice.reducer;