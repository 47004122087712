import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';

const textEditor = (options) => {
    return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
};

const AttributeTypeList = [
    { label: 'Multi Line Text', value: 'Multi Line Text' },
    { label: 'Single Line Text', value: 'Single Line Text' }
];


const AttributeTypeEditor = (options) => {
    return (
        <Dropdown value={options.value} options={AttributeTypeList} optionLabel="label" optionValue="value"
           
            itemTemplate={(option) => {
                return <span className='status-badge'>{option.label}</span>
            }} />
    );
}



export const CUSTOMIZATION_ATTRIBUTES_COLS = {
    COMMON_ATTRIBUTES_COLS: [
        {
            field: 'attributeName',
            header: 'Attribute Name',
            editor: (options) => textEditor(options),
            style: { flex: '1 0 200px' },
            sortable: true,
        },
        {
            field: 'attributeType',
            header: 'Attribute Type',
            editor: (options) => AttributeTypeEditor(options),
            style: { flex: '1 0 200px' },
            sortable: true,
            excludeGlobalFilter: true,
        },
        {
            field: 'isMandatory',
            header: 'Mandatory',
            editor: (options) => textEditor(options),
            style: { flex: '1 0 200px' },
            sortable: true,
            excludeGlobalFilter: true
        },
        {
            field: 'isValidated',
            header: 'Validated',
            editor: (options) => textEditor(options),
            style: { flex: '1 0 200px' },
            sortable: true,
            excludeGlobalFilter: true
        },
        {
            field: 'maxlength',
            header: 'Max. Length',
            editor: (options) => textEditor(options),
            style: { flex: '1 0 110px' },
            sortable: true,
            align: "right",
            excludeGlobalFilter: true
        }
    ]
    
};

