import React, { useCallback, useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";

import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../redux/hooks";
import { getUserRoles, getSalesOfficesOfUser, checkUserContainInspectionTask, setUserContainInspectionTask } from "redux/pages/Administration/User/userSlice";
import { getSelectedSalesOffices } from "redux/pages/Manage/OrganizationSettings/salesOfficeSlice";

import InformationBox from "components/common/Message/InformationBox/InformationBox";
import SelectSalesOffice from "../ManageUser/AddSelectSalesOffice";

import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { Dialog } from "primereact/dialog";
import { BsExclamationCircleFill } from "react-icons/bs";
import ConfirmationBox from "components/common/Message/ConfirmationBox/ConfirmationBox";

interface Props {
    showModal: boolean;
    setShowModal: (state: boolean) => void;
    selectedRows: any;
    onConvertRole: (data: any, body: any) => void; 
}

type FormData = {
    userRoleId: number;
};

export const ConvertUser: React.FC<Props> = ({ 
    showModal = false, 
    setShowModal = () => {}, 
    selectedRows = [],
    onConvertRole = () => {}
}) => {
    
    const dispatch = useDispatch<any>();
    const [message, setMessage] = useState("");
    const [userSelected, setUserSelected] = useState<any>({}); 
    const [roleSelected, setRoleSelected] = useState(null);
    const [allowedUserRoles, setAllowedUserRoles] = useState<any>([]);
    const [displayInfoModal, setDisplayInfoModal] = useState(false);
    const [showSelectSalesOffice, setShowSelectSalesOffice] = useState<boolean>(false);
    const [selectedSalesOffices, setSelectedSalesOffices] = useState<any>([]);
    const [displayConfirmModal, setDisplayConfirmModal] = useState<boolean>(false);
    const [isCheckCompleted, setIsCheckCompleted] = useState<boolean>(false);
    const [selectedUser, setSelectedUser] = useState<any>({});

    const userRoles = useAppSelector((state) => state.user.userRoles);
    const userPrivileges = useAppSelector((state) => state.user.userPrivileges);
    const salesOfficeIds = useAppSelector((state) => state.salesOffice?.selectedSalesOffices);
    const userContainInspectionTask = useAppSelector((state) => state.user.userContainInspectionTask);

    const convertUserForm: any = useRef();

    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm<FormData>({
        defaultValues: {
            userRoleId: undefined
        },
    });

    useEffect(() => {    
        if (selectedRows && selectedRows.length) {
            setUserSelected(selectedRows[0]);
        }
    }, [selectedRows]);

    useEffect(() => {
        if (selectedRows && selectedRows.length) {
            dispatch(getUserRoles());
            dispatch(getSalesOfficesOfUser(selectedRows[0]?.uniqueUserId, setSelectedSalesOffices));
        }
    }, [dispatch, selectedRows]);

    useEffect(() => {  
        if(isCheckCompleted){
        if (userContainInspectionTask?.isUserInspectionTask && selectedUser.userRoleId != 3 && selectedUser.userRoleId != 6) {
           setDisplayConfirmModal(true);
           setMessage("Sales office(s) of the user has changed, Converting will remove the user from inspection tasks assigned to different sales offices. Do you want to continue?");
        }else if ((selectedUser.userRoleId == 3 || selectedUser.userRoleId == 6) &&  userContainInspectionTask?.isUserInspectionTask){
               setDisplayConfirmModal(true);
               setMessage("Changing your role to Admin or Branch will remove the Lead Technician from the associated inspection tasks. Do you want to proceed?");
        }else if(userContainInspectionTask?.isUserInspectionTask === false) {
            let userRole = getUserRoleName(selectedUser.userRoleId);
                let offices = getSalesOfficeIds(selectedSalesOffices)
                let requestBody = {
                    userroleId: selectedUser.userRoleId,
                    salesofficeids: offices,
                    inspectionTaskIds:userContainInspectionTask?.inspectionTasksList
                }
                onConvertRole({ ...userSelected, newRole: userRole.userRoleName }, requestBody);
                setDisplayConfirmModal(false);
                setShowModal(false);
                dispatch(setUserContainInspectionTask(""))

        }
        setIsCheckCompleted(false);
    }
    }, [isCheckCompleted]);

    useEffect(() => {
        if (salesOfficeIds && salesOfficeIds.length) {
            let officeIds = new Set(selectedSalesOffices.map(element => element.uniqueSalesOfficeId));
            let mergedOffices = [...selectedSalesOffices, ...salesOfficeIds.filter(item => !officeIds.has(item.uniqueSalesOfficeId))];        
            setSelectedSalesOffices(mergedOffices);
        }
    }, [salesOfficeIds]);

    useEffect(() => {
        if (userRoles && userRoles.length) {
            let roles = userRoles.filter(element => ((element.userRoleId !== 1) && (element.userRoleName !== userSelected.userRole)));
            if (userPrivileges && userPrivileges.length) {
                let supportIndex = userPrivileges.findIndex(item => (item === 'SIETAP_SUPPORT_USER_MANAGE'));
                if (supportIndex === -1) {
                    roles =  roles.filter(role => (role.userRoleName !== 'Support'));
                }
                let adminIndex = userPrivileges.findIndex(item => (item === 'SIETAP_ADMIN_USER_MANAGE'));
                if (adminIndex === -1) {
                    roles =  roles.filter(role => (role.userRoleName !== 'Admin'));
                }
                let adminTechIndex = userPrivileges.findIndex(item => (item === 'SIETAP_TECH_ADMIN_MANAGE'));
                if (adminTechIndex === -1) {
                    roles =  roles.filter(role => (role.userRoleName !== 'Admin-Tech'));
                }
                let techIndex = userPrivileges.findIndex(item => (item === 'SIETAP_TECH_MANAGE'));
                if (techIndex === -1) {
                    roles =  roles.filter(role => (role.userRoleName !== 'Tech'));
                }
                let branchIndex = userPrivileges.findIndex(item => (item === 'SIETAP_BRANCH_ADMIN_MANAGE'));
                if (branchIndex === -1) {
                    roles =  roles.filter(role => (role.userRoleName !== 'Branch'));
                }
            }
            setAllowedUserRoles(roles);
            setValue('userRoleId', roles[0].userRoleId);
            setRoleSelected(roles[0].userRoleId);
        }
    }, [userRoles]);


    const formSubmitHandler = (data: any) => {
        convertUserForm.current.requestSubmit();
    };

    const getFormErrorMessage = (name) => {
        return (
            errors[name] && (
                <span className="tooltip-text">
                    <BsExclamationCircleFill />
                    {errors[name].message}
                </span>
            )
        );
    };

    const onChangeRole = (data) => {
        setRoleSelected(data);
    }

    const onConvertRoleFormSubmit = (data: any) => {
        if (data.userRoleId) {
            if ((data.userRoleId > 2) && !(selectedSalesOffices.length)) {
                setMessage("At least one Sales Office should be selected");
                setDisplayInfoModal(true);
                return;
            }
            else if((data.userRoleId > 2) && (selectedSalesOffices.length)){
                setSelectedUser(data);
                let offices = getSalesOfficeIds(selectedSalesOffices);
                let requestBody = {
                    salesofficeids: offices,
                    userroleId: data.userRoleId,
                    userIds: [userSelected.uniqueUserId],
                    isdelete:false
                }
                dispatch(checkUserContainInspectionTask(requestBody,setIsCheckCompleted))
            }
            else{
                let userRole = getUserRoleName(data.userRoleId);
                let offices = getSalesOfficeIds(selectedSalesOffices)
                let requestBody = {
                    userroleId: data.userRoleId,
                    salesofficeids: offices,
                    inspectionTaskIds:userContainInspectionTask?.inspectionTasksList
                }
                onConvertRole({ ...userSelected, newRole: userRole.userRoleName }, requestBody);
                setDisplayConfirmModal(false);
                setShowModal(false);
                dispatch(setUserContainInspectionTask(""))
                
            }
        }

    };

    const getUserRoleName = (roleId: any) => {
        return userRoles.find((element) => {
             if (element.userRoleId === roleId) {
                return element;
             }
        });
    };

    const onConfirmAction = () => {
            let userRole = getUserRoleName(selectedUser.userRoleId);
            let offices = getSalesOfficeIds(selectedSalesOffices);
            let requestBody = {
                userroleId : selectedUser.userRoleId,
                salesofficeids: offices,
                inspectionTaskIds:userContainInspectionTask?.inspectionTasksList
            }  
            onConvertRole({ ...userSelected, newRole: userRole.userRoleName}, requestBody); 
            setDisplayConfirmModal(false);
            setShowModal(false); 
            dispatch(setUserContainInspectionTask(""))
            
      };
    
      const onCancelAction = () => {
        setDisplayConfirmModal(false);
        setShowModal(false);
      };
    

    const getSalesOfficeIds = (salesOfficeIds: any) => {
        let officeIds: any = [];
        salesOfficeIds.map((item: any) => {
            officeIds.push(item.uniqueSalesOfficeId);
            return null;
        });
        return officeIds;
    }


    
    const renderFooter = () => {
        return (
            <>
                <Button label="Cancel" onClick={() => setShowModal(false)} className="cancel btn" />
                <Button label="Convert" className="update btn" autoFocus onClick={formSubmitHandler} />
            </>
        );
    };
    
    const resetSelectedUsers = useCallback(() => {
        dispatch(getSelectedSalesOffices([]));
    }, []);

    useEffect(() => {
        return () => {
            resetSelectedUsers();
        };
    }, []);


    return (
        <>
            <Dialog
                header="Convert to other User Role"
                visible={showModal}
                style={{ width: "40vw" }}
                onHide={() => setShowModal(false)}
                footer={renderFooter()}
                className="display-settings personal selectsite"
            >
                <form onSubmit={handleSubmit(onConvertRoleFormSubmit)} ref={convertUserForm}>
                    <div className="row px-2">
                        <div className="field col-12 md:col-2 mb-0 pb-0">
                            <span className="p-float-label">
                                <Controller
                                    name="userRoleId"
                                    control={control}
                                    rules={{
                                        required: "This field is required.",
                                    }}
                                    render={({ field, fieldState }) => (
                                        <Dropdown
                                            inputId="userRoleId"
                                            className={classNames("w-100 error-tooltip", {
                                                error: fieldState.invalid,
                                            })}
                                            {...field}
                                            value={field.value}
                                            onChange={(e) => {
                                                field.onChange(e.value);
                                                onChangeRole(e.value)
                                            }}
                                            options={allowedUserRoles}
                                            optionLabel="userRoleName"
                                            optionValue="userRoleId"
                                        />
                                    )}
                                />
                                <label className="mandatory" htmlFor="dropdown">
                                    To User Role
                                </label>
                                {getFormErrorMessage("userRoleId")}
                            </span>
                        </div>
                    </div>
                    <div className="row px-2">
                        {(roleSelected && (roleSelected > 2)) && 
                            <div className="field col-12 md:col-2 py-0 text-left m-0">
                                <Button
                                    label={`Select Sales Office(${selectedSalesOffices.length})`}
                                    aria-label="Select Sales Office"
                                    className="check-btnLink"
                                    onClick={(e: any) => {
                                        resetSelectedUsers();
                                        setShowSelectSalesOffice(true);
                                        e.preventDefault();
                                    }}
                                />
                            </div>
                        }
                        <div className="field col-12 md:col-2 text-left pl-0 bm-0 mt-3">
                            <span className="p-float-label">
                                <label className="mandatory">Required</label>
                            </span>
                        </div>
                    </div>
                </form>
            </Dialog>
            {showSelectSalesOffice ? (
                <SelectSalesOffice 
                    showModal={showSelectSalesOffice} 
                    setShowModal={setShowSelectSalesOffice} 
                    setMessage={setMessage}
                    setDisplayInfoModal={setDisplayInfoModal}
                    selectedSalesOffices={selectedSalesOffices}
                    userSelected={userSelected}
                    setSelectedSalesOffices={setSelectedSalesOffices}
                />
            ) : null}

            <InformationBox
                showInfoModal={displayInfoModal}
                setShowInfoModal={setDisplayInfoModal}
                message={message}
            />
            <ConfirmationBox
                showConfirmModal={displayConfirmModal}
                cancelAction={() => onCancelAction()}
                setShowConfirmModal={setDisplayConfirmModal}
                confirmAction={onConfirmAction}
                message={message}
            />
        </>
    );
};

export default ConvertUser;
