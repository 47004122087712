import React, { memo, useEffect, useRef, useState } from 'react'
import { AutoComplete } from 'primereact/autocomplete';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { Controller, useForm } from 'react-hook-form';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
//components
import ConfirmationBox from 'components/common/Message/ConfirmationBox/ConfirmationBox';
import InformationBox from 'components/common/Message/InformationBox/InformationBox';
import AddContract from 'pages/Contracts/ManageContract/AddContract';
import AddSite from 'pages/Sites/ManageSite/AddSite';
//icons
import { BsExclamationCircleFill, BsPlusLg } from 'react-icons/bs';
//redux
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'redux/hooks';
import { onAddSite, setNewSiteData } from 'redux/pages/Administration/Site/siteSlice';
import { onAddContract, setNewContractData } from 'redux/pages/Inspection/Contracts/contractSlice';
import { checkSalesOfficeExist, onCopyOrMoveSystemReports, onGetContract, onGetContractSalesOffice, onGetCustomers, onGetSites, onGetSystems } from 'redux/pages/Manage/Tools/ToolSlice';

type FormData = {
    fromCustomerSearchCriteria: string;
    fromSiteId: string;
    fromSystemId: string;
    toCustomerSearchCriteria: any;
    toSiteId: string;
    toContractId: string;
};

interface MoveSystemsPropType {
    systemType?: string;
}
const MoveSystems: React.FC<MoveSystemsPropType> = (props) => {
    const { systemType } = props;

    const [fromSiteList, setFromSiteList] = useState([]);
    const [toSiteList, setToSiteList] = useState<any>([]);
    const [systems, setSystems] = useState<any>([]);
    const [toContractList, setToContractList] = useState<any>([]);
    const [filterFromCustomers, setFilterFromCustomers] = useState<any>(null);
    const [filterToCustomers, setFilterToCustomers] = useState<any>(null);
    const [displayInfoModal, setDisplayInfoModal] = useState(false);
    const [message, setMessage] = useState("");
    const [showAddContract, setShowAddContract] = useState(false);
    const [showAddSite, setShowAddSite] = useState(false);
    const [displayConfirmModal, setDisplayConfirmModal] = useState(false);
    //case of submitting 
    const [bodyInfo, setBodyInfo] = useState<any>(null);
    const [targetPage, setTargetPage] = useState(1);
    const [statusMessage, setStatusMessage] = useState("");
    const [systemList, setSystemList] = useState<any>(null);
    const [isCopied, setIsCopied] = useState(false);
    const [showSystemDuplication, setShowSystemDuplication] = useState(false);
    const [showError, setShowError] = useState(false);
    const [destinationSite, setDestinationSite] = useState<any>(null);
    const addRef = useRef<any>(null);

    const dispatch = useDispatch<any>();
    const newSite = useAppSelector((state) => state.site.newSite);
    const newContractData = useAppSelector((state) => state.contract.newContractData);

    const {
        control,
        handleSubmit,
        watch,
        setValue,
        reset,
        formState: { errors },
    } = useForm<FormData>({
        defaultValues: {
            fromCustomerSearchCriteria: "",
            fromSiteId: "",
            fromSystemId: "",
            toCustomerSearchCriteria: "",
            toSiteId: "",
            toContractId: "",
        },
    });

    useEffect(() => {
        if (newSite) {
            let data = JSON.parse(JSON.stringify(newSite));
            data.siteName = `${data.siteName} (${data.siteNumber})`;
            toSiteList.push(data);
            const ascDatas = [...toSiteList].sort((a, b) => a.siteName - b.siteName);
            setToSiteList(ascDatas);
            dispatch(setNewSiteData(""));
        }
    }, [newSite])

    useEffect(() => {
        if (newContractData) {
            let data = JSON.parse(JSON.stringify(newContractData));
            data.contractNameWithoutSite = data.contractName;
            data.contractName = `${data.contractName} (${data.contractNumber})`;
            toContractList.push(data);
            const ascDatas = [...toContractList].sort((a, b) => a.contractNameWithoutSite?.localeCompare(b.contractNameWithoutSite));
            setToContractList(ascDatas);
            dispatch(setNewContractData(""));
        }
    }, [newContractData]);

    useEffect(() => {
        if (statusMessage) {
            setDisplayConfirmModal(true);
        }
    }, [statusMessage])

    useEffect(() => {
        if (isCopied) {
            reset();
            setIsCopied(false);
            setStatusMessage("");
            setTargetPage(1);
            setSystemList(null);
            setDisplayConfirmModal(false);
            setShowSystemDuplication(false);
            setFilterFromCustomers(null);
            setFilterToCustomers(null);
            setFromSiteList([]);
            setToSiteList([]);
            setToContractList([]);
            setSystems([]);
        }
    }, [isCopied])

    useEffect(() => {
        if (targetPage === 4) {
            setShowSystemDuplication(false);
        }
    }, [targetPage])

    const searchFromCustomer = (event) => {
        setTimeout(() => {
            const body = {
                uniqueBusinessZoneId: null,
                searchText: event?.query,
                uniqueSourceCustomerId: null,
            }
            dispatch(onGetCustomers(null, body, setFilterFromCustomers));
        }, 250);
    };

    const searchToCustomer = (event) => {
        const customer: any = watch("fromCustomerSearchCriteria");
        if (!customer) {
            setDisplayInfoModal(true);
            setMessage("Select a From Customer");
            return;
        }
        setTimeout(() => {
            const body = {
                uniqueBusinessZoneId: null,
                searchText: event?.query,
                uniqueSourceCustomerId: customer?.uniqueCustomerId,
            }
            dispatch(onGetCustomers(null, body, setFilterToCustomers));
        }, 250);
    }

    const copyData = (data) => {
        if (!data?.fromCustomerSearchCriteria?.uniqueCustomerId) {
            setDisplayInfoModal(true);
            setMessage("Select a From Customer");
            return;
        }
        if (!data?.toCustomerSearchCriteria?.uniqueCustomerId) {
            setDisplayInfoModal(true);
            setMessage("Select a To Customer");
            return;
        }

        const body = {
            uniqueSourceCustomerId: data?.fromCustomerSearchCriteria?.uniqueCustomerId,
            uniqueSourceSiteId: data?.fromSiteId ? data?.fromSiteId : '0',
            uniqueSourceSystemId: data?.fromSystemId,
            uniqueTargetCustomerId: data?.toCustomerSearchCriteria?.uniqueCustomerId,
            uniqueTargetContractId: data?.toContractId,
            uniqueTargetSiteId: data?.toSiteId ? data?.toSiteId : '0',
            keepSourceData: false,
            systemData: systemType === "system" ? "SELECTED_SYSTEM" : "ALL_SYSTEM",
            system: systemList,
            pageTarget: targetPage,
        }

        dispatch(onCopyOrMoveSystemReports(body, setStatusMessage, setTargetPage, setSystemList, setIsCopied, setMessage, setDisplayInfoModal));
        setBodyInfo(body);
    }

    const confirmedCopy = () => {
        if (targetPage === 3) {
            setShowSystemDuplication(true);
            setDisplayConfirmModal(false);
            return;
        }
        bodyInfo.system = systemList;
        bodyInfo.pageTarget = targetPage;
        setBodyInfo(bodyInfo);
        dispatch(onCopyOrMoveSystemReports(bodyInfo, setStatusMessage, setTargetPage, setSystemList, setIsCopied, setMessage, setDisplayInfoModal))
    }

    const onConfirmCancel = () => {
        setDisplayConfirmModal(false);
        setStatusMessage("");
        setTargetPage(1);
        setSystemList(null);
    }

    const onCancelSystem = () => {
        setStatusMessage("");
        setTargetPage(1);
        setSystemList(null);
        setShowSystemDuplication(false);
    }

    const onUpdateSystem = () => {
        if (targetPage === 3) {
            let isEmpty = false;
            systemList?.forEach(element => {
                if (!element?.uniqueSystemName)
                    isEmpty = true
            });
            if (isEmpty) {
                setShowError(true);
                return;
            }
        }
        const systems = systemList?.map(({ sourceSystemName, ...rest }: any) => {
            return rest;
        });
        bodyInfo.system = systems;
        bodyInfo.pageTarget = targetPage;
        dispatch(onCopyOrMoveSystemReports(bodyInfo, setStatusMessage, setTargetPage, setSystemList, setIsCopied, setMessage, setDisplayInfoModal))
        setBodyInfo(bodyInfo);
    }

    const onAddContractClick = (submitData: any) => {
        submitData.UniqueSiteId = destinationSite;
        dispatch(
            onAddContract(
                submitData,
                setMessage,
                setDisplayInfoModal,
                setShowAddContract,
                false,
                false,
                true,
            )
        );
    };

    const onAddSiteClick = (submitData: any) => {
        submitData.uniqueTargetCustomerId = watch("toCustomerSearchCriteria")?.uniqueCustomerId;
        submitData.isCustomerSiteMaping = true
            dispatch(
            onAddSite(
                submitData,
                setMessage,
                setDisplayInfoModal,
                setShowAddSite,
            )
        );
    };

    const addContract = () => {
        const fromCustomer: any = watch("fromCustomerSearchCriteria");
        const toCustomer: any = watch("toCustomerSearchCriteria");
        const toSite: any = watch("toSiteId");
        const sourceSystem = watch("fromSystemId");

        if (!fromCustomer?.uniqueCustomerId) {
            setDisplayInfoModal(true);
            setMessage("Select a From Customer");
            return;
        }
        if (!toCustomer?.uniqueCustomerId) {
            setDisplayInfoModal(true);
            setMessage("Select a To Customer");
            return;
        }
        if (!toSite) {
            setDisplayInfoModal(true);
            setMessage("Select Site");
            return;
        }
        if (!sourceSystem) {
            setDisplayInfoModal(true);
            setMessage("Select System");
            return;
        }
        const body = {
            uniqueSourceSystemId: sourceSystem,
            uniqueSourceContractId: null,
            systemData: "SELECTED_SYSTEM",
        }
        dispatch(checkSalesOfficeExist(body, setShowAddContract));
        dispatch(onGetContractSalesOffice(0, sourceSystem));
    }

    const addSite = () => {
        const fromCustomer: any = watch("fromCustomerSearchCriteria");
        const toCustomer: any = watch("toCustomerSearchCriteria");
        if (!fromCustomer?.uniqueCustomerId) {
            setDisplayInfoModal(true);
            setMessage("Select a From Customer");
            return;
        }
        if (!toCustomer?.uniqueCustomerId) {
            setDisplayInfoModal(true);
            setMessage("Select a To Customer");
            return;
        }
        setShowAddSite(true);
    }

    const getFormErrorMessage = (name) => {
        return (
            errors[name] && (
                <span className="tooltip-text">
                    <BsExclamationCircleFill />
                    {errors[name].message}
                </span>
            )
        );
    };

    const renderFooter = () => {
        return (
            <>
                <Button label="Cancel" onClick={onCancelSystem} className='cancel btn' />
                <Button label="Ok" className='save btn' onClick={onUpdateSystem} autoFocus />
            </>
        );
    }

    const editableTemplate = (rowData, columnDetails) => {

        return (
            <div className='custom-float-field'>
                <span className="p-float-label ">
                    <InputText
                        id={rowData?.uniqueSystemId}
                        value={rowData?.uniqueSystemName}
                        onChange={(e: any) => {
                            systemList[columnDetails?.rowIndex]["uniqueSystemName"] = e.target.value;
                            setSystemList([...systemList]);
                        }}
                        className={`w-100 ${showError && rowData?.uniqueSystemName === "" ? 'p-invalid error-tooltip error' : ''}`}
                    />
                    <label htmlFor={rowData?.uniqueSystemId} className={"mandatory"}>
                        Destination System Name
                    </label>
                    {showError && rowData?.uniqueSystemName === "" &&
                        <span className="tooltip-text">
                            <BsExclamationCircleFill />
                            <span>Enter System Name</span>
                        </span>
                    }
                </span>
            </div>
        )
    }

    return (
        <div className="copy-system mt-3">
            <p className="title">
                <b>System Move</b>
            </p>
            <div className="send-recieve-items">
                <form onSubmit={handleSubmit(copyData)} ref={addRef}>
                    <div className="from box">
                        <span className="pb-2 pl-2">
                            <b>From</b>
                        </span>
                        <div className="field col-12 md:col-2 mb-0">
                            <span className="p-float-label">
                                <Controller
                                    name="fromCustomerSearchCriteria"
                                    control={control}
                                    rules={{
                                        required: "Enter Customer Name",
                                    }}
                                    render={({ field, fieldState }) => (
                                        <AutoComplete
                                            id="fromCustomerSearchCriteria"
                                            value={field.value}
                                            className={classNames("w-100 error-tooltip", {
                                                error: fieldState.invalid,
                                            })}
                                            suggestions={filterFromCustomers}
                                            completeMethod={searchFromCustomer}
                                            onSelect={(e) => {
                                                const body = {
                                                    uniqueCustomerId: e.value.uniqueCustomerId,
                                                    uniqueBusinessZoneId: null,
                                                    searchText: "",
                                                    uniqueContractId: null,
                                                }
                                                setValue("fromSiteId", "");
                                                setFromSiteList([]);
                                                dispatch(onGetSites(null, body, setFromSiteList, false, setDisplayInfoModal, setMessage))
                                            }}
                                            field="customerName"
                                            onChange={(e) => field.onChange(e.value)}
                                            aria-label="Customers"
                                            dropdownAriaLabel="Select Customer"
                                        />
                                    )}
                                />
                                <label className="mandatory" htmlFor="fromCustomerSearchCriteria">
                                    Customer
                                </label>
                                {getFormErrorMessage("fromCustomerSearchCriteria")}
                            </span>
                        </div>

                        <div className="field col-12 md:col-2 mb-0">
                            <span className="p-float-label">
                                <Controller
                                    name="fromSiteId"
                                    control={control}
                                    rules={{
                                        required: watch("fromCustomerSearchCriteria") ? "Select a Site" : "",
                                    }}
                                    render={({ field, fieldState }) => (
                                        <Dropdown
                                            inputId="fromSiteId"
                                            className={classNames("w-100 error-tooltip", {
                                                error: fieldState.invalid,
                                            })}
                                            {...field}
                                            value={field.value}
                                            onChange={(e) => {
                                                field.onChange(e.value);
                                                const customer: any = watch("fromCustomerSearchCriteria");
                                                const body = {
                                                    uniqueCustomerId: customer?.uniqueCustomerId,
                                                    uniqueSiteId: e.value
                                                }
                                                setValue("fromSystemId", "");
                                                setSystems([]);
                                                dispatch(onGetSystems(body, setSystems, setDisplayInfoModal, setMessage))
                                            }}
                                            options={fromSiteList}
                                            optionLabel="siteName"
                                            optionValue="uniqueSiteId"
                                        />
                                    )}
                                />
                                <label className="mandatory" htmlFor="dropdown">
                                    Site
                                </label>
                                {getFormErrorMessage("fromSiteId")}
                            </span>
                        </div>

                        <div className="field col-12 md:col-2 mb-0">
                            <span className="p-float-label">
                                <Controller
                                    name="fromSystemId"
                                    control={control}
                                    rules={{
                                        required: watch("fromSiteId") ? "Select a System" : "",
                                    }}
                                    render={({ field, fieldState }) => (
                                        <Dropdown
                                            inputId="fromSystemId"
                                            className={classNames("w-100 error-tooltip", {
                                                error: fieldState.invalid,
                                            })}
                                            {...field}
                                            value={field.value}
                                            onChange={(e) => field.onChange(e.value)}
                                            options={systems}
                                            optionLabel="systemName"
                                            optionValue="uniqueSystemId"
                                        />
                                    )}
                                />
                                <label className="mandatory" htmlFor="dropdown">
                                    System
                                </label>
                                {getFormErrorMessage("fromSystemId")}
                            </span>
                        </div>

                    </div>
                    <div className="icon"></div>
                    <div className="to box">
                        <span className="pb-2 pl-2">
                            <b>To</b>
                        </span>
                        <div className="field col-12 md:col-2 mb-0">
                            <span className="p-float-label">
                                <Controller
                                    name="toCustomerSearchCriteria"
                                    control={control}
                                    rules={{
                                        required: "Enter Customer Name",
                                    }}
                                    render={({ field, fieldState }) => (
                                        <AutoComplete
                                            id="toCustomerSearchCriteria"
                                            value={field.value}
                                            className={classNames("w-100 error-tooltip", {
                                                error: fieldState.invalid,
                                            })}
                                            suggestions={filterToCustomers}
                                            completeMethod={searchToCustomer}
                                            onSelect={(e) => {
                                                const body = {
                                                    uniqueCustomerId: e.value.uniqueCustomerId,
                                                    uniqueBusinessZoneId: null,
                                                    searchText: "",
                                                    uniqueContractId: null,
                                                }
                                                setValue("toSiteId", "");
                                                setToSiteList([]);
                                                dispatch(onGetSites(e?.value?.countryId, body, setToSiteList, false, setDisplayInfoModal, setMessage))
                                            }}
                                            field="customerName"
                                            onChange={(e) => field.onChange(e.value)}
                                            aria-label="Customers"
                                            dropdownAriaLabel="Select Customer"
                                        />
                                    )}
                                />
                                <label className="mandatory" htmlFor="toCustomerSearchCriteria">
                                    Customer
                                </label>
                                {getFormErrorMessage("toCustomerSearchCriteria")}
                            </span>
                        </div>

                        <div className="row px-2 align-items-center">
                            <div className="field col-11 md:col-2 mb-0">
                                <span className="p-float-label">
                                    <Controller
                                        name="toSiteId"
                                        control={control}
                                        rules={{
                                            required: watch("toCustomerSearchCriteria") ? "Select a Site" : "",
                                        }}
                                        render={({ field, fieldState }) => (
                                            <Dropdown
                                                inputId="toSiteId"
                                                className={classNames("w-100 error-tooltip", {
                                                    error: fieldState.invalid,
                                                })}
                                                {...field}
                                                value={field.value}
                                                onChange={(e) => {
                                                    field.onChange(e.value);
                                                    const customer: any = watch("toCustomerSearchCriteria");
                                                    const body = {
                                                        uniqueSiteId: e.value,
                                                        uniqueCustomerId: customer?.uniqueCustomerId,
                                                        UniqueSystemId: watch("fromSystemId") ? watch("fromSystemId") : null,
                                                        IsCopy: false,
                                                    }
                                                    setDestinationSite(watch("toSiteId"));
                                                    setValue("toContractId", "");
                                                    setToContractList([]);
                                                    dispatch(onGetContract(body, setToContractList, setDisplayInfoModal, setMessage))
                                                }}
                                                options={toSiteList}
                                                optionLabel="siteName"
                                                optionValue="uniqueSiteId"
                                            />
                                        )}
                                    />
                                    <label className="mandatory" htmlFor="toSiteId">
                                        Site
                                    </label>
                                    {getFormErrorMessage("toSiteId")}
                                </span>
                            </div>
                            <div className="field col-1 md:col-2 mb-0 p-0">
                                <span className="p-float-label action-btns">
                                    <div
                                        className="button-icon"
                                        onClick={() => addSite()}
                                    >
                                        <BsPlusLg className="icon" />
                                    </div>
                                </span>
                            </div>
                        </div>

                        <div className="row px-2 align-items-center">
                            <div className="field col-11 md:col-2 mb-0">
                                <span className="p-float-label">
                                    <Controller
                                        name="toContractId"
                                        control={control}
                                        rules={{
                                            required: watch("toSiteId") ? "Select a Contract" : "",
                                        }}
                                        render={({ field, fieldState }) => (
                                            <Dropdown
                                                inputId="toContractId"
                                                className={classNames("w-100 error-tooltip", {
                                                    error: fieldState.invalid,
                                                })}
                                                {...field}
                                                value={field.value}
                                                onChange={(e) => field.onChange(e.value)}
                                                options={toContractList}
                                                optionLabel="contractName"
                                                optionValue="uniqueContractId"
                                            />
                                        )}
                                    />
                                    <label className="mandatory" htmlFor="toContractId">
                                        Contract
                                    </label>
                                    {getFormErrorMessage("toContractId")}
                                </span>
                            </div>
                            <div className="field col-1 md:col-2 mb-0 p-0">
                                <span className="p-float-label action-btns">
                                    <div
                                        className="button-icon"
                                        onClick={() => addContract()}
                                    >
                                        <BsPlusLg className="icon" />
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <Button label="Move" className="btn mt-3" autoFocus onClick={() => addRef?.current?.requestSubmit()} />

            <InformationBox
                showInfoModal={displayInfoModal}
                setShowInfoModal={setDisplayInfoModal}
                message={message}
            />
            <ConfirmationBox
                showConfirmModal={displayConfirmModal}
                setShowConfirmModal={onConfirmCancel}
                confirmAction={confirmedCopy}
                message={statusMessage}
            />
            {showAddContract && (
                <AddContract
                    showModal={showAddContract}
                    selectedCustomerId={watch("toCustomerSearchCriteria")?.uniqueCustomerId}
                    setShowModal={setShowAddContract}
                    onAddContractClick={(data) => onAddContractClick(data)}
                    isFromInspection={true}
                    isFromCopy={true}
                    siteId={watch("toSiteId")}
                />)
            }
            {showAddSite && (
                <AddSite
                    showModal={showAddSite}
                    setShowModal={setShowAddSite}
                    onAddSiteClick={(data) => onAddSiteClick(data)}
                />)
            }
            <Dialog
                header="System Name(s)"
                visible={showSystemDuplication}
                style={{ width: '35vw' }}
                footer={renderFooter()}
                onHide={onCancelSystem}
                className='s-modal'
            >
                <div>
                    {systemList?.length &&
                        <DataTable value={systemList} responsiveLayout="scroll">
                            <Column field="sourceSystemName" header="Source System Name"></Column>
                            <Column header="Destination System Name" body={editableTemplate}></Column>
                        </DataTable>
                    }
                </div>
            </Dialog>
        </div>
    )
}

export default memo(MoveSystems)