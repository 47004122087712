import React, { useState, useRef, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useDispatch } from "react-redux";
import ConfirmationBox from "components/common/Message/ConfirmationBox/ConfirmationBox";
import InformationBox from "components/common/Message/InformationBox/InformationBox";
import { onUpdateEquipmentGroup } from "redux/pages/Inspection/Systems/systemSlice";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Checkbox } from "primereact/checkbox";

interface Props {
  showModal: boolean;
  setShowModal: (state: boolean) => void;
  equipmentGroups: any;
  selectedRows: any
}

export const EquipmentGroupList: React.FC<Props> = ({
  showModal = false,
  setShowModal = () => { },
  equipmentGroups = [],
  selectedRows = []
}) => {
  const [equipmentList, setEquipmentList] = useState<any>([]);
  const [selection, setSelection] = useState([]);
  const [displayInfoModal, setDisplayInfoModal] = useState(false);
  const [displayConfirmModal, setDisplayConfirmModal] = useState(false);
  const [message, setMessage] = useState("");
  const selectedId = useRef<any>();
  const [btnDisable, setBtnDisable] = useState(false);
  const dispatch = useDispatch<any>();

  const updateEquipmentSubmit = () => {
    setDisplayConfirmModal(false)
    setBtnDisable(true);
    const body = { "equipmentIds": equipmentList.filter(function (item) { return item.isSelected === true }).map(item => item.equipmentId) }
    dispatch(onUpdateEquipmentGroup(selectedRows[0]?.uniqueSystemId, body, setMessage, setDisplayInfoModal, setShowModal))
  }

  const onConfirmClick = () => {
    setDisplayConfirmModal(false);
  }

  useEffect(() => {
    if (equipmentGroups && equipmentGroups.length) {
      setEquipmentList(equipmentGroups);
      setSelection(equipmentGroups[0])
    } else {
      setEquipmentList([]);
      setSelection([]);
    }
  }, [dispatch, equipmentGroups]);

  const bodyCheckBoxTemplate = (rowData: any, columnDetails: any) => {
    return (
      <div className="checkbox-wrapper field-checkbox">
        <Checkbox
          className="check-box mr-2 ml-2"
          id={rowData.equipmentId}
          checked={rowData.isSelected}
          disabled={rowData.isDisabled}
          onChange={(e) => onCheckBoxChange(e, columnDetails)}
        />
      </div>
    );
  };

  const onCheckBoxChange = (e, details) => {
    selectedId.current =  details.props.value[details.rowIndex].equipmentId
    equipmentList[details.rowIndex].isSelected = e.checked;
    setEquipmentList([...equipmentList]);
    if (e.checked === false) {
      setMessage("All Equipment data of the selected Equipment Group will be lost. Do you want to proceed?");
      setDisplayConfirmModal(true);
    }
  }

  const onOkClick = () => {
    setDisplayInfoModal(false);
    setBtnDisable(false);
    setShowModal(false);
  }

  const onCancelClick = () => {
    let index = equipmentList.findIndex((obj => obj.equipmentId === selectedId.current));
    let selectedItem = equipmentList.filter((item: any) => item.equipmentId === selectedId.current);
    selectedItem[0].isSelected = true;
    equipmentList.splice(index, 1,  selectedItem[0]);
    setDisplayConfirmModal(false);
  }
  
  return (
    <>
      <Dialog
        header="Equipment Groups"
        visible={showModal}
        style={{ width: "70vw" }}
        onHide={() => setShowModal(false)}
        className="s-modal equipment-groups"
      >
        <div className="content-box">
          <div className="header-text">
            <span className="label">System: </span>
            <span className="value">{selectedRows[0]?.systemName}</span>
          </div>
          <div className="table-wrap">
            <div className="custom-table">
              <DataTable
                value={equipmentList}
                selection={selection}
                selectionMode="single"
                onSelectionChange={(e) => {setSelection(e.value)}}
                dataKey="equipmentId"
                responsiveLayout="scroll"
              >
                <Column header="" body={bodyCheckBoxTemplate}></Column>
                <Column field="name" header="Equipment"></Column>
                <Column field="barcode" header="Barcode"></Column>
                <Column field="address" header="Address"></Column>
                <Column field="panelType" header="PanelType"></Column>
                <Column field="attachments" header="Attachments"></Column>
              </DataTable>
            </div>
          </div>
          <div className="footer text-left">
            <Button
              label="Cancel"
              onClick={() => {
                setShowModal(false);
              }}
              className="cancel-btn"
            />

            <Button
              label="Save Changes"
              onClick={updateEquipmentSubmit}
              className="submit-btn"
              disabled={btnDisable}
            />
          </div>
        </div>
      </Dialog>
      <ConfirmationBox showConfirmModal={displayConfirmModal} setShowConfirmModal={setDisplayConfirmModal} confirmAction={onConfirmClick} message={message} cancelAction={onCancelClick} onHideAction={onCancelClick}/>
      <InformationBox showInfoModal={displayInfoModal} setShowInfoModal={setDisplayInfoModal} message={message} okOnClick={onOkClick} />
    </>
  );
};

export default EquipmentGroupList;
