import React, { PropsWithChildren, ReactNode } from "react";
import { Dialog } from "primereact/dialog";
import { DataTable } from 'primereact/datatable';
import { Column } from "primereact/column";

interface IMappedReports {
  showModal: boolean;
  setShowModal: (state: boolean) => void;
  MappedReportNames?: any;
  message?: string;
  footer: ReactNode
}

export const MappedReports: React.FC<PropsWithChildren<IMappedReports>> = ({
  showModal = false,
  setShowModal = () => { },
  MappedReportNames = [],
  message = "",
  footer = "",
}) => {

  const renderHeader = () => {
    return (
      <>
        Tech Advance<sup>+</sup>
      </>
    )
  }

  return (
    <Dialog
      header={renderHeader()}
      visible={showModal}
      style={{ width: '45vw' }}
      onHide={() => { setShowModal(false) }}
      className='s-modal'
    >
      <div className="header">
        <span className="text-sm">{message}</span>
      </div>
      {MappedReportNames &&
        <div className="stickyTableHeader bg-white d-flex mt-1">
          <DataTable
            value={MappedReportNames}
            scrollHeight="35vh"
            resizableColumns
            columnResizeMode="fit"
          >
            <Column field="inspectionReportName" header="Report Name" />
            <Column field="datafieldName" header="Data Field Name" style={{whiteSpace:'unset'}} />
          </DataTable>
        </div>
      }
      <div className="mt-3 mb-3">
        <div className="field col-12 md:col-2 py-0 text-right m-0 p-0">
          {footer}
        </div>
      </div>

    </Dialog>
  );
};

export default MappedReports;
