import React, { useState, useEffect, useCallback, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import moment from "moment";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { classNames } from "primereact/utils";

import { BsExclamationCircleFill } from "react-icons/bs";

//redux
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../redux/hooks";

import AddModal from "components/Table/AddModal/AddModal";
import {
  getCountryList,
  getStatusList,
} from "redux/pages/Administration/administrationSlice";
import {
  USAPattern,
  CanadaPattern,
} from "../ManageCustomer/CustomerNumberPattern";
interface Props {
  showModal: boolean;
  setShowModal: (state: boolean) => void;
  onAddCustomerClick: (data: any) => void;
  isAddContract?: boolean;
  userCountryId?: string;
}

export const AddCustomer: React.FC<Props> = ({
  showModal = false,
  setShowModal = () => {},
  onAddCustomerClick = () => {},
  isAddContract = false,
  userCountryId = '220'
}) => {
  const statusList = useAppSelector((state) => state.administration.statusList);
  const countryList = useAppSelector(
    (state) => state.administration.countryList
  );
  const [statusLookup, setStatusLookup] = useState([{ name: "", code: "" }]);
  const [countryLookup, setCountryLookup] = useState([{ name: "", code: "" }]);
  const [selectedCountry, setSelectedCountry] = useState<any>("");
  const [showPatternModal, setShowPatternModal] = useState(false);
  const [disableCountry, setDisableCountry] = useState(false)

  const [pattern, setPattern] = useState<any>([]);
  const [customerNumberPattern, setCustomerNumberPattern] = useState<any>();
  const addForm: any = useRef();

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch<any>();

  const setDate = useCallback(() => {
    let todayDate = new Date();
    let day = todayDate.getDate();
    let month = todayDate.getMonth();
    let year = todayDate.getFullYear();
    setValue("accountExpiryDate", new Date(2050, 0, 1));
    setValue("phone", "None");
    setValue("fax", "None");
    setValue("contact", "None");
    setValue("email", "None");
  }, [setValue]);

  useEffect(() => {
    setDate();
    dispatch(getStatusList());
    dispatch(getCountryList());
  }, [dispatch, setDate]);

  useEffect(() => {
    if (statusList?.status && statusList?.status.length) {
      let status: { name: string; code: string }[] = [];
      statusList?.status.map((item: any) => {
        if (
          item.statusName === "Active" ||
          item.statusName === "Blocked" ||
          item.statusName === "Deleted" ||
          item.statusName === "Not Activated"
        ) {
          status.push({ name: item.statusName, code: item.statusCode });
        }
        return status;
      });
      setStatusLookup(status);
      setValue("status", statusList?.status[0].statusName);
    }
  }, [statusList, setValue]);

  useEffect(() => {
    if (countryList?.countries && countryList?.countries.length) {
      let country: { name: string; code: string }[] = [];
      countryList?.countries.map((item: any) => {
        return country.push({
          name: item.countryName,
          code: item.countryCode,
        });
      });
      setCountryLookup(country);
      if (isAddContract){
          let countryData = countryList?.countries.filter(
            (el: any) =>
              el.countryId === userCountryId
          );
        setValue("countryName", countryData[0]?.countryName);
        setDisableCountry(true)
        setSelectedCountry(countryData[0]?.countryName.trim());
      } else {
        if (countryList.countries && countryList.countries.length) {
          setValue("countryName", countryList?.countries[0].countryName);
          setSelectedCountry(countryList?.countries[0]?.countryName.trim());
        }
      }
    }
  }, [countryList, setValue]);

  useEffect(() => {
    if (selectedCountry?.trim() === "United States") {
      setPattern(USAPattern);
      setCustomerNumberPattern(/^[1,3][0-9]{7}$/);
    } else if (selectedCountry?.trim() === "Canada") {
      setPattern(CanadaPattern);
      setCustomerNumberPattern(/^[6][0-9]{5}$/);
    }
  }, [selectedCountry]);
  const onAddFormSubmit = (data: any) => {
    data.accountExpiryDate = moment(data?.accountExpiryDate)
      .format("DD MMM YYYY")
      .toString();
      data.customerName = data?.customerName.trim();
      data.customerAddress = data?.customerAddress.trim();
      data.cityStateZip = data?.cityStateZip.trim();
      data.contact = data?.contact.trim();
    onAddCustomerClick && onAddCustomerClick(data);
  };

  const formSubmitHandler = (data: any) => {
    addForm?.current?.requestSubmit();
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && (
        <span className="tooltip-text">
          <BsExclamationCircleFill />
          {errors[name].message}
        </span>
      )
    );
  };

  //handle form validation errors
  const onError = (errors: any, e: any) => {};

  return (
    <AddModal
      header="New Sold to Customer"
      showModal={showModal}
      setShowModal={setShowModal}
      OnSaveClick={formSubmitHandler}
      isAdd={true}
      style={{ width: "35vw", maxHeight: "95%" }}
    >
      <form onSubmit={handleSubmit(onAddFormSubmit, onError)} ref={addForm}>
        <Dialog
          header="Customer Number"
          visible={showPatternModal}
          onHide={() => setShowPatternModal(false)}
          className="pattern-overlay"
          style={{ width: "32vw" }}
        >
          <DataTable showGridlines={false} value={pattern}>
            <Column field="pattern" header="Pattern" />
            <Column field="format" header="Format" />
            <Column field="description" header="Description" />
          </DataTable>
        </Dialog>

        <div className="field col-12 md:col-4 pt-4 pb-0">
          <span className="p-float-label">
            <Controller
              name="countryName"
              control={control}
              rules={{ required: "Country Name is required."}}
              render={({ field, fieldState }) => (
                <Dropdown
                  inputId="dropdown"
                  disabled={disableCountry}
                  className={classNames("w-100 error-tooltip", {
                    error: fieldState.invalid,
                  })}
                  {...field}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e.value);
                    setSelectedCountry(e.value);
                  }}
                  options={countryLookup}
                  optionLabel="name"
                  optionValue="name"
                />
              )}
            />
            <label className="mandatory" htmlFor="dropdown">
              Country
            </label>
            {getFormErrorMessage("countryName")}
          </span>
        </div>
        <div className="field col-12 md:col-4 pt-0">
          <span
            className="pattern-view d-flex justify-content-end pb-1"
            role="button"
            onClick={() => setShowPatternModal(true)}
          >
            View Pattern
          </span>
          <span className="p-float-label">
            <Controller
              name="customerNumber"
              control={control}
              rules={{
                required: "Customer Number is required.",
                pattern: {
                  value: customerNumberPattern,
                  message: "Enter a valid Customer Number",
                },
              }}
              render={({ field, fieldState }) => (
                <InputText
                  id="inputtext"
                  maxLength={100}
                  className={classNames("w-100 error-tooltip", {
                    error: fieldState.invalid,
                  })}
                  {...field}
                />
              )}
            />
            <label className="mandatory" htmlFor="inputtext">
              Customer Number
            </label>
            {getFormErrorMessage("customerNumber")}
          </span>
        </div>
        <div className="field col-12 md:col-4">
          <span className="p-float-label">
            <Controller
              name="customerName"
              control={control}
              rules={{
                required: "Customer Name is required.",
                validate: (value) => {
                  return value.trim().length<=0
                    ?"Customer Name is required.":true
                },
              }}
              render={({ field, fieldState }) => (
                <InputText
                  id="inputtext"
                  maxLength={100}
                  className={classNames("w-100 error-tooltip", {
                    error: fieldState.invalid,
                  })}
                  {...field}
                />
              )}
            />
            <label className="mandatory" htmlFor="inputtext">
              Customer Name
            </label>
            {getFormErrorMessage("customerName")}
          </span>
        </div>
        <div className="field col-12 md:col-4">
          <span className="p-float-label">
            <Controller
              name="customerAddress"
              control={control}
              rules={{ required: "Customer Address is required.",
              validate: (value) => {
                return value.trim().length<=0
                  ?"Customer Address is required.":true
              },
            }}
              render={({ field, fieldState }) => (
                <InputText
                  id="inputtext"
                  maxLength={100}
                  className={classNames("w-100 error-tooltip", {
                    error: fieldState.invalid,
                  })}
                  {...field}
                />
              )}
            />
            <label className="mandatory" htmlFor="inputtext">
              Address
            </label>
            {getFormErrorMessage("customerAddress")}
          </span>
        </div>
        <div className="field col-12 md:col-4">
          <span className="p-float-label">
            <Controller
              name="cityStateZip"
              control={control}
              rules={{
                required: "City,State,Zip is required.",
                validate: (value) => {
                  return value.trim().length<=0
                    ?"City,State,Zip is required.":true
                }, 
              }}
              render={({ field, fieldState }) => (
                <InputText
                  id="inputtext"
                  maxLength={100}
                  className={classNames("w-100 error-tooltip", {
                    error: fieldState.invalid,
                  })}
                  {...field}
                />
              )}
            />
            <label className="mandatory" htmlFor="inputtext">
              City, State, Zip
            </label>
            {getFormErrorMessage("cityStateZip")}
          </span>
        </div>
        <div className="field col-12 md:col-4">
          <span className="p-float-label">
            <Controller
              name="phone"
              control={control}
              rules={{ required: "Phone is required.",
              validate: (value) => {
                return value.trim().length<=0
                  ?"Enter a valid Phone Number.":true
              }, 
              pattern:{value:/^[0-9!@#$ %^&*()_+\-=\[\]{};':"\\|,.<>\/?None]*$/,message:"Enter a valid Phone Number"}}}
              render={({ field, fieldState }) => (
                <InputText
                  maxLength={13}
                  id="inputtext"
                  className={classNames("w-100 error-tooltip", {
                    error: fieldState.invalid,
                  })}
                  {...field}
                />
              )}
            />
            <label className="mandatory" htmlFor="inputtext">
              Phone
            </label>
            {getFormErrorMessage("phone")}
          </span>
        </div>
        <div className="field col-12 md:col-4">
          <span className="p-float-label">
            <Controller
              name="fax"
              control={control}
              rules={{ required: "Fax is required.",
              pattern:{value:/^[0-9!@#$ %^&*()_+\-=\[\]{};':"\\|,.<>\/?None]*$/,message:"Enter a valid Fax"}}}
              render={({ field, fieldState }) => (
                <InputText
                  maxLength={25}
                  id="inputtext"
                  className={classNames("w-100 error-tooltip", {
                    error: fieldState.invalid,
                  })}
                  {...field}
                />
              )}
            />
            <label className="mandatory" htmlFor="inputtext">
              Fax
            </label>
            {getFormErrorMessage("fax")}
          </span>
        </div>
        <div className="field col-12 md:col-4">
          <span className="p-float-label">
            <Controller
              name="email"
              control={control}
              rules={{
                required: "Email is required.",
                pattern: {
                  value: /^None$|^\S+@\S+\.\S+$/,
                  message: "Enter a valid Email",
                },
              }}
              render={({ field, fieldState }) => (
                <InputText
                  id="inputtext"
                  maxLength={100}
                  className={classNames("w-100 error-tooltip", {
                    error: fieldState.invalid,
                  })}
                  {...field}
                />
              )}
            />
            <label className="mandatory" htmlFor="inputtext">
              Email
            </label>
            {getFormErrorMessage("email")}
          </span>
        </div>
        <div className="field col-12 md:col-4">
          <span className="p-float-label">
            <Controller
              name="contact"
              control={control}
              rules={{ required: "Contact is required.",
              validate: (value) => {
                return value.trim().length<=0
                  ?"Contact is required.":true
              }, 
               }}
              render={({ field, fieldState }) => (
                <InputText
                  id="inputtext"
                  maxLength={100}
                  className={classNames("w-100 error-tooltip", {
                    error: fieldState.invalid,
                  })}
                  {...field}
                />
              )}
            />
            <label className="mandatory" htmlFor="inputtext">
              Contact
            </label>
            {getFormErrorMessage("contact")}
          </span>
        </div>
        <div className="field col-12 md:col-4">
          <span className="p-float-label">
            <Controller
              name="status"
              control={control}
              rules={{ required: "Status is required." }}
              render={({ field, fieldState }) => (
                <Dropdown
                  inputId="dropdown"
                  className={classNames("w-100 error-tooltip", {
                    error: fieldState.invalid,
                  })}
                  {...field}
                  value={field.value}
                  onChange={(e) => field.onChange(e.value)}
                  options={statusLookup}
                  optionLabel="name"
                  optionValue="name"
                />
              )}
            />
            <label className="mandatory" htmlFor="dropdown">
              Status
            </label>
            {getFormErrorMessage("status")}
          </span>
        </div>
        <div className="field col-12 md:col-4">
          <span className="p-float-label">
            <Controller
              name="accountExpiryDate"
              control={control}
              rules={{
                required: "Account Expiry Date is required.",
                validate: () => {
                  return getValues("accountExpiryDate") <= new Date()
                    ? "Account Expiry Date should be a future date"
                    : undefined;
                },
              }}
              render={({ field, fieldState }) => (
                <Calendar
                  id="calendar"
                  className={classNames("w-100 error-tooltip", {
                    error: fieldState.invalid,
                  })}
                  dateFormat="yy-mm-dd"
                  {...field}
                  value={field.value}
                  onChange={(e) => field.onChange(e.value)}
                />
              )}
            />
            <label className="mandatory" htmlFor="calendar">
              Account Expiry Date
            </label>
            {getFormErrorMessage("accountExpiryDate")}
          </span>
        </div>
      </form>
    </AddModal>
  );
};

export default AddCustomer;
