
import React, { memo, useRef, useState } from 'react'
//components
import GeneralForm from 'components/common/Forms/GeneralForm/GeneralForm';
import AddModal from 'components/Table/AddModal/AddModal'
import { ProgressSpinner } from 'primereact/progressspinner';
import { ProgressBar } from 'primereact/progressbar';


interface AddEquipmentPropType {
    showModal: boolean;
    datafields?: any;
    onHideModal?: () => void;
    onFormSubmit?: (body, reset) => void;
    uniqueEquipmentClassId?: any;
    showProgressBar?: boolean;
}
const AddEquipment: React.FC<AddEquipmentPropType> = (props) => {
    const { showModal, datafields, onHideModal = () => { }, onFormSubmit = () => { }, uniqueEquipmentClassId, showProgressBar } = props;
    const addform = useRef<any>(null);
    const [fieldFocus, setFieldFocus] = useState<boolean>(false);

    let body = {
        uniqueEquipmentClassId: uniqueEquipmentClassId
    }

    const formSubmitHandler = () => {
        addform?.current?.requestSubmit();
    }


    return (
        <AddModal
            header={"New Equipment"}
            showModal={showModal}
            setShowModal={onHideModal}
            OnSaveClick={formSubmitHandler}
            isAdd={true}
            style={{ width: "40vw", maxHeight: "95%" }}
        >
            {showProgressBar &&
                <div className='addEdit-overlay-spinner'>
                    <ProgressSpinner />
                </div>
             }
            <GeneralForm ref={addform} datafields={datafields} isEditForm={false} ddAppendTo='self' onFormSubmit={onFormSubmit} body={body} fieldFocus={fieldFocus} setFieldFocus={setFieldFocus}/>
        </AddModal>
    )
}

export default memo(AddEquipment)