import CheckBoxGridView from "components/Inspection/ReportMapping/CheckBoxGridView";
import {
  useEffect,
  useState,
  useRef,
  useCallback,
  forwardRef,
  useImperativeHandle,
  memo
} from "react";
import { useAppSelector } from "redux/hooks";
import { useDispatch } from "react-redux";
import {
  clearReportMappingSavedDataForQuestion,
  getReportDetailsForQuestion,
  onDownloadReportMappingDetailsForQuestions,
  onSavecontractReportMappingDataForQuestion,
  setDisplayColumns,
  setIsDisplayColumnsReadyToUpdate,
  setReportDetailsForQuestion,
  setSelectedDisplayColumns,
} from "../../../../redux/pages/Manage/InspectionSettings/ReportMapping/ReportMappingslice";
import { Button } from "primereact/button";
import InformationBox from "components/common/Message/InformationBox/InformationBox";
import TreeView from "components/common/TreeView/TreeView";
import { MdOutlineSort } from "react-icons/md";
import {
  BsFillPencilFill,
  BsList,
  BsPlusLg,
  BsTrashFill,
} from "react-icons/bs";
import { ScrollPanel } from "primereact/scrollpanel";
import { getFirstElement, modifiedUpdatedData } from "../CheckBoxGridUtility";
interface IQuestions {
  countryId: number;
  groupId: any;
}
const Questions = forwardRef<any, IQuestions>((props, ref) => {
  useImperativeHandle(ref, () => ({
    onSaveclick: () => {
      checkBoxGridRef?.current?.onSaveclick();
    },
    onDownloadclick: () => {
      onDownload();
    },
    onShowDisplaySettings: () => {
      checkBoxGridRef?.current?.onShowDisplaySettings();
    }
  }));
  const dispatch = useDispatch<any>();
  const checkBoxGridRef = useRef<any>();
  const [displayInfoModal, setDisplayInfoModal] = useState(false);
  const [treeData, setTreeData] = useState<any>([]);
  const [message, setMessage] = useState("");
  const [reportRowData, setReportRowData] = useState<any>([]);
  const [expandedKeys, setExpandedKeys] = useState({});
  const [selectedColumns, setSelectedColumns] = useState([]);

  const reportDetails: any = useAppSelector((state) => state.reportMapping.reportDetailsForQuestion);
  const savedQuestionsReportMappingData: any = useAppSelector((state) => state.reportMapping.reportMappingSavedDataForQuestion);
  const columnsReadyToUpdate : any = useAppSelector((state) => state.reportMapping.isDisplayColumnsReadyToUpdate);
  const displayColumns: any = useAppSelector((state) => state.reportMapping.displayColumns)

  const [treeToggle, setTreetoggle] = useState(true);
  const [selectedItem, setSelectedItem] = useState<any>(null);

  useEffect(() => {
    if (props.countryId && props.groupId !== undefined) {
      setTreeData([]);
      setReportRowData([]);
      dispatch(getReportDetailsForQuestion(props.countryId, props.groupId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.countryId, props.groupId]);
  useEffect(() => {
    if (reportDetails) {
      let value: any = JSON.stringify(reportDetails?.reportGroupFields);
      value = value.replace(/\"reportGroupSectionId\":/g, '"key":');
      value = value.replace(/\"reportGroupSectionName\":/g, '"label":');
      value = value.replace(/\"reportGroupSections\":/g, '"children":');
      value = JSON.parse(value);
      setTreeData(value);
      let element = [];
      const firstElement = getFirstElement(value, element);
      let convItem = JSON.parse(JSON.stringify(reportDetails?.reportDetails));
      convItem?.forEach(element => {
        element.header = element?.reportName;
      });
      if(columnsReadyToUpdate){
          dispatch(setDisplayColumns(convItem))
        }
      setReportRowData(firstElement?.reportFields);
      setSelectedItem(firstElement?.key);
      dispatch(setReportDetailsForQuestion(""));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [reportDetails]);

  useEffect(() => {
    if(displayColumns && displayColumns.length) {
       if(columnsReadyToUpdate)
      dispatch(setSelectedDisplayColumns(displayColumns));
      dispatch(setIsDisplayColumnsReadyToUpdate(false))
    }
  },[displayColumns])

  useEffect(() => {
    if (treeData?.length) {
      let _expandedKeys = {};
      for (let node of treeData) {
        expandNode(node, _expandedKeys);
      }
      setExpandedKeys(_expandedKeys);
    }
  }, [treeData])

  const expandNode = (node, _expandedKeys) => {
    if (node.children && node.children.length) {
      _expandedKeys[node.key] = true;

      for (let child of node.children) {
        expandNode(child, _expandedKeys);
      }
    }
  }

  useEffect(() => {
    if (savedQuestionsReportMappingData) {
      setDisplayInfoModal(true);
      setMessage("Questions Report Mapping Saved");
      dispatch(clearReportMappingSavedDataForQuestion(""));
      const result = modifiedUpdatedData(treeData, selectedItem, savedQuestionsReportMappingData);
      setTreeData(result);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [savedQuestionsReportMappingData]);
  const onSaveContractReportMapping = (data: any) => {
    const body = {
      reportGroupFields: data,
    };
    dispatch(
      onSavecontractReportMappingDataForQuestion(
        props.countryId,
        props.groupId,
        body
      )
    );
  };

  const onNodeClick = useCallback((data) => {
    if (data?.reportFields) {
      setReportRowData(data?.reportFields);
    }
    setSelectedItem(data?.key);
  }, []);

  const getColumns = useCallback((columns) => {
    setSelectedColumns(columns);
  }, []);

  const onDownload = () => {
    if (!selectedColumns?.length) return;
    let reportIds: any = [];
    selectedColumns.forEach((element: any) => {
      reportIds.push(element.reportId)
    });
    const body = {
      reportIds: reportIds
    }
    dispatch(onDownloadReportMappingDetailsForQuestions(props.countryId, props.groupId, body))
  }

  return (
    <div className="questions">
      {treeToggle ? (
        <div className="tree-wrapper">
          <div className="treeview-scroll">
            <ScrollPanel className="treeview-scroll">
              <TreeView
                selectionMode="single"
                onNodeClick={onNodeClick}
                treeData={treeData}
                selectedItem={selectedItem}
                onToggle={e => setExpandedKeys(e.value)}
                expandedKeys={expandedKeys}
                isCustomTemplateForNode = {true }
              />
            </ScrollPanel>
          </div>
        </div>
      ) : (
        <div className="expanded" onClick={() => setTreetoggle(!treeToggle)}>
          <BsList />
        </div>
      )}
      <div className={`report-mapping-grid ${treeToggle ? "" : "expand"}`}>
        <CheckBoxGridView
          ref={checkBoxGridRef}
          gridData={reportRowData}
          columnData={displayColumns}
          onSaveCheckBoxData={onSaveContractReportMapping}
          isQuestionGridView={true}
          getColumns={getColumns}
        />
      </div>
      <InformationBox
        showInfoModal={displayInfoModal}
        setShowInfoModal={setDisplayInfoModal}
        message={message}
      />
    </div>
  );
});

export default memo(Questions);
