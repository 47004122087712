import React, { useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { Dropdown } from 'primereact/dropdown';
import { BsExclamationCircleFill } from "react-icons/bs";

import AddModal from "components/Table/AddModal/AddModal";
import { useAppSelector } from "redux/hooks";
import { useDispatch } from "react-redux";
import { getBuildingListLookup, getFloorDetails } from "redux/pages/Administration/Floor/floorSlice";

interface Props {
  showModal: boolean;
  setShowModal: (state: boolean) => void;
  selectedRow: any;
  onEditFloor: (data: any) => void;
}

type FormData = {
  uniqueBuildingId: any;
  floorName: string;
};

export const EditFloors: React.FC<Props> = ({
  showModal = false,
  setShowModal = () => {},
  selectedRow = [],
  onEditFloor = () => {},
}) => {
  const editForm: any = useRef();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<FormData>({
    defaultValues: {
      uniqueBuildingId: "",
      floorName: "",
    },
  });

  const buildngDetails = useAppSelector((state) => state.floor.buildingList);
  const floorDetails = useAppSelector((state) => state.floor.floorDetails);

  const [buildingLookup, setBuildingLookup] = useState<any>([]);

  const dispatch = useDispatch<any>();

  useEffect(() => {
    let body = {
      uniqueSiteId: selectedRow?.uniqueSiteId,
    };
    dispatch(getBuildingListLookup(selectedRow?.uniqueSiteId, body));
    dispatch(getFloorDetails(selectedRow?.uniqueSiteId, selectedRow?.uniqueBuildingId, selectedRow?.uniqueFloorId))
  }, [])

  useEffect(() => {
    if (buildngDetails) {
      let building: any = [];
      buildngDetails.map((item: any) => {
        building.push({uniqueBuildingId: item.uniqueBuildingId, buildingName: item.buildingName})
      })
      setBuildingLookup(building)
    }
  }, [buildngDetails])

  useEffect(() => {
    if (floorDetails) {
      setValue("floorName", floorDetails.floorName);
      setValue("uniqueBuildingId", floorDetails.building?.uniqueBuildingId);
    }
  },[floorDetails])

  const formSubmitHandler = (data: any) => {
    editForm.current.requestSubmit();
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && (
        <span className="tooltip-text">
          <BsExclamationCircleFill />
          {errors[name].message}
        </span>
      )
    );
  };

  const onEditFormSubmit = (data: any) => {
    data.floorName = data.floorName.trim();
    onEditFloor && onEditFloor(data);
  };

  const onError = (errors: any, e: any) => {};

  return (
    <>
      <AddModal
        header="Edit Floor"
        showModal={showModal}
        isAdd={false}
        setShowModal={setShowModal}
        style={{ width: "35vw", height: "38vh" }}
        OnSaveClick={formSubmitHandler}
      >
        <form
          onSubmit={handleSubmit(onEditFormSubmit, onError)}
          ref={editForm}
        >
           <div className="row px-2 pt-2 pb-2">
                        <div className="field col-12 md:col-2 mb-0 pb-0">
                            <span className="p-float-label">
                                <Controller
                                    name="uniqueBuildingId"
                                    control={control}
                                    rules={{
                                        required: 'Building Name is required.'
                                    }}
                                    render={({ field, fieldState }) => (
                                        <Dropdown
                                            inputId="BuildingName"
                                            className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })}
                                            {...field}
                                            value={field.value}
                                            onChange={(e) => field.onChange(e.value)}
                                            options={buildingLookup}
                                            optionLabel="buildingName"
                                            optionValue="uniqueBuildingId"
                                        />
                                    )} />
                                <label className='mandatory' htmlFor="dropdown">Building Name</label>
                                {getFormErrorMessage('uniqueBuildingId')}
                            </span>
                        </div>
                        
                    </div>

          <div className="row px-2 pb-2">
            <div className="field col-12 md:col-2 mb-0">
              <span className="p-float-label">
                <Controller
                  name="floorName"
                  control={control}
                  rules={{
                    required: "Floor Name is required.",
                  }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id="name"
                      className={classNames("w-100 error-tooltip", {
                        error: fieldState.invalid,
                      })}
                      maxLength={100}
                      {...field}
                    />
                  )}
                />
                <label className="mandatory" htmlFor="inputtext">
                Floor Name
                </label>
                {getFormErrorMessage("floorName")}
              </span>
            </div>
          </div>
        </form>
      </AddModal>
    </>
  );
};

export default EditFloors;
