import { Button } from 'primereact/button'
import React from 'react'
import { BsFillPencilFill, BsPlusLg, BsTrashFill } from 'react-icons/bs'
import { FaCog, FaDownload } from 'react-icons/fa'

const NFPAClassificationsDocument = () => {
  return (
    <>
      <h4 className="doc-title mb-2">
        NFPA Classifications
      </h4>

      <p>
        NFPA (National Fire Protection Association) publishes comprehensive set of regulations for fire 
        protection, electrical safety and building safety. NFPA has chartered 380 codes and standards that are 
        designed to minimize the risk and effects of fire by establishing criteria for building, processing, 
        design, service, and installation in different countries.
      </p>

      <p>
        As per NFPA, equipment in a Fire Safety System is classified under a standard NFPA Classification, 
        which is mapped against its Equipment Type. The classification could vary depending on the country.
      </p>

      <p>
        This option allows the users to classify the equipment based on NFPA standards. 
        Users can select Country from the list box and add/edit/delete NFPA Classifications associated to them.
      </p>

      <p>
        <span className="doc-subtitle"><b>Add:</b></span>
      </p>
      <p>
        Create an NFPA Classification by clicking the <span className="doc-subtitle">Add</span> {" "}
        <Button className="doc-button-icon"> 
          <BsPlusLg className="icon" title='Add'/> 
        </Button> {" "} icon. User can enter the NFPA Code, 
        Report Classification, NFPA Classification and Health Classification from its corresponding combo boxes.
        The Health Classification is not a mandatory field and the values will be listed in its list box only 
        if the selected Country has Health Classification values associated to it. 
        Equipment Type(s) relevant to NFPA classification can be selected from the check boxes displayed against
        it. Enter the values and click Save button.
      </p>

      <p>
        <span className="doc-subtitle"><b>Edit:</b></span>
      </p>
      <p>
        An NFPA Classification can be edited by selecting it and then clicking the <span className="doc-subtitle"> Edit</span> {' '}
        <Button className="doc-button-icon"> 
          <BsFillPencilFill className="icon" title='Edit' /> 
        </Button> {' '} icon.  After making necessary changes 
        click the Save Changes button to save the changes.
      </p>


      <p>
        <span className="doc-subtitle"><b>Delete:</b></span>
      </p>
      <p>
        Delete an NFPA Classification by selecting it and then clicking the <span className="doc-subtitle">Delete</span> {" "}
        <Button className="doc-button-icon"> 
          <BsTrashFill className="icon" title='Delete' /> 
        </Button>{" "} icon. 
      </p>

      <p><span className="doc-subtitle"><b>Download:</b></span>{" "}
        <Button className="doc-button-icon">
          <FaDownload className="icon" title='Download' />
        </Button>{" "}
      </p>
      <p>
        This option lists Report All, Report Highlighted, Export All, Export Highlighted and Group Highlighted. 
      </p>

      <p>
        <span className="doc-subtitle">Report All: </span>
      </p>

      <p>
        This option is for generating a report with all the records in the data grid. The report can be saved to PDF, 
        Text, Rich Text or Excel format by selecting the file format from the list under Save option, and then clicking OK button after 
        selecting the required options from the Export Settings window.
      </p>

      <p>
        <span className="doc-subtitle">Report Highlighted: </span>
      </p>
      <p>
        This option is for generating a report with only selected records in the grid. Select the desired rows by 
        holding down the control key and clicking on each of the records to be selected. Users can also select a set of rows 
        using the shift key. The selected records will be highlighted. Click on the <span className="doc-subtitle">Report Highlighted </span> 
        option in the <span className="doc-subtitle">Download </span> {" "}
        <Button className="doc-button-icon">
          <FaDownload className="icon" title='Download'/>
        </Button>{" "} menu to generate the report. The report will be created with the 
        highlighted records only. The report can be saved to PDF, Text, Rich Text or Excel format by selecting the file format from the list 
        under Save option, and then clicking OK button after selecting the required options from the Export Settings window.
      </p>
      <p>
        <span className="doc-subtitle"><b>More:</b></span>{' '}
        <Button className="doc-button-icon">
          <FaCog className="icon" title='More'/>
        </Button>{" "}
      </p>
      <p>
        This option lists Display Settings option. 
      </p>

      <p>
        <span className="doc-subtitle">Display Settings:</span>
      </p>
      <p>
        This option allows the user to change the visibility of the data fields displayed in the data grid. On selecting this option, 
        all the available data fields are displayed in a window. Display settings can be modified by selecting the required fields from the list. 
        The grid settings modified through this option will not be saved and will be effective only for the current instance of the data grid. 
      </p>


    </>
  );
}
export default NFPAClassificationsDocument;
