import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../../store";
import { baseURL, setAuthHeader } from "../../../service/HttpService";

export interface LoginState {
	isAuthenticated: boolean;
	isTokenGenerating: boolean;
	accessToken: any;
}

const initialState: LoginState = {
	isAuthenticated: false,
	isTokenGenerating: false,
	accessToken: null
};

const LoginSlice = createSlice({
	name: "login",
	initialState,
	reducers: {
		setIsAuthenticated: (state, { payload }: PayloadAction<any>) => {
			state.isAuthenticated = payload
		},
		setTokenProgress: (state, { payload }: PayloadAction<any>) => {
			state.isTokenGenerating = payload
		},
		setAccessToken: (state, { payload }: PayloadAction<any>) => {
			state.accessToken = payload
		}
	},
});

export const getToken = (): AppThunk => async (dispatch) => {
	dispatch(setTokenProgress(true));
	
	try {
		const res = await fetch(`${baseURL}api/v1/auth/tokenmanager/accesstoken`, {
			method: "GET",
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			}
		}).then((res) => res.json());

		if (res.accessToken == null) {
			document.location.href = `${baseURL}api/v1/auth/signin`;
		}
		sessionStorage.setItem("accesstoken", "Bearer " + res.accessToken);
		setAuthHeader();
		dispatch(setAccessToken(res));
		dispatch(setTokenProgress(false));
	}
	catch (e: any) {
		dispatch(setTokenProgress(false));
		dispatch(setAccessToken({}));
	}
};

export const { setIsAuthenticated, setTokenProgress, setAccessToken } = LoginSlice.actions;
export default LoginSlice.reducer;
