import React, { useCallback, useEffect, useRef, useState} from "react";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { ProgressBar } from "primereact/progressbar";

import { DEFAULT_HEADER_LIST } from "../../InspectionTasksData";
import EquipmentList from "./Equipment/EquipmentList";
import Contracts from "./Contracts/Contracts";
import Summary from "./Summary/Summary";
import Systems from "./Systems/Systems";
import Questions from "./Questions/Questions";

import { useDispatch } from "react-redux";
import { useAppSelector } from "redux/hooks";
import { getCheckSystemParseInProgress, getXMLImportStatus, setXMLImportStatus, setCheckSystemParseInProgress, setEquipmentPanelList, setEquipmentList, setEquipmentTypeList, setUserDefaultEquipmentClass, addUserDefaultEquipmentClass, setIsEquipmentClassDropdownChanged, getEquipmentPanelList, getEquipmentTypeListWithDefaultEquipmentClass, getEquipmentList } from "redux/pages/Inspection/InspectionTask/InspectionDetails/Equipment/EquipmentSlice";
import { setInspectionQuestion, setInspectionQuestionFieldValues, setMonitoringValues, setUpdatedQuestionDatafields } from "redux/pages/Inspection/InspectionTask/InspectionDetails/InspectionDetails/InspectionDetailsSlice";
import { setSelectedFailedEquipment } from "redux/pages/Dashboard/dashboardSlice";

import { showUI } from 'service/GeneralUtility';

interface ListItem {
    id: number;
    name: string;
    selected: boolean;
}

interface IInspectionDetails {
    showModal: boolean;
    setShowModal: (value: boolean) => void;
    taskWithoutBranchOffice: boolean;
    setShowReportModal?: (value: boolean) => void;
    selectedTask?: any;
    selectedReports?: any;
    inspectionTaskGridRefresh?: () => void;
    
}

const EQPGROUP_ACTIVETAB = {
    ADD: "ADD",
    EDIT: "EDIT",
};

export const InspectionDetails: React.FC<IInspectionDetails> = (props) => {
    const { showModal = false, setShowModal = () => { }, selectedTask, selectedReports, setShowReportModal = () => { }, taskWithoutBranchOffice = false} = props;
    let {inspectionTaskGridRefresh = () => {}} = props;
    const checkSystemParseInProgress = useAppSelector((state) => state.equipment.checkSystemParseInProgress);
    const xmlImportStatus = useAppSelector((state) => state.equipment.xmlImportStatus);
    const userPrivileges = useAppSelector((state) => state.user.userPrivileges);

    const [headerList, setHeaderList] = useState<Array<ListItem>>(DEFAULT_HEADER_LIST);
    const [progressValue, setProgressValue] = useState(0);
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [showXmlImportComplete, setShowXmlImportComplete] = useState(false);
    const [message, setMessage] = useState("");
    const [displayConfirmModal, setDisplayConfirmModal] = useState(false);
    const [clickedNode, setClickedNode] = useState<any>(null);
    const [closeBtnClicked, setCloseBtnClicked] = useState<boolean>(false);
    const [reportBtnClicked, setReportBtnClicked] = useState<boolean>(false);
    const dispatch = useDispatch<any>();
    const interval = useRef<any>(null);
    const isFieldValueChanged = useRef<any>(null);
    const userDefaultEquipmentClass = useAppSelector((state) => state.equipment.userDefaultEquipmentClass);
    const isEquipmentClassDropdownChanged = useAppSelector((state) => state.equipment.isEquipmentClassDropdownChanged);

    useEffect(() => {
        headerList.forEach(element => {
            element.selected = false;
        });
        if (taskWithoutBranchOffice) {
            headerList[0].selected = true;
        } else {
            headerList[3].selected = true;
        }
        setHeaderList([...headerList]);
    }, [])

    const handleListClick = (id: number) => {
        if(isFieldValueChanged.current && selectedTask?.status === "Active" && checkPrivilageForSave()) {
            setClickedNode(id);
            setDisplayConfirmModal(true);
        } else {
            setClickedNode(null);
            changeHeaderSelectedItem(id);
        }
    };

    const changeHeaderSelectedItem = (id: number) => {
        const updatedList = headerList.map((item: ListItem) => {
            if (item?.id === id) {
                item.selected = true;
            } else {
                item.selected = false;
            }
            return item;
        });

        setHeaderList(updatedList);
    };


    useEffect(() => {      
        if(selectedTask && selectedTask.system){
            dispatch(getCheckSystemParseInProgress(selectedTask?.system?.uniqueSystemId))
        }
    }, [selectedTask])

    useEffect(() => {
        if (selectedTask && checkSystemParseInProgress) {
            if (checkSystemParseInProgress.isSystemParseInProgress) {
                setShowProgressBar(true);
                interval.current = setInterval(() => {
                    const body = {
                        uniqueSystemId: selectedTask?.system?.uniqueSystemId,
                        UniqueInspectionTaskId: selectedTask?.uniqueInspectionTaskId
                    }
                    dispatch(getXMLImportStatus(body))
                }, 1000);

                return () => {
                    clearInterval(interval.current);
                }
            }
        }
    }, [selectedTask, checkSystemParseInProgress])

    useEffect(()=>{
        if (selectedTask && xmlImportStatus) {
            if (xmlImportStatus.isParseCompleted) { 
                setProgressValue(100);
                setShowProgressBar(false);         
                setShowXmlImportComplete(true);
                clearInterval(interval.current);
                dispatch(getEquipmentPanelList(selectedTask?.uniqueInspectionTaskId));
			    dispatch(getEquipmentTypeListWithDefaultEquipmentClass(userDefaultEquipmentClass,selectedTask?.uniqueInspectionTaskId));
                const body = {
                    uniquePanelId: selectedTask.selectedEquipmentGroup,
                    uniqueEquipmentClassId: selectedTask.selectedEquipmentType !== "All" ? selectedTask.selectedEquipmentType : null,
                    uniqueSystemId: selectedTask?.system?.uniqueSystemId,
                    inspectionTaskStatus: selectedTask?.status,
                    filterType: "NONE"
                };
                dispatch(getEquipmentList(selectedTask?.uniqueInspectionTaskId, body, 0));
                dispatch(setXMLImportStatus(""));
            } else {
                let currentValue = xmlImportStatus.progress ? parseInt(xmlImportStatus.progress) : 0;
                if (currentValue >= 100) {
                    currentValue = 100;      
                    setShowProgressBar(false);         
                    setShowXmlImportComplete(true);
                    clearInterval(interval.current);
                    dispatch(getEquipmentPanelList(selectedTask?.uniqueInspectionTaskId));
                    const body = {
                        uniquePanelId: selectedTask.selectedEquipmentGroup,
                        uniqueEquipmentClassId: selectedTask.selectedEquipmentType !== "All" ? selectedTask.selectedEquipmentType : null,
                        uniqueSystemId: selectedTask?.system?.uniqueSystemId,
                        inspectionTaskStatus: selectedTask?.status,
                        filterType: "NONE"
                    };
                    dispatch(getEquipmentList(selectedTask?.uniqueInspectionTaskId, body, 0));
                }
                setProgressValue(currentValue);
                dispatch(setXMLImportStatus(""));
            }    
        }
    },[selectedTask,xmlImportStatus]);

    useEffect(() => {
        if (progressValue) {
            if (progressValue >= 100) {
                setTimeout(() => {
                    setShowXmlImportComplete(false);
                }, 5000);                
            }
        }
    }, [progressValue]);

    useEffect(() => {
        if (displayConfirmModal) {
            headerList.map((item, key) => {
                if (item.id == 1 && item.selected === true) {
                    setMessage("Do you want to save the Contract Details?");
                } else if (item.id == 2 && item.selected === true) {
                    setMessage("Do you want to save the System Details?");
                } else if (item.id == 3 && item.selected === true) {
                    setMessage("Do you want to save the changes to");
                }
            });
        }
    }, [displayConfirmModal]);

    const InspectionTaskRefresh = (callback:any) =>{
            inspectionTaskGridRefresh = callback
    }

    const onCloseBtnClick = () => {
        if(isEquipmentClassDropdownChanged)
        {
        const body = {
            equipmentClassId:  userDefaultEquipmentClass == "All" ? 0 : userDefaultEquipmentClass,
            uniqueInspectionTaskId : selectedTask?.uniqueInspectionTaskId
        };
        dispatch(addUserDefaultEquipmentClass(body))
        dispatch(setIsEquipmentClassDropdownChanged(false))
    }
        dispatch(setUserDefaultEquipmentClass(''))
        setCloseBtnClicked(true);
        if (isFieldValueChanged.current && selectedTask?.status === "Active" && checkPrivilageForSave()) {
            setDisplayConfirmModal(true);
        } else {
            hideDialog(); 
        }
        inspectionTaskGridRefresh();
    };

    const hideDialog = () => {
        dispatch(setXMLImportStatus(""));
        dispatch(setCheckSystemParseInProgress(""));
        dispatch(setSelectedFailedEquipment(""));
        dispatch(setEquipmentList(""));
        dispatch(setEquipmentTypeList(""));
        dispatch(setEquipmentPanelList(""));
        dispatch(setInspectionQuestion(""));
        dispatch(setInspectionQuestionFieldValues(""));
        dispatch(setUpdatedQuestionDatafields(""));
        dispatch(setMonitoringValues(""));
        setShowModal(false);
    }

    const cancelUpdate = () => {
        if (clickedNode) {
            changeHeaderSelectedItem(clickedNode);
        } else {
            if(closeBtnClicked) {
                hideDialog();
                setCloseBtnClicked(false);
            }
        }
        if(reportBtnClicked) {
            setShowReportModal(true);
            setReportBtnClicked(false);
        }
        setDisplayConfirmModal(false);
        isFieldValueChanged.current = null;
        setClickedNode(null);
    };

    const hideConfirmModal = () => {
        setDisplayConfirmModal(false);
        setClickedNode(null);
        setCloseBtnClicked(false);
        setReportBtnClicked(false);
    };

    const checkPrivilageForSave = () => {
        let permitToSave;

        headerList.map((item, key) => {
            if (item.id == 1 && item.selected === true) {
                showUI(userPrivileges, "SIETAP_INSPECTIONDETAILS_CONTRACT_SAVE") ? permitToSave = true : permitToSave = false;
            } else if (item.id == 2 && item.selected === true) {
                showUI(userPrivileges, "SIETAP_INSPECTIONDETAILS_SYSTEM_SAVE") ? permitToSave = true : permitToSave = false;
            } else if (item.id == 3 && item.selected === true) {
                permitToSave = true;
            }
        });
        return permitToSave;
    };

    const onReportSelection = () => {
        if (isFieldValueChanged.current && selectedTask?.status === "Active" && checkPrivilageForSave()) {
            setReportBtnClicked(true);
            setDisplayConfirmModal(true);
        } else {
            setShowReportModal(true);
        }
    };

    const fieldValuesUpdated = useCallback((data) => {
        isFieldValueChanged.current = data ? data : null;
    },[]);

    return (
        <Dialog showHeader={false} visible={showModal} onHide={() => setShowModal(false)} className="equipment-list">
            <div className="custom-header">
                <div className="row">
                    <div className="col-3 box py-1 pr-0 pl-1">
                        <div className="title">Task</div>
                        <div className="sub-title" title={selectedTask?.inspectionTaskName}>{selectedTask?.inspectionTaskName}</div>
                    </div>
                    <div className="col-3 box py-1 pr-0 pl-1">
                        <div className="title">Contract</div>
                        <div className="sub-title" title={selectedTask?.contractName}>{selectedTask?.contractName}</div>
                    </div>
                    <div className="col-3 box py-1 pr-0 pl-1">
                        <div className="title">System</div>
                        <div className="sub-title" title={selectedTask?.systemNames}>{selectedTask?.systemNames}</div>
                    </div>
                    <div className="col-3 box py-1 pl-0 align-items-end pr-1">
                        <i className="pi pi-times" onClick={() => onCloseBtnClick()}></i>
                    </div>
                </div>
            </div>

            <div className="custom-table-header">
                <div className="row px-2">
                    <div className="col-12 tab-col">
                        <ul className="list">
                            {headerList?.length > 0 &&
                                headerList.map((item: ListItem) => (
                                    <li
                                        key={item?.id}
                                        role="button"
                                        className={classNames("item", { selected: item?.selected })}
                                        onClick={() => handleListClick(item?.id)}
                                    >
                                        {item?.name}
                                    </li>
                                ))}
                        </ul>
                        <div className="report-progress">
                            {(showProgressBar) ? 
                                (<div className="progressBar">
                                    <ProgressBar value={progressValue}></ProgressBar>
                                </div>) 
                                : (<></>)
                            }
                            {showXmlImportComplete && 
                                <span className="progressBar-msg">XML Importing Completed</span>
                            }
                            {(showUI(userPrivileges,"SIETAP_INSPECTIONS_REPORTSSELECTED")) && 
                                <div className="report-section d-flex align-items-center">
                                    <Button 
                                        label={`Report Selected (${selectedReports?.length || 0})`} 
                                        aria-label="Report Selected " 
                                        className="check-btnLink"
                                        onClick={onReportSelection} 
                                    />
                                </div> 
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className="grid-wrapper">
                {headerList.map((item, key) => {
                    if (item.id == 1 && item.selected === true) {
                        return (
                            <Contracts
                                key={key}
                                selectedTask={selectedTask}
                                setIsValueChanged={fieldValuesUpdated}
                                displayConfirmModal={displayConfirmModal}
                                setDisplayConfirmModal={setDisplayConfirmModal}
                                cancelUpdate={cancelUpdate}
                                confirmMessage={message}
                                hideConfirmModal={hideConfirmModal}
                                reportBtnClicked={reportBtnClicked}
                            />);
                    } else if (item.id == 2 && item.selected === true) {
                        return (
                            <Systems
                                key={key}
                                selectedTask={selectedTask}
                                setIsValueChanged={fieldValuesUpdated}
                                displayConfirmModal={displayConfirmModal}
                                setDisplayConfirmModal={setDisplayConfirmModal}
                                cancelUpdate={cancelUpdate}
                                confirmMessage={message}
                                hideConfirmModal={hideConfirmModal}
                                reportBtnClicked={reportBtnClicked}
                            />);
                    } else if (item.id == 3 && item.selected === true) {
                        return (
                            <Questions
                                key={key}
                                selectedTask={selectedTask}
                                setIsValueChanged={fieldValuesUpdated}
                                displayConfirmModal={displayConfirmModal}
                                setDisplayConfirmModal={setDisplayConfirmModal}
                                cancelUpdate={cancelUpdate}
                                confirmMessage={message}
                                setConfirmMessage={setMessage}
                                hideConfirmModal={hideConfirmModal}
                                reportBtnClicked={reportBtnClicked}
                            />);
                    } else if (item.id == 4 && item.selected === true) {
                        return <EquipmentList key={key} selectedTask={selectedTask} />;
                    } else if (item.id == 5 && item.selected === true) {
                        return <Summary key={key} selectedTask={selectedTask} />;
                    }
                })}
            </div>

        </Dialog>
    );
};

export default InspectionDetails;
