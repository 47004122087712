export const UNLINKEDEQUIPMENTGROUPS_COLS = {
    LIST_COLS: [
        {
            field: 'address',
            header: 'Address',
            style: { flex: '1 0 200px' },
            sortable: false,
        },
        {
            field: 'panelType',
            header: 'Type',
            style: { flex: '1 0 200px' },
            sortable: false,
        },
        {
            field: 'name',
            header: 'Name',
            style: { flex: '1 0 200px' },
            sortable: false,
        }
    ]
};