
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { zlink, baseURL } from "../../../../service/HttpService";
import { setProgressFlag } from "redux/common/commonSlice";
import { AppThunk } from "../../../store";
import { fetchLoggedInUserDetails } from "redux/pages/Administration/administrationSlice";

export interface InspectionReportState {
  errors: any;
  systemList: any;
  systemSummary: any;
  selectedSystemDetails: any;
  generatedReportDetails:any;
  reportList:any;
  reportListPageDetails: any;
  deletedReportDetails:any;
  viewDraftReport:any;
  viewFinalReport:any;
  downloadDraftReport:any;
  downloadFinalReport:any;
  isFinalReportUploaded:any;
  isInspectiontaskcompleted:any;
}

let initialState: InspectionReportState = {
  errors: "",
  systemList: [],
  systemSummary: "",
  selectedSystemDetails: "",
  generatedReportDetails:"",
  reportList:[],
  reportListPageDetails:"",
  deletedReportDetails:"",
  viewDraftReport:"",
  viewFinalReport:"",
  downloadDraftReport:"",
  downloadFinalReport:"",
  isFinalReportUploaded:[],
  isInspectiontaskcompleted:[],

};

const InspectionReportSlice = createSlice({
  name: "inspectionReport",
  initialState,
  reducers: {
    setErrors: (state, { payload }: PayloadAction<any>) => {
      state.errors = payload;
    },

    setSystemList: (state, { payload }: PayloadAction<any>) => {
      state.systemList = payload;
    },
    setSystemSummary: (state, { payload }: PayloadAction<any>) => {
      state.systemSummary = payload;
    },
    clearSystemSummary: (state, { payload }: PayloadAction<any>) => {
      state.systemSummary = payload;
    },
    setSelectedSystemDetails: (state, { payload }: PayloadAction<any>) => {
      state.selectedSystemDetails = payload;
    },
    setGeneratedReportDetails: (state, { payload }: PayloadAction<any>) => {
      state.generatedReportDetails = payload;
    },
    clearGeneratedReportDetails:(state, { payload }: PayloadAction<any>) => {
      state.generatedReportDetails = payload;
    },
    setReportList: (state, { payload }: PayloadAction<any>) => {
      state.reportList = payload;
    },
    setReportListPageDetails: (state, {payload}: PayloadAction<any>) => {
      state.reportListPageDetails = payload;
    },
    setDeletedReportDetails: (state, {payload}: PayloadAction<any>) => {
      state.deletedReportDetails = payload;
    },
    clearDeletedReportDetails:(state, {payload}: PayloadAction<any>) => {
      state.deletedReportDetails = payload;
    },
    setViewDraftReport: (state, {payload}: PayloadAction<any>) => {
      state.viewDraftReport = payload;
    },
    setViewFinalReport: (state, {payload}: PayloadAction<any>) => {
      state.viewFinalReport = payload;
    },
    setDownloadDraftReport: (state, {payload}: PayloadAction<any>) => {
      state.downloadDraftReport = payload;
    },
    setDownloadFinalReport: (state, {payload}: PayloadAction<any>) => {
			state.downloadFinalReport = payload;
		},
    setisFinalReportUploaded: (state, { payload }: PayloadAction<any>) => {
      state.isFinalReportUploaded = payload
    },
    setIsInspectionTaskCompleted: (state, { payload }: PayloadAction<any>) => {
      state.isInspectiontaskcompleted = payload
    },
    setUpdatedReportList: (state, { payload }: PayloadAction<any>) => {
			let currentIndex = state.reportList.findIndex((item: any) => item.systemPanelInspectionReportId === payload?.systemPanelInspectionReportId);
			state.reportList = state.reportList.filter((item: any) => item.systemPanelInspectionReportId !== payload?.systemPanelInspectionReportId);
			state.reportList.splice(currentIndex, 0, payload);
		},
  },
});

export const getSystemList = (): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.get(`${baseURL}api/v1/inspection/systems?isALLDetails=true`);
    if (res.status === "done") {
      dispatch(setSystemList(res?.data?.systemDetails));
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

// To list systems with status Closed, to view reports of such systems
export const getAllSystemList = (): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.get(`${baseURL}api/v1/inspection/allsystems`);
    if (res.status === "done") {
      dispatch(setSystemList(res?.data?.systemDetails));
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};


export const getSystemSummary = (systemId: any, taskHistoryFilter: any): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.get(`${baseURL}api/v1/inspection/systems/${systemId}/systemsummary?taskHistoryFilter=${taskHistoryFilter}`);
    if (res.status === "done") {
      if (res.data) {
        dispatch(setSystemSummary(res.data));
      }
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const setDefaultSystemDetails = (systemId: number): AppThunk => async (dispatch) => {
  const body = { activeSystemId: systemId };
  try {
    const res = await zlink.post(`${baseURL}api/v1/admin/user/defaults`, body);
    if (res.status === "done") {
      dispatch(fetchLoggedInUserDetails());
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const onGenerateReport = (systemId:number,body:any ): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.post(`${baseURL}api/v1/inspection/systems/${systemId}/generatereports`, body);
    if (res.status === "done") {
      dispatch(setGeneratedReportDetails(res));
      dispatch(setProgressFlag(false));
    } else {
      dispatch(setErrors(res.error));
      dispatch(setProgressFlag(false));
    }
  } catch (error) {
    dispatch(setErrors(error));
    dispatch(setProgressFlag(false));
  }
};

export const getReportList = (systemId: string, currentStart: number, sorField = "", sortOrder = ""): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.get(`${baseURL}api/v1/inspection/systems/${systemId}/reports?currentStart=${currentStart}&sortcolumn=${sorField}&sortorder=${sortOrder}`);
    if (res.status === "done") {
      dispatch(setReportList(res?.data?.inspectionReports));
      dispatch(setReportListPageDetails(res?.data?.pageDetails))
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const checkFinalReportUploaded = (systemId:string, body:any): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.post(`${baseURL}api/v1/inspection/systems/${systemId}/reports/checkfinalreportuploaded`,body);
    if (res.status === "done") {
      dispatch(setisFinalReportUploaded(res?.data));
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};
export const checkIsInspectionTaskCompleted = (systemId:string, body:any,setIsDelete?): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.post(`${baseURL}api/v1/inspection/systems/${systemId}/reports/Checkinspectiontaskiscompleted`,body);
    if (res.status === "done") {
      dispatch(setIsInspectionTaskCompleted(res?.data));
      setIsDelete(true);
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};



export const onRemoveReports = (systemId:string, body:any): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.post(`${baseURL}api/v1/inspection/systems/${systemId}/reports/remove`,body);
    if (res.status === "done") {
      dispatch(setDeletedReportDetails(body));
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const onViewDraftReport = (systemId: string, reportId: any, setStreamData: any, setShowViewModal: any, setFileName: any, setMessage: any, setDisplayInfoModal: any): AppThunk => async (dispatch) => {
  try {
    dispatch(setProgressFlag(true))
    const request: any = {
      method: "GET"
    };
    const fetchUrl = `${baseURL}api/v1/inspection/systems/${systemId}/reports/${reportId}/draft/view`;
    await fetch(fetchUrl, request).then((response: any) => {

      if (response && response.status) {
        if (response.status === 200) {

          const res = new Response(
            new ReadableStream({
              async start(controller) {
                const reader = response.body.getReader();
                for (; ;) {
                  const { done, value } = await reader.read();
                  if (done) {
                    dispatch(setProgressFlag(false));
                    break;
                  }
                  controller.enqueue(value);
                }
                controller.close();
              },
            })
          );

          res.blob().then((blob) => {
            const newBlob = new Blob([blob], { type: 'application/pdf' });
            const filename = response.headers.get("content-disposition")?.split(';')[1]?.split('=')[1];
            setStreamData(newBlob);
            setShowViewModal(true);
            setFileName(filename)
          });
        } else {
          dispatch(setProgressFlag(false))
          setShowViewModal(false);
          setMessage("File not found");
          setDisplayInfoModal(true);
        }
      }
    });
  } catch (error) {
    dispatch(setProgressFlag(false))
    dispatch(setErrors(error));
  }
};

export const onViewFinalReport = (systemId: string, reportId: any, setStreamData: any, setShowViewModal: any, setFileName: any, setMessage: any, setDisplayInfoModal: any): AppThunk => async (dispatch) => {
  try {
    dispatch(setProgressFlag(true))
    const request: any = {
      method: "GET"
    };
    const fetchUrl = `${baseURL}api/v1/inspection/systems/${systemId}/reports/${reportId}/final/view`;
    await fetch(fetchUrl, request).then((response: any) => {

      if (response && response.status) {
        if (response.status === 200) {

          const res = new Response(
            new ReadableStream({
              async start(controller) {
                const reader = response.body.getReader();
                for (; ;) {
                  const { done, value } = await reader.read();
                  if (done) {
                    dispatch(setProgressFlag(false));
                    break;
                  }
                  controller.enqueue(value);
                }
                controller.close();
              },
            })
          );

          res.blob().then((blob) => {
            const newBlob = new Blob([blob], { type: 'application/pdf' });
            const filename = response.headers.get("content-disposition")?.split(';')[1]?.split('=')[1];
            setStreamData(newBlob);
            setShowViewModal(true);
            setFileName(filename)
          });
        } else {
          dispatch(setProgressFlag(false))
          setShowViewModal(false);
          setMessage("File not found");
          setDisplayInfoModal(true);
        }
      }
    });
  } catch (error) {
    dispatch(setProgressFlag(false))
    dispatch(setErrors(error));
  }
};

export const onDownloadDraftReport = (systemId: string, reportIds: any, setMessage: any, setDisplayInfoModal: any): AppThunk => async (dispatch) => {
  try {
    dispatch(setProgressFlag(true))
    const request: any = {
      method: "POST",
      cache: "no-store",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: sessionStorage.getItem("accesstoken"),
      },
      body: JSON.stringify({reportIds: reportIds})
    };

    const downloadUrl = (window as any).envSettings.baseAPIUrl + `api/v1/inspection/systems/${systemId}/reports/draft/download`;
    await fetch(downloadUrl, request).then((response: any) => {
      if (response.ok) {

        const res = new Response(
          new ReadableStream({
            async start(controller) {
              const reader = response.body.getReader();
              for (; ;) {
                const { done, value } = await reader.read();
                if (done) {
                  dispatch(setProgressFlag(false));
                  break;
                }
                controller.enqueue(value);
              }
              controller.close();
            },
          })
        );

        res.blob().then((blob) => {
          const filename = response.headers.get("content-disposition")?.split(';')[1]?.split('=')[1];
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = filename.replaceAll('\"', '');
          a.click();
        });

      } else {
        dispatch(setProgressFlag(false))
        setDisplayInfoModal(true);
        setMessage("File not found")
      }
    });
  } catch (error) {
    dispatch(setProgressFlag(false))
    dispatch(setErrors(error));
  }
};

export const onDownloadFinalReport = (systemId: string, reportIds: any, setMessage: any, setDisplayInfoModal: any): AppThunk => async (dispatch) => {
  try {
    dispatch(setProgressFlag(true))
    const request: any = {
      method: "POST",
      cache: "no-store",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: sessionStorage.getItem("accesstoken"),
      },
      body: JSON.stringify({reportIds: reportIds})
    };
    const downloadUrl = (window as any).envSettings.baseAPIUrl + `api/v1/inspection/systems/${systemId}/reports/final/download`;
    await fetch(downloadUrl, request).then((response: any) => {
      if (response.ok) {

        const res = new Response(
          new ReadableStream({
            async start(controller) {
              const reader = response.body.getReader();
              for (; ;) {
                const { done, value } = await reader.read();
                if (done) {
                  dispatch(setProgressFlag(false));
                  break;
                }
                controller.enqueue(value);
              }
              controller.close();
            },
          })
        );

        res.blob().then((blob) => {
          const filename = response.headers.get("content-disposition")?.split(';')[1]?.split('=')[1];
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = filename.replaceAll('\"', '');
          a.click();
        });

      } else {
        dispatch(setProgressFlag(false))
        setDisplayInfoModal(true);
        setMessage("File not found")
      }
    });
  } catch (error) {
    dispatch(setProgressFlag(false))
    dispatch(setErrors(error));
  }
};

export const uploadFinalReport = (systemId: any, selectedReport: any, body: any, setShowUploadXMLModal: any, setMessage: any, setDisplayInfoModal: any, setSelectedRows: any, setLoader: any): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.post(`${baseURL}api/v1/inspection/systems/${systemId}/reports/${selectedReport?.systemPanelInspectionReportId}/final`,body);
    if (res.status === "done") {
      dispatch(setUpdatedReportList({...selectedReport, addedBy: res?.data?.files[0]?.addedBy, dateAndTimeUploaded: res?.data?.files[0]?.addedOn}))
      setSelectedRows([{...selectedReport, addedBy: res?.data?.files[0]?.addedBy, dateAndTimeUploaded: res?.data?.files[0]?.addedOn}]);
      setShowUploadXMLModal(false);
      setLoader(false);
      setMessage("Final Inspection Report uploaded");
      setDisplayInfoModal(true);
    } else {
      setLoader(false);
      setMessage(res.message);
      setDisplayInfoModal(true);
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const {
  setErrors,
  setSystemList,
  setSystemSummary,
  clearSystemSummary,
  setSelectedSystemDetails,
  setGeneratedReportDetails,
  setReportList,
  setReportListPageDetails,
  setDeletedReportDetails,
  clearDeletedReportDetails,
  clearGeneratedReportDetails,
  setViewDraftReport,
  setViewFinalReport,
  setDownloadDraftReport,
  setDownloadFinalReport,
  setUpdatedReportList,
  setisFinalReportUploaded,
  setIsInspectionTaskCompleted,
} = InspectionReportSlice.actions;

export default InspectionReportSlice.reducer;