import React from "react";

const AdministrationDocument = () => {
    return (
        <>
            <h4 className="doc-title mb-2">
                Overview of Administration Module
            </h4>
            <p>
                The Administration module of Tech Advance<sup>+</sup> 
                provides a set of tools and utilities to administrators for User management, 
                setting up of background data like Customers, Users, Sites, Buildings and Floors, 
                Zone, Sales Office, Branch Office, General Setup like Mail Domains and Password Policy. 
                Reports of background data and a Logbook of the activities in all modules by 
                all users are also included in the Administration module.
            </p>

            <p>
                <b>
                    <span className="doc-subtitle">Login</span>
                </b>
            </p>
            <p>
                The users can login to Tech Advance<sup>+</sup> using their Login Name and Password.
                A new user will receive a password through email. The user will be prompted to change the password
                immediately after the first login. The password will have to comply with the Password Policy set
                by the Super Administrator.
            </p>

            <p>
                If a user has forgotten his/her password, he/she can recover the password through the link
                <span className="doc-subtitle"> "Forgot password?"</span> displayed in the login page. On clicking
                <span className="doc-subtitle"> "Forgot password?"</span> link, 
                Retrieve Forgotten Password page will be opened with a field to enter User’s Email. 
                A randomly generated Password will be sent to the user's Email Id for
                Super Administrators. The user has to login using this new password and change the password
                immediately after login. For Users other than Super Administrators, they have to contact their
                immediate administrator to access their accounts.
            </p>

            <p>When a Support, Admin, Admin-Tech, Technician or Branch user logs into Tech Advance<sup>+</sup>, he/she will
                be directed to the Inspection module. When the Super Administrator logs into Tech Advance<sup>+ </sup>
                or changes a Site, he/she will be directed to the Administration module.
            </p>

        </>
    );
}

export default AdministrationDocument;