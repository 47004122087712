import React, { useEffect, useState } from 'react'
import { useDispatch } from "react-redux";
import { APP_ROUTES } from "constants/AppRoutes";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "components/common/Breadcrumb/Breadcrumb";
import ReportViewer from 'components/common/Report/ReportViewer'
import { useAppSelector } from "../../redux/hooks";
import { createReportPreviewSession, setReportPreviewSession } from "../../redux/pages/Administration/Site/siteSlice";
import { Controller, useForm } from 'react-hook-form';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { getSystems } from 'redux/pages/Inspection/InspectionTask/inspectionTaskSlice';
import { Button } from 'primereact/button';
import { ListGroup } from 'react-bootstrap';
//icons
import { BsArrowRight, BsFileEarmarkPdf } from "react-icons/bs";
import { AiOutlineFileText } from 'react-icons/ai';
import { GrDocumentRtf } from 'react-icons/gr';
import { RiFileExcel2Line } from 'react-icons/ri';
import { onExportReport } from 'redux/common/report/customizeReportSlice';

type FormData = {
	sortBy: string;
};

const MasterDataReport = () => {

	const {
		control,
		formState: { errors },
	} = useForm<FormData>({
		defaultValues: {
			sortBy: "",
		},
	});

  	const [reportName, setReportName] = useState<any>(null);
	const [selectedSystem, setSelectedSystem] = useState<any>("");
	const reportPreviewSession = useAppSelector((state) => state.site.reportPreviewSession);
	const systemData = useAppSelector((state) => state.inspectionTask.systemsData);
	const navigate = useNavigate();

	const dispatch = useDispatch<any>();

	useEffect(() => {
		dispatch(getSystems())
	}, [dispatch]);

	useEffect(() => {
		if (systemData && systemData.length) {
			setSelectedSystem(systemData[0].uniqueSystemId);
		}
	}, [systemData])
	

	useEffect(() => {
		if (selectedSystem) {
            const reportParameters = {

				reportParameters: {
					uniqueSystemId: selectedSystem
				},
				reportType: "System Master data Report"
				
			};

			dispatch(createReportPreviewSession(reportParameters));
		}

		return(()=>{
			dispatch(setReportPreviewSession(""));
		})
	},[selectedSystem])

	useEffect(() => {
		if (reportPreviewSession) {
			setReportName(reportPreviewSession.previewSessionId);
		}
	}, [reportPreviewSession])

	const exportAsExcel = () => {
		const reportParameters = {
			exportParameters: {
				uniqueSystemId: selectedSystem
			},
			exportType: "System Master data Report"
		};
		dispatch(onExportReport(reportParameters, "System Master data"));
	};

	return (

		<div className='site-reports site-report-dropdown'>
			<section className="d-flex align-items-center">
				<Breadcrumb
					title="Reports"
					classNames="report-header"
					handleGoBackClick={() => navigate(`/${APP_ROUTES.REPORT_VIEW}`)}
				/>
				<div className="breadcrumb-right-arrow ml-3 mr-3">
					<BsArrowRight className="icon" />
				</div>
				<div className="breadcrumb-sub-page-title">
					<span>Master Data Report</span>
				</div>
			</section>

			<div className="report-sort-field pt-2 pb-2">
				<div className="report-export-main d-flex">
					<div className="row px-2">
						<div className="report-field col-12 md:col-2">
							<div className="field  mb-0">
								<span className="p-float-label">
									<Controller
										name="sortBy"
										control={control}
										render={({ field, fieldState }) => (
											<Dropdown
												inputId="dropdown"
												className={classNames("w-100 error-tooltip", {
													error: fieldState.invalid,
												})}
												{...field}
												value={selectedSystem}
												onChange={(e) => {
													setSelectedSystem(e.value);
												}}
												options={systemData}
												optionLabel="systemName"
												optionValue="uniqueSystemId"
											/>
										)}
									/>
									<label htmlFor="dropdown">System</label>
								</span>
							</div>
						</div>
					</div>
					<div className="report-buttons-main d-flex">
						<div className='export-report-buttons'>
							<ListGroup horizontal>
								<span className='expor-title'>Export to</span>
								<ListGroup.Item>
									<Button className="export-btn" onClick={() => exportAsExcel()}> <RiFileExcel2Line className="icon" /> Excel </Button>
								</ListGroup.Item>
								{/* <ListGroup.Item>
									<Button className="export-btn"> <BsFileEarmarkPdf className="icon" /> PDF </Button>
								</ListGroup.Item>
								<ListGroup.Item>
									<Button className="export-btn"> <GrDocumentRtf className="icon" /> RTF </Button>
								</ListGroup.Item>
								<ListGroup.Item>
									<Button className="export-btn"> <AiOutlineFileText className="icon" /> Text </Button>
								</ListGroup.Item> */}
							</ListGroup>
						</div>
					</div>
				</div>
			</div>

			<ReportViewer reportId={reportName} />
		</div>

	)
}

export default MasterDataReport;