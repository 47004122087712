import React, { useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import { FilePond,registerPlugin } from 'react-filepond';
import FileUploadValidate from "filepond-plugin-file-validate-type";

import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { classNames } from "primereact/utils";
import { Dialog } from "primereact/dialog";
import { BsExclamationCircleFill } from "react-icons/bs";

import { baseURL } from "service/HttpService";
import { onUploadPlugin } from "redux/common/pluginSlice";

import "filepond/dist/filepond.min.css"
import { InputNumber } from "primereact/inputnumber";
import { Checkbox } from "primereact/checkbox";

interface Props {
  showModal: boolean;
  setShowModal: (state: boolean) => void;
  currentPlugin: any;
  showInfoModal: boolean;
  setShowInfoModal: (state: boolean) => void;
  message:any;
  countryId?:any;
  setMessage:(state: "") => void;
}

type FormData = {
  buildNumber: any;
  version: string;
  description: string;
  minCompatibleVersion: string
  versionCode: any,
  versionName: any,
  autoUpdateEnabled: boolean
};

export const UploadPlugin: React.FC<Props> = ({
  showModal = false,
  setShowModal = () => { },
  currentPlugin,
  showInfoModal= false,
  setShowInfoModal= () => {},
  message,
  countryId="",
  setMessage=() => {}
}) => {
  const [enableUpload, setEnableUpload] = useState(true);
  const [files, setFiles] = useState<any>([]);
  const [acceptedFileTypes, setAcceptedFileTypes] = useState<any>([""])
  const [header, setHeader] = useState<any>("")
  const uploadForm: any = useRef();
  const dispatch = useDispatch<any>();
  registerPlugin(FileUploadValidate);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<FormData>({
    defaultValues: {
      buildNumber: '',
      version: '',
      description: "",
      minCompatibleVersion: "",
      versionCode: '',
      versionName: '',
      autoUpdateEnabled: false
    },
  });

  useEffect(() => {
    if (currentPlugin) {
      setValue("buildNumber",currentPlugin?.buildNumber);
      setValue("version",currentPlugin?.version);
      setValue("description",currentPlugin?.description);
      setValue("minCompatibleVersion",currentPlugin?.minCompatibleVersion);
      if(currentPlugin?.plugin==="TAP Bridge"){   
      setAcceptedFileTypes(["application/x-zip-compressed", "application/x-zip", "application/zip"]) 
      setHeader("TAP Bridge");
      }
      else if(currentPlugin?.plugin?.toLowerCase() === 'tap app' ){
        setAcceptedFileTypes(["application/vnd.android.package-archive"])//accept only apk file.
        setHeader("TAP App");
      } 
      else if(currentPlugin?.plugin?.toLowerCase() === 'tap pro') {
        setAcceptedFileTypes(["application/vnd.android.package-archive"])//accept only apk file. 
        setHeader("TAP Pro");
      }
    }
  }, [currentPlugin])

  const onprocessfile = (error: any, file: any) => {
    setEnableUpload(false);
    setFiles(file);
  };

  const uploadFormSubmitHandler = (data: any) => {
    uploadForm.current.requestSubmit();
  };

  const onUploadFormSubmit = (data: any) => {
    if(data.autoUpdateEnabled==true){
      data.autoUpdateEnabled=1
    }
    else{
      data.autoUpdateEnabled=0
    }
    let response = JSON.parse(JSON.parse(JSON.stringify(files.serverId)));
    if (response?.status === "done") {
      let body = {
        version: data.version,
        buildNumber: data.buildNumber,
        description: data.description,
        minCompatibleVersion: data.minCompatibleVersion,
        TempStorageDirectory: response.data?.uniqueDirectoryId[0],
        countryId:countryId,
        isTapBridge:currentPlugin?.plugin.toLowerCase() === 'tap bridge'?true:false,
        isTapAppPro:currentPlugin?.plugin.toLowerCase() === 'tap pro'?true:false,
        versionCode: data.versionCode,
        versionName: data.versionName,
        autoUpdateEnabled:data.autoUpdateEnabled

      };
      setEnableUpload(true);
      dispatch(onUploadPlugin(body, setShowModal, setMessage, setShowInfoModal));
    }
  };

  const onError = (errors: any, e: any) => { };

  const onRemoveFile = () =>{
    setEnableUpload(true);
  }

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && (
        <span className="tooltip-text">
          <BsExclamationCircleFill />
          {errors[name].message}
        </span>
      )
    );
  };
  const renderFooter = () => {
    return (
      <>
        <Button
          label="Cancel"
          onClick={() => setShowModal(false)}
          className="cancel btn"
        />
        <Button label="Upload" className="update btn" autoFocus onClick={uploadFormSubmitHandler} disabled={enableUpload}/>
      </>
    );
  };

  return (
    <>
      <Dialog
        header={header}
        visible={showModal}
        style={currentPlugin?.plugin && currentPlugin?.plugin.toLowerCase() === 'tap app' || currentPlugin?.plugin&&currentPlugin?.plugin.toLowerCase() === 'tap pro'?{ width: "40vw", height: "485px" }:{ width: "40vw"}}
        onHide={() => setShowModal(false)}
        footer={renderFooter()}
        className="display-settings personal selectsite"
      >
        <form
          onSubmit={handleSubmit(onUploadFormSubmit, onError)}
          ref={uploadForm}
        >
          <div className="row px-2">
            <div className="field col-12 md:col-2 mb-0 d-flex w-100 align-items-center">
              <div className="left-sider-label">
                <div className="p-float-label">
                {currentPlugin?.plugin && currentPlugin?.plugin.toLowerCase() === 'tap app' && <p className="label mandatory">TAP App (apk) </p>}
                {currentPlugin?.plugin && currentPlugin?.plugin.toLowerCase() === 'tap pro' && <p className="label mandatory">TAP Pro (apk) </p>}
                {currentPlugin?.plugin === "TAP Bridge" && <p className="label mandatory">TAP Bridge File</p>}
                </div>
              </div>
              <div className="right-side-field">
              <FilePond
                      allowMultiple={false}
                      maxFiles={1}
                      server={`${baseURL}api/v1/common/files/upload`}
                      onprocessfile={onprocessfile}
                      onremovefile={onRemoveFile}
                      name="files"
                      credits={false}
                      labelIdle='Choose File'
                      labelFileLoading=""
                      labelTapToUndo=""
                      labelFileProcessing=""
                      labelFileProcessingComplete=""
                      labelFileProcessingAborted=""
                      labelFileProcessingError=""
                      labelButtonAbortItemProcessing=""
                      labelButtonUndoItemProcessing=""
                      labelButtonRetryItemProcessing=""
                      labelButtonProcessItem=""
                      labelTapToCancel=""
                      labelFileRemoveError=""
                      labelTapToRetry=""
                      allowFileTypeValidation={true}
                      acceptedFileTypes={acceptedFileTypes}
                      labelFileTypeNotAllowed={currentPlugin?.plugin&&currentPlugin?.plugin.toLowerCase() === 'tap app' 
                                            ||  currentPlugin?.plugin&&currentPlugin?.plugin.toLowerCase() === 'tap pro'?"Select an apk file":"Select a zip file"}
                    />
              </div>
            </div>
          </div>

          {(currentPlugin?.plugin && currentPlugin?.plugin.toLowerCase() === 'tap app' || currentPlugin?.plugin && currentPlugin?.plugin.toLowerCase() === 'tap pro') && <div className="row px-2 pt-1">
            <div className="field col-12 md:col-2 mb-0 d-flex">
              <div className="left-sider-label">
                <div className="p-float-label">
                  <p className="label mandatory">Build Number</p>
                </div>
              </div>
              <div className="right-side-field">
                <span className="p-float-label">
                  <Controller
                    name="buildNumber"
                    control={control}
                    rules={{
                      required: "This field is required.",
                    }}
                    render={({ field, fieldState }) => (
                      <InputNumber
                      id="buildNumber"
                      className={classNames("w-100 error-tooltip", {
                        error: fieldState.invalid,
                      })}
                        {...field}
                        value={field.value}
                        onChange={(e) => field.onChange(e.value)}
                        useGrouping={false}
                        minFractionDigits={0}
                        maxFractionDigits={20}
                      />
                    )}
                  />
                  {getFormErrorMessage("buildNumber")}
                </span>
              </div>
            </div>
          </div>}
          <div className="row px-2 pt-1">
            <div className="field col-12 md:col-2 mb-0 d-flex">
              <div className="left-sider-label">
                <div className="p-float-label">
                  <p className="label  mandatory">Version Number</p>
                </div>
              </div>
              <div className="right-side-field">
                <span className="p-float-label">
                  <Controller
                    name="version"
                    control={control}
                    rules={{
                      required: "This field is required.",
                      pattern: { value: /^\d+(\.\d+)+$/, message: "Enter a valid Version Number" }
                    }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id="version"
                        className={classNames("w-100 error-tooltip", {
                          error: fieldState.invalid,
                        })}
                        maxLength={50}
                        {...field}
                      />
                    )}
                  />
                  {getFormErrorMessage("version")}
                </span>
              </div>
            </div>
          </div>
          {currentPlugin?.plugin === "TAP Pro" &&
            <>
              <div className="row px-2 pt-1">
                <div className="field col-12 md:col-2 mb-0 d-flex">
                  <div className="left-sider-label">
                    <div className="p-float-label">
                      <p className="label">Version Code</p>
                    </div>
                  </div>
                  <div className="right-side-field">
                    <span className="p-float-label">
                      <Controller
                        name="versionCode"
                        control={control}
                        render={({ field, fieldState }) => (
                          <InputText
                            id="versionCode"
                            className={classNames("w-100 error-tooltip", {
                              error: fieldState.invalid,
                            })}
                            {...field}
                            keyfilter="pint"
                            maxLength={4}
                          />
                        )}
                      />
                      {getFormErrorMessage("versionCode")}
                    </span>
                  </div>
                </div>
              </div>

              <div className="row px-2 pt-1">
                <div className="field col-12 md:col-2 mb-0 d-flex">
                  <div className="left-sider-label">
                    <div className="p-float-label">
                      <p className="label">Version Name</p>
                    </div>
                  </div>
                  <div className="right-side-field">
                    <span className="p-float-label">
                      <Controller
                        name="versionName"
                        control={control}
                        rules={{
                          pattern: { value: /^\d+(\.\d+)+$/, message: "Enter a valid Version Name" }
                        }}
                        render={({ field, fieldState }) => (
                          <InputText
                            id="versionName"
                            className={classNames("w-100 error-tooltip", {
                              error: fieldState.invalid,
                            })}
                            maxLength={8}
                            {...field}
                          />
                        )}
                      />
                      {getFormErrorMessage("versionName")}
                    </span>
                  </div>
                </div>
              </div>

              <div className="row px-2">
                <div className="field col-12 md:col-4">
                  <div className="field-checkbox px-2">
                    <Controller
                      name="autoUpdateEnabled"
                      control={control}
                      render={({ field }) => (
                        <Checkbox
                          inputId="binary"
                          checked={field.value}
                          {...field}
                        />
                      )} />
                    <label className='mandatory' htmlFor="binary">Mandatory Update</label>
                  </div>
                </div>
              </div>
            </>
          }

          {currentPlugin?.plugin === "TAP Bridge" &&
            <div className="row px-2 pt-1">
              <div className="field col-12 md:col-2 mb-0 d-flex">
                <div className="left-sider-label">
                  <div className="p-float-label">
                    <p className="label">Description</p>
                  </div>
                </div>
                <div className="right-side-field">
                  <span className="p-float-label">
                    <Controller name="description" control={control} rules={{
                      maxLength: 4000
                    }} render={({ field, fieldState }) => (
                      <InputTextarea id="Textarea" className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })}  {...field} />
                    )} />
                    {getFormErrorMessage('description')}
                    <span className='textarea-txt-count'>Max 4000 Chars</span>
                  </span>
                </div>
              </div>
            </div>
          }
          {currentPlugin?.plugin === "TAP Bridge" &&
            <div className="row px-2 pt-1">
              <div className="field col-12 md:col-2 mb-0 d-flex">
                <div className="left-sider-label">
                  <div className="p-float-label">
                    <p className="label  mandatory"> Minimum Compatible Version</p>
                  </div>
                </div>
                <div className="right-side-field">
                  <span className="p-float-label">
                    <Controller
                      name="minCompatibleVersion"
                      control={control}
                      rules={{
                        required: "This field is required"
                      }}
                      render={({ field, fieldState }) => (
                        <InputText
                          id="minCompatibleVersion"
                          className={classNames("w-100 error-tooltip", { error: fieldState.invalid })}
                          {...field}
                        />
                      )}
                    />
                    {getFormErrorMessage("minCompatibleVersion")}
                  </span>
                </div>
              </div>
            </div>}
        </form>
      </Dialog>
    </>
  );
};

export default UploadPlugin;
