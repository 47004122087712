import React from 'react'
import { Button } from 'react-bootstrap';
import { BsFillEyeFill, BsFillPencilFill, BsPlusLg, BsTrashFill } from 'react-icons/bs';
import { FaDownload } from 'react-icons/fa';

const ReportsSalesOpportunityDocument = () => {
  return (
    <>
      <h4 className="doc-title mb-2">Sales Opportunity Report</h4>
     <p>
     The Sales Opportunity Report plays a crucial role in Siemens TAP System migration. This report assists the sales team in identifying the systems that require migration from older Panel Types and Equipment models to their updated counterparts. Specifically, the report focuses on equipment types such as devices, modules, and NAC panels having Make/Model information for each..
     </p>
     <p>
     Sales Opportunity Report showcases data related to all Systems (both active and closed) over the past 36 months.
     </p>
     <p>
     Access to the report is granted to Super Administrators, Support, Admin, and Admin-Tech users.
     </p>
     <p>
     To generate Sales Opportunity Report, the user should navigate to  <span className="doc-subtitle">Inspection: Reports: Sales Opportunity Report </span>. Filters to choose Branch Office, Panel Type, Equipment Type and Model are provided in the Report page
     </p>
     <p>
     After selecting all the required filters, click on the Apply button to generate Sales Opportunity Report. The generated report will display Branch Office Name, Customer Number, Customer Name, Site Number, Site Name, Panel Type, System Name, System Status, Equipment Type, Model and Model Count data. These data can also be exported to excel by clicking the export to excel button.
     </p>
     
    </>
  );
}
export default ReportsSalesOpportunityDocument;