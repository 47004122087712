import React from "react";
import { Button } from "primereact/button";

const PluginsTapAppDocument = () => {
    return (
        <>
            <h4 className="doc-title mb-2">
                Plugins - TAP App
            </h4>

            <p></p>
            <p>
                This option is used to upload the APK file for the TAP Application to Tech Advance+ by Super Administrator using
                <span className="doc-subtitle"> Upload</span> button. This can then be downloaded to Branch Administrator/Technician's
                phone/tab through <span className="doc-subtitle"> Send Mail </span>
                <Button className="doc-button-icon">
                    <i className="pi pi-envelope icon" title="Mail"/>
                </Button>
                {" "} icon. Send Mail option is provided so that the
                user can access the link to download the APK and download the APK file to any other device as per requirement via mail.
                Only the Super Administrator can access the <span className="doc-subtitle"> Download </span>
                <Button className="doc-button-icon">
                    <i className="pi pi-download icon" title="Download" />
                </Button>{" "} icon.
            </p>

            <p>
                The Super Administrator can update the latest version of the APK file by selecting the
                <span className="doc-subtitle"> Upload </span>
                <Button className="doc-button-icon">
                    <i className="pi pi-upload icon" title="Upload"/>
                </Button>
                {" "} icon displayed against it. On selecting <span className="doc-subtitle">Upload </span>
                <Button className="doc-button-icon">
                    <i className="pi pi-upload icon" title="Upload"/>
                </Button> {' '}
                icon, TAP APP window will be displayed with the
                options to browse for a valid (.apk) file, enter the Build Number and Version Number. Browse for a valid file, enter
                the details and click <span className="doc-subtitle"> Upload </span>
                <Button className="doc-button-icon">
                    <i className="pi pi-upload icon" title="Upload"/>
                </Button>
                {" "} icon to upload the file to Tech Advance<sup>+</sup>.
                The details: 'Selected File'| 'Version' of the uploaded file (if any) will be displayed as read only.
            </p>

            <p>
                The updated file can be downloaded by the Super Administrator by selecting the
                <span className="doc-subtitle"> Download </span>
                <Button className="doc-button-icon">
                    <i className="pi pi-download icon" title="Download"/>
                </Button>
                {" "} icon.
                User can download the APK file whenever the application gets expired or is of an older version.
            </p>

            <p>
                The option Send Mail is provided so that the Technicians and Branch Administrators can access the link to download
                the APK file directly through the user's phone/tab. Click <span className="doc-subtitle"> Send Mail </span>
                <Button className="doc-button-icon">
                    <i className="pi pi-envelope icon" title="Mail" />
                </Button>
                {" "} icon to send the APK download link to
                the user's mail. User can login to the mail account through mobile phone/tab and click on link to access the APK file.
                On selecting this link the APK file to install the TAP App will be downloaded to the user's phone or tab.
            </p>
        </>
    );
}

export default PluginsTapAppDocument;