import React from "react";

const ModulesDoument = () => {
    return (
        <>
            <h4 className="doc-title mb-2">
                Modules in Tech Advance<sup>+</sup>
            </h4>
            <p>
                Tech Advance<sup>+</sup> offers a rich and versatile suite of applications to 
                manage Users, Area, buildings, documents and Fire and Safety Equipment data. 
                Modularized and easily implemented, the system offers organization-wide 
                access and management control.
            </p>
            <p>
                Tech Advance<sup>+</sup> has the following set of application modules:
            </p>
            <ul className="bullet-list">
                <li><b>Administration Module </b> 
                performs administration of the system. 
                The Administration module functions are grouped under Customers, 
                Users, Sites, Buildings, Floors and Search.
                </li>
                <li><b>As Builts Module </b>
                stores CAD drawings, provides drawing mark-ups, and revision control. 
                As Builts module provides an online secure central repository of drawings, 
                organized by site, building, floor and discipline. It facilitates sharing of 
                drawings with professionals like engineers, construction managers, project managers,
                etc. Stakeholders who are given access privileges to these drawings can markup the 
                drawings with their ideas and comments for discussion and make decisions.
                As Builts module also provides for controlling revisions, keeping track of all the 
                changes that occur, and maintaining an archive of all past versions
                </li>
                <li><b>Inspection Module </b> 
                facilitates the management of Fire and Life Safety inspections by providing 
                organized maintenance of Contracts and documentation of the Fire Protection Systems
                with all System, Panel, Module, and Device information, with related floor plan 
                drawings and any other available documents.Inspection Tasks can be created and 
                managed through the system, and all inspection data can be stored in the system.
                The Inspection module functions are grouped under the menu options - Inspection Tasks, 
                Contracts, Systems, Search and Inspection Reports.
                </li>
            </ul>
        </>
    );
}

export default ModulesDoument;