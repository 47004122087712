import { Button } from 'primereact/button'
import React from 'react'
import { BsPlusLg, BsTrashFill } from 'react-icons/bs'
import { FaDownload } from 'react-icons/fa'


 const InspectionReportConfigsDocument = () => {
    return(
        <>
        
        <h4 className="doc-title mb-2">Report Configs</h4>
        <p>
           Report Configs option allows user to add Report Configuration files 
           which are used for various functionalities such as report generation.
        </p>

        <p><span className="doc-subtitle"><b>Add:</b></span></p>
        <p>
            User can upload one or more Report Config files by clicking the 
            <span className="doc-subtitle"> Add </span> 
            <Button className="doc-button-icon"> <BsPlusLg className="icon" title='Add'/> </Button> icon. 
            User can select the valid report config file (s) (.doc, .docx., .csv, .xml, .xls and 
            .xlsx) and click Upload button to save it. 'Uploaded On' column will display date and 
            time of the uploaded files.
        </p>

        <p><span className="doc-subtitle"><b>Delete:</b></span></p>
        <p>
            User can delete a Report Config file by clicking the 
            <span className="doc-subtitle"> Delete </span> 
            <Button className="doc-button-icon"><BsTrashFill className="icon" title='Delete'/> </Button> icon. 
        </p>

        <p><span className="doc-subtitle"><b>Download</b></span></p>
        <p>User can download one or more Report Configs Files by selecting the required Configs 
            files and then clicking the 
            <span className="doc-subtitle"> Download </span> 
            <Button className="doc-button-icon"><FaDownload className="icon" title='Download'/></Button> icon. 
            If multiple config files are selected, the files will be downloaded to a Zip file.
        </p>
        
        
        </>
    );
 }
 export default InspectionReportConfigsDocument;