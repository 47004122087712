export const showUI = (privileges, selectedPrivilege) => {
    if(!privileges?.length) return false;
    const findData = privileges.filter(privilege => (privilege === selectedPrivilege));
    if (findData?.length) return true;
    else return false;
}

export const createGuid = () => {
	return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
		var r = (Math.random() * 16) | 0,
			v = c == "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
};

export const blockedNonKeyboardCharacters = (data: any) => {
	if (data) {
		const blockedCharacters = data.match(/(§|µ)/g);
		let invalidCharacters = [];
		if (blockedCharacters != null && blockedCharacters.length > 0) {
			invalidCharacters = blockedCharacters.filter((item, index) => blockedCharacters.indexOf(item) === index);
		}
		return invalidCharacters;
	}
}