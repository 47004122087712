import { Button } from 'primereact/button'
import React from 'react'
import { BsFillPencilFill, BsPlusLg, BsTrashFill } from 'react-icons/bs'


 const InspectionReportsDocument = () => {
    return(
        <>
        <h4 className="doc-title mb-2">Reports</h4>

        <p>
           Report option allows user to add Reports for the selected Country with Report Name, 
           Report Title and Report Group which can be used for Report Generation. 
        </p>

        <p><span className="doc-subtitle"><b>Add:</b></span></p>
        <p>
            Create a Report by clicking the <span className="doc-subtitle"> Add </span> 
            <Button className="doc-button-icon"> <BsPlusLg className="icon" title='Add'/> </Button> icon. 
            User can enter the Report Name, Report Title, Report Group in corresponding 
            fields and click Save button to save it.
        </p>

        <p><span className="doc-subtitle"><b>Edit:</b></span></p>
        <p>
            User can create a Report by clicking the <span className="doc-subtitle"> Edit </span> 
            <Button className="doc-button-icon"><BsFillPencilFill className="icon" title='Edit'/></Button> icon. 
            After making necessary changes click the Save Changes button to save the changes.
        </p>

        <p><span className="doc-subtitle"><b>Delete:</b></span></p>
        <p>
            User can delete a Report by clicking the <span className="doc-subtitle"> Delete </span> 
            <Button className="doc-button-icon"><BsTrashFill className="icon" title='Delete'/> </Button> icon. 
        </p>

        <p><i>Note:</i> Edit and Delete options are also provided as Quick action icons{" "} 
        <Button className="doc-button-icon"><BsFillPencilFill className="icon" title='Edit'/></Button>{" "}
        <Button className="doc-button-icon"><BsTrashFill className="icon" title='Delete'/> </Button> , which are 
        displayed when the mouse points to or moves over the required row 
        </p>

        <p>
            Note: Added report should have mapped inspection fields and corresponding Report 
            Template for generating reports with valid values.
        </p>

       <p><span className="doc-subtitle"><b>Report Order</b></span></p>
       <p>
          User can re-order the Reports displayed in Report Selected page in the Inspection 
          Tasks using this option. Drag and drop the Reports listed in the required Order to 
          re-order them.
       </p>
        </>
    );
 }
 export default InspectionReportsDocument;