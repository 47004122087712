import React, { useState, useRef, useEffect, useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { Checkbox } from "primereact/checkbox";
import { ScrollPanel } from "primereact/scrollpanel";
import { useDispatch } from "react-redux";
import { useAppSelector } from "redux/hooks";
import { getAddressDetails, getEquipmentData, getUnassignedEquipmentTypes, setEquipmentData, setUnassignedEquipmentTypes } from "redux/pages/Inspection/InspectionTask/inspectionDrawingSlice";
import {  updateObjectBlockHandle } from "redux/pages/Inspection/InspectionTask/inspectionTaskSlice";
import { AutoComplete } from "primereact/autocomplete";
import EquipmentListData from "../ManageInspectionTasks/InspectionDetails/Equipment/EquipmentListData";
import { DataTableComponent } from "components/Table/DataTable";
import InformationBox from "components/common/Message/InformationBox/InformationBox";

interface Props {
  showModal: boolean;
  setShowModal: (state: boolean) => void;
  assignBlock: (data: any) => void;
}
type FormData = {
  Address: any;
};
export const AssignBlock: React.FC<Props> = ({
  showModal = false,
  setShowModal = () => { },
  assignBlock = () => { },
}) => {
  const [showAssignBlock, setShowAssignBlock] = useState(false);
  const [value1, value2] = useState([]);
  const {
    control,
    setValue,
  } = useForm<FormData>({
    defaultValues: {
      Address: null,
    },
  });

  const [equipmentTypes, setEquipmentTypes] = useState<any>([]);
  const [addressSearch, setAddressSearch] = useState<any>(null);
  const [filteredSearch, setFilteredSearch] = useState<any>(null);
  const [equipmentClassIds, setEquipmentClassIds] = useState<any>("");
  const [gridData, setGridData] = useState<any>([]);
  const [backupColumns, setBackupColumns] = useState<any>([]);
  const [tableColumns, setTableColumns] = useState<Array<any>>(backupColumns);
  const [columns, setColumns] = useState<any>([]);
  const [generalProperties, setGeneralProperties] = useState<any>([]);
  const [showTable, setShowTable] = useState(false);
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [selectedEquipmentId, setSelectedEquipmentId] =  useState<any>();
  const [displayInfoModal, setDisplayInfoModal] = useState(false);
  const [equipmentSelected, setEquipmentSelected] = useState(false);
  const [message, setMessage] = useState("");

  const selectedTreeNode = useAppSelector((state) => state.inspectionDrawing.selectedTreeNode);
  const selectedTask = useAppSelector((state) => state.inspectionDrawing.selectedTask);
  const unassignedEquipmentTypes = useAppSelector((state) => state.inspectionDrawing.unassignedEquipmentTypes);
  const addressDetails = useAppSelector((state) => state.inspectionDrawing.addressDetails);
  const equipmentData = useAppSelector((state) => state.inspectionDrawing.equipmentData);

  const searchContainerRef = useRef(null);
  const addressListRef: any = useRef([]);
  
  const dispatch = useDispatch<any>();

  useEffect(() => {
    dispatch(getUnassignedEquipmentTypes(selectedTask?.uniqueInspectionTaskId, selectedTreeNode[0]?.uniqueDrawingId))
  }, [selectedTreeNode])

  useEffect(() => {
    if (unassignedEquipmentTypes) {
      let equipmentType: any = [];
      unassignedEquipmentTypes.map((item: any) => {
        equipmentType.push({ id: item.value, value: item.equipmentType, checked: true })
      })
      setEquipmentTypes(equipmentType)
      dispatch(setUnassignedEquipmentTypes(""))
    }
  }, [unassignedEquipmentTypes])

  const onEquipmentTypeChange = (e: { value: any, checked: boolean }) => {
    let equipmentType = [...equipmentTypes]
    equipmentType.find((item: any) => item.value === e.value).checked = e.checked
    setEquipmentTypes(equipmentType)
  }

  useEffect(()=>{
    setShowTable(false);
    setEquipmentSelected(false);
    if (addressSearch?.length>2) {
     let selectedEquipmentType: any = []
     selectedEquipmentType = equipmentTypes.filter((item: any) => { return item.checked === true})
     let equipmentTypeId : any = "";
     selectedEquipmentType.map((item: any) => {
      equipmentTypeId =`${equipmentTypeId},${item.id}`
     })
     equipmentTypeId =  equipmentTypeId.substring(1);
     setEquipmentClassIds(equipmentTypeId)
     let body = {
      unassignedEquipmentClasses: equipmentTypeId,
      Search:addressSearch
      }
      dispatch(getAddressDetails(selectedTask?.uniqueInspectionTaskId, selectedTreeNode[0]?.uniqueDrawingId, body))
    }
  },[dispatch,addressSearch]);

  useEffect(()=>{    
    if(addressDetails && addressDetails?.length){
      let address: any = [];
      addressDetails?.map((item: any) => {   
        if (item.EquipmentId) 
         return address.push({ name: item.Address, code: item.EquipmentId })
      });   
      addressListRef.current = address;
      setFilteredSearch(address)
    } else {
      addressListRef.current = [];
      setFilteredSearch(null)
    }
  },[addressDetails]);

  const itemTemplate = (item: any) => {
    return (
        <div className="data-item">
            <div>{item.name}</div>
        </div>
    );
}

const onSearchAddress = useCallback(() => {
  
}, [addressListRef.current]);

const onSelectAddress = (event) => {
  setSelectedEquipmentId(event.code)
  let body = {
    unassignedEquipmentClasses: equipmentClassIds
  }
  dispatch(getEquipmentData(selectedTask?.uniqueInspectionTaskId, selectedTreeNode[0]?.uniqueDrawingId, event.code, body))
}

useEffect(() => {
  if (equipmentData) {
    setEquipmentListData(equipmentData);
  }
}, [equipmentData])


const setEquipmentListData = (list) => {
  setShowTable(true)
  setEquipmentSelected(true);
  setGridData([...list?.data]);
  setGeneralProperties(list?.generalProperties)
  dispatch(setEquipmentData(""))
}

useEffect(() => {
  if (backupColumns) {
      setTableColumns(columns);
  }
}, [backupColumns]);


useEffect(() => {
  if (tableColumns) {
      setColumns(tableColumns)
  }

}, [tableColumns])

  const onAssignClick = () => {
    if (selectedEquipmentId && equipmentSelected) {
      let data = {
        selectedEquipmentId: selectedEquipmentId,
        equipmentData: gridData
      }
      assignBlock && assignBlock(data)
    }
    else {
      setMessage("Select an Equipment")
      setDisplayInfoModal(true);
    }
  }


  const renderFooter = () => {
    return (
      <>
        <Button label="Close" onClick={() => setShowModal(false)} className="cancel btn" />
        <Button label="Assign" className="update btn" autoFocus onClick={onAssignClick} />
      </>
    );
  };

  const handleTableColumns = useCallback((data: any) => {
    setColumns([...data]);
    setBackupColumns([...data]);
}, []);

const handleRowSelect = useCallback((data: any) => {
  setSelectedRows([...data]);
}, []);

  return (
    <>
      <Dialog
        header="Assign Block"
        visible={showModal}
        style={{ width: "50vw" }}
        onHide={() => setShowModal(false)}
        footer={renderFooter()}
        className="display-settings selectsite"
      >
        <ScrollPanel style={{ width: "100%", height: "120px" }}>
          {equipmentTypes && equipmentTypes.length ?
            (equipmentTypes.map((item: any, key: any) => (
              <div className="field-checkbox" key={key}>
                <Checkbox tooltip={item.value} inputId={item.id} id={item.id} name={item.id} value={item.value} checked={item.checked} onChange={onEquipmentTypeChange} />
                <label htmlFor={item.id}>{item.value}</label>
              </div>
            ))) : null
          }
        </ScrollPanel>
        <div className="field col-12 md:col-4 mt-2 pt-3">
          <span className="p-float-label">
            <Controller
              name="Address"
              control={control}
              rules={{
                required: "This field is required",
              }}
              render={({ field, fieldState }) => (
                <AutoComplete minLength={3} value={addressSearch} suggestions={filteredSearch} completeMethod={onSearchAddress} field="name"  itemTemplate={itemTemplate} onChange={(e) => {setAddressSearch(e.value)}} onSelect={e => {onSelectAddress(e.value)}} aria-label="Address" appendTo={searchContainerRef?.current} />
              )}
            />
            <label htmlFor="Address">
              Address
            </label>
          </span>
        </div>
        {showTable? (<div>
          <EquipmentListData generalProperties={generalProperties} getTableColumns={handleTableColumns} />
          {columns?.length > 0 && (
            <DataTableComponent
              rows={gridData}
              cols={columns}
              paginator={false}
              dataKeyId="EquipmentId"
              title={``}
              showCustomSearchCmp
              getSelectedRowData={handleRowSelect}
            />
          )}
        </div>) : null}
      </Dialog>
      <InformationBox
          showInfoModal={displayInfoModal}
          setShowInfoModal={setDisplayInfoModal}
          message={message}
        />
    </>
  );
};

export default AssignBlock;
