import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";

import ReportViewer from "components/common/Report/ReportViewer";
import { useAppSelector } from "redux/hooks";
import { useDispatch } from "react-redux";
import { createReportPreviewSession, setReportPreviewSession } from "redux/pages/Administration/Site/siteSlice";

interface Props {
    showModal: boolean;
    setShowModal: (state: boolean) => void;
    reportFlag: string;
    selectedRows: any;
    uniqueInspectionTaskId: string;
    uniqueSystemId: string;
    selectedEquipmentGroup: number;
    selectedEquipmentType: string;
    filterBody: any;
    sortField: any;
    sortOrder: any;
}

export const InspectionDetailsReport: React.FC<Props> = ({
    showModal = false,
    setShowModal = () => { },
    reportFlag = '',
    selectedRows = [],
    uniqueInspectionTaskId = "",
    uniqueSystemId = "",
    selectedEquipmentGroup = 0,
    selectedEquipmentType = "",
    filterBody = {},
    sortField = '',
    sortOrder = ''
}) => {
    const [reportId, setReportId] = useState<any>(null);
    const reportPreviewSession = useAppSelector((state) => state.site.reportPreviewSession);
    const dispatch = useDispatch<any>();

    const getSelectedIds = () => {
        let params: any = [];
        selectedRows.map(item => {
            params.push(item.EquipmentId);
        });
        return params;
    };

    useEffect(() => {
        let params;
        if (reportFlag === 'all') {
            params = "";
        } else {
            params = getSelectedIds();
        }
        const reportParameters = {
            reportParameters: {
                uniqueInspectionTaskId: uniqueInspectionTaskId,
                uniqueSystemId: uniqueSystemId,
                uniqueEquipmentTypeId: selectedEquipmentType,
                panelId: selectedEquipmentGroup,
                sortColumn: sortField,
				sortOrder: sortOrder,
                selectedIds: params,
                ...filterBody,
            },
            reportType: "Equipment Details Report"
        };
        dispatch(createReportPreviewSession(reportParameters));
        
        return(()=>{
			dispatch(setReportPreviewSession(""));
		})
    }, []);

    useEffect(() => {
        if (reportPreviewSession) {
            setReportId(reportPreviewSession.previewSessionId);
        }
    }, [reportPreviewSession])

    return (
        <>
            <Dialog
                header="Report"
                visible={showModal}
                onHide={() => {
                    dispatch(setReportPreviewSession(""));
                    setShowModal(false);
                }}
                className="display-settings report selectsite"
                style={{ width: "90vw", height: "600px" }}
            >
                <div className="report-export-main d-flex mb-3">
                </div>
                <ReportViewer reportId={reportId} />
            </Dialog>
        </>
    );
};

export default InspectionDetailsReport;
