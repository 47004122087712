import React from 'react';
import { Button } from 'primereact/button';
import { BsPlusLg, BsFillPencilFill, BsTrashFill, BsPaperclip, BsSearch, BsSubtract, BsBoxArrowInDownRight, BsZoomIn } from 'react-icons/bs';
import { FaChevronDown, FaCog, FaDownload, FaSave, FaBackward } from "react-icons/fa";
import { BiUnlink } from "react-icons/bi";
import { GiArrowCursor } from "react-icons/gi";
import { RiAlarmWarningLine } from "react-icons/ri";

const InspectionTasksDocument = () => {
  return (
    <>
      <h4 className="doc-title mb-2">Inspection Tasks</h4>
      <p>
        This option is used to create and manage Inspection Tasks under a Contract for a Customer.
        Inspection Tasks are created for managing the inspection process at a customer. For example,
        an Inspection Task can be created for a System for conducting the Quarterly Inspection for
        the First Quarter of a year.  If there are multiple systems, one Inspection Task should be
        created for each System.
      </p>

      <p>
        On selecting Inspection Tasks menu, Inspection Tasks page with Customer and Contract drop
        downs will be displayed listing all the Inspection Task of the selected Site, Customer and
        Contract in the Right hand side.Left Hand Side of the Inspection Tasks page is provided with
        the following options:
      </p>

      <p>Search Site: User can search for another Site using this option. Selected Site will be
        displayed below the Search text box after making all the necessary changes.
      </p>

      <p>All Customers and Contracts mapped to the selected Site will be listed below.</p>

      <p>
        <span className="doc-subtitle">Filter Customer: </span>This option to enable or disable
        the filtering of Customers for the selected Site. If the <span className="doc-subtitle">Filter
          Customer </span>option is enabled, the text box besides the option becomes Filter Customer.
        User can type in Customer name and filter the Customer. This will be useful if the selected
        Site has multiple Customers.
        If the <span className="doc-subtitle">Filter Customer</span> option is turned off / Filter
        Contract option is enabled, the text box besides the option becomes Filter Contract.
        User can type in Contract name to filter the Contracts listed for the selected Customer.
        This will be useful if the selected Customer has multiple Contracts.
      </p>

      <p><span className="doc-subtitle">Map Customer: </span>New Customer or already existing
        Customers can be mapped using this option. On clicking <span className="doc-subtitle">
          Map Customer </span> link, Map Customer page will be listed with already mapped Customer.
        If any other Customer need to mapped, User can search for the required Customer and that
        Customer can be selected for Customer Mapping.
      </p>

      <p><span className="doc-subtitle">Add Customer: </span>New Customer can be added to the Site
        using this option and can be mapped to the selected Site using this
        <span className="doc-subtitle"> Add Customer </span>{" "}
        <Button className="doc-button-icon">
          <BsPlusLg className="icon" title="Add" />
        </Button>{" "} icon.
      </p>

      <p><span className="doc-subtitle">Map Contract:  </span>All the Contracts mapped / added to
        the selected Customer will be listed for managing the Inspection Tasks. Required Contract(s)
        can be selected either by selecting Select All check box or by selecting individual Contracts.
      </p>

      <p>A Link ‘Map Contract’ is also provided to map new or already existing Contracts to the
        selected Customer. On clicking <span className="doc-subtitle">Map Contract </span>link,
        Map Contract page will be listed with already mapped Contracts of the selected Customer.
        If any other Contract need to mapped, User can search for the required Contract and that
        Contract can be selected for Contract Mapping.
      </p>

      <p><span className="doc-subtitle">Add Contract:</span> New Contract can be added to the
        Customer and Site using this option and can be mapped to the selected Customer and Site using
        this <span className="doc-subtitle">Add Contract</span>{" "}
        <Button className="doc-button-icon">
          <BsPlusLg className="icon" title="Add" />
        </Button>{" "} icon.
      </p>

      <p><i>Note: </i> <span className="doc-subtitle">Add</span>{" "}
        <Button className="doc-button-icon">
          <BsPlusLg className="icon" title="Add" />
        </Button>{" "} icon will not be displayed for Technicians as they donot have the privilege
        to add Customers or Contracts.
      </p>

      <p>After selecting the required Customer and Contract, all the existing Inspection Tasks of
        the selected Customer and Contract will be listed in the data grid.
        The users can add/edit/delete Inspection Tasks.
      </p>

      <p>
        Only one Inspection Task will be active for a System. More than one Inspection Task can be
        active for a Customer or a Contract.
      </p>

      <p>Check Boxes - 'Active', 'Closed' and 'Completed' are provided in the Inspection Tasks page
        to filter the Inspection Tasks based on its status. By default, all the three check boxes
        will be selected. If 'Active' is selected, only active Inspection Tasks of the selected
        Customer and Contract will be listed. If 'Closed' is selected, only closed Inspection Tasks
        of the selected Customer and Contract will be listed.If 'Completed' is selected, only
        completed Inspection Tasks of the selected Customer and Contract will be listed. A drop down
        to filter the tasks created within Last 3 months, Last 6 months, Last 12 months,
        Last 18 months and All is also provided with 'Last 18 Months'option selected by default.
        If 'All' is selected in the drop down, all the tasks added will be listed.
      </p>

      <p>
        <>
          Font Size of the Texts in the Inspection Tasks page can be set for the logged in User
          based on the Font Size options - 'Small', 'Medium' and 'Large' {' '}
          <span className="adjust-font-help">
            <span className="text">
              <span className="small">A</span>
              <span className="medium active">A</span>
              <span className="large ">A</span>
            </span>
          </span> {' '}
          provided in the Inspection Tasks page. By Default, 'Medium' will be selected.
        </>
      </p>

      <p>
        Note: Number of rows displayed in the Inspection Tasks for a User will be based on the
        settings 'Rows per Page for Inspection Tasks' in the Personal Settings. A Keyword search
        option for Task Name and System Name is also provided in the Inspection Tasks page
      </p>

      <p><span className="doc-subtitle"><b>Add: </b></span></p>

      <p>A new Inspection Task can be added by clicking <span className="doc-subtitle">
        Add</span>{" "}
        <Button className="doc-button-icon">
          <BsPlusLg className="icon" title="Add" />
        </Button>{" "}icon. User has to enter the Inspection Task Name, System, Planned Start Date,
        Planned End Date, Qty of Techs, Lead Technician and Notification Number for the Inspection
        Task.  The selected Customer and Contract will be displayed as read only. An 'Upload File'
        will be displayed against the System drop down through which the user can upload
        configuration file if it is not assigned to the selected system. The TAP Bridge can be
        connected to an Inspection Task only after activating it.
      </p>

      <p>
        The technicians (if any) will be loaded in the Lead Technician drop down.
        The user has to select a Technician to activate the task. The selected technician
        will be considered as the Lead technician for the task and only this Technician can run
        the TAP Bridge initially for loading configuration file. All the Technicians associated
        to the selected Sales Office of the Contract can join the Inspection Task. Lead Technician
        is mandatory of all Inspection Tasks.
      </p>

      <p>
        Reports Selected link is also provided in this page so that the user can select the Reports
        required for the Inspection Task. The number of reports selected for the Inspection Task will
        be displayed against the link. To activate an Inspection Task, at least one Report should be
        selected. For more details see <span className="doc-subtitle">Reports Selected</span> .
      </p>

      <p><i>Note: </i>Notification Number field will be provided as a mandatory field for Inspection
        Task created under Contract Number starting with 5. A View Pattern link is provided next to
        the Notification Number field to view the valid Number Patterns for Notification Number.
        If the Number Pattern for United States is selected by the Customer, then Notification Number
        will also follow the Number Pattern for United States. Similarly if the Number Pattern for
        Canada is selected by the Customer, Number Pattern for Canada has to be followed in Notification
        Number also.
      </p>

      <p>
        Inspection Tasks having Systems with Panel Types other than MXL/XLS/Desigo FS20/Desigo FV20/
        Desigo Modular/Cerberus Pro Panels will be inspected manually. Technicians can join the
        Inspection Task through the mobile device only after activating the task. Number of
        Technicians required for the inspection has to be provided while creating an Inspection Task.
      </p>

      <p>
        Only one Inspection Task can be active for a System. If all necessary data are available in
        the application (valid XML file is uploaded and Reports are selected), the application starts
        to parse the XML and the Equipment will be imported to the server. The progress of XML
        importing will be shown in the Inspection Details page.
      </p>

      <p>
        Lead Technician will start the TAP Bridge for the Inspection.  The device to be tested will
        be triggered, and the system will send Test Results, which will be received by the TAP Bridge
        and sent to TechAdvance<sup>+</sup> and TAP App by the TAP Bridge. The technician can
        acknowledge or silence the alarms through the TAP App running in the mobile device.
      </p>

      <p>
        Only one Inspection Task can be active for a System. If the technician wants to join a task
        of another system he/she should sync all inspection data of the current task to TechAdvance
        <sup>+</sup> and Quit the current task.
      </p>

      <p>
        <span className="doc-subtitle"><b>Edit: </b></span>
      </p>

      <p>
        Edit an Inspection Task by selecting it and clicking the
        <span className="doc-subtitle"> Edit</span>{" "}
        <Button className="doc-button-icon">
          <BsFillPencilFill className="icon" title="Edit" />
        </Button>
        {' '} icon Make required modifications and click Save Changes button to save the
        changes. An active Inspection Task cannot be deactivated by unchecking the Activate Check box,
        but details like Inspection Start and End date, Report selected, Lead Technician etc.
        can be edited.
      </p>

      <p><span className="doc-subtitle"><b>Inline Edit: </b></span></p>

      <p>Inline edit option enable user to edit an Inspection Task by clicking the
        <span className="doc-subtitle"> Edit</span>{" "}
        <Button className="doc-button-icon">
          <i className="pi pi-pencil icon" title="Inline Edit" />
        </Button>{" "}  icon in the left hand side of the required row. On clicking
        <span className="doc-subtitle"> Edit</span>{" "}
        <Button className="doc-button-icon">
          <i className="pi pi-pencil icon" title="Inline Edit" />
        </Button>{" "} icon, the selected row will be displayed as editable and
        <span className="doc-subtitle"> Edit</span>{" "}
        <Button className="doc-button-icon">
          <i className="pi pi-pencil icon" title="Inline Edit" />
        </Button>{" "}  icon will be replaced with
        <span className="doc-subtitle"> Save</span>{" "}
        <Button className="doc-button-icon">
          <i className="pi pi-save icon" title="Save" />
        </Button>{" "}  and <span className="doc-subtitle"> Cancel</span>{" "}
        <Button className="doc-button-icon">{" "}
          <span className="pi pi-times icon" title="Cancel" ></span>{" "}
        </Button>{" "} icon. Do the necessary changes in the required fields and click on
        <span className="doc-subtitle"> Save</span>{" "}
        <Button className="doc-button-icon">
          <i className="pi pi-save icon" title="Save" />
        </Button>{" "}  icon. To cancel the Inline edit operation click on
        <span className="doc-subtitle"> Cancel</span>{" "}
        <Button className="doc-button-icon" title="Cancel" >{" "}
          <span className="pi pi-times icon"></span>
        </Button>{" "} icon.
      </p>

      <p><span className="doc-subtitle"><b>Delete: </b></span></p>

      <p>Delete an Inspection Task by selecting it and clicking the <span className="doc-subtitle">
        Delete</span>{" "}
        <Button className="doc-button-icon">
          <BsTrashFill className="icon" title="Delete" />
        </Button>{" "} icon. Inspection Tasks in Inactive or Closed or Completed Status only can be
        deleted.
      </p>

      <p>Note: No Inspection Task should be able to be deleted by anyone except Super Admin and
        Support users, if there are Final reports uploaded.</p>

      <p><span className="doc-subtitle"><b>Inspection Details: </b></span></p>

      <p>
        This option allows the user to enter/view the inspection details of an Inspection Task.
        The user can enter and save the details related to Contract, System, Questions, Equipment
        and Equipment Summary. (including data of all nodes of the Panel) using this option.
        User cannot add or edit any inspection details if the selected Inspection Task is in
        'Closed' or 'Inactive' or 'Completed' status.
        See <span className="doc-subtitle"> Inspection Tasks: Inspection Details </span>
        for more details.
      </p>

      <p><i>Note:</i> User cannot add or edit any inspection details if the selected Inspection Task
        is in 'Closed' or 'Inactive' or 'Completed' status.  </p>

      <p className="doc-subtitle"><b>Download: </b>{" "}
        <Button className="doc-button-icon w-36">
          <FaDownload className="icon" title="Download" />
          <FaChevronDown className="caret-small" />
        </Button>{" "}
      </p>

      <p>
        This option lists Report All, Report Highlighted, Export All, Export Highlighted and
        Group Highlighted.
      </p>

      <p>
        <span className="doc-subtitle">Report All: </span>
      </p>

      <p>
        This option is for generating a report with all the records in the data grid.
        The report can be saved to PDF, Text, Rich Text or Excel format by selecting the
        file format from the list under Save option, and then clicking OK button after selecting
        the required options from the Export Settings window.
      </p>

      <p>
        <span className="doc-subtitle">Report Highlighted: </span>
      </p>

      <p>
        This option is for generating a report with only selected records in the grid.
        Select the desired rows by holding down the control key and clicking on each of the
        records to be selected. Users can also select a set of rows using the shift key.
        The selected records will be highlighted. Click on the <span className="doc-subtitle">
          Report Highlighted </span> option in the <span className="doc-subtitle">
          Download </span>{" "}
        <Button className="doc-button-icon w-36">
          <FaDownload className="icon" title="Download" />
          <FaChevronDown className="caret-small" />
        </Button>{" "} menu to generate the report. The report will be created with the highlighted
        records only. The report can be saved to PDF, Text, Rich Text or Excel format by selecting
        the file format from the list under Save option, and then clicking OK button after selecting
        the required options from the Export Settings window.
      </p>


      <p><span className="doc-subtitle"><b> More </b></span>{" "}
        <Button className="doc-button-icon w-36">
          <FaCog className="icon" title="More" />
          <FaChevronDown className="caret-small" />
        </Button>
      </p>

      <p>This option lists Quit Task, Inactivate, Report Selected, Drawings, Compare,
        Import Previous Task Details and Display Settings option.
      </p>

      <p><span className="doc-subtitle">Close:</span></p>

      <p>
        Close an Inspection Task by selecting it and clicking the <span className="doc-subtitle">
          Close icon</span>. On closing an Inspection Task, its corresponding status will be displayed
        as Closed in the data grid. If the selected Inspection Task is in progress, it will show a
        message 'Do you want to quit all the jobs of the Technician(s) and close the
        Inspection Task?' with Technician names listed. On clicking yes to the message the selected
        Inspection Task will be closed. The Equipment details of a closed Inspection Task will not
        be editable.
      </p>

      <p>
        <i>Note:</i> On closing an Inspection Task, a Reminder message will be displayed as
        "Inspection Task closed. Reminder: Please make sure all final reports are signed and uploaded."
      </p>

      <p><span className="doc-subtitle">Complete:</span></p>

      <p>
        Complete an Inspection Task by selecting it and clicking the <span className="doc-subtitle">
          Complete icon </span>. An Active task cannot be changed to Complete, it will show a message
        'Selected Inspection Task is not closed, only closed Tasks can be set as completed'.
        if Reports are not generated for the task, it will show a message 'All the Inspection Reports
        are not Generated, Task cannot be completed'. If all the Inspection Reports are generated it
        will show message 'Are you sure you want to complete the selected Inspection Task?',
        click on Yes to change to Complete status.
      </p>

      <p>
        A new Task can be created for a System only after completing the previous task of the same
        System. Only Super Administrator and Support Users has the privilege to change a Complete
        status to Active status. For a System with multiple task only single task can be of Active/
        Closed status..
      </p>

      <p><span className="doc-subtitle">Quit Task:</span></p>

      <p>
        User can force close a task started by a Technician by selecting the Inspection Task and
        clicking the <span className="doc-subtitle">Quit Task</span> option from
        <span className="doc-subtitle"> More </span>{" "}
        <Button className="doc-button-icon w-36" >
          <FaCog className="icon" title="More" />
          <FaChevronDown className="caret-small" />
        </Button>{" "} icon. On selecting this option, Quit Task page will be displayed listing
        the names of the Technicians (if any) who have joined the Inspection Task. Select the required
        Technician(s) and click Quit button. Any unsynched data in the Technician's smart phone will
        be lost.
      </p>

      <p><span className="doc-subtitle">Inactivate:</span></p>

      <p>
        This option is to inactivate an Inspection Task. Inactivate an Inspection Task by selecting
        it and clicking the <span className="doc-subtitle">Inactivate</span> option from
        <span className="doc-subtitle"> More </span>{" "}
        <Button className="doc-button-icon w-36">
          <FaCog className="icon" title="More" />
          <FaChevronDown className="caret-small" />
        </Button>{" "} icon. On inactivating an Inspection Task, its corresponding status will be
        displayed as Inactive in the data grid. If the selected Inspection Task is in progress,
        it will show a message 'Do you want to quit all the jobs of the Technician(s) and inactivate
        the Inspection Task?' with Technician names listed. On clicking Yes to the message the
        selected Inspection Task will be inactivated. The Equipment details of an inactive Inspection
        Task will not be editable.
      </p>

      <p><i>Note:</i> An Inactive Inspection Task can also be made active by checking the Activate
        Check box in the Edit Inspection Task page.
      </p>


      <p><span className="doc-subtitle">Import Previous Task Details:</span></p>

      <p>This option is used to copy Equipment Test Results, System and Questions data into the
        selected Active Task from any Completed Task of the same System. For copying select an
        Active Inspection Task and click on <span className="doc-subtitle"> Import Previous
          Task Details </span> option from <span className="doc-subtitle"> More </span>{" "}
        <Button className="doc-button-icon w-36">
          <FaCog className="icon" title="More" />
          <FaChevronDown className="caret-small" />
        </Button>{" "} menu. User can either select 'Import Equipment Test Results' or 'Import System
        and Question Data' from Import Previous Task Details page, select the required task from the
        Task selection drop-down and click on Import button.
      </p>

      <p> If the selected Task is in Completed/Closed Status, should display message
        'Inspection Task is not active, cannot import previous Inspection Task result'.
        If the selected System have no Completed tasks exists, TechAdvance<sup>+</sup> should display
        'No Completed Task exists'.
      </p>



      <p><span className="doc-subtitle">Report Selected:</span></p>

      <p>
        This option is used to access the reports related to the inspection for the selected
        Inspection Task. The Reports listed here are the reports uploaded through
        <span className="doc-subtitle"> Manage: Inspection Settings: Report Mapping</span> option.
        On selecting this option all the reports accessible to the Inspection Task will be listed
        with its Name, Report Title and with check boxes against each report. The user can select
        the required report and click Save Changes button to save the changes.
      </p>

      <p>
        Configured reports are those reports that are defined as part of the inspection process.
        It includes all Inspection data fields and its default values. The data field values for
        each report will be saved during the inspection process. Initially the inspection data for
        each of these data fields will be populated by default values and the technician can modify
        it as required during inspection. All the data fields corresponding to Contract,
        System and Equipment will be included in these reports. All the necessary data will be
        sent to TAP Bridge and the report will be generated using this data and will be saved
        as a word file in the Drafted Reports.
      </p>

      <p><span className="doc-subtitle">Linked Reports:</span></p>

      <p> Configured reports are those reports that are defined as part of the inspection process.
        It includes all Inspection data fields and its default values. The data field values for
        each report will be saved during the inspection process. Initially the inspection data for
        each of these data fields will be populated by default values and the technician
        can modify it as required during inspection. All the data fields corresponding to
        Contract, System and Equipment will be included in these reports. All the necessary data
        will be sent to TAP Bridge and the report will be generated using this data and will be
        saved as a word file in the Drafted Reports.</p>

      <p>
        <span className="doc-subtitle">
          Drawings:
        </span>
      </p>

      <p>
        This option provides access to the Drawings uploaded. On selecting this option, Drawing
        List page will be displayed listing all the Drawings uploaded for the selected active
        Inspection Task with Open Drawing and Close button.
      </p>

      <p>
        All drawings that are available for the selected Inspection Task will be listed here,
        organized by Site, Building and Floor with the Drawing Category, Drawing File Name and
        File Size. This list can be sorted on any column by clicking on the column header.
      </p>

      <p>
        Select the required Drawing and click on {" "}
        <span className="doc-subtitle">
          Open Drawing
        </span>
        {" "} button to view the Drawing.
      </p>

      <p>
        Assign Block, Select Equipment, Delete, Search Text, Attachments, Delink, Stop Blink,
        Download, Save, More and also Tabular view of Equipment list options are accessible
        for users while opening drawings from the Inspection: More: Drawing View.
      </p>

      <p>
        <span className="doc-subtitle">Assign Block: </span>
        Through this <span className="doc-subtitle">Assign Block </span>
        <Button className="doc-button-icon">
          <BsSubtract className="icon" title="Assign Block" />
        </Button>
        {" "} icon, users can assign equipment to blocks in the Drawings.
      </p> <p>
        <span className="doc-subtitle">Select Equipment: </span>
        Through this <span className="doc-subtitle">Select Equipment </span>
        <Button className="doc-button-icon w-36">
          <GiArrowCursor className="icon" title="Select Equipment" />
          <FaChevronDown className="caret-small" />
        </Button>
        {" "} icon, users can view & edit the selected equipment from drawing.
      </p>

      <p>
        <span className="doc-subtitle">Delete: </span>
        Through this <span className="doc-subtitle">Delete </span>
        <Button className="doc-button-icon">
          <BsTrashFill className="icon" title="Delete" />
        </Button>
        {" "} icon, users can delete equipment from Drawing.
      </p>

      <p>
        <span className="doc-subtitle">Search Text: </span>
        Through this <span className="doc-subtitle">Search Text </span>
        <Button className="doc-button-icon">
          <BsSearch className="icon" title="Search Text" />
        </Button>
        {" "} icon, users can search text from Drawing.
      </p>

      <p>
        <span className="doc-subtitle">Attachments: </span>
        Through this <span className="doc-subtitle">Attachments </span>
        <Button className="doc-button-icon">
          <BsPaperclip className="icon" title="Attachments" />
        </Button>
        {" "} icon, users can view and add attachments to the selected equipment in the Drawing.
      </p>

      <p>
        <span className="doc-subtitle">Delink: </span>
        Through this <span className="doc-subtitle">Delink </span>
        <Button className="doc-button-icon">
          <BiUnlink className="icon" title="Delink" />
        </Button>
        {" "} icon, users can unassigned an equipment from the drawing.
        The unlinked equipment will be moved to the Unassigned tab in tabular view.
      </p>

      <p>
        <span className="doc-subtitle">Stop Blink: </span>
        Through this <span className="doc-subtitle">Stop Blinking </span>
        <Button className="doc-button-icon">
          <RiAlarmWarningLine className="icon" title="Stop Blink" />
        </Button>
        {" "} icon, users can stop the blinking of all the equipment in the drawing.
      </p>

      <p>
        <span className="doc-subtitle">Download: </span>
        Through this <span className="doc-subtitle">Download </span>
        <Button className="doc-button-icon">
          <FaDownload className="icon" title="Download" />
        </Button>
        {" "} icon, users can export the drawing in different available formats..
      </p>

      <p>
        <span className="doc-subtitle">Save: </span>
        Through this <span className="doc-subtitle">Save </span>
        <Button className="doc-button-icon">
          <FaSave className="icon" title="Save" />
        </Button>
        {" "} icon, users can Save the drawing in different available formats.
      </p>

      <p>
        <span className="doc-subtitle">More: </span>
        Through this <span className="doc-subtitle">More </span>
        <Button className="doc-button-icon w-36" title="More" >
          <FaCog className="icon" />
          <FaChevronDown className="caret-small" />
        </Button>
        {" "} icon, users can manage the options Show Layer, Hide Layer, Measure Area, Measure Distance, De-Hatch.
      </p>

      <p>
        <span className="doc-subtitle">Back: </span>
        Through this <span className="doc-subtitle">Back </span>
        <Button className="doc-button-icon">
          <FaBackward className="icon" title="Back" />
        </Button>
        {" "} icon, Users can go back to Inspection Page.
      </p>

      <p className="doc-subtitle">
        Common:
      </p>
      <p>
        All the equipment assigned to the drawing will be listed under the Common tab.
        The options to Edit, Delete, view/add Attachments,
        Show the Drawing, Show Zoomed, and Delink are available under this tab.
      </p>

      <p>
        <span className="doc-subtitle">Edit: </span>
        Selected equipment can be edited by clicking the
        <span className="doc-subtitle"> Edit </span>
        <Button className="doc-button-icon">
          <BsFillPencilFill className="icon" title="Edit" />
        </Button>{" "} icon.
      </p>

      <p>
        <span className="doc-subtitle">Delete: </span>
        Users can delete Equipment by clicking the
        <span className="doc-subtitle"> Delete </span>
        <Button className="doc-button-icon">
          <BsTrashFill className="icon" title="Delete" />
        </Button>{" "} icon.
      </p>

      <p>
        <span className="doc-subtitle">Attachments: </span>
        Users can view/add attachments by clicking the
        <span className="doc-subtitle"> Delete </span>
        <Button className="doc-button-icon">
          <BsPaperclip className="icon" title="Attachments" />
        </Button>{" "} icon.
      </p>

      <p>
        <span className="doc-subtitle">Show Drawing: </span>
        Users can view the selected equipment as blinking in the drawing using the
        <span className="doc-subtitle"> Show Drawing </span>
        <Button className="doc-button-icon">
          <BsBoxArrowInDownRight className="icon" title="Show Drawing" />
        </Button>{" "} icon.
      </p>

      <p>
        <span className="doc-subtitle">Show Zoomed: </span>
        By selecting
        <span className="doc-subtitle"> Show Zoomed </span>
        <Button className="doc-button-icon">
          <BsZoomIn className="icon" title="Show Zoomed" />
        </Button>
        {" "} icon, users can view the selected equipment as zoomed in the drawing.
      </p>

      <p>
        <span className="doc-subtitle">Delink: </span>
        Through this
        <span className="doc-subtitle"> Delink </span>
        <Button className="doc-button-icon">
          <BsZoomIn className="icon" title="Show Zoomed" />
        </Button>
        {" "} icon, users can unassign an equipment from the drawing.
        The unlinked equipment will be moved to the Unassigned tab in tabular view.
      </p>

      <p className="doc-subtitle">
        Equipment Specific:
      </p>
      <p>
        All the equipment assigned to the drawing will be listed as equipment specific under this tab.
        The options to Edit, Delete, view/add Attachments are available under this tab.
      </p>
      <p>
        <span className="doc-subtitle">Edit: </span>
        Selected equipment can be edited by clicking the
        <span className="doc-subtitle"> Edit </span>
        <Button className="doc-button-icon">
          <BsFillPencilFill className="icon" title="Edit" />
        </Button>
        {" "} icon.
      </p>
      <p>
        <span className="doc-subtitle">Delete: </span>
        Users can delete Equipment by clicking the
        <span className="doc-subtitle"> Delete </span>
        <Button className="doc-button-icon">
          <BsTrashFill className="icon" title="Delete" />
        </Button>
        {" "} icon.
      </p>
      <p>
        <span className="doc-subtitle">Attachments: </span>
        Users can view/add attachments by clicking the
        <span className="doc-subtitle"> Attachments </span>
        <Button className="doc-button-icon">
          <BsPaperclip className="icon" title="Attachments" />
        </Button>
        {" "} icon.
      </p>

      <p className="doc-subtitle">
        Unassigned:
      </p>
      <p>
        All the unassigned equipment in the drawing will be listed under the Unassigned tab.
        The options to Assign Block, Edit, Delete, view/add Attachments are available under this tab.
      </p>
      <p>
        <span className="doc-subtitle">Assign Block: </span>
        Through this <span className="doc-subtitle">Assign Block </span>
        <Button className="doc-button-icon">
          <BsSubtract className="icon" title="Assign Block" />
        </Button>
        {" "} icon, users can assign equipment to blocks in the Drawings.
      </p>
      <p>
        <span className="doc-subtitle">Edit: </span>
        Selected equipment can be edited by clicking the
        <span className="doc-subtitle"> Edit </span>
        <Button className="doc-button-icon">
          <BsFillPencilFill className="icon" title="Edit" />
        </Button>
        {" "} icon.
      </p>
      <p>
        <span className="doc-subtitle">Delete: </span>
        Users can delete Equipment by clicking the
        <span className="doc-subtitle"> Delete </span>
        <Button className="doc-button-icon">
          <BsTrashFill className="icon" title="Delete" />
        </Button>
        {" "} icon.
      </p>
      <p>
        <span className="doc-subtitle">Attachments: </span>
        Users can view/add attachments by clicking the
        <span className="doc-subtitle"> Attachments </span>
        <Button className="doc-button-icon">
          <BsPaperclip className="icon" title="Attachments" />
        </Button>
        {" "} icon.
      </p>

      <p>
        <span className="doc-subtitle">
          Compare:
        </span>
      </p>

      <p>
        This option is to compare Equipment details of two Inspection Tasks.
        Select two Inspection Tasks in Active, Closed, Inactive or Completed Status of the same
        System, click <span className="doc-subtitle"> Compare </span> option from
        <span className="doc-subtitle"> More </span>{" "}
        <Button className="doc-button-icon w-36">
          <FaCog className="icon" title="More" />
          <FaChevronDown className="caret-small" />
        </Button>{" "} icon. A Comparison Report window will be displayed with Equipment
        details of the System. If any data is modified, data will be shown as New Data
        [Previous data] and the status of the Equipment will be displayed as Updated in the Report.
        If any new Equipment is added to or deleted from the System, the Status of the Equipment
        will be shown as New or Deleted in the Report.
      </p>

      <p>
        While comparing the Inspection Tasks, New status will be displayed for Equipment available
        only in the latest Inspection Task. Updated status will be displayed for equipment in both
        Inspection Tasks. Deleted status will be displayed equipment available only in old Inspection
        Task.
      </p>

      <p>An option to export the Comparison report is provided in the Comparison Report window.</p>


      <p><span className="doc-subtitle">Display Settings:</span></p>

      <p>
        This option allows the user to change the visibility of the data fields displayed in
        the data grid. On selecting this option, all the available data fields are displayed
        in a window. Display settings can be modified by selecting the required fields from the list.
        The grid settings modified through this option will not be saved and will be effective only
        for the current instance of the data grid.
      </p>

      <p><span className="doc-subtitle">Equipment Summary:</span></p>

      <p>
        This is a Summary Report of Equipment based on NFPA or Health Classification.
        It shows the total number of Equipment, Number of Equipment Functionally Tested,
        its Percentage, Number of Equipment Visually Inspected, its Percentage,
        Number of Equipment Failed and its Percentage. Equipment with Classification 'N/A'
        and those without any Classification are ignored for the Report
      </p>
    </>
  );
}
export default InspectionTasksDocument;
