import React from 'react'

const ReportsMasterDataDocument = () => {
  return (
    <>
      <h4 className="doc-title mb-2">Master Data</h4>
      <p>
        This option is used to view the Master Equipment Data of the selected System which is the 
        collection of all Equipment existing under different Inspection Task of a selected System. 
        Equipment deleted from any specific Inspection Task of this selected System will not be deleted 
        from Master Equipment Data unless there is only one Inspection Task in the System. On selecting 
        this option, the latest updated details of Master Equipment Data associated with the selected 
        System will be listed Equipment Group wise. 
      </p>

      <p>
        Drop downs to select the System, Equipment Group and Equipment Type are provided in the 
        Report and System of the latest Inspection Task by the logged in User will be selected by 
        default in the System’s drop down. First Equipment Group of the selected System will be 
        selected in Equipment Group drop down. ‘All’ will be selected by default in the 
        Equipment Type drop down which lists  Equipment Types such as AHU, Air Sampling Systems, 
        Devices, Doors, Elevators, Emergency Communications, Emergency Lightings, Modules, Fire Pumps, 
        Kitchen Extinguishers, NAC Panels, Sprinklers, Water Based Equipment etc. On selecting the 
        required Equipment Type,  the Equipment list will be filtered based on the selected Equipment Type. 
      </p>    
    </>
  );
}
export default ReportsMasterDataDocument;