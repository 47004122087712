import React from 'react'

const ReportMappingDocuments = () => {
  return (
    <>
      <h4 className="doc-title mb-2">Report Mapping</h4>

      <p> 
       This option is used to set the Inspection Fields required for each Inspection Report which are added in 
       <span className="doc-subtitle"> Manage: Inspection Settings: Inspection Fields </span> 
       as per the Report Mapping CSV file.
      </p>

      <p>
        The Inspection Fields defined through the option 
        <span className="doc-subtitle"> Manage: Inspection Settings: Inspection Fields </span> 
        are to be associated with the different Inspection Reports that are to be generated after the
        inspection. The mapping of the Inspection Fields required for each Inspection Report is carried out through this option.
      </p>

      <p>
        This option is used to view, update and save the Report Field mapping corresponding to Inspection Reports.
      </p>
      
      <p>
        The Super Administrator can select/de-select and save the field settings as per the requirement in each inspection report.
      </p>
    </>
  );
}
export default ReportMappingDocuments;
