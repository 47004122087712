import React, { useEffect, useState } from 'react'
import { useDispatch } from "react-redux";
import { APP_ROUTES } from "constants/AppRoutes";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "components/common/Breadcrumb/Breadcrumb";
import ReportViewer from 'components/common/Report/ReportViewer'
import { useAppSelector } from "../../redux/hooks";
import { createReportPreviewSession, setReportPreviewSession } from "../../redux/pages/Administration/Site/siteSlice";
import { Dropdown } from 'primereact/dropdown';
import ListGroup from 'react-bootstrap/esm/ListGroup';
import { Button } from 'primereact/button';
//icons
import { BsArrowRight, BsFileEarmarkPdf } from "react-icons/bs";
import { AiOutlineFileText } from 'react-icons/ai';
import { GrDocumentRtf } from 'react-icons/gr';
import { RiFileExcel2Line } from 'react-icons/ri';
import { onExportReport } from 'redux/common/report/customizeReportSlice';


const UserDetailsReport = () => {

	const [reportName, setReportName] = useState<any>(null);
	const [userRoleId, setUserRoleId] = useState<any>(3);
	const reportPreviewSession = useAppSelector((state) => state.site.reportPreviewSession);
	const navigate = useNavigate();
	const dispatch = useDispatch<any>();
	const userRoles = [
		{roleId: 3, user: 'Admin'},
		{roleId: 4, user: 'Admin-Tech'},
		{roleId: 5, user: 'Tech'}
	];

	useEffect(() => {
		const reportParameters = {
			reportParameters: {
				userRoleId: userRoleId,
				userSearch: "",
				sortColumn: ""
			},
			reportType: "User detail report"
		};
		dispatch(createReportPreviewSession(reportParameters));
		return (() => {
			dispatch(setReportPreviewSession(""));
		})
	},[userRoleId])

	useEffect(() => {
		if (reportPreviewSession) {
			setReportName(reportPreviewSession.previewSessionId);
		}
	}, [reportPreviewSession])

	const exportAsExcel = () => {
		const reportParameters = {
			exportParameters: {
				userRoleId: userRoleId,
				userSearch: "",
				sortColumn: ""
			},
			exportType: "User detail report"
		};
		dispatch(onExportReport(reportParameters, userRoleId === 3 ? "Admin Details" : userRoleId === 4 ? "Admin-Tech Details" : "Tech Details"));
	};

  return (
   <div className='site-reports site-report-dropdown'>
	<section className="d-flex align-items-center">
        <Breadcrumb
          title="Reports"
          classNames="report-header"
          handleGoBackClick={() => navigate(`/${APP_ROUTES.REPORT_VIEW}`)}
        />
        <div className="breadcrumb-right-arrow ml-3 mr-3">
          <BsArrowRight className="icon" />
        </div>
        <div className="breadcrumb-sub-page-title">
          <span>{(userRoles.find(e => e.roleId === userRoleId))?.user} Details</span>
        </div>
      </section>
	
	<div className="report-sort-field pt-2 pb-2">
		<div className="report-export-main d-flex">
			<div className="row px-2">
				<div className="report-field col-12 md:col-2">
					<div className="field  mb-0">
						<span className="p-float-label">
							<Dropdown
								inputId="user-role"
								className="w-100"
								value={userRoleId}
								onChange={(e) => {
									setUserRoleId(e.value);
								}}
								options={userRoles}
								optionLabel="user"
								optionValue="roleId"
							/>
							<label htmlFor="user-role">User Role</label>
						</span>
					</div>
				</div>
			</div>
			<div className="report-buttons-main d-flex">
				<div className='export-report-buttons'>
					<ListGroup horizontal>
						<span className='expor-title'>Export to</span>
						<ListGroup.Item>
							<Button className="export-btn" onClick={() => exportAsExcel()}> <RiFileExcel2Line className="icon" /> Excel </Button>
						</ListGroup.Item>
						{/* <ListGroup.Item>
							<Button className="export-btn"> <BsFileEarmarkPdf className="icon" /> PDF </Button>
						</ListGroup.Item>
						<ListGroup.Item>
							<Button className="export-btn"> <GrDocumentRtf className="icon" /> RTF </Button>
						</ListGroup.Item>
						<ListGroup.Item>
							<Button className="export-btn"> <AiOutlineFileText className="icon" /> Text </Button>
						</ListGroup.Item> */}
					</ListGroup>
				</div>
			</div>
		</div>
	</div>

	<ReportViewer reportId={reportName}/>
   </div>
  )
}

export default UserDetailsReport;