import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";

import { Button } from "primereact/button";
import MultiSelect from "components/common/MultiSelect/MultiSelect";
import { Dialog } from "primereact/dialog";
import { BsExclamationCircleFill } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { getInspectionContracts } from "redux/pages/Inspection/InspectionTask/inspectionTaskSlice";
import { useAppSelector } from "redux/hooks";
import InformationBox from "components/common/Message/InformationBox/InformationBox";

interface Props {
  showModal: boolean;
  setShowModal: (state: boolean) => void;
  selectedCustomerDetails: any;
  mapContract: (data: any) => void;
}

type FormData = {};

export const InspectionMapContract: React.FC<Props> = ({
  showModal = false,
  setShowModal = () => { },
  selectedCustomerDetails = {},
  mapContract = () => { },
}) => {
  const mapContractForm: any = useRef();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {},
  });

  const contractList = useAppSelector((state) => state.inspectionTask.inspetctionTaskContracts);  
  const [displayInfoModal, setDisplayInfoModal] = useState(false);
  const [message, setMessage] = useState("");
  const [contractListData, setContractListData] = useState<any>([]);
  const [uniqueCustomerId, setUniqueCustomerId] = useState<any>('');
  const [customerName, setCustomerName] = useState<any>('');
  const dispatch = useDispatch<any>();
  const selectedContract = useRef<any>();
  const checkedRows = useRef<any>();
  const disabledRows = useRef<any>();  

  useEffect(() => {
    if (selectedCustomerDetails && selectedCustomerDetails.uniqueCustomerId) {
      dispatch(getInspectionContracts(selectedCustomerDetails.uniqueCustomerId));
      setUniqueCustomerId(selectedCustomerDetails.uniqueCustomerId);
      setCustomerName(selectedCustomerDetails.customerName + " (" + selectedCustomerDetails.customerNumber + ")");
    }
  }, [dispatch, selectedCustomerDetails]);

  useEffect(() => {
    if (contractList && contractList.length && showModal === true) {
      let siteArray: any = [];
      contractList.map((item: any) => {
        return siteArray.push({
          id: item.uniqueContractId,
          siteName: item.contractName,
          checked: item.isSelected,
          selected: false,
          disabled: item.isDisabled,
        });
      });
      setContractListData(siteArray);
    } else {
      setContractListData([]);
    }
  }, [contractList, selectedCustomerDetails, showModal]);

  const onMapContractFormSubmit = () => {
    if (contractList && contractList.length) {
      let contractIds: any = [];
      let allCheckedContracts = [...checkedRows.current, ...disabledRows.current];
      allCheckedContracts.map((item: any) => {
        contractIds.push(item.id);
        return null;
      });
      if (uniqueCustomerId) {
        const data = {
          ContractDetails: contractIds
        };
        mapContract && mapContract(data);
      }
    } else {
      setMessage("No Contracts exist");
      setDisplayInfoModal(true);
    }
  };

  const renderFooter = () => {
    return (
      <>
        <Button
          label="Cancel"
          onClick={() => {
            setShowModal(false);
            setContractListData([]);
          }}
          className="cancel btn"
        />
        <Button
          label="Save Changes"
          className="update btn"
          autoFocus
          onClick={onMapContractFormSubmit}
        />
      </>
    );
  };

  const onError = (errors: any, e: any) => { };

  return (
    <>
      <Dialog
        header="Map Contracts"
        visible={showModal}
        style={{ width: "40vw" }}
        onHide={() => {
          setShowModal(false);
          setContractListData([]);
        }}
        footer={renderFooter()}
        className="site-modal selectsite"
      >
        <div className="content-box mt-1 mb-1">
          <div className="header d-flex align-items-center">
            <span className="title">Customer:</span>
            <span className="value">{customerName}</span>
          </div>
        </div>
        <form
          onSubmit={handleSubmit(onMapContractFormSubmit, onError)}
          ref={mapContractForm}
        >
          <div className="row px-2 pt-1">
            {contractListData && contractListData.length ?
              <div className="field col-12 md:col-2 mb-0 py-0">
                <MultiSelect
                  list={contractListData}
                  getCheckedItems={(checkedItems) => {
                    checkedRows.current = checkedItems;
                  }}
                  getSelectedItems={(selectedItems) => {
                    selectedContract.current = selectedItems;
                  }}
                  getDisabledItems={(disabledItems) => {
                    disabledRows.current = disabledItems;
                  }}
                  headerName="Contract Name"
                />
              </div> : "No Contracts exist"}
          </div>
        </form>
      </Dialog>
      <InformationBox
        showInfoModal={displayInfoModal}
        setShowInfoModal={setDisplayInfoModal}
        message={message}
      />
    </>
  );
};

export default InspectionMapContract;
