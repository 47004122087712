import React, { useState, useCallback, memo, useEffect } from "react";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { Calendar } from "primereact/calendar";
import { BsArrowRight, BsGridFill } from "react-icons/bs";
import { FaDownload } from "react-icons/fa";
import { APP_ROUTES } from "constants/AppRoutes";
import { SAP_IMPORT_STATUS_COLS } from "./SapImportStatusData";
import moment from "moment";
//components
import { DataTableComponent } from "components/Table/DataTable";
import { ImportSapDetails } from "pages/ManageTools/SAPImportStatus/ManageSapImportStatus/ImportDetails";
import Breadcrumb from "components/common/Breadcrumb/Breadcrumb";
import InformationBox from "components/common/Message/InformationBox/InformationBox";
//redux
import { useDispatch } from "react-redux";
import { onSapImportDataDownload, onGetSapImportStatusDetails, onGetSapImportStatusList, setSapImportStatusList } from "redux/pages/Manage/Tools/ToolSlice";
import { useAppSelector } from "redux/hooks";


interface Props {
    showModal?: boolean;
    setShowModal?: (state: boolean) => void;
    // selectedRowData?: (data: any) => void;
}

export const SAPImportStatus: React.FC<Props> = () => {
    const navigate = useNavigate();

    const [showImportSapDetails, setShowImportSapDetails] = useState<boolean>(false);
    const [bodyInfo, setBodyInfo] = useState<any>({
        fromDate: moment(new Date().setFullYear(new Date().getFullYear() - 1)).format("yyyy-MM-DD"),
        toDate: moment(new Date()).format("yyyy-MM-DD")
    });
    const [gridData, setGridData] = useState([]);
    const [pageDetails, setPageDetails] = useState<any>({});
    const [pageIndex, setPageIndex] = useState(0);
    const [selectedRows, setSelectedRows] = useState<any>([]);
    const [currentStart, setCurrentStart] = useState<any>([]);
    const [sortField, setSortField] = useState('');
    const [sortOrder, setSortOrder] = useState('');
    const [displayInfoModal, setDisplayInfoModal] = useState(false);
    const [message, setMessage] = useState("");

    const dispatch = useDispatch<any>();
    const sapImportStatusList = useAppSelector((state) => state.tool.sapImportStatusList);
    const sapImportPageDetails = useAppSelector((state) => state.tool.sapImportPageDetails);
    const rowsPerPage = useAppSelector((state) => state.personalSettings.rowsPerPage);

    useEffect(() => {
        getSapImportStatusList();
    }, [rowsPerPage]);

    useEffect(() => {         
        if (sapImportStatusList) {
            setGridData(sapImportStatusList?.sapImportDetails);
            dispatch(setSapImportStatusList(""));
        }
    }, [sapImportStatusList]);

    useEffect(() => {
        if (sapImportPageDetails) {
            setPageDetails(sapImportPageDetails);
        }
    }, [sapImportPageDetails]);

    useEffect(() => {
        if (rowsPerPage && rowsPerPage > 0) {
            let modifiedPageDetails = { ...pageDetails, 'currentLength': rowsPerPage, 'pageCount': rowsPerPage };
            setPageDetails(modifiedPageDetails);
            setCurrentStart(1)
            setPageIndex(0)
        }
    }, [rowsPerPage])

    const getSapImportStatusList = () => {
        dispatch(onGetSapImportStatusList(bodyInfo, 1));
    }

    const handleRowSelect = useCallback((data: any) => {
        setSelectedRows(data)
    }, []);

    const clearFilter = () => {
        bodyInfo.fromDate = moment(new Date().setFullYear(new Date().getFullYear() - 1)).format("yyyy-MM-DD");
        bodyInfo.toDate = moment(new Date()).format("yyyy-MM-DD");
        setBodyInfo(bodyInfo);
        getSapImportStatusList();
    }

    const onSort = (event) => {
        let sortDirection: string;
        if (event.sortOrder === 1)
          sortDirection = "ASC"
        else
          sortDirection = "DESC"
        setSortField(event.sortField)
        setSortOrder(sortDirection)
        dispatch(onGetSapImportStatusList(bodyInfo, currentStart, event?.sortField, sortDirection)); 
      }

    const onPageChanged = (options: any) => {
        if (options !== pageIndex) { 
            let currentStart = (options * pageDetails?.currentLength) + 1;
            setPageIndex(options)
            setCurrentStart(currentStart)
            dispatch(onGetSapImportStatusList(bodyInfo, currentStart,sortField,sortOrder));  
        }
    };
    
    const showImportDetails = () => {
        if (selectedRows && selectedRows.length) {
            if (selectedRows.length === 1) {
                setShowImportSapDetails(true);
                dispatch(onGetSapImportStatusDetails(selectedRows[0].id));
            } else {
                setMessage("This operation can be performed only one row at a time");
                setDisplayInfoModal(true);
            }
        } else {
            setMessage("Select a Record");
            setDisplayInfoModal(true);
        }
    }

    const downloadSapImportData = () => {
        if (selectedRows && selectedRows.length) {
            if (selectedRows.length == 1) {
                dispatch(onSapImportDataDownload(selectedRows[0].id, selectedRows[0].fileName, setDisplayInfoModal, setMessage));
            } else {
                setMessage("This operation can be performed only one row at a time");
                setDisplayInfoModal(true);
            }
        } else {
            setMessage("Select a Record");
            setDisplayInfoModal(true);
        }
    }

    return (
        <div className="sap-import">
            <section className="d-flex align-items-center">
                <Breadcrumb title="Tools" classNames="report-header" handleGoBackClick={() => navigate(`/${APP_ROUTES.MANAGE_VIEW}`)} />
                <div className="breadcrumb-right-arrow ml-3 mr-3">
                    <BsArrowRight className="icon" />
                </div>
                <div className="breadcrumb-sub-page-title">
                    <span>SAP Import Status</span>
                </div>
            </section>
            <div className="heading">
                SAP Import Status
            </div>
            <div className="second-row">
                <div className="left-col">
                    <div className="field">
                        <div className="custom-float-field mb-0">
                            <span className="p-float-label">
                                <Calendar
                                    id="single"
                                    value={new Date(bodyInfo.fromDate)}
                                    onChange={(e: any) => setBodyInfo((body) => ({ ...body, fromDate: moment(e.value).format("yyyy-MM-DD") }))}
                                    selectionMode="single"
                                    dateFormat="yy-mm-dd"
                                    readOnlyInput
                                />
                                <label htmlFor="fieldValue">Uploaded From</label>
                            </span>
                        </div>
                    </div>
                    <div className="field">
                        <div className="custom-float-field mb-0">
                            <span className="p-float-label">
                                <Calendar
                                    id="single"
                                    value={new Date(bodyInfo.toDate)}
                                    onChange={(e: any) => setBodyInfo((body) => ({ ...body, toDate: moment(e.value).format("yyyy-MM-DD") }))}
                                    selectionMode="single"
                                    dateFormat="yy-mm-dd"
                                    readOnlyInput
                                />
                                <label htmlFor="fieldValue">Uploaded To</label>
                            </span>
                        </div>
                    </div>
                    <div className="btn-col">
                        <Button className=" btn" onClick={() => getSapImportStatusList()}>
                            Search
                        </Button>
                        
                        <Button className="pre btn" onClick={() => clearFilter()}>
                            Clear
                        </Button>
                    </div>
                </div>
                <div className="table-config">
                    <div className="action-btns">
                        <Button className="button-icon" tooltip="Status Details" tooltipOptions={{ position: "top" }}>
                            <BsGridFill className="icon" onClick={() => showImportDetails()} />
                        </Button>
                        <Button className="button-icon" tooltip="Download" onClick={() => downloadSapImportData()} tooltipOptions={{ position: "top" }}>
                            <FaDownload className="icon" />
                        </Button>
                    </div>
                </div>
            </div>
            <div className="sap-import-table">
                <DataTableComponent
                    rows={gridData}
                    cols={SAP_IMPORT_STATUS_COLS}
                    paginator={true}
                    dataKeyId="id"
                    title={``}
                    pageDetails={pageDetails}
                    pageIndex={pageIndex}
                    onPageChanged={onPageChanged}
                    showCustomSearchCmp
                    onSortData={onSort}
                    getSelectedRowData={handleRowSelect}
                />
            </div>
            <InformationBox
                showInfoModal={displayInfoModal}
                setShowInfoModal={setDisplayInfoModal}
                message={message}
            />
            {showImportSapDetails ? (
                <ImportSapDetails
                    showModal={showImportSapDetails}
                    setShowModal={setShowImportSapDetails}
                    selectedRows={selectedRows} />
            ) : null}
        </div>
    );
};

export default memo(SAPImportStatus);
