import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { BsExclamationCircleFill } from 'react-icons/bs';

const statusList = [
    { label: 'Active', value: 'Active' },
    { label: 'Blocked', value: 'Blocked' },
    { label: 'Deleted', value: 'Deleted' },
    { label: 'Not Activated', value: 'Not Activated' }
];

const getErrorMsg = options => {
    const { field, value } = options;
    const FIELD_REQUIRED = 'Building Name is required';
    const BLOCK_COMMA_MESSAGE = 'Comma is not allowed in building name';
    switch (field) {
        case 'buildingName': {
            if (!value.trim())
                return FIELD_REQUIRED;
            else if(value?.includes(","))
                return BLOCK_COMMA_MESSAGE 
            break;
        }
        default: {
            return ''
        }
    }
};


const statusEditor = (options) => {
    return (
        <Dropdown disabled value={options.value} options={statusList} optionLabel="label" optionValue="value"
            onChange={(e) => options.editorCallback(e.value)} placeholder="Select Status"
            itemTemplate={(option) => {
                return <span className='status-badge'>{option.label}</span>
            }} />
    );
}

const buildingEditor = (options) => {
    const errorMsg = getErrorMsg(options);
    return <>
        <InputText className={`cell-error error-tooltip ${errorMsg ? 'error' : ""}`} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value.trimStart())} />
        {errorMsg && <span className="tooltip-text"><BsExclamationCircleFill />{errorMsg}</span>}
    </>
}


const textEditor = (options) => {
    return <InputText disabled type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
}


export const SITEBUILD_COLS = [
    {
        field: 'siteName',
        header: 'Site',
        editor: (options) => textEditor(options),
        style: { flex: '1 0 290px' },
        sortable: true,
    },
    {
        field: 'buildingName',
        header: 'Building',
        editor: (options) => buildingEditor(options),
        style: { flex: '1 0 290px' },
        sortable: true,
    },
    {
        field: 'floorCount',
        header: 'Floor Count',
        editor: (options) => textEditor(options),
        style: { flex: '1 0 125px' },
        sortable: true,
        align: "right"
    },
    {
        field: 'attachmentCount',
        header: 'Attachments',
        editor: (options) => textEditor(options),
        style: { flex: '1 0 125px' },
        sortable: true,
        align: "right"
    },
    {
        field: 'status',
        header: 'Status',
        editor: (options) => statusEditor(options),
        style: { flex: '1 0 150px' },
        sortable: true,
    }
];