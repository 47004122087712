import React, { useEffect, useState } from "react";

import { Dialog } from "primereact/dialog";

import ReportViewer from "components/common/Report/ReportViewer";
import { useAppSelector } from "redux/hooks";
import { useDispatch } from "react-redux";
import { createReportPreviewSession, setReportPreviewSession } from "redux/pages/Administration/Site/siteSlice";
import { CUSTOMIZATION_ATTRIBUTES_TYPE } from "constants/common";

interface Props {
    showModal: boolean;
    setShowModal: (state: boolean) => void;
    reportFlag: string;
    selectedRows: any;
    selectedTab: any;
    attributeId?: any;
    attributeName?: any;
    equipmentTypeId?: any;
    equipmentTypeName?: any;
    sortByColumns: any;
    searchKeyword: string;
    sortColumn: string;
    sortOrder: string;
}

export const CustomizationReport: React.FC<Props> = ({
    showModal = false,
    setShowModal = () => { },
    reportFlag = '',
    selectedRows = [],
    selectedTab = "",
    attributeId = "",
    attributeName = "",
    equipmentTypeId = "",
    equipmentTypeName = "",
    sortByColumns = [],
    searchKeyword = '',
    sortColumn = '',
    sortOrder = '',
}) => {
    const [reportId, setReportId] = useState<any>(null);
    const reportPreviewSession = useAppSelector((state) => state.site.reportPreviewSession);
    const dispatch = useDispatch<any>();

    const getSelectedAttributeIds = () => {
        let params: any = [];
        selectedRows.map(item => {
            params.push(item.uniqueAttributeId);
        });
        return params;
    };

    const getSelectedValuesIds = () => {
        let params: any = [];
        selectedRows.map(item => {
            params.push(item.dataFieldLookUpId);
        });
        return params;
    };

    useEffect(() => {
        switch (selectedTab) {
            case CUSTOMIZATION_ATTRIBUTES_TYPE.COMMON_ATTRIBUTES: {
                let params;
                if (reportFlag === 'all') {
                    params = ""
                } else {
                    params = getSelectedAttributeIds();
                }
                const reportParameters = {
                    reportParameters: {
                        uniqueEquipmentClassId: params,
                        needCommonAttribute: "",
                        search: searchKeyword,
                        sortColumn: sortColumn,
                        sortOrder: sortOrder
                    },
                    reportType: "Object Attribute Grid Report"
                };
                dispatch(createReportPreviewSession(reportParameters));
              break;
            }
            case CUSTOMIZATION_ATTRIBUTES_TYPE.COMMON_ATTRIBUTES_VALUES: {
                let params;
                if (reportFlag === 'all') {
                    params = ""
                } else {
                    params = getSelectedValuesIds();
                }
                const reportParameters = {
                    reportParameters: {
                        uniqueDataFieldId: attributeId,
                        uniqueAttributeId: params,
                        attributeName: attributeName,
                        search: searchKeyword,
                        sortColumn: sortColumn,
                        sortOrder: sortOrder
                    },
                    reportType: "Object Attribute Values"
                };
                dispatch(createReportPreviewSession(reportParameters));
              break;
            }
            case CUSTOMIZATION_ATTRIBUTES_TYPE.EQUIPMENT_TYPE_ATTRIBUTES: {
                let params;
                if (reportFlag === 'all') {
                    params = ""
                } else {
                    params = getSelectedAttributeIds();
                }
                const reportParameters = {
                    reportParameters: {
                        uniqueEquipmentClassId: equipmentTypeId,
                        needCommonAttribute: false,
                        attributeName: equipmentTypeName,
                        selectedIds: params,
                        search: searchKeyword,
                        sortColumn: sortColumn,
                        sortOrder: sortOrder
                    },
                    reportType: "Equipment Type Attributes Report"
                };
                dispatch(createReportPreviewSession(reportParameters));
              break;
            }
            case CUSTOMIZATION_ATTRIBUTES_TYPE.EQUIPMENT_TYPE_ATTRIBUTES_VALUES: {
                let params;
                if (reportFlag === 'all') {
                    params = ""
                } else {
                    params = getSelectedValuesIds();
                }
                const reportParameters = {
                    reportParameters: {
                        uniqueDataFieldId: equipmentTypeId,
                        uniqueAttributeId: attributeId,
                        attributeName: attributeName,
                        equipmentTypeName: equipmentTypeName,
                        selectedIds: params,
                        needCommonAttribute: false,
                        search: searchKeyword,
                        sortColumn: sortColumn,
                        sortOrder: sortOrder
                    },
                    reportType: "Equipment Type Attributes Values Report"
                };
                dispatch(createReportPreviewSession(reportParameters));
              break;
            }
            default: {
                let params;
                if (reportFlag === 'all') {
                    params = ""
                } else {
                    params = getSelectedAttributeIds();
                }
                const reportParameters = {
                    reportParameters: {
                        uniqueEquipmentClassId: params,
                        needCommonAttribute: "",
                        search: searchKeyword,
                        sortColumn: sortColumn,
                        sortOrder: sortOrder
                    },
                    reportType: "Object Attribute Grid Report"
                };
                dispatch(createReportPreviewSession(reportParameters));
              break;
            }
          }

        return (() => {
            dispatch(setReportPreviewSession(""));
        })
    }, [dispatch, selectedTab]);

    useEffect(() => {
        if (reportPreviewSession) {
            setReportId(reportPreviewSession.previewSessionId);
        }
    }, [reportPreviewSession])

    return (
        <>
            <Dialog
                header="Report"
                visible={showModal}
                onHide={() => {
                    dispatch(setReportPreviewSession(""));
                    setShowModal(false);
                }}
                className="display-settings report selectsite"
                style={{ width: "90vw", height: "600px" }}
            >
                <div className="report-export-main d-flex mb-3">
                </div>
                <ReportViewer reportId={reportId} />
            </Dialog>
        </>
    );
};

export default CustomizationReport;
