import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";

import ReportViewer from "components/common/Report/ReportViewer";
import { useAppSelector } from "redux/hooks";
import { useDispatch } from "react-redux";
import { createReportPreviewSession, setReportPreviewSession } from "redux/pages/Administration/Site/siteSlice";


interface Props {
    showModal: boolean;
    setShowModal: (state: boolean) => void;
    reportFlag: string;
    uniqueInspctionTaskId: any;
    equipmentId: any;
    selectedRows: any;
    sortByColumns: any;
}

export const AttachmentReport: React.FC<Props> = ({
    showModal = false,
    setShowModal = () => { },
    reportFlag = '',
    uniqueInspctionTaskId = '',
    equipmentId = '',
    selectedRows = [],
    sortByColumns = [],
}) => {

    const [reportId, setReportId] = useState<any>(null);
    const reportPreviewSession = useAppSelector((state) => state.site.reportPreviewSession);
    const dispatch = useDispatch<any>();

    const getSelectedAttachmentIds = () => {
        let params: any = [];
        selectedRows.map(item => {
            params.push(item.attachmentId);
        });
        return params;
    };

    useEffect(() => {
        let params;
        if (reportFlag === 'all') {
            params = ""
        } else {
            params = getSelectedAttachmentIds();
        }
        const reportParameters = {
            reportParameters: {
                uniqueInspctionTaskId: uniqueInspctionTaskId,
                equipmentId: equipmentId,
                selectedIds: params
            },
            reportType: "Equipment Attachments Report"
        };
        dispatch(createReportPreviewSession(reportParameters));

        return (() => {
            dispatch(setReportPreviewSession(""));
        })
    }, []);

    useEffect(() => {
        if (reportPreviewSession) {
            setReportId(reportPreviewSession.previewSessionId);
        }
    }, [reportPreviewSession])


    return (
        <>
            <Dialog
                header="Attachment Report"
                visible={showModal}
                onHide={() => {
                    dispatch(setReportPreviewSession(""));
                    setShowModal(false);
                }}
                className="display-settings report selectsite"
                style={{ width: "90vw", height: "600px" }}
            >
                <ReportViewer reportId={reportId} />
            </Dialog>
        </>
    );
};

export default AttachmentReport;
