import React, { memo, useEffect, useRef, useState } from 'react'
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { RadioButton } from 'primereact/radiobutton';
import { classNames } from 'primereact/utils';
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from 'react-redux';
import { AutoComplete } from 'primereact/autocomplete';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
//components
import AddContract from 'pages/Contracts/ManageContract/AddContract';
import ConfirmationBox from 'components/common/Message/ConfirmationBox/ConfirmationBox';
import InformationBox from 'components/common/Message/InformationBox/InformationBox';
//icons 
import { BsExclamationCircleFill, BsPlusLg } from 'react-icons/bs';
//redux
import { onGetContract, onGetCustomers, onGetSites, onCopyOrMoveSystemReports, checkSalesOfficeExist, onGetContractSalesOffice } from 'redux/pages/Manage/Tools/ToolSlice';
import { onAddContract, setNewContractData } from 'redux/pages/Inspection/Contracts/contractSlice';
import { useAppSelector } from 'redux/hooks';



type FormData = {
    fromCustomerSearchCriteria: string;
    fromContractId: string;
    fromSiteId: string;
    toCustomerSearchCriteria: any;
    toSiteId: string;
    toContractId: string;
};

interface CopyAllSystemsPropType {
    systemType?: string;
}
const CopyAllSystems: React.FC<CopyAllSystemsPropType> = (props) => {
    const { systemType = "" } = props;

    const [copyKey, setCopyKey] = useState("copy-latest");
    const [isCopyAll, setIsCopyAll] = useState(true);
    const [fromContractList, setFromContractList] = useState([]);
    const [fromSiteList, setFromSiteList] = useState([]);
    const [toSiteList, setToSiteList] = useState([]);
    const [toContractList, setToContractList] = useState<any>([]);
    const [filterFromCustomers, setFilterFromCustomers] = useState<any>(null);
    const [filterToCustomers, setFilterToCustomers] = useState<any>(null);
    const [displayInfoModal, setDisplayInfoModal] = useState(false);
    const [message, setMessage] = useState("");
    const [showAddContract, setShowAddContract] = useState(false);
    const [displayConfirmModal, setDisplayConfirmModal] = useState(false);
    const addRef = useRef<any>(null);
    //case of submitting 
    const [bodyInfo, setBodyInfo] = useState<any>(null);
    const [targetPage, setTargetPage] = useState(1);
    const [statusMessage, setStatusMessage] = useState("");
    const [systemList, setSystemList] = useState<any>(null);
    const [isCopied, setIsCopied] = useState(false);
    const [showSystemDuplication, setShowSystemDuplication] = useState(false);
    const [showError, setShowError] = useState(false);
    const [destinationSite, setDestinationSite] = useState<any>(null);

    const dispatch = useDispatch<any>();
    const newContractData = useAppSelector((state) => state.contract.newContractData);

    const {
        control,
        handleSubmit,
        watch,
        setValue,
        reset,
        formState: { errors },
    } = useForm<FormData>({
        defaultValues: {
            fromCustomerSearchCriteria: "",
            fromContractId: "",
            fromSiteId: "",
            toCustomerSearchCriteria: "",
            toSiteId: "",
            toContractId: "",
        },
    });

    useEffect(() => {
        if (newContractData) {
            let data = JSON.parse(JSON.stringify(newContractData));
            data.contractNameWithoutSite = data.contractName;
            data.contractName = `${data.contractName} (${data.contractNumber})`;
            toContractList.push(data);
            const ascDatas = [...toContractList].sort((a, b) => a.contractNameWithoutSite.localeCompare(b.contractNameWithoutSite));
            setToContractList(ascDatas);
            dispatch(setNewContractData(""));
        }
    }, [newContractData]);

    useEffect(() => {
        if (statusMessage) {
            setDisplayConfirmModal(true);
        }
    }, [statusMessage])

    useEffect(() => {
        if (isCopied) {
            reset();
            setIsCopied(false);
            setStatusMessage("");
            setTargetPage(1);
            setSystemList(null);
            setDisplayConfirmModal(false);
            setShowSystemDuplication(false);
            setFilterFromCustomers(null);
            setFromContractList([]);
            setFilterToCustomers(null);
            setFromSiteList([]);
            setToSiteList([]);
            setToContractList([]);
        }
    }, [isCopied])

    useEffect(() => {
        if (targetPage === 4) {
            setShowSystemDuplication(false);
        }
    }, [targetPage])

    const searchFromCustomer = (event) => {
        setTimeout(() => {
            const body = {
                uniqueBusinessZoneId: null,
                searchText: event?.query,
                uniqueSourceCustomerId: null,
            }
            dispatch(onGetCustomers(null, body, setFilterFromCustomers));
        }, 250);
    };

    const searchToCustomer = (event) => {
        const customer: any = watch("fromCustomerSearchCriteria");
        if (!customer) {
            setDisplayInfoModal(true);
            setMessage("Select a From Customer");
            return;
        }
        setTimeout(() => {
            const body = {
                uniqueBusinessZoneId: null,
                searchText: event?.query,
                uniqueSourceCustomerId: customer?.uniqueCustomerId,
            }
            dispatch(onGetCustomers(null, body, setFilterToCustomers));
        }, 250);
    }

    const copyData = (data) => {
        if (!data?.fromCustomerSearchCriteria?.uniqueCustomerId) {
            setDisplayInfoModal(true);
            setMessage("Select a From Customer");
            return;
        }
        if (!data?.toCustomerSearchCriteria?.uniqueCustomerId) {
            setDisplayInfoModal(true);
            setMessage("Select a To Customer");
            return;
        }

        const body = {
            uniqueSourceCustomerId: data?.fromCustomerSearchCriteria?.uniqueCustomerId,
            uniqueSourceContractId: data?.fromContractId,
            uniqueSourceSiteId: data?.fromSiteId ? data?.fromSiteId : '0',
            uniqueSourceSystemId: null,
            uniqueTargetCustomerId: data?.toCustomerSearchCriteria?.uniqueCustomerId,
            uniqueTargetContractId: data?.toContractId,
            uniqueTargetSiteId: data?.toSiteId ? data?.toSiteId : '0',
            copySystem: copyKey === "copy-latest" ? "LATEST_TASK" : "ALL_TASKS",
            copyAllReport: isCopyAll,
            keepSourceData: true,
            systemData: systemType === "system" ? "SELECTED_SYSTEM" : "ALL_SYSTEM",
            system: systemList,
            pageTarget: targetPage,
        }

        dispatch(onCopyOrMoveSystemReports(body, setStatusMessage, setTargetPage, setSystemList, setIsCopied, setMessage, setDisplayInfoModal));
        setBodyInfo(body);
    }

    const addContract = () => {
        const fromCustomer: any = watch("fromCustomerSearchCriteria");
        const toCustomer: any = watch("toCustomerSearchCriteria");
        const toSite: any = watch("toSiteId");
        const sourceContract = watch("fromContractId");
        const sourceSite = watch("fromSiteId");

        if (!fromCustomer?.uniqueCustomerId) {
            setDisplayInfoModal(true);
            setMessage("Select a From Customer");
            return;
        }
        if (!toCustomer?.uniqueCustomerId) {
            setDisplayInfoModal(true);
            setMessage("Select a To Customer");
            return;
        }
        if (!toSite) {
            setDisplayInfoModal(true);
            setMessage("Select Site");
            return;
        }
        if (!sourceContract) {
            setDisplayInfoModal(true);
            setMessage("Select Contract");
            return;
        }
        if (!sourceSite) {
            setDisplayInfoModal(true);
            setMessage("Select Site");
            return;
        }
        const body = {
            uniqueSourceSiteId: sourceSite,
            uniqueSourceContractId: sourceContract,
            uniqueSourceCustomerId: fromCustomer?.uniqueCustomerId,
            systemData: "ALL_SYSTEM"
        }
        dispatch(checkSalesOfficeExist(body, setShowAddContract));
        dispatch(onGetContractSalesOffice(sourceContract, 0));
    }

    const onAddContractClick = (submitData: any) => {
        submitData.UniqueSiteId = destinationSite;
        dispatch(
            onAddContract(
                submitData,
                setMessage,
                setDisplayInfoModal,
                setShowAddContract,
                false,
                false,
                true
            )
        );
    };

    const confirmedCopy = () => {
        if (targetPage === 3) {
            setShowSystemDuplication(true);
            setDisplayConfirmModal(false);
            return;
        }
        bodyInfo.system = systemList;
        bodyInfo.pageTarget = targetPage;
        setBodyInfo(bodyInfo);
        dispatch(onCopyOrMoveSystemReports(bodyInfo, setStatusMessage, setTargetPage, setSystemList, setIsCopied, setMessage, setDisplayInfoModal))
    }

    const onConfirmCancel = () => {
        setDisplayConfirmModal(false);
        setStatusMessage("");
        setTargetPage(1);
        setSystemList(null);
    }

    const onCancelSystem = () => {
        setStatusMessage("");
        setTargetPage(1);
        setSystemList(null);
        setShowSystemDuplication(false);
    }

    const onUpdateSystem = () => {
        if (targetPage === 3) {
            let isEmpty = false;
            systemList?.forEach(element => {
                if (!element?.uniqueSystemName)
                    isEmpty = true
            });
            if (isEmpty) {
                setShowError(true);
                return;
            }
        }
        const systems = systemList?.map(({ sourceSystemName, ...rest }: any) => {
            return rest;
        });
        bodyInfo.system = systems;
        bodyInfo.pageTarget = targetPage;
        dispatch(onCopyOrMoveSystemReports(bodyInfo, setStatusMessage, setTargetPage, setSystemList, setIsCopied, setMessage, setDisplayInfoModal))
        setBodyInfo(bodyInfo);
    }

    const getFormErrorMessage = (name) => {
        return (
            errors[name] && (
                <span className="tooltip-text">
                    <BsExclamationCircleFill />
                    {errors[name].message}
                </span>
            )
        );
    };

    const renderFooter = () => {
        return (
            <>
                <Button label="Cancel" onClick={onCancelSystem} className='cancel btn' />
                <Button label="Ok" className='save btn' onClick={onUpdateSystem} autoFocus />
            </>
        );
    }

    const editableTemplate = (rowData, columnDetails) => {

        return (
            <div className='custom-float-field'>
                <span className="p-float-label ">
                    <InputText
                        id={rowData?.uniqueSystemId}
                        value={rowData?.uniqueSystemName}
                        onChange={(e: any) => {
                            systemList[columnDetails?.rowIndex]["uniqueSystemName"] = e.target.value;
                            setSystemList([...systemList]);
                        }}
                        className={`w-100 ${showError && rowData?.uniqueSystemName === "" ? 'p-invalid error-tooltip error' : ''}`}
                    />
                    <label htmlFor={rowData?.uniqueSystemId} className={"mandatory"}>
                        Destination System Name
                    </label>
                    {showError && rowData?.uniqueSystemName === "" &&
                        <span className="tooltip-text">
                            <BsExclamationCircleFill />
                            <span>Enter System Name</span>
                        </span>
                    }
                </span>
            </div>
        )
    }

    return (
        <div className="copy-system mt-3">
            <p className="title">
                <b>Copy All Systems in a Contract</b>
            </p>
            <div className="send-recieve-items">
                <form onSubmit={handleSubmit(copyData)} ref={addRef}>
                    <div className="from box">
                        <span className="pb-2 pl-2">
                            <b>From</b>
                        </span>
                        <div className="field col-12 md:col-2 mb-0">
                            <span className="p-float-label">
                                <Controller
                                    name="fromCustomerSearchCriteria"
                                    control={control}
                                    rules={{
                                        required: "Enter Customer Name",
                                    }}
                                    render={({ field, fieldState }) => (
                                        <AutoComplete
                                            id="fromCustomerSearchCriteria"
                                            value={field.value}
                                            className={classNames("w-100 error-tooltip", {
                                                error: fieldState.invalid,
                                            })}
                                            suggestions={filterFromCustomers}
                                            completeMethod={searchFromCustomer}
                                            onSelect={(e) => {
                                                const body = {
                                                    uniqueCustomerId: e.value.uniqueCustomerId,
                                                    uniqueSiteId: e.value.uniqueSiteId,
                                                    UniqueSystemId: null,
                                                    IsCopy: true,
                                                }
                                                setValue("fromContractId", "");
                                                setFromContractList([]);
                                                dispatch(onGetContract(body, setFromContractList, setDisplayInfoModal, setMessage))
                                            }}
                                            field="customerName"
                                            onChange={(e) => field.onChange(e.value)}
                                            aria-label="Customers"
                                            dropdownAriaLabel="Select Customer"
                                        />
                                    )}
                                />
                                <label className="mandatory" htmlFor="fromCustomerSearchCriteria">
                                    Customer
                                </label>
                                {getFormErrorMessage("fromCustomerSearchCriteria")}
                            </span>
                        </div>

                        <div className="field col-12 md:col-2 mb-0">
                            <span className="p-float-label">
                                <Controller
                                    name="fromContractId"
                                    control={control}
                                    rules={{
                                        required: watch("fromCustomerSearchCriteria") ? "Select a Contract" : "",
                                    }}
                                    render={({ field, fieldState }) => (
                                        <Dropdown
                                            inputId="fromContractId"
                                            className={classNames("w-100 error-tooltip", {
                                                error: fieldState.invalid,
                                            })}
                                            {...field}
                                            value={field.value}
                                            onChange={(e) => {
                                                field.onChange(e.value);
                                                const customer: any = watch("fromCustomerSearchCriteria");
                                                const body = {
                                                    uniqueCustomerId: customer?.uniqueCustomerId,
                                                    uniqueBusinessZoneId: null,
                                                    searchText: "",
                                                    uniqueContractId: e.value,
                                                }
                                                setValue("fromSiteId", "");
                                                setFromSiteList([]);
                                                dispatch(onGetSites(null, body, setFromSiteList, false, setDisplayInfoModal, setMessage))
                                            }}
                                            options={fromContractList}
                                            optionLabel="contractName"
                                            optionValue="uniqueContractId"
                                        />
                                    )}
                                />
                                <label className="mandatory" htmlFor="dropdown">
                                    Contract
                                </label>
                                {getFormErrorMessage("fromContractId")}
                            </span>
                        </div>

                        <div className="field col-12 md:col-2 mb-0">
                            <span className="p-float-label">
                                <Controller
                                    name="fromSiteId"
                                    control={control}
                                    rules={{
                                        required: watch("fromContractId") ? "Select a Site" : "",
                                    }}
                                    render={({ field, fieldState }) => (
                                        <Dropdown
                                            inputId="fromSiteId"
                                            className={classNames("w-100 error-tooltip", {
                                                error: fieldState.invalid,
                                            })}
                                            {...field}
                                            value={field.value}
                                            onChange={(e) => field.onChange(e.value)}
                                            options={fromSiteList}
                                            optionLabel="siteName"
                                            optionValue="uniqueSiteId"
                                        />
                                    )}
                                />
                                <label className="mandatory" htmlFor="dropdown">
                                    Site
                                </label>
                                {getFormErrorMessage("fromSiteId")}
                            </span>
                        </div>
                    </div>
                    <div className="icon"></div>
                    <div className="to box">
                        <span className="pb-2 pl-2">
                            <b>To</b>
                        </span>
                        <div className="field col-12 md:col-2 mb-0">
                            <span className="p-float-label">
                                <Controller
                                    name="toCustomerSearchCriteria"
                                    control={control}
                                    rules={{
                                        required: "Enter Customer Name",
                                    }}
                                    render={({ field, fieldState }) => (
                                        <AutoComplete
                                            id="toCustomerSearchCriteria"
                                            value={field.value}
                                            className={classNames("w-100 error-tooltip", {
                                                error: fieldState.invalid,
                                            })}
                                            suggestions={filterToCustomers}
                                            completeMethod={searchToCustomer}
                                            onSelect={(e) => {
                                                const body = {
                                                    uniqueCustomerId: e.value.uniqueCustomerId,
                                                    uniqueBusinessZoneId: null,
                                                    searchText: "",
                                                    uniqueContractId: null,
                                                }
                                                setValue("toSiteId", "");
                                                setToSiteList([]);
                                                dispatch(onGetSites(e?.value?.countryId, body, setToSiteList, true, setDisplayInfoModal, setMessage))
                                            }}
                                            field="customerName"
                                            onChange={(e) => field.onChange(e.value)}
                                            aria-label="Customers"
                                            dropdownAriaLabel="Select Customer"
                                        />
                                    )}
                                />
                                <label className="mandatory" htmlFor="toCustomerSearchCriteria">
                                    Customer
                                </label>
                                {getFormErrorMessage("toCustomerSearchCriteria")}
                            </span>
                        </div>

                        <div className="field col-12 md:col-2 mb-0">
                            <span className="p-float-label">
                                <Controller
                                    name="toSiteId"
                                    control={control}
                                    rules={{
                                        required: watch("toCustomerSearchCriteria") ? "Select a Site" : "",
                                    }}
                                    render={({ field, fieldState }) => (
                                        <Dropdown
                                            inputId="toSiteId"
                                            className={classNames("w-100 error-tooltip", {
                                                error: fieldState.invalid,
                                            })}
                                            {...field}
                                            value={field.value}
                                            onChange={(e) => {
                                                field.onChange(e.value);
                                                const customer: any = watch("toCustomerSearchCriteria");
                                                const body = {
                                                    uniqueSiteId: e.value ? e.value : '0',
                                                    uniqueCustomerId: customer?.uniqueCustomerId,
                                                    UniqueSystemId: null,
                                                    IsCopy: true,
                                                }
                                                setDestinationSite(watch("toSiteId"));
                                                setValue("toContractId", "");
                                                setToContractList([]);
                                                dispatch(onGetContract(body, setToContractList, setDisplayInfoModal, setMessage))
                                            }}
                                            options={toSiteList}
                                            optionLabel="siteName"
                                            optionValue="uniqueSiteId"
                                        />
                                    )}
                                />
                                <label className="mandatory" htmlFor="toSiteId">
                                    Site
                                </label>
                                {getFormErrorMessage("toSiteId")}
                            </span>
                        </div>

                        <div className="row px-2 align-items-center">
                            <div className="field col-11 md:col-2 mb-0">
                                <span className="p-float-label">
                                    <Controller
                                        name="toContractId"
                                        control={control}
                                        rules={{
                                            required: watch("toSiteId") ? "Select a Contract" : "",
                                        }}
                                        render={({ field, fieldState }) => (
                                            <Dropdown
                                                inputId="toContractId"
                                                className={classNames("w-100 error-tooltip", {
                                                    error: fieldState.invalid,
                                                })}
                                                {...field}
                                                value={field.value}
                                                onChange={(e) => field.onChange(e.value)}
                                                options={toContractList}
                                                optionLabel="contractName"
                                                optionValue="uniqueContractId"
                                            />
                                        )}
                                    />
                                    <label className="mandatory" htmlFor="toContractId">
                                        Contract
                                    </label>
                                    {getFormErrorMessage("toContractId")}
                                </span>
                            </div>
                            <div className="field col-1 md:col-2 mb-0 p-0">
                                <span className="p-float-label action-btns">
                                    <div
                                        className="button-icon"
                                        onClick={() => addContract()}
                                    >
                                        <BsPlusLg className="icon" />
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <div className="system-select d-flex pt-5">
                <div className="field-radiobutton d-flex pr-4 align-items-center  mb-0">
                    <RadioButton
                        inputId="copy-latest"
                        name="copy-latest"
                        value="copy-latest"
                        onChange={(e) => setCopyKey(e.value)}
                        checked={copyKey === "copy-latest"}
                    />
                    <label htmlFor="city1">
                        Copy only the latest task of all Systems
                    </label>
                </div>
                <div className="field-radiobutton d-flex align-items-center mb-0">
                    <RadioButton
                        inputId="copy-all"
                        name="copy-all"
                        value="copy-all"
                        onChange={(e) => setCopyKey(e.value)}
                        checked={copyKey === "copy-all"}
                    />
                    <label className="ml-2" htmlFor="city2">
                        Copy all the tasks under the Contract
                    </label>
                </div>
            </div>
            <div className="field-checkbox pt-2">
                <Checkbox
                    inputId="isCopyAll"
                    checked={isCopyAll}
                    onChange={(e) => setIsCopyAll(e.checked)}
                />
                <label className="mandatory" htmlFor="isCopyAll">
                    Copy all existing Reports
                </label>
            </div>
            <Button label="Copy" className="btn mt-3" autoFocus onClick={() => addRef?.current?.requestSubmit()} />

            <InformationBox
                showInfoModal={displayInfoModal}
                setShowInfoModal={setDisplayInfoModal}
                message={message}
            />
            <ConfirmationBox
                showConfirmModal={displayConfirmModal}
                setShowConfirmModal={onConfirmCancel}
                confirmAction={confirmedCopy}
                message={statusMessage}
            />
            {showAddContract && (
                <AddContract
                    showModal={showAddContract}
                    selectedCustomerId={watch("toCustomerSearchCriteria")?.uniqueCustomerId}
                    setShowModal={setShowAddContract}
                    onAddContractClick={(data) => onAddContractClick(data)}
                    isFromInspection={true}
                    isFromCopy={true}
                    siteId={watch("toSiteId")}
                />)
            }
            <Dialog
                header="System Name(s)"
                visible={showSystemDuplication}
                style={{ width: '35vw' }}
                footer={renderFooter()}
                onHide={onCancelSystem}
                className='s-modal'
            >
                <div>
                    {systemList?.length &&
                        <DataTable value={systemList} responsiveLayout="scroll">
                            <Column field="sourceSystemName" header="Source System Name"></Column>
                            <Column header="Destination System Name" body={editableTemplate}></Column>
                        </DataTable>
                    }
                </div>
            </Dialog>
        </div>
    )
}

export default memo(CopyAllSystems)