import React from 'react'

const PersonalSettingsDocument = () => {
  return (
    <>
        <h4 className="doc-title mb-2">Personal Settings</h4>
        <p>This option is used to edit First Name, Last Name, Email, Phone No1, Phone No2, Time Zone, 
          Rows per Page, etc. of the logged in user. 
        </p>
        <p>User can specify the number of Rows to be displayed in a grid page through Rows per Page 
          and Rows per page for Inspection Tasks page fields in the Personal Settings.
        </p>
        <p>Make the necessary changes/selections and click Save Changes button. The changes made 
          here will be reflected throughout the application for the logged in user.
        </p>
        <p>
           <span className="doc-subtitle">Change Password:</span> Users can change their password 
           through this option. On clicking the <span className="doc-subtitle">Change Password</span> button, 
           a Change Password page will be displayed. Enter the current password and the new password. 
           Only the Password of the logged in user can be changed through this option.
        </p>
    </>

  );
}
export default PersonalSettingsDocument;