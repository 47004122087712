import React from "react";

const OverviewDoument = () => {
    return (
        <>
            <h4 className="doc-title mb-2">
                Tech Advance<sup>+</sup> Overview
            </h4>
            <p>
                Tech Advance<sup>+</sup> is a set of software tools and services that enable
                organizations to manage their facilities and projects right from their desktop.
                Drawings and documents can be shared instantaneously among
                facility managers, engineers, architects, construction staff and project managers.
            </p>

            <p>
                It is a highly cost effective solution that allows organizations to manage geographically
                dispersed project teams, drawings, documents, and physical assets, and thereby avoiding
                costly investments in expensive software packages. Tech Advance<sup>+</sup> integrates drawings and
                various facility databases in a common organized repository and enables sharing of
                facility information with all stake holders.
            </p>

            <p>
                It is a network-based facilities management system that can be used with ease by small
                organizations with a few users or large multinationals with thousands of users.
                Tech Advance<sup>+</sup> is available on the internet on a subscription basis,
                and is also available as an intranet model for exclusive use.
            </p>

            <p>
                Tech Advance<sup>+</sup> offers a rich and versatile suite of applications to manage
                buildings, assets, documents and employee data.
                Modularized and easily implemented, the system offers organization-wide access and management control.
            </p>

            <p>
                Tech Advance<sup>+</sup> has the following set of application modules:
            </p>
            <ul className="bullet-list">
                <li>
                    <b>Administration</b> module performs administration of the system
                </li>
                <li>
                    <b>As Builts</b> module stores CAD drawings, provides drawing mark-ups, and revision control
                </li>
                <li>
                    <b>Inspection</b> module manages and tracks fire protection components with reference to facilities layout
                </li>
            </ul>
        </>
    );
}

export default OverviewDoument;