import React from 'react';
import { IoIosArrowBack } from "react-icons/io";

interface Props {
    title: string,
    classNames?: string,
    handleGoBackClick: (url: any) => void
}

export const Breadcrumb: React.FC<Props> = ({
    title = '',
    classNames = '',
    handleGoBackClick = () => { }
}) => {
    return (
        <div className={`breadcrumb ${classNames ? classNames : ''}`}>
            <IoIosArrowBack className='breadcrumb-icon' onClick={handleGoBackClick} />
            <span className='title'>{title}</span>
        </div>
    );
}

export default Breadcrumb;