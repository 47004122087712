import React, { memo, useMemo, useState } from 'react'
import { Tree } from 'primereact/tree';
import TreeNode from 'primereact/treenode';

interface TreeViewPropType {
    treeData: TreeNode[],
    selectionMode?: 'single' | 'multiple' | 'checkbox',
    filter?: boolean,
    selectedItem?: any;
    expandedKeys?: any;
    onNodeClick?: (data) => void;
    onToggle?: (e) => void;
    isCustomTemplateForNode?:boolean;
}

const TreeView: React.FC<TreeViewPropType> = (props) => {
    const { treeData, selectedItem, selectionMode, filter, onNodeClick = () => { }, expandedKeys = {}, onToggle = () => { },isCustomTemplateForNode } = props;
  
    const onSelectionChange = (e) => {
        const data = filterData(treeData, e.value);
        onNodeClick(data)
    }

    const filterData = (tree, value) => {
        if (tree?.length) {
            var result;
            for (let i = 0; i < tree?.length; i++) {
                if (value === tree[i].key)
                    return tree[i];
                let temp = filterData(tree[i]?.children, value);
                if (temp)
                    return result = temp
            }
            return result;
        }
    }

    const onGetSlicedName=(data:any,limit:any) =>{
        if(( data === null) || (data === undefined) || (data === "undefined"))
        {
            return data
        }
        else
        {
            if(data?.length > limit)
            data =  data.slice(0, limit)+"...";
            return data
        }
    }
    const onUpdateToolTipData=(data,limit,e: any) =>{
       if(data?.length > limit)
       {
           e.currentTarget.title = data;
   
       }  
   }


    return (
        <Tree
            value={treeData}            
            selectionMode={selectionMode}
            selectionKeys={selectedItem}
            filter={filter}
            filterMode="lenient"
            expandedKeys={expandedKeys}
            onToggle={onToggle}
            onSelectionChange={onSelectionChange}
            nodeTemplate= { isCustomTemplateForNode ?  (node) => (
              <span className="p-treenode-label" onMouseEnter={(e)=>{onUpdateToolTipData(node.label,45,e)}}>
              {onGetSlicedName(node.label,45)} 
              </span>
              ):null}
            
        />
    )
}

export default memo(TreeView);