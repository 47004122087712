import { Button } from 'primereact/button'
import React from 'react'
import { BsFillPencilFill, BsPlusLg, BsTrashFill } from 'react-icons/bs'
import { FaCog, FaDownload } from 'react-icons/fa'

const CommonAttributesDocument = () => {
  return (
    <>
        <h4 className="doc-title mb-2">
            Common Attributes
        </h4>
        <p>
            Administrators can add/edit/delete Common Attributes for inspection. The Attribute Name, 
            Field Type (Date, Date Time, Integer, Multi Line Text, Numeric or Single Line Text), whether 
            Mandatory or not, whether a Validated field or not. For Validated fields, the set of allowed values 
            will also have to be specified through the Common Attribute Values option. These Common Attributes 
            values are used in Inspection: Inspection Task: Inspection Details option. 
        </p>

        <p className="doc-subtitle">
            <b>Add: </b>
        </p>
        <p>
            Create a common attribute by clicking the <span className="doc-subtitle">Add</span> {" "}
            <Button className="doc-button-icon"> <BsPlusLg className="icon" title='Add'/></Button> {" "}icon.
        </p>
        <p className="doc-subtitle">
            <b>Edit: </b>
        </p>
        <p>
            Common Attribute can be edited by selecting it and then clicking the 
            <span className="doc-subtitle"> Edit</span> {' '} 
            <Button className="doc-button-icon"> 
                <BsFillPencilFill className="icon" title='Edit'/> 
            </Button> {' '} icon.  After making necessary changes click the Save Changes button to save the changes.
        </p>
        <p>
            A Validated Single Line Text Field cannot be changed to a Date Time or Numeric field after 
            values have been entered. 
        </p>

        <p className="doc-subtitle">
            <b>Delete: </b>
        </p>
        <p>
            Delete a common attribute by selecting it and then clicking the
            <span className="doc-subtitle"> Delete</span> {" "}
            <Button className="doc-button-icon"> <BsTrashFill className="icon" title='Delete'/> </Button>{" "}  icon.
        </p>


        <p className="doc-subtitle">
            <b>Download: </b> {" "}
            <Button className="doc-button-icon">
                <FaDownload className="icon" title='Download'/>
            </Button>{" "}
        </p>
        <p>
            This option lists Export All, Export Highlighted and Group Highlighted. 
        </p>

        <p>
            <span className="doc-subtitle">Report All: </span>
        </p>

        <p>
            This option is for generating a report with all the records in the data grid. 
            The report can be saved to PDF, Text, Rich Text or Excel format by selecting the file 
            format from the list under Save option, and then clicking OK button after selecting the required 
            options from the Export Settings window.
        </p>

        <p>
            <span className="doc-subtitle">Report Highlighted: </span>
        </p>
        <p>
            This option is for generating a report with only selected records in the grid. 
            Select the desired rows by holding down the control key and 
            clicking on each of the records to be selected. Users can also select a set of rows using the 
            shift key. The selected records will be highlighted. Click on the 
            <span className="doc-subtitle"> Report Highlighted </span> option in the 
            <span className="doc-subtitle"> Download</span> {" "}
            <Button className="doc-button-icon"><FaDownload className="icon" title='Download'/></Button>{" "} menu to generate the report. 
            The report will be created with the highlighted records only. The report can be saved to PDF, Text, 
            Rich Text or Excel format by selecting the file format from the list under Save option, and then 
            clicking OK button after selecting the required options from the Export Settings window.
        </p>


        <p className="doc-subtitle">
            <b>More: </b>{" "}
            <Button className="doc-button-icon">
                <FaCog className="icon" title='More'/>
            </Button>{" "}
        </p>
        <p>
            This option lists Field Values and Display Settings option. 
        </p>

        <p> 
            <span className="doc-subtitle">Field Values</span> 
        </p>
        <p>
            For validated fields, a set of allowed values will also have to be specified through this option.
        </p>


        <p>
            <span className="doc-subtitle">Display Settings</span>
        </p>
        <p>
            This option allows the user to change the visibility of the data fields displayed in the data grid. 
            On selecting this option, all the available data fields are displayed in a window. 
            Display settings can be modified by selecting the required fields from the list. 
            The grid settings modified through this option will not be saved and will be effective only for the current 
            instance of the data grid. 
        </p>

    </>
  );
}
export default CommonAttributesDocument;
