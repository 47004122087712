import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { onDownloadFile } from "redux/common/commonSlice";
import { zlink, baseURL } from "../../../../service/HttpService";
import { AppThunk } from "../../../store";

export interface SystemState {
  errors: any;
  systemList: any;
  systemPageDetails: any;
  systemReviseList: any;
  systemInUse:any;
  systemTypes: any;
  systemDetails: any;
  systemSearchId:any;
  newSystemData:any;
  XMLImportInProgress: any;
  provenOutcomesData: any;
  uploadedXmlFileList: any;
  isSystemforClose: any;
  newSystemDetails: any;
  xmlUploadedSystemData: any;
  unlinkedEquipmentGroups:any;
  systemEquipmemtGroups:any;
  panelInUse:any;
}

let initialState: SystemState = {
  errors: "",
  systemList: [],
  systemPageDetails: [],
  systemReviseList: [],
  systemInUse:[],
  systemTypes: [],
  systemDetails: "",
  systemSearchId:"",
  newSystemData: "",
  XMLImportInProgress: [],
  provenOutcomesData: [],
  uploadedXmlFileList: "",
  isSystemforClose: "",
  newSystemDetails:"",
  xmlUploadedSystemData:"",
  unlinkedEquipmentGroups:[],
  systemEquipmemtGroups:[],
  panelInUse:[]
};

const SystemSlice = createSlice({
  name: "system",
  initialState,
  reducers: {
    setErrors: (state, { payload }: PayloadAction<any>) => {
      state.errors = payload;
    },
    setSystemList: (state, { payload }: PayloadAction<any>) => {
      state.systemList = payload;

      let ReviseData: any = [];
      state.systemList.map((element: any, key: any) => {
        ReviseData = [
          ...ReviseData,
          {
            uniqueSystemId: element.uniqueSystemId,
            systemName: element.systemName,
            systemType: element.systemType,
            isNetworked: element.isNetworked ? "True" : "False",
            globalPMI: element.globalPMI,
            preferredLanguage: element.preferredLanguage,
            status: element.status,
            xmlFile: element.xmlFile,
            configFile: element.configFile,
            activeTask: element.activeTask,
            xmlUploadDate:element.xmlUploadDate,
            softwareVersion:element.softwareVersion,
            revision:element.revision
          },
        ];
        return null;
      } );
      state.systemReviseList = ReviseData;
    },
    setSystemPageDetails: (state, { payload }: PayloadAction<any>) => {
      state.systemPageDetails = payload;
    },
    setSystemInUse: (state, { payload }: PayloadAction<any>) => {
      state.systemInUse = payload;
    },
    setDeletedSystemList(state, { payload }: PayloadAction<any>) {
      state.systemReviseList = state.systemReviseList.filter((item: any) => item.uniqueSystemId !== payload);
    },
    setSystemTypes: (state, { payload }: PayloadAction<any>) => {
      state.systemTypes = payload;
    },
    setNewSystemData: (state, { payload }: PayloadAction<any>) => {
      let newData: any = [];

      newData = [
        {
          uniqueSystemId: payload.uniqueSystemId,
          systemName: payload.systemName,
          systemType: payload.systemType,
          isNetworked: payload.isNetworked ? "True" : "False",
          globalPMI: payload.globalPMI,
          preferredLanguage: payload.preferredLanguage,
          status: payload.status,
          xmlFile: payload.xmlFile === "Yes"? "Yes" : "No",
          configFile: payload.configFile === "Yes"? "Yes" : "No",
          activeTask: payload.activeTask,
          xmlUploadDate:payload.xmlUploadDate,
          softwareVersion:payload.softwareVersion,
          revision:payload.revision

        },
      ];
      state.newSystemDetails=newData[0];
      if (state.systemReviseList.length === 0 )
      state.systemReviseList = newData;
      else
      state.systemReviseList.unshift(newData[0]);
    },
    setNewInspectionSystemData: (state, { payload }: PayloadAction<any>) => {
      
      state.newSystemData = payload;
    },
    setSystemDetails: (state, { payload }: PayloadAction<any>) => {
      state.systemDetails = payload;
    },
    setUpdatedSystemData: (state, { payload }: PayloadAction<any>) => {
      let newData: any = [];
      newData = [
        {
          uniqueSystemId: payload.uniqueSystemId,
          systemName: payload.systemName,
          systemType: payload.systemType,
          isNetworked: payload.isNetworked ? "True" : "False",
          globalPMI: payload.globalPMI,
          preferredLanguage: payload.preferredLanguage,
          status: payload.status,
          xmlFile: payload.xmlFile === "Yes"? "Yes" : "No",
          configFile: payload.configFile === "Yes"? "Yes" : "No",
          activeTask: payload.activeTask,
          xmlUploadDate:payload.xmlUploadDate,
          softwareVersion:payload.softwareVersion,
          revision:payload.revision
        },
      ];
      let currentIndex = state.systemReviseList.findIndex((item: any) => item.uniqueSystemId === payload?.uniqueSystemId);
      state.systemReviseList = state.systemReviseList.filter((item: any) => item.uniqueSystemId !== payload?.uniqueSystemId);
      state.systemReviseList.splice(currentIndex, 0, newData[0]);
    },
    setSystemSearchId: (state, { payload }: PayloadAction<any>) => {
      state.systemSearchId = payload;
    },
    setXMLImportInProgress: (state, { payload }: PayloadAction<any>) => {
      state.XMLImportInProgress = payload;
    },
    setUploadedXmlData: (state, { payload }: PayloadAction<any>) => {
      state.xmlUploadedSystemData = payload;
      let currentIndex = state.systemReviseList.findIndex((item: any) => item.uniqueSystemId === payload?.uniqueSystemId);
      state.systemReviseList = state.systemReviseList.filter((item: any) => item.uniqueSystemId !== payload?.uniqueSystemId);
      state.systemReviseList.splice(currentIndex, 0, payload);
    },
    setSystemStatus: (state, { payload }: PayloadAction<any>) => {
      let currentIndex = state.systemReviseList.findIndex((item: any) => item.uniqueSystemId === payload?.uniqueSystemId);
      state.systemReviseList = state.systemReviseList.filter((item: any) => item.uniqueSystemId !== payload?.uniqueSystemId);
      state.systemReviseList.splice(currentIndex, 0, payload);
    },
    setProvenOutcomesData: (state, { payload }: PayloadAction<any>) => {
      state.provenOutcomesData = payload;
    },
    setUploadedXmlFileList: (state, { payload }: PayloadAction<any>) => {
      state.uploadedXmlFileList = payload;
    },
    setDeletedFileList: (state, { payload }: PayloadAction<any>) => {
      let currentIndex = state.systemReviseList.findIndex((item: any) => item.uniqueSystemId === payload?.uniqueSystemId);
      state.systemReviseList = state.systemReviseList.filter((item: any) => item.uniqueSystemId !== payload?.uniqueSystemId);
      state.systemReviseList.splice(currentIndex, 0, payload);
    },
    setSystemInspectionTask: (state, { payload }: PayloadAction<any>) => {
      let currentIndex = state.systemReviseList.findIndex((item: any) => item.uniqueSystemId === payload?.uniqueSystemId);
      state.systemReviseList = state.systemReviseList.filter((item: any) => item.uniqueSystemId !== payload?.uniqueSystemId);
      state.systemReviseList.splice(currentIndex, 0, payload);
    },
    setCheckSystemforClose: (state, { payload }: PayloadAction<any>) => {
      state.isSystemforClose = payload;
    },
    setUnlinkedEquipmentGroups: (state, { payload }: PayloadAction<any>) => {
      state.unlinkedEquipmentGroups = payload;
    },
    setDeleteUnlinkedEquipmentGroups: (state, { payload }: PayloadAction<any>) => {
      state.unlinkedEquipmentGroups = state.unlinkedEquipmentGroups.filter((item: any) => item.equipmentId !== payload);
    },
    setSystemEquipmentGroups: (state, { payload }: PayloadAction<any>) => {
      state.systemEquipmemtGroups = payload;
    },
    setPanelInUse: (state, { payload }: PayloadAction<any>) => {
      state.panelInUse = payload;
    },
    setDeletedSystemEquipmentGroups: (state, { payload }: PayloadAction<any>) => {
      state.systemEquipmemtGroups = state.systemEquipmemtGroups.filter((item: any) => item.equipmentId !== payload);
    },
    setNewlyAddedSystemStatus: (state, {payload}: PayloadAction<any>) => {
      if(payload.uniqueSystemId === state.newSystemData?.uniqueSystemId)    
      state.newSystemData.status = payload.status
    },
  },
});

export const getSystemList = (currentStart: any, keyword: any, sortField: any, sortOrder: any): AppThunk => async (dispatch) => {
  try {
    let requestUrl = `${baseURL}api/v1/inspection/systems`;
    if (currentStart) {
      requestUrl += `?currentstart=${currentStart}`
    }
    if (keyword) {
      requestUrl += `&keyword=${keyword}`
    }
    if (sortField && sortOrder) {
      requestUrl += `&sortcolumn=${sortField}&sortorder=${sortOrder}`;
    }
    const res = await zlink.get(`${requestUrl}`);
    if (res.status === "done") {
      if (res.data) {
        dispatch(setSystemList(res?.data?.systemDetails));
        dispatch(setSystemPageDetails(res?.data?.pageDetails));
      }
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const checkSystemInUse = (uniqueSystemId): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.get(`${baseURL}api/v1/inspection/system/${uniqueSystemId}/checksysteminuse`);
    if (res.status === "done") {
      dispatch(setSystemInUse(res?.data));
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const checkXMLImportInProgress = (uniqueSystemId): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.get(`${baseURL}api/v1/inspection/system/${uniqueSystemId}/checksystemparseinprogress`);
    if (res.status === "done") {
      dispatch(setXMLImportInProgress(res?.data));
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const deleteSystem = ({ uniqueSystemId, setSelectedRows, setMessage, setDisplayInfoModal }): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.post(`${baseURL}api/v1/inspection/system/${uniqueSystemId}/remove`, null);
    if (res.status === "done") {
      dispatch(setDeletedSystemList(uniqueSystemId));
      setSelectedRows([]);
      setMessage("System deleted");
      setDisplayInfoModal(true);
    } else {
      setSelectedRows([]);
      setMessage(res.message);
      setDisplayInfoModal(true);
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const getSystemTypes = (): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.get(`${baseURL}api/v1/inspection/system/paneltypes`);
    if (res.status === "done") {
      dispatch(setSystemTypes(res?.data?.panelTypes));
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const onAddSystem = (body: any, setMessage, setDisplayInfoModal, setDisplayConfirmModal, setShowAddModal, setIsAdd): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.post(`${baseURL}api/v1/inspection/system/add`, body);
    if (res.status === "done") {
      dispatch(setNewSystemData(res.data));
      dispatch(setNewInspectionSystemData(res.data))
      dispatch(setUploadedXmlFileList(""))
      if (res.data.systemType === "MXL" || res.data.systemType === "XLS" || res.data.systemType === "Desigo FS20" ||
        res.data.systemType === "Desigo FV20" || res.data.systemType === "Desigo Modular" || res.data.systemType === "Cerberus PRO FC92" ||
        res.data.systemType === "Cerberus PRO FV92" || res.data.systemType === "Cerberus PRO Modular") {
        setMessage("System added. Do you want to upload XML file to this System?");
        setDisplayConfirmModal(true);
        setIsAdd(true);
      } else {
        setMessage("System added");
        setDisplayInfoModal(true);
        setShowAddModal(false);
      }
    } else {
      dispatch(setErrors(res.error));
      setMessage(res.message);
      setDisplayInfoModal(true);
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const getSystemDetails = (uniqueSystemId): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.get(`${baseURL}api/v1/inspection/system/${uniqueSystemId}`);
    if (res.status === "done") {
      dispatch(setSystemDetails(res.data));
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const onEditSystem =
  (body: any, uniqueSystemId, setMessage, setDisplayInfoModal, setShowEditModal,selectedSystem:any,setIsEdit:any): AppThunk =>
    async (dispatch) => {
      try {
        const res = await zlink.post(`${baseURL}api/v1/inspection/system/${uniqueSystemId}/update`, body);
        if (res.status === "done") {
          res.data.configFile = selectedSystem.configFile;
          res.data.xmlFile=selectedSystem.xmlFile;
          dispatch(setUpdatedSystemData(res.data));
          setMessage("System updated");
          setShowEditModal(false);
          setDisplayInfoModal(true);
          setIsEdit(true);
        } else {
          dispatch(setErrors(res.error));
          setMessage(res.message);
          setDisplayInfoModal(true);
        }
      } catch (error) {
        dispatch(setErrors(error));
      }
    };

export const onInspectionTaskClose =
  (uniqueSystemId, setMessage, setDisplayInfoModal, setShowEditModal, setShowUploadXMLModal, isEdit: boolean, selectedSystem,setIsReactivate:any,setUniqueInspectionaskId:any): AppThunk =>
    async (dispatch) => {
      try {
        const res = await zlink.post(`${baseURL}api/v1/inspection/system/${uniqueSystemId}/closeactiveinspection`, null);
        if (res.status === "done") {
          setMessage("Inspection Task closed");
          setIsReactivate(true);
          setUniqueInspectionaskId(res.data?.uniqueInspectionTaskId);
          if (isEdit) {
          setShowEditModal(true);
          } else 
          setShowUploadXMLModal(true)
          dispatch(setSystemInspectionTask({ ...selectedSystem, activeTask: "" }));
          setDisplayInfoModal(true);
        } else {
          dispatch(setErrors(res.error));
          setMessage(res.message);
          setDisplayInfoModal(true);
        }
      } catch (error) {
        dispatch(setErrors(error));
      }
    };

export const getSystemSearchDetailsList = (uniqueSystemId:any): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.get(`${baseURL}api/v1/common/search/system/${uniqueSystemId}/systemlist`);
    if (res.status === "done") {
      dispatch(setSystemList(res?.data?.systemDetails));
      dispatch(setSystemPageDetails(res?.data?.pageDetails));
      dispatch(setSystemSearchId(""));
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const onUploadSystemXml = (uniqueSystemId,uniqueSiteId,body,setShowUploadXMLModal,setMessage,setDisplayInfoModal, isConfig, selectedSystem?, setSelectedRows?, isReactivate?, setIsReactivateOnFileUpload?): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.post(`${baseURL}api/v1/inspection/system/${uniqueSystemId}/site/${uniqueSiteId}/files/add?isConfig=${isConfig}`, body);
    if (res.status === "done") {
      if (selectedSystem) {
        if (isConfig){
          dispatch(setUploadedXmlData({ ...selectedSystem, configFile: "Yes"}));
          if (setSelectedRows) {
            setSelectedRows(prev => {
              let arr = prev;
              arr[0]={...selectedSystem, configFile: "Yes"};
              return arr;
            });
          }
        } else {
              selectedSystem ={...selectedSystem,
                               xmlUploadDate:res.data.systemDetails.xmlUploadDate, 
                               softwareVersion:res.data.systemDetails.softwareVersion,
                               revision:res.data.systemDetails.revision
                              }
              dispatch(setUploadedXmlData({...selectedSystem,xmlFile: "Yes"}));
          
              if (setSelectedRows) {
              setSelectedRows(prev => {
              let arr = prev;
              arr[0]={...selectedSystem, xmlFile: "Yes"};
              return arr;
            });
          }
        }
      }
      setShowUploadXMLModal(false);
      setDisplayInfoModal(true);
      setMessage("File uploaded");
      
      if(!selectedSystem)
      dispatch(setSystemInUse([]))

      if(isReactivate) {
        setIsReactivateOnFileUpload(true);
    }
    } else {
      setDisplayInfoModal(true);
      setMessage(res.message);
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const checkDownloadFile = (uniqueSystemId: any, isConfig: boolean, setMessage: any, setDisplayInfoModal: any): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.get(`${baseURL}api/v1/inspection/systems/${uniqueSystemId}/file?isConfig=${isConfig}`);
    if (res.status === "done") {
      if (res?.data?.isFileUploaded) {
        dispatch(onDownloadFile(res?.data?.fileId, res?.data?.fileName, res?.data?.filePath, setMessage, setDisplayInfoModal));
      } else {
        let message = isConfig ? `Config File is not uploaded to the selected System` : `XML File is not uploaded to the selected System`;
        setMessage(message);
        setDisplayInfoModal(true);
      }
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const getClosedTasksOutcomes = (uniqueSystemId: any, setMessage: any, setDisplayInfoModal: any, setShowProvenOutcomesModal: any): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.get(`${baseURL}api/v1/inspection/systems/${uniqueSystemId}/closedtasks`);
    if (res.status === "done") {
      if (res?.data?.inspectionTaskDetails && res?.data?.inspectionTaskDetails.length) {
        dispatch(setProvenOutcomesData(res?.data?.inspectionTaskDetails));
        setShowProvenOutcomesModal(true);
      } else {
        setMessage("No Tasks exist for the selected System");
        setDisplayInfoModal(true);
      }
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const downloadProvenOutcomes = (uniqueSystemId: any, body: any, setShowProvenOutcomesModal: any): AppThunk => async (dispatch) => {
  try {
    const request: any = {
      method: "POST",
      cache: "no-store",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: sessionStorage.getItem("accesstoken"),
      },
    };

    const downloadUrl = `${baseURL}api/v1/inspection/systems/${uniqueSystemId}/inspectiontask/${body.uniqueinspectiontaskid}/provenoutcomes`
    await fetch(downloadUrl, request).then((response: any) => {     
      
      const fileName: any = response.headers.get("filename");

      const res = new Response(
        new ReadableStream({
          async start(controller) {
            const reader = response.body.getReader();
            for (; ;) {
              const { done, value } = await reader.read();
              if (done) break;
              controller.enqueue(value);
            }
            controller.close();
          },
        })
      );
      res.blob().then((blob) => {
        setShowProvenOutcomesModal(false);
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        a.click();
      });
    });
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const getEquipmentGroups = (uniqueSystemId:any,setEquipmentGroups:any,setMessage,setDisplayInfoModal,setShowEquipmentGroupModal): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.get(`${baseURL}api/v1/inspection/systems/${uniqueSystemId}/equipmentgroups`);
    if (res.status === "done") {
      if(res.data?.equipmentGroups?.length>0){
        setShowEquipmentGroupModal(true);
        setEquipmentGroups(res.data?.equipmentGroups)
      }else{
        setDisplayInfoModal(true);
        setMessage("No Equipment Group data exists");
      }
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const onUpdateEquipmentGroup = (uniqueSystemId,body,setMessage,setDisplayInfoModal,setShowModal): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.post(`${baseURL}api/v1/inspection/systems/${uniqueSystemId}/equipmentgroups`, body);
    if (res.status === "done") {
      setDisplayInfoModal(true);
      setShowModal(true)
      setMessage("Equipment Group(s) updated");
    } else {
      setDisplayInfoModal(true);
      setMessage(res.message);
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
}

export const activateSystem = (selectedSystem, setSelectedRows, setMessage, setDisplayInfoModal): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.post(`${baseURL}api/v1/inspection/systems/${selectedSystem.uniqueSystemId}/activate`, {});
    if (res) {
      dispatch(setSystemStatus({...selectedSystem, status: "Active"}));
      dispatch(setNewlyAddedSystemStatus(res?.data))
      setMessage('System activated');
      setDisplayInfoModal(true);
      setSelectedRows(prev => {
        let arr = prev;
        arr[0]={...selectedSystem, status:"Active"};
        return arr;
      });
    } else {
      dispatch(setErrors(res.error));
      if (res.message) {
        setMessage(res.message);
        setDisplayInfoModal(true);
      }
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
}

export const closeSystem = (selectedSystem, setSelectedRows, setMessage, setDisplayInfoModal): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.post(`${baseURL}api/v1/inspection/systems/${selectedSystem.uniqueSystemId}/close`, {});
    if (res) {
      dispatch(setSystemStatus({...selectedSystem, status: "Closed",activeTask:""}));
      dispatch(setNewlyAddedSystemStatus(res?.data))
      setMessage('System closed');
      setDisplayInfoModal(true);
      setSelectedRows(prev => {
        let arr = prev;
        arr[0]={...selectedSystem, status:"Closed",activeTask:""};
        return arr;
      });
    } else {
      dispatch(setErrors(res.error));
      if (res.message) {
        setMessage(res.message);
        setDisplayInfoModal(true);
      }
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
}

export const getUploadedXMLFileList = (uniqueSystemId: any, isConfig: boolean): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.get(`${baseURL}api/v1/inspection/systems/${uniqueSystemId}/file?isConfig=${isConfig}`);
    if (res.status === "done") {
      dispatch(setUploadedXmlFileList(res?.data));
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const deleteXMLFile = (uniqueSystemId, fileId, isConfig,activeSiteId,setMessage, setDisplayInfoModal, setShowUploadXMLModal, selectedSystem?): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.post(`${baseURL}api/v1/inspection/systems/${uniqueSystemId}/files/${fileId}/siteid/${activeSiteId}/remove?isConfig=${isConfig}`, null);
    if (res.message) {
      if (res.message === "File deleted") {
        if (selectedSystem) {
          if (isConfig) {
            dispatch(setDeletedFileList({ ...selectedSystem, configFile: "No" }));
          } else {
            selectedSystem ={...selectedSystem,
              xmlUploadDate:"", 
              softwareVersion:"",
              revision:""
            }
            dispatch(setDeletedFileList({ ...selectedSystem, xmlFile: "No" }));
          }
          setShowUploadXMLModal(false)
        }
        dispatch(setUploadedXmlFileList(""))
        setMessage(res.message);
        setDisplayInfoModal(true);
      }
      else {
        dispatch(setErrors(res.error));
        setMessage(res.message);
        setDisplayInfoModal(true);
      }
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
}

export const checkSystemforClose = (uniqueSystemId): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.get(`${baseURL}api/v1/inspection/systems/${uniqueSystemId}/checksystemforclose`);
    if (res.status === "done") {
      if (res.data) {
        if (res.data.techniciansName && res.data.techniciansName.length) {
          if (res.data.techniciansName.length === 1) {
            if (res.data.techniciansName[0] === "") {
              res.data.techniciansName = [];
            }
          }
        }
        dispatch(setCheckSystemforClose(res?.data));
      }
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const reactivateInspectionTask = (uniqueInspectionTaskId:any, setMessage:any, setDisplayInfoModal:any, selectedSystem:any): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.post(`${baseURL}api/v1/inspection/inspectiontask/${uniqueInspectionTaskId}/activate`, null);
    if (res.status === "done") {
      if(res.data.isSiemens && res.data.isParsed){
        setMessage('Inspection Task Activated');
        setDisplayInfoModal(true);
      } else if(res.data.isSiemens && !res.data.isParsed) {
        setMessage('Inspection Task Activated. Sending Equipment list to the Server. For large systems, it will take some time..');
        setDisplayInfoModal(true);
      } else if (!res.data.isSiemens && !res.data.isParsed){
        setMessage('Inspection Task Activated');
        setDisplayInfoModal(true);
      }
      dispatch(setUpdatedSystemData({...selectedSystem, activeTask:  res.data?.inspectionTaskName}));
    } else {
      dispatch(setErrors(res.error));
      setMessage(res.message);
      setDisplayInfoModal(true);
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const getUnlinkedEquipmentGroups = (uniqueSiteId: any, setDisplayInfoModal: any, setMessage: any): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.get(`${baseURL}api/v1/inspection/systems/Sites/${uniqueSiteId}/unlinkedequipmentgroups`);
    if (res.status === "done") {
      if (res.data?.equipmentGroups?.length > 0) {
        dispatch(setUnlinkedEquipmentGroups(res.data?.equipmentGroups))
      } else {
        setDisplayInfoModal(true);
        setMessage("No Unlinked Equipment Group data exists");
      }
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const getSystemEquipmentGroups = (uniqueSystemId:any,setDisplayInfoModal:any,setMessage:any,setShowSystemEquipmentGroups:any): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.get(`${baseURL}api/v1/inspection/systems/${uniqueSystemId}/systemequipmentgroups`);
    if (res.status === "done") {
      if(res.data?.equipmentGroups?.length>0){
        dispatch(setSystemEquipmentGroups(res.data?.equipmentGroups))
        setShowSystemEquipmentGroups(true);
     }else{
        setShowSystemEquipmentGroups(false);
        setDisplayInfoModal(true);
        setMessage("No Equipment Group data exists");
      }
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const onDeleteUnlinkedEquipmentGroup = (uniqueSiteId:any,equipmentId:any,setSelectedEquipmentGroup:any,setDisplayInfoModal:any, setMessage:any): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.post(`${baseURL}api/v1/inspection/systems/sites/${uniqueSiteId}/unlinkedequipmentgroups/panels/${equipmentId}/remove`, null);
    if (res.status === "done") {
      dispatch(setDeleteUnlinkedEquipmentGroups(equipmentId));
      setSelectedEquipmentGroup([]);
      setMessage("Equipment Group deleted");
      setDisplayInfoModal(true);
    } else {
      setSelectedEquipmentGroup([]);
      setMessage(res.message);
      setDisplayInfoModal(true);
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const onCheckSystemEquipmentGroupInUse = (uniqueSystemId:any,equipmentId:any): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.get(`${baseURL}api/v1/inspection/systems/${uniqueSystemId}/equipmentgroups/panels/${equipmentId}/checkpanelinuse`,);
    if (res.status === "done") {
      dispatch(setPanelInUse(res?.data));
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
}

export const onDeleteSystemEquipmentGroup = (uniqueSystemId,equipmentId,setSelectedEquipmentGroup,setDisplayInfoModal, setMessage): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.post(`${baseURL}api/v1/inspection/systems/${uniqueSystemId}/equipmentgroups/panels/${equipmentId}/remove`, null);
    if (res.status === "done") {
      dispatch(setDeletedSystemEquipmentGroups(equipmentId))
      setSelectedEquipmentGroup([]);
      setMessage("Equipment Group deleted");
      setDisplayInfoModal(true);
    } else {
      setSelectedEquipmentGroup([]);
      setMessage(res.message);
      setDisplayInfoModal(true);
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const {
  setErrors,
  setSystemList,
  setSystemPageDetails,
  setSystemTypes,
  setNewSystemData,
  setSystemInUse,
  setDeletedSystemList,
  setSystemDetails,
  setUpdatedSystemData,
  setSystemSearchId,
  setXMLImportInProgress,
  setNewInspectionSystemData,
  setUploadedXmlData,
  setProvenOutcomesData,
  setSystemStatus,
  setUploadedXmlFileList,
  setDeletedFileList,
  setSystemInspectionTask,
  setCheckSystemforClose,
  setUnlinkedEquipmentGroups,
  setDeleteUnlinkedEquipmentGroups,
  setSystemEquipmentGroups,
  setPanelInUse,
  setDeletedSystemEquipmentGroups,
  setNewlyAddedSystemStatus
} = SystemSlice.actions;

export default SystemSlice.reducer;
