import { useForm, Controller } from 'react-hook-form';
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { RadioButton } from 'primereact/radiobutton';
import { Calendar } from 'primereact/calendar';
import { classNames } from 'primereact/utils';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import UploadXML from "../../Systems/ManageSystem/UploadXML";
import moment from 'moment';

import { BsExclamationCircleFill, BsPlusLg, BsPaperclip } from 'react-icons/bs';

import AddModal from 'components/Table/AddModal/AddModal';
import InspectionReports from './InspectionReports';
import InformationBox from 'components/common/Message/InformationBox/InformationBox';
import AddSystems from 'pages/Systems/ManageSystem/AddSystem';
import ContractDate from './ContractDate';

import { useDispatch } from 'react-redux';
import { useAppSelector } from 'redux/hooks';
import { activateSystem, getSystemListForTaskAdd, getTechnicians, setActivatedSystemDetails, setLinkedReportsData, setSystemData } from 'redux/pages/Inspection/InspectionTask/inspectionTaskSlice';
import { getContractDetails } from 'redux/pages/Inspection/Contracts/contractSlice';
import { checkSystemInUse, onAddSystem, setNewInspectionSystemData ,onUploadSystemXml,checkXMLImportInProgress, getUploadedXMLFileList, deleteXMLFile, setUploadedXmlFileList, setSystemInUse} from 'redux/pages/Inspection/Systems/systemSlice';
import ConfirmationBox from 'components/common/Message/ConfirmationBox/ConfirmationBox';
import { showUI } from 'service/GeneralUtility';
import { USATaskPattern,CanadaTaskPattern } from './InspectionTaskNumberPattern';

interface Props {
    showModal: boolean,
    setShowModal: (state: boolean) => void,
    uniqueContractId: string,
    uniqueCustomerId: string,
    customerName: string,
    contractName: string,
    onAddInspectionTaskClick: (data: any) => void,
}

type FormData = {
    uniqueCustomerId: string,
    uniqueContractId: string,
    inspectionTaskName: string,
    uniqueSystemId: string,
    uniqueTechnicianId: any,
    plannedStartDate: any,
    plannedEndDate: any,
    notificationNumber: any,
    quantityOfTech: number,
    isTechRequiredAtPanel: boolean,
    status: string,
    reportIds: any,
    activeSiteId:any
};

export const AddInspectionTask: React.FC<Props> = ({
    showModal = false,
    setShowModal = () => { },
    uniqueContractId = '',
    uniqueCustomerId = '',
    customerName = '',
    contractName = '',
    onAddInspectionTaskClick = () => { },
}) => {
    const {
        control,
        handleSubmit,
        watch,
        setValue,
        getValues,
        formState: { errors },
    } = useForm<FormData>({
        defaultValues: {
            uniqueCustomerId: uniqueCustomerId,
            uniqueContractId: uniqueContractId,
            inspectionTaskName: '',
            uniqueSystemId: undefined,
            uniqueTechnicianId: '',
            plannedStartDate: null,
            plannedEndDate: null,
            notificationNumber: '',
            quantityOfTech: 1,
            isTechRequiredAtPanel: false,
            status: '1',
            reportIds: [],
            activeSiteId:''
        }
    });

    const systemData = useAppSelector((state) => state.inspectionTask.systemsData);
    const technicianData = useAppSelector((state) => state.inspectionTask.technicianData);
    const systemInUse = useAppSelector((state) => state.system.systemInUse);
    const contractDetails = useAppSelector((state) => state.contract.contractDetails);
    const newSystemData = useAppSelector((state) => state.system.newSystemData);
    const XMLImportInProgress = useAppSelector((state) => state.system.XMLImportInProgress);
    const uploadedXmlFileList = useAppSelector((state) => state.system.uploadedXmlFileList);
    const userPrivileges = useAppSelector((state) => state.user.userPrivileges);
    const loggedInUserDetails = useAppSelector((state) => state.administration.loggedInUserDetails);
    const activatedSystemDetails = useAppSelector((state) => state.inspectionTask.activatedSystemDetails);
    
    const [showPatternModal, setShowPatternModal] = useState<boolean>(false);
    const [showReportsModal, setShowReportsModal] = useState<boolean>(false);
    const [showUploadXMLModal,setShowUploadXMLModal]=useState<boolean>(false);
    const [selectedReports, setSelectedReports] = useState<any>(null);
    const [systemRadioLookup, setSystemRadioLookup] = useState<any>([{name: "",code: "",systemType:"",disabled:true}]);
    const [selectedSystem,setSelectedSystem]=useState<any>();
    const [technicianLookup, setTechnicianLookup] = useState([])
    const [displayInfoModal, setDisplayInfoModal] = useState(false);
    const [message, setMessage] = useState("");
    const [submitData, setSubmitData] = useState<any>("");
    const [isSubmit, setIsSubmit] = useState(false);
    const [isAdd,setIsAdd]=useState(false);
    const [isFileUpload,setIsFileUpload]=useState(false);
    const [showAddSystem, setShowAddSystem] = useState(false);
    const [showContractDate, setShowContractDate] = useState(false);
    const [displayConfirmModal, setDisplayConfirmModal] = useState(false);
    const [contractData, setContractData] = useState<any>([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [pattern, setPattern] = useState<any>([]);
    const [inspectiontaskNumberPattern, setInspectiontaskNumberPattern] = useState<any>();
    const [showNotificationNumber, setShowNotificationNumber] = useState(false);
    const [countryId, setCountryId] = useState<any>()
    const [selectedClosedSystem, setSelectedClosedSystem] = useState<any>()
    const [isActivate, setIsActivate] = useState<any>()

    const addForm: any = useRef();
    const dispatch = useDispatch<any>();

    const setDate = useCallback(() => {
        setValue("plannedStartDate", new Date());
        setValue("plannedEndDate", new Date());
    }, [setValue])

    useEffect(() => {
        setDate();
        dispatch(getSystemListForTaskAdd())
        let body={
            UniqueContractId:uniqueContractId,
        };
        dispatch(getTechnicians(body))
        dispatch(getContractDetails(uniqueContractId))
        // dispatch(setSystemInUse([]))  -- keeping for reference
    }, [dispatch, setDate, uniqueContractId])

    useEffect(() => {
        if(isActivate)
         setDisplayConfirmModal(true);
    },[isActivate])

    useEffect(() => {
        if (systemData?.length > 0) {
            let system: any = [];
            systemData.map((item: any) => {
                return system.push({ name: item.systemName, code: item.uniqueSystemId,systemType:item.systemType,disabled:item.status=== "Active"?false:true })
            })
            setSystemRadioLookup(system);

           
            dispatch(setSystemData([]));
        }
    }, [systemData])
    useEffect(() => {
        if (Object.keys(activatedSystemDetails).length > 0) {
            let system: any = systemRadioLookup;

            let currentIndex = system.findIndex((item: any) => item.code === activatedSystemDetails?.uniqueSystemId);
            system.splice(currentIndex, 1, { name: activatedSystemDetails?.systemName, code: activatedSystemDetails?.uniqueSystemId, systemType: activatedSystemDetails?.systemType, disabled: false });
            getSortedSystemList(system)
            setValue("uniqueSystemId", activatedSystemDetails?.uniqueSystemId)
            setSelectedSystem([{ name: activatedSystemDetails?.systemName, code: activatedSystemDetails?.uniqueSystemId, systemType: activatedSystemDetails?.systemType, disabled: false }]);
            dispatch(setActivatedSystemDetails(""))
        }
    },[activatedSystemDetails])

    useEffect(() => {
        if (technicianData?.length > 0) {
            let technician: any = [];
            technicianData.map((item: any) => {
                return technician.push({ name: item.formattedLeadTechnicianName, code: item.uniqueUserId })
            })
            setTechnicianLookup(technician)
        }
    }, [technicianData])

    useEffect(() => {
        if (contractDetails && contractDetails?.contractNumber) {     
            setContractData(contractDetails)
            if (Object.keys(contractDetails).length !== 0 && 
                countryId === 220 && contractDetails?.contractNumber.startsWith("44OP") ||
                countryId === 220 && contractDetails?.contractNumber.startsWith("44OC") ||
                countryId === 38 && contractDetails?.contractNumber.startsWith("60")    ||
                countryId === 38 && /^[a-zA-Z][-][0-9]{2}[-][a-zA-Z][-][0-9]{4}$/.test(contractDetails?.contractNumber) ||
                countryId === 38 && /^[a-zA-Z][-]SI[-][0-9]{5}$/.test(contractDetails?.contractNumber) ||
                countryId === 38 && contractDetails?.contractNumber.startsWith("13")) {
                setShowNotificationNumber(false);
            }else
            {
                setShowNotificationNumber(true);
            }
        }
        
    }, [contractDetails,countryId])

     useEffect(()=>{
        if (isFileUpload && Object.keys(XMLImportInProgress).length !== 0) {
            if (XMLImportInProgress.isSystemParseInProgress === true) {
              setMessage("XML importing is in progress, XML file cannot be uploaded");
              setDisplayInfoModal(true);
            }
            else{
              dispatch(getUploadedXMLFileList(selectedSystem[0].code, false))
              setShowUploadXMLModal(true);
            }
            setIsFileUpload(false);
          }
     },[isFileUpload,XMLImportInProgress])
    const formSubmitHandler = (data: any) => {
        addForm.current.requestSubmit();
    };
 
    const getFormErrorMessage = (name) => {
        return errors[name] && <span className="tooltip-text"><BsExclamationCircleFill />{errors[name].message}</span>
    };
    
    const onUploadXml = (data: any) => {
        dispatch(onUploadSystemXml(selectedSystem[0].code,loggedInUserDetails?.userDefaults?.uniqueSiteId, data, setShowUploadXMLModal, setMessage, setDisplayInfoModal, false));
        setIsAdd(false);
        setShowAddSystem(false);
    }

    const onDeleteFileClick = (data: any) => {
        dispatch(deleteXMLFile(selectedSystem[0].code, data?.fileId,false, loggedInUserDetails?.userDefaults?.uniqueSiteId, setMessage, setDisplayInfoModal, setShowUploadXMLModal))
    }

    const onAddFormSubmit = (data: any) => {
        let system = systemRadioLookup.find((item: any) => item.code === data.uniqueSystemId)
        data.uniqueSystemId = system?.code;
        data.uniqueTechnicianId = data.uniqueTechnicianId.code
        data.plannedStartDate = moment(data?.plannedStartDate).format("YYYY-MM-DD").toString()
        data.plannedEndDate = moment(data?.plannedEndDate).format("YYYY-MM-DD").toString()
        data.qtyOfTech = data.quantityOfTech;
        data.activeSiteId= loggedInUserDetails?.userDefaults?.uniqueSiteId;
        setStartDate(data.plannedStartDate);
        setEndDate(data.plannedEndDate)
        if (data.reportIds.length === 0) {
            setMessage("Reports are not mapped for the selected Inspection Task, cannot be activated");
            setDisplayInfoModal(true);
        } else if (new Date(data.plannedStartDate) > new Date(data.plannedEndDate)) {
            setMessage("Planned End Date should be greater than Planned Start Date");
            setDisplayInfoModal(true);
        } else if (new Date(data.plannedStartDate?.replace(/-/g, '\/')) < new Date(contractData?.startDate?.replace(/-/g, '\/')) || new Date(data.plannedEndDate?.replace(/-/g, '\/')) > new Date(contractData?.endDate?.replace(/-/g, '\/'))) {
            if (showUI(userPrivileges,'SIETAP_FS_CONTRACT_DATE_UPDATE')) {
                setMessage("Inspection Task Planned End Date or Start Date is not within the Contract Start and End Date. Do you want to change the Contract Start and End Dates?");
                setDisplayConfirmModal(true);
            } else {
                setMessage("Planned Start and End Date of the Inspection task is not within the Start and End Date of Contract. Contact TAP Admin to update the Contract");
                setDisplayInfoModal(true);
            }
        } else {
            data.inspectionTaskName=data.inspectionTaskName?.trim();
            setSubmitData(data);
            setIsSubmit(true);
            dispatch(checkSystemInUse(data.uniqueSystemId));
        }
    };

    useEffect(() => {
        if (isSubmit && Object.keys(systemInUse).length !== 0 && systemInUse?.uniqueSystemId === submitData?.uniqueSystemId) {
            if (systemInUse.isActiveTaskExists) {
                setMessage("An active Inspection Task already exists for this system, cannot add new Inspection Task");
                setDisplayInfoModal(true);
            } else if (!systemInUse.isInspectionTaskCompleted) {
                setMessage("Inspection Task not completed for this System, cannot add an active Inspection Task");
                setDisplayInfoModal(true);
            } else if (!systemInUse.isXMLFileUploaded && systemInUse.isSiemens) {
                setMessage("XML File is not uploaded for the System, cannot activate the Inspection Task");
                setDisplayInfoModal(true); 
            }
            else if(systemInUse.systemStatus !== "Active"){
                setMessage("Selected System is not active. Refresh the page before proceeding.");
                setDisplayInfoModal(true); }
            else {
                onAddInspectionTaskClick && onAddInspectionTaskClick(submitData)
            }
            setIsSubmit(false)
            
        }
    }, [systemInUse, isSubmit, onAddInspectionTaskClick, submitData])

    const onContractUpdated = (data) => {
        setShowContractDate(false);
        setMessage("Contract updated")
        setDisplayInfoModal(true);
        let contractInfo = JSON.parse(JSON.stringify(contractData))
        contractInfo.startDate = data.startDate;
        contractInfo.endDate = data.endDate;
        setContractData(contractInfo);
    }

    const onError = (errors: any, e: any) => { };

    const onAddSystemClick = (event) => {
        event?.preventDefault();
        setShowAddSystem(true)
    }

    const onAddSystemData = (submitData: any) => {
        dispatch(
            onAddSystem(
                submitData,
                setMessage,
                setDisplayInfoModal,
                setDisplayConfirmModal,
                 setShowAddSystem,
                 setIsAdd,

            ))
    }

    const getSortedSystemList = (data:any) => {
 
        let enabledItems = data.filter(item => !item.disabled);
        let disabledItems = data.filter(item => item.disabled);
        enabledItems = enabledItems.sort((a, b) => a.name.localeCompare(b.name));
        setSystemRadioLookup([...enabledItems, ...disabledItems])

    }

    useEffect(() => {
        if (newSystemData) {
            let system: any=systemRadioLookup;
            let newSystem:any =[];
            system.push({ name: newSystemData?.systemName, code: newSystemData?.uniqueSystemId,systemType:newSystemData?.systemType,disabled:false})
            
            getSortedSystemList(system)
            if(newSystemData?.status === "Active")
            setValue("uniqueSystemId", newSystemData?.uniqueSystemId)

            newSystem[0]={ name: newSystemData?.systemName, code: newSystemData?.uniqueSystemId,systemType:newSystemData?.systemType}
            setSelectedSystem(newSystem);
            dispatch(setNewInspectionSystemData(""))
        }
    }, [newSystemData ,setValue])

    useEffect(()=> {
        if(selectedSystem && systemRadioLookup)
        {
            let checkedElement :any = document.getElementById(selectedSystem[0]?.code)
            checkedElement?.scrollIntoView()
        }
      
    },[selectedSystem,systemRadioLookup])

    const saveReportClick = (selections) => {
        let selectedReportId: any = [];
        selections.map((item: any) => {
            return selectedReportId.push(item.inspectionReportId.toString())
        })
        setShowReportsModal(false)
        setValue("reportIds", selectedReportId);
        setSelectedReports(selections)
        dispatch(setLinkedReportsData(""))
    }
    
    const handleSystemRadioChange=(value)=>{
        let system:any=[]
        systemRadioLookup.map((item)=>{
            if(item.code===value)
            system.push({ name: item.name, code: item.code,systemType:item.systemType,disabled:item.disabled})
          })
          setSelectedSystem(system);
        
    }


    const onUploadClick = (event) => {
        event?.preventDefault();
        dispatch(setUploadedXmlFileList(""))
        if (selectedSystem  === undefined || selectedSystem[0]?.name==="" || selectedSystem[0]?.name==="--Select--" ) {
            setMessage("Select a System");
            setDisplayInfoModal(true);
        
          } else {
            if (selectedSystem[0].systemType === "MXL" || selectedSystem[0].systemType === "XLS" || selectedSystem[0].systemType === "Desigo FS20" ||
            selectedSystem[0].systemType === "Desigo FV20" || selectedSystem[0].systemType === "Desigo Modular" || selectedSystem[0].systemType === "Cerberus PRO FC92" ||
            selectedSystem[0].systemType === "Cerberus PRO FV92" || selectedSystem[0].systemType === "Cerberus PRO Modular") {
                dispatch(checkXMLImportInProgress(selectedSystem[0].code));
                setIsFileUpload(true);
            } else{
              setMessage("System Type of selected System is not MXL/XLS/Desigo FS20/Desigo FV20/Desigo Modular/Cerberus PRO FC92/Cerberus PRO FV92/Cerberus PRO Modular, cannot upload the XML file")
              setDisplayInfoModal(true)
            }
          }
        
    }


    const onConfirmActivateClick = (systemId) => {
        setSelectedClosedSystem(systemId)
        setIsActivate(true)
        setMessage("Do you want to activate the selected System?");
    }
    
    const onConfirmClick = () => {
        
        if (isActivate) {
            setDisplayConfirmModal(false);
            dispatch(activateSystem(selectedClosedSystem, setMessage, setDisplayInfoModal));
            dispatch(setSystemInUse([]))
            setSelectedClosedSystem("")
            setIsActivate(false)
        }
        else {

            if (isAdd) {
                setDisplayConfirmModal(false);
                setShowUploadXMLModal(true);
                setIsAdd(false);
            }
            else {
                setShowContractDate(true);
            }
        }
    }
const onCancelClick =()=>{
   if(isActivate){
        setSelectedClosedSystem("")
        setIsActivate(false)}

   if(isAdd){
    setIsAdd(false);
    setShowAddSystem(false);
   }
}

const onHideClick =()=>{
    if(isActivate){
        setSelectedClosedSystem("")
        setIsActivate(false)}
}

useEffect(() => {
   if(loggedInUserDetails){
    let countryId:any = Number;
    countryId = loggedInUserDetails?.userDefaults?.countryId;
    setCountryId(loggedInUserDetails?.userDefaults?.countryId);
    if (countryId === 38) {
     setPattern(CanadaTaskPattern);
     setInspectiontaskNumberPattern(/^((600)[0-9]{6}|[5][0-9]{9})$/);   
   } else if (countryId === 220) {
     setPattern(USATaskPattern);
     setInspectiontaskNumberPattern(/^[5][0-9]{9}$/);
   }
   }
}, [loggedInUserDetails])



const CustomRadioButtonList = (systemProps) => {
    return (
        <div className={`field `}>
            <Controller
                name="uniqueSystemId"
                control={control}
                rules={{ required:'System is required',
                        validate: (value) => {
                            return value.trim().length<=0
                                ?"System is required.":true
                      }
                 }}
                render={({ field, fieldState }) => (
                    <div 
                     className={`radio-section`}
                    >             
                        {systemProps?.item?.map((systemItem: any, key: any) => (
                            <>
                                <span key={key} className="field-radiobutton">
                                    <RadioButton
                                        id={systemItem?.code}
                                        inputId={systemItem?.code}
                                        name={field.name}
                                        value={systemItem?.code}
                                        disabled={systemItem?.disabled}
                                        checked={systemItem?.code === watch("uniqueSystemId") ? true : false}
                                        onChange={(e) => {
                                            field.onChange(e.value);
                                            handleSystemRadioChange(e.value);
                                        }}    
                                        className={classNames({ "p-invalid": fieldState.invalid })} />

                                    <span className="ml-3" style={systemItem?.disabled ? { cursor: "pointer" } : {}} onClick={() => {
                                        if (systemItem?.disabled) {
                                            onConfirmActivateClick(systemItem?.code)
                                        }
                                    }}
                                    title={systemItem?.disabled ? "Click to activate" : undefined}>
                                        <label
                                            className='position-static mt-1'
                                            htmlFor={systemItem?.code}
                                            title={systemItem?.name?.length > 60 ? systemItem?.name : ""}
                                        >
                                            {systemItem?.name?.length > 60
                                                ? <div className={`${systemItem?.disabled ? "red-link" : ""}`}>{`${systemItem?.name.slice(0, 60)}...`}</div>
                                                : <div className={`${systemItem?.disabled ? "red-link" : ""}`}>{`${systemItem?.name}`}</div>}

                                        </label>
                                    </span>
                                    </span>
                            </>
                        ))}
                         {/* {getFormErrorMessage('uniqueSystemId')} */}
                    </div>
                )}
            />
        </div>
    );
};

    return (
        <>
            <AddModal
                header='New Inspection Task'
                showModal={showModal}
                isAdd={true}
                setShowModal={setShowModal}
                style={{ width: '35vw', maxHeight: '100%' }}
                OnSaveClick={formSubmitHandler}
            >
                <form onSubmit={handleSubmit(onAddFormSubmit, onError)} ref={addForm}>
                    <Dialog
                        header="Notification Number"
                        visible={showPatternModal}
                        onHide={() => setShowPatternModal(false)}
                        className='pattern-overlay'
                        style={{ width: '32vw' }}
                    >
                        <DataTable showGridlines={false} value={pattern}>
                            <Column field="pattern" header="Pattern" />
                            <Column field="format" header="Format" />
                            <Column field="description" header="Description" />
                        </DataTable>
                    </Dialog>

                    {showReportsModal && (<InspectionReports
                        showModal={showReportsModal}
                        setShowModal={setShowReportsModal}
                        selectedReports={selectedReports}
                        saveReportClick={saveReportClick}
                        isAdd={true}
                    />)}

                    <div className="row px-2">
                        <div className="field col-12 md:col-2 mb-0">
                            <span className="p-float-label text-container">
                                <label htmlFor="inputtext">Customer</label>
                                <Controller
                                    name="uniqueCustomerId"
                                    control={control}
                                    render={() => (
                                        <span className='text-value'>{customerName}</span>
                                    )} />
                            </span>
                        </div>
                    </div>

                    <div className="row px-2">
                        <div className="field col-12 md:col-2 mb-0 py-0">
                            <span className="p-float-label text-container">
                                <label htmlFor="inputtext">Contract</label>
                                <Controller
                                    name="uniqueContractId"
                                    control={control}
                                    render={() => (
                                        <span className='text-value'>{contractName}</span>
                                    )} />
                            </span>
                        </div>
                    </div>

                    <div className="row px-2 pt-2">
                        <div className="field col-12 md:col-2 mb-0">
                            <span className="p-float-label">
                                <Controller
                                    name="inspectionTaskName"
                                    control={control}
                                    rules={{
                                        required: 'Name is required.',
                                        validate: (value) => {
                                            return value.trim().length<=0
                                              ?"Name is required.":true
                                          }
                                    }}

                                    render={({ field, fieldState }) => (
                                        <InputText
                                            id="name"
                                            className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })}
                                            {...field}
                                        />
                                    )} />
                                <label className='mandatory' htmlFor="inputtext">Name</label>
                                {getFormErrorMessage('inspectionTaskName')}
                            </span>
                        </div>
                    </div>

                    <div className="row px-2 pt-2 d-flex align-items-center">
                        <div className="field col-10 md:col-10 mb-0">
                            <div className="border-box">
                            {errors['uniqueSystemId'] && (<span className="error-show checkbox-error"><BsExclamationCircleFill />System is Required</span>)}
                             <label className='mandatory title' htmlFor="">System</label>
                             <div className="system-radiobuttonlist">
                                <span className="p-float-label">
                                    {systemRadioLookup &&
                                        <CustomRadioButtonList item={systemRadioLookup}/>
                                    }
                                </span>
                             </div>
                            </div>
                        </div>
                        <div className="field col-2 md:col-2 mb-0 p-0 d-flex">
                            <span className="p-float-label action-btns mr-1">
                                <Button tooltip="Add" tooltipOptions={{ position: "top" }} className='button-icon' onClick={onAddSystemClick}>
                                    <BsPlusLg className='icon' />
                                </Button>
                            </span>
                            <span className="p-float-label action-btns">
                                <Button tooltip="Upload XML File" tooltipOptions={{ position: "top" }} className='button-icon' onClick={onUploadClick}>
                                    <BsPaperclip className='icon' />
                                </Button>
                            </span>
                        </div>
                    </div>

                    <div className="row px-2">
                        <div className="field col-12 md:col-2">
                            <span className="p-float-label">
                                <Controller
                                    name="plannedStartDate"
                                    control={control}
                                    rules={{
                                        required: 'Planned Start Date is required.'
                                    }}
                                    render={({ field, fieldState }) => (
                                      <Calendar
                                            id="plannedStartDate"
                                            className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })}
                                            dateFormat="yy-mm-dd"
                                            {...field}
                                            value={field.value}
                                            onChange={(e) => field.onChange(e.value)} />
                                    )} />
                                <label className='mandatory' htmlFor="calendar">Planned Start Date</label>
                                {getFormErrorMessage('plannedStartDate')}
                            </span>
                        </div>
                    </div>

                    <div className="row px-2">
                        <div className="field col-12 md:col-2">
                            <span className="p-float-label">
                                <Controller
                                    name="plannedEndDate"
                                    control={control}
                                    rules={{
                                        required: 'Planned End Date is required.'
                                    }}
                                    render={({ field, fieldState }) => (
                                        <Calendar
                                            id="plannedEndDate"
                                            className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })}
                                            dateFormat="yy-mm-dd"
                                            {...field}
                                            value={field.value}
                                            onChange={(e) => field.onChange(e.value)} />
                                    )} />
                                <label className='mandatory' htmlFor="calendar">Planned End Date</label>
                                {getFormErrorMessage('plannedEndDate')}
                            </span>
                        </div>
                    </div>

                    {showNotificationNumber? (<div className="row px-2 py-2">
                        <div className="field col-12 md:col-2 mb-0 pb-0">
                            <span className="p-float-label">
                                <Controller
                                    name="notificationNumber"
                                    control={control}
                                    rules={{
                                        required:'Notification Number is required',
                                        pattern: { value: inspectiontaskNumberPattern, message: 'Enter a valid Notification Number' }
                                    }}

                                    render={({ field, fieldState }) => (
                                        <InputText
                                            id="notificationNumber"
                                            className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })}
                                            {...field}
                                        />
                                    )} />
                                <label className='mandatory' htmlFor="inputtext">Notification Number</label>
                                {getFormErrorMessage('notificationNumber')}
                            </span>
                        </div>
                        <div className='row p-0'>
                            <div className="field col-12 md:col-2 py-0 text-right m-0 p-0">
                                <Button label="View Pattern" aria-label="View Pattern" className='check-btnLink' onClick={e => {
                                    e.preventDefault();
                                    setShowPatternModal(true);
                                }} />
                            </div>
                        </div>
                    </div>):null}

                    <div className="row px-2">
                        <div className="field col-12 md:col-4">
                            <div className="field-checkbox px-2">
                                <Controller
                                    name="isTechRequiredAtPanel"
                                    control={control}
                                    render={({ field }) => (
                                        <Checkbox
                                            inputId="binary"
                                            checked={field.value}
                                            {...field}
                                        />
                                    )} />
                                <label className='mandatory' htmlFor="binary">Requires a Technician at the Panel</label>
                            </div>
                        </div>
                    </div>

                    <div className="row px-2">
                        <div className="field col-12 md:col-2 mb-0 py-0">
                            <span className="p-float-label">
                                <Controller
                                    name="quantityOfTech"
                                    control={control}
                                    rules={{
                                        required: 'Qty of Techs is required.',
                                        maxLength: 3,
                                        validate: () => {
                                            return getValues("quantityOfTech") <1 || getValues("quantityOfTech") >99
                                              ? "Enter a value between 1 and 99 in Qty of Techs"
                                              : undefined;
                                          },
                                    }}

                                    render={({ field, fieldState }) => (
                                        <InputNumber
                                            inputId="inputnumber"
                                            className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })}
                                            {...field}
                                            value={field.value}
                                            onChange={(e) => field.onChange(e.value)} />
                                    )} />
                                <label className='mandatory' htmlFor="inputnumber">Qty of Techs</label>
                                {getFormErrorMessage('quantityOfTech')}
                            </span>
                        </div>
                    </div>

                    <div className="row px-2 pt-2">
                        <div className="field col-12 md:col-2 mb-0 pb-0">
                            <span className="p-float-label">
                                <Controller
                                    name="uniqueTechnicianId"
                                    control={control}
                                    rules={{
                                        required: 'Lead Technician is required.'
                                    }}
                                    render={({ field, fieldState }) => (
                                        <Dropdown
                                            inputId="leadTech"
                                            className={classNames('w-100 error-tooltip', { 'error': fieldState.invalid })}
                                            {...field}
                                            value={field.value}
                                            onChange={(e) => field.onChange(e.value)}
                                            options={technicianLookup}
                                            optionLabel="name"
                                        />
                                    )} />
                                <label className='mandatory' htmlFor="dropdown">Lead Technician</label>
                                {getFormErrorMessage('uniqueTechnicianId')}
                            </span>
                        </div>
                        <div className='row p-0'>
                            <div className="field col-12 md:col-2 py-0 text-right m-0 p-0">
                                <Button label={`Report Selected (${selectedReports?.length || 0})`} aria-label="Report Selected" className='check-btnLink' onClick={e => {
                                    e.preventDefault();
                                    setShowReportsModal(true);
                                }} />
                            </div>
                        </div>
                    </div>
                </form>
            </AddModal>
            {showUploadXMLModal && <UploadXML showModal={showUploadXMLModal} setShowModal={setShowUploadXMLModal} onUploadXmlClick={onUploadXml} uploadedFileList={uploadedXmlFileList} onDeleteFileClick={onDeleteFileClick} title="Upload XML File" typeFlag = "XML"/>}
            {showAddSystem && (<AddSystems showModal={showAddSystem} setShowModal={setShowAddSystem} onAddSystemClick={(data) => onAddSystemData(data)} />)}
            <InformationBox showInfoModal={displayInfoModal} setShowInfoModal={setDisplayInfoModal} message={message} />
            <ConfirmationBox showConfirmModal={displayConfirmModal} setShowConfirmModal={setDisplayConfirmModal} confirmAction={()=> {onConfirmClick();setDisplayConfirmModal(false)}} cancelAction={onCancelClick} message={message} onHideAction = {onHideClick} />
            {showContractDate && (<ContractDate showModal={showContractDate} setShowModal={setShowContractDate} contractDetails={contractData} plannedStartDate={startDate} plannedEndDate={endDate} onContractUpdated={onContractUpdated}/>)}
        </>
    );
}

export default AddInspectionTask;