import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { zlink, baseURL } from "../../../../service/HttpService";
import { AppThunk } from "../../../store";

export interface InspectionTaskFilterState {
  errors: any;
  customerList: any;
  contractList: any;
  customerReviseList: any;
  contractReviseList: any;
  selectedCustomerId: any;
  selectedContractId: any;
  isCustomerAll: any;
  isContractAll: any;
  selectedCustomerDetails: any;
  selectedContractDetails: any;
  customerSearchDetails:any;
  contractSearchDetails:any;
  selectedSite: any;
}

let initialState: InspectionTaskFilterState = {
  errors: "",
  customerList: "",
  contractList: "",
  customerReviseList: [],
  contractReviseList: [],
  selectedCustomerId: "",
  selectedContractId: "",
  isCustomerAll: false,
  isContractAll: false,
  selectedCustomerDetails: "",
  selectedContractDetails: "",
  customerSearchDetails:[],
  contractSearchDetails:[],
  selectedSite: "",
};

const InspectionTaskFilterSlice = createSlice({
  name: "inspectionTaskFilter",
  initialState,
  reducers: {
    setErrors: (state, { payload }: PayloadAction<any>) => {
      state.errors = payload;
    },
    setCustomerListData: (state, { payload }: PayloadAction<any>) => {
      state.customerList = payload;
    },
    setCustomerReviseList: (state, { payload }: PayloadAction<any>) => {
      state.customerReviseList = payload;
      state.selectedCustomerId = "";
      state.selectedContractId = "";
      state.selectedCustomerDetails = "";
      state.selectedContractDetails = "";
    },
    setContractListData: (state, { payload }: PayloadAction<any>) => {
      state.contractList = payload;
    },
    setSelectedCustomerId: (state, { payload }: PayloadAction<any>) => {
      if(state.selectedCustomerId !== payload){
        state.selectedCustomerId = payload;
      }
    },
    setSelectedContractId: (state, { payload }: PayloadAction<any>) => {
      if(state.selectedContractId !== payload){
        state.selectedContractId = payload;
      }
    },
    clearSelectedCustomerId: (state, { payload }: PayloadAction<any>) => {
      state.selectedCustomerId = payload;
    },
    clearSelectedContractId: (state, { payload }: PayloadAction<any>) => {
      state.selectedContractId = payload;
    },
    setSelectedCustomerDetails: (state, { payload }: PayloadAction<any>) => {
      state.selectedCustomerDetails = payload;
    },
    setSelectedSite: (state, { payload }: PayloadAction<any>) => {
      state.selectedSite = payload;
    },
    setIsCustomerAll: (state) => {
      state.isCustomerAll = !state.isCustomerAll;
    },
    setIsContractAllFalse: (state) => {
      state.isContractAll =false;
    },
    setIsContractAll: (state) => {
      state.isContractAll = !state.isContractAll;
    },
    setSelectedContractDetails: (state, { payload }: PayloadAction<any>) => {
      state.selectedContractDetails = payload;
    },
    setCustomerSearchDetails: (state, {payload}: PayloadAction<any>) => {
			state.customerSearchDetails = payload;
		},
    setContractSearchDetails: (state, {payload}: PayloadAction<any>) => {
			state.contractSearchDetails = payload;
		},
  },
});
export const getCustomerList = (): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.get(
      `${baseURL}api/v1/inspection/inspectiontask/customer/customerlist`
    );
    if (res.status === "done") {
      dispatch(setCustomerListData(res?.data?.customerDetails));
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const getContractList = (uniqueCustomerId): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.get(`${baseURL}api/v1/inspection/inspectiontask/customers/${uniqueCustomerId}/contracts`);
    if (res.status === "done") {  
      if (res.data && res.data.contractDetails) {
        dispatch(setContractListData(res.data.contractDetails));
      }    
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const getDefaultValues = (): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.get(`${baseURL}api/v1/users/defaults`);
    if (res.status === "done") {
      if (res.data) {
        dispatch(setSelectedCustomerId(res.data.uniqueCustomerId));
        dispatch(setSelectedContractId(res.data.uniqueContractId));
      }
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

export const getCustomerSearchDetails = (customerSearchText:any): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.get(`${baseURL}api/v1/inspection/inspectiontask/customer/customerlist?keyword=${customerSearchText}`);
		if (res.status === "done") {
			dispatch(setCustomerSearchDetails(res?.data?.customerDetails));
		} else {
			dispatch(setErrors(res.error));
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};
export const getContractSearchDetails = (contractSearchText:any): AppThunk => async (dispatch) => {
	try {
		const res = await zlink.get(`${baseURL}api/v1/inspection/inspectiontask/customers/search/contracts?search=${contractSearchText}`);
		if (res.status === "done") {
			dispatch(setContractSearchDetails(res?.data?.contractDetails));
		} else {
			dispatch(setErrors(res.error));
		}
	} catch (error) {
		dispatch(setErrors(error));
	}
};

export const setDefaultValues = (body): AppThunk => async (dispatch) => {
  try {
    const res = await zlink.post(`${baseURL}api/v1/admin/user/defaults`, body);
    if (res.status === "done") {
    } else {
      dispatch(setErrors(res.error));
    }
  } catch (error) {
    dispatch(setErrors(error));
  }
};

  export const mapInspectionContract =(uniqueCustomerId: any, body: any, setMessage: any, setDisplayInfoModal: any, setIsMapContract, setShowInspectionMapContract: any): AppThunk => async (dispatch) => {
    try {
      const res = await zlink.post(`${baseURL}api/v1/inspection/customers/${uniqueCustomerId}/contracts`, body);
      if (res.status === "done") {
        dispatch(getContractList(uniqueCustomerId));
        setIsMapContract(true);
        setMessage("Contract(s) updated");
        setDisplayInfoModal(true);
        setShowInspectionMapContract(false);
      } else {
        dispatch(setErrors(res.error));
      }
    } catch (error) {
      dispatch(setErrors(error));
    }
  };

  export const onMapCustomer =(body: any, setMessage: any, setDisplayInfoModal: any, setShowSiteMapCustomer: any): AppThunk => async (dispatch) => {
    try {
      const res = await zlink.post(`${baseURL}api/v1/inspection/sites/customers`, body);
      if (res.status === "done") {
        setMessage("Customers(s) updated");
        setDisplayInfoModal(true);
        setShowSiteMapCustomer(false)
      } else {
        dispatch(setErrors(res.error));
      }
    } catch (error) {
      dispatch(setErrors(error));
    }
  };

export const {
  setErrors,
  setCustomerListData,
  setContractListData,
  setSelectedCustomerId,
  setSelectedContractId,
  setIsCustomerAll,
  setIsContractAll,
  setIsContractAllFalse,
  setCustomerReviseList,
  setSelectedSite,
  setSelectedCustomerDetails,
  setSelectedContractDetails,
  setCustomerSearchDetails,
  setContractSearchDetails,
  clearSelectedCustomerId,
  clearSelectedContractId,
} = InspectionTaskFilterSlice.actions;
export default InspectionTaskFilterSlice.reducer;
