import React from 'react'
import { Button } from 'react-bootstrap'

const SearchSystemDocument = () => {
  return (
    <>

    <h4 className="doc-title mb-2">Search - System</h4>
    <p>
    System Search option allows the user to search for the required System. 
    User can type minimum three characters of the System Name in the text 
    box provided and select the required System from the list displayed. Click on 
    <span className="doc-subtitle"> Search </span> {" "}

    <Button className="doc-button-icon" >
    <i className="pi pi-search icon" title="Search" />
  </Button>{" "}
    
    icon. The page will be redirected to 
    <span className="doc-subtitle"> Inspection: Systems </span> 
    page where the selected System will be listed.
    </p>
 
    </>
  );
}
export default SearchSystemDocument;
