import React, { useState, useEffect,useRef} from 'react';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';

import { BsExclamationCircleFill } from 'react-icons/bs';

import { getCountryList,getTimeZoneList,getStateList} from "redux/pages/Administration/administrationSlice";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../redux/hooks";
import { getStatusList } from 'redux/pages/Administration/administrationSlice';

interface Props {
    getTableColumns?: (data: any) => void,
    selectedRow: any;
};

export const SiteTableData: React.FC<Props> = ({
    getTableColumns = () => { },
    selectedRow = [],
}) => {
    const countryList = useAppSelector((state) => state.administration.countryList);
    const stateList = useAppSelector((state) => state.administration.stateList);
    const timeZoneList = useAppSelector((state) => state.administration.TimeZoneList);
    const statusList = useAppSelector((state) => state.administration.statusList);

    const [countryLookup, setCountryLookup] = useState([{ label: "", value: "" }]);
    const [stateLookup, setStateLookup] =  useState([{ label: "", value: "" }]);
    const [timeZoneLookup, setTimeZoneLookup] =  useState([{ label: "", value: "" }]);
    const [statusLookup, setStatusLookup] =  useState([{ label: "", value: "" }]);
    const [countryId, setCountryId] = useState(220)

    const siteColumns = useRef<any>();

    const dispatch = useDispatch<any>();

useEffect(() => { 
    dispatch(getCountryList());
    dispatch(getStateList());
    dispatch(getTimeZoneList());
    dispatch(getStatusList());
    if (selectedRow?.countryName && (selectedRow?.countryName).trim() === "United States")
     setCountryId(220)
     else
     setCountryId(38)
}, [dispatch, selectedRow]);

useEffect(() => {
    if(countryList !== "" && stateList !== "" && timeZoneList !== "" && statusList !== ""){
        if (countryList?.countries && countryList?.countries.length) {
            let country: { label: string, value: string }[] = [];
            countryList?.countries.map((item: any) => {         
                return country.push({ label: item.countryName, value: item.countryId });             
            })          
            setCountryLookup(country)
        }
        if (stateList?.statesOfCountries && stateList?.statesOfCountries.length) {
            let state: { label: string, value: string }[] = [];
            stateList?.statesOfCountries.map((item: any) => {
                return state.push({ label: item.statesOfCountryName, value: item.statesOfCountryCode });
            });
            setStateLookup(state);
          }
        if (timeZoneList?.timeZones && timeZoneList?.timeZones.length) {
            let timeZone: { label: string, value: string }[] = [];
            timeZoneList?.timeZones.map((item: any) => {
                return timeZone.push({ label: item.timeZoneName, value: item.timeZoneId });
            });
            setTimeZoneLookup(timeZone);
        }
        if (statusList?.status && statusList?.status.length) {
            let status: { label: string, value: string }[] = [];
            statusList?.status.map((item: any) => {
                if (item.statusName === "Active" || item.statusName === "Closed" || item.statusName === "Proposed"){
                        status.push({ label: item.statusName, value: item.statusCode });
                }  ;
                return status;           
            });
            setStatusLookup(status);
        }
    }
}, [countryList,stateList,timeZoneList,statusList]);

const getErrorMsg = options => {
    const { field, value, rowData } = options;
    const FIELD_REQUIRED = 'This field is required';
    const NUMBER_VALID = 'Enter a valid Site Number';
    switch (field) {
      case 'siteName':
      case 'description':{
        if (!value?.trim())
          return FIELD_REQUIRED;
        break;
      }
      case 'siteNumber': {
        if (!isPatternValid(value, rowData.countryName))
          return NUMBER_VALID
        else if (!value)
          return FIELD_REQUIRED
        break;
      }
      default: {
        return ''
      }
    }
  };

  const isPatternValid = (siteNumber, countryName) => {
    if (!siteNumber) {
      return true;
    }
    if (countryName.trim() === "United States")
      return /^44OC[-][0-9]{7}$|44OP[-][0-9]{6}$|4433[-][0-9]{5}[-][0-9]{5}$|4433[-][0-9]{5}[-][0-9]{4}$/.test(siteNumber);
  };
  
const textEditor = (options) => {
    const errorMsg = getErrorMsg(options);
    return <>
      <InputText className={`cell-error error-tooltip ${errorMsg ? 'error' : ""}`} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />
      {errorMsg && <span className="tooltip-text"><BsExclamationCircleFill />{errorMsg}</span>}
    </>
}

const disabledTextEditor = (options) => {
    const errorMsg = getErrorMsg(options);
    return <>
      <InputText disabled className={`cell-error error-tooltip ${errorMsg ? 'error' : ""}`} type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />
      {errorMsg && <span className="tooltip-text"><BsExclamationCircleFill />{errorMsg}</span>}
    </>
}

const statusEditor = (options) => {
    return (
        <Dropdown value={options.value} disabled options={statusLookup} optionLabel="label" optionValue="label"
            onChange={(e) => options.editorCallback(e.value)} placeholder="Select Status"
            itemTemplate={(option) => {
                return <span className='status-badge'>{option.label}</span>
            }} />
    );
}

const stateEditor = (options) => {
    return (
        <Dropdown disabled value={options.value} options={stateLookup} optionLabel="label" optionValue="label"
            onChange={(e) => options.editorCallback(e.value)} placeholder="Select State"
            itemTemplate={(option) => {
                return <span className='role-badge'>{option.label}</span>
            }} />
    );
}

const CountryEditor = (options) => {
    return (      
        <Dropdown value={options.value} disabled={true} options={countryLookup} optionLabel="label" optionValue="label"
            onChange={(e) => options.editorCallback(e.value)} placeholder="Select Country"
            itemTemplate={(option) => {
                return <span className='role-badge'>{option.label}</span>
            }} />
    );
}

const timeZoneEditor = (options) => {
    return (
        <Dropdown value={options.value} options={timeZoneLookup} optionLabel="label" optionValue="label"
            onChange={(e) => options.editorCallback(e.value)} placeholder="Select Time Zone"
            itemTemplate={(option) => {
                return <span className='role-badge'>{option.label}</span>
            }} />
    );
}

const SITE_COLS = [
    {
        field: 'siteNumber',
        header: 'Site Number',
        editor: (options) => textEditor(options),
        style: { flex: '1 0 200px' },
        sortable: true,
    },
    {
        field: 'siteName',
        header: 'Site Name',
        editor: (options) => textEditor(options),
        style: { flex: '1 0 290px' },
        sortable: true,
    },
    {
        field: 'status',
        header: 'Site Status',
        editor: (options) => statusEditor(options),
        style: { flex: '1 0 150px' },
        sortable: true,
    },
    {
        field: 'countryName',
        header: 'Country',
        editor: (options) => CountryEditor(options),
        style: { flex: '1 0 200px' },
        sortable: true,
    },
    //Purposely commented based on requirement
    // {
    //     field: 'description',
    //     header: 'Description',
    //     editor: (options) => textEditor(options),
    //     style: { flex: '1 0 175px' },
    //     sortable: true,
    // },
    {
        field: 'state',
        header: 'State',
        editor: (options) => stateEditor(options),
        style: { flex: '1 0 175px' },
        sortable: true,
    },
    {
        field: 'timeZone',
        header: 'Time Zone',
        editor: (options) => timeZoneEditor(options),
        style: { flex: '1 0 290px' },
        sortable: true,
    },
    {
        field: 'systemCount',
        header: 'System Count',
        editor: (options) => disabledTextEditor(options),
        style: { flex: '1 0 125px' },
        sortable: true,
        align: "right"
    },
    {
        field: 'buildingCount',
        header: 'Building Count',
        editor: (options) => disabledTextEditor(options),
        style: { flex: '1 0 125px' },
        sortable: true,
        align: "right"
    },
    {
        field: 'attachments',
        header: 'Attachments',
        editor: (options) => disabledTextEditor(options),
        style: { flex: '1 0 110px' },
        sortable: true,
        align: "right"
    },
];

siteColumns.current = SITE_COLS;


useEffect(() => {
    
    if (statusLookup?.length > 1 && stateLookup?.length > 1 && countryLookup?.length >= 1 && timeZoneLookup?.length > 1){
        
        getTableColumns(siteColumns.current);
        
    }
    
}, [getTableColumns, statusLookup, stateLookup, countryLookup, timeZoneLookup]);

    return (
        <>
        </>
    )
} 
export default SiteTableData;

