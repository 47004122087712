import React from "react";
import { Button } from 'primereact/button';
import { BsPlusLg, BsFillPencilFill, BsTrashFill, BsFillEyeFill, BsPencil, BsCloud } from 'react-icons/bs';
import { FaCog, FaDownload, FaChevronDown, FaBackward, FaSlash } from 'react-icons/fa';
import { IoEllipseOutline } from "react-icons/io5";
import { AiOutlineDoubleLeft } from "react-icons/ai";
import { BiRectangle, BiMove, BiText, BiDotsVertical } from "react-icons/bi";
import { FiEdit, FiSave } from "react-icons/fi";
import { HiOutlinePencilAlt } from "react-icons/hi";

const AsBuiltsDrawingsDocument = () => {
    return (
        <>
            <h4 className="doc-title mb-2">Drawings</h4>
            <p>
                All drawings will be listed with details of Site, Building, Floor, Category, Type, Description,
                DWG file names, DWG file sizes, Uploaded By, Uploaded On, Revisions, Mark ups.
                The list can be sorted on any column by clicking the column headers.
                Through this option Users can Add, Edit Description, Delete, View and Download drawings.
                Revise, Replace, List Revisions, List Markups and Display Settings are grouped under
                More icon in Drawings. On clicking Drawings menu, drawings added if any will be listed in a grid.
            </p>

            <p className="doc-subtitle">Building:</p>
            <p>
                All Buildings will be listed as dropdown in this option.
                Users can select the building and corresponding Drawing from this option.
            </p>

            <p className="doc-subtitle">Expand Drawing: </p>
            <p>
                <span className="doc-subtitle">Expand Drawing </span> 
                <button className="doc-button-icon">
                    <BiDotsVertical className="icon" title="Expand Drawing" />
                </button>
                {" "}and
                <span className="doc-subtitle"> Expand Table </span>
                <button className="doc-button-icon">
                    <AiOutlineDoubleLeft className="icon" title="Expand Table" />
                </button>
                {" "} icons are provided in the Left Hand side of the Drawing list.
                <span className="doc-subtitle"> Expand Drawing </span>
                {" "} icon enables user to view the selected Drawing 
                and helps the user to expand the Drawing/Drawing List or view both together in the window. 
            </p>

            <p className="doc-subtitle">Add Drawing: </p>
            <p>
                This option is for uploading Drawings to Tech Advance<sup>+</sup>  repository.
                Click <span className="doc-subtitle"> Add </span>
                <Button className="doc-button-icon">
                    <BsPlusLg className="icon" title="Add" />
                </Button>{" "} icon.
                Add Drawing window will be displayed with the building selected and
                <span className="doc-subtitle"> Add </span>
                <Button className="doc-button-icon">
                    <BsPlusLg className="icon" title="Add" />
                </Button>{" "}
                icon displayed against floor field.
            </p>

            <p className="doc-subtitle">Edit Description:</p>
            <p>
                Description of a drawing can be edited through this option.
                Select the required drawing from the list and click
                <span className="doc-subtitle"> Edit Description </span>
                <Button className="doc-button-icon">
                    <BsFillPencilFill className="icon" title="Edit" />
                </Button>{" "} icon.
                Edit Description window will be displayed.
                Description entered can be edited and the changes can be saved by clicking the Save Changes button.
            </p>

            <p className="doc-subtitle">Delete:</p>
            <p>
                This option is for deleting a drawing from the repository. Select the drawing to be deleted and click
                <span className="doc-subtitle"> Delete </span>
                <Button className="doc-button-icon">
                    <BsTrashFill className="icon" title="Delte" />
                </Button>{" "} icon.
                A confirmation message will be displayed.
                Click Yes to delete the drawing and the drawing will be removed from the repository.
                The current version (latest version) of this drawing and all its mark-ups will be deleted.
            </p>

            <p className="doc-subtitle">View Drawing:</p>
            <p>
                This option is to view the selected drawing in the browser.
                Options are provided to save/export/print the current view of the drawing file.
                Users can add comments and markup the drawing with different shapes,
                including free hand sketches, and save them to the repository.
                Select the drawing to be viewed and click
                <span className="doc-subtitle"> View </span>
                <Button className="doc-button-icon">
                    <BsFillEyeFill className="icon" title="View" />
                </Button>
                {""} icon and the selected file will be opened up in the Browser.
            </p>

            <p>
                <span className="doc-subtitle">Type Tool: </span>
                Through this <span className="doc-subtitle"> Type Tool </span> 
                <Button className="doc-button-icon">
                    <BiText className="icon" title="Type Tool" />
                </Button>
                {" "} icon, users can add text in the drawing.
            </p>

            <p>
                <span className="doc-subtitle">Edit Comment: </span>
                Through this <span className="doc-subtitle"> Edit Comment </span>
                <Button className="doc-button-icon">
                    <FiEdit className="icon" title="Edit Comment" />
                </Button>
                {" "} icon, Users can edit text in the Drawing.
            </p>

            <p>
                <span className="doc-subtitle">Callout: </span>
                To add text comments on the drawing, click on the
                <span className="doc-subtitle"> Add Comment </span>
                icon in the toolbar above the drawing.
                On clicking this icon, the cursor turns to a cross.
                Click on the drawing where the comment is to be placed,
                to create a text box by clicking the corresponding checkboxes.
                Enter the comment in the text box that shows up and click Save button.
            </p>

            <p>
                <span className="doc-subtitle">Edit Callout: </span>
                The comments once added can be edited by selecting the comment to be edited after clicking the
                <span className="doc-subtitle"> Edit Comment </span>
                <Button className="doc-button-icon">
                    <HiOutlinePencilAlt className="icon" title="Edit Callout" />
                </Button>
                {" "} icon. Edit the changes in the comment in the text box that shows up and
                click Save button to update the comment. Click the <span className="doc-subtitle"> Save </span>
                <Button className="doc-button-icon">
                    <FiSave className="icon" title="Save" />
                </Button>
                {" "} icon to save the changes done in the comments in the current Drawing.
            </p>

            <p>
                <span className="doc-subtitle">Comment Colour: </span>
                This option helps to select the required colour with which comment need to be displayed.
            </p>

            <p>
                <span className="doc-subtitle">Rectangle: </span>
                Using this <span className="doc-subtitle"> Rectangle </span>
                <Button className="doc-button-icon">
                    <BiRectangle className="icon" title="Rectangle" />
                </Button>
                {" "} icon, user can draw rectangular mark ups
                by clicking on a specific point and dragging to the required size.
            </p>

            <p>
                <span className="doc-subtitle">Ellipse: </span>
                Users can draw elliptical mark up in the drawing using this
                <span className="doc-subtitle"> Ellipse </span>
                <Button className="doc-button-icon">
                    <IoEllipseOutline className="icon" title="Ellipse" />
                </Button>
                {" "} icon. Ellipse can be drawn by clicking on a specific point and dragging to the required size.
            </p>

            <p>
                <span className="doc-subtitle">Line: </span>
                Users can draw mark ups as Lines using this
                <span className="doc-subtitle"> Line </span>
                <Button className="doc-button-icon">
                    <FaSlash className="icon" title="Line" />
                </Button>
                {" "} icon in the Drawing.
            </p>

            <p>
                <span className="doc-subtitle">Cloud: </span>
                Using this <span className="doc-subtitle"> Cloud </span>
                <Button className="doc-button-icon">
                    <BsCloud className="icon" title="Cloud" />
                </Button>
                {" "} icon, Users can add mark ups as in the form of Cloud in the Drawing.
            </p>

            <p>
                <span className="doc-subtitle">Free Hand: </span>
                Users can use free hand Drawing or any shape using this
                <span className="doc-subtitle"> Free Hand </span>
                <Button className="doc-button-icon">
                    <BsPencil className="icon" title="Free Hand" />
                </Button>
                {" "} icon.
            </p>

            <p>
                <span className="doc-subtitle">Move: </span>
                Mark ups and comments can be moved using this option.
                Click on the <span className="doc-subtitle"> Move </span>
                <Button className="doc-button-icon">
                    <BiMove className="icon" title="Move" />
                </Button>
                {" "} icon, the cursor changes to a box shape.
                Select the markup to be moved by bringing the box cursor over the markup and clicking on it.
                After selection, when the cursor is moved, a rubber band like line will appear showing the line of movement.
                Click on the location where the Markup is to be placed.
                The Markup will be moved to the new place.
            </p>

            <p>
                <span className="doc-subtitle">Delete: </span>
                Unsaved Mark ups or comments can be deleted using this option.
                Click <span className="doc-subtitle"> Delete </span>
                <Button className="doc-button-icon">
                    <BsTrashFill className="icon" title="Delte" />
                </Button>{" "}
                {" "}icon, the cursor changes to a box shape. Click on the Markup to be deleted.
                A confirmation message will be displayed.
                Click Yes to the confirmation message, the selected Markup will get deleted.
            </p>

            <p>
                <span className="doc-subtitle">Save: </span>
                After creating comments and mark ups, click on the
                <span className="doc-subtitle"> Save </span>
                <Button className="doc-button-icon">
                    <i className="pi pi-save icon" title="Save" />
                </Button>
                {" "} icon to save the markup.
                A description about the markup also needs be entered, and on clicking the Save button
                the comments and markup added will be saved. On saving the markup each time,
                it is saved in a different file. All markups are listed and can be viewed through the
                <span className="doc-subtitle"> Drawings: More: List Markups </span> menu option.
            </p>

            <p>
                <span className="doc-subtitle">Download: </span>
                On clicking <span className="doc-subtitle"> Download </span>
                <Button className="doc-button-icon w-36">
                    <FaDownload className="icon" title="Download" />
                    <FaChevronDown className="caret-small" />
                </Button>
                {" "} icon, users can download the drawing in the format of their choice.
                Available formats include BMP, DWF, DXB, DXF, JPEG, PDF, and SVG.
            </p>

            <p>
                <span className="doc-subtitle">Back: </span>
                On clicking <span className="doc-subtitle"> Back </span>
                <Button className="doc-button-icon">
                    <FaBackward className="icon" title="Back" />
                </Button>
                {" "} icon in the View Drawing page, User can go back to the Drawing List page.
            </p>

            <p className="doc-subtitle">More: </p>
            <p>
                This option lists Revise, Replace, List Revisions, List Markups and Display Settings option.
            </p>

            <p className="doc-subtitle">Revise: </p>
            <p>
                This option is for adding revision for an existing drawing.
                Select a drawing to be revised from the list and click on
                <span className="doc-subtitle"> Revise </span> option from
                <span className="doc-subtitle"> More </span>
                <Button className="doc-button-icon w-36">
                    <FaCog className="icon" title="More" />
                    <FaChevronDown className="caret-small" />
                </Button> icon.
                Building, Floor and Drawing Category will be displayed as read only.
                Enter a new description or modify the existing description appropriately for the revised drawing if required,
                and choose the DWG file to be uploaded. (Browse the file location by clicking Browse button)
                The DWG file name must be the same, except for the extensions. Click on Upload button to
                add new drawing to the repository.
                This will be saved as a revised drawing of the selected building, floor and category.
                Any number of revisions can be made to the drawing. The latest revised drawing will be
                set by default as the current version of the corresponding building, floor and category.
            </p>

            <p className="doc-subtitle">Replace: </p>
            <p>
                This option is to replace the existing drawing.
                Select a drawing to be replaced from the list and click on
                <span className="doc-subtitle"> Replace </span> option from
                <span className="doc-subtitle"> More </span>
                <Button className="doc-button-icon w-36">
                    <FaCog className="icon" title="More" />
                    <FaChevronDown className="caret-small" />
                </Button> icon.
                Building, Floor, Drawing Category and Revision Number will be displayed as read only.
                The Description can be edited if required, and choose the DWG file to be uploaded.
                (Browse the file location by clicking Browse button) The DWG file name must be the same,
                except for the extensions. Click on Upload button to add new drawing to the repository.
            </p>

            <p className="doc-subtitle">List Revisions: </p>
            <p>
                On clicking <span className="doc-subtitle"> List Revisions </span> option from
                <span className="doc-subtitle"> More </span>
                <Button className="doc-button-icon w-36">
                    <FaCog className="icon" title="More" />
                    <FaChevronDown className="caret-small" />
                </Button>{" "}
                icon in the Drawing page, the revisions added to the drawing will be listed in a grid.
                Different menu options like Edit Description, and View to manage the revisions are also available.
            </p>
            <p>
                <span className="doc-subtitle">Edit Description: </span>
                Description of a revised drawing can be edited through this option.
                Select the required drawing from the list and click <span className="doc-subtitle"> Edit Description </span>
                <Button className="doc-button-icon">
                    <BsFillPencilFill className="icon" title="Edit" />
                </Button>{" "} icon.
                Edit Description window will be displayed.
                Description entered can be edited and the changes can be saved by clicking the Save Changes button.
            </p>
            <p>
                <span className="doc-subtitle">View: </span>
                This option is to view the selected drawing in the browser.
                Drawing can be viewed by clicking <span className="doc-subtitle"> View Drawing </span>
                <Button className="doc-button-icon">
                    <BsFillEyeFill className="icon" title="View" />
                </Button>
                {" "} icon in the Revisions list page.
                Options are provided to save/export/print the current view of the drawing file.
                Users can add comments and markup the drawing with different shapes,
                including free hand sketches, and save them to the repository.
            </p>

            <p className="doc-subtitle">List Markups: </p>
            <p>
                On clicking <span className="doc-subtitle"> List Markups </span> option from
                <span className="doc-subtitle"> More </span>
                <Button className="doc-button-icon w-36">
                    <FaCog className="icon" title="More" />
                    <FaChevronDown className="caret-small" />
                </Button>
                {" "} icon, all mark ups available for the drawing will be listed in a grid.
                Different menu options like Edit, Delete, View to manage the mark ups are also available.
            </p>

            <p>
                <span className="doc-subtitle">Edit: </span>
                Description of a Mark up can be edited through this option.
                Select the required Mark up file from the list and click <span className="doc-subtitle"> Edit </span>
                <Button className="doc-button-icon">
                    <BsFillPencilFill className="icon" title="Edit" />
                </Button>{" "} icon.
                Edit Description window will be displayed. Description entered can be edited and the changes
                can be saved by clicking the Save Changes button.
            </p>

            <p>
                <span className="doc-subtitle">Delete: </span>
                This option is to delete the mark up file added to a Drawing.
            </p>

            <p>
                <span className="doc-subtitle">View: </span>
                This option is to view the selected drawing in the browser.
                Drawing can be viewed by clicking <span className="doc-subtitle"> View </span>
                <Button className="doc-button-icon">
                    <BsFillEyeFill className="icon" title="View" />
                </Button>{" "} icon in the mark ups list page.
                Options are provided to save/export/print the current view of the drawing file.
                Users can add comments and mark up the drawing with different shapes,
                including free hand sketches, and save them to the repository.
            </p>

            <p className="doc-subtitle">Display Settings: </p>
            <p>
                This section provides options for the user to modify his default display settings for data items.
                Changes made here will not be saved and will be effective only for the current instance of the data grid.
            </p>
        </>
    );
}

export default AsBuiltsDrawingsDocument;